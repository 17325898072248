import { gql } from "@apollo/client";
import { dailyDiaryItemRegisterViewFields } from "./dailyDiaryItems.query";

export const dailyDiaryItemsRequiresMyAttentionQuery = gql`
  ${dailyDiaryItemRegisterViewFields}

  query dailyDiaryItemsRequiresMyAttention(
    $productInstanceId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    dailyDiaryItemsRequiresMyAttention(
      productInstanceId: $productInstanceId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        __typename
        ... on DailyDiaryItem {
          ...DailyDiaryItemRegisterViewFields
        }
        ... on EmptyDailyDiaryItem {
          productInstanceId
          date
        }
      }
    }
  }
`;
