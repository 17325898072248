import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const InfoIcon: React.FC<BaseIconProps> = ({
  height = "20",
  width = "20",
  color,
}) => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM7.25 7.25V11.75H8.75V7.25H7.25ZM7.25 4.25V5.75H8.75V4.25H7.25Z"
        fill={color || theme.palette.primary.main}
      />
    </svg>
  );
};
