import { NewAppPaths } from "../helpers/paths/paths";
import { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { AuthContext } from "providers/auth.context";
import { useHasAccess } from "hooks/useHasAccess";
import { PermissionsGateProps } from "helpers/Permissions/PermissionsGate";
import { CircularProgress } from "@mui/material";
import { FullSizeCenteredContainer } from "./FullSizeCenteredContainer";

export type AuthGuardedRouteProps = {
  children: React.ReactNode;
  perProductInstance?: boolean;
  requiredPermissions?: string[];
} & Omit<
  PermissionsGateProps,
  "children" | "productInstanceId" | "requiredPermissions"
>;

export const AuthGuardedRoute: React.FC<AuthGuardedRouteProps> = ({
  children,
  allowedRoles,
  requiredPermissions,
  perProductInstance,
}) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { productInstanceId } = useParams();
  const hasAccess = useHasAccess(
    allowedRoles,
    requiredPermissions,
    perProductInstance ? productInstanceId : undefined
  );

  if (isLoggedIn && hasAccess === "unknown") {
    return (
      <FullSizeCenteredContainer>
        <CircularProgress size="32px" />
      </FullSizeCenteredContainer>
    );
  }

  if (!isLoggedIn) {
    return <Navigate to={NewAppPaths.nonAuthorized.Login} replace />;
  }

  if (!hasAccess) {
    return <Navigate to={NewAppPaths.authorized.Home} replace />;
  }

  return <>{children}</>;
};
