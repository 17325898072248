import { gql } from "@apollo/client";
import { instructionItemFields } from "graphql/fragments/instructionItem.fragment";

export const instructionItemsQuery = gql`
  ${instructionItemFields}

  query instructionItems($productInstanceId: ID!) {
    instructionItems(productInstanceId: $productInstanceId) {
      items {
        ...InstructionItemFields
        compEvent {
          id
          number
        }
        claim {
          id
          number
        }
        variation {
          id
          number
        }
      }
    }
  }
`;
