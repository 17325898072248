import { Attachment, AttachmentInput } from "generated/graphql";
import { EnhancedAttachment, FileMIMEType, FileType } from "./Attachments.decl";
import { AttachmentTab } from "./components/AttachmentsTabs";

export const stringToFileMIMEType = (fileType?: string): FileMIMEType => {
  if (!fileType) {
    return FileMIMEType.Unknown;
  }

  if (fileType.indexOf("image/") >= 0) {
    return FileMIMEType.Img;
  } else if (fileType.indexOf("video/") >= 0) {
    return FileMIMEType.Video;
  } else if (fileType.indexOf("pdf") >= 0) {
    return FileMIMEType.Pdf;
  } else if (
    fileType.indexOf(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) >= 0 ||
    fileType.indexOf("application/vnd.ms-excel") >= 0
  ) {
    return FileMIMEType.Xls;
  } else if (fileType.indexOf("zip") >= 0) {
    return FileMIMEType.Zip;
  } else if (
    fileType.indexOf("application/msword") >= 0 ||
    fileType.indexOf(
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) >= 0
  ) {
    return FileMIMEType.Doc;
  } else if (
    fileType.indexOf("application/vnd.ms-powerpoint") >= 0 ||
    fileType.indexOf(
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) >= 0
  ) {
    return FileMIMEType.Ppt;
  }

  return FileMIMEType.Unknown;
};

export const isAttachment = (
  file: FileType | Attachment
): file is Attachment => {
  return (file as Attachment).fileSize !== undefined;
};

export const attachmentsAndFilesSortFn = (
  file1: FileType | Attachment,
  file2: FileType | Attachment
) => {
  if (isAttachment(file1) && isAttachment(file2)) {
    if (file1.locked) {
      if (file2.locked) {
        return (
          new Date(file2.dateCreated).getTime() -
          new Date(file1.dateCreated).getTime()
        );
      } else {
        return -1;
      }
    } else if (file2.locked) {
      return 1;
    } else {
      return (
        new Date(file2.dateCreated).getTime() -
        new Date(file1.dateCreated).getTime()
      );
    }
  } else if (isAttachment(file1) && !isAttachment(file2)) {
    if (file1.locked) {
      return -1;
    } else {
      return (
        new Date(file2.dateCreated).getTime() -
        new Date(file1.dateCreated).getTime()
      );
    }
  } else {
    return (
      new Date(file2.dateCreated).getTime() -
      new Date(file1.dateCreated).getTime()
    );
  }
};

export const attachmentsToAttachmentInputs = (
  files: (EnhancedAttachment | FileType)[]
): AttachmentInput[] => {
  return files.map((file) => ({
    id: isAttachment(file) ? file.id : undefined,
    fileName: file.fileName,
    fileUrl: file.fileUrl!,
    mimeType: file.mimeType!,
    description: file.description,
  }));
};

export const filterAttachmentsByTab = (
  tab: AttachmentTab,
  attachments: (Attachment | FileType)[]
) => {
  let requiredMimeTypes: FileMIMEType[] = [];
  switch (tab) {
    case AttachmentTab.Documents:
      requiredMimeTypes = [
        FileMIMEType.Doc,
        FileMIMEType.Pdf,
        FileMIMEType.Ppt,
        FileMIMEType.Xls,
      ];
      break;
    case AttachmentTab.Images:
      requiredMimeTypes = [FileMIMEType.Img];
      break;
    case AttachmentTab.Videos:
      requiredMimeTypes = [FileMIMEType.Video];
      break;
  }

  return requiredMimeTypes.length
    ? attachments.filter(
        (attach) =>
          requiredMimeTypes.indexOf(stringToFileMIMEType(attach.mimeType)) >= 0
      )
    : attachments;
};

export const downloadURI = (uri: string, name: string) => {
  const link = document.createElement("a");
  link.download = name;
  link.target = "_blank";
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
