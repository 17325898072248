import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";
import { instructionItemFields } from "graphql/fragments/instructionItem.fragment";

export const draftInstructionItemQuery = gql`
  ${instructionItemFields}
  ${attachmentFields}

  query draftInstructionItem($id: ID!) {
    draftInstructionItem(id: $id) {
      ...InstructionItemFields
      attachments {
        ...AttachmentFields
      }
    }
  }
`;
