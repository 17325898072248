import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const contractKeyDateFields = gql`
  ${creatorFields}

  fragment ContractKeyDateFields on ContractKeyDate {
    id
    number
    conditionToBeMet
    keyDate
    status
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
