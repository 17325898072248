import { Box, IconButton, Stack, Typography, css, styled } from "@mui/material";

export const Container = styled(Stack)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(4)};
    border-radius: 8px;
    overflow: hidden;
  `
);

export const Header = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.grey[100]};
    flex-direction: row;
    align-items: center;
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
  `
);

export const Body = styled(Box)`
  background: #f0f0f0; // Note: to use grey from theme
  position: relative;
`;

export const ImgContainer = styled(Box)(
  ({ theme }) => css`
    height: 600px;
    display: flex;
    align-items: center;
    padding-left: ${theme.spacing(10)};
    padding-right: ${theme.spacing(10)};
    cursor: pointer;
  `
);

export const NavigationIconButton = styled(IconButton)<{ left?: boolean }>(
  ({ theme, left }) => css`
    position: absolute;
    background: ${theme.palette.grey[300]};
    border-radius: 8px;
    top: 50%;
    transform: translateY(-50%);
    ${left
      ? css`
          left: ${theme.spacing(2)};
        `
      : css`
          right: ${theme.spacing(2)};
        `}
  `
);

export const FileName = styled(Typography)(
  ({ theme }) => css`
    font-weight: 600;
    color: ${theme.palette.grey[800]};
    margin-left: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
    white-space: nowrap;
  `
);
