import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DeleteConfirmationModal } from "components/DeleteConfirmationModal";
import {
  AddDailyDiaryResourceRecordInput,
  AttachmentInput,
  DailyDiaryExtraConfig,
  DailyDiaryPresetSection,
  DailyDiaryResourceRecord,
  EditDailyDiaryResourceRecordInput,
} from "generated/graphql";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { BaseHeaderSection } from "../BaseSectionHeader";
import {
  ResourceRecordModal,
  ResourceType,
} from "../ManpowerSection/ResourceRecordModal/ResourceRecordModal";
import { ResourceTable } from "../ManpowerSection/ResourceTable/ResourceTable";
import { useEquipmentSection } from "./useEquipmentSection";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { ImportEquipmentRecordsModal } from "./ImportEquipmentRecordsModal";
import { useDiaryMetadata } from "../../hooks/useDiaryMetadata";

export type EquipmentSectionProps = {
  section: DailyDiaryPresetSection;
};

export const EquipmentSection: React.FC<EquipmentSectionProps> = ({
  section,
}) => {
  const { t } = useTranslation();

  const [updatedAttachments, setUpdatedAttachments] =
    useState<AttachmentInput[]>();

  const { dailyDiaryId: dailyDiaryIdSearchParam } = useDiaryMetadata();

  const [recordToDeleteEdit, setRecordToDeleteEdit] =
    useState<DailyDiaryResourceRecord>();
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const {
    modalVisibility: importModalVisibility,
    toggleModalVisibility: toggleImportModalVisibility,
  } = useBasicModal();

  const { modalVisibility, toggleModalVisibility } = useBasicModal();

  const {
    contractLookupCollections,
    activeContractLookupCollections,
    dailyDiary,
    emptyDailyDiary,
    loading: contextLoading,
    productInstance,
    crtRevisionId,
    refetchDDByDate,
  } = useContext(DailyDiaryContext);
  const {
    equipmentRecords,
    loading: equipmentRecordsLoading,
    addEquipmentRecord,
    editEquipmentRecord,
    removeEquipmentRecord,
    refetchDDEquipmentRecords,
  } = useEquipmentSection(dailyDiary, crtRevisionId);

  const equipmentLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).equipmentLookupId
    );
  }, [productInstance]);

  const shiftLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).shiftLookupId
    );
  }, [productInstance]);

  const companyLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).companyLookupId
    );
  }, [productInstance]);

  const teamLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).teamLookupId
    );
  }, [productInstance]);

  const localSection = useMemo(() => {
    let overwrittenSection = section;

    overwrittenSection = equipmentLookupIdOverride
      ? {
          ...overwrittenSection,
          fields: overwrittenSection.fields.map((field) => {
            if (field.name === "Resource" && field.fieldType === "LKP") {
              return {
                ...field,
                lookupId: equipmentLookupIdOverride,
              };
            }
            return field;
          }),
        }
      : overwrittenSection;

    overwrittenSection = shiftLookupIdOverride
      ? {
          ...overwrittenSection,
          fields: overwrittenSection.fields.map((field) => {
            if (field.name === "Shift" && field.fieldType === "LKP") {
              return {
                ...field,
                lookupId: shiftLookupIdOverride,
              };
            }
            return field;
          }),
        }
      : overwrittenSection;

    overwrittenSection = companyLookupIdOverride
      ? {
          ...overwrittenSection,
          fields: overwrittenSection.fields.map((field) => {
            if (field.name === "Company" && field.fieldType === "LKP") {
              return {
                ...field,
                lookupId: companyLookupIdOverride,
              };
            }
            return field;
          }),
        }
      : overwrittenSection;

    overwrittenSection = teamLookupIdOverride
      ? {
          ...overwrittenSection,
          fields: overwrittenSection.fields.map((field) => {
            if (field.name === "Team" && field.fieldType === "LKP") {
              return {
                ...field,
                lookupId: teamLookupIdOverride,
              };
            }
            return field;
          }),
        }
      : overwrittenSection;

    return overwrittenSection;
  }, [
    section,
    equipmentLookupIdOverride,
    shiftLookupIdOverride,
    companyLookupIdOverride,
    teamLookupIdOverride,
  ]);

  const toggleConfirmationModalVisibility = () => {
    setConfirmationModalVisibility((crtState) => !crtState);
  };

  const triggerEditRecordFlow = (recordId: GridRowId) => {
    const record = equipmentRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    toggleModalVisibility();
  };

  const triggerRemoveFlow = (recordId: GridRowId) => {
    const record = equipmentRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    setConfirmationModalVisibility(true);
  };

  const handleAttachmentsUpdated = (attachmentInputs: AttachmentInput[]) => {
    setUpdatedAttachments(attachmentInputs);
  };

  const handleModalPrimaryClick = async (
    equipmentRecord:
      | AddDailyDiaryResourceRecordInput
      | EditDailyDiaryResourceRecordInput,
    closeModal: boolean
  ) => {
    if (recordToDeleteEdit) {
      await editEquipmentRecord({
        variables: {
          input: {
            ...(equipmentRecord as EditDailyDiaryResourceRecordInput),
            id: recordToDeleteEdit.id,
            attachments:
              updatedAttachments ??
              attachmentsToAttachmentInputs(
                recordToDeleteEdit.attachments ?? []
              ),
          },
        },
      });
    } else {
      await addEquipmentRecord({
        variables: {
          input: {
            ...(equipmentRecord as AddDailyDiaryResourceRecordInput),
            ...(dailyDiary?.id
              ? { dailyDiaryId: dailyDiary.id }
              : {
                  date: emptyDailyDiary?.date,
                  productInstanceId: emptyDailyDiary?.productInstanceId,
                }),
            attachments: updatedAttachments ?? [],
          },
        },
      });
    }

    setUpdatedAttachments(undefined);

    if (closeModal) {
      handleEquipmentModalClose();
    }
  };

  const handleSuccessfulImport = () => {
    toggleImportModalVisibility();
    if (dailyDiaryIdSearchParam) {
      refetchDDEquipmentRecords();
    } else {
      refetchDDByDate?.();
    }
  };

  const handleEquipmentModalClose = () => {
    toggleModalVisibility();
    setRecordToDeleteEdit(undefined);
  };

  const handleRemoveRecord = () => {
    toggleConfirmationModalVisibility();
    removeEquipmentRecord({ variables: { id: recordToDeleteEdit?.id! } });
    setRecordToDeleteEdit(undefined);
  };

  const handleAddEquipmentRecord = () => {
    toggleModalVisibility();
  };

  useEffect(() => {
    if (recordToDeleteEdit && !equipmentRecords.includes(recordToDeleteEdit)) {
      const updatedRecord = equipmentRecords.find(
        (rec) => rec.id === recordToDeleteEdit.id
      );
      if (updatedRecord) {
        setRecordToDeleteEdit(updatedRecord);
      }
    }
  }, [equipmentRecords, recordToDeleteEdit]);

  useEffect(() => {
    if (!recordToDeleteEdit) {
      setUpdatedAttachments(undefined);
    }
  }, [recordToDeleteEdit]);

  return (
    <>
      <DeleteConfirmationModal
        title={t("Projects.DailyDiaries.RemoveConfirmationModal.title")}
        subtitle={t("Projects.DailyDiaries.RemoveConfirmationModal.subtitle")}
        open={confirmationModalVisibility}
        onPrimaryClick={handleRemoveRecord}
        onSecondaryClick={toggleConfirmationModalVisibility}
      />
      {importModalVisibility && (
        <ImportEquipmentRecordsModal
          open={importModalVisibility}
          section={localSection}
          lookups={contractLookupCollections}
          loading={contextLoading}
          onClose={toggleImportModalVisibility}
          onImportSuccess={handleSuccessfulImport}
        />
      )}
      <ResourceRecordModal
        open={modalVisibility}
        onPrimaryClick={handleModalPrimaryClick}
        onSecondaryClick={handleEquipmentModalClose}
        onClose={handleEquipmentModalClose}
        onAttachmentsUpdated={handleAttachmentsUpdated}
        section={localSection}
        resourceRecord={recordToDeleteEdit}
        primaryBtnCaption={
          recordToDeleteEdit ? t("common.labels.saveChanges") : undefined
        }
        primaryBtnLoading={equipmentRecordsLoading}
        type={ResourceType.Equipment}
        attachmentsPreview={
          updatedAttachments && recordToDeleteEdit
            ? recordToDeleteEdit.attachments?.filter((attach) =>
                updatedAttachments.find(
                  (crtAttach) => crtAttach.id === attach.id
                )
              )
            : undefined
        }
        resourceLookupCollections={activeContractLookupCollections}
      />
      <Box width="100%">
        <BaseHeaderSection
          title={t("Projects.DailyDiaries.sections.equipment")}
          onAdd={handleAddEquipmentRecord}
          onImport={toggleImportModalVisibility}
          mb={3}
        />
        <ResourceTable
          section={localSection}
          records={equipmentRecords}
          resourceLookupCollections={contractLookupCollections}
          loading={equipmentRecordsLoading || contextLoading}
          recordName={t("Projects.DailyDiaries.sections.equipment")}
          onEdit={triggerEditRecordFlow}
          onRemove={triggerRemoveFlow}
        />
      </Box>
    </>
  );
};
