import {
  ProductItemNextStepsAllQuery,
  ProductItemNextStepsAllQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useCallback, useEffect, useMemo, useState } from "react";
import { productItemNextStepsAllQuery } from "../NextSteps.query";
import { NextStepItem } from "../NextStepsTable/NextStepsTable";
import { nextStepItemQueryToNextStepItem } from "../NextSteps.utils";
import uniqBy from "lodash.uniqby";

const recordsChunkLimit = 50;

// Note: even though query returns ProductItem, only CEs, VOs and Claims will be returned
export const useGetAllItems = () => {
  const [nextToken, setNextToken] = useState<string>();

  const [fetch, { data: items, fetchMore, refetch, loading }] =
    useGraphLazyQuery<
      ProductItemNextStepsAllQuery,
      ProductItemNextStepsAllQueryVariables
    >(productItemNextStepsAllQuery, {
      notifyOnNetworkStatusChange: true,
    });

  const loadMore = useCallback(async () => {
    if (nextToken) {
      const { data } = await fetchMore({
        variables: { limit: recordsChunkLimit, nextToken },
        updateQuery: (oldData, { fetchMoreResult: newData }) => {
          const newItems = [
            ...oldData.productItemNextStepsAll.items,
            ...newData.productItemNextStepsAll.items,
          ];

          const uniqNewItems = uniqBy(newItems, "id");

          return {
            ...newData,
            productItemNextStepsAll: {
              ...newData.productItemNextStepsAll,
              items: uniqNewItems,
            },
          };
        },
      });

      setNextToken(data.productItemNextStepsAll.nextToken ?? undefined);
    } else {
      const { data } = await fetch({
        variables: { limit: recordsChunkLimit },
      });

      setNextToken(data?.productItemNextStepsAll.nextToken ?? undefined);
    }
  }, [fetch, fetchMore, nextToken]);

  const reset = () => {
    setNextToken(undefined);
    refetch({ limit: recordsChunkLimit });
  };

  const itemsAll = useMemo(() => {
    const rawItems = (items?.productItemNextStepsAll.items ??
      []) as NextStepItem[];

    return nextStepItemQueryToNextStepItem(rawItems);
  }, [items]);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line
  }, []);

  return {
    itemsAll,
    loading,
    hasMore: !!nextToken,
    loadMore,
    reset,
  };
};
