import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimNoticeLapsedNoticePromptQuery = gql`
  query claimNoticeLapsedNoticePrompt(
    $input: ClaimNoticeLapsedNoticePromptInput!
  ) {
    claimNoticeLapsedNoticePrompt(input: $input) {
      remarks
    }
  }
`;

export const sendClaimNoticeLapsedNoticeMutation = gql`
  mutation sendClaimNoticeLapsedNotice(
    $input: SendClaimNoticeLapsedNoticeInput!
  ) {
    sendClaimNoticeLapsedNotice(input: $input) {
      id
    }
  }
`;

export const recordClaimNoticeLapsedNoticeMutation = gql`
  mutation recordClaimNoticeLapsedNotice(
    $input: RecordClaimNoticeLapsedNoticeInput!
  ) {
    recordClaimNoticeLapsedNotice(input: $input) {
      id
    }
  }
`;

export const generateSendClaimNoticeLapsedNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimNoticeLapsedNoticePreview(
    $input: SendClaimNoticeLapsedNoticePreviewInput!
  ) {
    generateSendClaimNoticeLapsedNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimNoticeLapsedNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimNoticeLapsedNoticePreview(
    $input: RecordClaimNoticeLapsedNoticePreviewInput!
  ) {
    generateRecordClaimNoticeLapsedNoticePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
