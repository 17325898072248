import { gql } from "@apollo/client";

export const documentTemplateRoutesQuery = gql`
  query documentTemplateRoutes {
    documentTemplateRoutes {
      items {
        id
        productOutputActionId
        contractTypeId
        originatingPartyId
        contractId
        targetPartyId
        defaultNumbering
        clause
        documentTemplateId
        isInternal
        dateCreated
        outputAction {
          id
          name
          productId
          isInternal
          status
          dateCreated
          product {
            id
            name
            status
          }
        }
        contractType {
          id
          description
          subType
          version
          status
          contractBindingTypes {
            items {
              id
              description
              status
            }
          }
        }
        originatingParty {
          id
          description
          status
        }
        targetParty {
          id
          description
          status
        }
        documentTemplate {
          id
          name
          description
          content
          isInternal
          status
          dateCreated
        }
        creator {
          id
          firstname
          surname
        }
      }
    }
  }
`;
