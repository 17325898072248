import { Stack, Typography, useTheme } from "@mui/material";
import { ClockCounterClockwise } from "phosphor-react";
import { useTranslation } from "react-i18next";

export const ChangelogTitle: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <ClockCounterClockwise size={24} color={theme.palette.grey[900]} />
      <Typography variant="h3" color="grey.900">
        {t("common.labels.changeLog")}
      </Typography>
    </Stack>
  );
};
