import { Box, Typography, css, styled, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { customShadows } from "theme/extendedTheme";
import { ApproverListItem } from "./ApproverListItem";
import React, { forwardRef } from "react";
import { ListItemsDivider } from "components/ListItemsDivider";
import {
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowLevel,
  AuthorizationWorkflowLevelMode,
} from "generated/graphql";

export type ApproversAvatarsFlyoutProps = {
  level: AuthorizationWorkflowLevel;
  authAudit?: AuthorizationWorkflowAudit;
};

const PopperContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    box-shadow: ${customShadows.popoverMedium};
    border-radius: 8px;
    padding: ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
  `
);

export const ApproversAvatarsFlyout = forwardRef(
  ({ level, authAudit }: ApproversAvatarsFlyoutProps, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <PopperContainer ref={ref}>
        <Typography
          variant="caption2"
          color="grey.800"
          fontWeight={700}
          textTransform="uppercase"
        >
          {t("Home.Authorizations.levelNo", {
            number: level.sequence,
          })}
        </Typography>
        <Typography
          variant="caption2"
          color="grey.800"
          fontWeight={400}
          mt={0.5}
          mb={theme.spacing(2)}
        >
          {t("Home.Authorizations.authorizationRuleNormal", {
            context:
              level.mode === AuthorizationWorkflowLevelMode.AnyoneCanAuthorize
                ? "anyone"
                : "everyone",
          })}
        </Typography>
        {level.users.items.map((user, index) => {
          return (
            <React.Fragment key={user.id}>
              <ApproverListItem
                user={user}
                status={
                  authAudit?.auditTrails?.find(
                    (trail) => trail.creatorId === user.id
                  )?.status ?? undefined
                }
              />
              {index < level.users.items.length - 1 && (
                <ListItemsDivider
                  sx={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </PopperContainer>
    );
  }
);
