import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Overlay } from "components/Overlay";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { FormPublicApi } from "types/decl";
import {
  AddContractBindingInput,
  Company,
  ContractBinding,
  ContractBindingStatus,
  ContractBindingType,
  EditContractBindingInput,
  User,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  dataValidators,
  defaultContractBinding,
} from "./ContractBindingForm.constants";
import { getUserNameOrEmail } from "helpers/miscelaneous";

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: flex-start;
  margin: 0;
  width: 100%;
  text-align: left;
`;

export type ContractBindingFormProps = {
  contractId: string;
  companies: Company[];
  bindingTypes: ContractBindingType[];
  companyUsers: User[];
  contractBinding?: ContractBinding;
  disabled?: boolean;
  apiRef?: React.Ref<FormPublicApi>;
  usersSelectDisabled?: boolean;
  onChange: (
    updatedContractBinding: AddContractBindingInput | EditContractBindingInput
  ) => void;
  onStatusChange?: (newStatus: ContractBindingStatus) => void;
};

export const ContractBindingForm: React.FC<ContractBindingFormProps> = ({
  contractId,
  contractBinding,
  disabled,
  companies,
  bindingTypes,
  companyUsers,
  usersSelectDisabled,
  apiRef,
  onStatusChange,
  onChange,
}) => {
  const { t } = useTranslation();
  const contractBindingStatusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ContractBindingStatus>[];
  const companiesIds = useMemo(
    () => companies.map((company) => company.id),
    [companies]
  );
  const bindingTypesIds = useMemo(
    () => bindingTypes.map((bindingType) => bindingType.id),
    [bindingTypes]
  );

  const [localStatus, setLocalStatus] = useState<
    ContractBindingStatus | undefined
  >(contractBinding?.status);

  const [formData, setFormData] = useState<AddContractBindingInput>(
    contractBinding ?? defaultContractBinding
  );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const companyUsersIds = useMemo(() => {
    const companyUsersClone = companyUsers.slice();

    return formData.companyId
      ? companyUsersClone
          .sort((u1, u2) =>
            getUserNameOrEmail(u1).localeCompare(getUserNameOrEmail(u2))
          )
          .map((companyUser) => companyUser.id)
      : [];
  }, [companyUsers, formData]);
  const handleCompanyChange = (
    _: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      companyId: value!,
      representativeId: "",
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { companyId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleBindingTypeChange = (
    _: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      contractBindingTypeId: value!,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { contractBindingTypeId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleUserChange = (
    _: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      representativeId: value!,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { representativeId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleCheckboxChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: checked,
    }));
  };

  const handleStatusChange = useCallback(
    (newStatus: StatusOption<ContractBindingStatus>) => {
      const newStatusValue = newStatus.id;

      setLocalStatus(newStatusValue);
      onStatusChange?.(newStatusValue);
    },
    [onStatusChange]
  );

  const validateForm = useCallback(
    (formData: AddContractBindingInput | EditContractBindingInput) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    []
  );

  const resetForm = useCallback(() => {
    setFormData(contractBinding ?? defaultContractBinding);
  }, [contractBinding]);

  useEffect(() => {
    const updatedFormData: AddContractBindingInput | EditContractBindingInput =
      {
        companyId: formData.companyId,
        contractBindingTypeId: formData.contractBindingTypeId,
        defaultCompany: !!formData.defaultCompany,
        isCompanyBilled: !!formData.isCompanyBilled,
        ...(formData.representativeId
          ? { representativeId: formData.representativeId }
          : {}),
        contractId,
        id: contractBinding?.id,
      };

    onChange?.(updatedFormData);
  }, [formData, onChange, contractId, contractBinding]);

  useEffect(() => {
    setFormData(contractBinding ?? defaultContractBinding);
    setLocalStatus(contractBinding?.status);
  }, [contractBinding]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [validateForm, formData, resetForm]
  );

  const selectedCompanyName =
    companies.find((company) => formData.companyId === company.id)
      ?.tradingName ?? "";

  return (
    <>
      {disabled && <Overlay withBackground />}
      <Grid container spacing={6}>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <InputLabel id="company-select-label" shrink>
              {t("common.labels.company")}
            </InputLabel>
            <Autocomplete
              disablePortal
              id="company-autcomplete"
              options={companiesIds}
              sx={{ maxHeight: 200 }}
              getOptionLabel={(optionValue) =>
                companies.find((company) => optionValue === company.id)
                  ?.tradingName ?? ""
              }
              value={formData.companyId || null}
              onChange={handleCompanyChange}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("common.labels.company")}
                  required
                  error={!!formDataErrors.companyId}
                  helperText={formDataErrors.companyId}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <InputLabel id="binding-type-select-label" shrink>
              {t("common.labels.bindingType")}
            </InputLabel>
            <Autocomplete
              disablePortal
              id="binding-type-autcomplete"
              options={bindingTypesIds}
              sx={{ maxHeight: 200 }}
              getOptionLabel={(optionValue) =>
                bindingTypes.find(
                  (bindingType) => optionValue === bindingType.id
                )?.description || ""
              }
              value={formData.contractBindingTypeId || null}
              onChange={handleBindingTypeChange}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("common.labels.bindingType")}
                  required
                  error={!!formDataErrors.contractBindingTypeId}
                  helperText={formDataErrors.contractBindingTypeId}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <InputLabel id="user-select-label" shrink>
              {t("common.labels.user")}
            </InputLabel>
            <Autocomplete
              disablePortal
              id="user-autcomplete"
              options={companyUsersIds}
              disabled={!!usersSelectDisabled}
              sx={{ maxHeight: 200 }}
              getOptionLabel={(optionValue) => {
                const user = companyUsers.find(
                  (companyUser) => optionValue === companyUser.id
                );

                return `${
                  user ? getUserNameOrEmail(user) : ""
                } [${selectedCompanyName}]`;
              }}
              value={formData.representativeId || null}
              onChange={handleUserChange}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t("common.labels.user")} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={3} xs={6}>
          <Box mt={2}>
            <FormControlLabel
              label={
                <Typography variant="caption">
                  {t("AdminConsole.Bindings.labels.defaultCompany")}
                </Typography>
              }
              labelPlacement="end"
              control={
                <Checkbox
                  inputProps={{
                    "aria-label": "Default company",
                  }}
                  name="defaultCompany"
                  onChange={handleCheckboxChange}
                  checked={formData.defaultCompany}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item md={3} xs={6}>
          <Box mt={2}>
            <FormControlLabel
              label={<Typography variant="caption">Bill to</Typography>}
              labelPlacement="end"
              control={
                <Checkbox
                  inputProps={{
                    "aria-label": "Bill to",
                  }}
                  name="isCompanyBilled"
                  onChange={handleCheckboxChange}
                  checked={formData.isCompanyBilled}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item md={3} xs={6}>
          {localStatus && (
            <StyledFormControlLabel
              label={
                <Typography
                  pb={0.75}
                  lineHeight={0.75}
                  width="100%"
                  fontSize="0.75rem"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  {t("common.labels.status")}
                </Typography>
              }
              labelPlacement="top"
              control={
                <StatusTag
                  status={localStatus}
                  options={contractBindingStatusOptions}
                  onChange={handleStatusChange}
                />
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
