import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import {
  ContractPriceTimeChangesExplorerModal,
  ContractPriceTimeChangesExplorerModalProps,
} from "containers/Projects/components/ContractPriceTimeChangesExplorerModal/ContractPriceTimeChangesExplorerModal";
import { Contract } from "generated/graphql";
import { CaretRight, Money } from "phosphor-react";
import { PriceTag } from "../HistoryModal/PriceTag";
import { TimeTag } from "../HistoryModal/TimeTag";

export type ContractPriceTimeChangeSectionProps = {
  reference: string;
  price: number;
  currency: string;
  time: number;
  loading?: boolean;
  contract: Contract;
  regardingItemNumber: string;
  isFIDIC17White?: boolean;
  isFIDIC99RedYellow?: boolean;
  productType: ContractPriceTimeChangesExplorerModalProps["productType"];
  explorerItems: ContractPriceTimeChangesExplorerModalProps["items"];
  explorerItemsLoading?: boolean;
};

export const ContractPriceTimeChangeSection: React.FC<
  ContractPriceTimeChangeSectionProps
> = ({
  reference,
  price,
  currency,
  time,
  loading,
  contract,
  regardingItemNumber,
  productType,
  isFIDIC17White,
  isFIDIC99RedYellow,
  explorerItems,
  explorerItemsLoading,
}) => {
  const theme = useTheme();

  const { modalVisibility, toggleModalVisibility } = useBasicModal();

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      {modalVisibility && (
        <ContractPriceTimeChangesExplorerModal
          contract={contract}
          open={modalVisibility}
          items={explorerItems}
          loading={explorerItemsLoading}
          productType={productType}
          regardingItemNumber={regardingItemNumber}
          onClose={toggleModalVisibility}
          isFIDIC17White={isFIDIC17White}
          isFIDIC99RedYellow={isFIDIC99RedYellow}
        />
      )}
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" alignItems="center">
            <Money size={24} color={theme.palette.grey[900]} />
            <Typography
              variant="p2"
              fontWeight={600}
              color="grey.900"
              ml={1}
              data-testid="reference-title"
            >
              {reference}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Box flex={1}>
              <PriceTag
                value={price}
                currency={currency}
                sx={{ width: "100%" }}
              />
            </Box>
            <Box ml={1} flex={1}>
              <TimeTag value={time} sx={{ width: "100%" }} />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignSelf="stretch"
        >
          <Button sx={{ minWidth: "32px" }}>
            <CaretRight
              color={theme.palette.grey[800]}
              size={18}
              onClick={toggleModalVisibility}
            />
          </Button>
        </Box>
      </Box>
    </>
  );
};
