import { Card } from "components/Card";
import { StyledProductItemsHeader } from "containers/Projects/components/ProductItemsView/ProductItemsView.styled";
import { TabWithCount } from "containers/Projects/components/TabsWithCount/TabsWithCount";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableContainer } from "../TableContainer";
import { NextStepItem, NextStepsTable } from "./NextStepsTable/NextStepsTable";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { Box, Typography } from "@mui/material";
import { useGetAllItems } from "./hooks/useGetAllItems";
import { useGetItemsForYourParty } from "./hooks/useGetItemsForYourParty";
import { useGetItemsForOtherParties } from "./hooks/useGetItemsForOtherParties";
import { useGetItemsScheduledForDeeming } from "./hooks/useGetItemsScheduledForDeeming";
import { isCompEvent } from "containers/Projects/components/CompEvents/CompEvents.utils";
import { isClaim } from "containers/Projects/components/Claims/Claims.utils";
import { isVariation } from "containers/Projects/Projects.utils";
import { useNavigate } from "react-router-dom";
import { NewAppPaths } from "helpers/paths/paths";
import { ProductType } from "generated/graphql";

enum Tab {
  All = "all",
  ForYourParty = "For your party",
  ForOtherParties = "For other parties",
  DeemingDate = "Deeming date",
}

export const NextSteps: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableRef = useRef<ProductItemsTablePublicAPI>(null);
  const [tableSelectionModel, setTableSelectionModel] =
    useState<GridRowSelectionModel>();
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.All);

  const [localItems, setLocalItems] = useState<NextStepItem[]>([]);

  const {
    itemsAll,
    hasMore: itemsAllHasMore,
    loadMore: loadMoreItemsAll,
    loading: itemsAllLoading,
  } = useGetAllItems();
  const {
    itemsForYourParty,
    hasMore: itemsForYourPartyHasMore,
    loadMore: loadMoreItemsForYourParty,
    loading: itemsForYourPartyLoading,
  } = useGetItemsForYourParty();
  const {
    itemsForOtherParties,
    hasMore: itemsForOtherPartiesHasMore,
    loadMore: loadMoreItemsForOtherParties,
    loading: itemsForOtherPartiesLoading,
  } = useGetItemsForOtherParties();
  const {
    itemsScheduledForDeeming,
    hasMore: itemsScheduledForDeemingHasMore,
    loadMore: loadMoreItemsScheduledForDeeming,
    loading: itemsScheduledForDeemingLoading,
  } = useGetItemsScheduledForDeeming();

  const tabs = useMemo(() => {
    const localTabs: TabWithCount[] = [
      {
        id: Tab.All,
        label: t("common.labels.all"),
        count: itemsAll.length,
      },
      {
        id: Tab.ForYourParty,
        label: t("Home.NextSteps.forYourParty"),
        count: itemsForYourParty.length,
      },
      {
        id: Tab.ForOtherParties,
        label: t("Home.NextSteps.forOtherParties"),
        count: itemsForOtherParties.length,
      },
      {
        id: Tab.DeemingDate,
        label: t("Home.NextSteps.deemingDate"),
        count: itemsScheduledForDeeming.length,
      },
    ];

    return localTabs;
  }, [
    itemsAll,
    itemsForYourParty,
    itemsForOtherParties,
    itemsScheduledForDeeming,
    t,
  ]);

  const handleClearSelection = () => {
    tableRef.current?.clearSelection();
  };

  const handleExportToExcel = () => {
    tableRef.current?.exportToExcel();
  };

  const handleSelectedTabChange = (newSelectedTabId: string) => {
    setSelectedTab(newSelectedTabId as Tab);
  };

  const hasMore = useMemo(() => {
    switch (selectedTab) {
      case Tab.All:
        return itemsAllHasMore;
      case Tab.ForYourParty:
        return itemsForYourPartyHasMore;
      case Tab.ForOtherParties:
        return itemsForOtherPartiesHasMore;
      case Tab.DeemingDate:
        return itemsScheduledForDeemingHasMore;
    }
  }, [
    itemsAllHasMore,
    itemsForYourPartyHasMore,
    itemsForOtherPartiesHasMore,
    itemsScheduledForDeemingHasMore,
    selectedTab,
  ]);

  const loadMore = useMemo(() => {
    switch (selectedTab) {
      case Tab.All:
        return loadMoreItemsAll;
      case Tab.ForYourParty:
        return loadMoreItemsForYourParty;
      case Tab.ForOtherParties:
        return loadMoreItemsForOtherParties;
      case Tab.DeemingDate:
        return loadMoreItemsScheduledForDeeming;
    }
  }, [
    loadMoreItemsAll,
    loadMoreItemsForYourParty,
    loadMoreItemsForOtherParties,
    loadMoreItemsScheduledForDeeming,
    selectedTab,
  ]);

  const handleLoadMore = () => {
    if (hasMore) {
      loadMore();
    }
  };

  const handleRowClick = (item: NextStepItem) => {
    let regardingProductInstanceId: string | undefined;
    let regardingType:
      | ProductType.Events
      | ProductType.Instructions
      | undefined;
    const regardingProductInstanceItemId = item.regardingId;

    if (isCompEvent(item) || isClaim(item)) {
      regardingProductInstanceId =
        item.regardingEvent?.productInstanceId ??
        item.regardingInstruction?.productInstanceId;
      regardingType = item.regardingEvent
        ? ProductType.Events
        : item.regardingInstruction
        ? ProductType.Instructions
        : undefined;
    } else if (isVariation(item)) {
      regardingProductInstanceId = item.regardingInstruction?.productInstanceId;
      regardingType = ProductType.Instructions;
    }

    if (
      !regardingProductInstanceId ||
      !regardingType ||
      !regardingProductInstanceItemId
    ) {
      console.warn("Navigation cannot be done because of missing data.");
      return;
    }

    navigate(
      NewAppPaths.authorized.Projects.children[
        regardingType === ProductType.Events
          ? "EventDetails"
          : "InstructionDetails"
      ].pathConstructor(
        regardingProductInstanceId,
        regardingProductInstanceItemId,
        regardingType
      )
    );
  };

  useEffect(() => {
    switch (selectedTab) {
      case Tab.All:
        setLocalItems(itemsAll);
        break;
      case Tab.ForYourParty:
        setLocalItems(itemsForYourParty);
        break;
      case Tab.ForOtherParties:
        setLocalItems(itemsForOtherParties);
        break;
      case Tab.DeemingDate:
        setLocalItems(itemsScheduledForDeeming);
        break;
      default:
        break;
    }
  }, [
    selectedTab,
    itemsAll,
    itemsForYourParty,
    itemsForOtherParties,
    itemsScheduledForDeeming,
  ]);

  const loading =
    itemsAllLoading ||
    itemsForYourPartyLoading ||
    itemsForOtherPartiesLoading ||
    itemsScheduledForDeemingLoading;

  return (
    <Card width="100%" height="100%">
      <Box mb={3}>
        <Typography variant="h3">{t("Home.NextSteps.nextSteps")}</Typography>
      </Box>
      <StyledProductItemsHeader
        tabs={tabs}
        selectedItemsCount={tableSelectionModel?.length ?? 0}
        onSelectedTabChange={handleSelectedTabChange}
        onExportToExcel={handleExportToExcel}
        onClearSelection={handleClearSelection}
      />
      <TableContainer
        mt={3}
        height={550}
        minHeight={160}
        data-testid="next-steps-container"
      >
        <NextStepsTable
          selectionModel={tableSelectionModel}
          items={localItems}
          loading={loading}
          apiRef={tableRef}
          onRowClick={handleRowClick}
          onLoadMore={handleLoadMore}
          onSelectionModelChange={setTableSelectionModel}
        />
      </TableContainer>
    </Card>
  );
};
