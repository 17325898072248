import {
  TextFieldProps as MuiTextFieldProps,
  TextField as MuiTextField,
} from "@mui/material";

export type TextFieldProps = { dataTestId?: string } & MuiTextFieldProps;

export const TextField: React.FC<TextFieldProps> = ({
  dataTestId,
  ...props
}) => {
  return (
    <MuiTextField
      data-testid={dataTestId}
      fullWidth
      type="text"
      variant="outlined"
      {...props}
    />
  );
};
