import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const sendClaimNoAgreementNoticeMutation = gql`
  mutation sendClaimNoAgreementNotice(
    $input: SendClaimNoAgreementNoticeInput!
  ) {
    sendClaimNoAgreementNotice(input: $input) {
      id
    }
  }
`;

export const recordClaimNoAgreementNoticeMutation = gql`
  mutation recordClaimNoAgreementNotice(
    $input: RecordClaimNoAgreementNoticeInput!
  ) {
    recordClaimNoAgreementNotice(input: $input) {
      id
    }
  }
`;

export const generateSendClaimNoAgreementNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimNoAgreementNoticePreview(
    $input: SendClaimNoAgreementNoticePreviewInput!
  ) {
    generateSendClaimNoAgreementNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimNoAgreementNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimNoAgreementNoticePreview(
    $input: RecordClaimNoAgreementNoticePreviewInput!
  ) {
    generateRecordClaimNoAgreementNoticePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
