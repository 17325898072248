import { ClaimHistoryItem, ProductType } from "generated/graphql";

import { ClaimHistoryItemDataView } from "./ClaimHistoryItemDataView";
import { BaseHistoryItemDetails } from "../../HistoryModal/BaseHistoryItemDetails";
import { useContext } from "react";
import { ClaimWidgetContext } from "../ClaimWidget/ClaimWidget.context";

type ClaimHistoryItemDetailsProps = {
  historyItem: ClaimHistoryItem;
};

export const ClaimHistoryItemDetails: React.FC<
  ClaimHistoryItemDetailsProps
> = ({ historyItem }) => {
  const { isFIDIC99RedYellow, contract } = useContext(ClaimWidgetContext);

  return (
    <BaseHistoryItemDetails
      historyItem={historyItem}
      productType={ProductType.Claims}
      isFIDIC99RedYellow={isFIDIC99RedYellow}
      contractTimezone={contract.timeZone}
    >
      <ClaimHistoryItemDataView historyItem={historyItem} />
    </BaseHistoryItemDetails>
  );
};
