import { ActionsDialogProps } from "components/ActionsDialog";
import { CalendarView } from "./CalendarView";

export type ImportRecordsModalProps = {
  children: React.ReactNode;
  calendarLoading?: boolean;
  targetDiaryDetails?: React.ReactNode;
} & ActionsDialogProps &
  Pick<
    React.ComponentProps<typeof CalendarView>,
    "onChange" | "onMonthChange" | "availableDates"
  >;

export enum WeekDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
