import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const LockIcon: React.FC<BaseIconProps> = ({
  height = "17",
  width = "17",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9943 5.50183H3.99229C3.71609 5.50183 3.49219 5.72573 3.49219 6.00193V13.0033C3.49219 13.2795 3.71609 13.5034 3.99229 13.5034H13.9943C14.2705 13.5034 14.4944 13.2795 14.4944 13.0033V6.00193C14.4944 5.72573 14.2705 5.50183 13.9943 5.50183Z"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74219 5.50126V3.25118C6.74219 2.65442 6.97925 2.0821 7.40122 1.66013C7.82319 1.23816 8.39551 1.0011 8.99227 1.0011C9.58903 1.0011 10.1613 1.23816 10.5833 1.66013C11.0053 2.0821 11.2423 2.65442 11.2423 3.25118V5.50126"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
