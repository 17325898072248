import { Stack, css, styled } from "@mui/material";
import { XCircleIcon } from "components/Icons/XCircleIcon";
import { customShadows } from "theme/extendedTheme";

export const ItemContainer = styled(Stack)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.grey[300]};
    box-shadow: ${customShadows.activeSmall};
    border-radius: 8px;
    height: 240px;
    width: 100%;
    cursor: pointer;
    position: relative;

    &:hover {
      svg {
        visibility: visible;
      }
    }
  `
);

export const DeleteIcon = styled(XCircleIcon)(
  ({ theme }) => css`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 11;
    cursor: pointer;
    visibility: hidden;
    transition: color 0.25s;

    &:active {
      color: ${theme.palette.grey[800]};
    }
  `
);
