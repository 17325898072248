import { write } from "helpers/localStorage";
import { noop } from "helpers/miscelaneous";
import React, { useCallback, useMemo, useState } from "react";
import { useIntercom } from "react-use-intercom";

export type AuthContextDataType = {
  AccessToken?: string;
  RefreshToken?: string;
  ExpiresIn?: number;
};

export type AuthLSDataType = {
  tokenData: AuthContextDataType;
  persist: boolean; // true if `keepMeLoggedIn checkbox is checked at login`
};

export type AuthContextType = AuthContextDataType & {
  setContextData: (data: AuthContextDataType) => void;
  isLoggedIn: boolean;
  logout: () => void;
};

const defaultContext: AuthContextType = {
  setContextData: noop,
  isLoggedIn: false,
  logout: noop,
};

export const AuthContext = React.createContext(defaultContext);

export const AuthContextProvider: React.FC<{
  externalAuthData?: AuthContextDataType;
  children: React.ReactNode;
}> = ({ externalAuthData, children }) => {
  const { shutdown } = useIntercom();

  const [authData, setAuthData] = useState<AuthContextDataType>(
    externalAuthData || {}
  );

  const logout = useCallback(() => {
    shutdown();
    write({});
    setAuthData({});
  }, [setAuthData, shutdown]);

  const updateAuthData = useCallback((data: AuthContextDataType) => {
    setAuthData(data);
  }, []);

  const authContextData = useMemo(() => {
    return {
      setContextData: updateAuthData,
      isLoggedIn: !!authData?.AccessToken,
      logout,
      ...authData,
    };
  }, [updateAuthData, authData, logout]);

  return (
    <AuthContext.Provider value={authContextData}>
      {children}
    </AuthContext.Provider>
  );
};
