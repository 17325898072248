import { Box, BoxProps, IconButton } from "@mui/material";
import { TrashIcon } from "components/Icons/TrashIcon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfilePicture } from "./ProfilePicture";
import { UploadButton } from "./UploadButton";

export type UserProfileButtonProps = {
  url?: string;
  uploadButtonCaption?: string;
  buttonHintCaption?: string;
  height?: string;
  width?: string;
  onPhotoUpload?: (photo: string | undefined) => void;
  onClick?: () => void;
} & BoxProps;

export const UserProfileButton: React.FC<UserProfileButtonProps> = ({
  url,
  uploadButtonCaption,
  buttonHintCaption,
  height,
  width,
  onPhotoUpload,
  onClick,
  ...restBoxProps
}) => {
  const { t } = useTranslation();

  const [photo, setPhoto] = useState<string>();

  const handlePhotoChange = (photo: string) => {
    setPhoto(photo);
    onPhotoUpload?.(photo);
  };

  const handleRemoveUploadedPhoto = () => {
    setPhoto(undefined);
    onPhotoUpload?.(undefined);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "default" }}
      {...restBoxProps}
    >
      <ProfilePicture photoUrl={photo || url} height={height} width={width} />
      {onPhotoUpload && (
        <Box ml={2} mr={1} display="flex" alignItems="center">
          <UploadButton
            caption={uploadButtonCaption || t("UserProfileButton.uploadImage")}
            hintCaption={buttonHintCaption}
            onPhotoUpload={handlePhotoChange}
          />
          {photo && (
            <IconButton onClick={handleRemoveUploadedPhoto}>
              <TrashIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};
