import { Box, IconButton } from "@mui/material";
import { OverflowTooltip } from "components/OverflowTooltip";
import { FileMIMEType } from "containers/Projects/components/Attachments/Attachments.decl";
import { stringToFileMIMEType } from "containers/Projects/components/Attachments/Attachments.utils";
import { Thumbnail } from "containers/Projects/components/Attachments/components/FileGridItem/Thumbnail";
import {
  FileIconSize,
  FileIconVariant,
  FileTypeIcon,
} from "containers/Projects/components/Attachments/components/FileTypeIcon";
import { Attachment } from "generated/graphql";
import { ArrowLeft, CaretLeft, CaretRight } from "phosphor-react";
import {
  Body,
  Container,
  FileName,
  Header,
  ImgContainer,
  NavigationIconButton,
} from "./AttachmentEnlargedView.styled";

type AttachmentEnlargedViewProps = {
  attachment: Attachment;
  mimeType: FileMIMEType;
  previewUrl?: string;
  hasMore: boolean;
  onBack: () => void;
  onPrevious: () => void;
  onNext: () => void;
  onClick: () => void;
};

export const AttachmentEnlargedView: React.FC<AttachmentEnlargedViewProps> = ({
  attachment,
  mimeType,
  previewUrl,
  hasMore,
  onBack,
  onPrevious,
  onNext,
  onClick,
}) => {
  return (
    <Container direction="column">
      <Header>
        <IconButton
          onClick={onBack}
          sx={{ height: "35px", width: "35px" }}
          data-testid={"back-to-grid-view-btn"}
        >
          <ArrowLeft />
        </IconButton>
        <Box>
          <FileTypeIcon
            type={stringToFileMIMEType(attachment.mimeType)}
            size={FileIconSize.Regular}
            variant={FileIconVariant.ColoredNoBackground}
          />
        </Box>
        <FileName variant="p2">{attachment.fileName}</FileName>
        <OverflowTooltip
          title={attachment.description}
          key={attachment.description}
          typographyProps={{
            variant: "p2",
            color: "grey.600",
            maxWidth: "100%",
          }}
        />
      </Header>
      <Body>
        {hasMore && (
          <NavigationIconButton onClick={onPrevious} size="small" left>
            <CaretLeft size={22} />
          </NavigationIconButton>
        )}
        <ImgContainer onClick={onClick}>
          <Box
            height={mimeType === FileMIMEType.Img ? "100%" : "auto"}
            ml="auto"
            mr="auto"
          >
            <Thumbnail
              mimeType={mimeType}
              imageUrl={previewUrl}
              imageObjectFit="contain"
              imgBorderRadius="straight"
              size={FileIconSize.Large}
            />
          </Box>
        </ImgContainer>
        {hasMore && (
          <NavigationIconButton onClick={onNext} size="small">
            <CaretRight size={22} />
          </NavigationIconButton>
        )}
      </Body>
    </Container>
  );
};
