import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventQuotationMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventQuotation($input: SendCompEventQuotationInput!) {
    sendCompEventQuotation(input: $input) {
      ...CompEventItemFields
    }
  }
`;
