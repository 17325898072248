import {
  CompaniesQuery,
  CompaniesQueryVariables,
  Company,
} from "generated/graphql";
import { companiesQuery } from "graphql/queries/companies.query";
import { useGraphQuery } from "./useGraphQuery";
import { useMemo } from "react";

export const useCompanies = (lite = false) => {
  const {
    data: companiesData,
    loading: companiesLoading,
    refetch,
  } = useGraphQuery<CompaniesQuery, CompaniesQueryVariables>(companiesQuery, {
    variables: {
      lite,
    },
  });

  const companiesSortedAlphabetically = useMemo(() => {
    const rawCompanies = (
      (companiesData?.companies.items as Company[]) ?? []
    ).slice();
    rawCompanies.sort((c1, c2) => c1.tradingName.localeCompare(c2.tradingName));

    return rawCompanies;
  }, [companiesData]);

  return {
    companies: companiesSortedAlphabetically,
    loading: companiesLoading,
    refetch,
  };
};
