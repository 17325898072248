import { useFileGridItem } from "./useFileGridItem";
import { Attachment } from "generated/graphql";
import { Thumbnail } from "./Thumbnail";
import { FileFooter } from "./FileFooter";
import { ItemContainer } from "./FileGridItemNewReadOnly.styled";
import { FileMIMEType } from "../../Attachments.decl";
import { useEffect } from "react";

type FileGridItemNewReadOnlyProps = {
  file: Attachment;
  onClick: () => void;
  onLoaded: (mimeType: FileMIMEType, previewUrl?: string) => void;
};

export const FileGridItemNewReadOnly: React.FC<
  FileGridItemNewReadOnlyProps
> = ({ file, onClick, onLoaded }) => {
  const {
    mimeTypeEnum,
    previewUrl,
    handleDescriptionChange,
    handleListItemClick,
  } = useFileGridItem({
    editable: false,
    file,
    onAddDescription: undefined,
    onDelete: undefined,
    onClick,
  });

  useEffect(() => {
    if (mimeTypeEnum === FileMIMEType.Img) {
      if (file.fileUrl && previewUrl) {
        onLoaded(mimeTypeEnum, previewUrl);
      }
    } else {
      onLoaded(mimeTypeEnum);
    }
  }, [mimeTypeEnum, file, previewUrl, onLoaded]);

  return (
    <ItemContainer onClick={handleListItemClick}>
      <Thumbnail mimeType={mimeTypeEnum} imageUrl={previewUrl} />
      <FileFooter
        file={file}
        editable={false}
        hideFileTypeIcon
        newStyle
        onDescriptionChange={handleDescriptionChange}
      />
    </ItemContainer>
  );
};
