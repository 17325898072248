import { IntercomEvents } from "constants/intercom";
import { getMetadataSet2 } from "containers/Projects/utils/intercom";
import { DailyDiaryItem } from "generated/graphql";
import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";

export const useDiaryTrackingEvents = () => {
  const { trackEvent } = useIntercom();

  const sendDiaryTrackingEvent = useCallback(
    (event: IntercomEvents, diary: DailyDiaryItem, extraData?: object) => {
      trackEvent(event, {
        ...getMetadataSet2({
          diaryNumber: diary.number!,
          diaryRevision: Number(diary.latestRevision.number) ?? 0,
          productInstanceName: diary.productInstance.description,
          projectFn: diary.productInstance.contract.project.friendlyName,
          productInstanceId: diary.productInstance.id,
          diaryId: diary.id,
          date: diary.date,
          revisionId: diary.latestRevision.id,
          contractFn: diary.productInstance.contract.friendlyName,
          version: process.env.REACT_APP_VERSION!,
        }),
        ...extraData,
      });
    },
    [trackEvent]
  );

  return { sendDiaryTrackingEvent };
};
