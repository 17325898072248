import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DeleteConfirmationModal } from "components/DeleteConfirmationModal";
import {
  AddDailyDiaryHseRecordInput,
  DailyDiaryPresetSection,
  DailyDiaryHseRecord,
  EditDailyDiaryHseRecordInput,
  FieldTypeEnum,
  AttachmentInput,
  DailyDiaryExtraConfig,
} from "generated/graphql";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { BaseHeaderSection } from "../BaseSectionHeader";
import { HSERecordModal } from "./HSERecordModal/HSERecordModal";
import { HSETable } from "./HSETable/HSETable";
import { useHSESection } from "./useHSESection";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { ImportHSERecordsModal } from "./ImportHSERecordsModal";
import { useDiaryMetadata } from "../../hooks/useDiaryMetadata";
import { HSELookups } from "./HSERecordModal/HSERecordForm";

export type HSESectionProps = {
  section: DailyDiaryPresetSection;
};

export const HSESection: React.FC<HSESectionProps> = ({ section }) => {
  const { t } = useTranslation();

  const [updatedAttachments, setUpdatedAttachments] =
    useState<AttachmentInput[]>();

  const { dailyDiaryId: dailyDiaryIdSearchParam } = useDiaryMetadata();

  const [recordToDeleteEdit, setRecordToDeleteEdit] =
    useState<DailyDiaryHseRecord>();
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const {
    modalVisibility: importModalVisibility,
    toggleModalVisibility: toggleImportModalVisibility,
  } = useBasicModal();

  const {
    dailyDiary,
    emptyDailyDiary,
    contractLookupCollections,
    activeContractLookupCollections,
    loading: contextLoading,
    crtRevisionId,
    productInstance,
    refetchDDByDate,
  } = useContext(DailyDiaryContext);
  const { modalVisibility, toggleModalVisibility } = useBasicModal();
  const {
    hseRecords,
    loading: hseRecordsLoading,
    addHseRecord,
    editHseRecord,
    removeHseRecord,
    refetchDDHseRecords,
  } = useHSESection(dailyDiary, crtRevisionId);

  const hseTypeLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).hseTypeLookupId
    );
  }, [productInstance]);

  const localLookups = useMemo(() => {
    const neededLookups = section.fields
      .filter((field) => field.fieldType === FieldTypeEnum.Lkp)
      .map((field) => {
        if (field.name === "HSEType" && hseTypeLookupIdOverride) {
          return { fieldName: field.name, lookupId: hseTypeLookupIdOverride };
        }
        return { fieldName: field.name, lookupId: field.lookupId! };
      });

    const hseLookups = {} as HSELookups;

    neededLookups.forEach((neededLookup) => {
      switch (neededLookup.fieldName) {
        case "HSEType":
          hseLookups.HSEType = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
      }
    });

    return hseLookups;
  }, [section, activeContractLookupCollections, hseTypeLookupIdOverride]);

  const toggleConfirmationModalVisibility = () => {
    setConfirmationModalVisibility((crtState) => !crtState);
  };

  const handleAddHseRecord = () => {
    toggleModalVisibility();
  };

  const triggerEditRecordFlow = (recordId: GridRowId) => {
    const record = hseRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    toggleModalVisibility();
  };

  const handleHseModalClose = () => {
    toggleModalVisibility();
    setRecordToDeleteEdit(undefined);
  };

  const triggerRemoveFlow = (recordId: GridRowId) => {
    const record = hseRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    setConfirmationModalVisibility(true);
  };

  const handleAttachmentsUpdated = (attachmentInputs: AttachmentInput[]) => {
    setUpdatedAttachments(attachmentInputs);
  };

  const handleModalPrimaryClick = async (
    hseRecord: AddDailyDiaryHseRecordInput | EditDailyDiaryHseRecordInput,
    closeModal: boolean
  ) => {
    if (recordToDeleteEdit) {
      await editHseRecord({
        variables: {
          input: {
            ...(hseRecord as EditDailyDiaryHseRecordInput),
            id: recordToDeleteEdit.id,
            attachments:
              updatedAttachments ??
              attachmentsToAttachmentInputs(
                recordToDeleteEdit.attachments ?? []
              ),
          },
        },
      });
    } else {
      await addHseRecord({
        variables: {
          input: {
            ...(hseRecord as AddDailyDiaryHseRecordInput),
            ...(dailyDiary?.id
              ? { dailyDiaryId: dailyDiary.id }
              : {
                  date: emptyDailyDiary?.date,
                  productInstanceId: emptyDailyDiary?.productInstanceId,
                }),
            attachments: updatedAttachments ?? [],
          },
        },
      });
    }
    setUpdatedAttachments(undefined);

    if (closeModal) {
      handleHseModalClose();
    }
  };

  const handleSuccessfulImport = () => {
    toggleImportModalVisibility();
    if (dailyDiaryIdSearchParam) {
      refetchDDHseRecords();
    } else {
      refetchDDByDate?.();
    }
  };

  const handleRemoveRecord = () => {
    toggleConfirmationModalVisibility();
    removeHseRecord({ variables: { id: recordToDeleteEdit?.id! } });
    setRecordToDeleteEdit(undefined);
  };

  useEffect(() => {
    if (recordToDeleteEdit && !hseRecords.includes(recordToDeleteEdit)) {
      const updatedRecord = hseRecords.find(
        (rec) => rec.id === recordToDeleteEdit.id
      );
      if (updatedRecord) {
        setRecordToDeleteEdit(updatedRecord);
      }
    }
  }, [hseRecords, recordToDeleteEdit]);

  useEffect(() => {
    if (!recordToDeleteEdit) {
      setUpdatedAttachments(undefined);
    }
  }, [recordToDeleteEdit]);

  return (
    <>
      <DeleteConfirmationModal
        title={t("Projects.DailyDiaries.RemoveConfirmationModal.title")}
        subtitle={t("Projects.DailyDiaries.RemoveConfirmationModal.subtitle")}
        open={confirmationModalVisibility}
        onPrimaryClick={handleRemoveRecord}
        onSecondaryClick={toggleConfirmationModalVisibility}
      />
      {importModalVisibility && (
        <ImportHSERecordsModal
          open={importModalVisibility}
          lookups={contractLookupCollections}
          loading={contextLoading}
          onClose={toggleImportModalVisibility}
          onImportSuccess={handleSuccessfulImport}
        />
      )}
      <HSERecordModal
        open={modalVisibility}
        onPrimaryClick={handleModalPrimaryClick}
        onSecondaryClick={handleHseModalClose}
        onClose={handleHseModalClose}
        onAttachmentsUpdated={handleAttachmentsUpdated}
        primaryBtnLoading={hseRecordsLoading}
        section={section}
        attachmentsPreview={
          updatedAttachments && recordToDeleteEdit
            ? recordToDeleteEdit.attachments?.filter((attach) =>
                updatedAttachments.find(
                  (crtAttach) => crtAttach.id === attach.id
                )
              )
            : undefined
        }
        hseLookups={localLookups}
        hseRecord={recordToDeleteEdit}
      />
      <Box width="100%">
        <BaseHeaderSection
          title={t("Projects.DailyDiaries.sections.HSE")}
          onAdd={handleAddHseRecord}
          onImport={toggleImportModalVisibility}
          mb={3}
        />
        <HSETable
          records={hseRecords}
          hseLookupCollections={contractLookupCollections}
          loading={hseRecordsLoading || contextLoading}
          onEdit={triggerEditRecordFlow}
          onRemove={triggerRemoveFlow}
        />
      </Box>
    </>
  );
};
