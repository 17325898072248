import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridEditInputCell,
} from "@mui/x-data-grid-pro";
import { DoubleSaveIcon } from "components/Icons/DoubleSaveIcon";
import { TrashIcon } from "components/Icons/TrashIcon";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { temporaryRowId } from "constants/constants";
import { SchemaSection, SchemaSectionStatus } from "generated/graphql";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Article, Trash } from "phosphor-react";
import { Tooltip } from "@mui/material";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export type ColumnsArgs = {
  statusOptions: StatusOption<SchemaSectionStatus>[];
  rowModesModel: GridRowModesModel;
  onStatusChange: (row: SchemaSection, newStatus: SchemaSectionStatus) => void;
  onSaveRow: (rowId: GridRowId) => void;
  onDeleteRow: (rowId: GridRowId) => void;
  onSeeFields: (rowId: GridRowId) => void;
  onSwapColumns: (rowId: GridRowId) => void;
};

export const useColumns = ({
  statusOptions,
  onStatusChange,
  rowModesModel,
  onSaveRow,
  onDeleteRow,
  onSeeFields,
  onSwapColumns,
}: ColumnsArgs): GridColDef<SchemaSection>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: t("common.labels.name"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          editable: true,
          sortable: false,
          renderEditCell: (
            params: GridRenderEditCellParams<any, SchemaSection>
          ) => {
            return <GridEditInputCell {...params} error={!params.row.name} />;
          },
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortable: false,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, SchemaSection, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disablePortal={false}
                disabled={params.row.id === temporaryRowId}
                onChange={(newStatus: StatusOption<SchemaSectionStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "displayText",
          headerName: t("AdminConsole.Products.labels.displayText"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          editable: true,
          renderEditCell: (
            params: GridRenderEditCellParams<any, SchemaSection>
          ) => {
            return (
              <GridEditInputCell {...params} error={!params.row.displayText} />
            );
          },
        },
        {
          field: "displayOrder",
          headerName: t("AdminConsole.Products.labels.displayOrder"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.1,
          type: "actions",
          sortable: false,
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            const actions = [];

            if (isInEditMode) {
              actions.push(
                <GridActionsCellItem
                  icon={<DoubleSaveIcon width="20px" height="20px" />}
                  label="Save"
                  onClick={() => onSaveRow(id)}
                />,
                <GridActionsCellItem
                  icon={<TrashIcon />}
                  label="Delete"
                  onClick={() => onDeleteRow(id)}
                />
              );
            } else {
              actions.push(
                <GridActionsCellItem
                  className="visible-on-hover"
                  icon={
                    <Tooltip
                      title={
                        t(
                          "AdminConsole.Products.labels.showSchemaFields"
                        ) as string
                      }
                    >
                      <Article size={18} color="black" />
                    </Tooltip>
                  }
                  label="See fields"
                  onClick={() => onSeeFields(id)}
                />,
                <GridActionsCellItem
                  className="visible-on-hover"
                  icon={
                    <Tooltip title={t("common.buttons.delete") as string}>
                      <Trash size={18} color="black" />
                    </Tooltip>
                  }
                  label="Delete"
                  onClick={() => onDeleteRow(id)}
                />,
                <GridActionsCellItem
                  className="visible-on-hover"
                  icon={
                    <Tooltip
                      title={
                        t(
                          "AdminConsole.Products.labels.moveToTheOtherColumn"
                        ) as string
                      }
                    >
                      <SwapVertIcon sx={{ color: "black" }} />
                    </Tooltip>
                  }
                  label="Swap column"
                  onClick={() => onSwapColumns(id)}
                />
              );
            }

            return actions;
          },
        },
      ] as GridColDef<SchemaSection>[],
    [
      t,
      dateCreatedColConfig,
      statusOptions,
      onStatusChange,
      rowModesModel,
      onSaveRow,
      onDeleteRow,
      onSeeFields,
      onSwapColumns,
    ]
  );
  return columns;
};
