import React from "react";
import { BaseIconProps } from "./decl";

export type ExcelIconProps = BaseIconProps & {
  onClick?: () => void;
};

export const ExcelIcon: React.FC<ExcelIconProps> = ({
  height = "20",
  width = "18",
  color = "#00BC3A",
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.38256 2.39752L11.8576 0.901683C11.9167 0.893203 11.9769 0.89753 12.0342 0.914371C12.0915 0.931212 12.1445 0.960174 12.1897 0.999294C12.2348 1.03841 12.271 1.08678 12.2958 1.14111C12.3206 1.19544 12.3334 1.25446 12.3334 1.31418V18.6859C12.3334 18.7455 12.3206 18.8044 12.2958 18.8587C12.2711 18.913 12.235 18.9613 12.1899 19.0004C12.1449 19.0395 12.092 19.0685 12.0348 19.0854C11.9776 19.1023 11.9174 19.1067 11.8584 19.0983L1.38173 17.6025C1.18308 17.5742 1.0013 17.4752 0.869801 17.3236C0.738298 17.1721 0.665895 16.9782 0.665894 16.7775V3.22252C0.665895 3.02186 0.738298 2.82794 0.869801 2.67639C1.0013 2.52483 1.18308 2.42581 1.38173 2.39752H1.38256ZM13.1667 2.50002H16.5001C16.7211 2.50002 16.933 2.58781 17.0893 2.74409C17.2456 2.90037 17.3334 3.11234 17.3334 3.33335V16.6667C17.3334 16.8877 17.2456 17.0997 17.0893 17.2559C16.933 17.4122 16.7211 17.5 16.5001 17.5H13.1667V2.50002ZM7.50006 10L9.83339 6.66668H7.83339L6.50006 8.57168L5.16673 6.66668H3.16673L5.50006 10L3.16673 13.3334H5.16673L6.50006 11.4283L7.83339 13.3334H9.83339L7.50006 10Z"
        fill={color}
      />
    </svg>
  );
};
