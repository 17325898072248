import { Box } from "@mui/material";
import { Comment } from "./Comment";
import { CommentsEditor } from "../CommentsEditor";
import { UserMentionOption } from "../Editor/plugins/MentionsPlugin/useMentionLookupService";
import {
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { LocalComment } from "../useComments";
import { CommentsContext } from "../Comments.context";
import { CloseReplyEditorConfirmModal } from "../CloseReplyEditorConfirmModal";

export type CommentEditorPublicAPI = {
  close: () => void;
  isDirty: () => boolean;
};

export type CommentWithReplyEditorProps = {
  comment: LocalComment;
  loading: boolean;
  typeaheadOptions: UserMentionOption[];
  readOnly?: boolean;
  onSend: (comment: string, commentToReplyToId: string) => void;
};

export const CommentWithReplyEditor: React.FC<CommentWithReplyEditorProps> = ({
  comment,
  loading,
  typeaheadOptions,
  readOnly,
  onSend,
}) => {
  const apiRef = useRef<CommentEditorPublicAPI>(null);
  const isEditorDirtyRef = useRef(false);
  const { currentOpenEditorRef, setCurrentOpenEditorRef } =
    useContext(CommentsContext);
  const [showReplyEditor, setShowReplyEditor] = useState(false);
  const [closeConfirmModalVisibility, setCloseConfirmModalVisibility] =
    useState(false);

  const handleSendComment = (content: string) => {
    onSend(content, comment.id);
  };

  const showCommentEditor = useCallback(() => {
    if (currentOpenEditorRef?.current) {
      // there is an existing CommentReplyEditor open
      if (currentOpenEditorRef.current.isDirty()) {
        setCloseConfirmModalVisibility(true);
      } else {
        // the other CommentReplyEditor is empty, we can close it
        currentOpenEditorRef.current.close();
        setShowReplyEditor(true);
        setCurrentOpenEditorRef(apiRef);
      }
    } else {
      // there's no other CommentReplyEditor open
      setShowReplyEditor(true);
      setCurrentOpenEditorRef(apiRef);
    }
  }, [currentOpenEditorRef, setCurrentOpenEditorRef]);

  const handleCloseEditor = useCallback(() => {
    setShowReplyEditor(false);
    setCurrentOpenEditorRef(null);
  }, [setCurrentOpenEditorRef]);

  const handleEditorDirtyChange = (isDirty: boolean) => {
    isEditorDirtyRef.current = isDirty;
  };

  const handleCancelCloseReplyConfirmModal = () => {
    setCloseConfirmModalVisibility(false);
  };

  const handleConfirmCloseReplyConfirmModal = () => {
    currentOpenEditorRef?.current?.close();
    setCloseConfirmModalVisibility(false);
    setShowReplyEditor(true);
    setCurrentOpenEditorRef(apiRef);
  };

  const isDirty = useCallback(
    () => isEditorDirtyRef.current,
    [isEditorDirtyRef]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      close: handleCloseEditor,
      isDirty,
    }),
    [handleCloseEditor, isDirty]
  );

  return (
    <>
      <CloseReplyEditorConfirmModal
        open={closeConfirmModalVisibility}
        onClose={handleCancelCloseReplyConfirmModal}
        onPrimaryClick={handleConfirmCloseReplyConfirmModal}
        onSecondaryClick={handleCancelCloseReplyConfirmModal}
      />
      <Box display="flex" flexDirection="column">
        <Comment
          comment={comment}
          readOnly={readOnly}
          onReply={showCommentEditor}
        />
        {showReplyEditor && !readOnly && (
          <Box ml={6} mt={3}>
            <CommentsEditor
              disabled={loading}
              typeaheadOptions={typeaheadOptions}
              onSend={handleSendComment}
              onEditorDirtyChange={handleEditorDirtyChange}
              onClose={handleCloseEditor}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
