import { useClaimProductInstance } from "containers/Projects/hooks/useClaimProductInstance";
import { Contract } from "generated/graphql";

export const useClaimWidget = (contract: Contract) => {
  const { claimProductInstance, loading: claimProdInstanceLoading } =
    useClaimProductInstance(contract.id);

  return {
    loading: claimProdInstanceLoading,
    claimProductInstance,
  };
};
