import { gql } from "@apollo/client";
import { draftVariationProposalFields } from "graphql/fragments/draftVariationProposal.fragment";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationProposalForProposedVariationPromptQuery = gql`
  ${draftVariationProposalFields}
  query variationProposalForProposedVariationPrompt(
    $input: VariationProposalForProposedVariationPromptInput!
  ) {
    variationProposalForProposedVariationPrompt(input: $input) {
      draftProposals {
        ...DraftVariationProposalFields
      }
    }
  }
`;

export const sendVariationProposalForProposedVariationMutation = gql`
  mutation sendVariationProposalForProposedVariation(
    $input: SendVariationProposalForProposedVariationInput!
  ) {
    sendVariationProposalForProposedVariation(input: $input) {
      id
    }
  }
`;

export const recordVariationProposalForProposedVariationMutation = gql`
  mutation recordVariationProposalForProposedVariation(
    $input: RecordVariationProposalForProposedVariationInput!
  ) {
    recordVariationProposalForProposedVariation(input: $input) {
      id
    }
  }
`;

export const generateSendVariationProposalForProposedVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationProposalForProposedVariationPreview(
    $input: SendVariationProposalForProposedVariationPreviewInput!
  ) {
    generateSendVariationProposalForProposedVariationPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationProposalForProposedVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationProposalForProposedVariationPreview(
    $input: RecordVariationProposalForProposedVariationPreviewInput!
  ) {
    generateRecordVariationProposalForProposedVariationPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
