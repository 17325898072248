import { gql } from "@apollo/client";

export const reviseDailyDiaryItemMutation = gql`
  mutation reviseDailyDiaryItem($id: ID!) {
    reviseDailyDiaryItem(id: $id) {
      id
      productInstanceId
      date
      number
      status
      latestRevision {
        id
        number
        sentById
        dateSent
        status
      }
      dateCreated
      dateModified
      creatorId
      sentById
      dateSent
    }
  }
`;
