import { gql } from "@apollo/client";
import { compEventHistoryItemFields } from "graphql/fragments/compEventHistoryItem.fragment";

export const compEventHistoryItemQuery = gql`
  ${compEventHistoryItemFields}

  query compEventHistoryItem($id: ID!) {
    compEventHistoryItem(id: $id) {
      ...CompEventHistoryItemFields
    }
  }
`;
