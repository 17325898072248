import { gql } from "@apollo/client";
import { schemaFieldFields } from "graphql/fragments/schemaField.fragment";

export const productSchemaQuery = gql`
  ${schemaFieldFields}

  query productSchema($id: ID!) {
    productSchema(id: $id) {
      id
      name
      isDefault
      productId
      isInternal
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
      schemaSections {
        items {
          id
          name
          displayText
          displayOrder
          productSchemaId
          isInternal
          status
          dateCreated
          columnPlacement
          creatorId
          creator {
            id
            firstname
            surname
          }
          schemaFields {
            items {
              ...SchemaFieldFields
            }
          }
        }
      }
    }
  }
`;

export const addSchemaSectionMutation = gql`
  ${schemaFieldFields}

  mutation addSchemaSection($input: AddSchemaSectionInput!) {
    addSchemaSection(input: $input) {
      id
      name
      displayText
      displayOrder
      productSchemaId
      status
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
      schemaFields {
        items {
          ...SchemaFieldFields
        }
      }
    }
  }
`;

export const addSchemaFieldMutation = gql`
  mutation addSchemaField($input: AddSchemaFieldInput!) {
    addSchemaField(input: $input) {
      id
      name
    }
  }
`;

export const changeSchemaSectionStatusMutation = gql`
  mutation changeSchemaSectionStatus($id: ID!, $status: SchemaSectionStatus!) {
    changeSchemaSectionStatus(id: $id, status: $status) {
      id
      name
      status
    }
  }
`;

export const changeSchemaFieldStatusMutation = gql`
  mutation changeSchemaFieldStatus($id: ID!, $status: SchemaFieldStatus!) {
    changeSchemaFieldStatus(id: $id, status: $status) {
      id
      name
      status
    }
  }
`;

export const editSchemaSectionMutation = gql`
  mutation editSchemaSection($input: EditSchemaSectionInput!) {
    editSchemaSection(input: $input) {
      id
      name
      status
    }
  }
`;

export const editSchemaFieldMutation = gql`
  mutation editSchemaField($input: EditSchemaFieldInput!) {
    editSchemaField(input: $input) {
      id
      name
      status
    }
  }
`;

export const fieldTypesQuery = gql`
  query fieldTypes {
    fieldTypes {
      items {
        id
        description
        shortCode
        status
      }
    }
  }
`;
