import {
  Avatar,
  AvatarGroup,
  ClickAwayListener,
  Fade,
  Popper,
  css,
  styled,
  useTheme,
} from "@mui/material";
import {
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import React, { useCallback, useState } from "react";
import { ApproversAvatarsFlyout } from "./ApproversAvatarsFlyout";
import { computeAvatarInitials } from "./AuthorizationProgress.utils";

export type ApproversAvatarsProps = {
  level: AuthorizationWorkflowLevel;
  authAudit?: AuthorizationWorkflowAudit;
};

const StyledAvatarGroup = styled(AvatarGroup, {
  shouldForwardProp: (prop) => prop !== "grouped",
})<{ grouped?: boolean }>(
  ({ theme, grouped }) => css`
    cursor: pointer;

    ${grouped &&
    css`
      .MuiAvatar-root:first-of-type {
        color: ${theme.palette.info.main};
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        background: ${theme.palette.info.light};
      }
    `}

    && {
      .MuiAvatar-root {
        width: 36px;
        height: 36px;
        border: 1px solid ${theme.palette.grey[200]};
      }
    }
  `
);

const popperModifiers = [
  {
    name: "flip",
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: "document",
      padding: 8,
    },
  },
  {
    name: "preventOverflow",
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: true,
      tether: true,
      rootBoundary: "document",
      padding: 8,
    },
  },

  {
    name: "offset",
    options: {
      offset: [0, 10],
    },
  },
];

export const ApproversAvatars: React.FC<ApproversAvatarsProps> = ({
  level,
  authAudit,
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "transition-popper" : undefined;

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  return (
    <>
      <StyledAvatarGroup
        total={level.users.items.length}
        max={2}
        spacing={20}
        sx={{
          mb: theme.spacing(1),
          display: "flex",
          justifyContent: "flex-end",
        }}
        grouped={level.users.items.length > 2}
        onClick={handleClick}
      >
        {level.users.items.map((user) => (
          <Avatar
            key={user.id}
            src={user.profilePicture ?? undefined}
            alt={getUserName(user)}
            sx={{
              background: user.profilePicture ? "white" : "orange",
            }}
          >
            {computeAvatarInitials(user)}
          </Avatar>
        ))}
      </StyledAvatarGroup>
      {open && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement="right-start"
          disablePortal={false}
          sx={{ zIndex: 9999 }}
          modifiers={popperModifiers}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <ApproversAvatarsFlyout level={level} authAudit={authAudit} />
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </>
  );
};
