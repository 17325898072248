import {
  AuthorizationWorkflow,
  AuthorizationWorkflowQuery,
  AuthorizationWorkflowQueryVariables,
  ChangeAuthorizationWorkflowStatusMutation,
  ChangeAuthorizationWorkflowStatusMutationVariables,
  EditAuthorizationWorkflowMutation,
  EditAuthorizationWorkflowMutationVariables,
} from "generated/graphql";
import { changeAuthorizationWorkflowStatusMutation } from "graphql/mutations/changeAuthorizationWorkflowStatus";
import { editAuthorizationWorkflowMutation } from "graphql/mutations/editAuthorizationWorkflow";
import { authorizationWorkflowQuery } from "graphql/queries/authorizationWorkflow.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useTranslation } from "react-i18next";

export const useAuthorizationWorkflowDetails = (authWorkflowId: string) => {
  const { t } = useTranslation();

  const {
    data: authorizationWorkflow,
    refetch: refetchAuthorizationWorkflow,
    loading: authorizationWorkflowLoading,
  } = useGraphQuery<
    AuthorizationWorkflowQuery,
    AuthorizationWorkflowQueryVariables
  >(authorizationWorkflowQuery, {
    variables: { id: authWorkflowId },
  });

  const [
    editAuthorizationWorkflow,
    { loading: editAuthorizationWorkflowLoading },
  ] = useGraphMutation<
    EditAuthorizationWorkflowMutation,
    EditAuthorizationWorkflowMutationVariables
  >(
    editAuthorizationWorkflowMutation,
    {
      update: (cache, data) => {
        if (data.data?.editAuthorizationWorkflow.id === authWorkflowId) {
          refetchAuthorizationWorkflow();
        }

        cache.evict({ id: "ROOT_QUERY", fieldName: "authorizationWorkflows" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "workflowActionsPrompt" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.AuthorizationWorkflows.authorizationWorkflow"),
    })
  );

  const [
    changeAuthorizationWorkflowStatus,
    { loading: changeAuthorizationWorkflowStatusLoading },
  ] = useGraphMutation<
    ChangeAuthorizationWorkflowStatusMutation,
    ChangeAuthorizationWorkflowStatusMutationVariables
  >(
    changeAuthorizationWorkflowStatusMutation,
    {
      update: (cache) => {
        refetchAuthorizationWorkflow();

        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "authorizationWorkflows",
        });
        cache.evict({ id: "ROOT_QUERY", fieldName: "workflowActionsPrompt" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.AuthorizationWorkflows.authorizationWorkflow"),
    })
  );

  return {
    authorizationWorkflow: authorizationWorkflow?.authorizationWorkflow as
      | AuthorizationWorkflow
      | undefined,
    loading:
      authorizationWorkflowLoading ||
      changeAuthorizationWorkflowStatusLoading ||
      editAuthorizationWorkflowLoading,
    editAuthorizationWorkflowLoading,
    editAuthorizationWorkflow,
    changeAuthorizationWorkflowStatus,
  };
};
