import { Box, css, ListItem, styled } from "@mui/material";

export const PopperContentContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    box-shadow: 0px 4px 6px rgba(37, 44, 50, 0.08),
      0px 4px 8px rgba(37, 44, 50, 0.1);
    border-radius: 5px;
    border: 1px solid ${theme.palette.grey[100]};
  `
);

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "focused",
})<{
  size?: "small" | "large";
  selected?: boolean;
  focused?: boolean;
}>(
  ({ theme, size = "large", selected, focused }) => css`
    ${size === "small"
      ? css`
          padding: ${theme.spacing(1)} ${theme.spacing(2)};
        `
      : css`
          padding: ${theme.spacing(1.5)} ${theme.spacing(2.5)};
        `}
    cursor: pointer;
    &:hover {
      background: ${theme.palette.action.hover};
    }
    ${focused
      ? css`
          background: ${theme.palette.action.hover};
        `
      : ""}
    ${selected && `background: ${theme.palette.grey[100]};`}
    min-width: 160px;
  `
);
