import { gql } from "@apollo/client";

export const createUploadAttachmentPreSignedUrlMutation = gql`
  mutation createUploadAttachmentPreSignedUrl(
    $input: CreateUploadAttachmentPreSignedUrlInput!
  ) {
    createUploadAttachmentPreSignedUrl(input: $input) {
      fileName
      fileUrl
    }
  }
`;
