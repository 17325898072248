import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const dailyDiaryResourceRecordLiteFields = gql`
  ${creatorFields}

  fragment DailyDiaryResourceRecordLiteFields on DailyDiaryResourceRecord {
    id
    resourceOptionId
    resourceLookupId
    quantity
    hours
    totalHours
    remarks
    companyOptionId
    companyLookupId
    shiftOptionId
    shiftLookupId
    teamOptionId
    teamLookupId
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
