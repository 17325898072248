import {
  ResendInviteMutation,
  ResendInviteMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { resendInviteMutation } from "../containers/CompanyDetails/CompanyDetails.query";
import { useTranslation } from "react-i18next";

export const useResendInvitation = (onDone: () => void) => {
  const { t } = useTranslation();

  const [resendInvitation, { loading: resendInvitationLoading }] =
    useGraphMutation<ResendInviteMutation, ResendInviteMutationVariables>(
      resendInviteMutation,
      {
        update: () => onDone(),
      },
      t("AdminConsole.Companies.successMessages.invitationSent")
    );

  return {
    resendInvitation,
    resendInvitationLoading,
  };
};
