import {
  AuthContextDataType,
  AuthContextProvider,
} from "providers/auth.context";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { App } from "./App";
import { IntercomProvider } from "react-use-intercom";
import { useConfig } from "providers/config.context";

export type AppWrapperProps = {
  authData?: AuthContextDataType;
};

export const AppWrapper: React.FC<AppWrapperProps> = ({
  authData: externalAuthData,
}) => {
  const { config } = useConfig();

  return (
    <IntercomProvider
      appId={config.REACT_APP_INTERCOM_APP_ID}
      apiBase={process.env.REACT_APP_INTERCOM_BASE_API}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthContextProvider externalAuthData={externalAuthData}>
          <App />
        </AuthContextProvider>
      </LocalizationProvider>
    </IntercomProvider>
  );
};
