import { Box, BoxProps } from "@mui/material";

export type FullSizeCenteredContainerProps = BoxProps;

export const FullSizeCenteredContainer: React.FC<BoxProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      {...rest}
    >
      {children}
    </Box>
  );
};
