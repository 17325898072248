import { gql } from "@apollo/client";
import { contractTypeLiteFields } from "graphql/fragments/contractTypeLite.fragment";

export const contractTypesQuery = gql`
  ${contractTypeLiteFields}
  query contractTypes {
    contractTypes {
      items {
        ...ContractTypeLiteFields
      }
    }
  }
`;

export const contractTypesWithBindingTypesQuery = gql`
  ${contractTypeLiteFields}

  query contractTypesWithBindingTypes {
    contractTypes {
      items {
        ...ContractTypeLiteFields
        contractBindingTypes {
          items {
            id
            description
            contractTypeId
            status
          }
        }
      }
    }
  }
`;
