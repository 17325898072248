import {
  ClaimHistoryItem,
  ClaimHistoryItemsQuery,
  ClaimHistoryItemsQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";
import { claimHistoryItemsQuery } from "../ClaimWidget.query";

export const useClaimHistory = (claimId: string) => {
  const { data: claimHistoryItems, loading: claimHistoryItemsLoading } =
    useGraphQuery<ClaimHistoryItemsQuery, ClaimHistoryItemsQueryVariables>(
      claimHistoryItemsQuery,
      {
        variables: { claimId },
      }
    );

  const orderedHistoryItems = useMemo(
    () =>
      (claimHistoryItems?.claimHistoryItems.items ?? [])
        .slice()
        .sort(
          (item1, item2) =>
            new Date(item2.dateSent).getTime() -
            new Date(item1.dateSent).getTime()
        ) as ClaimHistoryItem[],
    [claimHistoryItems]
  );

  return {
    claimHistoryItems: orderedHistoryItems,
    loading: claimHistoryItemsLoading,
  };
};
