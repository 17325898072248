import { Box, Typography, useTheme } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import { MetadataHeading } from "components/SchemaInterpretor/MetadataHeading";
import { MetadataText } from "components/SchemaInterpretor/MetadataText";
import { ProductType } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";

type ProductItemPreviewHeaderProps = {
  itemNumber: string;
  productType: ProductType;
  notifiedBy?: string;
  dateCreated: string;
  contractTimezone: string;
};

export const ProductItemPreviewHeader: React.FC<
  ProductItemPreviewHeaderProps
> = ({
  itemNumber,
  productType,
  notifiedBy,
  dateCreated,
  contractTimezone,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" alignItems="center" width="100%">
        <ItemTag type={productType} />
        <Typography
          ml={1.5}
          variant="h3"
          color={theme.palette.grey[900]}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {itemNumber}
        </Typography>
      </Box>
      <Box display="flex" mt={3} width="100%">
        {notifiedBy && (
          <Box display="flex" flexDirection="column" flex={1}>
            <MetadataHeading>{t("common.labels.notifiedBy")}</MetadataHeading>
            <MetadataText>{notifiedBy}</MetadataText>
          </Box>
        )}
        <Box display="flex" flexDirection="column" flex={1}>
          <MetadataHeading>{t("common.labels.notified")}</MetadataHeading>
          <DateWithTimeTooltip
            datetime={dateCreated}
            variant="p1"
            color={theme.palette.grey[600]}
            timezone={contractTimezone}
            mt={0.5}
          />
        </Box>
      </Box>
    </>
  );
};
