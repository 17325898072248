import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";

export const authorizationWorkflowQuery = gql`
  ${authorizationWorkflowFields}

  query authorizationWorkflow($id: ID!) {
    authorizationWorkflow(id: $id) {
      ...AuthorizationWorkflowFields
    }
  }
`;
