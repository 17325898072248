import { Box, css, styled, Typography } from "@mui/material";
import { mainNavigationHeight } from "components/MainNavigation/MainNavigation.styled";

export const NavigationContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    height: 70px;
    padding-left: ${theme.spacing(4)};
    padding-right: ${theme.spacing(4)};
    position: relative;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06); // TODO ADI B. check with system design for box-shadows
    position: sticky;
    top: ${mainNavigationHeight}px;
    background: ${theme.palette.common.white};
    z-index: 9;
    & > * {
      margin-right: ${theme.spacing(6)};
    }
  `
);

export const StyledAdminLinkText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "dataText" && prop !== "selected",
})<{
  selected?: boolean;
  dataText?: string;
}>(({ theme, selected = false, dataText }) => {
  return css`
    && {
      color: ${theme.palette.common.black};
      font-weight: ${selected ? 700 : 500};
      font-size: 0.875rem;
      line-height: 1.43;

      &::after {
        content: "${dataText}";
        display: block;
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  `;
});
