import { useTranslation } from "react-i18next";
import { GridHeader } from "components/GridHeader";

export type ContractTypesBindingTypesHeaderProps = {
  visibleRowsCount: number;
  selectedCount?: number;
  onExportToExcel: () => void;
};

export const ContractTypesBindingTypesHeader: React.FC<
  ContractTypesBindingTypesHeaderProps
> = ({ visibleRowsCount, selectedCount, onExportToExcel }) => {
  const { t } = useTranslation();

  return (
    <GridHeader
      title={t("common.labels.bindingTypes")}
      visibleRowsCount={visibleRowsCount || 0}
      selectedCount={selectedCount}
      onExportToExcel={onExportToExcel}
    />
  );
};
