import { countriesData } from "./countries.constants";
import { CountryData } from "./countries.decl";

export const getCountryByName = (
  countryName?: string
): CountryData | undefined => {
  if (!countryName) {
    return undefined;
  }

  return Object.values(countriesData).find(
    (countryData) => countryData.name === countryName
  );
};

export const getCountryNameByCode = (countryCode?: string) => {
  if (countryCode) {
    return (countriesData[countryCode] as CountryData).name;
  }
};
