import { Company } from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useCompanyDetailsViewObject = (companyDetails?: Company) => {
  const { t } = useTranslation();

  const companyEntityObj = useMemo(() => {
    if (!companyDetails) {
      return [];
    }

    return [
      {
        label: t("AdminConsole.Companies.labels.companyDetails"),
        items: [
          {
            id: "registeredName",
            label: t("AdminConsole.Companies.labels.registeredName"),
            value: companyDetails.registeredName,
          },
          {
            id: "tradingName",
            label: t("AdminConsole.Companies.labels.tradingName"),
            value: companyDetails.tradingName,
          },
          {
            id: "type",
            label: t("AdminConsole.Companies.labels.companyType"),
            value: companyDetails.type,
          },
          {
            id: "registrationNumber",
            label: t("AdminConsole.Companies.labels.registrationNumber"),
            value: companyDetails.registrationNumber,
          },
          {
            id: "vatRegistrationNumber",
            label: t("AdminConsole.Companies.labels.vatRegistrationNumber"),
            value: companyDetails.vatRegistrationNumber,
          },
          {
            id: "logo",
            label: t("AdminConsole.Companies.labels.companyLogo"),
            value: (
              <img
                style={{ maxHeight: "48px" }}
                alt="company logo"
                src={companyDetails.logo || ""}
              />
            ),
          },
        ],
      },
      {
        label: t("AdminConsole.Companies.labels.physicalAddress"),
        items: [
          {
            id: "physicalAddress.line1",
            label: t("AdminConsole.Companies.labels.addressLine1"),
            value: companyDetails.physicalAddress?.line1,
          },
          {
            id: "physicalAddress.line2",
            label: t("AdminConsole.Companies.labels.addressLine2"),
            value: companyDetails.physicalAddress?.line2,
          },
          {
            id: "physicalAddress.city",
            label: t("AdminConsole.Companies.labels.city"),
            value: companyDetails.physicalAddress?.city,
          },
          {
            id: "physicalAddress.provinceState",
            label: t("AdminConsole.Companies.labels.provinceOrState"),
            value: companyDetails.physicalAddress?.provinceState,
          },
          {
            id: "physicalAddress.country",
            label: t("AdminConsole.Companies.labels.country"),
            value: companyDetails.physicalAddress?.country,
          },
          {
            id: "physicalAddress.code",
            label: t("AdminConsole.Companies.labels.code"),
            value: companyDetails.physicalAddress?.code,
          },
        ],
      },
      {
        label: t("AdminConsole.Companies.labels.postalAddress"),
        items: [
          {
            id: "postalAddress.line1",
            label: t("AdminConsole.Companies.labels.addressLine1"),
            value: companyDetails.postalAddress?.line1,
          },
          {
            id: "postalAddress.line2",
            label: t("AdminConsole.Companies.labels.addressLine2"),
            value: companyDetails.postalAddress?.line2,
          },
          {
            id: "postalAddress.city",
            label: t("AdminConsole.Companies.labels.city"),
            value: companyDetails.postalAddress?.city,
          },
          {
            id: "postalAddress.provinceState",
            label: t("AdminConsole.Companies.labels.provinceOrState"),
            value: companyDetails.postalAddress?.provinceState,
          },
          {
            id: "postalAddress.country",
            label: t("AdminConsole.Companies.labels.country"),
            value: companyDetails.postalAddress?.country,
          },
          {
            id: "postalAddress.code",
            label: t("AdminConsole.Companies.labels.code"),
            value: companyDetails.postalAddress?.code,
          },
        ],
      },
    ];
  }, [companyDetails, t]);

  return companyEntityObj;
};
