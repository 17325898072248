import { Box, Typography } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  CompEventQuotation,
  CompEventQuotationAcceptancePrompt,
  DraftQuotation,
} from "generated/graphql";
import {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { QuotationsTable } from "../SubmitQuotationCEAction/QuotationsTable/QuotationsTable";
import { RecordCEQuotationAcceptance } from "./RecordCEQuotationAcceptance";
import { SendCEQuotationAcceptance } from "./SendCEQuotationAcceptance";
import { useAcceptQuotationCEAction } from "./useAcceptQuotationCEAction";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";
import { useCEQuotationAssessmentModal } from "../../../CEQuotationAssessmentModal/useCEQuotationAssessmentModal";
import { CEQuotationAssessmentReadOnlyModal } from "../../../CEQuotationAssessmentReadOnlyModal/CEQuotationAssessmentReadOnlyModal";

const defaultFormData: CompEventQuotationAcceptancePrompt = {
  quotations: [],
};

export const AcceptQuotationCEAction: React.FC<
  CEActionFormProps & { triggersAuthWorkflow?: boolean }
> = ({ apiRef, triggersAuthWorkflow, onClose }) => {
  const { t } = useTranslation();
  const { compEvent, contract } = useContext(CompEventWidgetContext);

  const [formData, setFormData] =
    useState<CompEventQuotationAcceptancePrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [selectedQuotation, setSelectedQuotation] =
    useState<CompEventQuotation>();

  const {
    modalVisibility: quotationModalVisibility,
    toggleModalVisibility: toggleQuotationModalVisibility,
    loading: quotationsLoading, // TODO: this is true only on Add/edit/remove which is not used here. See if usage can be removed
  } = useCEQuotationAssessmentModal();

  const quotationIds = useMemo(
    () => formData.quotations.map((quotation) => quotation.id),
    [formData]
  );

  const {
    sendCEQuotationAcceptance,
    recordCEQuotationAcceptance,
    loading: acceptQuotationLoading,
    actionLoading,
    quotations,
  } = useAcceptQuotationCEAction(false, triggersAuthWorkflow);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCEQuotation = async () => {
    await sendCEQuotationAcceptance({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          acceptedQuotationId: quotationIds[0],
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEQuotation = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCEQuotationAcceptance({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          acceptedQuotationId: quotationIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const handleQuotationRowClick = (
    quotation: DraftQuotation | CompEventQuotation
  ) => {
    setSelectedQuotation(quotation as CompEventQuotation);
    toggleQuotationModalVisibility();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => true,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    []
  );

  useEffect(() => {
    if (quotations) {
      setFormData({
        quotations,
      });
    }
  }, [quotations]);

  return acceptQuotationLoading || (!selectedQuotation && quotationsLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {quotationModalVisibility && selectedQuotation && (
        <CEQuotationAssessmentReadOnlyModal
          open={quotationModalVisibility}
          onClose={toggleQuotationModalVisibility}
          quotationAssessment={selectedQuotation}
          contract={contract}
        />
      )}
      {sendModalVisibility && (
        <SendCEQuotationAcceptance
          open={sendModalVisibility}
          quotations={formData.quotations}
          acceptedQuotationId={quotationIds[0]}
          onPrimaryClick={handleSendCEQuotation}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {recordModalVisibility && (
        <RecordCEQuotationAcceptance
          open={recordModalVisibility}
          quotations={formData.quotations}
          acceptedQuotationId={quotationIds[0]}
          onPrimaryClick={handleRecordCEQuotation}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            variant="h3"
            fontSize="18px"
            fontWeight={600}
            color="grey.800"
          >
            {t("Projects.CompEvents.submittedQuotations")}
          </Typography>
          {/* <Typography
            variant="p1"
            fontWeight={400}
            color="grey.800"
            mt={1.5}
          > TODO
            Select the checkbox of the quotation you want to accept.
          </Typography> */}
        </Box>
        <QuotationsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={quotationsLoading}
          quotations={formData.quotations}
          selectable
          acceptedQuotationId={
            formData.quotations && formData.quotations[0]
              ? formData.quotations[0].id
              : undefined
          }
          onRowClick={handleQuotationRowClick}
        />
      </Box>
    </>
  );
};
