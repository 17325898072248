import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateSendCompEventQuotationAcceptancePreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateSendCompEventQuotationAcceptancePreview(
    $input: SendCompEventQuotationAcceptancePreviewInput!
  ) {
    generateSendCompEventQuotationAcceptancePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
