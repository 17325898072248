import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationObjectionVariationPreviewMutation,
  GenerateRecordVariationObjectionVariationPreviewMutationVariables,
  RecordVariationObjectionVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateRecordVariationObjectionVariationPreviewMutation } from "./ObjectToInstructionOfVariationAction.query";

export const useRecordObjectionToInstructionOfVariationModal = (
  reasons: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [variationPreview, setVariationPreview] =
    useState<RecordVariationObjectionVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationObjectionVariationPreviewMutation,
    GenerateRecordVariationObjectionVariationPreviewMutationVariables
  >(generateRecordVariationObjectionVariationPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            reasons,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateRecordVariationObjectionVariationPreview
        );
      }
    }, [generatePreview, variation, reasons]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview,
    contract,
  };
};
