import { gql } from "@apollo/client";

export const contractTypeLiteFields = gql`
  fragment ContractTypeLiteFields on ContractType {
    id
    description
    subType
    version
    status
  }
`;
