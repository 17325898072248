import { styled } from "@mui/material";
import { CountriesSelectNew } from "./CountriesSelectNew";

export const CountriesSelectAdornment = styled(CountriesSelectNew)`
  && {
    .countries-select-value-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
