import { Box, BoxProps, css, styled } from "@mui/material";
import { forwardRef } from "react";
import { customShadows } from "theme/extendedTheme";

export type CardProps = {
  children: React.ReactNode;
} & BoxProps;

const CardContainer = styled(Box)(
  ({ theme }) => css`
    background: white;
    padding: ${theme.spacing(3)};
    border-radius: 16px;
    box-shadow: ${customShadows.cards};
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
  `
);

export const Card = forwardRef(
  ({ children, ...restBoxProps }: CardProps, ref) => {
    return (
      <CardContainer {...restBoxProps} ref={ref}>
        {children}
      </CardContainer>
    );
  }
);
