import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { NoStyleLink } from "components/StyledLink";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { NewAppPaths } from "../../../helpers/paths/paths";
import {
  NavigationContainer,
  StyledAdminLinkText,
} from "./AdminNavigation.styled";
import {
  adminConsoleLogsMenuItems,
  adminConsoleSystemMenuItems,
} from "./AdminNavigation.constants";
import { useTranslation } from "react-i18next";
import { ButtonWithOptions } from "components/ButtonWithOptions/ButtonWithOptions";

export const AdminNavigation: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isCompaniesSelected = useMemo(
    () =>
      matchPath(
        `${NewAppPaths.authorized.AdminConsole.children.Companies.path}/*`,
        location.pathname
      ),
    [location.pathname]
  );

  const isProjectsSelected = useMemo(
    () =>
      matchPath(
        `${NewAppPaths.authorized.AdminConsole.children.Projects.path}/*`,
        location.pathname
      ),
    [location.pathname]
  );
  const isSupportSelected = useMemo(
    () =>
      matchPath(
        NewAppPaths.authorized.AdminConsole.children.Support,
        location.pathname
      ),
    [location.pathname]
  );
  const isSystemSelected = useMemo(
    () =>
      matchPath(
        `${NewAppPaths.authorized.AdminConsole.children.System.path}/*`,
        location.pathname
      ),
    [location.pathname]
  );
  const isLogsSelected = useMemo(
    () =>
      matchPath(
        `${NewAppPaths.authorized.AdminConsole.children.Logs.path}/*`,
        location.pathname
      ),
    [location.pathname]
  );

  const handleMenuItemSelect = (itemId: string) => {
    navigate(itemId);
  };

  return (
    <NavigationContainer>
      <NoStyleLink
        to={NewAppPaths.authorized.AdminConsole.children.Companies.path}
      >
        <StyledAdminLinkText
          selected={!!isCompaniesSelected}
          dataText={t("AdminConsole.Navigation.labels.companiesAndUsers")}
        >
          {t("AdminConsole.Navigation.labels.companiesAndUsers")}
        </StyledAdminLinkText>
      </NoStyleLink>
      <NoStyleLink
        to={NewAppPaths.authorized.AdminConsole.children.Projects.path}
      >
        <StyledAdminLinkText
          selected={!!isProjectsSelected}
          dataText={t("AdminConsole.Navigation.labels.projects")}
        >
          {t("AdminConsole.Navigation.labels.projects")}
        </StyledAdminLinkText>
      </NoStyleLink>
      <Box mr={1}>
        <ButtonWithOptions
          options={adminConsoleSystemMenuItems}
          width="134px"
          triggerLabel={t("AdminConsole.Navigation.labels.system")}
          boldedText={!!isSystemSelected}
          onItemSelect={handleMenuItemSelect}
        />
      </Box>
      <Box mr={1}>
        <ButtonWithOptions
          options={adminConsoleLogsMenuItems}
          width="120px"
          triggerLabel={t("AdminConsole.Navigation.labels.logs")}
          boldedText={!!isLogsSelected}
          onItemSelect={handleMenuItemSelect}
        />
      </Box>
      <NoStyleLink to={NewAppPaths.authorized.AdminConsole.children.Support}>
        <StyledAdminLinkText
          selected={!!isSupportSelected}
          dataText={t("AdminConsole.Navigation.labels.support")}
        >
          {t("AdminConsole.Navigation.labels.support")}
        </StyledAdminLinkText>
      </NoStyleLink>
    </NavigationContainer>
  );
};
