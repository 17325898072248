import { gql } from "@apollo/client";

export const itemDataFields = gql`
  fragment ItemDataFields on ItemData {
    sections {
      name
      entries {
        name
        value
      }
    }
  }
`;
