import {
  RemoveVariationPreviewMutation,
  RemoveVariationPreviewMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { removeVariationPreviewMutation } from "./useRemoveVariationPreview.query";

export const useRemoveVariationPreview = () => {
  const [removeVariationPreview, { loading: removeVariationPreviewLoading }] =
    useGraphMutation<
      RemoveVariationPreviewMutation,
      RemoveVariationPreviewMutationVariables
    >(removeVariationPreviewMutation, {}, null);

  return {
    removeVariationPreview,
    loading: removeVariationPreviewLoading,
  };
};
