import {
  AuthorizationWorkflowActionMapping,
  AuthorizationWorkflowActionMappingsQuery,
  AuthorizationWorkflowActionMappingsQueryVariables,
} from "generated/graphql";
import { authorizationWorkflowActionMappingsQuery } from "graphql/queries/authorizationWorkflowActionMappings.query";
import { useGraphQuery } from "./useGraphQuery";

export const useAuthorizationWorkflowMappings = (
  productInstanceId: string,
  skip?: boolean
) => {
  const {
    data: authWorkflowActionMappings,
    refetch: refetchWorkflowActionMappings,
    loading: authWorkflowActionMappingsLoading,
  } = useGraphQuery<
    AuthorizationWorkflowActionMappingsQuery,
    AuthorizationWorkflowActionMappingsQueryVariables
  >(authorizationWorkflowActionMappingsQuery, {
    variables: { productInstanceId },
    skip,
  });

  return {
    authWorkflowActionMappings: (authWorkflowActionMappings
      ?.authorizationWorkflowActionMappings?.items ??
      []) as AuthorizationWorkflowActionMapping[],
    refetchWorkflowActionMappings,
    loading: authWorkflowActionMappingsLoading,
  };
};
