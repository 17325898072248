import { Box, Grid, Typography, css, styled } from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { useTranslation } from "react-i18next";

const GridItemContainer = styled(Grid)(
  ({ theme }) => css`
    padding-left: 0 !important;
    margin-left: -${theme.spacing(0.5)};
  `
);

export const ResultingDueDateField: React.FC<{ dueDate: string }> = ({
  dueDate,
}) => {
  const { t } = useTranslation();

  return (
    <GridItemContainer item xs={3}>
      <FormLabel
        label={t("Projects.CompEvents.ActionModal.resultingDueDate")}
      />
      <Box pt={1}>
        <Typography variant="p1" pt={1}>
          {dueDate}
        </Typography>
      </Box>
    </GridItemContainer>
  );
};
