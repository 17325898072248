import {
  ProductInstance,
  ProductInstanceLiteQuery,
  ProductInstanceLiteQueryVariables,
} from "generated/graphql";
import { productInstanceQuery } from "graphql/queries/productInstance.query";
import { useGraphQuery } from "./useGraphQuery";

export const useProductInstance = (
  productInstanceId?: string,
  skip?: boolean
) => {
  const {
    data: productInstance,
    refetch: refetchProductInstance,
    loading: productInstanceLoading,
  } = useGraphQuery<
    ProductInstanceLiteQuery,
    ProductInstanceLiteQueryVariables
  >(productInstanceQuery, {
    variables: {
      id: productInstanceId!,
    },
    skip: skip || !productInstanceId,
  });

  return {
    productInstance: productInstance?.productInstance as
      | ProductInstance
      | undefined,
    loading: productInstanceLoading,
    refetchDailyDiary: refetchProductInstance,
  };
};
