import { useProductItemContext } from "hooks/useProductItemContext";
import { useParams } from "react-router-dom";
import { useNewRisk } from "./useNewRisk";
import { ProductInstance } from "generated/graphql";
import { useNewEvent } from "./useNewEvent";

export const useNewRiskEvent = () => {
  const { projectId, contractId, productInstanceId } = useParams();
  const {
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
  } = useProductItemContext(projectId!, contractId!, productInstanceId!);

  return {
    ...useNewRisk(
      projectDataLite,
      contractDataLite,
      productInstanceData as ProductInstance,
      productInstanceDataLoading
    ),
    ...useNewEvent(
      projectDataLite,
      contractDataLite,
      productInstanceData as ProductInstance,
      productInstanceDataLoading
    ),
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
  };
};
