import {
  Box,
  Breadcrumbs,
  Collapse,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { BasicModal } from "components/BasicModal/BasicModal";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList, ListItem } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useContractTypeStatusOptions } from "components/StatusTag/useContractTypeStatusOptions";
import { FormPublicApi } from "types/decl";
import {
  AddSchemaSectionInput,
  AddSchemaSectionMutation,
  AddSchemaSectionMutationVariables,
  ChangeProductSchemaStatusMutation,
  ChangeProductSchemaStatusMutationVariables,
  ChangeSchemaSectionStatusMutation,
  ChangeSchemaSectionStatusMutationVariables,
  ColumnPlacementType,
  EditProductSchemaInput,
  EditProductSchemaMutation,
  EditProductSchemaMutationVariables,
  EditSchemaSectionInput,
  EditSchemaSectionMutation,
  EditSchemaSectionMutationVariables,
  FieldTypesQuery,
  FieldTypesQueryVariables,
  ProductLiteQuery,
  ProductLiteQueryVariables,
  ProductSchema,
  ProductSchemaQuery,
  ProductSchemaQueryVariables,
  ProductSchemaStatus,
  SchemaField,
  SchemaSection,
  SchemaSectionStatus,
  User,
} from "generated/graphql";
import { changeProductSchemaStatusMutation } from "graphql/mutations/changeProductSchemaStatus";
import { editProductSchemaMutation } from "graphql/mutations/editProductSchema";
import { productLiteQuery } from "graphql/queries/productLite.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FormSchemaType, SchemaForm } from "./components/SchemaForm/SchemaForm";
import { SchemaSections } from "./components/SchemaSections/SchemaSections";
import { SectionFields } from "./components/SectionFields/SectionFields";
import { useSectionFields } from "./components/SectionFields/useSectionFields";
import { useBasicModal } from "../../../../../../../components/BasicModal/useBasicModal";
import {
  addSchemaSectionMutation,
  changeSchemaSectionStatusMutation,
  editSchemaSectionMutation,
  fieldTypesQuery,
  productSchemaQuery,
} from "./SchemaDetails.query";
import { PencilSimple, TreeStructure } from "phosphor-react";
import { getUserName } from "helpers/miscelaneous";

export const SchemaDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { productId, schemaId } = useParams();
  const productSchemaFormRef = useRef<FormPublicApi>(null);
  const statusOptions =
    useContractTypeStatusOptions() as StatusOption<ProductSchemaStatus>[];

  const updatedProductSchemaDetails = useRef<EditProductSchemaInput>();
  const [showDetails, setShowDetails] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const [selectedSection, setSelectedSection] = useState<SchemaSection>();

  const {
    data: schemaData,
    refetch: refetchSchemaData,
    loading: getSchemaDataLoading,
  } = useGraphQuery<ProductSchemaQuery, ProductSchemaQueryVariables>(
    productSchemaQuery,
    {
      variables: { id: schemaId! },
    }
  );

  const handleFieldChange = useCallback(() => {
    refetchSchemaData();
  }, [refetchSchemaData]);

  const {
    addField,
    editField,
    changeFieldStatus,
    loading: fieldsLoading,
  } = useSectionFields({
    onFieldAdded: handleFieldChange,
    onFieldEdited: handleFieldChange,
    onFieldStatusChange: handleFieldChange,
  });
  const {
    modalVisibility: fieldsModalVisibility,
    toggleModalVisibility: toggleFieldsModalVisibility,
  } = useBasicModal();

  const { data: productDataLite, loading: getProductDataLiteLoading } =
    useGraphQuery<ProductLiteQuery, ProductLiteQueryVariables>(
      productLiteQuery,
      {
        variables: { id: productId! },
      }
    );

  const { data: fieldTypes, loading: getFieldTypesLoading } = useGraphQuery<
    FieldTypesQuery,
    FieldTypesQueryVariables
  >(fieldTypesQuery);

  const [
    changeProductSchemaStatus,
    { loading: changeProductSchemaStatusLoading },
  ] = useGraphMutation<
    ChangeProductSchemaStatusMutation,
    ChangeProductSchemaStatusMutationVariables
  >(
    changeProductSchemaStatusMutation,
    {
      update: () => {
        refetchSchemaData();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.schema"),
    })
  );

  const [
    changeSchemaSectionStatus,
    { loading: changeSchemaSectionStatusLoading },
  ] = useGraphMutation<
    ChangeSchemaSectionStatusMutation,
    ChangeSchemaSectionStatusMutationVariables
  >(
    changeSchemaSectionStatusMutation,
    {
      update: () => {
        refetchSchemaData();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.schema"),
    })
  );

  const [addSchemaSection, { loading: addSchemaSectionLoading }] =
    useGraphMutation<
      AddSchemaSectionMutation,
      AddSchemaSectionMutationVariables
    >(
      addSchemaSectionMutation,
      {
        update: () => {
          refetchSchemaData();
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("common.labels.section"),
      })
    );

  const [editProductSchema, { loading: editProductSchemaLoading }] =
    useGraphMutation<
      EditProductSchemaMutation,
      EditProductSchemaMutationVariables
    >(
      editProductSchemaMutation,
      {
        update: (cache) => {
          refetchSchemaData();
          cache.evict({ id: "ROOT_QUERY", fieldName: "product" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.schema"),
      })
    );

  const [editSchemaSection, { loading: editSchemaSectionLoading }] =
    useGraphMutation<
      EditSchemaSectionMutation,
      EditSchemaSectionMutationVariables
    >(
      editSchemaSectionMutation,
      {
        update: () => {
          refetchSchemaData();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.section"),
      })
    );

  const handleProductSchemaChange = useCallback(
    (updatedProductSchema: FormSchemaType) => {
      updatedProductSchemaDetails.current = {
        ...updatedProductSchema,
        productId: productDataLite?.product.id,
      } as EditProductSchemaInput;
    },
    [productDataLite]
  );

  const handleSchemaStatusChange = useCallback(
    async (newStatus: ProductSchemaStatus) => {
      await changeProductSchemaStatus({
        variables: {
          id: schemaId!,
          status: newStatus,
        },
      });
    },
    [changeProductSchemaStatus, schemaId]
  );

  const handleSaveClick = useCallback(async () => {
    if (
      !updatedProductSchemaDetails.current ||
      !productSchemaFormRef.current?.validate()
    ) {
      return;
    }
    const { errors } = await editProductSchema({
      variables: {
        input: updatedProductSchemaDetails.current,
      },
    });
    if (!errors) {
      setReadOnly(true);
    }
  }, [editProductSchema]);

  const handleSchemaSectionAdd = useCallback(
    async (sectionData: Omit<AddSchemaSectionInput, "productSchemaId">) => {
      await addSchemaSection({
        variables: {
          input: {
            productSchemaId: schemaId!,
            name: sectionData.name,
            displayText: sectionData.displayText,
            displayOrder: sectionData.displayOrder,
            columnPlacement: sectionData.columnPlacement,
          },
        },
      });
    },
    [addSchemaSection, schemaId]
  );

  const handleSchemaSectionStatusChange = useCallback(
    async (row: SchemaSection, newStatus: SchemaSectionStatus) => {
      await changeSchemaSectionStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeSchemaSectionStatus]
  );

  const handleSchemaSectionUpdate = useCallback(
    async (sectionData: Omit<EditSchemaSectionInput, "productSchemaId">) => {
      await editSchemaSection({
        variables: {
          input: {
            productSchemaId: schemaId!,
            id: sectionData.id,
            name: sectionData.name,
            displayOrder: sectionData.displayOrder,
            displayText: sectionData.displayText,
            ...(sectionData.columnPlacement
              ? { columnPlacement: sectionData.columnPlacement }
              : {}),
          },
        },
      });
    },
    [editSchemaSection, schemaId]
  );

  const handleShowSchemaSectionFields = useCallback(
    (sectionId?: GridRowId) => {
      const selectedSection =
        schemaData?.productSchema.schemaSections.items.find(
          (section) => section.id === sectionId
        );

      setSelectedSection(selectedSection as SchemaSection);
      toggleFieldsModalVisibility();
    },
    [schemaData, toggleFieldsModalVisibility]
  );

  const handleCancelClick = useCallback(() => {
    productSchemaFormRef.current?.reset();
    setReadOnly(true);
  }, []);

  const handleEditClick = useCallback(() => {
    setReadOnly(false);
    setShowDetails(true);
  }, []);

  const creatorStr = useMemo(() => {
    if (
      schemaData?.productSchema.creator.firstname &&
      schemaData?.productSchema.creator.surname
    ) {
      return getUserName(schemaData.productSchema.creator as User);
    }

    return "";
  }, [schemaData]);

  const handleBreadcrumbClick = (parent?: "product") => {
    if (parent === "product") {
      navigate(-1);
    } else {
      navigate(-2);
    }
  };

  const schemaEntityObj = useMemo(() => {
    const toReturn: ListItem[] = [];

    if (!schemaData?.productSchema) {
      return toReturn;
    }

    return [
      {
        id: "name",
        label: t("common.labels.name"),
        value: schemaData.productSchema.name,
      },
      {
        id: "isDefault",
        label: t("AdminConsole.Products.labels.isDefault"),
        value: String(schemaData.productSchema.isDefault),
      },
    ];
  }, [schemaData, t]);

  const loading =
    getSchemaDataLoading ||
    changeProductSchemaStatusLoading ||
    getProductDataLiteLoading ||
    addSchemaSectionLoading ||
    changeSchemaSectionStatusLoading ||
    editSchemaSectionLoading;

  const primaryColumnSections = useMemo(() => {
    return (
      schemaData?.productSchema.schemaSections.items.filter(
        (section) => section.columnPlacement === ColumnPlacementType.Primary
      ) || []
    );
  }, [schemaData]);

  const secondaryColumnSections = useMemo(() => {
    return (
      schemaData?.productSchema.schemaSections.items.filter(
        (section) => section.columnPlacement === ColumnPlacementType.Secondary
      ) || []
    );
  }, [schemaData]);

  return (
    <Box>
      <BasicModal
        headerIcon={<PencilSimple size={24} />}
        open={fieldsModalVisibility}
        title={t("AdminConsole.Products.labels.sectionNameFields", {
          sectionName: selectedSection?.name,
        })}
        onClose={toggleFieldsModalVisibility}
      >
        <SectionFields
          fieldTypes={fieldTypes?.fieldTypes.items || []}
          loading={getFieldTypesLoading || fieldsLoading}
          fields={
            (schemaData?.productSchema.schemaSections.items.find(
              (section) => selectedSection?.id === section.id
            )?.schemaFields.items as SchemaField[]) || []
          }
          schemaSectionId={selectedSection?.id}
          sectionName={selectedSection?.name}
          onSchemaFieldAdd={addField}
          onSchemaFieldStatusChange={changeFieldStatus}
          onSchemaFieldUpdate={editField}
        />
      </BasicModal>
      <EntityDetailsHeader
        loading={loading}
        title={schemaData?.productSchema.name || ""}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={() => handleBreadcrumbClick()}>
              <Typography variant="body2">
                {t("AdminConsole.Products.labels.products")}
              </Typography>
            </Link>
            <Link key="2" onClick={() => handleBreadcrumbClick("product")}>
              <Typography variant="body2">
                {productDataLite?.product.name || ""}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        status={schemaData?.productSchema.status}
        statusOptions={statusOptions}
        creator={creatorStr}
        dateCreated={schemaData?.productSchema.dateCreated}
        onStatusChange={handleSchemaStatusChange}
      />
      <CollapsibleHeader
        title={t("AdminConsole.Products.labels.schemaDetails")}
        collapsed={!showDetails}
        icon={
          <TreeStructure
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onCancel={handleCancelClick}
        onEdit={handleEditClick}
        onSave={handleSaveClick}
        onSaveActionLoading={editProductSchemaLoading}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList loading={loading} entity={schemaEntityObj} />
          ) : loading ? null : (
            <SchemaForm
              schema={schemaData!.productSchema as ProductSchema}
              disabled={loading}
              apiRef={productSchemaFormRef}
              onChange={handleProductSchemaChange}
            />
          )}
        </PageContentContainer>
      </Collapse>
      <SchemaSections
        sections={primaryColumnSections as SchemaSection[]}
        schemaName={schemaData?.productSchema.name}
        columnPlacement={ColumnPlacementType.Primary}
        onSeeSchemaSectionFields={handleShowSchemaSectionFields}
        onSchemaSectionAdd={handleSchemaSectionAdd}
        onSchemaSectionStatusChange={handleSchemaSectionStatusChange}
        onSchemaSectionUpdate={handleSchemaSectionUpdate}
      />
      <SchemaSections
        sections={secondaryColumnSections as SchemaSection[]}
        schemaName={schemaData?.productSchema.name}
        columnPlacement={ColumnPlacementType.Secondary}
        onSeeSchemaSectionFields={handleShowSchemaSectionFields}
        onSchemaSectionAdd={handleSchemaSectionAdd}
        onSchemaSectionStatusChange={handleSchemaSectionStatusChange}
        onSchemaSectionUpdate={handleSchemaSectionUpdate}
      />
    </Box>
  );
};
