import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { unofficialThemeColors } from "theme/extendedTheme";

export const EmptyQuotationsAssessmentsTableView: React.FC<{
  isAssessment?: boolean;
}> = ({ isAssessment }) => {
  const { t } = useTranslation();

  const computedLine1 = t(
    `Projects.CompEvents.QuotationAssessmentModal.${
      isAssessment ? "noAssessments" : "noQuotations"
    }`
  );
  const computedLine2 = t(
    `Projects.CompEvents.QuotationAssessmentModal.${
      isAssessment ? "clickToAddAssessment" : "clickToAdd"
    }`
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      width="100%"
      sx={{ background: unofficialThemeColors.blueWidgetLastStep }}
    >
      <Typography
        variant="h3"
        fontSize="18px"
        fontWeight={600}
        color="grey.800"
      >
        {computedLine1}
      </Typography>
      <Typography variant="p1" fontWeight={400} color="grey.700" mt={1}>
        {computedLine2}
      </Typography>
    </Box>
  );
};
