import { Box, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { TrashIconContained } from "components/Icons/TrashIconContained";
import { useTranslation } from "react-i18next";

export type DeleteAttachmentModalProps = {
  fileName: string;
} & ActionsDialogProps;

export const DeleteAttachmentModal: React.FC<DeleteAttachmentModalProps> = ({
  fileName,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ActionsDialog
      iconsHeader={<TrashIconContained />}
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Attachments.DeleteConfirmModal.title")}
          </Typography>
          <Box mb={2}>
            <Typography
              variant="p1"
              color={theme.palette.grey[700]}
              component="span"
            >
              {t("Attachments.DeleteConfirmModal.subtitle1")}
            </Typography>
            <Typography
              variant="p1"
              color={theme.palette.grey[700]}
              fontWeight="600"
              component="span"
            >
              {fileName}
            </Typography>
            <Typography
              variant="p1"
              color={theme.palette.grey[700]}
              component="span"
            >
              {t("Attachments.DeleteConfirmModal.subtitle2")}
            </Typography>
          </Box>
        </Box>
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      secondaryBtnCaption="Cancel"
      primaryBtnCaption="Delete"
      {...restDialogProps}
    />
  );
};
