import { dateISOFormat } from "constants/constants";
import {
  DailyDiaryItem,
  DailyDiaryItemByDateQuery,
  DailyDiaryItemByDateQueryVariables,
  EmptyDailyDiaryItem,
} from "generated/graphql";
import { dailyDiaryItemByDateQuery } from "graphql/queries/dailyDiaryItemByDate.query";
import moment from "moment";
import { useMemo } from "react";
import { useGraphQuery } from "./useGraphQuery";

export type DailyDiaryDateQuery = { date: string; productInstanceId: string };

export const useGetDailyDiary = (dateQuery: DailyDiaryDateQuery) => {
  const {
    data: dailyDiaryItemByDate,
    refetch: refetchDDByDate,
    loading: dailyDiaryItemByDateLoading,
  } = useGraphQuery<
    DailyDiaryItemByDateQuery,
    DailyDiaryItemByDateQueryVariables
  >(dailyDiaryItemByDateQuery, {
    variables: {
      productInstanceId: dateQuery?.productInstanceId!,
      date: moment(dateQuery?.date!).format(dateISOFormat),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const dailyDiaryItemFetched = !!dailyDiaryItemByDate;

  const dailyDiary = useMemo(() => {
    if (
      dailyDiaryItemByDate?.dailyDiaryItemByDate?.__typename ===
      "DailyDiaryItem"
    ) {
      return dailyDiaryItemByDate?.dailyDiaryItemByDate as DailyDiaryItem;
    }
  }, [dailyDiaryItemByDate]);

  const emptyDailyDiary = useMemo(() => {
    if (
      dailyDiaryItemByDate?.dailyDiaryItemByDate?.__typename ===
      "EmptyDailyDiaryItem"
    ) {
      return dailyDiaryItemByDate?.dailyDiaryItemByDate as EmptyDailyDiaryItem;
    }
  }, [dailyDiaryItemByDate]);

  return {
    dailyDiary,
    emptyDailyDiary,
    loading: dailyDiaryItemByDateLoading,
    dailyDiaryItemFetched,
    refetchDDByDate,
  };
};
