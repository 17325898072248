import { gql } from "@apollo/client";
import { dailyDiaryResourceRecordLiteFields } from "graphql/fragments/dailyDiaryResourceRecordLite.fragment";

export const addDailyDiaryEquipmentRecordMutation = gql`
  ${dailyDiaryResourceRecordLiteFields}

  mutation addDailyDiaryEquipmentRecord(
    $input: AddDailyDiaryResourceRecordInput!
  ) {
    addDailyDiaryEquipmentRecord(input: $input) {
      ...DailyDiaryResourceRecordLiteFields
    }
  }
`;
