import { gql } from "@apollo/client";
import { productItemAuthWorkflowAuditFields } from "containers/Projects/fragments/productItemAuthWorkflowAudit.fragment";
import { claimItemFields } from "graphql/fragments/claimItem.fragment";

export const localClaimItemQuery = gql`
  ${claimItemFields}
  ${productItemAuthWorkflowAuditFields}

  query localClaimItem($id: ID!) {
    claimItem(id: $id) {
      ...ClaimItemFields
      ongoingAuthorizationAudit {
        ...ProductItemAuthWorkflowAuditFields
      }
    }
  }
`;
