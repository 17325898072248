import { gql } from "@apollo/client";
import { lookupCollectionFields } from "graphql/queries/lookupCollection.query";

export const addLookupCollectionMutation = gql`
  ${lookupCollectionFields}
  mutation addLookupCollection($input: AddLookupCollectionInput!) {
    addLookupCollection(input: $input) {
      ...LookupCollectionFields
    }
  }
`;
