import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventGrantingOfMoreTimePreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventGrantingOfMoreTimePreview(
    $input: RecordCompEventGrantingOfMoreTimePreviewInput!
  ) {
    generateRecordCompEventGrantingOfMoreTimePreview(input: $input) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
