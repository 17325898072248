import { EditUserInput } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";

export const formDataValidators: DataValidators = {
  country: {
    validators: [ValidatorType.Required],
  },
  dateOfBirth: {
    validators: [ValidatorType.Required, ValidatorType.ValidDate],
  },
  firstname: {
    validators: [ValidatorType.Required],
  },
  surname: {
    validators: [ValidatorType.Required],
  },
  jobTitle: {
    validators: [ValidatorType.Required],
  },
  mobileNumber: {
    validators: [ValidatorType.Required, ValidatorType.ValidPhoneNumber],
  },
  alternateNumber: {
    validators: [ValidatorType.NullablePhoneNumber],
  },
};

export const defaultFormData: EditUserInput = {
  id: "",
  companyId: "",
  alternateNumber: "",
  country: "",
  dateOfBirth: "",
  firstname: "",
  jobTitle: "",
  mobileNumber: "",
  profilePicture: "",
  surname: "",
};
