import {
  AddDailyDiaryHseRecordMutation,
  AddDailyDiaryHseRecordMutationVariables,
  DailyDiaryItem,
  EditDailyDiaryHseRecordMutation,
  EditDailyDiaryHseRecordMutationVariables,
  RemoveDailyDiaryHseRecordMutation,
  RemoveDailyDiaryHseRecordMutationVariables,
} from "generated/graphql";
import { addDailyDiaryHSERecordMutation } from "graphql/mutations/addDailyDiaryHSERecord";
import { editDailyDiaryHSERecordMutation } from "graphql/mutations/editDailyDiaryHSERecord";
import { removeDailyDiaryHSERecordMutation } from "graphql/mutations/removeDailyDiaryHSERecord";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { useHSERecords } from "./useHSERecords";

export const useHSESection = (
  dailyDiary?: DailyDiaryItem,
  revisionId?: string
) => {
  const { t } = useTranslation();

  const { refetchDDByDate } = useContext(DailyDiaryContext);

  const { hseRecords, hseRecordsLoading, refetchDDHseRecords } = useHSERecords(
    dailyDiary,
    revisionId
  );

  const [addDDHseRecord, { loading: addDDHseRecordLoading }] = useGraphMutation<
    AddDailyDiaryHseRecordMutation,
    AddDailyDiaryHseRecordMutationVariables
  >(
    addDailyDiaryHSERecordMutation,
    {
      update: async () => {
        if (!dailyDiary) {
          await refetchDDByDate?.();
        } else {
          refetchDDHseRecords();
        }
      },
    },
    t("common.successMessages.entityCreated", {
      entity: t("Projects.DailyDiaries.HSERecord"),
    })
  );

  const [editDDHseRecord, { loading: editDDHseRecordLoading }] =
    useGraphMutation<
      EditDailyDiaryHseRecordMutation,
      EditDailyDiaryHseRecordMutationVariables
    >(
      editDailyDiaryHSERecordMutation,
      {
        update: () => {
          refetchDDHseRecords();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.DailyDiaries.HSERecord"),
      })
    );

  const [removeDDHseRecord, { loading: removeDDHseRecordLoading }] =
    useGraphMutation<
      RemoveDailyDiaryHseRecordMutation,
      RemoveDailyDiaryHseRecordMutationVariables
    >(
      removeDailyDiaryHSERecordMutation,
      {
        update: () => {
          refetchDDHseRecords();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.DailyDiaries.HSERecord"),
      })
    );

  return {
    hseRecords,
    loading:
      hseRecordsLoading ||
      addDDHseRecordLoading ||
      editDDHseRecordLoading ||
      removeDDHseRecordLoading,
    addHseRecord: addDDHseRecord,
    editHseRecord: editDDHseRecord,
    removeHseRecord: removeDDHseRecord,
    refetchDDHseRecords,
  };
};
