import { IntercomEvents } from "constants/intercom";
import { getMetadataSet1 } from "containers/Projects/utils/intercom";
import {
  AddDraftEventItemMutation,
  AddDraftEventItemMutationVariables,
  AddEventItemMutation,
  AddEventItemMutationVariables,
  Contract,
  DraftEventItemQuery,
  DraftEventItemQueryVariables,
  EditDraftEventItemMutation,
  EditDraftEventItemMutationVariables,
  ProductInstance,
  ProductType,
  Project,
} from "generated/graphql";
import { addDraftEventItemMutation } from "graphql/mutations/addDraftEventItem";
import { addEventItemMutation } from "graphql/mutations/addEventItem";
import { editDraftEventItemMutation } from "graphql/mutations/editDraftEventItem";
import { draftEventItemQuery } from "graphql/queries/draftEventItem.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

/**
 * Should be used only under ExplorerContext.Provider, otherwise event tracking wouldn't be working
 * @returns create operation on Event & DraftEvent items
 */
export const useNewEvent = (
  projectDataLite?: Project,
  contractDataLite?: Contract,
  productInstanceData?: ProductInstance,
  productInstanceDataLoading?: boolean
) => {
  const { t } = useTranslation();
  const { trackEvent } = useIntercom();
  const { productInstanceId } = useParams();

  const [
    fetchDraftEventData,
    {
      data: draftEventData,
      loading: fetchDraftEventDataLoading,
      refetch: refetchDraftEventData,
    },
  ] = useGraphLazyQuery<DraftEventItemQuery, DraftEventItemQueryVariables>(
    draftEventItemQuery
  );

  const [addEventItem, { loading: addEventItemLoading }] = useGraphMutation<
    AddEventItemMutation,
    AddEventItemMutationVariables
  >(
    addEventItemMutation,
    {
      update: (_cache, result) => {
        trackEvent(
          IntercomEvents.CreatedEvent,
          getMetadataSet1({
            productInstanceId: productInstanceId!,
            productInstanceItemId: result.data?.addEventItem.id!,
            productType: ProductType.Events,
            contractFn: contractDataLite?.friendlyName ?? "",
            projectFn: projectDataLite?.friendlyName ?? "",
            productInstanceItemName: result.data?.addEventItem.title ?? "",
            version: process.env.REACT_APP_VERSION!,
          })
        );
        // cache.evict({ id: "ROOT_QUERY", fieldName: "eventItems" });
        // cache.evict({ id: "ROOT_QUERY", fieldName: "draftEventItems" });
        // cache.gc();
      },
    },
    t("common.successMessages.entityCreated", {
      entity: t("Projects.Events.event"),
    })
  );

  const [addEventItemDraft, { loading: addEventItemDraftLoading }] =
    useGraphMutation<
      AddDraftEventItemMutation,
      AddDraftEventItemMutationVariables
    >(
      addDraftEventItemMutation,
      {
        // update: (cache) => {
        //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftEventItems" });
        //   cache.gc();
        // },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.Events.draftEvent"),
      })
    );

  const [editEventItemDraft, { loading: editEventItemDraftLoading }] =
    useGraphMutation<
      EditDraftEventItemMutation,
      EditDraftEventItemMutationVariables
    >(
      editDraftEventItemMutation,
      {
        update: (_cache) => {
          refetchDraftEventData();

          //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftEventItems" });
          //   cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Events.draftEvent"),
      })
    );

  return {
    draftEventData,
    fetchDraftEventData,
    fetchDraftEventDataLoading,
    addEventItem,
    addEventItemLoading,
    addEventItemDraft,
    addEventItemDraftLoading,
    editEventItemDraft,
    editEventItemDraftLoading,
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
  };
};
