import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateSendCompEventNoticeRequestQuotationPreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateSendCompEventNoticeRequestQuotationPreview(
    $input: SendCompEventNoticeRequestQuotationPreviewInput!
  ) {
    generateSendCompEventNoticeRequestQuotationPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
