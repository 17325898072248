import { OnMutationDone } from "types/decl";
import {
  AddContractKeyDateInput,
  Contract,
  ContractKeyDateStatus,
  EditContractInput,
  EditContractKeyDateInput,
  EditContractMutation,
  EditContractMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { editContractMutation } from "../ContractDetails.query";
import { contractToEditContractInput } from "../ContractDetails.utils";
import { snackbarAutoHideDuration } from "constants/constants";

export type useContractKeyDatesArgs = {
  contract?: Contract;
  onEdit: OnMutationDone;
};

export const useContractKeyDates = ({
  contract,
  onEdit,
}: useContractKeyDatesArgs) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [editContract, { loading }] = useGraphMutation<
    EditContractMutation,
    EditContractMutationVariables
  >(
    editContractMutation,
    {
      update: onEdit,
    },
    null
  );

  const handleAddKeyDate = useCallback(
    async (newKeyDate: AddContractKeyDateInput) => {
      const contractToUpdate: EditContractInput = contractToEditContractInput(
        contract!
      );
      contractToUpdate.keyDates?.push({
        ...newKeyDate,
        status: ContractKeyDateStatus.Active,
      });

      const { data } = await editContract({
        variables: {
          input: contractToUpdate,
        },
      });

      if (data) {
        enqueueSnackbar(
          t("common.successMessages.entityCreated", {
            entity: t("AdminConsole.ContractKeyDates.contractKeyDate"),
          }),
          {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          }
        );
      }

      return !!data;
    },
    [editContract, contract, t, enqueueSnackbar]
  );

  const handleEditKeyDate = useCallback(
    async (updatedKeyDate: EditContractKeyDateInput) => {
      const isDeleteCase =
        updatedKeyDate.status === ContractKeyDateStatus.Removed;
      const contractToUpdate: EditContractInput = contractToEditContractInput(
        contract!
      );

      if (isDeleteCase) {
        contractToUpdate.keyDates = contractToUpdate.keyDates?.filter(
          (keyDate) => keyDate.id !== updatedKeyDate.id
        );
      } else {
        contractToUpdate.keyDates = contractToUpdate.keyDates?.map((keyDate) =>
          keyDate.id === updatedKeyDate.id ? updatedKeyDate : keyDate
        );
      }

      const { data } = await editContract({
        variables: {
          input: contractToUpdate,
        },
      });

      if (data) {
        enqueueSnackbar(
          t(
            `common.successMessages.${
              isDeleteCase ? "entityDeleted" : "entityUpdated"
            }`,
            {
              entity: t("AdminConsole.ContractKeyDates.contractKeyDate"),
            }
          ),
          {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          }
        );
      }

      return !!data;
    },
    [editContract, contract, t, enqueueSnackbar]
  );

  return {
    addKeyDate: handleAddKeyDate,
    editKeyDate: handleEditKeyDate,
    loading,
  };
};
