import { Attachment } from "generated/graphql";
import { isAttachment } from "../Attachments.utils";
import { DescriptionText, EditableLabel } from "./FileListItem/EditableLabel";
import { FileType } from "../Attachments.decl";

type FileDescriptionProps = {
  file: Attachment | FileType;
  editable?: boolean;
  newStyle?: boolean;
  onDescriptionChange: (newDescription?: string) => void;
};

export const FileDescription: React.FC<FileDescriptionProps> = ({
  file,
  editable,
  newStyle,
  onDescriptionChange,
}) => {
  return (isAttachment(file) && file.locked) || !editable ? (
    file.description ? (
      <DescriptionText title={file.description} newStyle={newStyle} />
    ) : null
  ) : !isAttachment(file) && file.loading ? null : (
    <EditableLabel
      text={file.description ?? ""}
      onChange={onDescriptionChange}
    />
  );
};
