import {
  Box,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Tag } from "components/Tag";
import { useCallback } from "react";
import { PageContentHeader } from "./PageContentHeader/PageContentHeader";
import { ExcelIcon } from "./Icons/ExcelIcon";
import { useTranslation } from "react-i18next";
import {
  EditEntityHeaderButtons,
  EditEntityHeaderButtonsProps,
} from "./PageContentHeader/EditEntityHeaderButtons";
import { LoadingButton } from "@mui/lab";

export type GridHeaderProps = {
  visibleRowsCount?: number;
  title: string;
  actionButtonCaption?: string;
  leftContent?: React.ReactNode;
  selectedCount?: number;
  sticky?: boolean;
  withShadow?: boolean;
  editable?: boolean;
  onActionButtonLoading?: boolean;
  containerStyle?: SxProps<Theme>;
  actionBtnDataTestId?: string;
  onExportToExcel?: () => void;
  onActionButtonClick?: () => void;
} & Omit<EditEntityHeaderButtonsProps, "onEditTooltip">;

// TODO: come up with a better name - It's used in CollapsibleHeader.tsx which is a Header used not only for grids
export const GridHeader: React.FC<GridHeaderProps> = ({
  visibleRowsCount,
  title,
  selectedCount,
  leftContent,
  actionButtonCaption,
  sticky = false,
  withShadow,
  editable = false,
  editMode,
  onSaveActionLoading,
  onActionButtonLoading,
  containerStyle,
  actionBtnDataTestId,
  onCancel,
  onEdit,
  onSave,
  onExportToExcel,
  onActionButtonClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const getSelectedItemsLabel = useCallback((selectedCount: number) => {
    return `${selectedCount} selected item${selectedCount > 1 ? "s" : ""}`;
  }, []);

  return (
    <PageContentHeader
      sticky={sticky}
      withShadow={withShadow}
      sx={containerStyle}
    >
      <Box display="flex" alignItems="center">
        {leftContent}
        <Typography fontSize="18px" fontWeight={500} lineHeight="22px">
          {title}
        </Typography>
        {typeof visibleRowsCount !== "undefined" &&
        visibleRowsCount !== null ? (
          <Tag ml={2} minWidth="20px">
            <Typography variant="caption">{visibleRowsCount}</Typography>
          </Tag>
        ) : null}
      </Box>
      <Box display="flex" alignItems="center" ml="auto">
        {selectedCount ? (
          <Typography variant="caption" fontWeight={700} fontSize="13px">
            {getSelectedItemsLabel(selectedCount)}
          </Typography>
        ) : null}
        {onExportToExcel && (
          <Box ml={4} mr={onActionButtonClick ? 4 : 0}>
            <IconButton
              onClick={selectedCount ? onExportToExcel : undefined}
              style={{
                background: "transparent",
                color: selectedCount
                  ? theme.palette.text.primary
                  : theme.palette.action.disabled,
                cursor: selectedCount ? "pointer" : "default",
              }}
              size="small"
            >
              <ExcelIcon
                color={
                  selectedCount
                    ? theme.palette.primary.main
                    : theme.palette.action.disabled
                }
              />
              {selectedCount ? (
                <Typography variant="body2" pl={1} fontWeight={500}>
                  {t("common.labels.exportToExcel")}
                </Typography>
              ) : (
                <Tooltip title={t("common.labels.excelExportTooltip")} arrow>
                  <Typography variant="body2" pl={1} fontWeight={500}>
                    {t("common.labels.exportToExcel")}
                  </Typography>
                </Tooltip>
              )}
            </IconButton>
          </Box>
        )}
        {onActionButtonClick && (
          <LoadingButton
            onClick={onActionButtonClick}
            variant="contained"
            data-testid={actionBtnDataTestId}
            color="primary"
            disableRipple
            size="small"
            style={{ color: "white", textTransform: "none" }}
            loading={onActionButtonLoading}
          >
            <Typography variant="body2" fontWeight={500}>
              {actionButtonCaption}
            </Typography>
          </LoadingButton>
        )}
        {editable && (
          <EditEntityHeaderButtons
            editMode={editMode}
            onCancel={onCancel!}
            onEdit={onEdit}
            onSave={onSave!}
            onSaveActionLoading={onSaveActionLoading}
          />
        )}
      </Box>
    </PageContentHeader>
  );
};
