import { Box, css, styled, Typography, useTheme } from "@mui/material";
import { UserProfileButton } from "components/UserProfileButton/UserProfileButton";
import { Comment as CommentType, CommentReply } from "generated/graphql";
import { ChatCircle } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { ListItemsDivider } from "../../../../../components/ListItemsDivider";
import { LocalComment, LocalCommentReply } from "../useComments";
import "./Comment.css";
import { getUserName, noop } from "helpers/miscelaneous";
import { DateWithTimeTooltip } from "../../DateWithTimeTooltip";
import { useContext } from "react";
import { CommentsContext } from "../Comments.context";

export type CommentProps = {
  comment: LocalComment | LocalCommentReply;
  readOnly?: boolean;
  onReply?: () => void;
};

export const isCommentReply = (
  file: CommentType | CommentReply
): file is CommentReply => {
  return (file as CommentReply).commentId !== undefined;
};

const CommentContentContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.grey[100]};
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  `
);

const CommentReplyFooter: React.FC<{
  onReply: () => void;
  repliesNo: number;
}> = ({ repliesNo, onReply }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <ChatCircle size={12} />
      <Typography
        variant="p2"
        fontWeight={700}
        color="grey.900"
        ml={1.25}
        sx={{ cursor: "pointer" }}
        onClick={onReply}
      >
        Reply
      </Typography>
      {repliesNo > 0 && (
        <>
          <ListItemsDivider
            orientation="vertical"
            sx={{
              height: "20px",
              borderWidth: "1px",
              my: 0,
              mx: theme.spacing(2),
            }}
          />
          <Typography variant="p1" color="grey.500">{`${repliesNo} ${t(
            `Comments.${repliesNo === 0 ? "reply" : "replies"}`
          )}`}</Typography>
        </>
      )}
    </Box>
  );
};

const CommentContent: React.FC<{
  comment: LocalComment | LocalCommentReply;
}> = ({ comment }) => {
  const { t } = useTranslation();
  const { timezone } = useContext(CommentsContext);

  return (
    <CommentContentContainer
      display="flex"
      flexDirection="column"
      position="relative"
      px={2}
      py={1}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="p1" color="grey.800" fontWeight={600}>
          {getUserName(comment.creator)}
        </Typography>

        {comment.isOptimistic ? (
          <Typography variant="p2" color="grey.500">
            {t("Comments.postingSuspension")}
          </Typography>
        ) : (
          <DateWithTimeTooltip
            datetime={comment.dateCreated}
            variant="p2"
            color="grey.500"
            timezone={timezone}
          />
        )}
      </Box>
      <Typography variant="p3" color="grey.600" mb={1.5}>
        {comment.creator.company.tradingName}
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: comment.content }}></div>
    </CommentContentContainer>
  );
};

export const Comment: React.FC<CommentProps> = ({
  comment,
  readOnly,
  onReply,
}) => (
  <Box display="flex" alignItems="flex-start" width="100%">
    <UserProfileButton
      width="40px"
      height="40px"
      mr={1}
      url={comment.creator.profilePicture ?? undefined}
    />
    <Box display="flex" flexDirection="column" flex={1} position="relative">
      <CommentContent comment={comment} />
      {!isCommentReply(comment) && !readOnly && (
        <Box mt={1.5}>
          <CommentReplyFooter
            onReply={onReply!}
            repliesNo={(comment as CommentType).replies.items.length}
          />
        </Box>
      )}
      {!isCommentReply(comment) &&
        comment.replies.items.length > 0 &&
        comment.replies.items.map((commentReply) => {
          return (
            <Box key={commentReply.id} mt={3}>
              {/* Replying to replies not possible for now */}
              <Comment comment={commentReply} onReply={noop} />
            </Box>
          );
        })}
    </Box>
  </Box>
);
