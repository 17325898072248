import {
  Checkbox,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
  GridEditInputCell,
  GridEditSingleSelectCell,
} from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { temporaryRowId } from "constants/constants";
import { FieldType, SchemaField, SchemaFieldStatus } from "generated/graphql";
import {
  getCommonActions,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<SchemaFieldStatus>[],
  onStatusChange: (row: SchemaField, newStatus: SchemaFieldStatus) => void,
  onFieldTypeChange: (
    row: SchemaField,
    event: SelectChangeEvent<unknown>
  ) => void,
  rowModesModel: GridRowModesModel,
  handleSaveRow: (rowId: GridRowId) => void,
  handleDeleteRow: (rowId: GridRowId) => void,
  fieldTypes: FieldType[],
  handleIsRequiredChange: (rowId: GridRowId) => void
): GridColDef<SchemaField>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const fieldTypeOptions = fieldTypes.map((type) => ({
    value: type.id,
    label: type.description,
  }));

  const columns = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: t("common.labels.name"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          editable: true,
          sortable: false,
          renderEditCell: (
            params: GridRenderEditCellParams<any, SchemaField>
          ) => {
            return <GridEditInputCell {...params} error={!params.row.name} />;
          },
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortable: false,
          sortComparator: statusComparatorFunction,
          renderCell: (params: GridRenderCellParams<any, SchemaField, any>) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disablePortal={false}
                disabled={params.row.id === temporaryRowId}
                onChange={(newStatus: StatusOption<SchemaFieldStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "displayText",
          headerName: t("AdminConsole.Products.labels.displayText"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          editable: true,
          sortable: false,
          renderEditCell: (
            params: GridRenderEditCellParams<any, SchemaField>
          ) => {
            return (
              <GridEditInputCell {...params} error={!params.row.displayText} />
            );
          },
        },
        {
          field: "fieldTypeId",
          headerName: t("AdminConsole.Products.labels.fieldType"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          editable: true,
          sortable: false,
          type: "singleSelect",
          valueOptions: fieldTypeOptions,
          valueFormatter: (value: string) => {
            const option = fieldTypeOptions.find(
              ({ value: optionValue }: { value: string; label: string }) =>
                value === optionValue
            );

            return option?.label;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<any, SchemaField, any>
          ) => (
            <GridEditSingleSelectCell
              {...cellParams}
              error={!cellParams.row.fieldTypeId}
              onValueChange={(event: SelectChangeEvent<any>) => {
                onFieldTypeChange(cellParams.row, event);
              }}
              initialOpen={false}
            />
          ),
        },
        {
          field: "displayOrder",
          headerName: t("AdminConsole.Products.labels.displayOrder"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
        },
        {
          field: "isRequired",
          headerName: t("common.labels.required"),
          flex: 0.075,
          minWidth: 90,
          resizable: true,
          sortable: false,
          renderCell: (params: GridRenderCellParams<any, SchemaField, any>) => {
            return (
              <Checkbox
                checked={params.row.isRequired}
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
                onChange={() => {
                  handleIsRequiredChange(params.row.id);
                }}
              />
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "extraData",
          headerName: t("AdminConsole.Products.labels.extraData"),
          flex: 0.125,
          width: 180,
          resizable: true,
          sortable: false,
          renderCell: (params: GridRenderCellParams<any, SchemaField, any>) => {
            if (!params.row.extraData) {
              return (
                <Typography variant="body2">
                  {t("common.labels.n/a") as string}
                </Typography>
              );
            }
            const parsedExtraData = JSON.parse(params.row.extraData);
            const value = Array.isArray(parsedExtraData)
              ? parsedExtraData.join(", ")
              : params.row.extraData;

            return (
              <Tooltip title={value}>
                <Typography
                  variant="body2"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  width="100%"
                >
                  {value ?? t("common.labels.n/a")}
                </Typography>
              </Tooltip>
            );
          },
        },
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          sortable: false,
          getActions: (cellParams) =>
            getCommonActions(
              cellParams,
              rowModesModel,
              handleSaveRow,
              handleDeleteRow
            ),
        },
      ] as GridColDef<SchemaField>[],
    [
      t,
      dateCreatedColConfig,
      statusOptions,
      fieldTypeOptions,
      onStatusChange,
      onFieldTypeChange,
      rowModesModel,
      handleSaveRow,
      handleDeleteRow,
      handleIsRequiredChange,
    ]
  );
  return columns;
};
