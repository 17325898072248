import { gql } from "@apollo/client";
import { draftAssessmentFields } from "graphql/fragments/draftAssessment.fragment";

export const compEventOwnAssessmentPromptQuery = gql`
  ${draftAssessmentFields}

  query compEventOwnAssessmentPrompt(
    $input: CompEventOwnAssessmentPromptInput!
  ) {
    compEventOwnAssessmentPrompt(input: $input) {
      draftAssessments {
        ...DraftAssessmentFields
      }
    }
  }
`;
