import { Box, Button, Typography } from "@mui/material";
import { SquareSuccessIcon } from "components/Icons/SquareSuccessIcon";
import { useTranslation } from "react-i18next";
import { ResetPasswordFooter } from "./ResetPasswordFooter";
import { AuthPageHeader } from "./AuthPageHeader";

export const ResetPasswordEmailSentView: React.FC<{
  onEnterSecurityCodeClick: () => void;
}> = ({ onEnterSecurityCodeClick }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" maxWidth="420px">
      <SquareSuccessIcon />
      <Box mt={5} mb={6}>
        <AuthPageHeader
          title={t("ResetPassword.linkSuccessfullySent")}
          subtitle={t("ResetPassword.checkInbox")}
        />
      </Box>
      <Button
        variant="contained"
        onClick={onEnterSecurityCodeClick}
        size="large"
      >
        <Typography variant="p2" fontWeight={700}>
          {t("ResetPassword.proceed")}
        </Typography>
      </Button>
      <Box mt={3}>
        <ResetPasswordFooter />
      </Box>
    </Box>
  );
};
