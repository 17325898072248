import { gql } from "@apollo/client";

export const contractBindingTypesQuery = gql`
  query contractBindingTypes($id: ID!) {
    contract(id: $id) {
      id
      contractType {
        id
        description
        subType
        version
        status
        contractBindingTypes {
          items {
            id
            description
            contractTypeId
            status
          }
        }
      }
    }
  }
`;
