import { gql } from "@apollo/client";
import { eventItemFields } from "graphql/fragments/eventItem.fragment";

export const addEventItemMutation = gql`
  ${eventItemFields}
  mutation addEventItem($input: AddEventItemInput!, $draftId: ID) {
    addEventItem(input: $input, draftId: $draftId) {
      ...EventItemFields
    }
  }
`;
