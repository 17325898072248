import { Box } from "@mui/material";
import {
  ContractType,
  ContractTypesQuery,
  ContractTypesQueryVariables,
  EffectPreset,
  EffectPresetsQuery,
  EffectPresetsQueryVariables,
} from "generated/graphql";
import { contractTypesQuery } from "graphql/queries/contractTypes.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { effectPresetsQuery } from "./Effect.query";
import { EffectPresets } from "./components/EffectPresets/EffectPresets";
import { useEffectPresets } from "./components/EffectPresets/useEffectPresets";

export const Effect = () => {
  const {
    data: effectPresets,
    refetch: refetchEffectPresets,
    loading: getEffectPresetsLoading,
  } = useGraphQuery<EffectPresetsQuery, EffectPresetsQueryVariables>(
    effectPresetsQuery
  );

  const { data: contractTypes, loading: getContractTypesLoading } =
    useGraphQuery<ContractTypesQuery, ContractTypesQueryVariables>(
      contractTypesQuery
    );

  const handleEffectPresetChange = () => {
    refetchEffectPresets();
  };

  const {
    loading,
    addEffectPreset,
    editEffectPreset,
    changeEffectPresetStatus,
  } = useEffectPresets({
    onAdd: handleEffectPresetChange,
    onEdit: handleEffectPresetChange,
    onStatusChange: handleEffectPresetChange,
  });

  return (
    <Box>
      <EffectPresets
        effectPresets={
          (effectPresets?.effectPresets.items as EffectPreset[]) || []
        }
        contractTypes={
          (contractTypes?.contractTypes.items as ContractType[]) || []
        }
        loading={getEffectPresetsLoading || getContractTypesLoading || loading}
        onStatusChange={changeEffectPresetStatus}
        onAdd={addEffectPreset}
        onUpdate={editEffectPreset}
      />
    </Box>
  );
};
