import { ActionsDialogProps } from "components/ActionsDialog";
import { VariationActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useSendErrorFoundInAgreementNoticeModal } from "./useSendErrorFoundInAgreementNoticeModal";
import { useTranslation } from "react-i18next";

export type SendErrorFoundInAgreementNoticeModalProps = {
  details: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendErrorFoundInAgreementNoticeModal: React.FC<
  SendErrorFoundInAgreementNoticeModalProps
> = ({ details, attachments = [], ...restDialogProps }) => {
  const { variationPreview, contract } =
    useSendErrorFoundInAgreementNoticeModal(details);
  const { t } = useTranslation();

  return (
    <>
      <SendAction
        variationPreview={variationPreview}
        actionType={VariationActionType.NotifyErrorFoundAgreement}
        summaryEl={
          <ReasonsAttachmentsSummary
            text={details}
            label={t("Projects.Variations.ActionModal.errorsFound")}
            attachments={attachments}
            contractTimezone={contract.timeZone}
          />
        }
        {...restDialogProps}
      />
    </>
  );
};
