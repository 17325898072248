import {
  CompEventHistoryItem,
  CompEventHistoryItemQuery,
  CompEventHistoryItemQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "../../../../../../../hooks/useGraphQuery";
import { compEventHistoryItemQuery } from "./compEventHistoryItem.query";

export const useCompEventHistoryItem = (id?: string) => {
  const { data: compEventHistoryItem, loading: compEventHistoryItemLoading } =
    useGraphQuery<
      CompEventHistoryItemQuery,
      CompEventHistoryItemQueryVariables
    >(compEventHistoryItemQuery, {
      variables: {
        id: id!,
      },
      skip: !id,
    });

  return {
    compEventHistoryItem: compEventHistoryItem?.compEventHistoryItem as
      | CompEventHistoryItem
      | undefined,
    loading: compEventHistoryItemLoading,
  };
};
