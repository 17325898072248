import {
  TextareaAutosizeProps,
  TextareaAutosize,
  styled,
  css,
  Color,
} from "@mui/material";
import { forwardRef } from "react";

export type TextareaProps = {
  error?: boolean;
  dataTestId?: string;
} & TextareaAutosizeProps;

const StyledTextArea = styled(TextareaAutosize, {
  shouldForwardProp: (prop) => prop !== "error",
})<{ error?: boolean }>(
  ({ theme, error }) => css`
    border-color: ${theme.palette.grey[200]};
    border-radius: 8px;
    color: ${theme.palette.grey[800]};
    font-size: ${theme.typography.p1?.fontSize};
    line-height: ${theme.typography.p1?.lineHeight};
    padding: ${theme.spacing(1)};
    outline: none !important;
    resize: vertical;
    min-height: 120px;
    width: 100%;
    box-sizing: border-box;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: ${theme.typography.p1?.fontWeight};
    font-size: ${theme.typography.p1?.fontSize};
    line-height: ${theme.typography.p1?.lineHeight};

    &:placeholder {
      color: ${theme.palette.grey[500]};
    }
    &:hover {
      border-color: ${theme.palette.grey[500]};
    }
    &:active,
    &:focus,
    &:focus-visible {
      border-color: ${(theme.palette.primary as Partial<Color>)[500]};
      border-width: 1px;
    }

    &:disabled {
      background-color: ${theme.palette.grey[100]};
      color: ${theme.palette.text.disabled};
      border: none;
    }

    ${error &&
    css`
      border-color: ${theme.palette.error.main};
    `}
  `
);

export const Textarea: React.FC<TextareaProps> = forwardRef(
  ({ error, dataTestId = "textarea", ...textAreaProps }, ref) => {
    return (
      <StyledTextArea
        error={error}
        ref={ref}
        data-testid={dataTestId}
        {...textAreaProps}
      ></StyledTextArea>
    );
  }
);
