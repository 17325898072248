import { Box, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { WarningIcon } from "components/Icons/WarningIcon";
import { ProductType } from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export type ProductItemDraftConfirmModalProps = {
  productItemType: ProductType;
} & ActionsDialogProps;

export const ProductItemDraftConfirmModal: React.FC<
  ProductItemDraftConfirmModalProps
> = ({ productItemType, ...restDialogProps }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const productItemName = useMemo(() => {
    let itemString = "";

    switch (productItemType) {
      case ProductType.RisksRegister:
        itemString = "risk";
        break;
      case ProductType.EarlyWarnings:
        itemString = "earlyWarning";
        break;
      case ProductType.Events:
        itemString = "event";
        break;
      case ProductType.Instructions:
        itemString = "instruction";
        break;
    }

    return t(`common.labels.${itemString}`).toLowerCase();
  }, [t, productItemType]);

  return (
    <ActionsDialog
      iconsHeader={<WarningIcon />}
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Projects.Risks.DraftConfirmModal.title", {
              productItem: productItemName,
            })}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]} mb={2}>
            {t("Projects.Risks.DraftConfirmModal.subtitle", {
              productItem: productItemName,
            })}
          </Typography>
        </Box>
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      {...restDialogProps}
    />
  );
};
