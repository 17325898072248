import { Box, Color, SxProps, useTheme } from "@mui/material";

export type IconContainerProps = {
  children: React.ReactNode;
  height?: string;
  width?: string;
  sx?: SxProps;
  greyBackground?: boolean;
};

export const IconContainer: React.FC<IconContainerProps> = ({
  children,
  sx,
  height = "40px",
  width = "40px",
  greyBackground = false,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width,
        height,
        borderRadius: "8px",
        backgroundColor: greyBackground
          ? (theme.palette.grey as Partial<Color>)[100]
          : (theme.palette.secondary as Partial<Color>)[100],
        ...sx,
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
};
