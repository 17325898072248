import { EffectPreset } from "generated/graphql";
import { EffectWidgetValue } from "../SchemaInterpretor/Field/Effect/Effect.decl";
import { defaultSeverityPreset } from "../SchemaInterpretor/Field/Severity/Severity.constants";

export const computeFieldChangeText = (
  oldValue: string,
  newValue: string,
  effectPreset?: EffectPreset
) => {
  if (newValue.indexOf("effectPresetId") >= 0) {
    try {
      // effect field changed
      const parsedOldValue = oldValue
        ? (JSON.parse(oldValue) as EffectWidgetValue)
        : null;
      const parsedNewValue = newValue
        ? (JSON.parse(newValue) as EffectWidgetValue)
        : null;

      if (!effectPreset || parsedNewValue?.effectPresetId !== effectPreset.id) {
        throw new Error("Could not display the effect field changes.");
      }

      const computedOldValue =
        parsedOldValue && parsedOldValue.answers.length
          ? parsedOldValue.answers
              .map(
                (oldAnswer) =>
                  `${
                    effectPreset!.options.items.find(
                      (option) => option.id === oldAnswer.optionId
                    )?.label
                  }, selected = ${oldAnswer.selected}${
                    oldAnswer.value
                      ? `, value = ${Number(oldAnswer.value).toLocaleString(
                          "en-US"
                        )}`
                      : ""
                  }`
              )
              .join(", ")
          : "All unselected.";

      const computedNewValue =
        parsedNewValue && parsedNewValue.answers.length
          ? parsedNewValue.answers.map(
              (newAnswer) =>
                `${
                  effectPreset!.options.items.find(
                    (option) => option.id === newAnswer.optionId
                  )?.label
                }, selected = ${newAnswer.selected}${
                  newAnswer.value
                    ? `, value = ${Number(newAnswer.value).toLocaleString(
                        "en-US"
                      )}`
                    : ""
                }`
            )
          : "All unselected.";

      return {
        oldValue: computedOldValue,
        newValue: computedNewValue,
      };
    } catch (error) {
      console.warn("Error happened while parsing Effect widget data.");
    }
  } else if (newValue.indexOf("consequence") >= 0) {
    // severity field changed
    try {
      const oldValueParsed = JSON.parse(oldValue) as {
        consequence: number;
        likelihood: number;
      };
      const newValueParsed = JSON.parse(newValue) as {
        consequence: number;
        likelihood: number;
      };
      return {
        oldValue: `consequence: ${
          defaultSeverityPreset.consequenceSets[oldValueParsed.consequence - 1]
        }, likelihood: ${
          defaultSeverityPreset.likelihoodSets[oldValueParsed.likelihood - 1]
        }`,
        newValue: `consequence: ${
          defaultSeverityPreset.consequenceSets[newValueParsed.consequence - 1]
        }, likelihood: ${
          defaultSeverityPreset.likelihoodSets[newValueParsed.likelihood - 1]
        }`,
      };
    } catch (error) {
      console.warn("False positive Severity widget data change.");
    }
  }

  return {
    oldValue,
    newValue,
  };
};
