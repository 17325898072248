import {
  Box,
  BoxProps,
  Dialog,
  DialogProps,
  PaperProps,
  Typography,
} from "@mui/material";
import { X } from "phosphor-react";

export type BasicModalProps = Omit<DialogProps, "title"> & {
  title?: React.ReactNode;
  titleNextToIcon?: boolean;
  headerIcon?: React.ReactNode;
  children?: React.ReactNode;
  contentProps?: BoxProps;
  paperProps?: PaperProps;
};

export const ModalTitle: React.FC<{
  title: React.ReactNode;
  hasHeaderIcon?: boolean;
}> = ({ title, hasHeaderIcon }) => {
  return typeof title === "string" ? (
    <Typography
      variant="h3"
      fontWeight={600}
      fontSize="20px"
      color="grey.800"
      ml={hasHeaderIcon ? 2 : 0}
    >
      {title}
    </Typography>
  ) : (
    <>{title}</>
  );
};

export const BasicModal: React.FC<BasicModalProps> = ({
  open,
  children,
  headerIcon,
  title,
  titleNextToIcon,
  contentProps,
  maxWidth = "xl",
  onClose,
  paperProps,
  ...restProps
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth={maxWidth}
    PaperProps={paperProps}
    {...restProps}
  >
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={3}
        pt={4}
      >
        {headerIcon}
        {titleNextToIcon && (
          <ModalTitle title={title} hasHeaderIcon={!!headerIcon} />
        )}
        <Box ml="auto" display="flex">
          <X
            size={24}
            cursor="pointer"
            onClick={() => {
              onClose?.({}, "escapeKeyDown");
            }}
          />
        </Box>
      </Box>
      {!titleNextToIcon && (
        <Box py={2} px={3}>
          <ModalTitle title={title} hasHeaderIcon={!!headerIcon} />
        </Box>
      )}
      {children && (
        <Box px={3} pt={2} pb={3} {...contentProps}>
          {children}
        </Box>
      )}
    </Box>
  </Dialog>
);
