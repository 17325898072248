import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Overlay } from "components/Overlay";
import {
  AddProjectInput,
  Company,
  Project,
  ProjectSector,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  projectDataValidators,
  ProjectSectorMapping,
} from "./ProjectForm.constants";

export type ProjectFormProps = {
  project?: Project;
  disabled?: boolean;
  companiesList: Company[];
  onChange?: (projectData: AddProjectInput | Project) => void;
};

// TODO unify for all projects; add reset function
export type ProjectFormPublicApi = {
  validate: () => boolean;
};

export const defaultProject: AddProjectInput = {
  description: "",
  friendlyName: "",
  name: "",
  number: "",
  sector: "" as ProjectSector,
  billingCompanyId: "",
};

export const ProjectForm = React.forwardRef(
  (
    { project, companiesList, disabled, onChange }: ProjectFormProps,
    ref: React.Ref<ProjectFormPublicApi>
  ) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<AddProjectInput | Project>(
      project || defaultProject
    );
    const [formDataErrors, setFormDataErrors] = useState<{
      [key: string]: string;
    }>({});

    const handleTextFieldChange: React.ChangeEventHandler<
      HTMLTextAreaElement | HTMLInputElement
    > = (evt) => {
      setFormData((curData) => ({
        ...curData,
        [evt.target.name]: evt.target.value,
      }));

      setFormDataErrors((curFormDataErrs) => {
        const { [evt.target.name]: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const validateForm = useCallback((formData: AddProjectInput | Project) => {
      const validationResult = validateData(formData, projectDataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);
      return false;
    }, []);

    const handleSectorChange = (
      event: SelectChangeEvent<ProjectSector | null>
    ) => {
      setFormData((curData) => ({
        ...curData,
        sector: event.target.value as ProjectSector,
      }));
    };

    const handleBillingCompanyChange = (event: SelectChangeEvent<string>) => {
      setFormData((curData) => ({
        ...curData,
        billingCompanyId: event.target.value,
      }));
    };

    useEffect(() => {
      onChange?.(formData);
    }, [formData, onChange]);

    useEffect(() => {
      setFormData(project || defaultProject);
    }, [project]);

    useImperativeHandle(
      ref,
      () => ({
        validate: () => validateForm(formData),
      }),
      [validateForm, formData]
    );

    return (
      <>
        {disabled && <Overlay withBackground />}
        <Grid container spacing={6}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.name")}
              variant="standard"
              error={!!formDataErrors.name}
              helperText={formDataErrors.name}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="friendlyName"
              value={formData.friendlyName}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.friendlyName")}
              variant="standard"
              error={!!formDataErrors.friendlyName}
              helperText={formDataErrors.friendlyName}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="number"
              value={formData.number}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.number")}
              variant="standard"
              error={!!formDataErrors.number}
              helperText={formDataErrors.number}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
              <>
                <InputLabel id="project-sector-select-label" shrink>
                  <Typography
                    color={formDataErrors.sector ? "error" : "inherit"}
                  >{`${t(
                    "AdminConsole.Projects.labels.sector"
                  )} *`}</Typography>
                </InputLabel>
                <Select
                  labelId="project-sector-select-label"
                  id="demo-simple-select-standard"
                  value={formData.sector}
                  onChange={handleSectorChange}
                  label={t("AdminConsole.Projects.labels.sector")}
                  error={!!formDataErrors.sector}
                  required
                >
                  {Object.entries(ProjectSectorMapping).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                {!!formDataErrors.sector && (
                  <Typography variant="caption" color="error" mt={0.5}>
                    {formDataErrors.sector}
                  </Typography>
                )}
              </>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
              <>
                <InputLabel id="project-billing-company-select-label" shrink>
                  <Typography
                    color={
                      formDataErrors.billingCompanyId ? "error" : "inherit"
                    }
                  >{`${t(
                    "AdminConsole.Projects.labels.billingCompany"
                  )} *`}</Typography>
                </InputLabel>
                <Select
                  labelId="project-billing-company-select-label"
                  id="billing-company-select"
                  value={formData.billingCompanyId}
                  onChange={handleBillingCompanyChange}
                  label={t("AdminConsole.Projects.labels.billingCompany")}
                  error={!!formDataErrors.billing}
                  required
                >
                  {companiesList.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.tradingName}
                    </MenuItem>
                  ))}
                </Select>
                {!!formDataErrors.billingCompanyId && (
                  <Typography variant="caption" color="error" mt={0.5}>
                    {formDataErrors.billingCompanyId}
                  </Typography>
                )}
              </>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              name="description"
              value={formData.description}
              onChange={handleTextFieldChange}
              type="text"
              multiline
              rows={5}
              label={t("common.labels.description")}
              variant="standard"
              error={!!formDataErrors.description}
              helperText={formDataErrors.description}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);
