import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationObjectionProposedVariationPromptQuery = gql`
  query variationObjectionProposedVariationPrompt(
    $input: VariationObjectionProposedVariationPromptInput!
  ) {
    variationObjectionProposedVariationPrompt(input: $input) {
      defaultText
    }
  }
`;

export const sendVariationObjectionProposedVariationMutation = gql`
  mutation sendVariationObjectionProposedVariation(
    $input: SendVariationObjectionProposedVariationInput!
  ) {
    sendVariationObjectionProposedVariation(input: $input) {
      id
    }
  }
`;

export const recordVariationObjectionProposedVariationMutation = gql`
  mutation recordVariationObjectionProposedVariation(
    $input: RecordVariationObjectionProposedVariationInput!
  ) {
    recordVariationObjectionProposedVariation(input: $input) {
      id
    }
  }
`;

export const generateSendVariationObjectionProposedVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationObjectionProposedVariationPreview(
    $input: SendVariationObjectionProposedVariationPreviewInput!
  ) {
    generateSendVariationObjectionProposedVariationPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationObjectionProposedVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationObjectionProposedVariationPreview(
    $input: RecordVariationObjectionProposedVariationPreviewInput!
  ) {
    generateRecordVariationObjectionProposedVariationPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
