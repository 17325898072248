import { ApolloCache, FetchResult } from "@apollo/client";
import { snackbarAutoHideDuration } from "constants/constants";
import {
  SendAuthorizationAcceptanceMutation,
  SendAuthorizationAcceptanceMutationVariables,
  SendAuthorizationDenialMutation,
  SendAuthorizationDenialMutationVariables,
} from "generated/graphql";
import { sendAuthorizationAcceptanceMutation } from "graphql/mutations/sendAuthorizationAcceptance";
import { sendAuthorizationDenialMutation } from "graphql/mutations/sendAuthorizationDenial";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useAuthStore } from "state-management/store/authStore";

export const useAuthorizeDenyActionModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const authModalState = useAuthStore((state) => state.authModalState);

  const onUpdate = (
    cache: ApolloCache<any>,
    _result:
      | Omit<FetchResult<SendAuthorizationAcceptanceMutation>, "context">
      | Omit<FetchResult<SendAuthorizationDenialMutation>, "context">
  ) => {
    const compEventId = authModalState?.compEventId;
    const claimId = authModalState?.claimId;
    const variationId = authModalState?.variationId;

    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "userPendingAuthorizationActions",
    });

    if (compEventId) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventItem",
        args: { id: compEventId },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItem",
        args: { id: compEventId },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItems",
        args: { compEventId },
      });
    }
    if (claimId) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimItem",
        args: { id: claimId },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimHistoryItem",
        args: { id: claimId },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimHistoryItems",
        args: { claimId },
      });
    }

    if (variationId) {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationItem",
        args: { id: variationId },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItem",
        args: { id: variationId },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId },
      });
    }
    authModalState?.onChange?.();
  };

  const [
    sendAuthorizationAcceptance,
    { loading: sendAuthorizationAcceptanceLoading },
  ] = useGraphMutation<
    SendAuthorizationAcceptanceMutation,
    SendAuthorizationAcceptanceMutationVariables
  >(
    sendAuthorizationAcceptanceMutation,
    {
      update: onUpdate,
    },
    null
  );

  const [sendAuthorizationDenial, { loading: sendAuthorizationDenialLoading }] =
    useGraphMutation<
      SendAuthorizationDenialMutation,
      SendAuthorizationDenialMutationVariables
    >(
      sendAuthorizationDenialMutation,
      {
        update: onUpdate,
      },
      null
    );

  const doAuthorize = useCallback(
    async (authAuditId: string, successMessage: string) => {
      const result = await sendAuthorizationAcceptance({
        variables: { authorizationWorkflowAuditId: authAuditId },
      });

      enqueueSnackbar(successMessage, {
        autoHideDuration: snackbarAutoHideDuration,
        variant: "success",
      });

      return result;
    },
    [sendAuthorizationAcceptance, enqueueSnackbar]
  );

  const doDeny = useCallback(
    async (
      authAuditId: string,
      denialReason: string,
      successMessage: string
    ) => {
      const result = await sendAuthorizationDenial({
        variables: { authorizationWorkflowAuditId: authAuditId, denialReason },
      });

      enqueueSnackbar(successMessage, {
        autoHideDuration: snackbarAutoHideDuration,
        variant: "success",
      });

      return result;
    },
    [sendAuthorizationDenial, enqueueSnackbar]
  );

  return {
    authorize: doAuthorize,
    deny: doDeny,
    loading:
      sendAuthorizationAcceptanceLoading || sendAuthorizationDenialLoading,
  };
};
