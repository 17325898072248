import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";
import { authorizationWorkflowAuditTrailFields } from "graphql/fragments/authorizationWorkflowAuditTrail.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const productItemAuthWorkflowAuditFields = gql`
  ${creatorFields}
  ${authorizationWorkflowActionMappingFields}
  ${authorizationWorkflowAuditTrailFields}
  ${authorizationWorkflowFields}

  fragment ProductItemAuthWorkflowAuditFields on AuthorizationWorkflowAudit {
    id
    initiatedById
    initiatedBy {
      ...CreatorFields
    }
    regardingProductItemId
    regardingProductType
    regardingItemHistoryId
    actionId
    action {
      ...AuthorizationWorkflowActionMappingFields
    }
    workflowId
    workflow {
      ...AuthorizationWorkflowFields
    }
    status
    dateCreated
    auditTrails {
      ...AuthorizationWorkflowAuditTrailFields
    }
  }
`;
