import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventGrantingOfMoreTimeMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventGrantingOfMoreTime(
    $input: SendCompEventGrantingOfMoreTimeInput!
  ) {
    sendCompEventGrantingOfMoreTime(input: $input) {
      ...CompEventItemFields
    }
  }
`;
