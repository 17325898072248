import { useCallback } from "react";
import { FileItemProps } from "../Attachments.decl";
import { isAttachment } from "../Attachments.utils";

export const useFileItem = ({
  file,
  onAddDescription,
  onDelete,
  onClick,
}: FileItemProps) => {
  const handleDescriptionChange = useCallback(
    (newDescription?: string) => {
      onAddDescription?.(file, newDescription ?? "");
    },
    [onAddDescription, file]
  );

  const handleDelete: React.MouseEventHandler<SVGSVGElement> = useCallback(
    (evt) => {
      evt.stopPropagation();
      onDelete?.();
    },
    [onDelete]
  );

  const handleListItemClick = useCallback(() => {
    const selection = window.getSelection()?.toString();

    if (
      !selection &&
      (isAttachment(file) || (!isAttachment(file) && !file.loading))
    ) {
      onClick();
    }
  }, [onClick, file]);

  return {
    handleDescriptionChange,
    handleDelete,
    handleListItemClick,
  };
};
