import { gql } from "@apollo/client";

export const productRoleActionsQuery = gql`
  query productRoleActions {
    productRoleActions {
      items {
        id
        name
        isInternal
        status
        dateCreated
        creatorId
        creator {
          id
          firstname
          surname
        }
      }
    }
  }
`;
