import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType, ListItem } from "generated/graphql";
import { NotifyCEClaimSummary } from "../../../ActionModal/NotifyCEClaimSummary";
import { SendAction } from "../components/SendAction";
import { useSendNotifyClaimAction } from "./useSendNotifyClaimAction";
import { useContext } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";

export type SendClaimNoticeProps = {
  claimType: ListItem;
  awGiven?: ListItem;
} & ActionsDialogProps;

export const SendClaimNotice: React.FC<SendClaimNoticeProps> = ({
  claimType,
  awGiven,
  ...restDialogProps
}) => {
  const { isFIDIC99RedYellow } = useContext(ClaimWidgetContext);
  const { claimPreview } = useSendNotifyClaimAction(claimType, awGiven);
  return (
    <SendAction
      claimPreview={claimPreview}
      actionType={ClaimActionType.NotifyClaim}
      summaryEl={
        <NotifyCEClaimSummary
          claimType={claimType.name}
          ewTitle={awGiven?.name}
          contextType="claim"
          isFIDIC99RedYellow={isFIDIC99RedYellow}
        />
      }
      {...restDialogProps}
    />
  );
};
