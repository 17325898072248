import { Box, Typography, css, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

export type LevelLabelProps = {
  levelNo: number;
};

const LevelLabelContainer = styled(Box)(
  ({ theme }) => css`
    border-left: 1px solid ${theme.palette.grey[300]};
    padding-left: ${theme.spacing(1.5)};
    height: 32px;
    margin-top: ${theme.spacing(4.5)};
    margin-left: ${theme.spacing(0.5)};
    display: flex;
    align-items: flex-end;
  `
);

export const LevelLabel: React.FC<LevelLabelProps> = ({ levelNo }) => {
  const { t } = useTranslation();

  return (
    <LevelLabelContainer>
      <Typography variant="caption3" color="grey.800" textTransform="uppercase">
        {t("Home.Authorizations.levelNo", { number: levelNo })}
      </Typography>
    </LevelLabelContainer>
  );
};
