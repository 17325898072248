import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationObjectionProposedVariationMutation,
  RecordVariationObjectionProposedVariationMutationVariables,
  SendVariationObjectionProposedVariationMutation,
  SendVariationObjectionProposedVariationMutationVariables,
  VariationObjectionProposedVariationPromptQuery,
  VariationObjectionProposedVariationPromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "types/decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationObjectionProposedVariationMutation,
  sendVariationObjectionProposedVariationMutation,
  variationObjectionProposedVariationPromptQuery,
} from "./ObjectionToProposedVariationAction.query";

export const useObjectionToProposedVariationAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationObjectionProposedVariationPromptQuery,
    VariationObjectionProposedVariationPromptQueryVariables
  >(variationObjectionProposedVariationPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationDraftVariationProposals",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalForProposedVariationPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [
    sendProposedVariationObjection,
    { loading: sendProposedVariationObjectionLoading },
  ] = useGraphMutation<
    SendVariationObjectionProposedVariationMutation,
    SendVariationObjectionProposedVariationMutationVariables
  >(
    sendVariationObjectionProposedVariationMutation,
    { update: onUpdated },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Variations.proposedVariationObjection"),
    })
  );

  const [
    recordProposedVariationObjection,
    { loading: recordProposedVariationObjectionLoading },
  ] = useGraphMutation<
    RecordVariationObjectionProposedVariationMutation,
    RecordVariationObjectionProposedVariationMutationVariables
  >(
    recordVariationObjectionProposedVariationMutation,
    { update: onUpdated },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.proposedVariationObjection"),
    })
  );

  return {
    defaultText:
      promptData?.variationObjectionProposedVariationPrompt.defaultText ?? "",
    loading,
    sendProposedVariationObjection,
    recordProposedVariationObjection,
    actionLoading:
      sendProposedVariationObjectionLoading ||
      recordProposedVariationObjectionLoading,
  };
};
