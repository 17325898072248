import { Autocomplete, SelectChangeEvent, TextField } from "@mui/material";
import {
  GridColDef,
  GridEditSingleSelectCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import {
  ProductRole,
  ProductUserRole,
  ProductUserRoleStatus,
  User,
} from "generated/graphql";
import { temporaryRowId } from "constants/constants";
import {
  getCommonActions,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useMemo } from "react";
import { getUserName, getUserNameOrEmail } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<ProductUserRoleStatus>[],
  rowModesModel: GridRowModesModel,
  productRoles: ProductRole[],
  users: User[],
  onStatusChange: (
    row: ProductUserRole,
    newStatus: ProductUserRoleStatus
  ) => void,
  onRoleChange: (row: ProductUserRole, newRoleId: string) => void,
  onUserChange: (row: ProductUserRole, newUserId: string) => void,
  handleSaveRow: (rowId: GridRowId) => void,
  handleDeleteRow: (rowId: GridRowId) => void
): GridColDef<ProductUserRole>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const productRoleOptions = productRoles.map((role) => ({
    value: role.id,
    label: role.name,
  }));
  const userOptions = users
    .map((user) => ({
      value: user.id,
      label: `${getUserNameOrEmail(user)} [${user.company.tradingName}]`,
    }))
    .sort((u1, u2) => u1.label.localeCompare(u2.label));
  const columns = useMemo(
    () =>
      [
        {
          field: "user",
          headerName: t("common.labels.user"),
          flex: 0.2,
          resizable: true,
          editable: true,
          valueFormatter: (value: User) => {
            const selectedOption = userOptions.find(
              (valueOption: { value: string; label: string }) => {
                return valueOption.value === value.id;
              }
            );

            return selectedOption?.label;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<any, ProductUserRole, any>
          ) => (
            <Autocomplete
              id="user-select"
              options={userOptions}
              sx={{ maxHeight: 200 }}
              getOptionLabel={(optionValue) => optionValue.label}
              value={cellParams.row.value}
              onChange={(_event: SyntheticEvent<Element, Event>, value) => {
                onUserChange(cellParams.row, value.value);
              }}
              getOptionKey={(option) => option.value}
              fullWidth
              disabled={cellParams.id !== temporaryRowId}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!cellParams.row.productRoleId}
                  required
                  variant="outlined"
                />
              )}
            />
          ),
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          width: 110,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, ProductUserRole, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                onChange={(newStatus: StatusOption<ProductUserRoleStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "jobTitle",
          headerName: t("Register.labels.jobTitle"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => {
            return row.user?.jobTitle ?? "";
          },
        },
        {
          field: "company",
          headerName: t("common.labels.company"),
          flex: 0.125,
          resizable: true,
          valueGetter: (_, row) => {
            return row.user?.company?.registeredName ?? "";
          },
        },
        {
          field: "productRole",
          headerName: t("common.labels.role"),
          flex: 0.075,
          resizable: true,
          editable: true,
          sortable: false,
          type: "singleSelect",
          valueOptions: productRoleOptions,
          valueFormatter: (value: ProductRole) => {
            const selectedOption = productRoleOptions.find(
              (valueOption: { value: string; label: string }) =>
                valueOption.value === value.id
            );

            return selectedOption?.label;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<any, ProductUserRole, any>
          ) => (
            <GridEditSingleSelectCell
              {...cellParams}
              error={!cellParams.row.productRoleId}
              onValueChange={(event: SelectChangeEvent<any>) => {
                onRoleChange(cellParams.row, event.target.value);
              }}
              initialOpen={false}
            />
          ),
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.075,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          sortable: false,
          getActions: (cellParams) =>
            getCommonActions(
              cellParams,
              rowModesModel,
              handleSaveRow,
              handleDeleteRow
            ),
        },
      ] as GridColDef<ProductUserRole>[],
    [
      t,
      dateCreatedColConfig,
      statusOptions,
      rowModesModel,
      productRoleOptions,
      userOptions,
      onStatusChange,
      onRoleChange,
      onUserChange,
      handleSaveRow,
      handleDeleteRow,
    ]
  );
  return columns;
};
