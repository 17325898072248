import {
  AddNewClaimTypeOverrideInput,
  AddNewClaimTypeOverrideMutation,
  AddNewClaimTypeOverrideMutationVariables,
  ChangeNewClaimTypeOverrideStatusMutation,
  ChangeNewClaimTypeOverrideStatusMutationVariables,
  ClaimType,
  ClaimTypesQuery,
  ClaimTypesQueryVariables,
  ClaimTypeStatus,
  DisableClaimTypeMutation,
  DisableClaimTypeMutationVariables,
  DisabledClaimTypesOverrideQuery,
  DisabledClaimTypesOverrideQueryVariables,
  EnableClaimTypeMutation,
  EnableClaimTypeMutationVariables,
  NewClaimTypeOverride,
  NewClaimTypeOverrideStatus,
  NewClaimTypesOverrideQuery,
  NewClaimTypesOverrideQueryVariables,
} from "generated/graphql";
import { addNewClaimTypeOverrideMutation } from "graphql/mutations/addClaimTypeOverride";
import { changeNewClaimTypeOverrideStatusMutation } from "graphql/mutations/changeNewClaimTypeOverrideStatus";
import { disableClaimTypeMutation } from "graphql/mutations/disableClaimType";
import { enableClaimTypeMutation } from "graphql/mutations/enableClaimType";
import { claimTypesQuery } from "graphql/queries/contractTypeClaimTypes.query";
import { disabledClaimTypesOverrideQuery } from "graphql/queries/disabledContractTypeClaimTypes.query";
import { newClaimTypesOverrideQuery } from "graphql/queries/newContractClaimTypesOverride.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export type useClaimTypesArgs = {
  contractTypeId?: string;
  contractId: string;
};

export type useClaimTypesReturnType = {
  contractTypeClaimTypes: ClaimType[];
  additionalClaimTypes: NewClaimTypeOverride[];
  claimTypesLoading: boolean;
  additionalClaimTypesLoading: boolean;
  addClaimType: (
    newClaimType: AddNewClaimTypeOverrideInput
  ) => Promise<boolean>;
  changeNewClaimTypeStatus: (
    id: string,
    status: NewClaimTypeOverrideStatus
  ) => Promise<boolean>;
  changeClaimTypeStatus: (
    claimTypeId: string,
    contractId: string,
    status: ClaimTypeStatus
  ) => Promise<boolean>;
};

export const useClaimTypes = ({
  contractTypeId,
  contractId,
}: useClaimTypesArgs): useClaimTypesReturnType => {
  const { t } = useTranslation();

  const {
    data: contractTypeClaimTypes,
    refetch: refetchContractTypeClaimTypes,
    loading: contractTypeClaimTypesLoading,
  } = useGraphQuery<ClaimTypesQuery, ClaimTypesQueryVariables>(
    claimTypesQuery,
    {
      skip: !contractTypeId,
      variables: { contractTypeId: contractTypeId! },
    }
  );

  const {
    data: disabledClaimTypesOverride,
    refetch: refetchDisabledClaimTypesOverride,
    loading: disabledClaimTypesOverrideLoading,
  } = useGraphQuery<
    DisabledClaimTypesOverrideQuery,
    DisabledClaimTypesOverrideQueryVariables
  >(disabledClaimTypesOverrideQuery, {
    variables: { contractId },
  });

  const {
    data: newClaimTypesOverride,
    refetch: refetchNewClaimTypesOverride,
    loading: newClaimTypesOverrideLoading,
  } = useGraphQuery<
    NewClaimTypesOverrideQuery,
    NewClaimTypesOverrideQueryVariables
  >(newClaimTypesOverrideQuery, {
    variables: { contractId },
  });

  const [addNewClaimType, { loading: addNewClaimTypeLoading }] =
    useGraphMutation<
      AddNewClaimTypeOverrideMutation,
      AddNewClaimTypeOverrideMutationVariables
    >(
      addNewClaimTypeOverrideMutation,
      {
        update: () => refetchNewClaimTypesOverride(),
      },
      t("common.successMessages.entityCreated", {
        entity: t("AdminConsole.ClaimTypes.claimType"),
      })
    );

  const [
    changeNewClaimTypeStatus,
    { loading: changeNewClaimTypeStatusLoading },
  ] = useGraphMutation<
    ChangeNewClaimTypeOverrideStatusMutation,
    ChangeNewClaimTypeOverrideStatusMutationVariables
  >(
    changeNewClaimTypeOverrideStatusMutation,
    {
      update: () => refetchNewClaimTypesOverride(),
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.ClaimTypes.claimType"),
    })
  );

  const [enableClaimType, { loading: enableClaimTypeLoading }] =
    useGraphMutation<EnableClaimTypeMutation, EnableClaimTypeMutationVariables>(
      enableClaimTypeMutation,
      {
        update: () => {
          refetchContractTypeClaimTypes();
          refetchDisabledClaimTypesOverride();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("AdminConsole.ClaimTypes.claimType"),
      })
    );
  const [disableClaimType, { loading: disableClaimTypeLoading }] =
    useGraphMutation<
      DisableClaimTypeMutation,
      DisableClaimTypeMutationVariables
    >(
      disableClaimTypeMutation,
      {
        update: () => {
          refetchContractTypeClaimTypes();
          refetchDisabledClaimTypesOverride();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("AdminConsole.ClaimTypes.claimType"),
      })
    );

  const handleContractTypeClaimTypeStatusChange = useCallback(
    async (
      claimTypeId: string,
      contractId: string,
      status: ClaimTypeStatus
    ) => {
      if (status === ClaimTypeStatus.Active) {
        // the user is reenabling a disabled claimType
        const disabledClaimType =
          disabledClaimTypesOverride?.disabledClaimTypesOverride.items.find(
            (crtDisabledClaimType) =>
              crtDisabledClaimType.claimTypeId === claimTypeId
          );

        const { data } = await enableClaimType({
          variables: { disabledClaimTypeId: disabledClaimType!.id },
        });

        return !!data;
      }

      // the user is disabling a valid enabled claimType
      const { data } = await disableClaimType({
        variables: { contractId, claimTypeId },
      });

      return !!data;
    },
    [enableClaimType, disableClaimType, disabledClaimTypesOverride]
  );

  const handleAddNewClaimType = useCallback(
    async (newClaimType: AddNewClaimTypeOverrideInput) => {
      const { data } = await addNewClaimType({
        variables: { input: newClaimType },
      });

      return !!data;
    },
    [addNewClaimType]
  );

  const handleNewClaimTypeStatusChange = useCallback(
    async (id: string, status: NewClaimTypeOverrideStatus) => {
      const { data } = await changeNewClaimTypeStatus({
        variables: {
          id,
          status,
        },
      });

      return !!data;
    },
    [changeNewClaimTypeStatus]
  );

  /**
   * Make claimTypes reflect the right status based on the precence of disabledClaimTypes.
   */
  const computedClaimTypes = useMemo(() => {
    const disabledClaimTypeIds =
      disabledClaimTypesOverride?.disabledClaimTypesOverride.items.map(
        (crtDisabledCT) => crtDisabledCT.claimTypeId
      );

    return (contractTypeClaimTypes?.claimTypes.items ?? []).map(
      (crtClaimType) =>
        disabledClaimTypeIds &&
        disabledClaimTypeIds.indexOf(crtClaimType.id) >= 0
          ? {
              ...crtClaimType,
              status: ClaimTypeStatus.Removed,
            }
          : crtClaimType
    ) as ClaimType[];
  }, [disabledClaimTypesOverride, contractTypeClaimTypes]);

  return {
    claimTypesLoading:
      contractTypeClaimTypesLoading ||
      disabledClaimTypesOverrideLoading ||
      enableClaimTypeLoading ||
      disableClaimTypeLoading,
    additionalClaimTypesLoading:
      newClaimTypesOverrideLoading ||
      addNewClaimTypeLoading ||
      changeNewClaimTypeStatusLoading,
    contractTypeClaimTypes: computedClaimTypes,
    additionalClaimTypes:
      (newClaimTypesOverride?.newClaimTypesOverride
        .items as NewClaimTypeOverride[]) ?? [],
    addClaimType: handleAddNewClaimType,
    changeNewClaimTypeStatus: handleNewClaimTypeStatusChange,
    changeClaimTypeStatus: handleContractTypeClaimTypeStatusChange,
  };
};
