import { Box, Typography } from "@mui/material";
import {
  defaultSeverityIndex,
  SeverityColors,
  SeverityColorsMapping,
} from "./Severity.constants";
import { computeSeverityRating } from "./Severity.utils";
import { SeverityPickerItemIcon } from "./SeverityPickerItemIcon";
import { SeverityReadOnlyProps } from "./Severity.decl";

export const SeverityPreviewExtraSmall: React.FC<SeverityReadOnlyProps> = ({
  severityPreset,
  consequenceValue,
  likelihoodValue,
}) => {
  const severityRatingValue =
    consequenceValue && likelihoodValue
      ? consequenceValue * likelihoodValue
      : undefined;
  const severityRating = computeSeverityRating(
    severityPreset,
    consequenceValue,
    likelihoodValue
  );

  return (
    <Box display="flex" alignItems="center">
      <SeverityPickerItemIcon
        itemSet={severityPreset.likelihoodSets}
        value={
          severityRatingValue
            ? severityPreset.ratings.items.indexOf(severityRating) + 1
            : defaultSeverityIndex
        }
        height={16}
        width={16}
        step={2.5}
        highContrast={true}
        filledColor={{
          highContrastColor:
            SeverityColorsMapping[severityRating.colour as SeverityColors]
              .filledColor,
          lowContrastColor: "",
        }}
        unfilledColor={{
          highContrastColor:
            SeverityColorsMapping[severityRating.colour as SeverityColors]
              .unfilledColor,
          lowContrastColor: "",
        }}
      />
      <Typography
        variant="p3"
        fontWeight={700}
        ml={1}
        color={
          SeverityColorsMapping[severityRating.colour as SeverityColors]
            .filledColor
        }
      >
        {severityRating.name}
      </Typography>
    </Box>
  );
};
