import {
  DailyDiaryDelayRecord,
  EditDailyDiaryDelayRecordInput,
} from "generated/graphql";
import { hoursToDate } from "../WeatherSection/WeatherSection.utils";

export const delayRecordToEditInput = (
  record: DailyDiaryDelayRecord
): EditDailyDiaryDelayRecordInput => {
  const {
    __typename: _,
    creator: __,
    creatorId: ___,
    dateCreated: ____,
    dateModified: _____,
    attachments: ______,
    areaLookupId,
    teamLookupId,
    shiftLookupId,
    delayTypeLookupId,
    disciplineLookupId,
    duration,
    ...rest
  } = record;

  return {
    ...rest,
    duration: duration ? hoursToDate(duration) : null,
    attachments: [], // TODO
  };
};
