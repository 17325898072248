import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { Product, ProductStatus } from "generated/graphql";
import { statusComparatorFunction } from "helpers/dataGrid.helpers";
import { noop } from "helpers/miscelaneous";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useColumns = (
  statusOptions: StatusOption<ProductStatus>[]
): GridColDef<Product>[] => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("common.labels.name"),
        flex: 0.15,
        minWidth: 190,
        resizable: true,
      },
      {
        field: "status",
        headerName: t("common.labels.status"),
        flex: 0.125,
        minWidth: 90,
        resizable: true,
        sortComparator: statusComparatorFunction,
        renderCell: (params: GridRenderCellParams<any, Product, any>) => {
          return (
            <StatusTag
              status={params.row.status}
              options={statusOptions}
              disabled
              onChange={noop}
            />
          );
        },
      },
    ],
    [statusOptions, t]
  );

  return columns;
};
