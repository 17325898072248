import { Avatar, Box, Stack, Typography, css, styled } from "@mui/material";
import { OverflowTooltip } from "components/OverflowTooltip";
import { AuthorizationActionStatus, User } from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import { useTranslation } from "react-i18next";
import { computeAvatarInitials } from "./AuthorizationProgress.utils";

export type ApproverListItemProps = {
  user: User;
  status?: AuthorizationActionStatus;
};

const StyledAvatar = styled(Avatar)(
  ({ theme }) => css`
    width: 38px;
    height: 38px;
    border: 1px solid ${theme.palette.grey[200]};
  `
);

export const ApproverListItem: React.FC<ApproverListItemProps> = ({
  user,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <StyledAvatar
        key={user.id}
        src={user.profilePicture ?? undefined}
        alt={getUserName(user)}
        sx={{
          background: user.profilePicture ? "white" : "orange",
        }}
      >
        {computeAvatarInitials(user)}
      </StyledAvatar>
      <Stack spacing={0.25} ml={1}>
        <OverflowTooltip
          title={getUserName(user)}
          typographyProps={{
            variant: "p2",
            color: "grey.800",
            maxWidth: "170px",
          }}
        />
        <Typography variant="p3" color="grey.600">
          {!status || status === AuthorizationActionStatus.Pending
            ? "-"
            : t(
                `Home.Authorizations.status.${
                  status === AuthorizationActionStatus.Approved
                    ? "Authorized"
                    : "Denied"
                }`
              )}
        </Typography>
      </Stack>
    </Box>
  );
};
