export interface DynamicConfig {
  REACT_APP_AUTH_BASE_URL: string;
  REACT_APP_GRAPHQL_URL: string;
  REACT_APP_API_KEY: string;
  REACT_APP_INTERCOM_APP_ID: string;
  REACT_APP_VERSION: string;
}

export const defaultConfig: DynamicConfig = {
  REACT_APP_AUTH_BASE_URL: "",
  REACT_APP_GRAPHQL_URL: "",
  REACT_APP_API_KEY: "",
  REACT_APP_INTERCOM_APP_ID: "",
  REACT_APP_VERSION: "",
};

export const dynamicConfigUrl = "/config.json";
