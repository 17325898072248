import { gql } from "@apollo/client";

const changelogFields = gql`
  fragment ChangelogFields on ChangeLogItemList {
    items {
      id
      itemId
      details {
        __typename
        title
        ... on AttachmentAddedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on AttachmentDescriptionAddedDetails {
          attachmentChanges {
            fileName
            oldDescription
            newDescription
          }
        }
        ... on AttachmentDescriptionEditedDetails {
          attachmentChanges {
            fileName
            oldDescription
            newDescription
          }
        }
        ... on AttachmentRemovedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CommentRepliedDetails {
          content
        }
        ... on CompEventConfirmationRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventConfirmationSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventNoticeRecordedDetails {
          attachments {
            fileName
            description
          }
          notifiedByParty
        }
        ... on CompEventNoticeRequestQuotationRecordedDetails {
          attachments {
            fileName
            description
          }
          notifiedByParty
        }
        ... on CompEventNoticeRequestQuotationSentDetails {
          attachments {
            fileName
            description
          }
          notifiedByParty
        }
        ... on CompEventNoticeSentDetails {
          attachments {
            fileName
            description
          }
          notifiedByParty
        }
        ... on CompEventOwnAssessmentNoticeRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventOwnAssessmentNoticeSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventOwnAssessmentRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventOwnAssessmentSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventQuotationAcceptanceRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventQuotationAcceptanceSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventQuotationRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventQuotationRevisionRequestRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventQuotationRevisionRequestSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventQuotationSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventRejectionRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on CompEventRejectionSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on EventAddedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on EventEditedDetails {
          fieldChanges {
            fieldName
            oldValue
            newValue
          }
        }
        ... on EventOwnerChangedDetails {
          oldOwner
          newOwner
        }
        ... on EventStatusChangedDetails {
          oldStatus
          newStatus
          reason
          details
        }
        ... on RiskEditedDetails {
          fieldChanges {
            fieldName
            oldValue
            newValue
          }
        }
        ... on EwEditedDetails {
          fieldChanges {
            fieldName
            oldValue
            newValue
          }
        }
        ... on EwOwnerChangedDetails {
          oldOwner
          newOwner
        }
        ... on EwRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on EwSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on EwStatusChangedDetails {
          oldStatus
          newStatus
          reason
          details
        }
        ... on InstructionEditedDetails {
          fieldChanges {
            fieldName
            oldValue
            newValue
          }
        }
        ... on InstructionOwnerChangedDetails {
          oldOwner
          newOwner
        }
        ... on InstructionRecordedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on InstructionSentDetails {
          attachments {
            fileName
            description
          }
        }
        ... on InstructionStatusChangedDetails {
          oldStatus
          newStatus
          reason
          details
        }
        ... on NewCommentAddedDetails {
          content
        }
        ... on RiskAddedDetails {
          attachments {
            fileName
            description
          }
        }
        ... on RiskEditedDetails {
          fieldChanges {
            fieldName
            oldValue
            newValue
          }
        }
        ... on RiskOwnerChangedDetails {
          oldOwner
          newOwner
        }
        ... on RiskStatusChangedDetails {
          oldStatus
          newStatus
          reason
          details
        }
        ... on UserMentionedDetails {
          content
        }
      }
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;

export const riskItemChangelogQuery = gql`
  ${changelogFields}

  query riskItemChangelog($id: ID!) {
    riskItem(id: $id) {
      id
      changeLog {
        ...ChangelogFields
      }
    }
  }
`;

export const earlyWarningItemChangelogQuery = gql`
  ${changelogFields}

  query earlyWarningItemChangelog($id: ID!) {
    earlyWarningItem(id: $id) {
      id
      changeLog {
        ...ChangelogFields
      }
    }
  }
`;

export const eventItemChangelogQuery = gql`
  ${changelogFields}

  query eventItemChangelog($id: ID!) {
    eventItem(id: $id) {
      id
      changeLog {
        ...ChangelogFields
      }
    }
  }
`;

export const instructionItemChangelogQuery = gql`
  ${changelogFields}

  query instructionItemChangelog($id: ID!) {
    instructionItem(id: $id) {
      id
      changeLog {
        ...ChangelogFields
      }
    }
  }
`;
