import {
  DailyDiaryItem,
  DailyDiaryManpowerImportSourceItemsQuery,
  DailyDiaryManpowerImportSourceItemsQueryVariables,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { dailyDiaryManpowerImportSourceItemsQuery } from "./ManpowerSection.query";

export const useAvailableDiariesToImportFrom = () => {
  const [
    fetchAvailableDiaries,
    { data: availableDiariesToImportFrom, loading },
  ] = useGraphLazyQueryLite<
    DailyDiaryManpowerImportSourceItemsQuery,
    DailyDiaryManpowerImportSourceItemsQueryVariables
  >(dailyDiaryManpowerImportSourceItemsQuery);

  return {
    availableDiariesToImportFrom: (availableDiariesToImportFrom?.data
      .dailyDiaryManpowerImportSourceItems.items ?? []) as DailyDiaryItem[],
    availableDiariesToImportFromLoading: loading,
    fetchAvailableDiaries,
  };
};
