import { gql } from "@apollo/client";
import { contractLiteFields } from "./contractLite.fragment";
import { creatorFields } from "./creator.fragment";
import { productLiteFields } from "./productLite.fragment";

export const productInstanceLiteFields = gql`
  ${creatorFields}
  ${productLiteFields}
  ${contractLiteFields}

  fragment ProductInstanceLiteFields on ProductInstance {
    id
    description
    numberingFormat
    soloModeSupported
    contractId
    productId
    productSchemaId
    statusCollectionId
    status
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
    #roles: ProductUserRoleList!
    contract {
      ...ContractLiteFields
    }
    product {
      ...ProductLiteFields
    }
    #productSchema: ProductSchema!
    statusCollection {
      id
      name
      isDefault
      status
    }
    #extraConfig: ExtraConfigType
  }
`;
