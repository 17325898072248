import { gql } from "@apollo/client";
import { contractBindingLiteFields } from "graphql/fragments/contractBindingLite.fragment";

export const inviteUserMutation = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      success
    }
  }
`;

export const companyProductInstancesQuery = gql`
  ${contractBindingLiteFields}

  query companyProductInstances($id: ID!) {
    company(id: $id) {
      id
      registeredName
      tradingName
      contractBindings {
        items {
          ...ContractBindingLiteFields
        }
      }
      projects {
        items {
          id
          name
          friendlyName
          status
          contracts {
            items {
              id
              name
              friendlyName
              status
              productInstances {
                items {
                  id
                  description
                  status
                  product {
                    id
                    name
                    status
                    roles {
                      items {
                        id
                        name
                        status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
