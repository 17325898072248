import { Box, Tab, Tabs, Typography } from "@mui/material";
import { UserProfileButton } from "components/UserProfileButton/UserProfileButton";
import { useCallback, useMemo, useContext, MouseEventHandler } from "react";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { NewAppPaths } from "../../helpers/paths/paths";
import {
  MainNavigationContainer,
  MainNavigationInnerContainer,
} from "./MainNavigation.styled";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { AuthContext } from "providers/auth.context";
import { BasicContextMenu } from "components/BasicContextMenu/BasicContextMenu";
import { Notifications } from "./Notifications/Notifications";
import { useApolloClient } from "@apollo/client";
import { getUserName } from "helpers/miscelaneous";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ListItemsDivider } from "components/ListItemsDivider";
import { ReactComponent as CCOMLogo } from "../../assets/ccom-logo.svg";
import { GlobalAuthorizationsModals } from "./components/GlobalAuthorizationsModals";
import { GlobalLoading } from "./components/GlobalLoading";

enum MenuItem {
  Profile = "profile",
  Logout = "logout",
}

const contextMenuItems = [
  {
    id: MenuItem.Profile,
    label: "common.labels.profile",
  },
  {
    id: MenuItem.Logout,
    label: "common.buttons.logout",
  },
];

export const MainNavigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    authenticatedUser,
    loading,
    isAdmin,
    reset: resetGlobalContext,
  } = useContext(GlobalContext);
  const { logout } = useContext(AuthContext);
  const apolloClient = useApolloClient();

  const handleProfileNavigation = useCallback(() => {
    navigate(NewAppPaths.authorized.Settings.children.ProfileEdit);
  }, [navigate]);

  const handleLogoClick = () => {
    navigate(NewAppPaths.authorized.Home);
  };

  const isProjectsSubpathMath = useMemo(
    () =>
      [
        ...Object.values(NewAppPaths.authorized.Projects.children),
        NewAppPaths.authorized.Projects,
      ] // TODO: remove when HomePage won't be Projects page anymore
        .map((path) => !!matchPath(path, location.pathname))
        .reduce((acc, crtVal) => acc || crtVal),
    [location]
  );

  const selectedTab = useMemo(() => {
    if (isProjectsSubpathMath) {
      return NewAppPaths.authorized.Projects.path;
    } else if (location.pathname === NewAppPaths.authorized.Home) {
      return NewAppPaths.authorized.Home;
    }
    // TODO: add it back when we add reporting
    //  else if (location.pathname === NewAppPaths.authorized.Reporting) {
    //   return NewAppPaths.authorized.Reporting;
    // }
    else if (
      location.pathname.indexOf(NewAppPaths.authorized.AdminConsole.path) >= 0
    ) {
      return NewAppPaths.authorized.AdminConsole.path;
    } else {
      return false;
    }
  }, [location.pathname, isProjectsSubpathMath]);

  const handleProjectsNavigate: MouseEventHandler<HTMLAnchorElement> = (
    evt
  ) => {
    if (matchPath(NewAppPaths.authorized.Projects.path, location.pathname)) {
      // if user is already on Projects path
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  const handleMenuItemSelect = (itemId: string) => {
    const localItemId = itemId as MenuItem;

    if (localItemId === MenuItem.Logout) {
      logout();
      resetGlobalContext(); // TODO: ideally it should happen automatically within the logout process; Think of a better solution
      apolloClient.clearStore(); // TODO: ideally it should happen automatically within the logout process; Think of a better solution
    } else if (localItemId === MenuItem.Profile) {
      handleProfileNavigation();
    }
  };

  return (
    <>
      <MainNavigationContainer>
        <GlobalAuthorizationsModals />
        <GlobalLoading />
        <MainNavigationInnerContainer direction="row">
          <Box
            mr={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CCOMLogo
              width={100}
              onClick={handleLogoClick}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box
            maxWidth="500px"
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            height="60px"
          >
            {/* height = the height of the MainNavigation. Sometimes the NavigationContainer doesn't apply correctly height=70px, thus, we're forcing it from the children */}
            <Tabs value={selectedTab} aria-label="navigation tabs">
              <Tab
                label={t("MainNavigation.labels.home")}
                value={NewAppPaths.authorized.Home}
                to={NewAppPaths.authorized.Home}
                id="home-tab"
                component={Link}
                sx={{ textTransform: "none", height: "60px" }}
              />
              <Tab
                label={t("MainNavigation.labels.projects")}
                value={NewAppPaths.authorized.Projects.path}
                to={NewAppPaths.authorized.Projects.path}
                id="projects-tab"
                onClick={handleProjectsNavigate}
                component={Link}
                sx={{ textTransform: "none", height: "60px" }}
              />
              {/* TODO: add it back when needed */}
              {/* <Tab
            id="reporting-tab"
            label={t("MainNavigation.labels.reporting")}
            value={NewAppPaths.authorized.Reporting}
            to={NewAppPaths.authorized.Reporting}
            component={Link}
            sx={{ textTransform: "none", height: "60px" }}
          /> */}
              {isAdmin === true && (
                <Tab
                  id="admin-console-tab"
                  label={t("MainNavigation.labels.adminConsole")}
                  value={NewAppPaths.authorized.AdminConsole.path}
                  to={NewAppPaths.authorized.AdminConsole.path}
                  component={Link}
                  sx={{ textTransform: "none", height: "60px" }}
                />
              )}
            </Tabs>
          </Box>
          <Box ml="auto" display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr={4}>
              <Notifications />
            </Box>
            <Box display="flex" alignItems="center">
              <UserProfileButton
                width="40px"
                height="40px"
                url={authenticatedUser?.profilePicture ?? undefined}
              />
              <BasicContextMenu
                menuHeader={
                  <span>
                    {loading ? (
                      <CenteredLoadingIndicator size={18} />
                    ) : (
                      <>
                        <Typography variant="p2" px={2} color="grey.500">
                          {getUserName(authenticatedUser)}
                        </Typography>
                      </>
                    )}
                    <ListItemsDivider sx={{ mt: 1, mb: 0 }} />
                  </span>
                }
                items={contextMenuItems}
                onItemSelect={handleMenuItemSelect}
                triggerCaption=""
                menuItemMinWidth="125px"
              />
            </Box>
          </Box>
        </MainNavigationInnerContainer>
      </MainNavigationContainer>
    </>
  );
};
