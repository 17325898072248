import { Box, Dialog, DialogProps, Typography } from "@mui/material";
import { XIcon } from "components/Icons/XIcon";
import { ItemTag } from "components/ItemTag";
import {
  ClaimHistoryItem,
  CompEventHistoryItem,
  Contract,
  ProductType,
  VariationHistoryItem,
} from "generated/graphql";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HistoryList } from "./HistoryList";

export type HistoryModalProps = Omit<DialogProps, "title"> & {
  isFIDIC17White?: boolean;
  isFIDIC99RedYellow?: boolean;
  productType:
    | ProductType.CompEvents
    | ProductType.Claims
    | ProductType.Variations;
  title: string;
  historyItems:
    | CompEventHistoryItem[]
    | ClaimHistoryItem[]
    | VariationHistoryItem[];
  contract: Contract;
  renderHistoryItemDetails: (
    historyItem: CompEventHistoryItem | ClaimHistoryItem | VariationHistoryItem
  ) => JSX.Element;
};

export const HistoryModal: React.FC<HistoryModalProps> = ({
  isFIDIC17White,
  isFIDIC99RedYellow,
  title,
  productType,
  historyItems,
  contract,
  renderHistoryItemDetails,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [selectedHistoryItemId, setSelectedHistoryItemId] = useState<string>(
    historyItems[0].id
  );

  const selectedHistoryItem = useMemo(
    () =>
      (historyItems as []).find(
        (
          historyItem:
            | CompEventHistoryItem
            | ClaimHistoryItem
            | VariationHistoryItem
        ) => historyItem.id === selectedHistoryItemId
      )!,
    [historyItems, selectedHistoryItemId]
  );

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
      {...restProps}
    >
      <Box
        sx={{ height: "calc(100vh - 64px)" }} // TODO: why this calc
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Box
          py={4}
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <ItemTag type={productType} />
            <Typography variant="h3" color="grey.800" fontWeight={400} ml={1.5}>
              {title}
            </Typography>
            <Typography variant="h3" color="grey.800" fontWeight={600} ml={1}>
              {t("common.labels.history")}
            </Typography>
          </Box>
          <Box display="flex" sx={{ cursor: "pointer" }}>
            <XIcon
              onClick={() => {
                onClose?.({}, "backdropClick");
              }}
            />
          </Box>
        </Box>
        <Box
          p={3}
          pt={0}
          display="flex"
          height="100%"
          flex={1}
          overflow="hidden"
        >
          <Box sx={{ overflowY: "auto" }} maxHeight="100%" mr={2}>
            <HistoryList
              historyItems={historyItems}
              selectedHistoryItemId={selectedHistoryItemId}
              onSelectedItemChange={setSelectedHistoryItemId}
              productType={productType}
              contract={contract}
              isFIDIC17White={isFIDIC17White}
              isFIDIC99RedYellow={isFIDIC99RedYellow}
            />
          </Box>
          <Box flex={1} overflow="auto">
            {renderHistoryItemDetails(selectedHistoryItem)}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
