import { create } from "zustand";

type AuthModalState = {
  authAuditId: string;
  type: "AuthorizeDeny" | "AuditTrail" | "Any";
  bypassUserAuthorizer?: boolean; // no matter if user is authorizer or not, at least AuditTrailModal will be displayed
  // usefull when invalidating queries after Authorize/Deny action
  compEventId?: string;
  claimId?: string;
  variationId?: string;
  onChange?: () => void; // TODO: to be replaced with event/Instruction ID and invalidate that query & refetch
};

type AuthState = {
  authModalState?: AuthModalState | null;
  setAuthModalState: (authModalState?: AuthModalState | null) => void;
};

export const useAuthStore = create<AuthState>()((set) => ({
  authModalState: undefined,
  setAuthModalState: (authModalState?: AuthModalState | null) =>
    set(() => ({ authModalState })),
}));
