import {
  MarkAllNotificationsAsReadMutation,
  MarkAllNotificationsAsReadMutationVariables,
  MarkNotificationMutation,
  MarkNotificationMutationVariables,
  NewNotificationsQuery,
  NewNotificationsQueryVariables,
  Notification,
  NotificationsQuery,
  NotificationsQueryVariables,
  NotificationStatus,
  UnreadNotificationsQuery,
  UnreadNotificationsQueryVariables,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  markAllNotificationsAsReadMutation,
  markNotificationMutation,
  newNotificationsQuery,
  notificationsQuery,
  unreadNotificationsQuery,
} from "./Notifications.query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export type useNotificationsReturnType = {
  notifications: Notification[];
  loading?: boolean;
  unreadNotificationsCount?: number;
  hasMore: boolean;
  newUnloadedNotifications?: number;
  error: boolean;
  loadMore: () => void;
  startFresh: () => void;
  stopListening: () => void;
  markNotification: (
    notificationId: string,
    action: NotificationStatus
  ) => void;
  markAllNotificationsAsRead: () => void;
};

const limit = 10;
const checkForUnreadNotificationsDelay = 1000 * 30;
const checkForNewNotificationsDelay = 1000 * 10;

export const useNotifications = (): useNotificationsReturnType => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const continuationToken = useRef("");
  const oldFirstPageNotifications = useRef<Notification[]>([]);
  const loadingNewerNotifications = useRef<boolean>(false);
  const [localNotifications, setLocalNotifications] = useState<Notification[]>(
    []
  );
  const [newestNotification, setNewestNotification] = useState<Notification>();
  const [error, setError] = useState(false);

  const {
    data: unreadNotifications,
    refetch: refetchNoOfUnreadNotifications,
    startPolling: startPollingUnreadNotificationsCount,
    stopPolling: stopPollingUnreadNotificationsCount,
  } = useGraphQuery<
    UnreadNotificationsQuery,
    UnreadNotificationsQueryVariables
  >(unreadNotificationsQuery);

  const {
    data: newNotificationsToLoad,
    previousData: oldNewNotificationsToLoad,
    startPolling: startPollingNewNotificationsToLoad,
    stopPolling: stopPollingNewNotificationsToLoad,
  } = useGraphQuery<NewNotificationsQuery, NewNotificationsQueryVariables>(
    newNotificationsQuery,
    {
      variables: {
        lastChecked:
          newestNotification?.dateCreated || moment(new Date()).utc().format(),
      },
      skip: !newestNotification,
    }
  );

  const [
    fetchNotifications,
    { data: notifications, loading: getNotificationsLoading },
  ] = useGraphLazyQueryLite<NotificationsQuery, NotificationsQueryVariables>(
    notificationsQuery
  );

  const [doMarkNotification] = useGraphMutation<
    MarkNotificationMutation,
    MarkNotificationMutationVariables
  >(
    markNotificationMutation,
    {
      update: (cache, data) => {
        refetchNoOfUnreadNotifications();
        cache.modify({
          id: cache.identify(data.data!.markNotification),
          fields: {
            status() {
              return data.data!.markNotification.status;
            },
          },
        });

        setLocalNotifications((crtNotifications) => {
          return crtNotifications.map((notif) => {
            if (notif.id === data.data?.markNotification.id) {
              return {
                ...notif,
                status: data.data.markNotification.status,
              };
            }
            return notif;
          });
        });

        refetchNoOfUnreadNotifications();
      },
    },
    null
  );

  const [doMarkAllNotificationsAsRead] = useGraphMutation<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >(
    markAllNotificationsAsReadMutation,
    {
      update: (cache, data) => {
        if (data.data?.markAllNotificationsAsRead) {
          cache.updateQuery({ query: notificationsQuery }, () => ({
            notifications: {
              ...notifications?.data.notifications,
              items: notifications?.data.notifications.items.map((notif) => ({
                ...notif,
                status: NotificationStatus.Read,
              })),
            },
          }));

          setLocalNotifications((crtNotifications) => {
            return crtNotifications.map((notif) => ({
              ...notif,
              status: NotificationStatus.Read,
            }));
          });
        }

        refetchNoOfUnreadNotifications();
      },
    },
    null
  );

  const markNotification = useCallback(
    (notificationId: string, action: NotificationStatus) => {
      doMarkNotification({
        variables: { id: notificationId, status: action },
        optimisticResponse: {
          markNotification: {
            __typename: "Notification",
            id: notificationId,
            status: action,
          },
        },
      });
    },
    [doMarkNotification]
  );

  const markAllNotificationsAsRead = useCallback(() => {
    doMarkAllNotificationsAsRead({
      optimisticResponse: {
        markAllNotificationsAsRead: true,
      },
    });
  }, [doMarkAllNotificationsAsRead]);

  const loadMore = useCallback(async () => {
    if (continuationToken.current) {
      fetchNotifications({
        limit,
        nextToken: continuationToken.current,
      });
    } else {
      const { data, error } = await fetchNotifications(
        { limit },
        "network-only"
      );

      if (error) {
        setError(true);
        enqueueSnackbar(t("common.errorMessages.notifications"), {
          variant: "error",
        });
        return;
      }

      const fetchedNotifications = data!.notifications
        .items as unknown as Notification[];

      if (fetchedNotifications === oldFirstPageNotifications.current) {
        setLocalNotifications(fetchedNotifications);
        continuationToken.current = data!.notifications.nextToken || "";
      } else {
        oldFirstPageNotifications.current = fetchedNotifications;
      }
    }
  }, [continuationToken, fetchNotifications, enqueueSnackbar, t]);

  const startFresh = useCallback(() => {
    continuationToken.current = "";
    setError(false);
    setLocalNotifications([]);
    if (newNotificationsToLoad?.newNotifications) {
      loadingNewerNotifications.current = true;
    }
  }, [newNotificationsToLoad]);

  const stopListening = useCallback(() => {
    stopPollingUnreadNotificationsCount();
    stopPollingNewNotificationsToLoad();
  }, [stopPollingUnreadNotificationsCount, stopPollingNewNotificationsToLoad]);

  useEffect(() => {
    continuationToken.current =
      notifications?.data.notifications.nextToken || "";
  }, [notifications]);

  useEffect(() => {
    startPollingUnreadNotificationsCount(checkForUnreadNotificationsDelay);
  }, [startPollingUnreadNotificationsCount]);

  useEffect(() => {
    setLocalNotifications((crtNotifications) => {
      const incomingNotifications =
        (notifications?.data.notifications
          .items as unknown as Notification[]) || [];

      let oldNotifications = crtNotifications;
      if (loadingNewerNotifications.current) {
        // when there are new notifications to load / starting fresh, we don't want the old notifications
        oldNotifications = [];
      }
      const existingNotifications = oldNotifications
        ? oldNotifications.filter(
            (notif) =>
              !incomingNotifications.find(
                (incomingNotif) => incomingNotif.id === notif.id
              )
          )
        : [];

      return [...existingNotifications, ...incomingNotifications];
    });
  }, [notifications]);

  useEffect(() => {
    if (localNotifications.length) {
      setNewestNotification(localNotifications[0]);
    }
  }, [localNotifications]);

  useEffect(() => {
    if (
      oldNewNotificationsToLoad &&
      oldNewNotificationsToLoad.newNotifications > 0 &&
      newNotificationsToLoad?.newNotifications === 0
    ) {
      loadingNewerNotifications.current = false;
    }
  }, [newNotificationsToLoad, oldNewNotificationsToLoad]);

  useEffect(() => {
    if (newestNotification) {
      startPollingNewNotificationsToLoad(checkForNewNotificationsDelay);
    } else {
      stopPollingNewNotificationsToLoad();
    }
    // eslint-disable-next-line
  }, [newestNotification]);

  return {
    notifications: localNotifications,
    loading: getNotificationsLoading,
    unreadNotificationsCount: unreadNotifications?.unreadNotifications,
    hasMore: !!continuationToken.current,
    newUnloadedNotifications: newNotificationsToLoad?.newNotifications,
    error,
    markNotification,
    markAllNotificationsAsRead,
    stopListening,
    loadMore,
    startFresh,
  };
};
