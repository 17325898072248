export const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
export const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";

export const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

export const CapitalizedNameMentionsRegex = new RegExp(
  "(^|[^#])((?:" + DocumentMentionsRegex.NAME + "{" + 1 + ",})$)"
);

export const PUNC = DocumentMentionsRegex.PUNCTUATION;

export const TRIGGERS = ["@"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
export const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
export const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNC +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";

export const LENGTH_LIMIT = 75;

export const AtSignMentionsRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    VALID_JOINS +
    "){0," +
    LENGTH_LIMIT +
    "})" +
    ")$"
);

// 50 is the longest alias length limit.
export const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
export const AtSignMentionsRegexAliasRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    "){0," +
    ALIAS_LENGTH_LIMIT +
    "})" +
    ")$"
);

// At most, 5 suggestions are shown in the popup.
export const SUGGESTION_LIST_LENGTH_LIMIT = 5;
export const CHARS_TO_TRIGGER_NAME_SUGGESTIONS = 7;
