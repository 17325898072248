import { gql } from "@apollo/client";
import { eventItemFields } from "graphql/fragments/eventItem.fragment";

export const addDraftEventItemMutation = gql`
  ${eventItemFields}
  mutation addDraftEventItem($input: AddEventItemInput!) {
    addDraftEventItem(input: $input) {
      ...EventItemFields
    }
  }
`;
