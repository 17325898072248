import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Typography } from "@mui/material";
import { dateTimeISOFormat } from "constants/constants";
import { HeaderLoadingContainer } from "containers/AdminConsole/containers/Companies/components/HeaderLoadingContainer";
import { useNavigateBack } from "hooks/useNavigateBack";
import moment from "moment";
import { Trans } from "react-i18next";
import {
  PageContentHeader,
  PageContentHeaderSize,
} from "./PageContentHeader/PageContentHeader";
import { StatusOption, StatusTag } from "./StatusTag/StatusTag";

export type EntityDetailsHeaderProps<T extends string> = {
  loading?: boolean;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  status?: T;
  statusOptions?: StatusOption<T>[];
  creator?: string;
  dateCreated?: string;
  onStatusChange?: (newStatus: T) => void;
};

export const EntityDetailsHeader = <T extends string>({
  title,
  status,
  subtitle,
  creator,
  dateCreated,
  loading,
  statusOptions,
  onStatusChange,
}: EntityDetailsHeaderProps<T>) => {
  const handleNavigateBack = useNavigateBack();

  const handleStatusChange = (newStatus: StatusOption<T>) => {
    onStatusChange?.(newStatus.id as T);
  };

  return (
    <PageContentHeader size={PageContentHeaderSize.Large}>
      {loading && (
        <HeaderLoadingContainer>
          <CircularProgress />
        </HeaderLoadingContainer>
      )}
      <Box display="flex" alignItems="center">
        <ArrowBackIcon
          onClick={handleNavigateBack}
          style={{ cursor: "pointer" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          ml={3}
          mr={1}
        >
          <Typography variant="subtitle2">{subtitle}</Typography>
          <Typography variant="h5">{title}</Typography>
        </Box>
        {status && statusOptions && (
          <Box alignSelf="flex-end">
            <StatusTag
              status={status}
              onChange={handleStatusChange}
              options={statusOptions}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {dateCreated ? (
          creator ? (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">
                <Trans
                  i18nKey="common.labels.createdByOn"
                  values={{
                    creator,
                    dateCreated: moment(dateCreated).format(dateTimeISOFormat),
                  }}
                />
              </Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">
                <Trans
                  i18nKey="common.labels.dateCreatedOn"
                  values={{
                    dateCreated: moment(dateCreated).format(dateTimeISOFormat),
                  }}
                />
              </Typography>
            </Box>
          )
        ) : null}
      </Box>
    </PageContentHeader>
  );
};
