import { gql } from "@apollo/client";
import { companyLiteFields } from "graphql/fragments/companyLite.fragment";

export const changeCompanyStatusMutation = gql`
  ${companyLiteFields}

  mutation changeCompanyStatus($id: ID!, $status: CompanyStatus!) {
    changeCompanyStatus(id: $id, status: $status) {
      ...CompanyLiteFields
    }
  }
`;
