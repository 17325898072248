import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { ItemTag } from "components/ItemTag";
import {
  ExtendedOptionStyles,
  StatusOptionNew,
} from "components/StatusTag/StatusTagNew";
import { dateISOFormat } from "constants/constants";
import {
  AuthorizationActionStatus,
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowAuditStatus,
} from "generated/graphql";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { datetimeComparatorFn } from "helpers/dataGrid.helpers";
import { OverflowTooltip } from "components/OverflowTooltip";
import { computeRegardingColumnText } from "./Authorizations.utils";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";

export const useCommonAuthorizationColumns = (colsSortable: boolean = true) => {
  const { t } = useTranslation();

  return useMemo((): GridColDef<AuthorizationWorkflowAudit>[] => {
    return [
      {
        field: "action",
        headerName: t("Home.Authorizations.action"),
        flex: 0.15,
        sortable: colsSortable,
        resizable: true,
        valueGetter: (_, row) =>
          t(
            `Home.Authorizations.AuthorizeDenyModal.actions.${row.action.productOutputAction.name}`
          ),
        renderCell: (params) => {
          return (
            <OverflowTooltip
              title={params.value}
              typographyProps={{
                variant: "p2",
                color: "grey.600",
              }}
            />
          );
        },
      },
      {
        field: "regarding",
        headerName: t("common.labels.regarding"),
        width: 220,
        resizable: true,
        sortable: colsSortable,
        valueGetter: (_, row) => computeRegardingColumnText(row),
        sortComparator: (
          label1: AuthorizationWorkflowAudit,
          label2: AuthorizationWorkflowAudit
        ) => {
          if (label1 < label2) {
            return -1;
          } else if (label1 > label2) {
            return 1;
          }

          return 0;
        },
        renderCell: (params) => {
          return (
            <Box display="flex" alignItems="center" width="100%">
              <ItemTag type={params.row.regardingProductType} />
              <OverflowTooltip
                title={params.value}
                typographyProps={{
                  variant: "p2",
                  ml: 1,
                }}
              />
            </Box>
          );
        },
      },
      {
        field: "project",
        headerName: t("common.labels.project"),
        flex: 0.15,
        resizable: true,
        sortable: colsSortable,
        valueGetter: (_, row) =>
          row.action.productInstance.contract.project.friendlyName,
        renderCell: (params) => {
          return (
            <OverflowTooltip
              title={params.value}
              typographyProps={{
                variant: "p2",
                color: "grey.600",
              }}
            />
          );
        },
      },
      {
        field: "contract",
        headerName: t("common.labels.contract"),
        flex: 0.15,
        resizable: true,
        sortable: colsSortable,
        valueGetter: (_, row) =>
          row.action.productInstance.contract.friendlyName,
        renderCell: (params) => {
          return (
            <OverflowTooltip
              title={params.value}
              typographyProps={{
                variant: "p2",
                color: "grey.600",
              }}
            />
          );
        },
      },
      {
        field: "initiated",
        headerName: t("Home.Authorizations.initiated"),
        width: 100,
        resizable: true,
        sortable: colsSortable,
        sortComparator: datetimeComparatorFn,
        valueGetter: (_, row) => row.dateInitiated,
        valueFormatter: (value: string) => moment(value).format(dateISOFormat),
        renderCell: (params) => {
          return (
            <DateWithTimeTooltip
              datetime={params.value}
              timezone={
                params.row.regardingProductItem.productInstance.contract
                  .timeZone
              }
              color="grey.600"
              variant="p2"
            />
          );
        },
      },
    ];
  }, [t, colsSortable]);
};

export const authorizationAuditStatusOptions: StatusOptionNew<string>[] = [
  {
    id: AuthorizationWorkflowAuditStatus.InProgress,
    label: `Home.Authorizations.status.${AuthorizationWorkflowAuditStatus.InProgress}`,
    style: ExtendedOptionStyles.Amber,
  },
  {
    id: AuthorizationWorkflowAuditStatus.Authorized,
    label: `Home.Authorizations.status.${AuthorizationWorkflowAuditStatus.Authorized}`,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: AuthorizationWorkflowAuditStatus.Denied,
    label: `Home.Authorizations.status.${AuthorizationWorkflowAuditStatus.Denied}`,
    style: ExtendedOptionStyles.Red,
  },
  {
    id: AuthorizationWorkflowAuditStatus.Removed,
    label: `Home.Authorizations.status.${AuthorizationWorkflowAuditStatus.Removed}`,
    style: ExtendedOptionStyles.Grey,
  },
];

export const authorizationAuditTrailStatusOptions: StatusOptionNew<string>[] = [
  {
    id: AuthorizationActionStatus.Pending,
    label: `Home.Authorizations.actionStatus.${AuthorizationActionStatus.Pending}`,
    style: ExtendedOptionStyles.Amber,
  },
  {
    id: AuthorizationActionStatus.Approved,
    label: `Home.Authorizations.actionStatus.${AuthorizationActionStatus.Approved}`,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: AuthorizationActionStatus.Denied,
    label: `Home.Authorizations.actionStatus.${AuthorizationActionStatus.Denied}`,
    style: ExtendedOptionStyles.Red,
  },
];
