import { useEffect } from "react";

export const useArrowsNavigation = (
  onLeft?: () => void,
  onRight?: () => void,
  onUp?: () => {},
  onDown?: () => {}
) => {
  useEffect(() => {
    const handleKeyDown = (evt: KeyboardEvent) => {
      if (evt.key === "ArrowLeft") {
        onLeft?.();
      } else if (evt.key === "ArrowRight") {
        onRight?.();
      } else if (evt.key === "ArrowUp") {
        onUp?.();
      } else if (evt.key === "ArrowDown") {
        onDown?.();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onLeft, onRight, onUp, onDown]);
};
