import { gql } from "@apollo/client";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";

export const editAuthorizationWorkflowActionMappingMutation = gql`
  ${authorizationWorkflowActionMappingFields}

  mutation editAuthorizationWorkflowActionMapping(
    $input: EditAuthorizationActionInput!
  ) {
    editAuthorizationWorkflowActionMapping(input: $input) {
      ...AuthorizationWorkflowActionMappingFields
    }
  }
`;
