import { gql } from "@apollo/client";
import { dailyDiaryItemRegisterViewFields } from "./dailyDiaryItems.query";

export const dailyDiaryItemsReviewedByMeQuery = gql`
  ${dailyDiaryItemRegisterViewFields}

  query dailyDiaryItemsReviewedByMe(
    $productInstanceId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    dailyDiaryItemsReviewedByMe(
      productInstanceId: $productInstanceId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        ...DailyDiaryItemRegisterViewFields
      }
    }
  }
`;
