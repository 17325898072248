import { gql } from "@apollo/client";

export const addItemStatusCollectionMutation = gql`
  mutation addItemStatusCollection($input: AddItemStatusCollectionInput!) {
    addItemStatusCollection(input: $input) {
      id
      name
      isDefault
      productId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;

export const editItemStatusCollectionMutation = gql`
  mutation editItemStatusCollection($input: EditItemStatusCollectionInput!) {
    editItemStatusCollection(input: $input) {
      id
      name
      isDefault
      productId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;
