import { Stack } from "@mui/material";
import { DailyDiaryItem, DailyDiaryItemRevision } from "generated/graphql";
import { RevisionListItem } from "./RevisionListItem";
import { useNavigate } from "react-router-dom";
import { NewAppPaths } from "helpers/paths/paths";

type RevisionsListProps = {
  revisions: DailyDiaryItemRevision[];
  crtRevisionId: string;
  dailyDiary: DailyDiaryItem;
};

export const RevisionsList: React.FC<RevisionsListProps> = ({
  revisions,
  crtRevisionId,
  dailyDiary,
}) => {
  const navigate = useNavigate();

  const handleRevisionClick = (revisionId: string) => {
    navigate(
      NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
        dailyDiary.productInstanceId,
        dailyDiary.date,
        dailyDiary.id,
        revisionId
      )
    );
  };

  return (
    <Stack spacing={4} width="100%">
      {revisions.map((revision) => (
        <RevisionListItem
          key={revision.id}
          revision={revision}
          isSelected={crtRevisionId === revision.id}
          onRevisionClick={handleRevisionClick}
        />
      ))}
    </Stack>
  );
};
