import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const compEventActionQuery = gql`
  ${authorizationWorkflowActionMappingFields}
  ${authorizationWorkflowFields}
  ${creatorFields}

  query compEventAction($input: CompEventActionInput!) {
    compEventAction(input: $input) {
      items {
        type
        actionMapping {
          ...AuthorizationWorkflowActionMappingFields
        }
      }
      ongoingAuthorizationAudit {
        id
        initiatedById
        initiatedBy {
          ...CreatorFields
        }
        regardingProductItemId
        regardingProductType
        regardingItemHistoryId
        actionId
        action {
          ...AuthorizationWorkflowActionMappingFields
        }
        workflowId
        workflow {
          ...AuthorizationWorkflowFields
        }
        status
        dateInitiated
        dateCreated
      }
    }
  }
`;
