import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const earlyWarningItemLiteFields = gql`
  ${creatorFields}

  fragment EarlyWarningItemLiteFields on EarlyWarningItem {
    id
    title
    severity
    #data: ItemData!
    number
    productInstanceId
    ownerId
    statusOptionId
    dateCreated
    creatorId
    # creator {
    #   ...CreatorFields
    # }
    #statusOption: ItemStatusOption!
    # owner {
    #   ...CreatorFields
    # }
    # productInstance {
    #   id
    #   description
    #   numberingFormat
    #   product {
    #     id
    #     numberingFormat
    #   }
    # }
    dateModified
    #attachments: [Attachment!]
    #changeLog: ChangeLogItemList!
    #comments(limit: Int!, nextToken: String): CommentList!
    offline
    givenById
    dateSent
  }
`;
