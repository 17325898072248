import {
  Box,
  Color,
  css,
  IconButton,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { TrashIcon } from "components/Icons/TrashIcon";
import { formatBytes } from "helpers/miscelaneous";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

export type UserProfilePhotoSelectorProps = {
  url?: string;
  height?: string;
  width?: string;
  onPhotoUpload?: (photo: string | undefined) => void;
};

const Byte = 1;
const KB = 1024 * Byte;
const MB = 1024 * KB;
const fiveMB = 5 * MB;
const fileSizeLimit = fiveMB;

const DroppingAreaContainer = styled(Box)<{ isDragActive: boolean }>(
  ({ theme, isDragActive }) => {
    const activeStyle = css`
      background-color: ${(theme.palette.secondary as Partial<Color>)[100]};
      border-color: ${(theme.palette.secondary as Partial<Color>)[600]};
    `;

    return css`
      display: flex;
      flex-direction: column;
      border: 1px dashed ${theme.palette.grey[300]};
      background: ${theme.palette.grey[100]};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      &:hover {
        background-color: rgba(227, 243, 229, 0.4);
        border-color: ${(theme.palette.secondary as Partial<Color>)[700]};
      }

      &:focus,
      &:active {
        ${activeStyle}
      }

      ${isDragActive && activeStyle}
    `;
  }
);

export const UserProfilePhotoSelector: React.FC<
  UserProfilePhotoSelectorProps
> = ({ url, height = 200, width = 200, onPhotoUpload }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [photo, setPhoto] = useState<string | undefined>(url ?? undefined);

  const handleRemoveUploadedPhoto = () => {
    setPhoto(undefined);
    onPhotoUpload?.(undefined);
  };

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      rejectedFiles: FileRejection[],
      __: DropEvent
    ) => {
      if (acceptedFiles && acceptedFiles.length) {
        const file = acceptedFiles[0];
        const FR = new FileReader();

        FR.addEventListener("load", async function (evt) {
          onPhotoUpload?.(evt.target!.result! as string);
          setPhoto(evt.target!.result! as string);
        });

        FR.readAsDataURL(file);
      }
      if (rejectedFiles && rejectedFiles.length) {
        for (let index = 0; index < rejectedFiles.length; index++) {
          const rejectedFile = rejectedFiles[index];

          if (rejectedFile.errors[0].code === "file-too-large") {
            enqueueSnackbar(
              t("Attachments.fileTooLarge", {
                fileName: rejectedFile.file.name,
                sizeLimit: formatBytes(fileSizeLimit),
              })
            );
          }
        }
      }
    },
    [onPhotoUpload, t, enqueueSnackbar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: fileSizeLimit,
    accept: {
      "image/*": [".jpeg", ".jpg", ".gif", ".svg", ".png"],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    setPhoto(url);
  }, [url]);

  return (
    <Box display="flex" alignItems="center">
      {photo ? (
        <>
          <Box
            sx={{ width: "200px", height: "200px", borderRadius: "50%" }}
            mr={1}
          >
            <img
              src={photo}
              alt="user's profile"
              height="100%"
              width="100%"
              style={{ objectFit: "cover", borderRadius: "50%" }}
            />
          </Box>
          <IconButton onClick={handleRemoveUploadedPhoto}>
            <TrashIcon />
          </IconButton>
        </>
      ) : (
        <DroppingAreaContainer
          height={height}
          width={width}
          isDragActive={isDragActive}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Typography variant="p1" color="grey.500">
            {t("Settings.profile.dragImageHere")}
          </Typography>
          <Typography
            variant="p1"
            color={(theme.palette.primary as Partial<Color>)[400]}
            sx={{ cursor: "pointer" }}
          >
            {t("Settings.profile.uploadImage")}
          </Typography>
        </DroppingAreaContainer>
      )}
    </Box>
  );
};
