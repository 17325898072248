import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const CloudCheckIcon: React.FC<BaseIconProps> = ({
  height = "17",
  width = "17",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color || theme.palette.grey[700];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00081 8.00248C5.00081 7.01337 5.29411 6.04648 5.84363 5.22406C6.39315 4.40165 7.1742 3.76066 8.08802 3.38215C9.00183 3.00363 10.0074 2.9046 10.9775 3.09756C11.9476 3.29053 12.8387 3.76683 13.5381 4.46623C14.2375 5.16563 14.7138 6.05673 14.9067 7.02683C15.0997 7.99693 15.0007 9.00247 14.6222 9.91628C14.2436 10.8301 13.6026 11.6111 12.7802 12.1607C11.9578 12.7102 10.9909 13.0035 10.0018 13.0035H4.50071C3.57226 13.0035 2.68184 12.6347 2.02533 11.9782C1.36882 11.3216 1 10.4312 1 9.50278C1 8.57433 1.36882 7.68392 2.02533 7.02741C2.68184 6.3709 3.57226 6.00207 4.50071 6.00207C4.7936 6.00196 5.0854 6.03765 5.36963 6.10834"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0002 7.25244L9.00005 10.2525L7.5 8.75249"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
