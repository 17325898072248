import React from "react";
import LoginBgImage from "../../assets/login_bg_image_cropped.jpg";

export const AuthTemplateBackground: React.FC = () => (
  <img
    src={LoginBgImage}
    style={{ height: "100vh", width: "100%" }}
    alt="grey lines backdrop"
  />
);
