import { TimelineConnector, TimelineDot, TimelineItem } from "@mui/lab";
import { Box, Color, css, styled } from "@mui/material";

export const StepperContainer = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 80px;
    transform: translate(0, -50%);
    background: white;
    border-radius: 8px;
    width: 70%;
    max-width: 410px;
    height: 230px;
    padding: ${theme.spacing(4)};
    box-sizing: border-box;
    overflow: hidden;
  `
);

export const StyledTimelineItem = styled(TimelineItem)`
  &:before {
    display: none;
  }
`;

export const StyledTimelineDot = styled(TimelineDot)<{
  state: "active" | "completed" | "rest";
}>(
  ({ theme, state }) => css`
    height: 21px;
    width: 21px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    font-size: 12px;
    font-weight: 600;
    padding: 2px;
    ${state === "rest"
      ? css`
          color: ${theme.palette.grey[500]};
          border-color: ${theme.palette.grey[500]};
        `
      : css`
          background-color: ${(theme.palette.primary as Partial<Color>)[500]};
        `}
  `
);

export const StyledTimelineConnector = styled(TimelineConnector, {
  shouldForwardProp: (prop) => prop !== "visible",
})<{
  visible?: boolean;
}>(
  ({ theme, visible }) => css`
    ${visible
      ? css`
          background-color: ${theme.palette.grey[300]};
          width: 1px;
        `
      : css`
          background-color: white;
        `}
  `
);
