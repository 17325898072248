import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationFurtherParticularsRequestPromptQuery = gql`
  query variationFurtherParticularsRequestPrompt(
    $input: VariationFurtherParticularsRequestPromptInput!
  ) {
    variationFurtherParticularsRequestPrompt(input: $input) {
      reasons
    }
  }
`;

export const sendVariationFurtherParticularsRequestMutation = gql`
  mutation sendVariationFurtherParticularsRequest(
    $input: SendVariationFurtherParticularsRequestInput!
  ) {
    sendVariationFurtherParticularsRequest(input: $input) {
      id
    }
  }
`;

export const recordVariationFurtherParticularsRequestMutation = gql`
  mutation recordVariationFurtherParticularsRequest(
    $input: RecordVariationFurtherParticularsRequestInput!
  ) {
    recordVariationFurtherParticularsRequest(input: $input) {
      id
    }
  }
`;

export const generateSendVariationFurtherParticularsRequestPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationFurtherParticularsRequestPreview(
    $input: SendVariationFurtherParticularsRequestPreviewInput!
  ) {
    generateSendVariationFurtherParticularsRequestPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationFurtherParticularsRequestPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationFurtherParticularsRequestPreview(
    $input: RecordVariationFurtherParticularsRequestPreviewInput!
  ) {
    generateRecordVariationFurtherParticularsRequestPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
