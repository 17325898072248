import { AddCompanyInput, CompanyType } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";

export const CompanyTypeMapping: Record<CompanyType, string> = {
  [CompanyType.JointVenture]: "Joint venture",
  [CompanyType.Other]: "Other",
  [CompanyType.Partnership]: "Partnership",
  [CompanyType.Private]: "Private",
  [CompanyType.Public]: "Public",
  [CompanyType.SoleProprietor]: "Sole proprietor",
};

export const companyDataValidators: DataValidators = {
  registeredName: {
    validators: [ValidatorType.Required],
  },
  tradingName: {
    validators: [ValidatorType.Required],
  },
  type: {
    validators: [ValidatorType.Required],
  },
};

export const defaultCompanyData: AddCompanyInput = {
  logo: "", // "https://via.placeholder.com/150x50?text=CompanyLogo", // TODO
  physicalAddress: {
    city: "",
    code: "",
    country: "",
    line1: "",
    line2: "",
    provinceState: "",
  },
  postalAddress: {
    city: "",
    code: "",
    country: "",
    line1: "",
    line2: "",
    provinceState: "",
  },
  registeredName: "",
  registrationNumber: "",
  tradingName: "",
  type: "" as CompanyType,
  vatRegistrationNumber: "",
};
