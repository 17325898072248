import {
  DailyDiaryWeatherRecord,
  EditDailyDiaryWeatherRecordInput,
} from "generated/graphql";

export const hoursToDate = (time: string) => new Date(`2023/01/01 ${time}`);

export const weatherRecordToEditInput = (
  record: DailyDiaryWeatherRecord
): EditDailyDiaryWeatherRecordInput => {
  const {
    __typename: _,
    creator: __,
    creatorId: ___,
    dateCreated: ____,
    dateModified: _____,
    attachments: ______,
    conditionsLookupId,
    ...rest
  } = record;

  return {
    ...rest,
    timeOfMeasurement: hoursToDate(record.timeOfMeasurement),
    attachments: [], // TODO
  };
};
