import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { VariationActionType, VariationProposal } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordConsentToProposalForProposedVariationModal } from "./useRecordConsentToProposalForProposedVariationModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ConsentToProposalForProposedVariationSummary } from "./ConsentToProposalForProposedVariationSummary";

export type RecordConsentToProposalForProposedVariationModalProps = {
  remarks: string;
  acceptedProposal: VariationProposal;
  attachments?: EnhancedAttachment[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordConsentToProposalForProposedVariationModal: React.FC<
  RecordConsentToProposalForProposedVariationModalProps
> = ({ remarks, acceptedProposal, attachments = [], ...restDialogProps }) => {
  const { variationPreview, contract } =
    useRecordConsentToProposalForProposedVariationModal(
      remarks,
      acceptedProposal.id
    );
  return (
    <RecordAction
      actionType={VariationActionType.ConsentToProposalForProposedVariation}
      variationPreview={variationPreview}
      summaryEl={
        <ConsentToProposalForProposedVariationSummary
          remarks={remarks}
          proposal={acceptedProposal}
          attachments={attachments}
          allowViewDetails={false}
          contract={contract}
        />
      }
      {...restDialogProps}
    />
  );
};
