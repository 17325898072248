import { gql } from "@apollo/client";

export const itemStatusCollectionLiteQuery = gql`
  query itemStatusCollectionLite($id: ID!) {
    itemStatusCollection(id: $id) {
      id
      name
    }
  }
`;
