import { Grid } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { FormLabel } from "components/FormLabel";
import { useContext, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { useDenyMoreTimeToRespondCEAction } from "./useDenyMoreTimeToRespondCEAction";
import { SendCEDenialForMoreTime } from "./SendCEDenialForMoreTime";
import { RecordCEDenialForMoreTime } from "./RecordCEDenialForMoreTime";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";

type FormType = {
  remarks: string;
};

const defaultFormData: FormType = {
  remarks: "",
};

export const DenyMoreTimeToRespondCEAction: React.FC<CEActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { compEvent } = useContext(CompEventWidgetContext);

  const [formData, setFormData] = useState<FormType>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendCompEventDenialForMoreTime,
    recordCompEventDenialForMoreTime,
    loading: denyMoreTimeLoading,
    actionLoading,
  } = useDenyMoreTimeToRespondCEAction();

  const handleRemarksChange = (remarks: string) => {
    setFormData((curData) => ({
      ...curData,
      remarks,
    }));
  };

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCEDenialForMoreTime = async () => {
    await sendCompEventDenialForMoreTime({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          remarks: formData.remarks,
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEDenialForMoreTime = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCompEventDenialForMoreTime({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          remarks: formData.remarks,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => true,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    []
  );

  return denyMoreTimeLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {sendModalVisibility && (
        <SendCEDenialForMoreTime
          open={sendModalVisibility}
          remarks={formData.remarks}
          onPrimaryClick={handleSendCEDenialForMoreTime}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {recordModalVisibility && (
        <RecordCEDenialForMoreTime
          open={recordModalVisibility}
          remarks={formData.remarks}
          onPrimaryClick={handleRecordCEDenialForMoreTime}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel label={t("common.labels.remarks")} />
          <RichTextArea
            content={formData.remarks ?? ""}
            onChange={handleRemarksChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
