import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const PlusIcon: React.FC<BaseIconProps> = ({
  height = "25",
  width = "25",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[100];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.74609 12.002H21.2494"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 3.75098V20.2543"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
