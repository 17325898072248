import { useGraphMutation } from "hooks/useGraphMutation";
import { changeUserCompanyMutation } from "./ChangeUserCompanyModal.query";
import { useTranslation } from "react-i18next";
import {
  ChangeUserCompanyMutation,
  ChangeUserCompanyMutationVariables,
} from "generated/graphql";

export const useChangeUserCompany = () => {
  const { t } = useTranslation();

  const [changeUserCompany, { loading: changeUserCompanyLoading }] =
    useGraphMutation<
      ChangeUserCompanyMutation,
      ChangeUserCompanyMutationVariables
    >(
      changeUserCompanyMutation,
      {
        update: () => {},
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.company"),
      })
    );

  return {
    changeUserCompany,
    changeUserCompanyLoading,
  };
};
