import { gql } from "@apollo/client";

export const compEventAssessmentFields = gql`
  fragment CompEventAssessmentFields on CompEventAssessment {
    id
    compEventId
    reference
    details
    price
    currency
    time
    revision
    keyDatesChanges {
      ...CompEventQuotationTimeChangeFields
    }
    sectionalChanges {
      ...CompEventQuotationTimeChangeFields
    }
    attachments {
      ...AttachmentFields
    }
    dateCreated
    creatorId
  }
`;
