import { GridSortModel } from "@mui/x-data-grid-pro";
import { datetimeComparatorFn } from "helpers/dataGrid.helpers";
import { useMemo, useState } from "react";

const recordCompareFn = <T extends { dateCreated: string }>(
  rec1: T,
  rec2: T
) => {
  return datetimeComparatorFn(rec1.dateCreated, rec2.dateCreated);
};

export const useDiaryTableSorting = <T extends { dateCreated: string }>(
  records: T[]
) => {
  const [sortingModel, setSortingModel] = useState<GridSortModel>([]);

  const rows = useMemo(() => {
    if (sortingModel.length) {
      // user selected custom sorting on the table
      return records;
    }

    return [...records].sort(recordCompareFn);
  }, [sortingModel, records]);

  return {
    rows,
    sortingModel,
    setSortingModel,
  };
};
