import { DataValidators, ValidatorType } from "helpers/validators";
import { FormType } from "./AuthorizationWorkflowForm";

export const defaultFormData: FormType = {
  name: "",
  levels: [],
  projectId: "",
};

export const dataValidators: DataValidators = {
  name: {
    validators: [ValidatorType.Required],
  },
  levels: {
    validators: [ValidatorType.NonEmptyArray],
  },
};
