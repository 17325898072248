import {
  AuthorizationActionStatus,
  AuthorizationWorkflowAudit,
} from "generated/graphql";
import { AuthorizationLevel } from "./AuthorizationLevel";
import { Box, BoxProps, Stack } from "@mui/material";
import { LevelConnector } from "./LevelConnector";
import { computeLevelStatus } from "../../../../../containers/Home/components/Authorizations/Authorizations.utils";

export type AuthorizationLevelsProps = {
  authAudit: AuthorizationWorkflowAudit;
  showLevelsConnector?: boolean;
} & BoxProps;

// TODO: lift it up
export const AuthorizationLevels: React.FC<AuthorizationLevelsProps> = ({
  authAudit,
  showLevelsConnector = true,
  ...restBoxProps
}) => {
  const activeAuthAuditTrails =
    authAudit.auditTrails?.filter(
      (auditTrail) => auditTrail.status !== AuthorizationActionStatus.Pending
    ) ?? [];

  const levels = authAudit.workflow.levels
    .slice()
    .sort((a, b) => a.sequence - b.sequence); // TODO: should ideally be done in one place, or on BE

  return (
    <Box position="relative" {...restBoxProps}>
      {showLevelsConnector && <LevelConnector zIndex={0} />}
      <Stack spacing={4} zIndex={1} position="relative">
        {levels.map((level) => {
          const allLevelApproverIds = level.userIds;
          const settledTrails =
            activeAuthAuditTrails.filter((auditTrail) =>
              allLevelApproverIds.includes(auditTrail.creatorId)
            ) ?? [];

          return (
            <AuthorizationLevel
              levelStatus={computeLevelStatus(level, authAudit)}
              authAuditStatus={authAudit.status}
              key={level.sequence}
              authLevel={level}
              settledTrails={settledTrails}
              contractTimezone={
                authAudit.regardingProductItem.productInstance.contract.timeZone
              }
            />
          );
        })}
      </Stack>
    </Box>
  );
};
