import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RegistrationStep } from "../Register";

export type RegistrationFooterProps = {
  step: RegistrationStep;
  createAccountLoading?: boolean;
  createAccountDisabled?: boolean;
  onBack: () => void;
  onNext: () => void;
  onCreateAccount: () => void;
};

export const RegistrationFooter: React.FC<RegistrationFooterProps> = ({
  step,
  createAccountLoading,
  createAccountDisabled,
  onNext,
  onBack,
  onCreateAccount,
}) => {
  const { t } = useTranslation();

  return step === RegistrationStep.Account ? (
    <Button fullWidth variant="contained" size="large" onClick={onNext}>
      {t("common.buttons.next")}
    </Button>
  ) : (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" size="large" fullWidth onClick={onBack}>
        {t("common.buttons.back")}
      </Button>
      <LoadingButton
        onClick={onCreateAccount}
        loading={createAccountLoading}
        disabled={createAccountDisabled}
        variant="contained"
        size="large"
        fullWidth
      >
        {t("Register.createAccount")}
      </LoadingButton>
    </Stack>
  );
};
