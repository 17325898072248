import { gql } from "@apollo/client";
import { attachmentLiteFields } from "./attachmentLite.fragment";
import { compEventAssessmentFields } from "./compEventAssessment.fragment";
import { compEventHistoryConfirmationAnswersFields } from "./compEventHistoryConfirmationAnswers.fragment";
import { compEventHistoryNoticeAnswersFields } from "./compEventHistoryNoticeAnswers.fragment";
import { compEventHistoryRejectionAnswersFields } from "./compEventHistoryRejectionAnswers.fragment";
import { compEventQuotationFields } from "./compEventQuotation.fragment";
import { contractBindingTypeFields } from "./contractBindingType.fragment";
import { creatorFields } from "./creator.fragment";
import { draftAssessmentFields } from "./draftAssessment.fragment";
import { authorizationWorkflowAuditFields } from "./authorizationWorkflowAudit.fragment";
import { claimTypeInfoFields } from "./claimTypeInfo.fragment";

export const compEventHistoryItemFields = gql`
  ${compEventHistoryNoticeAnswersFields}
  ${compEventHistoryConfirmationAnswersFields}
  ${compEventHistoryRejectionAnswersFields}
  ${creatorFields}
  ${contractBindingTypeFields}
  ${attachmentLiteFields}
  ${compEventQuotationFields}
  ${compEventAssessmentFields}
  ${claimTypeInfoFields}
  ${draftAssessmentFields}
  ${authorizationWorkflowAuditFields}

  fragment CompEventHistoryItemFields on CompEventHistoryItem {
    id
    compEventId
    actionType
    actionNumber
    isIncomplete
    authorizationWorkflowAudit {
      ...AuthorizationWorkflowAuditFields
    }
    noticeAnswers {
      ...CompEventHistoryNoticeAnswersFields
    }
    confirmationAnswers {
      ...CompEventHistoryConfirmationAnswersFields
    }
    rejectionAnswers {
      ...CompEventHistoryRejectionAnswersFields
    }
    noticeRequestQuotationAnswers {
      claimTypeId
      assumptions
      claimType {
        ...ClaimTypeInfoFields
      }
    }
    quotationRevisionRequestAnswers {
      reason
    }
    quotationAcceptanceAnswers {
      acceptedQuotationId
      acceptedQuotation {
        ...CompEventQuotationFields
      }
    }
    noticeOfSilenceCompEventNoticeAnswers {
      remarks
      deemedAcceptedDate
    }
    noticeOfSilenceQuotationAnswers {
      acceptedQuotationId
      remarks
      deemedAcceptedDate
      acceptedQuotation {
        ...CompEventQuotationFields
      }
    }
    noticeOfSilenceOwnAssessmentAnswers {
      acceptedQuotationId
      remarks
      deemedAcceptedDate
      acceptedQuotation {
        ...CompEventQuotationFields
      }
    }
    noticeProposedInstructionAnswers {
      claimTypeId
      assumptions
      claimType {
        ...ClaimTypeInfoFields
      }
    }
    abandonInstructionAnswers {
      remarks
    }
    quotationAnswers {
      preferredQuotationId
      preferredQuotation {
        ...CompEventQuotationFields
      }
    }
    ownAssessmentNoticeAnswers {
      reason
    }
    ownAssessmentAnswers {
      draftAssessmentId
      draftAssessment {
        ...DraftAssessmentFields
      }
      assessmentId
      assessment {
        ...CompEventAssessmentFields
      }
    }
    requestForMoreTimeAnswers {
      requestedDays
      remarks
    }
    grantingOfMoreTimeAnswers {
      grantedDays
      remarks
    }
    denyingOfMoreTimeAnswers {
      remarks
    }
    attachments {
      ...AttachmentLiteFields
    }
    recipients {
      userId
      name
      preference {
        inApp
        email
      }
    }
    dateSent
    dateCreated
    dueDate
    sentByUserId
    sentByPartyId
    offline
    daysLate
    # Relationshiop with User as Sent by User
    sentByUser {
      ...CreatorFields
    }
    # Relationshiop with Contract Binding Type as Sent by Party
    sentByParty {
      ...ContractBindingTypeFields
    }
  }
`;
