import { SxProps, Theme } from "@mui/material";
import { PriceTimeTag, PriceTimeTagStyle } from "./PriceTimeTag";
import { useTranslation } from "react-i18next";

type TimeTagProps = {
  value?: number | null;
  sx?: SxProps<Theme>;
};

export const TimeTag: React.FC<TimeTagProps> = ({ value, sx }) => {
  const { t } = useTranslation();

  let label;
  let style;

  if (typeof value === "number") {
    label = t("common.labels.xDays", {
      number: Math.abs(value),
      count: Math.abs(value),
    });
    style =
      value > 0
        ? PriceTimeTagStyle.Increase
        : value < 0
        ? PriceTimeTagStyle.Decrease
        : PriceTimeTagStyle.Static;
  } else {
    label = undefined;
    style = PriceTimeTagStyle.Static;
  }
  return <PriceTimeTag type="time" label={label} style={style} sx={sx} />;
};
