import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type ImportRecordsTableHeaderProps = {
  targetDiaryDetails: React.ReactNode;
};

export const ImportRecordsTableHeader: React.FC<
  ImportRecordsTableHeaderProps
> = ({ targetDiaryDetails }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4" fontWeight={600} color="grey.800">
          {t("Projects.DailyDiaries.ImportRecordsModal.resources")}
        </Typography>
        <Typography variant="h4" fontWeight={600} color="grey.800">
          {targetDiaryDetails}
        </Typography>
      </Stack>
      <Typography variant="caption1" color="grey.700">
        {t("Projects.DailyDiaries.ImportRecordsModal.importHint")}
      </Typography>
    </Stack>
  );
};
