import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { DailyDiaryPresetSection } from "generated/graphql";
import { DelaySection } from "../DelaySection/DelaySection";
import { EquipmentSection } from "../EquipmentSection/EquipmentSection";
import { GeneralSection } from "../GeneralSection/GeneralSection";
import { HSESection } from "../HSESection/HSESection";
import { ManpowerSection } from "../ManpowerSection/ManpowerSection";
import { WeatherSection } from "../WeatherSection/WeatherSection";
import { WorkSection } from "../WorkSection/WorkSection";
import { SectionContainer } from "components/miscellaneous/SectionContainer";

export type DDSectionsInterpretorProps = {
  sections: DailyDiaryPresetSection[];
  mainColumnExtraWidgetsBottom?: React.ReactNode;
  mainColumnExtraWidgetsFront?: React.ReactNode;
  secondaryColumnExtraWidgets?: React.ReactNode;
};

export enum DDSection {
  Weather = "Weather",
  Manpower = "Manpower",
  Equipment = "Equipment",
  Work = "Work",
  Delays = "Delays",
  HSE = "HSE",
  General = "General",
}

export const DDSectionsInterpretor: React.FC<DDSectionsInterpretorProps> = ({
  sections,
  mainColumnExtraWidgetsBottom,
  mainColumnExtraWidgetsFront,
  secondaryColumnExtraWidgets,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      spacing={3}
      alignItems="flex-start"
      direction={isMobile ? "column" : "row"}
      width="100%"
    >
      <Stack spacing={3} flex="3" overflow="hidden">
        {mainColumnExtraWidgetsFront && (
          <Box width="100%">{mainColumnExtraWidgetsFront}</Box>
        )}
        <Box width="100%" key="records-section">
          <SectionContainer>
            <Stack spacing={6} width="100%">
              {sections.map((section) =>
                section.name === DDSection.Weather ? (
                  <WeatherSection key={section.name} section={section} />
                ) : section.name === DDSection.Manpower ? (
                  <ManpowerSection key={section.name} section={section} />
                ) : section.name === DDSection.Equipment ? (
                  <EquipmentSection key={section.name} section={section} />
                ) : section.name === DDSection.Work ? (
                  <WorkSection key={section.name} section={section} />
                ) : section.name === DDSection.Delays ? (
                  <DelaySection key={section.name} section={section} />
                ) : section.name === DDSection.HSE ? (
                  <HSESection key={section.name} section={section} />
                ) : section.name === DDSection.General ? (
                  <GeneralSection key={section.name} section={section} />
                ) : null
              )}
            </Stack>
          </SectionContainer>
        </Box>
        {mainColumnExtraWidgetsBottom}
      </Stack>
      <Stack spacing={3} width={isMobile ? "100%" : "432px"}>
        {/* Changelog, reviewers, etc */}
        {secondaryColumnExtraWidgets}
      </Stack>
    </Stack>
  );
};
