import { ClaimActionType } from "generated/graphql";

export enum ClaimActionTypeFIDIC99RedYellow {
  NotifyClaim99RY = "NotifyClaim99RY",
  SubmitInterimDetailedClaim99RY = "SubmitInterimDetailedClaim99RY",
  SubmitFinalDetailedClaim99RY = "SubmitFinalDetailedClaim99RY",
  NotifyClaimNoticeInvalid99RY = "NotifyClaimNoticeInvalid99RY",
  NotifyClaimNoticeValidityHasLapsed99RY = "NotifyClaimNoticeValidityHasLapsed99RY",
  RequestFurtherParticulars99RY = "RequestFurtherParticulars99RY",
  ProvideFurtherParticulars99RY = "ProvideFurtherParticulars99RY",
  NotifyAgreementClaim99RY = "NotifyAgreementClaim99RY",
  NotifyDeterminationClaim99RY = "NotifyDeterminationClaim99RY",
  NotifyAgreementClaimNotReached99RY = "NotifyAgreementClaimNotReached99RY",
  RespondRegardingDetailedClaim99RY = "RespondRegardingDetailedClaim99RY",
}

export type ClaimActionTypeAll =
  | ClaimActionType
  | ClaimActionTypeFIDIC99RedYellow;

export const claimActionTypeFIDIC99RedYellowTypeMapping = (
  actionType: ClaimActionType
) => {
  switch (actionType) {
    case ClaimActionType.NotifyClaim:
      return ClaimActionTypeFIDIC99RedYellow.NotifyClaim99RY;
    case ClaimActionType.SubmitInterimDetailedClaim:
      return ClaimActionTypeFIDIC99RedYellow.SubmitInterimDetailedClaim99RY;
    case ClaimActionType.SubmitFinalDetailedClaim:
      return ClaimActionTypeFIDIC99RedYellow.SubmitFinalDetailedClaim99RY;
    case ClaimActionType.NotifyClaimNoticeInvalid:
      return ClaimActionTypeFIDIC99RedYellow.NotifyClaimNoticeInvalid99RY;
    case ClaimActionType.NotifyClaimNoticeValidityHasLapsed:
      return ClaimActionTypeFIDIC99RedYellow.NotifyClaimNoticeValidityHasLapsed99RY;
    case ClaimActionType.RequestFurtherParticulars:
      return ClaimActionTypeFIDIC99RedYellow.RequestFurtherParticulars99RY;
    case ClaimActionType.ProvideFurtherParticulars:
      return ClaimActionTypeFIDIC99RedYellow.ProvideFurtherParticulars99RY;
    case ClaimActionType.NotifyAgreementClaim:
      return ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaim99RY;
    case ClaimActionType.NotifyDeterminationClaim:
      return ClaimActionTypeFIDIC99RedYellow.NotifyDeterminationClaim99RY;
    case ClaimActionType.NotifyAgreementClaimNotReached:
      return ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaimNotReached99RY;
    case ClaimActionType.RespondRegardingDetailedClaim:
      return ClaimActionTypeFIDIC99RedYellow.RespondRegardingDetailedClaim99RY;
    default:
      return actionType;
  }
};
