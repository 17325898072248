import { UserPreferences, UserPreferencesInput } from "generated/graphql";

export const notificationPreferencesToInput = (
  preferences: UserPreferences
): UserPreferencesInput => {
  return {
    anyNewRecordedEW: {
      inApp: preferences.anyNewRecordedEW.inApp,
      email: preferences.anyNewRecordedEW.email,
    },
    anyNewRisk: {
      inApp: preferences.anyNewRisk.inApp,
      email: preferences.anyNewRisk.email,
    },
    anyNewSentEW: {
      inApp: preferences.anyNewSentEW.inApp,
      email: preferences.anyNewSentEW.email,
    },
    attachmentChanges: {
      inApp: preferences.attachmentChanges.inApp,
      email: preferences.attachmentChanges.email,
    },
    changesToAnyEW: {
      inApp: preferences.changesToAnyEW.inApp,
      email: preferences.changesToAnyEW.email,
    },
    changesToAnyRisk: {
      inApp: preferences.changesToAnyRisk.inApp,
      email: preferences.changesToAnyRisk.email,
    },
    commentsMentioned: {
      inApp: preferences.commentsMentioned.inApp,
      email: preferences.commentsMentioned.email,
    },
    commentsReplied: {
      inApp: preferences.commentsReplied.inApp,
      email: preferences.commentsReplied.email,
    },
    newComments: {
      inApp: preferences.newComments.inApp,
      email: preferences.newComments.email,
    },
    ownershipAssignment: {
      inApp: preferences.ownershipAssignment.inApp,
      email: preferences.ownershipAssignment.email,
    },
    anyNewEvent: {
      inApp: preferences.anyNewEvent.inApp,
      email: preferences.anyNewEvent.email,
    },
    anyNewRecordedInstruction: {
      inApp: preferences.anyNewRecordedInstruction.inApp,
      email: preferences.anyNewRecordedInstruction.email,
    },
    anyNewSentInstruction: {
      inApp: preferences.anyNewSentInstruction.inApp,
      email: preferences.anyNewSentInstruction.email,
    },
    changesToAnyEvent: {
      inApp: preferences.changesToAnyEvent.inApp,
      email: preferences.changesToAnyEvent.email,
    },
    changesToAnyInstruction: {
      inApp: preferences.changesToAnyInstruction.inApp,
      email: preferences.changesToAnyInstruction.email,
    },
    anyNewCEActionRecorded: {
      inApp: preferences.anyNewCEActionRecorded.inApp,
      email: preferences.anyNewCEActionRecorded.email,
    },
    anyNewCEActionSent: {
      inApp: preferences.anyNewCEActionSent.inApp,
      email: preferences.anyNewCEActionSent.email,
    },
    anyNewClaimActionRecorded: {
      inApp: preferences.anyNewClaimActionRecorded.inApp,
      email: preferences.anyNewClaimActionRecorded.email,
    },
    anyNewClaimActionSent: {
      inApp: preferences.anyNewClaimActionSent.inApp,
      email: preferences.anyNewClaimActionSent.email,
    },
    anyNewVariationActionSent: {
      inApp: preferences.anyNewVariationActionSent.inApp,
      email: preferences.anyNewVariationActionSent.email,
    },
    anyNewVariationActionRecorded: {
      inApp: preferences.anyNewVariationActionRecorded.inApp,
      email: preferences.anyNewVariationActionRecorded.email,
    },
    authorizationRequired: {
      inApp: preferences.authorizationRequired.inApp,
      email: preferences.authorizationRequired.email,
    },
    authorizedActionExecuted: {
      inApp: preferences.authorizedActionExecuted.inApp,
      email: preferences.authorizedActionExecuted.email,
    },
    authorizedActionPrevented: {
      inApp: preferences.authorizedActionPrevented.inApp,
      email: preferences.authorizedActionPrevented.email,
    },
  };
};
