import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DeleteConfirmationModal } from "components/DeleteConfirmationModal";
import {
  AddDailyDiaryGeneralRecordInput,
  DailyDiaryPresetSection,
  DailyDiaryGeneralRecord,
  EditDailyDiaryGeneralRecordInput,
  AttachmentInput,
} from "generated/graphql";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { BaseHeaderSection } from "../BaseSectionHeader";
import { GeneralRecordModal } from "./GeneralRecordModal/GeneralRecordModal";
import { GeneralTable } from "./GeneralTable/GeneralTable";
import { useGeneralSection } from "./useGeneralSection";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { ImportGeneralRecordsModal } from "./ImportGeneralRecordsModal";
import { useDiaryMetadata } from "../../hooks/useDiaryMetadata";

export type GeneralSectionProps = {
  section: DailyDiaryPresetSection;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ section }) => {
  const { t } = useTranslation();

  const [updatedAttachments, setUpdatedAttachments] =
    useState<AttachmentInput[]>();

  const { dailyDiaryId: dailyDiaryIdSearchParam } = useDiaryMetadata();

  const [recordToDeleteEdit, setRecordToDeleteEdit] =
    useState<DailyDiaryGeneralRecord>();
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const {
    modalVisibility: importModalVisibility,
    toggleModalVisibility: toggleImportModalVisibility,
  } = useBasicModal();

  const {
    dailyDiary,
    emptyDailyDiary,
    loading: contextLoading,
    refetchDDByDate,
  } = useContext(DailyDiaryContext);
  const { modalVisibility, toggleModalVisibility } = useBasicModal();
  const {
    generalRecords,
    loading: generalRecordsLoading,
    addGeneralRecord,
    editGeneralRecord,
    removeGeneralRecord,
    refetchDDGeneralRecords,
  } = useGeneralSection(dailyDiary);

  const toggleConfirmationModalVisibility = () => {
    setConfirmationModalVisibility((crtState) => !crtState);
  };

  const handleAddGeneralRecord = () => {
    toggleModalVisibility();
  };

  const triggerEditRecordFlow = (recordId: GridRowId) => {
    const record = generalRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    toggleModalVisibility();
  };

  const handleGeneralModalClose = () => {
    toggleModalVisibility();
    setRecordToDeleteEdit(undefined);
  };

  const triggerRemoveFlow = (recordId: GridRowId) => {
    const record = generalRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    setConfirmationModalVisibility(true);
  };

  const handleAttachmentsUpdated = (attachmentInputs: AttachmentInput[]) => {
    setUpdatedAttachments(attachmentInputs);
  };

  const handleModalPrimaryClick = async (
    generalRecord:
      | AddDailyDiaryGeneralRecordInput
      | EditDailyDiaryGeneralRecordInput,
    closeModal: boolean
  ) => {
    if (recordToDeleteEdit) {
      await editGeneralRecord({
        variables: {
          input: {
            ...(generalRecord as EditDailyDiaryGeneralRecordInput),
            id: recordToDeleteEdit.id,
            attachments:
              updatedAttachments ??
              attachmentsToAttachmentInputs(
                recordToDeleteEdit.attachments ?? []
              ),
          },
        },
      });
    } else {
      await addGeneralRecord({
        variables: {
          input: {
            ...(generalRecord as AddDailyDiaryGeneralRecordInput),
            ...(dailyDiary?.id
              ? { dailyDiaryId: dailyDiary.id }
              : {
                  date: emptyDailyDiary?.date,
                  productInstanceId: emptyDailyDiary?.productInstanceId,
                }),
            attachments: updatedAttachments ?? [],
          },
        },
      });
    }
    setUpdatedAttachments(undefined);

    if (closeModal) {
      handleGeneralModalClose();
    }
  };

  const handleSuccessfulImport = () => {
    toggleImportModalVisibility();
    if (dailyDiaryIdSearchParam) {
      refetchDDGeneralRecords();
    } else {
      refetchDDByDate?.();
    }
  };

  const handleRemoveRecord = () => {
    toggleConfirmationModalVisibility();
    removeGeneralRecord({ variables: { id: recordToDeleteEdit?.id! } });
    setRecordToDeleteEdit(undefined);
  };

  useEffect(() => {
    if (recordToDeleteEdit && !generalRecords.includes(recordToDeleteEdit)) {
      const updatedRecord = generalRecords.find(
        (rec) => rec.id === recordToDeleteEdit.id
      );
      if (updatedRecord) {
        setRecordToDeleteEdit(updatedRecord);
      }
    }
  }, [generalRecords, recordToDeleteEdit]);

  useEffect(() => {
    if (!recordToDeleteEdit) {
      setUpdatedAttachments(undefined);
    }
  }, [recordToDeleteEdit]);

  return (
    <>
      <DeleteConfirmationModal
        title={t("Projects.DailyDiaries.RemoveConfirmationModal.title")}
        subtitle={t("Projects.DailyDiaries.RemoveConfirmationModal.subtitle")}
        open={confirmationModalVisibility}
        onPrimaryClick={handleRemoveRecord}
        onSecondaryClick={toggleConfirmationModalVisibility}
      />
      {importModalVisibility && (
        <ImportGeneralRecordsModal
          open={importModalVisibility}
          loading={contextLoading}
          onClose={toggleImportModalVisibility}
          onImportSuccess={handleSuccessfulImport}
        />
      )}
      <GeneralRecordModal
        open={modalVisibility}
        onPrimaryClick={handleModalPrimaryClick}
        onSecondaryClick={handleGeneralModalClose}
        onClose={handleGeneralModalClose}
        onAttachmentsUpdated={handleAttachmentsUpdated}
        primaryBtnLoading={generalRecordsLoading}
        section={section}
        attachmentsPreview={
          updatedAttachments && recordToDeleteEdit
            ? recordToDeleteEdit.attachments?.filter((attach) =>
                updatedAttachments.find(
                  (crtAttach) => crtAttach.id === attach.id
                )
              )
            : undefined
        }
        generalRecord={recordToDeleteEdit}
      />
      <Box width="100%">
        <BaseHeaderSection
          title={t("Projects.DailyDiaries.sections.general")}
          onAdd={handleAddGeneralRecord}
          onImport={toggleImportModalVisibility}
          mb={3}
        />
        <GeneralTable
          records={generalRecords}
          loading={generalRecordsLoading || contextLoading}
          onEdit={triggerEditRecordFlow}
          onRemove={triggerRemoveFlow}
        />
      </Box>
    </>
  );
};
