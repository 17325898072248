import { Box, Color, css, styled, Typography, useTheme } from "@mui/material";
import { StatusIndicator } from "containers/Projects/components/StatusIndicator";
import { Status } from "types/decl";
import React from "react";

export type ListItemProps = {
  status: Status;
  userName: string;
  userCompanyName: string;
  userJobTitle: string;
  selected?: boolean;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

const ListItemContainer = styled(Box)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};

    &:hover {
      background-color: ${selected
        ? (theme.palette.primary as Partial<Color>)[100]
        : theme.palette.grey[100]};
      cursor: pointer;
    }

    ${selected &&
    css`
      background-color: ${(theme.palette.primary as Partial<Color>)[100]};
      color: ${(theme.palette.primary as Partial<Color>)[600]};
    `}
  `
);

export const ListItem: React.FC<ListItemProps> = ({
  status,
  userName,
  userCompanyName,
  userJobTitle,
  selected,
  onSelect,
}) => {
  const theme = useTheme();

  return (
    <ListItemContainer onClick={onSelect} selected={selected}>
      <Box display="flex" alignItems="center">
        <StatusIndicator status={status} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pl={1}
          justifyContent="space-around"
        >
          <Typography
            variant="p2"
            mb={0.25}
            color={
              selected
                ? (theme.palette.primary as Partial<Color>)[600]
                : theme.palette.grey[800]
            }
            fontWeight={selected ? 600 : 400}
          >
            {userName}
          </Typography>
          <Typography
            variant="p3"
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth="200px"
            noWrap
          >
            {userJobTitle}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="p3"
        color={
          selected
            ? (theme.palette.primary as Partial<Color>)[600]
            : theme.palette.grey[800]
        }
        overflow="hidden"
        textOverflow="ellipsis"
        maxWidth="200px"
        noWrap
      >
        {userCompanyName}
      </Typography>
    </ListItemContainer>
  );
};
