import { useMemo } from "react";
import { useVariationProposalExplorerItems } from "../../hooks/useVariationProposalExplorerItems";

export const useFinalVariationDetermination = () => {
  const { variationDeterminations, loading } =
    useVariationProposalExplorerItems();

  const finalDetermination = useMemo(() => {
    return variationDeterminations.sort((determination1, determination2) => {
      const date1 = new Date(determination1.dateCreated as string).getTime();
      const date2 = new Date(determination2.dateCreated as string).getTime();

      return date2 - date1;
    })[0];
  }, [variationDeterminations]);

  return {
    finalDetermination,
    loading,
  };
};
