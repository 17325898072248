import { gql } from "@apollo/client";
import { companyLiteFields } from "graphql/fragments/companyLite.fragment";

export const addCompanyMutation = gql`
  ${companyLiteFields}

  mutation addCompany($input: AddCompanyInput!) {
    addCompany(input: $input) {
      ...CompanyLiteFields
    }
  }
`;
