import { gql } from "@apollo/client";
import { productItemAuthWorkflowAuditFields } from "containers/Projects/fragments/productItemAuthWorkflowAudit.fragment";
import { variationItemFields } from "graphql/fragments/variationItem.fragment";

export const localVariationItemQuery = gql`
  ${variationItemFields}
  ${productItemAuthWorkflowAuditFields}

  query localVariationItem($id: ID!) {
    variationItem(id: $id) {
      ...VariationItemFields
      ongoingAuthorizationAudit {
        ...ProductItemAuthWorkflowAuditFields
      }
    }
  }
`;
