import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
} from "@apollo/client";

export type FormPublicApi = {
  validate: () => boolean;
  reset: () => void;
};

export enum Status {
  Active = "Active",
  Suspended = "Suspended",
  Removed = "Removed",
  Offline = "Offline",
  Closed = "Closed",
}

export type OnMutationDone = MutationUpdaterFunction<
  any,
  any,
  DefaultContext,
  ApolloCache<any>
>;
