import { gql } from "@apollo/client";
import { contractKeyDateFields } from "graphql/fragments/contractKeyDate.fragment";
import { contractSectionFields } from "graphql/fragments/contractSection.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const contractQuery = gql`
  ${creatorFields}
  ${contractSectionFields}
  ${contractKeyDateFields}

  query contract($id: ID!) {
    contract(id: $id) {
      id
      name
      friendlyName
      number
      valueCurrency
      value
      startDate
      endDate
      contractTypeId
      projectId
      country
      province
      timeZone
      coordinatesLatitude
      coordinatesLongitude
      status
      dateCreated
      contractType {
        id
        description
        subType
        version
        status
        contractBindingTypes {
          items {
            id
            description
            status
          }
        }
      }
      creator {
        ...CreatorFields
      }
      sections {
        ...ContractSectionFields
      }
      keyDates {
        ...ContractKeyDateFields
      }
      contractBindings {
        items {
          id
          status
          defaultCompany
          representativeId
          isCompanyBilled
          dateCreated
          creatorId
          creator {
            ...CreatorFields
          }
          companyId
          company {
            id
            registeredName
            tradingName
          }
          contractBindingType {
            id
            description
          }
          representative {
            ...CreatorFields
          }
        }
      }
      productInstances {
        items {
          id
          description
          numberingFormat
          soloModeSupported
          contractId
          productId
          productSchemaId
          productSchema {
            id
            name
          }
          statusCollectionId
          # statusCollection {
          #  id
          #  name
          # }
          status
          dateCreated
          creatorId
          creator {
            ...CreatorFields
          }
          # roles: ProductUserRoleList!
          #contract: Contract!
          product {
            id
            name
            #numberingFormat
            #soloModeSupported
            #allowMultipleInstances
            #status
            #dateCreated
            #creatorId
            #creator: User!
            #productSchemas: ProductSchemaList!
            #statusCollections: ItemStatusCollectionList!
            #roles: ProductRoleList!
          }
        }
      }
      templateRoutesOverride {
        items {
          id
          productOutputActionId
          contractTypeId
          originatingPartyId
          contractId
          targetPartyId
          defaultNumbering
          clause
          documentTemplateId
          isInternal
          dateCreated
          creatorId
          outputAction {
            id
            name
            product {
              id
              name
              status
            }
          }
          contractType {
            id
            description
            subType
            version
            status
          }
          originatingParty {
            id
            description
            status
          }
          targetParty {
            id
            description
            status
          }
          documentTemplate {
            id
            name
            description
            content
            status
          }
        }
      }
    }
  }
`;

export const editContractMutation = gql`
  ${creatorFields}
  ${contractSectionFields}
  ${contractKeyDateFields}

  mutation editContract($input: EditContractInput!) {
    editContract(input: $input) {
      id
      name
      friendlyName
      number
      valueCurrency
      value
      startDate
      endDate
      contractTypeId
      projectId
      country
      province
      timeZone
      coordinatesLatitude
      coordinatesLongitude
      status
      dateCreated
      creator {
        ...CreatorFields
      }
      contractBindings {
        items {
          id
          status
          defaultCompany
          representativeId
          isCompanyBilled
          dateCreated
          creatorId
          creator {
            ...CreatorFields
          }
          companyId
        }
      }
      sections {
        ...ContractSectionFields
      }
      keyDates {
        ...ContractKeyDateFields
      }
    }
  }
`;
