import { ActionsDialogProps } from "components/ActionsDialog";
import { VariationActionType } from "generated/graphql";
import { useSendRequestRevisedProposalModal } from "./useSendRequestRevisedProposalModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { SendAction } from "../components/SendAction";
import { useTranslation } from "react-i18next";

export type SendRequestRevisedProposalModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendRequestRevisedProposalModal: React.FC<
  SendRequestRevisedProposalModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { t } = useTranslation();
  const { variationPreview, contract } =
    useSendRequestRevisedProposalModal(remarks);
  return (
    <>
      <SendAction
        variationPreview={variationPreview}
        actionType={
          VariationActionType.RequestRevisedProposalForProposedVariation
        }
        summaryEl={
          <ReasonsAttachmentsSummary
            text={remarks}
            label={t("common.labels.remarks")}
            attachments={attachments}
            contractTimezone={contract.timeZone}
          />
        }
        {...restDialogProps}
      />
    </>
  );
};
