import { Box, Stack, Typography } from "@mui/material";
import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { AuthorizationProgress } from "components/Authorization/AuthorizationProgress/AuthorizationProgress";
import { Path } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { IconContainer } from "components/IconContainer";
import { AuthorizeDenyActionView } from "containers/Home/components/Authorizations/AuthorizeDenyActionView/AuthorizeDenyActionView";
import { useAuthAudit } from "../../../containers/Home/components/Authorizations/AuthorizeDenyActionView/hooks/useAuthAudit/useAuthAudit";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";

export type AuditTrailModalProps = {
  authAuditId: string;
  onClose: () => void;
} & Omit<BasicModalProps, "onClose">;

export const AuditTrailModal: React.FC<AuditTrailModalProps> = ({
  authAuditId,
  onClose,
  ...modalProps
}) => {
  const { t } = useTranslation();

  const { authAudit, loading: authAuditLoading } = useAuthAudit(authAuditId);

  return (
    <BasicModal
      headerIcon={
        <IconContainer>
          <Path size={24} />
        </IconContainer>
      }
      title={
        <Stack>
          <Typography variant="h3" color="grey.800">
            {t("Authorizations.AuditTrailModal.title")}
          </Typography>
          <Typography variant="p1" color="grey.700" mt={1}>
            {t("Authorizations.AuditTrailModal.subtitle")}
          </Typography>
        </Stack>
      }
      maxWidth="md"
      contentProps={{
        pt: 1,
      }}
      {...modalProps}
      onClose={onClose}
    >
      <Box>
        {authAuditLoading || !authAudit ? (
          <CenteredLoadingIndicator />
        ) : (
          <>
            <AuthorizationProgress
              levels={authAudit.workflow.levels}
              authAudit={authAudit}
            />
            <Box mt={3}>
              <AuthorizeDenyActionView
                authAuditId={authAuditId}
                authAudit={authAudit}
                onClose={onClose}
              />
            </Box>
          </>
        )}
      </Box>
    </BasicModal>
  );
};
