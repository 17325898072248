import {
  ExtendedOptionStyles,
  StatusOptionNew,
} from "components/StatusTag/StatusTagNew";
import { VariationStatus } from "generated/graphql";

export const variationStatusOptions: StatusOptionNew<string>[] = [
  {
    id: VariationStatus.Abandoned,
    label: `Projects.Variations.status.${VariationStatus.Abandoned}`,
    style: ExtendedOptionStyles.Grey,
  },
  {
    id: VariationStatus.Agreed,
    label: `Projects.Variations.status.${VariationStatus.Agreed}`,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: VariationStatus.Instructed,
    label: `Projects.Variations.status.${VariationStatus.Instructed}`,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: VariationStatus.None,
    label: `Projects.Variations.status.${VariationStatus.None}`,
    style: ExtendedOptionStyles.Black,
  },
  {
    id: VariationStatus.Priced,
    label: `Projects.Variations.status.${VariationStatus.Priced}`,
    style: ExtendedOptionStyles.Amber,
  },
  {
    id: VariationStatus.Proposed,
    label: `Projects.Variations.status.${VariationStatus.Proposed}`,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: VariationStatus.Determined,
    label: `Projects.Variations.status.${VariationStatus.Determined}`,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: VariationStatus.Revoked,
    label: `Projects.Variations.status.${VariationStatus.Revoked}`,
    style: ExtendedOptionStyles.Grey,
  },
];
