import { gql } from "@apollo/client";
import { newClaimTypeOverrideFields } from "graphql/fragments/newClaimType.fragment";

export const addNewClaimTypeOverrideMutation = gql`
  ${newClaimTypeOverrideFields}

  mutation addNewClaimTypeOverride($input: AddNewClaimTypeOverrideInput!) {
    addNewClaimTypeOverride(input: $input) {
      ...NewClaimTypeOverrideFields
    }
  }
`;
