import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";
import { dailyDiaryHSERecordLiteFields } from "graphql/fragments/dailyDiaryHSERecordLite.fragment";

export const dailyDiaryHSERecordsQuery = gql`
  ${dailyDiaryHSERecordLiteFields}
  ${attachmentFields}

  query dailyDiaryHSERecords($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryHSERecords(dailyDiaryId: $dailyDiaryId, revisionId: $revisionId) {
      ...DailyDiaryHSERecordLiteFields
      attachments {
        ...AttachmentFields
      }
    }
  }
`;

export const dailyDiaryHSEImportSourceItemsQuery = gql`
  query dailyDiaryHSEImportSourceItems(
    $productInstanceId: ID!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    dailyDiaryHSEImportSourceItems(
      productInstanceId: $productInstanceId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      items {
        id
        productInstanceId
        date
        number
        status
        dateCreated
        creatorId
        sentById
        dateSent
        latestRevision {
          id
          number
          status
          dateSent
        }
      }
    }
  }
`;

export const importDailyDiaryHSERecordsMutation = gql`
  mutation importDailyDiaryHSERecords($input: ImportDailyDiaryRecordsInput!) {
    importDailyDiaryHSERecords(input: $input)
  }
`;
