import { gql } from "@apollo/client";

export const productLiteFields = gql`
  fragment ProductLiteFields on Product {
    id
    name
    numberingFormat
    soloModeSupported
    allowMultipleInstances
    status
    # productSchemas
    # statusCollections: ItemStatusCollectionList!
    # roles: ProductRoleList!
    # outputActions: ProductOutputActionList!
  }
`;
