import { Box, Typography } from "@mui/material";
import { ProfilePicture } from "components/UserProfileButton/ProfilePicture";
import { User } from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";

export const UserInfo: React.FC<{ authenticatedUser: User }> = ({
  authenticatedUser,
}) => {
  return (
    <Box display="flex">
      <ProfilePicture
        photoUrl={authenticatedUser.profilePicture ?? undefined}
        width="56px"
        height="56px"
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex={1}
        ml={2}
      >
        <Typography
          variant="h4"
          fontWeight={600}
          color="grey.900"
          fontSize="18px"
        >
          {getUserName(authenticatedUser)}
        </Typography>
        <Typography variant="p3" fontSize="14px" color="grey.900">
          {`${authenticatedUser.jobTitle} at ${authenticatedUser.company.tradingName}`}
        </Typography>
      </Box>
    </Box>
  );
};
