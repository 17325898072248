import { ProductRole } from "generated/graphql";
import { useHasAccess } from "hooks/useHasAccess";

export type PermissionsGateProps = {
  children: React.ReactNode;
  /**
   * Roles that have access to this view
   */
  allowedRoles?: ProductRole | ProductRole[];
  /**
   * Only users that have these required permissions would be able to the view
   */
  requiredPermissions?: string[];
  /**
   * If set, PermissionsGate will look for permissions only under the specified productInstance
   */
  productInstanceId?: string;
  /**
   * If set to true/false, it'll be taken into account when computing the permissionGranted flag. Otherwise it'll be ignored
   */
  fallbackCheck?: boolean;
};

export const PermissionsGate: React.FC<PermissionsGateProps> = ({
  children,
  allowedRoles = [],
  requiredPermissions,
  productInstanceId,
  fallbackCheck,
}) => {
  let permissionGranted = useHasAccess(
    allowedRoles,
    requiredPermissions,
    productInstanceId ?? undefined
  );

  if (typeof fallbackCheck === "boolean") {
    permissionGranted = permissionGranted || fallbackCheck;
  }

  if (!permissionGranted) return <></>;

  return <>{children}</>;
};
