import { Box, useTheme } from "@mui/material";
import { OverflowTooltip } from "components/OverflowTooltip";

export const CreatedByColumnRenderer: React.FC<{ createdBy?: string }> = ({
  createdBy,
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" width="100%">
      <OverflowTooltip
        title={createdBy}
        typographyProps={{
          variant: "p2",
          color: theme.palette.grey[600],
        }}
      />
    </Box>
  );
};
