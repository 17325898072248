import { Box, Stack, css, styled } from "@mui/material";
import { unofficialThemeColors } from "theme/extendedTheme";

export const ActionDetailsContainer = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.grey[300]};
    border-radius: 12px;
  `
);

export const ActionMetaDataContainer = styled(Stack)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    border-radius: 8px;
    border: 1px solid rgba(24, 106, 222, 0.3); // Note: this is info.main color, but with 0,30 opacity
    background-color: ${unofficialThemeColors.bluePale}; // note: the same color used in notifications
  `
);
