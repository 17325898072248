import { Card } from "components/Card";

export const RHSCard: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Card sx={{ pt: 0 }} overflow="auto" maxHeight="100%" height="100%">
      {children}
    </Card>
  );
};
