import { Box, css, styled } from "@mui/material";

export type CountBadgeProps = {
  count: number;
  size?: "small" | "medium";
};

const CountBadgeContainer = styled(Box)<{ size: "small" | "medium" }>(
  ({ theme, size = "small" }) => css`
    height: ${size === "small" ? "23px" : "32px"};
    width: ${size === "small" ? "23px" : "32px"};
    background: ${theme.palette.error.main};
    color: ${theme.palette.common.white};
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;

    position: absolute;
    top: ${size === "small" ? "-5px" : "-10px"};
    right: ${size === "small" ? "-5px" : "-10px"};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  `
);

export const CountBadge: React.FC<CountBadgeProps> = ({ count, size }) => {
  const autoComputedSize = count > 100 ? "medium" : "small";
  const computedSize = size ?? autoComputedSize;

  return (
    <CountBadgeContainer size={computedSize}>
      {count >= 100 ? "99+" : count}
    </CountBadgeContainer>
  );
};
