import {
  AuthorizationWorkflow,
  AuthorizationWorkflowsQuery,
  AuthorizationWorkflowsQueryVariables,
  ChangeAuthorizationWorkflowStatusMutation,
  ChangeAuthorizationWorkflowStatusMutationVariables,
} from "generated/graphql";
import { changeAuthorizationWorkflowStatusMutation } from "graphql/mutations/changeAuthorizationWorkflowStatus";
import { authorizationWorkflowsQuery } from "graphql/queries/authorizationWorkflows.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useTranslation } from "react-i18next";

export const useProjectAuthorizationWorkflows = (projectId: string) => {
  const { t } = useTranslation();

  const {
    data: projectAuthorizationWofklows,
    loading: projectAuthorizationWofklowsLoading,
    refetch,
  } = useGraphQuery<
    AuthorizationWorkflowsQuery,
    AuthorizationWorkflowsQueryVariables
  >(authorizationWorkflowsQuery, {
    variables: { projectId },
  });

  const [
    changeAuthorizationWorkflowStatus,
    { loading: changeAuthorizationWorkflowStatusLoading },
  ] = useGraphMutation<
    ChangeAuthorizationWorkflowStatusMutation,
    ChangeAuthorizationWorkflowStatusMutationVariables
  >(
    changeAuthorizationWorkflowStatusMutation,
    {
      update: (cache) => {
        refetch();

        cache.evict({ id: "ROOT_QUERY", fieldName: "authorizationWorkflows" });
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.AuthorizationWorkflows.authorizationWorkflow"),
    })
  );

  return {
    authorizationWorkflows: (projectAuthorizationWofklows
      ?.authorizationWorkflows?.items ?? []) as AuthorizationWorkflow[],
    loading:
      projectAuthorizationWofklowsLoading ||
      changeAuthorizationWorkflowStatusLoading,
    changeAuthorizationWorkflowStatus,
  };
};
