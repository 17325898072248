import { useTheme } from "@mui/material";
import {
  AuthorizationWorkflowStatus,
  ClaimTypeStatus,
  CompanyLookupCollectionStatus,
  ContractBindingStatus,
  ContractBindingTypeStatus,
  ContractKeyDateStatus,
  ContractSectionStatus,
  DocumentTemplateStatus,
  EffectOptionStatus,
  EffectPresetStatus,
  ItemStatusCollectionStatus,
  ItemStatusOptionReasonStatus,
  ItemStatusOptionStatus,
  LookupCollectionStatus,
  LookupOptionStatus,
  ProductUserRoleStatus,
  SchemaFieldStatus,
  SchemaSectionStatus,
  TimePeriodStatus,
} from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StatusOption } from "./StatusTag";

enum ActiveRemoved {
  Active = "Active",
  Removed = "Removed",
}

/**
 * Valid for any enum of type Active & Removed
 * @returns Array of valid options for <StatusTag />
 */
export const useActiveRemovedStatusOptions = (): StatusOption<
  | ActiveRemoved
  | ContractBindingStatus
  | ContractBindingTypeStatus
  | SchemaSectionStatus
  | SchemaFieldStatus
  | ItemStatusCollectionStatus
  | ItemStatusOptionStatus
  | ItemStatusOptionReasonStatus
  | ProductUserRoleStatus
  | EffectPresetStatus
  | EffectOptionStatus
  | DocumentTemplateStatus
  | LookupCollectionStatus
  | LookupOptionStatus
  | ContractSectionStatus
  | ContractKeyDateStatus
  | TimePeriodStatus
  | ClaimTypeStatus
  | CompanyLookupCollectionStatus
  | AuthorizationWorkflowStatus
>[] => {
  const theme = useTheme();
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(ActiveRemoved).map((status) => ({
      id: status,
      label: t(`Enums.ActiveRemovedStatus.${status}`),
      color:
        status === ActiveRemoved.Active
          ? theme.palette.primary.main
          : theme.palette.grey[700],
    }));
  }, [theme, t]);

  return options;
};
