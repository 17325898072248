import { Box, Typography, css, styled } from "@mui/material";
import { AuthorizationWorkflowLevelMode } from "generated/graphql";
import { useTranslation } from "react-i18next";

type ReviewersLevelHeaderProps = {
  levelNo: number;
  authMode: AuthorizationWorkflowLevelMode;
};

const HeaderLine = styled(Box)(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.grey[300]};
    width: 100%;
    position: absolute;
  `
);

const HeaderTextContainer = styled(Box)`
  position: absolute;
  top: -13px;
  width: 100%;
  text-align: center;
`;

export const ReviewersLevelHeader: React.FC<ReviewersLevelHeaderProps> = ({
  levelNo,
  authMode,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <HeaderLine />
      <HeaderTextContainer>
        <Typography
          variant="caption3"
          color="grey.500"
          sx={{ background: "white", px: 1 }}
        >
          {t("Home.Authorizations.levelNumber", { number: levelNo })} -{" "}
          {t("Home.Authorizations.authorizationRuleNormal", {
            context:
              authMode === AuthorizationWorkflowLevelMode.AnyoneCanAuthorize
                ? "anyone"
                : "everyone",
          })}
        </Typography>
      </HeaderTextContainer>
    </Box>
  );
};
