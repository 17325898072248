import { Box, css, styled } from "@mui/material";

export const PageContentContainer = styled(Box)<{
  px?: number;
  py?: number;
  maxWidth?: string;
}>(
  ({ theme, px = 4, py = 4, maxWidth = "1300px" }) => css`
    max-width: ${maxWidth};
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    padding-left: ${theme.spacing(px)};
    padding-right: ${theme.spacing(px)};
    padding-top: ${theme.spacing(py)};
    padding-bottom: ${theme.spacing(py)};
  `
);
