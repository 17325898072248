import { ActionsDialogProps } from "components/ActionsDialog";
import { DraftVariationProposal, VariationActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendProposalForProposedVariationModal } from "./useSendProposalForProposedVariationModal";
import { VariationProposalAgreementDeterminationSummary } from "../components/VariationProposalAgreementDeterminationSummary";

export type SendProposalForProposedVariationModalProps = {
  draftProposals: DraftVariationProposal[];
  preferredProposalId: string;
} & ActionsDialogProps;

export const SendProposalForProposedVariationModal: React.FC<
  SendProposalForProposedVariationModalProps
> = ({ draftProposals, preferredProposalId, ...restDialogProps }) => {
  const { variationPreview, contract } =
    useSendProposalForProposedVariationModal(
      draftProposals,
      preferredProposalId
    );
  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.SubmitProposalForProposedVariation}
      summaryEl={
        <VariationProposalAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftProposals}
          type="VariationProposal"
        />
      }
      {...restDialogProps}
    />
  );
};
