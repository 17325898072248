import { OnMutationDone } from "types/decl";
import {
  AddLookupOptionMutation,
  AddLookupOptionMutationVariables,
  ChangeLookupOptionStatusMutation,
  ChangeLookupOptionStatusMutationVariables,
  EditLookupOptionMutation,
  EditLookupOptionMutationVariables,
} from "generated/graphql";
import { addLookupOptionMutation } from "graphql/mutations/addLookupOption";
import { changeLookupOptionStatusMutation } from "graphql/mutations/changeLookupOptionStatus";
import { editLookupOptionMutation } from "graphql/mutations/editLookupOption";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";

export type useLookupCollectionOptionsArgs = {
  onAdd?: OnMutationDone;
  onEdit?: OnMutationDone;
  onStatusChange?: OnMutationDone;
  showToastMessage?: boolean;
};

export const useLookupCollectionOptions = ({
  onAdd,
  onEdit,
  onStatusChange,
  showToastMessage = true,
}: useLookupCollectionOptionsArgs) => {
  const { t } = useTranslation();

  const [addLookupOption, { loading: addLookupOptionLoading }] =
    useGraphMutation<AddLookupOptionMutation, AddLookupOptionMutationVariables>(
      addLookupOptionMutation,
      {
        update: onAdd,
      },
      showToastMessage
        ? t("common.successMessages.entityCreated", {
            entity: t("AdminConsole.Lookups.lookupOption"),
          })
        : null
    );

  const [editLookupOption, { loading: editLookupOptionLoading }] =
    useGraphMutation<
      EditLookupOptionMutation,
      EditLookupOptionMutationVariables
    >(
      editLookupOptionMutation,
      {
        update: onEdit,
      },
      showToastMessage
        ? t("common.successMessages.entityUpdated", {
            entity: t("AdminConsole.Lookups.lookupOption"),
          })
        : null
    );

  const [
    changeLookupOptionStatus,
    { loading: changeLookupOptionStatusLoading },
  ] = useGraphMutation<
    ChangeLookupOptionStatusMutation,
    ChangeLookupOptionStatusMutationVariables
  >(
    changeLookupOptionStatusMutation,
    {
      update: onStatusChange,
    },
    showToastMessage
      ? t("common.successMessages.entityUpdated", {
          entity: t("AdminConsole.Lookups.lookupOption"),
        })
      : null
  );

  return {
    addLookupOption,
    editLookupOption,
    changeLookupOptionStatus,
    loading:
      changeLookupOptionStatusLoading ||
      addLookupOptionLoading ||
      editLookupOptionLoading,
  };
};
