import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";

export type EnumValue = {
  value: string;
  label: string;
};

export type EnumFieldProps = {
  options: EnumValue[];
  fieldDisplayText?: string;
  editMode?: boolean;
  fieldValue?: EnumValue;
  fieldError?: string;
  fieldTouched?: boolean;
  onChange: (selectedOption: string) => void;
};

export const EnumField: React.FC<EnumFieldProps> = ({
  options,
  fieldDisplayText,
  editMode,
  fieldValue,
  fieldError,
  fieldTouched,
  onChange,
}) => {
  const theme = useTheme();
  const handleChange = (event: SelectChangeEvent<string | null>) => {
    onChange(event.target.value!);
  };

  return editMode ? (
    <>
      <Select
        labelId="enum-select-label"
        id="enum-select"
        data-testid="enum-select"
        value={fieldValue?.value ?? ""}
        onChange={handleChange}
        error={!!fieldError && fieldTouched}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {fieldError && fieldTouched && (
        <Typography
          variant="caption"
          color="error"
          mt={0.5}
          data-testid="enum-error"
        >
          {fieldError}
        </Typography>
      )}
    </>
  ) : (
    <Typography
      variant="p1"
      color={theme.palette.grey[800]}
      data-testid={`${fieldDisplayText}-enu-readonly`}
    >
      {fieldValue?.label}
    </Typography>
  );
};
