import { Grid, css, styled } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers-pro";

export const GridDatePickerItem = styled(Grid)(
  ({ theme }) => css`
    .MuiPickersLayout-root {
      /** Note: because of the header's bottom margin of 16px, it's pushing the entire content down
      and on months with more days, the last row is cut */
      height: 354px;
      background-color: ${theme.palette.common.white};
    }
  `
);

export const ImportRecordsDatePicker = styled(StaticDatePicker)(
  ({ theme }) => css`
    .MuiDateCalendar-root {
      position: relative;
      width: 100%;
      background-color: ${theme.palette.common.white};
      overflow: visible;

      .MuiPickersCalendarHeader-root {
        border-bottom: 1px solid ${theme.palette.grey[200]};
        padding-bottom: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(2)};
      }

      .MuiPickersCalendarHeader-labelContainer {
        margin-left: auto;
        display: flex;
        justify-content: center;
      }

      .MuiPickersArrowSwitcher-root {
        button {
          position: absolute;
          top: 12px;
        }
        button:nth-of-type(1) {
          left: ${theme.spacing(2)};
        }
        button:nth-of-type(2) {
          right: ${theme.spacing(2)};
        }
      }

      .MuiDayCalendar-weekContainer,
      .MuiDayCalendar-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .MuiDayCalendar-weekContainer {
        button {
          font-size: ${theme.typography.p2.fontSize};
          font-weight: 600;

          &:not(.Mui-selected):not(.Mui-disabled) {
            color: ${theme.palette.grey[800]};
          }

          &:mui-disabled {
            color: ${theme.palette.grey[200]};
          }
        }
      }

      .MuiDayCalendar-header {
        span {
          color: ${theme.palette.grey[500]};
          font-size: ${theme.typography.caption3.fontSize};
          font-weight: 600;
        }
      }

      .MuiYearCalendar-root {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  `
);
