import { DataValidators, ValidatorType } from "helpers/validators";
import { useMemo } from "react";
import {
  AddDailyDiaryWorkRecordInput,
  DailyDiaryPresetSection,
  EditDailyDiaryWorkRecordInput,
} from "generated/graphql";
import { WorkLookups } from "../WorkTable/WorkTable";

export const useWorkRecordFormValidators = (
  section: DailyDiaryPresetSection,
  formData: AddDailyDiaryWorkRecordInput | EditDailyDiaryWorkRecordInput,
  workLookups?: WorkLookups
) => {
  const dataValidators: DataValidators = useMemo(() => {
    // DESCRIPTION
    const descriptionField = section.fields.find(
      (field) => field.name === "Description"
    );

    const descriptionColValidators: DataValidators | undefined =
      descriptionField &&
      !descriptionField.isHidden &&
      descriptionField.isRequired
        ? {
            description: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    // SHIFT
    const shiftField = section.fields.find((field) => field.name === "Shift");

    const shiftColValidators: DataValidators | undefined =
      shiftField && !shiftField.isHidden
        ? {
            shiftOptionId: {
              validators: [
                shiftField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.shiftOptionId,
                workLookups?.Shift.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // AREA
    const areaField = section.fields.find((field) => field.name === "Area");

    const areaColValidators: DataValidators | undefined =
      areaField && !areaField.isHidden
        ? {
            areaOptionId: {
              validators: [
                areaField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.areaOptionId,
                workLookups?.Area.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // DISCIPLINE
    const disciplineField = section.fields.find(
      (field) => field.name === "Discipline"
    );

    const disciplineColValidators: DataValidators | undefined =
      disciplineField && !disciplineField.isHidden
        ? {
            disciplineOptionId: {
              validators: [
                disciplineField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.disciplineOptionId,
                workLookups?.Discipline.options.items?.map(
                  (option) => option.id
                ),
              ],
            },
          }
        : undefined;

    // TEAM
    const teamField = section.fields.find((field) => field.name === "Team");

    const teamColValidators: DataValidators | undefined =
      teamField && !teamField.isHidden
        ? {
            teamOptionId: {
              validators: [
                teamField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.teamOptionId,
                workLookups?.Team.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // ACTIVITY
    const activityField = section.fields.find(
      (field) => field.name === "Activity"
    );

    const activityColValidators: DataValidators | undefined =
      activityField && !activityField.isHidden
        ? {
            activityOptionId: {
              validators: [
                activityField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.activityOptionId,
                workLookups?.Activity.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // WORK TYPE
    const workTypeField = section.fields.find(
      (field) => field.name === "WorkType"
    );

    const workTypeColValidators: DataValidators | undefined =
      workTypeField && !workTypeField.isHidden
        ? {
            workTypeOptionId: {
              validators: [
                workTypeField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.workTypeOptionId,
                workLookups?.WorkType.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // UNIT OF MEASUREMENT
    const unitOfMeasurementField = section.fields.find(
      (field) => field.name === "UnitOfMeasurement"
    );

    const unitOfMeasurementColValidators: DataValidators | undefined =
      unitOfMeasurementField && !unitOfMeasurementField.isHidden
        ? {
            unitOfMeasurementOptionId: {
              validators: [
                unitOfMeasurementField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.unitOfMeasurementOptionId,
                workLookups?.UnitOfMeasurement.options.items?.map(
                  (option) => option.id
                ),
              ],
            },
          }
        : undefined;

    // AMOUNT
    const amountField = section.fields.find((field) => field.name === "Amount");

    const amountColValidators: DataValidators | undefined =
      amountField && !amountField.isHidden && amountField.isRequired
        ? {
            amount: {
              validators: [ValidatorType.PositiveNumberOrZero],
            },
          }
        : undefined;

    return {
      ...descriptionColValidators,
      ...shiftColValidators,
      ...areaColValidators,
      ...disciplineColValidators,
      ...teamColValidators,
      ...activityColValidators,
      ...workTypeColValidators,
      ...unitOfMeasurementColValidators,
      ...amountColValidators,
    };
  }, [section, workLookups, formData]);

  return dataValidators;
};
