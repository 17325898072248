import { gql } from "@apollo/client";

export const productQuery = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      name
      numberingFormat
      soloModeSupported
      allowMultipleInstances
      status
      productSchemas {
        items {
          id
          name
          productId
          status
          creatorId
          isDefault
          creator {
            id
            firstname
            surname
          }
          dateCreated
        }
      }
      statusCollections {
        items {
          id
          name
          isDefault
          productId
          status
          creatorId
          creator {
            id
            firstname
            surname
          }
          dateCreated
        }
      }
    }
  }
`;

export const addProductSchemaMutation = gql`
  mutation addProductSchema($input: AddProductSchemaInput!) {
    addProductSchema(input: $input) {
      id
      name
      isDefault
      productId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;
