import {
  FormControl,
  Grid,
  Box,
  Typography,
  Select,
  MenuItem,
  useTheme,
  SelectChangeEvent,
} from "@mui/material";
import { AsteriskIcon } from "components/Icons/AsteriskIcon";
import { ItemStatusOptionReason } from "generated/graphql";
import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Textarea } from "../../../../components/TextArea";

export type ChangeProductItemFormValuesType = {
  reasonId?: string | null;
  remarks: string;
};

export type ChangeProductItemStatusFormProps = {
  reasons: ItemStatusOptionReason[];
  reasonId?: string | null;
  remarks: string;
  onChange: (values: ChangeProductItemFormValuesType) => void;
};

export const ChangeProductItemStatusForm: React.FC<
  ChangeProductItemStatusFormProps
> = ({ reasons, reasonId, remarks, onChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleReasonChange = (event: SelectChangeEvent<string | null>) => {
    onChange({ reasonId: event.target.value!, remarks });
  };

  const handleTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    onChange({ reasonId, remarks: event.target.value });
  };

  return (
    <Grid container spacing={3.5}>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <>
            <Box display="flex" alignItems="center">
              <Typography
                variant="p1"
                fontWeight={600}
                color={theme.palette.grey[800]}
                mb={1}
              >
                {t("Projects.Risks.ChangeStatusModal.reason")}
              </Typography>
              <Box alignSelf="flex-start">
                <AsteriskIcon />
              </Box>
            </Box>
            <Select
              labelId="reason-select-label"
              id="reason-select"
              value={reasonId ?? ""}
              onChange={handleReasonChange}
              placeholder={t("Projects.Risks.ChangeStatusModal.selectReason")}
              size="small"
              variant="outlined"
            >
              {reasons.map((reason) => (
                <MenuItem key={reason.id} value={reason.id}>
                  {reason.description}
                </MenuItem>
              ))}
            </Select>
          </>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <Typography
            variant="p1"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Projects.Risks.ChangeStatusModal.remarks")}
          </Typography>
          <Textarea value={remarks} onChange={handleTextareaChange} />
        </Box>
      </Grid>
    </Grid>
  );
};
