import { gql } from "@apollo/client";
import { contractTypeLiteFields } from "./contractTypeLite.fragment";
import { creatorFields } from "./creator.fragment";

export const claimTypeFields = gql`
  ${creatorFields}
  ${contractTypeLiteFields}

  fragment ClaimTypeFields on ClaimType {
    id
    contractTypeId
    clause
    description
    isInternal
    status
    dateCreated
    creatorId
    contractType {
      ...ContractTypeLiteFields
    }
    creator {
      ...CreatorFields
    }
  }
`;
