import { Timeline } from "@mui/lab";
import {
  ClaimHistoryItem,
  CompEventActionType,
  CompEventHistoryItem,
  Contract,
  ProductType,
  VariationHistoryItem,
} from "generated/graphql";
import { HistoryItemStatus, HistoryListItem } from "./HistoryListItem";
import { useTranslation } from "react-i18next";
import {
  ceActionTypeToHistoryItemTextMapping,
  ceActionTypesToItemStatusMapping,
  claimActionTypeToHistoryItemTextMapping,
  claimActionTypesToItemStatusMapping,
  variationActionTypeToHistoryItemTextMapping,
  variationActionTypesToItemStatusMapping,
} from "./HistoryModal.constants";
import {
  variationActionTypeFIDIC17WhiteTypeMapping,
  variationActionTypeFIDIC99RedYellowTypeMapping,
} from "../Variations/Variations.types";
import { claimActionTypeFIDIC99RedYellowTypeMapping } from "../Claims/types";

export type HistoryListProps = {
  isFIDIC17White?: boolean;
  isFIDIC99RedYellow?: boolean;
  historyItems:
    | CompEventHistoryItem[]
    | ClaimHistoryItem[]
    | VariationHistoryItem[];
  selectedHistoryItemId: string;
  productType:
    | ProductType.CompEvents
    | ProductType.Claims
    | ProductType.Variations;
  contract: Contract;
  onSelectedItemChange: (historyItemId: string) => void;
};

export const HistoryList: React.FC<HistoryListProps> = ({
  isFIDIC17White,
  isFIDIC99RedYellow,
  historyItems,
  contract,
  productType,
  selectedHistoryItemId,
  onSelectedItemChange,
}) => {
  const { t } = useTranslation();

  return (
    <Timeline position="right" sx={{ padding: 0, margin: 0 }}>
      {historyItems.map((historyItem, index) => {
        let title = "";
        let status: HistoryItemStatus;

        if (productType === ProductType.CompEvents) {
          // CE
          const crtHistoryItem = historyItem as CompEventHistoryItem;
          title = t(
            ceActionTypeToHistoryItemTextMapping[crtHistoryItem.actionType],
            [
              CompEventActionType.NotifySilenceCompEventNotice,
              CompEventActionType.NotifySilenceQuotation,
              CompEventActionType.NotifySilenceOwnAssessment,
            ].includes(crtHistoryItem.actionType)
              ? { party: crtHistoryItem.sentByParty?.description }
              : {}
          );
          status = ceActionTypesToItemStatusMapping[crtHistoryItem.actionType];
        } else if (productType === ProductType.Claims) {
          // Claim
          const crtHistoryItem = historyItem as ClaimHistoryItem;

          title = t(
            claimActionTypeToHistoryItemTextMapping[
              isFIDIC99RedYellow
                ? claimActionTypeFIDIC99RedYellowTypeMapping(
                    crtHistoryItem.actionType
                  )
                : crtHistoryItem.actionType
            ]
          );
          status =
            claimActionTypesToItemStatusMapping[crtHistoryItem.actionType];
        } else {
          // Variations
          const crtHistoryItem = historyItem as VariationHistoryItem;

          title = t(
            variationActionTypeToHistoryItemTextMapping[
              isFIDIC17White
                ? variationActionTypeFIDIC17WhiteTypeMapping(
                    crtHistoryItem.actionType
                  )
                : isFIDIC99RedYellow
                ? variationActionTypeFIDIC99RedYellowTypeMapping(
                    crtHistoryItem.actionType
                  )
                : crtHistoryItem.actionType
            ]
          );
          status =
            variationActionTypesToItemStatusMapping[crtHistoryItem.actionType];
        }

        return (
          <HistoryListItem
            historyItem={historyItem}
            position={
              historyItems.length === 1
                ? "start-end"
                : index === 0
                ? "start"
                : index === historyItems.length - 1
                ? "end"
                : "middle"
            }
            key={historyItem.id}
            selected={selectedHistoryItemId === historyItem.id}
            onClick={() => onSelectedItemChange(historyItem.id)}
            contract={contract}
            status={status}
            title={title}
          />
        );
      })}
    </Timeline>
  );
};
