import { DataValidators, ValidatorType } from "helpers/validators";

export const dataValidators: DataValidators = {
  name: {
    validators: [ValidatorType.Required],
  },
  optionsLockedForUsers: {
    validators: [ValidatorType.Required],
  },
};
