import { Box, Typography } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import {
  ClaimItem,
  CompEventItem,
  ProductType,
  VariationItem,
} from "generated/graphql";
import { claimStatusOptions } from "../Claims/ClaimsView/ClaimsView.constants";
import { compEventStatusOptions } from "../CompEvents/CompEventsView/CompEventsView.constants";
import { variationStatusOptions } from "../Variations/VariationsView/VariationsView.constants";

export type WidgetHeaderProps<
  T extends CompEventItem | ClaimItem | VariationItem
> = {
  productItem: T;
  productType:
    | ProductType.CompEvents
    | ProductType.Claims
    | ProductType.Variations;
};

export const WidgetHeader = <
  T extends CompEventItem | ClaimItem | VariationItem
>({
  productItem,
  productType,
}: WidgetHeaderProps<T>) => {
  return (
    <Box display="flex" alignItems="center">
      <ItemTag type={productType} />
      <Typography variant="h3" color="grey.800" fontWeight={400} mx={1}>
        {productItem.number}
      </Typography>
      <StatusTagNew
        selectedOptionId={productItem.status}
        disabled
        options={
          productType === ProductType.CompEvents
            ? compEventStatusOptions
            : productType === ProductType.Claims
            ? claimStatusOptions
            : variationStatusOptions
        }
      />
    </Box>
  );
};
