import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import { temporaryRowId } from "constants/constants";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  computeContractTypeFullName,
  computeRowIds,
  documentTemplateRoutesToLocalDocumentTemplateRoutes,
  sortDocumentTemplateRoutes,
} from "./DocumentTemplateRoutes.utils";
import { getColumns, IdNameType } from "./DocumentTemplateRoutes.constants";
import { GridHeader } from "components/GridHeader";
import {
  DocumentTemplateRoutesProps,
  LocalDocumentTemplateRoute,
} from "./DocumentTemplateRoutes.decl";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import {
  AddDocumentTemplateRouteInput,
  AddDocumentTemplateRouteOverrideInput,
  EditDocumentTemplateRouteInput,
  EditDocumentTemplateRouteOverrideInput,
} from "generated/graphql";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { Files } from "phosphor-react";
import { adminConsolePageContentDifference } from "containers/AdminConsole/AdminConsole.constants";

export const DocumentTemplateRoutes: React.FC<DocumentTemplateRoutesProps> = ({
  documentTemplateRoutes,
  products,
  contractTypes,
  documentTemplates,
  loading,
  fixedContractType,
  contractId,
  contractName,
  collapsibleHeader,
  onAddDocumentTemplateRoute,
  onEditDocumentTemplateRoute,
  onRemoveDocumentTemplateRoute,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const addUpdateInProgress = useRef<boolean>(false);

  const [showRoutes, setShowRoutes] = useState(true);
  const [rows, setRows] = useState<LocalDocumentTemplateRoute[]>(
    sortDocumentTemplateRoutes(
      documentTemplateRoutesToLocalDocumentTemplateRoutes(
        documentTemplateRoutes
      )
    )
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(rows)
  );
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.product"), key: "product" },
      {
        header: t("AdminConsole.Templates.labels.outputAction"),
        key: "outputAction",
      },
      {
        header: t("common.labels.contractType"),
        key: "contractType",
      },
      {
        header: t("AdminConsole.Templates.labels.originatingParty"),
        key: "originatingParty",
      },
      {
        header: t("AdminConsole.Templates.labels.targetParty"),
        key: "targetParty",
      },
      {
        header: t("common.labels.documentTemplate"),
        key: "documentTemplate",
      },
    ];

    const rowsToExport = rows
      .filter((route) => (selectionModel || []).indexOf(route.id) >= 0)
      .map((route) => ({
        ...route,
        product: route.product?.name,
        outputAction: route.outputAction?.name,
        contractType: fixedContractType
          ? computeContractTypeFullName(fixedContractType)
          : route.contractType?.name,
        originatingParty: route.originatingParty?.name,
        targetParty: route.targetParty?.name,
        documentTemplate: route.documentTemplate?.name,
      }));

    exportToExcel(
      fixedContractType
        ? `${contractName} - ${t(
            "AdminConsole.Templates.labels.documentTemplateOverrides"
          )}`
        : t("AdminConsole.Navigation.labels.documentTemplateRouter"),
      columns,
      rowsToExport
    );
  };

  const handleProductChange = useCallback(
    (row: LocalDocumentTemplateRoute, newProduct: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              product: newProduct,
              outputAction: { id: "", name: "" },
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleOutputActionChange = useCallback(
    (row: LocalDocumentTemplateRoute, newOutputAction: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              outputAction: newOutputAction,
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleContractTypeChange = useCallback(
    (row: LocalDocumentTemplateRoute, newContractType: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              contractType: newContractType,
              originatingParty: { id: "", name: "" },
              targetParty: { id: "", name: "" },
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleOriginatingPartyChange = useCallback(
    (row: LocalDocumentTemplateRoute, newOriginatingParty: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              originatingParty: newOriginatingParty,
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleTargetPartyChange = useCallback(
    (row: LocalDocumentTemplateRoute, newTargetParty: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              targetParty: newTargetParty,
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleDocumentTemplateChange = useCallback(
    (row: LocalDocumentTemplateRoute, newDocumentTemplate: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              documentTemplate: newDocumentTemplate,
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        const crtRow = rows.find((routes) => routes.id === rowId)!;
        onRemoveDocumentTemplateRoute(crtRow.id);
      }
    },
    [onRemoveDocumentTemplateRoute, rows]
  );

  // TODO: export these functions in generic hook
  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const isRowValid = (row: GridRowModel<LocalDocumentTemplateRoute>) => {
    const {
      productId,
      contractTypeId,
      documentTemplateId,
      originatingPartyId,
      outputActionId,
      targetPartyId,
    } = computeRowIds(row);

    return (
      !!productId &&
      !!outputActionId &&
      !!contractTypeId &&
      !!originatingPartyId &&
      !!targetPartyId &&
      !!documentTemplateId
    );
  };

  const handleAddDocumentTemplateRoute = useCallback(
    async (updatedRow: LocalDocumentTemplateRoute) => {
      const updatedRowIds = computeRowIds(updatedRow);

      addUpdateInProgress.current = true;
      const input = {
        documentTemplateId: updatedRowIds.documentTemplateId,
        originatingPartyId: updatedRowIds.originatingPartyId,
        productOutputActionId: updatedRowIds.outputActionId,
        targetPartyId: updatedRowIds.targetPartyId,
      };

      if (fixedContractType) {
        (input as AddDocumentTemplateRouteOverrideInput).contractId =
          contractId!;
      } else {
        (input as AddDocumentTemplateRouteInput).contractTypeId =
          updatedRowIds.contractTypeId;
      }

      const { errors } = await onAddDocumentTemplateRoute(
        input as
          | AddDocumentTemplateRouteInput
          | AddDocumentTemplateRouteOverrideInput
      );
      addUpdateInProgress.current = false;
      if (errors) {
        setRowModesModel((prevData) => ({
          ...prevData,
          [updatedRow.id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: "product",
          },
        }));
      } else {
        // remove temporary row
        setRows((curRows) =>
          curRows.filter((curRow) => curRow.id !== temporaryRowId)
        );
      }
    },
    [contractId, fixedContractType, onAddDocumentTemplateRoute]
  );

  const handleEditDocumentTemplateRoute = useCallback(
    async (updatedRow: LocalDocumentTemplateRoute) => {
      const updatedRowIds = computeRowIds(updatedRow);

      addUpdateInProgress.current = true;
      const input = {
        id: updatedRow.id,
        documentTemplateId: updatedRowIds.documentTemplateId,
        originatingPartyId: updatedRowIds.originatingPartyId,
        productOutputActionId: updatedRowIds.outputActionId,
        targetPartyId: updatedRowIds.targetPartyId,
      };

      if (fixedContractType) {
        (input as EditDocumentTemplateRouteOverrideInput).contractId =
          contractId!;
      } else {
        (input as EditDocumentTemplateRouteInput).contractTypeId =
          updatedRowIds.contractTypeId;
      }

      const { errors } = await onEditDocumentTemplateRoute(
        input as
          | EditDocumentTemplateRouteInput
          | EditDocumentTemplateRouteOverrideInput
      );
      addUpdateInProgress.current = false;

      if (errors) {
        setRowModesModel((prevData) => ({
          ...prevData,
          [updatedRow.id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: "product",
          },
        }));
      } else {
        setRows((curRows) =>
          curRows.map((crtRow) => {
            if (crtRow.id === updatedRow.id) {
              return {
                ...crtRow,
                changesCommitted: true,
              };
            }
            return crtRow;
          })
        );
      }
    },
    [contractId, fixedContractType, onEditDocumentTemplateRoute]
  );

  const handleRowChangesCommitted = useCallback(
    async (
      newRow: GridRowModel<LocalDocumentTemplateRoute>,
      _: GridRowModel<LocalDocumentTemplateRoute>
    ) => {
      const updatedRow = rows.find((row) => row.id === newRow.id)!;

      if (!isRowValid(updatedRow)) {
        setRowModesModel((prevData) => ({
          ...prevData,
          [updatedRow.id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: "product",
          },
        }));

        return updatedRow;
      }

      if (addUpdateInProgress.current) {
        // debounce wasn't an option because we need to return `newRow` so that grid gets out of select mode
        console.log(
          "Row already in progress. Ignoring this change...",
          updatedRow
        );
        return updatedRow;
      }

      if (updatedRow.id === temporaryRowId) {
        handleAddDocumentTemplateRoute(updatedRow);
      } else if (updatedRow.changesCommitted) {
        return updatedRow;
      } else {
        handleEditDocumentTemplateRoute(updatedRow);
      }

      return updatedRow;
    },
    [handleAddDocumentTemplateRoute, handleEditDocumentTemplateRoute, rows]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        id: temporaryRowId,
        product: {
          id: "",
          name: "",
        },
        outputAction: {
          id: "",
          name: "",
        },
        contractType: {
          id: fixedContractType?.id ?? "",
          name: fixedContractType?.description ?? "",
        },
        originatingParty: {
          id: "",
          name: "",
        },
        targetParty: {
          id: "",
          name: "",
        },
        documentTemplate: {
          id: "",
          name: "",
        },
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "product",
        },
      }));
    });
  };

  const columns = useMemo(
    () =>
      getColumns({
        rowModesModel,
        products,
        contractTypes: fixedContractType ? [fixedContractType] : contractTypes,
        documentTemplates,
        fixedContractType,
        t,
        onProductChange: handleProductChange,
        onOutputActionChange: handleOutputActionChange,
        onContractTypeChange: handleContractTypeChange,
        onOriginatingPartyChange: handleOriginatingPartyChange,
        onTargetPartyChange: handleTargetPartyChange,
        onDocumentTemplateChange: handleDocumentTemplateChange,
        handleDeleteRow,
        handleSaveRow: handleRowSaveClick,
      }),
    [
      rowModesModel,
      products,
      contractTypes,
      documentTemplates,
      fixedContractType,
      t,
      handleProductChange,
      handleOutputActionChange,
      handleContractTypeChange,
      handleOriginatingPartyChange,
      handleTargetPartyChange,
      handleDocumentTemplateChange,
      handleRowSaveClick,
      handleDeleteRow,
    ]
  );

  useEffect(() => {
    const localDocumentTemplateRoutes =
      documentTemplateRoutesToLocalDocumentTemplateRoutes(
        documentTemplateRoutes ?? []
      );

    setRows(sortDocumentTemplateRoutes(localDocumentTemplateRoutes));
    setRowModesModel(computeGridRowModes(localDocumentTemplateRoutes));
  }, [documentTemplateRoutes]);

  const routesGrid = (
    <>
      <StyledDataGrid
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        getRowId={(rowData: LocalDocumentTemplateRoute) => rowData.id}
        onRowSelectionModelChange={setSelectionModel}
        loading={loading}
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={handleRowChangesCommitted}
        // experimentalFeatures={{ newEditingApi: true }}
        checkboxSelection
        disableRowSelectionOnClick
        hideFooter
        autoHeight={collapsibleHeader}
      />
      <DataGridAddRecordButton
        onClick={handleAddTemporaryRecord}
        disabled={rowsContainTemporaryRecord(rows)}
      />
    </>
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex="1">
      {collapsibleHeader ? (
        <CollapsibleHeader
          title={t("AdminConsole.Templates.labels.documentTemplateOverrides")}
          collapsed={!showRoutes}
          icon={
            <Files size={22} weight="fill" color={theme.palette.primary.main} />
          }
          onToggleCollapse={() => setShowRoutes((state) => !state)}
          withShadow={false}
          visibleRowsCount={visibleRowsCount || 0}
          selectedCount={selectionModel?.length || 0}
          onExportToExcel={handleExportToExcel}
        />
      ) : (
        <GridHeader
          title={t("AdminConsole.Navigation.labels.documentTemplateRouter")}
          actionButtonCaption={t("common.buttons.addEntity", {
            entity: t(
              "AdminConsole.Templates.labels.documentTemplateRoute"
            ).toLowerCase(),
          })}
          visibleRowsCount={visibleRowsCount || 0}
          selectedCount={selectionModel?.length || 0}
          onExportToExcel={handleExportToExcel}
          sticky
        />
      )}

      {collapsibleHeader ? (
        // contract details page
        <Collapse
          in={collapsibleHeader ? showRoutes : false}
          sx={{ width: "100%" }}
        >
          <Box width="100%">{routesGrid}</Box>
        </Collapse>
      ) : (
        // document templates router page
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          width="100%"
          sx={{
            maxHeight: `calc(100vh - ${adminConsolePageContentDifference}px)`,
          }}
        >
          {routesGrid}
        </Box>
      )}
    </Box>
  );
};
