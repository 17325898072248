import {
  EventItem,
  EventItemQuery,
  EventItemQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "./useGraphQuery";
import { eventItemQuery } from "containers/Projects/containers/EventDetails/EventDetails.query";
import { ErrorHandlingType } from "./useGraphMutation";

export const useEventItem = (id: string) => {
  const {
    data: eventItemData,
    refetch: refetchEventData,
    loading: getEventItemDataLoading,
    error,
  } = useGraphQuery<EventItemQuery, EventItemQueryVariables>(
    eventItemQuery,
    {
      variables: { id },
      fetchPolicy: "cache-and-network",
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  return {
    eventItemData: eventItemData?.eventItem as EventItem,
    loading: getEventItemDataLoading,
    refetchEventData,
    error,
  };
};
