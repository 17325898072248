import { gql } from "@apollo/client";
import { compEventQuotationFields } from "graphql/fragments/compEventQuotation.fragment";

export const compEventQuotationAcceptancePromptQuery = gql`
  ${compEventQuotationFields}

  query compEventQuotationAcceptancePrompt(
    $input: CompEventQuotationAcceptancePromptInput!
  ) {
    compEventQuotationAcceptancePrompt(input: $input) {
      quotations {
        ...CompEventQuotationFields
      }
    }
  }
`;
