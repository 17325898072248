import { useGraphQuery } from "hooks/useGraphQuery";
import {
  LocalVariationItemQuery,
  LocalVariationItemQueryVariables,
  VariationItem,
} from "generated/graphql";
import { ErrorHandlingType } from "hooks/useGraphMutation";
import { localVariationItemQuery } from "./useVariation.query";

export const useVariation = (id?: string) => {
  const {
    data: variationItem,
    refetch,
    loading,
    error,
  } = useGraphQuery<LocalVariationItemQuery, LocalVariationItemQueryVariables>(
    localVariationItemQuery,
    {
      variables: { id: id! },
      skip: !id,
      notifyOnNetworkStatusChange: true,
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  return {
    variation: variationItem?.variationItem as VariationItem | undefined,
    refetchVariation: refetch,
    variationLoading: loading,
    error,
  };
};
