import {
  DailyDiaryEquipmentRecordsQuery,
  DailyDiaryEquipmentRecordsQueryVariables,
  DailyDiaryItem,
  DailyDiaryResourceRecord,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { dailyDiaryEquipmentRecordsQuery } from "./EquipmentSection.query";

export const useEquipmentRecords = (
  dailyDiary?: DailyDiaryItem, // TODO: pass only dailyDiaryId - no need for the entire object
  revisionId?: string
) => {
  const {
    data: equipmentRecords,
    loading: equipmentRecordsLoading,
    refetch: refetchDDEquipmentRecords,
  } = useGraphQuery<
    DailyDiaryEquipmentRecordsQuery,
    DailyDiaryEquipmentRecordsQueryVariables
  >(dailyDiaryEquipmentRecordsQuery, {
    variables: { dailyDiaryId: dailyDiary?.id!, revisionId },
    skip: !dailyDiary,
    notifyOnNetworkStatusChange: true,
  });

  return {
    equipmentRecords: (equipmentRecords?.dailyDiaryEquipmentRecords ??
      []) as DailyDiaryResourceRecord[],
    equipmentRecordsLoading,
    refetchDDEquipmentRecords,
  };
};
