import {
  ChangeEventItemStatusMutation,
  ChangeEventItemStatusMutationVariables,
  EditEventItemMutation,
  EditEventItemMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import {
  changeEventItemStatusMutation,
  editEventItemMutation,
} from "../EventDetails.query";

export const useEventDetails = (onUpdate: () => void) => {
  const { t } = useTranslation();

  const [changeEventItemStatus, { loading: changeEventItemStatusLoading }] =
    useGraphMutation<
      ChangeEventItemStatusMutation,
      ChangeEventItemStatusMutationVariables
    >(
      changeEventItemStatusMutation,
      {
        update: () => {
          onUpdate();
          // cache.evict({ id: "ROOT_QUERY", fieldName: "eventItems" });
          // cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Events.event"),
      })
    );

  const [doEditEvent] = useGraphMutation<
    EditEventItemMutation,
    EditEventItemMutationVariables
  >(
    editEventItemMutation,
    {
      update: onUpdate,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Events.event"),
    })
  );

  return {
    changeEventItemStatus,
    changeEventItemStatusLoading,
    doEditEvent,
  };
};
