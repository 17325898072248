import { OnMutationDone } from "types/decl";
import {
  GetUserProductInstancesByIdQuery,
  GetUserProductInstancesByIdQueryVariables,
  GetUserRolesByIdQuery,
  GetUserRolesByIdQueryVariables,
  ProductUserRole,
  Project,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  getUserProductInstancesByIdQuery,
  getUserRolesByIdQuery,
} from "./UserRoles.query";
import { useUserProductRole } from "./useUserProductRole";
import { useMemo } from "react";

export type useUserRolesProps = {
  onAdd: OnMutationDone;
  onEdit: OnMutationDone;
  onStatusChange: OnMutationDone;
};

export type useUserRolesArgsType = {
  userId: string;
  callbacks: useUserRolesProps;
};

export const useUserRoles = ({
  userId,
  callbacks: { onAdd, onEdit, onStatusChange },
}: useUserRolesArgsType) => {
  const { data: userProductInstances, loading: userProductInstancesLoading } =
    useGraphQuery<
      GetUserProductInstancesByIdQuery,
      GetUserProductInstancesByIdQueryVariables
    >(getUserProductInstancesByIdQuery, {
      variables: { id: userId },
    });

  const { data: userRoles, loading: userRolesLoading } = useGraphQuery<
    GetUserRolesByIdQuery,
    GetUserRolesByIdQueryVariables
  >(getUserRolesByIdQuery, {
    variables: { id: userId },
  });

  const {
    addUserRole,
    addUserRoleLoading,
    editUserRole,
    editUserRoleLoading,
    changeUserRoleStatus,
    changeUserRoleStatusLoading,
  } = useUserProductRole({ onAdd, onEdit, onStatusChange });

  const projectsWithBindingsToUserCompany = useMemo(() => {
    const allProjects =
      (userProductInstances?.user?.company.projects.items as Project[]) ?? [];
    const filteredProjects = allProjects.filter((project) =>
      project.contracts.items.find((contract) =>
        contract.activeBindings.items.find(
          (binding) =>
            binding.companyId === userProductInstances?.user?.companyId
        )
      )
    );

    return filteredProjects.map((proj) => {
      return {
        ...proj,
        contracts: {
          ...proj.contracts,
          items: proj.contracts.items.filter((contract) =>
            contract.activeBindings.items.find(
              (binding) =>
                binding.companyId === userProductInstances?.user?.companyId
            )
          ),
        },
      };
    });
  }, [userProductInstances]);

  return {
    loading: userProductInstancesLoading || userRolesLoading,
    projects: projectsWithBindingsToUserCompany,
    roles: userRoles?.user?.roles.items as ProductUserRole[],
    addUserRole,
    addUserRoleLoading,
    editUserRole,
    editUserRoleLoading,
    changeUserRoleStatus,
    changeUserRoleStatusLoading,
  };
};
