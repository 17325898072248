import { Box, Theme, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridValueGetter,
} from "@mui/x-data-grid-pro";
import { TrashIcon } from "components/Icons/TrashIcon";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { ProductItem } from "containers/Projects/Projects.decl";
import {
  InstructionItem,
  ItemStatusOption,
  ProductType,
} from "generated/graphql";
import { TFunction } from "i18next";
import { defaultSeverityPreset } from "../SchemaInterpretor/Field/Severity/Severity.constants";
import { SeverityValue } from "../SchemaInterpretor/Field/Severity/Severity.decl";
import { SeverityPreview } from "../SchemaInterpretor/Field/Severity/SeverityPreview";
import { ProductItemPreview } from "./components/ProductItemPreview/ProductItemPreview";
import {
  datetimeComparatorFn,
  statusComparatorFunction,
} from "helpers/dataGrid.helpers";
import { ItemTag } from "components/ItemTag";
import { OverflowTooltip } from "components/OverflowTooltip";
import { getUserName } from "helpers/miscelaneous";
import { DateWithTimeTooltip } from "../DateWithTimeTooltip";

const getDateSentColumn = (t: TFunction) => ({
  field: "dateSent",
  headerName: t("common.labels.dateSent"),
  width: 105,
  resizable: true,
  sortComparator: datetimeComparatorFn,
  renderCell: (params: GridRenderCellParams<ProductItem>) => {
    return params.value ? (
      <DateWithTimeTooltip
        datetime={params.value}
        timezone={params.row.productInstance.contract.timeZone}
        color="grey.600"
        variant="p2"
      />
    ) : (
      "N/A"
    );
  },
});
const getDateModifiedColumn = (t: TFunction) => ({
  field: "dateModified",
  headerName: t("common.labels.dateModified"),
  width: 135,
  resizable: true,
  sortComparator: datetimeComparatorFn,
  renderCell: (params: GridRenderCellParams<ProductItem>) => {
    return params.value ? (
      <DateWithTimeTooltip
        datetime={params.value}
        timezone={params.row.productInstance.contract.timeZone}
        color="grey.600"
        variant="p2"
      />
    ) : (
      "N/A"
    );
  },
});
const getDateCreatedColumn = (t: TFunction) => ({
  field: "dateCreated",
  headerName: t("common.labels.dateCreated"),
  width: 135,
  resizable: true,
  sortComparator: datetimeComparatorFn,
  renderCell: (params: GridRenderCellParams<ProductItem>) => {
    return params.value ? (
      <DateWithTimeTooltip
        datetime={params.value}
        timezone={params.row.productInstance.contract.timeZone}
        color="grey.600"
        variant="p2"
      />
    ) : (
      "N/A"
    );
  },
});
const getRelatedColumn = (t: TFunction, theme: Theme) => ({
  field: "related",
  headerName: t("common.labels.related"),
  flex: 0.25,
  minWidth: 225,
  sortable: true,
  valueGetter: ((_, row) => {
    const relatedCE = (row as InstructionItem)?.compEvent;
    const relatedClaim = (row as InstructionItem)?.claim;
    const relatedVariation = (row as InstructionItem)?.variation;

    return (
      relatedCE?.number ??
      relatedClaim?.number ??
      relatedVariation?.number ??
      ""
    );
  }) as GridValueGetter<ProductItem>,
  resizable: true,
  renderCell: (params: GridRenderCellParams<ProductItem, any, any>) => {
    // TODO: remove cast as InstructionItem after implementing Variations on Events.
    const relatedCE = (params.row as InstructionItem).compEvent;
    const relatedClaim = (params.row as InstructionItem).claim;
    const relatedVariation = (params.row as InstructionItem).variation;
    const hasRelatedProductInstanceItem =
      relatedCE ?? relatedClaim ?? relatedVariation;

    return hasRelatedProductInstanceItem ? (
      <Box display="flex" alignItems="center" width="100%">
        <ItemTag
          type={
            relatedCE
              ? ProductType.CompEvents
              : relatedClaim
              ? ProductType.Claims
              : ProductType.Variations
          }
        />
        <OverflowTooltip
          title={
            relatedCE?.number ??
            relatedClaim?.number ??
            relatedVariation?.number
          }
          typographyProps={{
            variant: "p2",
            ml: 1,
            color: theme.palette.grey[700],
          }}
        />
      </Box>
    ) : null;
  },
});

const getBaseColumns = (
  statusOptions: ItemStatusOption[],
  t: TFunction,
  theme: Theme,
  includeSeverity = false
): GridColDef<ProductItem>[] => {
  return [
    {
      field: "number",
      headerName: t("common.labels.id"),
      flex: 0.05,
      minWidth: 150,
      resizable: true,
      renderCell: (params) => {
        return (
          <OverflowTooltip
            title={params.value}
            typographyProps={{
              variant: "p2",
              color: "text.primary",
            }}
          />
        );
      },
    },
    {
      field: "title",
      headerName: t("common.labels.title"),
      flex: 0.3,
      minWidth: 250,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any, ProductItem, any>) => {
        return (
          <Typography
            variant="p2"
            color={theme.palette.grey[700]}
            fontWeight={600}
            maxWidth="100%"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {params.row.title}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 0.1,
      maxWidth: 130,
      minWidth: 100,
      resizable: true,
      sortable: true,
      sortComparator: statusComparatorFunction,
      valueGetter: (_, row) => row.statusOption.description,
      renderCell: (params: GridRenderCellParams<any, ProductItem, any>) => {
        return (
          <StatusTagNew
            selectedOptionId={params.row.statusOptionId}
            disabled
            options={statusOptions.map((statusOption) => ({
              id: statusOption.id,
              label: statusOption.description,
              style: statusOption.style,
            }))}
          />
        );
      },
    },
    ...(includeSeverity
      ? [
          {
            field: "severity",
            headerName: t("Projects.Risks.severity"),
            flex: 0.1,
            maxWidth: 130,
            minWidth: 110,
            resizable: true,
            renderCell: (
              params: GridRenderCellParams<any, ProductItem, any>
            ) => {
              const severityValue: SeverityValue = params.row.severity
                ? JSON.parse(params.row.severity)
                : undefined;

              return (
                // TODO: remove default severity preset and search for the one from the risk
                <SeverityPreview
                  size="xSmall"
                  severityPreset={defaultSeverityPreset}
                  consequenceValue={
                    severityValue ? severityValue.consequence : undefined
                  }
                  likelihoodValue={
                    severityValue ? severityValue.likelihood : undefined
                  }
                />
              );
            },
          },
        ]
      : []),
    {
      field: "owner",
      headerName: t("common.labels.owner"),
      flex: 0.15,
      minWidth: 190,
      resizable: true,
      valueGetter: (_, row) => {
        return getUserName(row.owner);
      },
    },
    {
      field: "options",
      headerName: "",
      width: 50,
      resizable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ProductItem, any, any>) => {
        return (
          <ProductItemPreview
            includeSeverity={includeSeverity}
            productItem={params.row}
          />
        );
      },
    },
  ];
};

export const getRisksTableColumns = (
  statusOptions: ItemStatusOption[],
  t: TFunction,
  theme: Theme,
  includeSeverity?: boolean
): GridColDef<ProductItem>[] => {
  const allColumns = getBaseColumns(statusOptions, t, theme, includeSeverity);
  allColumns.splice(
    allColumns.length - 1,
    0,
    ...[getDateModifiedColumn(t), getDateCreatedColumn(t)]
  );

  return allColumns;
};

export const getEventsTableColumns = (
  statusOptions: ItemStatusOption[],
  t: TFunction,
  theme: Theme,
  includeSeverity?: boolean
): GridColDef<ProductItem>[] => {
  const allColumns = getBaseColumns(statusOptions, t, theme, includeSeverity);

  allColumns.splice(
    allColumns.length - 1,
    0,
    ...[
      getDateModifiedColumn(t),
      getDateCreatedColumn(t),
      getRelatedColumn(t, theme),
    ]
  );

  return allColumns;
};

export const getEarlyWarningsTableColumns = (
  statusOptions: ItemStatusOption[],
  t: TFunction,
  theme: Theme,
  includeSeverity?: boolean
): GridColDef<ProductItem>[] => {
  const allColumns = getBaseColumns(statusOptions, t, theme, includeSeverity);
  allColumns.splice(allColumns.length - 1, 0, ...[getDateSentColumn(t)]);

  return allColumns;
};

export const getInstructionsTableColumns = (
  statusOptions: ItemStatusOption[],
  t: TFunction,
  theme: Theme,
  isNECContractType = true,
  includeSeverity?: boolean
): GridColDef<ProductItem>[] => {
  const allColumns = getBaseColumns(statusOptions, t, theme, includeSeverity);
  allColumns.splice(
    allColumns.length - 1,
    0,
    ...[getDateSentColumn(t), getRelatedColumn(t, theme)]
  );

  allColumns.splice(
    allColumns.length - 3,
    0,
    ...[
      {
        field: "type",
        headerName: t("common.labels.type"),
        flex: 0.125,
        maxWidth: 250,
        minWidth: 120,
        resizable: true,
        valueGetter: ((_, row) => {
          // Note: if type is moved in a different section, it won't display right in the Register view.
          const type = row.data.sections[0].entries.find(
            (field) => field.name === "Type"
          )?.value;

          return type
            ? t(
                `Projects.Instructions.${
                  isNECContractType ? "NECType" : "FIDICType"
                }.${type}`
              )
            : "";
        }) as GridValueGetter<ProductItem>,
        renderCell: (params: GridRenderCellParams<ProductItem, any, any>) => {
          return (
            <Box display="flex" alignItems="center" width="100%">
              <OverflowTooltip
                title={params.value}
                typographyProps={{
                  variant: "p2",
                  ml: 1,
                  color: theme.palette.grey[700],
                }}
              />
            </Box>
          );
        },
      },
    ]
  );

  return allColumns;
};

export const getDraftProductItemsTableColumns = <T extends ProductItem>(
  handleDeleteDraft: (draftId: string) => void,
  t: TFunction,
  theme: Theme,
  includeSeverity?: boolean
): GridColDef<T>[] => {
  return [
    {
      field: "title",
      headerName: t("common.labels.title"),
      flex: 0.4,
      minWidth: 190,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any, T, any>) => {
        return (
          <Typography
            variant="p2"
            color={theme.palette.grey[700]}
            fontWeight={600}
          >
            {params.row.title}
          </Typography>
        );
      },
    },
    ...(includeSeverity
      ? [
          {
            field: "severity",
            headerName: t("Projects.Risks.severity"),
            flex: 0.125,
            resizable: true,
            renderCell: (params: GridRenderCellParams<any, T, any>) => {
              const severityValue: SeverityValue = params.row.severity
                ? JSON.parse(params.row.severity)
                : undefined;

              return (
                // TODO: remove default severity preset and search for the one from the risk
                <SeverityPreview
                  size="xSmall"
                  severityPreset={defaultSeverityPreset}
                  consequenceValue={
                    severityValue ? severityValue.consequence : undefined
                  }
                  likelihoodValue={
                    severityValue ? severityValue.likelihood : undefined
                  }
                />
              );
            },
          },
        ]
      : []),
    getDateModifiedColumn(t),
    {
      field: "options",
      headerName: "",
      width: 50,
      resizable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, T, any>) => {
        return (
          <GridActionsCellItem
            icon={<TrashIcon />}
            label="Delete"
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();

              handleDeleteDraft(params.row.id);
            }}
          />
        );
      },
    },
  ];
};
