import { Box, Typography, useTheme, BoxProps } from "@mui/material";
import {
  EventType,
  getEventType,
} from "components/MainNavigation/Notifications/Notifications.utils";
import {
  AttachmentAddedDetails,
  AttachmentDescriptionAddedDetails,
  AttachmentRemovedDetails,
  ChangeLogItem,
  EffectPreset,
  RiskEditedDetails,
  RiskOwnerChangedDetails,
  RiskStatusChangedDetails,
} from "generated/graphql";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { computeFieldChangeText } from "./Changelog.utils";
import { ChangelogRichTextarea } from "./ChangelogRichTextarea";
import { getUserName } from "helpers/miscelaneous";

export type ChangelogTextProps = {
  changelog: ChangeLogItem;
  effectPreset: EffectPreset;
} & BoxProps;

export const ChangelogText: React.FC<ChangelogTextProps> = ({
  changelog,
  effectPreset,
  ...restBoxProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const changelogType = useMemo(() => getEventType(changelog), [changelog]);
  const creatorName = useMemo(
    () => getUserName(changelog.creator),
    [changelog]
  );

  const transComponentsConfig = useMemo(
    () => ({
      bold: <Typography variant="p1" fontWeight={600} />,
      underline: (
        <Typography variant="p1" sx={{ textDecoration: "underline" }} />
      ),
    }),
    []
  );

  const detailsTransComponentsConfig = useMemo(
    () => ({
      bold: <Typography variant="p3" fontWeight={600} />,
      rich: <ChangelogRichTextarea />,
      small: <Typography variant="p3" />,
    }),
    []
  );
  const TextContent = useMemo(() => {
    switch (changelogType) {
      case EventType.RiskAdded:
      case EventType.EventAdded:
        return (
          <Trans
            i18nKey="Changelog.productItemCreated"
            values={{
              user: creatorName,
              productItemName: changelog.details.title,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.RiskEdited:
      case EventType.EventEdited:
      case EventType.InstructionEdited:
      case EventType.EwEdited: {
        const fieldChanges = (changelog.details as RiskEditedDetails)
          .fieldChanges;

        return (
          <>
            <Trans
              i18nKey="Changelog.productItemUpdated.title"
              values={{
                user: creatorName,
              }}
              components={transComponentsConfig}
            />
            {fieldChanges.map((fieldChange) => {
              const { oldValue, newValue } = computeFieldChangeText(
                fieldChange.oldValue,
                fieldChange.newValue,
                effectPreset
              );

              return (
                <React.Fragment key={fieldChange.fieldName}>
                  <Trans
                    i18nKey="Changelog.productItemUpdated.fieldChangeItem"
                    values={{
                      field: fieldChange.fieldName,
                      oldValue: window.encodeURIComponent(oldValue),
                      newValue: window.encodeURIComponent(newValue as string),
                    }}
                    components={detailsTransComponentsConfig}
                  />
                </React.Fragment>
              );
            })}
          </>
        );
      }
      case EventType.RiskOwnerChanged:
      case EventType.EwOwnerChanged:
      case EventType.EventOwnerChanged:
      case EventType.InstructionOwnerChanged:
        return (
          <Trans
            i18nKey="Changelog.ownerChanged"
            values={{
              user: creatorName,
              oldOwner: (changelog.details as RiskOwnerChangedDetails).oldOwner,
              newOwner: (changelog.details as RiskOwnerChangedDetails).newOwner,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.RiskStatusChanged:
      case EventType.EwStatusChanged:
      case EventType.InstructionStatusChanged:
      case EventType.EventStatusChanged:
        return (
          <React.Fragment>
            <Trans
              i18nKey="Changelog.statusChanged.title"
              values={{
                user: creatorName,
                oldStatus: (changelog.details as RiskStatusChangedDetails)
                  .oldStatus,
                newStatus: (changelog.details as RiskStatusChangedDetails)
                  .newStatus,
              }}
              components={transComponentsConfig}
            />
            <br />
            <Trans
              i18nKey="Changelog.statusChanged.fieldChangeItem"
              values={{
                field: t("AdminConsole.Products.labels.reason"),
                fieldValue: (changelog.details as RiskStatusChangedDetails)
                  .reason,
              }}
              components={detailsTransComponentsConfig}
            />
            <br />
            <Trans
              i18nKey="Changelog.statusChanged.fieldChangeItem"
              values={{
                field: t("AdminConsole.Products.labels.remarks"),
                fieldValue:
                  (changelog.details as RiskStatusChangedDetails).details ||
                  "-",
              }}
              components={detailsTransComponentsConfig}
            />
          </React.Fragment>
        );
      case EventType.CommentReplied:
        return (
          <Trans
            i18nKey="Changelog.commentReplied"
            values={{
              user: creatorName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.NewCommentAdded:
        return (
          <Trans
            i18nKey="Changelog.commentAdded"
            values={{
              user: creatorName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.UserMentioned:
        return (
          <Trans
            i18nKey="Changelog.userMentioned"
            values={{
              user: creatorName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.AttachmentAdded:
        return (
          <Trans
            i18nKey="Changelog.attachmentsAdded"
            values={{
              user: creatorName,
              attachment: (changelog.details as AttachmentAddedDetails)
                .attachments[0].fileName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.AttachmentDescriptionAdded:
      case EventType.AttachmentDescriptionEdited: {
        // attachment description updates cannot happen in bulk
        const firstAttachment = (
          changelog.details as AttachmentDescriptionAddedDetails
        ).attachmentChanges?.[0];

        return (
          <Trans
            i18nKey="Changelog.attachmentDescriptionEdited"
            values={{
              user: creatorName,
              attachment: firstAttachment.fileName,
              oldDescription: firstAttachment.oldDescription,
              newDescription: firstAttachment.newDescription,
            }}
            components={transComponentsConfig}
          />
        );
      }
      // TODO: probably never used
      // case EventType.CompEventConfirmationRecorded:
      // case EventType.CompEventConfirmationSent:
      // case EventType.CompEventNoticeRecorded:
      // case EventType.CompEventNoticeRequestQuotationRecorded:
      // case EventType.CompEventNoticeRequestQuotationSent:
      // case EventType.CompEventNoticeSent:
      // case EventType.CompEventOwnAssessmentNoticeRecorded:
      // case EventType.CompEventOwnAssessmentNoticeSent:
      // case EventType.CompEventOwnAssessmentRecorded:
      // case EventType.CompEventOwnAssessmentSent:
      // case EventType.CompEventQuotationAcceptanceRecorded:
      // case EventType.CompEventQuotationAcceptanceSent:
      // case EventType.CompEventQuotationRecorded:
      // case EventType.CompEventQuotationRevisionRequestRecorded:
      // case EventType.CompEventQuotationRevisionRequestSent:
      // case EventType.CompEventQuotationSent:
      // case EventType.CompEventRejectionRecorded:
      // case EventType.CompEventRejectionSent:
      // case EventType.CompEventRequestForMoreTimeSent:
      // case EventType.CompEventRequestForMoreTimeRecorded:
      // case EventType.CompEventNoticeProposedInstructionSent:
      // case EventType.CompEventNoticeProposedInstructionRecorded:
      // case EventType.CompEventAbandonInstructionSent:
      // case EventType.CompEventAbandonInstructionRecorded:
      // case EventType.CompEventGrantingOfMoreTimeSent:
      // case EventType.CompEventGrantingOfMoreTimeRecorded:
      // case EventType.CompEventDenyingOfMoreTimeSent:
      // case EventType.CompEventDenyingOfMoreTimeRecorded:
      // case EventType.CompEventNoticeOfSilenceCompEventNoticeSent:
      // case EventType.CompEventNoticeOfSilenceCompEventNoticeRecorded:
      // case EventType.CompEventNoticeOfSilenceOwnAssessmentSent:
      // case EventType.CompEventNoticeOfSilenceOwnAssessmentRecordedHandler:
      // case EventType.CompEventNoticeOfSilenceQuotationSent:
      // case EventType.CompEventNoticeOfSilenceQuotationRecorded:
      //   return (
      //     // TODO: probably it never reaches this, because CE history is displayed in HistoryExplorer
      //     <Trans
      //       i18nKey={`Changelog.CE.${changelogType}`}
      //       values={{
      //         user: creatorName,
      //       }}
      //       components={transComponentsConfig}
      //     />
      //   );
      case EventType.AttachmentRemoved:
        return (
          <Trans
            i18nKey="Changelog.attachmentRemoved"
            values={{
              user: creatorName,
              attachments: (
                changelog.details as AttachmentRemovedDetails
              ).attachments
                ?.map((attach) => attach.fileName)
                .join(", "),
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.EwRecorded:
      case EventType.InstructionRecorded:
        return (
          <Trans
            i18nKey={`${
              changelogType === EventType.EwRecorded
                ? "Changelog.ewRecorded"
                : "Changelog.instructionRecorded"
            }`}
            values={{
              user: creatorName,
              productItemName: changelog.details.title,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.EwSent:
      case EventType.InstructionSent:
        return (
          <Trans
            i18nKey={`${
              changelogType === EventType.EwSent
                ? "Changelog.ewSent"
                : "Changelog.instructionSent"
            }`}
            values={{
              user: creatorName,
              productItemName: changelog.details.title,
            }}
            components={transComponentsConfig}
          />
        );
      default:
        return (
          <Typography variant="p2" color="grey.900">
            {changelog.details.title}
          </Typography>
        );
    }
  }, [
    creatorName,
    changelog.details,
    changelogType,
    effectPreset,
    transComponentsConfig,
    t,
    detailsTransComponentsConfig,
  ]);

  return (
    <Box
      fontSize={theme.typography.p1.fontSize}
      color={theme.palette.grey[800]}
      sx={{ wordBreak: "break-word" }}
      {...restBoxProps}
    >
      {TextContent}
    </Box>
  );
};
