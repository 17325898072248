import {
  DailyDiaryGeneralRecord,
  DailyDiaryGeneralRecordsQuery,
  DailyDiaryGeneralRecordsQueryVariables,
  DailyDiaryItem,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { dailyDiaryGeneralRecordsQuery } from "./GeneralSection.query";

export const useGeneralRecords = (
  dailyDiary?: DailyDiaryItem, // TODO: pass only dailyDiaryId - no need for the entire object
  revisionId?: string
) => {
  const {
    data: generalRecords,
    loading: generalRecordsLoading,
    refetch: refetchDDGeneralRecords,
  } = useGraphQuery<
    DailyDiaryGeneralRecordsQuery,
    DailyDiaryGeneralRecordsQueryVariables
  >(dailyDiaryGeneralRecordsQuery, {
    variables: { dailyDiaryId: dailyDiary?.id!, revisionId },
    skip: !dailyDiary,
    notifyOnNetworkStatusChange: true,
  });

  return {
    generalRecords: (generalRecords?.dailyDiaryGeneralRecords ??
      []) as DailyDiaryGeneralRecord[],
    generalRecordsLoading,
    refetchDDGeneralRecords,
  };
};
