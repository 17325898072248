import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { temporaryRowId } from "constants/constants";
import { LookupCollection, LookupCollectionStatus } from "generated/graphql";
import { Copy } from "phosphor-react";
import { Tooltip } from "@mui/material";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<LookupCollectionStatus>[],
  onStatusChange: (
    row: LookupCollection,
    newStatus: LookupCollectionStatus
  ) => void,
  onDuplicateCollection: (row: LookupCollection) => void
): GridColDef<LookupCollection>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: t("common.labels.name"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "optionsLockedForUsers",
          headerName: t("AdminConsole.Lookups.optionsLockedForUsers"),
          flex: 0.15,
          width: 120,
          resizable: true,
        },
        {
          field: "optionsCharLengthLimit",
          headerName: t("AdminConsole.Lookups.optionsCharLengthLimit"),
          flex: 0.15,
          width: 120,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, LookupCollection, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                onChange={(newStatus: StatusOption<LookupCollectionStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          width: 130,
          resizable: true,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          getActions: (params) => {
            const actions =
              params.id === temporaryRowId
                ? []
                : [
                    <GridActionsCellItem
                      className="visible-on-hover"
                      icon={
                        <Tooltip title={t("common.labels.duplicate") as string}>
                          <Copy size={16} />
                        </Tooltip>
                      }
                      label="Delete"
                      onClick={() => onDuplicateCollection(params.row)}
                    />,
                  ];

            return actions;
          },
        },
      ] as GridColDef<LookupCollection>[],
    [
      t,
      dateCreatedColConfig,
      statusOptions,
      onStatusChange,
      onDuplicateCollection,
    ]
  );

  return columns;
};
