import { gql } from "@apollo/client";
import { lookupOptionFields } from "graphql/queries/lookupOption.query";

export const addLookupOptionMutation = gql`
  ${lookupOptionFields}
  mutation addLookupOption($input: AddLookupOptionInput!) {
    addLookupOption(input: $input) {
      ...LookupOptionFields
    }
  }
`;
