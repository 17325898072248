import { Typography } from "@mui/material";
import { NewAppPaths } from "../../../helpers/paths/paths";
import { useCallback, useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login, resetPassword, setNewPassword } from "services/AuthService";
import { ErrorsType } from "types";
import { SetNewPasswordForm } from "./SetNewPasswordForm";
import { AuthContext, AuthContextDataType } from "providers/auth.context";
import { useConfig } from "providers/config.context";
import { SecurityCodeValidationView } from "containers/ResetPassword/SetNewPassword/SecurityCodeValidationView";
import { NewAuthPageTemplate } from "components/NewAuthPageTemplate";
import { ResetPasswordResponseType } from "containers/ResetPassword/ResetPassword.decl";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { snackbarAutoHideDuration } from "constants/constants";

enum SetNewPasswordStep {
  EnterSecurityCode = "enterSecurityCode",
  EnterNewPassword = "enterNewPassword",
}

export const SetNewPassword = () => {
  const navigate = useNavigate();
  const { config } = useConfig();
  const { setContextData } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("email");
  const securityCode = searchParams.get("securityCode");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [step, setStep] = useState<SetNewPasswordStep>(
    SetNewPasswordStep.EnterSecurityCode
  );
  const [newPasswordErrors, setNewPasswordErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const userInputSecurityCode = useRef("");

  useEffect(() => {
    if (!userEmail) {
      // user navigated here by manually updating the URL - missing params
      navigate(NewAppPaths.nonAuthorized.Login, { replace: true });
    }
  }, [navigate, userEmail]);

  const handleChangePassword = useCallback(
    async (newPassword: string) => {
      let response;

      try {
        setLoading(true);
        response = await setNewPassword(
          config.REACT_APP_AUTH_BASE_URL,
          userEmail!,
          newPassword,
          userInputSecurityCode.current
        );
      } catch (error: any) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }

      if ((response as ErrorsType).Errors) {
        setNewPasswordErrors((response as ErrorsType).Errors[0]);
      } else {
        setLoading(true);
        const result = await login(
          config.REACT_APP_AUTH_BASE_URL,
          userEmail!,
          newPassword
        );
        setLoading(false);
        if ((result as AuthContextDataType).AccessToken) {
          setContextData(result as AuthContextDataType);
          navigate(NewAppPaths.authorized.Home);
        } else {
          navigate(NewAppPaths.nonAuthorized.Login);
        }
      }
    },
    [userEmail, navigate, setContextData, config]
  );

  const handleEnterSecurityCode = (inputSecurityCode: string) => {
    userInputSecurityCode.current = inputSecurityCode;
    setStep(SetNewPasswordStep.EnterNewPassword);
  };

  const handleResendCode = async () => {
    const response = await resetPassword(
      config.REACT_APP_AUTH_BASE_URL,
      userEmail!
    );

    if ((response as ResetPasswordResponseType).AttributeName) {
      enqueueSnackbar(t("common.successMessages.resetPassword"), {
        autoHideDuration: snackbarAutoHideDuration,
        variant: "success",
      });
    }
  };

  return (
    <NewAuthPageTemplate>
      {step === SetNewPasswordStep.EnterSecurityCode ? (
        <SecurityCodeValidationView
          initialSecurityCode={securityCode ?? undefined}
          onEnterSecurityCode={handleEnterSecurityCode}
          onResendCode={handleResendCode}
        />
      ) : (
        <>
          <SetNewPasswordForm
            loading={loading}
            onSetNewPassword={handleChangePassword}
          />
          {newPasswordErrors && (
            <Typography variant="caption" color="error" pt={1} display="block">
              {newPasswordErrors}
            </Typography>
          )}
        </>
      )}
    </NewAuthPageTemplate>
  );
};
