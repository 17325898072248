import { useTheme } from "@mui/material";
import { FileFooter } from "./FileFooter";
import { FileItemProps } from "../../Attachments.decl";
import { Thumbnail } from "./Thumbnail";
import { DeleteIcon, ItemContainer } from "./FileGridItem.styled";
import { useFileGridItem } from "./useFileGridItem";

export const FileGridItem: React.FC<FileItemProps> = (props) => {
  const theme = useTheme();
  const {
    fileLoading,
    fileLocked,
    editable,
    mimeTypeEnum,
    previewUrl,
    handleDelete,
    handleDescriptionChange,
    handleListItemClick,
  } = useFileGridItem(props);

  return (
    <ItemContainer onClick={handleListItemClick}>
      {!fileLoading && !fileLocked && editable && (
        <DeleteIcon
          width="20px"
          height="20px"
          color={theme.palette.grey[500]}
          backgroundColor="white"
          onClick={editable ? handleDelete : undefined}
        />
      )}
      <Thumbnail mimeType={mimeTypeEnum} imageUrl={previewUrl} />
      <FileFooter
        file={props.file}
        editable={editable}
        onDescriptionChange={handleDescriptionChange}
      />
    </ItemContainer>
  );
};
