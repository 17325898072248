import { Box, css, styled, TextField } from "@mui/material";

export const UnitInputContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    border: 1px solid ${theme.palette.grey[200]};
    border-radius: 8px;
    padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
  `
);

export const NakedTextField = styled(TextField)(
  ({ theme }) => css`
    color: ${theme.palette.grey[800]};
    font-size: ${theme.typography.p2.fontSize};
    font-weight: ${theme.typography.p2.fontWeight};
    line-height: ${theme.typography.p2.lineHeight};

    input {
      height: 20px;
      box-sizing: border-box;
    }

    && {
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  `
);
