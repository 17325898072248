import { gql } from "@apollo/client";

export const variationItemLiteFields = gql`
  fragment VariationItemLiteFields on VariationItem {
    id
    number
    productInstanceId
    type
    regardingId
    status
    priceSought
    priceGranted
    timeSought
    timeGranted
    dateCreated
    dateModified
    creatorId
  }
`;
