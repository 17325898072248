import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const InfoIconOutlined: React.FC<BaseIconProps> = ({
  height = "17",
  width = "16",
  onMouseEnter,
  onMouseLeave,
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[600];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.0049C11.3137 14.0049 14 11.3186 14 8.00488C14 4.69117 11.3137 2.00488 8 2.00488C4.68629 2.00488 2 4.69117 2 8.00488C2 11.3186 4.68629 14.0049 8 14.0049Z"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 7.50439H7.99992V11.0038H8.49983"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87518 6.00476C8.2895 6.00476 8.62537 5.66889 8.62537 5.25458C8.62537 4.84026 8.2895 4.50439 7.87518 4.50439C7.46087 4.50439 7.125 4.84026 7.125 5.25458C7.125 5.66889 7.46087 6.00476 7.87518 6.00476Z"
        fill={computedColor}
      />
    </svg>
  );
};
