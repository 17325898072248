import { Grid } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import {
  ClaimAction,
  ClaimDetailsExplorerItem,
  ClaimItem,
  ClaimRegardingType,
  Contract,
  EventItem,
  InstructionItem,
  ProductType,
} from "generated/graphql";
import { useCallback, useContext, useMemo } from "react";
import {
  ClaimWidgetContext,
  ClaimWidgetContextType,
} from "./ClaimWidget.context";
import { useClaimWidget } from "./hooks/useClaimWidget";
import { WidgetHeader } from "../../Widget/WidgetHeader";
import { CEWidgetSectionType, WidgetSection } from "../../Widget/WidgetSection";
import { ClaimWidgetNextStepSection } from "./components/ClaimWidgetNextStepSection";
import { ClaimWidgetLastStepSection } from "./components/ClaimWidgetLastStepSection";
import { WidgetEwAwSection } from "../../Widget/WidgetEwAwSection";
import { useClaimDetailsExplorerItems } from "../hooks/useClaimDetailsExplorerItems/useClaimDetailsExplorerItems";
import { ContractPriceTimeChangeSection } from "../../Widget/ContractPriceTimeChangeSection";
import { useClaimWidgetNextStepSection } from "containers/Projects/hooks/useClaimWidgetNextStepSection";
import { isFIDIC99RedYellowContractType } from "containers/Projects/Projects.utils";
import { useClaim } from "./hooks/useClaim/useClaim";

type ClaimWidgetInnerProps = {
  previewMode?: boolean;
  claim?: ClaimItem;
  detailsExplorerItems: ClaimDetailsExplorerItem[];
  detailsExplorerItemsLoading?: boolean;
  claimActionList: ClaimAction[];
};

type ClaimWidgetProps = {
  loading?: boolean;
  eventItem?: EventItem;
  claimId?: string;
  instructionItem?: InstructionItem;
  regardingId: string;
  onChange?: () => void;
  contract: Contract;
  projectId?: string;
  projectName?: string;
} & Omit<
  ClaimWidgetInnerProps,
  | "detailsExplorerItems"
  | "detailsExplorerItemsLoading"
  | "claimActionList"
  | "claim"
>;

const ClaimWidgetInner: React.FC<ClaimWidgetInnerProps> = ({
  previewMode,
  claim,
  detailsExplorerItems,
  detailsExplorerItemsLoading,
  claimActionList,
}) => {
  const { isFIDIC99RedYellow } = useContext(ClaimWidgetContext);
  const shallDisplayNextStepSection =
    claim?.nextStep || claimActionList.length > 0;

  const mostRecentExplorerItem = useMemo(() => {
    const agreementOrDetermination = detailsExplorerItems.find(
      (item) =>
        item.__typename === "ClaimAgreement" ||
        item.__typename === "ClaimDetermination"
    );

    // return first and only ClaimAgreement/ClaimDetermination or most recent DetailedClaim
    return (
      agreementOrDetermination ??
      detailsExplorerItems
        .slice()
        .filter((item) => item.__typename === "DetailedClaim")
        .sort(
          (dc1, dc2) =>
            new Date(dc2.dateCreated).getTime() -
            new Date(dc1.dateCreated).getTime()
        )[0]
    );
  }, [detailsExplorerItems]);

  const { contract } = useContext(ClaimWidgetContext);

  return (
    <Grid
      container
      spacing={previewMode ? 1.5 : 2}
      data-testid="claim-widget-container"
    >
      {!previewMode && claim && (
        <Grid item xs={12}>
          <WidgetHeader productItem={claim} productType={ProductType.Claims} />
        </Grid>
      )}
      {shallDisplayNextStepSection && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.NextStep}>
            <ClaimWidgetNextStepSection
              claim={claim}
              previewMode={previewMode}
              claimActionList={claimActionList}
            />
          </WidgetSection>
        </Grid>
      )}
      {claim && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.LastAction}>
            <ClaimWidgetLastStepSection
              previewMode={previewMode}
              claim={claim}
            />
          </WidgetSection>
        </Grid>
      )}
      {claim && detailsExplorerItems.length > 0 && !previewMode && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.Quotation}>
            <ContractPriceTimeChangeSection
              time={mostRecentExplorerItem.time}
              price={mostRecentExplorerItem.price}
              currency={mostRecentExplorerItem.currency}
              reference={mostRecentExplorerItem.reference}
              loading={detailsExplorerItemsLoading}
              contract={contract}
              regardingItemNumber={claim.number}
              productType={ProductType.Claims}
              explorerItems={detailsExplorerItems}
              explorerItemsLoading={detailsExplorerItemsLoading}
              isFIDIC99RedYellow={isFIDIC99RedYellow}
            />
          </WidgetSection>
        </Grid>
      )}
      {claim && claim.advanceWarningId && !previewMode && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.EarlyWarning}>
            <WidgetEwAwSection
              productItem={claim}
              isFIDIC99RedYellow={isFIDIC99RedYellow}
              contractTimezone={contract.timeZone}
            />
          </WidgetSection>
        </Grid>
      )}
    </Grid>
  );
};

export const ClaimWidget: React.FC<ClaimWidgetProps> = ({
  claimId,
  contract,
  projectId,
  projectName,
  eventItem,
  instructionItem,
  previewMode,
  regardingId,
  loading,
  onChange,
}) => {
  const { claim, claimLoading, refetchClaim } = useClaim(claimId);
  const { claimProductInstance, loading: claimProdInstanceLoading } =
    useClaimWidget(contract);
  const { detailsExplorerItems, loading: detailsExplorerItemsLoading } =
    useClaimDetailsExplorerItems(claimId);

  const { claimActionList, loading: claimActionListLoading } =
    useClaimWidgetNextStepSection(
      eventItem ? ClaimRegardingType.Event : ClaimRegardingType.Instruction,
      regardingId,
      claimProductInstance?.id ?? "",
      claimId
    );

  const refetchClaimItem = useCallback(() => {
    if (claimId) {
      refetchClaim({ id: claimId });
    } else {
      onChange?.();
    }
  }, [claimId, refetchClaim, onChange]);

  const claimContextValue = useMemo(
    (): ClaimWidgetContextType => ({
      productInstanceId: claimProductInstance?.id ?? "",
      productInstanceNumberingFormat:
        claimProductInstance?.numberingFormat ?? "",
      recordSupported: !!claimProductInstance?.soloModeSupported,
      contract: contract!,
      projectId: projectId ?? "", // TODO to check if needed
      projectName: projectName ?? "",
      regardingType: eventItem
        ? ClaimRegardingType.Event
        : ClaimRegardingType.Instruction,
      isFIDIC99RedYellow: isFIDIC99RedYellowContractType(contract),
      regardingId,
      instructionItem,
      eventItem,
      claim,
      onChange: refetchClaimItem,
    }),
    [
      projectId,
      projectName,
      eventItem,
      instructionItem,
      contract,
      regardingId,
      claimProductInstance,
      claim,
      refetchClaimItem,
    ]
  );

  const showLoadingState =
    loading ||
    claimLoading ||
    claimProdInstanceLoading ||
    claimActionListLoading ||
    !claimContextValue.productInstanceId ||
    !contract;

  return showLoadingState ? (
    <CenteredLoadingIndicator />
  ) : (
    <ClaimWidgetContext.Provider value={claimContextValue}>
      <ClaimWidgetInner
        previewMode={previewMode}
        claimActionList={claimActionList}
        detailsExplorerItems={detailsExplorerItems}
        detailsExplorerItemsLoading={detailsExplorerItemsLoading}
        claim={claim}
      />
    </ClaimWidgetContext.Provider>
  );
};
