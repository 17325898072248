import { gql } from "@apollo/client";
import { companyLookupCollectionFields } from "graphql/fragments/companyLookupCollection.fragment";

export const addCompanyLookupCollectionMutation = gql`
  ${companyLookupCollectionFields}

  mutation addCompanyLookupCollection(
    $input: AddCompanyLookupCollectionInput!
  ) {
    addCompanyLookupCollection(input: $input) {
      ...CompanyLookupCollectionFields
    }
  }
`;
