import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  DraftVariationDetermination,
  VariationActionType,
} from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordDeterminationOfVariationNoticeModal } from "./useRecordDeterminationOfVariationNoticeModal";
import { VariationProposalAgreementDeterminationSummary } from "../components/VariationProposalAgreementDeterminationSummary";

export type RecordDeterminationOfVariationNoticeModalProps = {
  draftVariationDeterminations: DraftVariationDetermination[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordDeterminationOfVariationNoticeModal: React.FC<
  RecordDeterminationOfVariationNoticeModalProps
> = ({ draftVariationDeterminations, ...restDialogProps }) => {
  const { variationPreview, contract } =
    useRecordDeterminationOfVariationNoticeModal(
      draftVariationDeterminations[0].id
    );
  return (
    <RecordAction
      actionType={VariationActionType.NotifyDeterminationVariation}
      variationPreview={variationPreview}
      summaryEl={
        <VariationProposalAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftVariationDeterminations}
          type="VariationDetermination"
        />
      }
      {...restDialogProps}
    />
  );
};
