import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationErrorAgreementDisagreementPromptQuery = gql`
  query variationErrorAgreementDisagreementPrompt(
    $input: VariationErrorAgreementDisagreementPromptInput!
  ) {
    variationErrorAgreementDisagreementPrompt(input: $input) {
      remarks
    }
  }
`;

export const sendVariationErrorAgreementDisagreementMutation = gql`
  mutation sendVariationErrorAgreementDisagreement(
    $input: SendVariationErrorAgreementDisagreementInput!
  ) {
    sendVariationErrorAgreementDisagreement(input: $input) {
      id
    }
  }
`;

export const recordVariationErrorAgreementDisagreementMutation = gql`
  mutation recordVariationErrorAgreementDisagreement(
    $input: RecordVariationErrorAgreementDisagreementInput!
  ) {
    recordVariationErrorAgreementDisagreement(input: $input) {
      id
    }
  }
`;

export const generateSendVariationErrorAgreementDisagreementPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationErrorAgreementDisagreementPreview(
    $input: SendVariationErrorAgreementDisagreementPreviewInput!
  ) {
    generateSendVariationErrorAgreementDisagreementPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationErrorAgreementDisagreementPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationErrorAgreementDisagreementPreview(
    $input: RecordVariationErrorAgreementDisagreementPreviewInput!
  ) {
    generateRecordVariationErrorAgreementDisagreementPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
