import { Contract, InstructionItem, VariationItem } from "generated/graphql";
import { createContext } from "react";

export type VariationWidgetContextType = {
  productInstanceId: string;
  productInstanceNumberingFormat: string;
  recordSupported: boolean;
  projectId: string;
  projectName: string;
  contract: Contract;
  regardingId: string;
  variation?: VariationItem;
  instructionItem?: InstructionItem;
  isFIDIC17White?: boolean;
  isFIDIC99RedYellow?: boolean;
  onChange?: () => void;
};

const contextDefaultValue: VariationWidgetContextType = {
  productInstanceId: "",
  productInstanceNumberingFormat: "",
  projectId: "",
  projectName: "",
  recordSupported: false,
  contract: {} as Contract,
  regardingId: "",
  isFIDIC17White: undefined,
  isFIDIC99RedYellow: undefined,
  variation: undefined,
  onChange: undefined,
  instructionItem: undefined,
};

export const VariationWidgetContext =
  createContext<VariationWidgetContextType>(contextDefaultValue);
