import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventNoticeOfSilenceOwnAssessmentMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventNoticeOfSilenceOwnAssessment(
    $input: SendCompEventNoticeOfSilenceOwnAssessmentInput!
  ) {
    sendCompEventNoticeOfSilenceOwnAssessment(input: $input) {
      ...CompEventItemFields
    }
  }
`;
