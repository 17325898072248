import { Color, useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const EmptyViewIcon: React.FC<BaseIconProps> = ({
  height = "88",
  width = "89",
  color,
}) => {
  const theme = useTheme();
  const computedColor =
    color ?? (theme.palette.secondary as Partial<Color>)[600];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 89 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="88" height="88" rx="24" fill="#E3F3E5" />
      <path
        d="M60.248 65.001H28.748C28.2839 65.001 27.8388 64.8166 27.5106 64.4884C27.1824 64.1602 26.998 63.7151 26.998 63.251V24.751C26.998 24.2868 27.1824 23.8417 27.5106 23.5135C27.8388 23.1854 28.2839 23.001 28.748 23.001H49.748L61.998 35.251V63.251C61.998 63.7151 61.8137 64.1602 61.4855 64.4884C61.1573 64.8166 60.7122 65.001 60.248 65.001Z"
        stroke={computedColor}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.748 23.001V35.251H61.998"
        stroke={computedColor}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.957 52.7061L51.457 56.2061"
        stroke={computedColor}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.625 54.499C47.0077 54.499 49.75 51.7568 49.75 48.374C49.75 44.9913 47.0077 42.249 43.625 42.249C40.2423 42.249 37.5 44.9913 37.5 48.374C37.5 51.7568 40.2423 54.499 43.625 54.499Z"
        stroke={computedColor}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
