import { Box, Typography, useTheme } from "@mui/material";
import { EmptyViewIcon } from "components/Icons/EmptyViewIcon";
import { useTranslation } from "react-i18next";

export enum EmptyViewStep {
  ProjectNotSelected = "ProjectNotSelected",
  ContractNotSelected = "ContractNotSelected",
  ProductNotSelected = "ProductNotSelected",
  ProductInstanceNotSelected = "ProductInstanceNotSelected",
}

export const EmptyView: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <EmptyViewIcon />
      <Typography
        variant="h2"
        fontWeight={700}
        color={theme.palette.grey[800]}
        mt={4}
      >
        {t("common.labels.getStarted")}
      </Typography>
      <Typography variant="p1" color={theme.palette.grey[700]} mt={1}>
        {t("Projects.labels.emptyViewSubText")}
      </Typography>
    </Box>
  );
};
