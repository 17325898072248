import { Box, Typography, useTheme } from "@mui/material";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { CompEventConfirmationPrompt } from "generated/graphql";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export const ConfirmCESummary: React.FC<CompEventConfirmationPrompt> = memo(
  ({ assumptions, ewCouldveBeenGiven }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="p2" color="grey.800" fontWeight={600}>
          {t("Projects.CompEvents.ActionModal.assumptions")}
        </Typography>
        <RichTextArea
          readOnly
          content={assumptions || t("common.labels.none")}
          color={theme.palette.grey[600]}
        />
        <Box mt={2} display="flex" flexDirection="column">
          <Typography variant="p2" color="grey.800" fontWeight={600}>
            {t("Projects.CompEvents.ActionModal.ewCouldveBeenGivenButWasNot")}
          </Typography>
          <Typography variant="p1" color="grey.600">
            {t(`common.labels.${String(ewCouldveBeenGiven)}`)}
          </Typography>
        </Box>
      </Box>
    );
  }
);
