import { Box, Grid, Typography } from "@mui/material";
import { SeverityPreset } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { ConsequenceLikelihoodListItem } from "./ConsequenceLikelihoodListItem";
import { SeverityPreview } from "./SeverityPreview";

export type SeverityEditableProps = {
  severityPreset: SeverityPreset;
  consequenceValue?: number;
  likelihoodValue?: number;
  onChange: (newValue: string) => void;
};

export const SeverityEditable: React.FC<SeverityEditableProps> = ({
  severityPreset,
  consequenceValue,
  likelihoodValue,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleConsequenceChange = (newConsequenceValue: number) => {
    onChange(
      JSON.stringify({
        consequence: newConsequenceValue,
        likelihood: likelihoodValue,
      })
    );
  };
  const handleLikelihoodChange = (newLikelihoodValue: number) => {
    onChange(
      JSON.stringify({
        consequence: consequenceValue,
        likelihood: newLikelihoodValue,
      })
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography mb={5} variant="p2" color="grey.600">
        {t("Projects.Risks.selectConsequenceAndLikelihood")}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Typography
            variant="p1"
            fontWeight={600}
            mb={2}
            display="inline-block"
          >
            {t("Projects.Risks.consequence")}
          </Typography>
          <Grid container spacing={2}>
            {severityPreset.consequenceSets.map((consequence, index) => (
              <Grid item xs={12} key={consequence}>
                <ConsequenceLikelihoodListItem
                  itemSet={severityPreset.consequenceSets}
                  value={index + 1}
                  selected={consequenceValue === index + 1}
                  label={consequence}
                  onClick={() => handleConsequenceChange(index + 1)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="p1"
            fontWeight={600}
            flex={1}
            mb={2}
            display="inline-block"
          >
            {t("Projects.Risks.likelihood")}
          </Typography>
          <Grid container spacing={2}>
            {severityPreset.likelihoodSets.map((likelihood, index) => (
              <Grid item xs={12} key={likelihood}>
                <ConsequenceLikelihoodListItem
                  itemSet={severityPreset.likelihoodSets}
                  value={index + 1}
                  selected={likelihoodValue === index + 1}
                  label={likelihood}
                  onClick={() => handleLikelihoodChange(index + 1)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6} alignSelf="flex-end">
          <SeverityPreview
            size="large"
            severityPreset={severityPreset}
            consequenceValue={consequenceValue}
            likelihoodValue={likelihoodValue}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
