import { Box, css, styled } from "@mui/material";

export const LevelConnector = styled(Box)(
  ({ theme }) => css`
    border-left: 1px solid ${theme.palette.grey[300]};
    height: 100%;
    position: absolute;
    left: 24px;
  `
);
