import { Box, Color, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { IconContainer } from "components/IconContainer";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { RecipientsPreview } from "containers/Projects/components/RecipientsPreview";
import {
  RecordProductItemExtraData,
  RecordProductItemForm,
} from "containers/Projects/components/RecordProductItemForm";
import { useContractBindingTypes } from "containers/Projects/hooks/useContractBindingTypes";
import {
  ClaimActionType,
  ProductType,
  RecordClaimAgreementNoticePreview,
  RecordClaimDetailsResponsePreview,
  RecordClaimDeterminationNoticePreview,
  RecordClaimErrorAgreementDisagreementPreview,
  RecordClaimErrorAgreementNoticePreview,
  RecordClaimErrorDeterminationDisagreementPreview,
  RecordClaimErrorDeterminationNoticePreview,
  RecordClaimFinalClaimDetailsPreview,
  RecordClaimFurtherParticularsRequestPreview,
  RecordClaimFurtherParticularsSubmissionPreview,
  RecordClaimInterimClaimDetailsPreview,
  RecordClaimNoAgreementNoticePreview,
  RecordClaimNoticeInvalidNoticePreview,
  RecordClaimNoticeLapsedNoticePreview,
  RecordClaimNoticePreview,
} from "generated/graphql";
import { Plugs } from "phosphor-react";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { ActionModalTitle } from "containers/Projects/components/ActionModal/ActionModalTitle";
import { claimActionTypeTranslations } from "../../ClaimWidget/ClaimWidget.constants";
import { claimActionTypeFIDIC99RedYellowTypeMapping } from "../../types";

export type RecordActionProps = {
  claimPreview?:
    | RecordClaimNoticePreview
    | RecordClaimInterimClaimDetailsPreview
    | RecordClaimFinalClaimDetailsPreview
    | RecordClaimAgreementNoticePreview
    | RecordClaimNoAgreementNoticePreview
    | RecordClaimDeterminationNoticePreview
    | RecordClaimFurtherParticularsRequestPreview
    | RecordClaimFurtherParticularsSubmissionPreview
    | RecordClaimNoticeInvalidNoticePreview
    | RecordClaimNoticeLapsedNoticePreview
    | RecordClaimDetailsResponsePreview
    | RecordClaimErrorAgreementNoticePreview
    | RecordClaimErrorAgreementDisagreementPreview
    | RecordClaimErrorDeterminationNoticePreview
    | RecordClaimErrorDeterminationDisagreementPreview;
  actionType: ClaimActionType;
  summaryEl: React.ReactNode;
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordAction: React.FC<RecordActionProps> = ({
  summaryEl,
  claimPreview,
  actionType,
  onPrimaryClick,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    contract,
    productInstanceNumberingFormat,
    claim,
    projectName,
    isFIDIC99RedYellow,
  } = useContext(ClaimWidgetContext);
  const { bindingTypes, loading: bindingTypesLoading } =
    useContractBindingTypes(contract.id);
  const extraData = useRef<RecordProductItemExtraData>();
  const extraDataValid = useRef<boolean>(false);

  const handleExtraDataChange = (data: RecordProductItemExtraData) => {
    extraData.current = data;
  };

  const handleExtraDataValidityChange = (isValid: boolean) => {
    extraDataValid.current = isValid;
  };

  const handlePrimaryClick = () => {
    if (extraDataValid.current) {
      onPrimaryClick(extraData.current!);
    }
  };

  return (
    <ActionsDialog
      onPrimaryClick={handlePrimaryClick}
      primaryBtnCaption={t("common.buttons.record")}
      iconsHeader={
        <IconContainer>
          <Plugs
            size={18}
            color={(theme.palette.secondary as Partial<Color>)[700]}
          />
        </IconContainer>
      }
      primaryBtnDisabled={!claimPreview}
      content={
        !claimPreview || bindingTypesLoading ? (
          <CenteredLoadingIndicator />
        ) : (
          <Box>
            <ActionModalTitle mb={2}>
              {`${t(
                claimActionTypeTranslations[
                  isFIDIC99RedYellow
                    ? claimActionTypeFIDIC99RedYellowTypeMapping(actionType)
                    : actionType
                ]
              )} ${t("common.labels.record")}`}
            </ActionModalTitle>
            {summaryEl}
            <Box mt={4}>
              <RecordProductItemForm
                bindingTypes={bindingTypes}
                productType={ProductType.Claims}
                onChange={handleExtraDataChange}
                onValidationChange={handleExtraDataValidityChange}
              />
            </Box>
            <Box mt={4}>
              <ProductItemCard
                itemName={
                  claim?.number ?? `${productInstanceNumberingFormat}-XXXX`
                }
                productType={ProductType.Claims}
                projectName={projectName}
                contractName={contract.friendlyName}
              />
            </Box>
            <Box mt={4}>
              <CollapsibleContainer
                title={`${t(`common.labels.recipients`)} (${
                  claimPreview.recipients.length
                })`}
              >
                <RecipientsPreview recipients={claimPreview.recipients} />
              </CollapsibleContainer>
            </Box>
          </Box>
        )
      }
      sx={{ minWidth: "420px" }}
      {...restDialogProps}
    />
  );
};
