import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import {
  CompEventDenyingOfMoreTimePrompt,
  CompEventDenyingOfMoreTimePromptQuery,
  CompEventDenyingOfMoreTimePromptQueryVariables,
  RecordCompEventDenyingOfMoreTimeMutation,
  RecordCompEventDenyingOfMoreTimeMutationVariables,
  SendCompEventDenyingOfMoreTimeMutation,
  SendCompEventDenyingOfMoreTimeMutationVariables,
} from "generated/graphql";
import { recordCompEventDenyingOfMoreTimeMutation } from "graphql/mutations/recordCompEventDenyingOfMoreTime";
import { sendCompEventDenyingOfMoreTimeMutation } from "graphql/mutations/sendCompEventDenyingOfMoreTime";
import { compEventDenyingOfMoreTimePromptQuery } from "graphql/queries/compEventDenyingOfMoreTimePrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const useDenyMoreTimeToRespondCEAction = () => {
  const { onChange, compEvent } = useContext(CompEventWidgetContext);
  const { t } = useTranslation();

  // TODO: not used right now, but will probabaly be when displaying the requestedDays & remarks of the other party before accepting/denying the request
  const {
    data: denyMoreTimePromptData,
    loading: denyMoreTimePromptDataLoading,
  } = useGraphQuery<
    CompEventDenyingOfMoreTimePromptQuery,
    CompEventDenyingOfMoreTimePromptQueryVariables
  >(compEventDenyingOfMoreTimePromptQuery, {
    variables: {
      input: {
        compEventId: compEvent?.id!,
      },
    },
  });

  const [
    sendCompEventDenialForMoreTime,
    { loading: sendCompEventDenialForMoreTimeLoading },
  ] = useGraphMutation<
    SendCompEventDenyingOfMoreTimeMutation,
    SendCompEventDenyingOfMoreTimeMutationVariables
  >(
    sendCompEventDenyingOfMoreTimeMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventDenialOfMoreTime"),
    })
  );

  const [
    recordCompEventDenialForMoreTime,
    { loading: recordCompEventDenialForMoreTimeLoading },
  ] = useGraphMutation<
    RecordCompEventDenyingOfMoreTimeMutation,
    RecordCompEventDenyingOfMoreTimeMutationVariables
  >(
    recordCompEventDenyingOfMoreTimeMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventDenialOfMoreTime"),
    })
  );

  return {
    loading: denyMoreTimePromptDataLoading,
    actionLoading:
      sendCompEventDenialForMoreTimeLoading ||
      recordCompEventDenialForMoreTimeLoading,
    denyMoreTimePromptData:
      denyMoreTimePromptData?.compEventDenyingOfMoreTimePrompt as
        | CompEventDenyingOfMoreTimePrompt
        | undefined,
    denyMoreTimePromptDataLoading,
    sendCompEventDenialForMoreTime,
    recordCompEventDenialForMoreTime,
  };
};
