import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  defaultSeverityIndex,
  SeverityColors,
  SeverityColorsMapping,
} from "./Severity.constants";
import { SeverityReadOnlyProps } from "./Severity.decl";
import { computeSeverityRating } from "./Severity.utils";
import { SeverityPickerItemIcon } from "./SeverityPickerItemIcon";

export const SeverityPreviewLarge: React.FC<SeverityReadOnlyProps> = ({
  severityPreset,
  consequenceValue,
  likelihoodValue,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const severityRatingValue =
    consequenceValue && likelihoodValue
      ? consequenceValue * likelihoodValue
      : undefined;
  const severityRating = computeSeverityRating(
    severityPreset,
    consequenceValue,
    likelihoodValue
  );

  return (
    <Box
      sx={{
        background: theme.palette.grey[100],
        borderRadius: "16px",
        height: "230px",
      }}
      p={3}
      pr={5}
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="p1" color={theme.palette.grey[600]}>
            {t("Projects.Risks.severity")}
          </Typography>
          <Typography variant="h1" color={theme.palette.grey[800]} mt={1}>
            {severityRating.name}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Grid container spacing={1}>
            {severityPreset.ratings.items.map((rating) => (
              <Grid
                item
                display="flex"
                alignItems="center"
                key={rating.id}
                xs={12}
              >
                <Box
                  sx={{
                    width: "8px",
                    height: "12px",
                    borderRadius: "12px",
                    backgroundColor:
                      SeverityColorsMapping[rating.colour as SeverityColors]
                        .filledColor,
                  }}
                ></Box>
                <Typography variant="p3" color={theme.palette.grey[800]} ml={1}>
                  {rating.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="flex-end">
        <SeverityPickerItemIcon
          itemSet={severityPreset.likelihoodSets}
          value={
            severityRatingValue
              ? severityPreset.ratings.items.indexOf(severityRating) + 1
              : defaultSeverityIndex
          }
          height={170}
          width={150}
          step={25}
          highContrast={true}
          filledColor={{
            highContrastColor:
              SeverityColorsMapping[severityRating.colour as SeverityColors]
                .filledColor,
            lowContrastColor: "",
          }}
          unfilledColor={{
            highContrastColor:
              SeverityColorsMapping[severityRating.colour as SeverityColors]
                .unfilledColor,
            lowContrastColor: "",
          }}
        />
      </Box>
    </Box>
  );
};
