import { gql } from "@apollo/client";
import { dailyDiaryItemRegisterViewFields } from "./dailyDiaryItems.query";

export const dailyDiaryItemsSentByMeQuery = gql`
  ${dailyDiaryItemRegisterViewFields}

  query dailyDiaryItemsSentByMe(
    $productInstanceId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    dailyDiaryItemsSentByMe(
      productInstanceId: $productInstanceId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        ...DailyDiaryItemRegisterViewFields
      }
    }
  }
`;
