import { gql } from "@apollo/client";

export const addProjectMutation = gql`
  mutation addProject($input: AddProjectInput!) {
    addProject(input: $input) {
      id
      name
      dateCreated
      creator {
        id
        firstname
        surname
      }
      status
      friendlyName
      number
      sector
      description
    }
  }
`;
