import { css, styled } from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";

export const StyledNestedMenuItem = styled(NestedMenuItem)(
  ({ theme }) => css`
    && {
      padding-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
      p {
        font-size: 14px;
      }

      svg {
        height: 20px;
        color: ${theme.palette.grey[800]};
      }
    }
  `
);
