import { Box, Stack, Typography } from "@mui/material";
import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { FormLabel } from "components/FormLabel";
import { IconContainer } from "components/IconContainer";
import { PrimarySecondaryTertiaryButtons } from "components/PrimarySecondaryTertiaryButtons";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { ShieldCheck } from "phosphor-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const dataValidators: DataValidators = {
  denialReason: {
    validators: [ValidatorType.RequiredRichText],
  },
};

type FormData = {
  denialReason: string;
};

export type DenyAuthorizationModalProps = {
  primaryBtnLoading?: boolean;
  onCancel: () => void;
  onBack: () => void;
  onConfirm: (denialReason: string) => void;
} & Omit<BasicModalProps, "onClose">;

export const DenyAuthorizationModal: React.FC<DenyAuthorizationModalProps> = ({
  open,
  primaryBtnLoading,
  onCancel,
  onBack,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    denialReason: "",
  });
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateForm = useCallback((formData: FormData) => {
    const validationResult = validateData(formData, dataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);

    return false;
  }, []);

  const handleTextFieldChange = (reason: string) => {
    setFormData((curData) => ({
      ...curData,
      denialReason: reason,
    }));

    validateForm({ ...formData, denialReason: reason });
  };

  const handleConfirm = () => {
    if (validateForm(formData)) {
      onConfirm(formData.denialReason);
    }
  };

  return (
    <BasicModal
      open={open}
      title={
        <Stack spacing={1}>
          <Typography
            variant="h3"
            fontWeight={600}
            fontSize="20px"
            color="grey.800"
          >
            {t("Home.Authorizations.confirmAuthorizationDenialTitle")}
          </Typography>
          <Typography variant="p1" color="grey.700">
            {t("Home.Authorizations.confirmAuthorizationDenialText")}
          </Typography>
        </Stack>
      }
      maxWidth="md"
      headerIcon={
        <IconContainer>
          <ShieldCheck size={24} />
        </IconContainer>
      }
      onClose={onBack}
      paperProps={{ className: "deny-authorization-modal" }}
    >
      <Box mt={-2}>
        <FormLabel
          label={t("Home.Authorizations.authorizationDenialReason")}
          required
        />
        <RichTextArea
          content={formData.denialReason}
          error={!!formDataErrors.denialReason}
          onChange={handleTextFieldChange}
        />
        {!!formDataErrors.denialReason && (
          <Typography variant="caption" color="error" mt={0.5}>
            {formDataErrors.denialReason}
          </Typography>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={4}>
        <PrimarySecondaryTertiaryButtons
          primaryBtnLoading={primaryBtnLoading}
          secondaryBtnCaption={t("common.buttons.back")}
          primaryBtnCaption={t("common.buttons.confirm")}
          onTertiaryClick={onCancel}
          onSecondaryClick={onBack}
          onPrimaryClick={handleConfirm}
        />
      </Box>
    </BasicModal>
  );
};
