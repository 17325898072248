import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { CompanyLookupCollection } from "generated/graphql";
import { SyntheticEvent } from "react";

type LookupGridItemProps = {
  options: CompanyLookupCollection[];
  value: CompanyLookupCollection | null;
  label: string;
  loading?: boolean;
  errorMsg?: string;
  onChange: (
    evt: SyntheticEvent<Element, Event>,
    value: CompanyLookupCollection | null
  ) => void;
};

export const LookupGridItem: React.FC<LookupGridItemProps> = ({
  options,
  label,
  value,
  loading,
  errorMsg,
  onChange,
}) => {
  return (
    <Grid item md={6} xs={12}>
      <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
        <>
          <InputLabel id={`${label}-lookup-select-label`} shrink>
            {label}
          </InputLabel>
          <Autocomplete
            disablePortal
            id={`${label}-lookup-autcomplete`}
            options={options || []}
            sx={{ maxHeight: 200 }}
            value={value}
            disabled={loading}
            onChange={onChange}
            getOptionLabel={(lookupCollection) => lookupCollection.name}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={label}
                error={!!errorMsg}
                helperText={errorMsg}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          />
        </>
      </FormControl>
    </Grid>
  );
};
