import React, { useState, useCallback, useRef } from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "services/AuthService";
import { ErrorsType } from "../../types";
import { ResetPasswordForm } from "./components/ResetPasswordForm";
import { ResetPasswordResponseType } from "./ResetPassword.decl";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useConfig } from "providers/config.context";
import { NewAuthPageTemplate } from "components/NewAuthPageTemplate";
import { ResetPasswordEmailSentView } from "./components/ResetPasswordEmailSentView";
import { NewAppPaths } from "helpers/paths/paths";
import { snackbarAutoHideDuration } from "constants/constants";

enum ResetPasswordStep {
  ForgotPassword = "forgotPassword",
  SecurityCodeSent = "securityCodeSent",
}

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  const userEmail = useRef<string>();
  const [loading, setLoading] = useState(false);
  const [resetPasswordErrors, setResetPasswordErrors] = useState("");
  const [step, setStep] = useState<ResetPasswordStep>(
    ResetPasswordStep.ForgotPassword
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { config } = useConfig();

  const handleResetPassword = useCallback(
    async (email: string) => {
      setLoading(true);
      userEmail.current = email;
      const response = await resetPassword(
        config.REACT_APP_AUTH_BASE_URL,
        email
      );
      setLoading(false);

      if ((response as ResetPasswordResponseType).AttributeName) {
        setStep(ResetPasswordStep.SecurityCodeSent);
        enqueueSnackbar(t("common.successMessages.resetPassword"), {
          autoHideDuration: snackbarAutoHideDuration,
          variant: "success",
        });
      } else {
        setResetPasswordErrors((response as ErrorsType).Errors[0]);
      }
    },
    [enqueueSnackbar, t, config]
  );

  const goToSecurityCodeValidationStep = () => {
    navigate(
      `${NewAppPaths.nonAuthorized.SetNewPassowrd}?email=${userEmail.current!}`
    );
  };

  return (
    <NewAuthPageTemplate>
      {step === ResetPasswordStep.ForgotPassword ? (
        <>
          <ResetPasswordForm loading={loading} onReset={handleResetPassword} />
          {resetPasswordErrors && (
            <Typography variant="caption" color="error" pt={1} display="block">
              {resetPasswordErrors}
            </Typography>
          )}
        </>
      ) : step === ResetPasswordStep.SecurityCodeSent ? (
        <ResetPasswordEmailSentView
          onEnterSecurityCodeClick={goToSecurityCodeValidationStep}
        />
      ) : null}
    </NewAuthPageTemplate>
  );
};
