import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusTag, StatusOption } from "components/StatusTag/StatusTag";
import { Contract, ContractStatus } from "generated/graphql";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export const useColumns = (
  contractStatusOptions: StatusOption<ContractStatus>[],
  onStatusChange: (row: Contract, newStatus: ContractStatus) => void
): GridColDef<Contract>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();

  const columns = useMemo(
    () =>
      [
        {
          field: "friendlyName",
          headerName: t("AdminConsole.Contracts.labels.contract"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "Status",
          headerName: t("common.labels.status"),
          flex: 0.075,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (params: GridRenderCellParams<any, Contract, any>) => {
            return (
              <StatusTag
                status={params.row.status}
                options={contractStatusOptions}
                onChange={(newStatus: StatusOption<ContractStatus>) =>
                  onStatusChange(params.row, newStatus.id as ContractStatus)
                }
              />
            );
          },
        },
        {
          field: "Project",
          headerName: t("AdminConsole.Projects.labels.project"),
          flex: 0.2,
          width: 120,
          resizable: true,
          valueGetter: (_, row) => row.project.friendlyName,
        },
        dateCreatedColConfig,
      ] as GridColDef<Contract>[],
    [t, dateCreatedColConfig, onStatusChange, contractStatusOptions]
  );
  return columns;
};
