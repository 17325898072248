import {
  Box,
  ClickAwayListener,
  Fade,
  List,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useMemo,
  useState,
} from "react";
import { CountryData } from "helpers/countries/countries.decl";
import { countriesData } from "helpers/countries/countries.constants";
import {
  PopperContentContainer,
  StyledListItem,
} from "../ListComponents/ListComponents";
import { ValueContainer } from "./CountriesSelectNew.styled";
import { FlagCountryName } from "./FlagCountryName";
import {
  computeCountryToFocusDown,
  computeCountryToFocusUp,
  getCountryListItemId,
} from "./CountriesSelectNew.utils";
import { CaretDown } from "phosphor-react";

export type CountriesSelectNewProps = {
  value: CountryData | undefined;
  hideSelectedCountryName?: boolean;
  hasErrors?: boolean;
  className?: string;
  placeholder?: string;
  adminConsoleStyle?: boolean;
  onChange: (newCountry: CountryData) => void;
};

export const CountriesSelectNew: React.FC<CountriesSelectNewProps> = ({
  value,
  hideSelectedCountryName,
  hasErrors,
  className,
  placeholder,
  adminConsoleStyle,
  onChange,
}) => {
  const [focusedCountry, setFocusedCountry] = useState<CountryData>();
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!popperRef?.contains((event as any).target)) {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "transition-popper" : undefined;

  const closeFlyout = () => {
    setAnchorEl(null);
    setSearchQuery("");
    setFocusedCountry(undefined);
  };

  const handleClickAway = () => {
    closeFlyout();
  };

  const handleItemClick = (newCountry: CountryData) => {
    onChange(newCountry);
    closeFlyout();
  };

  const handleSearchQueryChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (evt) => {
    setSearchQuery(evt.target.value);
  };

  const focusCountry = (country: CountryData) => {
    setFocusedCountry(country);
    const countryElId = getCountryListItemId(country);
    const countryEl = document.getElementById(countryElId);
    countryEl?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  const handleArrowDown = () => {
    const countryToFocus = computeCountryToFocusDown(
      filteredCountries,
      focusedCountry,
      value
    );

    if (countryToFocus) {
      focusCountry(countryToFocus);
    }
  };

  const handleArrowUp = () => {
    const countryToFocus = computeCountryToFocusUp(
      filteredCountries,
      focusedCountry,
      value
    );
    if (countryToFocus) {
      focusCountry(countryToFocus);
    }
  };

  const handleEnter = () => {
    focusedCountry && handleItemClick(focusedCountry);
  };

  const handleSearchKeyDown: KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === "ArrowDown") {
      handleArrowDown();
    } else if (evt.key === "Tab") {
      closeFlyout();
    }
  };

  const handleTriggerKeyDown: KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === "Enter") {
      handleClick(evt as unknown as React.MouseEvent<HTMLElement>);
    }
  };

  const handleContainerKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (
    evt
  ) => {
    if (evt.key === "Escape") {
      closeFlyout();
    } else if (evt.key === "ArrowDown") {
      handleArrowDown();
    } else if (evt.key === "ArrowUp") {
      handleArrowUp();
    } else if (evt.key === "Enter") {
      handleEnter();
    }
  };

  const filteredCountries = useMemo(() => {
    return Object.values(countriesData).filter((country) =>
      country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery]);

  return (
    <Box onClick={handleClick} className={className}>
      <ValueContainer
        focused={open}
        onKeyDown={handleTriggerKeyDown}
        tabIndex={0}
        direction="row"
        spacing={1}
        error={hasErrors}
        adminConsoleStyle={adminConsoleStyle}
        className="countries-select-value-container"
      >
        {value ? (
          <FlagCountryName
            country={value}
            hideSelectedCountryName={hideSelectedCountryName}
          />
        ) : placeholder ? (
          <Typography variant="p2" color="grey.200">
            {placeholder}
          </Typography>
        ) : null}
        <Box ml="auto !important" display="flex" alignItems="center">
          <CaretDown size={14} />
        </Box>
      </ValueContainer>
      {open && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement={"bottom-start"}
          disablePortal={true}
          ref={setPopperRef}
          sx={{ zIndex: 1300 }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <PopperContentContainer
                  width="100%"
                  onKeyDown={handleContainerKeyDown}
                >
                  <Stack>
                    <Box px={1} pt={1}>
                      <TextField
                        placeholder="Search"
                        onChange={handleSearchQueryChange}
                        onKeyDown={handleSearchKeyDown}
                        size="small"
                        autoComplete="off"
                        error={hasErrors}
                        fullWidth
                        autoFocus
                      />
                    </Box>
                    <Box maxHeight={300} overflow="auto">
                      <List>
                        {filteredCountries.map((country) => (
                          <StyledListItem
                            id={getCountryListItemId(country)}
                            key={country.countryCodeA2}
                            onClick={() => handleItemClick(country)}
                            size="small"
                            selected={
                              country.countryCodeA2 === value?.countryCodeA2
                            }
                            onMouseOver={() => setFocusedCountry(country)}
                            focused={
                              focusedCountry?.countryCodeA2 ===
                              country.countryCodeA2
                            }
                          >
                            <FlagCountryName country={country} />
                          </StyledListItem>
                        ))}
                      </List>
                    </Box>
                  </Stack>
                </PopperContentContainer>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </Box>
  );
};
