import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import React from "react";
import { useVariationHistoryItem } from "../hooks/useVariationHistoryItem/useVariationHistoryItem";
import { VariationHistoryItemDetailsSummary } from "containers/Projects/components/Variations/VariationHistoryModal/VariationHistoryItemDataView";
import { useContract } from "containers/Projects/hooks/useContract/useContract";

type VariationSummaryProps = {
  authAudit: AuthorizationWorkflowAudit;
};

export const VariationSummary: React.FC<VariationSummaryProps> = ({
  authAudit,
}) => {
  const { variationHistoryItem, loading } = useVariationHistoryItem(
    authAudit.regardingItemHistoryId ?? undefined
  );
  const { contract, contractLoading } = useContract(
    authAudit.action.productInstance.contractId
  );

  return loading || !variationHistoryItem || contractLoading || !contract ? (
    <CenteredLoadingIndicator />
  ) : (
    <VariationHistoryItemDetailsSummary
      contract={contract}
      historyItem={variationHistoryItem}
    />
  );
};
