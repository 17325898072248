import { Grid, Typography, useTheme } from "@mui/material";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { authorizationAuditTrailStatusOptions } from "../../../../../containers/Home/components/Authorizations/Authorizations.constants";
import {
  AuthorizationActionStatus,
  AuthorizationWorkflowAuditStatus,
  AuthorizationWorkflowAuditTrail,
  User,
} from "generated/graphql";
import { getUserNameOrEmail } from "helpers/miscelaneous";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";

export type AuthorizationLevelOverviewRecordProps = {
  user: User;
  auditTrail?: AuthorizationWorkflowAuditTrail;
  levelStatus: AuthorizationWorkflowAuditStatus;
  authAuditStatus: AuthorizationWorkflowAuditStatus;
  contractTimezone: string;
};

export const AuthorizationLevelOverviewRecord: React.FC<
  AuthorizationLevelOverviewRecordProps
> = ({ user, auditTrail, levelStatus, authAuditStatus, contractTimezone }) => {
  const theme = useTheme();

  const settledStatuses = [
    AuthorizationWorkflowAuditStatus.Authorized,
    AuthorizationWorkflowAuditStatus.Denied,
    AuthorizationWorkflowAuditStatus.Removed,
  ];
  const levelOrAuthAuditSettled =
    settledStatuses.includes(levelStatus) ||
    settledStatuses.includes(authAuditStatus);

  return (
    <Grid container py={1} px={3} data-testid={`authorizer-${user.id}`}>
      <Grid item xs={4}>
        <Typography variant="p2" color="grey.800">
          {getUserNameOrEmail(user)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {levelOrAuthAuditSettled &&
        (!auditTrail?.status ||
          auditTrail.status === AuthorizationActionStatus.Pending) ? null : (
          <StatusTagNew
            options={authorizationAuditTrailStatusOptions}
            selectedOptionId={
              auditTrail?.status ?? AuthorizationActionStatus.Pending
            }
            disabled
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {auditTrail?.dateCreated ? (
          <DateWithTimeTooltip
            datetime={auditTrail.dateCreated}
            timezone={contractTimezone}
            variant="p2"
            color="grey.600"
          />
        ) : (
          <Typography variant="p2" color="grey.600">
            -
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        <RichTextArea
          readOnly
          content={auditTrail?.denialReason ?? "-"}
          color={theme.palette.grey[600]}
        />
      </Grid>
    </Grid>
  );
};
