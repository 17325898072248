import { Box, Color, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { ProductType } from "generated/graphql";
import { PaperPlaneTilt } from "phosphor-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IconContainer } from "components/IconContainer";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import {
  ActionModalSubtitle,
  ActionModalTitle,
} from "containers/Projects/components/ActionModal/ActionModalTitle";
import moment from "moment";
import { dailyDiaryDateFormat } from "../../DailyDiary.constants";

export type SendDailyDiaryModalProps = ActionsDialogProps;

export const SendDailyDiaryModal: React.FC<SendDailyDiaryModalProps> = ({
  ...dialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { dailyDiary } = useContext(DailyDiaryContext);

  return (
    <ActionsDialog
      iconsHeader={
        <IconContainer>
          <PaperPlaneTilt
            size={18}
            color={(theme.palette.secondary as Partial<Color>)[700]}
          />
        </IconContainer>
      }
      title={
        <Box>
          <ActionModalTitle mb={1}>
            {t("Projects.DailyDiaries.SendDDModal.title")}
          </ActionModalTitle>
          <ActionModalSubtitle mb={2}>
            {t("Projects.DailyDiaries.SendDDModal.subtitle")}
          </ActionModalSubtitle>
        </Box>
      }
      primaryBtnCaption={t("common.buttons.send")}
      maxWidth="sm"
      fullWidth
      content={
        <Box>
          <ProductItemCard
            itemName={moment(dailyDiary?.date).format(dailyDiaryDateFormat)}
            productType={ProductType.DailyDiary}
            projectName={
              dailyDiary?.productInstance.contract.project.friendlyName ?? ""
            }
            contractName={
              dailyDiary?.productInstance.contract.friendlyName ?? ""
            }
          />
          {/* REVIEWERS go here  */}
        </Box>
      }
      {...dialogProps}
    />
  );
};
