import { IconButton } from "@mui/material";
import { useNavigateBack } from "hooks/useNavigateBack";
import { CaretLeft } from "phosphor-react";

export type HeaderBackButtonProps = {
  disabled?: boolean;
  onBack?: () => void;
};

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({
  disabled = false,
  onBack,
}) => {
  const navigateBack = useNavigateBack();

  return (
    <IconButton
      onClick={onBack ?? navigateBack}
      data-testid="back-btn"
      size="medium"
      disabled={disabled}
      sx={{ p: 0.75 }}
    >
      <CaretLeft />
    </IconButton>
  );
};
