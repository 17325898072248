import {
  DraftRiskItemsQuery,
  DraftRiskItemsQueryVariables,
  RiskItemsQuery,
  RiskItemsQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { draftRiskItemsQuery, riskItemsQuery } from "../Projects.query";

export const useRisksRegister = () => {
  const [
    fetchRiskItemsData,
    { data: riskItemsData, loading: riskItemsDataLoading },
  ] = useGraphLazyQuery<RiskItemsQuery, RiskItemsQueryVariables>(
    riskItemsQuery
  );

  const [
    fetchDraftRiskItemsData,
    { data: draftRiskItemsData, loading: draftRiskItemsDataLoading },
  ] = useGraphLazyQuery<DraftRiskItemsQuery, DraftRiskItemsQueryVariables>(
    draftRiskItemsQuery
  );

  return {
    riskItemsData,
    draftRiskItemsData,
    riskItemsDataLoading,
    draftRiskItemsDataLoading,
    risksLoading: riskItemsDataLoading || draftRiskItemsDataLoading,
    fetchDraftRiskItemsData,
    fetchRiskItemsData,
  };
};
