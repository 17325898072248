import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { ProductInstance, ProductInstanceStatus } from "generated/graphql";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<ProductInstanceStatus>[],
  onStatusChange: (
    row: ProductInstance,
    newStatus: ProductInstanceStatus
  ) => void
): GridColDef<ProductInstance>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "productName",
          headerName: t("common.labels.product"),
          flex: 0.125,
          minWidth: 290,
          resizable: true,
          valueGetter: (_, row) => {
            return row.product.name;
          },
        },
        {
          field: "description",
          headerName: t("AdminConsole.ProductInstances.labels.instance"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
        },
        {
          field: "numberingFormat",
          headerName: t("AdminConsole.ProductInstances.labels.numbering"),
          flex: 0.05,
          minWidth: 90,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, ProductInstance, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                onChange={(newStatus: StatusOption<ProductInstanceStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "productSchemaId",
          headerName: t("common.labels.config"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => {
            return row.productSchema.name;
          },
        },
        {
          field: "soloModeSupported",
          headerName: t("AdminConsole.ProductInstances.labels.mode"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => {
            return t(
              `AdminConsole.ProductInstances.labels.${
                row.soloModeSupported ? "solo" : "multi"
              }`
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
      ] as GridColDef<ProductInstance>[],
    [t, dateCreatedColConfig, statusOptions, onStatusChange]
  );
  return columns;
};
