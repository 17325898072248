import { useMemo } from "react";
import { useVariationProposalExplorerItems } from "./useVariationProposalExplorerItems";

export const useVariationConsentedOrFinalProposal = () => {
  const { variationProposals, loading } = useVariationProposalExplorerItems();

  const consentedProposal = useMemo(() => {
    return variationProposals.find((proposal) => proposal.consented);
  }, [variationProposals]);

  const finalProposal = useMemo(() => {
    return variationProposals.sort((proposal1, proposal2) => {
      const date1 = new Date(proposal1.dateCreated as string).getTime();
      const date2 = new Date(proposal2.dateCreated as string).getTime();

      return date2 - date1;
    })[0];
  }, [variationProposals]);

  return {
    consentedProposal,
    finalProposal,
    loading,
  };
};
