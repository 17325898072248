import { gql } from "@apollo/client";

export const contractTypeQuery = gql`
  query contractType($id: ID!) {
    contractType(id: $id) {
      id
      description
      subType
      version
      status
      contractBindingTypes {
        items {
          id
          description
          contractTypeId
          status
        }
      }
    }
  }
`;
