import { SeverityPreset } from "generated/graphql";
import { unknownSeverityRating } from "./Severity.constants";

export const computeSeverityRating = (
  severityPreset: SeverityPreset,
  consequenceValue?: number,
  likelihoodValue?: number
) => {
  const severityRatingValue =
    consequenceValue && likelihoodValue
      ? consequenceValue * likelihoodValue
      : undefined;

  return severityRatingValue
    ? severityPreset.ratings.items.find(
        (rating) =>
          rating.lowerRange <= severityRatingValue &&
          severityRatingValue <= rating.upperRange
      )!
    : unknownSeverityRating;
};
