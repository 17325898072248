import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { ContractPriceTimeChangeReadOnlyView } from "../../HistoryModal/ContractPriceTimeChangeReadOnlyView";
import { useMemo } from "react";
import {
  ClaimAgreement,
  ClaimDetermination,
  Contract,
  ContractTimeImpact,
  DetailedClaim,
  DetailedClaimTimeChange,
  DraftDetailedClaim,
  ProductType,
} from "generated/graphql";
import { IconContainer } from "components/IconContainer";
import { Money } from "phosphor-react";

type DetailedClaimAgreementDeterminationReadOnlyModalProps = {
  item:
    | DraftDetailedClaim // TODO: does it really need to support DraftDC ?
    | DetailedClaim
    | ClaimAgreement
    | ClaimDetermination;
  contract: Contract;
} & BasicModalProps;

export const DetailedClaimAgreementDeterminationReadOnlyModal: React.FC<
  DetailedClaimAgreementDeterminationReadOnlyModalProps
> = ({ item, contract, ...restDialogProps }) => {
  const sectionalChanges = useMemo(() => {
    if (item.sectionalChanges && item.sectionalChanges.length) {
      if ((item.sectionalChanges as ContractTimeImpact[])[0].id) {
        return item.sectionalChanges as ContractTimeImpact[];
      } else {
        return item.sectionalChanges as DetailedClaimTimeChange[];
      }
    }

    return undefined;
  }, [item]);

  return (
    <BasicModal
      maxWidth="sm"
      titleNextToIcon
      title={item.reference}
      headerIcon={
        <IconContainer greyBackground>
          <Money size={24} />
        </IconContainer>
      }
      {...restDialogProps}
    >
      <ContractPriceTimeChangeReadOnlyView
        details={item.details}
        time={item.time}
        price={item.price}
        contract={contract}
        dateSent={item.dateCreated}
        sectionalChanges={sectionalChanges}
        attachments={item.attachments}
        productType={ProductType.Claims}
      />
    </BasicModal>
  );
};
