import {
  ClaimFurtherParticularsSubmissionPromptQuery,
  ClaimFurtherParticularsSubmissionPromptQueryVariables,
  RecordClaimFurtherParticularsSubmissionMutation,
  RecordClaimFurtherParticularsSubmissionMutationVariables,
  SendClaimFurtherParticularsSubmissionMutation,
  SendClaimFurtherParticularsSubmissionMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { OnMutationDone } from "types/decl";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  claimFurtherParticularsSubmissionPromptQuery,
  recordClaimFurtherParticularsSubmissionMutation,
  sendClaimFurtherParticularsSubmissionMutation,
} from "./ProvideFurtherParticularsAction.query";

export const useProvideFurtherParticularsAction = () => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const { data: promptData, loading } = useGraphQuery<
    ClaimFurtherParticularsSubmissionPromptQuery,
    ClaimFurtherParticularsSubmissionPromptQueryVariables
  >(claimFurtherParticularsSubmissionPromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
  });

  const [
    sendFurtherParticularsSubmission,
    { loading: sendFurtherParticularsSubmissionLoading },
  ] = useGraphMutation<
    SendClaimFurtherParticularsSubmissionMutation,
    SendClaimFurtherParticularsSubmissionMutationVariables
  >(
    sendClaimFurtherParticularsSubmissionMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.additionalParticularsSubmissionSent${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  const [
    recordFurtherParticularsSubmission,
    { loading: recordFurtherParticularsSubmissionLoading },
  ] = useGraphMutation<
    RecordClaimFurtherParticularsSubmissionMutation,
    RecordClaimFurtherParticularsSubmissionMutationVariables
  >(
    recordClaimFurtherParticularsSubmissionMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.additionalParticularsSubmissionRecord${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  return {
    details: promptData?.claimFurtherParticularsSubmissionPrompt.details,
    loading,
    actionLoading:
      sendFurtherParticularsSubmissionLoading ||
      recordFurtherParticularsSubmissionLoading,
    sendFurtherParticularsSubmission,
    recordFurtherParticularsSubmission,
  };
};
