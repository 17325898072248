import { gql } from "@apollo/client";
import { dailyDiaryHSERecordLiteFields } from "graphql/fragments/dailyDiaryHSERecordLite.fragment";

export const editDailyDiaryHSERecordMutation = gql`
  ${dailyDiaryHSERecordLiteFields}

  mutation editDailyDiaryHSERecord($input: EditDailyDiaryHSERecordInput!) {
    editDailyDiaryHSERecord(input: $input) {
      ...DailyDiaryHSERecordLiteFields
    }
  }
`;
