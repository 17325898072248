import { useIntervalEffect } from "@react-hookz/web";
import { compareVersions } from "compare-versions";
import { DynamicConfig } from "config/config";
import { getConfigFileUrl } from "config/utils";
import { useCallback, useEffect, useRef, useState } from "react";

const refetchTime = 5 * 60 * 1000; // 5 minute
export const useNewUIVersion = () => {
  const [newUIVersionReleased, setNewUIVersionReleased] =
    useState<boolean>(false);
  const initialUIVersionRef = useRef<string>();

  const handleUIVersionChange = useCallback((uiVersion: string) => {
    if (!initialUIVersionRef.current) {
      initialUIVersionRef.current = uiVersion;
    } else if (compareVersions(uiVersion, initialUIVersionRef.current) === 1) {
      console.info(
        "%c New UI version released!",
        "background: #222; color: #bada55"
      );
      setNewUIVersionReleased(true);
    }
  }, []);

  const fetchConfigFile = useCallback(async () => {
    try {
      const resp = await fetch(getConfigFileUrl());

      const respData = (await resp.json()) as DynamicConfig;
      console.info(
        `%c version ${JSON.stringify(
          respData.REACT_APP_VERSION
        )} at ${Date.now()}`,
        "background: #222; color: #bada55"
      );

      handleUIVersionChange(respData.REACT_APP_VERSION);
    } catch (err) {}
  }, [handleUIVersionChange]);

  useIntervalEffect(
    fetchConfigFile,
    newUIVersionReleased ? undefined : refetchTime
  );

  useEffect(() => {
    fetchConfigFile();
    // eslint-disable-next-line
  }, []);

  return {
    newUIVersionReleased,
  };
};
