import { Stack, StackProps, Typography } from "@mui/material";
import { AuthorizationBanner } from "components/Authorization/AuthorizationBanner";
import {
  AuthorizationWorkflow,
  AuthorizationWorkflowAudit,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { AuthorizationProgress } from "./AuthorizationProgress/AuthorizationProgress";

export type AuthorizationProgressPreviewProps = {
  authorizationWorkflow: AuthorizationWorkflow;
  authorizationWorkflowAudit?: AuthorizationWorkflowAudit;
  omitTitle?: boolean;
} & StackProps;

export const AuthorizationProgressPreview: React.FC<
  AuthorizationProgressPreviewProps
> = ({
  authorizationWorkflow,
  authorizationWorkflowAudit,
  omitTitle,
  ...containerProps
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} {...containerProps}>
      {!authorizationWorkflowAudit && !omitTitle && (
        <Typography variant="h4" color="grey.800" fontWeight={600}>
          {t("Home.Authorizations.authorizationRequired")}
        </Typography>
      )}
      <AuthorizationBanner
        stage={authorizationWorkflowAudit ? "inProgress" : "notStarted"}
        size="large"
      />
      <AuthorizationProgress
        levels={authorizationWorkflow.levels}
        authAudit={authorizationWorkflowAudit}
      />
    </Stack>
  );
};
