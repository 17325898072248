import { gql } from "@apollo/client";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";

export const sendEarlyWarningItemMutation = gql`
  mutation sendEarlyWarningItem(
    $input: SendEarlyWarningItemInput!
    $draftId: ID
  ) {
    sendEarlyWarningItem(input: $input, draftId: $draftId) {
      id
      title
    }
  }
`;

export const recordEarlyWarningItemMutation = gql`
  mutation recordEarlyWarningItem(
    $input: RecordEarlyWarningItemInput!
    $draftId: ID
  ) {
    recordEarlyWarningItem(input: $input, draftId: $draftId) {
      id
      title
    }
  }
`;

export const addDraftEarlyWarningItemMutation = gql`
  mutation addDraftEarlyWarningItem($input: SendEarlyWarningItemInput!) {
    addDraftEarlyWarningItem(input: $input) {
      id
      title
    }
  }
`;

export const editDraftEarlyWarningItemMutation = gql`
  mutation editDraftEarlyWarningItem($input: EditDraftEarlyWarningItemInput!) {
    editDraftEarlyWarningItem(input: $input) {
      id
      title
    }
  }
`;

export const generateEarlyWarningPreviewMutation = gql`
  ${authorizationWorkflowActionMappingFields}

  mutation generateEarlyWarningPreview($input: SendEarlyWarningItemInput!) {
    generateEarlyWarningPreview(input: $input) {
      id
      fileUrl
      actionMapping {
        ...AuthorizationWorkflowActionMappingFields
      }
      recipients {
        userId
        name
        preference {
          inApp
          inAppReadOnly
          email
          emailReadOnly
        }
      }
    }
  }
`;

export const removeEarlyWarningPreviewMutation = gql`
  mutation removeEarlyWarningPreview($id: ID!) {
    removeEarlyWarningPreview(id: $id)
  }
`;

export const draftEarlyWarningItemQuery = gql`
  query draftEarlyWarningItem($id: ID!) {
    draftEarlyWarningItem(id: $id) {
      id
      title
      data {
        sections {
          name
          entries {
            name
            value
          }
        }
      }
      severity
      number
      productInstanceId
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
      statusOption {
        id
        description
        equatesToRemoved
        statusCollectionId
        isInternal
        status
      }
      owner {
        id
        firstname
        surname
      }
      dateModified
      attachments {
        id
        fileName
        fileUrl
        mimeType
        description
        locked
        fileSize
        status
        dateCreated
        dateModified
        creatorId
        creator {
          id
          firstname
          surname
        }
      }
    }
  }
`;
