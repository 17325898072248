import { gql } from "@apollo/client";
import { instructionItemFields } from "graphql/fragments/instructionItem.fragment";

export const editDraftInstructionItemMutation = gql`
  ${instructionItemFields}

  mutation editDraftInstructionItem($input: EditDraftInstructionItemInput!) {
    editDraftInstructionItem(input: $input) {
      ...InstructionItemFields
    }
  }
`;
