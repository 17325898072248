import {
  ClaimItem,
  CompEventItem,
  EventItem,
  InstructionItem,
  VariationItem,
} from "generated/graphql";
import { ceStatusToNextStepWordingMap } from "./CompEventWidget/CompEventWidget.constants";

export const isCompEvent = (
  productItem: CompEventItem | ClaimItem | VariationItem
): productItem is CompEventItem =>
  !!((productItem as CompEventItem).__typename === "CompEventItem");

export const getNextStepText = (compEvent?: CompEventItem) => {
  return compEvent
    ? compEvent.nextStep
      ? ceStatusToNextStepWordingMap[compEvent.nextStep.actionType]
      : null
    : ceStatusToNextStepWordingMap.None;
};

export const getNextStepDueDate = (
  compEvent?: CompEventItem,
  eventItem?: EventItem,
  instructionItem?: InstructionItem
) => {
  return compEvent
    ? compEvent.nextStep?.dueDate
    : eventItem?.notifyDueDate || instructionItem?.notifyDueDate;
};
