import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const CaretUpIcon: React.FC<
  BaseIconProps & { onClick?: () => void }
> = ({ height = "7.5", width = "15", dataTestId, color, onClick }) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 18 10"
      fill="none"
      data-testid={dataTestId}
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <path
        d="M1.49609 9.0031L8.9976 1.50159L16.4991 9.0031"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
