import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";
import { dailyDiaryDelayRecordLiteFields } from "graphql/fragments/dailyDiaryDelayRecordLite.fragment";

export const dailyDiaryDelayRecordsQuery = gql`
  ${dailyDiaryDelayRecordLiteFields}
  ${attachmentFields}

  query dailyDiaryDelayRecords($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryDelayRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      ...DailyDiaryDelayRecordLiteFields
      attachments {
        ...AttachmentFields
      }
    }
  }
`;

export const dailyDiaryDelayImportSourceItemsQuery = gql`
  query dailyDiaryDelayImportSourceItems(
    $productInstanceId: ID!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    dailyDiaryDelayImportSourceItems(
      productInstanceId: $productInstanceId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      items {
        id
        productInstanceId
        date
        number
        status
        dateCreated
        creatorId
        sentById
        dateSent
        latestRevision {
          id
          number
          status
          dateSent
        }
      }
    }
  }
`;

export const importDailyDiaryDelayRecordsMutation = gql`
  mutation importDailyDiaryDelayRecords($input: ImportDailyDiaryRecordsInput!) {
    importDailyDiaryDelayRecords(input: $input)
  }
`;
