import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { UserFormPublicApi } from "containers/AdminConsole/containers/Companies/containers/UserDetails/UserForm";
import { ConfirmChangesModal } from "containers/Settings/components/ConfirmChangesModal";
import {
  EditUserInput,
  EditUserMutationMutation,
  EditUserMutationMutationVariables,
  GetCompanyLiteByIdQuery,
  GetCompanyLiteByIdQueryVariables,
} from "generated/graphql";
import { editUserMutation } from "graphql/mutations/editUserMutation";
import { getCompanyLiteByIdQuery } from "graphql/queries/companyByIdLite.query";
import { useCallbackPrompt } from "hooks/useCallbackPrompt";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  useMemo,
  useRef,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { ProfileForm } from "./components/ProfileForm/ProfileForm";
import { RHSCard } from "../NotificationsSettings/RHSCard";
import { HeaderContainer } from "./ProfileEdit.styled";

export const ProfileEdit = () => {
  const { t } = useTranslation();

  const [isFormPristine, setIsFormPristine] = useState(true);

  const {
    showPrompt: showBeforeNavigateAwayModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(!isFormPristine);

  const {
    authenticatedUser,
    loading: userLoading,
    refetchAuthenticatedUser,
  } = useContext(GlobalContext);
  const updatedUserDetails = useRef<EditUserInput>();
  const userFormRef = useRef<UserFormPublicApi>(null);

  const [
    fetchCompanyData,
    { data: companyData, loading: getCompanyDataLoading },
  ] = useGraphLazyQuery<
    GetCompanyLiteByIdQuery,
    GetCompanyLiteByIdQueryVariables
  >(getCompanyLiteByIdQuery);

  const [editUser, { loading: editUserLoading }] = useGraphMutation<
    EditUserMutationMutation,
    EditUserMutationMutationVariables
  >(
    editUserMutation,
    {
      update: () => {
        refetchAuthenticatedUser();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.profile"),
    })
  );

  const handleUserDataChange = useCallback((updatedUser: EditUserInput) => {
    updatedUserDetails.current = updatedUser;
    setIsFormPristine(false);
  }, []);

  const loading = useMemo(
    () => userLoading || getCompanyDataLoading || editUserLoading,
    [userLoading, getCompanyDataLoading, editUserLoading]
  );

  const handleSaveProfile = useCallback(async () => {
    if (!updatedUserDetails.current || !userFormRef.current?.validate()) {
      return;
    }

    await editUser({
      variables: {
        input: updatedUserDetails.current,
      },
    });
    setIsFormPristine(true);
  }, [editUser, updatedUserDetails]);

  const handleConfirmModalClose = () => {
    cancelNavigation();
  };

  const handleConfirmModalDontSave = () => {
    confirmNavigation(); // for navigate away scenarios
  };

  const handleConfirmModalSave = async () => {
    await handleSaveProfile();
    confirmNavigation();
  };

  useEffect(() => {
    if (authenticatedUser?.companyId) {
      fetchCompanyData({ variables: { id: authenticatedUser.companyId } });
    }
  }, [fetchCompanyData, authenticatedUser?.companyId]);

  return (
    <RHSCard>
      <HeaderContainer>
        <Typography variant="h3" fontWeight={600} color="grey.900">
          {t("Settings.profile.editProfile")}
        </Typography>
      </HeaderContainer>
      <ConfirmChangesModal
        open={showBeforeNavigateAwayModal}
        secondaryBtnCaption={t("common.buttons.dontSave")}
        primaryBtnCaption={t("common.buttons.save")}
        primaryBtnLoading={editUserLoading}
        onClose={handleConfirmModalClose}
        onPrimaryClick={handleConfirmModalSave}
        onSecondaryClick={handleConfirmModalDontSave}
      />
      <Box mt={2}>
        <ProfileForm
          companyName={companyData?.company?.tradingName}
          user={authenticatedUser}
          loading={loading}
          onChange={handleUserDataChange}
          ref={userFormRef}
        />
      </Box>
      <Box ml="auto" mt={5}>
        <LoadingButton
          variant="contained"
          onClick={handleSaveProfile}
          loading={editUserLoading}
        >
          {t("common.labels.saveChanges")}
        </LoadingButton>
      </Box>
    </RHSCard>
  );
};
