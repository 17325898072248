import { gql } from "@apollo/client";
import { userLiteFields } from "./userLite.fragment";

export const authorizationWorkflowLevelFields = gql`
  ${userLiteFields}

  fragment AuthorizationWorkflowLevelFields on AuthorizationWorkflowLevel {
    sequence
    mode
    userIds
    users {
      items {
        ...UserLiteFields
      }
    }
  }
`;
