import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationNoAgreementNoticePromptQuery = gql`
  query variationNoAgreementNoticePrompt(
    $input: VariationNoAgreementNoticePromptInput!
  ) {
    variationNoAgreementNoticePrompt(input: $input) {
      remarks
    }
  }
`;

export const sendVariationNoAgreementNoticeMutation = gql`
  mutation sendVariationNoAgreementNotice(
    $input: SendVariationNoAgreementNoticeInput!
  ) {
    sendVariationNoAgreementNotice(input: $input) {
      id
    }
  }
`;

export const recordVariationNoAgreementNoticeMutation = gql`
  mutation recordVariationNoAgreementNotice(
    $input: RecordVariationNoAgreementNoticeInput!
  ) {
    recordVariationNoAgreementNotice(input: $input) {
      id
    }
  }
`;

export const generateSendVariationNoAgreementNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationNoAgreementNoticePreview(
    $input: SendVariationNoAgreementNoticePreviewInput!
  ) {
    generateSendVariationNoAgreementNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationNoAgreementNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationNoAgreementNoticePreview(
    $input: RecordVariationNoAgreementNoticePreviewInput!
  ) {
    generateRecordVariationNoAgreementNoticePreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
