import { gql } from "@apollo/client";
import { instructionItemFields } from "graphql/fragments/instructionItem.fragment";

export const addDraftInstructionItemMutation = gql`
  ${instructionItemFields}

  mutation addDraftInstructionItem($input: SendInstructionItemInput!) {
    addDraftInstructionItem(input: $input) {
      ...InstructionItemFields
    }
  }
`;
