import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventQuotationAcceptanceMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventQuotationAcceptance(
    $input: SendCompEventQuotationAcceptanceInput!
  ) {
    sendCompEventQuotationAcceptance(input: $input) {
      ...CompEventItemFields
    }
  }
`;
