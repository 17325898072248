import { gql } from "@apollo/client";

// TODO: use to display remarks and requested days prior accepting/denying the request
export const compEventDenyingOfMoreTimePromptQuery = gql`
  query compEventDenyingOfMoreTimePrompt(
    $input: CompEventDenyingOfMoreTimePromptInput!
  ) {
    compEventDenyingOfMoreTimePrompt(input: $input) {
      remarks
    }
  }
`;
