import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimFurtherParticularsSubmissionPromptQuery = gql`
  query claimFurtherParticularsSubmissionPrompt(
    $input: ClaimFurtherParticularsSubmissionPromptInput!
  ) {
    claimFurtherParticularsSubmissionPrompt(input: $input) {
      details
    }
  }
`;

export const sendClaimFurtherParticularsSubmissionMutation = gql`
  mutation sendClaimFurtherParticularsSubmission(
    $input: SendClaimFurtherParticularsSubmissionInput!
  ) {
    sendClaimFurtherParticularsSubmission(input: $input) {
      id
    }
  }
`;

export const recordClaimFurtherParticularsSubmissionMutation = gql`
  mutation recordClaimFurtherParticularsSubmission(
    $input: RecordClaimFurtherParticularsSubmissionInput!
  ) {
    recordClaimFurtherParticularsSubmission(input: $input) {
      id
    }
  }
`;

export const generateSendClaimFurtherParticularsSubmissionPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimFurtherParticularsSubmissionPreview(
    $input: SendClaimFurtherParticularsSubmissionPreviewInput!
  ) {
    generateSendClaimFurtherParticularsSubmissionPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimFurtherParticularsSubmissionPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimFurtherParticularsSubmissionPreview(
    $input: RecordClaimFurtherParticularsSubmissionPreviewInput!
  ) {
    generateRecordClaimFurtherParticularsSubmissionPreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
