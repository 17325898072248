import { ApolloProvider } from "@apollo/client";
import React, { useMemo } from "react";
import { getApolloClient } from "state-management/graphql/apolloClient";
import { useConfig } from "./config.context";

export const GraphqlProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { config } = useConfig();

  const computedApolloClient = useMemo(() => {
    return getApolloClient(config);
  }, [config]);

  return config ? (
    <ApolloProvider client={computedApolloClient}>{children}</ApolloProvider>
  ) : null;
};
