import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  ClaimActionType,
  ClaimFinalClaimDetailsPrompt,
} from "generated/graphql";
import { DetailedClaimAgreementDeterminationSummary } from "../components/DetailedClaimAgreementDeterminationSummary";
import { useRecordFinalDetailedClaimAction } from "./useRecordFinalDetailedClaimAction";
import { RecordAction } from "../components/RecordAction";

export type RecordFinalDetailedClaimActionProps = {
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Pick<ClaimFinalClaimDetailsPrompt, "draftDetailedClaims"> &
  Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordFinalDetailedClaimAction: React.FC<
  RecordFinalDetailedClaimActionProps
> = ({ draftDetailedClaims, ...restDialogProps }) => {
  const { claimPreview, contract } = useRecordFinalDetailedClaimAction(
    draftDetailedClaims[0]
  );
  return (
    <RecordAction
      actionType={ClaimActionType.SubmitFinalDetailedClaim}
      claimPreview={claimPreview}
      summaryEl={
        <DetailedClaimAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftDetailedClaims}
          type="DetailedClaim"
        />
      }
      {...restDialogProps}
    />
  );
};
