import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { Company } from "generated/graphql";
import { Copy } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export type DuplicateLookupCollectionModalProps = {
  companies?: Company[];
  collectionToDuplicateName?: string;
  collectionToDuplicateId?: string;
  onPrimaryClick: (newCollectionName: string, companyId?: string) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

const DuplicateModalHeader: React.FC<{ collectionToDuplicateName: string }> = ({
  collectionToDuplicateName,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="h5"
        fontWeight={600}
        color={theme.palette.grey[800]}
        mb={1}
      >
        {t("AdminConsole.Lookups.DuplicateLookupCollectionModal.title", {
          collectionName: collectionToDuplicateName,
        })}
      </Typography>
      <Typography variant="p1" color={theme.palette.grey[700]} mb={2}>
        {t("AdminConsole.Lookups.DuplicateLookupCollectionModal.subtitle")}
      </Typography>
    </Box>
  );
};

export const DuplicateLookupCollectionModal: React.FC<
  DuplicateLookupCollectionModalProps
> = ({
  collectionToDuplicateName,
  companies,
  collectionToDuplicateId,
  onPrimaryClick,
  ...restDialogProps
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<{
    newCollectionName: string;
    companyId?: string;
  }>({
    newCollectionName: collectionToDuplicateName
      ? `${collectionToDuplicateName} - ${t("common.labels.duplicate")}`
      : "",
    companyId: collectionToDuplicateId ?? "",
  });

  const handleTextFieldChange: React.ChangeEventHandler<HTMLInputElement> = (
    evt
  ) => {
    setFormData((crtFormData) => ({
      ...crtFormData,
      [evt.target.name]: evt.target.value,
    }));
  };

  const handleCompanyChange = (event: SelectChangeEvent<string | null>) => {
    setFormData((curData) => ({
      ...curData,
      companyId: event.target.value ?? "",
    }));
  };

  const handlePrimaryBtnClick = () => {
    if (formData.newCollectionName) {
      onPrimaryClick(formData.newCollectionName, formData.companyId);
    }
  };

  useEffect(() => {
    setFormData((crtData) => ({
      ...crtData,
      newCollectionName: collectionToDuplicateName
        ? `${collectionToDuplicateName} - ${t("common.labels.duplicate")}`
        : "",
    }));
  }, [collectionToDuplicateName, t]);

  useEffect(() => {
    setFormData((crtData) => ({
      ...crtData,
      companyId: collectionToDuplicateId ?? "",
    }));
  }, [collectionToDuplicateName, collectionToDuplicateId]);

  return (
    <ActionsDialog
      iconsHeader={<Copy size={32} />}
      title={
        <DuplicateModalHeader
          collectionToDuplicateName={collectionToDuplicateName!}
        />
      }
      content={
        <Grid container spacing={5}>
          <Grid item md={12}>
            <TextField
              name="newCollectionName"
              value={formData.newCollectionName}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.name")}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          {companies && (
            <Grid item md={12}>
              <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
                <>
                  <InputLabel id="company-select-label" shrink>
                    <Typography>{`${t("common.labels.company")} *`}</Typography>
                  </InputLabel>
                  <Select
                    labelId="company-select-label"
                    id="demo-simple-select-standard"
                    value={formData.companyId}
                    onChange={handleCompanyChange}
                    required
                  >
                    {companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.tradingName}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </FormControl>
            </Grid>
          )}
        </Grid>
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      onPrimaryClick={handlePrimaryBtnClick}
      primaryBtnDisabled={!formData.newCollectionName}
      {...restDialogProps}
    />
  );
};
