import { Box } from "@mui/material";
import { UserProfileButton } from "components/UserProfileButton/UserProfileButton";
import { useContext } from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { Editor } from "./Editor/Editor";
import { UserMentionOption } from "./Editor/plugins/MentionsPlugin/useMentionLookupService";

export type CommentsEditorProps = {
  disabled: boolean;
  typeaheadOptions: UserMentionOption[];
  onSend: (content: string) => void;
  onEditorDirtyChange?: (isDirty: boolean) => void;
  onClose?: () => void;
};

export const CommentsEditor: React.FC<CommentsEditorProps> = ({
  disabled,
  typeaheadOptions,
  onSend,
  onEditorDirtyChange,
  onClose,
}) => {
  const { authenticatedUser } = useContext(GlobalContext);

  return (
    <Box display="flex" alignItems="flex-start">
      <UserProfileButton
        width="40px"
        height="40px"
        mr={1}
        url={authenticatedUser?.profilePicture ?? undefined}
      />
      <Editor
        disabled={disabled}
        typeaheadOptions={typeaheadOptions}
        onSend={onSend}
        onEditorDirtyChange={onEditorDirtyChange}
        onClose={onClose}
        dataTestId="comments-editor"
      />
    </Box>
  );
};
