import { useTranslation } from "react-i18next";
import { useGraphMutation } from "./useGraphMutation";
import {
  ChangeEventItemOwnerMutation,
  ChangeEventItemOwnerMutationVariables,
} from "generated/graphql";
import { changeEventItemOwnerMutation } from "graphql/mutations/changeEventOwner";

export const useChangeEventOwner = (onOwnerChange?: () => void) => {
  const { t } = useTranslation();

  const [changeEventOwner, { loading }] = useGraphMutation<
    ChangeEventItemOwnerMutation,
    ChangeEventItemOwnerMutationVariables
  >(
    changeEventItemOwnerMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "events" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "event" });
        cache.gc();
        onOwnerChange?.();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Events.event"),
    })
  );

  return {
    changeEventOwner,
    loading,
  };
};
