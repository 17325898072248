import { ClaimActionType } from "generated/graphql";
import { ClaimActionTypeAll, ClaimActionTypeFIDIC99RedYellow } from "../types";

export const claimActionTypeTranslations: Record<ClaimActionTypeAll, string> = {
  [ClaimActionType.NotifyClaim]: "Projects.Claims.ActionTypes.NotifyClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaim99RY]:
    "Projects.Claims.ActionTypes.NotifyClaim99RY",
  [ClaimActionType.SubmitInterimDetailedClaim]:
    "Projects.Claims.ActionTypes.SubmitInterimDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.SubmitInterimDetailedClaim99RY]:
    "Projects.Claims.ActionTypes.SubmitInterimDetailedClaim99RY",
  [ClaimActionType.SubmitFinalDetailedClaim]:
    "Projects.Claims.ActionTypes.SubmitFinalDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.SubmitFinalDetailedClaim99RY]:
    "Projects.Claims.ActionTypes.SubmitFinalDetailedClaim99RY",
  [ClaimActionType.NotifyAgreementClaim]:
    "Projects.Claims.ActionTypes.NotifyAgreementClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaim99RY]:
    "Projects.Claims.ActionTypes.NotifyAgreementClaim99RY",
  [ClaimActionType.NotifyAgreementClaimNotReached]:
    "Projects.Claims.ActionTypes.NotifyAgreementClaimNotReached",
  [ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaimNotReached99RY]:
    "Projects.Claims.ActionTypes.NotifyAgreementClaimNotReached99RY",
  [ClaimActionType.RequestFurtherParticulars]:
    "Projects.Claims.ActionTypes.RequestFurtherParticulars",
  [ClaimActionTypeFIDIC99RedYellow.RequestFurtherParticulars99RY]:
    "Projects.Claims.ActionTypes.RequestFurtherParticulars99RY",
  [ClaimActionType.ProvideFurtherParticulars]:
    "Projects.Claims.ActionTypes.ProvideFurtherParticulars",
  [ClaimActionTypeFIDIC99RedYellow.ProvideFurtherParticulars99RY]:
    "Projects.Claims.ActionTypes.ProvideFurtherParticulars99RY",
  [ClaimActionType.RespondRegardingDetailedClaim]:
    "Projects.Claims.ActionTypes.RespondRegardingDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.RespondRegardingDetailedClaim99RY]:
    "Projects.Claims.ActionTypes.RespondRegardingDetailedClaim99RY",
  [ClaimActionType.RequestMoreTime]:
    "Projects.Claims.ActionTypes.RequestMoreTime",
  [ClaimActionType.NotifyDeterminationClaim]:
    "Projects.Claims.ActionTypes.NotifyDeterminationClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyDeterminationClaim99RY]:
    "Projects.Claims.ActionTypes.NotifyDeterminationClaim99RY",
  [ClaimActionType.NotifyClaimNoticeInvalid]:
    "Projects.Claims.ActionTypes.NotifyClaimNoticeInvalid",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaimNoticeInvalid99RY]:
    "Projects.Claims.ActionTypes.NotifyClaimNoticeInvalid99RY",
  [ClaimActionType.NotifyClaimNoticeValidityHasLapsed]:
    "Projects.Claims.ActionTypes.NotifyClaimNoticeValidityHasLapsed",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaimNoticeValidityHasLapsed99RY]:
    "Projects.Claims.ActionTypes.NotifyClaimNoticeValidityHasLapsed99RY",
  [ClaimActionType.NotifyErrorFoundAgreement]:
    "Projects.Claims.ActionTypes.NotifyErrorFoundAgreement",
  [ClaimActionType.DisagreeWithNoticeErrorAgreement]:
    "Projects.Claims.ActionTypes.DisagreeWithNoticeErrorAgreement",
  [ClaimActionType.NotifyErrorFoundDetermination]:
    "Projects.Claims.ActionTypes.NotifyErrorFoundDetermination",
  [ClaimActionType.DisagreeWithNoticeErrorDetermination]:
    "Projects.Claims.ActionTypes.DisagreeWithNoticeErrorDetermination",
} as any;

/**
 * Note: many of these intentionally don't have translations because the text displayed for `NextStep` is
 * actually the next action to take in a happy flow (notify -> submit detailed claim -> agreement)
 * with some minor deviations like requestMoreTime, etc.
 */
export const claimStatusToNextStepWordingMap: Record<
  | ClaimActionTypeAll
  | "None"
  | "None99RY"
  | "ReviseAgreement"
  | "ResponseToErrorInAgreement"
  | "ReviseDetermination"
  | "ResponseToErrorInDetermination",
  string | null
> = {
  None: "Projects.Claims.nextStepWordings.none",
  None99RY: "Projects.Claims.nextStepWordings.none99RY",
  [ClaimActionType.NotifyClaim]: "Projects.Claims.nextStepWordings.NotifyClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaim99RY]:
    "Projects.Claims.nextStepWordings.NotifyClaim99RY",
  [ClaimActionType.SubmitInterimDetailedClaim]:
    "Projects.Claims.nextStepWordings.SubmitInterimDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.SubmitInterimDetailedClaim99RY]:
    "Projects.Claims.nextStepWordings.SubmitInterimDetailedClaim99RY",
  [ClaimActionType.SubmitFinalDetailedClaim]:
    "Projects.Claims.nextStepWordings.SubmitFinalDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.SubmitFinalDetailedClaim99RY]:
    "Projects.Claims.nextStepWordings.SubmitFinalDetailedClaim99RY",
  [ClaimActionType.NotifyAgreementClaim]:
    "Projects.Claims.nextStepWordings.NotifyAgreementClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaim99RY]:
    "Projects.Claims.nextStepWordings.NotifyAgreementClaim99RY",
  [ClaimActionType.NotifyDeterminationClaim]:
    "Projects.Claims.nextStepWordings.NotifyDeterminationClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyDeterminationClaim99RY]:
    "Projects.Claims.nextStepWordings.NotifyDeterminationClaim99RY",
  [ClaimActionType.RequestMoreTime]:
    "Projects.Claims.nextStepWordings.RequestMoreTime",
  [ClaimActionType.ProvideFurtherParticulars]:
    "Projects.Claims.nextStepWordings.ProvideFurtherParticulars",
  [ClaimActionTypeFIDIC99RedYellow.ProvideFurtherParticulars99RY]:
    "Projects.Claims.nextStepWordings.ProvideFurtherParticulars99RY",
  ReviseAgreement: "Projects.Claims.nextStepWordings.ReviseAgreement",
  ResponseToErrorInAgreement:
    "Projects.Claims.nextStepWordings.ResponseToErrorInAgreement",
  ReviseDetermination: "Projects.Claims.nextStepWordings.ReviseDetermination",
  ResponseToErrorInDetermination:
    "Projects.Claims.nextStepWordings.ResponseToErrorInDetermination",
} as any; // TODO: remove
