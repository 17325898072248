import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";
import { FormPublicApi } from "types/decl";
import { AddDocumentTemplateInput, DocumentTemplate } from "generated/graphql";
import { validateData } from "helpers/validators";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Editor, { OnChange } from "@monaco-editor/react";
import { DocumentTemplatePreview } from "../DocumentTemplatePreview";
import { Placeholders } from "../../NewTemplate/components/Placeholders/Placeholders";
import {
  defaultDocumentTemplate,
  documentTemplateDataValidators,
} from "./DocumentTemplateForm.constants";
import { Textarea } from "components/TextArea";

export type DocumentTemplateFormProps = {
  documentTemplate?: DocumentTemplate;
  onChange?: (
    contractTypeData: AddDocumentTemplateInput | DocumentTemplate
  ) => void;
};

export const DocumentTemplateForm = React.forwardRef(
  (
    { documentTemplate, onChange }: DocumentTemplateFormProps,
    ref: React.Ref<FormPublicApi>
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [formData, setFormData] = useState<
      AddDocumentTemplateInput | DocumentTemplate
    >(documentTemplate || defaultDocumentTemplate);
    const [formDataErrors, setFormDataErrors] = useState<{
      [key: string]: string;
    }>({});

    const handleTextFieldChange: React.ChangeEventHandler<
      HTMLTextAreaElement | HTMLInputElement
    > = (evt) => {
      setFormData((curData) => ({
        ...curData,
        [evt.target.name]: evt.target.value,
      }));

      setFormDataErrors((curFormDataErrs) => {
        const { [evt.target.name]: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const validateForm = useCallback(
      (formData: AddDocumentTemplateInput | DocumentTemplate) => {
        const validationResult = validateData(
          formData,
          documentTemplateDataValidators
        );

        if (validationResult.valid) {
          setFormDataErrors({});
          return true;
        }
        setFormDataErrors(validationResult.errors);
        return false;
      },
      []
    );

    const handleTemplateChange: OnChange = (value) => {
      setFormData((crtValue) => ({
        ...crtValue,
        content: value ?? "",
      }));

      setFormDataErrors((curFormDataErrs) => {
        const { content: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const resetForm = useCallback(() => {
      setFormData(documentTemplate || defaultDocumentTemplate);
      setFormDataErrors({});
    }, [documentTemplate]);

    useEffect(() => {
      onChange?.(formData);
    }, [formData, onChange]);

    useEffect(() => {
      setFormData(documentTemplate || defaultDocumentTemplate);
    }, [documentTemplate]);

    useImperativeHandle(
      ref,
      () => ({
        validate: () => validateForm(formData),
        reset: () => resetForm,
      }),
      [validateForm, formData, resetForm]
    );

    return (
      <>
        <Grid container spacing={6}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.name")}
              variant="standard"
              error={!!formDataErrors.name}
              helperText={formDataErrors.name}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              variant="caption"
              mb={0.5}
              display="block"
              color="text.primary"
            >
              {t("common.labels.description")}
            </Typography>
            <Textarea
              value={formData.description}
              name="description"
              onChange={handleTextFieldChange}
              error={!!formDataErrors.description}
            />
            {!!formDataErrors.description && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.description}
              </Typography>
            )}
          </Grid>
          <Grid item md={6} xs={12} position="relative">
            <Placeholders />
            <Box
              flex={1}
              sx={{
                border: "1px solid",
                borderColor: formDataErrors.content
                  ? theme.palette.error.main
                  : theme.palette.grey[400],
                borderRadius: "8px",
              }}
            >
              <Editor
                height="62.5vh"
                defaultLanguage="html"
                defaultValue={formData.content}
                onChange={handleTemplateChange}
              />
            </Box>
            {!!formDataErrors.content && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.content}
              </Typography>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              flex={1}
              height="100%"
              sx={{
                border: "1px solid",
                borderColor: theme.palette.grey[400],
                borderRadius: "8px",
              }}
              maxHeight="62.5vh"
              overflow="auto"
            >
              <DocumentTemplatePreview
                documentTemplateHTML={formData.content}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
);
