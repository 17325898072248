import { Box, Divider, useTheme } from "@mui/material";
import { Minus, Plus } from "phosphor-react";
import { useRef } from "react";

enum ActionType {
  Increment,
  Decrement,
}

export const PlusMinusController: React.FC<{
  onIncrement: () => void;
  onDecrement: () => void;
}> = ({ onIncrement, onDecrement }) => {
  const theme = useTheme();

  const intervalRef = useRef<NodeJS.Timer>();

  const startCounting = (type: ActionType) => {
    intervalRef.current = setInterval(() => {
      if (type === ActionType.Increment) {
        onIncrement();
      } else {
        onDecrement();
      }
    }, 200);
  };

  const handleMouseDown = (type: ActionType) => {
    // In case the user mouseDowned on the button and dragged outside the button, the mouseUp event wouldn't fire and the interval would just keep going.
    // Resetting the interval on mouseDown.
    clearInterval(intervalRef.current);
    startCounting(type);
  };

  const handleMouseUp = () => {
    clearInterval(intervalRef.current);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        sx={{ cursor: "pointer" }}
        onClick={onDecrement}
        onMouseDown={() => handleMouseDown(ActionType.Decrement)}
        onMouseUp={handleMouseUp}
        data-testid="minus-btn"
      >
        <Minus size={18} color={theme.palette.grey[800]} />
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          mx: theme.spacing(2),
          borderColor: theme.palette.grey[400],
          height: theme.spacing(2),
        }}
      />
      <Box
        display="flex"
        sx={{ cursor: "pointer" }}
        onClick={onIncrement}
        onMouseDown={() => handleMouseDown(ActionType.Increment)}
        onMouseUp={handleMouseUp}
        data-testid="plus-btn"
      >
        <Plus size={18} color={theme.palette.grey[800]} />
      </Box>
    </Box>
  );
};
