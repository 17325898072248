import { Box, Typography, TypographyProps, css, styled } from "@mui/material";

export const ContentContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${theme.spacing(2)};
    background: ${theme.palette.grey[100]};
    border-radius: 12px;
  `
);

export const Heading: React.FC<TypographyProps> = ({
  children,
  ...restProps
}) => (
  <Typography variant="p1" color="grye.800" {...restProps}>
    {children}
  </Typography>
);

export const ContentText: React.FC<TypographyProps> = ({
  children,
  ...restProps
}) => (
  <Typography
    variant="p1"
    fontWeight={600}
    mt={0.5}
    color={"grey.900"}
    {...restProps}
  >
    {children}
  </Typography>
);
