import {
  AddCompanyLookupCollectionInput,
  AddLookupCollectionInput,
} from "generated/graphql";
import { useState } from "react";
import { useDuplicateCustomLookupCollection } from "../../CustomSimpleLookups/useDuplicateCustomLookupColection";
import { useDuplicateLookupCollection } from "./useDuplicateLookupCollection";

export const useDuplicateLookupModal = () => {
  const {
    duplicateLookupCollection,
    loading: duplicateLookupCollectionloading,
  } = useDuplicateLookupCollection();
  const {
    duplicateLookupCollection: duplicateCustomLookupCollection,
    loading: duplicateCustomLookupCollectionLoading,
  } = useDuplicateCustomLookupCollection();

  const [modalVisibility, setModalVisibility] = useState(false);

  const handleConfirm = (
    collectionToDuplicateId: string,
    newCollectionInput:
      | AddLookupCollectionInput
      | AddCompanyLookupCollectionInput
  ) => {
    if ((newCollectionInput as AddCompanyLookupCollectionInput).usage) {
      return duplicateCustomLookupCollection(
        collectionToDuplicateId,
        newCollectionInput as AddCompanyLookupCollectionInput
      );
    }
    return duplicateLookupCollection(
      collectionToDuplicateId,
      newCollectionInput
    );
  };

  const closeModal = () => {
    setModalVisibility(false);
  };

  const handleCancel = () => {
    closeModal();
  };

  return {
    modalVisible: modalVisibility,
    setModalVisible: setModalVisibility,
    loading:
      duplicateLookupCollectionloading ||
      duplicateCustomLookupCollectionLoading,
    handleConfirm,
    handleCancel,
    handleCloseModal: closeModal,
  };
};
