import {
  GridEventListener,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { isVariation } from "containers/Projects/Projects.utils";
import { ExplorerState } from "containers/Projects/components/Explorer/Explorer.context";
import {
  ClaimItem,
  ClaimRegardingType,
  CompEventItem,
  ProductType,
  VariationItem,
} from "generated/graphql";
import { getProductItemDetailsPath } from "helpers/paths/pathConstructors";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useCEClaimVariationTable = (
  projectId: string,
  contractId: string,
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void
) => {
  const navigate = useNavigate();

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<ClaimItem | CompEventItem | VariationItem>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        const prodId = isVariation(rowData.row)
          ? rowData.row.regardingInstruction!.productInstance.product.id
          : rowData.row.regardingType === ClaimRegardingType.Event
          ? rowData.row.regardingEvent!.productInstance.product.id
          : rowData.row.regardingInstruction!.productInstance.product.id;

        const prodInstanceId = isVariation(rowData.row)
          ? rowData.row.regardingInstruction!.productInstanceId
          : rowData.row.regardingType === ClaimRegardingType.Event
          ? rowData.row.regardingEvent!.productInstanceId
          : rowData.row.regardingInstruction!.productInstanceId;

        navigate(
          getProductItemDetailsPath(
            prodInstanceId,
            rowData.row.regardingId,
            isVariation(rowData.row)
              ? ProductType.Instructions
              : rowData.row.regardingEvent
              ? ProductType.Events
              : ProductType.Instructions
          ),
          {
            state: {
              projectId,
              contractId,
              productId: prodId,
              productInstanceId: prodInstanceId,
            } as ExplorerState,
          }
        );
      }
    },
    [navigate, projectId, contractId]
  );

  const handleClearSelection = useCallback(() => {
    onSelectionModelChange([]);
  }, [onSelectionModelChange]);

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    onSelectionModelChange(newSelectionModel);
  };

  return {
    handleRowClick,
    handleClearSelection,
    handleSelectionModelChange,
  };
};
