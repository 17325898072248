import {
  Box,
  Button,
  Dialog,
  DialogProps,
  Typography,
  css,
  styled,
} from "@mui/material";
import { BackIcon } from "components/Icons/BackIcon";
import { useTranslation } from "react-i18next";

export type FullScreenProductItemPreviewProps = {
  previewUrl: string;
} & DialogProps;

export const HeaderContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(3)};
    position: sticky;
    top: 0;
    background: ${theme.palette.common.white};
    z-index: 2;
  `
);

export const FullScreenProductItemPreview: React.FC<
  FullScreenProductItemPreviewProps
> = ({ previewUrl, open, onClose, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="image-productItem-preview-dialog"
      aria-describedby="image-productItem-preview-dialog"
      maxWidth="xl"
      {...restProps}
    >
      <Box
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        px={6}
        pb={5}
      >
        <HeaderContainer>
          <Button
            variant="outlined"
            onClick={() => onClose?.({}, "backdropClick")}
            size="large"
          >
            <BackIcon />
            <Typography variant="p2" fontWeight={700} color="grey.800" ml={1}>
              {t("common.buttons.back")}
            </Typography>
          </Button>
          <Typography variant="h3" color="grey.900" ml={2}>
            {t("common.labels.preview")}
          </Typography>
        </HeaderContainer>
        <Box width="100%">
          <img
            alt="Preview of the product item"
            style={{ objectFit: "contain" }}
            src={previewUrl}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
