import {
  GridColDef,
  GridEditInputCell,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { temporaryRowId } from "constants/constants";
import { TimePeriod, TimePeriodOverride } from "generated/graphql";
import {
  getCommonActions,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import {
  CustomDataGridSingleSelect,
  DataGridSelectOption,
} from "components/CustomDataGridSingleSelect";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = ({
  overridableTimePeriods,
  allTimePeriods,
  rowModesModel,
  onTimePeriodChange,
  onDeleteRow,
  onSaveRow,
}: {
  overridableTimePeriods: TimePeriod[];
  allTimePeriods: TimePeriod[];
  rowModesModel: GridRowModesModel;
  onTimePeriodChange: (
    row: TimePeriodOverride,
    newTimePeriodId: string
  ) => void;
  onSaveRow: (rowId: GridRowId) => void;
  onDeleteRow: (rowId: GridRowId) => void;
}): GridColDef<TimePeriodOverride>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const overridableTimePeriodOptions = overridableTimePeriods
    .map((timePeriod) => ({
      value: timePeriod.id,
      label: `${timePeriod.reference} ${timePeriod.description}`,
    }))
    .sort((t1, t2) => t1.label.localeCompare(t2.label));
  const columns = useMemo(
    () =>
      [
        {
          field: "timePeriod",
          headerName: t("AdminConsole.TimePeriods.timePeriod"),
          flex: 0.5,
          minWidth: 90,
          resizable: true,
          editable: true,
          type: "singleSelect",
          valueOptions: overridableTimePeriodOptions,
          valueFormatter: (value: TimePeriod) => {
            const selectedTimePeriod = allTimePeriods.find(
              (tp) => tp.id === value.id
            );

            return `${selectedTimePeriod?.reference} ${selectedTimePeriod?.description}`;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<any, TimePeriodOverride, any>
          ) => {
            const styledValueOptions = (
              (overridableTimePeriodOptions as DataGridSelectOption[]) ?? []
            ).map((valueOption) => {
              return {
                value: valueOption.value,
                label: (
                  <Tooltip title={valueOption.label!}>
                    <Typography
                      variant="body2"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                    >
                      {valueOption.label}
                    </Typography>
                  </Tooltip>
                ),
              };
            });

            const selectedValue = styledValueOptions.find(
              (option) => option.value === cellParams.row.timePeriodId
            );

            return (
              <CustomDataGridSingleSelect
                disabled={cellParams.id !== temporaryRowId}
                error={!cellParams.row.timePeriodId}
                options={styledValueOptions}
                value={selectedValue?.value ?? ""}
                onChange={(event: SelectChangeEvent<any>) => {
                  onTimePeriodChange(cellParams.row, event.target.value);
                }}
              />
            );
          },
        },
        {
          field: "default",
          headerName: `${t("common.labels.default")} (days)`,
          flex: 0.1,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => {
            return row.timePeriod?.duration ?? "N/A";
          },
        },
        {
          field: "duration",
          headerName: `${t("common.labels.override")} (days)`,
          flex: 0.1,
          minWidth: 90,
          resizable: true,
          editable: true,
          renderEditCell: (
            cellParams: GridRenderEditCellParams<any, TimePeriodOverride, any>
          ) => (
            <GridEditInputCell
              {...cellParams}
              type="number"
              required
              variant="outlined"
              disabled={cellParams.id !== temporaryRowId}
              inputProps={{
                min: 0,
              }}
              error={!cellParams.row.duration}
            />
          ),
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          getActions: (cellParams) =>
            getCommonActions(cellParams, rowModesModel, onSaveRow, onDeleteRow),
        },
      ] as GridColDef<TimePeriodOverride>[],
    [
      t,
      dateCreatedColConfig,
      allTimePeriods,
      rowModesModel,
      overridableTimePeriodOptions,
      onTimePeriodChange,
      onDeleteRow,
      onSaveRow,
    ]
  );
  return columns;
};
