import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimErrorDeterminationNoticePromptQuery = gql`
  query claimErrorDeterminationNoticePrompt(
    $input: ClaimErrorDeterminationNoticePromptInput!
  ) {
    claimErrorDeterminationNoticePrompt(input: $input) {
      details
    }
  }
`;

export const sendClaimErrorDeterminationNoticeMutation = gql`
  mutation sendClaimErrorDeterminationNotice(
    $input: SendClaimErrorDeterminationNoticeInput!
  ) {
    sendClaimErrorDeterminationNotice(input: $input) {
      id
    }
  }
`;

export const recordClaimErrorDeterminationNoticeMutation = gql`
  mutation recordClaimErrorDeterminationNotice(
    $input: RecordClaimErrorDeterminationNoticeInput!
  ) {
    recordClaimErrorDeterminationNotice(input: $input) {
      id
    }
  }
`;

export const generateSendClaimErrorDeterminationNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimErrorDeterminationNoticePreview(
    $input: SendClaimErrorDeterminationNoticePreviewInput!
  ) {
    generateSendClaimErrorDeterminationNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimErrorDeterminationNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimErrorDeterminationNoticePreview(
    $input: RecordClaimErrorDeterminationNoticePreviewInput!
  ) {
    generateRecordClaimErrorDeterminationNoticePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
