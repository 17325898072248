import { gql } from "@apollo/client";
import { authorizationWorkflowAuditFields } from "graphql/fragments/authorizationWorkflowAudit.fragment";

export const authorizationWorkflowAuditQuery = gql`
  ${authorizationWorkflowAuditFields}

  query authorizationWorkflowAudit($id: ID!) {
    authorizationWorkflowAudit(id: $id) {
      ...AuthorizationWorkflowAuditFields
    }
  }
`;
