import { Grid } from "@mui/material";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import {
  ProductItemCard,
  ProductItemCardProps,
} from "components/ProductItemCard/ProductItemCard";
import {
  EarlyWarningItemPreview,
  InstructionItemPreview,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { RecipientsPreview } from "../../../../components/RecipientsPreview";
import {
  RecordProductItemExtraData,
  RecordProductItemForm,
  RecordProductItemFormProps,
} from "../../../../components/RecordProductItemForm";

export type RecordEWInstructionConfirmModalContentProps = {
  ewInstructionPreview: EarlyWarningItemPreview | InstructionItemPreview;
  isProvideQuotationType: boolean;
  contractId: string;
  onRecordExtraDataChange: (
    recordEWExtraData: RecordProductItemExtraData
  ) => void;
} & ProductItemCardProps &
  Omit<RecordProductItemFormProps, "onChange">;

export const RecordEWInstructionConfirmModalContent: React.FC<
  RecordEWInstructionConfirmModalContentProps
> = ({
  ewInstructionPreview,
  bindingTypes,
  onValidationChange,
  onRecordExtraDataChange,
  itemName,
  productType,
  projectName,
  contractName,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <RecordProductItemForm
          bindingTypes={bindingTypes}
          productType={productType}
          onChange={onRecordExtraDataChange}
          onValidationChange={onValidationChange}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductItemCard
          itemName={itemName}
          productType={productType}
          projectName={projectName}
          contractName={contractName}
        />
      </Grid>
      <Grid item xs={12}>
        <CollapsibleContainer
          title={`${t(`common.labels.recipients`)} (${
            ewInstructionPreview.recipients.length
          })`}
        >
          <RecipientsPreview recipients={ewInstructionPreview.recipients} />
        </CollapsibleContainer>
      </Grid>
    </Grid>
  );
};
