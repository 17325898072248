import { gql } from "@apollo/client";
import { compEventAssessmentFields } from "graphql/fragments/compEventAssessment.fragment";
import { compEventQuotationFields } from "graphql/fragments/compEventQuotation.fragment";

export const compEventQuotationAssessmentsQuery = gql`
  ${compEventQuotationFields}
  ${compEventAssessmentFields}

  query compEventQuotationAssessments($compEventId: ID!) {
    compEventQuotationAssessments(compEventId: $compEventId) {
      items {
        ... on CompEventQuotation {
          ...CompEventQuotationFields
        }

        ... on CompEventAssessment {
          ...CompEventAssessmentFields
        }
      }
    }
  }
`;
