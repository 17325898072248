import { gql } from "@apollo/client";

export const changeProductSchemaStatusMutation = gql`
  mutation changeProductSchemaStatus($id: ID!, $status: ProductSchemaStatus!) {
    changeProductSchemaStatus(id: $id, status: $status) {
      id
      name
      isDefault
      productId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;
