import { gql } from "@apollo/client";
import { draftQuotationFields } from "graphql/fragments/draftQuotation.fragment";

export const addDraftQuotationMutation = gql`
  ${draftQuotationFields}

  mutation addDraftQuotation($input: AddDraftQuotationInput!) {
    addDraftQuotation(input: $input) {
      ...DraftQuotationFields
    }
  }
`;
