import { Box, Typography } from "@mui/material";
import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { StyledLink } from "components/StyledLink";
import { NewAppPaths } from "helpers/paths/paths";
import { Warning } from "phosphor-react";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

export const AuthorizationInvalidModal: React.FC<BasicModalProps> = (
  modalProps
) => {
  const { t } = useTranslation();

  const transComponentsConfig = useMemo(
    () => ({
      customLink: (
        <StyledLink
          to={NewAppPaths.authorized.Home}
          onClick={() => modalProps.onClose?.({}, "escapeKeyDown")}
        ></StyledLink>
      ),
      normal: <Typography variant="body1"></Typography>,
    }),
    [modalProps]
  );

  return (
    <BasicModal
      headerIcon={
        <IconContainer>
          <Warning size={24} />
        </IconContainer>
      }
      {...modalProps}
      title={t("Home.Authorizations.InvalidAuthAuditModal.title")}
      titleNextToIcon
      maxWidth="md"
      onClose={modalProps.onClose}
    >
      <Box>
        <Trans
          i18nKey={"Home.Authorizations.InvalidAuthAuditModal.body"}
          components={transComponentsConfig}
        >
          <Typography variant="h4">
            Check the Authorizations panel on{" "}
            <StyledLink to={NewAppPaths.authorized.Home}>Home</StyledLink> for
            any items currently requiring your authorization.
          </Typography>
        </Trans>
      </Box>
    </BasicModal>
  );
};
