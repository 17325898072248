import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { schemaFieldFields } from "graphql/fragments/schemaField.fragment";

export const productInstanceSchemaQuery = gql`
  ${creatorFields}
  ${schemaFieldFields}

  query productInstanceSchema($id: ID!) {
    productInstance(id: $id) {
      id
      description
      soloModeSupported
      productSchema {
        id
        name
        isDefault
        productId
        isInternal
        status
        creatorId
        creator {
          ...CreatorFields
        }
        dateCreated
        schemaSections {
          items {
            id
            name
            displayText
            displayOrder
            columnPlacement
            productSchemaId
            isInternal
            status
            dateCreated
            creatorId
            creator {
              ...CreatorFields
            }
            schemaFields {
              items {
                ...SchemaFieldFields
              }
            }
          }
        }
      }
    }
  }
`;
