import {
  CompanyLookupCollection,
  DailyDiaryWorkRecord,
} from "generated/graphql";
import { TFunction } from "i18next";
import { getUserName } from "helpers/miscelaneous";

export const toRecordJSON = (
  record: DailyDiaryWorkRecord,
  withShiftColumn: boolean,
  withActivityColumn: boolean,
  withAreaColumn: boolean,
  withDescriptionColumn: boolean,
  withDisciplineColumn: boolean,
  withTeamColumn: boolean,
  workLookups: CompanyLookupCollection[],
  t: TFunction
) => {
  return {
    ...(withDescriptionColumn && {
      [t("common.labels.description")]: record.description ?? "",
    }),
    ...(withShiftColumn && {
      [t("Projects.DailyDiaries.Work.shift")]:
        workLookups
          .find((lkp) => lkp.id === record.shiftLookupId)
          ?.options.items.find((option) => option.id === record.shiftOptionId)
          ?.value ?? "",
    }),
    ...(withAreaColumn && {
      [t("Projects.DailyDiaries.Work.area")]:
        workLookups
          .find((lkp) => lkp.id === record.areaLookupId)
          ?.options.items.find((option) => option.id === record.areaOptionId)
          ?.value ?? "",
    }),
    ...(withDisciplineColumn && {
      [t("Projects.DailyDiaries.Work.discipline")]:
        workLookups
          .find((lkp) => lkp.id === record.disciplineLookupId)
          ?.options.items.find(
            (option) => option.id === record.disciplineOptionId
          )?.value ?? "",
    }),
    ...(withTeamColumn && {
      [t("Projects.DailyDiaries.Work.team")]:
        workLookups
          .find((lkp) => lkp.id === record.teamLookupId)
          ?.options.items.find((option) => option.id === record.teamOptionId)
          ?.value ?? "",
    }),
    ...(withActivityColumn && {
      [t("Projects.DailyDiaries.Work.activity")]:
        workLookups
          .find((lkp) => lkp.id === record.activityLookupId)
          ?.options.items.find(
            (option) => option.id === record.activityOptionId
          )?.value ?? "",
    }),
    [t("Projects.DailyDiaries.Work.workType")]:
      workLookups
        .find((lkp) => lkp.id === record.workTypeLookupId)
        ?.options.items.find((option) => option.id === record.workTypeOptionId)
        ?.value ?? "",
    [t("Projects.DailyDiaries.Work.amount")]: record.amount
      ? `${record.amount}`
      : "",
    [t("Projects.DailyDiaries.Work.unitOfMeasurement")]:
      workLookups
        .find((lkp) => lkp.id === record.unitOfMeasurementLookupId)
        ?.options.items.find(
          (option) => option.id === record.unitOfMeasurementOptionId
        )?.value ?? "",
    [t("common.labels.createdBy")]: getUserName(record.creator),
  };
};
