import { Box, BoxProps, IconButton, InputAdornment } from "@mui/material";
import { Eye, EyeClosed } from "phosphor-react";

export type PasswordEyeAdornmentProps = {
  visibility: boolean;
  onToggleVisibility: () => void;
} & Omit<BoxProps, "visibility">;

export const PasswordEyeAdornment: React.FC<PasswordEyeAdornmentProps> = ({
  visibility,
  onToggleVisibility,
  ...restBoxProps
}) => (
  <InputAdornment position="end">
    <Box {...restBoxProps}>
      <IconButton
        aria-label="toggle password visibility"
        onClick={onToggleVisibility}
        onMouseDown={onToggleVisibility}
      >
        {visibility ? <Eye /> : <EyeClosed />}
      </IconButton>
    </Box>
  </InputAdornment>
);
