import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const CompanyIcon: React.FC<BaseIconProps> = ({
  height = "16",
  width = "18",
  color,
}) => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 14H17.25V15.5H0.75V14H2.25V1.25C2.25 1.05109 2.32902 0.860322 2.46967 0.71967C2.61032 0.579018 2.80109 0.5 3 0.5H15C15.1989 0.5 15.3897 0.579018 15.5303 0.71967C15.671 0.860322 15.75 1.05109 15.75 1.25V14ZM6 7.25V8.75H8.25V7.25H6ZM6 4.25V5.75H8.25V4.25H6ZM6 10.25V11.75H8.25V10.25H6ZM9.75 10.25V11.75H12V10.25H9.75ZM9.75 7.25V8.75H12V7.25H9.75ZM9.75 4.25V5.75H12V4.25H9.75Z"
        fill={color || theme.palette.primary.main}
      />
    </svg>
  );
};
