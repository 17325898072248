import { Box, css, styled } from "@mui/material";

export const SectionHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "collapsed",
})<{ collapsed?: boolean }>(
  ({ collapsed, theme }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${collapsed ? 0 : theme.spacing(2)};
    position: sticky;
    top: 0;
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${collapsed ? 0 : theme.spacing(1)};
    background: ${theme.palette.common.white};
    z-index: 1;
    transition: padding-bottom 0.25s, margin-bottom 0.25s;
  `
);
