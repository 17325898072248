import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventGrantingOfMoreTimeMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventGrantingOfMoreTime(
    $input: RecordCompEventGrantingOfMoreTimeInput!
  ) {
    recordCompEventGrantingOfMoreTime(input: $input) {
      ...CompEventItemFields
    }
  }
`;
