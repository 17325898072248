import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventQuotationAcceptancePreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventQuotationAcceptancePreview(
    $input: RecordCompEventQuotationAcceptancePreviewInput!
  ) {
    generateRecordCompEventQuotationAcceptancePreview(input: $input) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
