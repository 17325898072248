import { css, styled, Typography } from "@mui/material";

export const StyledBoldableText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "dataText",
})<{ dataText?: string }>(
  ({ theme, dataText }) => css`
    && {
      color: ${theme.palette.common.black};
      font-size: 0.875rem;
      line-height: 1.43;

      &::after {
        content: "${dataText}";
        display: block;
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  `
);
