import { css, styled } from "@mui/material";
import { NewAppPaths } from "helpers/paths/paths";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

type AuthorizationToastTransMessageProps = {
  i18nKey?: string;
};

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(0.75)};
    text-decoration: underline;
    color: ${theme.palette.common.white};
  `
);

export const AuthorizationToastTransMessage: React.FC<
  AuthorizationToastTransMessageProps
> = ({ i18nKey }) => {
  const { closeSnackbar } = useSnackbar();

  const transComponentsConfig = useMemo(
    () => ({
      customLink: (
        <StyledLink
          to={`${NewAppPaths.authorized.Home}?yourRequests=true`}
          state={{ scrollToAuthorizations: true }}
          onClick={() => closeSnackbar()}
        ></StyledLink>
      ),
    }),
    [closeSnackbar]
  );

  return (
    <Trans
      i18nKey={
        i18nKey ?? "common.successMessages.actionInitiatedForAuthorization"
      }
      components={transComponentsConfig}
    />
  );
};
