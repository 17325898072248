import { Box } from "@mui/material";
import {
  NewEntityHeader,
  NewEntityHeaderFontSize,
} from "components/NewEntityHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { PageContentHeaderSize } from "components/PageContentHeader/PageContentHeader";
import {
  AddProjectInput,
  AddProjectMutation,
  AddProjectMutationVariables,
  Project,
} from "generated/graphql";
import { useCompanies } from "hooks/useCompanies";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ProjectForm,
  ProjectFormPublicApi,
} from "../components/ProjectForm/ProjectForm";
import { addProjectMutation } from "./NewProject.query";

export const NewProject = () => {
  const { t } = useTranslation();
  const [projectData, setProjectData] = useState<AddProjectInput>();
  const ref = useRef<ProjectFormPublicApi>(null);
  const navigate = useNavigate();

  const { companies, loading: companiesLoading } = useCompanies();

  const [addNewProject, { loading: addNewProjectLoading }] = useGraphMutation<
    AddProjectMutation,
    AddProjectMutationVariables
  >(
    addProjectMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "projects" });
        cache.gc();
      },
    },
    t("common.successMessages.entityCreated", {
      entity: t("common.labels.project"),
    })
  );

  const handleAddProject = useCallback(async () => {
    if (ref.current?.validate()) {
      const { errors } = await addNewProject({
        variables: {
          input: projectData!,
        },
      });

      if (!errors) {
        navigate(-1);
      }
    }
  }, [addNewProject, projectData, navigate]);

  const handleFormChange = useCallback((data: AddProjectInput | Project) => {
    setProjectData(data as AddProjectInput);
  }, []);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <NewEntityHeader
        onAdd={handleAddProject}
        size={PageContentHeaderSize.Normal}
        entityName={t("common.labels.project").toLowerCase()}
        fontSize={NewEntityHeaderFontSize.Large}
        generalLoading={addNewProjectLoading || companiesLoading}
        addActionLoading={addNewProjectLoading}
      />
      <PageContentContainer>
        <ProjectForm
          onChange={handleFormChange}
          ref={ref}
          disabled={addNewProjectLoading || companiesLoading}
          companiesList={companies}
        />
      </PageContentContainer>
    </Box>
  );
};
