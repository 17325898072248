import { gql } from "@apollo/client";
import { companyLookupCollectionFields } from "graphql/fragments/companyLookupCollection.fragment";

export const changeCompanyLookupCollectionStatusMutation = gql`
  ${companyLookupCollectionFields}

  mutation changeCompanyLookupCollectionStatus(
    $id: ID!
    $status: CompanyLookupCollectionStatus!
  ) {
    changeCompanyLookupCollectionStatus(id: $id, status: $status) {
      ...CompanyLookupCollectionFields
    }
  }
`;
