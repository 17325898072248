import { gql } from "@apollo/client";

export const changeLookupCollectionStatusMutation = gql`
  mutation changeLookupCollectionStatus(
    $id: ID!
    $status: LookupCollectionStatus!
  ) {
    changeLookupCollectionStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
