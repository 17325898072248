import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";

export const compEventQuotationTimeChangeFields = gql`
  fragment CompEventQuotationTimeChangeFields on CompEventQuotationTimeChange {
    number
    description
    days
  }
`;

export const compEventQuotationFields = gql`
  ${compEventQuotationTimeChangeFields}
  ${attachmentFields}

  fragment CompEventQuotationFields on CompEventQuotation {
    id
    compEventId
    reference
    details
    price
    currency
    time
    accepted
    revision
    keyDatesChanges {
      ...CompEventQuotationTimeChangeFields
    }
    sectionalChanges {
      ...CompEventQuotationTimeChangeFields
    }
    attachments {
      ...AttachmentFields
    }
    dateCreated
    creatorId
  }
`;
