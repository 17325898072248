import { gql } from "@apollo/client";
import { lookupCollectionFields } from "graphql/queries/lookupCollection.query";

export const editLookupCollectionMutation = gql`
  ${lookupCollectionFields}
  mutation editLookupCollection($input: EditLookupCollectionInput!) {
    editLookupCollection(input: $input) {
      ...LookupCollectionFields
    }
  }
`;
