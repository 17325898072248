import {
  DailyDiaryHseImportSourceItemsQuery,
  DailyDiaryHseImportSourceItemsQueryVariables,
  DailyDiaryItem,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { dailyDiaryHSEImportSourceItemsQuery } from "./HSESection.query";

export const useAvailableDiariesToImportFrom = () => {
  const [
    fetchAvailableDiaries,
    { data: availableDiariesToImportFrom, loading },
  ] = useGraphLazyQueryLite<
    DailyDiaryHseImportSourceItemsQuery,
    DailyDiaryHseImportSourceItemsQueryVariables
  >(dailyDiaryHSEImportSourceItemsQuery);

  return {
    availableDiariesToImportFrom: (availableDiariesToImportFrom?.data
      .dailyDiaryHSEImportSourceItems.items ?? []) as DailyDiaryItem[],
    availableDiariesToImportFromLoading: loading,
    fetchAvailableDiaries,
  };
};
