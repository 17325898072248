import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationInstructionCommenceWorkPromptQuery = gql`
  query variationInstructionCommenceWorkPrompt(
    $input: VariationInstructionCommenceWorkPromptInput!
  ) {
    variationInstructionCommenceWorkPrompt(input: $input) {
      remarks
      agreementSubmitted
    }
  }
`;

export const sendVariationInstructionCommenceWorkMutation = gql`
  mutation sendVariationInstructionCommenceWork(
    $input: SendVariationInstructionCommenceWorkInput!
  ) {
    sendVariationInstructionCommenceWork(input: $input) {
      id
    }
  }
`;

export const recordVariationInstructionCommenceWorkMutation = gql`
  mutation recordVariationInstructionCommenceWork(
    $input: RecordVariationInstructionCommenceWorkInput!
  ) {
    recordVariationInstructionCommenceWork(input: $input) {
      id
    }
  }
`;

export const generateSendVariationInstructionCommenceWorkPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationInstructionCommenceWorkPreview(
    $input: SendVariationInstructionCommenceWorkPreviewInput!
  ) {
    generateSendVariationInstructionCommenceWorkPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationInstructionCommenceWorkPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationInstructionCommenceWorkPreview(
    $input: RecordVariationInstructionCommenceWorkPreviewInput!
  ) {
    generateRecordVariationInstructionCommenceWorkPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
