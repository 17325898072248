import { gql } from "@apollo/client";
import { companyLookupCollectionFields } from "graphql/fragments/companyLookupCollection.fragment";

export const editCompanyLookupCollectionMutation = gql`
  ${companyLookupCollectionFields}

  mutation editCompanyLookupCollection(
    $input: EditCompanyLookupCollectionInput!
  ) {
    editCompanyLookupCollection(input: $input) {
      ...CompanyLookupCollectionFields
    }
  }
`;
