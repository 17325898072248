import { gql } from "@apollo/client";
import { dailyDiaryResourceRecordLiteFields } from "graphql/fragments/dailyDiaryResourceRecordLite.fragment";

export const addDailyDiaryManpowerRecordMutation = gql`
  ${dailyDiaryResourceRecordLiteFields}

  mutation addDailyDiaryManpowerRecord(
    $input: AddDailyDiaryResourceRecordInput!
  ) {
    addDailyDiaryManpowerRecord(input: $input) {
      ...DailyDiaryResourceRecordLiteFields
    }
  }
`;
