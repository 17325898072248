import { Box, Collapse } from "@mui/material";
import {
  AddCompanyInput,
  ChangeCompanyStatusMutation,
  ChangeCompanyStatusMutationVariables,
  ChangeContractStatusMutation,
  ChangeContractStatusMutationVariables,
  ChangeUserStatusMutation,
  ChangeUserStatusMutationVariables,
  Company,
  CompanyStatus,
  ContractStatus,
  EditCompanyMutation,
  EditCompanyMutationVariables,
  EditCompanyInput,
  User,
  UserStatus,
  Contract,
  GetCompanyDetailsQuery,
  GetCompanyDetailsQueryVariables,
} from "generated/graphql";
import { useRef, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  CompanyForm,
  CompanyFormPublicApi,
} from "../../components/CompanyForm/CompanyForm";
import { changeCompanyStatusMutation } from "../../Companies.query";
import {
  changeUserStatusMutation,
  editCompanyMutation,
  getCompanyDetailsQuery,
} from "./CompanyDetails.query";
import { CompanyDetailsHeader } from "./components/CompanyDetailsHeader";
import { CompanyIcon } from "../../../../../../components/Icons/CompanyIcon";
import { CompanyUsers } from "./components/CompanyUsers/CompanyUsers";
import { CompanyContracts } from "./components/CompanyContracts/CompanyContracts";
import { useTranslation } from "react-i18next";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { changeContractStatusMutation } from "graphql/mutations/changeContractStatus";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { HeaderPublicApi } from "components/NewEntityHeader";
import { DetailsList } from "components/DetailsList";
import { useCompanyDetailsViewObject } from "./useCompanyDetailsViewObject";
import { useResendInvitation } from "../../hooks/useResendInvitation";

export const CompanyDetails = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const headerRef = useRef<HeaderPublicApi>(null);

  const [readOnly, setReadOnly] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const [companyDetails, setCompanyDetails] = useState<Company>();
  const [updatedCompanyDetails, setUpdatedCompanyDetails] = useState<Company>(); // TODO: remove this and expose a .reset() fn in form. Do this in all forms
  const companyFormRef = useRef<CompanyFormPublicApi>(null);

  const {
    data: companyData,
    refetch: reloadCompanyDetails,
    loading: getCompanyDataLoading,
  } = useGraphQuery<GetCompanyDetailsQuery, GetCompanyDetailsQueryVariables>(
    getCompanyDetailsQuery,
    { variables: { id: companyId! } }
  );

  const [changeCompanyStatus, { loading: changeCompanyStatusLoading }] =
    useGraphMutation<
      ChangeCompanyStatusMutation,
      ChangeCompanyStatusMutationVariables
    >(
      changeCompanyStatusMutation,
      {
        update: (cache) => {
          reloadCompanyDetails();
          cache.evict({ id: "ROOT_QUERY", fieldName: "companies" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.company"),
      })
    );

  const [changeUserStatus, { loading: changeUserStatusLoading }] =
    useGraphMutation<
      ChangeUserStatusMutation,
      ChangeUserStatusMutationVariables
    >(
      changeUserStatusMutation,
      {
        update: () => reloadCompanyDetails(),
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.user"),
      })
    );

  const [changeContractStatus, { loading: changeContractStatusLoading }] =
    useGraphMutation<
      ChangeContractStatusMutation,
      ChangeContractStatusMutationVariables
    >(
      changeContractStatusMutation,
      {
        update: () => reloadCompanyDetails(),
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.contract"),
      })
    );

  const [updateCompany, { loading: updateCompanyLoading }] = useGraphMutation<
    EditCompanyMutation,
    EditCompanyMutationVariables
  >(
    editCompanyMutation,
    {
      update: (cache) => {
        reloadCompanyDetails();
        cache.evict({ id: "ROOT_QUERY", fieldName: "companies" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.company"),
    })
  );

  const { resendInvitation, resendInvitationLoading } =
    useResendInvitation(reloadCompanyDetails);

  const handleCompanyUpdate = async () => {
    if (!companyFormRef.current?.validate()) {
      return;
    }

    const companyDataToUpdate: EditCompanyInput = {
      id: updatedCompanyDetails!.id,
      type: updatedCompanyDetails!.type,
      registeredName: updatedCompanyDetails!.registeredName,
      tradingName: updatedCompanyDetails!.tradingName,
      registrationNumber: updatedCompanyDetails!.registrationNumber,
      logo: updatedCompanyDetails!.logo,
      vatRegistrationNumber: updatedCompanyDetails!.vatRegistrationNumber,
      ...(updatedCompanyDetails?.physicalAddress?.country
        ? {
            physicalAddress: {
              line1: updatedCompanyDetails?.physicalAddress.line1,
              line2: updatedCompanyDetails?.physicalAddress.line2,
              city: updatedCompanyDetails?.physicalAddress.city,
              provinceState:
                updatedCompanyDetails?.physicalAddress.provinceState,
              country: updatedCompanyDetails?.physicalAddress.country,
              code: updatedCompanyDetails?.physicalAddress.code,
            },
          }
        : undefined),
      ...(updatedCompanyDetails?.postalAddress?.country
        ? {
            postalAddress: {
              line1: updatedCompanyDetails?.postalAddress.line1,
              line2: updatedCompanyDetails?.postalAddress.line2,
              city: updatedCompanyDetails?.postalAddress.city,
              provinceState: updatedCompanyDetails?.postalAddress.provinceState,
              country: updatedCompanyDetails?.postalAddress.country,
              code: updatedCompanyDetails?.postalAddress.code,
            },
          }
        : undefined),
    };

    const { errors } = await updateCompany({
      variables: {
        input: companyDataToUpdate,
      },
    });

    if (!errors) {
      setReadOnly(true);
    }
  };

  const handleCompanyDetailsChange = useCallback(
    (companyData: Company | AddCompanyInput) => {
      setUpdatedCompanyDetails(companyData as Company);
    },
    []
  );

  const handleCompanyStatusChange = async (newStatus: CompanyStatus) => {
    await changeCompanyStatus({
      variables: {
        id: companyId!,
        status: newStatus,
      },
    });
  };

  const handleUserStatusChange = async (user: User, newStatus: UserStatus) => {
    await changeUserStatus({
      variables: {
        id: user.id,
        status: newStatus,
      },
    });
  };

  const handleContractStatusChange = async (
    contract: Contract,
    newStatus: ContractStatus
  ) => {
    await changeContractStatus({
      variables: {
        id: contract.id,
        status: newStatus,
      },
    });
  };

  const handleResendUserInvitation = async (user: User) => {
    await resendInvitation({
      variables: {
        input: {
          email: user.email,
          companyId: companyId!,
        },
      },
    });
  };

  const handleLastFieldTabPress = useCallback(() => {
    headerRef.current?.focusMainActionBtn();
  }, []);

  const handleCancel = () => {
    // reset companyData sent to the form
    setCompanyDetails(undefined);
    setTimeout(() => {
      setCompanyDetails(companyData!.company! as Company);
    });
    setReadOnly(true);
  };

  const handleEdit = () => {
    setShowDetails(true);
    setReadOnly(false);
  };

  useEffect(() => {
    if (companyData?.company) {
      setCompanyDetails(companyData.company! as Company);
    }
  }, [companyData?.company]);

  const companyEntityObj = useCompanyDetailsViewObject(companyDetails);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <CompanyDetailsHeader
        name={companyData?.company?.registeredName}
        status={companyData?.company?.status}
        onStatusChange={handleCompanyStatusChange}
        apiRef={headerRef}
        loading={
          getCompanyDataLoading ||
          changeCompanyStatusLoading ||
          updateCompanyLoading
        }
      />
      <CollapsibleHeader
        title={t("AdminConsole.Companies.labels.companyDetails")}
        collapsed={!showDetails}
        icon={<CompanyIcon />}
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onCancel={handleCancel}
        onEdit={handleEdit}
        onSave={handleCompanyUpdate}
        onSaveActionLoading={updateCompanyLoading}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList
              loading={getCompanyDataLoading}
              entity={companyEntityObj}
            />
          ) : getCompanyDataLoading ? null : (
            <CompanyForm
              company={companyDetails || undefined}
              onChange={handleCompanyDetailsChange}
              ref={companyFormRef}
              onLastFieldTabPressed={handleLastFieldTabPress}
            />
          )}
        </PageContentContainer>
      </Collapse>
      <CompanyUsers
        companyName={companyDetails?.tradingName}
        users={companyDetails?.users.items || []}
        onUserStatusChange={handleUserStatusChange}
        onResendRegisterInvitation={handleResendUserInvitation}
        loading={
          getCompanyDataLoading ||
          changeUserStatusLoading ||
          resendInvitationLoading
        }
      />
      <CompanyContracts
        companyName={companyDetails?.tradingName}
        contracts={companyDetails?.contracts.items || []}
        onContractStatusChange={handleContractStatusChange}
        loading={changeContractStatusLoading || getCompanyDataLoading}
      />
    </Box>
  );
};
