import { Color, useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const CreateIcon: React.FC<BaseIconProps> = ({
  height = "41",
  width = "41",
  color,
}) => {
  const theme = useTheme();
  const computedColor =
    color ?? (theme.palette.secondary as Partial<Color>)[900];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40.0048"
        height="40.0048"
        rx="8"
        fill={(theme.palette.secondary as Partial<Color>)[100]}
      />
      <path
        d="M16.0625 15.689L20.0008 11.7507L23.9391 15.689"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0039 22.2528V11.7507"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2533 22.2529V27.504C28.2533 27.7029 28.1743 27.8937 28.0336 28.0344C27.8929 28.1751 27.7021 28.2541 27.5032 28.2541H12.5002C12.3012 28.2541 12.1104 28.1751 11.9697 28.0344C11.829 27.8937 11.75 27.7029 11.75 27.504V22.2529"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
