import { Box, Button, Stack, css, styled } from "@mui/material";

export const mainNavigationHeight = 64;
export const MainNavigationContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    color: ${theme.palette.grey[500]};
    border-bottom: 1px solid ${theme.palette.grey[200]};
    height: ${mainNavigationHeight}px;
    position: sticky;
    top: 0;
    z-index: 9;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  `
);

export const MainNavigationInnerContainer = styled(Stack)(
  ({ theme }) => css`
    padding: 0 ${theme.spacing(6)};
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  `
);

export const TogglebleButton = styled(Button)<{ selected?: boolean }>(
  ({ theme, selected = false }) => css`
    && {
      background-color: transparent;
      color: ${theme.palette.grey[500]};

      ${selected ? `color: ${theme.palette.primary.main}` : ""}
    }
  `
);
