import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventConfirmationMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventConfirmation($input: SendCompEventConfirmationInput!) {
    sendCompEventConfirmation(input: $input) {
      ...CompEventItemFields
    }
  }
`;
