import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type UserCompanyDetailListItemProps = {
  companyName: string;
  onChangeCompany: () => void;
};

export const UserCompanyDetailListItem: React.FC<
  UserCompanyDetailListItemProps
> = ({ companyName, onChangeCompany }) => {
  const { t } = useTranslation();

  return (
    <Stack
      display="flex"
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={1}
    >
      <Typography variant="subtitle2" data-testid={companyName}>
        {companyName}
      </Typography>
      <Button
        onClick={onChangeCompany}
        size="small"
        variant="text"
        sx={{ p: 0, height: "auto" }}
      >
        <Typography variant="p2" textTransform="none">
          {t("common.buttons.change")}
        </Typography>
      </Button>
    </Stack>
  );
};
