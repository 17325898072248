import { Box, styled, css } from "@mui/material";
import { defaultPageBackgroundColor } from "theme/extendedTheme";

export const NewPageContentContainer = styled(Box)<{
  px?: number;
  py?: number;
}>(
  ({ theme, px = 6, py = 4 }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    background-color: ${defaultPageBackgroundColor};
    padding-left: ${theme.spacing(px)};
    padding-right: ${theme.spacing(px)};
    padding-top: ${theme.spacing(py)};
    padding-bottom: ${theme.spacing(py + 2)};
  `
);
