import axios from "axios";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  CreateUploadAttachmentPreSignedUrlMutation,
  CreateUploadAttachmentPreSignedUrlMutationVariables,
} from "generated/graphql";
import { createUploadAttachmentPreSignedUrlMutation } from "graphql/mutations/createUploadAttachmentPresignedUrl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EnhancedAttachment, FileType } from "../Attachments.decl";
import {
  attachmentsAndFilesSortFn,
  attachmentsToAttachmentInputs,
  downloadURI,
  isAttachment,
} from "../Attachments.utils";
import { useRemoveAttachmentFile } from "./useRemoveAttachmentFile";
import { useAttachmentDownloadPresignedUrl } from "./useAttachmentDownloadPresignedUrl";
import { snackbarAutoHideDuration } from "../../../../../constants";
import uniqBy from "lodash.uniqby";

type useAttachmentsResponse = {
  allAttachments: (EnhancedAttachment | FileType)[];
  attachmentsLoading: boolean;
  addAttachments: (newAttachments: FileType[]) => void;
  removeAttachment: (attachment: FileType | Attachment) => void;
  updateAttachment: (updatedAttachment: FileType | Attachment) => void;
  unloadLocalAttachments: () => void;
  downloadAttachment: (attachment: FileType | Attachment) => void;
};

export enum AttachmentOperation {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export const useAttachments = (
  attachments: EnhancedAttachment[],
  editProductItemAttachments?: (
    attachments: AttachmentInput[],
    operation: AttachmentOperation
  ) => Promise<void> // NOTE: see if this can return void
): useAttachmentsResponse => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const activeAttachments = useMemo(
    () =>
      attachments.filter((attach) => attach.status === AttachmentStatus.Active),
    [attachments]
  );

  const uploadingFilesRef = useRef<FileType[]>([]);

  const [localLoadingAttachments, setLocalLoadingAttachments] = useState<
    FileType[]
  >([]);
  const [localUploadedAttachments, setLocalUploadedAttachments] = useState<
    FileType[]
  >([]);
  const [localComittingAttachments, setLocalComittingAttachments] = useState<
    FileType[]
  >([]);
  const [localAttachmentsToCommit, setLocalAttachmentsToCommit] = useState<
    FileType[]
  >([]);

  const allAttachments: (EnhancedAttachment | FileType)[] = useMemo(() => {
    const activeAttachmentsUrls = activeAttachments.map(
      (activeAttach) => activeAttach.fileUrl
    );

    // filter out local attachments that have recently been added to a product item, thus they're now coming from "attachments" argument
    const filteredLocalAttachments = localComittingAttachments.filter(
      (localAttach) =>
        !activeAttachmentsUrls.find((url) => url.indexOf(localAttach.id) >= 0)
    );

    if (filteredLocalAttachments.length !== localComittingAttachments.length) {
      setLocalComittingAttachments((crtLocalAttach) =>
        crtLocalAttach.filter(
          (localAttach) =>
            !activeAttachmentsUrls.find(
              (url) => url.indexOf(localAttach.id) >= 0
            )
        )
      );
    }
    const allFilesRaw = [
      ...activeAttachments,
      ...filteredLocalAttachments,
      ...localLoadingAttachments,
      ...localUploadedAttachments,
    ];
    allFilesRaw.sort(attachmentsAndFilesSortFn);

    return allFilesRaw;
  }, [
    localComittingAttachments,
    activeAttachments,
    localLoadingAttachments,
    localUploadedAttachments,
  ]);

  const [createUploadAttachmentPresignedUrl] = useGraphMutation<
    CreateUploadAttachmentPreSignedUrlMutation,
    CreateUploadAttachmentPreSignedUrlMutationVariables
  >(
    createUploadAttachmentPreSignedUrlMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItem" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "draftEarlyWarningItem" });
        cache.gc();
      },
    },
    null
  );

  const { getAttachmentDownloadPresignedUrl } =
    useAttachmentDownloadPresignedUrl();

  const removeAttachmentFile = useRemoveAttachmentFile();

  const uploadFile = useCallback(
    async (file: FileType) => {
      const { data } = await createUploadAttachmentPresignedUrl({
        variables: {
          input: { fileName: file.fileName, mimeType: file.mimeType },
        },
      });

      if (data) {
        await axios
          .put(data.createUploadAttachmentPreSignedUrl.fileUrl, file.rawFile, {
            headers: {
              "Content-Type": file.mimeType,
            },
          })
          .catch((error) =>
            console.error(error.response.data, { request: error.request })
          );

        enqueueSnackbar(t("Attachments.attachmentUploadedMsg"), {
          autoHideDuration: snackbarAutoHideDuration,
          variant: "success",
        });

        return {
          fileName: data.createUploadAttachmentPreSignedUrl.fileName,
          fileUrl: data.createUploadAttachmentPreSignedUrl.fileUrl,
        };
      }
    },
    [createUploadAttachmentPresignedUrl, t, enqueueSnackbar]
  );

  const tryNotifyConsumerOnAddedAttachments = useCallback(
    async (newAttachments: FileType[]) => {
      // remove newAttachments from localUploadedAttachments
      setLocalUploadedAttachments((crtAttachments) =>
        crtAttachments.filter(
          (attach) =>
            !newAttachments.find((newAttach) => newAttach.id === attach.id)
        )
      );

      if (localComittingAttachments.length) {
        // if there are local attachments when adding a new attachment, it means there's already an upload in progress.
        // Try again and again each 1s until the previous attachment ended
        console.log(
          "upload in progress... saving current attachments for a future commit",
          newAttachments.map((attach) => attach.displayName).join(", ")
        );
        setLocalAttachmentsToCommit((crtAttachmentsToCommit) => [
          ...crtAttachmentsToCommit,
          ...newAttachments,
        ]);
      } else {
        setLocalComittingAttachments(newAttachments);
        await editProductItemAttachments?.(
          attachmentsToAttachmentInputs(
            [...activeAttachments, ...newAttachments].sort(
              attachmentsAndFilesSortFn
            )
          ),
          AttachmentOperation.Create
        );
      }
    },
    [editProductItemAttachments, activeAttachments, localComittingAttachments]
  );

  const handleAddAttachments = async (newAttachments: FileType[]) => {
    setLocalLoadingAttachments(
      newAttachments.map((attachment) => ({
        ...attachment,
        loading: true,
      }))
    );
  };

  const handleRemoveAttachment = (attachment: FileType | Attachment) => {
    // Note: check if need to filter from all local attachments
    setLocalComittingAttachments((crtLocalAttachments) =>
      crtLocalAttachments.filter(
        (crtLocalAttachment) => crtLocalAttachment.id !== attachment.id
      )
    );

    editProductItemAttachments?.(
      attachmentsToAttachmentInputs(
        allAttachments
          .filter((crtAttachment) => crtAttachment.id !== attachment.id)
          .sort(attachmentsAndFilesSortFn)
      ),
      AttachmentOperation.Delete
    );

    if (!isAttachment(attachment)) {
      // this means the file is not saved against a product item, thus FE needs to manually call remove file from S3 function
      removeAttachmentFile({ variables: { fileName: attachment.id } });
    }
  };

  const handleUpdateAttachment = (updatedAttachment: FileType | Attachment) => {
    editProductItemAttachments?.(
      attachmentsToAttachmentInputs(
        allAttachments
          .map((crtAttachment) =>
            crtAttachment.id === updatedAttachment.id
              ? updatedAttachment
              : crtAttachment
          )
          .sort(attachmentsAndFilesSortFn)
      ),
      AttachmentOperation.Update
    );

    if (!isAttachment(updatedAttachment)) {
      // AKA if updatedAttachment is a local file not yet uploaded against the product item
      setLocalComittingAttachments((crtLocalAttachments) =>
        crtLocalAttachments.map((crtAttachment) =>
          crtAttachment.id === updatedAttachment.id
            ? updatedAttachment
            : crtAttachment
        )
      );
    }
  };

  const unloadLocalAttachments = () => {
    for (let index = 0; index < localComittingAttachments.length; index++) {
      const crtAttach = localComittingAttachments[index];
      removeAttachmentFile({ variables: { fileName: crtAttach.id } });
    }
  };

  const downloadAttachment = async (attachment: FileType | Attachment) => {
    const dwdUrl = await getAttachmentDownloadPresignedUrl(
      attachment.fileUrl!,
      attachment.fileName
    );

    if (dwdUrl) {
      downloadURI(dwdUrl, attachment.fileName);
    }
  };

  const attachmentAlreadyUploading = useCallback((attachment: FileType) => {
    return uploadingFilesRef.current.find(
      (uploadingFile) =>
        uploadingFile.rawFile.size === attachment.rawFile.size &&
        uploadingFile.rawFile.name === attachment.rawFile.name
    );
  }, []);

  const filterOutAlreadyUploadingFiles = useCallback(
    (attachmentsToUpload: FileType[]) => {
      return attachmentsToUpload.filter(
        (attachmentToUpload) => !attachmentAlreadyUploading(attachmentToUpload)
      );
    },
    [attachmentAlreadyUploading]
  );

  const uploadFiles = useCallback(
    async (attachments: FileType[]) => {
      const attachmentsToUpload = filterOutAlreadyUploadingFiles(attachments);
      const uploadedAttachments: FileType[] = [];

      /* eslint-disable no-loop-func */
      for (let index = 0; index < attachmentsToUpload.length; index++) {
        const newAttachment = attachmentsToUpload[index];

        uploadingFilesRef.current = [
          ...uploadingFilesRef.current,
          newAttachment,
        ];
        const result = await uploadFile(newAttachment);
        uploadedAttachments.push({
          ...newAttachment,
          fileUrl: result?.fileUrl,
          id: result?.fileName || newAttachment.id,
          loading: false,
        });
        uploadingFilesRef.current = uploadingFilesRef.current.filter(
          (uploadingFile) =>
            !(
              uploadingFile.rawFile.name === newAttachment.rawFile.name &&
              uploadingFile.rawFile.size === newAttachment.rawFile.size
            )
        );
      }
      /* eslint-enable no-loop-func */

      setLocalLoadingAttachments([]);
      setLocalUploadedAttachments(uploadedAttachments);
    },
    [uploadFile, filterOutAlreadyUploadingFiles]
  );

  const attachmentsLoading = useMemo(
    () => !!allAttachments.find((attachment) => attachment.loading),
    [allAttachments]
  );

  useEffect(() => {
    if (localUploadedAttachments.length) {
      tryNotifyConsumerOnAddedAttachments(localUploadedAttachments);
    }
  }, [localUploadedAttachments, tryNotifyConsumerOnAddedAttachments]);

  useEffect(() => {
    if (localLoadingAttachments.length) {
      uploadFiles(localLoadingAttachments);
    }
  }, [localLoadingAttachments, uploadFiles]);

  useEffect(() => {
    if (localAttachmentsToCommit.length) {
      // new attachments failed to commit because another upload was in progress. When first upload finishes, newlyAttached files are removed from
      // localAttachments, thus we can be certain we can commit the new attachments when `localAttachments` change
      console.log(
        "committing local attachments to commit",
        localAttachmentsToCommit.map((attach) => attach.fileName).join(", ")
      );

      editProductItemAttachments?.(
        attachmentsToAttachmentInputs(
          [
            ...activeAttachments,
            ...uniqBy(
              [...localComittingAttachments, ...localAttachmentsToCommit],
              "id"
            ),
          ].sort(attachmentsAndFilesSortFn)
        ),
        AttachmentOperation.Create
      );
      setLocalAttachmentsToCommit([]);
      setLocalComittingAttachments((crtAttachments) => [
        ...crtAttachments,
        ...localAttachmentsToCommit,
      ]);
    }
  }, [
    activeAttachments,
    editProductItemAttachments,
    localAttachmentsToCommit,
    localComittingAttachments,
  ]);

  return {
    allAttachments,
    attachmentsLoading,
    addAttachments: handleAddAttachments,
    removeAttachment: handleRemoveAttachment,
    updateAttachment: handleUpdateAttachment,
    unloadLocalAttachments,
    downloadAttachment,
  };
};
