import { gql } from "@apollo/client";

const userPreferencesFields = gql`
  fragment PreferenceValueCommonFields on UserPreferencesValue {
    inApp
    inAppReadOnly
    email
    emailReadOnly
  }

  fragment PreferenceValuesFields on UserPreferences {
    userId
    ownershipAssignment {
      ...PreferenceValueCommonFields
    }
    anyNewRisk {
      ...PreferenceValueCommonFields
    }
    changesToAnyRisk {
      ...PreferenceValueCommonFields
    }
    anyNewSentEW {
      ...PreferenceValueCommonFields
    }
    anyNewRecordedEW {
      ...PreferenceValueCommonFields
    }
    changesToAnyEW {
      ...PreferenceValueCommonFields
    }
    attachmentChanges {
      ...PreferenceValueCommonFields
    }
    newComments {
      ...PreferenceValueCommonFields
    }
    commentsMentioned {
      ...PreferenceValueCommonFields
    }
    commentsReplied {
      ...PreferenceValueCommonFields
    }
    anyNewEvent {
      ...PreferenceValueCommonFields
    }
    changesToAnyEvent {
      ...PreferenceValueCommonFields
    }
    anyNewRecordedInstruction {
      ...PreferenceValueCommonFields
    }
    anyNewSentInstruction {
      ...PreferenceValueCommonFields
    }
    changesToAnyInstruction {
      ...PreferenceValueCommonFields
    }
    anyNewCEActionRecorded {
      ...PreferenceValueCommonFields
    }
    anyNewCEActionSent {
      ...PreferenceValueCommonFields
    }
    anyNewClaimActionSent {
      ...PreferenceValueCommonFields
    }
    anyNewClaimActionRecorded {
      ...PreferenceValueCommonFields
    }
    anyNewVariationActionSent {
      ...PreferenceValueCommonFields
    }
    anyNewVariationActionRecorded {
      ...PreferenceValueCommonFields
    }
    authorizationRequired {
      ...PreferenceValueCommonFields
    }
    authorizedActionExecuted {
      ...PreferenceValueCommonFields
    }
    authorizedActionPrevented {
      ...PreferenceValueCommonFields
    }
    creatorId
    dateCreated
    dateModified
  }
`;

export const userPreferencesQuery = gql`
  ${userPreferencesFields}
  query userPreferences {
    userPreferences {
      ...PreferenceValuesFields
    }
  }
`;

export const setUserPreferencesMutation = gql`
  ${userPreferencesFields}
  mutation setUserPreferences($input: UserPreferencesInput!) {
    setUserPreferences(input: $input) {
      ...PreferenceValuesFields
    }
  }
`;
