import { Box, Collapse, useTheme } from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { PageContentContainer } from "components/PageContentContainer";
import {
  AddProjectInput,
  AuthorizationWorkflow,
  AuthorizationWorkflowStatus,
  ChangeContractStatusMutation,
  ChangeContractStatusMutationVariables,
  ChangeProjectStatusMutation,
  ChangeProjectStatusMutationVariables,
  Contract,
  ContractStatus,
  EditProjectInput,
  EditProjectMutation,
  EditProjectMutationVariables,
  Project,
  ProjectQuery,
  ProjectQueryVariables,
  ProjectSector,
  ProjectStatus,
  User,
} from "generated/graphql";
import { changeContractStatusMutation } from "graphql/mutations/changeContractStatus";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  ProjectForm,
  ProjectFormPublicApi,
} from "../components/ProjectForm/ProjectForm";
import { changeProjectStatusMutation } from "../Projects.query";
import { ProjectContracts } from "./ProjectContracts/ProjectContracts";
import { editProjectMutation, projectQuery } from "./ProjectDetails.query";
import { ProjectDetailsHeader } from "./ProjectDetailsHeader";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { DetailsList } from "components/DetailsList";
import { Kanban } from "phosphor-react";
import { useCompanies } from "hooks/useCompanies";
import { ProjectAuthorizationWorkflows } from "./ProjectAuthorizationWorkflows/ProjectAuthorizationWorkflows";
import { useProjectAuthorizationWorkflows } from "./ProjectAuthorizationWorkflows/useProjectAuthorizationWorkflows";
import { ProjectAuthorizationTrails } from "./ProjectAuthorizationTrails/ProjectAuthorizationTrails";
import { useProjectAuthorizationTrails } from "./ProjectAuthorizationTrails/useProjectAuthorizationTrails";
import { getUserName } from "helpers/miscelaneous";

export const ProjectDetails = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const projectFormRef = useRef<ProjectFormPublicApi>(null);
  const theme = useTheme();

  const [projectDetails, setProjectDetails] = useState<Project>();
  const updatedProjectDetails = useRef<Project>();
  const [showDetails, setShowDetails] = useState(true);
  const [readOnly, setReadOnly] = useState(true);

  const { companies, loading: companiesloading } = useCompanies();

  const {
    data: projectData,
    refetch: refetchProjectData,
    loading: getProjectDataLoading,
  } = useGraphQuery<ProjectQuery, ProjectQueryVariables>(projectQuery, {
    variables: { id: projectId! },
  });

  const {
    authorizationWorkflows,
    changeAuthorizationWorkflowStatus,
    loading: authorizationWorkflowsLoading,
  } = useProjectAuthorizationWorkflows(projectId!);

  const {
    authAuditTrails,
    loadMore,
    hasMore,
    loading: authAuditTrailsLoading,
  } = useProjectAuthorizationTrails(projectId!);

  const [changeProjectStatus, { loading: changeProjectStatusLoading }] =
    useGraphMutation<
      ChangeProjectStatusMutation,
      ChangeProjectStatusMutationVariables
    >(
      changeProjectStatusMutation,
      {
        update: (cache) => {
          refetchProjectData();

          cache.evict({ id: "ROOT_QUERY", fieldName: "projects" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.project"),
      })
    );

  const [changeContractStatus, { loading: changeContractStatusLoading }] =
    useGraphMutation<
      ChangeContractStatusMutation,
      ChangeContractStatusMutationVariables
    >(
      changeContractStatusMutation,
      {
        update: (cache) => {
          refetchProjectData();

          cache.evict({ id: "ROOT_QUERY", fieldName: "projects" });
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.contract"),
      })
    );

  const [editProject, { loading: editProjectLoading }] = useGraphMutation<
    EditProjectMutation,
    EditProjectMutationVariables
  >(
    editProjectMutation,
    {
      update: (cache) => {
        refetchProjectData();

        cache.evict({ id: "ROOT_QUERY", fieldName: "projects" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.project"),
    })
  );

  const handleStatusChange = useCallback(
    async (newStatus: ProjectStatus) => {
      await changeProjectStatus({
        variables: {
          id: projectData!.project.id,
          status: newStatus,
        },
      });
    },
    [changeProjectStatus, projectData]
  );

  const handleEditClick = useCallback(() => {
    setReadOnly(false);
  }, []);

  const handleSaveClick = useCallback(async () => {
    if (!projectFormRef.current?.validate()) {
      return;
    }

    const projectDataToUpdate: EditProjectInput = {
      description: updatedProjectDetails.current!.description,
      friendlyName: updatedProjectDetails.current!.friendlyName,
      id: updatedProjectDetails.current!.id,
      name: updatedProjectDetails.current!.name,
      number: updatedProjectDetails.current!.number,
      sector: updatedProjectDetails.current!.sector as ProjectSector,
      billingCompanyId: updatedProjectDetails.current!.billingCompanyId,
    };

    const { errors } = await editProject({
      variables: {
        input: projectDataToUpdate,
      },
    });

    if (!errors) {
      setReadOnly(true);
    }
  }, [editProject]);

  const handleContractStatusChange = useCallback(
    async (contract: Contract, newStatus: ContractStatus) => {
      await changeContractStatus({
        variables: {
          id: contract.id,
          status: newStatus,
        },
      });
    },
    [changeContractStatus]
  );

  const handleAuthorizationWorkflowStatusChange = useCallback(
    async (
      authWorkflow: AuthorizationWorkflow,
      newStatus: AuthorizationWorkflowStatus
    ) => {
      await changeAuthorizationWorkflowStatus({
        variables: {
          id: authWorkflow.id,
          status: newStatus,
        },
      });
    },
    [changeAuthorizationWorkflowStatus]
  );

  const projectDetailsChange = (projectData: AddProjectInput | Project) => {
    updatedProjectDetails.current = projectData as Project;
  };

  const creatorName = useMemo(
    () => getUserName(projectData?.project.creator as User | undefined),
    [projectData]
  );

  const handleLoadMore = () => {
    if (hasMore) {
      loadMore();
    }
  };

  const handleCancelClick = () => {
    // TODO: reset projectData sent to the form -> use reset function
    setProjectDetails(undefined);
    setTimeout(() => {
      setProjectDetails(projectData?.project as Project);
    });
    setReadOnly(true);
  };

  useEffect(() => {
    if (projectData?.project) {
      setProjectDetails(projectData?.project as Project);
    }
  }, [projectData?.project]);

  const projectEntityObj = useMemo(() => {
    if (!projectData) {
      return [];
    }

    return [
      {
        id: "name",
        label: t("common.labels.name"),
        value: projectData.project.name,
      },
      {
        id: "friendlyName",
        label: t("common.labels.friendlyName"),
        value: projectData.project.friendlyName,
      },
      {
        id: "number",
        label: t("common.labels.number"),
        value: projectData.project.number,
      },
      {
        id: "sector",
        label: t("AdminConsole.Projects.labels.sector"),
        value: projectData.project.sector,
      },
      {
        id: "billingCompany",
        label: t("AdminConsole.Projects.labels.billingCompany"),
        value: projectData.project.billingCompany.tradingName,
      },
      {
        id: "description",
        label: t("common.labels.description"),
        value: projectData.project.description,
        fullWidth: true,
      },
    ];
  }, [projectData, t]);

  return (
    <Box>
      <ProjectDetailsHeader
        friendlyName={projectDetails?.friendlyName || ""}
        status={projectDetails?.status}
        name={projectDetails?.name || ""}
        creator={creatorName || ""}
        dateCreated={projectDetails?.dateCreated}
        generalLoading={
          getProjectDataLoading ||
          changeProjectStatusLoading ||
          editProjectLoading
        }
        onStatusChange={handleStatusChange}
      />
      <CollapsibleHeader
        title={t("AdminConsole.Projects.labels.projectDetails")}
        collapsed={!showDetails}
        icon={
          <Kanban size={22} weight="fill" color={theme.palette.primary.main} />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        onSaveActionLoading={editProjectLoading}
        editMode={!readOnly}
        onCancel={handleCancelClick}
        onEdit={handleEditClick}
        onSave={handleSaveClick}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList
              loading={getProjectDataLoading}
              entity={projectEntityObj}
            />
          ) : getProjectDataLoading ? null : (
            <ProjectForm
              project={projectDetails}
              companiesList={companies}
              onChange={projectDetailsChange}
              disabled={getProjectDataLoading || companiesloading}
              ref={projectFormRef}
            />
          )}
        </PageContentContainer>
      </Collapse>
      <ProjectContracts
        contracts={projectDetails?.contracts.items || []}
        projectName={projectDetails?.name}
        loading={getProjectDataLoading || changeContractStatusLoading}
        onContractStatusChange={handleContractStatusChange}
      />
      <ProjectAuthorizationWorkflows
        workflows={authorizationWorkflows}
        projectName={projectDetails?.name}
        loading={getProjectDataLoading || authorizationWorkflowsLoading}
        onWorkflowStatusChange={handleAuthorizationWorkflowStatusChange}
      />
      <ProjectAuthorizationTrails
        auditTrails={authAuditTrails}
        projectName={projectDetails?.name}
        loading={getProjectDataLoading || authAuditTrailsLoading}
        onLoadMore={handleLoadMore}
      />
    </Box>
  );
};
