import { Box, Collapse, Typography } from "@mui/material";
import { CaretDown, CaretUp } from "phosphor-react";
import React, { useState } from "react";

export type CollapsibleContainerProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  collapsed?: boolean;
  iconPlacement?: "left" | "right";
  children: React.ReactNode;
};

export const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  title,
  subtitle,
  collapsed: externalCollapsed = false,
  iconPlacement = "right",
  children,
}) => {
  const [collapsed, setCollapsed] = useState(externalCollapsed);

  const toggleCollapse = () => {
    setCollapsed((state) => !state);
  };

  return (
    <Box width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent={
          iconPlacement === "right" ? "space-between" : "flex-start"
        }
        flexDirection={iconPlacement === "right" ? "row" : "row-reverse"}
        mb={2}
      >
        <>
          {typeof title === "string" ? (
            <Typography
              variant="h4"
              fontWeight={600}
              color="grey.800"
              fontSize="18px"
            >
              {title}
            </Typography>
          ) : (
            { title }
          )}
          <Box
            onClick={toggleCollapse}
            sx={{ cursor: "pointer" }}
            display="flex"
          >
            {collapsed ? <CaretDown size={20} /> : <CaretUp size={20} />}
          </Box>
        </>
      </Box>
      <Collapse in={!collapsed}>
        <>
          {subtitle ? (
            typeof subtitle === "string" ? (
              <Typography variant="p1" color="grey.700" component="div" mt={-1}>
                {subtitle}
              </Typography>
            ) : (
              { subtitle }
            )
          ) : null}
          {children}
        </>
      </Collapse>
    </Box>
  );
};
