import { Box } from "@mui/material";
import { NewAppPaths } from "../../helpers/paths/paths";
import {
  matchPath,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AdminNavigation } from "./components/AdminNavigation";
import { useContext, useEffect } from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";

export const AdminConsole = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdmin } = useContext(GlobalContext);

  useEffect(() => {
    if (isAdmin === false) {
      navigate(NewAppPaths.authorized.Home, { replace: true });
    }
  }, [isAdmin, navigate]);

  if (matchPath("/admin", location.pathname)) {
    return (
      <Navigate
        to={NewAppPaths.authorized.AdminConsole.children.Companies.path}
        replace
      />
    );
  }

  return (
    <Box flex="1" display="flex" flexDirection="column" pb={4}>
      <AdminNavigation />
      <Outlet />
    </Box>
  );
};
