import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { FormErrorLabel } from "components/FormErrorLabel";
import { FormLabel } from "components/FormLabel";
import { IconContainer } from "components/IconContainer";
import { Textarea } from "components/TextArea";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { XCircle } from "phosphor-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

type DenyDailyDiaryModalProps = {
  onPrimaryClick: (denialReason: string) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

type FormDataType = {
  denialReason: string;
};

const defaultFormData: FormDataType = {
  denialReason: "",
};

export const formDataValidators: DataValidators = {
  denialReason: {
    validators: [ValidatorType.Required],
  },
};

export const DenyDailyDiaryModal: React.FC<DenyDailyDiaryModalProps> = ({
  onPrimaryClick,
  onClose,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateForm = useCallback((formData: FormDataType) => {
    const validationResult = validateData(formData, formDataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);
    return false;
  }, []);

  const resetForm = () => {
    setFormDataErrors({});
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    validateForm({ denialReason: evt.target.value });
  };

  const handlePrimaryBtnClick = () => {
    if (validateForm(formData)) {
      onPrimaryClick(formData.denialReason);
    }
  };

  const handleModalClose = () => {
    resetForm();
    onClose?.({}, "backdropClick");
  };

  return (
    <ActionsDialog
      iconsHeader={
        <IconContainer sx={{ backgroundColor: theme.palette.error.light }}>
          <XCircle size={24} color={theme.palette.error.main} />
        </IconContainer>
      }
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Projects.DailyDiaries.DenyDailyDiaryModal.title")}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]}>
            {t("Projects.DailyDiaries.DenyDailyDiaryModal.subtitle")}
          </Typography>
        </Box>
      }
      content={
        <Stack spacing={1} mt={-1}>
          <FormLabel
            label={t("Projects.DailyDiaries.DenyDailyDiaryModal.reasonLabel1")}
            required
            mb={0}
          />
          <Typography variant="p2" color="grey.600">
            {t("Projects.DailyDiaries.DenyDailyDiaryModal.reasonLabel2")}
          </Typography>
          <Textarea
            placeholder={t(
              "Projects.DailyDiaries.DenyDailyDiaryModal.reasonPlaceholder"
            )}
            value={formData.denialReason}
            name="denialReason"
            error={!!formDataErrors.denialReason}
            onChange={handleTextFieldChange}
          />
          <FormErrorLabel
            errorMessage={formDataErrors.denialReason}
            dataTestId="denial-reason-err-msg"
          />
        </Stack>
      }
      onPrimaryClick={handlePrimaryBtnClick}
      fullWidth
      maxWidth="xs"
      onClose={handleModalClose}
      primaryBtnCaption={t("common.buttons.reject")}
      secondaryBtnCaption={t("common.buttons.cancel")}
      primaryBtnColor="error"
      {...restDialogProps}
    />
  );
};
