import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  ListItem as MUIListItem,
  SelectChangeEvent,
  TextField,
  useTheme,
} from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { ListItem, ProductType, SendClaimNoticeInput } from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useEarlyWarningsProductInstance } from "hooks/useEarlyWarningsProductInstance";
import { useNotifyClaimAction } from "./useNotifyClaimAction";
import { ClaimActionFormProps } from "../ClaimActionView";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { ClaimTypeSelect } from "containers/Projects/components/ActionModal/ClaimTypeSelectGridItem";
import { SendClaimNotice } from "./SendClaimNotice";
import { RecordClaimNotice } from "./RecordClaimNotice";

const dataValidators: DataValidators = {
  claimTypeId: {
    validators: [ValidatorType.Required],
  },
};

type FormDataType = Pick<
  SendClaimNoticeInput,
  "claimTypeId" | "advanceWarningId"
>;

const defaultFormData = {
  claimTypeId: "",
  advanceWarningId: undefined, // FIDIC 1999 yellow/red books have EW instead of AW. But keeping the same form structure to not overcomplicate it
};

export const NotifyClaimAction: React.FC<ClaimActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    productInstanceId,
    regardingId,
    regardingType,
    contract,
    isFIDIC99RedYellow,
  } = useContext(ClaimWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    loading,
    actionLoading,
    advanceWarnings,
    claimTypes,
    sendClaimNotice,
    recordClaimNotice,
  } = useNotifyClaimAction();

  // Note: in the future, create useAdvanceWarningProdInstance if needed
  const {
    earlyWarningsProductInstance: awProductInstance,
    loading: awProdInstanceLoading,
  } = useEarlyWarningsProductInstance(contract.id);

  const handleClaimTypeChange = (event: SelectChangeEvent<string>) => {
    setFormData((curData) => ({
      ...curData,
      claimTypeId: event.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { claimTypeId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleAWChange = (
    _: SyntheticEvent<Element, Event>,
    value: ListItem | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      advanceWarningId: value?.id || undefined,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { advanceWarningId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const result = validateData(formData, dataValidators);

    if (result.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(result.errors);
    }

    return result.valid;
  }, []);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };
  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendClaimNotice = async () => {
    await sendClaimNotice({
      variables: {
        input: {
          claimTypeId: formData.claimTypeId,
          advanceWarningId: formData.advanceWarningId || undefined,
          productInstanceId,
          regardingId,
          regardingType,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordClaimNotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordClaimNotice({
      variables: {
        input: {
          claimTypeId: formData.claimTypeId,
          advanceWarningId: formData.advanceWarningId || undefined,
          productInstanceId,
          regardingId,
          regardingType,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [validateForm, formData]
  );

  const selectedAWItem = useMemo(
    () =>
      advanceWarnings?.find((aw) => aw.id === formData.advanceWarningId) ||
      null,
    [advanceWarnings, formData]
  );

  const awGivenDisabled =
    loading || !awProductInstance || !advanceWarnings?.length;

  const awGivenPlaceholder = !awProductInstance
    ? t(
        `Projects.Claims.ActionModal.${
          isFIDIC99RedYellow ? "noAccessToEW" : "noAccessToAW"
        }`
      )
    : !advanceWarnings?.length
    ? t(
        `Projects.Claims.ActionModal.${
          isFIDIC99RedYellow ? "noEWsFound" : "noAWsFound"
        }`
      )
    : undefined;

  return loading || awProdInstanceLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {formData.claimTypeId && sendModalVisibility && (
        <SendClaimNotice
          open={sendModalVisibility}
          claimType={
            claimTypes!.find(
              (claimType) => claimType.id === formData.claimTypeId
            )!
          }
          awGiven={advanceWarnings?.find(
            (aw) => aw.id === formData.advanceWarningId
          )}
          onPrimaryClick={handleSendClaimNotice}
          primaryBtnLoading={actionLoading}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
        />
      )}
      {formData.claimTypeId && recordModalVisibility && (
        <RecordClaimNotice
          open={recordModalVisibility}
          claimType={
            claimTypes!.find(
              (claimType) => claimType.id === formData.claimTypeId
            )!
          }
          awGiven={advanceWarnings?.find(
            (aw) => aw.id === formData.advanceWarningId
          )}
          primaryBtnLoading={actionLoading}
          onPrimaryClick={handleRecordClaimNotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ClaimTypeSelect
              value={formData.claimTypeId}
              productType={ProductType.Claims}
              claimTypes={claimTypes!}
              error={formDataErrors.claimTypeId}
              onChange={handleClaimTypeChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <>
                <FormLabel
                  label={t(
                    `Projects.Claims.ActionModal.${
                      isFIDIC99RedYellow ? "ewGiven" : "awGiven"
                    }`
                  )}
                />
                {awGivenDisabled ? (
                  <TextField
                    disabled
                    variant="outlined"
                    placeholder={awGivenPlaceholder}
                    InputProps={{
                      sx: {
                        "input.Mui-disabled": {
                          WebkitTextFillColor: theme.palette.grey[800],
                        },
                      },
                    }}
                    size="small"
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    disablePortal
                    id="aw-ew-select-standard"
                    options={advanceWarnings || []}
                    sx={{ maxHeight: 200 }}
                    value={selectedAWItem}
                    onChange={handleAWChange}
                    getOptionLabel={(preset) => preset.name}
                    fullWidth
                    disabled={awGivenDisabled}
                    renderOption={(props, option) => (
                      <MUIListItem
                        {...props}
                        data-testid={`claim-aw-ew-given-${option.name}-option`}
                      >
                        {option.name}
                      </MUIListItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                )}
              </>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
