import {
  ProductInstanceUserRolesQuery,
  ProductInstanceUserRolesQueryVariables,
  User,
  UserStatus,
} from "generated/graphql";
import { productInstanceUserRolesQuery } from "graphql/queries/productInstanceUserRoles";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";

/**
 * Returns the users which have access to the specific riskItem or ewItem. It's usually used as candidates for
 * item ownership or in the comments' possible mentionable users.
 */
export const useInvolvedUsers = (
  productInstanceId: string,
  ownerCandidates?: boolean
) => {
  const {
    data: productInstanceUserRoles,
    loading: productInstanceUserRolesLoading,
  } = useGraphQuery<
    ProductInstanceUserRolesQuery,
    ProductInstanceUserRolesQueryVariables
  >(productInstanceUserRolesQuery, { variables: { id: productInstanceId! } });

  const candidates = useMemo(() => {
    const users = productInstanceUserRoles?.productInstance.roles.items
      .filter((userRole) => {
        const hasRightStatus =
          [UserStatus.Active, UserStatus.Suspended].indexOf(
            userRole.user.status
          ) >= 0;
        // TODO: change this to an enum or smth
        if (ownerCandidates) {
          const hasRightRole =
            ["owner", "creator"].indexOf(
              userRole.productRole.name.toLowerCase()
            ) >= 0;

          return hasRightStatus && hasRightRole;
        }

        return hasRightStatus;
      })
      .map((userRole) => userRole.user);

    return users?.filter(
      (user, index) => users.indexOf(user) === index && user.registered
    );
  }, [productInstanceUserRoles, ownerCandidates]);

  return {
    candidates: (candidates ?? []) as User[],
    loading: productInstanceUserRolesLoading,
  };
};
