import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Slide, ThemeProvider } from "@mui/material";
import { baseTheme } from "./theme/baseTheme";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { read } from "helpers/localStorage";
import { AuthLSDataType } from "providers/auth.context";
import { LicenseInfo } from "@mui/x-license-pro";
import { SnackbarOrigin, SnackbarProvider } from "notistack";
import configurei18n from "i18n/config";
import { ConfigContextProvider } from "providers/config.context";
import { AppWrapper } from "containers/App/AppWrapper";
import { DismissAction } from "components/DismissAction";

LicenseInfo.setLicenseKey(
  "d6b3585122db5f2586025caa0f44ff39Tz05MzI4NCxFPTE3NTEwOTUzMjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

configurei18n();

const root = ReactDOM.createRoot(document.getElementById("root")!);
const lsAuthData = read() as AuthLSDataType;
const snackBarAnchorOrigin: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right",
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigContextProvider>
        <ThemeProvider theme={baseTheme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={snackBarAnchorOrigin}
            TransitionComponent={Slide}
            action={(snackbarId) => <DismissAction id={snackbarId} />}
          >
            <AppWrapper
              authData={
                lsAuthData?.persist && lsAuthData?.tokenData
                  ? lsAuthData.tokenData
                  : undefined
              }
            />
          </SnackbarProvider>
        </ThemeProvider>
      </ConfigContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
