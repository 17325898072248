import { Box, css, styled, Tooltip, Typography, useTheme } from "@mui/material";
import { Status } from "types/decl";
import React from "react";
import { StatusIndicator } from "../../StatusIndicator";
import { t } from "i18next";

export type ListItemProps = {
  status: Status;
  mainText: string;
  rightText?: string;
  selected?: boolean;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

const ListItemContainer = styled(Box)<{
  selected?: boolean;
  disabled?: boolean;
}>(
  ({ theme, selected, disabled }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};

    &:hover {
      background-color: ${selected
        ? (theme.palette.primary as any)[100]
        : theme.palette.grey[100]};
      cursor: pointer;
    }

    ${selected &&
    css`
      background-color: ${(theme.palette.primary as any)[100]};
      color: ${(theme.palette.primary as any)[600]};
    `}

    && {
      ${disabled
        ? css`
            cursor: default;
          `
        : ""}
    }
  `
);

export const ListItem: React.FC<ListItemProps> = ({
  status,
  mainText,
  rightText,
  selected,
  onSelect,
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={
        status === Status.Offline
          ? (t("Projects.labels.explorerOfflineItemWarning") as string)
          : ""
      }
    >
      <ListItemContainer
        onClick={status === Status.Offline ? undefined : onSelect}
        selected={selected}
        disabled={status === Status.Offline}
        data-testid={mainText}
      >
        <>
          <Box display="flex" alignItems="center">
            <StatusIndicator status={status} />
            <Typography pl={1} variant="p2">
              {mainText}
            </Typography>
          </Box>
          {rightText && (
            <Typography
              fontSize="12px"
              lineHeight="16px"
              fontWeight={600}
              color={theme.palette.grey[800]}
            >
              {rightText}
            </Typography>
          )}
        </>
      </ListItemContainer>
    </Tooltip>
  );
};
