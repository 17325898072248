import { Box, Grid, Typography } from "@mui/material";
import {
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { FormLabel } from "components/FormLabel";
import { useTranslation } from "react-i18next";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { VariationActionFormProps } from "../VariationActionView";
import { useObjectToInstructionOfVariationAction } from "./useObjectToInstructionOfVariationAction";
import { SendObjectionToInstructionOfVariationModal } from "./SendObjectionToInstructionOfVariationModal";
import { RecordObjectionToInstructionOfVariationModal } from "./RecordObjectionToInstructionOfVariationModal";

const dataValidators: DataValidators = {
  reasons: {
    validators: [ValidatorType.RequiredRichText],
  },
};

type FormDataType = {
  reasons: string;
  // attachments?: AttachmentInput[];
};

const defaultReasonsFIDIC2017RedBook =
  '<ul><li value="1"><span>the varied work was Unforeseeable having regard to the scope and nature of the Works described in the Specification</span></li><li value="2"><span>the Contractor cannot readily obtain the Goods required for the Variation</span></li><li value="3"><span>it will adversely affect the Contractor\'s ability to comply with health and safety obligations and/or protection of the environment</span></li></ul>';

const defaultReasonsFIDIC2017YellowBook =
  '<ul><li value="1"><span>the varied work was Unforeseeable having regard to the scope and nature of the Works described in the Employer\'s Requirements</span></li><li value="2"><span>the Contractor cannot readily obtain the Goods required for the Variation</span></li><li value="3"><span>it will adversely affect the Contractor\'s ability to comply with health and safety obligations and/or protection of the environment</span></li><li value="4"><span>it will have an adverse impact on the achievement of the Schedule of Performance Guarantees</span></li><li value="5"><span>it may adversely affect the Contractor\'s obligation to complete the Works so that they shall be fit for the purpose(s) for which they are intended.</span></li></ul>';

const defaultReasonsFIDIC2017WhiteBook =
  '<ul><li value="1"><span>Consultant does not possess the relevant skills or resources to carry out the Variation</span></li></ul><ul><li value="1"><span>Consultant considers that the variation will substantially change the extent or nature of the Services</span></li></ul>';

const defaultReasonsFIDIC99RedBook =
  '<ul><li value="1"><span>cannot readily obtain the Goods required for the Variation</span></li></ul>';

const defaultReasonsFIDIC99YellowBook =
  '<ul><li value="1"><span>cannot readily obtain the Goods required for the Variation</span></li><li value="2"><span>will reduce&nbsp;the safety or suitability of the Works</span></li><li value="3"><span>will have an adverse impact on the achievement of the Schedule of Guarantees</span></li></ul>';

const defaultFormData: FormDataType = {
  reasons: "",
  // attachments: [],
};

export const ObjectToInstructionOfVariationAction: React.FC<
  VariationActionFormProps
> = ({ apiRef, onClose }) => {
  const { t } = useTranslation();
  const { variation, contract } = useContext(VariationWidgetContext);

  const defaultReasonsText = useMemo(() => {
    if (contract.contractType.version === "2017") {
      if (contract.contractType.subType?.toLowerCase().includes("red")) {
        return defaultReasonsFIDIC2017RedBook;
      } else if (
        contract.contractType.subType?.toLowerCase().includes("yellow")
      ) {
        return defaultReasonsFIDIC2017YellowBook;
      } else if (
        contract.contractType.subType?.toLowerCase().includes("white")
      ) {
        return defaultReasonsFIDIC2017WhiteBook;
      }
    } else if (contract.contractType.version === "1999") {
      if (contract.contractType.subType?.toLowerCase().includes("red")) {
        return defaultReasonsFIDIC99RedBook;
      } else if (
        contract.contractType.subType?.toLowerCase().includes("yellow")
      ) {
        return defaultReasonsFIDIC99YellowBook;
      }
    }

    return "";
  }, [contract]);

  const [formData, setFormData] = useState<FormDataType>({
    ...defaultFormData,
    reasons: defaultReasonsText,
  });
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const { sendVariationObjection, recordVariationObjection, actionLoading } =
    useObjectToInstructionOfVariationAction();

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendVariationObjection = async () => {
    await sendVariationObjection({
      variables: {
        input: {
          variationId: variation?.id!,
          reasons: formData.reasons,
          // attachments: formData.attachments,
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordVariationObjection = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordVariationObjection({
      variables: {
        input: {
          variationId: variation?.id!,
          reasons: formData.reasons,
          // attachments: formData.attachments,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const handleTextFieldChange = (reasons: string) => {
    setFormData((curData) => ({
      ...curData,
      reasons,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { reasons: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const result = validateData(formData, dataValidators);

    if (result.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(result.errors);
    }

    return result.valid;
  }, []);

  const isFormValid = useMemo(() => !!formData.reasons, [formData]);

  // const handleAttachmentsUpdated = async (
  //   attachmentsUpdated: AttachmentInput[]
  // ) => {
  //   setFormData((crtFormData) => ({
  //     ...crtFormData,
  //     attachments: attachmentsUpdated,
  //   }));
  // };

  // const {
  //   allAttachments,
  //   addAttachments,
  //   removeAttachment,
  //   updateAttachment,
  //   downloadAttachment,
  //   unloadLocalAttachments,
  // } = useAttachments([], handleAttachmentsUpdated); // always starting from empty attachments.

  // const handleBeforeAbort = useCallback(() => {
  //   // consider flow aborted. Remove all allegedly added attachments from the server
  //   unloadLocalAttachments();
  // }, [unloadLocalAttachments]);

  // const {
  //   imageAttachmentPreviewModalVisible,
  //   imagePreviewData,
  //   previewUrl,
  //   handleAttachmentClick,
  //   closeModal: closeImagePreviewModal,
  // } = useImagePreviewModal(downloadAttachment);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
      onBeforeAbort: () => {},
      // onBeforeAbort: handleBeforeAbort,
    }),
    [formData, validateForm]
  );

  return (
    <>
      {/* <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      /> */}
      {isFormValid && sendModalVisibility && (
        <SendObjectionToInstructionOfVariationModal
          open={sendModalVisibility}
          reasons={formData.reasons}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handleSendVariationObjection}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordObjectionToInstructionOfVariationModal
          open={recordModalVisibility}
          reasons={formData.reasons}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handleRecordVariationObjection}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.reasons")} required />
            <RichTextArea
              content={formData.reasons}
              error={!!formDataErrors.reasons}
              onChange={handleTextFieldChange}
            />
            {!!formDataErrors.reasons && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.reasons}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <Attachments
              editMode
              showTabs={false}
              timezone={}
              dencity={AttachmentsDencity.Compact}
              defaultLayout={AttachmentsLayout.List}
              attachments={allAttachments}
              onAttachmentsAdd={addAttachments}
              onAttachmentRemove={removeAttachment}
              onAttachmentUpdate={updateAttachment}
              onAttachmentClick={handleAttachmentClick}
            />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};
