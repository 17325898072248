import {
  ClaimItem,
  CompEventItem,
  InstructionItem,
  VariationActionType,
  VariationItem,
  VariationStatus,
} from "generated/graphql";
import { variationStatusToNextStepWordingMap } from "./VariationWidget/VariationWidget.constants";

export const isVariation = (
  productItem: CompEventItem | ClaimItem | VariationItem
): productItem is VariationItem =>
  !!((productItem as VariationItem).__typename === "VariationItem");

export const getNextStepText = (variation?: VariationItem) => {
  if (variation?.nextStep) {
    if (
      variation.status === VariationStatus.Agreed ||
      variation.status === VariationStatus.Rejected
    ) {
      if (
        variation.lastAction.actionType ===
        VariationActionType.NotifyErrorFoundAgreement
      ) {
        if (
          variation.lastAction.party.description
            .toLowerCase()
            .includes("engineer") ||
          variation.lastAction.party.description
            .toLowerCase()
            .includes("client")
        ) {
          // engineer notified error found in Agreement
          return variationStatusToNextStepWordingMap.ReviseAgreement;
        } else {
          // contractor / employer notified error found in Agreement
          return variationStatusToNextStepWordingMap.ResponseToErrorInAgreement;
        }
      } else if (
        variation.lastAction.actionType ===
        VariationActionType.NotifyErrorFoundDetermination
      ) {
        if (
          variation.lastAction.party.description
            .toLowerCase()
            .includes("engineer")
        ) {
          // engineer notified error found in Determination
          return variationStatusToNextStepWordingMap.ReviseDetermination;
        } else {
          // contractor / employer notified error found in Determination
          return variationStatusToNextStepWordingMap.ResponseToErrorInDetermination;
        }
      }
    }
    return variationStatusToNextStepWordingMap[variation.nextStep.actionType];
  }

  return null;
};

export const getNextStepDueDate = (
  variation?: VariationItem,
  instructionItem?: InstructionItem
) => {
  return variation
    ? variation.nextStep?.dueDate
    : instructionItem?.notifyDueDate;
};
