import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const AddIcon: React.FC<BaseIconProps> = ({
  height = "20",
  width = "20",
  color,
}) => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9H5V11H9V15H11V11H15V9H11V5H9V9Z"
        fill={color || theme.palette.common.black}
      />
    </svg>
  );
};
