import { Box, BoxProps } from "@mui/material";
import React from "react";
import { AttachmentsTabs } from "../AttachmentsTabs";
import { Attachment } from "generated/graphql";
import { FileType } from "../../Attachments.decl";
import { GridFour, List } from "phosphor-react";
import {
  StyledToggleBtn,
  StyledToggleButtonGroup,
} from "./AttachmentsHeader.styled";

export enum AttachmentsLayout {
  List = "list",
  Grid = "grid",
}

type AttachmentsHeaderProps = {
  showTabs: boolean;
  attachments: (Attachment | FileType)[];
  attachmentsLayout: AttachmentsLayout;
  onFilterTabChange: (selectedTab: string) => void;
  onLayoutChange: (selectedLayout: AttachmentsLayout) => void;
} & BoxProps;

export const AttachmentsHeader: React.FC<AttachmentsHeaderProps> = ({
  showTabs,
  attachments,
  attachmentsLayout,
  onFilterTabChange,
  onLayoutChange,
  ...containerProps
}) => {
  const handleLayoutChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    value: AttachmentsLayout | null
  ) => {
    if (value) {
      onLayoutChange(value);
    }
  };

  return (
    <Box
      mt={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...containerProps}
    >
      {showTabs && (
        <AttachmentsTabs
          attachments={attachments}
          onSelectedTabChange={onFilterTabChange}
        />
      )}

      <Box ml="auto">
        <StyledToggleButtonGroup
          exclusive
          onChange={handleLayoutChange}
          value={attachmentsLayout}
          aria-label="layout toggle button group"
        >
          <StyledToggleBtn value={AttachmentsLayout.List} size="medium">
            <List size={16} />
          </StyledToggleBtn>
          <StyledToggleBtn value={AttachmentsLayout.Grid} size="medium">
            <GridFour size={16} />
          </StyledToggleBtn>
        </StyledToggleButtonGroup>
      </Box>
    </Box>
  );
};
