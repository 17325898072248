import { gql } from "@apollo/client";

export const itemStatusCollectionQuery = gql`
  query itemStatusCollection($id: ID!) {
    itemStatusCollection(id: $id) {
      id
      name
      isDefault
      productId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
      statusOptions {
        items {
          id
          displayOrder
          description
          equatesToRemoved
          style
          statusCollectionId
          status
          creatorId
          creator {
            id
            firstname
            surname
          }
          dateCreated
          # optionReasons: ItemStatusOptionReasonList!
        }
      }
    }
  }
`;
