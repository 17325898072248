import { useTranslation } from "react-i18next";
import { mockedPlaceholdersData } from "../NewTemplate/NewTemplate.constants";
import { ReactLiquid } from "../NewTemplate/ReactLiquid";

export type DocumentTemplatePreviewProps = {
  documentTemplateHTML: string;
};

export const DocumentTemplatePreview: React.FC<
  DocumentTemplatePreviewProps
> = ({ documentTemplateHTML }) => {
  const { t } = useTranslation();

  return (
    <ReactLiquid
      template={documentTemplateHTML}
      data={mockedPlaceholdersData}
      emptyPreviewText={t("AdminConsole.Templates.labels.previewPlaceholder")}
    />
  );
};
