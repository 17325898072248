import { gql } from "@apollo/client";
import { instructionItemFields } from "graphql/fragments/instructionItem.fragment";

export const sendInstructionItemMutation = gql`
  ${instructionItemFields}

  mutation sendInstructionItem(
    $input: SendInstructionItemInput!
    $draftId: ID
  ) {
    sendInstructionItem(input: $input, draftId: $draftId) {
      ...InstructionItemFields
    }
  }
`;
