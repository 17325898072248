import { Box, css, styled, useTheme } from "@mui/material";
import {
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { ApproversAvatars } from "./ApproversAvatars";
import { LevelLabel } from "./LevelLabel";

export type AuthorizationProgressLevelProps = {
  level: AuthorizationWorkflowLevel;
  positionLeft: string;
  authAudit?: AuthorizationWorkflowAudit;
};

const ArrowDown = styled(Box)(
  ({ theme }) => css`
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid ${theme.palette.grey[300]};
    border-radius: 1px;
  `
);

export const AuthorizationProgressLevel: React.FC<
  AuthorizationProgressLevelProps
> = ({ level, positionLeft, authAudit }) => {
  const theme = useTheme();

  return (
    <Box position="absolute" left={positionLeft} top={theme.spacing(3)}>
      <ApproversAvatars level={level} authAudit={authAudit} />
      <ArrowDown />
      <LevelLabel levelNo={level.sequence} />
    </Box>
  );
};
