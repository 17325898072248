import {
  DailyDiaryItem,
  ImportDailyDiaryHseRecordsMutation,
  ImportDailyDiaryHseRecordsMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useHSERecords } from "./useHSERecords";
import { importDailyDiaryHSERecordsMutation } from "./HSESection.query";

export const useImportHSERecordsModal = (dailyDiary?: DailyDiaryItem) => {
  const { t } = useTranslation();

  const { hseRecords, hseRecordsLoading } = useHSERecords(dailyDiary);

  const [importRecords, { loading: importLoading }] = useGraphMutation<
    ImportDailyDiaryHseRecordsMutation,
    ImportDailyDiaryHseRecordsMutationVariables
  >(
    importDailyDiaryHSERecordsMutation,
    {},
    t("Projects.DailyDiaries.ImportRecordsModal.importSuccess")
  );

  return {
    hseRecords,
    loading: hseRecordsLoading || importLoading,
    importLoading,
    hseRecordsLoading,
    importRecords,
  };
};
