import { gql } from "@apollo/client";
import { lookupOptionFields } from "graphql/queries/lookupOption.query";

export const editLookupOptionMutation = gql`
  ${lookupOptionFields}
  mutation editLookupOption($input: EditLookupOptionInput!) {
    editLookupOption(input: $input) {
      ...LookupOptionFields
    }
  }
`;
