import { ChangeLogItem, Notification } from "generated/graphql";

// TODO: remove when coming from BE - right now it's taken from types that implement EventDetails
export enum EventType {
  // Authorizations
  ActionAuthorized = "ActionAuthorized",
  ActionPrevented = "ActionPrevented",
  AuthorizationRequired = "AuthorizationRequired",
  // Attachments
  AttachmentAdded = "AttachmentAdded",
  AttachmentDescriptionAdded = "AttachmentDescriptionAdded",
  AttachmentDescriptionEdited = "AttachmentDescriptionEdited",
  AttachmentRemoved = "AttachmentRemoved",
  // Claims
  ClaimAgreementNoticeRecorded = "ClaimAgreementNoticeRecorded",
  ClaimAgreementNoticeSent = "ClaimAgreementNoticeSent",
  ClaimDetailsResponseRecorded = "ClaimDetailsResponseRecorded",
  ClaimDetailsResponseSent = "ClaimDetailsResponseSent",
  ClaimDeterminationNoticeRecorded = "ClaimDeterminationNoticeRecorded",
  ClaimDeterminationNoticeSent = "ClaimDeterminationNoticeSent",
  ClaimErrorAgreementDisagreementRecorded = "ClaimErrorAgreementDisagreementRecorded",
  ClaimErrorAgreementDisagreementSent = "ClaimErrorAgreementDisagreementSent",
  ClaimErrorAgreementNoticeRecorded = "ClaimErrorAgreementNoticeRecorded",
  ClaimErrorAgreementNoticeSent = "ClaimErrorAgreementNoticeSent",
  ClaimErrorDeterminationDisagreementRecorded = "ClaimErrorDeterminationDisagreementRecorded",
  ClaimErrorDeterminationDisagreementSent = "ClaimErrorDeterminationDisagreementSent",
  ClaimErrorDeterminationNoticeRecorded = "ClaimErrorDeterminationNoticeRecorded",
  ClaimErrorDeterminationNoticeSent = "ClaimErrorDeterminationNoticeSent",
  ClaimFinalClaimDetailsRecorded = "ClaimFinalClaimDetailsRecorded",
  ClaimFinalClaimDetailsSent = "ClaimFinalClaimDetailsSent",
  ClaimFurtherParticularsRequestRecorded = "ClaimFurtherParticularsRequestRecorded",
  ClaimFurtherParticularsRequestSent = "ClaimFurtherParticularsRequestSent",
  ClaimFurtherParticularsSubmissionRecorded = "ClaimFurtherParticularsSubmissionRecorded",
  ClaimFurtherParticularsSubmissionSent = "ClaimFurtherParticularsSubmissionSent",
  ClaimInterimClaimDetailsRecorded = "ClaimInterimClaimDetailsRecorded",
  ClaimInterimClaimDetailsSent = "ClaimInterimClaimDetailsSent",
  ClaimNoAgreementNoticeRecorded = "ClaimNoAgreementNoticeRecorded",
  ClaimNoAgreementNoticeSent = "ClaimNoAgreementNoticeSent",
  ClaimNoticeInvalidNoticeRecorded = "ClaimNoticeInvalidNoticeRecorded",
  ClaimNoticeInvalidNoticeSent = "ClaimNoticeInvalidNoticeSent",
  ClaimNoticeLapsedNoticeRecorded = "ClaimNoticeLapsedNoticeRecorded",
  ClaimNoticeLapsedNoticeSent = "ClaimNoticeLapsedNoticeSent",
  ClaimNoticeRecorded = "ClaimNoticeRecorded",
  ClaimNoticeSent = "ClaimNoticeSent",
  // Variations
  VariationProposalForProposedVariationSent = "VariationProposalForProposedVariationSent",
  VariationProposalForProposedVariationRecorded = "VariationProposalForProposedVariationRecorded",
  VariationObjectionProposedVariationSent = "VariationObjectionProposedVariationSent",
  VariationObjectionProposedVariationRecorded = "VariationObjectionProposedVariationRecorded",
  VariationNoAgreementNoticeSent = "VariationNoAgreementNoticeSent",
  VariationNoAgreementNoticeRecorded = "VariationNoAgreementNoticeRecorded",
  VariationRevisedProposalForProposedVariationRequestSent = "VariationRevisedProposalForProposedVariationRequestSent",
  VariationRevisedProposalForProposedVariationRequestRecorded = "VariationRevisedProposalForProposedVariationRequestRecorded",
  VariationAgreementNoticeSent = "VariationAgreementNoticeSent",
  VariationAgreementNoticeRecorded = "VariationAgreementNoticeRecorded",
  VariationProposalConsentForProposedVariationSent = "VariationProposalConsentForProposedVariationSent",
  VariationProposalConsentForProposedVariationRecorded = "VariationProposalConsentForProposedVariationRecorded",
  VariationProposalAbandonmentSent = "VariationProposalAbandonmentSent",
  VariationProposalAbandonmentRecorded = "VariationProposalAbandonmentRecorded",
  VariationDeterminationNoticeSent = "VariationDeterminationNoticeSent",
  VariationDeterminationNoticeRecorded = "VariationDeterminationNoticeRecorded",
  VariationErrorAgreementNoticeSent = "VariationErrorAgreementNoticeSent",
  VariationErrorAgreementNoticeRecorded = "VariationErrorAgreementNoticeRecorded",
  VariationErrorAgreementDisagreementSent = "VariationErrorAgreementDisagreementSent",
  VariationErrorAgreementDisagreementRecorded = "VariationErrorAgreementDisagreementRecorded",
  VariationErrorDeterminationNoticeSent = "VariationErrorDeterminationNoticeSent",
  VariationErrorDeterminationNoticeRecorded = "VariationErrorDeterminationNoticeRecorded",
  VariationErrorDeterminationDisagreementSent = "VariationErrorDeterminationDisagreementSent",
  VariationErrorDeterminationDisagreementRecorded = "VariationErrorDeterminationDisagreementRecorded",
  VariationFurtherParticularsRequestSent = "VariationFurtherParticularsRequestSent",
  VariationFurtherParticularsRequestRecorded = "VariationFurtherParticularsRequestRecorded",
  VariationFurtherParticularsSubmissionSent = "VariationFurtherParticularsSubmissionSent",
  VariationFurtherParticularsSubmissionRecorded = "VariationFurtherParticularsSubmissionRecorded",
  VariationProposalForVariationSent = "VariationProposalForVariationSent",
  VariationProposalForVariationRecorded = "VariationProposalForVariationRecorded",
  VariationRevocationSent = "VariationRevocationSent",
  VariationRevocationRecorded = "VariationRevocationRecorded",
  VariationObjectionVariationSent = "VariationObjectionVariationSent",
  VariationObjectionVariationRecorded = "VariationObjectionVariationRecorded",
  VariationConfirmationSent = "VariationConfirmationSent",
  VariationConfirmationRecorded = "VariationConfirmationRecorded",
  VariationInstructionCommenceWorkSent = "VariationInstructionCommenceWorkSent",
  VariationInstructionCommenceWorkRecorded = "VariationInstructionCommenceWorkRecorded",
  // CompEvents
  CompEventNoticeSent = "CompEventNoticeSent",
  CompEventNoticeRecorded = "CompEventNoticeRecorded",
  CompEventNoticeOfSilenceCompEventNoticeSent = "CompEventNoticeOfSilenceCompEventNoticeSent",
  CompEventNoticeOfSilenceCompEventNoticeRecorded = "CompEventNoticeOfSilenceCompEventNoticeRecorded",
  CompEventNoticeRequestQuotationSent = "CompEventNoticeRequestQuotationSent",
  CompEventNoticeRequestQuotationRecorded = "CompEventNoticeRequestQuotationRecorded",
  CompEventNoticeProposedInstructionSent = "CompEventNoticeProposedInstructionSent",
  CompEventNoticeProposedInstructionRecorded = "CompEventNoticeProposedInstructionRecorded",
  CompEventConfirmationSent = "CompEventConfirmationSent",
  CompEventConfirmationRecorded = "CompEventConfirmationRecorded",
  CompEventDeemingConfirmed = "CompEventDeemingConfirmed",
  CompEventQuotationSent = "CompEventQuotationSent",
  CompEventQuotationRecorded = "CompEventQuotationRecorded",
  CompEventNoticeOfSilenceQuotationSent = "CompEventNoticeOfSilenceQuotationSent",
  CompEventNoticeOfSilenceQuotationRecorded = "CompEventNoticeOfSilenceQuotationRecorded",
  CompEventQuotationAcceptanceSent = "CompEventQuotationAcceptanceSent",
  CompEventQuotationAcceptanceRecorded = "CompEventQuotationAcceptanceRecorded",
  CompEventDeemingQuotationAccepted = "CompEventDeemingQuotationAccepted",
  CompEventQuotationRevisionRequestSent = "CompEventQuotationRevisionRequestSent",
  CompEventQuotationRevisionRequestRecorded = "CompEventQuotationRevisionRequestRecorded",
  CompEventOwnAssessmentNoticeSent = "CompEventOwnAssessmentNoticeSent",
  CompEventOwnAssessmentNoticeRecorded = "CompEventOwnAssessmentNoticeRecorded",
  CompEventNoticeOfSilenceOwnAssessmentSent = "CompEventNoticeOfSilenceOwnAssessmentSent",
  CompEventNoticeOfSilenceOwnAssessmentRecorded = "CompEventNoticeOfSilenceOwnAssessmentRecorded",
  CompEventOwnAssessmentSent = "CompEventOwnAssessmentSent",
  CompEventOwnAssessmentRecorded = "CompEventOwnAssessmentRecorded",
  CompEventRejectionSent = "CompEventRejectionSent",
  CompEventRejectionRecorded = "CompEventRejectionRecorded",
  CompEventAbandonInstructionSent = "CompEventAbandonInstructionSent",
  CompEventAbandonInstructionRecorded = "CompEventAbandonInstructionRecorded",
  CompEventRequestForMoreTimeSent = "CompEventRequestForMoreTimeSent",
  CompEventRequestForMoreTimeRecorded = "CompEventRequestForMoreTimeRecorded",
  CompEventDenyingOfMoreTimeSent = "CompEventDenyingOfMoreTimeSent",
  CompEventDenyingOfMoreTimeRecorded = "CompEventDenyingOfMoreTimeRecorded",
  CompEventGrantingOfMoreTimeSent = "CompEventGrantingOfMoreTimeSent",
  CompEventGrantingOfMoreTimeRecorded = "CompEventGrantingOfMoreTimeRecorded",
  // Events
  EventAdded = "EventAdded",
  EventEdited = "EventEdited",
  EventOwnerChanged = "EventOwnerChanged",
  EventStatusChanged = "EventStatusChanged",
  // Early warnings
  EwEdited = "EwEdited",
  EwOwnerChanged = "EwOwnerChanged",
  EwRecorded = "EwRecorded",
  EwSent = "EwSent",
  EwStatusChanged = "EwStatusChanged",
  // Instructions
  InstructionEdited = "InstructionEdited",
  InstructionOwnerChanged = "InstructionOwnerChanged",
  InstructionRecorded = "InstructionRecorded",
  InstructionSent = "InstructionSent",
  InstructionStatusChanged = "InstructionStatusChanged",
  // Risks
  RiskAdded = "RiskAdded",
  RiskEdited = "RiskEdited",
  RiskOwnerChanged = "RiskOwnerChanged",
  RiskStatusChanged = "RiskStatusChanged",
  // Comments
  CommentReplied = "CommentReplied",
  NewCommentAdded = "NewCommentAdded",
  UserMentioned = "UserMentioned",
}

export const getEventType = (event: Notification | ChangeLogItem) => {
  const eventDetailsTypename = (event.details as any).__typename as string;
  const lastDetailsWordIndex = eventDetailsTypename.lastIndexOf("Details");
  if (lastDetailsWordIndex < 0) {
    // this shouldn't be the case though, as all contain the word Details at the end
    return eventDetailsTypename;
  }

  return eventDetailsTypename.substring(0, lastDetailsWordIndex);
};
