import { DailyDiaryGeneralRecord } from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import { TFunction } from "i18next";

export const toRecordJSON = (record: DailyDiaryGeneralRecord, t: TFunction) => {
  return {
    [t("common.labels.description")]: record.description,
    [t("common.labels.createdBy")]: getUserName(record.creator),
  };
};
