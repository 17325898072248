import {
  ChangeDocumentTemplateStatusMutation,
  ChangeDocumentTemplateStatusMutationVariables,
} from "generated/graphql";
import { changeDocumentTemplateStatusMutation } from "graphql/mutations/changeDocumentTemplateStatus";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useDocumentTemplates } from "./useDocumentTemplates";

export const useTemplates = (fetchAll?: boolean) => {
  const { t } = useTranslation();

  const {
    documentTemplates,
    fetchDocumentTemplatesLoading,
    loadMore,
    hasMore,
    refetchDocumentTemplates,
  } = useDocumentTemplates(fetchAll);

  const [
    changeDocumentTemplateStatus,
    { loading: changeDocumentTemplateStatusLoading },
  ] = useGraphMutation<
    ChangeDocumentTemplateStatusMutation,
    ChangeDocumentTemplateStatusMutationVariables
  >(
    changeDocumentTemplateStatusMutation,
    {
      update: () => {
        refetchDocumentTemplates();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Templates.labels.template"),
    })
  );

  return {
    documentTemplates,
    hasMore,
    loadMore,
    fetchDocumentTemplatesLoading,
    changeDocumentTemplateStatus,
    changeDocumentTemplateStatusLoading,
  };
};
