import { Box, Grid, Typography } from "@mui/material";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { SendCompEventQuotationRevisionRequestInput } from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import { useContext, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecordRequestRevisedQuotationCEAction } from "./RecordRequestRevisedQuotationCEAction";
import { SendRequestRevisedQuotationCEAction } from "./SendRequestRevisedQuotationCEAction";
import { useRequestRevisedQuotationCEAction } from "./useRequestRevisedQuotationCEAction";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";

const dataValidators: DataValidators = {
  reason: {
    validators: [ValidatorType.RequiredRichText],
  },
};

type FormDataType = Pick<SendCompEventQuotationRevisionRequestInput, "reason">;

const defaultFormData = {
  reason: "",
};

export const RequestRevisedQuotationCEAction: React.FC<CEActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { compEvent } = useContext(CompEventWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendCEQuotationRevisionRequest,
    recordCEQuotationRevisionRequest,
    actionLoading,
  } = useRequestRevisedQuotationCEAction();

  const handleTextFieldChange = (reason: string) => {
    setFormData((curData) => ({
      ...curData,
      reason,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { reason: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const isFormValid = useMemo(() => {
    const result = validateData(formData, dataValidators);

    return result.valid;
  }, [formData]);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };
  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCEOwnAssessmentNotice = async () => {
    await sendCEQuotationRevisionRequest({
      variables: {
        input: {
          compEventId: compEvent!.id,
          reason: formData.reason,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEOwnAssessmentNotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCEQuotationRevisionRequest({
      variables: {
        input: {
          compEventId: compEvent!.id,
          reason: formData.reason,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  return (
    <>
      {formData.reason && sendModalVisibility && (
        <SendRequestRevisedQuotationCEAction
          open={sendModalVisibility}
          reason={formData.reason}
          onPrimaryClick={handleSendCEOwnAssessmentNotice}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {formData.reason && recordModalVisibility && (
        <RecordRequestRevisedQuotationCEAction
          open={recordModalVisibility}
          reason={formData.reason}
          onPrimaryClick={handleRecordCEOwnAssessmentNotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel
              label={t("Projects.CompEvents.revisedQuotationReason")}
              required
            />
            <RichTextArea
              content={formData.reason}
              error={!!formDataErrors.reason}
              onChange={handleTextFieldChange}
            />
            {!!formDataErrors.reason && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.reason}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
