import { gql } from "@apollo/client";

export const addProductInstanceMutation = gql`
  mutation addProductInstance($input: AddProductInstanceInput!) {
    addProductInstance(input: $input) {
      id
      description
    }
  }
`;
