import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const dailyDiaryDelayRecordLiteFields = gql`
  ${creatorFields}

  fragment DailyDiaryDelayRecordLiteFields on DailyDiaryDelayRecord {
    id
    description
    delayTypeOptionId
    delayTypeLookupId
    areaOptionId
    areaLookupId
    disciplineOptionId
    disciplineLookupId
    shiftOptionId
    shiftLookupId
    teamOptionId
    teamLookupId
    duration
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
