import { Typography, TypographyProps } from "@mui/material";

export type FormErrorLabelProps = {
  dataTestId: string;
  errorMessage: string;
} & TypographyProps;

export const FormErrorLabel: React.FC<FormErrorLabelProps> = ({
  dataTestId,
  errorMessage,
  mt = 0.5,
  ...restProps
}) => {
  return (
    <Typography
      variant="caption"
      color="error"
      data-testid={dataTestId}
      mt={mt}
      {...restProps}
    >
      {errorMessage}
    </Typography>
  );
};
