import { useProjectLite } from "../containers/AdminConsole/hooks/useProjectLite";
import { isNECContractType } from "containers/Projects/Projects.utils";
import { useProductInstanceSchema } from "./useProductInstanceSchema";
import { useContractLite } from "./useContractLite";

export const useProductItemContext = (
  projectId: string,
  contractId: string,
  productInstanceId: string
) => {
  const { contractLite, contractLiteLoading } = useContractLite(contractId);
  const { projectDataLite, loading: projectDataLoading } =
    useProjectLite(projectId);
  const { productInstanceData, loading: productInstanceDataLoading } =
    useProductInstanceSchema(productInstanceId);

  return {
    contractDataLite: contractLite,
    contractDataLoading: contractLiteLoading,
    isNECContractType: isNECContractType(contractLite),
    contractTypeId: contractLite?.contractTypeId,
    projectDataLite,
    projectDataLoading,
    productInstanceData,
    productInstanceDataLoading,
  };
};
