import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { ContractType, ContractTypeStatus } from "generated/graphql";
import { statusComparatorFunction } from "helpers/dataGrid.helpers";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useColumns = (
  contractTypeOptions: StatusOption<ContractTypeStatus>[]
): GridColDef<ContractType>[] => {
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      [
        {
          field: "description",
          headerName: t("AdminConsole.ContractTypes.labels.contractType"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "subType",
          headerName: t("AdminConsole.ContractTypes.labels.contractSubtype"),
          flex: 0.15,
          width: 120,
          resizable: true,
        },
        {
          field: "version",
          headerName: t("AdminConsole.ContractTypes.labels.version"),
          flex: 0.15,
          width: 120,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, ContractType, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={contractTypeOptions}
                disabled
              />
            );
          },
        },
      ] as GridColDef<ContractType>[],
    [t, contractTypeOptions]
  );

  return columns;
};
