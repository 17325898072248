import { gql } from "@apollo/client";
import { dailyDiaryDelayRecordLiteFields } from "graphql/fragments/dailyDiaryDelayRecordLite.fragment";

export const addDailyDiaryDelayRecordMutation = gql`
  ${dailyDiaryDelayRecordLiteFields}

  mutation addDailyDiaryDelayRecord($input: AddDailyDiaryDelayRecordInput!) {
    addDailyDiaryDelayRecord(input: $input) {
      ...DailyDiaryDelayRecordLiteFields
    }
  }
`;
