import { Box, Grid } from "@mui/material";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { SendCompEventRequestForMoreTimeInput } from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import {
  ChangeEventHandler,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useRequestForMoreTimeCEAction } from "./useRequestForMoreTimeCEAction";
import { SendCERequestForMoreTime } from "./SendCERequestForMoreTime";
import { RecordCERequestForMoreTime } from "./RecordCERequestForMoreTime";
import moment from "moment";
import { dailyDiaryDateFormat } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import { ResultingDueDateField } from "./ResultingDueDateField";
import { DaysTextField } from "./DaysTextField";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";

const dataValidators: DataValidators = {
  requestedDays: {
    validators: [ValidatorType.PositiveNumber],
  },
};

type FormDataType = Pick<
  SendCompEventRequestForMoreTimeInput,
  "remarks" | "requestedDays"
>;

const defaultFormData: FormDataType = {
  remarks: "",
  requestedDays: "" as any,
};

export const RequestForMoreTimeCEAction: React.FC<CEActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { compEvent } = useContext(CompEventWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendCERequestForMoreTime,
    recordCERequestForMoreTime,
    actionLoading,
  } = useRequestForMoreTimeCEAction();

  const handleRemarksChange = (remarks: string) => {
    setFormData((curData) => ({
      ...curData,
      remarks,
    }));
  };

  const handleRequestedDaysChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setFormData((curData) => ({
      ...curData,
      requestedDays: event.target.value as any,
    }));

    const validationResult = validateData(
      {
        ...formData,
        requestedDays: Number(event.target.value),
      },
      dataValidators
    );

    if (!validationResult.valid) {
      setFormDataErrors(validationResult.errors);
    } else {
      setFormDataErrors({});
    }
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const validationResult = validateData(formData, dataValidators);

    if (!validationResult.valid) {
      setFormDataErrors(validationResult.errors);
    } else {
      setFormDataErrors({});
    }

    return validationResult.valid;
  }, []);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };
  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCERequestForMoreTime = async () => {
    await sendCERequestForMoreTime({
      variables: {
        input: {
          compEventId: compEvent!.id,
          remarks: formData.remarks,
          requestedDays: formData.requestedDays,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCERequestForMoreTime = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCERequestForMoreTime({
      variables: {
        input: {
          compEventId: compEvent!.id,
          remarks: formData.remarks,
          requestedDays: formData.requestedDays,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [formData, validateForm]
  );

  const computedDueDate = useMemo(() => {
    const crtDueDate = compEvent?.nextStep?.dueDate!;
    if (formData.requestedDays < 0) {
      return t("common.labels.n/a");
    }

    return moment(crtDueDate)
      .add(formData.requestedDays ?? 0, "days")
      .format(dailyDiaryDateFormat);
  }, [formData, compEvent, t]);

  return (
    <>
      {formData.requestedDays && sendModalVisibility ? (
        <SendCERequestForMoreTime
          open={sendModalVisibility}
          remarks={formData.remarks}
          requestedDays={formData.requestedDays}
          onPrimaryClick={handleSendCERequestForMoreTime}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      ) : null}
      {formData.requestedDays && recordModalVisibility ? (
        <RecordCERequestForMoreTime
          open={recordModalVisibility}
          remarks={formData.remarks}
          requestedDays={formData.requestedDays}
          onPrimaryClick={handleRecordCERequestForMoreTime}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      ) : null}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={9} pr={2.5}>
            <FormLabel
              label={t("Projects.CompEvents.requestForMoreTimeLabel")}
              required
            />
            <DaysTextField
              name="requestedDays"
              value={formData.requestedDays}
              onChange={handleRequestedDaysChange}
              placeholder={t(
                "Projects.CompEvents.requestForMoreTimePlaceholder"
              )}
              error={!!formDataErrors.requestedDays}
              helperText={formDataErrors.requestedDays}
            />
          </Grid>
          <ResultingDueDateField dueDate={computedDueDate} />
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.remarks")} />
            <RichTextArea
              content={formData.remarks ?? ""}
              onChange={handleRemarksChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
