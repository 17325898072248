import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  ClaimActionType,
  ClaimInterimClaimDetailsPrompt,
} from "generated/graphql";
import { DetailedClaimAgreementDeterminationSummary } from "../components/DetailedClaimAgreementDeterminationSummary";
import { RecordAction } from "../components/RecordAction";
import { useRecordInterimDetailedClaimAction } from "./useRecordInterimDetailedClaimAction";

export type RecordInterimDetailedClaimActionProps = {
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Pick<ClaimInterimClaimDetailsPrompt, "draftDetailedClaims"> &
  Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordInterimDetailedClaimAction: React.FC<
  RecordInterimDetailedClaimActionProps
> = ({ draftDetailedClaims, ...restDialogProps }) => {
  const { claimPreview, contract } = useRecordInterimDetailedClaimAction(
    draftDetailedClaims[0]
  );
  return (
    <RecordAction
      actionType={ClaimActionType.SubmitInterimDetailedClaim}
      claimPreview={claimPreview}
      summaryEl={
        <DetailedClaimAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftDetailedClaims}
          type="DetailedClaim"
        />
      }
      {...restDialogProps}
    />
  );
};
