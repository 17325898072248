import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";
import { useSendDisagreementRegardingNoticeOfErrorInAgreementModal } from "./useSendDisagreementRegardingNoticeOfErrorInAgreementModal";

export type SendDisagreementRegardingNoticeOfErrorInAgreementModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendDisagreementRegardingNoticeOfErrorInAgreementModal: React.FC<
  SendDisagreementRegardingNoticeOfErrorInAgreementModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { claimPreview, contract } =
    useSendDisagreementRegardingNoticeOfErrorInAgreementModal(remarks);
  const { t } = useTranslation();

  return (
    <>
      <SendAction
        claimPreview={claimPreview}
        actionType={ClaimActionType.DisagreeWithNoticeErrorAgreement}
        summaryEl={
          <ReasonsAttachmentsSummary
            text={remarks}
            label={t("common.labels.remarks")}
            attachments={attachments}
            contractTimezone={contract.timeZone}
          />
        }
        {...restDialogProps}
      />
    </>
  );
};
