import { Box, Button, Typography, useTheme } from "@mui/material";
import { ItemTag, ProductTypeExtra } from "components/ItemTag";
import { dateISOFormat } from "constants/constants";
import { ClaimItem, CompEventItem, ProductType } from "generated/graphql";
import moment from "moment";
import { CaretRight } from "phosphor-react";
import { useMemo } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NewAppPaths } from "helpers/paths/paths";

export type WidgetEwAwSectionProps = {
  productItem: CompEventItem | ClaimItem;
  contractTimezone: string;
  isFIDIC99RedYellow?: boolean;
};

const isCompEventType = (
  productItem: CompEventItem | ClaimItem
): productItem is CompEventItem =>
  !!((productItem as CompEventItem).__typename === "CompEventItem");

// EarlyWarning / AdvanceWarning section for CE/Claim widgets
export const WidgetEwAwSection: React.FC<WidgetEwAwSectionProps> = ({
  productItem,
  contractTimezone,
  isFIDIC99RedYellow,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isCompEvent = isCompEventType(productItem);

  const transComponentsConfig = useMemo(
    () => ({
      bold: <Typography variant="p2" fontWeight={600} />,
    }),
    []
  );

  const earlyWarningAdvanceWarning =
    (productItem as CompEventItem).earlyWarning ??
    (productItem as ClaimItem).advanceWarning;
  const earlyWarningAdvanceWarningId =
    (productItem as CompEventItem).earlyWarningId! ??
    (productItem as ClaimItem).advanceWarningId!;

  const handleEWClick = () => {
    navigate(
      NewAppPaths.authorized.Projects.children.EarlyWarningDetails.pathConstructor(
        productItem.productInstanceId,
        earlyWarningAdvanceWarningId,
        ProductType.EarlyWarnings
      )
    );
  };

  return earlyWarningAdvanceWarning ? (
    <Box display="flex" width="100%">
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <ItemTag
            type={
              isCompEvent || isFIDIC99RedYellow
                ? ProductType.EarlyWarnings
                : ProductTypeExtra.AdvanceWarning
            }
          />
          <Typography variant="p2" color="grey.800" fontWeight={600} ml={0.5}>
            {earlyWarningAdvanceWarning.number}
          </Typography>
        </Box>
        <Typography variant="p2" mt={1} color="grey.800">
          <Trans
            i18nKey={`Projects.${isCompEvent ? "CompEvents" : "Claims"}.${
              isCompEvent ? "ewSent" : "awSent"
            }`}
            values={{
              date: moment(earlyWarningAdvanceWarning.dateSent)
                .tz(contractTimezone)
                .format(dateISOFormat),
            }}
            components={transComponentsConfig}
          />
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignSelf="stretch"
        ml="auto"
      >
        <Button sx={{ minWidth: "32px" }}>
          <CaretRight
            color={theme.palette.grey[800]}
            size={18}
            onClick={handleEWClick}
          />
        </Button>
      </Box>
    </Box>
  ) : null;
};
