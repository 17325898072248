import {
  DailyDiaryExtraConfig,
  DailyDiaryPreset,
  DailyDiaryPresetQuery,
  DailyDiaryPresetQueryVariables,
} from "generated/graphql";
import { dailyDiaryPresetQuery } from "graphql/queries/dailyDiaryPreset.query";
import { useEffect } from "react";
import { useGraphLazyQuery } from "./useGraphLazyQuery";
import { useProductInstance } from "./useProductInstance";

export enum DailyDiaryPresetFetchMode {
  ByPresetId = "ByPresetId",
  ByProductInstanceId = "ByProductInstanceId",
}

export const useDailyDiaryPreset = (
  mode: DailyDiaryPresetFetchMode,
  presetId?: string,
  productInstanceId?: string
) => {
  const [
    fetchDailyDiaryPreset,
    {
      data: dailyDiaryPreset,
      refetch: refetchDailyDiaryPreset,
      loading: dailyDiaryPresetLoading,
    },
  ] = useGraphLazyQuery<DailyDiaryPresetQuery, DailyDiaryPresetQueryVariables>(
    dailyDiaryPresetQuery,
    {
      variables: {
        id: mode === DailyDiaryPresetFetchMode.ByPresetId ? presetId! : "",
      },
    }
  );

  const { productInstance, loading: productInstanceLoading } =
    useProductInstance(productInstanceId);

  useEffect(() => {
    if (mode === DailyDiaryPresetFetchMode.ByProductInstanceId) {
      if (
        productInstance &&
        (productInstance.extraConfig as DailyDiaryExtraConfig).presetId
      ) {
        fetchDailyDiaryPreset({
          variables: {
            id: (productInstance.extraConfig as DailyDiaryExtraConfig).presetId,
          },
        });
      }
    } else {
      fetchDailyDiaryPreset({
        variables: {
          id: presetId!,
        },
      });
    }
  }, [productInstance, mode, presetId, fetchDailyDiaryPreset]);

  return {
    dailyDiaryPreset: dailyDiaryPreset?.dailyDiaryPreset as
      | DailyDiaryPreset
      | undefined,
    loading: dailyDiaryPresetLoading || productInstanceLoading,
    refetchDailyDiaryPreset,
  };
};
