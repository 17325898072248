import { gql } from "@apollo/client";
import { userPreferencesValueFields } from "./userPreferencesValue.fragment";

export const compEventRecipientFields = gql`
  ${userPreferencesValueFields}

  fragment CompEventRecipientFields on CompEventRecipient {
    userId
    name
    preference {
      ...UserPreferencesValueFields
    }
  }
`;
