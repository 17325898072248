import { Box, ThemeProvider, Typography, useTheme } from "@mui/material";
import { NewAppPaths } from "helpers/paths/paths";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { extendedTheme } from "theme/extendedTheme";
import { AuthTemplateBackground } from "./Icons/AuthTemplateBackground";
import { NewThemeStyledLink } from "./NewThemeStyledLink";
import { useBasicModal } from "./BasicModal/useBasicModal";
import { TermsOfUseModal } from "./TermsOfUseModal/TermsOfUseModal";
import { ReactComponent as CCOMLogo } from "../assets/ccom-logo.svg";
import { PrivacyPolicyModal } from "./PrivacyPolicyModal/PrivacyPolicyModal";

const LHSChildren = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    modalVisibility: termsOfUseModalVisibility,
    toggleModalVisibility: toggleTermsOfUseModalVisibility,
  } = useBasicModal();

  const {
    modalVisibility: privacyPolicyModalVisibility,
    toggleModalVisibility: togglePrivacyPolicyModalVisibility,
  } = useBasicModal();

  return (
    <>
      <TermsOfUseModal
        open={termsOfUseModalVisibility}
        onClose={toggleTermsOfUseModalVisibility}
      />
      <PrivacyPolicyModal
        open={privacyPolicyModalVisibility}
        onClose={togglePrivacyPolicyModalVisibility}
      />
      <Box
        position="absolute"
        left={theme.spacing(10)}
        bottom={theme.spacing(12.5)}
      >
        <Typography
          fontSize="18px"
          fontWeight={600}
          color="grey.800"
          maxWidth="240px"
          sx={{ wordWrap: "wrap" }}
        >
          {t("common.branding.slogan")}
        </Typography>
      </Box>
      <Box
        position="absolute"
        left={0}
        bottom={theme.spacing(7)}
        width="100%"
        sx={{
          paddingLeft: theme.spacing(10),
          paddingRight: theme.spacing(7.5),
          boxSizing: "border-box",
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="p2" color="grey.700">
          {t("common.branding.trademark")}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
        >
          <NewThemeStyledLink
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              toggleTermsOfUseModalVisibility();
            }}
          >
            <Typography variant="body2" color="grey.700">
              {t("TermsOfUse.termsOfUse")}
            </Typography>
          </NewThemeStyledLink>
          <NewThemeStyledLink
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              togglePrivacyPolicyModalVisibility();
            }}
            marginLeft={3}
          >
            <Typography variant="body2" color="grey.700">
              {t("PrivacyPolicy.privacyPolicy")}
            </Typography>
          </NewThemeStyledLink>
        </Box>
      </Box>
    </>
  );
};

export const NewAuthPageTemplate: React.FC<{
  children: React.ReactNode;
  lhsChildren?: React.ReactNode;
}> = ({ children, lhsChildren }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToLoginPage = () => {
    navigate(NewAppPaths.nonAuthorized.Login);
  };

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <Box display="flex" maxHeight="100%">
        <Box flex={1} overflow="hidden" position="relative">
          <Box sx={{ overflow: "hidden" }} maxHeight="100%">
            <AuthTemplateBackground />
          </Box>
          <Box
            top={theme.spacing(10)}
            left={theme.spacing(10)}
            position="absolute"
            sx={{ cursor: "pointer" }}
            onClick={navigateToLoginPage}
          >
            <CCOMLogo />
          </Box>
          {lhsChildren ?? <LHSChildren />}
        </Box>
        <Box flex={1.25} px={10} py={12.5} overflow="auto">
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
