import { ProductTypenameToProduct } from "constants/products";
import {
  ProductInstanceStatus,
  ProductType,
  ProductUserRoleStatus,
  UserProductInstancesQuery,
  UserProductInstancesQueryVariables,
} from "generated/graphql";
import { userProductInstancesQuery } from "graphql/queries/userProductInstances.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext, useMemo } from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";

export const useCompEventProductInstance = (contractId?: string) => {
  const { authenticatedUser } = useContext(GlobalContext);

  const { data, loading } = useGraphQuery<
    UserProductInstancesQuery,
    UserProductInstancesQueryVariables
  >(userProductInstancesQuery, {
    variables: {
      id: authenticatedUser!.id,
    },
  });

  /**
   * Returns the CompEvent product instance. There shouldn't be more than 1 product instance per contract.
   */
  const compEventProductInstance = useMemo(() => {
    const compEventProdInstances = data?.user?.roles.items
      .filter(
        (role) =>
          role.status === ProductUserRoleStatus.Active &&
          ProductTypenameToProduct[role.productInstance.product.__typename!] ===
            ProductType.CompEvents
      )
      .map((role) => role.productInstance)
      .filter(
        (prodInstance) => prodInstance.status === ProductInstanceStatus.Active
      )
      .filter((prodInstance) => prodInstance.contractId === contractId);

    return compEventProdInstances?.[0];
  }, [data, contractId]);

  return {
    loading,
    compEventProductInstance,
  };
};
