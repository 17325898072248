import { isCompEvent } from "containers/Projects/components/CompEvents/CompEvents.utils";
import { NextStepItem } from "./NextStepsTable/NextStepsTable";
import { isClaim } from "containers/Projects/components/Claims/Claims.utils";
import { isVariation } from "containers/Projects/Projects.utils";
import {
  ClaimActionType,
  CompEventActionType,
  VariationActionType,
} from "generated/graphql";

/**
 * Because CEItem, ClaimItem and VOItem, they all have `actionType` attr, but of different types (CEActionType, ClaimActionType, VariationActionType),
 * GraphQL forces us in the query to give aliases to these attributes so they don't conflict. For easier usage in the rest of the code, here we're converting
 * ceActionType -> actionType, claimActionType -> actionType, voActionType -> actionType
 * @param items
 * @returns
 */
export const nextStepItemQueryToNextStepItem = (items: NextStepItem[]) => {
  return items.map((item) => ({
    ...item,
    nextStep: {
      ...item.nextStep,
      actionType: isCompEvent(item)
        ? ((item.nextStep as any).ceActionType as CompEventActionType)
        : isClaim(item)
        ? ((item.nextStep as any).claimActionType as ClaimActionType)
        : isVariation(item)
        ? ((item.nextStep as any).voActionType as VariationActionType)
        : "",
    },
  })) as NextStepItem[];
};
