import {
  Box,
  Breadcrumbs,
  Collapse,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList, ListItem } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { FormPublicApi, OnMutationDone } from "types/decl";
import {
  AddLookupCollectionInput,
  AddLookupOptionInput,
  EditLookupCollectionInput,
  EditLookupOptionInput,
  LookupCollection,
  LookupCollectionStatus,
  LookupOption,
  LookupOptionStatus,
  User,
} from "generated/graphql";
import { useLookupCollection } from "hooks/useLookupCollection";
import { useNavigateBack } from "hooks/useNavigateBack";
import { ListBullets } from "phosphor-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LookupCollectionForm } from "../LookupCollectionForm/LookupCollectionForm";
import { LookupCollectionOptions } from "./LookupCollectionOptions/LookupCollectionOptions";
import { useLookupCollectionOptions } from "./LookupCollectionOptions/useLookupCollectionOptions";
import { getUserName } from "helpers/miscelaneous";

export const LookupCollectionDetails = () => {
  const { t } = useTranslation();
  const handleNavigateBack = useNavigateBack();
  const { lookupCollectionId } = useParams();
  const theme = useTheme();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<LookupCollectionStatus>[];

  const [readOnly, setReadOnly] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const updatedLookupCollectionDetails = useRef<LookupCollection>();
  const formRef = useRef<FormPublicApi>(null);

  const {
    lookupCollection,
    lookupCollectionLoading,
    refetchLookupCollection,
    editLookupCollection,
    editLookupCollectionLoading,
    changeLookupCollectionStatus,
    changeLookupCollectionStatusLoading,
  } = useLookupCollection(lookupCollectionId!);

  const handleLookupCollectionOptionsChange: OnMutationDone = () => {
    refetchLookupCollection();
  };

  const {
    loading: lookupCollectionOptionsLoading,
    addLookupOption,
    editLookupOption,
    changeLookupOptionStatus,
  } = useLookupCollectionOptions({
    onAdd: handleLookupCollectionOptionsChange,
    onEdit: handleLookupCollectionOptionsChange,
    onStatusChange: handleLookupCollectionOptionsChange,
  });

  const updateLookupCollection = useCallback(async () => {
    if (!updatedLookupCollectionDetails) {
      return;
    }

    if (!formRef.current?.validate()) {
      // form invalid
      return;
    }

    const dataToUpdate: EditLookupCollectionInput = {
      id: lookupCollectionId!,
      name: updatedLookupCollectionDetails.current!.name ?? "",
      optionsCharLengthLimit:
        updatedLookupCollectionDetails.current!.optionsCharLengthLimit ??
        undefined,
      optionsLockedForUsers:
        updatedLookupCollectionDetails.current!.optionsLockedForUsers,
    };

    const { errors } = await editLookupCollection({
      variables: { input: dataToUpdate! },
    });

    if (!errors) {
      setReadOnly(true);
    }
  }, [
    updatedLookupCollectionDetails,
    editLookupCollection,
    lookupCollectionId,
  ]);

  const handleLookupCollectionStatusChange = useCallback(
    async (newStatus: LookupCollectionStatus) => {
      await changeLookupCollectionStatus({
        variables: {
          id: lookupCollectionId!,
          status: newStatus,
        },
      });
    },
    [changeLookupCollectionStatus, lookupCollectionId]
  );

  const handleLookupCollectionDetailsChange = useCallback(
    (lookupCollectionData: AddLookupCollectionInput | LookupCollection) => {
      updatedLookupCollectionDetails.current =
        lookupCollectionData as LookupCollection;
    },
    []
  );

  const handleCollectionOptionStatusChange = useCallback(
    async (collectionOption: LookupOption, newStatus: LookupOptionStatus) => {
      const { errors } = await changeLookupOptionStatus({
        variables: { id: collectionOption!.id, status: newStatus },
      });

      return !errors;
    },
    [changeLookupOptionStatus]
  );

  const handleCollectionOptionAdd = useCallback(
    async (input: AddLookupOptionInput) => {
      const { errors } = await addLookupOption({
        variables: {
          input,
        },
      });

      return !errors;
    },
    [addLookupOption]
  );

  const handleCollectionOptionEdit = useCallback(
    async (input: EditLookupOptionInput) => {
      const { errors } = await editLookupOption({
        variables: {
          input,
        },
      });

      return !errors;
    },
    [editLookupOption]
  );

  const handleCancel = () => {
    formRef.current?.reset();
    setReadOnly(true);
  };

  const creatorStr = useMemo(() => {
    if (
      lookupCollection?.lookupCollection.creator.firstname &&
      lookupCollection?.lookupCollection.creator.surname
    ) {
      return getUserName(
        lookupCollection?.lookupCollection.creator as User | undefined
      );
    }

    return "";
  }, [lookupCollection]);

  const lookupCollectionEntityObj = useMemo(() => {
    const toReturn: ListItem[] = [];

    if (!lookupCollection?.lookupCollection) {
      return toReturn;
    }

    return [
      {
        id: "name",
        label: t("common.labels.name"),
        value: lookupCollection.lookupCollection.name,
      },
      {
        id: "optionsLockedForUsers",
        label: t("AdminConsole.Lookups.optionsLockedForUsers"),
        value: String(lookupCollection.lookupCollection.optionsLockedForUsers),
      },
      {
        id: "optionsCharLengthLimit",
        label: t("AdminConsole.Lookups.optionsCharLengthLimit"),
        value: lookupCollection.lookupCollection.optionsCharLengthLimit,
      },
    ];
  }, [lookupCollection, t]);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <EntityDetailsHeader
        loading={
          lookupCollectionLoading ||
          changeLookupCollectionStatusLoading ||
          editLookupCollectionLoading
        }
        status={lookupCollection?.lookupCollection.status}
        onStatusChange={handleLookupCollectionStatusChange}
        title={lookupCollection?.lookupCollection.name || ""}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={handleNavigateBack}>
              <Typography variant="body2">
                {t("AdminConsole.Lookups.lookupCollections")}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        statusOptions={statusOptions}
        creator={creatorStr}
        dateCreated={lookupCollection?.lookupCollection.dateCreated}
      />
      <CollapsibleHeader
        title={t("AdminConsole.Lookups.collectionDetails")}
        collapsed={!showDetails}
        icon={
          <ListBullets
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onEdit={() => setReadOnly(false)}
        onSaveActionLoading={
          editLookupCollectionLoading || changeLookupCollectionStatusLoading
        }
        onCancel={handleCancel}
        onSave={updateLookupCollection}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList
              loading={lookupCollectionLoading}
              entity={lookupCollectionEntityObj}
            />
          ) : lookupCollectionLoading ? null : (
            <LookupCollectionForm
              ref={formRef}
              lookupCollection={
                (lookupCollection?.lookupCollection as LookupCollection) ??
                undefined
              }
              onChange={handleLookupCollectionDetailsChange}
            />
          )}
        </PageContentContainer>
      </Collapse>
      <LookupCollectionOptions
        collectionOptions={
          (lookupCollection?.lookupCollection.options.items ??
            []) as LookupOption[]
        }
        collectionId={lookupCollection?.lookupCollection.id}
        collectionName={lookupCollection?.lookupCollection.name}
        loading={lookupCollectionOptionsLoading}
        onCollectionOptionAdd={handleCollectionOptionAdd}
        onCollectionOptionEdit={handleCollectionOptionEdit}
        onCollectionOptionStatusChange={handleCollectionOptionStatusChange}
      />
    </Box>
  );
};
