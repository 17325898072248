import { gql } from "@apollo/client";
import { homePageAuthorizationWorkflowAuditFields } from "./userPendingAuthorizationActions.query";

export const userPendingAuthorizationAuditsQuery = gql`
  ${homePageAuthorizationWorkflowAuditFields}

  query userPendingAuthorizationAudits($limit: Int!, $nextToken: String) {
    userPendingAuthorizationAudits(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        ...HomePageAuthorizationWorkflowAuditFields
      }
    }
  }
`;
