import { ItemStatusOption } from "generated/graphql";

export const sortCollectionOptions = (
  statusOptions: ItemStatusOption[]
): ItemStatusOption[] => {
  const statusOptionsCopy = JSON.parse(
    JSON.stringify(statusOptions)
  ) as ItemStatusOption[];

  statusOptionsCopy.sort((statusOption1, statusOption2) => {
    if (statusOption1.displayOrder < statusOption2.displayOrder) {
      return -1;
    } else if (statusOption1.displayOrder > statusOption2.displayOrder) {
      return 1;
    } else {
      return (
        new Date(statusOption1.dateCreated).getTime() -
        new Date(statusOption2.dateCreated).getTime()
      );
    }
  });

  return statusOptionsCopy;
};
