import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const schemaFieldFields = gql`
  ${creatorFields}

  fragment SchemaFieldFields on SchemaField {
    id
    name
    schemaSectionId
    fieldTypeId
    displayText
    displayOrder
    isRequired
    isInternal
    complexTypeItemId
    extraData
    status
    creatorId
    creator {
      ...CreatorFields
    }
    dateCreated
    fieldType {
      id
      description
      shortCode
      status
    }
  }
`;
