import { Grid, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useInstructionProvideQuotationExtraDataForm } from "./useInstructionProvideQuotationExtraDataForm";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { useTranslation } from "react-i18next";
import { FormLabel } from "components/FormLabel";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ClaimTypeSelect } from "containers/Projects/components/ActionModal/ClaimTypeSelectGridItem";
import { ProductType } from "generated/graphql";

export type InstructionProvideQuotationExtraDataType = {
  claimTypeId: string;
  assumptions?: string | null;
};

const dataValidators: DataValidators = {
  claimTypeId: {
    validators: [ValidatorType.Required],
  },
};

const defaultFormData: InstructionProvideQuotationExtraDataType = {
  claimTypeId: "",
  assumptions: "",
};

export type InstructionProvideQuotationExtraDataFormProps = {
  contractId: string;
  extraData?: InstructionProvideQuotationExtraDataType;
  assumptionsPlaceholder?: string;
  onChange: (extraData: InstructionProvideQuotationExtraDataType) => void;
  onFormValidationChange: (isValid: boolean) => void;
};

export const InstructionProvideQuotationExtraDataForm: React.FC<
  InstructionProvideQuotationExtraDataFormProps
> = ({
  contractId,
  extraData,
  assumptionsPlaceholder,
  onChange,
  onFormValidationChange,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] =
    useState<InstructionProvideQuotationExtraDataType>(
      extraData ?? defaultFormData
    );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const { activeClaimTypes, loading: activeClaimTypesLoading } =
    useInstructionProvideQuotationExtraDataForm(contractId);

  const handleClaimTypeChange = (event: SelectChangeEvent<string>) => {
    setFormData((curData) => ({
      ...curData,
      claimTypeId: event.target.value,
    }));

    onChange({
      ...formData,
      claimTypeId: event.target.value,
    });

    const validationResult = validateData(
      { ...formData, claimTypeId: event.target.value },
      dataValidators
    );

    if (validationResult.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(validationResult.errors);
    }
  };

  const handleAssumptionsChange = (updatedAssuptions: string) => {
    setFormData((curData) => ({
      ...curData,
      assumptions: updatedAssuptions,
    }));

    onChange({
      ...formData,
      assumptions: updatedAssuptions,
    });
  };

  const isFormValid = !!formData.claimTypeId;

  useEffect(() => {
    onFormValidationChange(isFormValid);
  }, [isFormValid, onFormValidationChange]);

  return activeClaimTypesLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ClaimTypeSelect
          productType={ProductType.CompEvents}
          value={formData.claimTypeId ?? ""}
          claimTypes={activeClaimTypes}
          error={formDataErrors.claimTypeId}
          onChange={handleClaimTypeChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel label={t("Projects.CompEvents.ActionModal.assumptions")} />
        <RichTextArea
          content={formData.assumptions ?? ""}
          placeholder={assumptionsPlaceholder}
          onChange={handleAssumptionsChange}
        />
      </Grid>
    </Grid>
  );
};
