import { Box, Skeleton, Typography, Grid } from "@mui/material";
import React from "react";

export type ListItem = {
  id: string;
  label: string;
  value: React.ReactNode;
  fullWidth?: boolean;
};

export type GroupedListItem = {
  label: string;
  items: ListItem[];
};

export type DetailsListProps = {
  entity: ListItem[] | GroupedListItem[];
  loading?: boolean;
};

const isListItem = (item: ListItem | GroupedListItem): item is ListItem => {
  return !!(item as ListItem).id;
};

const DetailsListItem: React.FC<{ listItem: ListItem; loading?: boolean }> = ({
  listItem,
  loading,
}) => {
  return (
    <Box display="flex" alignItems="flex-start">
      <Typography variant="subtitle2" fontWeight="bold">
        {listItem.label}:
      </Typography>
      <Box ml={1} display="flex" alignItems="center">
        {loading ? (
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={150} />
        ) : (
          <Typography variant="subtitle2" data-testid={listItem.label}>
            {listItem.value}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const DetailsList: React.FC<DetailsListProps> = ({
  entity,
  loading,
}) => {
  return entity && entity.length && isListItem(entity[0]) ? (
    <Grid container spacing={1}>
      {entity.map((entityProp) => {
        const entityPropListItem = entityProp as ListItem;
        return (
          <Grid
            item
            md={entityPropListItem.fullWidth ? 12 : 6}
            xs={12}
            key={entityPropListItem.id}
          >
            <DetailsListItem
              listItem={entityPropListItem}
              loading={loading}
              key={entityPropListItem.id}
            />
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <Box>
      {entity.map((entityProp, index) => {
        const entityPropListItem = entityProp as GroupedListItem;

        return (
          <React.Fragment key={entityPropListItem.label}>
            <Typography
              variant="h3"
              fontSize="20px"
              fontWeight={600}
              mt={index === 0 ? 0 : 4}
              mb={1}
            >
              {entityPropListItem.label}
            </Typography>
            <DetailsList entity={entityPropListItem.items} loading={loading} />
          </React.Fragment>
        );
      })}
    </Box>
  );
};
