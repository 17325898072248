import { Box, css, styled, Typography, useTheme } from "@mui/material";
import { Minus, Plus } from "phosphor-react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export type TimeFieldProps = {
  number: number;
  unit?: string;
  positiveOnly?: boolean;
  onIncrement: () => void;
  onDecrement: () => void;
};

const TimeFieldContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.grey[200]};
    padding: ${theme.spacing(0.75)} ${theme.spacing(2)};
    box-sizing: border-box;
    user-select: none;
  `
);

const ButtonWrapper = styled(Box)<{
  variant?: "success" | "error";
  disabled?: boolean;
}>(
  ({ theme, variant, disabled }) => css`
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}

    &:hover {
      background-color: ${variant === "success"
        ? theme.palette.success.light
        : theme.palette.error.light};
    }
  `
);

export enum TimeFieldActionType {
  Increment = "Increment",
  Decrement = "Decrement",
}

export const TimeField: React.FC<TimeFieldProps> = ({
  number,
  unit,
  positiveOnly,
  onIncrement,
  onDecrement,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const intervalRef = useRef<NodeJS.Timer>();
  const computedUnit = unit ?? t("common.labels.days").toLowerCase();

  const startCounting = (type: TimeFieldActionType) => {
    intervalRef.current = setInterval(() => {
      if (type === TimeFieldActionType.Increment) {
        onIncrement();
      } else {
        onDecrement();
      }
    }, 200);
  };

  const handleMouseDown = (type: TimeFieldActionType) => {
    // In case the user mouseDowned on the button and dragged outside the button, the mouseUp event wouldn't fire and the interval would just keep going.
    // Resetting the interval on mouseDown.
    clearInterval(intervalRef.current);
    startCounting(type);
  };

  const handleMouseUp = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    if (positiveOnly && number <= 0) {
      clearInterval(intervalRef.current);
    }
  }, [number, positiveOnly]);

  return (
    <TimeFieldContainer>
      <ButtonWrapper
        variant="success"
        onClick={onDecrement}
        onMouseDown={() => handleMouseDown(TimeFieldActionType.Decrement)}
        onMouseUp={handleMouseUp}
        data-testid="decrement-btn"
        disabled={positiveOnly && number <= 0}
      >
        <Minus
          size={18}
          color={
            positiveOnly && number <= 0
              ? theme.palette.action.disabled
              : theme.palette.success.main
          }
        />
      </ButtonWrapper>
      <Box display="flex" alignItems="center">
        <Typography
          variant="p2"
          data-testid="time-value"
          color={
            number > 0
              ? theme.palette.error.main
              : number < 0
              ? theme.palette.success.main
              : "grey.800"
          }
        >
          {number > 0 ? `+${number}` : number}
        </Typography>
        <Typography variant="p2" color="grey.500" ml={1}>
          {computedUnit}
        </Typography>
      </Box>
      <ButtonWrapper
        variant="error"
        onClick={onIncrement}
        onMouseDown={() => handleMouseDown(TimeFieldActionType.Increment)}
        onMouseUp={handleMouseUp}
        data-testid="increment-btn"
      >
        <Plus size={18} color={theme.palette.error.main} />
      </ButtonWrapper>
    </TimeFieldContainer>
  );
};
