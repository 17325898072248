import {
  ClaimItem,
  ClaimRegardingType,
  Contract,
  EventItem,
  InstructionItem,
} from "generated/graphql";
import { createContext } from "react";

export type ClaimWidgetContextType = {
  productInstanceId: string;
  productInstanceNumberingFormat: string;
  recordSupported: boolean;
  projectId: string;
  projectName: string;
  contract: Contract;
  regardingId: string;
  regardingType: ClaimRegardingType;
  claim?: ClaimItem;
  instructionItem?: InstructionItem;
  eventItem?: EventItem;
  isFIDIC99RedYellow?: boolean;
  onChange?: () => void;
};

const contextDefaultValue: ClaimWidgetContextType = {
  productInstanceId: "",
  productInstanceNumberingFormat: "",
  projectId: "",
  projectName: "",
  recordSupported: false,
  contract: {} as Contract,
  regardingId: "",
  regardingType: ClaimRegardingType.Event,
  claim: undefined,
  onChange: undefined,
  instructionItem: undefined,
  isFIDIC99RedYellow: undefined,
  eventItem: undefined,
};

export const ClaimWidgetContext =
  createContext<ClaimWidgetContextType>(contextDefaultValue);
