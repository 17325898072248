import { dateISOFormat } from "constants/constants";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { NewAppPaths } from "helpers/paths/paths";

export const useDailyDiaryHeader = (
  productInstanceId: string,
  date: string
) => {
  const navigate = useNavigate();
  const crtDateFormatted = useMemo(
    () => moment(date, dateISOFormat).format(dateISOFormat),
    [date]
  );

  const navigateToSpecificDate = useCallback(
    (date: string) => {
      const newDate = moment(date).format(dateISOFormat);

      navigate(
        NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
          productInstanceId,
          newDate
        )
      );
    },
    [navigate, productInstanceId]
  );

  const navigateToPreviousDate = useCallback(() => {
    const newDate = moment(crtDateFormatted, dateISOFormat)
      .subtract(1, "day")
      .format(dateISOFormat);

    navigate(
      NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
        productInstanceId,
        newDate
      )
    );
  }, [navigate, crtDateFormatted, productInstanceId]);

  const navigateToNextDate = useCallback(() => {
    const newDate = moment(crtDateFormatted, dateISOFormat)
      .add(1, "day")
      .format(dateISOFormat);

    navigate(
      NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
        productInstanceId,
        newDate
      )
    );
  }, [navigate, crtDateFormatted, productInstanceId]);

  return {
    navigateToSpecificDate,
    navigateToPreviousDate,
    navigateToNextDate,
  };
};
