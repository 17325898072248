import { css, Link, styled } from "@mui/material";

export const NewThemeStyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "colorTone",
})<{ colorTone?: "lighter" | "darker" }>(
  ({ theme, colorTone = "lighter" }) => css`
    text-decoration: none;
    cursor: pointer;
    color: ${colorTone === "lighter"
      ? theme.palette.grey[500]
      : theme.palette.grey[900]};

    ${colorTone === "darker" &&
    css`
      font-weight: 600;
      text-decoration: underline;
    `}

    &:hover {
      color: ${colorTone === "lighter"
        ? theme.palette.grey[700]
        : theme.palette.grey[900]};
    }
  `
);
