import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const ClockIcon: React.FC<BaseIconProps> = ({
  height = "18",
  width = "17",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9973 15.0011C12.3117 15.0011 14.9985 12.3142 14.9985 8.99987C14.9985 5.68549 12.3117 2.99866 8.9973 2.99866C5.68293 2.99866 2.99609 5.68549 2.99609 8.99987C2.99609 12.3142 5.68293 15.0011 8.9973 15.0011Z"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeMiterlimit="10"
      />
      <path
        d="M8.99609 5.49902V8.99915H12.4962"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
