import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";
import { dailyDiaryResourceRecordLiteFields } from "graphql/fragments/dailyDiaryResourceRecordLite.fragment";

export const dailyDiaryManpowerRecordsQuery = gql`
  ${dailyDiaryResourceRecordLiteFields}
  ${attachmentFields}

  query dailyDiaryManpowerRecords($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryManpowerRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      ...DailyDiaryResourceRecordLiteFields
      attachments {
        ...AttachmentFields
      }
    }
  }
`;

export const dailyDiaryManpowerImportSourceItemsQuery = gql`
  query dailyDiaryManpowerImportSourceItems(
    $productInstanceId: ID!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    dailyDiaryManpowerImportSourceItems(
      productInstanceId: $productInstanceId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      items {
        id
        productInstanceId
        date
        number
        status
        dateCreated
        creatorId
        sentById
        dateSent
        latestRevision {
          id
          number
          status
          dateSent
        }
      }
    }
  }
`;

export const importDailyDiaryManpowerRecordsMutation = gql`
  mutation importDailyDiaryManpowerRecords(
    $input: ImportDailyDiaryRecordsInput!
  ) {
    importDailyDiaryManpowerRecords(input: $input)
  }
`;
