import {
  AddLookupCollectionInput,
  AddLookupCollectionMutation,
  AddLookupCollectionMutationVariables,
  LookupCollectionQuery,
  LookupCollectionQueryVariables,
  LookupOptionStatus,
} from "generated/graphql";
import { addLookupCollectionMutation } from "graphql/mutations/addLookupCollection";
import { lookupCollectionQuery } from "graphql/queries/lookupCollection.query";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useState } from "react";
import { useLookupCollectionOptions } from "../LookupCollectionDetails/LookupCollectionOptions/useLookupCollectionOptions";
import { noop } from "helpers/miscelaneous";

// TODO: move this logic on the BE side and make it transaction-like
export const useDuplicateLookupCollection = () => {
  const [loading, setLoading] = useState(false);

  const [fetchLookupCollection] = useGraphLazyQueryLite<
    LookupCollectionQuery,
    LookupCollectionQueryVariables
  >(lookupCollectionQuery);

  const { addLookupOption } = useLookupCollectionOptions({
    onAdd: noop,
    showToastMessage: false,
  });

  const [newLookupCollection] = useGraphMutation<
    AddLookupCollectionMutation,
    AddLookupCollectionMutationVariables
  >(addLookupCollectionMutation, {}, null);

  const duplicateLookupCollection = async (
    collectionId: string,
    addNewCollectionInput: AddLookupCollectionInput
  ) => {
    setLoading(true);
    const { data: collectionToDuplicate, errors } = await fetchLookupCollection(
      {
        id: collectionId,
      }
    );
    const { data: newCollectionData, errors: newCollectionErrors } =
      await newLookupCollection({
        variables: { input: addNewCollectionInput },
      });

    const addOptionsPromises: Promise<any>[] = [];

    collectionToDuplicate.lookupCollection.options.items
      .filter((option) => option.status === LookupOptionStatus.Active)
      .sort((option1, option2) => option1.displayOrder - option2.displayOrder)
      .forEach((lookupOption, index) => {
        addOptionsPromises.push(
          addLookupOption({
            variables: {
              input: {
                collectionId: newCollectionData!.addLookupCollection.id,
                value: lookupOption.value,
                displayOrder: index + 1,
              },
            },
          })
        );
      });

    await Promise.all(addOptionsPromises);
    setLoading(false);

    return !errors && !newCollectionErrors;
  };

  return {
    duplicateLookupCollection,
    loading,
  };
};
