import { gql } from "@apollo/client";
import { compEventQuotationFields } from "graphql/fragments/compEventQuotation.fragment";

export const compEventNoticeOfSilenceOwnAssessmentPromptQuery = gql`
  ${compEventQuotationFields}

  query compEventNoticeOfSilenceOwnAssessmentPrompt(
    $input: CompEventNoticeOfSilenceOwnAssessmentPromptInput!
  ) {
    compEventNoticeOfSilenceOwnAssessmentPrompt(input: $input) {
      remarks
      quotations {
        ...CompEventQuotationFields
      }
    }
  }
`;
