import {
  CompEventAssessment,
  CompEventQuotation,
  CompEventQuotationAssessment,
  CompEventQuotationAssessmentsQuery,
  CompEventQuotationAssessmentsQueryVariables,
  Contract,
} from "generated/graphql";
import { compEventQuotationAssessmentsQuery } from "graphql/queries/compEventQuotations.query";
import { useCompEventProductInstance } from "hooks/useCompEventProductInstance";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";

export const useCompEventWidget = (
  contract: Contract,
  compEventId?: string
) => {
  const { data: compEventQuotations, loading: compEventQuotationsLoading } =
    useGraphQuery<
      CompEventQuotationAssessmentsQuery,
      CompEventQuotationAssessmentsQueryVariables
    >(compEventQuotationAssessmentsQuery, {
      variables: {
        compEventId: compEventId!,
      },
      skip: !compEventId,
    });

  const { compEventProductInstance, loading: compEventProdInstanceLoading } =
    useCompEventProductInstance(contract.id);

  const quotations = useMemo(
    () =>
      (compEventQuotations?.compEventQuotationAssessments.items.filter(
        (quotationAssessment) =>
          quotationAssessment.__typename === "CompEventQuotation"
      ) ?? []) as CompEventQuotation[],
    [compEventQuotations]
  );

  const assessments = useMemo(
    () =>
      (compEventQuotations?.compEventQuotationAssessments.items.filter(
        (quotationAssessment) =>
          quotationAssessment.__typename === "CompEventAssessment"
      ) ?? []) as CompEventAssessment[],
    [compEventQuotations]
  );

  return {
    compEventQuotations: quotations,
    compEventAssessments: assessments,
    compEventQuotationAssessments: (compEventQuotations
      ?.compEventQuotationAssessments.items ??
      []) as CompEventQuotationAssessment[],
    loading: compEventQuotationsLoading || compEventProdInstanceLoading,
    compEventProductInstance,
  };
};
