import { Box, Color, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { FullScreenProductItemPreview } from "containers/Projects/components/FullScreenProductItemPreview";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { SmallProductItemPreview } from "containers/Projects/components/SmallProductItemPreview";
import { RecipientsPreview } from "containers/Projects/components/RecipientsPreview";
import {
  ClaimActionType,
  ProductType,
  SendClaimAgreementNoticePreview,
  SendClaimDetailsResponsePreview,
  SendClaimDeterminationNoticePreview,
  SendClaimErrorAgreementDisagreementPreview,
  SendClaimErrorAgreementNoticePreview,
  SendClaimErrorDeterminationDisagreementPreview,
  SendClaimErrorDeterminationNoticePreview,
  SendClaimFinalClaimDetailsPreview,
  SendClaimFurtherParticularsRequestPreview,
  SendClaimFurtherParticularsSubmissionPreview,
  SendClaimInterimClaimDetailsPreview,
  SendClaimNoAgreementNoticePreview,
  SendClaimNoticeInvalidNoticePreview,
  SendClaimNoticeLapsedNoticePreview,
  SendClaimNoticePreview,
} from "generated/graphql";
import { PaperPlaneTilt } from "phosphor-react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContainer } from "components/IconContainer";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { useRemoveClaimPreview } from "../hooks/useRemoveClaimPreview";
import { ActionModalTitle } from "containers/Projects/components/ActionModal/ActionModalTitle";
import { claimActionTypeTranslations } from "../../ClaimWidget/ClaimWidget.constants";
import { claimActionTypeFIDIC99RedYellowTypeMapping } from "../../types";

export type SendActionProps = {
  claimPreview?:
    | SendClaimNoticePreview
    | SendClaimInterimClaimDetailsPreview
    | SendClaimFinalClaimDetailsPreview
    | SendClaimAgreementNoticePreview
    | SendClaimNoAgreementNoticePreview
    | SendClaimDeterminationNoticePreview
    | SendClaimFurtherParticularsRequestPreview
    | SendClaimFurtherParticularsSubmissionPreview
    | SendClaimNoticeInvalidNoticePreview
    | SendClaimNoticeLapsedNoticePreview
    | SendClaimDetailsResponsePreview
    | SendClaimErrorAgreementNoticePreview
    | SendClaimErrorAgreementDisagreementPreview
    | SendClaimErrorDeterminationNoticePreview
    | SendClaimErrorDeterminationDisagreementPreview;
  actionType: ClaimActionType;
  summaryEl: React.ReactNode;
} & ActionsDialogProps;

export const SendAction: React.FC<SendActionProps> = ({
  claimPreview,
  actionType,
  summaryEl,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    productInstanceNumberingFormat,
    claim,
    projectName,
    contract,
    isFIDIC99RedYellow,
  } = useContext(ClaimWidgetContext);

  const [fullPreviewModalVisibility, setFullPreviewModalVisibility] =
    useState(false);

  const { removeClaimPreview } = useRemoveClaimPreview();

  const toggleFullPreviewModalVisibility = () => {
    setFullPreviewModalVisibility((state) => !state);
  };

  const handlePrimaryClick = () => {
    clearModal();
    onPrimaryClick();
  };

  const handleSecondaryClick = () => {
    clearModal();
    onSecondaryClick();
  };

  const handleClose = async (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    clearModal();
    onClose?.(event, reason);
  };

  const clearModal = async () => {
    if (claimPreview?.id) {
      await removeClaimPreview({ variables: { id: claimPreview.id } });
    }
  };

  return (
    <>
      {claimPreview && (
        <FullScreenProductItemPreview
          previewUrl={claimPreview?.fileUrl}
          open={fullPreviewModalVisibility}
          onClose={toggleFullPreviewModalVisibility}
        />
      )}
      <ActionsDialog
        iconsHeader={
          <IconContainer>
            <PaperPlaneTilt
              size={18}
              color={(theme.palette.secondary as Partial<Color>)[700]}
            />
          </IconContainer>
        }
        onPrimaryClick={handlePrimaryClick}
        onSecondaryClick={handleSecondaryClick}
        primaryBtnCaption={t("common.buttons.send")}
        primaryBtnDisabled={!claimPreview}
        onClose={handleClose}
        fullWidth
        content={
          !claimPreview ? (
            <CenteredLoadingIndicator />
          ) : (
            <Box>
              <ActionModalTitle mb={2}>
                {t(
                  claimActionTypeTranslations[
                    isFIDIC99RedYellow
                      ? claimActionTypeFIDIC99RedYellowTypeMapping(actionType)
                      : actionType
                  ]
                )}
              </ActionModalTitle>
              {summaryEl}
              <Box mt={4}>
                <ProductItemCard
                  itemName={
                    claim?.number ?? `${productInstanceNumberingFormat}-XXXX`
                  }
                  productType={ProductType.Claims}
                  projectName={projectName}
                  contractName={contract.friendlyName}
                />
              </Box>
              <Box mt={4}>
                <CollapsibleContainer title={t("common.labels.preview")}>
                  <SmallProductItemPreview
                    imageUrl={claimPreview.fileUrl}
                    onClick={toggleFullPreviewModalVisibility}
                  />
                </CollapsibleContainer>
              </Box>
              <Box mt={4}>
                <CollapsibleContainer
                  title={`${t(`common.labels.recipients`)} (${
                    claimPreview.recipients.length
                  })`}
                >
                  <RecipientsPreview recipients={claimPreview.recipients} />
                </CollapsibleContainer>
              </Box>
            </Box>
          )
        }
        sx={{ minWidth: "420px" }}
        {...restDialogProps}
      />
    </>
  );
};
