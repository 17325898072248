import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SeverityPickerItemIcon } from "./SeverityPickerItemIcon";
import { SeverityPreview } from "./SeverityPreview";
import { defaultSeverityIndex } from "./Severity.constants";
import { SeverityReadOnlyProps } from "./Severity.decl";

export const SeverityReadOnly: React.FC<
  SeverityReadOnlyProps & { size?: "small" | "medium" }
> = ({
  severityPreset,
  consequenceValue,
  likelihoodValue,
  size = "medium",
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      spacing={size === "small" ? 1.5 : 3}
    >
      <Grid item xs={size === "small" ? 3.5 : 3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="p2" fontWeight={600} mb={1}>
            {t("Projects.Risks.consequence")}
          </Typography>
          <Box display="flex" alignItems="center">
            <SeverityPickerItemIcon
              itemSet={severityPreset.consequenceSets}
              value={consequenceValue ?? defaultSeverityIndex}
              height={16}
              width={16}
              step={2.5}
              highContrast
            />
            <Typography variant="p1" color="grey.600" ml={1.5}>
              {consequenceValue
                ? severityPreset.consequenceSets[consequenceValue - 1]
                : "Unknown"}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={size === "small" ? 3.5 : 3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="p2" fontWeight={600} mb={1}>
            {t("Projects.Risks.likelihood")}
          </Typography>
          <Box display="flex" alignItems="center">
            <SeverityPickerItemIcon
              itemSet={severityPreset.likelihoodSets}
              value={likelihoodValue ?? defaultSeverityIndex}
              height={16}
              width={16}
              step={2.5}
              highContrast
            />
            <Typography variant="p1" color="grey.600" ml={1.5}>
              {likelihoodValue
                ? severityPreset.likelihoodSets[likelihoodValue - 1]
                : "Unknown"}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={size === "small" ? 5 : 6}>
        <SeverityPreview
          size={size}
          consequenceValue={consequenceValue}
          likelihoodValue={likelihoodValue}
          severityPreset={severityPreset}
        />
      </Grid>
    </Grid>
  );
};
