import { Box } from "@mui/material";
import { CompEventItem, ProductType } from "generated/graphql";
import { CompEventWidget } from "../../CompEventWidget/CompEventWidget";
import { ProductItemPreviewContainer } from "components/ProductItemPreview/ProductItemPreviewContainer";
import { ProductItemPreviewHeader } from "components/ProductItemPreview/ProductItemPreviewHeader";

export type CompEventItemPreviewProps = {
  compEvent: CompEventItem;
};

export const CompEventItemPreview: React.FC<CompEventItemPreviewProps> = ({
  compEvent,
}) => {
  return (
    <ProductItemPreviewContainer>
      <ProductItemPreviewHeader
        productType={ProductType.CompEvents}
        itemNumber={compEvent.number}
        notifiedBy={compEvent.notifiedByParty.description}
        dateCreated={compEvent.dateCreated}
        contractTimezone={compEvent.productInstance.contract.timeZone}
      />
      <Box mt={3} width="100%">
        <CompEventWidget
          previewMode
          loading={false}
          compEventId={compEvent.id}
          regardingId={compEvent.regardingId}
          contract={compEvent.productInstance.contract}
          projectId={compEvent.productInstance.contract.project.id}
          projectName={compEvent.productInstance.contract.project.friendlyName}
          eventItem={compEvent.regardingEvent ?? undefined}
          instructionItem={compEvent.regardingInstruction ?? undefined}
        />
      </Box>
    </ProductItemPreviewContainer>
  );
};
