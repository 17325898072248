import { gql } from "@apollo/client";

export const activeClaimTypesQuery = gql`
  query activeClaimTypes($contractId: ID!) {
    activeClaimTypes(contractId: $contractId) {
      id
      name
    }
  }
`;
