import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimFurtherParticularsRequestPromptQuery = gql`
  query claimFurtherParticularsRequestPrompt(
    $input: ClaimFurtherParticularsRequestPromptInput!
  ) {
    claimFurtherParticularsRequestPrompt(input: $input) {
      reasons
    }
  }
`;

export const sendClaimFurtherParticularsRequestMutation = gql`
  mutation sendClaimFurtherParticularsRequest(
    $input: SendClaimFurtherParticularsRequestInput!
  ) {
    sendClaimFurtherParticularsRequest(input: $input) {
      id
    }
  }
`;

export const recordClaimFurtherParticularsRequestMutation = gql`
  mutation recordClaimFurtherParticularsRequest(
    $input: RecordClaimFurtherParticularsRequestInput!
  ) {
    recordClaimFurtherParticularsRequest(input: $input) {
      id
    }
  }
`;

export const generateSendClaimFurtherParticularsRequestPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimFurtherParticularsRequestPreview(
    $input: SendClaimFurtherParticularsRequestPreviewInput!
  ) {
    generateSendClaimFurtherParticularsRequestPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimFurtherParticularsRequestPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimFurtherParticularsRequestPreview(
    $input: RecordClaimFurtherParticularsRequestPreviewInput!
  ) {
    generateRecordClaimFurtherParticularsRequestPreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
