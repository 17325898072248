import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationObjectionVariationPromptQuery = gql`
  query variationObjectionVariationPrompt(
    $input: VariationObjectionVariationPromptInput!
  ) {
    variationObjectionVariationPrompt(input: $input) {
      defaultText
    }
  }
`;

export const sendVariationObjectionVariationMutation = gql`
  mutation sendVariationObjectionVariation(
    $input: SendVariationObjectionVariationInput!
  ) {
    sendVariationObjectionVariation(input: $input) {
      id
    }
  }
`;

export const recordVariationObjectionVariationMutation = gql`
  mutation recordVariationObjectionVariation(
    $input: RecordVariationObjectionVariationInput!
  ) {
    recordVariationObjectionVariation(input: $input) {
      id
    }
  }
`;

export const generateSendVariationObjectionVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationObjectionVariationPreview(
    $input: SendVariationObjectionVariationPreviewInput!
  ) {
    generateSendVariationObjectionVariationPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationObjectionVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationObjectionVariationPreview(
    $input: RecordVariationObjectionVariationPreviewInput!
  ) {
    generateRecordVariationObjectionVariationPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
