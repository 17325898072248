import {
  EditRiskItemMutation,
  EditRiskItemMutationVariables,
  RiskItem,
  RiskItemQuery,
  RiskItemQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  editRiskItemMutation,
  riskItemQuery,
} from "../../RiskDetails/RiskDetails.query";
import { useParams } from "react-router-dom";
import { ErrorHandlingType, useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";

export const useEditRisk = () => {
  const { riskItemId } = useParams();
  const { t } = useTranslation();

  const {
    data: riskItemData,
    refetch: refetchRiskItem,
    loading: riskItemLoading,
    error: riskItemError,
  } = useGraphQuery<RiskItemQuery, RiskItemQueryVariables>(
    riskItemQuery,
    {
      variables: { id: riskItemId! },
      errorPolicy: "none",
      fetchPolicy: "cache-and-network",
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  const [doEditRisk, { loading: editRiskLoading }] = useGraphMutation<
    EditRiskItemMutation,
    EditRiskItemMutationVariables
  >(
    editRiskItemMutation,
    {},
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Risks.riskItem"),
    })
  );

  return {
    riskItem: riskItemData?.riskItem as RiskItem | undefined,
    riskItemLoading,
    refetchRiskItem,
    riskItemError,
    doEditRisk,
    editRiskLoading,
  };
};
