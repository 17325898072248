import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationFurtherParticularsSubmissionPromptQuery = gql`
  query variationFurtherParticularsSubmissionPrompt(
    $input: VariationFurtherParticularsSubmissionPromptInput!
  ) {
    variationFurtherParticularsSubmissionPrompt(input: $input) {
      details
    }
  }
`;

export const sendVariationFurtherParticularsSubmissionMutation = gql`
  mutation sendVariationFurtherParticularsSubmission(
    $input: SendVariationFurtherParticularsSubmissionInput!
  ) {
    sendVariationFurtherParticularsSubmission(input: $input) {
      id
    }
  }
`;

export const recordVariationFurtherParticularsSubmissionMutation = gql`
  mutation recordVariationFurtherParticularsSubmission(
    $input: RecordVariationFurtherParticularsSubmissionInput!
  ) {
    recordVariationFurtherParticularsSubmission(input: $input) {
      id
    }
  }
`;

export const generateSendVariationFurtherParticularsSubmissionPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationFurtherParticularsSubmissionPreview(
    $input: SendVariationFurtherParticularsSubmissionPreviewInput!
  ) {
    generateSendVariationFurtherParticularsSubmissionPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationFurtherParticularsSubmissionPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationFurtherParticularsSubmissionPreview(
    $input: RecordVariationFurtherParticularsSubmissionPreviewInput!
  ) {
    generateRecordVariationFurtherParticularsSubmissionPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
