import { gql } from "@apollo/client";
import { claimHistoryItemFields } from "graphql/fragments/claimHistoryItem.fragment";

export const claimHistoryItemQuery = gql`
  ${claimHistoryItemFields}

  query claimHistoryItem($id: ID!) {
    claimHistoryItem(id: $id) {
      ...ClaimHistoryItemFields
    }
  }
`;
