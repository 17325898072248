import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const lookupOptionFields = gql`
  ${creatorFields}

  fragment LookupOptionFields on LookupOption {
    id
    value
    displayOrder
    collectionId
    isInternal
    status
    creatorId
    dateCreated
    creator {
      ...CreatorFields
    }
  }
`;

export const lookupOptionQuery = gql`
  ${lookupOptionFields}

  query lookupOption($id: ID!) {
    lookupOption(id: $id) {
      ...LookupOptionFields
    }
  }
`;
