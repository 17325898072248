import { Typography, TypographyProps } from "@mui/material";

export const ActionModalTitle: React.FC<TypographyProps> = ({
  children,
  ...typographyProps
}) => {
  return (
    <Typography
      variant="h3"
      fontWeight={600}
      color="grey.800"
      mb={1}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};

export const ActionModalSubtitle: React.FC<TypographyProps> = ({
  children,
  ...typographyProps
}) => {
  return (
    <Typography
      variant="p1"
      fontWeight={400}
      color="grey.700"
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};
