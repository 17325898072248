import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventNoticeRequestQuotationMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventNoticeRequestQuotation(
    $input: SendCompEventNoticeRequestQuotationInput!
  ) {
    sendCompEventNoticeRequestQuotation(input: $input) {
      ...CompEventItemFields
    }
  }
`;
