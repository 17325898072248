import { gql } from "@apollo/client";
import { eventItemFields } from "graphql/fragments/eventItem.fragment";

export const eventItemsQuery = gql`
  ${eventItemFields}

  query eventItems($productInstanceId: ID!) {
    eventItems(productInstanceId: $productInstanceId) {
      items {
        ...EventItemFields
        compEvent {
          id
          number
        }
        claim {
          id
          number
        }
        # TODO add variation when added
      }
    }
  }
`;
