import { ContractKeyDate, ContractSection } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";
import { QuotationAssessmentFormDataType } from "./CEQuotationAssessment.decl";

export const getDefaultData = (
  compEventId: string,
  keyDates: ContractKeyDate[],
  sections: ContractSection[]
) => {
  const defaultFormData: QuotationAssessmentFormDataType = {
    attachments: [],
    compEventId,
    details: "",
    keyDatesChanges: [],
    price: 0,
    sectionalChanges: [],
    time: 0,
  };

  defaultFormData.keyDatesChanges = keyDates.map((keyDate) => ({
    id: keyDate.id,
    days: 0,
  }));

  defaultFormData.sectionalChanges = sections.map((section) => ({
    id: section.id,
    days: 0,
  }));

  return defaultFormData;
};

export const dataValidators: DataValidators = {
  details: {
    validators: [ValidatorType.RequiredRichText],
  },
  price: {
    validators: [ValidatorType.Required],
  },
  time: {
    validators: [ValidatorType.Required],
  },
  keyDatesChanges: {
    validators: [ValidatorType.Required],
  },
  sectionalChanges: {
    validators: [ValidatorType.Required],
  },
};
