import { Box, Button } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export type ActionModalFooterProps = {
  recordDisabled?: boolean;
  recordSupported?: boolean;
  sendDisabled?: boolean;
  onCancel: () => void;
  onRecord: () => void;
  onSend: () => void;
};

export const ActionModalFooter: React.FC<ActionModalFooterProps> = memo(
  ({
    sendDisabled,
    recordDisabled,
    recordSupported,
    onCancel,
    onSend,
    onRecord,
  }) => {
    const { t } = useTranslation();

    return (
      <Box display="flex" alignItems="center">
        <Button variant="text" onClick={onCancel} data-testid="cancel-btn">
          {t("common.buttons.cancel")}
        </Button>
        {recordSupported && (
          <Box ml={4}>
            <Button
              variant="outlined"
              onClick={onRecord}
              disabled={recordDisabled}
              data-testid="record-btn"
            >
              {t("common.buttons.record")}
            </Button>
          </Box>
        )}
        <Box ml={2}>
          <Button
            variant="contained"
            onClick={onSend}
            disabled={sendDisabled}
            data-testid="send-btn"
          >
            {t("common.buttons.send")}
          </Button>
        </Box>
      </Box>
    );
  }
);
