import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";
import { LoginData, LoginForm } from "./components/LoginForm";
import { isMFAResponse, login } from "services/AuthService";
import { AuthContext, AuthContextDataType } from "providers/auth.context";
import { Navigate, useNavigate } from "react-router-dom";
import { NewAppPaths } from "../../helpers/paths/paths";
import { ErrorsType } from "../../types";
import { useConfig } from "providers/config.context";
import { NewAuthPageTemplate } from "components/NewAuthPageTemplate";

export const Login: React.FC = () => {
  const { setContextData, isLoggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const { config } = useConfig();
  const navigate = useNavigate();

  const handleLogin = async ({
    email,
    password,
    keepMeLoggedIn,
  }: LoginData) => {
    setLoading(true);
    const result = await login(
      config.REACT_APP_AUTH_BASE_URL,
      email,
      password,
      keepMeLoggedIn
    );
    setLoading(false);
    if (isMFAResponse(result)) {
      return navigate(NewAppPaths.nonAuthorized.MFA, {
        state: {
          email,
          password,
          session: result.Session,
          keepMeLoggedIn,
        },
      });
    } else if ((result as AuthContextDataType).AccessToken) {
      setContextData(result as AuthContextDataType);
    } else {
      setLoginError((result as ErrorsType).Errors[0]);
    }
  };

  if (isLoggedIn) {
    return <Navigate replace to={NewAppPaths.authorized.Home} />;
  }

  return (
    <NewAuthPageTemplate>
      <LoginForm onLogin={handleLogin} loading={loading} />
      {loginError && (
        <Typography variant="caption" color="error" pt={1} display="block">
          {loginError}
        </Typography>
      )}
    </NewAuthPageTemplate>
  );
};
