import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationRevisedProposalForProposedVariationRequestPromptQuery = gql`
  query variationRevisedProposalForProposedVariationRequestPrompt(
    $input: VariationRevisedProposalForProposedVariationRequestPromptInput!
  ) {
    variationRevisedProposalForProposedVariationRequestPrompt(input: $input) {
      remarks
    }
  }
`;

export const sendVariationRevisedProposalForProposedVariationRequestMutation = gql`
  mutation sendVariationRevisedProposalForProposedVariationRequest(
    $input: SendVariationRevisedProposalForProposedVariationRequestInput!
  ) {
    sendVariationRevisedProposalForProposedVariationRequest(input: $input) {
      id
    }
  }
`;

export const recordVariationRevisedProposalForProposedVariationRequestMutation = gql`
  mutation recordVariationRevisedProposalForProposedVariationRequest(
    $input: RecordVariationRevisedProposalForProposedVariationRequestInput!
  ) {
    recordVariationRevisedProposalForProposedVariationRequest(input: $input) {
      id
    }
  }
`;

export const generateSendVariationRevisedProposalForProposedVariationRequestPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationRevisedProposalForProposedVariationRequestPreview(
    $input: SendVariationRevisedProposalForProposedVariationRequestPreviewInput!
  ) {
    generateSendVariationRevisedProposalForProposedVariationRequestPreview(
      input: $input
    ) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationRevisedProposalForProposedVariationRequestPreview(
    $input: RecordVariationRevisedProposalForProposedVariationRequestPreviewInput!
  ) {
    generateRecordVariationRevisedProposalForProposedVariationRequestPreview(
      input: $input
    ) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
