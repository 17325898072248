import { OnMutationDone } from "types/decl";
import {
  AddSchemaFieldInput,
  AddSchemaFieldMutation,
  AddSchemaFieldMutationVariables,
  ChangeSchemaFieldStatusMutation,
  ChangeSchemaFieldStatusMutationVariables,
  EditSchemaFieldInput,
  EditSchemaFieldMutation,
  EditSchemaFieldMutationVariables,
  SchemaField,
  SchemaFieldStatus,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  addSchemaFieldMutation,
  changeSchemaFieldStatusMutation,
  editSchemaFieldMutation,
} from "../../SchemaDetails.query";

export type useSectionFieldsProps = {
  onFieldAdded?: OnMutationDone;
  onFieldStatusChange?: OnMutationDone;
  onFieldEdited?: OnMutationDone;
};

export const useSectionFields = ({
  onFieldAdded,
  onFieldStatusChange,
  onFieldEdited,
}: useSectionFieldsProps) => {
  const { t } = useTranslation();
  const [addSchemaField, { loading: addSchemaFieldLoading }] = useGraphMutation<
    AddSchemaFieldMutation,
    AddSchemaFieldMutationVariables
  >(
    addSchemaFieldMutation,
    {
      update: onFieldAdded,
    },
    t("common.successMessages.entityCreated", {
      entity: t("common.labels.field"),
    })
  );

  const [changeSchemaFieldStatus, { loading: changeSchemaFieldStatusLoading }] =
    useGraphMutation<
      ChangeSchemaFieldStatusMutation,
      ChangeSchemaFieldStatusMutationVariables
    >(
      changeSchemaFieldStatusMutation,
      {
        update: onFieldStatusChange,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.field"),
      })
    );

  const [editSchemaField, { loading: editSchemaFieldLoading }] =
    useGraphMutation<EditSchemaFieldMutation, EditSchemaFieldMutationVariables>(
      editSchemaFieldMutation,
      {
        update: onFieldEdited,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.field"),
      })
    );

  const addField = useCallback(
    async (fieldData: AddSchemaFieldInput) => {
      await addSchemaField({
        variables: {
          input: fieldData,
        },
      });
    },
    [addSchemaField]
  );

  const changeFieldStatus = useCallback(
    async (row: SchemaField, newStatus: SchemaFieldStatus) => {
      await changeSchemaFieldStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeSchemaFieldStatus]
  );

  const editField = useCallback(
    async (fieldData: EditSchemaFieldInput) => {
      await editSchemaField({
        variables: {
          input: fieldData,
        },
      });
    },
    [editSchemaField]
  );

  return {
    loading:
      addSchemaFieldLoading ||
      changeSchemaFieldStatusLoading ||
      editSchemaFieldLoading,
    addField,
    changeFieldStatus,
    editField,
  };
};
