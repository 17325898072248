import { useMemo } from "react";
import { useVariationWidgetNextStepSection } from "./useVariationWidgetNextStepSection";
import { useVariationProductInstance } from "./useVariationProductInstance";

export const useShallDisplayVariationWidget = (
  variationId?: string,
  contractId?: string
) => {
  const { variationProductInstance, loading: variationProdInstanceLoading } =
    useVariationProductInstance(contractId);

  const { variationActionList } =
    useVariationWidgetNextStepSection(variationId);

  const canAccessVariationWidget = useMemo(() => {
    if (variationProdInstanceLoading) {
      return false;
    }

    if (!variationProductInstance) {
      return false;
    }

    return (
      !variationProdInstanceLoading &&
      !!variationProductInstance &&
      (!!variationId || variationActionList.length > 0)
    );
  }, [
    variationProductInstance,
    variationProdInstanceLoading,
    variationActionList,
    variationId,
  ]);

  return canAccessVariationWidget;
};
