import { Attachment } from "generated/graphql";
import { AttachmentsDencity, FileType } from "./Attachments.decl";
import { AttachmentsEditable } from "./components/AttachmentsEditable";
import { AttachmentsReadOnly } from "./components/AttachmentsReadOnly";
import { AttachmentsLayout } from "./components/AttachmentsHeader/AttachmentsHeader";
import { noop } from "helpers/miscelaneous";
import { AttachmentsContextProvider } from "./Attachments.context";

export type AttachmentsProps = {
  editMode: boolean;
  attachments: (Attachment | FileType)[];
  showTabs?: boolean;
  dencity?: AttachmentsDencity;
  defaultLayout?: AttachmentsLayout;
  hideTitle?: boolean;
  hideHeader?: boolean;
  timezone?: string;
  onAttachmentsAdd?: (newAttachments: FileType[]) => void;
  onAttachmentRemove?: (attachment: FileType | Attachment) => void;
  onAttachmentUpdate?: (updatedAttachment: FileType | Attachment) => void;
  onAttachmentClick: (attachment: FileType | Attachment) => void;
};

export const Attachments: React.FC<AttachmentsProps> = ({
  attachments,
  editMode = false,
  showTabs = true,
  dencity = AttachmentsDencity.Comfortable,
  defaultLayout,
  hideTitle = false,
  hideHeader = false,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  onAttachmentsAdd,
  onAttachmentRemove,
  onAttachmentUpdate,
  onAttachmentClick,
}) => {
  return (
    <AttachmentsContextProvider timezone={timezone}>
      {editMode ? (
        <AttachmentsEditable
          attachments={attachments}
          showTabs={showTabs}
          defaultLayout={defaultLayout}
          dencity={dencity}
          hideTitle={hideTitle}
          hideHeader={hideHeader}
          onAttachmentsAdd={onAttachmentsAdd ?? noop}
          onAttachmentRemove={onAttachmentRemove ?? noop}
          onAttachmentUpdate={onAttachmentUpdate ?? noop}
          onAttachmentClick={onAttachmentClick}
        />
      ) : (
        <AttachmentsReadOnly
          attachments={attachments}
          showTabs={showTabs}
          hideTitle={hideTitle}
          hideHeader={hideHeader}
          dencity={dencity}
          onAttachmentClick={onAttachmentClick}
        />
      )}
    </AttachmentsContextProvider>
  );
};
