import { gql } from "@apollo/client";

export const getCompanyLiteByIdQuery = gql`
  query getCompanyLiteById($id: ID) {
    company(id: $id) {
      id
      registeredName
      tradingName
    }
  }
`;
