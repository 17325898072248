import { gql } from "@apollo/client";

export const userPreferencesValueFields = gql`
  fragment UserPreferencesValueFields on UserPreferencesValue {
    inApp
    inAppReadOnly
    email
    emailReadOnly
  }
`;
