import {
  AuthorizationWorkflowCandidatesQuery,
  AuthorizationWorkflowCandidatesQueryVariables,
  ContractStatus,
  User,
  UserStatus,
} from "generated/graphql";
import { useGraphQuery } from "./useGraphQuery";
import { authorizationWorkflowCandidatesQuery } from "containers/AdminConsole/containers/Projects/NewAuthorizationWorkflow/authorizationWorkflowCandidates.query";
import { useMemo } from "react";
import { getUserNameOrEmail } from "helpers/miscelaneous";

export const useAuthorizationWorkflowUserCandidates = (projectId: string) => {
  const {
    data: authorizationWorkflowUserCandidates,
    loading: authorizationWorkflowUserCandidatesLoading,
  } = useGraphQuery<
    AuthorizationWorkflowCandidatesQuery,
    AuthorizationWorkflowCandidatesQueryVariables
  >(authorizationWorkflowCandidatesQuery, {
    variables: { projectId },
  });

  const userCandidates = useMemo(() => {
    let users: User[] = [];

    authorizationWorkflowUserCandidates?.project.contracts.items
      .filter(
        (contract) =>
          [ContractStatus.Active, ContractStatus.Offline].indexOf(
            contract.status
          ) >= 0
      )
      .forEach((contract) => {
        contract.activeBindings.items.forEach((activeBinding) => {
          users = [
            ...(users as User[]),
            ...(activeBinding.company.users.items
              .filter(
                (user) =>
                  [UserStatus.Active, UserStatus.Suspended].indexOf(
                    user.status
                  ) >= 0
              )
              .map((user) => ({
                ...user,
                company: activeBinding.company,
              })) as User[]),
          ];
        });
      });

    const usersObj: { [id: string]: User } = {};

    users.forEach((user) => {
      usersObj[user.id] = user;
    });

    const rawUserCandidates = Object.values(usersObj);
    rawUserCandidates.sort((u1, u2) =>
      getUserNameOrEmail(u1).localeCompare(getUserNameOrEmail(u2))
    );

    return rawUserCandidates;
  }, [authorizationWorkflowUserCandidates]);

  return {
    userCandidates,
    loading: authorizationWorkflowUserCandidatesLoading,
  };
};
