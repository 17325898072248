import { Box, css, styled } from "@mui/material";
import { UploadButton } from "components/UserProfileButton/UploadButton";

export const StyledUploadBtn = styled(UploadButton)<{ fullWidth?: boolean }>(
  ({ theme, fullWidth }) => css`
    border: 1px dashed ${theme.palette.common.black};
    height: 48px;
    background: transparent;
    width: ${fullWidth ? "100%" : "auto"};
  `
);

export const LogoPlaceholder = styled(Box)(
  ({ theme }) => css`
    border: 1px dashed ${theme.palette.common.black};
    height: 48px;
    width: 150px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);
