import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventNoticeOfSilenceOwnAssessmentPreview(
    $input: RecordCompEventNoticeOfSilenceOwnAssessmentPreviewInput!
  ) {
    generateRecordCompEventNoticeOfSilenceOwnAssessmentPreview(input: $input) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
