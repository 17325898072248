import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";
import { draftClaimAgreementFields } from "graphql/fragments/draftClaimAgreement.fragment";

export const claimAgreementNoticePromptQuery = gql`
  ${draftClaimAgreementFields}

  query claimAgreementNoticePrompt($input: ClaimAgreementNoticePromptInput!) {
    claimAgreementNoticePrompt(input: $input) {
      draftClaimAgreements {
        ...DraftClaimAgreementFields
      }
    }
  }
`;

export const sendClaimAgreementNoticeMutation = gql`
  mutation sendClaimAgreementNotice($input: SendClaimAgreementNoticeInput!) {
    sendClaimAgreementNotice(input: $input) {
      id
    }
  }
`;

export const recordClaimAgreementNoticeMutation = gql`
  mutation recordClaimAgreementNotice(
    $input: RecordClaimAgreementNoticeInput!
  ) {
    recordClaimAgreementNotice(input: $input) {
      id
    }
  }
`;

export const generateSendClaimAgreementNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimAgreementNoticePreview(
    $input: SendClaimAgreementNoticePreviewInput!
  ) {
    generateSendClaimAgreementNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimAgreementNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimAgreementNoticePreview(
    $input: RecordClaimAgreementNoticePreviewInput!
  ) {
    generateRecordClaimAgreementNoticePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
