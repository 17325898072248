import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";
import { productItemProductInstanceFields } from "../RiskDetails/RiskDetails.query";

const localEventDetailsFields = gql`
  ${productItemProductInstanceFields}
  ${creatorFields}
  ${notificationRecipientFields}

  fragment LocalEventDetailsFields on EventItem {
    __typename
    id
    title
    data {
      sections {
        name
        entries {
          name
          value
        }
      }
    }
    number
    productInstanceId
    productInstance {
      ...ProductInstanceFields
    }
    ownerId
    statusOptionId
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
    statusOption {
      id
      description
      equatesToRemoved
      statusCollectionId
      isInternal
      status
    }
    owner {
      ...CreatorFields
    }
    dateModified
    notifyDueDate
    attachments {
      id
      fileName
      fileUrl
      mimeType
      description
      locked
      fileSize
      status
      dateCreated
      dateModified
      creatorId
      creator {
        ...CreatorFields
        company {
          registeredName
        }
      }
    }
    compEvent {
      id
    }
    claim {
      id
    }
    notificationRecipients {
      ...NotificationRecipientFields
    }
  }
`;

export const eventItemQuery = gql`
  ${localEventDetailsFields}

  query eventItem($id: ID!) {
    eventItem(id: $id) {
      ...LocalEventDetailsFields
    }
  }
`;

export const editEventItemMutation = gql`
  ${localEventDetailsFields}

  mutation editEventItem($input: EditEventItemInput!) {
    editEventItem(input: $input) {
      ...LocalEventDetailsFields
    }
  }
`;

export const changeEventItemStatusMutation = gql`
  ${localEventDetailsFields}

  mutation changeEventItemStatus($input: ChangeEventItemStatusInput!) {
    changeEventItemStatus(input: $input) {
      ...LocalEventDetailsFields
    }
  }
`;
