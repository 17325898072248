import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const ArrowDownIcon: React.FC<BaseIconProps> = ({
  height = "17",
  width = "17",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color || theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1300_41298)">
        <path
          d="M4.67188 6.66895L8.00521 10.0023L11.3385 6.66895H4.67188Z"
          fill={computedColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1300_41298">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.00390625 0.00244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
