import { Chip, css, styled, ThemeOptions } from "@mui/material";

export const StyledChip = styled(Chip)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    min-width: 30px;
    background-color: ${theme.palette.grey[selected ? 900 : 300]};
  `
);

export const overridenTabComponentStyles: ThemeOptions = {
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          color: "#62707F",
          "&.Mui-selected": {
            fontWeight: 700,
          },
          "&:hover": {
            color: "#252C32",
            boxShadow: "inset 0 -1px #252C32",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          ".MuiTabs-indicator": {
            height: "1px",
            backgroundColor: "#252C32",
          },
          ".MuiTab-root": {
            padding: 0,
            minWidth: "10px",
            marginLeft: "12px",
            marginRight: "12px",
          },
          ".MuiTab-root:first-of-type": {
            marginLeft: "0px",
          },
          ".MuiTabs-scroller": {
            height: "44px",
          },
        },
      },
    },
  },
};
