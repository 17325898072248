import { useGraphQuery } from "hooks/useGraphQuery";
import {
  ClaimItem,
  LocalClaimItemQuery,
  LocalClaimItemQueryVariables,
} from "generated/graphql";
import { ErrorHandlingType } from "hooks/useGraphMutation";
import { localClaimItemQuery } from "./useClaim.query";

export const useClaim = (id?: string) => {
  const {
    data: claimItem,
    refetch,
    loading,
    error,
  } = useGraphQuery<LocalClaimItemQuery, LocalClaimItemQueryVariables>(
    localClaimItemQuery,
    {
      variables: { id: id! },
      skip: !id,
      notifyOnNetworkStatusChange: true,
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  return {
    claim: claimItem?.claimItem as ClaimItem | undefined,
    refetchClaim: refetch,
    claimLoading: loading,
    error,
  };
};
