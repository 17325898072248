import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventRequestForMoreTimeMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventRequestForMoreTime(
    $input: SendCompEventRequestForMoreTimeInput!
  ) {
    sendCompEventRequestForMoreTime(input: $input) {
      ...CompEventItemFields
    }
  }
`;
