import { Box, Breadcrumbs, Typography } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import { ProfilePicture } from "components/UserProfileButton/ProfilePicture";
import { Notification, NotificationStatus } from "generated/graphql";
import moment from "moment";
import { useState } from "react";
import { NotificationContainer } from "./Notifications.styled";
import { NotificationTextContent } from "./NotificationTextContent";
import { ReadUnreadButton } from "./ReadButton";

export type NotificationListItemProps = {
  notification: Notification;
  onClick: () => void;
  onReadUnreadNotification: (action: "read" | "unread") => void;
};

export const NotificationListItem: React.FC<NotificationListItemProps> = ({
  notification,
  onClick,
  onReadUnreadNotification,
}) => {
  const [readUnreadBtnHovered, setReadUnreadBtnHovered] =
    useState<boolean>(false);

  return (
    <NotificationContainer
      unread={notification.status === NotificationStatus.UnRead}
      readUnreadBtnHovered={readUnreadBtnHovered}
    >
      <Box display="flex" flexDirection="column" flex="1">
        <Box ml={3.75}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography variant="p3" color="grey.500">
              {notification.project.friendlyName}
            </Typography>
            <Typography variant="p3" color="grey.500">
              {notification.contract.friendlyName}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box display="flex" mt={1.5} alignItems="center">
          <ReadUnreadButton
            onClick={onReadUnreadNotification}
            btnStyle={
              notification.status === NotificationStatus.Read
                ? "unread"
                : "read"
            }
            mr={1.5}
            mt={1}
            alignSelf="flex-start"
            onMouseEnter={() => setReadUnreadBtnHovered(true)}
            onMouseLeave={() => setReadUnreadBtnHovered(false)}
          />
          <Box alignSelf="flex-start">
            <ProfilePicture
              width="40px"
              height="40px"
              photoUrl={notification.creator.profilePicture ?? undefined}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            ml={2}
            mr={1}
            flex={1}
            alignSelf="flex-start"
          >
            <NotificationTextContent
              notification={notification}
              onClick={onClick}
            />
          </Box>
          <ItemTag
            type={notification.productType}
            ml="auto"
            alignSelf="flex-start"
            mt={1}
          />
        </Box>
        <Typography variant="p3" color="grey.500" mt={1} ml="86px">
          {moment(notification.dateCreated)
            .tz(notification.contract.timeZone)
            .format("D MMMM YYYY, HH:mm")}
        </Typography>
      </Box>
    </NotificationContainer>
  );
};
