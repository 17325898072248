import { OnMutationDone } from "types/decl";
import {
  ChangeEffectPresetStatusMutation,
  ChangeEffectPresetStatusMutationVariables,
  EffectPreset,
  EffectPresetStatus,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { changeEffectPresetStatusMutation } from "../../Effect.query";

export const useChangeEffectPresetStatus = (onStatusChange: OnMutationDone) => {
  const { t } = useTranslation();

  const [
    doChangeEffectPresetStatus,
    { loading: changeEffectPresetStatusLoading },
  ] = useGraphMutation<
    ChangeEffectPresetStatusMutation,
    ChangeEffectPresetStatusMutationVariables
  >(
    changeEffectPresetStatusMutation,
    {
      update: onStatusChange,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Effect.labels.effectPreset"),
    })
  );

  const changeEffectPresetStatus = async (
    effectPreset: EffectPreset,
    newStatus: EffectPresetStatus
  ) => {
    await doChangeEffectPresetStatus({
      variables: {
        id: effectPreset.id,
        status: newStatus,
      },
    });
  };

  return {
    loading: changeEffectPresetStatusLoading,
    changeEffectPresetStatus,
  };
};
