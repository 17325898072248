import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const dailyDiaryItemSubmissionFields = gql`
  ${creatorFields}

  fragment DailyDiaryItemSubmissionFields on DailyDiaryItemSubmission {
    userId
    date
    user {
      ...CreatorFields
    }
  }
`;
