import {
  PickersCalendarHeader,
  StaticDatePickerProps,
} from "@mui/x-date-pickers";
import { ImportRecordsDatePicker } from "./ImportRecordsModal.styled";
import { WeekDays } from "./ImportRecordsModal.types";
import { useTranslation } from "react-i18next";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import moment from "moment";

type CalendarViewProps = {
  availableDates: Date[];
} & StaticDatePickerProps<Date>;

export const CalendarView = (props: CalendarViewProps) => {
  const { availableDates } = props;
  const { t } = useTranslation();

  const shouldDisableDate = (day: Date) =>
    !availableDates.find((availableDate) => {
      return moment(availableDate).isSame(moment(day), "date");
    });

  return (
    <ImportRecordsDatePicker
      orientation="landscape"
      shouldDisableDate={shouldDisableDate}
      openTo="day"
      renderLoading={() => <CenteredLoadingIndicator size={18} />}
      showDaysOutsideCurrentMonth
      disableFuture
      disableHighlightToday
      dayOfWeekFormatter={(day) =>
        t(`common.weekDays.${WeekDays[day.getDay()]}`).toUpperCase()
      }
      slots={{
        toolbar: undefined,
        actionBar: () => <></>,
        calendarHeader: (props) => (
          <PickersCalendarHeader
            {...props}
            slots={{
              switchViewButton: () => <></>,
            }}
          />
        ),
      }}
      {...props}
    />
  );
};
