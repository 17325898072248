import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import {
  RecordCompEventRejectionMutation,
  RecordCompEventRejectionMutationVariables,
  SendCompEventRejectionMutation,
  SendCompEventRejectionMutationVariables,
} from "generated/graphql";
import { recordCompEventRejectionMutation } from "graphql/mutations/recordCompEventRejection";
import { sendCompEventRejectionMutation } from "graphql/mutations/sendCompEventRejection";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const useRejectCEAction = () => {
  const { onChange, compEvent } = useContext(CompEventWidgetContext);
  const { t } = useTranslation();

  const [sendCompEventRejection, { loading: sendCompEventRejectionLoading }] =
    useGraphMutation<
      SendCompEventRejectionMutation,
      SendCompEventRejectionMutationVariables
    >(
      sendCompEventRejectionMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "compEventHistoryItems",
            args: { compEventId: compEvent!.id },
          });
          cache.gc();
          onChange?.();
        },
      },
      t("common.successMessages.entitySent", {
        entity: t("Projects.CompEvents.compEventRejection"),
      })
    );

  const [
    recordCompEventRejection,
    { loading: recordCompEventRejectionLoading },
  ] = useGraphMutation<
    RecordCompEventRejectionMutation,
    RecordCompEventRejectionMutationVariables
  >(
    recordCompEventRejectionMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventRejection"),
    })
  );

  return {
    actionLoading:
      sendCompEventRejectionLoading || recordCompEventRejectionLoading,
    sendCompEventRejection,
    recordCompEventRejection,
  };
};
