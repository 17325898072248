import { InputBaseComponentProps } from "@mui/material";
import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

export const FormattedNumericInput = forwardRef<any, any>(
  function FormattedNumericInput(props, ref) {
    const {
      onChange,
      prefix,
      type,
      thousandSeparator = true,
      ...other
    } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        type={type ?? undefined}
        thousandSeparator={thousandSeparator}
        valueIsNumericString
      />
    );
  }
);

export const NumericInputNoSeparator = forwardRef(
  (props: InputBaseComponentProps, ref) => (
    <FormattedNumericInput {...props} ref={ref} thousandSeparator={false} />
  )
);
