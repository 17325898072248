import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const AuthorizeDenyModalTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography variant="h3" fontWeight={600} color="grey.800">
        {t("Home.Authorizations.AuthorizeDenyModal.title")}
      </Typography>
      <Typography variant="p1" color="grey.700" mt={1}>
        {t("Home.Authorizations.AuthorizeDenyModal.subtitle")}
      </Typography>
    </Stack>
  );
};
