import { gql } from "@apollo/client";

export const addRiskItemMutation = gql`
  mutation addRiskItem($input: AddRiskItemInput!, $draftId: ID) {
    addRiskItem(input: $input, draftId: $draftId) {
      id
      title
      severity
      number
      productInstanceId
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
      statusOption {
        id
        description
        equatesToRemoved
        statusCollectionId
        isInternal
        status
      }
      owner {
        id
        firstname
        surname
      }
      dateModified
    }
  }
`;

export const addDraftRiskItemMutation = gql`
  mutation addDraftRiskItem($input: AddRiskItemInput!) {
    addDraftRiskItem(input: $input) {
      id
      title
      severity
      number
      productInstanceId
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
      statusOption {
        id
        description
        equatesToRemoved
        statusCollectionId
        isInternal
        status
      }
      owner {
        id
        firstname
        surname
      }
      dateModified
    }
  }
`;

export const editDraftRiskItemMutation = gql`
  mutation editDraftRiskItem($input: EditRiskItemInput!) {
    editDraftRiskItem(input: $input) {
      id
      title
      severity
      number
      productInstanceId
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
      statusOption {
        id
        description
        equatesToRemoved
        statusCollectionId
        isInternal
        status
      }
      owner {
        id
        firstname
        surname
      }
      dateModified
    }
  }
`;

export const draftRiskItemQuery = gql`
  query draftRiskItem($id: ID!) {
    draftRiskItem(id: $id) {
      id
      title
      data {
        sections {
          name
          entries {
            name
            value
          }
        }
      }
      severity
      number
      productInstanceId
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
      statusOption {
        id
        description
        equatesToRemoved
        statusCollectionId
        isInternal
        status
      }
      owner {
        id
        firstname
        surname
      }
      dateModified
      attachments {
        id
        fileName
        fileUrl
        mimeType
        description
        locked
        fileSize
        status
        dateCreated
        dateModified
        creatorId
        creator {
          id
          firstname
          surname
        }
      }
    }
  }
`;
