import { Box, Grid, Typography } from "@mui/material";
import {
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { FormLabel } from "components/FormLabel";
import { useTranslation } from "react-i18next";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { VariationActionFormProps } from "../VariationActionView";
import { useConfirmInstructionOfVariationAction } from "./useConfirmInstructionOfVariationAction";
import { SendInstructionOfVariationConfirmationModal } from "./SendInstructionOfVariationConfirmationModal";
import { RecordInstructionOfVariationConfirmationModal } from "./RecordInstructionOfVariationConfirmationModal";

const dataValidators: DataValidators = {
  remarks: {
    validators: [ValidatorType.RequiredRichText],
  },
};

type FormDataType = {
  remarks: string;
  // attachments?: AttachmentInput[];
};

const defaultFormData: FormDataType = {
  remarks: "",
  // attachments: [],
};

export const ConfirmInstructionOfVariationAction: React.FC<
  VariationActionFormProps
> = ({ apiRef, onClose }) => {
  const { t } = useTranslation();
  const { variation } = useContext(VariationWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendVariationConfirmation,
    recordVariationConfirmation,
    actionLoading,
  } = useConfirmInstructionOfVariationAction();

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handlesendVariationConfirmation = async () => {
    await sendVariationConfirmation({
      variables: {
        input: {
          variationId: variation?.id!,
          remarks: formData.remarks,
          // attachments: formData.attachments,
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handlerecordVariationConfirmation = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordVariationConfirmation({
      variables: {
        input: {
          variationId: variation?.id!,
          remarks: formData.remarks,
          // attachments: formData.attachments,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const handleTextFieldChange = (remarks: string) => {
    setFormData((curData) => ({
      ...curData,
      remarks,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { remarks: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const result = validateData(formData, dataValidators);

    if (result.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(result.errors);
    }

    return result.valid;
  }, []);

  const isFormValid = useMemo(() => !!formData.remarks, [formData]);

  // const handleAttachmentsUpdated = async (
  //   attachmentsUpdated: AttachmentInput[]
  // ) => {
  //   setFormData((crtFormData) => ({
  //     ...crtFormData,
  //     attachments: attachmentsUpdated,
  //   }));
  // };

  // const {
  //   allAttachments,
  //   addAttachments,
  //   removeAttachment,
  //   updateAttachment,
  //   downloadAttachment,
  //   unloadLocalAttachments,
  // } = useAttachments([], handleAttachmentsUpdated); // always starting from empty attachments.

  // const handleBeforeAbort = useCallback(() => {
  //   // consider flow aborted. Remove all allegedly added attachments from the server
  //   unloadLocalAttachments();
  // }, [unloadLocalAttachments]);

  // const {
  //   imageAttachmentPreviewModalVisible,
  //   imagePreviewData,
  //   previewUrl,
  //   handleAttachmentClick,
  //   closeModal: closeImagePreviewModal,
  // } = useImagePreviewModal(downloadAttachment);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
      onBeforeAbort: () => {},
      // onBeforeAbort: handleBeforeAbort,
    }),
    [formData, validateForm]
  );

  return (
    <>
      {/* <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      /> */}
      {isFormValid && sendModalVisibility && (
        <SendInstructionOfVariationConfirmationModal
          open={sendModalVisibility}
          remarks={formData.remarks}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handlesendVariationConfirmation}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordInstructionOfVariationConfirmationModal
          open={recordModalVisibility}
          remarks={formData.remarks}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handlerecordVariationConfirmation}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.remarks")} required />
            <RichTextArea
              content={formData.remarks}
              error={!!formDataErrors.remarks}
              onChange={handleTextFieldChange}
            />
            {!!formDataErrors.remarks && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.remarks}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <Attachments
              editMode
              showTabs={false}
              timezone={}
              dencity={AttachmentsDencity.Compact}
              defaultLayout={AttachmentsLayout.List}
              attachments={allAttachments}
              onAttachmentsAdd={addAttachments}
              onAttachmentRemove={removeAttachment}
              onAttachmentUpdate={updateAttachment}
              onAttachmentClick={handleAttachmentClick}
            />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};
