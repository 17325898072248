import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const lookupOptionFields = gql`
  ${creatorFields}

  fragment LookupOptionFields on LookupOption {
    id
    value
    displayOrder
    collectionId
    isInternal
    status
    creatorId
    dateCreated
    creator {
      ...CreatorFields
    }
  }
`;
