import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimNoticePromptQuery = gql`
  query claimNoticePrompt($input: ClaimNoticePromptInput!) {
    claimNoticePrompt(input: $input) {
      claimTypes {
        id
        name
      }
      advanceWarnings {
        id
        name
      }
    }
  }
`;

export const sendClaimNoticeMutation = gql`
  mutation sendClaimNotice($input: SendClaimNoticeInput!) {
    sendClaimNotice(input: $input) {
      id
    }
  }
`;

export const recordClaimNoticeMutation = gql`
  mutation recordClaimNotice($input: RecordClaimNoticeInput!) {
    recordClaimNotice(input: $input) {
      id
    }
  }
`;

export const generateSendClaimNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimNoticePreview(
    $input: SendClaimNoticePreviewInput!
  ) {
    generateSendClaimNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimNoticePreview(
    $input: RecordClaimNoticePreviewInput!
  ) {
    generateRecordClaimNoticePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
