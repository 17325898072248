import { Stack, Typography, TypographyProps, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AttachmentsDencity } from "../Attachments.decl";
import { Paperclip } from "phosphor-react";

export type AttachmentsTitleProps = {
  attachmentsCount?: number;
  dencity?: AttachmentsDencity;
} & TypographyProps;

export const AttachmentsTitle: React.FC<AttachmentsTitleProps> = ({
  attachmentsCount,
  dencity,
  ...restTypographyProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      mb={dencity === AttachmentsDencity.Compact ? 2 : 4}
    >
      <Paperclip color={theme.palette.grey[800]} size={24} />
      <Typography
        variant={dencity === AttachmentsDencity.Compact ? "h4" : "h3"}
        fontWeight={600}
        {...restTypographyProps}
      >
        {t("Attachments.attachments")}{" "}
        {attachmentsCount ? `(${attachmentsCount})` : ""}
      </Typography>
    </Stack>
  );
};
