import {
  ClaimItem,
  ClaimItemsRegisterQuery,
  ClaimItemsRegisterQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { claimItemsRegisterQuery } from "./claimItemsRegister.query";
import { useMemo } from "react";

export const useClaimsRegister = () => {
  const [
    fetchClaimItemsData,
    { data: claimItemsData, loading: claimItemsDataLoading },
  ] = useGraphLazyQuery<
    ClaimItemsRegisterQuery,
    ClaimItemsRegisterQueryVariables
  >(claimItemsRegisterQuery);

  const items = useMemo(
    () => (claimItemsData?.claimItems.items ?? []) as ClaimItem[],
    [claimItemsData]
  );

  return {
    claimItemsData: items,
    claimItemsDataLoading,
    fetchClaimItemsData,
  };
};
