import { gql } from "@apollo/client";
import { productItemProductInstanceFields } from "../RiskDetails/RiskDetails.query";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";

export const attachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id
    fileName
    fileUrl
    mimeType
    description
    locked
    fileSize
    status
    dateCreated
    dateModified
    creatorId
    creator {
      id
      firstname
      surname
      company {
        registeredName
      }
    }
  }
`;

export const ewSubTypesFields = gql`
  fragment StatusOptionFields on ItemStatusOption {
    id
    description
    equatesToRemoved
    statusCollectionId
    isInternal
    status
  }

  fragment LightUserFields on User {
    id
    firstname
    surname
  }
`;

const localEarlyWarningFields = gql`
  ${ewSubTypesFields}
  ${productItemProductInstanceFields}
  ${attachmentFields}
  ${notificationRecipientFields}

  fragment LocalEarlyWarningFields on EarlyWarningItem {
    __typename
    id
    title
    severity
    data {
      sections {
        name
        entries {
          name
          value
        }
      }
    }
    number
    productInstanceId
    ownerId
    statusOptionId
    dateCreated
    creatorId
    creator {
      ...LightUserFields
    }
    statusOption {
      ...StatusOptionFields
    }
    owner {
      ...LightUserFields
    }
    productInstance {
      ...ProductInstanceFields
    }
    dateModified
    attachments {
      ...AttachmentFields
    }
    offline
    givenById
    dateSent
    authorizationWorkflowAudit {
      id
    }
    notificationRecipients {
      ...NotificationRecipientFields
    }
  }
`;

export const earlyWarningItemQuery = gql`
  ${localEarlyWarningFields}

  query earlyWarningItem($id: ID!) {
    earlyWarningItem(id: $id) {
      ...LocalEarlyWarningFields
    }
  }
`;

export const editEarlyWarningItemMutation = gql`
  ${localEarlyWarningFields}

  mutation editEarlyWarningItem($input: EditEarlyWarningItemInput!) {
    editEarlyWarningItem(input: $input) {
      ...LocalEarlyWarningFields
    }
  }
`;

export const changeEarlyWarningItemStatusMutation = gql`
  ${localEarlyWarningFields}

  mutation changeEarlyWarningItemStatus(
    $input: ChangeEarlyWarningItemStatusInput!
  ) {
    changeEarlyWarningItemStatus(input: $input) {
      ...LocalEarlyWarningFields
    }
  }
`;
