import {
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import { useCallback, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { exportToExcel } from "../../../../../helpers/exportToExcel";
import { getUserName } from "helpers/miscelaneous";
import { useColumns } from "./AuthorizationsRequiredFromYouTable.constants";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { computeRegardingColumnText } from "../Authorizations.utils";
import { excelDateTimeISOFormat } from "constants/constants";
import { getDatetimeOnTz } from "helpers/timezones";

export type AuthorizationsRequiredFromYouTableProps = {
  audits: AuthorizationWorkflowAudit[];
  loading: boolean;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
  onRowClick: (rowData: AuthorizationWorkflowAudit) => void;
};

export const AuthorizationsRequiredFromYouTable: React.FC<
  AuthorizationsRequiredFromYouTableProps
> = ({
  audits,
  loading,
  apiRef,
  selectionModel,
  onSelectionModelChange,
  onRowClick,
}) => {
  const { t } = useTranslation();

  const columns = useColumns();

  const gridApiRef = useGridApiRef();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "initiated", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = useCallback(async () => {
    if (!audits || !audits.length) {
      console.warn("No records to export");
      return;
    }

    const columns = [
      {
        header: t("AuthorizationWorkflows.action"),
        key: "action",
      },
      { header: t("common.labels.regarding"), key: "regarding" },
      {
        header: t("common.labels.project"),
        key: "project",
      },
      {
        header: t("common.labels.contract"),
        key: "contract",
      },
      {
        header: t("Home.Authorizations.initiated"),
        key: "initiated",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("Home.Authorizations.initiator"),
        key: "initiator",
      },
    ];

    const rows = audits
      .filter((audit) => (selectionModel || []).indexOf(audit.id) >= 0)
      .map((audit) => ({
        ...audit,
        action: audit.action.productOutputAction.name,
        regarding: computeRegardingColumnText(audit),
        project: audit.action.productInstance.contract.project.friendlyName,
        contract: audit.action.productInstance.contract.friendlyName,
        initiated: getDatetimeOnTz(
          audit.dateInitiated,
          audit.regardingProductItem.productInstance.contract.timeZone
        ),
        initiator: getUserName(audit.initiatedBy),
      }));

    exportToExcel(
      t("Home.Authorizations.authorizationsRequredFromYou"),
      columns,
      rows
    );
  }, [audits, selectionModel, t]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<AuthorizationWorkflowAudit>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        onRowClick(rowData.row);
      }
    },
    [onRowClick]
  );

  const handleClearSelection = useCallback(() => {
    onSelectionModelChange([]);
  }, [onSelectionModelChange]);

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    onSelectionModelChange(newSelectionModel);
  };

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={audits}
      columns={columns}
      getRowId={(rowData: AuthorizationWorkflowAudit) => rowData.id}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading}
      sortingMode="client"
      sortModel={sortingModel}
      onSortModelChange={setSortingModel}
      filterMode="client"
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onRowClick={handleRowClick}
      checkboxSelection
      autoHeight
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
