import { Box, ClickAwayListener, Fade, Popper, useTheme } from "@mui/material";
import { NotificationStatus } from "generated/graphql";
import { BellSimple } from "phosphor-react";
import { useCallback, useEffect, useState } from "react";
import { CountBadge } from "../../CountBadge";
import {
  BadgeContainer,
  NotificationsIconContainer,
} from "./Notifications.styled";
import { NotificationsPopperContent } from "./NotificationsPopperContent";
import { useNotifications } from "./useNotifications";

export const Notifications: React.FC = () => {
  const {
    notifications,
    loading,
    unreadNotificationsCount,
    hasMore,
    newUnloadedNotifications,
    error,
    loadMore,
    startFresh,
    markNotification,
    markAllNotificationsAsRead,
  } = useNotifications();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "transition-popper" : undefined;

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleReadUnreadNotification = (
    notificationId: string,
    action: "read" | "unread"
  ) => {
    markNotification(
      notificationId,
      action === "read" ? NotificationStatus.Read : NotificationStatus.UnRead
    );
  };

  const handleStartFresh = () => {
    startFresh();
    loadMore();
  };

  useEffect(() => {
    if (!open) {
      startFresh();
    }
  }, [open, startFresh]);

  useEffect(() => {
    if (open) {
      loadMore();
    }
  }, [open, loadMore]);

  useEffect(() => {
    if (error) {
      // close the popup
      setAnchorEl(null);
    }
  }, [error]);

  const hasUnreadNotifications = !!unreadNotificationsCount;

  const theme = useTheme();

  return (
    <Box>
      <BadgeContainer onClick={handleClick}>
        <NotificationsIconContainer active={open}>
          <BellSimple size={16} color={theme.palette.grey[700]} />
        </NotificationsIconContainer>
        {hasUnreadNotifications && (
          <CountBadge count={unreadNotificationsCount!} />
        )}
      </BadgeContainer>
      {open && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom-end"
          disablePortal={false}
          sx={{ zIndex: 20 }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "offset",
              options: {
                offset: [100, 5],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <NotificationsPopperContent
                  loading={loading}
                  notifications={notifications}
                  hasMore={hasMore}
                  newUnloadedNotifications={newUnloadedNotifications}
                  onStartFresh={handleStartFresh}
                  onLoadMoreNotifications={loadMore}
                  onCloseNotificationsPopup={handleClickAway}
                  onReadUnreadNotification={handleReadUnreadNotification}
                  onMarkAllNotificationsAsRead={markAllNotificationsAsRead}
                />
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </Box>
  );
};
