import { useAuthorizationsRequiredFromYou } from "./useAuthorizationsRequiredFromYou";
import { AuthorizationsRequiredFromYouTable } from "./AuthorizationsRequiredFromYouTable";
import { useRef, useState } from "react";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { TableHeaderSelectView } from "components/TableHeaderSelectView";
import {
  AuthorizationWorkflowAudit,
  DailyDiaryItem,
  ProductType,
} from "generated/graphql";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NewAppPaths } from "helpers/paths/paths";
import { TableContainer } from "../../TableContainer";
import { useAuthStore } from "state-management/store/authStore";

export const AuthorizationsRequiredFromYou: React.FC = () => {
  const navigate = useNavigate();
  const setAuthModalState = useAuthStore((state) => state.setAuthModalState);

  const tableRef = useRef<ProductItemsTablePublicAPI>(null);
  const [tableSelectionModel, setTableSelectionModel] =
    useState<GridRowSelectionModel>();

  const { authorizationsRequiredFromYou, loading } =
    useAuthorizationsRequiredFromYou();

  const handleClearSelection = () => {
    tableRef.current?.clearSelection();
  };

  const handleExportToExcel = () => {
    tableRef.current?.exportToExcel();
  };

  const handleAuthorizationClick = (authAudit: AuthorizationWorkflowAudit) => {
    if (authAudit.regardingProductType === ProductType.DailyDiary) {
      const dailyDiaryItem = authAudit.regardingProductItem as DailyDiaryItem;

      navigate(
        NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
          dailyDiaryItem.productInstance.id,
          dailyDiaryItem.date,
          dailyDiaryItem.id
        )
      );
    } else {
      setAuthModalState({
        authAuditId: authAudit.id,
        type: "AuthorizeDeny",
        compEventId:
          authAudit.regardingProductType === ProductType.CompEvents
            ? authAudit.regardingProductItemId
            : undefined,
        claimId:
          authAudit.regardingProductType === ProductType.Claims
            ? authAudit.regardingProductItemId
            : undefined,
        variationId:
          authAudit.regardingProductType === ProductType.Variations
            ? authAudit.regardingProductItemId
            : undefined,
      });
    }
  };

  return (
    <>
      <TableContainer data-testid="auth-container-required-from-you">
        {tableSelectionModel?.length ? (
          <Box mb={3}>
            <TableHeaderSelectView
              selectedItemsCount={tableSelectionModel?.length ?? 0}
              onClearSelection={handleClearSelection}
              onExportToExcel={handleExportToExcel}
            />
          </Box>
        ) : null}
        <AuthorizationsRequiredFromYouTable
          selectionModel={tableSelectionModel}
          audits={authorizationsRequiredFromYou}
          loading={loading}
          apiRef={tableRef}
          onSelectionModelChange={setTableSelectionModel}
          onRowClick={handleAuthorizationClick}
        />
      </TableContainer>
    </>
  );
};
