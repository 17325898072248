import { DocumentNode, WatchQueryFetchPolicy } from "@apollo/client";
import {
  AddCommentMutation,
  AddCommentMutationVariables,
  EarlyWarningItemCommentsQuery,
  EarlyWarningItemCommentsQueryVariables,
  ReplyCommentMutation,
  ReplyCommentMutationVariables,
  RiskItemCommentsQuery,
  RiskItemCommentsQueryVariables,
  Comment,
  EventItemCommentsQuery,
  EventItemCommentsQueryVariables,
  InstructionItemCommentsQuery,
  InstructionItemCommentsQueryVariables,
  ProductType,
  AddCommentInput,
  ReplyCommentInput,
  CommentReply,
  DailyDiaryItemCommentsQuery,
  DailyDiaryItemCommentsQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";
import isNumeric from "validator/lib/isNumeric";
import {
  addCommentMutation,
  dailyDiaryItemCommentsQuery,
  eventItemCommentsQuery,
  ewItemCommentsQuery,
  instructionItemCommentsQuery,
  replyCommentMutation,
  riskItemCommentsQuery,
} from "./Comments.query";

const commentsBatchSize = 10;
const queryOptions = {
  fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
  notifyOnNetworkStatusChange: true,
};

export type LocalCommentReply = CommentReply & {
  isOptimistic?: boolean;
};

export type LocalComment = Omit<Comment, "replies"> & {
  replies: {
    __typename: "CommentReplyList";
    items: LocalCommentReply[];
  };
  isOptimistic?: boolean;
};

export const useComments = (
  productItemId: string,
  productType: ProductType,
  versionId?: string
) => {
  const { authenticatedUser } = useContext(GlobalContext);
  const nextTokenRef = useRef<string>();
  const [hasMore, setHasMore] = useState(false);

  const [
    loadRiskComments,
    {
      data: riskItemComments,
      fetchMore: fetchMoreRiskComments,
      loading: riskItemCommentsLoading,
    },
  ] = useGraphLazyQuery<RiskItemCommentsQuery, RiskItemCommentsQueryVariables>(
    riskItemCommentsQuery,
    queryOptions
  );

  const [
    loadEWComments,
    {
      data: ewItemComments,
      fetchMore: fetchMoreEWComments,
      loading: ewItemCommentsLoading,
    },
  ] = useGraphLazyQuery<
    EarlyWarningItemCommentsQuery,
    EarlyWarningItemCommentsQueryVariables
  >(ewItemCommentsQuery, queryOptions);

  const [
    loadEventComments,
    {
      data: eventItemComments,
      fetchMore: fetchMoreEventComments,
      loading: eventItemCommentsLoading,
    },
  ] = useGraphLazyQuery<
    EventItemCommentsQuery,
    EventItemCommentsQueryVariables
  >(eventItemCommentsQuery, queryOptions);

  const [
    loadInstructionComments,
    {
      data: instructionItemComments,
      fetchMore: fetchMoreInstructionComments,
      loading: instructionItemCommentsLoading,
    },
  ] = useGraphLazyQuery<
    InstructionItemCommentsQuery,
    InstructionItemCommentsQueryVariables
  >(instructionItemCommentsQuery, queryOptions);

  const [
    loadDailyDiaryComments,
    {
      data: dailyDiaryItemComments,
      fetchMore: fetchMoreDailyDiaryComments,
      loading: dailyDiaryItemCommentsLoading,
    },
  ] = useGraphLazyQuery<
    DailyDiaryItemCommentsQuery,
    DailyDiaryItemCommentsQueryVariables
  >(dailyDiaryItemCommentsQuery, queryOptions);

  const comments = useMemo(() => {
    let rawComments: Comment[];
    let nextToken: string | undefined | null;

    switch (productType) {
      case ProductType.RisksRegister:
        rawComments = (riskItemComments?.riskItem.comments.items ??
          []) as Comment[];
        nextToken = riskItemComments?.riskItem.comments.nextToken;
        break;
      case ProductType.EarlyWarnings:
        rawComments = (ewItemComments?.earlyWarningItem?.comments.items ??
          []) as Comment[];
        nextToken = ewItemComments?.earlyWarningItem?.comments.nextToken;
        break;
      case ProductType.Events:
        rawComments = (eventItemComments?.eventItem.comments.items ??
          []) as Comment[];
        nextToken = eventItemComments?.eventItem.comments.nextToken;
        break;
      case ProductType.Instructions:
        rawComments = (instructionItemComments?.instructionItem?.comments
          .items ?? []) as Comment[];
        nextToken =
          instructionItemComments?.instructionItem?.comments.nextToken;
        break;
      case ProductType.DailyDiary: {
        rawComments = (dailyDiaryItemComments?.dailyDiaryItemComments.items ??
          []) as Comment[];
        if (dailyDiaryItemComments) {
          nextToken = dailyDiaryItemComments.dailyDiaryItemComments.nextToken;
        }
        break;
      }
      default:
        return [];
    }

    setHasMore(!!nextToken);

    return rawComments.map((comment) => ({
      ...comment,
      replies: {
        ...comment.replies,
        items: comment.replies.items.map((commentReply) => ({
          ...commentReply,
          isOptimistic: isNumeric(commentReply.id),
        })),
      },
      isOptimistic: isNumeric(comment.id),
    })) as LocalComment[];
  }, [
    productType,
    riskItemComments,
    ewItemComments,
    eventItemComments,
    instructionItemComments,
    dailyDiaryItemComments,
  ]);

  const [addComment, { loading: addCommentLoading }] = useGraphMutation<
    AddCommentMutation,
    AddCommentMutationVariables
  >(
    addCommentMutation,
    {
      update: (cache, data) => {
        if (data.data?.addComment) {
          const newComment = data.data?.addComment;
          let commentsQuery: DocumentNode;
          let queryName: string | undefined;

          switch (productType) {
            case ProductType.RisksRegister:
              commentsQuery = riskItemCommentsQuery;
              queryName = "riskItem";
              break;
            case ProductType.EarlyWarnings:
              commentsQuery = ewItemCommentsQuery;
              queryName = "earlyWarningItem";
              break;
            case ProductType.Events:
              commentsQuery = eventItemCommentsQuery;
              queryName = "eventItem";
              break;
            case ProductType.Instructions:
              commentsQuery = instructionItemCommentsQuery;
              queryName = "instructionItem";
              break;
            case ProductType.DailyDiary:
              commentsQuery = dailyDiaryItemCommentsQuery;
              queryName = "dailyDiaryItem";
              break;
          }

          const queryVariables = {
            limit: commentsBatchSize,
            nextToken: nextTokenRef.current,
            ...(productType === ProductType.DailyDiary
              ? {
                  dailyDiaryId: productItemId,
                  revisionId: versionId,
                }
              : {
                  id: productItemId,
                }),
          };

          const queryData = cache.readQuery({
            query: commentsQuery!,
            variables: queryVariables,
          });

          if (queryData) {
            // update query cached data only queryData exists; AKA, only if the latest comments page is fetched
            cache.updateQuery(
              {
                query: commentsQuery!,
                variables: queryVariables,
              },
              (data) => {
                return productType === ProductType.DailyDiary
                  ? {
                      ...data,
                      dailyDiaryItemComments: {
                        ...data.dailyDiaryItemComments,
                        items: [
                          ...data.dailyDiaryItemComments.items,
                          newComment,
                        ],
                      },
                    }
                  : {
                      ...data,
                      [queryName!]: {
                        ...data[queryName!],
                        comments: {
                          ...data[queryName!].comments,
                          items: [
                            ...data[queryName!].comments.items,
                            newComment,
                          ],
                        },
                      },
                    };
              }
            );
          }
        }
      },
    },
    null
  );

  const [replyComment, { loading: replyCommentLoading }] = useGraphMutation<
    ReplyCommentMutation,
    ReplyCommentMutationVariables
  >(
    replyCommentMutation,
    {
      update: (cache, data) => {
        if (data.data?.replyComment) {
          const newReplyComment = data.data?.replyComment;

          const cacheItemIdentifier = cache.identify({
            __typename: "Comment",
            id: newReplyComment.commentId,
          });

          cache.modify({
            id: cacheItemIdentifier,
            fields: {
              replies(oldReplies) {
                return {
                  ...oldReplies,
                  items: [...oldReplies.items, newReplyComment],
                };
              },
            },
          });
        }
      },
    },
    null
  );

  const loadComments = useCallback(
    async (productType: ProductType, productItemId: string) => {
      switch (productType) {
        case ProductType.RisksRegister: {
          let data: RiskItemCommentsQuery | undefined;

          if (nextTokenRef.current) {
            const result = await fetchMoreRiskComments({
              variables: {
                id: productItemId,
                limit: commentsBatchSize,
                nextToken: nextTokenRef.current,
              },
              updateQuery: (oldData, { fetchMoreResult: newData }) => ({
                ...newData,
                riskItem: {
                  ...newData.riskItem,
                  comments: {
                    ...newData.riskItem.comments,
                    items: [
                      ...oldData.riskItem.comments.items,
                      ...newData.riskItem.comments.items,
                    ],
                  },
                },
              }),
            });

            data = result.data;
          } else {
            const result = await loadRiskComments({
              variables: {
                id: productItemId,
                limit: commentsBatchSize,
              },
            });
            data = result.data;
          }

          if (data) {
            nextTokenRef.current =
              data.riskItem.comments.nextToken ?? undefined;
          }
          break;
        }
        case ProductType.EarlyWarnings: {
          let data: EarlyWarningItemCommentsQuery | undefined;

          if (nextTokenRef.current) {
            const result = await fetchMoreEWComments({
              variables: {
                id: productItemId,
                limit: commentsBatchSize,
                nextToken: nextTokenRef.current,
              },
              updateQuery: (oldData, { fetchMoreResult: newData }) => ({
                ...newData,
                earlyWarningItem: {
                  id: newData.earlyWarningItem!.id, // when fetching more comments usually ewItem exists..
                  title: newData.earlyWarningItem!.title,
                  ...newData.earlyWarningItem,
                  comments: {
                    ...newData.earlyWarningItem?.comments,
                    items: [
                      ...(oldData.earlyWarningItem?.comments.items ?? []),
                      ...(newData.earlyWarningItem?.comments.items ?? []),
                    ],
                  },
                },
              }),
            });

            data = result.data;
          } else {
            const result = await loadEWComments({
              variables: {
                id: productItemId,
                limit: commentsBatchSize,
              },
            });
            data = result.data;
          }

          if (data) {
            nextTokenRef.current =
              data.earlyWarningItem?.comments.nextToken ?? undefined;
          }
          break;
        }
        case ProductType.Events: {
          let data: EventItemCommentsQuery | undefined;

          if (nextTokenRef.current) {
            const result = await fetchMoreEventComments({
              variables: {
                id: productItemId,
                limit: commentsBatchSize,
                nextToken: nextTokenRef.current,
              },
              updateQuery: (oldData, { fetchMoreResult: newData }) => ({
                ...newData,
                eventItem: {
                  ...newData.eventItem,
                  comments: {
                    ...newData.eventItem.comments,
                    items: [
                      ...oldData.eventItem.comments.items,
                      ...newData.eventItem.comments.items,
                    ],
                  },
                },
              }),
            });

            data = result.data;
          } else {
            const result = await loadEventComments({
              variables: {
                id: productItemId,
                limit: commentsBatchSize,
              },
            });

            data = result.data;
          }

          if (data) {
            nextTokenRef.current =
              data.eventItem.comments.nextToken ?? undefined;
          }
          break;
        }
        case ProductType.Instructions: {
          let data: InstructionItemCommentsQuery | undefined;

          if (nextTokenRef.current) {
            const result = await fetchMoreInstructionComments({
              variables: {
                id: productItemId,
                limit: commentsBatchSize,
                nextToken: nextTokenRef.current,
              },
              updateQuery: (oldData, { fetchMoreResult: newData }) => ({
                ...newData,
                instructionItem: {
                  ...newData.instructionItem,
                  id: newData.instructionItem!.id!, // usually when fetching more comments, the instructionItem should be there
                  title: newData.instructionItem!.title!,
                  comments: {
                    ...newData.instructionItem?.comments,
                    items: [
                      ...(oldData.instructionItem?.comments.items ?? []),
                      ...(newData.instructionItem?.comments.items ?? []),
                    ],
                  },
                },
              }),
            });
            data = result.data;
          } else {
            const result = await loadInstructionComments({
              variables: {
                id: productItemId,
                limit: commentsBatchSize,
              },
            });
            data = result.data;
          }

          if (data) {
            nextTokenRef.current =
              data.instructionItem?.comments.nextToken ?? undefined;
          }
          break;
        }
        case ProductType.DailyDiary: {
          let data: DailyDiaryItemCommentsQuery | undefined;

          if (nextTokenRef.current) {
            const result = await fetchMoreDailyDiaryComments({
              variables: {
                dailyDiaryId: productItemId,
                revisionId: versionId,
                limit: commentsBatchSize,
                nextToken: nextTokenRef.current,
              },
              updateQuery: (oldData, { fetchMoreResult: newData }) =>
                ({
                  ...newData,
                  dailyDiaryItemComments: {
                    ...newData.dailyDiaryItemComments,
                    items: [
                      ...oldData.dailyDiaryItemComments.items,
                      ...newData.dailyDiaryItemComments.items,
                    ],
                  },
                } as DailyDiaryItemCommentsQuery),
            });

            data = result.data;
          } else {
            const result = await loadDailyDiaryComments({
              variables: {
                dailyDiaryId: productItemId,
                revisionId: versionId,
                limit: commentsBatchSize,
              },
            });

            data = result.data;
          }

          if (data) {
            nextTokenRef.current =
              data.dailyDiaryItemComments.nextToken ?? undefined;
          }
          break;
        }
      }
    },
    [
      versionId,
      loadRiskComments,
      loadEWComments,
      loadEventComments,
      loadInstructionComments,
      loadDailyDiaryComments,
      fetchMoreRiskComments,
      fetchMoreEWComments,
      fetchMoreEventComments,
      fetchMoreInstructionComments,
      fetchMoreDailyDiaryComments,
    ]
  );

  const loadMore = useCallback(async () => {
    loadComments(productType, productItemId);
  }, [productType, productItemId, loadComments]);

  const handleAddComment = useCallback(
    (newComment: AddCommentInput) => {
      return addComment({
        variables: {
          input: newComment,
        },
        optimisticResponse: {
          addComment: {
            __typename: "Comment",
            id: String(Math.random() * 1000000),
            itemId: newComment.itemId,
            content: newComment.content,
            dateCreated: new Date().toString(),
            creatorId: authenticatedUser!.id,
            replies: {
              items: [],
            },
            creator: authenticatedUser!,
          } as Comment,
        },
      });
    },
    [addComment, authenticatedUser]
  );

  const handleAddReplyComment = useCallback(
    (newReplyComment: ReplyCommentInput) => {
      return replyComment({
        variables: {
          input: newReplyComment,
        },
        optimisticResponse: {
          replyComment: {
            __typename: "CommentReply",
            id: String(Math.random() * 1000000),
            commentId: newReplyComment.commentId,
            content: newReplyComment.content,
            dateCreated: new Date().toString(),
            creatorId: authenticatedUser!.id,
            creator: authenticatedUser!,
          } as CommentReply,
        },
      });
    },
    [replyComment, authenticatedUser]
  );

  useEffect(() => {
    nextTokenRef.current = undefined;
  }, [productType, versionId]);

  const commentsLoading =
    riskItemCommentsLoading ||
    ewItemCommentsLoading ||
    eventItemCommentsLoading ||
    instructionItemCommentsLoading ||
    dailyDiaryItemCommentsLoading;

  return {
    addComment: handleAddComment,
    replyComment: handleAddReplyComment,
    comments,
    commentsLoading,
    hasMore,
    loadMore,
    loading: commentsLoading || addCommentLoading || replyCommentLoading,
  };
};
