import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValueFormatter,
  GridValueGetter,
} from "@mui/x-data-grid-pro";
import {
  CompanyLookupCollection,
  DailyDiaryResourceRecord,
} from "generated/graphql";
import { useMemo } from "react";
import { useDailyDiaryStatus } from "../../../hooks/useDailyDiaryStatus";
import { useTranslation } from "react-i18next";
import { useAttachmentsColumn } from "../../AttachmentsColumn";
import { useKebabButtonColumn } from "../../KebabButtonColumn";
import { DescriptionColumnRenderer } from "../../DescriptionColumnRenderer";
import { useCreatedByColumn } from "../../CreatedByColumn";
import { timeStripMinutes } from "../../../utilts";

export const useColumns = ({
  localLookups,
  withShiftColumn,
  withCompanyColumn,
  withTeamColumn,
  withAttachments,
  onEdit,
  onRemove,
  onAttachmentsClick,
}: {
  onEdit?: (rowId: GridRowId) => void;
  onRemove?: (rowId: GridRowId) => void;
  onAttachmentsClick: (rowId: GridRowId) => void;
  withAttachments?: boolean;
  localLookups: CompanyLookupCollection[];
  withShiftColumn?: boolean;
  withCompanyColumn?: boolean;
  withTeamColumn?: boolean;
}) => {
  const { t } = useTranslation();
  const { isDraftOrEmptyDailyDiary } = useDailyDiaryStatus();

  const attachmentsCol = useAttachmentsColumn(onAttachmentsClick);
  const kebabButtonColumn = useKebabButtonColumn<DailyDiaryResourceRecord>(
    onEdit,
    onRemove
  );
  const createdByColumn = useCreatedByColumn<DailyDiaryResourceRecord>();

  return useMemo((): GridColDef<DailyDiaryResourceRecord>[] => {
    return [
      {
        field: "resource",
        headerName: t("Projects.DailyDiaries.Resource.resource"),
        flex: 0.125,
        resizable: true,
        valueGetter: (_, row) => {
          return localLookups
            .find((lkp) => lkp.id === row.resourceLookupId)
            ?.options.items.find((option) => option.id === row.resourceOptionId)
            ?.value;
        },
      },
      ...(withShiftColumn
        ? [
            {
              field: "shift",
              headerName: t("Projects.DailyDiaries.Resource.shift"),
              width: 95,
              resizable: true,
              valueGetter: ((_, row) => {
                return localLookups
                  .find((lkp) => lkp.id === row.shiftLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.shiftOptionId
                  )?.value;
              }) as GridValueGetter<DailyDiaryResourceRecord>,
            },
          ]
        : []),
      ...(withCompanyColumn
        ? [
            {
              field: "company",
              headerName: t("Projects.DailyDiaries.Resource.company"),
              resizable: true,
              valueGetter: ((_, row) => {
                return localLookups
                  .find((lkp) => lkp.id === row.companyLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.companyOptionId
                  )?.value;
              }) as GridValueGetter<DailyDiaryResourceRecord>,
            },
          ]
        : []),
      {
        field: "quantity",
        headerName: t("Projects.DailyDiaries.Resource.quantity"),
        width: 70,
        resizable: true,
      },
      {
        field: "hours",
        headerName: t("Projects.DailyDiaries.Resource.hours"),
        width: 55,
        resizable: true,
        valueFormatter: ((value) =>
          timeStripMinutes(value)) as GridValueFormatter<
          DailyDiaryResourceRecord,
          any,
          any,
          string
        >,
      },
      {
        field: "totalHours",
        headerName: t("Projects.DailyDiaries.Resource.totalHours"),
        width: 90,
        resizable: true,
        valueFormatter: ((value) =>
          timeStripMinutes(value)) as GridValueFormatter<
          DailyDiaryResourceRecord,
          any,
          any,
          string
        >,
      },
      {
        field: "remarks",
        headerName: t("Projects.DailyDiaries.Resource.remarks"),
        flex: 0.25,
        resizable: true,
        renderCell: (
          params: GridRenderCellParams<DailyDiaryResourceRecord, any, any>
        ) => (
          <DescriptionColumnRenderer
            description={params.row.remarks ?? undefined}
          />
        ),
      },
      ...(withTeamColumn
        ? [
            {
              field: "team",
              headerName: t("Projects.DailyDiaries.Resource.team"),
              resizable: true,
              valueGetter: ((_, row) =>
                localLookups
                  .find((lkp) => lkp.id === row.teamLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.teamOptionId
                  )?.value) as GridValueGetter<DailyDiaryResourceRecord>,
            },
          ]
        : []),
      createdByColumn,
      ...(withAttachments ? [attachmentsCol] : []),
      ...(isDraftOrEmptyDailyDiary && (onRemove || onEdit)
        ? [kebabButtonColumn]
        : []),
    ];
  }, [
    t,
    localLookups,
    withShiftColumn,
    withCompanyColumn,
    kebabButtonColumn,
    createdByColumn,
    withTeamColumn,
    isDraftOrEmptyDailyDiary,
    attachmentsCol,
    withAttachments,
    onEdit,
    onRemove,
  ]);
};
