import { Stack, css, styled } from "@mui/material";

export const ItemContainer = styled(Stack)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.grey[200]};
    background: ${theme.palette.grey[100]};
    border-radius: 8px;
    padding: ${theme.spacing(1)};
    height: 320px;
    width: 100%;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;

    & img {
      border-radius: 8px;
    }

    &:hover {
      svg {
        visibility: visible;
      }
    }
  `
);
