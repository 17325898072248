import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const dailyDiaryWeatherRecordLiteFields = gql`
  ${creatorFields}

  fragment DailyDiaryWeatherRecordLiteFields on DailyDiaryWeatherRecord {
    id
    conditionsOptionId
    conditionsLookupId
    temperature
    rainFall
    windSpeed
    humidity
    timeOfMeasurement
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
