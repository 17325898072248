import {
  AddAuthorizationWorkflowMutation,
  AddAuthorizationWorkflowMutationVariables,
} from "generated/graphql";
import { addAuthorizationWorkflowMutation } from "graphql/mutations/addAuthorizationWorkflow";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";

export const useNewAuthorizationWorkflow = () => {
  const { t } = useTranslation();

  const [
    addAuthorizationWorkflow,
    { loading: addAuthorizationWorkflowLoading },
  ] = useGraphMutation<
    AddAuthorizationWorkflowMutation,
    AddAuthorizationWorkflowMutationVariables
  >(
    addAuthorizationWorkflowMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "authorizationWorkflows" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "workflowActionsPrompt" });
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.AuthorizationWorkflows.authorizationWorkflow"),
    })
  );

  return {
    addAuthorizationWorkflow,
    loading: addAuthorizationWorkflowLoading,
  };
};
