import { Box } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import { GridHeader } from "components/GridHeader";
import { useContractTypeStatusOptions } from "components/StatusTag/useContractTypeStatusOptions";
import {
  ContractType,
  ContractTypesQuery,
  ContractTypesQueryVariables,
  ContractTypeStatus,
} from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { NewAppPaths } from "helpers/paths/paths";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useColumns } from "./ContractTypes.constants";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { contractTypesQuery } from "graphql/queries/contractTypes.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { StatusOption } from "components/StatusTag/StatusTag";
import { openLocallyOrInNewTab } from "helpers/miscelaneous";

export const ContractTypes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const contractTypeStatusOptions =
    useContractTypeStatusOptions() as StatusOption<ContractTypeStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "description", sort: "asc" },
    { field: "version", sort: "desc" },
    { field: "subtype", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ContractTypeStatus.Active, ContractTypeStatus.Offline],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const { data: contractTypes, loading: getContractTypesLoading } =
    useGraphQuery<ContractTypesQuery, ContractTypesQueryVariables>(
      contractTypesQuery
    );

  const handleExcelExport = () => {
    const columns = [
      {
        header: t("AdminConsole.ContractTypes.labels.contractType"),
        key: "description",
        width: 20,
      },
      {
        header: t("AdminConsole.ContractTypes.labels.contractSubtype"),
        key: "subType",
        width: 20,
      },
      {
        header: t("AdminConsole.ContractTypes.labels.version"),
        key: "version",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
    ];
    const rows =
      contractTypes!
        .contractTypes!.items.filter(
          (contractType) => (selectionModel || []).indexOf(contractType.id) >= 0
        )
        .map((contractType) => ({
          ...contractType,
          type: contractType.description,
        })) || [];

    exportToExcel(
      t("AdminConsole.ContractTypes.labels.contractTypes"),
      columns,
      rows
    );
  };

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (clickedRowData: GridRowParams<ContractType>, event) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        const url =
          NewAppPaths.authorized.AdminConsole.children.System.children.ContractTypeDetails.pathConstructor(
            clickedRowData.row.id
          );
        openLocallyOrInNewTab(url, event, navigate);
      }
    },
    [navigate]
  );

  const columns = useColumns(contractTypeStatusOptions);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex="1">
      <GridHeader
        title={t("AdminConsole.ContractTypes.labels.contractTypes")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExcelExport}
        sticky
      />
      <Box sx={{ flex: 1, width: "100%" }}>
        <StyledDataGrid
          apiRef={gridApiRef}
          rows={(contractTypes?.contractTypes.items as ContractType[]) || []}
          columns={columns}
          getRowId={(rowData: ContractType) => rowData.id}
          onRowSelectionModelChange={setSelectionModel}
          loading={getContractTypesLoading}
          sortingMode="client"
          sortModel={sortingModel}
          onSortModelChange={setSortingModel}
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onRowClick={handleRowClick}
          getCellClassName={(
            params: GridCellParams<any, ContractType, any>
          ) => {
            return params.row.status === ContractTypeStatus.Removed
              ? "greyed-out"
              : "";
          }}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};
