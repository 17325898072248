import {
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { EditUserInput, User } from "generated/graphql";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { UserProfileButton } from "components/UserProfileButton/UserProfileButton";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import { getCountryByName } from "helpers/countries/countries";
import { useTranslation } from "react-i18next";
import { DisabledTextFields } from "containers/Register/Register.styled";
import { Overlay } from "components/Overlay";
import moment from "moment";
import { cleanseBase64Image } from "helpers/miscelaneous";
import { dateISOFormat } from "constants/constants";
import { CountryData } from "helpers/countries/countries.decl";
import { CountriesSelectNew } from "components/CountriesSelectNew";

export type UserFormProps = {
  companyName?: string;
  user?: User;
  loading?: boolean;
  readOnly?: boolean;
  allowProfilePhotoUpdate?: boolean;
  profilePictureAtTop?: boolean;
  onChange: (data: EditUserInput) => void;
};

const formDataValidators: DataValidators = {
  country: {
    validators: [ValidatorType.Required],
  },
  dateOfBirth: {
    validators: [ValidatorType.Required, ValidatorType.ValidDate],
  },
  firstname: {
    validators: [ValidatorType.Required],
  },
  surname: {
    validators: [ValidatorType.Required],
  },
  jobTitle: {
    validators: [ValidatorType.Required],
  },
  mobileNumber: {
    validators: [ValidatorType.Required, ValidatorType.ValidPhoneNumber],
  },
};

const defaultFormData: EditUserInput = {
  id: "",
  companyId: "",
  alternateNumber: "",
  country: "",
  dateOfBirth: "",
  firstname: "",
  jobTitle: "",
  mobileNumber: "",
  profilePicture: "",
  surname: "",
};

export type UserFormPublicApi = {
  validate: () => boolean;
  reset: () => void;
};

export const UserForm = React.forwardRef(
  (
    {
      companyName,
      user,
      loading,
      readOnly,
      allowProfilePhotoUpdate = false,
      profilePictureAtTop = false,
      onChange,
    }: UserFormProps,
    ref: React.Ref<UserFormPublicApi>
  ) => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState(defaultFormData);
    const [formDataErrors, setFormDataErrors] = useState<{
      [key: string]: string;
    }>({});
    const [selectedCountry, setSelectedCountry] = useState<CountryData>();

    const handleCountryChange = (newCountry: CountryData) => {
      setSelectedCountry(newCountry);
      setFormData((curFormData) => ({
        ...curFormData,
        country: newCountry.name,
      }));

      setFormDataErrors((curFormDataErrs) => {
        const { country: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const handleTextFieldChange: React.ChangeEventHandler<
      HTMLTextAreaElement | HTMLInputElement
    > = (evt) => {
      setFormData((curData) => ({
        ...curData,
        [evt.target.name]: evt.target.value,
      }));

      setFormDataErrors((curFormDataErrs) => {
        const { [evt.target.name]: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const validateForm = useCallback(
      (formData: EditUserInput | User) => {
        const validationResult = validateData(formData, formDataValidators);

        if (validationResult.valid) {
          setFormDataErrors({});
          return true;
        }
        if (!readOnly) {
          setFormDataErrors(validationResult.errors);
        }
        return false;
      },
      [readOnly]
    );

    const resetForm = useCallback(() => {
      setFormData((user || defaultFormData) as EditUserInput);
    }, [user]);

    const handlePhotoUpload = (photo?: string) => {
      setFormData((curFormData) => ({
        ...curFormData,
        profilePicture: photo,
      }));
    };

    useEffect(() => {
      setFormData((user || defaultFormData) as EditUserInput);
    }, [user]);

    useEffect(() => {
      onChange?.({
        alternateNumber: formData.alternateNumber,
        companyId: formData.companyId,
        country: formData.country,
        dateOfBirth: formData.dateOfBirth,
        firstname: formData.firstname,
        id: formData.id,
        jobTitle: formData.jobTitle,
        mobileNumber: formData.mobileNumber,
        surname: formData.surname,
        profilePicture: cleanseBase64Image(formData.profilePicture),
      });
    }, [formData, onChange]);

    useEffect(() => {
      if (user) {
        const country = getCountryByName(user.country)!;
        const updatedUser = {
          ...user,
          dateOfBirth: moment(user.dateOfBirth).format(dateISOFormat),
        };

        setFormData(updatedUser as EditUserInput);
        setSelectedCountry(country);
        validateForm(updatedUser);
      }
    }, [user, validateForm]);

    useEffect(() => {
      if (readOnly) {
        setFormDataErrors({});
      }
    }, [readOnly]);

    useImperativeHandle(
      ref,
      () => ({
        validate: () => validateForm(formData),
        reset: resetForm,
      }),
      [validateForm, formData, resetForm]
    );

    return (
      <>
        {/* withBackground={!readOnly} */}
        {(loading || readOnly) && <Overlay withBackground={false} />}
        <Grid container spacing={6}>
          {profilePictureAtTop && (
            <Grid item md={12} xs={12}>
              <UserProfileButton
                url={user?.profilePicture ?? undefined}
                onPhotoUpload={
                  allowProfilePhotoUpdate ? handlePhotoUpload : undefined
                }
              />
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <DisabledTextFields
              fullWidth
              name="email"
              value={user?.email ?? ""}
              type="email"
              disabled
              placeholder={t("common.labels.email")}
              label={t("common.labels.email")}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              required={!readOnly}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DisabledTextFields
              fullWidth
              name="company"
              value={companyName ?? ""}
              type="text"
              placeholder={t("common.labels.company")}
              label={t("common.labels.company")}
              variant="standard"
              disabled
              InputLabelProps={{ shrink: true }}
              required={!readOnly}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              name="firstname"
              value={formData.firstname ?? ""}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.firstName")}
              variant="standard"
              error={!!formDataErrors.firstname}
              helperText={formDataErrors.firstname}
              InputLabelProps={{ shrink: true }}
              required={!readOnly}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              name="surname"
              value={formData.surname ?? ""}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.surname")}
              variant="standard"
              error={!!formDataErrors.surname}
              helperText={formDataErrors.surname}
              InputLabelProps={{ shrink: true }}
              required={!readOnly}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="jobTitle"
              value={formData.jobTitle ?? ""}
              onChange={handleTextFieldChange}
              type="text"
              label={t("Register.labels.jobTitle")}
              variant="standard"
              error={!!formDataErrors.jobTitle}
              helperText={formDataErrors.jobTitle}
              InputLabelProps={{ shrink: true }}
              required={!readOnly}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              {readOnly ? (
                <TextField
                  fullWidth
                  value={selectedCountry?.name ?? ""}
                  type="text"
                  label={t("AdminConsole.Companies.labels.country")}
                  placeholder=""
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              ) : (
                <>
                  <FormLabel id="country-select">
                    <Typography
                      variant="caption"
                      color={formDataErrors.country ? "error" : "inherit"}
                    >
                      {t("common.labels.country")} *
                    </Typography>
                  </FormLabel>
                  <CountriesSelectNew
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    hasErrors={!!formDataErrors.country}
                    adminConsoleStyle
                  />
                  {!!formDataErrors.country && (
                    <Typography variant="caption" color="error">
                      {formDataErrors.country}
                    </Typography>
                  )}
                </>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              fullWidth
              name="mobileNumber"
              value={formData.mobileNumber ?? ""}
              onChange={handleTextFieldChange}
              type="text"
              placeholder={readOnly ? "" : t("common.labels.phonePlaceholder")}
              label={t("common.labels.mobileNumber")}
              variant="standard"
              error={!!formDataErrors.mobileNumber}
              helperText={formDataErrors.mobileNumber}
              InputLabelProps={{ shrink: true }}
              required={!readOnly}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              fullWidth
              name="alternateNumber"
              value={formData.alternateNumber ?? ""}
              onChange={handleTextFieldChange}
              type="text"
              placeholder={readOnly ? "" : t("common.labels.phonePlaceholder")}
              label={t("common.labels.alternativeNumber")}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            {/* TODO: replace with custom DatePicker */}
            <TextField
              fullWidth
              name="dateOfBirth"
              value={formData.dateOfBirth ?? ""}
              onChange={handleTextFieldChange}
              type="date"
              placeholder="DD.MM.YYYY"
              label={t("common.labels.dateOfBirth")}
              variant="standard"
              error={!!formDataErrors.dateOfBirth}
              helperText={formDataErrors.dateOfBirth}
              InputLabelProps={{ shrink: true }}
              required={!readOnly}
            />
          </Grid>
          {!profilePictureAtTop && (
            <Grid item md={6} xs={12}>
              <UserProfileButton
                url={user?.profilePicture ?? undefined}
                onPhotoUpload={
                  allowProfilePhotoUpdate ? handlePhotoUpload : undefined
                }
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
);
