import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RegistrationStep } from "../Register";

export type RegistrationHeaderProps = {
  step: RegistrationStep;
};

export const RegistrationHeader: React.FC<RegistrationHeaderProps> = ({
  step,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={0.75}>
      <Typography variant="p2" color="grey.600">
        {t("Register.step", {
          stepNumber: step === RegistrationStep.Account ? 1 : 2,
        })}
      </Typography>
      <Typography variant="h1" color="grey.800" fontWeight={700}>
        {step === RegistrationStep.Account
          ? t("Register.accountTitle")
          : t("Register.personalInformationTitle")}
      </Typography>
      <Typography variant="h4" color="grey.700" lineHeight="24px">
        {step === RegistrationStep.Account
          ? t("Register.accountSubtitle")
          : t("Register.personalInformationSubtitle")}
      </Typography>
    </Stack>
  );
};
