import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { ClockIcon } from "components/Icons/ClockIcon";
import { EffectEstimationType } from "generated/graphql";
import { timeRelatedUnits } from "../constants";
import { NumericInputNoSeparator } from "./FormattedNumericInput";
import { CurrencyTextField } from "components/CurrencyTextField";
import { useCallback, useMemo } from "react";

export type UnitValueEditableProps = {
  unit?: string;
  value: string;
  currency?: string; // EUR, USD, RON, etc etc.
  estimationType: EffectEstimationType.Decimal | EffectEstimationType.Monetary;
  onChange: (newValue: string) => void;
};

export const UnitValueEditable: React.FC<UnitValueEditableProps> = ({
  unit,
  value,
  currency,
  estimationType,
  onChange,
}) => {
  const handleUnitValueChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (evt) => {
      onChange(evt.target.value);
    },
    [onChange]
  );

  const monetaryValue = useMemo(
    () => (
      <CurrencyTextField
        value={value}
        currency={currency ?? ""}
        onChange={handleUnitValueChange}
      />
    ),
    [currency, handleUnitValueChange, value]
  );

  const renderUnitValue = () => {
    return (
      <>
        <TextField
          fullWidth
          value={value}
          onChange={handleUnitValueChange}
          type="text"
          variant="outlined"
          size="small"
          data-testid="unit-value-textfield"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {unit && timeRelatedUnits.indexOf(unit?.toLowerCase()) >= 0 && (
                  <ClockIcon />
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="p1" color="grey.600">
                  {unit}
                </Typography>
              </InputAdornment>
            ),
            inputComponent: NumericInputNoSeparator,
          }}
        />
      </>
    );
  };

  return (
    <Box display="flex" alignItems="center">
      {estimationType === EffectEstimationType.Monetary
        ? monetaryValue
        : renderUnitValue()}
    </Box>
  );
};
