import { useCallback, useContext, useEffect, useState } from "react";
import {
  DraftVariationProposal,
  GenerateRecordVariationProposalForProposedVariationPreviewMutation,
  GenerateRecordVariationProposalForProposedVariationPreviewMutationVariables,
  RecordVariationProposalForProposedVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateRecordVariationProposalForProposedVariationPreviewMutation } from "./SubmitProposalForProposedVariationAction.query";

export const useRecordProposalForProposedVariationModal = (
  draftProposals: DraftVariationProposal[],
  preferredProposalId: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [variationPreview, setVariationPreview] =
    useState<RecordVariationProposalForProposedVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationProposalForProposedVariationPreviewMutation,
    GenerateRecordVariationProposalForProposedVariationPreviewMutationVariables
  >(
    generateRecordVariationProposalForProposedVariationPreviewMutation,
    {},
    null
  );

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            proposalIds: draftProposals.map((proposal) => proposal.id),
            preferredProposalId,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateRecordVariationProposalForProposedVariationPreview
        );
      }
    }, [generatePreview, variation, draftProposals, preferredProposalId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview,
    contract,
  };
};
