import { Box } from "@mui/material";
import {
  GridCellParams,
  GridFilterModel,
  GridLogicOperator,
  GridRowModesModel,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  ContractBindingType,
  ContractBindingTypeStatus,
} from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useColumns } from "./ContractTypeBindingTypes.constants";
import { ContractTypesBindingTypesHeader } from "./ContractTypeBindingTypesHeader";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StatusOption } from "components/StatusTag/StatusTag";
import { computeGridRowModes } from "helpers/dataGrid.helpers";

export type ContractTypeBindingTypesProps = {
  contractTypeName?: string;
  bindingTypes: ContractBindingType[];
  loading?: boolean;
};

export const ContractTypeBindingTypes: React.FC<
  ContractTypeBindingTypesProps
> = ({ contractTypeName, bindingTypes, loading }) => {
  const { t } = useTranslation();
  const bindingTypeStatusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ContractBindingTypeStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [rows, setRows] = useState<ContractBindingType[]>(bindingTypes);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(bindingTypes)
  );
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "description", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ContractBindingTypeStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const handleExportBindingTypesToExcel = () => {
    const columns = [
      {
        header: t("common.labels.description"),
        key: "description",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
    ];

    const rows = bindingTypes
      .filter(
        (bindingType) => (selectionModel || []).indexOf(bindingType.id) >= 0
      )
      .map((bindingType) => ({
        ...bindingType,
      }));

    exportToExcel(
      `${contractTypeName}-${t("common.labels.bindingTypes")}`,
      columns,
      rows
    );
  };

  const columns = useColumns(bindingTypeStatusOptions);

  useEffect(() => {
    setRows(bindingTypes);
    setRowModesModel(computeGridRowModes(bindingTypes));
  }, [bindingTypes]);

  return (
    <Box>
      <ContractTypesBindingTypesHeader
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportBindingTypesToExcel}
      />
      <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
        <StyledDataGrid
          apiRef={gridApiRef}
          rows={rows}
          columns={columns}
          getRowId={(rowData: ContractBindingType) => rowData.id}
          onRowSelectionModelChange={handleGridRowSelectionModelChange}
          loading={loading}
          sortingMode="client"
          sortModel={sortingModel}
          onSortModelChange={setSortingModel}
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          rowModesModel={rowModesModel}
          getCellClassName={(
            params: GridCellParams<any, ContractBindingType, any>
          ) => {
            return params.row.status === ContractBindingTypeStatus.Removed
              ? "greyed-out"
              : "";
          }}
          autoHeight
          hideFooter
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};
