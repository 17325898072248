import { countriesData } from "helpers/countries/countries.constants";

export const getCountryByPhoneNumber = (phoneNumber: string) => {
  return Object.values(countriesData).find((country) => {
    const phoneNumberWithPlus =
      phoneNumber[0] === "+" ? phoneNumber : `+${phoneNumber}`;

    return phoneNumberWithPlus.startsWith(country.phonePrefix);
  });
};
