import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  GenerateRecordClaimErrorAgreementNoticePreviewMutation,
  GenerateRecordClaimErrorAgreementNoticePreviewMutationVariables,
  RecordClaimErrorAgreementNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { generateRecordClaimErrorAgreementNoticePreviewMutation } from "./NotifyErrorFoundInAgreement.query";

export const useRecordErrorFoundInAgreementNoticeModal = (details: string) => {
  const { claim, contract } = useContext(ClaimWidgetContext);

  const [claimPreview, setClaimPreview] =
    useState<RecordClaimErrorAgreementNoticePreview>();

  const [generateClaimPreview] = useGraphMutation<
    GenerateRecordClaimErrorAgreementNoticePreviewMutation,
    GenerateRecordClaimErrorAgreementNoticePreviewMutationVariables
  >(generateRecordClaimErrorAgreementNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            details,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateRecordClaimErrorAgreementNoticePreview);
      }
    }, [generateClaimPreview, claim, details]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    claimPreview,
    contract,
  };
};
