import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const AsteriskIcon: React.FC<BaseIconProps> = ({
  height = "16",
  width = "16",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.error.main;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0096 10.3172L8.99771 7.99881L13.0096 5.68043C13.0717 5.65061 13.1271 5.60826 13.1721 5.55604C13.2171 5.50382 13.2508 5.44286 13.2711 5.37698C13.2914 5.3111 13.2979 5.24173 13.2901 5.17324C13.2823 5.10474 13.2605 5.03859 13.2259 4.97895C13.1913 4.9193 13.1448 4.86746 13.0892 4.82666C13.0336 4.78587 12.9702 4.75701 12.9029 4.7419C12.8357 4.7268 12.766 4.72576 12.6983 4.73887C12.6307 4.75197 12.5664 4.77894 12.5097 4.81806L8.49779 7.1302V2.49968C8.49779 2.36709 8.44512 2.23993 8.35137 2.14618C8.25762 2.05243 8.13046 1.99976 7.99787 1.99976C7.86528 1.99976 7.73813 2.05243 7.64437 2.14618C7.55062 2.23993 7.49795 2.36709 7.49795 2.49968V7.1302L3.48608 4.81806C3.37263 4.76364 3.24282 4.75401 3.12258 4.79109C3.00233 4.82817 2.90049 4.90923 2.83738 5.01809C2.77427 5.12695 2.75454 5.25561 2.78212 5.37838C2.8097 5.50115 2.88256 5.60901 2.98616 5.68043L6.99803 7.99881L2.98616 10.3172C2.87211 10.3838 2.78904 10.4928 2.75509 10.6204C2.72113 10.748 2.73906 10.8839 2.80494 10.9983C2.84714 11.0754 2.90964 11.1394 2.98565 11.1835C3.06166 11.2276 3.14828 11.25 3.23612 11.2483C3.32434 11.25 3.41116 11.2262 3.48608 11.1796L7.49795 8.86742V13.4979C7.49795 13.6305 7.55062 13.7577 7.64437 13.8514C7.73813 13.9452 7.86528 13.9979 7.99787 13.9979C8.13046 13.9979 8.25762 13.9452 8.35137 13.8514C8.44512 13.7577 8.49779 13.6305 8.49779 13.4979V8.86742L12.5097 11.1796C12.5846 11.2262 12.6714 11.25 12.7596 11.2483C12.8475 11.25 12.9341 11.2276 13.0101 11.1835C13.0861 11.1394 13.1486 11.0754 13.1908 10.9983C13.2567 10.8839 13.2746 10.748 13.2407 10.6204C13.2067 10.4928 13.1236 10.3838 13.0096 10.3172Z"
        fill={computedColor}
      />
    </svg>
  );
};
