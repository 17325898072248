import { Typography, TypographyProps, useTheme } from "@mui/material";

export const MetadataText: React.FC<TypographyProps> = ({
  children,
  ...typographyProps
}) => {
  const theme = useTheme();

  return (
    <Typography
      variant="p1"
      color={theme.palette.grey[600]}
      mt={0.5}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};
