import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { useColumns } from "./ProductInstanceWorkflowActionMappings.constants";
import { StyledDataGrid } from "components/StyledDataGrid";
import { dateTimeISOFormat, temporaryRowId } from "constants/constants";
import {
  AddAuthorizationActionInput,
  AuthorizationWorkflow,
  AuthorizationWorkflowActionMapping,
  AuthorizationWorkflowActionMappingStatus,
  ContractBindingType,
  EditAuthorizationActionInput,
  ProductInstance,
  ProductOutputAction,
  ProductOutputActionOriginatingParty,
  User,
} from "generated/graphql";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import moment from "moment";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { Keyhole } from "phosphor-react";

export type ProductInstanceWorkflowActionMappingsProps = {
  workflowActionMappings: AuthorizationWorkflowActionMapping[];
  productOutputActions: ProductOutputActionOriginatingParty[];
  authorizationWorkflows: AuthorizationWorkflow[];
  loading?: boolean;
  projectFriendlyName?: string;
  contractFriendlyName?: string;
  productInstanceName?: string;
  productInstanceId: string;
  onMappingAdd: (newMapping: AddAuthorizationActionInput) => Promise<boolean>;
  onMappingEdit: (
    updatedMapping: EditAuthorizationActionInput
  ) => Promise<boolean>;
  onMappingDelete: (mappingId: string) => Promise<boolean>;
};

export const ProductInstanceWorkflowActionMappings: React.FC<
  ProductInstanceWorkflowActionMappingsProps
> = ({
  workflowActionMappings,
  loading,
  projectFriendlyName,
  contractFriendlyName,
  productInstanceName,
  productOutputActions,
  authorizationWorkflows,
  productInstanceId,
  onMappingAdd,
  onMappingEdit,
  onMappingDelete,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser } = useContext(GlobalContext);
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();

  const [rows, setRows] = useState<AuthorizationWorkflowActionMapping[]>(
    workflowActionMappings
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(rows)
  );
  const [showSections, setShowSections] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("AdminConsole.AuthorizationWorkflows.action"),
        key: "action",
        width: 20,
      },
      {
        header: t("AdminConsole.AuthorizationWorkflows.workflow"),
        key: "workflow",
        width: 20,
      },
    ];

    const rowsToExport = rows
      .filter((mapping) => (selectionModel || []).indexOf(mapping.id) >= 0)
      .map((mapping) => ({
        ...mapping,
        action: mapping.productOutputAction.name,
        workflow: mapping.workflow.name,
      }));

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}-${productInstanceName}-${t(
        "AdminConsole.AuthorizationWorkflows.authorizationWorkflowOutputActionMappings"
      )}`,
      columns,
      rowsToExport
    );
  };

  const handleActionChange = useCallback(
    async (row: AuthorizationWorkflowActionMapping, newActionId: string) => {
      if (row.id === temporaryRowId) {
        const tokens = newActionId.split("_");
        const productOutputActionId = tokens[0];
        const contractBindingTypeId = tokens[1];

        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                productOutputActionId,
                ...(contractBindingTypeId
                  ? { partyId: contractBindingTypeId }
                  : {}),
              };
            }

            return row;
          })
        );
      }
    },
    []
  );

  const handleWorkflowChange = useCallback(
    (row: AuthorizationWorkflowActionMapping, newWorkflowId: string) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                workflowId: newWorkflowId,
              };
            }

            return row;
          })
        );
      } else {
        onMappingEdit({
          id: row.id,
          workflowId: newWorkflowId,
        });
      }
    },
    [onMappingEdit]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    async (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
        setRowModesModel((curModel) => ({
          ...curModel,
          [rowId]: { mode: GridRowModes.View },
        }));
      } else {
        const rowToDelete = rows.find((row) => row.id === rowId)!;
        // call BE to delete row
        const success = await onMappingDelete(rowId as string);
        if (!success) {
          // add it back
          setRows((crtRows) => [...crtRows, rowToDelete]);
        }
      }
    },
    [onMappingDelete, rows]
  );

  // TODO: export these functions in generic hook
  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<AuthorizationWorkflowActionMapping>,
      oldRow: GridRowModel<AuthorizationWorkflowActionMapping>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.workflowId && newRow.productOutputActionId) {
          const success = await onMappingAdd({
            productInstanceId: newRow.productInstanceId,
            productOutputActionId: newRow.productOutputActionId,
            workflowId: newRow.workflowId,
            ...(newRow.partyId
              ? { contractBindingTypeId: newRow.partyId }
              : {}),
          });
          if (success) {
            return newRow;
          }
        }
        setRowModesModel((prevData) => ({
          ...prevData,
          [temporaryRowId]: {
            mode: GridRowModes.Edit,
            fieldToFocus: !newRow.workflowId
              ? "workflow"
              : "productOutputAction",
          },
        }));
      } else if (newRow.workflowId !== oldRow.workflowId) {
        await onMappingEdit({
          id: newRow.id,
          workflowId: newRow.workflowId,
        });
      }

      return newRow;
    },
    [onMappingAdd, onMappingEdit]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        id: temporaryRowId,
        userId: authenticatedUser?.id ?? "",
        user: {} as User,
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        dateModified: moment(new Date().toString()).format(dateTimeISOFormat),
        modifiedBy: {} as User,
        modifiedById: "",
        productInstance: {} as ProductInstance,
        partyId: "",
        party: {} as ContractBindingType,
        productInstanceId,
        productOutputAction: {} as ProductOutputAction,
        productOutputActionId: "",
        status: AuthorizationWorkflowActionMappingStatus.Active,
        workflow: {} as AuthorizationWorkflow,
        workflowId: "",
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "productOutputAction",
        },
      }));
    });
  };

  const columns = useColumns({
    actions: productOutputActions,
    workflows: authorizationWorkflows,
    rowModesModel,
    onActionChange: handleActionChange,
    onWorkflowChange: handleWorkflowChange,
    onSaveRow: handleRowSaveClick,
    onDeleteRow: handleDeleteRow,
  });

  useEffect(() => {
    setRows(workflowActionMappings);
    setRowModesModel(computeGridRowModes(workflowActionMappings));
  }, [workflowActionMappings]);

  return (
    <Box
      data-testid={t(
        "AdminConsole.AuthorizationWorkflows.authorizationWorkflowOutputActionMappings"
      )}
    >
      <CollapsibleHeader
        title={t(
          "AdminConsole.AuthorizationWorkflows.authorizationWorkflowOutputActionMappings"
        )}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <Keyhole size={22} weight="fill" color={theme.palette.primary.main} />
        }
        onToggleCollapse={() => setShowSections((state) => !state)}
        collapsed={!showSections}
      />
      <Collapse in={showSections}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: AuthorizationWorkflowActionMapping) =>
              rowData.id
            }
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            filterMode="client"
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
