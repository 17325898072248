import { Box, Collapse, Stack, Typography, useTheme } from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList } from "components/DetailsList";
import { PageContentContainer } from "components/PageContentContainer";
import { UserProfileButton } from "components/UserProfileButton/UserProfileButton";
import { dateISOFormat } from "constants/constants";
import { OnMutationDone } from "types/decl";
import {
  ChangeUserStatusMutation,
  ChangeUserStatusMutationVariables,
  EditUserInput,
  GetCompanyLiteByIdQuery,
  GetCompanyLiteByIdQueryVariables,
  UserStatus,
} from "generated/graphql";
import { getCompanyLiteByIdQuery } from "graphql/queries/companyByIdLite.query";
import { NewAppPaths } from "helpers/paths/paths";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import moment from "moment";
import { UserCircle } from "phosphor-react";
import { useMemo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { changeUserStatusMutation } from "../CompanyDetails/CompanyDetails.query";
import { UserDetailsHeader } from "./UserDetailsHeader";
import { UserForm, UserFormPublicApi } from "./UserForm";
import { UserRoles } from "./UserRoles/UserRoles";
import { useUserRoles } from "./UserRoles/useUserRoles";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import ReactCountryFlag from "react-country-flag";
import { getCountryByName } from "helpers/countries/countries";
import { UserCompanyDetailListItem } from "./UserCompanyDetailListItem";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { ChangeUserCompanyModal } from "./ChangeUserCompanyModal/ChangeUserCompanyModal";
import { useResendInvitation } from "../../hooks/useResendInvitation";
import { useEditUser } from "./hooks/useEditUser";
import { useUserById } from "./hooks/useUserById";

export const UserDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { companyId, userId } = useParams();
  const userFormRef = useRef<UserFormPublicApi>(null);

  const [showDetails, setShowDetails] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const updatedUserDetails = useRef<EditUserInput>();

  const {
    modalVisibility: changeCompanyModalVisibility,
    toggleModalVisibility: toggleChangeCompanyModalVisibility,
    setModalVisibility: setChangeCompanyModalVisibility,
  } = useBasicModal();

  const { user, reloadUser, getUserLoading, userError } = useUserById(userId!);

  if (userError && userError.message.indexOf("was not found") >= 0) {
    // Admin accessed the link of a non existing user (deleted / not registered)
    navigate(NewAppPaths.authorized.Home);
  }

  const handleUserRolesUpdates: OnMutationDone = (cache) => {
    reloadUser();
    cache.evict({ id: "ROOT_QUERY", fieldName: "user" });
    cache.gc();
  };

  const {
    loading: userRolesLoading,
    projects,
    roles,
    addUserRole,
    addUserRoleLoading,
    editUserRole,
    editUserRoleLoading,
    changeUserRoleStatus,
    changeUserRoleStatusLoading,
  } = useUserRoles({
    userId: userId!,
    callbacks: {
      onAdd: handleUserRolesUpdates,
      onEdit: handleUserRolesUpdates,
      onStatusChange: handleUserRolesUpdates,
    },
  });

  const { resendInvitation, resendInvitationLoading } =
    useResendInvitation(reloadUser);

  const { data: companyData, loading: getCompanyDataLoading } = useGraphQuery<
    GetCompanyLiteByIdQuery,
    GetCompanyLiteByIdQueryVariables
  >(getCompanyLiteByIdQuery, { variables: { id: companyId! } });

  const [changeUserStatus, { loading: changeUserStatusLoading }] =
    useGraphMutation<
      ChangeUserStatusMutation,
      ChangeUserStatusMutationVariables
    >(
      changeUserStatusMutation,
      {
        update: (cache) => {
          reloadUser();

          cache.evict({ id: "ROOT_QUERY", fieldName: "company" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.user"),
      })
    );

  const { editUser, editUserLoading } = useEditUser(reloadUser);

  const handleCancel = () => {
    userFormRef.current?.reset();
    setReadOnly(true);
  };

  const handleChangeUserStatus = useCallback(
    async (newStatus: UserStatus) => {
      await changeUserStatus({
        variables: {
          id: user?.id!,
          status: newStatus,
        },
      });
    },
    [user?.id, changeUserStatus]
  );

  const handleUserUpdate = useCallback((updatedUser: EditUserInput) => {
    updatedUserDetails.current = updatedUser;
  }, []);

  const handleSaveUserUpdates = useCallback(async () => {
    if (!updatedUserDetails.current || !userFormRef.current?.validate()) {
      return;
    }

    const { errors } = await editUser({
      variables: {
        input: updatedUserDetails.current,
      },
    });

    if (!errors) {
      setReadOnly(true);
    }
  }, [editUser, updatedUserDetails]);

  const handleResendInvitation = useCallback(() => {
    if (!user?.email || !companyId) {
      return;
    }

    resendInvitation({
      variables: {
        input: {
          email: user.email,
          companyId,
        },
      },
    });
  }, [resendInvitation, user, companyId]);

  const userEntityObj = useMemo(() => {
    if (!user || !companyData?.company) {
      return [];
    }

    const countryCodeA2 = getCountryByName(user.country)?.countryCodeA2;

    return [
      {
        id: "email",
        label: t("common.labels.email"),
        value: user.email,
      },
      {
        id: "company",
        label: t("common.labels.company"),
        value: (
          <UserCompanyDetailListItem
            companyName={companyData.company.registeredName}
            onChangeCompany={toggleChangeCompanyModalVisibility}
          />
        ),
      },
      {
        id: "firstName",
        label: t("common.labels.firstName"),
        value: user.firstname,
      },
      {
        id: "surname",
        label: t("common.labels.surname"),
        value: user.surname,
      },
      {
        id: "jobTitle",
        label: t("Register.labels.jobTitle"),
        value: user.jobTitle,
      },
      {
        id: "country",
        label: t("AdminConsole.Companies.labels.country"),
        value: (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="subtitle2"
              data-testid={t("AdminConsole.Companies.labels.country")}
            >
              {user.country}
            </Typography>
            {countryCodeA2 && (
              <ReactCountryFlag
                countryCode={countryCodeA2}
                svg
                style={{
                  fontSize: "1.5em",
                  lineHeight: "1.5em",
                }}
              />
            )}
          </Stack>
        ),
      },
      {
        id: "mobileNumber",
        label: t("common.labels.mobileNumber"),
        value: user.mobileNumber,
      },
      {
        id: "alternateNumber",
        label: t("common.labels.alternativeNumber"),
        value: user.alternateNumber,
      },
      {
        id: "dateOfBirth",
        label: t("common.labels.dateOfBirth"),
        value: user.dateOfBirth
          ? moment(user.dateOfBirth).format(dateISOFormat)
          : "",
      },
      {
        id: "profilePicture",
        label: t("AdminConsole.Users.labels.profilePicture"),
        value: <UserProfileButton url={user.profilePicture ?? undefined} />,
      },
    ];
  }, [user, companyData, t, toggleChangeCompanyModalVisibility]);

  const generalLoading = useMemo(() => {
    return (
      getUserLoading ||
      getCompanyDataLoading ||
      changeUserStatusLoading ||
      editUserLoading ||
      resendInvitationLoading
    );
  }, [
    getUserLoading,
    getCompanyDataLoading,
    changeUserStatusLoading,
    editUserLoading,
    resendInvitationLoading,
  ]);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      {user?.id && companyId && (
        <ChangeUserCompanyModal
          open={changeCompanyModalVisibility}
          onClose={() => setChangeCompanyModalVisibility(false)}
          userId={user.id}
          crtCompanyId={companyId}
          onCompanyChanged={(newCompanyId: string) => {
            navigate(
              NewAppPaths.authorized.AdminConsole.children.Companies.children.CompanyDetails.children.UserDetails.pathConstructor(
                newCompanyId,
                user.id
              ),
              {
                replace: true,
              }
            );
          }}
        />
      )}
      <UserDetailsHeader
        user={user}
        companyName={companyData?.company?.registeredName}
        generalLoading={generalLoading}
        onStatusChange={handleChangeUserStatus}
        onResendRegisterInvitation={handleResendInvitation}
      />
      <CollapsibleHeader
        title={t("AdminConsole.Users.labels.userDetails")}
        collapsed={!showDetails}
        icon={
          <UserCircle
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onCancel={handleCancel}
        onEdit={() => setReadOnly(false)}
        onSaveActionLoading={editUserLoading}
        onSave={handleSaveUserUpdates}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList
              loading={getUserLoading || getCompanyDataLoading}
              entity={userEntityObj}
            />
          ) : getUserLoading || getCompanyDataLoading ? null : (
            <UserForm
              readOnly={readOnly || !user?.registered}
              companyName={companyData?.company?.registeredName}
              user={user}
              onChange={handleUserUpdate}
              loading={generalLoading}
              ref={userFormRef}
            />
          )}
        </PageContentContainer>
      </Collapse>
      {projects && roles && user ? (
        <UserRoles
          roles={roles}
          projects={projects}
          user={user}
          loading={
            userRolesLoading ||
            addUserRoleLoading ||
            editUserRoleLoading ||
            changeUserRoleStatusLoading
          }
          onRoleStatusChange={changeUserRoleStatus}
          onRoleAdd={addUserRole}
          onRoleEdit={editUserRole}
        />
      ) : (
        <Box mt={6}>
          <CenteredLoadingIndicator />
        </Box>
      )}
    </Box>
  );
};
