import { Box, Button, Typography, useTheme } from "@mui/material";
import { dateISOFormat } from "constants/constants";
import { CompEventActionModal } from "containers/Projects/components/CompEvents/CompEventActionModal/CompEventActionModal";
import { DaysLate } from "containers/Projects/components/DaysLate";
import { CompEventItem } from "generated/graphql";
import moment from "moment";
import { CaretRight } from "phosphor-react";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CompEventWidgetContext } from "../../CompEventWidget.context";
import { useCEWidgetNextStepSection } from "./useCEWidgetNextStepSection";
import { AuthorizationBanner } from "../../../../../../../components/Authorization/AuthorizationBanner";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { getNextStepDueDate, getNextStepText } from "../../../CompEvents.utils";
import { useAuthStore } from "state-management/store/authStore";
import { useLoggedInUserReviewer } from "hooks/useLoggedInUserReviewer";

export type CEWidgetNextStepSectionProps = {
  compEvent?: CompEventItem;
  previewMode?: boolean;
};

export const CEWidgetNextStepSection: React.FC<
  CEWidgetNextStepSectionProps
> = ({ compEvent, previewMode = false }) => {
  const setAuthModalState = useAuthStore((state) => state.setAuthModalState);

  const { t } = useTranslation();
  const theme = useTheme();
  const {
    productInstanceId,
    regardingId,
    regardingType,
    eventItem,
    instructionItem,
    onChange,
  } = useContext(CompEventWidgetContext);
  const {
    modalVisibility: nextStepModalVisibility,
    toggleModalVisibility: toggleNextStepModalVisibility,
  } = useBasicModal();

  const { compEventActionList, ongoingAuthorizationAudit } =
    useCEWidgetNextStepSection(
      regardingType,
      regardingId,
      productInstanceId,
      compEvent?.id
    );

  const computedOngoingAuthorizationAudit = useMemo(
    () => compEvent?.ongoingAuthorizationAudit ?? ongoingAuthorizationAudit,
    [compEvent, ongoingAuthorizationAudit]
  );

  const { canUserReviewCrtLevel, isUserReviewer } = useLoggedInUserReviewer(
    computedOngoingAuthorizationAudit ?? undefined
  );

  const computedNextStepText = getNextStepText(compEvent);
  const computedNextStepDueDate = getNextStepDueDate(
    compEvent,
    eventItem,
    instructionItem
  );

  const computedNextStepDaysLate = useMemo(() => {
    if (compEvent) {
      return moment().diff(moment(compEvent.nextStep?.dueDate), "days");
    }

    if (computedNextStepDueDate) {
      const daysDifference = moment(computedNextStepDueDate).diff(
        moment(),
        "days"
      );
      if (daysDifference >= 0) {
        return undefined;
      }

      return Math.abs(daysDifference);
    }
  }, [compEvent, computedNextStepDueDate]);

  const shallDisplayNextStepButton = !!compEventActionList.length;

  const triggerNextStepFlow = () => {
    toggleNextStepModalVisibility();
  };

  const handleAuthorizationBannerClick = () => {
    if (computedOngoingAuthorizationAudit) {
      setAuthModalState({
        authAuditId: computedOngoingAuthorizationAudit.id,
        type: canUserReviewCrtLevel ? "AuthorizeDeny" : "AuditTrail",
        compEventId: compEvent?.id,
        onChange,
      });
    }
  };

  return (
    <>
      <CompEventActionModal
        open={nextStepModalVisibility}
        onClose={toggleNextStepModalVisibility}
        compEvent={compEvent}
        compEventActions={compEventActionList}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        data-testid="ce-next-step-section"
      >
        <Box
          display="flex"
          flexDirection={previewMode ? "row" : "column"}
          alignItems={previewMode ? "center" : "flex-start"}
          width="100%"
        >
          {computedOngoingAuthorizationAudit && !previewMode && (
            <AuthorizationBanner
              size="small"
              stage="inProgress"
              onClick={
                isUserReviewer ? handleAuthorizationBannerClick : undefined
              }
              mb={2}
            />
          )}
          <Box display="flex" flexDirection="column" flex={3}>
            <Typography variant="p2" fontWeight={600} color="grey.800">
              {t("Projects.CompEvents.nextStep")}
            </Typography>
            <Typography
              variant="p2"
              color="grey.800"
              mt={0.5}
              data-testid="next-step"
            >
              {t(computedNextStepText!)}
            </Typography>
          </Box>
          {computedNextStepDueDate && (
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              mt={previewMode ? 0 : 2}
            >
              <Typography variant="p2" fontWeight={600} color="grey.800">
                {t("common.labels.dueDate")}
              </Typography>
              <Box display="flex" alignItems="center" mt={0.5}>
                <Typography
                  variant="p2"
                  color="grey.800"
                  data-testid="due-date"
                >
                  {moment(computedNextStepDueDate).format(dateISOFormat)}
                </Typography>
                {computedNextStepDaysLate && computedNextStepDaysLate > 0 ? (
                  <DaysLate daysLate={computedNextStepDaysLate} ml={1.5} />
                ) : null}
              </Box>
            </Box>
          )}
        </Box>
        {shallDisplayNextStepButton && !previewMode && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignSelf="stretch"
          >
            <Button
              sx={{ minWidth: "32px" }}
              data-testid="ce-next-step-btn"
              onClick={triggerNextStepFlow}
            >
              <CaretRight color={theme.palette.grey[800]} size={18} />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
