import {
  AuthorizationWorkflowAudit,
  CompEventAction,
  CompEventActionQuery,
  CompEventActionQueryVariables,
  CompEventRegardingType,
} from "generated/graphql";
import { compEventActionQuery } from "graphql/queries/compEventActionsList.query";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useCEWidgetNextStepSection = (
  regardingType: CompEventRegardingType,
  regardingId: string,
  productInstanceId?: string,
  compEventId?: string
) => {
  const { data: compEventActionList, loading: compEventActionListLoading } =
    useGraphQuery<CompEventActionQuery, CompEventActionQueryVariables>(
      compEventActionQuery,
      {
        variables: {
          input: {
            productInstanceId: compEventId ? undefined : productInstanceId,
            compEventId,
            regardingType,
            regardingId, // Note: it happened `regardingId` to be ''. Possibly because of useMemo from CompEventWidget.tsx. If it happens again, take it directly from useParams
          },
        },
        skip: !productInstanceId && !compEventId,
      }
    );

  return {
    compEventActionList: (compEventActionList?.compEventAction.items ??
      []) as CompEventAction[],
    ongoingAuthorizationAudit: compEventActionList?.compEventAction
      .ongoingAuthorizationAudit as AuthorizationWorkflowAudit | undefined,
    loading: compEventActionListLoading,
  };
};
