import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  GenerateRecordClaimDetailsResponsePreviewMutation,
  GenerateRecordClaimDetailsResponsePreviewMutationVariables,
  RecordClaimDetailsResponsePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { generateRecordClaimDetailsResponsePreviewMutation } from "./RespondRegardingDetailedClaimAction.query";

export const useRecordResponseRegardingDetailedClaimModal = (
  detailedClaimId: string,
  details: string
) => {
  const { claim, contract } = useContext(ClaimWidgetContext);

  const [claimPreview, setClaimPreview] =
    useState<RecordClaimDetailsResponsePreview>();

  const [generateClaimPreview] = useGraphMutation<
    GenerateRecordClaimDetailsResponsePreviewMutation,
    GenerateRecordClaimDetailsResponsePreviewMutationVariables
  >(generateRecordClaimDetailsResponsePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            detailedClaimId,
            details,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateRecordClaimDetailsResponsePreview);
      }
    }, [generateClaimPreview, claim, detailedClaimId, details]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    claimPreview,
    contract,
  };
};
