import { NewPageContentContainer } from "components/NewPageContentContainer";
import { ReactComponent as NotFoundSvg } from "../../assets/icons/not-found.svg";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NewAppPaths } from "helpers/paths/paths";
import { extendedTheme } from "theme/extendedTheme";
import { NotFoundCard, NotFoundTextContainer } from "./NotFound.styled";
import { useTranslation } from "react-i18next";

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateHome = () => {
    navigate(NewAppPaths.authorized.Home);
  };

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <NewPageContentContainer
        flexDirection="row"
        sx={{ alignItems: "flex-start" }}
      >
        <NotFoundCard>
          <NotFoundTextContainer>
            <Typography variant="h2" fontWeight={700} color="grey.800">
              {t("NotFoundPage.title")}
            </Typography>
            <Typography variant="p1" mt={2} color="grey.800">
              {t("NotFoundPage.subtitle")}
            </Typography>
            <Box mt={5}>
              <Button variant="contained" onClick={navigateHome}>
                {t("NotFoundPage.backBtnCaption")}
              </Button>
            </Box>
          </NotFoundTextContainer>
          <Box mr={6}>
            <NotFoundSvg />
          </Box>
        </NotFoundCard>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
