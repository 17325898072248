import { AddContractInput } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";

export const defaultContract: AddContractInput = {
  contractTypeId: "",
  country: "",
  endDate: "",
  friendlyName: "",
  name: "",
  number: "",
  projectId: "",
  province: "",
  startDate: "",
  timeZone: "",
  value: "" as any,
  valueCurrency: "",
  coordinatesLongitude: undefined,
  coordinatesLatitude: undefined,
};

export const getContractDataValidators = (hasAmount?: boolean) => {
  const dataValidators: DataValidators = {
    name: {
      validators: [ValidatorType.Required],
    },
    friendlyName: {
      validators: [ValidatorType.Required],
    },
    contractTypeId: {
      validators: [ValidatorType.Required],
    },
    country: {
      validators: [ValidatorType.Required],
    },
    timeZone: {
      validators: [ValidatorType.Required],
    },
    value: {
      validators: [ValidatorType.PositiveNumberOrZero],
    },
    valueCurrency: {
      validators: [ValidatorType.Required],
    },
    coordinatesLatitude: {
      validators: [ValidatorType.ValidOptionalNumber],
    },
    coordinatesLongitude: {
      validators: [ValidatorType.ValidOptionalNumber],
    },
  };

  if (hasAmount) {
    dataValidators.valueCurrency = { validators: [ValidatorType.Required] };
  }

  return dataValidators;
};
