import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFR from "./locales/fr/translation.json";
import translationEN from "./locales/en/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};
const configurei18n = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "en",
      fallbackLng: "en",

      interpolation: {
        escapeValue: false,
      },
    });
};

export default configurei18n;
