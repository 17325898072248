import { gql } from "@apollo/client";
import { dailyDiaryWorkRecordLiteFields } from "graphql/fragments/dailyDiaryWorkRecordLite.fragment";

export const addDailyDiaryWorkRecordMutation = gql`
  ${dailyDiaryWorkRecordLiteFields}

  mutation addDailyDiaryWorkRecord($input: AddDailyDiaryWorkRecordInput!) {
    addDailyDiaryWorkRecord(input: $input) {
      ...DailyDiaryWorkRecordLiteFields
    }
  }
`;
