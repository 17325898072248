import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import React from "react";
import { useClaimHistoryItem } from "../hooks/useClaimHistoryItem/useClaimHistoryItem";
import { ClaimHistoryItemDetailsSummary } from "containers/Projects/components/Claims/ClaimHistoryModal/ClaimHistoryItemDataView";
import { useContract } from "containers/Projects/hooks/useContract/useContract";

type ClaimSummaryProps = {
  authAudit: AuthorizationWorkflowAudit;
};

export const ClaimSummary: React.FC<ClaimSummaryProps> = ({ authAudit }) => {
  const { claimHistoryItem, loading } = useClaimHistoryItem(
    authAudit.regardingItemHistoryId ?? undefined
  );
  const { contract, contractLoading } = useContract(
    authAudit.action.productInstance.contractId
  );

  return loading || !claimHistoryItem || contractLoading || !contract ? (
    <CenteredLoadingIndicator />
  ) : (
    <ClaimHistoryItemDetailsSummary
      historyItem={claimHistoryItem}
      contract={contract}
    />
  );
};
