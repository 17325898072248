import { gql } from "@apollo/client";
import { lookupCollectionFields } from "./lookupCollection.query";

export const lookupCollectionsQuery = gql`
  ${lookupCollectionFields}

  query lookupCollections {
    lookupCollections {
      items {
        ...LookupCollectionFields
      }
    }
  }
`;
