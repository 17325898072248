import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventRequestForMoreTimePreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventRequestForMoreTimePreview(
    $input: RecordCompEventRequestForMoreTimePreviewInput!
  ) {
    generateRecordCompEventRequestForMoreTimePreview(input: $input) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
