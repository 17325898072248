import { debounce } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateSendClaimErrorAgreementDisagreementPreviewMutation,
  GenerateSendClaimErrorAgreementDisagreementPreviewMutationVariables,
  SendClaimErrorAgreementDisagreementPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { eventDebounceDuration } from "constants/constants";
import { generateSendClaimErrorAgreementDisagreementPreviewMutation } from "./DisagreeWithNoticeOfErrorInAgreement.query";

export const useSendDisagreementRegardingNoticeOfErrorInAgreementModal = (
  remarks: string
) => {
  const [claimPreview, setClaimPreview] =
    useState<SendClaimErrorAgreementDisagreementPreview>();

  const { claim, contract } = useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimErrorAgreementDisagreementPreviewMutation,
    GenerateSendClaimErrorAgreementDisagreementPreviewMutationVariables
  >(generateSendClaimErrorAgreementDisagreementPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            remarks,
          },
        },
      });

      if (data) {
        setClaimPreview(
          data.generateSendClaimErrorAgreementDisagreementPreview
        );
      }
    }, [generateClaimPreview, claim, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // TODO: right now we're generating a preview based on the first set of props the component receives. If it was to re-generate every time the props change
    // and knowing how React is working, it would've generated too many preview files which had to be deleted later. If previews turn out not to be accurate,
    // we should instead do a deep equality on the props and remove the old one and generate a new preview only if props are indeed different.

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
