import { gql } from "@apollo/client";

export const addContractMutation = gql`
  mutation addContract($input: AddContractInput!) {
    addContract(input: $input) {
      id
      name
      friendlyName
      #number
      #valueCurrency
      #value
      #startDate
      #endDate
      #contractTypeId
      #projectId
      #country
      #province
      #timeZone
      #coordinatesLatitude
      #coordinatesLongitude
      #status
      #dateCreated
      #creator {
      #  id
      #  firstname
      #  surname
      #}
      #contractBindings {
      #  items {
      #    id
      #    status
      #    defaultCompany
      #    representativeId
      #    isCompanyBilled
      #    dateCreated
      #    creatorId
      #    creator
      #    companyId
      #  }
      #}
    }
  }
`;
