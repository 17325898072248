import { gql } from "@apollo/client";
import { authorizationWorkflowLevelFields } from "./authorizationWorkflowLevel.fragment";
import { creatorFields } from "./creator.fragment";
import { projectLiteFields } from "./projectLite.fragment";

export const authorizationWorkflowFields = gql`
  ${authorizationWorkflowLevelFields}
  ${creatorFields}
  ${projectLiteFields}

  fragment AuthorizationWorkflowFields on AuthorizationWorkflow {
    id
    name
    levels {
      ...AuthorizationWorkflowLevelFields
    }
    status
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
    }
    modifiedById
    modifiedBy {
      ...CreatorFields
    }
    projectId
    project {
      ...ProjectLiteFields
    }
  }
`;
