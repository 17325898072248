import { Box } from "@mui/material";
import { ClaimItem, ProductType } from "generated/graphql";
import { ProductItemPreviewContainer } from "components/ProductItemPreview/ProductItemPreviewContainer";
import { ProductItemPreviewHeader } from "components/ProductItemPreview/ProductItemPreviewHeader";
import { ClaimWidget } from "../ClaimWidget/ClaimWidget";
import { useContractByProductInstanceId } from "containers/Projects/hooks/useContractByProductInstanceId/useContractByProductInstanceId";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";

export type ClaimItemPreviewProps = {
  claim: ClaimItem;
};

export const ClaimItemPreview: React.FC<ClaimItemPreviewProps> = ({
  claim,
}) => {
  const { contract, loading } = useContractByProductInstanceId(
    claim.productInstanceId
  );

  return loading || !contract ? (
    <CenteredLoadingIndicator />
  ) : (
    <ProductItemPreviewContainer>
      <ProductItemPreviewHeader
        productType={ProductType.Claims}
        itemNumber={claim.number}
        dateCreated={claim.dateCreated}
        contractTimezone={contract.timeZone}
      />
      <Box mt={3} width="100%">
        <ClaimWidget
          previewMode
          loading={false}
          claimId={claim.id}
          contract={contract}
          regardingId={claim.regardingId}
          projectId={contract.project.id}
          projectName={contract.project.friendlyName}
          eventItem={claim.regardingEvent ?? undefined}
          instructionItem={claim.regardingInstruction ?? undefined}
        />
      </Box>
    </ProductItemPreviewContainer>
  );
};
