import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const TrashIconContained: React.FC<BaseIconProps> = ({
  height = "41",
  width = "41",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.error.main;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40.0048"
        height="40.0048"
        rx="8"
        fill={theme.palette.error.light}
      />
      <path
        d="M28.2533 13.2511H11.75"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7539 17.752V23.7532"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2539 17.752V23.7532"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7527 13.2511V27.504C26.7527 27.7029 26.6737 27.8937 26.533 28.0344C26.3923 28.1751 26.2015 28.2541 26.0026 28.2541H14.0002C13.8012 28.2541 13.6104 28.1751 13.4697 28.0344C13.329 27.8937 13.25 27.7029 13.25 27.504V13.2511"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7515 13.2511V11.7508C23.7515 11.3529 23.5934 10.9713 23.3121 10.6899C23.0307 10.4086 22.6491 10.2505 22.2512 10.2505H17.7503C17.3524 10.2505 16.9708 10.4086 16.6894 10.6899C16.4081 10.9713 16.25 11.3529 16.25 11.7508V13.2511"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
