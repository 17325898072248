import { useRef, useState } from "react";

const headersOffset = 250;
const reactRootElId = "#root";

export const useHeaderLinks = () => {
  const attachmentsContainerRef = useRef<HTMLDivElement>();
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const [commentsCount, setCommentsCount] = useState<number>();
  const [hasMoreComments, setHasMoreComments] = useState<boolean>();

  const handleCommentsLoaded = (count: number, hasMore?: boolean) => {
    setCommentsCount(count);
    setHasMoreComments(hasMore);
  };

  const scrollToContainer = (el: HTMLDivElement) => {
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headersOffset;

    document.body.querySelector(reactRootElId)!.scrollBy({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleAttachmentsClick = () => {
    if (!attachmentsContainerRef.current) return;

    scrollToContainer(attachmentsContainerRef.current);
  };

  const handleCommentsClick = () => {
    if (!commentsContainerRef.current) return;

    scrollToContainer(commentsContainerRef.current);
  };

  return {
    attachmentsContainerRef,
    commentsContainerRef,
    commentsCount,
    hasMoreComments,
    handleCommentsLoaded,
    handleAttachmentsClick,
    handleCommentsClick,
  };
};
