import { css, Stack, styled } from "@mui/material";

export const ValueContainer = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== "focused" && prop !== "adminConsoleStyle" && prop !== "error",
})<{ focused?: boolean; adminConsoleStyle?: boolean; error?: boolean }>(
  ({ theme, focused, adminConsoleStyle, error }) => {
    const focusedCSS = css`
      border-color: ${theme.palette.primary.main};
      outline: none;
      z-index: 1;
    `;

    return css`
      box-sizing: border-box;
      width: 100%;
      height: ${adminConsoleStyle ? "32px" : "56px"};
      border: 1px solid ${theme.palette.grey[200]};
      padding: ${theme.spacing(2)} ${theme.spacing(1.75)};
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      min-width: 80px;
      position: relative;

      &:hover {
        border-color: ${theme.palette.grey[500]};
      }

      ${focused ? focusedCSS : ""}
      &:focus {
        ${focusedCSS}
      }

      ${adminConsoleStyle
        ? css`
            border: none;
            border-bottom: 1px solid ${theme.palette.grey[600]};
            border-radius: 0;
            padding: 0;

            &:hover,
            &:focus,
            &:active {
              border-bottom-width: 2px;
              border-bottom-color: ${theme.palette.common.black};
            }

            ${focused
              ? css`
                  &,
                  &:hover {
                    border-bottom-width: 2px;
                    border-bottom-color: ${theme.palette.primary.main};
                  }
                `
              : ""}
          `
        : ""}

      ${error
        ? css`
            border-color: ${theme.palette.error.main};
          `
        : ""}
    `;
  }
);
