import { Box } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { GridHeader } from "components/GridHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  CompanyLookupCollection,
  CompanyLookupCollectionStatus,
} from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { NewAppPaths } from "helpers/paths/paths";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useSnackbar } from "notistack";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useColumns,
  UsageToTranslation,
} from "./CustomSimpleLookups.constants";
import { useCompanies } from "hooks/useCompanies";
import { useDuplicateLookupModal } from "../SimpleLookups/DuplicateLookupModal/useDuplicateLookupModal";
import { DuplicateLookupCollectionModal } from "../SimpleLookups/DuplicateLookupModal/DuplicateLookupModal";
import { useCustomSimpleLookups } from "./useCustomSimpleLookups";
import { adminConsolePageContentDifference } from "containers/AdminConsole/AdminConsole.constants";
import { getUserName, openLocallyOrInNewTab } from "helpers/miscelaneous";
import { excelDateTimeISOFormat } from "constants/constants";

// NOTE: For now, CustomLookups are in fact CompanyLookups. Kept the "Custom" name in case there will appear other type of customisations
export const CustomSimpleLookups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<CompanyLookupCollectionStatus>[];
  const collectionToDuplicate = useRef<CompanyLookupCollection>();
  const {
    modalVisible,
    loading: duplicateLoading,
    setModalVisible: setDuplicateLookupCollectionModalVisibility,
    handleConfirm: handleDuplicateConfirm,
    handleCancel: handleDuplicateCancel,
    handleCloseModal: handleDuplicateClose,
  } = useDuplicateLookupModal();
  const { companies, loading: companiesLoading } = useCompanies();

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [CompanyLookupCollectionStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const {
    companyLookupCollections,
    loading: companyLookupCollectionsLoading,
    hasMore,
    loadMore,
    changeCompanyLookupCollectionStatus,
    refetchCompanyLookupCollections,
  } = useCustomSimpleLookups();

  const handleExcelExport = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name" },
      {
        header: t("AdminConsole.Lookups.optionsLockedForUsers"),
        key: "optionsLockedForUsers",
      },
      {
        header: t("AdminConsole.Lookups.optionsCharLengthLimit"),
        key: "optionsCharLengthLimit",
      },
      { header: t("common.labels.status"), key: "status" },
      { header: t("common.labels.company"), key: "company" },
      { header: t("AdminConsole.Lookups.usage"), key: "usage" },
      {
        header: t("common.labels.dateCreated"),
        key: "created",
        numFmt: excelDateTimeISOFormat,
      },
      { header: t("common.labels.createdBy"), key: "creator" },
    ];
    const rows =
      companyLookupCollections
        .filter(
          (lookupCollection) =>
            (selectionModel || []).indexOf(lookupCollection.id) >= 0
        )
        .map((lookupCollection) => ({
          ...lookupCollection,
          company: companies.find(
            (company) => company.id === lookupCollection.companyId
          )?.tradingName,
          usage: UsageToTranslation[lookupCollection.usage],
          creator: getUserName(lookupCollection.creator),
          created: lookupCollection
            ? new Date(lookupCollection.dateCreated)
            : "",
        })) || [];

    exportToExcel(
      t("AdminConsole.Lookups.customLookupCollections"),
      columns,
      rows
    );
  };

  const handleCreateNewLookupCollection = () => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.System.children
        .NewCustomLookupCollection
    );
  };

  const handleDuplicateLookupCollection = async (
    newCollectionName: string,
    selectedCompanyId?: string
  ) => {
    if (!collectionToDuplicate.current) {
      // something went wrong, there's no collection to duplicate, however the duplicate modal is open
      return;
    }

    const response = await handleDuplicateConfirm(
      collectionToDuplicate.current!.id,
      {
        name: newCollectionName,
        optionsCharLengthLimit:
          collectionToDuplicate.current!.optionsCharLengthLimit,
        optionsLockedForUsers:
          collectionToDuplicate.current!.optionsLockedForUsers,
        usage: collectionToDuplicate.current!.usage,
        companyId:
          selectedCompanyId ?? collectionToDuplicate.current!.companyId,
      }
    );

    if (response) {
      refetchCompanyLookupCollections();
      setDuplicateLookupCollectionModalVisibility(false);
    } else {
      enqueueSnackbar(t("common.errorMessages.unknownErrorOccured"), {
        variant: "error",
      });
    }
  };

  const triggerDuplicateCollectionFlow = useCallback(
    async (lookupCollection: CompanyLookupCollection) => {
      collectionToDuplicate.current = lookupCollection;
      setDuplicateLookupCollectionModalVisibility(true);
    },
    [setDuplicateLookupCollectionModalVisibility]
  );

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (clickedRowData: GridRowParams<CompanyLookupCollection>, event) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        const url =
          NewAppPaths.authorized.AdminConsole.children.System.children.CustomLookupCollectionDetails.pathConstructor(
            clickedRowData.row.id
          );
        openLocallyOrInNewTab(url, event, navigate);
      }
    },
    [navigate]
  );

  const handleRowsScrollEnd = useCallback(() => {
    if (hasMore) {
      console.log("Has more collections to load. Loading more...");
      loadMore();
    }
  }, [hasMore, loadMore]);

  const handleLookupCollectionStatusChange = useCallback(
    async (
      row: CompanyLookupCollection,
      newStatus: CompanyLookupCollectionStatus
    ) => {
      await changeCompanyLookupCollectionStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeCompanyLookupCollectionStatus]
  );

  const columns = useColumns({
    statusOptions,
    companies,
    onStatusChange: handleLookupCollectionStatusChange,
    onDuplicateCollection: triggerDuplicateCollectionFlow,
  });

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex="1">
      <DuplicateLookupCollectionModal
        open={modalVisible}
        companies={companies}
        collectionToDuplicateName={collectionToDuplicate.current?.name}
        collectionToDuplicateId={collectionToDuplicate.current?.companyId}
        primaryBtnLoading={duplicateLoading}
        onClose={handleDuplicateClose}
        onPrimaryClick={handleDuplicateLookupCollection}
        onSecondaryClick={handleDuplicateCancel}
      />
      <GridHeader
        title={t("AdminConsole.Lookups.customLookupCollections")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t(
            "AdminConsole.Lookups.customLookupCollection"
          ).toLowerCase(),
        })}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExcelExport}
        onActionButtonClick={handleCreateNewLookupCollection}
        sticky
      />
      <Box
        sx={{
          flex: 1,
          width: "100%",
          maxHeight: `calc(100vh - ${adminConsolePageContentDifference}px)`,
        }}
      >
        <StyledDataGrid
          apiRef={gridApiRef}
          rows={companyLookupCollections}
          columns={columns}
          getRowId={(rowData: CompanyLookupCollection) => rowData.id}
          onRowSelectionModelChange={setSelectionModel}
          loading={companyLookupCollectionsLoading || companiesLoading}
          sortingMode="client"
          sortModel={sortingModel}
          onSortModelChange={setSortingModel}
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onRowClick={handleRowClick}
          getCellClassName={(
            params: GridCellParams<any, CompanyLookupCollection, any>
          ) => {
            return params.row.status === CompanyLookupCollectionStatus.Removed
              ? "greyed-out"
              : "";
          }}
          onRowsScrollEnd={handleRowsScrollEnd}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};
