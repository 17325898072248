import { Box } from "@mui/material";
import { ListItemsDivider } from "components/ListItemsDivider";
import { EffectOption } from "generated/graphql";
import React, { useMemo } from "react";
import { EffectOptionFieldReadOnly } from "./components/EffectOptionFieldReadOnly";
import { EffectWidgetValue } from "./Effect.decl";

export type EffectReadOnlyProps = {
  effectOptions: EffectOption[];
  widgetData: EffectWidgetValue;
  contractCurrency: string;
  noOverflow?: boolean;
};

export const EffectReadOnly: React.FC<EffectReadOnlyProps> = ({
  effectOptions,
  widgetData,
  contractCurrency,
  noOverflow,
}) => {
  const noOfSelectedOptions = useMemo(
    () => widgetData.answers.filter((optionData) => optionData.selected).length,
    [widgetData]
  );

  return (
    <Box display="flex" flexDirection="column">
      {effectOptions.map((effectOption, index) => {
        const optionData = widgetData.answers.find(
          (answer) => answer.optionId === effectOption.id
        );

        return (
          <React.Fragment key={effectOption.id}>
            <EffectOptionFieldReadOnly
              effectOption={effectOption}
              data={optionData}
              contractCurrency={contractCurrency}
              noOverflow={noOverflow}
              allowCheck={noOfSelectedOptions > 0}
            />
            {index < effectOptions.length - 1 && <ListItemsDivider />}
          </React.Fragment>
        );
      })}
    </Box>
  );
};
