import { ProjectSector } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";

export const projectDataValidators: DataValidators = {
  name: {
    validators: [ValidatorType.Required],
  },
  friendlyName: {
    validators: [ValidatorType.Required],
  },
  sector: {
    validators: [ValidatorType.Required],
  },
  billingCompanyId: {
    validators: [ValidatorType.Required],
  },
};

export const ProjectSectorMapping: Record<ProjectSector, string> = {
  [ProjectSector.Building]: "Building",
  [ProjectSector.EnergyFossil]: "Energy fossil",
  [ProjectSector.EnergyNuclear]: "Energy nuclear",
  [ProjectSector.EnergyRenewable]: "Energy renewable",
  [ProjectSector.MiningMinerals]: "Mining minerals",
  [ProjectSector.OilGas]: "Oil gas",
  [ProjectSector.Other]: "Other",
  [ProjectSector.Petrochem]: "Petrochem",
  [ProjectSector.Rail]: "Rail",
  [ProjectSector.Roads]: "Roads",
  [ProjectSector.Telecoms]: "Telecoms",
};
