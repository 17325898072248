import {
  DailyDiaryItem,
  ImportDailyDiaryEquipmentRecordsMutation,
  ImportDailyDiaryEquipmentRecordsMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useEquipmentRecords } from "./useEquipmentRecords";
import { importDailyDiaryEquipmentRecordsMutation } from "./EquipmentSection.query";

export const useImportEquipmentRecordsModal = (dailyDiary?: DailyDiaryItem) => {
  const { t } = useTranslation();

  const { equipmentRecords, equipmentRecordsLoading } =
    useEquipmentRecords(dailyDiary);

  const [importRecords, { loading: importLoading }] = useGraphMutation<
    ImportDailyDiaryEquipmentRecordsMutation,
    ImportDailyDiaryEquipmentRecordsMutationVariables
  >(
    importDailyDiaryEquipmentRecordsMutation,
    {},
    t("Projects.DailyDiaries.ImportRecordsModal.importSuccess")
  );

  return {
    equipmentRecords,
    loading: equipmentRecordsLoading || importLoading,
    importLoading,
    equipmentRecordsLoading,
    importRecords,
  };
};
