import { gql } from "@apollo/client";
import { dailyDiaryPresetFields } from "graphql/fragments/dailyDiaryPreset.fragment";

export const dailyDiaryPresetQuery = gql`
  ${dailyDiaryPresetFields}

  query dailyDiaryPreset($id: ID!) {
    dailyDiaryPreset(id: $id) {
      ...DailyDiaryPresetFields
    }
  }
`;
