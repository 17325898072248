import { Box, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { ChangeIcon } from "components/Icons/ChangeIcon";
import { ItemStatusOptionReason } from "generated/graphql";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ChangeProductItemStatusForm,
  ChangeProductItemFormValuesType,
} from "./ChangeProductItemStatusForm";

export type ChangeProductItemStatusModalProps = {
  oldStatusName: string;
  newStatusName: string;
  reasons: ItemStatusOptionReason[];
  onPrimaryClick: (reasonId: string, remarks?: string) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const ChangeProductItemStatusModal: React.FC<
  ChangeProductItemStatusModalProps
> = ({
  oldStatusName,
  newStatusName,
  reasons,
  onSecondaryClick,
  onPrimaryClick,
  onClose,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [selectedReasonId, setSelectedReasonId] = useState<string>("");
  const [currentRemarks, setCurrentRemarks] = useState<string>("");

  const sortedReasons = useMemo(() => {
    const copy = reasons.slice();
    copy.sort((r1, r2) => r1.displayOrder - r2.displayOrder);
    return copy;
  }, [reasons]);

  const cleanForm = () => {
    setSelectedReasonId("");
    setCurrentRemarks("");
  };

  const handlePrimaryBtnClick = () => {
    if (selectedReasonId) {
      onPrimaryClick(selectedReasonId, currentRemarks);
      cleanForm();
    }
  };

  const handleSecondaryClick = () => {
    onSecondaryClick();
    cleanForm();
  };

  const handleOnClose = () => {
    onClose?.({}, "backdropClick");
    cleanForm();
  };

  const handleFormChange = ({
    reasonId,
    remarks,
  }: ChangeProductItemFormValuesType) => {
    if (reasonId && reasonId !== selectedReasonId) {
      setSelectedReasonId(reasonId);
    }
    if (remarks !== currentRemarks) {
      setCurrentRemarks(remarks);
    }
  };

  return (
    <ActionsDialog
      onPrimaryClick={handlePrimaryBtnClick}
      onSecondaryClick={handleSecondaryClick}
      onClose={handleOnClose}
      iconsHeader={<ChangeIcon />}
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
          >
            {t("Projects.Risks.ChangeStatusModal.title", {
              oldStatus: oldStatusName,
              newStatus: newStatusName,
            })}
          </Typography>
        </Box>
      }
      content={
        <ChangeProductItemStatusForm
          reasons={sortedReasons}
          reasonId={selectedReasonId}
          remarks={currentRemarks}
          onChange={handleFormChange}
        />
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      secondaryBtnCaption={t("common.buttons.cancel")}
      primaryBtnCaption={t("common.labels.saveChanges")}
      {...restDialogProps}
    />
  );
};
