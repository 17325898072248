import { Box, css, Stack, styled, Tooltip } from "@mui/material";
import {
  ClaimDetailsExplorerItem,
  CompEventQuotation,
  CompEventQuotationAssessment,
  Contract,
  ProductType,
  VariationProposalExplorerItem,
} from "generated/graphql";
import { t } from "i18next";
import { CheckCircle } from "phosphor-react";
import { ContractPriceTimeChangeListItem } from "./ContractPriceTimeChangeListItem";

export type ContractPriceTimeChangeListProps = {
  items:
    | CompEventQuotationAssessment[]
    | ClaimDetailsExplorerItem[]
    | VariationProposalExplorerItem[];
  productType:
    | ProductType.CompEvents
    | ProductType.Claims
    | ProductType.Variations;
  selectedItemId: string;
  contract: Contract;
  onSelectedItemChange: (itemId: string) => void;
};

const AcceptedContractPriceTimeChangeContainer = styled(Box)(
  ({ theme }) => css`
    border-radius: 50%;
    background-color: ${theme.palette.success.main};
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1.5)};
  `
);

export const ContractPriceTimeChangeList: React.FC<
  ContractPriceTimeChangeListProps
> = ({
  items,
  selectedItemId,
  contract,
  productType,
  onSelectedItemChange,
}) => {
  return (
    <Stack spacing={2} direction="column">
      {items.map((item) => {
        const isQuotationOrAssessment =
          item.__typename === "CompEventAssessment" ||
          item.__typename === "CompEventQuotation";
        const isAccepted =
          item.__typename === "CompEventAssessment" ||
          (item.__typename === "CompEventQuotation" &&
            (item as CompEventQuotation).accepted) ||
          item.__typename === "ClaimAgreement" ||
          item.__typename === "ClaimDetermination" ||
          item.__typename === "VariationAgreement" ||
          item.__typename === "VariationDetermination";

        return (
          <Stack
            direction="row"
            key={item.id}
            alignItems="center"
            justifyContent="flex-end"
          >
            <>
              {isAccepted && (
                <Tooltip
                  title={
                    (isQuotationOrAssessment
                      ? t(
                          "Projects.CompEvents.acceptedQuotationAssessmentTooltip"
                        )
                      : t(
                          `Projects.${
                            productType === ProductType.Claims
                              ? "Claims"
                              : "Variations"
                          }.acceptedContractPriceTimeChangeTooltip`
                        )) as string
                  }
                >
                  <AcceptedContractPriceTimeChangeContainer>
                    <CheckCircle color="white" />
                  </AcceptedContractPriceTimeChangeContainer>
                </Tooltip>
              )}
              <ContractPriceTimeChangeListItem
                key={item.id}
                item={item}
                contract={contract}
                selected={selectedItemId === item.id}
                onClick={() => onSelectedItemChange(item.id)}
              />
            </>
          </Stack>
        );
      })}
    </Stack>
  );
};
