import { gql } from "@apollo/client";
import { companyLiteFields } from "./companyLite.fragment";
import { creatorFields } from "./creator.fragment";

export const projectLiteFields = gql`
  ${creatorFields}
  ${companyLiteFields}

  fragment ProjectLiteFields on Project {
    id
    name
    friendlyName
    number
    sector
    description
    status
    dateCreated
    creatorId
    billingCompanyId
    creator {
      ...CreatorFields
    }
    contracts {
      items {
        id
        name
        friendlyName
        status
      }
    }
    billingCompany {
      ...CompanyLiteFields
    }
  }
`;
