import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { lookupOptionFields } from "./lookupOption.query";

export const lookupCollectionFields = gql`
  ${creatorFields}

  fragment LookupCollectionFields on LookupCollection {
    id
    name
    optionsLockedForUsers
    optionsCharLengthLimit
    isInternal
    status
    creatorId
    dateCreated
    creator {
      ...CreatorFields
    }
  }
`;

export const lookupCollectionQuery = gql`
  ${lookupCollectionFields}
  ${lookupOptionFields}

  query lookupCollection($id: ID!) {
    lookupCollection(id: $id) {
      ...LookupCollectionFields
      options {
        items {
          ...LookupOptionFields
        }
      }
    }
  }
`;
