import {
  AddDailyDiaryDelayRecordInput,
  DailyDiaryPresetSection,
  EditDailyDiaryDelayRecordInput,
} from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";
import { useMemo } from "react";
import { DelayLookups } from "./DelayRecordForm";

export const useDelayRecordFormValidators = (
  section: DailyDiaryPresetSection,
  formData: AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput,
  lookups: DelayLookups
) => {
  const dataValidators: DataValidators = useMemo(() => {
    // DESCRIPTION
    const descriptionField = section.fields.find(
      (field) => field.name === "Description"
    );

    const descriptionColValidators: DataValidators | undefined =
      descriptionField &&
      !descriptionField.isHidden &&
      descriptionField.isRequired
        ? {
            description: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    // DelayType
    const delayTypeField = section.fields.find(
      (field) => field.name === "DelayType"
    );

    const delayTypeColValidators: DataValidators | undefined =
      delayTypeField && !delayTypeField.isHidden
        ? {
            delayTypeOptionId: {
              validators: [
                delayTypeField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.delayTypeOptionId,
                lookups?.DelayType.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // Shift
    const shiftField = section.fields.find((field) => field.name === "Shift");

    const shiftColValidators: DataValidators | undefined =
      shiftField && !shiftField.isHidden
        ? {
            shiftOptionId: {
              validators: [
                shiftField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.shiftOptionId,
                lookups?.Shift.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // Area
    const areaField = section.fields.find((field) => field.name === "Area");

    const areaColValidators: DataValidators | undefined =
      areaField && !areaField.isHidden
        ? {
            areaOptionId: {
              validators: [
                areaField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.areaOptionId,
                lookups?.Area.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // Discipline
    const disciplineField = section.fields.find(
      (field) => field.name === "Discipline"
    );

    const disciplineColValidators: DataValidators | undefined =
      disciplineField && !disciplineField.isHidden
        ? {
            disciplineOptionId: {
              validators: [
                disciplineField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.disciplineOptionId,
                lookups?.Discipline.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // Team
    const teamField = section.fields.find((field) => field.name === "Team");

    const teamColValidators: DataValidators | undefined =
      teamField && !teamField.isHidden
        ? {
            teamOptionId: {
              validators: [
                teamField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.teamOptionId,
                lookups?.Team.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // Duration
    const durationField = section.fields.find(
      (field) => field.name === "Duration"
    );

    const durationColValidators: DataValidators | undefined =
      durationField && !durationField.isHidden && durationField.isRequired
        ? {
            duration: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    return {
      ...descriptionColValidators,
      ...delayTypeColValidators,
      ...shiftColValidators,
      ...areaColValidators,
      ...disciplineColValidators,
      ...teamColValidators,
      ...durationColValidators,
    };
  }, [lookups, formData, section]);

  return dataValidators;
};
