import {
  DailyDiaryPreset,
  DailyDiaryPresetsQuery,
  DailyDiaryPresetsQueryVariables,
} from "generated/graphql";
import { dailyDiaryPresetsQuery } from "graphql/queries/dailyDiaryPresets.query";
import { useGraphQuery } from "./useGraphQuery";

export const useDailyDiaryPresets = () => {
  const {
    data: dailyDiaryPresets,
    refetch: refetchDailyDiaryPresets,
    loading: dailyDiaryPresetsLoading,
  } = useGraphQuery<DailyDiaryPresetsQuery, DailyDiaryPresetsQueryVariables>(
    dailyDiaryPresetsQuery
  );

  return {
    dailyDiaryPresets: (dailyDiaryPresets?.dailyDiaryPresets.items ??
      []) as DailyDiaryPreset[],
    loading: dailyDiaryPresetsLoading,
    refetchDailyDiaryPresets,
  };
};
