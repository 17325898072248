import { Box, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { IconContainer } from "components/IconContainer";
import {
  ContractKeyDate,
  ContractKeyDateStatus,
  ContractSection,
  ContractSectionStatus,
  DraftAssessment,
  DraftQuotation,
} from "generated/graphql";
import { Money } from "phosphor-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CEQuotationAssessment,
  CEQuotationAssessmentFormPublicAPI,
} from "./CEQuotationAssessment/CEQuotationAssessment";
import { QuotationAssessmentFormDataType } from "./CEQuotationAssessment/CEQuotationAssessment.decl";

export enum CEQuotationAssessmentModalType {
  Quotation = "Quotation",
  Assessment = "Assessment",
}

enum InternalModalType {
  CreateQuotation = "CreateQuotation",
  EditQuotation = "EditQuotation",
  CreateAssessment = "CreateAssessment",
  EditAssessment = "EditAssessment",
}

export type CEQuotationAssessmentModalProps = {
  type: CEQuotationAssessmentModalType;
  contractCurrency: string;
  contractKeyDates: ContractKeyDate[];
  contractSections: ContractSection[];
  draftQuotation?: DraftQuotation;
  draftAssessment?: DraftAssessment;
  onPrimaryClick: (
    quotationAssessment: QuotationAssessmentFormDataType
  ) => void;
  onAttachmentsChange: (
    quotationAssessment: QuotationAssessmentFormDataType
  ) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const CEQuotationAssessmentModal: React.FC<
  CEQuotationAssessmentModalProps
> = ({
  draftQuotation,
  draftAssessment,
  type,
  contractCurrency,
  contractKeyDates,
  contractSections,
  onPrimaryClick,
  onSecondaryClick,
  onAttachmentsChange,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const formRef = useRef<CEQuotationAssessmentFormPublicAPI>(null);
  const quotationAssessmentRef = useRef<QuotationAssessmentFormDataType>();
  const [primaryBtnPressed, setPrimaryBtnPressed] = useState(false);

  const [isFormValid, setIsFormValid] = useState(true);
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>();

  const isEditMode = useMemo(
    () => draftQuotation || draftAssessment,
    [draftQuotation, draftAssessment]
  );

  const activeKeyDates = useMemo(
    () =>
      contractKeyDates.filter(
        (keyDate) => keyDate.status === ContractKeyDateStatus.Active
      ),
    [contractKeyDates]
  );
  const activeSections = useMemo(
    () =>
      contractSections.filter(
        (section) => section.status === ContractSectionStatus.Active
      ),
    [contractSections]
  );

  const internalModalType = useMemo(() => {
    if (type === CEQuotationAssessmentModalType.Assessment) {
      return draftAssessment
        ? InternalModalType.EditAssessment
        : InternalModalType.CreateAssessment;
    }
    return draftQuotation
      ? InternalModalType.EditQuotation
      : InternalModalType.CreateQuotation;
  }, [type, draftQuotation, draftAssessment]);

  const headerText = useMemo(() => {
    switch (internalModalType) {
      case InternalModalType.CreateQuotation:
        return {
          title: t("Projects.CompEvents.QuotationAssessmentModal.createTitle", {
            entity: t("Projects.CompEvents.quotation"),
          }),
          subtitle: t(
            "Projects.CompEvents.QuotationAssessmentModal.createSubtitle",
            { entity: t("Projects.CompEvents.quotation") }
          ),
        };
      case InternalModalType.EditQuotation:
        return {
          title: t("Projects.CompEvents.QuotationAssessmentModal.editTitle", {
            entity: t("Projects.CompEvents.quotation"),
          }),
          subtitle: t(
            "Projects.CompEvents.QuotationAssessmentModal.editSubtitle",
            { entity: t("Projects.CompEvents.quotation") }
          ),
        };
      case InternalModalType.CreateAssessment:
        return {
          title: t("Projects.CompEvents.QuotationAssessmentModal.createTitle", {
            entity: t("Projects.CompEvents.assessment"),
          }),
          subtitle: t(
            "Projects.CompEvents.QuotationAssessmentModal.createSubtitle",
            { entity: t("Projects.CompEvents.assessment") }
          ),
        };
      case InternalModalType.EditAssessment:
        return {
          title: t("Projects.CompEvents.QuotationAssessmentModal.editTitle", {
            entity: t("Projects.CompEvents.assessment"),
          }),
          subtitle: t(
            "Projects.CompEvents.QuotationAssessmentModal.editSubtitle",
            { entity: t("Projects.CompEvents.assessment") }
          ),
        };
    }
  }, [internalModalType, t]);

  const primaryBtnCaption = isEditMode
    ? t("common.buttons.save")
    : `${t("common.buttons.create")} ${t(
        `Projects.CompEvents.${
          type === CEQuotationAssessmentModalType.Quotation
            ? "quotation"
            : "assessment"
        }`
      )}`;

  const handleSubmit = useCallback(() => {
    const isFormValid = formRef.current?.validate();

    if (isFormValid) {
      onPrimaryClick(quotationAssessmentRef.current!);
      setPrimaryBtnPressed(true);
    }

    setIsFormValid(!!isFormValid);
  }, [onPrimaryClick]);

  const handleSecondaryClick = () => {
    formRef.current?.onBeforeAbort();
    onSecondaryClick();
  };

  const handleClose = () => {
    formRef.current?.onBeforeAbort();
    onSecondaryClick();
  };

  const handleQuotationAssessmentChange = useCallback(
    (quotationAssessment: QuotationAssessmentFormDataType) => {
      quotationAssessmentRef.current = quotationAssessment;
      setIsFormValid(true);
    },
    []
  );

  return (
    <ActionsDialog
      iconsHeader={
        <IconContainer greyBackground>
          <Money size={24} />
        </IconContainer>
      }
      onPrimaryClick={handleSubmit}
      onSecondaryClick={handleSecondaryClick}
      onClose={handleClose}
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {headerText.title}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]}>
            {headerText.subtitle}
          </Typography>
        </Box>
      }
      fullWidth
      maxWidth="md"
      contentSx={{ minWidth: "420px" }}
      primaryBtnCaption={primaryBtnCaption}
      primaryBtnDisabled={!isFormValid || attachmentsLoading}
      primaryBtnDisabledTooltipText={
        !isFormValid
          ? t("common.labels.invalidForm")
          : attachmentsLoading
          ? t("Attachments.loadingTooltip")
          : undefined
      }
      content={
        <CEQuotationAssessment
          apiRef={formRef}
          keyDates={activeKeyDates}
          sections={activeSections}
          contractCurrency={contractCurrency}
          draftAssessment={draftAssessment}
          draftQuotation={draftQuotation}
          onAttachmentsLoadingChange={setAttachmentsLoading}
          onChange={handleQuotationAssessmentChange}
          onAttachmentsChange={onAttachmentsChange}
        />
      }
      primaryBtnLoading={primaryBtnPressed}
      {...restDialogProps}
    />
  );
};
