import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventQuotationRevisionRequestMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventQuotationRevisionRequest(
    $input: SendCompEventQuotationRevisionRequestInput!
  ) {
    sendCompEventQuotationRevisionRequest(input: $input) {
      ...CompEventItemFields
    }
  }
`;
