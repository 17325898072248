import { gql } from "@apollo/client";

export const contractBindingLiteFields = gql`
  fragment ContractBindingLiteFields on ContractBinding {
    id
    contractBindingTypeId
    contractId
    companyId
    defaultCompany
    representativeId
    isCompanyBilled
    status
    dateCreated
    creatorId
  }
`;
