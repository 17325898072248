import { DailyDiaryPresetSection } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";
import { useMemo } from "react";

export const useGeneralRecordFormValidators = (
  section: DailyDiaryPresetSection
) => {
  const dataValidators: DataValidators = useMemo(() => {
    // DESCRIPTION
    const descriptionField = section.fields.find(
      (field) => field.name === "Description"
    );

    const descriptionColValidators: DataValidators | undefined =
      descriptionField &&
      !descriptionField.isHidden &&
      descriptionField.isRequired
        ? {
            description: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    return {
      ...descriptionColValidators,
    };
  }, [section]);

  return dataValidators;
};
