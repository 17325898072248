import { gql } from "@apollo/client";
import { variationHistoryItemFields } from "graphql/fragments/variationHistoryItem.fragment";

export const variationHistoryItemsQuery = gql`
  ${variationHistoryItemFields}

  query variationHistoryItems($variationId: ID!) {
    variationHistoryItems(variationId: $variationId) {
      items {
        ...VariationHistoryItemFields
      }
    }
  }
`;
