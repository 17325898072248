import { Box, Skeleton, Stack, useTheme } from "@mui/material";
import { Card, CardProps } from "components/Card";
import { User } from "generated/graphql";
import { ListItemType, SubPagesTabs } from "./SubPagesTabs";
import { UserInfo } from "./UserInfo";
import { NewAppPaths } from "helpers/paths/paths";
import {
  // User as UserIcon,
  PencilSimple,
  LockSimple,
  // Folder,
  Bell,
} from "phosphor-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";

const subPathsItems = [
  // {
  //   icon: UserIcon,
  //   label: "Account",
  //   path: SettingsSubPaths.Profile,
  // },
  {
    icon: PencilSimple,
    label: "Edit profile",
    path: NewAppPaths.authorized.Settings.children.ProfileEdit,
  },
  {
    icon: LockSimple,
    label: "Change password",
    path: NewAppPaths.authorized.Settings.children.ChangePassword,
  },
  // {
  //   icon: Folder,
  //   label: "Project settings",
  //   path: SettingsSubPaths.Project,
  // },
  {
    icon: Bell,
    label: "Notification settings",
    path: NewAppPaths.authorized.Settings.children.Notifications,
  },
];

const LoadingIndicator = () => {
  return (
    <Stack direction="column" spacing={1} px={3}>
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        spacing={2}
        py={1}
      >
        <Skeleton variant="circular" width={60} height={60} />
        <Stack direction="column" flex="1">
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Stack>
      </Stack>
      <Skeleton variant="rounded" width="100%" height={54} />
      <Skeleton variant="rounded" width="100%" height={54} />
      <Skeleton variant="rounded" width="100%" height={54} />
    </Stack>
  );
};

export type LeftSideNavigationProps = {
  authenticatedUser?: User;
  loading?: boolean;
} & Omit<CardProps, "children">;

export const LeftSideNavigation: React.FC<LeftSideNavigationProps> = ({
  loading,
  authenticatedUser,
  ...restCardProps
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const onTabClick = (tabItem: ListItemType) => {
    navigate(tabItem.path);
  };

  const selectedTab = useMemo(
    () =>
      subPathsItems.find((pathItem) => pathItem.path === location.pathname) ??
      null,
    [location.pathname]
  );

  return (
    <Card
      display="flex"
      flexDirection="column"
      {...restCardProps}
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        ...restCardProps.sx,
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box px={3} py={1}>
            <UserInfo authenticatedUser={authenticatedUser!} />
          </Box>
          <Box mt={2}>
            <SubPagesTabs
              items={subPathsItems}
              onClick={onTabClick}
              selectedItem={selectedTab}
            />
          </Box>
        </>
      )}
    </Card>
  );
};
