import { Link as MuiLink, Theme, css, styled } from "@mui/material";
import { Link } from "react-router-dom";

const getLinkSharedStyle = (theme: Theme) => css`
  text-decoration: none;
  color: ${theme.palette.primary.main};

  &:hover {
    color: ${theme.palette.primary.dark};
  }
`;

export const StyledLink = styled(Link)(({ theme }) =>
  getLinkSharedStyle(theme)
);

export const StyledMuiLink = styled(MuiLink)(({ theme }) =>
  getLinkSharedStyle(theme)
);

export const NoStyleLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: none;
    color: ${theme.palette.grey[500]};
  `
);
