import { Box, BoxProps, Typography } from "@mui/material";
import { AsteriskIcon } from "components/Icons/AsteriskIcon";

export type FormLabelProps = {
  required?: boolean;
  label: React.ReactNode;
  defaultMarginBottom?: boolean;
} & BoxProps;

export const FormLabel: React.FC<FormLabelProps> = ({
  required,
  label,
  defaultMarginBottom = true,
  ...boxProps
}) => (
  <Box
    display="flex"
    alignItems="center"
    sx={{ userSelect: "none" }}
    {...boxProps}
  >
    <>
      {typeof label === "string" ? (
        <Typography
          variant="p1"
          fontWeight={600}
          color="grey.800"
          mb={defaultMarginBottom ? 1 : 0}
        >
          {label}
        </Typography>
      ) : (
        { label }
      )}

      {required && (
        <Box alignSelf="flex-start">
          <AsteriskIcon />
        </Box>
      )}
    </>
  </Box>
);
