import { gql } from "@apollo/client";
import { companyLiteFields } from "graphql/fragments/companyLite.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const projectsQuery = gql`
  ${companyLiteFields}

  query projects {
    projects {
      items {
        id
        name
        dateCreated
        status
        number
        description
        contracts {
          items {
            id
            name
            friendlyName
            status
          }
        }
        friendlyName
        sector
        billingCompany {
          ...CompanyLiteFields
        }
        creator {
          id
          firstname
          surname
        }
      }
    }
  }
`;

export const companyProjectsQuery = gql`
  ${creatorFields}

  query companyProjects($id: ID!) {
    company(id: $id) {
      id
      registeredName
      tradingName
      projects {
        items {
          id
          dateCreated
          status
          friendlyName
          sector
          creator {
            ...CreatorFields
          }
        }
      }
    }
  }
`;

export const changeProjectStatusMutation = gql`
  mutation changeProjectStatus($id: ID!, $status: ProjectStatus!) {
    changeProjectStatus(id: $id, status: $status) {
      id
      name
      friendlyName
      status
    }
  }
`;
