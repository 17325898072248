import {
  AuthorizationWorkflowAudit,
  UserPendingAuthorizationAuditsQuery,
  UserPendingAuthorizationAuditsQueryVariables,
} from "generated/graphql";
import { userPendingAuthorizationAuditsQuery } from "graphql/queries/userPendingAuthorizationAudits.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import uniqBy from "lodash.uniqby";
import { useCallback, useEffect, useMemo, useState } from "react";

const recordsChunkLimit = 50;

export const useAuthorizationsInitiatedByYou = () => {
  const [nextToken, setNextToken] = useState<string>();

  const [
    loadAudits,
    {
      data: authorizationsInitiatedByYouData,
      fetchMore,
      refetch,
      loading: authorizationsInitiatedByYouLoading,
    },
  ] = useGraphLazyQuery<
    UserPendingAuthorizationAuditsQuery,
    UserPendingAuthorizationAuditsQueryVariables
  >(userPendingAuthorizationAuditsQuery, { notifyOnNetworkStatusChange: true });

  const loadMore = useCallback(async () => {
    if (nextToken) {
      const { data } = await fetchMore({
        variables: { limit: recordsChunkLimit, nextToken },
        updateQuery: (oldData, { fetchMoreResult: newData }) => {
          const newItems = [
            ...oldData.userPendingAuthorizationAudits.items,
            ...newData.userPendingAuthorizationAudits.items,
          ];

          const uniqNewItems = uniqBy(newItems, "id");

          return {
            ...newData,
            userPendingAuthorizationAudits: {
              ...newData.userPendingAuthorizationAudits,
              items: uniqNewItems,
            },
          };
        },
      });

      setNextToken(data.userPendingAuthorizationAudits.nextToken ?? undefined);
    } else {
      const { data } = await loadAudits({
        variables: { limit: recordsChunkLimit },
      });

      setNextToken(data?.userPendingAuthorizationAudits.nextToken ?? undefined);
    }
  }, [loadAudits, fetchMore, nextToken]);

  const reset = () => {
    setNextToken(undefined);
    refetch({ limit: recordsChunkLimit });
  };

  const authorizationsInitiatedByYou = useMemo(
    () =>
      (authorizationsInitiatedByYouData?.userPendingAuthorizationAudits.items ??
        []) as AuthorizationWorkflowAudit[],
    [authorizationsInitiatedByYouData]
  );

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line
  }, []);

  return {
    authorizationsInitiatedByYou,
    loading: authorizationsInitiatedByYouLoading,
    hasMore: !!nextToken,
    loadMore,
    reset,
  };
};
