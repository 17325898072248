import {
  Box,
  Breadcrumbs,
  Collapse,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useContractStatusOptions } from "components/StatusTag/useContractStatusOptions";
import { FormPublicApi, OnMutationDone } from "types/decl";
import {
  AddProductInstanceInput,
  ChangeProductInstanceStatusMutation,
  ChangeProductInstanceStatusMutationVariables,
  EditProductInstanceInput,
  EditProductInstanceMutation,
  EditProductInstanceMutationVariables,
  ItemStatusCollection,
  Product,
  ProductInstance,
  ProductInstanceQuery,
  ProductInstanceQueryVariables,
  ProductInstanceStatus,
  ProductRole,
  ProductSchema,
  ProductType,
  ProductUserRole,
  User,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { Browsers } from "phosphor-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ProductInstanceForm } from "../../components/ProductInstanceForm/ProductInstanceForm";
import { useProductInstanceForm } from "../../components/ProductInstanceForm/useProductInstanceForm";
import { changeProductInstanceStatusMutation } from "../ContractProductInstances/ContractProductInstances.query";
import { ProductInstanceRoles } from "./components/ProductInstanceRoles/ProductInstanceRoles";
import { useProductInstanceRoles } from "./components/ProductInstanceRoles/useProductInstanceRoles";
import {
  editProductInstanceMutation,
  productInstanceQuery,
} from "./ContractProductInstanceDetails.query";
import { useProductInstanceEntityObj } from "./ContractProductInstanceDetails.utils";
import { useProjectLite } from "containers/AdminConsole/hooks/useProjectLite";
import { ProductInstanceWorkflowActionMappings } from "./components/ProductInstanceWorkflowActionMappings/ProductInstanceWorkflowActionMappings";
import { useProductInstanceWorkflowActionMappings } from "./components/ProductInstanceWorkflowActionMappings/useProductInstanceWorkflowActionMappings";
import { useContractLite } from "hooks/useContractLite";
import { useContractProducts } from "containers/AdminConsole/hooks/useContractProducts";
import { getUserName } from "helpers/miscelaneous";
import { ProductTypename, ProductTypenameToProduct } from "constants/products";

export const ContractProductInstanceDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { projectId, contractId, productInstanceId } = useParams();
  const formRef = useRef<FormPublicApi>(null);
  const statusOptions =
    useContractStatusOptions() as StatusOption<ProductInstanceStatus>[];

  const updatedProductInstance = useRef<EditProductInstanceInput>();
  const [showDetails, setShowDetails] = useState(true);
  const [readOnly, setReadOnly] = useState(true);

  const {
    data: productInstance,
    refetch: refetchProductInstance,
    loading: getProductInstanceLoading,
  } = useGraphQuery<ProductInstanceQuery, ProductInstanceQueryVariables>(
    productInstanceQuery,
    {
      variables: { id: productInstanceId! },
    }
  );

  const supportsAuthorizationWorkflows =
    [
      ProductType.CompEvents,
      ProductType.EarlyWarnings,
      ProductType.Instructions,
      ProductType.DailyDiary,
      ProductType.Claims,
      ProductType.Variations,
    ].indexOf(
      ProductTypenameToProduct[
        productInstance?.productInstance.product.__typename as ProductTypename
      ]
    ) >= 0;

  const {
    workflows,
    outputActions,
    authWorkflowActionMappings,
    loading: workflowsLoading,
    addAuthorizationWorkflowActionMapping,
    editAuthorizationWorkflowActionMapping,
    removeAuthorizationWorkflowActionMapping,
  } = useProductInstanceWorkflowActionMappings(
    productInstanceId!,
    projectId!,
    !supportsAuthorizationWorkflows
  );

  const { projectDataLite, loading: projectDataLoading } = useProjectLite(
    projectId!
  );

  const { products, loading: productsLoading } = useContractProducts(
    contractId!
  );

  const { contractLite, contractLiteLoading } = useContractLite(contractId!);

  const [changeProductInstanceStatus, { loading: changeStatusLoading }] =
    useGraphMutation<
      ChangeProductInstanceStatusMutation,
      ChangeProductInstanceStatusMutationVariables
    >(
      changeProductInstanceStatusMutation,
      {
        update: (cache) => {
          refetchProductInstance();

          cache.evict({ id: "ROOT_QUERY", fieldName: "contract" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.productInstance"),
      })
    );

  const [editProductInstance, { loading: editProductInstanceLoading }] =
    useGraphMutation<
      EditProductInstanceMutation,
      EditProductInstanceMutationVariables
    >(
      editProductInstanceMutation,
      {
        update: (cache) => {
          refetchProductInstance();

          cache.evict({ id: "ROOT_QUERY", fieldName: "contract" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.productInstance"),
      })
    );

  const {
    loading: productDataLoading,
    selectedProductLite,
    selectedProductSchemas,
    selectedProductStatusCollections,
    handleSelectedProductChange,
  } = useProductInstanceForm(productInstance?.productInstance.product.id);

  const handleProductInstanceRolesUpdates: OnMutationDone = (cache) => {
    refetchProductInstance();
    cache.evict({ id: "ROOT_QUERY", fieldName: "user" });
    cache.gc();
  };

  const {
    addUserRole,
    addUserRoleLoading,
    editUserRole,
    editUserRoleLoading,
    changeUserRoleStatus,
    changeUserRoleStatusLoading,
    availableUsersForRoles,
  } = useProductInstanceRoles(productInstanceId!, {
    onAdd: handleProductInstanceRolesUpdates,
    onEdit: handleProductInstanceRolesUpdates,
    onStatusChange: handleProductInstanceRolesUpdates,
  });

  const handleBreadcrumbClick = (parent?: "contract" | "project") => {
    if (parent === "contract") {
      navigate(-1);
    } else if (parent === "project") {
      navigate(-2);
    } else {
      navigate(-3);
    }
  };

  const updateProductInstance = useCallback(async () => {
    if (!updatedProductInstance.current || !formRef.current?.validate()) {
      return;
    }

    const { errors } = await editProductInstance({
      variables: {
        input: updatedProductInstance.current,
      },
    });

    if (!errors) {
      setReadOnly(true);
    }
  }, [editProductInstance]);

  const handleFormChange = useCallback(
    (data: AddProductInstanceInput | EditProductInstanceInput) => {
      updatedProductInstance.current = data as EditProductInstanceInput;
    },
    []
  );

  const handleCancelClick = useCallback(() => {
    formRef.current?.reset();
    setReadOnly(true);
  }, []);

  const handleEditClick = useCallback(() => {
    setReadOnly(false);
    setShowDetails(true);
  }, []);

  const handleProductInstanceStatusChange = useCallback(
    async (newStatus: ProductInstanceStatus) => {
      await changeProductInstanceStatus({
        variables: {
          id: productInstance?.productInstance.id!,
          status: newStatus,
        },
      });
    },
    [changeProductInstanceStatus, productInstance]
  );

  const handleSaveClick = useCallback(() => {
    updateProductInstance();
  }, [updateProductInstance]);

  const creatorStr = useMemo(() => {
    if (
      productInstance?.productInstance.creator.firstname &&
      productInstance?.productInstance.creator.surname
    ) {
      return getUserName(
        productInstance?.productInstance.creator as User | undefined
      );
    }

    return "";
  }, [productInstance]);

  const productInstanceEntityObj = useProductInstanceEntityObj(
    productInstance?.productInstance as ProductInstance | undefined
  );

  return (
    <Box>
      <EntityDetailsHeader
        loading={
          getProductInstanceLoading ||
          changeStatusLoading ||
          editProductInstanceLoading ||
          projectDataLoading ||
          contractLiteLoading
        }
        title={productInstance?.productInstance.description}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={() => handleBreadcrumbClick()}>
              <Typography variant="body2">
                {t("AdminConsole.Projects.labels.projects")}
              </Typography>
            </Link>
            <Link key="2" onClick={() => handleBreadcrumbClick("project")}>
              <Typography variant="body2">
                {projectDataLite?.friendlyName}
              </Typography>
            </Link>
            <Link key="3" onClick={() => handleBreadcrumbClick("contract")}>
              <Typography variant="body2">
                {contractLite?.friendlyName}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        creator={creatorStr}
        dateCreated={new Date().toString()}
        status={productInstance?.productInstance.status}
        onStatusChange={handleProductInstanceStatusChange}
        statusOptions={statusOptions}
      />
      <CollapsibleHeader
        title={t("AdminConsole.ProductInstances.labels.productInstanceDetails")}
        collapsed={!showDetails}
        icon={
          <Browsers
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onCancel={handleCancelClick}
        onEdit={handleEditClick}
        onSave={handleSaveClick}
        onSaveActionLoading={editProductInstanceLoading}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList
              loading={getProductInstanceLoading}
              entity={productInstanceEntityObj}
            />
          ) : getProductInstanceLoading ? null : (
            <ProductInstanceForm
              productInstance={
                productInstance?.productInstance as unknown as ProductInstance
              }
              products={products}
              selectedProductSchemas={
                (selectedProductSchemas as ProductSchema[]) || []
              }
              selectedProductStatusCollections={
                (selectedProductStatusCollections as ItemStatusCollection[]) ||
                []
              }
              selectedProductLite={selectedProductLite as Partial<Product>}
              disabled={productsLoading || productDataLoading}
              apiRef={formRef}
              contractId={productInstance?.productInstance.contract.id}
              onChange={handleFormChange}
              onProductChange={handleSelectedProductChange}
            />
          )}
        </PageContentContainer>
      </Collapse>
      {supportsAuthorizationWorkflows && (
        <ProductInstanceWorkflowActionMappings
          workflowActionMappings={authWorkflowActionMappings}
          productOutputActions={outputActions}
          authorizationWorkflows={workflows}
          loading={workflowsLoading}
          projectFriendlyName={projectDataLite?.friendlyName}
          contractFriendlyName={contractLite?.friendlyName}
          productInstanceName={productInstance?.productInstance.description}
          productInstanceId={productInstanceId!}
          onMappingAdd={addAuthorizationWorkflowActionMapping}
          onMappingEdit={editAuthorizationWorkflowActionMapping}
          onMappingDelete={removeAuthorizationWorkflowActionMapping}
        />
      )}
      <Box mt={4}>
        <ProductInstanceRoles
          roles={
            (productInstance?.productInstance.roles
              .items as ProductUserRole[]) || []
          }
          productRoles={
            (productInstance?.productInstance.product.roles
              .items as ProductRole[]) || []
          }
          users={availableUsersForRoles as User[]}
          loading={
            addUserRoleLoading ||
            editUserRoleLoading ||
            changeUserRoleStatusLoading
          }
          projectFriendlyName={projectDataLite?.friendlyName}
          contractFriendlyName={contractLite?.friendlyName}
          productInstanceName={productInstance?.productInstance.description}
          productInstanceId={productInstance?.productInstance.id}
          onRoleStatusChange={changeUserRoleStatus}
          onRoleAdd={addUserRole}
          onRoleEdit={editUserRole}
        />
      </Box>
    </Box>
  );
};
