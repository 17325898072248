import { gql } from "@apollo/client";

export const documentTemplateFields = gql`
  fragment DocumentTemplateFields on DocumentTemplate {
    id
    name
    description
    content
    isInternal
    status
    dateCreated
    creatorId
    creator {
      id
      firstname
      surname
    }
  }
`;
