import { Box, css, Stack, styled, Typography, useTheme } from "@mui/material";
import {
  ClaimDetailsExplorerItem,
  CompEventQuotationAssessment,
  Contract,
  VariationProposalExplorerItem,
} from "generated/graphql";
import { ClipboardText, Money } from "phosphor-react";
import { TimeTag } from "../HistoryModal/TimeTag";
import { PriceTag } from "../HistoryModal/PriceTag";
import { DateWithTimeTooltip } from "../DateWithTimeTooltip";

export type ContractPriceTimeChangeListItemProps = {
  item:
    | CompEventQuotationAssessment
    | ClaimDetailsExplorerItem
    | VariationProposalExplorerItem;
  selected: boolean;
  contract: Contract;
  onClick: () => void;
};

const ListItemWrapper = styled(Box)<{ selected?: boolean }>(
  ({ theme, selected = false }) => css`
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.grey[200]};
    background: ${selected ? theme.palette.grey[100] : "white"};
    box-sizing: border-box;
    cursor: pointer;
  `
);

export const ContractPriceTimeChangeListItem: React.FC<
  ContractPriceTimeChangeListItemProps
> = ({ item, contract, selected, onClick }) => {
  const theme = useTheme();

  return (
    <ListItemWrapper
      width="320px"
      selected={selected}
      onClick={onClick}
      data-testid={`price-time-change-list-item-${item.id}`}
    >
      <Stack direction="row" spacing={1.25}>
        {/* TODO: not sure why we show different icon for these 3 items */}
        {item.__typename === "CompEventQuotation" ||
        item.__typename === "DetailedClaim" ||
        item.__typename === "VariationProposal" ? (
          <Money size={24} color={theme.palette.grey[900]} />
        ) : (
          <ClipboardText size={24} color={theme.palette.grey[900]} />
        )}
        <Typography variant="p1" color="grey.900" fontWeight={600}>
          {item.reference}
        </Typography>
      </Stack>
      <DateWithTimeTooltip
        datetime={item.dateCreated}
        timezone={contract.timeZone}
        variant="p2"
        color="grey.600"
        mt={0.5}
      />
      <Stack direction="row" spacing={1} mt={2}>
        <PriceTag
          value={item.price}
          currency={contract.valueCurrency}
          sx={{ flex: 1 }}
        />
        <TimeTag value={item.time} sx={{ flex: 1 }} />
      </Stack>
    </ListItemWrapper>
  );
};
