import { gql } from "@apollo/client";

export const documentTemplatesQuery = gql`
  query documentTemplates($limit: Int, $nextToken: String) {
    documentTemplates(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        description
        isInternal
        status
        dateCreated
        creatorId
        creator {
          id
          firstname
          surname
        }
      }
    }
  }
`;

export const documentTemplatesNameOnlyQuery = gql`
  query documentTemplatesNameOnly($limit: Int, $nextToken: String) {
    documentTemplates(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
      }
    }
  }
`;
