import {
  ProductType,
  RemoveEarlyWarningPreviewMutation,
  RemoveEarlyWarningPreviewMutationVariables,
  RemoveInstructionPreviewMutation,
  RemoveInstructionPreviewMutationVariables,
} from "generated/graphql";
import { removeInstructionPreviewMutation } from "graphql/mutations/removeInstructionPreview";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useMemo } from "react";
import { removeEarlyWarningPreviewMutation } from "../NewEarlyWarning.query";

export const useRemoveEWInstructionPreview = (productType: ProductType) => {
  const [removeEWPreview, { loading: removeEWPreviewLoading }] =
    useGraphMutation<
      RemoveEarlyWarningPreviewMutation,
      RemoveEarlyWarningPreviewMutationVariables
    >(removeEarlyWarningPreviewMutation, {}, null);

  const [
    removeInstructionPreview,
    { loading: removeInstructionPreviewLoading },
  ] = useGraphMutation<
    RemoveInstructionPreviewMutation,
    RemoveInstructionPreviewMutationVariables
  >(removeInstructionPreviewMutation, {}, null);

  const removePreview = useMemo(
    () =>
      productType === ProductType.EarlyWarnings
        ? removeEWPreview
        : removeInstructionPreview,
    [productType, removeEWPreview, removeInstructionPreview]
  );

  const loading = useMemo(
    () =>
      productType === ProductType.EarlyWarnings
        ? removeEWPreviewLoading
        : removeInstructionPreviewLoading,
    [productType, removeEWPreviewLoading, removeInstructionPreviewLoading]
  );

  return {
    removePreview,
    loading,
  };
};
