import { Box, css, styled } from "@mui/material";

export const HeaderContainer = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(2)};
    background: ${theme.palette.common.white};
    z-index: 3;
  `
);
