import { OnMutationDone } from "types/decl";
import {
  AddItemStatusCollectionInput,
  AddItemStatusCollectionMutation,
  AddItemStatusCollectionMutationVariables,
  ChangeItemStatusCollectionStatusMutation,
  ChangeItemStatusCollectionStatusMutationVariables,
  EditItemStatusCollectionInput,
  EditItemStatusCollectionMutation,
  EditItemStatusCollectionMutationVariables,
  ItemStatusCollection,
  ItemStatusCollectionStatus,
} from "generated/graphql";
import { changeItemStatusCollectionStatusMutation } from "graphql/mutations/changeStatusCollectionStatus";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  addItemStatusCollectionMutation,
  editItemStatusCollectionMutation,
} from "./ProductStatusCollections.query";

export type useProductStatusCollectionsProps = {
  onProductStatusCollectionAdded: OnMutationDone;
  onProductStatusCollectionUpdated: OnMutationDone;
  onProductStatusCollectionStatusChange: OnMutationDone;
};

export const useProductStatusCollections = ({
  onProductStatusCollectionAdded,
  onProductStatusCollectionStatusChange,
  onProductStatusCollectionUpdated,
}: useProductStatusCollectionsProps) => {
  const { t } = useTranslation();

  const [
    addProductStatusCollection,
    { loading: addProductStatusCollectionLoading },
  ] = useGraphMutation<
    AddItemStatusCollectionMutation,
    AddItemStatusCollectionMutationVariables
  >(
    addItemStatusCollectionMutation,
    {
      update: onProductStatusCollectionAdded,
    },
    t("common.successMessages.entityCreated", {
      entity: t("AdminConsole.Products.labels.statusCollection"),
    })
  );

  const [
    editProductStatusCollection,
    { loading: editProductStatusCollectionLoading },
  ] = useGraphMutation<
    EditItemStatusCollectionMutation,
    EditItemStatusCollectionMutationVariables
  >(
    editItemStatusCollectionMutation,
    {
      update: onProductStatusCollectionUpdated,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Products.labels.statusCollection"),
    })
  );

  const [
    changeProductStatusCollectionStatus,
    { loading: changeProductStatusCollectionStatusLoading },
  ] = useGraphMutation<
    ChangeItemStatusCollectionStatusMutation,
    ChangeItemStatusCollectionStatusMutationVariables
  >(
    changeItemStatusCollectionStatusMutation,
    {
      update: onProductStatusCollectionStatusChange,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Products.labels.statusCollection"),
    })
  );

  const handleAddProductStatusCollection = useCallback(
    async (statusCollection: AddItemStatusCollectionInput) => {
      await addProductStatusCollection({
        variables: {
          input: statusCollection,
        },
      });
    },
    [addProductStatusCollection]
  );

  const handleEditProductStatusCollection = useCallback(
    async (statusCollection: EditItemStatusCollectionInput) => {
      await editProductStatusCollection({
        variables: {
          input: statusCollection,
        },
      });
    },
    [editProductStatusCollection]
  );

  const handleProductCollectionStatusChange = useCallback(
    async (
      row: ItemStatusCollection,
      newStatus: ItemStatusCollectionStatus
    ) => {
      await changeProductStatusCollectionStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeProductStatusCollectionStatus]
  );

  return {
    addProductStatusCollection: handleAddProductStatusCollection,
    addProductStatusCollectionLoading,
    editProductStatusCollection: handleEditProductStatusCollection,
    editProductStatusCollectionLoading,
    changeProductStatusCollectionStatus: handleProductCollectionStatusChange,
    changeProductStatusCollectionStatusLoading,
  };
};
