import { gql } from "@apollo/client";

export const getUserByIdQuery = gql`
  query getUserById($id: ID) {
    user(id: $id) {
      id
      firstname
      surname
      jobTitle
      country
      mobileNumber
      alternateNumber
      dateOfBirth
      profilePicture
      email
      companyId
      registered
      status
      lastActive
      dateInvited
      registeredDate
      invitedBy {
        id
        firstname
        surname
        email
      }
    }
  }
`;
