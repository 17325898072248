import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryWeatherRecordInput,
  Attachment,
  AttachmentInput,
  CompanyLookupCollection,
  DailyDiaryPresetSection,
  DailyDiaryWeatherRecord,
  EditDailyDiaryWeatherRecordInput,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecordModalFooter } from "../../RecordModalFooter";
import { WeatherRecordForm } from "./WeatherRecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export type WeatherRecordModalProps = {
  weatherRecord?: DailyDiaryWeatherRecord;
  weatherLookupCollections: CompanyLookupCollection[];
  section: DailyDiaryPresetSection;
  attachmentsPreview?: Attachment[];
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onPrimaryClick: (
    weatherRecord:
      | AddDailyDiaryWeatherRecordInput
      | EditDailyDiaryWeatherRecordInput,
    closeModal: boolean
  ) => void;
  onSecondaryClick: () => void;
} & Omit<ActionsDialogProps, "onPrimaryClick" | "onSecondaryClick">;

export const WeatherRecordModal: React.FC<WeatherRecordModalProps> = ({
  weatherRecord,
  weatherLookupCollections,
  section,
  attachmentsPreview,
  onAttachmentsUpdated,
  onPrimaryClick,
  onSecondaryClick,
  onClose,
  primaryBtnCaption,
  secondaryBtnCaption,
  primaryBtnLoading,
  secondaryBtnLoading,
  primaryBtnDisabled,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const { handleClose } = useDisableBackdropClick(onClose);

  const [addAnotherRecord, setAddAnotherRecord] = useState(false);
  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const weatherRecordRef = useRef<
    AddDailyDiaryWeatherRecordInput | EditDailyDiaryWeatherRecordInput
  >();

  const handleWeatherRecordChange = (
    updatedWeatherRecord:
      | EditDailyDiaryWeatherRecordInput
      | AddDailyDiaryWeatherRecordInput
  ) => {
    weatherRecordRef.current = updatedWeatherRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (weatherRecord) {
        // edit
        onPrimaryClick(weatherRecordRef.current!, true);
      } else {
        // add
        const { id, ...addRecordInput } =
          weatherRecordRef.current! as EditDailyDiaryWeatherRecordInput;
        onPrimaryClick(addRecordInput, !addAnotherRecord);

        if (addAnotherRecord) {
          // clear modal
          formApiRef.current.reset();
          setModalFooterKey((crtKey) => crtKey + 1);
        }
      }
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.WeatherModal.${
              weatherRecord ? "editWeatherRecord" : "addWeatherRecord"
            }`
          )}
        </Typography>
      }
      fullWidth
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <WeatherRecordForm
        apiRef={formApiRef}
        weatherLookups={weatherLookupCollections}
        section={section}
        weatherRecord={weatherRecord}
        onChange={handleWeatherRecordChange}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        onAddAnotherRecordChange={setAddAnotherRecord}
        primaryBtnCaption={
          weatherRecord ? t("common.labels.saveChanges") : undefined
        }
        showAddAnotherRecord={!weatherRecord}
        addAnotherRecord={addAnotherRecord}
        primaryBtnLoading={primaryBtnLoading}
        primaryBtnDisabled={primaryBtnDisabled}
        secondaryBtnCaption={secondaryBtnCaption}
        secondaryBtnLoading={secondaryBtnLoading}
        onAttachmentsUpdated={onAttachmentsUpdated}
        attachments={attachmentsPreview ?? weatherRecord?.attachments ?? []}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={onSecondaryClick}
      />
    </BasicModal>
  );
};
