import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { ClaimActionType, ClaimAgreementNoticePrompt } from "generated/graphql";
import { DetailedClaimAgreementDeterminationSummary } from "../components/DetailedClaimAgreementDeterminationSummary";
import { RecordAction } from "../components/RecordAction";
import { useRecordAgreementClaimNoticeModal } from "./useRecordAgreementClaimNoticeModal";

export type RecordAgreementClaimNoticeModalProps = {
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Pick<ClaimAgreementNoticePrompt, "draftClaimAgreements"> &
  Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordAgreementClaimNoticeModal: React.FC<
  RecordAgreementClaimNoticeModalProps
> = ({ draftClaimAgreements, ...restDialogProps }) => {
  const { claimPreview, contract } = useRecordAgreementClaimNoticeModal(
    draftClaimAgreements[0].id
  );
  return (
    <RecordAction
      actionType={ClaimActionType.NotifyAgreementClaim}
      claimPreview={claimPreview}
      summaryEl={
        <DetailedClaimAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftClaimAgreements}
          type="ClaimAgreement"
        />
      }
      {...restDialogProps}
    />
  );
};
