import {
  DailyDiaryGeneralRecord,
  EditDailyDiaryGeneralRecordInput,
} from "generated/graphql";

export const generalRecordToEditInput = (
  record: DailyDiaryGeneralRecord
): EditDailyDiaryGeneralRecordInput => {
  const {
    __typename: _,
    creator: __,
    creatorId: ___,
    dateCreated: ____,
    dateModified: _____,
    attachments: ______,
    ...rest
  } = record;

  return {
    ...rest,
    attachments: [], // TODO
  };
};
