import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";

export const changeAuthorizationWorkflowStatusMutation = gql`
  ${authorizationWorkflowFields}

  mutation changeAuthorizationWorkflowStatus(
    $id: ID!
    $status: AuthorizationWorkflowStatus!
  ) {
    changeAuthorizationWorkflowStatus(id: $id, status: $status) {
      ...AuthorizationWorkflowFields
    }
  }
`;
