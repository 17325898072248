import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventAbandonInstructionMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventAbandonInstruction(
    $input: SendCompEventAbandonInstructionInput!
  ) {
    sendCompEventAbandonInstruction(input: $input) {
      ...CompEventItemFields
    }
  }
`;
