import React, { useCallback, useEffect, useImperativeHandle } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { StyledBoldableText } from "./BasicContextMenu.styled";
import { KeyboardArrowDownIcon } from "components/Icons/KeyboardArrowDownIcon";
import { useTranslation } from "react-i18next";
import { BasicContextMenuProps } from "./BasicContextMenu.decl";

// TODO: rename to dropdown
export const BasicContextMenu: React.FC<BasicContextMenuProps> = ({
  triggerCaption,
  triggerIcon,
  boldedText,
  items,
  menuItems,
  menuItemMinWidth,
  apiRef,
  menuHeader,
  wrapperProps,
  onItemSelect,
  onDropdownVisibilityChange,
  ...restMenuProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleItemSelect = (id: string) => {
    onItemSelect?.(id);
    handleClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      close: handleClose,
    }),
    [handleClose]
  );

  useEffect(() => {
    onDropdownVisibilityChange?.(open);
  }, [open, onDropdownVisibilityChange]);

  return (
    <Box {...wrapperProps}>
      <Button
        id="basic-button"
        data-testid="context-menu-trigger"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ textTransform: "none", background: "transparent" }}
        variant="text"
        disableRipple
        sx={{ minWidth: "32px" }}
      >
        <Box
          display="flex"
          alignItems="center"
          color={theme.palette.common.black}
        >
          {triggerCaption ? (
            typeof triggerCaption === "string" ? (
              <StyledBoldableText
                mr={2}
                fontWeight={boldedText ? 700 : 500}
                dataText={triggerCaption}
              >
                {triggerCaption}
              </StyledBoldableText>
            ) : (
              triggerCaption
            )
          ) : null}
          {triggerIcon ?? <KeyboardArrowDownIcon />}
        </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...restMenuProps}
      >
        {menuHeader}
        {menuItems ??
          items?.map((item) => (
            <MenuItem
              key={item.id}
              sx={{ minWidth: menuItemMinWidth }}
              onClick={() => handleItemSelect(item.id)}
            >
              <Typography variant="body2">{t(item.label)}</Typography>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};
