import {
  DocumentTemplate,
  DocumentTemplatesNameOnlyQuery,
  DocumentTemplatesNameOnlyQueryVariables,
  DocumentTemplatesQuery,
  DocumentTemplatesQueryVariables,
} from "generated/graphql";
import {
  documentTemplatesNameOnlyQuery,
  documentTemplatesQuery,
} from "graphql/queries/templates.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import uniqBy from "lodash.uniqby";
import { useCallback, useEffect } from "react";

const recordsChunkLimit = 50;

export const useDocumentTemplates = (
  nameOnlyQuery?: boolean,
  fetchAll?: boolean
) => {
  const {
    data: documentTemplates,
    refetch: refetchDocumentTemplates,
    loading: fetchDocumentTemplatesLoading,
    fetchMore,
  } = useGraphQuery<
    DocumentTemplatesQuery | DocumentTemplatesNameOnlyQuery,
    DocumentTemplatesQueryVariables | DocumentTemplatesNameOnlyQueryVariables
  >(nameOnlyQuery ? documentTemplatesNameOnlyQuery : documentTemplatesQuery, {
    variables: { limit: recordsChunkLimit },
    notifyOnNetworkStatusChange: true,
  });

  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        limit: recordsChunkLimit,
        nextToken: documentTemplates?.documentTemplates.nextToken,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newItems = [
          ...prevResult.documentTemplates.items,
          ...fetchMoreResult.documentTemplates.items,
        ];

        const uniqNewItems = uniqBy(newItems, "id");

        return {
          documentTemplates: {
            ...fetchMoreResult.documentTemplates,
            items: uniqNewItems,
          },
        };
      },
    });
  }, [fetchMore, documentTemplates?.documentTemplates.nextToken]);

  useEffect(() => {
    if (
      fetchAll &&
      (documentTemplates?.documentTemplates.nextToken || !documentTemplates)
    ) {
      loadMore();
    }
  }, [fetchAll, loadMore, documentTemplates]);

  return {
    documentTemplates:
      (documentTemplates?.documentTemplates.items as DocumentTemplate[]) ?? [],
    loadMore,
    hasMore: !!documentTemplates?.documentTemplates.nextToken,
    refetchDocumentTemplates,
    fetchDocumentTemplatesLoading,
  };
};
