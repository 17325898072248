import { gql } from "@apollo/client";

export const getExplorerDataQuery = gql`
  query GetExplorerData($id: ID!) {
    user(id: $id) {
      id
      firstname
      surname
      email
      roles {
        items {
          userId
          productInstanceId
          productRoleId
          status
          dateCreated
          creatorId
          creator {
            id
            firstname
            surname
          }
          productInstance {
            id
            description
            soloModeSupported
            contractId
            productId
            productSchemaId
            statusCollectionId
            status
            dateCreated
            creatorId
            creator {
              id
              firstname
              surname
            }
            contract {
              id
              name
              friendlyName
              number
              valueCurrency
              value
              startDate
              endDate
              contractTypeId
              projectId
              country
              province
              timeZone
              coordinatesLatitude
              coordinatesLongitude
              status
              dateCreated
              creator {
                id
                firstname
                surname
              }
              project {
                id
                name
                friendlyName
                number
                sector
                description
                status
                dateCreated
                creator {
                  id
                  firstname
                  surname
                }
              }
              contractType {
                id
                description
                subType
                version
                status
              }
            }
            product {
              id
              name
              numberingFormat
              soloModeSupported
              allowMultipleInstances
              status
            }
          }
          productRole {
            id
            name
            productId
            actionIds
            status
            dateCreated
            creatorId
            creator {
              id
              firstname
              surname
            }
          }
        }
      }
    }
  }
`;
