import { gql } from "@apollo/client";
import { companyLiteFields } from "graphql/fragments/companyLite.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const getCompanyDetailsQuery = gql`
  ${companyLiteFields}
  ${creatorFields}

  query getCompanyDetails($id: ID!) {
    company(id: $id) {
      ...CompanyLiteFields
      contracts {
        items {
          id
          friendlyName
          number
          country
          province
          timeZone
          status
          contractTypeId
          dateCreated
          project {
            id
            friendlyName
          }
          creator {
            ...CreatorFields
          }
        }
      }
      users {
        items {
          id
          firstname
          surname
          email
          companyId
          registered
          status
          lastActive
          dateInvited
          invitedBy {
            ...CreatorFields
          }
        }
      }
    }
  }
`;

export const changeUserStatusMutation = gql`
  mutation changeUserStatus($id: ID!, $status: UserStatus!) {
    changeUserStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const editCompanyMutation = gql`
  ${companyLiteFields}

  mutation editCompany($input: EditCompanyInput!) {
    editCompany(input: $input) {
      ...CompanyLiteFields
    }
  }
`;

export const resendInviteMutation = gql`
  mutation resendInvite($input: InviteUserInput!) {
    resendInvite(input: $input) {
      success
    }
  }
`;
