import {
  DailyDiaryItem,
  ImportDailyDiaryManpowerRecordsMutation,
  ImportDailyDiaryManpowerRecordsMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { importDailyDiaryManpowerRecordsMutation } from "./ManpowerSection.query";
import { useManpowerRecords } from "./useManpowerRecords";

export const useImportManpowerRecordsModal = (dailyDiary?: DailyDiaryItem) => {
  const { t } = useTranslation();

  const { manpowerRecords, manpowerRecordsLoading } =
    useManpowerRecords(dailyDiary);

  const [importRecords, { loading: importLoading }] = useGraphMutation<
    ImportDailyDiaryManpowerRecordsMutation,
    ImportDailyDiaryManpowerRecordsMutationVariables
  >(
    importDailyDiaryManpowerRecordsMutation,
    {},
    t("Projects.DailyDiaries.ImportRecordsModal.importSuccess")
  );

  return {
    manpowerRecords,
    loading: manpowerRecordsLoading || importLoading,
    importLoading,
    manpowerRecordsLoading,
    importRecords,
  };
};
