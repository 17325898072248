import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridFilterModel,
  GridLogicOperator,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StyledDataGrid } from "components/StyledDataGrid";
import { User, UserRoleAssignmentInput, UserStatus } from "generated/graphql";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { UsersThree } from "phosphor-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useContractUsers } from "./useContractUsers";
import { useColumns } from "./ContractUsers.constants";
import { getUserName } from "helpers/miscelaneous";
import { exportToExcel } from "helpers/exportToExcel";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { BulkAssignUsersRolesModal } from "./BulkAssignUsersRolesModal";

type ContractUsersType = {
  projectFriendlyName?: string;
  contractFriendlyName?: string;
};

const minimumRecordsNoForScroll = 11;

export const ContractUsers: React.FC<ContractUsersType> = ({
  projectFriendlyName,
  contractFriendlyName,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { contractId } = useParams();

  const {
    usersByContract: users,
    usersByContractLoading,
    hasMore: hasMoreUsers,
    loadMore: loadMoreUsers,
    bulkAssignUserRolesLoading,
    bulkAssignUserRoles,
    contractProductInstances,
    contractProductInstancesLoading,
  } = useContractUsers(contractId!);

  const loading =
    usersByContractLoading ||
    contractProductInstancesLoading ||
    bulkAssignUserRolesLoading;

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [showUsers, setShowUsers] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [UserStatus.Active, UserStatus.Suspended],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const { modalVisibility, toggleModalVisibility, setModalVisibility } =
    useBasicModal();

  const handleExportToExcel = () => {
    const productInstancesColumnConfigs = contractProductInstances.map(
      (productInstance) => ({
        header: productInstance.description,
        key: `productInstance-${productInstance.id}`,
      })
    );

    const columns = [
      {
        header: t("common.labels.name"),
        key: "name",
      },
      {
        header: t("common.labels.email"),
        key: "email",
      },
      {
        header: t("common.labels.status"),
        key: "status",
      },
      {
        header: t("common.labels.company"),
        key: "company",
      },
      ...productInstancesColumnConfigs,
    ];

    const rows = users
      .filter((user) => (selectionModel || []).indexOf(user.id) >= 0)
      .map((user) => ({
        ...user,
        name: user.registered ? getUserName(user) : "",
        company: user.company.tradingName,
        ...productInstancesColumnConfigs.reduce(
          (acc: { [key: string]: string | undefined }, crt) => {
            acc[crt.key] = user.roles.items.find((role) =>
              crt.key.includes(role.productInstanceId)
            )?.productRole.name;

            return acc;
          },
          {}
        ),
      }));

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}${t(
        "AdminConsole.Users.labels.contractUsersRoles"
      )}`,
      columns,
      rows
    );
  };

  const handleLoadMore = () => {
    if (hasMoreUsers) {
      loadMoreUsers();
    }
  };

  const columns = useColumns(contractProductInstances);

  const gridHasScroll = users.length >= minimumRecordsNoForScroll;

  const handleBulkAssignUsersRoles = async (
    roleAssignments: UserRoleAssignmentInput[]
  ) => {
    if (!selectionModel) {
      return;
    }

    const { data } = await bulkAssignUserRoles(
      selectionModel as string[],
      roleAssignments
    );
    if (data) {
      setModalVisibility(false);
    }
  };

  return (
    <Box>
      {modalVisibility && (
        <BulkAssignUsersRolesModal
          productInstances={contractProductInstances}
          onPrimaryClick={handleBulkAssignUsersRoles}
          open={modalVisibility}
          primaryBtnDisabled={bulkAssignUserRolesLoading}
          onSecondaryClick={toggleModalVisibility}
        />
      )}
      <CollapsibleHeader
        title={t("AdminConsole.Users.labels.usersRoles")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={<UsersThree color={theme.palette.primary.main} />}
        onToggleCollapse={() => setShowUsers((state) => !state)}
        onActionButtonClick={
          selectionModel?.length ? toggleModalVisibility : undefined
        }
        actionButtonCaption={t("AdminConsole.Users.labels.assignRoles")}
        collapsed={!showUsers}
      />
      <Collapse in={showUsers}>
        <Box
          sx={{
            maxHeight: 600,
            height: gridHasScroll ? 600 : "unset",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={users}
            columns={columns}
            getRowId={(rowData: User) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            onRowsScrollEnd={handleLoadMore}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight={!gridHasScroll}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
