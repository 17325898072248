import { ActionsDialogProps } from "components/ActionsDialog";
import { VariationActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendObjectionToInstructionOfVariationModal } from "./useSendObjectionToInstructionOfVariationModal";
import { ReasonsAttachmentsSummary } from "containers/Projects/components/ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";

export type SendObjectionToInstructionOfVariationModalProps = {
  reasons: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendObjectionToInstructionOfVariationModal: React.FC<
  SendObjectionToInstructionOfVariationModalProps
> = ({ reasons, attachments = [], ...restDialogProps }) => {
  const { t } = useTranslation();

  const { variationPreview, contract } =
    useSendObjectionToInstructionOfVariationModal(reasons);
  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.ObjectToInstructionOfVariation}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={reasons}
          label={t("common.labels.reasons")}
          attachments={attachments}
          contractTimezone={contract.timeZone}
        />
      }
      {...restDialogProps}
    />
  );
};
