import { gql } from "@apollo/client";

export const changeContractStatusMutation = gql`
  mutation changeContractStatus($id: ID!, $status: ContractStatus!) {
    changeContractStatus(id: $id, status: $status) {
      id
      name
      friendlyName
      number
      valueCurrency
      value
      startDate
      endDate
      contractTypeId
      country
      province
      timeZone
      coordinatesLatitude
      coordinatesLongitude
      status
      dateCreated
      creator {
        id
        firstname
        surname
      }
    }
  }
`;
