import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  dateTimeISOFormat,
  excelDateTimeISOFormat,
  temporaryRowId,
} from "constants/constants";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import moment from "moment";
import {
  AddItemStatusOptionReasonInput,
  EditItemStatusOptionReasonInput,
  ItemStatusOptionReason,
  ItemStatusOptionReasonStatus,
  User,
} from "generated/graphql";
import { sortOptionReasons } from "./OptionChangeReasons.utils";
import { useColumns } from "./OptionChangeReasons.constants";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { ListBullets } from "phosphor-react";
import { getUserName } from "helpers/miscelaneous";

export type OptionChangeReasonsProps = {
  optionReasons: ItemStatusOptionReason[];
  optionName?: string;
  optionId?: string;
  loading?: boolean;
  onOptionReasonStatusChange: (
    row: ItemStatusOptionReason,
    newStatus: ItemStatusOptionReasonStatus
  ) => void;
  onOptionReasonAdd: (optionReasonData: AddItemStatusOptionReasonInput) => void;
  onOptionReasonUpdate: (
    optionReasonData: EditItemStatusOptionReasonInput
  ) => void;
};

export const OptionChangeReasons: React.FC<OptionChangeReasonsProps> = ({
  optionReasons,
  optionName,
  optionId,
  loading,
  onOptionReasonAdd,
  onOptionReasonStatusChange,
  onOptionReasonUpdate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser } = useContext(GlobalContext);
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const reasonsStatusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ItemStatusOptionReasonStatus>[];

  const [rows, setRows] = useState<ItemStatusOptionReason[]>(
    sortOptionReasons(optionReasons)
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(optionReasons)
  );

  const [showOptions, setShowOptions] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ItemStatusOptionReasonStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("AdminConsole.Products.labels.displayOrder"),
        key: "displayOrder",
      },
      {
        header: t("AdminConsole.Products.labels.reason"),
        key: "description",
      },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
      { header: t("common.labels.createdBy"), key: "creator" },
    ];

    const rowsToExport = rows
      .filter(
        (optionReason) => (selectionModel || []).indexOf(optionReason.id) >= 0
      )
      .map((optionReason) => ({
        ...optionReason,
        displayOrder: parseInt(`${optionReason.displayOrder}`),
        dateCreated: optionReason.dateCreated
          ? new Date(optionReason.dateCreated)
          : "",
        creator: getUserName(optionReason.creator),
      }));

    exportToExcel(
      `${optionName}-${t("AdminConsole.Products.labels.optionReasons")}`,
      columns,
      rowsToExport
    );
  };

  const handleRowChangesCommited = useCallback(
    (
      newRow: GridRowModel<ItemStatusOptionReason>,
      oldRow: GridRowModel<ItemStatusOptionReason>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.description) {
          onOptionReasonAdd({
            description: newRow.description,
            displayOrder: newRow.displayOrder,
            statusOptionId: optionId!,
          });
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "description",
            },
          }));
        }
      } else if (newRow.displayOrder !== oldRow.displayOrder) {
        onOptionReasonUpdate({
          id: newRow.id,
          displayOrder: newRow.displayOrder,
        });
      }

      return newRow;
    },
    [onOptionReasonAdd, onOptionReasonUpdate, optionId]
  );

  const handleOptionReasonStatusChange = useCallback(
    (row: ItemStatusOptionReason, newStatus: ItemStatusOptionReasonStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        onOptionReasonStatusChange(row, newStatus);
      }
    },
    [onOptionReasonStatusChange]
  );

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        description: "",
        displayOrder: rows.length + 1 || 1,
        id: temporaryRowId,
        status: ItemStatusOptionReasonStatus.Active,
        isInternal: false,
        statusOptionId: optionId!,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "description",
        },
      }));
    });
  };

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onOptionReasonStatusChange(
          optionReasons.find((optionReason) => optionReason.id === rowId)!,
          ItemStatusOptionReasonStatus.Removed
        );
      }
    },
    [onOptionReasonStatusChange, optionReasons]
  );

  const handleRowOrderChange: GridEventListener<"rowOrderChange"> = (
    params
  ) => {
    const optionReason = params.row;
    const newIndex = params.targetIndex + 1;

    if (newIndex !== optionReason.displayOrder) {
      onOptionReasonUpdate({
        id: optionReason.id,
        displayOrder: newIndex,
      });
    }
  };

  const columns = useColumns(
    reasonsStatusOptions,
    rowModesModel,
    handleOptionReasonStatusChange,
    handleRowSaveClick,
    handleDeleteRow
  );

  useEffect(() => {
    setRows(sortOptionReasons(optionReasons));
    setRowModesModel(computeGridRowModes(optionReasons));
  }, [optionReasons]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Products.labels.optionReasons")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <ListBullets
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowOptions((state) => !state)}
        collapsed={!showOptions}
      />
      <Collapse in={showOptions}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: ItemStatusOptionReason) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            onRowOrderChange={handleRowOrderChange}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, ItemStatusOptionReason, any>
            ) => {
              return params.row.status === ItemStatusOptionReasonStatus.Removed
                ? "greyed-out"
                : "";
            }}
            rowReordering
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
