import {
  DailyDiaryItem,
  DailyDiaryWeatherImportSourceItemsQuery,
  DailyDiaryWeatherImportSourceItemsQueryVariables,
} from "generated/graphql";
import { dailyDiaryWeatherImportSourceItemsQuery } from "./WeatherSection.query";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";

export const useAvailableDiariesToImportFrom = () => {
  const [
    fetchAvailableDiaries,
    { data: availableDiariesToImportFrom, loading },
  ] = useGraphLazyQueryLite<
    DailyDiaryWeatherImportSourceItemsQuery,
    DailyDiaryWeatherImportSourceItemsQueryVariables
  >(dailyDiaryWeatherImportSourceItemsQuery);

  return {
    availableDiariesToImportFrom: (availableDiariesToImportFrom?.data
      .dailyDiaryWeatherImportSourceItems.items ?? []) as DailyDiaryItem[],
    availableDiariesToImportFromLoading: loading,
    fetchAvailableDiaries,
  };
};
