import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationErrorAgreementNoticePromptQuery = gql`
  query variationErrorAgreementNoticePrompt(
    $input: VariationErrorAgreementNoticePromptInput!
  ) {
    variationErrorAgreementNoticePrompt(input: $input) {
      details
    }
  }
`;

export const sendVariationErrorAgreementNoticeMutation = gql`
  mutation sendVariationErrorAgreementNotice(
    $input: SendVariationErrorAgreementNoticeInput!
  ) {
    sendVariationErrorAgreementNotice(input: $input) {
      id
    }
  }
`;

export const recordVariationErrorAgreementNoticeMutation = gql`
  mutation recordVariationErrorAgreementNotice(
    $input: RecordVariationErrorAgreementNoticeInput!
  ) {
    recordVariationErrorAgreementNotice(input: $input) {
      id
    }
  }
`;

export const generateSendVariationErrorAgreementNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationErrorAgreementNoticePreview(
    $input: SendVariationErrorAgreementNoticePreviewInput!
  ) {
    generateSendVariationErrorAgreementNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationErrorAgreementNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationErrorAgreementNoticePreview(
    $input: RecordVariationErrorAgreementNoticePreviewInput!
  ) {
    generateRecordVariationErrorAgreementNoticePreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
