import { Chip, css, styled } from "@mui/material";

export const StyledChipNew = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "bgColor" && prop !== "disabled",
})<{ bgColor: string; disabled?: boolean }>(({ bgColor, disabled = false }) => {
  return css`
    && {
      cursor: ${disabled ? "default" : "pointer"};
      background: ${bgColor};
    }
  `;
});
