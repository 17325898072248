import { Box, Typography } from "@mui/material";
import { NewEntityHeader } from "components/NewEntityHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { FormPublicApi } from "types/decl";
import {
  AddContractInput,
  AddContractMutation,
  AddContractMutationVariables,
  ContractType,
  ContractTypesQuery,
  ContractTypesQueryVariables,
  EditContractInput,
  GetProjectByIdLiteQuery,
  GetProjectByIdLiteQueryVariables,
} from "generated/graphql";
import { contractTypesQuery } from "graphql/queries/contractTypes.query";
import { getProjectByIdLiteQuery } from "graphql/queries/projectByIdLite.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { ContractForm } from "../components/ContractForm/ContractForm";
import { addContractMutation } from "./NewContract.query";

export const NewContract = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as { projectFriendlyName?: string } | undefined;
  const navigateBack = useNavigateBack();
  const { projectId } = useParams();
  const contractFormRef = useRef<FormPublicApi>(null);
  const contractFormData = useRef<AddContractInput>();

  const [addNewContract, { loading: addNewContractLoading }] = useGraphMutation<
    AddContractMutation,
    AddContractMutationVariables
  >(
    addContractMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "project" });
        cache.gc();
      },
    },
    t("common.successMessages.entityCreated", {
      entity: t("common.labels.contract"),
    })
  );

  const [
    getProjectDataLite,
    { data: projectDataLite, loading: getProjectDataLiteLoading },
  ] = useGraphLazyQuery<
    GetProjectByIdLiteQuery,
    GetProjectByIdLiteQueryVariables
  >(getProjectByIdLiteQuery);

  const { data: contractTypes, loading: getContractTypesLoading } =
    useGraphQuery<ContractTypesQuery, ContractTypesQueryVariables>(
      contractTypesQuery
    );

  const handleAddContract = useCallback(async () => {
    if (!contractFormData.current || !contractFormRef.current?.validate()) {
      return;
    }

    const { errors } = await addNewContract({
      variables: {
        input: contractFormData.current,
      },
    });

    if (!errors) {
      navigateBack();
    }
  }, [addNewContract, navigateBack]);

  const handleFormChange = useCallback(
    (updatedContract: AddContractInput | EditContractInput) => {
      contractFormData.current = updatedContract as AddContractInput;
    },
    [contractFormData]
  );

  useEffect(() => {
    if (!state) {
      getProjectDataLite({ variables: { id: projectId! } });
    }
  }, [state, getProjectDataLite, projectId]);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <NewEntityHeader
        onAdd={handleAddContract}
        title={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            ml={3}
            mr={1}
          >
            <Typography variant="subtitle2">
              {state?.projectFriendlyName ??
                projectDataLite?.project.friendlyName}
            </Typography>
            <Typography variant="h5">
              {t("common.labels.newEntity", {
                entity: t("common.labels.contract").toLowerCase(),
              })}
            </Typography>
          </Box>
        }
        generalLoading={
          addNewContractLoading ||
          getProjectDataLiteLoading ||
          getContractTypesLoading
        }
        addActionLoading={addNewContractLoading}
      />
      <PageContentContainer>
        <ContractForm
          projectId={projectId!}
          onChange={handleFormChange}
          contractTypes={
            (contractTypes?.contractTypes.items as ContractType[]) || []
          }
          apiRef={contractFormRef}
          disabled={addNewContractLoading || getContractTypesLoading}
        />
      </PageContentContainer>
    </Box>
  );
};
