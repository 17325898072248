import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimErrorAgreementDisagreementPromptQuery = gql`
  query claimErrorAgreementDisagreementPrompt(
    $input: ClaimErrorAgreementDisagreementPromptInput!
  ) {
    claimErrorAgreementDisagreementPrompt(input: $input) {
      remarks
    }
  }
`;

export const sendClaimErrorAgreementDisagreementMutation = gql`
  mutation sendClaimErrorAgreementDisagreement(
    $input: SendClaimErrorAgreementDisagreementInput!
  ) {
    sendClaimErrorAgreementDisagreement(input: $input) {
      id
    }
  }
`;

export const recordClaimErrorAgreementDisagreementMutation = gql`
  mutation recordClaimErrorAgreementDisagreement(
    $input: RecordClaimErrorAgreementDisagreementInput!
  ) {
    recordClaimErrorAgreementDisagreement(input: $input) {
      id
    }
  }
`;

export const generateSendClaimErrorAgreementDisagreementPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimErrorAgreementDisagreementPreview(
    $input: SendClaimErrorAgreementDisagreementPreviewInput!
  ) {
    generateSendClaimErrorAgreementDisagreementPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimErrorAgreementDisagreementPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimErrorAgreementDisagreementPreview(
    $input: RecordClaimErrorAgreementDisagreementPreviewInput!
  ) {
    generateRecordClaimErrorAgreementDisagreementPreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
