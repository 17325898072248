import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  ListBullets,
  ListNumbers,
  PaperPlaneTilt,
  TextBolder,
  TextItalic,
  Table,
} from "phosphor-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToolbarIconContainer } from "./Editor.styled";
import { blockTypeToBlockName } from "./Editor.constants";
// import { INSERT_TABLE_COMMAND } from "./plugins/TablePlugin/TablePlugin";
import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { InsertTableModal } from "./plugins/TablePlugin/InsertTableModal";
import { TableConfigType } from "./plugins/TablePlugin/InsertTableForm/InsertTableForm";

export const EditorToolbar: React.FC<{
  sendBtnDisabled?: boolean;
  withTable?: boolean;
  fullWidth?: boolean;
  onSend?: () => void;
  onCancel?: () => void;
}> = ({ sendBtnDisabled, withTable, fullWidth, onSend, onCancel }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [editor] = useLexicalComposerContext();

  const [activeEditor, setActiveEditor] = useState(editor);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>("paragraph");

  const {
    modalVisibility: insertTableModalVisibility,
    toggleModalVisibility: toggleInsertTableModalVisibility,
  } = useBasicModal();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
    }
  }, [activeEditor]);

  const formatNumberedList = () => {
    if (blockType !== "number") {
      activeEditor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatBulletList = () => {
    if (blockType !== "bullet") {
      activeEditor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const triggerInsertTableFlow = () => {
    toggleInsertTableModalVisibility();
  };

  const handleInsertTableModalConfirmBtnClick = (
    tableConfig: TableConfigType
  ) => {
    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
      rows: String(tableConfig.noOfRows),
      columns: String(tableConfig.noOfColumns),
      includeHeaders: tableConfig.includeHeaders,
    });
    toggleInsertTableModalVisibility();
  };

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      })
    );
  }, [activeEditor, editor, updateToolbar]);

  return (
    <>
      {withTable && (
        <InsertTableModal
          open={insertTableModalVisibility}
          onClose={toggleInsertTableModalVisibility}
          onPrimaryClick={handleInsertTableModalConfirmBtnClick}
          onSecondaryClick={toggleInsertTableModalVisibility}
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        bottom="8px"
        left="8px"
        width={fullWidth ? "calc(100% - 16px)" : "auto"}
        zIndex={8}
        boxSizing="border-box"
        sx={{
          background: theme.palette.grey[100],
          borderRadius: "10px",
          p: theme.spacing(1.25),
          paddingRight: fullWidth ? undefined : theme.spacing(1.5),
        }}
      >
        <ToolbarIconContainer ml={0.5} isSelected={isBold}>
          <TextBolder
            size={16}
            color={theme.palette.grey[900]}
            data-testid="editor-bold-btn"
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
            }}
          />
        </ToolbarIconContainer>
        <ToolbarIconContainer ml={0.5} isSelected={isItalic}>
          <TextItalic
            size={16}
            color={theme.palette.grey[900]}
            data-testid="editor-italic-btn"
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
            }}
          />
        </ToolbarIconContainer>
        <ToolbarIconContainer ml={0.5} isSelected={blockType === "number"}>
          <ListNumbers
            size={16}
            color={theme.palette.grey[900]}
            data-testid="editor-numbered-list-btn"
            onClick={formatNumberedList}
          />
        </ToolbarIconContainer>
        <ToolbarIconContainer ml={0.5} isSelected={blockType === "bullet"}>
          <ListBullets
            size={16}
            color={theme.palette.grey[900]}
            data-testid="editor-bullet-list-btn"
            onClick={formatBulletList}
          />
        </ToolbarIconContainer>
        {withTable && (
          <ToolbarIconContainer ml={0.5}>
            <Table
              size={16}
              color={theme.palette.grey[900]}
              data-testid="editor-insert-table-btn"
              onClick={triggerInsertTableFlow}
            />
          </ToolbarIconContainer>
        )}

        <Box ml="auto" display="flex" alignItems="center">
          {onCancel && onSend && (
            <Box mr={1}>
              <Button variant="outlined" onClick={onCancel}>
                <Typography variant="p2" fontWeight={700}>
                  {t("common.buttons.cancel")}
                </Typography>
              </Button>
            </Box>
          )}
          {onSend && (
            <Button
              variant="contained"
              onClick={onSend}
              disabled={sendBtnDisabled}
              data-testid="comment-send-btn"
            >
              <PaperPlaneTilt size={16} />
              <Typography variant="p2" fontWeight={700} ml={1}>
                {t("common.buttons.send")}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
