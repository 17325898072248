import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const XCircleIcon: React.FC<
  BaseIconProps & {
    onClick?: React.MouseEventHandler<SVGSVGElement>;
    backgroundColor?: string;
  }
> = ({
  height = "17",
  width = "18",
  color,
  backgroundColor = "none",
  onClick,
  ...restProps
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      style={{ cursor: "pointer" }}
      viewBox="0 0 17 18"
      fill={backgroundColor}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M8.9973 15.001C12.3117 15.001 14.9985 12.3141 14.9985 8.99975C14.9985 5.68537 12.3117 2.99854 8.9973 2.99854C5.68293 2.99854 2.99609 5.68537 2.99609 8.99975C2.99609 12.3141 5.68293 15.001 8.9973 15.001Z"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeMiterlimit="10"
      />
      <path
        d="M10.9962 6.99951L6.99609 10.9997"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9962 10.9997L6.99609 6.99951"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
