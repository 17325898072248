import { Box, BoxProps, Typography } from "@mui/material";
import { Overlay } from "components/Overlay";

export type CompanyFormSectionProps = {
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
} & BoxProps;

export const CompanyFormSection: React.FC<CompanyFormSectionProps> = ({
  title,
  children,
  disabled = false,
  ...otherProps
}) => (
  <Box
    display="flex"
    flexDirection="column"
    position="relative"
    {...otherProps}
  >
    <Typography variant="subtitle1" fontWeight={500}>
      {title}
    </Typography>
    <Box mt={4}>{children}</Box>
    {disabled && <Overlay />}
  </Box>
);
