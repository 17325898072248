import { Box, BoxProps, Typography, css, styled } from "@mui/material";
import { AuthorizationWorkflowLevelMode } from "generated/graphql";
import { Trans } from "react-i18next";

export type LevelRuleBannerProps = {
  mode: AuthorizationWorkflowLevelMode;
} & BoxProps;

const LevelRuleBannerContainer = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    border-radius: 8px;
    background: ${theme.palette.grey[100]};
    border: 1px solid ${theme.palette.grey[300]};
  `
);

const detailsTransComponentsConfig = {
  bold: <Typography variant="p2" fontWeight={700} />,
};

export const LevelRuleBanner: React.FC<LevelRuleBannerProps> = ({
  mode,
  ...restProps
}) => (
  <LevelRuleBannerContainer {...restProps}>
    <Typography variant="p2">
      <Trans
        i18nKey="Home.Authorizations.authorizationRule"
        values={{
          context:
            mode === AuthorizationWorkflowLevelMode.AnyoneCanAuthorize
              ? "anyone"
              : "everyone",
        }}
        components={detailsTransComponentsConfig}
      />
    </Typography>
  </LevelRuleBannerContainer>
);
