import { Stack, Tooltip, Typography } from "@mui/material";
import { Question } from "phosphor-react";
import { useTranslation } from "react-i18next";

type NotifiedUsersWidgetHeaderProps = {
  recipientsCount: number;
};

export const NotifiedUsersWidgetHeader: React.FC<
  NotifiedUsersWidgetHeaderProps
> = ({ recipientsCount }) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="flex-start" direction="row" spacing={0.5}>
      <Typography variant="h3" color="grey.900">
        {t("NotifiedUsers.recipients", {
          count: recipientsCount,
        })}
      </Typography>
      <Tooltip
        title={
          <Typography variant="body2">{t("NotifiedUsers.info")}</Typography>
        }
      >
        <Question size={16} cursor="pointer" />
      </Tooltip>
    </Stack>
  );
};
