import { gql } from "@apollo/client";
import { draftAssessmentFields } from "graphql/fragments/draftAssessment.fragment";

export const addDraftAssessmentMutation = gql`
  ${draftAssessmentFields}

  mutation addDraftAssessment($input: AddDraftAssessmentInput!) {
    addDraftAssessment(input: $input) {
      ...DraftAssessmentFields
    }
  }
`;
