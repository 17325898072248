import { Box, css, styled } from "@mui/material";

export const HeaderLoadingContainer = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    margin-top: ${theme.spacing(0.5)};
    left: 50%;
    transform: translateX(-50%);
  `
);
