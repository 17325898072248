import { FileMIMEType } from "../Attachments.decl";
import {
  FilePpt,
  FileZip,
  FilePdf,
  FileDoc,
  FileXls,
  FileImage,
  FileVideo,
  File,
} from "phosphor-react";
import { Box, BoxProps, Color, css, styled } from "@mui/material";

export enum FileIconSize {
  Small = "small",
  Regular = "regular",
  Large = "large",
  FullWidth = "fullWidth",
}

export enum FileIconVariant {
  Colored = "colored",
  Grey = "grey",
  ColoredNoBackground = "coloredNoBackground",
}

export type FileTypeIconProps = {
  /**
   * type represents the MIME-Type of the file
   */
  type?: FileMIMEType;
  size?: FileIconSize;
  variant?: FileIconVariant;
} & BoxProps;

const IconContainer = styled(Box)<{
  size: FileIconSize;
  variant: FileIconVariant;
}>(({ theme, size, variant }) => {
  const iconContainerSize =
    size === FileIconSize.Small
      ? "32px"
      : size === FileIconSize.Regular
      ? "44px"
      : size === FileIconSize.Large
      ? "64px"
      : "100%";

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${iconContainerSize};
    width: ${iconContainerSize};
    min-width: ${iconContainerSize};
    border-radius: 8px;
    background-color: ${variant === FileIconVariant.Colored
      ? (theme.palette.secondary as Partial<Color>)[100]
      : variant === FileIconVariant.ColoredNoBackground
      ? "transparent"
      : theme.palette.common.white};
    svg {
      color: ${variant === FileIconVariant.Colored ||
      variant === FileIconVariant.ColoredNoBackground
        ? (theme.palette.secondary as Partial<Color>)[700]
        : theme.palette.grey[300]};
    }
  `;
});

export const FileTypeIcon: React.FC<FileTypeIconProps> = ({
  type,
  size = FileIconSize.Regular,
  variant = FileIconVariant.Colored,
  ...containerProps
}) => {
  const iconSize =
    size === FileIconSize.Small ? 16 : size === FileIconSize.Regular ? 24 : 64;
  let icon;

  switch (type) {
    case FileMIMEType.Pdf:
      icon = <FilePdf size={iconSize} />;
      break;
    case FileMIMEType.Xls:
      icon = <FileXls size={iconSize} />;
      break;
    case FileMIMEType.Img:
      icon = <FileImage size={iconSize} />;
      break;
    case FileMIMEType.Video:
      icon = <FileVideo size={iconSize} />;
      break;
    case FileMIMEType.Zip:
      icon = <FileZip size={iconSize} />;
      break;
    case FileMIMEType.Ppt:
      icon = <FilePpt size={iconSize} />;
      break;
    case FileMIMEType.Doc:
      icon = <FileDoc size={iconSize} />;
      break;
    case FileMIMEType.Unknown:
    default:
      icon = <File size={iconSize} />;
  }

  return (
    <IconContainer size={size} variant={variant} {...containerProps}>
      {icon}
    </IconContainer>
  );
};
