import {
  AddProductInstanceMutation,
  AddProductInstanceMutationVariables,
} from "generated/graphql";
import { useProjectLite } from "containers/AdminConsole/hooks/useProjectLite";
import { useGraphMutation } from "hooks/useGraphMutation";
import { addProductInstanceMutation } from "./NewProductInstance.query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useContractLite } from "hooks/useContractLite";
import { useContractProducts } from "containers/AdminConsole/hooks/useContractProducts";

export const useNewProductInstance = () => {
  const { t } = useTranslation();
  const { projectId, contractId } = useParams();

  const { contractLite, contractLiteLoading } = useContractLite(contractId!);

  const { projectDataLite, loading: projectDataLiteLoading } = useProjectLite(
    projectId!
  );

  const { products, loading: productsLoading } = useContractProducts(
    contractId!
  );

  const [addNewProductInstance, { loading: addNewProductInstanceLoading }] =
    useGraphMutation<
      AddProductInstanceMutation,
      AddProductInstanceMutationVariables
    >(
      addProductInstanceMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "contract" });
          cache.gc();
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("common.labels.productInstance"),
      })
    );

  return {
    contractLite,
    contractLiteLoading,
    projectDataLite,
    projectDataLiteLoading,
    products,
    productsLoading,
    addNewProductInstance,
    addNewProductInstanceLoading,
  };
};
