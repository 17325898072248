import { Box } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  AddDraftClaimDeterminationInput,
  AttachmentInput,
  ClaimDeterminationNoticePrompt,
  DetailedClaim,
  DraftClaimDetermination,
  EditDraftClaimDeterminationInput,
} from "generated/graphql";
import { ClaimActionFormProps } from "../ClaimActionView";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { TableHeader } from "containers/Projects/components/ActionModal/TableHeader";
import {
  ClaimResolveType,
  DetailedClaimsAgreementsDeterminationsTable,
} from "../components/DetailedClaimsAgreementsDeterminationsTable/DetailedClaimsAgreementsDeterminationsTable";
import { useDetailedClaimAgreementDeterminationModal } from "../../DetailedClaimAgreementClaimModal/useDetailedClaimAgreementDeterminationModal";
import {
  DetailedClaimAgreementDeterminationFormDataType,
  DetailedClaimAgreementDeterminationModal,
  ModalType,
} from "../../DetailedClaimAgreementClaimModal/DetailedClaimAgreementDeterminationModal";
import { useNotifyClaimDeterminationAction } from "./useNotifyClaimDeterminationAction";
import { SendClaimDeterminationNoticeModal } from "./SendClaimDeterminationNoticeModal";
import { RecordClaimDeterminationNoticeModal } from "./RecordClaimDeterminationNoticeModal";

const defaultFormData: ClaimDeterminationNoticePrompt = {
  draftClaimDeterminations: [], // Note: even though prompt accepts an array of ClaimDeterminations, engineers will be able to add only one at a time
};

export const NotifyClaimDeterminationAction: React.FC<
  ClaimActionFormProps & {
    finalDetailedClaim: DetailedClaim;
    triggersAuthWorkflow?: boolean;
  }
> = ({ apiRef, finalDetailedClaim, onClose, triggersAuthWorkflow }) => {
  const { claim, contract, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const [formData, setFormData] =
    useState<ClaimDeterminationNoticePrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [
    selectedDraftClaimDeterminationId,
    setSelectedDraftClaimDeterminationId,
  ] = useState<string>();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const {
    modalVisibility: claimDeterminationModalVisibility,
    toggleModalVisibility: toggleClaimDeterminationModalVisibility,
    loading: claimDeterminationLoading,
    addDraftClaimDetermination,
    editDraftClaimDetermination,
    removeDraftClaimDetermination,
  } = useDetailedClaimAgreementDeterminationModal();

  const draftClaimDeterminationIds = useMemo(
    () =>
      formData.draftClaimDeterminations.map(
        (draftDetermination) => draftDetermination.id
      ),
    [formData]
  );

  const {
    sendClaimDeterminationNotice,
    recordClaimDeterminationNotice,
    draftClaimDeterminations: promptDraftClaimDeterminations,
    loading: promptDraftClaimDeterminationsLoading,
    actionLoading,
  } = useNotifyClaimDeterminationAction(triggersAuthWorkflow);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendClaimDeterminationNotice = async () => {
    await sendClaimDeterminationNotice({
      variables: {
        input: {
          claimId: claim?.id!,
          draftClaimDeterminationId: draftClaimDeterminationIds[0],
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordClaimDeterminationNotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordClaimDeterminationNotice({
      variables: {
        input: {
          claimId: claim?.id!,
          draftClaimDeterminationId: draftClaimDeterminationIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => !!formData.draftClaimDeterminations.length,
    [formData]
  );

  const handleCreateEditDraftClaimDetermination = useCallback(
    async (
      data: DetailedClaimAgreementDeterminationFormDataType,
      keepModalOpen?: boolean
    ) => {
      const draftClaimDeterminationData = data as
        | AddDraftClaimDeterminationInput
        | EditDraftClaimDeterminationInput;

      if (formData.draftClaimDeterminations.length) {
        // edit mode
        const { data } = await editDraftClaimDetermination({
          variables: {
            input:
              draftClaimDeterminationData as EditDraftClaimDeterminationInput,
          },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftClaimDeterminations: [
              data.editDraftClaimDetermination as DraftClaimDetermination,
            ],
          }));
        }
      } else {
        // create new draft claim determination
        const { data } = await addDraftClaimDetermination({
          variables: { input: draftClaimDeterminationData },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftClaimDeterminations: [
              data.addDraftClaimDetermination as DraftClaimDetermination,
            ],
          }));
        }
      }

      if (!keepModalOpen) {
        toggleClaimDeterminationModalVisibility();
      }
    },
    [
      formData,
      addDraftClaimDetermination,
      editDraftClaimDetermination,
      toggleClaimDeterminationModalVisibility,
    ]
  );

  const handleAttachmentsChange = useCallback(
    (formData: DetailedClaimAgreementDeterminationFormDataType) => {
      updatedAttachmentsRef.current = formData.attachments;
      const isEditMode = !!selectedDraftClaimDeterminationId;

      if (isEditMode) {
        // update live the attachments if form is valid
        handleCreateEditDraftClaimDetermination(formData, true);
      }
    },
    [handleCreateEditDraftClaimDetermination, selectedDraftClaimDeterminationId]
  );

  const handleClaimDeterminationModalClose = () => {
    toggleClaimDeterminationModalVisibility();
    setSelectedDraftClaimDeterminationId(undefined);
  };

  const handleClaimDeterminationRowClick = (
    draftClaimDetermination: ClaimResolveType
  ) => {
    setSelectedDraftClaimDeterminationId(
      (draftClaimDetermination as DraftClaimDetermination).id
    );
    toggleClaimDeterminationModalVisibility();
  };

  const handleDeleteDraftClaimDetermination = useCallback(
    (claimDeterminationId: string) => {
      removeDraftClaimDetermination({
        variables: { id: claimDeterminationId },
      });
    },
    [removeDraftClaimDetermination]
  );

  const handleNewClaimDeterminationClick = () => {
    setSelectedDraftClaimDeterminationId(undefined);
    toggleClaimDeterminationModalVisibility();
  };

  const selectedDraftClaimDetermination = useMemo(
    () =>
      formData.draftClaimDeterminations.find(
        (draftClaimDetermination) =>
          draftClaimDetermination.id === selectedDraftClaimDeterminationId
      ),
    [formData, selectedDraftClaimDeterminationId]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  useEffect(() => {
    if (promptDraftClaimDeterminations) {
      setFormData({
        draftClaimDeterminations: promptDraftClaimDeterminations,
      });
    }
  }, [promptDraftClaimDeterminations]);

  return !selectedDraftClaimDeterminationId &&
    (claimDeterminationLoading || promptDraftClaimDeterminationsLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {claimDeterminationModalVisibility && (
        <DetailedClaimAgreementDeterminationModal
          open={claimDeterminationModalVisibility}
          type={ModalType.DraftClaimDetermination}
          draftClaimDetermination={selectedDraftClaimDetermination}
          contractCurrency={contract.valueCurrency ?? ""}
          contractSections={contract.sections}
          finalDetailedClaim={finalDetailedClaim}
          maxWidth="lg"
          onAttachmentsChange={handleAttachmentsChange}
          onPrimaryClick={handleCreateEditDraftClaimDetermination}
          onClose={handleClaimDeterminationModalClose}
          onSecondaryClick={handleClaimDeterminationModalClose}
        />
      )}
      {isFormValid && sendModalVisibility && (
        <SendClaimDeterminationNoticeModal
          open={sendModalVisibility}
          draftClaimDeterminations={formData.draftClaimDeterminations}
          onPrimaryClick={handleSendClaimDeterminationNotice}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordClaimDeterminationNoticeModal
          open={recordModalVisibility}
          draftClaimDeterminations={formData.draftClaimDeterminations}
          onPrimaryClick={handleRecordClaimDeterminationNotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <TableHeader
          type="ClaimDetermination"
          onAdd={handleNewClaimDeterminationClick}
          disabled={!!formData.draftClaimDeterminations.length}
          isFIDIC99RedYellow={isFIDIC99RedYellow}
        />
        <DetailedClaimsAgreementsDeterminationsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={claimDeterminationLoading}
          items={formData.draftClaimDeterminations}
          type="ClaimDetermination"
          onRowClick={handleClaimDeterminationRowClick}
          onDelete={handleDeleteDraftClaimDetermination}
        />
      </Box>
    </>
  );
};
