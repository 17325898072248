import { gql } from "@apollo/client";

export const creatorFields = gql`
  fragment CreatorFields on User {
    id
    firstname
    surname
    email
  }
`;
