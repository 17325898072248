import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType, ClaimAgreementNoticePrompt } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendAgreementClaimNoticeModal } from "./useSendAgreementClaimNoticeModal";
import { DetailedClaimAgreementDeterminationSummary } from "../components/DetailedClaimAgreementDeterminationSummary";

export type SendAgreementClaimNoticeModalProps = Pick<
  ClaimAgreementNoticePrompt,
  "draftClaimAgreements"
> &
  ActionsDialogProps;

export const SendAgreementClaimNoticeModal: React.FC<
  SendAgreementClaimNoticeModalProps
> = ({ draftClaimAgreements, ...restDialogProps }) => {
  const { claimPreview, contract } = useSendAgreementClaimNoticeModal(
    draftClaimAgreements[0].id
  );
  return (
    <SendAction
      claimPreview={claimPreview}
      actionType={ClaimActionType.NotifyAgreementClaim}
      summaryEl={
        <DetailedClaimAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftClaimAgreements}
          type="ClaimAgreement"
        />
      }
      {...restDialogProps}
    />
  );
};
