import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CEHistoryItemDetailsSummary } from "containers/Projects/components/CompEvents/CompEventHistoryModal/CEHistoryItemDataView";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import { useCompEventHistoryItem } from "containers/Home/components/Authorizations/AuthorizeDenyActionView/hooks/useCompEventHistoryItem/useCompEventHistoryItem";
import React from "react";
import { useContract } from "containers/Projects/hooks/useContract/useContract";

type CompEventSummaryProps = {
  authAudit: AuthorizationWorkflowAudit;
};

export const CompEventSummary: React.FC<CompEventSummaryProps> = ({
  authAudit,
}) => {
  const { compEventHistoryItem, loading } = useCompEventHistoryItem(
    authAudit.regardingItemHistoryId ?? undefined
  );
  const { contract, contractLoading } = useContract(
    authAudit.action.productInstance.contractId
  );

  return loading || !compEventHistoryItem || contractLoading || !contract ? (
    <CenteredLoadingIndicator />
  ) : (
    <CEHistoryItemDetailsSummary
      historyItem={compEventHistoryItem}
      contract={contract}
    />
  );
};
