import { Box, css, styled } from "@mui/material";

export const StyledCodeVerificationContainer = styled(Box)(
  ({ theme }) => css`
    && {
      .ReactInputVerificationCode__container {
        justify-content: flex-start;

        .ReactInputVerificationCode__item {
          height: 56px;
          width: 56px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 600;

          &:not(:first-of-type) {
            margin-left: ${theme.spacing(2)};
          }
        }
      }
    }
  `
);
