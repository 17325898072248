import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ProductType, Project, ProjectStatus } from "generated/graphql";
import {
  StatusOption,
  StatusTag,
} from "../../../../components/StatusTag/StatusTag";
import { getProjectActiveContractsNumber } from "./Projects.utils";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  companyStatusOptions: StatusOption<ProjectStatus>[],
  onStatusChange: (row: Project, newStatus: ProjectStatus) => void
): GridColDef<Project>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();

  const columns = useMemo(
    () =>
      [
        {
          field: "friendlyName",
          headerName: t("common.labels.friendlyName"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (params: GridRenderCellParams<any, Project, any>) => {
            return (
              <StatusTag
                status={params.row.status}
                options={companyStatusOptions}
                onChange={(newStatus: StatusOption<ProjectStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "sector",
          headerName: t("AdminConsole.Projects.labels.sector"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "billingCompany",
          headerName: t("AdminConsole.Projects.labels.billingCompany"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
          valueGetter: (_, row) => row.billingCompany.tradingName,
        },
        {
          field: "activeContracts",
          headerName: t("AdminConsole.Projects.labels.activeContracts"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
          valueGetter: (_, row) => getProjectActiveContractsNumber(row),
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          width: 130,
          resizable: true,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
      ] as GridColDef<Project>[],
    [t, dateCreatedColConfig, companyStatusOptions, onStatusChange]
  );

  return columns;
};

export enum ContractTypeEnum {
  NEC = "NEC",
  FIDIC = "FIDIC",
  FIDIC17White = "FIDIC17White",
}

export const contractProductsMapping: Record<ContractTypeEnum, ProductType[]> =
  {
    [ContractTypeEnum.NEC]: [
      ProductType.CompEvents,
      ProductType.DailyDiary,
      ProductType.EarlyWarnings,
      ProductType.Events,
      ProductType.Instructions,
      ProductType.RisksRegister,
    ],
    [ContractTypeEnum.FIDIC]: [
      ProductType.Claims,
      ProductType.DailyDiary,
      ProductType.EarlyWarnings,
      ProductType.Events,
      ProductType.Instructions,
      ProductType.RisksRegister,
      ProductType.Variations,
    ],
    [ContractTypeEnum.FIDIC17White]: [
      ProductType.DailyDiary,
      ProductType.EarlyWarnings,
      ProductType.Events,
      ProductType.Instructions,
      ProductType.RisksRegister,
      ProductType.Variations,
    ],
  };
