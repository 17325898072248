import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  dateTimeISOFormat,
  excelDateTimeISOFormat,
  temporaryRowId,
} from "constants/constants";
import {
  AddProductUserRoleInput,
  EditProductUserRoleInput,
  ProductInstance,
  ProductRole,
  ProductUserRole,
  ProductUserRoleStatus,
  User,
} from "generated/graphql";
import { computeGridRowModes } from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { useColumns } from "./ProductInstanceRoles.constants";
import {
  rowsContainTemporaryRecord,
  sortRoles,
} from "./ProductInstanceRoles.utils";
import moment from "moment";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { Keyhole } from "phosphor-react";
import { getUserName } from "helpers/miscelaneous";

export type ProductInstanceRolesProps = {
  roles: ProductUserRole[];
  productRoles: ProductRole[];
  users: User[];
  loading?: boolean;
  projectFriendlyName?: string;
  contractFriendlyName?: string;
  productInstanceName?: string;
  productInstanceId?: string;
  onRoleStatusChange: (
    row: ProductUserRole,
    newStatus: ProductUserRoleStatus
  ) => Promise<boolean>;
  onRoleAdd: (newUserRole: AddProductUserRoleInput) => Promise<boolean>;
  onRoleEdit: (updatedUserRole: EditProductUserRoleInput) => Promise<boolean>;
};

export const ProductInstanceRoles: React.FC<ProductInstanceRolesProps> = ({
  roles,
  productRoles,
  users,
  loading,
  projectFriendlyName,
  contractFriendlyName,
  productInstanceName,
  productInstanceId,
  onRoleStatusChange,
  onRoleAdd,
  onRoleEdit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser, refetchAuthenticatedUser } =
    useContext(GlobalContext);
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ProductUserRoleStatus>[];

  const [rows, setRows] = useState<ProductUserRole[]>(sortRoles(roles));
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(rows)
  );
  const [showSections, setShowSections] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ProductUserRoleStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.user"), key: "user" },
      { header: t("common.labels.status"), key: "status" },
      { header: t("Register.labels.jobTitle"), key: "jobTitle" },
      { header: t("common.labels.company"), key: "company" },
      { header: t("common.labels.role"), key: "role" },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
      { header: t("common.labels.createdBy"), key: "creator" },
    ];

    const rowsToExport = rows
      .filter(
        (productUserRole) =>
          (selectionModel || []).indexOf(productUserRole.id) >= 0
      )
      .map((productUserRole) => ({
        ...productUserRole,
        user: getUserName(productUserRole.user),
        jobTitle: productUserRole.user.jobTitle,
        company: productUserRole.user.company.registeredName,
        role: productUserRole.productRole.name,
        dateCreated: productUserRole.dateCreated
          ? new Date(productUserRole.dateCreated)
          : "",
        creator: getUserName(productUserRole.creator),
      }));

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}-${productInstanceName}-${t(
        "common.labels.roles"
      )}`,
      columns,
      rowsToExport
    );
  };

  const handleStatusChange = useCallback(
    async (row: ProductUserRole, newStatus: ProductUserRoleStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        const success = await onRoleStatusChange(row, newStatus);
        if (success && row.id === authenticatedUser!.id) {
          refetchAuthenticatedUser();
        }
      }
    },
    [onRoleStatusChange, authenticatedUser, refetchAuthenticatedUser]
  );

  const handleRoleChange = useCallback(
    async (row: ProductUserRole, newRoleId: string) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                productRoleId: newRoleId,
              };
            }

            return row;
          })
        );
      } else {
        const success = await onRoleEdit({
          id: row.id,
          productRoleId: newRoleId,
          productInstanceId: row.productInstanceId,
          userId: row.userId,
        });

        if (success && row.userId === authenticatedUser!.id) {
          refetchAuthenticatedUser();
        }
      }
    },
    [onRoleEdit, refetchAuthenticatedUser, authenticatedUser]
  );

  const handleUserChange = useCallback(
    (row: ProductUserRole, newUserId: string) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                user: users.find(
                  (curUser) => curUser.id === newUserId
                )! as User,
                userId: newUserId,
              };
            }

            return row;
          })
        );
      }
    },
    [users]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onRoleStatusChange(
          rows.find((role) => role.id === rowId)!,
          ProductUserRoleStatus.Removed
        );
      }
    },
    [onRoleStatusChange, rows]
  );

  // TODO: export these functions in generic hook
  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<ProductUserRole>,
      oldRow: GridRowModel<ProductUserRole>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.productRoleId) {
          const success = await onRoleAdd({
            productInstanceId: newRow.productInstanceId,
            userId: newRow.userId,
            productRoleId: newRow.productRoleId,
          });
          if (success && newRow.userId === authenticatedUser!.id) {
            refetchAuthenticatedUser();
          } else {
            // remove temporary row
            setRows((curRows) =>
              curRows.filter((curRow) => curRow.id !== temporaryRowId)
            );
          }
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "role",
            },
          }));
        }
      } else if (newRow.productRoleId !== oldRow.productRoleId) {
        const success = await onRoleEdit({
          id: newRow.id,
          productInstanceId: newRow.productInstanceId,
          userId: newRow.userId,
          productRoleId: newRow.productRoleId,
        });

        if (success && newRow.userId === authenticatedUser!.id) {
          refetchAuthenticatedUser();
        }
      }

      return newRow;
    },
    [authenticatedUser, onRoleAdd, onRoleEdit, refetchAuthenticatedUser]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        productInstance: {} as ProductInstance,
        productInstanceId: productInstanceId!,
        productRole: {} as ProductRole,
        productRoleId: "",
        status: ProductUserRoleStatus.Active,
        user: {} as User,
        userId: authenticatedUser?.id ?? "",
        id: temporaryRowId,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "role",
        },
      }));
    });
  };

  const columns = useColumns(
    statusOptions,
    rowModesModel,
    productRoles,
    users,
    handleStatusChange,
    handleRoleChange,
    handleUserChange,
    handleRowSaveClick,
    handleDeleteRow
  );

  useEffect(() => {
    setRows(sortRoles(roles));
    setRowModesModel(computeGridRowModes(roles));
  }, [roles]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("common.labels.roles")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <Keyhole size={22} weight="fill" color={theme.palette.primary.main} />
        }
        onToggleCollapse={() => setShowSections((state) => !state)}
        collapsed={!showSections}
      />
      <Collapse in={showSections}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: ProductUserRole) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, ProductUserRole, any>
            ) => {
              return params.row.status === ProductUserRoleStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
