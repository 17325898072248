import { gql } from "@apollo/client";

export const dailyDiaryPresetSchemaSectionFieldFields = gql`
  fragment DailyDiaryPresetSchemaSectionFieldFields on DailyDiaryPresetSchemaSectionField {
    name
    displayName
    fieldType
    isRequired
    canBeHidden
    units
  }
`;
