import { Box } from "@mui/material";
import {
  ClaimAgreement,
  ClaimDetermination,
  ClaimHistoryItem,
  Contract,
  ProductType,
} from "generated/graphql";
import { memo, useContext } from "react";
import { HistoryItemMeta } from "../../HistoryModal/HistoryItemMeta";
import { ClaimWidgetContext } from "../ClaimWidget/ClaimWidget.context";
import { NotifyCEClaimSummary } from "../../ActionModal/NotifyCEClaimSummary";
import { DetailedClaimAgreementDeterminationReadOnlyModal } from "../DetailedClaimAgreementDeterminationReadOnlyModal/DetailedClaimAgreementDeterminationReadOnlyModal";
import { DetailedClaimsAgreementsDeterminationsTable } from "../ClaimActionModal/components/DetailedClaimsAgreementsDeterminationsTable/DetailedClaimsAgreementsDeterminationsTable";
import { useDetailedClaimAgreementDeterminationModal } from "../DetailedClaimAgreementClaimModal/useDetailedClaimAgreementDeterminationModal";
import { ReasonSummary } from "../../ActionModal/ReasonSummary";
import { useTranslation } from "react-i18next";
import { ResponseRegardingDetailedClaimSummary } from "../ClaimActionModal/RespondRegardingDetailedClaimAction/ResponseRegardingDetailedClaimSummary";

type ClaimHistoryItemDetailsSummaryProps = {
  historyItem: ClaimHistoryItem;
  contract: Contract;
};

export const ClaimHistoryItemDetailsSummary: React.FC<
  ClaimHistoryItemDetailsSummaryProps
> = ({ historyItem, contract }) => {
  const { t } = useTranslation();
  const { isFIDIC99RedYellow } = useContext(ClaimWidgetContext);

  const {
    modalVisibility: detailedClaimAgreementDeterminationModalVisibility,
    toggleModalVisibility:
      toggleDetailedClaimAgreementDeterminationModalVisibility,
  } = useDetailedClaimAgreementDeterminationModal();

  if (historyItem.answers?.__typename === "ClaimHistoryNoticeAnswers") {
    return (
      <NotifyCEClaimSummary
        claimType={`${historyItem.answers.claimType.clause} ${historyItem.answers.claimType.description}`}
        ewTitle={historyItem.answers.advanceWarning?.title}
        contextType="claim"
        isFIDIC99RedYellow={isFIDIC99RedYellow}
      />
    );
  } else if (
    historyItem.answers?.__typename === "ClaimHistoryInterimDetailsAnswers" ||
    historyItem.answers?.__typename === "ClaimHistoryFinalDetailsAnswers"
  ) {
    const currency =
      contract.valueCurrency ?? historyItem.answers.detailedClaim.currency;

    return (
      <>
        <DetailedClaimAgreementDeterminationReadOnlyModal
          open={detailedClaimAgreementDeterminationModalVisibility}
          onClose={toggleDetailedClaimAgreementDeterminationModalVisibility}
          item={historyItem.answers.detailedClaim}
          contract={contract}
        />
        <DetailedClaimsAgreementsDeterminationsTable
          contractCurrency={currency}
          type="DetailedClaim"
          items={[historyItem.answers.detailedClaim]}
          onRowClick={toggleDetailedClaimAgreementDeterminationModalVisibility}
        />
      </>
    );
  } else if (
    historyItem.answers?.__typename === "ClaimHistoryAgreementAnswers"
  ) {
    // Note: agreement is optional to handle the case of pending authorization, but in historyView, there should always be an agreement
    const currency =
      contract.valueCurrency ??
      historyItem.answers.agreement?.currency ??
      historyItem.authorizationWorkflowAudit?.action.productInstance.contract
        .valueCurrency;

    const agreement = (
      historyItem.isIncomplete
        ? historyItem.answers.draftAgreement!
        : historyItem.answers.agreement!
    ) as ClaimAgreement;

    return (
      <>
        <DetailedClaimAgreementDeterminationReadOnlyModal
          open={detailedClaimAgreementDeterminationModalVisibility}
          onClose={toggleDetailedClaimAgreementDeterminationModalVisibility}
          item={agreement}
          contract={contract}
        />
        <DetailedClaimsAgreementsDeterminationsTable
          contractCurrency={currency ?? ""}
          type="ClaimAgreement"
          items={[agreement]}
          onRowClick={toggleDetailedClaimAgreementDeterminationModalVisibility}
        />
      </>
    );
  } else if (
    historyItem.answers?.__typename === "ClaimHistoryDeterminationAnswers"
  ) {
    // Note: determination is optional to handle the case of pending authorization, but in historyView, there should always be an determination
    const currency =
      contract.valueCurrency ??
      historyItem.answers.determination?.currency ??
      historyItem.authorizationWorkflowAudit?.action.productInstance.contract
        .valueCurrency;

    const determination = (
      historyItem.isIncomplete
        ? historyItem.answers.draftDetermination!
        : historyItem.answers.determination!
    ) as ClaimDetermination;

    return (
      <>
        <DetailedClaimAgreementDeterminationReadOnlyModal
          open={detailedClaimAgreementDeterminationModalVisibility}
          onClose={toggleDetailedClaimAgreementDeterminationModalVisibility}
          item={determination}
          contract={contract}
        />
        <DetailedClaimsAgreementsDeterminationsTable
          contractCurrency={currency ?? ""}
          type="ClaimDetermination"
          items={[determination]}
          onRowClick={toggleDetailedClaimAgreementDeterminationModalVisibility}
        />
      </>
    );
  } else if (
    historyItem.answers?.__typename === "ClaimHistoryNoAgreementReachedAnswers"
  ) {
    return (
      <ReasonSummary
        reason={historyItem.answers.remarks}
        label={t("common.labels.remarks")}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
    "ClaimHistoryFurtherParticularsRequestAnswers"
  ) {
    return (
      <ReasonSummary
        reason={historyItem.answers.reasons}
        label={t("common.labels.reasons")}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
    "ClaimHistoryFurtherParticularsSubmissionAnswers"
  ) {
    return (
      <ReasonSummary
        reason={historyItem.answers.details}
        label={t("common.labels.details")}
      />
    );
  } else if (
    historyItem.answers?.__typename === "ClaimHistoryNoticeInvalidNoticeAnswers"
  ) {
    return (
      <ReasonSummary
        reason={historyItem.answers.reasons}
        label={t("common.labels.reasons")}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
    "ClaimHistoryNoticeValidityLapsedAnswers"
  ) {
    return (
      <ReasonSummary
        reason={historyItem.answers.remarks}
        label={t("common.labels.remarks")}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
    "ClaimHistoryClaimDetailsResponseAnswers"
  ) {
    return (
      <ResponseRegardingDetailedClaimSummary
        details={historyItem.answers.details}
        detailedClaim={historyItem.answers.detailedClaim}
        allowViewDetails
        attachments={[]} // attachments handled separately
        contract={contract}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
      "ClaimHistoryErrorAgreementNoticeAnswers" ||
    historyItem.answers?.__typename ===
      "ClaimHistoryErrorDeterminationNoticeAnswers"
  ) {
    return (
      <ReasonSummary
        reason={historyItem.answers.details}
        label={t("Projects.Claims.ActionModal.errorsFound")}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
      "ClaimHistoryErrorAgreementDisagreementAnswers" ||
    historyItem.answers?.__typename ===
      "ClaimHistoryErrorDeterminationDisagreementAnswers"
  ) {
    return (
      <ReasonSummary
        reason={historyItem.answers.remarks}
        label={t("common.labels.remarks")}
      />
    );
  }

  return null;
};

type ClaimHistoryItemDataViewProps = {
  historyItem: ClaimHistoryItem;
};

export const ClaimHistoryItemDataView: React.FC<ClaimHistoryItemDataViewProps> =
  memo(({ historyItem }) => {
    const { contract } = useContext(ClaimWidgetContext);

    return (
      <Box display="flex" flexDirection="column">
        <ClaimHistoryItemDetailsSummary
          historyItem={historyItem}
          contract={contract}
        />
        <Box mt={3}>
          <HistoryItemMeta
            contractTimezone={contract.timeZone}
            historyItem={historyItem}
            productType={ProductType.Claims}
          />
        </Box>
      </Box>
    );
  });
