import { gql } from "@apollo/client";

export const productsQuery = gql`
  query products {
    products {
      items {
        __typename
        numberingFormat
        soloModeSupported
        allowMultipleInstances
        id
        name
        status
      }
    }
  }
`;

export const productsWithOutputActionsQuery = gql`
  query productsWithOutputActions {
    products {
      items {
        id
        name
        numberingFormat
        soloModeSupported
        allowMultipleInstances
        status
        outputActions {
          items {
            id
            name
            productId
            isInternal
            status
            dateCreated
            creatorId
            creator {
              id
              firstname
              surname
            }
          }
        }
      }
    }
  }
`;
