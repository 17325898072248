import { FormControl, Grid, TextField, useTheme } from "@mui/material";
import { PasswordEyeAdornment } from "components/miscellaneous/PasswordEyeAdornment";
import { defaultCognitoPasswordRequirementsRegex } from "constants/constants";
import { FormLabel } from "components/FormLabel";
import { RegisterUserInput } from "generated/graphql";
import { useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PasswordFieldLabel } from "../../../components/PasswordFieldLabel";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import { FormPublicApi } from "types/decl";

type FormType = Pick<RegisterUserInput, "password"> & {
  confirmPassword: string;
};

export type UserAccountFormProps = {
  companyName: string;
  email: string;
  initialData?: string;
  apiRef?: React.Ref<FormPublicApi>;
  onChange: (password: string) => void;
};

const defaultFormData: FormType = {
  password: "",
  confirmPassword: "",
};

export const UserAccountForm: React.FC<UserAccountFormProps> = ({
  companyName,
  email,
  initialData,
  apiRef,
  onChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [formData, setFormData] = useState(
    initialData
      ? { password: initialData, confirmPassword: initialData }
      : defaultFormData
  );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formDataValidators: DataValidators = useMemo(
    () => ({
      password: {
        validators: [ValidatorType.ValidText],
        getData: () => [
          formData.password,
          defaultCognitoPasswordRequirementsRegex,
        ],
      },
      confirmPassword: {
        validators: [ValidatorType.ValidConfirmPassword],
        getData: () => [formData.password, formData.confirmPassword],
      },
    }),
    [formData.password, formData.confirmPassword]
  );

  const togglePasswordVisibility = () => {
    setShowPassword((state) => !state);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((state) => !state);
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });

    onChange(evt.target.value);
  };

  const validateForm = useCallback(
    (formData: FormType) => {
      const validationResult = validateData(formData, formDataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [formDataValidators]
  );

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [validateForm, formData, resetForm]
  );

  return (
    <Grid container spacing={4.5}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel label={t("common.labels.company")} />
          <TextField
            fullWidth
            value={companyName}
            type="text"
            size="medium"
            disabled
            InputProps={{
              sx: {
                "input.Mui-disabled": {
                  WebkitTextFillColor: theme.palette.grey[800],
                },
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel label={t("common.labels.email")} />
          <TextField
            fullWidth
            value={email}
            type="text"
            size="medium"
            disabled
            InputProps={{
              sx: {
                "input.Mui-disabled": {
                  WebkitTextFillColor: theme.palette.grey[800],
                },
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} position="relative">
        <PasswordFieldLabel
          label={t("common.labels.chooseYour", {
            entity: t("common.labels.password").toLowerCase(),
          })}
          error={!!formDataErrors.password}
        />
        <TextField
          fullWidth
          onChange={handleTextFieldChange}
          value={formData.password}
          type={showPassword ? "text" : "password"}
          name="password"
          size="medium"
          error={!!formDataErrors.password}
          helperText={formDataErrors.password}
          autoComplete="new-password"
          required
          inputProps={{
            pattern: defaultCognitoPasswordRequirementsRegex,
            autoComplete: "new-password",
          }}
          InputProps={{
            autoComplete: "new-password",
            endAdornment: (
              <PasswordEyeAdornment
                visibility={showPassword}
                onToggleVisibility={togglePasswordVisibility}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordFieldLabel
          label={t("common.labels.repeatPassword")}
          error={!!formDataErrors.confirmPassword}
        />
        <TextField
          fullWidth
          onChange={handleTextFieldChange}
          value={formData.confirmPassword}
          type={showConfirmPassword ? "text" : "password"}
          name="confirmPassword"
          size="medium"
          error={!!formDataErrors.confirmPassword}
          helperText={formDataErrors.confirmPassword}
          required
          autoComplete="new-password"
          inputProps={{
            pattern: defaultCognitoPasswordRequirementsRegex,
            autoComplete: "new-password",
          }}
          InputProps={{
            autoComplete: "new-password",
            endAdornment: (
              <PasswordEyeAdornment
                visibility={showConfirmPassword}
                onToggleVisibility={toggleConfirmPasswordVisibility}
              />
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
