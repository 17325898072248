import { gql } from "@apollo/client";
import { eventItemFields } from "graphql/fragments/eventItem.fragment";

export const editDraftEventItemMutation = gql`
  ${eventItemFields}
  mutation editDraftEventItem($input: EditEventItemInput!) {
    editDraftEventItem(input: $input) {
      ...EventItemFields
    }
  }
`;
