import { AuthorizeDenyActionModal } from "components/Authorization/AuthorizeDenyActionModal/AuthorizeDenyActionModal";
import { useAuthorizeDenyActionModal } from "components/Authorization/AuthorizeDenyActionModal/useAuthorizeDenyActionModal";
import { ConfirmAuthorizationModal } from "components/Authorization/ConfirmAuthorizationModal";
import { DenyAuthorizationModal } from "components/Authorization/DenyAuthorizationModal";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "state-management/store/authStore";

export const AuthorizeDenyModalWrapper: React.FC = () => {
  const { authModalState, setAuthModalState } = useAuthStore((state) => ({
    authModalState: state.authModalState,
    setAuthModalState: state.setAuthModalState,
  }));
  const { t } = useTranslation();

  const {
    modalVisibility: confirmModalVisibility,
    toggleModalVisibility: toggleConfirmModalVisibility,
  } = useBasicModal();
  const {
    modalVisibility: denyModalVisibility,
    toggleModalVisibility: toggleDenyModalVisibility,
  } = useBasicModal();

  const {
    authorize,
    deny,
    loading: authorizeDenyLoading,
  } = useAuthorizeDenyActionModal();

  const handleAuthorize = async () => {
    if (authModalState) {
      const { data } = await authorize(
        authModalState.authAuditId,
        t("common.successMessages.authorizationSuccessful")
      );

      if (data) {
        closeAuthorizationModals();
      }
    }
  };

  const handleDeny = async (denialReason: string) => {
    if (authModalState) {
      const { data } = await deny(
        authModalState.authAuditId,
        denialReason,
        t("common.successMessages.authorizationDenied")
      );

      if (data) {
        closeDenyModals();
      }
    }
  };

  const closeAuthorizationModals = () => {
    toggleConfirmModalVisibility();
    handleCloseAuthorizationModal();
  };

  const closeDenyModals = () => {
    toggleDenyModalVisibility();
    handleCloseAuthorizationModal();
  };

  const handleCloseAuthorizationModal = () => {
    setAuthModalState(undefined);
  };

  return (
    <>
      {authModalState?.type === "AuthorizeDeny" &&
      authModalState.authAuditId ? (
        <>
          {confirmModalVisibility && (
            <ConfirmAuthorizationModal
              open={confirmModalVisibility}
              primaryBtnLoading={authorizeDenyLoading}
              onBack={toggleConfirmModalVisibility}
              onCancel={closeAuthorizationModals}
              onConfirm={handleAuthorize}
            />
          )}
          {denyModalVisibility && (
            <DenyAuthorizationModal
              open={denyModalVisibility}
              primaryBtnLoading={authorizeDenyLoading}
              onBack={toggleDenyModalVisibility}
              onCancel={closeDenyModals}
              onConfirm={handleDeny}
            />
          )}
          <AuthorizeDenyActionModal
            open
            authAuditId={authModalState.authAuditId}
            onAuthorize={toggleConfirmModalVisibility}
            onDeny={toggleDenyModalVisibility}
            onClose={handleCloseAuthorizationModal}
          />
        </>
      ) : null}
    </>
  );
};
