import { Box, TextField } from "@mui/material";
import {
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { AddDescriptionBtn } from "./AddDescriptionBtn";
import {
  OverflowTooltip,
  OverflowTooltipProps,
} from "components/OverflowTooltip";

export const DescriptionText: React.FC<
  OverflowTooltipProps & { newStyle?: boolean }
> = ({ title, typographyProps, newStyle }) => (
  <OverflowTooltip
    title={title}
    typographyProps={{
      variant: "p2",
      color: newStyle ? "grey.600" : "grey.800",
      maxWidth: "100%",
      ...typographyProps,
    }}
  />
);

export const EditableLabel: React.FC<{
  text?: string;
  onChange: (newText?: string) => void;
}> = ({ text, onChange }) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLDivElement>(null);

  const [localText, setLocalText] = useState(text || "");
  const [inputVisible, setInputVisible] = useState(false);

  const handleTextFieldChange: React.ChangeEventHandler<HTMLInputElement> = (
    evt
  ) => {
    setLocalText(evt.target.value);
  };

  const showInput = () => {
    setInputVisible(true);
    setTimeout(() => {
      inputRef.current?.focus();
    });
  };

  const handleAddDescription = () => {
    showInput();
  };

  const handleDescriptionClick: MouseEventHandler<HTMLSpanElement> = (evt) => {
    evt.stopPropagation();
    showInput();
  };

  const handleFieldKeyDown: KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === "Enter") {
      onChange(localText);
      setInputVisible(false);
    }

    if (evt.key === "Escape") {
      setLocalText(text ?? "");
      setInputVisible(false);
      evt.stopPropagation();
    }
  };

  const handleInputClick: MouseEventHandler<HTMLDivElement> = (evt) => {
    evt.stopPropagation();
  };

  const handleLoseFocus = () => {
    if (localText !== text) {
      onChange(localText);
    }
    setInputVisible(false);
  };

  useEffect(() => {
    setLocalText(text ?? "");
  }, [text]);

  return inputVisible ? (
    <Box mt={1} width="100%">
      <TextField
        fullWidth
        name="description"
        value={localText}
        onChange={handleTextFieldChange}
        type="text"
        inputProps={{
          ref: inputRef,
        }}
        placeholder={t("Attachments.addDescriptionPlaceholder")}
        variant="outlined"
        size="small"
        onBlur={handleLoseFocus}
        onKeyDown={handleFieldKeyDown}
        onClick={handleInputClick}
      />
    </Box>
  ) : localText ? (
    <DescriptionText
      typographyProps={{ onClick: handleDescriptionClick }}
      title={localText}
    />
  ) : (
    <AddDescriptionBtn onClick={handleAddDescription} />
  );
};
