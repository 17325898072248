import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  dateTimeISOFormat,
  excelDateTimeISOFormat,
  temporaryRowId,
} from "constants/constants";
import {
  AddSchemaSectionInput,
  ColumnPlacementType,
  EditSchemaSectionInput,
  SchemaSection,
  SchemaSectionStatus,
  User,
} from "generated/graphql";
import { computeGridRowModes } from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { useColumns } from "./SchemaSections.constants";
import {
  rowsContainTemporaryRecord,
  sortSections,
} from "./SchemaSections.utils";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { TreeStructure } from "phosphor-react";
import { getUserName } from "helpers/miscelaneous";

const columnPlacementTranslationKeyMapping: Record<
  ColumnPlacementType,
  string
> = {
  [ColumnPlacementType.Primary]: "AdminConsole.Products.labels.primary",
  [ColumnPlacementType.Secondary]: "AdminConsole.Products.labels.secondary",
};

export type SchemaSectionsProps = {
  sections: SchemaSection[];
  schemaId?: string;
  schemaName?: string;
  loading?: boolean;
  columnPlacement: ColumnPlacementType;
  onSchemaSectionAdd: (
    newSchemaSection: Omit<AddSchemaSectionInput, "productSchemaId">
  ) => void;
  onSchemaSectionStatusChange: (
    row: SchemaSection,
    newStatus: SchemaSectionStatus
  ) => void;
  onSchemaSectionUpdate: (
    schemaData: Omit<EditSchemaSectionInput, "productSchemaId">
  ) => void;
  onSeeSchemaSectionFields: (sectionId?: GridRowId) => void;
};

export const SchemaSections: React.FC<SchemaSectionsProps> = ({
  sections,
  schemaId,
  schemaName,
  loading,
  columnPlacement,
  onSchemaSectionAdd,
  onSchemaSectionStatusChange,
  onSchemaSectionUpdate,
  onSeeSchemaSectionFields,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser } = useContext(GlobalContext);
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<SchemaSectionStatus>[];

  const [rows, setRows] = useState<SchemaSection[]>(sortSections(sections));
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(sections)
  );
  const [showSections, setShowSections] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [SchemaSectionStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name" },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("AdminConsole.Products.labels.displayText"),
        key: "displayText",
      },
      {
        header: t("AdminConsole.Products.labels.displayOrder"),
        key: "displayOrder",
      },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
      { header: t("common.labels.createdBy"), key: "creator" },
    ];

    const rowsToExport = rows
      .filter((section) => (selectionModel || []).indexOf(section.id) >= 0)
      .map((section) => ({
        ...section,
        dateCreated: section.dateCreated ? new Date(section.dateCreated) : "",
        creator: getUserName(section.creator),
      }));

    exportToExcel(
      `${schemaName}-${t("AdminConsole.Products.labels.schemaSections")}`,
      columns,
      rowsToExport
    );
  };

  const handleSchemaSectionStatusChange = useCallback(
    (row: SchemaSection, newStatus: SchemaSectionStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        onSchemaSectionStatusChange(row, newStatus);
      }
    },
    [onSchemaSectionStatusChange]
  );

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onSchemaSectionStatusChange(
          sections.find((schemaSection) => schemaSection.id === rowId)!,
          SchemaSectionStatus.Removed
        );
      }
    },
    [onSchemaSectionStatusChange, sections]
  );

  const handleSwapColumns = useCallback(
    (rowId: GridRowId) => {
      const rowToUpdate = rows.find((row) => row.id === rowId)!;

      onSchemaSectionUpdate({
        id: rowToUpdate.id,
        name: rowToUpdate.name,
        displayText: rowToUpdate.displayText,
        displayOrder: rowToUpdate.displayOrder,
        columnPlacement:
          columnPlacement === ColumnPlacementType.Primary
            ? ColumnPlacementType.Secondary
            : ColumnPlacementType.Primary,
      });
    },
    [rows, columnPlacement, onSchemaSectionUpdate]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowChangesCommited = useCallback(
    (
      newRow: GridRowModel<SchemaSection>,
      oldRow: GridRowModel<SchemaSection>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (
          newRow.name &&
          newRow.displayText &&
          newRow.displayOrder !== undefined &&
          newRow.displayOrder !== null
        ) {
          onSchemaSectionAdd({
            name: newRow.name,
            displayText: newRow.displayText,
            displayOrder: newRow.displayOrder,
            columnPlacement,
          });
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "name",
            },
          }));
        }
      } else if (
        newRow.name !== oldRow.name ||
        newRow.displayText !== oldRow.displayText
      ) {
        onSchemaSectionUpdate({
          id: newRow.id,
          name: newRow.name,
          displayText: newRow.displayText,
          displayOrder: newRow.displayOrder,
        });
      }

      return newRow;
    },
    [onSchemaSectionAdd, onSchemaSectionUpdate, columnPlacement]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        name: "",
        displayOrder: rows.length + 1 || 1,
        id: temporaryRowId,
        productSchemaId: schemaId!,
        status: SchemaSectionStatus.Active,
        schemaFields: { items: [] },
        columnPlacement,
        isInternal: false,
        displayText: "",
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "name",
        },
      }));
    });
  };

  const handleRowOrderChange: GridEventListener<"rowOrderChange"> = (
    params
  ) => {
    const section = params.row;
    const newIndex = params.targetIndex + 1;

    if (newIndex !== section.displayOrder) {
      onSchemaSectionUpdate({
        id: section.id,
        name: section.name,
        displayText: section.displayText,
        displayOrder: newIndex,
      });
    }
  };

  const columns = useColumns({
    statusOptions,
    onStatusChange: handleSchemaSectionStatusChange,
    rowModesModel,
    onSaveRow: handleRowSaveClick,
    onDeleteRow: handleDeleteRow,
    onSwapColumns: handleSwapColumns,
    onSeeFields: onSeeSchemaSectionFields,
  });

  useEffect(() => {
    setRows(sortSections(sections));
    setRowModesModel(computeGridRowModes(sections));
  }, [sections]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Products.labels.schemaSections", {
          columnPlacement: t(
            columnPlacementTranslationKeyMapping[columnPlacement]
          ),
        })}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <TreeStructure
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowSections((state) => !state)}
        collapsed={!showSections}
      />
      <Collapse in={showSections}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: SchemaSection) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            onRowOrderChange={handleRowOrderChange}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, SchemaSection, any>
            ) => {
              return params.row.status === SchemaSectionStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
            rowReordering
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
