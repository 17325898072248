import { gql } from "@apollo/client";
import { companyLookupCollectionFields } from "graphql/fragments/companyLookupCollection.fragment";

export const companyLookupCollectionsQuery = gql`
  ${companyLookupCollectionFields}

  query companyLookupCollections($limit: Int!, $nextToken: String) {
    companyLookupCollections(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        ...CompanyLookupCollectionFields
      }
    }
  }
`;
