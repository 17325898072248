import { gql } from "@apollo/client";
import { dailyDiaryDelayRecordLiteFields } from "graphql/fragments/dailyDiaryDelayRecordLite.fragment";

export const editDailyDiaryDelayRecordMutation = gql`
  ${dailyDiaryDelayRecordLiteFields}

  mutation editDailyDiaryDelayRecord($input: EditDailyDiaryDelayRecordInput!) {
    editDailyDiaryDelayRecord(input: $input) {
      ...DailyDiaryDelayRecordLiteFields
    }
  }
`;
