import { Box, Button, Typography, useTheme } from "@mui/material";
import { DaysLate } from "containers/Projects/components/DaysLate";
import {
  ClaimHistoryItem,
  CompEventHistoryItem,
  ProductType,
  VariationActionType,
  VariationHistoryItem,
  VariationItem,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { HistoryModal } from "containers/Projects/components/HistoryModal/HistoryModal";
import { useCallback, useContext, useState } from "react";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CaretRight } from "phosphor-react";
import { VariationWidgetContext } from "../VariationWidget.context";
import { useVariationHistory } from "../hooks/useVariationHistory";
import { VariationHistoryItemDetails } from "../../VariationHistoryModal/VariationHistoryItemDetails";
import {
  VariationActionTypeAll,
  VariationActionTypeFIDIC17White,
  VariationActionTypeFIDIC99RedYellow,
  variationActionTypeFIDIC17WhiteTypeMapping,
  variationActionTypeFIDIC99RedYellowTypeMapping,
} from "../../Variations.types";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";

export type VariationWidgetLastStepSectionProps = {
  variation: VariationItem;
  previewMode?: boolean;
};

export const actionTypeToLastActionTextMapping: Record<
  VariationActionTypeAll,
  string
> = {
  [VariationActionType.None]: "Projects.Variations.LastAction.None",
  [VariationActionType.SubmitProposalForVariation]:
    "Projects.Variations.LastAction.SubmitProposalForVariation",
  [VariationActionType.SubmitProposalForProposedVariation]:
    "Projects.Variations.LastAction.SubmitProposalForProposedVariation",
  [VariationActionType.ConsentToProposalForProposedVariation]:
    "Projects.Variations.LastAction.ConsentToProposalForProposedVariation",
  [VariationActionTypeFIDIC99RedYellow.ConsentToProposalForProposedVariation99RY]:
    "Projects.Variations.LastAction.ConsentToProposalForProposedVariation99RY",
  [VariationActionType.GiveReasonsWhyProposalCannotBeGivenForProposedVariation]:
    "Projects.Variations.LastAction.GiveReasonsWhyProposalCannotBeGivenForProposedVariation",
  [VariationActionType.NotifyAgreementVariation]:
    "Projects.Variations.LastAction.NotifyAgreementVariation",
  [VariationActionType.NotifyAgreementVariationNotReached]:
    "Projects.Variations.LastAction.NotifyAgreementVariationNotReached",
  [VariationActionType.RequestRevisedProposalForProposedVariation]:
    "Projects.Variations.LastAction.RequestRevisedProposalForProposedVariation",
  [VariationActionType.NotifyDeterminationVariation]:
    "Projects.Variations.LastAction.NotifyDeterminationVariation",
  [VariationActionType.AbandonProposedVariation]:
    "Projects.Variations.LastAction.AbandonProposedVariation",
  [VariationActionTypeFIDIC99RedYellow.AbandonProposedVariation99RY]:
    "Projects.Variations.LastAction.AbandonProposedVariation99RY",
  [VariationActionType.RevokeInstructionOfVariation]:
    "Projects.Variations.LastAction.RevokeInstructionOfVariation",
  [VariationActionTypeFIDIC17White.RevokeInstructionOfVariationFIDIC17White]:
    "Projects.Variations.LastAction.RevokeInstructionOfVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.AbandonProposedVariationFIDIC17White]:
    "Projects.Variations.LastAction.AbandonProposedVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.ConsentToProposalForProposedVariationFIDIC17White]:
    "Projects.Variations.LastAction.ConsentToProposalForProposedVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.ConfirmInstructionOfVariationFIDIC17White]:
    "Projects.Variations.LastAction.ConfirmInstructionOfVariationFIDIC17White",
  [VariationActionType.ObjectToInstructionOfVariation]:
    "Projects.Variations.LastAction.ObjectToInstructionOfVariation",
  [VariationActionType.ConfirmInstructionOfVariation]:
    "Projects.Variations.LastAction.ConfirmInstructionOfVariation",
  [VariationActionType.InstructWorkToCommenceOnVariation]:
    "Projects.Variations.LastAction.InstructWorkToCommenceOnVariation",
  [VariationActionType.RequestFurtherParticulars]:
    "Projects.Variations.LastAction.RequestFurtherParticulars",
  [VariationActionType.ProvideFurtherParticulars]:
    "Projects.Variations.LastAction.ProvideFurtherParticulars",
  [VariationActionType.NotifyErrorFoundAgreement]:
    "Projects.Variations.LastAction.NotifyErrorFoundAgreement",
  [VariationActionType.DisagreeWithNoticeErrorAgreement]:
    "Projects.Variations.LastAction.DisagreeWithNoticeErrorAgreement",
  [VariationActionType.NotifyErrorFoundDetermination]:
    "Projects.Variations.LastAction.NotifyErrorFoundDetermination",
  [VariationActionType.DisagreeWithNoticeErrorDetermination]:
    "Projects.Variations.LastAction.DisagreeWithNoticeErrorDetermination",
} as any;

export const VariationWidgetLastStepSection: React.FC<
  VariationWidgetLastStepSectionProps
> = ({ variation, previewMode = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const { contract, isFIDIC17White, isFIDIC99RedYellow } = useContext(
    VariationWidgetContext
  );

  const { variationHistoryItems, loading: variationHistoryItemsLoading } =
    useVariationHistory(variation.id);

  const toggleHistoryModal = useCallback(
    () => setHistoryModalVisible((state) => !state),
    []
  );

  return (
    <>
      {historyModalVisible && (
        <HistoryModal
          open={historyModalVisible}
          title={variation.number}
          historyItems={variationHistoryItems}
          onClose={toggleHistoryModal}
          productType={ProductType.Variations}
          contract={contract}
          isFIDIC17White={isFIDIC17White}
          isFIDIC99RedYellow={isFIDIC99RedYellow}
          renderHistoryItemDetails={(
            historyItem:
              | ClaimHistoryItem
              | CompEventHistoryItem
              | VariationHistoryItem
          ) => (
            <VariationHistoryItemDetails
              historyItem={historyItem as VariationHistoryItem}
            />
          )}
        />
      )}
      <Box
        display="flex"
        flexDirection={previewMode ? "row" : "column"}
        alignItems={previewMode ? "center" : "flex-start"}
        width="100%"
      >
        <Box display="flex" flexDirection="column" flex={3}>
          <Typography variant="p2" fontWeight={600}>
            {t("Projects.Variations.lastAction")}
          </Typography>
          <Typography variant="p2" mt={0.5} data-testid="last-action">
            {t(
              actionTypeToLastActionTextMapping[
                isFIDIC17White
                  ? variationActionTypeFIDIC17WhiteTypeMapping(
                      variation.lastAction.actionType
                    )
                  : isFIDIC99RedYellow
                  ? variationActionTypeFIDIC99RedYellowTypeMapping(
                      variation.lastAction.actionType
                    )
                  : variation.lastAction.actionType
              ]
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          mt={previewMode ? 0 : 2}
          flex={1}
        >
          <Typography variant="p2" fontWeight={600}>
            {t("common.labels.dateSent")}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            mt={0.5}
            data-testid="date-sent"
          >
            <DateWithTimeTooltip
              timezone={contract.timeZone}
              datetime={variation.lastAction.date}
              variant="p2"
            />
            {variation.lastAction.daysLate ? (
              <DaysLate daysLate={variation.lastAction.daysLate} ml={1.5} />
            ) : null}
          </Box>
        </Box>
        {!previewMode && (
          <Box mt={3}>
            {variationHistoryItemsLoading ? (
              <CenteredLoadingIndicator size={20} />
            ) : (
              <Button
                variant="text"
                sx={{ padding: 0 }}
                onClick={toggleHistoryModal}
                data-testid="view-full-history-btn"
              >
                <Typography
                  variant="p2"
                  color="grey.800"
                  fontWeight={600}
                  mr={0.25}
                >
                  {t("Projects.Variations.viewFullHistory", {
                    itemsNo: variationHistoryItems.length,
                  })}
                </Typography>
                <CaretRight color={theme.palette.grey[800]} size={18} />
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
