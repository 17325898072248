import { SeverityPreviewExtraSmall } from "./SeverityPreviewExtraSmall";
import { SeverityPreviewMedium } from "./SeverityPreviewMedium";
import { SeverityPreviewLarge } from "./SeverityPreviewLarge";
import { SeverityReadOnlyProps } from "./Severity.decl";
import { SeverityPreviewSmall } from "./SeverityPreviewSmall";

export type SeverityPreviewProps = {
  size?: "xSmall" | "small" | "medium" | "large";
} & SeverityReadOnlyProps;

export const SeverityPreview: React.FC<SeverityPreviewProps> = ({
  size = "small",
  ...restProps
}) => {
  switch (size) {
    case "large":
      return <SeverityPreviewLarge {...restProps} />;
    case "medium":
      return <SeverityPreviewMedium {...restProps} />;
    case "xSmall":
      return <SeverityPreviewExtraSmall {...restProps} />;
    case "small":
    default:
      return <SeverityPreviewSmall {...restProps} />;
  }
};
