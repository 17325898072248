import { gql } from "@apollo/client";
import {
  nextStepsClaimItemFields,
  nextStepsCompEventItemFields,
  nextStepsVariationItemFields,
} from "./NextSteps.fragments";

export const productItemNextStepsAllQuery = gql`
  ${nextStepsCompEventItemFields}
  ${nextStepsVariationItemFields}
  ${nextStepsClaimItemFields}

  query productItemNextStepsAll($limit: Int!, $nextToken: String) {
    productItemNextStepsAll(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        ... on CompEventItem {
          ...NextStepsCompEventItemFields
        }
        ... on VariationItem {
          ...NextStepsVariationItemFields
        }
        ... on ClaimItem {
          ...NextStepsClaimItemFields
        }
      }
    }
  }
`;

export const productItemNextStepsYourPartyQuery = gql`
  ${nextStepsCompEventItemFields}
  ${nextStepsVariationItemFields}
  ${nextStepsClaimItemFields}

  query productItemNextStepsYourParty($limit: Int!, $nextToken: String) {
    productItemNextStepsYourParty(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        ... on CompEventItem {
          ...NextStepsCompEventItemFields
        }
        ... on VariationItem {
          ...NextStepsVariationItemFields
        }
        ... on ClaimItem {
          ...NextStepsClaimItemFields
        }
      }
    }
  }
`;

export const productItemNextStepsOtherPartiesQuery = gql`
  ${nextStepsCompEventItemFields}
  ${nextStepsVariationItemFields}
  ${nextStepsClaimItemFields}

  query productItemNextStepsOtherParties($limit: Int!, $nextToken: String) {
    productItemNextStepsOtherParties(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        ... on CompEventItem {
          ...NextStepsCompEventItemFields
        }
        ... on VariationItem {
          ...NextStepsVariationItemFields
        }
        ... on ClaimItem {
          ...NextStepsClaimItemFields
        }
      }
    }
  }
`;

export const productItemNextStepsDeemingQuery = gql`
  ${nextStepsCompEventItemFields}
  ${nextStepsVariationItemFields}
  ${nextStepsClaimItemFields}

  query productItemNextStepsDeeming($limit: Int!, $nextToken: String) {
    productItemNextStepsDeeming(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        ... on CompEventItem {
          ...NextStepsCompEventItemFields
        }
        ... on VariationItem {
          ...NextStepsVariationItemFields
        }
        ... on ClaimItem {
          ...NextStepsClaimItemFields
        }
      }
    }
  }
`;
