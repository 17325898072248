import {
  Box,
  capitalize,
  css,
  styled,
  Typography,
  Popover,
  useTheme,
} from "@mui/material";
import { KeyboardArrowDownIcon } from "components/Icons/KeyboardArrowDownIcon";
import { KeyboardArrowUpIcon } from "components/Icons/KeyboardArrowUpIcon";
import { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExplorerEntity } from "../Explorer.decl";
import { Flyout } from "./Flyout";

export type EntityExplorerProps<T extends ExplorerEntity> = {
  items: T[];
  entityType: string;
  selectedEntityId?: string;
  rightListHeader?: string;
  flyoutPlacement?: "left" | "right";
  loading?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  onSelectionChange: (item: T) => void;
};

const EntityExplorerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "disabled",
})<{
  active?: boolean;
  disabled?: boolean;
}>(
  ({ theme, active, disabled }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    gap: 8px;
    box-sizing: border-box;
    cursor: ${disabled ? "default" : "pointer"};
    height: 66px;
    min-width: 290px;
    width: 100%;
    background: ${theme.palette.common.white};
    border: 1px solid ${theme.palette.grey[300]};
    border-radius: 12px;

    ${active && `border-color: ${(theme.palette.primary as any)[500]};`}
  `
);

export const EntityExplorer = <T extends ExplorerEntity>({
  items,
  entityType,
  selectedEntityId,
  rightListHeader,
  flyoutPlacement = "left",
  dataTestId,
  loading,
  disabled,
  onSelectionChange,
}: EntityExplorerProps<T>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const selectedEntity = selectedEntityId
    ? items.find((item) => item.id === selectedEntityId)
    : null;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event?: Event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleSelectionChange = (selectedItem: T) => {
    handleClose();
    onSelectionChange(selectedItem);
  };

  const open = Boolean(anchorEl);
  const id = open ? "explorer-popover" : undefined;

  return (
    <EntityExplorerContainer
      aria-describedby={id}
      onClick={disabled ? undefined : handleClick}
      active={open}
      disabled={disabled}
      data-testid={dataTestId}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="95%"
      >
        {loading && !open /* && selectedEntityId */ ? (
          <Typography variant="p3" color={theme.palette.grey[600]}>
            {t("common.labels.suspenseLoading")}
          </Typography>
        ) : (
          <>
            <Typography
              variant="p3"
              color={
                disabled
                  ? theme.palette.action.disabled
                  : theme.palette.grey[600]
              }
            >
              {entityType}
            </Typography>
            {selectedEntity?.listItemRightText ? (
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography
                  variant="p1"
                  color={theme.palette.grey[800]}
                  fontWeight={600}
                >
                  {selectedEntity?.listItemLeftText}
                </Typography>
                <Typography
                  variant="p1"
                  color={theme.palette.grey[800]}
                  fontWeight={600}
                >
                  {selectedEntity?.listItemRightText}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="p1"
                color={
                  disabled
                    ? theme.palette.action.disabled
                    : theme.palette.grey[800]
                }
                fontWeight={600}
              >
                {selectedEntity?.listItemLeftText ||
                  t("common.labels.selectEntity", {
                    entity: capitalize(entityType),
                  })}
              </Typography>
            )}
          </>
        )}
      </Box>
      {anchorEl ? (
        <KeyboardArrowUpIcon
          color={disabled ? theme.palette.action.disabled : undefined}
        />
      ) : (
        <KeyboardArrowDownIcon
          color={disabled ? theme.palette.action.disabled : undefined}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(event: Event) => handleClose(event)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: flyoutPlacement,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: flyoutPlacement,
        }}
      >
        <Flyout
          items={items}
          onSelectionChange={handleSelectionChange}
          leftHeader={entityType}
          rightHeader={rightListHeader}
          selectedEntityId={selectedEntityId}
          loading={loading}
        />
      </Popover>
    </EntityExplorerContainer>
  );
};
