import { gql } from "@apollo/client";
import { draftAssessmentFields } from "graphql/fragments/draftAssessment.fragment";

export const editDraftAssessmentMutation = gql`
  ${draftAssessmentFields}

  mutation editDraftAssessment($input: EditDraftAssessmentInput!) {
    editDraftAssessment(input: $input) {
      ...DraftAssessmentFields
    }
  }
`;
