import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const userLiteFields = gql`
  ${creatorFields}
  fragment UserLiteFields on User {
    id
    firstname
    surname
    jobTitle
    country
    mobileNumber
    alternateNumber
    dateOfBirth
    profilePicture
    email
    companyId
    company {
      id
      tradingName
      registeredName
    }
    registered
    status
    lastActive
    dateInvited
    registeredDate
    invitedBy {
      ...CreatorFields
    }
    # roles: ProductUserRoleList!
  }
`;
