import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  GenerateRecordClaimErrorAgreementDisagreementPreviewMutation,
  GenerateRecordClaimErrorAgreementDisagreementPreviewMutationVariables,
  RecordClaimErrorAgreementDisagreementPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { generateRecordClaimErrorAgreementDisagreementPreviewMutation } from "./DisagreeWithNoticeOfErrorInAgreement.query";

export const useRecordDisagreementRegardingNoticeOfErrorInAgreementModal = (
  remarks: string
) => {
  const { claim, contract } = useContext(ClaimWidgetContext);

  const [claimPreview, setClaimPreview] =
    useState<RecordClaimErrorAgreementDisagreementPreview>();

  const [generateClaimPreview] = useGraphMutation<
    GenerateRecordClaimErrorAgreementDisagreementPreviewMutation,
    GenerateRecordClaimErrorAgreementDisagreementPreviewMutationVariables
  >(generateRecordClaimErrorAgreementDisagreementPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            remarks,
          },
        },
      });

      if (data) {
        setClaimPreview(
          data.generateRecordClaimErrorAgreementDisagreementPreview
        );
      }
    }, [generateClaimPreview, claim, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    claimPreview,
    contract,
  };
};
