import React from "react";
import { RichTextArea } from "../../../../components/RichTextArea/RichTextArea";
import { css, styled } from "@mui/material";

const StyledRichTextarea = styled(RichTextArea)(
  ({ theme }) => css`
    && {
      font-size: 12px;
      border-radius: 8px;
      padding: ${theme.spacing(1)};
    }
  `
);

export const ChangelogRichTextarea: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <StyledRichTextarea
    content={window.decodeURIComponent(children as string)}
    readOnly
  />
);
