import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { schemaSectionFields } from "./schemaSection.fragment";

export const productSchemaFields = gql`
  ${creatorFields}
  ${schemaSectionFields}

  fragment ProductSchemaFields on ProductSchema {
    id
    name
    isDefault
    productId
    isInternal
    status
    creatorId
    creator {
      ...CreatorFields
    }
    dateCreated
    schemaSections {
      items {
        ...SchemaSectionFields
      }
    }
  }
`;
