import { IntercomEvents } from "constants/intercom";
import { getMetadataSet1 } from "containers/Projects/utils/intercom";
import { EventItem, ProductType } from "generated/graphql";
import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";

export const useEventDetailsTrackingEvents = () => {
  const { trackEvent } = useIntercom();

  const trackEventDetailsEvent = useCallback(
    (trackingEvent: IntercomEvents, event: EventItem, extraData?: object) => {
      trackEvent(trackingEvent, {
        ...getMetadataSet1({
          productInstanceId: event.productInstance.id,
          productInstanceItemId: event.id,
          productInstanceItemName: event.title,
          contractFn: event.productInstance.contract.friendlyName,
          projectFn: event.productInstance.contract.project.friendlyName,
          productType: ProductType.Events,
          version: process.env.REACT_APP_VERSION!,
        }),
        ...extraData,
      });
    },
    [trackEvent]
  );

  return {
    trackEventDetailsEvent,
  };
};
