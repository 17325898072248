import {
  ExtendedOptionStyles,
  StatusOptionNew,
} from "components/StatusTag/StatusTagNew";
import { DailyDiaryItemStatus } from "generated/graphql";

export const dailyDiaryStatusOptions: StatusOptionNew<string>[] = [
  {
    id: DailyDiaryItemStatus.Draft,
    label: DailyDiaryItemStatus.Draft,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: DailyDiaryItemStatus.LockedDraft,
    label: DailyDiaryItemStatus.LockedDraft,
    style: ExtendedOptionStyles.Purple,
  },
  {
    id: DailyDiaryItemStatus.Sent,
    label: DailyDiaryItemStatus.Sent,
    style: ExtendedOptionStyles.Amber,
  },
  {
    id: DailyDiaryItemStatus.Rejected,
    label: DailyDiaryItemStatus.Rejected,
    style: ExtendedOptionStyles.Red,
  },
  {
    id: DailyDiaryItemStatus.Approved,
    label: DailyDiaryItemStatus.Approved,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: DailyDiaryItemStatus.Waived,
    label: DailyDiaryItemStatus.Waived,
    style: ExtendedOptionStyles.Grey,
  },
];

export enum DailyDiaryExtraTab {
  SentByMe = "SentByMe",
  RequiresMyAttention = "RequiresMyAttention",
  ReviewedByMe = "ReviewedByMe",
  SubmittedByMe = "SubmittedByMe",
}
