import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryResourceRecordInput,
  CompanyLookupCollection,
  DailyDiaryPresetSection,
  DailyDiaryResourceRecord,
  EditDailyDiaryResourceRecordInput,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Textarea } from "components/TextArea";
import { resourceRecordToEditInput } from "../ManpowerSection.utils";
import moment from "moment";
import { dailyDiaryTimeFormat } from "../../../DailyDiary.constants";
import { defaultFormData } from "./ResourceRecordForm.constants";
import { datetimeToHoursAndMinutes } from "./ResourceRecordForm.utils";
import { TimePicker } from "components/TimePicker";
import { FormErrorLabel } from "components/FormErrorLabel";
import { addDefaultMinutes00 } from "../../../utilts";
import { useResourceRecordFormValidators } from "./useResourceRecordFormValidators";

export type ResourceFormDataType = EditDailyDiaryResourceRecordInput &
  Pick<DailyDiaryResourceRecord, "totalHours">;

export type ResourceRecordFormProps = {
  resourceRecord?: DailyDiaryResourceRecord;
  resourceLookups: CompanyLookupCollection[];
  section: DailyDiaryPresetSection;
  apiRef?: React.Ref<FormPublicApi>;
  onChange: (
    resourceRecord:
      | AddDailyDiaryResourceRecordInput
      | EditDailyDiaryResourceRecordInput
  ) => void;
};

export const ResourceRecordForm: React.FC<ResourceRecordFormProps> = ({
  resourceRecord,
  section,
  resourceLookups,
  apiRef,
  onChange,
}) => {
  const { t } = useTranslation();

  const firstFieldRef = useRef<any>(null);

  const [formData, setFormData] = useState<ResourceFormDataType>(
    resourceRecord ? resourceRecordToEditInput(resourceRecord) : defaultFormData
  );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleResourceChange = (
    lkpKey: keyof EditDailyDiaryResourceRecordInput,
    event: SelectChangeEvent<string | null>
  ) => {
    setFormData((curData) => ({
      ...curData,
      [lkpKey]: event.target.value ?? "",
    }));

    setFormDataErrors((crtFormDataErrs) => {
      const { [lkpKey]: _, ...rest } = crtFormDataErrs;

      return rest;
    });
  };

  const resourceLookupOptions = useMemo(
    () =>
      resourceLookups.find(
        (lkp) =>
          lkp.id ===
          section.fields.find((field) => field.name === "Resource")!.lookupId
      )!.options.items,
    [resourceLookups, section]
  );

  const shiftLookupOptions = useMemo(
    () =>
      resourceLookups.find(
        (lkp) =>
          lkp.id ===
          section.fields.find((field) => field.name === "Shift")?.lookupId
      )?.options.items,
    [resourceLookups, section]
  );

  const companyLookupOptions = useMemo(
    () =>
      resourceLookups.find(
        (lkp) =>
          lkp.id ===
          section.fields.find((field) => field.name === "Company")?.lookupId
      )?.options.items,
    [resourceLookups, section]
  );

  const teamLookupOptions = useMemo(
    () =>
      resourceLookups.find(
        (lkp) =>
          lkp.id ===
          section.fields.find((field) => field.name === "Team")?.lookupId
      )?.options.items,
    [resourceLookups, section]
  );

  const dataValidators = useResourceRecordFormValidators(
    section,
    formData,
    resourceLookupOptions,
    shiftLookupOptions,
    companyLookupOptions,
    teamLookupOptions
  );

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
    firstFieldRef.current.focus();
  }, []);

  const validateForm = useCallback(
    (
      formData:
        | AddDailyDiaryResourceRecordInput
        | EditDailyDiaryResourceRecordInput
    ) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [dataValidators]
  );

  const handleHoursValueChange = (value: any) => {
    setFormData((crtFormData) => ({
      ...crtFormData,
      hours: value,
      totalHours: datetimeToHoursAndMinutes(value, crtFormData.quantity || 1),
    }));

    setFormDataErrors((crtFormDataErrs) => {
      const { hours: _, ...rest } = crtFormDataErrs;

      return rest;
    });
  };

  const handleQtyChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((crtData) => {
      const newQty = Number(evt.target.value);

      return {
        ...crtData,
        quantity: newQty,
        totalHours: datetimeToHoursAndMinutes(crtData.hours, newQty),
      };
    });

    setFormDataErrors((curFormDataErrs) => {
      const { quantity: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setFormData((curData) => ({
      ...curData,
      remarks: event.target.value,
    }));
  };

  useEffect(() => {
    const {
      totalHours: _,
      companyOptionId,
      teamOptionId,
      shiftOptionId,
      ...restData
    } = formData;

    onChange({
      ...restData,
      ...(companyOptionId ? { companyOptionId } : {}),
      ...(teamOptionId ? { teamOptionId } : {}),
      ...(shiftOptionId ? { shiftOptionId } : {}),
      ...(restData.hours
        ? {
            hours: moment(restData.hours).format(dailyDiaryTimeFormat),
          }
        : {}),
      ...(restData.quantity
        ? {
            quantity: Number(restData.quantity),
          }
        : {}),
    });
  }, [onChange, formData]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [resetForm, formData, validateForm]
  );

  useEffect(() => {
    firstFieldRef.current.focus();
  }, []);

  useEffect(() => {
    if (resourceRecord) {
      validateForm(formData);
    }
  }, [validateForm, formData, resourceRecord]);

  const handleHoursInputChange = (evt: InputEvent) => {
    addDefaultMinutes00(evt, !formData.hours, handleHoursValueChange);
  };

  const withShiftColumn = useMemo(() => {
    const shiftCol = section.fields.find((field) => field.name === "Shift");
    return shiftCol && !shiftCol.isHidden;
  }, [section]);

  const withCompanyCol = useMemo(() => {
    const companyCol = section.fields.find((field) => field.name === "Company");
    return companyCol && !companyCol.isHidden;
  }, [section]);

  const withTeamCol = useMemo(() => {
    const teamCol = section.fields.find((field) => field.name === "Team");
    return teamCol && !teamCol.isHidden;
  }, [section]);

  const isResourceRequired = section.fields.find(
    (field) => field.name === "Resource"
  )?.isRequired;
  const isShiftRequired = section.fields.find(
    (field) => field.name === "Shift"
  )?.isRequired;
  const isQtyRequired = section.fields.find(
    (field) => field.name === "Qty"
  )?.isRequired;
  const isHoursRequired = section.fields.find(
    (field) => field.name === "Hours"
  )?.isRequired;
  const isRemarksRequired = section.fields.find(
    (field) => field.name === "Remarks"
  )?.isRequired;
  const isCompanyRequired = section.fields.find(
    (field) => field.name === "Company"
  )?.isRequired;
  const isTeamRequired = section.fields.find(
    (field) => field.name === "Team"
  )?.isRequired;

  return (
    <Grid container spacing={5}>
      <Grid item sm={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.Resource.resource")}
            required={isResourceRequired}
          />
          <Select
            labelId="resource-select-label"
            id="resource-select"
            value={formData.resourceOptionId}
            onChange={(evt) => handleResourceChange("resourceOptionId", evt)}
            variant="outlined"
            error={!!formDataErrors.resourceOptionId}
            size="small"
            inputRef={firstFieldRef}
            required={isResourceRequired}
          >
            {resourceLookupOptions.map((lkpOption) => (
              <MenuItem key={lkpOption.id} value={lkpOption.id}>
                {lkpOption.value}
              </MenuItem>
            ))}
          </Select>
          {!!formDataErrors.resourceOptionId && (
            <FormErrorLabel
              dataTestId="resourceOptionId-error-msg"
              errorMessage={formDataErrors.resourceOptionId}
            />
          )}
        </FormControl>
      </Grid>
      {withShiftColumn && (
        <Grid item sm={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Resource.shift")}
              required={isShiftRequired}
            />
            <Select
              labelId="shift-select-label"
              id="shift-select"
              value={formData.shiftOptionId}
              required={isShiftRequired}
              onChange={(evt) => handleResourceChange("shiftOptionId", evt)}
              variant="outlined"
              error={!!formDataErrors.shiftOptionId}
              size="small"
            >
              {shiftLookupOptions?.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.shiftOptionId && (
              <FormErrorLabel
                dataTestId="shiftOptionId-error-msg"
                errorMessage={formDataErrors.shiftOptionId}
              />
            )}
          </FormControl>
        </Grid>
      )}
      {withCompanyCol && (
        <Grid item sm={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Resource.company")}
              required={isCompanyRequired}
            />
            <Select
              labelId="company-select-label"
              id="company-select"
              value={formData.companyOptionId}
              required={isCompanyRequired}
              error={!!formDataErrors.companyOptionId}
              onChange={(evt) => handleResourceChange("companyOptionId", evt)}
              variant="outlined"
              size="small"
            >
              {companyLookupOptions?.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.companyOptionId && (
              <FormErrorLabel
                dataTestId="companyOptionId-error-msg"
                errorMessage={formDataErrors.companyOptionId}
              />
            )}
          </FormControl>
        </Grid>
      )}
      <Grid item sm={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Resource.qty")}
          required={isQtyRequired}
        />
        <TextField
          fullWidth
          name="quantity"
          value={formData.quantity}
          onChange={handleQtyChange}
          type="number"
          variant="outlined"
          size="small"
          required={isQtyRequired}
          InputProps={{ inputProps: { min: 0 } }}
          error={!!formDataErrors.quantity}
        />
        {formDataErrors.quantity && (
          <FormErrorLabel
            dataTestId="quantity-error-msg"
            errorMessage={formDataErrors.quantity}
          />
        )}
      </Grid>
      <Grid item sm={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Resource.hours")}
          required={isHoursRequired}
        />
        <TimePicker
          label="hh:mm"
          value={formData.hours}
          ampm={false}
          onChange={handleHoursValueChange}
          error={!!formDataErrors.hours}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
              onInput: handleHoursInputChange,
            },
          }}
        />
        {!!formDataErrors.hours && (
          <FormErrorLabel
            dataTestId="hours-error-msg"
            errorMessage={formDataErrors.hours}
          />
        )}
      </Grid>
      <Grid item sm={6}>
        <FormLabel label={t("Projects.DailyDiaries.Resource.totalHours")} />
        <TextField
          fullWidth
          name="totalHours"
          value={formData.totalHours}
          type="text"
          variant="outlined"
          size="small"
          disabled
        />
      </Grid>
      <Grid item sm={12}>
        <FormLabel
          label={t("Projects.DailyDiaries.Resource.remarks")}
          required={isRemarksRequired}
        />
        <Textarea
          value={formData.remarks ?? ""}
          required={isRemarksRequired}
          onChange={handleTextareaChange}
        />
      </Grid>
      {withTeamCol && (
        <Grid item sm={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Resource.team")}
              required={isTeamRequired}
            />
            <Select
              labelId="team-select-label"
              id="team-select"
              value={formData.teamOptionId}
              onChange={(evt) => handleResourceChange("teamOptionId", evt)}
              variant="outlined"
              required={isTeamRequired}
              size="small"
            >
              {teamLookupOptions?.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
