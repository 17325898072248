import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { CompanyStatus } from "generated/graphql";
import {
  PageContentHeader,
  PageContentHeaderSize,
} from "../../../../../../../components/PageContentHeader/PageContentHeader";
import { HeaderLoadingContainer } from "../../../components/HeaderLoadingContainer";
import {
  StatusTag,
  StatusOption,
} from "../../../../../../../components/StatusTag/StatusTag";
import { useActiveRemovedSuspendedStatusOptions } from "../../../../../../../components/StatusTag/useActiveRemovedSuspendedOptions";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useCallback, useImperativeHandle, useRef } from "react";
import { HeaderPublicApi } from "components/NewEntityHeader";
import { useTranslation } from "react-i18next";

export type CompanyDetailsHeaderProps = {
  name?: string;
  status?: CompanyStatus;
  loading?: boolean;
  apiRef?: React.Ref<HeaderPublicApi>;
  onStatusChange: (newStatus: CompanyStatus) => void;
};

export const CompanyDetailsHeader: React.FC<CompanyDetailsHeaderProps> = ({
  name,
  status,
  loading,
  apiRef,
  onStatusChange,
}) => {
  const companyStatusOptions =
    useActiveRemovedSuspendedStatusOptions() as StatusOption<CompanyStatus>[];
  const handleNavigateBack = useNavigateBack();
  const { t } = useTranslation();

  const handleStatusChange = (newStatus: StatusOption<CompanyStatus>) => {
    onStatusChange(newStatus.id as CompanyStatus);
  };

  const updateBtnRef = useRef<HTMLButtonElement>(null);

  const focusMainActionBtn = useCallback(() => {
    updateBtnRef.current?.focus();
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      focusMainActionBtn,
    }),
    [focusMainActionBtn]
  );

  return (
    <PageContentHeader size={PageContentHeaderSize.Large}>
      {loading && (
        <HeaderLoadingContainer>
          <CircularProgress />
        </HeaderLoadingContainer>
      )}
      <Box display="flex" alignItems="center">
        <ArrowBackIcon
          onClick={handleNavigateBack}
          style={{ cursor: "pointer" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          ml={3}
          mr={1}
        >
          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: "14px" }} width={150} />
          ) : (
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link key="1" onClick={handleNavigateBack}>
                <Typography variant="body2">
                  {t("AdminConsole.Companies.labels.companies")}
                </Typography>
              </Link>
            </Breadcrumbs>
          )}
          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: "24px" }} width={150} />
          ) : (
            <Typography variant="h5">{name}</Typography>
          )}
        </Box>
        {status && (
          <Box alignSelf="flex-end">
            <StatusTag
              status={status}
              onChange={handleStatusChange}
              options={companyStatusOptions}
            />
          </Box>
        )}
      </Box>
    </PageContentHeader>
  );
};
