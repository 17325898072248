import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import {
  RecordCompEventConfirmationMutation,
  RecordCompEventConfirmationMutationVariables,
  SendCompEventConfirmationMutation,
  SendCompEventConfirmationMutationVariables,
} from "generated/graphql";
import { recordCompEventConfirmationMutation } from "graphql/mutations/recordCompEventConfirmation";
import { sendCompEventConfirmationMutation } from "graphql/mutations/sendCompEventConfirmation";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const useConfirmCEAction = (triggersAuthWorkflow?: boolean) => {
  const { onChange, compEvent } = useContext(CompEventWidgetContext);
  const { t } = useTranslation();

  const [
    sendCompEventConfirmation,
    { loading: sendCompEventConfirmationLoading },
  ] = useGraphMutation<
    SendCompEventConfirmationMutation,
    SendCompEventConfirmationMutationVariables
  >(
    sendCompEventConfirmationMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t("Projects.CompEvents.compEventConfirmation"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordCompEventConfirmation,
    { loading: recordCompEventConfirmationLoading },
  ] = useGraphMutation<
    RecordCompEventConfirmationMutation,
    RecordCompEventConfirmationMutationVariables
  >(
    recordCompEventConfirmationMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.CompEvents.compEventConfirmation"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  return {
    actionLoading:
      sendCompEventConfirmationLoading || recordCompEventConfirmationLoading,
    sendCompEventConfirmation,
    recordCompEventConfirmation,
  };
};
