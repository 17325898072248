import { Box, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { WarningIcon } from "components/Icons/WarningIcon";
import { useTranslation } from "react-i18next";

export type DeleteConfirmationModalProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
} & ActionsDialogProps;

export const DeleteConfirmationModal: React.FC<
  DeleteConfirmationModalProps
> = ({ title, subtitle, ...restDialogProps }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ActionsDialog
      iconsHeader={<WarningIcon />}
      title={
        <Box display="flex" flexDirection="column">
          <>
            {typeof title === "string" ? (
              <Typography
                variant="h3"
                fontWeight={600}
                color={theme.palette.grey[800]}
                mb={1}
              >
                {title}
              </Typography>
            ) : (
              title
            )}

            {subtitle && typeof subtitle === "string" ? (
              <Typography variant="p1" color={theme.palette.grey[700]} mb={2}>
                {subtitle}
              </Typography>
            ) : (
              { subtitle }
            )}
          </>
        </Box>
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      secondaryBtnCaption={t("common.buttons.cancel")}
      primaryBtnCaption={t("common.buttons.delete")}
      {...restDialogProps}
    />
  );
};
