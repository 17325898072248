import { Box, Collapse } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { ContractsIcon } from "components/Icons/ContractsIcon";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useContractStatusOptions } from "components/StatusTag/useContractStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import { Contract, ContractStatus } from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { NewAppPaths } from "helpers/paths/paths";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useColumns } from "./ProjectContracts.constants";
import { getUserName, openLocallyOrInNewTab } from "helpers/miscelaneous";
import { excelDateTimeISOFormat } from "constants/constants";

export type ProjectContractsProps = {
  contracts: Contract[];
  projectName?: string;
  loading?: boolean;
  onContractStatusChange: (row: Contract, newStatus: ContractStatus) => void;
};

export const ProjectContracts: React.FC<ProjectContractsProps> = ({
  contracts,
  projectName,
  loading,
  onContractStatusChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const contractStatusOptions =
    useContractStatusOptions() as StatusOption<ContractStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [showContracts, setShowContracts] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "friendlyName", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [
          ContractStatus.Active,
          ContractStatus.Offline,
          ContractStatus.Closed,
        ],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("common.labels.friendlyName"),
        key: "friendlyName",
      },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("common.labels.number"),
        key: "number",
      },
      { header: t("common.labels.type"), key: "type" },
      {
        header: t("common.labels.startDate"),
        key: "startDate",
      },
      {
        header: t("AdminConsole.Contracts.labels.province"),
        key: "province",
      },
      {
        header: t("common.labels.country"),
        key: "country",
      },
      {
        header: t("common.labels.timezone"),
        key: "timeZone",
      },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.createdBy"),
        key: "createdBy",
      },
    ];
    const rows = contracts
      .filter((contract) => (selectionModel || []).indexOf(contract.id) >= 0)
      .map((contract) => ({
        ...contract,
        type: `${contract.contractType.description} ${
          contract.contractType.version ?? ""
        } ${contract.contractType.subType ?? ""}`,
        startDate: contract.startDate ? new Date(contract.startDate) : "",
        dateCreated: contract.dateCreated ? new Date(contract.dateCreated) : "",
        createdBy: getUserName(contract.creator),
      }));

    exportToExcel(
      `${projectName}-${t("AdminConsole.Contracts.labels.contracts")}`,
      columns,
      rows
    );
  };

  const handleNewContract = useCallback(() => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.Projects.children.ProjectDetails.children.NewContract.pathConstructor(
        projectId!
      )
    );
  }, [navigate, projectId]);

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (rowData: GridRowParams<Contract>, event) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        const url =
          NewAppPaths.authorized.AdminConsole.children.Projects.children.ContractDetails.pathConstructor(
            projectId!,
            rowData.row.id
          );
        openLocallyOrInNewTab(url, event, navigate);
      }
    },
    [navigate, projectId]
  );

  const columns = useColumns(contractStatusOptions, onContractStatusChange);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Contracts.labels.contracts")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t("common.labels.contract").toLowerCase(),
        })}
        actionBtnDataTestId="new-contract-btn"
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        onActionButtonClick={handleNewContract}
        icon={<ContractsIcon />}
        onToggleCollapse={() => setShowContracts((state) => !state)}
        collapsed={!showContracts}
      />
      <Collapse in={showContracts}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={contracts}
            columns={columns}
            getRowId={(rowData: Contract) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            onRowClick={handleRowClick}
            getCellClassName={(params: GridCellParams<any, Contract, any>) => {
              return params.row.status === ContractStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
          />
        </Box>
      </Collapse>
    </Box>
  );
};
