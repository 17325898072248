import { gql } from "@apollo/client";
import { attachmentLiteFields } from "./attachmentLite.fragment";
import { contractBindingTypeFields } from "./contractBindingType.fragment";
import { creatorFields } from "./creator.fragment";
import { authorizationWorkflowAuditFields } from "./authorizationWorkflowAudit.fragment";
import { claimTypeInfoFields } from "./claimTypeInfo.fragment";
import { detailedClaimLiteFields } from "./detailedClaimLite.fragment";
import { earlyWarningItemLiteFields } from "./earlyWarningItemLite.fragment";
import { claimAgreementLiteFields } from "./claimAgreementLite.fragment";
import { claimDeterminationLiteFields } from "./claimDeterminationLite.fragment";
import { draftClaimAgreementFields } from "./draftClaimAgreement.fragment";
import { draftClaimDeterminationFields } from "./draftClaimDetermination.fragment";

export const claimHistoryItemFields = gql`
  ${creatorFields}
  ${contractBindingTypeFields}
  ${attachmentLiteFields}
  ${claimTypeInfoFields}
  ${authorizationWorkflowAuditFields}
  ${detailedClaimLiteFields}
  ${earlyWarningItemLiteFields}
  ${claimAgreementLiteFields}
  ${draftClaimAgreementFields}
  ${draftClaimDeterminationFields}
  ${claimDeterminationLiteFields}

  fragment ClaimHistoryItemFields on ClaimHistoryItem {
    id
    claimId
    actionType
    actionNumber
    answers {
      __typename
      ... on ClaimHistoryNoticeAnswers {
        claimTypeId
        advanceWarningId
        claimType {
          ...ClaimTypeInfoFields
        }
        advanceWarning {
          ...EarlyWarningItemLiteFields
        }
      }
      ... on ClaimHistoryInterimDetailsAnswers {
        detailedClaimId
        detailedClaim {
          ...DetailedClaimLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }
      ... on ClaimHistoryFinalDetailsAnswers {
        detailedClaimId
        detailedClaim {
          ...DetailedClaimLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }
      ... on ClaimHistoryAgreementAnswers {
        agreementId
        agreement {
          ...ClaimAgreementLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
        draftAgreementId
        draftAgreement {
          ...DraftClaimAgreementFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }
      ... on ClaimHistoryNoAgreementReachedAnswers {
        remarks
      }
      ... on ClaimHistoryDeterminationAnswers {
        determinationId
        determination {
          ...ClaimDeterminationLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
        draftDeterminationId
        draftDetermination {
          ...DraftClaimDeterminationFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }
      ... on ClaimHistoryFurtherParticularsRequestAnswers {
        reasons
      }
      ... on ClaimHistoryFurtherParticularsSubmissionAnswers {
        details
      }
      ... on ClaimHistoryNoticeInvalidNoticeAnswers {
        reasons
      }
      ... on ClaimHistoryNoticeValidityLapsedAnswers {
        remarks
      }
      ... on ClaimHistoryClaimDetailsResponseAnswers {
        details
        detailedClaimId
        detailedClaim {
          ...DetailedClaimLiteFields
        }
      }
      ... on ClaimHistoryErrorAgreementNoticeAnswers {
        details
      }
      ... on ClaimHistoryErrorDeterminationNoticeAnswers {
        details
      }
      ... on ClaimHistoryErrorAgreementDisagreementAnswers {
        remarks
      }
      ... on ClaimHistoryErrorDeterminationDisagreementAnswers {
        remarks
      }
    }
    attachments {
      ...AttachmentLiteFields
    }
    recipients {
      userId
      name
      preference {
        inApp
        email
      }
    }
    dateCreated
    dateSent
    dueDate
    sentByUserId
    sentByPartyId
    offline
    daysLate
    isIncomplete
    authorizationWorkflowAudit {
      ...AuthorizationWorkflowAuditFields
    }
    sentByUser {
      ...CreatorFields
    }
    sentByParty {
      ...ContractBindingTypeFields
    }
  }
`;
