import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";
import { dailyDiaryResourceRecordLiteFields } from "graphql/fragments/dailyDiaryResourceRecordLite.fragment";

export const dailyDiaryEquipmentRecordsQuery = gql`
  ${dailyDiaryResourceRecordLiteFields}
  ${attachmentFields}

  query dailyDiaryEquipmentRecords($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryEquipmentRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      ...DailyDiaryResourceRecordLiteFields
      attachments {
        ...AttachmentFields
      }
    }
  }
`;

export const dailyDiaryEquipmentImportSourceItemsQuery = gql`
  query dailyDiaryEquipmentImportSourceItems(
    $productInstanceId: ID!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    dailyDiaryEquipmentImportSourceItems(
      productInstanceId: $productInstanceId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      items {
        id
        productInstanceId
        date
        number
        status
        dateCreated
        creatorId
        sentById
        dateSent
        latestRevision {
          id
          number
          status
          dateSent
        }
      }
    }
  }
`;

export const importDailyDiaryEquipmentRecordsMutation = gql`
  mutation importDailyDiaryEquipmentRecords(
    $input: ImportDailyDiaryRecordsInput!
  ) {
    importDailyDiaryEquipmentRecords(input: $input)
  }
`;
