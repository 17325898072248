import { AuditTrailModal } from "components/Authorization/AuditTrailModal/AuditTrailModal";
import { useAuthStore } from "state-management/store/authStore";

export const AuditTrailModalWrapper: React.FC = () => {
  const { authModalState, setAuthModalState } = useAuthStore((state) => ({
    authModalState: state.authModalState,
    setAuthModalState: state.setAuthModalState,
  }));

  const handleCloseModal = () => {
    setAuthModalState(undefined);
  };

  return (
    <>
      {authModalState &&
      authModalState.type === "AuditTrail" &&
      authModalState.authAuditId ? (
        <AuditTrailModal
          open
          onClose={handleCloseModal}
          authAuditId={authModalState.authAuditId}
        />
      ) : null}
    </>
  );
};
