import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Color,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { StyledLink } from "components/StyledLink";
import { defaultFormErrors } from "./constants";
import {
  defaultCognitoPasswordRequirementsRegex,
  FIELD_VALIDATION_ERRORS,
} from "constants/constants";
import { isValidEmail } from "helpers/validators";
import { PasswordEyeAdornment } from "components/miscellaneous/PasswordEyeAdornment";
import { useTranslation } from "react-i18next";
import { AuthPageHeader } from "containers/ResetPassword/components/AuthPageHeader";
import { ResetPasswordFooter } from "containers/ResetPassword/components/ResetPasswordFooter";
import { Info } from "phosphor-react";

export type LoginData = {
  email: string;
  password: string;
  keepMeLoggedIn?: boolean;
};

export type LoginFormProps = {
  loading?: boolean;
  onLogin: (formData: LoginData) => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({ onLogin, loading }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [formData, setFormData] = useState<LoginData>({
    email: "",
    password: "",
    keepMeLoggedIn: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);

  const handleInputChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((currentData) => ({
      ...currentData,
      [evt.target.name]: evt.target.value,
    }));
    setFormErrors(defaultFormErrors);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === "Enter") {
      handleLogin();
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!formData.email) {
      setFormErrors((currentFormErrors) => ({
        ...currentFormErrors,
        email: FIELD_VALIDATION_ERRORS.RequiredField,
      }));
      isValid = false;
    } else if (!isValidEmail(formData.email)) {
      setFormErrors((currentFormErrors) => ({
        ...currentFormErrors,
        email: FIELD_VALIDATION_ERRORS.InvalidField,
      }));
      isValid = false;
    }
    if (!formData.password) {
      setFormErrors((currentFormErrors) => ({
        ...currentFormErrors,
        password: FIELD_VALIDATION_ERRORS.RequiredField,
      }));
      isValid = false;
    }

    if (isValid) {
      setFormErrors(defaultFormErrors);
    }

    return isValid;
  };

  const handleLogin = () => {
    if (validateForm()) {
      onLogin(formData);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((vis) => !vis);
  };

  const handleKeepMeLoggedInChange = () => {
    setFormData((formData) => ({
      ...formData,
      keepMeLoggedIn: !formData.keepMeLoggedIn,
    }));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      maxWidth="430px"
    >
      <Box mb={7}>
        <AuthPageHeader
          title={t("Login.title")}
          subtitle={t("Login.subtitle")}
        />
      </Box>
      <Box display="flex" flexDirection="column" width="100%">
        <Typography
          variant="p1"
          fontWeight={600}
          color="grey.800"
          mb={1}
          display="block"
        >
          {t("common.labels.email")}
        </Typography>
        <TextField
          fullWidth
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          type="email"
          onKeyDown={handleKeyDown}
          placeholder={t("common.labels.enterYour", {
            entity: t("common.labels.email").toLowerCase(),
          })}
          inputProps={{
            "data-testid": "email-input",
          }}
          variant="outlined"
          error={!!formErrors.email}
          helperText={formErrors.email}
          InputLabelProps={{ shrink: true }}
          required
        />
      </Box>
      <Box display="flex" flexDirection="column" width="100%" mt={4}>
        <Typography
          variant="p1"
          fontWeight={600}
          color="grey.800"
          mb={1}
          display="block"
        >
          {t("common.labels.password")}
        </Typography>
        <TextField
          fullWidth
          onChange={handleInputChange}
          value={formData.password}
          onKeyDown={handleKeyDown}
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder={t("common.labels.enterYour", {
            entity: t("common.labels.password").toLowerCase(),
          })}
          variant="outlined"
          error={!!formErrors.password}
          helperText={formErrors.password}
          required
          InputLabelProps={{ shrink: true }}
          inputProps={{
            pattern: defaultCognitoPasswordRequirementsRegex,
            "data-testid": "password-input",
          }}
          InputProps={{
            endAdornment: (
              <PasswordEyeAdornment
                visibility={showPassword}
                onToggleVisibility={togglePasswordVisibility}
                mb={0}
              />
            ),
          }}
        />
      </Box>
      <Box mt={1.5}>
        <StyledLink to={"/reset-password"}>
          <Typography
            variant="p2"
            fontWeight={600}
            color={(theme.palette.primary as Partial<Color>)[500]}
          >
            {t("Login.buttons.forgotPassword")}
          </Typography>
        </StyledLink>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        width="100%"
      >
        <FormControlLabel
          label={
            <Typography variant="body2">
              {t("Login.buttons.keepMeLoggedIn")}
            </Typography>
          }
          labelPlacement="end"
          control={
            <Checkbox
              inputProps={{
                "aria-label": "keepMeLoggedIn",
              }}
              onChange={handleKeepMeLoggedInChange}
              checked={!!formData.keepMeLoggedIn}
            />
          }
        />
        <Tooltip title={t("Login.rememberMeTip")} arrow>
          <Box display="flex">
            <Info size={18} />
          </Box>
        </Tooltip>
      </Box>
      <Box mt={6} width="100%">
        <LoadingButton
          disableRipple={true}
          onClick={handleLogin}
          loading={loading}
          variant="contained"
          fullWidth
          style={{ textTransform: "none" }}
          size="large"
          data-testid="login-btn"
        >
          <Typography color="white" variant="body2">
            {t("Login.buttons.login")}
          </Typography>
        </LoadingButton>
      </Box>
      <Box mt={3} display="flex" justifyContent="center" width="100%">
        <ResetPasswordFooter isLogin />
      </Box>
    </Box>
  );
};
