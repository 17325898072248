import { ActionsDialogProps } from "components/ActionsDialog";
import {
  DraftVariationDetermination,
  VariationActionType,
} from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendDeterminationOfVariationNoticeModal } from "./useSendDeterminationOfVariationNoticeModal";
import { VariationProposalAgreementDeterminationSummary } from "../components/VariationProposalAgreementDeterminationSummary";

export type SendDeterminationOfVariationNoticeModalProps = {
  draftVariationDeterminations: DraftVariationDetermination[];
} & ActionsDialogProps;

export const SendDeterminationOfVariationNoticeModal: React.FC<
  SendDeterminationOfVariationNoticeModalProps
> = ({ draftVariationDeterminations, ...restDialogProps }) => {
  const { variationPreview, contract } =
    useSendDeterminationOfVariationNoticeModal(
      draftVariationDeterminations[0].id
    );
  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.NotifyDeterminationVariation}
      summaryEl={
        <VariationProposalAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftVariationDeterminations}
          type="VariationDetermination"
        />
      }
      {...restDialogProps}
    />
  );
};
