import { useLoggedInUserDiaryApprover } from "./useLoggedInUserDiaryApprover";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import {
  DailyDiaryUserRole,
  useDailyDiaryUserBERole,
} from "./useDailyDiaryUserBERole";

export const useDailyDiaryUserRoles = (
  ddProductInstanceId: string,
  authAudit?: AuthorizationWorkflowAudit
) => {
  const { isLoggedInUserDiaryApprover, loading: approverRoleLoading } =
    useLoggedInUserDiaryApprover(ddProductInstanceId, authAudit);
  const { userRole, loading: otherRoleLoading } =
    useDailyDiaryUserBERole(ddProductInstanceId);

  return {
    userRoles: userRole
      ? [
          userRole,
          ...(isLoggedInUserDiaryApprover ? [DailyDiaryUserRole.Reviewer] : []),
        ]
      : undefined,
    loading: !!otherRoleLoading || approverRoleLoading,
  };
};
