import { NewAppPaths } from "../../../helpers/paths/paths";
import { MenuItemData } from "mui-nested-menu";

export const adminConsoleSystemMenuItems: MenuItemData[] = [
  {
    uid: NewAppPaths.authorized.AdminConsole.children.System.children
      .ContractTypes,
    label: "AdminConsole.Navigation.labels.contractTypes",
  },
  {
    uid: NewAppPaths.authorized.AdminConsole.children.System.children.Products
      .path,
    label: "AdminConsole.Products.labels.products",
  },
  {
    uid: NewAppPaths.authorized.AdminConsole.children.System.children.Terms,
    label: "AdminConsole.Navigation.labels.terms",
  },
  {
    uid: NewAppPaths.authorized.AdminConsole.children.System.children.Templates,
    label: "AdminConsole.Navigation.labels.templates",
    items: [
      {
        uid: NewAppPaths.authorized.AdminConsole.children.System.children
          .Templates,
        label: "AdminConsole.Navigation.labels.templates",
      },
      {
        uid: NewAppPaths.authorized.AdminConsole.children.System.children
          .DocumentTemplateRouter,
        label: "AdminConsole.Navigation.labels.documentTemplateRouter",
      },
    ],
  },
  {
    uid: NewAppPaths.authorized.AdminConsole.children.System.children
      .ActionTypes,
    label: "AdminConsole.Navigation.labels.actionTypes",
  },
  {
    uid: NewAppPaths.authorized.AdminConsole.children.System.children
      .Notifications,
    label: "AdminConsole.Navigation.labels.notifications",
  },
  {
    uid: NewAppPaths.authorized.AdminConsole.children.System.children.Lookups,
    label: "AdminConsole.Navigation.labels.lookups",
    items: [
      {
        uid: NewAppPaths.authorized.AdminConsole.children.System.children
          .LookupsEffect.path,
        label: "AdminConsole.Navigation.labels.effect",
      },
      {
        uid: NewAppPaths.authorized.AdminConsole.children.System.children
          .SimpleLookups,
        label: "AdminConsole.Navigation.labels.simpleLookups",
      },
      {
        uid: NewAppPaths.authorized.AdminConsole.children.System.children
          .CustomSimpleLookups,
        label: "AdminConsole.Navigation.labels.customSimpleLookups",
      },
    ],
  },
];

export const adminConsoleLogsMenuItems: MenuItemData[] = [
  {
    uid: NewAppPaths.authorized.AdminConsole.children.Logs.children
      .LoginHistory,
    label: "AdminConsole.Navigation.labels.loginHistory",
  },
  {
    uid: NewAppPaths.authorized.AdminConsole.children.Logs.children
      .Notifications,
    label: "AdminConsole.Navigation.labels.notifications",
  },
  {
    uid: NewAppPaths.authorized.AdminConsole.children.Logs.children.Activities,
    label: "AdminConsole.Navigation.labels.activities",
  },
];
