import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";
import { draftDetailedClaimFields } from "graphql/fragments/draftIterimDetailedClaim.fragment";

export const claimFinalClaimDetailsPromptQuery = gql`
  ${draftDetailedClaimFields}
  query claimFinalClaimDetailsPrompt(
    $input: ClaimFinalClaimDetailsPromptInput!
  ) {
    claimFinalClaimDetailsPrompt(input: $input) {
      draftDetailedClaims {
        ...DraftDetailedClaimFields
      }
    }
  }
`;

export const sendClaimFinalClaimDetailsMutation = gql`
  mutation sendClaimFinalClaimDetails(
    $input: SendClaimFinalClaimDetailsInput!
  ) {
    sendClaimFinalClaimDetails(input: $input) {
      id
    }
  }
`;

export const recordClaimFinalClaimDetailsMutation = gql`
  mutation recordClaimFinalClaimDetails(
    $input: RecordClaimFinalClaimDetailsInput!
  ) {
    recordClaimFinalClaimDetails(input: $input) {
      id
    }
  }
`;

export const generateSendClaimFinalClaimDetailsPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimFinalClaimDetailsPreview(
    $input: SendClaimFinalClaimDetailsPreviewInput!
  ) {
    generateSendClaimFinalClaimDetailsPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimFinalClaimDetailsPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimFinalClaimDetailsPreview(
    $input: RecordClaimFinalClaimDetailsPreviewInput!
  ) {
    generateRecordClaimFinalClaimDetailsPreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
