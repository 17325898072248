import { Box, Typography, useTheme } from "@mui/material";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export type NotifyCERequestQuotationSummaryProps = {
  claimType: string;
  assumptions?: string;
};

export const NotifyCERequestQuotationSummary: React.FC<NotifyCERequestQuotationSummaryProps> =
  memo(({ claimType, assumptions }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="p2" color="grey.800" fontWeight={600}>
          {t("Projects.CompEvents.ActionModal.compEventType")}
        </Typography>
        <Typography variant="p1" color="grey.600" mt={0.5}>
          {claimType}
        </Typography>
        <Box mt={2} display="flex" flexDirection="column">
          <Typography variant="p2" color="grey.800" fontWeight={600}>
            {t("Projects.CompEvents.ActionModal.assumptions")}
          </Typography>
          <RichTextArea
            readOnly
            content={assumptions || t("common.labels.none")}
            color={theme.palette.grey[600]}
          />
        </Box>
      </Box>
    );
  });
