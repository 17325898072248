import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { DailyDiaryItemStatus } from "generated/graphql";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryHeaderContext } from "./DailyDiaryHeader.context";
import { ReviewerButtons } from "./ReviewerButtons";

export const CreatorButtons: React.FC = () => {
  const { t } = useTranslation();
  const { dailyDiaryStatus, loading, isReviewer, onSubmit } = useContext(
    DailyDiaryHeaderContext
  );

  return (
    <>
      {isReviewer ? <ReviewerButtons /> : null}
      {dailyDiaryStatus === DailyDiaryItemStatus.Draft ? (
        <LoadingButton
          variant="contained"
          size="large"
          onClick={onSubmit}
          disabled={loading}
          data-testid="header-submit-btn"
        >
          <Typography variant="p2" fontWeight={700}>
            {t("common.buttons.submit")}
          </Typography>
        </LoadingButton>
      ) : null}
    </>
  );
};
