import { customSort } from "containers/Projects/Projects.utils";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import {
  CompEventNoticePromptQuery,
  CompEventNoticePromptQueryVariables,
  RecordCompEventNoticeMutation,
  RecordCompEventNoticeMutationVariables,
  SendCompEventNoticeMutation,
  SendCompEventNoticeMutationVariables,
} from "generated/graphql";
import { recordCompEventNoticeMutation } from "graphql/mutations/recordCompEventNotice";
import { sendCompEventNoticeMutation } from "graphql/mutations/sendCompEventNotice";
import { compEventNoticePromptQuery } from "graphql/queries/compEventNoticePrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useNotifyCEACtion = () => {
  const { t } = useTranslation();
  const { onChange, productInstanceId } = useContext(CompEventWidgetContext);

  const { data, loading } = useGraphQuery<
    CompEventNoticePromptQuery,
    CompEventNoticePromptQueryVariables
  >(compEventNoticePromptQuery, {
    variables: {
      input: {
        productInstanceId: productInstanceId!,
      },
    },
    skip: !productInstanceId,
  });

  const [sendCompEventNotice, { loading: sendCompEventNoticeLoading }] =
    useGraphMutation<
      SendCompEventNoticeMutation,
      SendCompEventNoticeMutationVariables
    >(
      sendCompEventNoticeMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
          cache.gc();
          onChange?.();
        },
      },
      t("common.successMessages.entitySent", {
        entity: t("Projects.CompEvents.compEvent"),
      })
    );

  const [recordCompEventNotice, { loading: recordCompEventNoticeLoading }] =
    useGraphMutation<
      RecordCompEventNoticeMutation,
      RecordCompEventNoticeMutationVariables
    >(
      recordCompEventNoticeMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
          cache.gc();
          onChange?.();
        },
      },
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.CompEvents.compEvent"),
      })
    );

  const orderedClaimTypes = useMemo(
    () =>
      data?.compEventNoticePrompt.compEventTypes
        .slice()
        .sort((claim1, claim2) => claim1.name.localeCompare(claim2.name))
        .sort(customSort), // TODO: remove this logic when claimTypes have a displayOrder attribute
    [data]
  );

  return {
    claimTypes: orderedClaimTypes,
    earlyWarnings: data?.compEventNoticePrompt.earlyWarnings,
    loading,
    actionLoading: sendCompEventNoticeLoading || recordCompEventNoticeLoading,
    sendCompEventNotice,
    recordCompEventNotice,
  };
};
