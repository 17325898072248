import { Box, useTheme } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ItemTag, ProductTypeExtra } from "components/ItemTag";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { ClaimItem, Contract, ProductType } from "generated/graphql";
import moment from "moment";
import { dateISOFormat } from "constants/constants";
import {
  datetimeComparatorFn,
  statusComparatorFunction,
} from "helpers/dataGrid.helpers";
import { claimStatusOptions } from "../ClaimsView.constants";
import { ClaimItemPreview } from "../ClaimItemPreview";
import { OverflowTooltip } from "components/OverflowTooltip";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { PriceTag } from "containers/Projects/components/HistoryModal/PriceTag";
import { TimeTag } from "containers/Projects/components/HistoryModal/TimeTag";
import { isFIDIC99RedYellowContractType } from "containers/Projects/Projects.utils";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";

export const useColumns = (contract?: Contract): GridColDef<ClaimItem>[] => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isFIDIC99RedYellow = useMemo(
    () => isFIDIC99RedYellowContractType(contract),
    [contract]
  );

  return useMemo(
    () => [
      {
        field: "number",
        headerName: t("Projects.Claims.claimNumber"),
        flex: 0.1,
        minWidth: 150,
        resizable: true,
        renderCell: (params) => {
          return (
            <OverflowTooltip
              title={params.value}
              typographyProps={{
                variant: "p2",
                color: "text.primary",
              }}
            />
          );
        },
      },
      {
        field: "status",
        headerName: t("common.labels.status"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        sortComparator: statusComparatorFunction,
        valueGetter: (_, row) => row.status,
        renderCell: (params: GridRenderCellParams<any, ClaimItem, any>) => {
          return (
            <StatusTagNew
              selectedOptionId={params.row.status}
              disabled
              options={claimStatusOptions}
            />
          );
        },
      },
      {
        field: "paymentSought",
        headerName: t("Projects.Claims.paymentSought"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        valueGetter: (_, row) => row.priceSought,
        renderCell: (
          params: GridRenderCellParams<
            ClaimItem,
            number | null | undefined,
            any
          >
        ) => {
          return (
            <PriceTag
              value={params.value}
              currency={contract?.valueCurrency}
              sx={{ width: "100%" }}
            />
          );
        },
      },
      {
        field: "eotSought",
        headerName: t("Projects.Claims.eotSought"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        valueGetter: (_, row) => row.timeSought,
        renderCell: (
          params: GridRenderCellParams<
            ClaimItem,
            number | null | undefined,
            any
          >
        ) => <TimeTag value={params.value} sx={{ width: "100%" }} />,
      },
      {
        field: "paymentGranted",
        headerName: t("Projects.Claims.paymentGranted"),
        flex: 0.1,
        width: 150,
        resizable: true,
        valueGetter: (_, row) => row.priceGranted,
        renderCell: (
          params: GridRenderCellParams<
            ClaimItem,
            number | null | undefined,
            any
          >
        ) => (
          <PriceTag
            value={params.value}
            currency={contract?.valueCurrency}
            sx={{ width: "100%" }}
          />
        ),
      },
      {
        field: "eotGranted",
        headerName: t("Projects.Claims.eotGranted"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        valueGetter: (_, row) => row.timeGranted,
        renderCell: (
          params: GridRenderCellParams<
            ClaimItem,
            number | null | undefined,
            any
          >
        ) => <TimeTag value={params.value} sx={{ width: "100%" }} />,
      },
      {
        field: "advanceWarning",
        headerName: t(
          `Projects.EarlyWarnings.${
            isFIDIC99RedYellow ? "earlyWarning" : "advanceWarning"
          }`
        ),
        flex: 0.2,
        minWidth: 175,
        resizable: true,
        valueGetter: (_, row) => row.advanceWarning?.number ?? "",
        renderCell: (params: GridRenderCellParams<ClaimItem, any, any>) => {
          return params.row.advanceWarning ? (
            <Box display="flex" alignItems="center" width="100%">
              <ItemTag
                type={
                  isFIDIC99RedYellow
                    ? ProductType.EarlyWarnings
                    : ProductTypeExtra.AdvanceWarning
                }
              />
              <OverflowTooltip
                title={params.row.advanceWarning?.number}
                typographyProps={{
                  variant: "p2",
                  ml: 1,
                  color: theme.palette.grey[700],
                }}
              />
            </Box>
          ) : null;
        },
      },
      {
        field: "lastAction",
        headerName: t("Projects.Claims.lastAction"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        sortComparator: datetimeComparatorFn,
        valueGetter: (_, row) => row.lastAction.date,
        valueFormatter: (value: string) => moment(value).format(dateISOFormat),
        renderCell: (params) => {
          return (
            <DateWithTimeTooltip
              datetime={params.value}
              timezone={params.row.productInstance.contract.timeZone}
              color="grey.600"
              variant="p2"
            />
          );
        },
      },
      {
        field: "regarding",
        headerName: t("common.labels.regarding"),
        flex: 0.35,
        minWidth: 225,
        resizable: true,
        sortable: true,
        valueGetter: (_, row) =>
          (row.regardingEvent?.title || row.regardingInstruction?.title) ?? "",
        renderCell: (params: GridRenderCellParams<ClaimItem, string, any>) => {
          return (
            <Box display="flex" alignItems="center" width="100%">
              <ItemTag
                type={
                  params.row.regardingEvent
                    ? ProductType.Events
                    : ProductType.Instructions
                }
              />
              <OverflowTooltip
                title={params.value}
                typographyProps={{
                  variant: "p2",
                  ml: 1,
                  color: theme.palette.grey[700],
                }}
              />
            </Box>
          );
        },
      },
      {
        field: "options",
        headerName: "",
        width: 50,
        resizable: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, ClaimItem, any>) => {
          return <ClaimItemPreview claim={params.row} />;
        },
      },
    ],
    [theme, t, contract, isFIDIC99RedYellow]
  );
};
