import { Box, useTheme } from "@mui/material";
import {
  AttachmentStatus,
  ClaimHistoryItem,
  CompEventHistoryItem,
  ProductType,
  VariationHistoryItem,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { useAttachments } from "../Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "../Attachments/hooks/useImagePreviewModal";
import { PhotoAttachmentPreviewModal } from "../PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { Attachments } from "../Attachments/Attachments";
import { AttachmentsDencity } from "../Attachments/Attachments.decl";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { RecipientsPreview } from "../RecipientsPreview";
import { HistoryItemDetailsContainer } from "../HistoryModal/HistoryItemDetailsContainer";
import { HistoryItemDetailsHeader } from "../HistoryModal/HistoryItemDetailsHeader";
import { ListItemsDivider } from "components/ListItemsDivider";
import { AuthorizationLevels } from "components/Authorization/AuthorizeDenyActionModal/components/AuthorizationLevels/AuthorizationLevels";

type BaseHistoryItemDetailsProps = {
  historyItem: CompEventHistoryItem | ClaimHistoryItem | VariationHistoryItem;
  isFIDIC17White?: boolean;
  isFIDIC99RedYellow?: boolean;
  contractTimezone: string;
  productType:
    | ProductType.CompEvents
    | ProductType.Claims
    | ProductType.Variations;
  children: React.ReactNode;
};

export const BaseHistoryItemDetails: React.FC<BaseHistoryItemDetailsProps> = ({
  historyItem,
  productType,
  children,
  contractTimezone,
  isFIDIC17White,
  isFIDIC99RedYellow,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    (historyItem.attachments ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || []
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  return (
    <>
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        contractTimezone={contractTimezone}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <HistoryItemDetailsContainer>
        <HistoryItemDetailsHeader
          historyItemActionType={historyItem.actionType}
          historyItemNumber={historyItem.actionNumber}
          productType={productType}
          isFIDIC17White={isFIDIC17White}
          isFIDIC99RedYellow={isFIDIC99RedYellow}
        />
        <ListItemsDivider sx={{ my: theme.spacing(3) }} />
        {children}
        <ListItemsDivider sx={{ my: theme.spacing(4) }} />
        {historyItem.authorizationWorkflowAudit && (
          <AuthorizationLevels
            showLevelsConnector
            authAudit={historyItem.authorizationWorkflowAudit}
            mb={4}
          />
        )}
        {allAttachments.length > 0 && (
          <Box mb={4}>
            <Attachments
              editMode={false}
              showTabs={false}
              timezone={contractTimezone}
              dencity={AttachmentsDencity.Compact}
              attachments={allAttachments}
              onAttachmentsAdd={addAttachments}
              onAttachmentRemove={removeAttachment}
              onAttachmentUpdate={updateAttachment}
              onAttachmentClick={handleAttachmentClick}
            />
          </Box>
        )}
        <CollapsibleContainer
          collapsed={true}
          title={`${t(`common.labels.recipients`)} ${
            historyItem.recipients?.length
              ? `(${historyItem.recipients.length})`
              : ""
          }`}
        >
          <RecipientsPreview recipients={historyItem.recipients ?? []} />
        </CollapsibleContainer>
      </HistoryItemDetailsContainer>
    </>
  );
};
