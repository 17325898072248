import { useTheme } from "@mui/material";
import {
  ContractTypeStatus,
  ProductSchemaStatus,
  ProductStatus,
} from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StatusOption } from "./StatusTag";

/**
 * Valid for ContractTypeStatus & ProductStatus & ProductSchemaStatus
 * @returns Array of valid options for <StatusTag />
 */
export const useContractTypeStatusOptions = (): StatusOption<
  ContractTypeStatus | ProductStatus | ProductSchemaStatus
>[] => {
  const theme = useTheme();
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(ContractTypeStatus).map((status) => ({
      id: status,
      label: t(`Enums.ContractTypeStatus.${status}`),
      color:
        status === ContractTypeStatus.Active
          ? theme.palette.primary.main
          : status === ContractTypeStatus.Offline
          ? theme.palette.warning.main
          : theme.palette.grey[700],
    }));
  }, [theme, t]);

  return options;
};
