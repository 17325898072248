import { Box, css, Fade, MenuItem, styled, Typography } from "@mui/material";
import { StyledBoldableText } from "components/BasicContextMenu/BasicContextMenu.styled";
import { StyledNestedMenuItem } from "components/BasicNestedDropdownMenu/BasicNestedDropdownMenu.styled";
import { KeyboardArrowDownIcon } from "components/Icons/KeyboardArrowDownIcon";
import { MenuItemData } from "mui-nested-menu";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type ButtonWithOptionsProps = {
  triggerLabel: string;
  options: MenuItemData[];
  boldedText?: boolean;
  width?: string;
  onItemSelect?: (id: string) => void;
};

const OptionsContainer = styled(Box)<{ open: boolean; width?: string }>(
  ({ theme, open, width = "120px" }) => css`
    ${open
      ? css`
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 100%;
          left: 0;
          padding: ${theme.spacing(1)} 0;
          box-shadow: rgb(37 44 50 / 12%) 0px 2px 6px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          background: white;
          width: ${width};
          z-index: -1;
        `
      : css`
          display: none;
        `}
  `
);

const Trigger = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isHovered",
})<{ isHovered?: boolean; width?: string }>(
  ({ theme, isHovered, width = "120px" }) => css`
    cursor: pointer;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: ${theme.spacing(0.75)} ${theme.spacing(2)};
    background: white;
    transition: box-shadow 0.1s ease-in;
    ${isHovered && `box-shadow: rgb(37 44 50 / 12%) 0px -3px 5px -1px;`}
    width: ${width};
    z-index: 2;
  `
);

export const ButtonWithOptions: React.FC<ButtonWithOptionsProps> = ({
  triggerLabel,
  options,
  boldedText,
  width,
  onItemSelect,
}) => {
  const { t } = useTranslation();

  const [showOptions, setShowOptions] = useState(false);

  const handleMouseEnter = () => {
    setShowOptions(true);
  };

  const handleMouseLeave = () => {
    setShowOptions(false);
  };

  const handleMenuItemSelect = (itemId: string) => {
    onItemSelect?.(itemId);
    setShowOptions(false);
  };

  return (
    <Box position="relative">
      <Trigger
        isHovered={showOptions}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        width={width}
      >
        <StyledBoldableText
          mr={1}
          fontWeight={boldedText ? 700 : 500}
          dataText={triggerLabel}
        >
          {triggerLabel}
        </StyledBoldableText>
        <KeyboardArrowDownIcon height="6" width="11" />
      </Trigger>
      <Fade in={showOptions} timeout={125} easing="ease-in">
        <OptionsContainer
          open={showOptions}
          width={width}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {options.map((menuItem) => {
            if (menuItem.items) {
              return (
                <StyledNestedMenuItem
                  key={menuItem.uid!}
                  label={t(menuItem.label!)}
                  parentMenuOpen={showOptions}
                  nonce=""
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: "8px",
                      },
                    },
                    disablePortal: true,
                  }}
                >
                  {menuItem.items.map((nestedMenuItem) => (
                    <MenuItem
                      key={nestedMenuItem.uid!}
                      onClick={() => handleMenuItemSelect(nestedMenuItem.uid!)}
                    >
                      <Typography variant="body2">
                        {t(nestedMenuItem.label!)}
                      </Typography>
                    </MenuItem>
                  ))}
                </StyledNestedMenuItem>
              );
            }
            return (
              <MenuItem
                key={menuItem.uid!}
                onClick={() => handleMenuItemSelect(menuItem.uid!)}
              >
                <Typography variant="body2">{t(menuItem.label!)}</Typography>
              </MenuItem>
            );
          })}
        </OptionsContainer>
      </Fade>
    </Box>
  );
};
