import {
  RecordClaimErrorDeterminationDisagreementMutation,
  RecordClaimErrorDeterminationDisagreementMutationVariables,
  SendClaimErrorDeterminationDisagreementMutation,
  SendClaimErrorDeterminationDisagreementMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";

import { OnMutationDone } from "types/decl";
import {
  recordClaimErrorDeterminationDisagreementMutation,
  sendClaimErrorDeterminationDisagreementMutation,
} from "./DisagreeWithNoticeOfErrorInDeterminationAction.query";

export const useDisagreeWithNoticeOfErrorInDetermination = () => {
  const { t } = useTranslation();
  const { onChange, claim } = useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendClaimErrorDeterminationDisagreement,
    { loading: sendClaimErrorDeterminationDisagreementLoading },
  ] = useGraphMutation<
    SendClaimErrorDeterminationDisagreementMutation,
    SendClaimErrorDeterminationDisagreementMutationVariables
  >(
    sendClaimErrorDeterminationDisagreementMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Claims.disagreementErrorInDetermination"),
    })
  );

  const [
    recordClaimErrorDeterminationDisagreement,
    { loading: recordClaimErrorDeterminationDisagreementLoading },
  ] = useGraphMutation<
    RecordClaimErrorDeterminationDisagreementMutation,
    RecordClaimErrorDeterminationDisagreementMutationVariables
  >(
    recordClaimErrorDeterminationDisagreementMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Claims.disagreementErrorInDetermination"),
    })
  );

  return {
    actionLoading:
      sendClaimErrorDeterminationDisagreementLoading ||
      recordClaimErrorDeterminationDisagreementLoading,
    sendClaimErrorDeterminationDisagreement,
    recordClaimErrorDeterminationDisagreement,
  };
};
