import { gql } from "@apollo/client";

export const statusOptionLightFields = gql`
  fragment StatusOptionLightFields on ItemStatusOption {
    id
    displayOrder
    description
    equatesToRemoved
    style
    statusCollectionId
    isInternal
    status
  }
`;
