import { Box, Color, css, styled, Typography } from "@mui/material";
import { ListItemsDivider } from "components/ListItemsDivider";
import { IconProps } from "phosphor-react";
import React from "react";

export type ListItemType = {
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  label: string;
  path: string;
};

export type SubPagesTabsProps = {
  items: ListItemType[];
  selectedItem: ListItemType | null;
  onClick: (item: ListItemType) => void;
};

const ListItemContainer = styled(Box)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    color: ${theme.palette.grey[600]};
    cursor: pointer;
    display: flex;
    align-items: center;

    ${selected &&
    css`
      color: ${theme.palette.grey[900]};
      border-left: 2px solid ${(theme.palette.primary as Partial<Color>)[500]};
    `}
  `
);

export const SubPagesTabs: React.FC<SubPagesTabsProps> = ({
  items,
  selectedItem,
  onClick,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      {items.map((item, index) => {
        const ItemIcon = item.icon;
        return (
          <React.Fragment key={item.label}>
            <ListItemContainer
              onClick={() => onClick(item)}
              selected={selectedItem?.label === item.label}
            >
              <ItemIcon size={24} />
              <Typography variant="p1" fontWeight={600} ml={1.5}>
                {item.label}
              </Typography>
            </ListItemContainer>
            {index < items.length - 1 && (
              <ListItemsDivider
                sx={{
                  marginTop: 0,
                  marginBottom: 0,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};
