import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";
import { detailedClaimLiteFields } from "graphql/fragments/detailedClaimLite.fragment";

export const claimDetailsResponsePromptQuery = gql`
  ${detailedClaimLiteFields}

  query claimDetailsResponsePrompt($input: ClaimDetailsResponsePromptInput!) {
    claimDetailsResponsePrompt(input: $input) {
      details
      pendingDetailedClaims {
        ...DetailedClaimLiteFields
      }
    }
  }
`;

export const sendClaimDetailsResponseMutation = gql`
  mutation sendClaimDetailsResponse($input: SendClaimDetailsResponseInput!) {
    sendClaimDetailsResponse(input: $input) {
      id
    }
  }
`;

export const recordClaimDetailsResponseMutation = gql`
  mutation recordClaimDetailsResponse(
    $input: RecordClaimDetailsResponseInput!
  ) {
    recordClaimDetailsResponse(input: $input) {
      id
    }
  }
`;

export const generateSendClaimDetailsResponsePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimDetailsResponsePreview(
    $input: SendClaimDetailsResponsePreviewInput!
  ) {
    generateSendClaimDetailsResponsePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimDetailsResponsePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimDetailsResponsePreview(
    $input: RecordClaimDetailsResponsePreviewInput!
  ) {
    generateRecordClaimDetailsResponsePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
