import { gql } from "@apollo/client";

export const getCompanyUsersQuery = gql`
  query getCompanyUsers($id: ID) {
    company(id: $id) {
      id
      users {
        items {
          id
          firstname
          surname
          email
          companyId
          registered
          status
          lastActive
          dateInvited
        }
      }
    }
  }
`;
