import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { ClockIcon } from "components/Icons/ClockIcon";
import React from "react";
import { useTranslation } from "react-i18next";

export const DaysTextField: React.FC<TextFieldProps> = (textFieldProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth
      type="number"
      size="small"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ClockIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Typography variant="p1" color="grey.600">
              {t("common.labels.days").toLowerCase()}
            </Typography>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
};
