import {
  EventItemRecipientsQuery,
  EventItemRecipientsQueryVariables,
  NotificationRecipient,
  RiskItemRecipientsQuery,
  RiskItemRecipientsQueryVariables,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import {
  eventItemRecipientsQuery,
  riskItemRecipientsQuery,
} from "./NewRiskEventItemConfirmModal.query";
import { useEffect, useMemo } from "react";

export const useNewRiskEventItemConfirmModal = (
  productInstanceId: string,
  isEvent: boolean,
  skip?: boolean
) => {
  const [
    fetchEventItemRecipients,
    { data: eventItemRecipients, loading: eventItemRecipientsLoading },
  ] = useGraphLazyQueryLite<
    EventItemRecipientsQuery,
    EventItemRecipientsQueryVariables
  >(eventItemRecipientsQuery);

  const [
    fetchRiskItemRecipients,
    { data: riskItemRecipients, loading: riskItemRecipientsLoading },
  ] = useGraphLazyQueryLite<
    RiskItemRecipientsQuery,
    RiskItemRecipientsQueryVariables
  >(riskItemRecipientsQuery);

  useEffect(() => {
    if (!skip) {
      if (isEvent) {
        fetchEventItemRecipients({ productInstanceId });
      } else {
        fetchRiskItemRecipients({ productInstanceId });
      }
    }
  }, [
    isEvent,
    productInstanceId,
    skip,
    fetchEventItemRecipients,
    fetchRiskItemRecipients,
  ]);

  const recipients = useMemo(() => {
    return (eventItemRecipients?.data.eventItemRecipients ??
      riskItemRecipients?.data.riskItemRecipients) as
      | NotificationRecipient[]
      | undefined;
  }, [eventItemRecipients, riskItemRecipients]);

  return {
    recipients,
    loading: eventItemRecipientsLoading || riskItemRecipientsLoading,
  };
};
