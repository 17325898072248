import { gql } from "@apollo/client";

export const getRegistrationInfoQuery = gql`
  query userRegistration($invitationId: ID) {
    userRegistration(invitationId: $invitationId) {
      invitationId
      email
      company {
        id
        tradingName
      }
      dateInvited
    }
  }
`;

export const registerUserMutation = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      id
      firstname
      surname
      jobTitle
      country
      mobileNumber
      alternateNumber
      dateOfBirth
      profilePicture
      email
      companyId
      registered
      status
      lastActive
      dateInvited
    }
  }
`;
