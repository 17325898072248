import { Box, Typography } from "@mui/material";
import {
  DraftVariationAgreement,
  DraftVariationDetermination,
  DraftVariationProposal,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import {
  VOProposalsAgreementsDeterminationsTable,
  VariationItemTypes,
} from "./VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";

export type VariationProposalAgreementDeterminationSummaryProps = {
  contractCurrency: string;
  items:
    | DraftVariationProposal[]
    | DraftVariationAgreement[]
    | DraftVariationDetermination[];
  type: VariationItemTypes;
};

export const VariationProposalAgreementDeterminationSummary: React.FC<
  VariationProposalAgreementDeterminationSummaryProps
> = ({ contractCurrency, type, items }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="h4"
        fontSize="18px"
        color="grey.800"
        fontWeight={600}
        mb={2.5}
      >
        {type === "VariationProposal"
          ? t("Projects.Variations.variationProposal")
          : type === "VariationAgreement"
          ? t("Projects.Variations.variationAgreement")
          : t("Projects.Variations.variationDetermination")}
      </Typography>
      <VOProposalsAgreementsDeterminationsTable
        items={items}
        type={type}
        contractCurrency={contractCurrency}
      />
    </Box>
  );
};
