import { gql } from "@apollo/client";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";

export const generateInstructionPreviewMutation = gql`
  ${authorizationWorkflowActionMappingFields}

  mutation generateInstructionPreview($input: SendInstructionItemInput!) {
    generateInstructionPreview(input: $input) {
      id
      fileUrl
      actionMapping {
        ...AuthorizationWorkflowActionMappingFields
      }
      recipients {
        userId
        name
        preference {
          inApp
          inAppReadOnly
          email
          emailReadOnly
        }
      }
    }
  }
`;
