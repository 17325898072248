import { Box, Checkbox, Typography } from "@mui/material";
import {
  UserPreferencesValue,
  UserPreferencesValueInput,
} from "generated/graphql";

export type NotificationPreferenceListItemProps = {
  preference: UserPreferencesValue;
  label: string;
  id: string;
  readOnly?: boolean;
  onChange: (id: string, newPreference: UserPreferencesValueInput) => void;
};

export const NotificationPreferenceListItem: React.FC<
  NotificationPreferenceListItemProps
> = ({ label, preference, readOnly, id, onChange }) => {
  const handleCheckUncheck = (type: "inApp" | "email", checked: boolean) => {
    const newPreference: UserPreferencesValueInput = {
      inApp: type === "inApp" ? checked : preference.inApp,
      email: type === "email" ? checked : preference.email,
    };

    onChange(id, newPreference);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={1}
    >
      <Typography variant="p2" color="grey.800">
        {label}
      </Typography>
      <Box display="flex" alignItems="center">
        <Checkbox
          readOnly={preference.inAppReadOnly || readOnly}
          disabled={preference.inAppReadOnly}
          onChange={(_, checked) => handleCheckUncheck("inApp", checked)}
          checked={preference.inApp}
        />
        <Box ml={6} mr={1}>
          <Checkbox
            readOnly={preference.emailReadOnly || readOnly}
            disabled={preference.emailReadOnly}
            onChange={(_, checked) => handleCheckUncheck("email", checked)}
            checked={preference.email}
          />
        </Box>
      </Box>
    </Box>
  );
};
