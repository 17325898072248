import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { FormattedNumericInput } from "containers/Projects/components/SchemaInterpretor/Field/Effect/components/FormattedNumericInput";
import getSymbolFromCurrency from "currency-symbol-map";

export type CurrencyTextFieldProps = { currency: string } & TextFieldProps;

export const CurrencyTextField: React.FC<CurrencyTextFieldProps> = ({
  currency,
  ...restTextfieldProps
}) => {
  return (
    <TextField
      fullWidth
      type="text"
      variant="outlined"
      size="small"
      data-testid="monetary-value-textfield"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {getSymbolFromCurrency(currency ?? "")}
          </InputAdornment>
        ),
        inputComponent: FormattedNumericInput as any,
      }}
      sx={{
        "& .MuiFormHelperText-root.Mui-error": {
          marginLeft: 0,
        },
        ...restTextfieldProps.sx,
      }}
      {...restTextfieldProps}
    />
  );
};
