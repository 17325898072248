import { OnMutationDone } from "types/decl";
import {
  AddItemStatusOptionInput,
  AddItemStatusOptionMutation,
  AddItemStatusOptionMutationVariables,
  ChangeItemStatusOptionStatusMutation,
  ChangeItemStatusOptionStatusMutationVariables,
  EditItemStatusOptionInput,
  EditItemStatusOptionMutation,
  EditItemStatusOptionMutationVariables,
  ItemStatusOption,
  ItemStatusOptionStatus,
} from "generated/graphql";
import { changeItemStatusOptionStatusMutation } from "graphql/mutations/changeStatusCollectionOptionStatus";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  addItemStatusOptionMutation,
  editItemStatusOptionMutation,
} from "./StatusCollectionOptions.query";

export type useStatusCollectionOptionsProps = {
  onStatusCollectionOptionAdded: OnMutationDone;
  onStatusCollectionOptionUpdated: OnMutationDone;
  onStatusCollectionOptionStatusChange: OnMutationDone;
};

export const useStatusCollectionOptions = ({
  onStatusCollectionOptionAdded,
  onStatusCollectionOptionStatusChange,
  onStatusCollectionOptionUpdated,
}: useStatusCollectionOptionsProps) => {
  const { t } = useTranslation();

  const [
    addStatusCollectionOption,
    { loading: addStatusCollectionOptionLoading },
  ] = useGraphMutation<
    AddItemStatusOptionMutation,
    AddItemStatusOptionMutationVariables
  >(
    addItemStatusOptionMutation,
    {
      update: onStatusCollectionOptionAdded,
    },
    t("common.successMessages.entityCreated", {
      entity: t("AdminConsole.Products.labels.statusOption"),
    })
  );

  const [
    editStatusCollectionOption,
    { loading: editStatusCollectionOptionLoading },
  ] = useGraphMutation<
    EditItemStatusOptionMutation,
    EditItemStatusOptionMutationVariables
  >(
    editItemStatusOptionMutation,
    {
      update: onStatusCollectionOptionUpdated,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Products.labels.statusOption"),
    })
  );

  const [
    changeStatusCollectionOptionStatus,
    { loading: changeStatusCollectionOptionStatusLoading },
  ] = useGraphMutation<
    ChangeItemStatusOptionStatusMutation,
    ChangeItemStatusOptionStatusMutationVariables
  >(
    changeItemStatusOptionStatusMutation,
    {
      update: onStatusCollectionOptionStatusChange,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Products.labels.statusOption"),
    })
  );

  const handleAddStatusCollectionOption = useCallback(
    async (statusCollectionOption: AddItemStatusOptionInput) => {
      await addStatusCollectionOption({
        variables: {
          input: statusCollectionOption,
        },
      });
    },
    [addStatusCollectionOption]
  );

  const handleEditStatusCollectionOption = useCallback(
    async (statusCollectionOption: EditItemStatusOptionInput) => {
      await editStatusCollectionOption({
        variables: {
          input: statusCollectionOption,
        },
      });
    },
    [editStatusCollectionOption]
  );

  const handleStatusCollectionOptionStatusChange = useCallback(
    async (row: ItemStatusOption, newStatus: ItemStatusOptionStatus) => {
      await changeStatusCollectionOptionStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeStatusCollectionOptionStatus]
  );

  return {
    addStatusCollectionOption: handleAddStatusCollectionOption,
    addStatusCollectionOptionLoading,
    editStatusCollectionOption: handleEditStatusCollectionOption,
    editStatusCollectionOptionLoading,
    changeStatusCollectionOptionStatus:
      handleStatusCollectionOptionStatusChange,
    changeStatusCollectionOptionStatusLoading,
  };
};
