import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export type EditorPlaceholderProps = {
  disabled: boolean;
  placeholder?: string;
};

const EditorPlaceholderContainer = styled(Box)`
  display: inline-block;
  pointer-events: none;
  user-select: none;
  font-size: 15px;
  top: 10px;
  left: 10px;
  text-overflow: ellipsis;
  position: absolute;
  overflow: hidden;
  color: #999;
`;

export const EditorPlaceholder: React.FC<EditorPlaceholderProps> = ({
  disabled,
  placeholder = "Comments.addCommentHere",
}) => {
  const { t } = useTranslation();

  return (
    <EditorPlaceholderContainer className="editor-placeholder">
      <Typography variant="p1" color="grey.500">
        {disabled ? t("common.labels.suspenseLoading") : t(placeholder)}
      </Typography>
    </EditorPlaceholderContainer>
  );
};
