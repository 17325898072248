import { Navigate, Route, Routes } from "react-router-dom";
import { Register } from "containers/Register/Register";
// import { Reporting } from "containers/Reporting/Reporting"; // TODO: uncomment when adding back reporting
import { AdminConsole } from "containers/AdminConsole/AdminConsole";
import { ProfileEdit } from "containers/Settings/containers/ProfileEdit/ProfileEdit";
// import { Profile } from "containers/Settings/containers/ProfileSettings/ProfileSettings";
import { Companies } from "containers/AdminConsole/containers/Companies/Companies";
import { Projects as AdminProjects } from "containers/AdminConsole/containers/Projects/Projects";
import { Support as AdminSupport } from "containers/AdminConsole/containers/Support/Support";
import { ContractTypes } from "containers/AdminConsole/containers/System/containers/ContractTypes/ContractTypes";
import { Products as AdminSystemProducts } from "containers/AdminConsole/containers/System/containers/Products/Products";
import { Terms } from "containers/AdminConsole/containers/System/containers/Terms";
import { Templates } from "containers/AdminConsole/containers/System/containers/Templates/Templates";
import { ActionTypes } from "containers/AdminConsole/containers/System/containers/ActionTypes";
import { Notifications as ConsoleSystemNotifications } from "containers/AdminConsole/containers/System/containers/Notifications";
import { LoginHistory } from "containers/AdminConsole/containers/Logs/LoginHistory";
import { Notifications as ConsoleLogsNotifications } from "containers/AdminConsole/containers/Logs/Notifications";
import { Activities } from "containers/AdminConsole/containers/Logs/Activities";
import { NewCompany } from "containers/AdminConsole/containers/Companies/containers/NewCompany/NewCompany";
import { CompanyDetails } from "containers/AdminConsole/containers/Companies/containers/CompanyDetails/CompanyDetails";
import { InviteUser } from "containers/AdminConsole/containers/Companies/containers/InviteUser/InviteUser";
import { UserDetails } from "containers/AdminConsole/containers/Companies/containers/UserDetails/UserDetails";
import { ContractTypeDetails } from "containers/AdminConsole/containers/System/containers/ContractTypes/ContractTypeDetails/ContractTypeDetails";
import { NewProject } from "containers/AdminConsole/containers/Projects/NewProject/NewProject";
import { ProjectDetails } from "containers/AdminConsole/containers/Projects/ProjectDetails/ProjectDetails";
import { AuthGuardedRoute } from "components/AuthGuardedRoute";
import { Login } from "containers/Login/Login";
import { Projects } from "containers/Projects/Projects";
import { SetNewPassword } from "containers/ResetPassword/SetNewPassword/SetNewPassword";
import { ResetPassword } from "containers/ResetPassword/ResetPassword";
import { Support } from "containers/Support/Support";
import { TermsOfUse } from "containers/TermsOfUse/TermsOfUse";
import { NewContract } from "containers/AdminConsole/containers/Projects/NewContract/NewContract";
import { ContractDetails } from "containers/AdminConsole/containers/Projects/ContractDetails/ContractDetails";
import { NewContractBinding } from "containers/AdminConsole/containers/Projects/ContractDetails/NewContractBinding/NewContractBinding";
import { ContractBindingDetails } from "containers/AdminConsole/containers/Projects/ContractDetails/ContractBindingDetails/ContractBindingDetails";
import { Settings } from "containers/Settings/Settings";
import { Passwords } from "containers/Settings/containers/PasswordsSettings/PasswordsSettings";
import { NotificationsSettings } from "containers/Settings/containers/NotificationsSettings/NotificationsSettings";
import { ProductDetails } from "containers/AdminConsole/containers/System/containers/Products/ProductDetails/ProductDetails";
import { SchemaDetails } from "containers/AdminConsole/containers/System/containers/Products/SchemaDetails/SchemaDetails";
import { StatusCollectionDetails } from "containers/AdminConsole/containers/System/containers/Products/StatusCollectionDetails/StatusCollectionDetails";
import { StatusOptionDetails } from "containers/AdminConsole/containers/System/containers/Products/StatusOptionDetails/StatusOptionDetails";
import { NewProductInstance } from "containers/AdminConsole/containers/Projects/ContractDetails/NewProductInstance/NewProductInstance";
import { ContractProductInstanceDetails } from "containers/AdminConsole/containers/Projects/ContractDetails/ContractProductInstanceDetails/ContractProductInstanceDetails";
import { NewRiskEventItem } from "containers/Projects/containers/NewRiskEventItem/NewRiskEventItem";
import { RiskItemDetails } from "containers/Projects/containers/RiskDetails/RiskDetails";
import { EditRisk } from "containers/Projects/containers/EditRisk/EditRisk";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { Effect } from "containers/AdminConsole/containers/System/containers/Effect/Effect";
import { EffectPresetDetails } from "containers/AdminConsole/containers/System/containers/Effect/EffectPresetDetails";
import { useMemo } from "react";
import { NewTemplate } from "containers/AdminConsole/containers/System/containers/Templates/NewTemplate/NewTemplate";
import { TemplateDetails } from "containers/AdminConsole/containers/System/containers/Templates/TemplateDetails/TemplateDetails";
import { DocumentTemplateRouter } from "containers/AdminConsole/containers/System/containers/Templates/DocumentTemplateRouter/DocumentTemplateRouter";
import { EarlyWarningDetails } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails";
import { NewEarlyWarning } from "containers/Projects/containers/NewEarlyWarning/NewEarlyWarning";
import { SimpleLookups } from "containers/AdminConsole/containers/System/containers/SimpleLookups/SimpleLookups";
import { NewLookupCollection } from "containers/AdminConsole/containers/System/containers/SimpleLookups/NewLookupCollection";
import { LookupCollectionDetails } from "containers/AdminConsole/containers/System/containers/SimpleLookups/LookupCollectionDetails/LookupCollectionDetails";
import { EventDetails } from "containers/Projects/containers/EventDetails/EventDetails";
import { EditEvent } from "containers/Projects/containers/EditEvent/EditEvent";
import { NewInstruction } from "containers/Projects/containers/NewInstruction/NewInstruction";
import { InstructionDetails } from "containers/Projects/containers/InstructionDetails/InstructionDetails";
import { CustomSimpleLookups } from "containers/AdminConsole/containers/System/containers/CustomSimpleLookups/CustomSimpleLookups";
import { NewCustomLookupCollection } from "containers/AdminConsole/containers/System/containers/CustomSimpleLookups/NewCustomLookupCollection";
import { CustomLookupCollectionDetails } from "containers/AdminConsole/containers/System/containers/CustomSimpleLookups/CustomLookupCollectionDetails/CustomLookupCollectionDetails";
import { DailyDiary } from "containers/Projects/containers/DailyDiary/DailyDiary";
import { NewAppPaths } from "helpers/paths/paths";
import { NotFound } from "containers/NotFound/NotFound";
import { NewAuthorizationWorkflow } from "containers/AdminConsole/containers/Projects/NewAuthorizationWorkflow/NewAuthorizationWorkflow";
import { AuthorizationWorkflowDetails } from "containers/AdminConsole/containers/Projects/AuthorizationWorkflowDetails/AuthorizationWorkflowDetails";
import { Home } from "containers/Home/Home";
import { AuthorizationAuditTrailDetails } from "containers/AdminConsole/containers/Projects/AuthorizationAuditTrailDetails/AuthorizationAuditTrailDetails";
import { MFA } from "containers/MFA/MFA";

export const Router = () => {
  const privateRoutes = useMemo(() => {
    return (
      <>
        {/* TODO: uncomment when adding back reporting */}
        {/* <Route
          path={NewAppPaths.authorized.Reporting}
          element={
            <AuthGuardedRoute>
              <Reporting />
            </AuthGuardedRoute>
          }
        /> */}
        <Route path={NewAppPaths.authorized.NotFound} element={<NotFound />} />
        <Route
          path={NewAppPaths.authorized.AdminConsole.path}
          element={
            <AuthGuardedRoute>
              <AdminConsole />
            </AuthGuardedRoute>
          }
        >
          <Route
            path={NewAppPaths.authorized.AdminConsole.children.Companies.path}
            element={
              <AuthGuardedRoute>
                <Companies />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Companies.children
                .NewCompany
            }
            element={
              <AuthGuardedRoute>
                <NewCompany />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Companies.children
                .CompanyDetails.path
            }
            element={
              <AuthGuardedRoute>
                <CompanyDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Companies.children
                .CompanyDetails.children.InviteUser.path
            }
            element={
              <AuthGuardedRoute>
                <InviteUser />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Companies.children
                .CompanyDetails.children.UserDetails.path
            }
            element={
              <AuthGuardedRoute>
                <UserDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={NewAppPaths.authorized.AdminConsole.children.Projects.path}
            element={
              <AuthGuardedRoute>
                <AdminProjects />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .ProjectDetails.path
            }
            element={
              <AuthGuardedRoute>
                <ProjectDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .ProjectDetails.children.NewContract.path
            }
            element={
              <AuthGuardedRoute>
                <NewContract />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .ContractDetails.path
            }
            element={
              <AuthGuardedRoute>
                <ContractDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .ProjectDetails.children.NewAuthorizationWorkflow.path
            }
            element={
              <AuthGuardedRoute>
                <NewAuthorizationWorkflow />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .AuthorizationWorkflowDetails.path
            }
            element={
              <AuthGuardedRoute>
                <AuthorizationWorkflowDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .AuthorizationAuditTrailDetails.path
            }
            element={
              <AuthGuardedRoute>
                <AuthorizationAuditTrailDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .ContractDetails.children.BindingDetails.path
            }
            element={
              <AuthGuardedRoute>
                <ContractBindingDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .ContractDetails.children.ProductInstanceDetails.path
            }
            element={
              <AuthGuardedRoute>
                <ContractProductInstanceDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .ContractDetails.children.NewBinding.path
            }
            element={
              <AuthGuardedRoute>
                <NewContractBinding />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .ContractDetails.children.NewProductInstance.path
            }
            element={
              <AuthGuardedRoute>
                <NewProductInstance />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Projects.children
                .NewProject
            }
            element={
              <AuthGuardedRoute>
                <NewProject />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .ContractTypes
            }
            element={
              <AuthGuardedRoute>
                <ContractTypes />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .ContractTypeDetails.path
            }
            element={
              <AuthGuardedRoute>
                <ContractTypeDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .Products.path
            }
            element={
              <AuthGuardedRoute>
                <AdminSystemProducts />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .Products.children.ProductDetails.path
            }
            element={
              <AuthGuardedRoute>
                <ProductDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .Products.children.ProductDetails.children.SchemaDetails.path
            }
            element={
              <AuthGuardedRoute>
                <SchemaDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .Products.children.ProductDetails.children
                .StatusCollectionDetails.path
            }
            element={
              <AuthGuardedRoute>
                <StatusCollectionDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .Products.children.ProductDetails.children
                .StatusCollectionDetails.children.CollectionOptionDetails.path
            }
            element={
              <AuthGuardedRoute>
                <StatusOptionDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children.Terms
            }
            element={
              <AuthGuardedRoute>
                <Terms />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .Templates
            }
            element={
              <AuthGuardedRoute>
                <Templates />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .NewTemplate
            }
            element={
              <AuthGuardedRoute>
                <NewTemplate />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .DocumentTemplateRouter
            }
            element={
              <AuthGuardedRoute>
                <DocumentTemplateRouter />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .TemplateDetails.path
            }
            element={
              <AuthGuardedRoute>
                <TemplateDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .ActionTypes
            }
            element={
              <AuthGuardedRoute>
                <ActionTypes />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .Notifications
            }
            element={
              <AuthGuardedRoute>
                <ConsoleSystemNotifications />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .LookupsEffect.path
            }
            element={
              <AuthGuardedRoute>
                <Effect />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .SimpleLookups
            }
            element={
              <AuthGuardedRoute>
                <SimpleLookups />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .CustomSimpleLookups
            }
            element={
              <AuthGuardedRoute>
                <CustomSimpleLookups />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .NewLookupCollection
            }
            element={
              <AuthGuardedRoute>
                <NewLookupCollection />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .NewCustomLookupCollection
            }
            element={
              <AuthGuardedRoute>
                <NewCustomLookupCollection />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .LookupCollectionDetails.path
            }
            element={
              <AuthGuardedRoute>
                <LookupCollectionDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .CustomLookupCollectionDetails.path
            }
            element={
              <AuthGuardedRoute>
                <CustomLookupCollectionDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.System.children
                .LookupsEffect.children.EffectPreset.path
            }
            element={
              <AuthGuardedRoute>
                <EffectPresetDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Logs.children
                .LoginHistory
            }
            element={
              <AuthGuardedRoute>
                <LoginHistory />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Logs.children
                .Notifications
            }
            element={
              <AuthGuardedRoute>
                <ConsoleLogsNotifications />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.AdminConsole.children.Logs.children
                .Activities
            }
            element={
              <AuthGuardedRoute>
                <Activities />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={NewAppPaths.authorized.AdminConsole.children.Support}
            element={
              <AuthGuardedRoute>
                <AdminSupport />
              </AuthGuardedRoute>
            }
          />
        </Route>
        <Route
          path={NewAppPaths.authorized.Settings.path}
          element={
            <AuthGuardedRoute>
              <Settings />
            </AuthGuardedRoute>
          }
        >
          {/* <Route
          path="profile"
          element={
            <AuthGuardedRoute>
              <Profile />
            </AuthGuardedRoute>
          }
        /> */}
          <Route
            path={NewAppPaths.authorized.Settings.children.ProfileEdit}
            element={
              <AuthGuardedRoute>
                <ProfileEdit />
              </AuthGuardedRoute>
            }
          />
          {/* <Route
          path="project"
          element={
            <AuthGuardedRoute>
              <Profile />
            </AuthGuardedRoute>
          }
        /> */}
          <Route
            path={NewAppPaths.authorized.Settings.children.ChangePassword}
            element={
              <AuthGuardedRoute>
                <Passwords />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={NewAppPaths.authorized.Settings.children.Notifications}
            element={
              <AuthGuardedRoute>
                <NotificationsSettings />
              </AuthGuardedRoute>
            }
          />
        </Route>

        {/* NON ADMIN TOP LEVEL PAGES */}
        <Route
          path={NewAppPaths.authorized.Projects.path}
          element={
            <AuthGuardedRoute>
              <Projects />
            </AuthGuardedRoute>
          }
        >
          <Route
            path={NewAppPaths.authorized.Projects.children.NewRisk.path}
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.Add]}
                perProductInstance
              >
                <NewRiskEventItem />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={NewAppPaths.authorized.Projects.children.RiskDetails.path}
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.View]}
                perProductInstance
              >
                <RiskItemDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={NewAppPaths.authorized.Projects.children.NewEvent.path}
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.Add]}
                perProductInstance
              >
                <NewRiskEventItem isRisk={false} />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={NewAppPaths.authorized.Projects.children.NewEarlyWarning.path}
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.Add]}
                perProductInstance
              >
                <NewEarlyWarning />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={NewAppPaths.authorized.Projects.children.NewInstruction.path}
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.Add]}
                perProductInstance
              >
                <NewInstruction />
              </AuthGuardedRoute>
            }
          />

          <Route
            path={NewAppPaths.authorized.Projects.children.EventDetails.path}
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.View]}
                perProductInstance
              >
                <EventDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.Projects.children.EarlyWarningDetails.path
            }
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.View]}
                perProductInstance
              >
                <EarlyWarningDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.Projects.children.InstructionDetails.path
            }
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.View]}
                perProductInstance
              >
                <InstructionDetails />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={
              NewAppPaths.authorized.Projects.children.DailyDiaryDetails.path
            }
            element={
              <AuthGuardedRoute
                requiredPermissions={[PermissionEnum.View]}
                perProductInstance
              >
                <DailyDiary />
              </AuthGuardedRoute>
            }
          />
          {/* TODO - why not adding requiredPermissions just like for new Item? If not possible, document it */}
          <Route
            path={NewAppPaths.authorized.Projects.children.EditRisk.path}
            element={
              <AuthGuardedRoute>
                <EditRisk />
              </AuthGuardedRoute>
            }
          />
          <Route
            path={NewAppPaths.authorized.Projects.children.EditEvent.path}
            element={
              <AuthGuardedRoute>
                <EditEvent />
              </AuthGuardedRoute>
            }
          />
        </Route>

        <Route
          path={NewAppPaths.authorized.Home}
          element={
            <AuthGuardedRoute>
              <Home />
            </AuthGuardedRoute>
          }
        />
        {/* STOP - NON ADMIN TOP LEVEL PAGES */}
      </>
    );
  }, []);

  const publicRoutes = useMemo(() => {
    return (
      <>
        <Route path={NewAppPaths.nonAuthorized.Login} element={<Login />} />
        <Route
          path={NewAppPaths.nonAuthorized.ResetPassword}
          element={<ResetPassword />}
        />
        <Route
          path={NewAppPaths.nonAuthorized.SetNewPassowrd}
          element={<SetNewPassword />}
        />
        <Route path={NewAppPaths.nonAuthorized.MFA} element={<MFA />} />
        <Route
          path={NewAppPaths.nonAuthorized.TermsOfUse}
          element={<TermsOfUse />}
        />
        <Route
          path={NewAppPaths.nonAuthorized.Register}
          element={<Register />}
        />
        <Route path={NewAppPaths.nonAuthorized.Support} element={<Support />} />
      </>
    );
  }, []);

  return (
    <Routes>
      {publicRoutes}
      {privateRoutes}
      <Route
        path="/"
        element={<Navigate replace to={NewAppPaths.authorized.Home} />}
      />
      <Route
        path="*"
        element={<Navigate replace to={NewAppPaths.authorized.NotFound} />}
      />
    </Routes>
  );
};
