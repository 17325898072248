import { ApolloQueryResult, FetchPolicy } from "@apollo/client";
import {
  AuthenticationData,
  AuthenticationDataQuery,
  AuthenticationDataQueryVariables,
  Exact,
  GlobalConfiguration,
  GlobalConfigurationQuery,
  GlobalConfigurationQueryVariables,
  ProductRoleAction,
  ProductRoleActionsQuery,
  ProductRoleActionsQueryVariables,
  User,
} from "generated/graphql";
import { productRoleActionsQuery } from "graphql/queries/productRoleActions";
import {
  createContext,
  useMemo,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { AuthContext } from "providers/auth.context";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { globalConfigurationQuery } from "graphql/queries/globalConfiguration.query";
import { authenticationDataQuery } from "graphql/queries/authenticationData.query";
import { useIntercom } from "react-use-intercom";
import { getUserName, noop } from "helpers/miscelaneous";

const localDefaultGlobalConfiguration = { maxUploadFileSize: 50 };

export type GlobalContextType = {
  authenticatedUser?: User;
  isAdmin?: boolean;
  allProductRolesActions?: ProductRoleAction[];
  loading?: boolean;
  globalConfiguration: GlobalConfiguration;
  refetchAuthenticatedUser: (
    variables?:
      | Exact<{
          [key: string]: never;
        }>
      | undefined,
    fetchPolicy?: FetchPolicy | undefined
  ) => Promise<ApolloQueryResult<AuthenticationDataQuery>>;
  reset: () => void;
};

export const GlobalContext = createContext<GlobalContextType>({
  authenticatedUser: undefined,
  allProductRolesActions: undefined,
  loading: false,
  refetchAuthenticatedUser: noop as any,
  globalConfiguration: localDefaultGlobalConfiguration,
  reset: noop,
});

export const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { boot } = useIntercom();
  const [localAuthenticationData, setLocalAuthenticationData] =
    useState<AuthenticationData>();

  const [
    getAuthenticationData,
    { data: authenticationData, loading: authenticationDataLoading },
  ] = useGraphLazyQueryLite<
    AuthenticationDataQuery,
    AuthenticationDataQueryVariables
  >(authenticationDataQuery);

  const [
    getAllProductRolesActions,
    { data: allProductRolesActions, loading: getAllProductRolesActionsLoading },
  ] = useGraphLazyQueryLite<
    ProductRoleActionsQuery,
    ProductRoleActionsQueryVariables
  >(productRoleActionsQuery);

  const [
    getGlobalConfiguration,
    { data: globalConfiguration, loading: globalConfigurationLoading },
  ] = useGraphLazyQueryLite<
    GlobalConfigurationQuery,
    GlobalConfigurationQueryVariables
  >(globalConfigurationQuery);

  const reset = useCallback(() => {
    setLocalAuthenticationData(undefined);
  }, []);

  const refetchAuthenticatedUser = useCallback(
    (
      variables?:
        | Exact<{
            [key: string]: never;
          }>
        | undefined,
      fetchPolicy: FetchPolicy = "network-only"
    ) => {
      return getAuthenticationData(variables ?? undefined, fetchPolicy);
    },
    [getAuthenticationData]
  );

  const globalContextValue = useMemo(
    () => ({
      authenticatedUser: localAuthenticationData?.user,
      isAdmin: localAuthenticationData?.isAdmin,
      allProductRolesActions: allProductRolesActions?.data.productRoleActions
        .items as ProductRoleAction[],
      globalConfiguration:
        globalConfiguration?.data.globalConfiguration ??
        localDefaultGlobalConfiguration,
      loading:
        authenticationDataLoading ||
        getAllProductRolesActionsLoading ||
        globalConfigurationLoading,
      refetchAuthenticatedUser,
      reset,
    }),
    [
      localAuthenticationData,
      allProductRolesActions,
      authenticationDataLoading,
      getAllProductRolesActionsLoading,
      globalConfigurationLoading,
      globalConfiguration,
      refetchAuthenticatedUser,
      reset,
    ]
  );

  useEffect(() => {
    if (isLoggedIn) {
      getAllProductRolesActions();
      getGlobalConfiguration();
      getAuthenticationData(undefined, "cache-first");
    }
  }, [
    isLoggedIn,
    getAllProductRolesActions,
    getGlobalConfiguration,
    getAuthenticationData,
  ]);

  useEffect(() => {
    setLocalAuthenticationData(
      authenticationData?.data
        .authenticationData as unknown as AuthenticationData
    );
  }, [authenticationData]);

  useEffect(() => {
    if (authenticationData?.data.authenticationData) {
      const user = authenticationData.data.authenticationData.user;
      const userHash =
        authenticationData?.data.authenticationData.intercomIdentityHash;
      const dateRegisteredUnixTimestamp =
        new Date(user.registeredDate).getTime() / 1000;

      boot({
        name: getUserName(user as User),
        email: user.email,
        phone: user.mobileNumber,
        userHash,
        createdAt: dateRegisteredUnixTimestamp,
        company: {
          companyId: user.companyId,
          name: user.company.registeredName,
        },
        customAttributes: {
          "Date of birth": new Date(user.dateOfBirth).getTime() / 1000,
          "Job title": user.jobTitle,
          "Alternative phone": user.alternateNumber,
          "Uses latest version": true,
        },
      });
    }
  }, [authenticationData, boot]);

  return (
    <GlobalContext.Provider value={globalContextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
