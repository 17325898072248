import { Box, css, styled } from "@mui/material";
import { Status } from "types/decl";
import { unofficialThemeColors } from "theme/extendedTheme";

export type StatusIndicatorProps = {
  status: Status;
};

export const StatusIndicator = styled(Box)<{ status: Status }>(
  ({ theme, status }) => {
    const getStatusStyle = (status: Status) => {
      switch (status) {
        case Status.Active:
          return theme.palette.success.main;
        case Status.Offline:
        case Status.Suspended:
          return unofficialThemeColors.amberDarker;
        case Status.Closed:
        case Status.Removed:
        default:
          return theme.palette.grey[700];
      }
    };

    return css`
      width: 6px;
      height: 12px;
      background-color: ${getStatusStyle(status)};
      border-radius: 12px;
    `;
  }
);
