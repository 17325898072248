import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Collapse,
  Typography,
  useTheme,
  Link,
} from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList, ListItem } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { FormPublicApi } from "types/decl";
import {
  AddDocumentTemplateInput,
  ChangeDocumentTemplateStatusMutation,
  ChangeDocumentTemplateStatusMutationVariables,
  DocumentTemplate,
  DocumentTemplateQuery,
  DocumentTemplateQueryVariables,
  DocumentTemplateStatus,
  EditDocumentTemplateInput,
  EditDocumentTemplateMutation,
  EditDocumentTemplateMutationVariables,
  User,
} from "generated/graphql";
import { changeDocumentTemplateStatusMutation } from "graphql/mutations/changeDocumentTemplateStatus";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useNavigateBack } from "hooks/useNavigateBack";
import { FileCode } from "phosphor-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DocumentTemplateForm } from "../components/DocumentTemplateForm/DocumentTemplateForm";
import { DocumentTemplatePreview } from "../components/DocumentTemplatePreview";
import {
  documentTemplateQuery,
  editDocumentTemplateMutation,
} from "./TemplateDetails.query";
import { getUserName } from "helpers/miscelaneous";

export const TemplateDetails = () => {
  const { t } = useTranslation();
  const handleNavigateBack = useNavigateBack();
  const theme = useTheme();
  const { templateId } = useParams();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<DocumentTemplateStatus>[];

  const [showDetails, setShowDetails] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const updatedTemplateDetails = useRef<DocumentTemplate>();
  const formRef = useRef<FormPublicApi>(null);

  const {
    data: documentTemplate,
    refetch: refetchDocumentTemplate,
    loading: getDocumentTemplateLoading,
  } = useGraphQuery<DocumentTemplateQuery, DocumentTemplateQueryVariables>(
    documentTemplateQuery,
    {
      variables: { id: templateId! },
    }
  );

  const [
    changeDocumentTemplateStatus,
    { loading: changeDocumentTemplateStatusLoading },
  ] = useGraphMutation<
    ChangeDocumentTemplateStatusMutation,
    ChangeDocumentTemplateStatusMutationVariables
  >(
    changeDocumentTemplateStatusMutation,
    {
      update: () => {
        refetchDocumentTemplate();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.documentTemplate"),
    })
  );

  const [editDocumentTemplate, { loading: editDocumentTemplateLoading }] =
    useGraphMutation<
      EditDocumentTemplateMutation,
      EditDocumentTemplateMutationVariables
    >(
      editDocumentTemplateMutation,
      {
        update: () => {
          refetchDocumentTemplate();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.documentTemplate"),
      })
    );

  const handleStatusChange = useCallback(
    (newStatus: string) => {
      changeDocumentTemplateStatus({
        variables: {
          id: templateId!,
          status: newStatus as DocumentTemplateStatus,
        },
      });
    },
    [templateId, changeDocumentTemplateStatus]
  );

  const handleDocumentTemplateChange = useCallback(
    (data: AddDocumentTemplateInput | DocumentTemplate) => {
      updatedTemplateDetails.current = data as DocumentTemplate;
    },
    []
  );

  const handleEditClick = useCallback(() => {
    setReadOnly(false);
  }, []);

  const handleCancelClick = () => {
    formRef.current?.reset();
    setReadOnly(true);
  };

  const handleSaveClick = useCallback(async () => {
    if (!formRef.current?.validate()) {
      return;
    }

    const documentTemplateUpdateData: EditDocumentTemplateInput = {
      id: updatedTemplateDetails.current!.id,
      name: updatedTemplateDetails.current!.name,
      description: updatedTemplateDetails.current!.description,
      content: updatedTemplateDetails.current!.content,
    };

    const { errors } = await editDocumentTemplate({
      variables: {
        input: documentTemplateUpdateData,
      },
    });

    if (!errors) {
      setReadOnly(true);
    }
  }, [editDocumentTemplate]);

  const creatorName = useMemo(
    () =>
      getUserName(
        documentTemplate?.documentTemplate.creator as User | undefined
      ),
    [documentTemplate]
  );

  const documentTemplateObj = useMemo(() => {
    const toReturn: ListItem[] = [];

    if (!documentTemplate?.documentTemplate) {
      return toReturn;
    }

    return [
      {
        id: "name",
        label: t("common.labels.name"),
        value: documentTemplate.documentTemplate.name,
      },
      {
        id: "description",
        label: t("common.labels.description"),
        value: documentTemplate.documentTemplate.description,
      },
    ];
  }, [documentTemplate, t]);

  return (
    <Box>
      <EntityDetailsHeader
        status={documentTemplate?.documentTemplate.status}
        statusOptions={statusOptions}
        title={documentTemplate?.documentTemplate.name || ""}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={handleNavigateBack}>
              <Typography variant="body2">
                {t("AdminConsole.Templates.labels.templates")}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        creator={creatorName || ""}
        dateCreated={documentTemplate?.documentTemplate.dateCreated}
        loading={
          getDocumentTemplateLoading || changeDocumentTemplateStatusLoading
        }
        onStatusChange={handleStatusChange}
      />
      <CollapsibleHeader
        title={t("common.labels.details")}
        collapsed={!showDetails}
        icon={
          <FileCode
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        onSaveActionLoading={
          editDocumentTemplateLoading || changeDocumentTemplateStatusLoading
        }
        editMode={!readOnly}
        onCancel={handleCancelClick}
        onEdit={handleEditClick}
        onSave={handleSaveClick}
      />
      <PageContentContainer maxWidth="100%">
        <Collapse in={showDetails}>
          {getDocumentTemplateLoading ? (
            <CircularProgress />
          ) : readOnly ? (
            <Box display="flex" flexDirection="column">
              <Box maxWidth="1300px">
                <DetailsList
                  loading={getDocumentTemplateLoading}
                  entity={documentTemplateObj}
                />
              </Box>
              <Box mt={4} display="flex" justifyContent="center">
                <Box
                  width="80%"
                  p={4}
                  sx={{ border: "1px solid #ccc", borderRadius: "8px" }}
                >
                  <DocumentTemplatePreview
                    documentTemplateHTML={
                      documentTemplate!.documentTemplate.content
                    }
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <DocumentTemplateForm
              documentTemplate={
                documentTemplate!.documentTemplate as DocumentTemplate
              }
              onChange={handleDocumentTemplateChange}
              ref={formRef}
            />
          )}
        </Collapse>
      </PageContentContainer>
    </Box>
  );
};
