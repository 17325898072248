import React, { useRef, useState } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Button, Typography } from "@mui/material";

export type SplitButtonOptionType = {
  label: string;
  icon?: React.ReactNode;
};

export type SplitButtonProps = {
  options: SplitButtonOptionType[];
  buttonSize?: "small" | "large" | "medium";
  minWidth?: string;
  disabled?: boolean;
  onClick: (selectedOption: string) => void;
};

export const SplitButton: React.FC<SplitButtonProps> = ({
  options,
  minWidth,
  disabled,
  buttonSize = "large",
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    onClick(options[selectedIndex].label);
  };

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    onClick(options[index].label);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{ boxShadow: "none" }}
      >
        <Button
          onClick={handleClick}
          size={buttonSize}
          sx={{ minWidth }}
          data-testid="split-btn-main"
          disabled={disabled}
        >
          {options[selectedIndex].label}
        </Button>
        <Button
          size={buttonSize}
          disabled={disabled}
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          data-testid="split-btn-menu-trigger"
          sx={{ paddingX: 0 }}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedIndex}
                      disabled={disabled}
                      data-testid={`${option.label}-option`}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={{ minWidth: "140px" }}
                    >
                      {option.icon}
                      <Typography variant="p2" ml={1}>
                        {option.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
