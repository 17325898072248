import { Box, css, styled } from "@mui/material";
import { customShadows } from "theme/extendedTheme";

export const SectionContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "liteVariant",
})<{ liteVariant?: boolean }>(
  ({ liteVariant = false, theme }) => css`
    background: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: ${liteVariant ? "none" : customShadows.cards};
    padding: ${theme.spacing(liteVariant ? 2 : 3)};
  `
);
