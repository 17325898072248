import {
  DraftClaimAgreement,
  DraftClaimDetermination,
  DraftDetailedClaim,
  EditDraftClaimAgreementInput,
  EditDraftClaimDeterminationInput,
  EditDraftDetailedClaimInput,
} from "generated/graphql";
import { attachmentsToAttachmentInputs } from "../../../Attachments/Attachments.utils";

export const draftClaimDataToEditClaimDataInput = (
  draftClaimData?:
    | DraftClaimAgreement
    | DraftDetailedClaim
    | DraftClaimDetermination
):
  | EditDraftClaimAgreementInput
  | EditDraftDetailedClaimInput
  | EditDraftClaimDeterminationInput
  | undefined => {
  if (!draftClaimData) {
    return draftClaimData;
  }

  return {
    attachments: attachmentsToAttachmentInputs(draftClaimData.attachments),
    claimId: draftClaimData.claimId,
    details: draftClaimData.details,
    id: draftClaimData.id,
    price: draftClaimData.price,
    sectionalChanges: draftClaimData.sectionalChanges,
    time: draftClaimData.time,
  };
};
