import { gql } from "@apollo/client";

export const addressFields = gql`
  fragment AddressFields on Address {
    line1
    line2
    city
    provinceState
    country
    code
  }
`;
