import { OnMutationDone } from "types/decl";
import {
  AddDraftClaimAgreementMutation,
  AddDraftClaimAgreementMutationVariables,
  AddDraftClaimDeterminationMutation,
  AddDraftClaimDeterminationMutationVariables,
  AddDraftDetailedClaimMutation,
  AddDraftDetailedClaimMutationVariables,
  EditDraftClaimAgreementMutation,
  EditDraftClaimAgreementMutationVariables,
  EditDraftClaimDeterminationMutation,
  EditDraftClaimDeterminationMutationVariables,
  EditDraftDetailedClaimMutation,
  EditDraftDetailedClaimMutationVariables,
  RemoveDraftClaimAgreementMutation,
  RemoveDraftClaimAgreementMutationVariables,
  RemoveDraftClaimDeterminationMutation,
  RemoveDraftClaimDeterminationMutationVariables,
  RemoveDraftDetailedClaimMutation,
  RemoveDraftDetailedClaimMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../ClaimWidget/ClaimWidget.context";
import {
  addDraftClaimAgreementMutation,
  addDraftClaimDeterminationMutation,
  addDraftDetailedClaimMutation,
  editDraftClaimAgreementMutation,
  editDraftClaimDeterminationMutation,
  editDraftDetailedClaimMutation,
  removeDraftClaimAgreementMutation,
  removeDraftClaimDeterminationMutation,
  removeDraftDetailedClaimMutation,
} from "./DetailedClaimAgreementDeterminationModal.query";

export const useDetailedClaimAgreementDeterminationModal = () => {
  const { claim, isFIDIC99RedYellow } = useContext(ClaimWidgetContext);
  const { t } = useTranslation();

  const [modalVisibility, setModalVisibility] = useState(false);

  const toggleModalVisibility = () => {
    setModalVisibility((state) => !state);
  };

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimAgreementNoticePrompt",
        args: { input: { claimId: claim!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimDeterminationNoticePrompt",
        args: { input: { claimId: claim!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimInterimClaimDetailsPrompt",
        args: { input: { claimId: claim!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimFinalClaimDetailsPrompt",
        args: { input: { claimId: claim!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimAction",
      });
      cache.gc();
    },
    [claim]
  );

  const [addDraftDetailedClaim, { loading: addDraftDetailedClaimLoading }] =
    useGraphMutation<
      AddDraftDetailedClaimMutation,
      AddDraftDetailedClaimMutationVariables
    >(
      addDraftDetailedClaimMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityCreated", {
        entity: t(
          `Projects.Claims.draftDetailedClaim${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    );

  const [editDraftDetailedClaim, { loading: editDraftDetailedClaimLoading }] =
    useGraphMutation<
      EditDraftDetailedClaimMutation,
      EditDraftDetailedClaimMutationVariables
    >(
      editDraftDetailedClaimMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityUpdated", {
        entity: t(
          `Projects.Claims.draftDetailedClaim${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    );

  const [
    removeDraftDetailedClaim,
    { loading: removeDraftDetailedClaimLoading },
  ] = useGraphMutation<
    RemoveDraftDetailedClaimMutation,
    RemoveDraftDetailedClaimMutationVariables
  >(
    removeDraftDetailedClaimMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityDeleted", {
      entity: t(
        `Projects.Claims.draftDetailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
      ),
    })
  );

  const [addDraftClaimAgreement, { loading: addDraftClaimAgreementLoading }] =
    useGraphMutation<
      AddDraftClaimAgreementMutation,
      AddDraftClaimAgreementMutationVariables
    >(
      addDraftClaimAgreementMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityCreated", {
        entity: t(
          `Projects.Claims.draftClaimAgreement${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    );

  const [editDraftClaimAgreement, { loading: editDraftClaimAgreementLoading }] =
    useGraphMutation<
      EditDraftClaimAgreementMutation,
      EditDraftClaimAgreementMutationVariables
    >(
      editDraftClaimAgreementMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityUpdated", {
        entity: t(
          `Projects.Claims.draftClaimAgreement${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    );

  const [
    removeDraftClaimAgreement,
    { loading: removeDraftClaimAgreementLoading },
  ] = useGraphMutation<
    RemoveDraftClaimAgreementMutation,
    RemoveDraftClaimAgreementMutationVariables
  >(
    removeDraftClaimAgreementMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityDeleted", {
      entity: t(
        `Projects.Claims.draftClaimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
      ),
    })
  );

  const [
    addDraftClaimDetermination,
    { loading: addDraftClaimDeterminationLoading },
  ] = useGraphMutation<
    AddDraftClaimDeterminationMutation,
    AddDraftClaimDeterminationMutationVariables
  >(
    addDraftClaimDeterminationMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityCreated", {
      entity: t(
        `Projects.Claims.draftClaimDetermination${
          isFIDIC99RedYellow ? "99RY" : ""
        }`
      ),
    })
  );

  const [
    editDraftClaimDetermination,
    { loading: editDraftClaimDeterminationLoading },
  ] = useGraphMutation<
    EditDraftClaimDeterminationMutation,
    EditDraftClaimDeterminationMutationVariables
  >(
    editDraftClaimDeterminationMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityUpdated", {
      entity: t(
        `Projects.Claims.draftClaimDetermination${
          isFIDIC99RedYellow ? "99RY" : ""
        }`
      ),
    })
  );

  const [
    removeDraftClaimDetermination,
    { loading: removeDraftClaimDeterminationLoading },
  ] = useGraphMutation<
    RemoveDraftClaimDeterminationMutation,
    RemoveDraftClaimDeterminationMutationVariables
  >(
    removeDraftClaimDeterminationMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityDeleted", {
      entity: t(
        `Projects.Claims.draftClaimDetermination${
          isFIDIC99RedYellow ? "99RY" : ""
        }`
      ),
    })
  );

  return {
    modalVisibility,
    toggleModalVisibility,
    addDraftDetailedClaim,
    editDraftDetailedClaim,
    removeDraftDetailedClaim,
    addDraftClaimAgreement,
    editDraftClaimAgreement,
    removeDraftClaimAgreement,
    addDraftClaimDetermination,
    editDraftClaimDetermination,
    removeDraftClaimDetermination,
    loading:
      addDraftDetailedClaimLoading ||
      editDraftDetailedClaimLoading ||
      removeDraftDetailedClaimLoading ||
      addDraftClaimAgreementLoading ||
      editDraftClaimAgreementLoading ||
      removeDraftClaimAgreementLoading ||
      addDraftClaimDeterminationLoading ||
      editDraftClaimDeterminationLoading ||
      removeDraftClaimDeterminationLoading,
  };
};
