import { gql } from "@apollo/client";
import { productItemAuthWorkflowAuditFields } from "containers/Projects/fragments/productItemAuthWorkflowAudit.fragment";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const localCompEventItemQuery = gql`
  ${compEventItemFields}
  ${productItemAuthWorkflowAuditFields}

  query localCompEventItem($id: ID!) {
    compEventItem(id: $id) {
      ...CompEventItemFields
      ongoingAuthorizationAudit {
        ...ProductItemAuthWorkflowAuditFields
      }
    }
  }
`;
