import { ProductInstanceStatus, ProductRole } from "generated/graphql";
import {
  canAccess,
  hasRoleAccess,
} from "helpers/Permissions/Permissions.utils";
import { useContext } from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";

export const useHasAccess = (
  allowedRoles?: ProductRole | ProductRole[],
  requiredPermissions?: string[],
  productInstanceId?: string
): boolean | "unknown" => {
  const { authenticatedUser, allProductRolesActions } =
    useContext(GlobalContext);

  if (!authenticatedUser || !allProductRolesActions) {
    // cannot establish if the user has the required permissions if the permissions and the user themselves haven't been fetched
    return "unknown";
  }

  const requiredPermissionsLowerCased = requiredPermissions?.map((permission) =>
    permission.toLowerCase()
  );
  const requiredPermissionsObjects = requiredPermissionsLowerCased
    ? allProductRolesActions?.filter(
        (action) =>
          requiredPermissionsLowerCased.indexOf(action.name.toLowerCase()) >= 0
      )
    : undefined;

  // TODO: maybe leave from the premise that the user has no access and provide it later.
  // Later edit: setting it to false it causes an infinite loop. The access to some pages is sometimes decided on that very page and this might be the reason why
  // starting with true.
  let permissionGranted = true;

  const allUserRoles =
    authenticatedUser?.roles.items.filter(
      (role) => role.productInstance.status !== ProductInstanceStatus.Offline
    ) || [];
  const userRoles = productInstanceId
    ? allUserRoles.filter(
        (role) => role.productInstanceId === productInstanceId
      )
    : allUserRoles;

  if (requiredPermissionsObjects) {
    permissionGranted = canAccess(userRoles, requiredPermissionsObjects);
  } else if (allowedRoles) {
    permissionGranted = hasRoleAccess(userRoles, allowedRoles);
  }

  return permissionGranted;
};
