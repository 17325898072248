import { Box, Typography } from "@mui/material";

export const DescriptionColumnRenderer: React.FC<{
  description?: string;
}> = ({ description }) => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      {
        <Typography
          variant="p2"
          color="grey.600"
          width="100%"
          flexWrap="wrap"
          whiteSpace="pre-line"
        >
          {description}
        </Typography>
      }
    </Box>
  );
};
