import { Box } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { GridHeader } from "components/GridHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import { DocumentTemplate, DocumentTemplateStatus } from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { NewAppPaths } from "helpers/paths/paths";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useColumns } from "./Templates.constants";
import { useTemplates } from "./useTemplates";
import { adminConsolePageContentDifference } from "containers/AdminConsole/AdminConsole.constants";
import { getUserName, openLocallyOrInNewTab } from "helpers/miscelaneous";
import { excelDateTimeISOFormat } from "constants/constants";

export const Templates = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<DocumentTemplateStatus>[];
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [DocumentTemplateStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const {
    documentTemplates,
    loadMore,
    hasMore,
    fetchDocumentTemplatesLoading,
    changeDocumentTemplateStatus,
    changeDocumentTemplateStatusLoading,
  } = useTemplates();

  const handleExcelExport = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name" },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("common.labels.dateCreated"),
        key: "created",
        numFmt: excelDateTimeISOFormat,
      },
      { header: t("common.labels.createdBy"), key: "creator" },
    ];

    const rows =
      documentTemplates!
        .filter(
          (documentTemplate) =>
            (selectionModel || []).indexOf(documentTemplate.id) >= 0
        )
        .map((documentTemplate) => ({
          ...documentTemplate,
          creator: getUserName(documentTemplate.creator),
          created: documentTemplate
            ? new Date(documentTemplate.dateCreated)
            : "",
        })) || [];

    exportToExcel(t("AdminConsole.Templates.labels.templates"), columns, rows);
  };

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (rowData: GridRowParams<DocumentTemplate>, event) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        const url =
          NewAppPaths.authorized.AdminConsole.children.System.children.TemplateDetails.pathConstructor(
            rowData.row.id
          );
        openLocallyOrInNewTab(url, event, navigate);
      }
    },
    [navigate]
  );

  const handleCreateNewTemplate = () => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.System.children.NewTemplate
    );
  };

  const handleDocumentTemplateStatusChange = useCallback(
    async (row: DocumentTemplate, newStatus: DocumentTemplateStatus) => {
      await changeDocumentTemplateStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeDocumentTemplateStatus]
  );

  const handleRowsScrollEnd = useCallback(() => {
    if (hasMore) {
      loadMore();
    }
  }, [hasMore, loadMore]);

  const columns = useColumns(statusOptions, handleDocumentTemplateStatusChange);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex="1">
      <GridHeader
        title={t("AdminConsole.Templates.labels.templates")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t("AdminConsole.Templates.labels.template").toLowerCase(),
        })}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExcelExport}
        onActionButtonClick={handleCreateNewTemplate}
        sticky
      />
      <Box
        sx={{
          flex: 1,
          width: "100%",
          maxHeight: `calc(100vh - ${adminConsolePageContentDifference}px)`,
        }}
      >
        <StyledDataGrid
          apiRef={gridApiRef}
          rows={documentTemplates}
          columns={columns}
          getRowId={(rowData: DocumentTemplate) => rowData.id}
          loading={
            fetchDocumentTemplatesLoading || changeDocumentTemplateStatusLoading
          }
          onRowSelectionModelChange={setSelectionModel}
          sortingMode="client"
          sortModel={sortingModel}
          onSortModelChange={setSortingModel}
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onRowClick={handleRowClick}
          getCellClassName={(
            params: GridCellParams<any, DocumentTemplate, any>
          ) => {
            return params.row.status === DocumentTemplateStatus.Removed
              ? "greyed-out"
              : "";
          }}
          onRowsScrollEnd={handleRowsScrollEnd}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};
