import * as Excel from "exceljs";
import FileSaver from "file-saver";
import { Workbook, Worksheet } from "exceljs";

export const exportToExcel = (
  workSheetName: string = "worksheet",
  columns: Partial<Excel.Column>[],
  rows: any[],
  fileName?: string
) => {
  const workbook: Workbook = new Excel.Workbook();
  const worksheet: Worksheet = workbook.addWorksheet(workSheetName, {
    properties: {
      defaultColWidth: 20,
    },
  });

  worksheet.columns = columns;

  rows.forEach((row) => {
    worksheet.addRow(row);
  });

  worksheet.getRow(1).font = {
    bold: true,
    color: {
      argb: "000",
    },
  };

  columns.forEach((column) => {
    if (column.numFmt) {
      worksheet.getColumn(column.key!).eachCell((cell) => {
        cell.numFmt = column.numFmt!;
      });
    }
  });

  const blobType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: blobType });
    FileSaver.saveAs(blob, fileName || workSheetName || "export.xlsx");
  });
};
