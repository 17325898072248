import {
  CompEventItem,
  CompEventItemsQuery,
  CompEventItemsQueryVariables,
} from "generated/graphql";
import { compEventItemsQuery } from "graphql/queries/compEventItems.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useMemo } from "react";

export const useCompEventsRegister = () => {
  const [
    fetchCompEventItemsData,
    { data: compEventItemsData, loading: compEventItemsDataLoading },
  ] = useGraphLazyQuery<CompEventItemsQuery, CompEventItemsQueryVariables>(
    compEventItemsQuery
  );

  const items = useMemo(
    () => (compEventItemsData?.compEventItems.items ?? []) as CompEventItem[],
    [compEventItemsData]
  );

  return {
    compEventItemsData: items,
    compEventItemsDataLoading,
    fetchCompEventItemsData,
  };
};
