import { gql } from "@apollo/client";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";

export const authorizationWorkflowActionMappingsQuery = gql`
  ${authorizationWorkflowActionMappingFields}

  query authorizationWorkflowActionMappings($productInstanceId: ID!) {
    authorizationWorkflowActionMappings(productInstanceId: $productInstanceId) {
      items {
        ...AuthorizationWorkflowActionMappingFields
      }
    }
  }
`;
