import { IntercomEvents } from "constants/intercom";
import { getMetadataSet1 } from "containers/Projects/utils/intercom";
import { EarlyWarningItem, ProductType } from "generated/graphql";
import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";

export const useEWDetailsTrackingEvents = () => {
  const { trackEvent } = useIntercom();

  const trackEWDetailsEvent = useCallback(
    (
      trackingEvent: IntercomEvents,
      earlyWarning: EarlyWarningItem,
      extraData?: object
    ) => {
      trackEvent(trackingEvent, {
        ...getMetadataSet1({
          productInstanceId: earlyWarning.productInstance.id,
          productInstanceItemId: earlyWarning.id,
          productInstanceItemName: earlyWarning.title,
          contractFn: earlyWarning.productInstance.contract.friendlyName,
          projectFn: earlyWarning.productInstance.contract.project.friendlyName,
          productType: ProductType.EarlyWarnings,
          version: process.env.REACT_APP_VERSION!,
        }),
        ...extraData,
      });
    },
    [trackEvent]
  );

  return {
    trackEWDetailsEvent,
  };
};
