import { gql } from "@apollo/client";
import { earlyWarningItemLiteFields } from "./earlyWarningItemLite.fragment";
import { claimTypeInfoFields } from "./claimTypeInfo.fragment";

export const compEventHistoryNoticeAnswersFields = gql`
  ${earlyWarningItemLiteFields}
  ${claimTypeInfoFields}

  fragment CompEventHistoryNoticeAnswersFields on CompEventHistoryNoticeAnswers {
    claimTypeId
    earlyWarningId
    claimType {
      ...ClaimTypeInfoFields
    }
    earlyWarning {
      ...EarlyWarningItemLiteFields
    }
  }
`;
