import {
  RecordClaimErrorAgreementNoticeMutation,
  RecordClaimErrorAgreementNoticeMutationVariables,
  SendClaimErrorAgreementNoticeMutation,
  SendClaimErrorAgreementNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  recordClaimErrorAgreementNoticeMutation,
  sendClaimErrorAgreementNoticeMutation,
} from "./NotifyErrorFoundInAgreement.query";
import { OnMutationDone } from "types/decl";

export const useNotifyErrorFoundInAgreement = () => {
  const { t } = useTranslation();
  const { onChange, claim } = useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendClaimErrorAgreementNotice,
    { loading: sendClaimErrorAgreementNoticeLoading },
  ] = useGraphMutation<
    SendClaimErrorAgreementNoticeMutation,
    SendClaimErrorAgreementNoticeMutationVariables
  >(
    sendClaimErrorAgreementNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Claims.errorInAgreement"),
    })
  );

  const [
    recordClaimErrorAgreementNotice,
    { loading: recordClaimErrorAgreementNoticeLoading },
  ] = useGraphMutation<
    RecordClaimErrorAgreementNoticeMutation,
    RecordClaimErrorAgreementNoticeMutationVariables
  >(
    recordClaimErrorAgreementNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Claims.errorInAgreement"),
    })
  );

  return {
    actionLoading:
      sendClaimErrorAgreementNoticeLoading ||
      recordClaimErrorAgreementNoticeLoading,
    sendClaimErrorAgreementNotice,
    recordClaimErrorAgreementNotice,
  };
};
