import {
  GetProjectByIdLiteQuery,
  GetProjectByIdLiteQueryVariables,
  Project,
} from "generated/graphql";
import { useGraphQuery } from "../../../hooks/useGraphQuery";
import { getProjectByIdLiteQuery } from "graphql/queries/projectByIdLite.query";

export const useProjectLite = (projectId: string) => {
  const { data: projectDataLite, loading: projectDataLiteLoading } =
    useGraphQuery<GetProjectByIdLiteQuery, GetProjectByIdLiteQueryVariables>(
      getProjectByIdLiteQuery,
      {
        variables: {
          id: projectId!,
        },
        fetchPolicy: "cache-and-network",
      }
    );

  return {
    projectDataLite: projectDataLite?.project as Project | undefined,
    loading: projectDataLiteLoading,
  };
};
