import { Box, IconButton, Typography, useTheme, BoxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddIcon } from "./Icons/AddIcon";

export type DataGridAddRecordButtonProps = {
  disabled: boolean;
  onClick: () => void;
} & BoxProps;

export const DataGridAddRecordButton: React.FC<
  DataGridAddRecordButtonProps
> = ({ disabled, onClick, ...restBoxProps }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <IconButton
      data-id="add-new-record"
      onClick={onClick}
      disabled={disabled}
      style={{
        background: "transparent",
      }}
    >
      <Box display="flex" alignItems="center" ml={6.5} {...restBoxProps}>
        <AddIcon
          color={
            disabled
              ? theme.palette.action.disabled
              : theme.palette.common.black
          }
        />
        <Typography
          variant="body2"
          ml={1.5}
          color={
            disabled
              ? theme.palette.action.disabled
              : theme.palette.common.black
          }
        >
          {t("common.buttons.addNew")}
        </Typography>
      </Box>
    </IconButton>
  );
};
