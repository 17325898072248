import {
  GridEventListener,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import {
  ClaimAgreement,
  ClaimDetermination,
  DetailedClaim,
  DraftClaimAgreement,
  DraftClaimDetermination,
  DraftDetailedClaim,
} from "generated/graphql";
import { useCallback, useContext, useEffect, useState } from "react";
import { EmptyTableView } from "./EmptyTableView";
import { useColumns } from "./DetailedClaimsAgreementsDeterminationsTable.constants";
import { ClaimWidgetContext } from "../../../ClaimWidget/ClaimWidget.context";

export type ClaimItemsTypes =
  | "DetailedClaim"
  | "ClaimAgreement"
  | "ClaimDetermination";

export type ClaimResolveType =
  | DraftDetailedClaim
  | DetailedClaim
  | DraftClaimAgreement
  | ClaimAgreement
  | DraftClaimDetermination
  | ClaimDetermination;

export type LocalClaimResolveType = { isSelected?: boolean } & ClaimResolveType;

export type DetailedClaimsAgreementsDeterminationsTableProps = {
  contractCurrency: string;
  loading?: boolean;
  items: ClaimResolveType[];
  type: ClaimItemsTypes;
  hasError?: boolean;
  onSelectionChange?: (item?: ClaimResolveType) => void;
  onDelete?: (itemId: string) => void;
  onRowClick?: (item: ClaimResolveType) => void;
};

export const DetailedClaimsAgreementsDeterminationsTable: React.FC<
  DetailedClaimsAgreementsDeterminationsTableProps
> = ({
  loading,
  contractCurrency,
  items,
  type,
  hasError,
  onSelectionChange,
  onDelete,
  onRowClick,
}) => {
  const { isFIDIC99RedYellow } = useContext(ClaimWidgetContext);
  const columns = useColumns(
    contractCurrency,
    type,
    isFIDIC99RedYellow,
    onDelete
  );
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const selectionEnabled = !!onSelectionChange;

  const [rows, setRows] = useState<LocalClaimResolveType[]>(items ?? []);

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (params: GridRowParams<LocalClaimResolveType>) => {
      const item = params.row;

      if (onRowClick) {
        const { isSelected, ...rest } = item;
        onRowClick(rest);
      }
    },
    [onRowClick]
  );

  const handleGridRowSelectionModelChange = useCallback(
    (crtSelection: GridRowSelectionModel) => {
      if (crtSelection.length > 1) {
        const selectionSet = new Set(selectionModel);
        const result = crtSelection.filter((s) => !selectionSet.has(s));

        setSelectionModel(result);
        const selectedItem = items.find((item) => item.id === result[0]);
        onSelectionChange?.(selectedItem);
      } else {
        setSelectionModel(crtSelection);
        const selectedItem = items.find((item) => item.id === crtSelection[0]);
        onSelectionChange?.(selectedItem);
      }
    },
    [selectionModel, items, onSelectionChange]
  );

  useEffect(() => {
    setRows(items ?? []);
  }, [items]);

  return (
    <NewStyledDataGrid
      rows={rows}
      columns={columns}
      getRowId={(rowData: LocalClaimResolveType) => rowData.id}
      loading={loading}
      data-testid={`${type}s-table${selectionEnabled ? "-selectable" : ""}`}
      slots={{
        noRowsOverlay: () => (
          <EmptyTableView type={type} isFIDIC99RedYellow={isFIDIC99RedYellow} />
        ),
      }}
      onRowClick={handleRowClick}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      rowSelectionModel={selectionEnabled ? selectionModel : undefined}
      checkboxSelection={selectionEnabled}
      onRowSelectionModelChange={handleGridRowSelectionModelChange}
      rowSelection={selectionEnabled}
      error={hasError}
      hideFooter
      autoHeight
      disableSelectAll
    />
  );
};
