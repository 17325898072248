import { Box, Fade, CircularProgress } from "@mui/material";
import { InfoIconOutlined } from "components/Icons/InfoIconOutlined";
import { useState } from "react";
import { customShadows } from "theme/extendedTheme";
import { TooltipContainer } from "components/TooltipContainer";

export type ProductItemPreviewContainerProps = {
  loading?: boolean;
  children: React.ReactNode;
};

export const ProductItemPreviewContainer: React.FC<
  ProductItemPreviewContainerProps
> = ({ loading, children }) => {
  const [mouseOnInfoIcon, setMouseOnInfoIcon] = useState(false);

  const handleInfoIconMouseEnter = () => {
    setMouseOnInfoIcon(true);
  };

  const handleInfoIconMouseLeave = () => {
    setMouseOnInfoIcon(false);
  };

  return (
    <Box position="relative" display="flex" lineHeight="20px">
      <InfoIconOutlined
        onMouseEnter={handleInfoIconMouseEnter}
        onMouseLeave={handleInfoIconMouseLeave}
      />
      {mouseOnInfoIcon && (
        <Fade in={mouseOnInfoIcon}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TooltipContainer boxShadow={customShadows.popoverMedium}>
              {children}
            </TooltipContainer>
          )}
        </Fade>
      )}
    </Box>
  );
};
