import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { ClaimActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";
import { useRecordNoticeOfClaimValidityHasLapsedModal } from "./useRecordNoticeOfClaimValidityHasLapsedModal";

export type RecordNoticeOfClaimValidityHasLapsedModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordNoticeOfClaimValidityHasLapsedModal: React.FC<
  RecordNoticeOfClaimValidityHasLapsedModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { t } = useTranslation();
  const { claimPreview, contract } =
    useRecordNoticeOfClaimValidityHasLapsedModal(remarks);
  return (
    <RecordAction
      actionType={ClaimActionType.NotifyClaimNoticeValidityHasLapsed}
      claimPreview={claimPreview}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={remarks}
          label={t("common.labels.remarks")}
          attachments={attachments}
          contractTimezone={contract.timeZone}
        />
      }
      {...restDialogProps}
    />
  );
};
