import { Box, css, styled } from "@mui/material";
import {
  AuthorizationWorkflowAuditStatus,
  AuthorizationWorkflowAuditTrail,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { AuthorizationLevelOverviewRecord } from "./AuthorizationLevelOverviewRecord";
import { ListItemsDivider } from "components/ListItemsDivider";
import React from "react";

export type AuthorizationLevelOverviewProps = {
  authLevel: AuthorizationWorkflowLevel;
  settledTrails: AuthorizationWorkflowAuditTrail[];
  levelStatus: AuthorizationWorkflowAuditStatus;
  authAuditStatus: AuthorizationWorkflowAuditStatus;
  contractTimezone: string;
};

const AuthorizationLevelOverviewContainer = styled(Box)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.grey[300]};
    border-radius: 8px;
    padding: ${theme.spacing(2)} 0;
    background: ${theme.palette.common.white};
  `
);

export const AuthorizationLevelOverview: React.FC<
  AuthorizationLevelOverviewProps
> = ({
  authLevel,
  settledTrails,
  levelStatus,
  authAuditStatus,
  contractTimezone,
}) => {
  return (
    <AuthorizationLevelOverviewContainer>
      {authLevel.users.items.map((user, index) => {
        const crtAuditTrail = settledTrails.find(
          (settledTrail) => settledTrail.creatorId === user.id
        );
        return (
          <React.Fragment key={user.id}>
            <AuthorizationLevelOverviewRecord
              user={user}
              auditTrail={crtAuditTrail}
              levelStatus={levelStatus}
              authAuditStatus={authAuditStatus}
              contractTimezone={contractTimezone}
            />
            {index < authLevel.users.items.length - 1 && (
              <ListItemsDivider
                sx={{ marginTop: 0, marginBottom: 0, borderColor: "grey.100" }}
              />
            )}
          </React.Fragment>
        );
      })}
    </AuthorizationLevelOverviewContainer>
  );
};
