import { Box, Button, Typography, useTheme } from "@mui/material";
import { DownloadIcon } from "components/Icons/DownloadIcon";
import { useTranslation } from "react-i18next";
import { RoundedChip } from "../containers/Projects/components/ProductItemsView/components/ProductItemsHeader";

export type TableHeaderSelectViewProps = {
  selectedItemsCount?: number;
  onClearSelection?: () => void;
  onExportToExcel?: () => void;
};

export const TableHeaderSelectView: React.FC<TableHeaderSelectViewProps> = ({
  selectedItemsCount,
  onClearSelection,
  onExportToExcel,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="48px"
      width="100%"
    >
      <Box display="flex" alignItems="center">
        <Typography
          variant="p2"
          fontWeight={700}
          color={theme.palette.grey[900]}
          mr={1}
        >
          {t("Grid.labels.selected")}
        </Typography>
        <RoundedChip>{selectedItemsCount}</RoundedChip>
        <Box ml={4}>
          <Button variant="text" onClick={onClearSelection}>
            {t("Grid.labels.clearSelection")}
          </Button>
        </Box>
      </Box>
      <Button variant="outlined" size="large" onClick={onExportToExcel}>
        <Box mr={1} display="flex" alignItems="center" justifyContent="center">
          <DownloadIcon />
        </Box>
        {t("common.labels.exportToExcel")}
      </Button>
    </Box>
  );
};
