import { Box, css, styled } from "@mui/material";

export const HeaderContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-top: ${theme.spacing(3)};
    z-index: 1;
    position: sticky;
    top: 0;
    background: ${theme.palette.common.white};
  `
);
