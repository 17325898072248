import { gql } from "@apollo/client";
import { eventItemFields } from "graphql/fragments/eventItem.fragment";

export const draftEventItemsQuery = gql`
  ${eventItemFields}

  query draftEventItems($productInstanceId: ID!) {
    draftEventItems(productInstanceId: $productInstanceId) {
      items {
        ...EventItemFields
      }
    }
  }
`;
