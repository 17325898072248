import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryWorkRecordInput,
  DailyDiaryPresetSection,
  DailyDiaryWorkRecord,
  EditDailyDiaryWorkRecordInput,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { WorkLookups, WorkOptionalColumn } from "../WorkTable/WorkTable";
import { Textarea } from "components/TextArea";
import { workRecordToEditInput } from "../WorkSection.utils";
import { FormErrorLabel } from "components/FormErrorLabel";
import { useWorkRecordFormValidators } from "./useWorkRecordFormValidators";

export type WorkRecordFormProps = {
  workLookups: WorkLookups;
  optionalColumns: WorkOptionalColumn[];
  section: DailyDiaryPresetSection;
  workRecord?: DailyDiaryWorkRecord;
  apiRef?: React.Ref<FormPublicApi>;
  onChange: (
    workRecord: AddDailyDiaryWorkRecordInput | EditDailyDiaryWorkRecordInput
  ) => void;
};

export const defaultFormData: EditDailyDiaryWorkRecordInput = {
  id: "",
  activityOptionId: undefined,
  amount: "" as any,
  areaOptionId: undefined,
  attachments: [],
  description: undefined,
  disciplineOptionId: undefined,
  teamOptionId: undefined,
  unitOfMeasurementOptionId: undefined,
  workTypeOptionId: undefined,
};

export const WorkRecordForm: React.FC<WorkRecordFormProps> = ({
  workRecord,
  optionalColumns,
  workLookups,
  section,
  apiRef,
  onChange,
}) => {
  const { t } = useTranslation();

  const firstFieldRef = useRef<any>(null);

  const [formData, setFormData] = useState<
    AddDailyDiaryWorkRecordInput | EditDailyDiaryWorkRecordInput
  >(workRecord ? workRecordToEditInput(workRecord) : defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const dataValidators = useWorkRecordFormValidators(
    section,
    formData,
    workLookups
  );

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback(
    (
      formData: AddDailyDiaryWorkRecordInput | EditDailyDiaryWorkRecordInput
    ) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [dataValidators]
  );

  const handleDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setFormData((curData) => ({
      ...curData,
      description: event.target.value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string | null>) => {
    setFormData((curData) => ({
      ...curData,
      [event.target.name]: event.target.value ?? "",
    }));

    setFormDataErrors((crtFormDataErrs) => {
      const { [event.target.name]: _, ...rest } = crtFormDataErrs;

      return rest;
    });
  };

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
    firstFieldRef.current.focus();
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [resetForm, formData, validateForm]
  );

  useEffect(() => {
    onChange({
      ...formData,
      ...(formData.amount
        ? { amount: formData.amount }
        : { amount: undefined }),
    });
  }, [onChange, formData]);

  useEffect(() => {
    firstFieldRef.current.focus();
  }, []);

  const isDescriptionRequired = section.fields.find(
    (field) => field.name === "Description"
  )?.isRequired;
  const isShiftRequired = section.fields.find(
    (field) => field.name === "Shift"
  )?.isRequired;
  const isAreaRequired = section.fields.find(
    (field) => field.name === "Area"
  )?.isRequired;
  const isDisciplineRequired = section.fields.find(
    (field) => field.name === "Discipline"
  )?.isRequired;
  const isTeamRequired = section.fields.find(
    (field) => field.name === "Team"
  )?.isRequired;
  const isActivityRequired = section.fields.find(
    (field) => field.name === "Activity"
  )?.isRequired;
  const isWorkTypeRequired = section.fields.find(
    (field) => field.name === "WorkType"
  )?.isRequired;
  const isAmountRequired = section.fields.find(
    (field) => field.name === "Amount"
  )?.isRequired;
  const isUnitOfMeasurementRequired = section.fields.find(
    (field) => field.name === "UnitOfMeasurement"
  )?.isRequired;

  return (
    <Grid container spacing={5}>
      {optionalColumns.includes(WorkOptionalColumn.Description) && (
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Work.description")}
              required={isDescriptionRequired}
            />
            <Textarea
              value={formData.description ?? ""}
              onChange={handleDescriptionChange}
              autoFocus
              error={!!formDataErrors.description}
              ref={firstFieldRef}
              required={isDescriptionRequired}
            />
            {!!formDataErrors.description && (
              <FormErrorLabel
                dataTestId="description-err-msg"
                errorMessage={formDataErrors.description}
              />
            )}
          </FormControl>
        </Grid>
      )}
      {optionalColumns.includes(WorkOptionalColumn.Shift) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Work.shift")}
              required={isShiftRequired}
            />
            <Select
              labelId="shift-select-label"
              id="shift-select"
              value={formData.shiftOptionId ?? ""}
              name="shiftOptionId"
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.shiftOptionId}
              size="small"
              required={isShiftRequired}
            >
              {workLookups.Shift.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.shiftOptionId && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.shiftOptionId}
              </Typography>
            )}
          </FormControl>
        </Grid>
      )}
      {optionalColumns.includes(WorkOptionalColumn.Area) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Work.area")}
              required={isAreaRequired}
            />
            <Select
              labelId="area-select-label"
              id="area-select"
              value={formData.areaOptionId ?? ""}
              name="areaOptionId"
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.areaOptionId}
              size="small"
              required={isAreaRequired}
            >
              {workLookups.Area.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.areaOptionId && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.areaOptionId}
              </Typography>
            )}
          </FormControl>
        </Grid>
      )}
      {optionalColumns.includes(WorkOptionalColumn.Discipline) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Work.discipline")}
              required={isDisciplineRequired}
            />
            <Select
              labelId="discipline-select-label"
              id="discipline-select"
              value={formData.disciplineOptionId ?? ""}
              name="disciplineOptionId"
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.disciplineOptionId}
              size="small"
              required={isDisciplineRequired}
            >
              {workLookups.Discipline.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.disciplineOptionId && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.disciplineOptionId}
              </Typography>
            )}
          </FormControl>
        </Grid>
      )}
      {optionalColumns.includes(WorkOptionalColumn.Team) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Work.team")}
              required={isTeamRequired}
            />
            <Select
              labelId="team-select-label"
              id="team-select"
              name="teamOptionId"
              value={formData.teamOptionId ?? ""}
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.teamOptionId}
              size="small"
              required={isTeamRequired}
            >
              {workLookups.Team.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.teamOptionId && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.teamOptionId}
              </Typography>
            )}
          </FormControl>
        </Grid>
      )}
      {optionalColumns.includes(WorkOptionalColumn.Activity) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Work.activity")}
              required={isActivityRequired}
            />
            <Select
              labelId="activity-select-label"
              id="activity-select"
              value={formData.activityOptionId ?? ""}
              name="activityOptionId"
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.activityOptionId}
              size="small"
              required={isActivityRequired}
            >
              {workLookups.Activity.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.activityOptionId && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.activityOptionId}
              </Typography>
            )}
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.Work.workType")}
            required={isWorkTypeRequired}
          />
          <Select
            labelId="workType-select-label"
            id="workType-select"
            value={formData.workTypeOptionId ?? ""}
            name="workTypeOptionId"
            onChange={handleSelectChange}
            variant="outlined"
            error={!!formDataErrors.workTypeOptionId}
            size="small"
            required={isWorkTypeRequired}
          >
            {workLookups.WorkType.options.items.map((lkpOption) => (
              <MenuItem key={lkpOption.id} value={lkpOption.id}>
                {lkpOption.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Work.amount")}
          required={isAmountRequired}
        />
        <TextField
          fullWidth
          name="amount"
          value={formData.amount}
          onChange={handleTextFieldChange}
          required={isAmountRequired}
          type="number"
          variant="outlined"
          size="small"
          InputProps={{ inputProps: { min: 0 } }}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.Work.unitOfMeasurement")}
            required={isUnitOfMeasurementRequired}
          />
          <Select
            labelId="unitOfMeasurement-select-label"
            id="unitOfMeasurement-select"
            value={formData.unitOfMeasurementOptionId ?? ""}
            name="unitOfMeasurementOptionId"
            onChange={handleSelectChange}
            variant="outlined"
            error={!!formDataErrors.unitOfMeasurementOptionId}
            size="small"
            required={isUnitOfMeasurementRequired}
          >
            {workLookups.UnitOfMeasurement.options.items.map((lkpOption) => (
              <MenuItem key={lkpOption.id} value={lkpOption.id}>
                {lkpOption.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
