import { Stack, Typography } from "@mui/material";
import { CountryData } from "helpers/countries/countries.decl";
import ReactCountryFlag from "react-country-flag";

export const FlagCountryName: React.FC<{
  country: CountryData;
  hideSelectedCountryName?: boolean;
}> = ({ country, hideSelectedCountryName = false }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" tabIndex={-1}>
      <ReactCountryFlag
        countryCode={country.countryCodeA2}
        svg
        style={{
          fontSize: "1.75em",
          lineHeight: "1.75em",
        }}
      />
      {!hideSelectedCountryName && (
        <Typography variant="p2">{country.name}</Typography>
      )}
    </Stack>
  );
};
