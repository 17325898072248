import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  ColumnPlacementType,
  ItemDataInput,
  ItemDataSectionInput,
  ProductSchema,
  ProductType,
  SchemaSectionStatus,
  User,
} from "generated/graphql";
import { useEffect, useMemo, useRef, useState } from "react";
import { SchemaInterpretorContextProvider } from "./SchemaInterpretor.context";
import { Section } from "./Section/Section";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { MetadataFooter } from "./MetadataFooter/MetadataFooter";

export type ItemMetadata = {
  dateModified: string;
  dateCreated: string;
  owner: User;
  creator: User;
  productItemId: string;
  dateSent?: string;
  offline?: boolean;
};

export enum ExtraWidget {
  Attachments = "attachments",
  Comments = "comments",
}

export type SchemaInterpretorProps = {
  schema: ProductSchema;
  schemaValues?: ItemDataInput;
  editMode: boolean;
  metadata?: ItemMetadata;
  mainColumnExtraWidgets?: React.ReactNode;
  secondaryColumnExtraWidgets?: React.ReactNode;
  contractCurrency: string;
  contractTimezone: string;
  isNECContractType?: boolean;
  productInstanceId: string;
  productItemType: ProductType;
  authAuditId?: string;
  contractTypeId?: string; // Used only for InstructionTypeField
  /**
   * Usually used inside AuthorizeDenyModal and basically displays all the sections
   * in primary column, hides section shadows, and makes spacings smaller
   */
  liteVariant?: boolean;
  onSchemaValuesChange?: (schemaValues: ItemDataInput) => void;
  onSchemaValidityChange?: (validity: boolean) => void;
  onOwnerChange?: () => void;
};

export const SchemaInterpretor: React.FC<SchemaInterpretorProps> = ({
  schema,
  schemaValues,
  editMode,
  metadata,
  mainColumnExtraWidgets,
  secondaryColumnExtraWidgets,
  contractCurrency,
  contractTimezone,
  isNECContractType = true,
  productInstanceId,
  productItemType,
  liteVariant = false,
  authAuditId,
  contractTypeId,
  onSchemaValuesChange,
  onSchemaValidityChange,
  onOwnerChange,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Note: When implementing mobile screen, to be determined what and how we display. For now, considering lite variant on mobile screens
  const computedLiteVariant = useMemo(
    () => liteVariant || isMobile,
    [liteVariant, isMobile]
  );

  const [schemaValidity, setSchemaValidity] = useState(true);
  const sectionsValidity = useRef<{ [sectionId: string]: boolean }>({});

  const primaryColumnSchemaSections = useMemo(
    () =>
      schema.schemaSections.items
        .filter((section) => {
          const matchingColumns = [
            ColumnPlacementType.Primary,
            ...(computedLiteVariant ? [ColumnPlacementType.Secondary] : []),
          ];

          return (
            matchingColumns.indexOf(section.columnPlacement) >= 0 &&
            section.status === SchemaSectionStatus.Active
          );
        })
        .sort((section1, section2) => {
          if (
            section1.columnPlacement === ColumnPlacementType.Primary &&
            section2.columnPlacement === ColumnPlacementType.Secondary
          ) {
            return -1;
          } else if (
            section1.columnPlacement === ColumnPlacementType.Secondary &&
            section2.columnPlacement === ColumnPlacementType.Primary
          ) {
            return 1;
          } else {
            return section1.displayOrder - section2.displayOrder;
          }
        }),
    [schema, computedLiteVariant]
  );
  const secondaryColumnSchemaSections = useMemo(
    () =>
      schema.schemaSections.items
        .filter(
          (section) =>
            section.columnPlacement === ColumnPlacementType.Secondary &&
            section.status === SchemaSectionStatus.Active
        )
        .sort(
          (section1, section2) => section1.displayOrder - section2.displayOrder
        ),
    [schema]
  );

  const handleSectionValuesChange = (newSectionData: ItemDataSectionInput) => {
    onSchemaValuesChange?.({
      sections: [
        ...(schemaValues?.sections.filter(
          (section) => section.name !== newSectionData.name
        ) || []),
        newSectionData,
      ],
    });
  };

  const handleSectionValidityChange = (
    validity: boolean,
    sectionId: string
  ) => {
    sectionsValidity.current[sectionId] = validity;

    setSchemaValidity(
      !Object.values(sectionsValidity.current).some((value) => !value)
    );
  };

  const contextValue = useMemo(() => {
    return {
      productInstanceId,
      contractCurrency,
      contractTimezone,
      productItemType,
      liteVariant: computedLiteVariant,
      metadata,
      authAuditId,
      isNECContractType,
      contractTypeId,
      onOwnerChange,
    };
  }, [
    contractCurrency,
    productInstanceId,
    contractTimezone,
    computedLiteVariant,
    authAuditId,
    productItemType,
    metadata,
    isNECContractType,
    contractTypeId,
    onOwnerChange,
  ]);

  useEffect(() => {
    onSchemaValidityChange?.(schemaValidity);
  }, [schemaValidity, onSchemaValidityChange]);

  return (
    <SchemaInterpretorContextProvider value={contextValue}>
      <Stack
        spacing={3}
        alignItems="flex-start"
        direction={computedLiteVariant ? "column" : "row"}
      >
        <Stack
          flex="3"
          overflow="hidden"
          width={computedLiteVariant ? "100%" : "auto"}
          spacing={computedLiteVariant ? 0 : 3}
        >
          {!editMode && metadata && (
            <SectionContainer>
              <MetadataFooter />
            </SectionContainer>
          )}
          {primaryColumnSchemaSections.map((section, index) => {
            // This flag is meant to mark the section which will:
            // - we'll search through this section for `title` field and not display it when editMode === false - because it's displayed in the header
            // This flag is a hack and is a tempoary solution. TODO
            const isMainSection = index === 0;

            return (
              <Box width="100%" key={section.id}>
                <Section
                  section={section}
                  editMode={editMode}
                  isMainSection={isMainSection}
                  sectionValues={schemaValues?.sections.find(
                    (sectionValue) => sectionValue.name === section.name
                  )}
                  onSectionValuesChange={handleSectionValuesChange}
                  onSectionValidityChange={handleSectionValidityChange}
                />
              </Box>
            );
          })}
          {mainColumnExtraWidgets}
        </Stack>
        {!computedLiteVariant && (
          <Stack spacing={3} width="432px">
            {secondaryColumnSchemaSections.map((section) => (
              <Box width="100%" key={section.id}>
                <Section
                  section={section}
                  editMode={editMode}
                  sectionValues={schemaValues?.sections.find(
                    (sectionValue) => sectionValue.name === section.name
                  )}
                  onSectionValuesChange={handleSectionValuesChange}
                  onSectionValidityChange={handleSectionValidityChange}
                />
              </Box>
            ))}
            {secondaryColumnExtraWidgets}
          </Stack>
        )}
      </Stack>
    </SchemaInterpretorContextProvider>
  );
};
