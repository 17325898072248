import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import { Clock } from "phosphor-react";
import { useTranslation } from "react-i18next";

export type DaysLateProps = {
  daysLate: number;
} & BoxProps;

export const DaysLate: React.FC<DaysLateProps> = ({
  daysLate,
  ...restContainerProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" {...restContainerProps}>
      <Clock size={18} color={theme.palette.error.main} />
      <Typography
        variant="p3"
        fontWeight={700}
        color="error.main"
        textTransform="uppercase"
        data-testid="days-late"
        ml={0.75}
      >
        {t("Projects.CompEvents.daysLate", { number: daysLate })}
      </Typography>
    </Box>
  );
};
