import { BoxProps, useTheme } from "@mui/material";
import {
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowAuditStatus,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { useMemo, useState } from "react";
import { AuthorizationProgressLevel } from "./AuthorizationProgressLevel";
import { computeLevelStatus } from "containers/Home/components/Authorizations/Authorizations.utils";
import {
  Container,
  EmptyProgressBar,
  ProgressBar,
} from "./AuthorizationProgress.styled";

export type AuthorizationProgressProps = {
  levels: AuthorizationWorkflowLevel[];
  authAudit?: AuthorizationWorkflowAudit;
} & BoxProps;

const firstStepOffset = 24;
const lastStepOffset = 24;

export const AuthorizationProgress: React.FC<AuthorizationProgressProps> = ({
  levels,
  authAudit,
  ...containerProps
}) => {
  const theme = useTheme();
  const [emptyProgressBarRef, setEmptyProgressBarRef] =
    useState<HTMLDivElement>();

  const step = useMemo(() => {
    if (emptyProgressBarRef && levels) {
      return (
        (emptyProgressBarRef.offsetWidth - lastStepOffset - firstStepOffset) /
        levels.length
      );
    }
    return 0;
  }, [emptyProgressBarRef, levels]);

  const authorizedLevels = useMemo(() => {
    let lastAuthorizedLevel = 0;
    if (!authAudit) {
      return lastAuthorizedLevel;
    }

    for (let index = 0; index < levels.length; index++) {
      const level = levels[index];
      if (
        computeLevelStatus(level, authAudit) ===
        AuthorizationWorkflowAuditStatus.Authorized
      ) {
        lastAuthorizedLevel++;
      } else {
        break;
      }
    }

    return lastAuthorizedLevel;
  }, [authAudit, levels]);

  const authorized =
    authAudit?.status === AuthorizationWorkflowAuditStatus.Authorized;

  return (
    <Container {...containerProps}>
      <EmptyProgressBar ref={setEmptyProgressBarRef} mt={9}>
        <ProgressBar
          completed={authorized}
          width={
            authorized
              ? "100%"
              : `${firstStepOffset + step * authorizedLevels}px`
          }
        />
      </EmptyProgressBar>
      {levels.map((level) => {
        return (
          <AuthorizationProgressLevel
            key={level.sequence}
            positionLeft={`${
              firstStepOffset +
              (level.sequence - 1) * step +
              parseInt(theme.spacing(1))
            }px`}
            level={level}
            authAudit={authAudit}
          />
        );
      })}
    </Container>
  );
};
