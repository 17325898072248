import { gql } from "@apollo/client";
import { dailyDiaryWeatherRecordLiteFields } from "graphql/fragments/dailyDiaryWeatherRecordLite.fragment";

export const editDailyDiaryWeatherRecordMutation = gql`
  ${dailyDiaryWeatherRecordLiteFields}

  mutation editDailyDiaryWeatherRecord(
    $input: EditDailyDiaryWeatherRecordInput!
  ) {
    editDailyDiaryWeatherRecord(input: $input) {
      ...DailyDiaryWeatherRecordLiteFields
    }
  }
`;
