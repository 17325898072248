import { Grid, Typography } from "@mui/material";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { CompEventRejectionPrompt } from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import { useContext, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecordCERejection } from "./RecordCERejection";
import { SendCERejection } from "./SendCERejection";
import { useRejectCEAction } from "./useRejectCEAction";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";
import { isNEC3PSCContractType } from "containers/Projects/Projects.utils";

const dataValidators: DataValidators = {
  reason: {
    validators: [ValidatorType.Required],
  },
};

const defaultNEC3ECCReason =
  '<ul><li value="1"><span>arises from a fault of the&nbsp;</span><i><em class="PlaygroundEditorTheme__textItalic">Contractor</em></i></li><li value="2"><span>has not happened and is not expected to happen</span></li><li value="3"><span>has no effect upon Defined Cost, Completion or meeting a Key Date</span></li><li value="4"><span>is not one of the compensation events stated in this contract</span></li><li value="5"><span>was not notified in time</span></li></ul>';
const defaultNEC3PSCReason = `<ul><li value="1"><span>arises from a fault of the&nbsp;</span><i><em class="PlaygroundEditorTheme__textItalic">Consultant</em></i></li><li value="2"><span>has not happened and is not expected to happen</span></li><li value="3"><span>has no effect upon the&nbsp;</span><i><em class="PlaygroundEditorTheme__textItalic">Consultant's&nbsp;</em></i><span>costs, Completion or meeting a Key Date</span></li><li value="4"><span>is not one of the compensation events stated in this contract</span></li><li value="5"><span>was not notified in time</span></li></ul>`;

const defaultFormData: CompEventRejectionPrompt = {
  reason: "",
};

export const RejectCEAction: React.FC<CEActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { compEvent, contract } = useContext(CompEventWidgetContext);

  const defaultReasonsText = useMemo(() => {
    if (isNEC3PSCContractType(contract)) {
      return defaultNEC3PSCReason;
    }

    return defaultNEC3ECCReason;
  }, [contract]);

  const [formData, setFormData] = useState<CompEventRejectionPrompt>({
    ...defaultFormData,
    reason: defaultReasonsText,
  });
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const { sendCompEventRejection, recordCompEventRejection, actionLoading } =
    useRejectCEAction();

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleTextFieldChange = (reason: string) => {
    setFormData((curData) => ({
      ...curData,
      reason,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { reason: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleSendCERejection = async () => {
    await sendCompEventRejection({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          reason: formData.reason,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCERejection = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCompEventRejection({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
          reason: formData.reason,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => validateData(formData, dataValidators).valid,
    [formData]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  return (
    <>
      {isFormValid && sendModalVisibility && (
        <SendCERejection
          open={sendModalVisibility}
          reason={formData.reason}
          onPrimaryClick={handleSendCERejection}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordCERejection
          open={recordModalVisibility}
          reason={formData.reason}
          onPrimaryClick={handleRecordCERejection}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel label={t("common.labels.reason")} required />
          <RichTextArea
            content={formData.reason}
            error={!!formDataErrors.reason}
            onChange={handleTextFieldChange}
          />
          {!!formDataErrors.reason && (
            <Typography variant="caption" color="error" mt={0.5}>
              {formDataErrors.reason}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};
