import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventNoticeRequestQuotationPreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventNoticeRequestQuotationPreview(
    $input: RecordCompEventNoticeRequestQuotationPreviewInput!
  ) {
    generateRecordCompEventNoticeRequestQuotationPreview(input: $input) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
