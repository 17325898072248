import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DeleteConfirmationModal } from "components/DeleteConfirmationModal";
import {
  AddDailyDiaryWorkRecordInput,
  AttachmentInput,
  DailyDiaryExtraConfig,
  DailyDiaryPresetSection,
  DailyDiaryWorkRecord,
  EditDailyDiaryWorkRecordInput,
  FieldTypeEnum,
} from "generated/graphql";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { BaseHeaderSection } from "../BaseSectionHeader";
import { useWorkSection } from "./useWorkSection";
import { WorkRecordModal } from "./WorkRecordModal/WorkRecordModal";
import {
  WorkLookups,
  WorkOptionalColumn,
  WorkTable,
} from "./WorkTable/WorkTable";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { ImportWorkRecordsModal } from "./ImportWorkRecordsModal";
import { useDiaryMetadata } from "../../hooks/useDiaryMetadata";

export type WorkSectionProps = {
  section: DailyDiaryPresetSection;
};

export const WorkSection: React.FC<WorkSectionProps> = ({ section }) => {
  const { t } = useTranslation();

  const [recordToDeleteEdit, setRecordToDeleteEdit] =
    useState<DailyDiaryWorkRecord>();
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [updatedAttachments, setUpdatedAttachments] =
    useState<AttachmentInput[]>();

  const { dailyDiaryId: dailyDiaryIdSearchParam } = useDiaryMetadata();

  const {
    dailyDiary,
    emptyDailyDiary,
    contractLookupCollections,
    activeContractLookupCollections,
    loading: contextLoading,
    crtRevisionId,
    productInstance,
    refetchDDByDate,
  } = useContext(DailyDiaryContext);
  const { modalVisibility, toggleModalVisibility } = useBasicModal();
  const {
    modalVisibility: importModalVisibility,
    toggleModalVisibility: toggleImportModalVisibility,
  } = useBasicModal();
  const {
    workRecords,
    loading: workRecordsLoading,
    addWorkRecord,
    editWorkRecord,
    removeWorkRecord,
    refetchDDWorkRecords,
  } = useWorkSection(dailyDiary, crtRevisionId);

  const areaLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).areaLookupId
    );
  }, [productInstance]);

  const shiftLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).shiftLookupId
    );
  }, [productInstance]);

  const teamLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).teamLookupId
    );
  }, [productInstance]);

  const optionalColumns: WorkOptionalColumn[] = useMemo(() => {
    const columnsToReturn: WorkOptionalColumn[] = [];

    section.fields.forEach((field) => {
      switch (field.name) {
        case "Description":
          !field.isHidden &&
            columnsToReturn.push(WorkOptionalColumn.Description);
          break;
        case "Area":
          !field.isHidden && columnsToReturn.push(WorkOptionalColumn.Area);
          break;
        case "Discipline":
          !field.isHidden &&
            columnsToReturn.push(WorkOptionalColumn.Discipline);
          break;
        case "Team":
          !field.isHidden && columnsToReturn.push(WorkOptionalColumn.Team);
          break;
        case "Shift":
          !field.isHidden && columnsToReturn.push(WorkOptionalColumn.Shift);
          break;
        case "Activity":
          !field.isHidden && columnsToReturn.push(WorkOptionalColumn.Activity);
          break;
      }
    });

    return columnsToReturn;
  }, [section]);

  const activeLookups = useMemo(() => {
    const neededLookups = section.fields
      .filter((field) => field.fieldType === FieldTypeEnum.Lkp)
      .map((field) => {
        if (field.name === "Area" && areaLookupIdOverride) {
          return { fieldName: field.name, lookupId: areaLookupIdOverride };
        }
        if (field.name === "Shift" && shiftLookupIdOverride) {
          return { fieldName: field.name, lookupId: shiftLookupIdOverride };
        }
        if (field.name === "Team" && teamLookupIdOverride) {
          return { fieldName: field.name, lookupId: teamLookupIdOverride };
        }
        return { fieldName: field.name, lookupId: field.lookupId! };
      });

    const workLookups = {} as WorkLookups;

    neededLookups.forEach((neededLookup) => {
      switch (neededLookup.fieldName) {
        case "Shift":
          workLookups.Shift = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "Area":
          workLookups.Area = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "Discipline":
          workLookups.Discipline = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "Team":
          workLookups.Team = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "Activity":
          workLookups.Activity = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "WorkType":
          workLookups.WorkType = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "UnitOfMeasurement":
          workLookups.UnitOfMeasurement = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
      }
    });

    return workLookups;
  }, [
    section,
    activeContractLookupCollections,
    areaLookupIdOverride,
    shiftLookupIdOverride,
    teamLookupIdOverride,
  ]);

  const toggleConfirmationModalVisibility = () => {
    setConfirmationModalVisibility((crtState) => !crtState);
  };

  const handleAddWorkRecord = () => {
    toggleModalVisibility();
  };

  const triggerEditRecordFlow = (recordId: GridRowId) => {
    const record = workRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    toggleModalVisibility();
  };

  const handleWorkModalClose = () => {
    toggleModalVisibility();
    setRecordToDeleteEdit(undefined);
  };

  const triggerRemoveFlow = (recordId: GridRowId) => {
    const record = workRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    setConfirmationModalVisibility(true);
  };

  const handleAttachmentsUpdated = (attachmentInputs: AttachmentInput[]) => {
    setUpdatedAttachments(attachmentInputs);
  };

  const handleModalPrimaryClick = async (
    workRecord: AddDailyDiaryWorkRecordInput | EditDailyDiaryWorkRecordInput,
    closeModal: boolean
  ) => {
    if (recordToDeleteEdit) {
      await editWorkRecord({
        variables: {
          input: {
            ...(workRecord as EditDailyDiaryWorkRecordInput),
            id: recordToDeleteEdit.id,
            attachments:
              updatedAttachments ??
              attachmentsToAttachmentInputs(
                recordToDeleteEdit.attachments ?? []
              ),
          },
        },
      });
    } else {
      await addWorkRecord({
        variables: {
          input: {
            ...(workRecord as AddDailyDiaryWorkRecordInput),
            ...(dailyDiary?.id
              ? { dailyDiaryId: dailyDiary.id }
              : {
                  date: emptyDailyDiary?.date,
                  productInstanceId: emptyDailyDiary?.productInstanceId,
                }),
            attachments: updatedAttachments ?? [],
          },
        },
      });
    }
    setUpdatedAttachments(undefined);

    if (closeModal) {
      handleWorkModalClose();
    }
  };

  const handleSuccessfulImport = () => {
    toggleImportModalVisibility();
    if (dailyDiaryIdSearchParam) {
      refetchDDWorkRecords();
    } else {
      refetchDDByDate?.();
    }
  };

  const handleRemoveRecord = () => {
    toggleConfirmationModalVisibility();
    removeWorkRecord({ variables: { id: recordToDeleteEdit?.id! } });
    setRecordToDeleteEdit(undefined);
  };

  useEffect(() => {
    if (recordToDeleteEdit && !workRecords.includes(recordToDeleteEdit)) {
      const updatedRecord = workRecords.find(
        (rec) => rec.id === recordToDeleteEdit.id
      );
      if (updatedRecord) {
        setRecordToDeleteEdit(updatedRecord);
      }
    }
  }, [workRecords, recordToDeleteEdit]);

  useEffect(() => {
    if (!recordToDeleteEdit) {
      setUpdatedAttachments(undefined);
    }
  }, [recordToDeleteEdit]);

  return (
    <>
      <DeleteConfirmationModal
        title={t("Projects.DailyDiaries.RemoveConfirmationModal.title")}
        subtitle={t("Projects.DailyDiaries.RemoveConfirmationModal.subtitle")}
        open={confirmationModalVisibility}
        onPrimaryClick={handleRemoveRecord}
        onSecondaryClick={toggleConfirmationModalVisibility}
      />
      {importModalVisibility && (
        <ImportWorkRecordsModal
          open={importModalVisibility}
          lookups={contractLookupCollections}
          loading={contextLoading}
          optionalColumns={optionalColumns}
          onClose={toggleImportModalVisibility}
          onImportSuccess={handleSuccessfulImport}
        />
      )}
      <WorkRecordModal
        open={modalVisibility}
        onPrimaryClick={handleModalPrimaryClick}
        onSecondaryClick={handleWorkModalClose}
        onClose={handleWorkModalClose}
        onAttachmentsUpdated={handleAttachmentsUpdated}
        optionalColumns={optionalColumns}
        section={section}
        primaryBtnLoading={workRecordsLoading}
        workLookups={activeLookups}
        attachmentsPreview={
          updatedAttachments && recordToDeleteEdit
            ? recordToDeleteEdit.attachments?.filter((attach) =>
                updatedAttachments.find(
                  (crtAttach) => crtAttach.id === attach.id
                )
              )
            : undefined
        }
        workRecord={recordToDeleteEdit}
      />
      <Box width="100%">
        <BaseHeaderSection
          title={t("Projects.DailyDiaries.sections.work")}
          onAdd={handleAddWorkRecord}
          onImport={toggleImportModalVisibility}
          mb={3}
        />
        <WorkTable
          records={workRecords}
          optionalColumns={optionalColumns}
          workLookupCollections={contractLookupCollections}
          loading={workRecordsLoading || contextLoading}
          onEdit={triggerEditRecordFlow}
          onRemove={triggerRemoveFlow}
        />
      </Box>
    </>
  );
};
