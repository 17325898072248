import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const EditIcon: React.FC<BaseIconProps> = ({
  height = "25",
  width = "25",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color || theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99716 20.2543H5.49625C5.2973 20.2543 5.10649 20.1753 4.96581 20.0346C4.82513 19.8939 4.7461 19.7031 4.7461 19.5042V15.3127C4.74576 15.2153 4.76463 15.1188 4.80164 15.0287C4.83864 14.9385 4.89306 14.8566 4.96177 14.7876L16.214 3.53531C16.2838 3.46444 16.367 3.40815 16.4588 3.36973C16.5506 3.33131 16.649 3.31152 16.7485 3.31152C16.848 3.31152 16.9465 3.33131 17.0382 3.36973C17.13 3.40815 17.2132 3.46444 17.283 3.53531L21.4651 7.71741C21.536 7.78721 21.5923 7.87041 21.6307 7.96217C21.6691 8.05393 21.6889 8.15241 21.6889 8.25189C21.6889 8.35137 21.6691 8.44985 21.6307 8.54161C21.5923 8.63337 21.536 8.71657 21.4651 8.78637L9.99716 20.2543Z"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2484 20.2539H9.99609"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 6.00098L19.0011 11.252"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
