import {
  AddContractSectionInput,
  ContractSection,
  ContractSectionStatus,
  EditContractSectionInput,
} from "generated/graphql";
import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { StatusOption } from "components/StatusTag/StatusTag";
import { StyledDataGrid } from "components/StyledDataGrid";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumns } from "./ContractSections.constants";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import {
  dateISOFormat,
  dateTimeISOFormat,
  temporaryRowId,
} from "constants/constants";
import { GlobalContext } from "state-management/globalContext/Global.context";
import moment from "moment";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { PuzzlePiece } from "phosphor-react";

export type ContractSectionsProps = {
  sections: ContractSection[];
  loading?: boolean;
  projectFriendlyName?: string;
  contractFriendlyName?: string;
  onSectionAdd: (newSection: AddContractSectionInput) => any;
  onSectionEdit: (editedSection: EditContractSectionInput) => any;
};

export const ContractSections: React.FC<ContractSectionsProps> = ({
  sections,
  loading,
  projectFriendlyName,
  contractFriendlyName,
  onSectionAdd,
  onSectionEdit,
}) => {
  const { t } = useTranslation();
  const { authenticatedUser } = useContext(GlobalContext);
  const theme = useTheme();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ContractSectionStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [rows, setRows] = useState<ContractSection[]>(sections);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(sections)
  );
  const [gridVisibility, setGridVisibility] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "number", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ContractSectionStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const handleExportContractSectionsToExcel = () => {
    const columns = [
      {
        header: t("common.labels.number"),
        key: "number",
        width: 20,
      },
      {
        header: t("common.labels.description"),
        key: "description",
        width: 20,
      },
      {
        header: t("AdminConsole.ContractSections.completionDate"),
        key: "completionDate",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
    ];

    const rows = sections
      .filter((section) => (selectionModel || []).indexOf(section.id) >= 0)
      .map((section) => ({
        ...section,
        completionDate: section.completionDate
          ? new Date(section.completionDate)
          : "",
      }));

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}-${t(
        "AdminConsole.ContractSections.contractSections"
      )}`,
      columns,
      rows
    );
  };

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<ContractSection>,
      oldRow: GridRowModel<ContractSection>
    ) => {
      if (!newRow.description || !newRow.completionDate) {
        setRowModesModel((prevData) => ({
          ...prevData,
          [temporaryRowId]: {
            mode: GridRowModes.Edit,
            fieldToFocus: !newRow.description
              ? "description"
              : "completionDate",
          },
        }));
        return newRow;
      }

      if (newRow.id === temporaryRowId) {
        const success = await onSectionAdd({
          number: newRow.number,
          description: newRow.description,
          completionDate: moment(newRow.completionDate).format(dateISOFormat),
        });

        if (!success) {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "description",
            },
          }));
        }
      } else if (
        newRow.description !== oldRow.description ||
        newRow.completionDate !== oldRow.completionDate ||
        newRow.number !== oldRow.number
      ) {
        onSectionEdit({
          id: newRow.id,
          number: newRow.number,
          description: newRow.description,
          completionDate: moment(newRow.completionDate).format(dateISOFormat),
          status: newRow.status,
        });
      }

      return newRow;
    },
    [onSectionAdd, onSectionEdit]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleSectionStatusChange = useCallback(
    (row: ContractSection, newStatus: ContractSectionStatus) => {
      onSectionEdit({
        id: row.id,
        number: row.number,
        description: row.description,
        completionDate: moment(row.completionDate).format(dateISOFormat),
        status: newStatus,
      });
    },
    [onSectionEdit]
  );

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        const sectionToUpdate = sections.find(
          (section) => section.id === rowId
        )!;

        onSectionEdit({
          id: sectionToUpdate.id,
          number: sectionToUpdate.number,
          description: sectionToUpdate.description,
          completionDate: moment(sectionToUpdate.completionDate).format(
            dateISOFormat
          ),
          status: ContractSectionStatus.Removed,
        });
      }
    },
    [onSectionEdit, sections]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser!,
        creatorId: authenticatedUser!.id,
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        dateModified: moment(new Date().toString()).format(dateTimeISOFormat),
        completionDate: moment(new Date())
          .add(31, "days")
          .format(dateISOFormat),
        description: "",
        id: temporaryRowId,
        status: ContractSectionStatus.Active,
        contractTypeId: "",
        number: null,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "number",
        },
      }));
    });
  };

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const columns = useMemo(
    () =>
      getColumns({
        t,
        onStatusChange: handleSectionStatusChange,
        handleDeleteRow,
        statusOptions,
        handleSaveRow: handleRowSaveClick,
        rowModesModel,
      }),
    [
      statusOptions,
      t,
      rowModesModel,
      handleRowSaveClick,
      handleDeleteRow,
      handleSectionStatusChange,
    ]
  );

  useEffect(() => {
    setRows(sections);
    setRowModesModel(computeGridRowModes(sections));
  }, [sections]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.ContractSections.contractSections")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportContractSectionsToExcel}
        onToggleCollapse={() => setGridVisibility((state) => !state)}
        collapsed={!gridVisibility}
        withShadow={false}
        icon={
          <PuzzlePiece
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
      />
      <Collapse in={gridVisibility}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: ContractSection) => rowData.id}
            onRowSelectionModelChange={handleGridRowSelectionModelChange}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, ContractSection, any>
            ) => {
              return params.row.status === ContractSectionStatus.Removed
                ? "greyed-out"
                : "";
            }}
            autoHeight
            hideFooter
            checkboxSelection
            disableRowSelectionOnClick
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
