import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";
import { useSendErrorFoundInDeterminationNoticeModal } from "./useSendErrorFoundInDeterminationNoticeModal";

export type SendErrorFoundInDeterminationNoticeModalProps = {
  details: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendErrorFoundInDeterminationNoticeModal: React.FC<
  SendErrorFoundInDeterminationNoticeModalProps
> = ({ details, attachments = [], ...restDialogProps }) => {
  const { claimPreview, contract } =
    useSendErrorFoundInDeterminationNoticeModal(details);
  const { t } = useTranslation();

  return (
    <>
      <SendAction
        claimPreview={claimPreview}
        actionType={ClaimActionType.NotifyErrorFoundDetermination}
        summaryEl={
          <ReasonsAttachmentsSummary
            text={details}
            label={t("Projects.Claims.ActionModal.errorsFound")}
            attachments={attachments}
            contractTimezone={contract.timeZone}
          />
        }
        {...restDialogProps}
      />
    </>
  );
};
