import { ActionsDialogProps } from "components/ActionsDialog";
import { VariationActionType } from "generated/graphql";
import { useSendFurtherParticularsRequestModal } from "./useSendFurtherParticularsRequestModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";
import { SendAction } from "../components/SendAction";

export type SendFurtherParticularsRequestModalProps = {
  reasons: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendFurtherParticularsRequestModal: React.FC<
  SendFurtherParticularsRequestModalProps
> = ({ reasons, attachments = [], ...restDialogProps }) => {
  const { variationPreview, contract } =
    useSendFurtherParticularsRequestModal(reasons);
  const { t } = useTranslation();

  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.RequestFurtherParticulars}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={reasons}
          label={t("Projects.Variations.ActionModal.furtherParticularsReasons")}
          attachments={attachments}
          contractTimezone={contract.timeZone}
        />
      }
      {...restDialogProps}
    />
  );
};
