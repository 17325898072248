import { Box, Color, debounce, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { IconContainer } from "components/IconContainer";
import { eventDebounceDuration } from "constants/constants";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { RecipientsPreview } from "containers/Projects/components/RecipientsPreview";
import {
  RecordProductItemExtraData,
  RecordProductItemForm,
} from "containers/Projects/components/RecordProductItemForm";
import { useContractBindingTypes } from "containers/Projects/hooks/useContractBindingTypes";
import {
  CompEventActionType,
  GenerateRecordCompEventNoticePreviewMutation,
  GenerateRecordCompEventNoticePreviewMutationVariables,
  ListItem,
  ProductType,
  RecordCompEventNoticePreview,
} from "generated/graphql";
import { generateRecordCompEventNoticePreviewMutation } from "graphql/mutations/generateRecordCompEventNoticePreview";
import { useGraphMutation } from "hooks/useGraphMutation";
import { Plugs } from "phosphor-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ActionModalTitle } from "../../../../ActionModal/ActionModalTitle";
import { NotifyCEClaimSummary } from "containers/Projects/components/ActionModal/NotifyCEClaimSummary";

export type RecordCENoticeProps = {
  claimType: ListItem;
  ewGiven?: ListItem;
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordCENotice: React.FC<RecordCENoticeProps> = ({
  claimType,
  ewGiven,
  onPrimaryClick,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    contract,
    productInstanceNumberingFormat,
    regardingId,
    regardingType,
    productInstanceId,
    projectName,
  } = useContext(CompEventWidgetContext);
  const { bindingTypes, loading: bindingTypesLoading } =
    useContractBindingTypes(contract.id);
  const extraData = useRef<RecordProductItemExtraData>();
  const extraDataValid = useRef<boolean>(false);

  const [compEventPreview, setCompEventPreview] =
    useState<RecordCompEventNoticePreview>();

  const [generateCEPreview] = useGraphMutation<
    GenerateRecordCompEventNoticePreviewMutation,
    GenerateRecordCompEventNoticePreviewMutationVariables
  >(generateRecordCompEventNoticePreviewMutation, {}, null);

  const handleExtraDataChange = (data: RecordProductItemExtraData) => {
    extraData.current = data;
  };

  const handleExtraDataValidityChange = (isValid: boolean) => {
    extraDataValid.current = isValid;
  };

  const handlePrimaryClick = () => {
    if (extraDataValid.current) {
      onPrimaryClick(extraData.current!);
    }
  };

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateCEPreview({
        variables: {
          input: {
            productInstanceId,
            regardingId,
            regardingType,
          },
        },
      });

      if (data) {
        setCompEventPreview(data.generateRecordCompEventNoticePreview);
      }
    }, [productInstanceId, generateCEPreview, regardingId, regardingType]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return (
    <ActionsDialog
      onPrimaryClick={handlePrimaryClick}
      primaryBtnCaption={t("common.buttons.record")}
      iconsHeader={
        <IconContainer>
          <Plugs
            size={18}
            color={(theme.palette.secondary as Partial<Color>)[700]}
          />
        </IconContainer>
      }
      primaryBtnDisabled={!compEventPreview}
      content={
        !compEventPreview || bindingTypesLoading ? (
          <CenteredLoadingIndicator />
        ) : (
          <Box>
            <ActionModalTitle mb={2}>
              {`${t(
                `Projects.CompEvents.ActionTypes.${CompEventActionType.NotifyCompEvent}`
              )} (record)`}
            </ActionModalTitle>
            <NotifyCEClaimSummary
              claimType={claimType.name}
              ewTitle={ewGiven?.name}
              contextType="compEvent"
            />
            <Box mt={4}>
              <RecordProductItemForm
                bindingTypes={bindingTypes}
                productType={ProductType.CompEvents}
                onChange={handleExtraDataChange}
                onValidationChange={handleExtraDataValidityChange}
              />
            </Box>
            <Box mt={4}>
              <ProductItemCard
                itemName={`${productInstanceNumberingFormat}-XXXX`}
                productType={ProductType.CompEvents}
                projectName={projectName}
                contractName={contract.friendlyName}
              />
            </Box>
            <Box mt={4}>
              <CollapsibleContainer
                title={`${t(`common.labels.recipients`)} (${
                  compEventPreview.recipients.length
                })`}
              >
                <RecipientsPreview recipients={compEventPreview.recipients} />
              </CollapsibleContainer>
            </Box>
          </Box>
        )
      }
      sx={{ minWidth: "420px" }}
      {...restDialogProps}
    />
  );
};
