import {
  CompanyLookupCollection,
  DailyDiaryResourceRecord,
} from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import { TFunction } from "i18next";
import { timeStripMinutes } from "../../../utilts";

export const toRecordJSON = ({
  record,
  withCompanyColumn,
  withShiftColumn,
  withTeamColumn,
  lookups,
  t,
}: {
  record: DailyDiaryResourceRecord;
  lookups?: CompanyLookupCollection[];
  withShiftColumn: boolean;
  withCompanyColumn: boolean;
  withTeamColumn: boolean;
  t: TFunction;
}) => {
  return {
    [t("Projects.DailyDiaries.Resource.resource")]:
      lookups
        ?.find((lkp) => lkp.id === record.resourceLookupId)
        ?.options.items.find((option) => option.id === record.resourceOptionId)
        ?.value ?? "",
    ...(withShiftColumn && {
      [t("Projects.DailyDiaries.Resource.shift")]:
        lookups
          ?.find((lkp) => lkp.id === record.shiftLookupId)
          ?.options.items.find((option) => option.id === record.shiftOptionId)
          ?.value ?? "",
    }),
    [t("Projects.DailyDiaries.Resource.quantity")]: record.quantity,
    [t("Projects.DailyDiaries.Resource.hours")]: timeStripMinutes(record.hours),
    [t("Projects.DailyDiaries.Resource.totalHours")]: timeStripMinutes(
      record.totalHours
    ),
    [t("Projects.DailyDiaries.Resource.remarks")]: record.remarks ?? "",
    ...(withCompanyColumn && {
      [t("Projects.DailyDiaries.Resource.company")]:
        lookups
          ?.find((lkp) => lkp.id === record.companyLookupId)
          ?.options.items.find((option) => option.id === record.companyOptionId)
          ?.value ?? "",
    }),
    ...(withTeamColumn && {
      [t("Projects.DailyDiaries.Resource.team")]:
        lookups
          ?.find((lkp) => lkp.id === record.teamLookupId)
          ?.options.items.find((option) => option.id === record.teamOptionId)
          ?.value ?? "",
    }),
    [t("common.labels.createdBy")]: getUserName(record.creator),
  };
};
