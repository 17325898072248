import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationProposalConsentForProposedVariationPreviewMutation,
  GenerateRecordVariationProposalConsentForProposedVariationPreviewMutationVariables,
  RecordVariationProposalConsentForProposedVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateRecordVariationProposalConsentForProposedVariationPreviewMutation } from "./ConsentToProposalForProposedVariationAction.query";

export const useRecordConsentToProposalForProposedVariationModal = (
  remarks: string,
  acceptedProposalId: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [variationPreview, setVariationPreview] =
    useState<RecordVariationProposalConsentForProposedVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationProposalConsentForProposedVariationPreviewMutation,
    GenerateRecordVariationProposalConsentForProposedVariationPreviewMutationVariables
  >(
    generateRecordVariationProposalConsentForProposedVariationPreviewMutation,
    {},
    null
  );

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
            acceptedProposalId,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateRecordVariationProposalConsentForProposedVariationPreview
        );
      }
    }, [generatePreview, variation, remarks, acceptedProposalId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview,
    contract,
  };
};
