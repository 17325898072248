import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventRejectionPreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventRejectionPreview(
    $input: RecordCompEventRejectionPreviewInput!
  ) {
    generateRecordCompEventRejectionPreview(input: $input) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
