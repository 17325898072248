import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryHseRecordInput,
  CompanyLookupCollection,
  DailyDiaryHseRecord,
  DailyDiaryPresetSection,
  EditDailyDiaryHseRecordInput,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Textarea } from "components/TextArea";
import { hseRecordToEditInput } from "../HSESection.utils";
import { useHSERecordFormValidators } from "./useHSERecordFromValidators";

export type HSELookups = {
  HSEType: CompanyLookupCollection;
};

export type HSERecordFormProps = {
  hseLookups: HSELookups;
  hseRecord?: DailyDiaryHseRecord;
  section: DailyDiaryPresetSection;
  apiRef?: React.Ref<FormPublicApi>;
  onChange: (
    hseRecord: AddDailyDiaryHseRecordInput | EditDailyDiaryHseRecordInput
  ) => void;
};

export const defaultFormData: EditDailyDiaryHseRecordInput = {
  id: "",
  attachments: [],
  description: "",
  hseTypeOptionId: "",
};

export const HSERecordForm: React.FC<HSERecordFormProps> = ({
  hseRecord,
  hseLookups,
  section,
  apiRef,
  onChange,
}) => {
  const { t } = useTranslation();

  const firstFieldRef = useRef<any>(null);

  const [formData, setFormData] = useState<
    AddDailyDiaryHseRecordInput | EditDailyDiaryHseRecordInput
  >(hseRecord ? hseRecordToEditInput(hseRecord) : defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const dataValidators = useHSERecordFormValidators(
    section,
    formData.hseTypeOptionId,
    hseLookups
  );

  const validateForm = useCallback(
    (formData: AddDailyDiaryHseRecordInput | EditDailyDiaryHseRecordInput) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [dataValidators]
  );

  const handleDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setFormData((curData) => ({
      ...curData,
      description: event.target.value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string | null>) => {
    setFormData((curData) => ({
      ...curData,
      [event.target.name]: event.target.value ?? "",
    }));

    setFormDataErrors((crtFormDataErrs) => {
      const { [event.target.name]: _, ...rest } = crtFormDataErrs;

      return rest;
    });
  };

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
    firstFieldRef.current.focus();
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [resetForm, formData, validateForm]
  );

  useEffect(() => {
    onChange(formData);
  }, [onChange, formData]);

  useEffect(() => {
    firstFieldRef.current.focus();
  }, []);

  const isDescriptionRequired = section.fields.find(
    (field) => field.name === "Description"
  )?.isRequired;
  const isTypeRequired = section.fields.find(
    (field) => field.name === "HSEType"
  )?.isRequired;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.HSE.description")}
            required={isDescriptionRequired}
          />
          <Textarea
            value={formData.description ?? ""}
            onChange={handleDescriptionChange}
            required={isDescriptionRequired}
            ref={firstFieldRef}
          />
          {!!formDataErrors.description && (
            <Typography variant="caption" color="error" mt={0.5}>
              {formDataErrors.description}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.HSE.HSEType")}
            required={isTypeRequired}
          />
          <Select
            labelId="area-select-label"
            id="area-select"
            value={formData.hseTypeOptionId ?? ""}
            name="hseTypeOptionId"
            onChange={handleSelectChange}
            variant="outlined"
            error={!!formDataErrors.hseTypeOptionId}
            size="small"
            required={isTypeRequired}
          >
            {hseLookups.HSEType.options.items.map((lkpOption) => (
              <MenuItem key={lkpOption.id} value={lkpOption.id}>
                {lkpOption.value}
              </MenuItem>
            ))}
          </Select>
          {!!formDataErrors.hseTypeOptionId && (
            <Typography variant="caption" color="error" mt={0.5}>
              {formDataErrors.hseTypeOptionId}
            </Typography>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};
