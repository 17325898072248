import {
  DailyDiaryItem,
  ImportDailyDiaryGeneralRecordsMutation,
  ImportDailyDiaryGeneralRecordsMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useGeneralRecords } from "./useGeneralRecords";
import { importDailyDiaryGeneralRecordsMutation } from "./GeneralSection.query";

export const useImportGeneralRecordsModal = (dailyDiary?: DailyDiaryItem) => {
  const { t } = useTranslation();

  const { generalRecords, generalRecordsLoading } =
    useGeneralRecords(dailyDiary);

  const [importRecords, { loading: importLoading }] = useGraphMutation<
    ImportDailyDiaryGeneralRecordsMutation,
    ImportDailyDiaryGeneralRecordsMutationVariables
  >(
    importDailyDiaryGeneralRecordsMutation,
    {},
    t("Projects.DailyDiaries.ImportRecordsModal.importSuccess")
  );

  return {
    generalRecords,
    loading: generalRecordsLoading || importLoading,
    importLoading,
    generalRecordsLoading,
    importRecords,
  };
};
