import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { OnMutationDone } from "types/decl";
import {
  RecordCompEventRequestForMoreTimeMutation,
  RecordCompEventRequestForMoreTimeMutationVariables,
  SendCompEventRequestForMoreTimeMutation,
  SendCompEventRequestForMoreTimeMutationVariables,
} from "generated/graphql";
import { recordCompEventRequestForMoreTimeMutation } from "graphql/mutations/recordCompEventRequestForMoreTime";
import { sendCompEventRequestForMoreTimeMutation } from "graphql/mutations/sendCompEventRequestForMoreTime";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

export const useRequestForMoreTimeCEAction = () => {
  const { t } = useTranslation();
  const { onChange, compEvent } = useContext(CompEventWidgetContext);

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItems",
        args: { compEventId: compEvent!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, compEvent]
  );

  const [
    sendCERequestForMoreTime,
    { loading: sendCERequestForMoreTimeLoading },
  ] = useGraphMutation<
    SendCompEventRequestForMoreTimeMutation,
    SendCompEventRequestForMoreTimeMutationVariables
  >(
    sendCompEventRequestForMoreTimeMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventRequestForMoreTime"),
    })
  );

  const [
    recordCERequestForMoreTime,
    { loading: recordCERequestForMoreTimeLoading },
  ] = useGraphMutation<
    RecordCompEventRequestForMoreTimeMutation,
    RecordCompEventRequestForMoreTimeMutationVariables
  >(
    recordCompEventRequestForMoreTimeMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventRequestForMoreTime"),
    })
  );

  return {
    actionLoading:
      sendCERequestForMoreTimeLoading || recordCERequestForMoreTimeLoading,
    sendCERequestForMoreTime,
    recordCERequestForMoreTime,
  };
};
