import {
  Box,
  Breadcrumbs,
  CircularProgress,
  IconButton,
  Link,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PageContentHeader,
  PageContentHeaderSize,
} from "components/PageContentHeader/PageContentHeader";
import { User, UserStatus } from "generated/graphql";
import { HeaderLoadingContainer } from "../../components/HeaderLoadingContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { Trans, useTranslation } from "react-i18next";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useActiveRemovedSuspendedStatusOptions } from "components/StatusTag/useActiveRemovedSuspendedOptions";
import moment from "moment";

import { InvitationIcon } from "components/Icons/InvitationIcon";
import { useNavigate } from "react-router-dom";
import { dateTimeISOFormat } from "constants/constants";
import { getUserName } from "helpers/miscelaneous";

export type UserDetailsHeaderProps = {
  user?: User;
  companyName?: string;
  generalLoading?: boolean;
  onResendRegisterInvitation: () => void;
  onStatusChange: (newStatus: UserStatus) => void;
};

export const UserDetailsHeader: React.FC<UserDetailsHeaderProps> = ({
  user,
  companyName,
  generalLoading,
  onResendRegisterInvitation,
  onStatusChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigateBack = useNavigateBack();
  const userStatusOptions =
    useActiveRemovedSuspendedStatusOptions() as StatusOption<UserStatus>[];

  const handleStatusChange = (newStatus: StatusOption<UserStatus>) => {
    onStatusChange(newStatus.id as UserStatus);
  };

  const handleBreadcrumbClick = (parent?: "company") => {
    if (parent === "company") {
      navigate(-1);
    } else {
      navigate(-2);
    }
  };

  return (
    <PageContentHeader size={PageContentHeaderSize.Large}>
      {generalLoading && (
        <HeaderLoadingContainer>
          <CircularProgress />
        </HeaderLoadingContainer>
      )}
      <Box display="flex" alignItems="center">
        {/* nitpick: this block is similar to ProjectDetailsHeader and InviteUserHeader - extract when app becomes more mature */}
        <ArrowBackIcon
          onClick={handleNavigateBack}
          style={{ cursor: "pointer" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          ml={3}
          mr={1}
        >
          {generalLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "14px" }} width={150} />
          ) : (
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link key="1" onClick={() => handleBreadcrumbClick()}>
                <Typography variant="body2">
                  {t("AdminConsole.Companies.labels.companies")}
                </Typography>
              </Link>
              <Link key="2" onClick={() => handleBreadcrumbClick("company")}>
                <Typography variant="body2">{companyName}</Typography>
              </Link>
            </Breadcrumbs>
          )}
          {generalLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "24px" }} width={150} />
          ) : (
            <Typography variant="h5">{getUserName(user)}</Typography>
          )}
        </Box>
        {user?.status && (
          <Box alignSelf="flex-end">
            <StatusTag
              status={user.status}
              onChange={handleStatusChange}
              options={userStatusOptions}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {user?.dateInvited && (
          <Box display="flex" alignItems="center">
            <Typography variant="body2">
              <Trans
                i18nKey="common.labels.invitedByOn"
                values={{
                  inviter: getUserName(user.invitedBy),
                  dateInvited: moment(user.dateInvited).format(
                    dateTimeISOFormat
                  ),
                }}
              />
            </Typography>
            {!user.registered && (
              <Tooltip
                title={t("AdminConsole.Users.labels.resendInvite")! as string}
                arrow
                placement="right"
              >
                <IconButton onClick={onResendRegisterInvitation}>
                  <InvitationIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
        {user?.registered && user.registeredDate && (
          <Box display="flex" alignItems="center" ml={4}>
            <Typography variant="body2">
              {t("common.labels.dateRegistered")}
            </Typography>
            <Typography variant="body2" fontWeight={600} ml={0.5}>
              {moment(user.registeredDate).format(dateTimeISOFormat)}
            </Typography>
          </Box>
        )}
      </Box>
    </PageContentHeader>
  );
};
