import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationConfirmationMutation,
  RecordVariationConfirmationMutationVariables,
  SendVariationConfirmationMutation,
  SendVariationConfirmationMutationVariables,
  VariationConfirmationPromptQuery,
  VariationConfirmationPromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "types/decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationConfirmationMutation,
  sendVariationConfirmationMutation,
  variationConfirmationPromptQuery,
} from "./ConfirmInstructionOfVariationAction.query";

export const useConfirmInstructionOfVariationAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationConfirmationPromptQuery,
    VariationConfirmationPromptQueryVariables
  >(variationConfirmationPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationConfirmationPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [
    sendVariationConfirmation,
    { loading: sendVariationConfirmationLoading },
  ] = useGraphMutation<
    SendVariationConfirmationMutation,
    SendVariationConfirmationMutationVariables
  >(
    sendVariationConfirmationMutation,
    { update: onUpdated },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Variations.variationConfirmation"),
    })
  );

  const [
    recordVariationConfirmation,
    { loading: recordVariationConfirmationLoading },
  ] = useGraphMutation<
    RecordVariationConfirmationMutation,
    RecordVariationConfirmationMutationVariables
  >(
    recordVariationConfirmationMutation,
    { update: onUpdated },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.variationConfirmation"),
    })
  );

  return {
    remarks: promptData?.variationConfirmationPrompt.remarks ?? "",
    loading,
    sendVariationConfirmation,
    recordVariationConfirmation,
    actionLoading:
      sendVariationConfirmationLoading || recordVariationConfirmationLoading,
  };
};
