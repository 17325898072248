import {
  ProductItemNextStepsDeemingQuery,
  ProductItemNextStepsDeemingQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useCallback, useEffect, useMemo, useState } from "react";
import { productItemNextStepsDeemingQuery } from "../NextSteps.query";
import { NextStepItem } from "../NextStepsTable/NextStepsTable";
import { nextStepItemQueryToNextStepItem } from "../NextSteps.utils";
import uniqBy from "lodash.uniqby";

const recordsChunkLimit = 50;

// Note: even though query returns ProductItem, only CEs will be returned as only CEs have deeming mechanism
export const useGetItemsScheduledForDeeming = () => {
  const [nextToken, setNextToken] = useState<string>();

  const [fetch, { data: items, fetchMore, refetch, loading }] =
    useGraphLazyQuery<
      ProductItemNextStepsDeemingQuery,
      ProductItemNextStepsDeemingQueryVariables
    >(productItemNextStepsDeemingQuery, {
      notifyOnNetworkStatusChange: true,
    });

  const loadMore = useCallback(async () => {
    if (nextToken) {
      const { data } = await fetchMore({
        variables: { limit: recordsChunkLimit, nextToken },
        updateQuery: (oldData, { fetchMoreResult: newData }) => {
          const newItems = [
            ...oldData.productItemNextStepsDeeming.items,
            ...newData.productItemNextStepsDeeming.items,
          ];

          const uniqNewItems = uniqBy(newItems, "id");

          return {
            ...newData,
            productItemNextStepsDeeming: {
              ...newData.productItemNextStepsDeeming,
              items: uniqNewItems,
            },
          };
        },
      });

      setNextToken(data.productItemNextStepsDeeming.nextToken ?? undefined);
    } else {
      const { data } = await fetch({
        variables: { limit: recordsChunkLimit },
      });

      setNextToken(data?.productItemNextStepsDeeming.nextToken ?? undefined);
    }
  }, [fetch, fetchMore, nextToken]);

  const reset = () => {
    setNextToken(undefined);
    refetch({ limit: recordsChunkLimit });
  };

  const itemsScheduledForDeeming = useMemo(() => {
    const rawItems = (items?.productItemNextStepsDeeming.items ??
      []) as NextStepItem[];

    return nextStepItemQueryToNextStepItem(rawItems);
  }, [items]);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line
  }, []);

  return {
    itemsScheduledForDeeming,
    loading,
    hasMore: !!nextToken,
    loadMore,
    reset,
  };
};
