import {
  VariationHistoryItem,
  VariationHistoryItemQuery,
  VariationHistoryItemQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { variationHistoryItemQuery } from "./variationHistoryItem.query";

export const useVariationHistoryItem = (id?: string) => {
  const { data: variationHistoryItem, loading: variationHistoryItemLoading } =
    useGraphQuery<
      VariationHistoryItemQuery,
      VariationHistoryItemQueryVariables
    >(variationHistoryItemQuery, {
      variables: {
        id: id!,
      },
      skip: !id,
    });

  return {
    variationHistoryItem: variationHistoryItem?.variationHistoryItem as
      | VariationHistoryItem
      | undefined,
    loading: variationHistoryItemLoading,
  };
};
