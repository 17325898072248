import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  dateTimeISOFormat,
  excelDateTimeISOFormat,
  temporaryRowId,
} from "constants/constants";
import {
  AddItemStatusCollectionInput,
  EditItemStatusCollectionInput,
  ItemStatusCollection,
  ItemStatusCollectionStatus,
  User,
} from "generated/graphql";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { useColumns } from "./ProductStatusCollections.constants";
import moment from "moment";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { ListBullets } from "phosphor-react";
import { NewAppPaths } from "helpers/paths/paths";
import { getUserName, openLocallyOrInNewTab } from "helpers/miscelaneous";

export type ProductStatusCollectionsProps = {
  collections: ItemStatusCollection[];
  productName?: string;
  productId?: string;
  loading?: boolean;
  onCollectionStatusChange: (
    row: ItemStatusCollection,
    newStatus: ItemStatusCollectionStatus
  ) => void;
  onCollectionAdd: (collectionData: AddItemStatusCollectionInput) => void;
  onCollectionUpdate: (collectionData: EditItemStatusCollectionInput) => void;
};

export const ProductStatusCollections: React.FC<
  ProductStatusCollectionsProps
> = ({
  collections,
  productId,
  productName,
  loading,
  onCollectionAdd,
  onCollectionStatusChange,
  onCollectionUpdate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ItemStatusCollectionStatus>[];

  const [rows, setRows] = useState<ItemStatusCollection[]>(collections);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(collections)
  );
  const [showCollections, setShowCollections] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "name", sort: "desc" },
  ]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ItemStatusCollectionStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name" },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("AdminConsole.Products.labels.isDefault"),
        key: "isDefault",
      },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
      { header: t("common.labels.createdBy"), key: "creator" },
    ];

    const rows = collections
      .filter(
        (collection) => (selectionModel || []).indexOf(collection.id) >= 0
      )
      .map((collection) => ({
        ...collection,
        isDefault: !!collection.isDefault,
        dateCreated: collection.dateCreated
          ? new Date(collection.dateCreated)
          : "",
        creator: getUserName(collection.creator),
      }));

    exportToExcel(
      `${productName}-${t("AdminConsole.Products.labels.statusCollections")}`,
      columns,
      rows
    );
  };

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (rowData: GridRowParams<ItemStatusCollection>, event) => {
      const nameCells = [
        ...window.document.querySelectorAll(
          '.MuiDataGrid-cell[data-field="name"]'
        ),
      ];
      if (!nameCells.some((cell) => cell.contains(event.target as any))) {
        // click was NOT inside "name" cell
        const selection = window.getSelection()?.toString();
        if (!selection) {
          const url =
            NewAppPaths.authorized.AdminConsole.children.System.children.Products.children.ProductDetails.children.StatusCollectionDetails.pathConstructor(
              productId!,
              rowData.row.id
            );
          openLocallyOrInNewTab(url, event, navigate);
        }
      }
    },
    [navigate, productId]
  );

  const handleRowChangesCommited = useCallback(
    (
      newRow: GridRowModel<ItemStatusCollection>,
      oldRow: GridRowModel<ItemStatusCollection>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.name) {
          onCollectionAdd({
            name: newRow.name,
            isDefault: newRow.isDefault,
            productId: productId!,
          });
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "name",
            },
          }));
        }
      } else if (
        newRow.name !== oldRow.name ||
        newRow.isDefault !== oldRow.isDefault
      ) {
        onCollectionUpdate({
          id: newRow.id,
          name: newRow.name,
          isDefault: newRow.isDefault,
          productId: productId!,
        });
      }

      return newRow;
    },
    [onCollectionAdd, onCollectionUpdate, productId]
  );

  const handleSchemaStatusChange = useCallback(
    (row: ItemStatusCollection, newStatus: ItemStatusCollectionStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        onCollectionStatusChange(row, newStatus);
      }
    },
    [onCollectionStatusChange]
  );

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        name: "",
        id: temporaryRowId,
        status: ItemStatusCollectionStatus.Active,
        isDefault: false,
        isInternal: false,
        productId: productId!,
        statusOptions: { items: [] },
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "name",
        },
      }));
    });
  };

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onCollectionStatusChange(
          collections.find((collection) => collection.id === rowId)!,
          ItemStatusCollectionStatus.Removed
        );
      }
    },
    [onCollectionStatusChange, collections]
  );

  const handleIsDefaultChange = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                isDefault: !row.isDefault,
              };
            }

            return row;
          })
        );
      } else {
        const updatedCollection = collections.find(
          (collection) => collection.id === rowId
        )!;

        onCollectionUpdate({
          id: String(rowId),
          name: updatedCollection.name,
          isDefault: !updatedCollection.isDefault,
          productId: productId!,
        });
      }
    },
    [onCollectionUpdate, collections, productId]
  );

  const columns = useColumns(
    statusOptions,
    rowModesModel,
    productId!,
    handleSchemaStatusChange,
    handleRowSaveClick,
    handleDeleteRow,
    handleIsDefaultChange
  );

  useEffect(() => {
    setRows(collections);
    setRowModesModel(computeGridRowModes(collections));
  }, [collections]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Products.labels.statusCollections")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <ListBullets
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowCollections((state) => !state)}
        collapsed={!showCollections}
      />
      <Collapse in={showCollections}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: ItemStatusCollection) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            onRowClick={handleRowClick}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, ItemStatusCollection, any>
            ) => {
              return params.row.status === ItemStatusCollectionStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
