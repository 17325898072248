import { DailyDiaryResourceRecord } from "generated/graphql";
import { hoursToDate } from "../WeatherSection/WeatherSection.utils";
import { ResourceFormDataType } from "./ResourceRecordModal/ResourceRecordForm";

export const resourceRecordToEditInput = (
  record: DailyDiaryResourceRecord
): ResourceFormDataType => {
  const {
    __typename: _,
    creator: __,
    creatorId: ___,
    dateCreated: ____,
    dateModified: _____,
    attachments: ______,
    teamLookupId,
    shiftLookupId,
    companyLookupId,
    resourceLookupId,
    hours,
    ...rest
  } = record;

  return {
    ...rest,
    hours: hoursToDate(hours),
    attachments: [], // TODO
  };
};
