import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  ListItem as MUIListItem,
  SelectChangeEvent,
  TextField,
  useTheme,
} from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  ListItem,
  ProductType,
  SendCompEventNoticeInput,
} from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { RecordCENotice } from "./RecordCENotice";
import { SendCENotice } from "./SendCENotice";
import { useNotifyCEACtion } from "./useNotifyCEAction";
import { useEarlyWarningsProductInstance } from "hooks/useEarlyWarningsProductInstance";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";
import { ClaimTypeSelect } from "containers/Projects/components/ActionModal/ClaimTypeSelectGridItem";

const dataValidators: DataValidators = {
  claimTypeId: {
    validators: [ValidatorType.Required],
  },
};

type FormDataType = Pick<
  SendCompEventNoticeInput,
  "claimTypeId" | "earlyWarningId"
>;

const defaultFormData = {
  claimTypeId: "",
  earlyWarningId: undefined,
};

export const NotifyCEAction: React.FC<CEActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { productInstanceId, regardingId, regardingType, contract } =
    useContext(CompEventWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    loading,
    actionLoading,
    earlyWarnings,
    claimTypes,
    sendCompEventNotice,
    recordCompEventNotice,
  } = useNotifyCEACtion();

  const { earlyWarningsProductInstance, loading: ewProdInstanceLoading } =
    useEarlyWarningsProductInstance(contract.id);

  const handleClaimTypeChange = (event: SelectChangeEvent<string>) => {
    setFormData((curData) => ({
      ...curData,
      claimTypeId: event.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { claimTypeId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleEWChange = (
    _: SyntheticEvent<Element, Event>,
    value: ListItem | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      earlyWarningId: value?.id || undefined,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { earlyWarningId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const result = validateData(formData, dataValidators);

    if (result.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(result.errors);
    }

    return result.valid;
  }, []);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };
  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCENotice = async () => {
    await sendCompEventNotice({
      variables: {
        input: {
          claimTypeId: formData.claimTypeId,
          earlyWarningId: formData.earlyWarningId || undefined,
          productInstanceId,
          regardingId,
          regardingType,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCENotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCompEventNotice({
      variables: {
        input: {
          claimTypeId: formData.claimTypeId,
          earlyWarningId: formData.earlyWarningId || undefined,
          productInstanceId,
          regardingId,
          regardingType,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [validateForm, formData]
  );

  const selectedEWItem = useMemo(
    () =>
      earlyWarnings?.find((ew) => ew.id === formData.earlyWarningId) || null,
    [earlyWarnings, formData]
  );

  const ewGivenDisabled =
    loading || !earlyWarningsProductInstance || !earlyWarnings?.length;

  const ewGivenPlaceholder = !earlyWarningsProductInstance
    ? t("Projects.CompEvents.noAccessToEW")
    : !earlyWarnings?.length
    ? t("Projects.CompEvents.noEWsFound")
    : undefined;

  return loading || ewProdInstanceLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {formData.claimTypeId && sendModalVisibility && (
        <SendCENotice
          open={sendModalVisibility}
          claimType={
            claimTypes!.find(
              (claimType) => claimType.id === formData.claimTypeId
            )!
          }
          ewGiven={earlyWarnings?.find(
            (ew) => ew.id === formData.earlyWarningId
          )}
          onPrimaryClick={handleSendCENotice}
          primaryBtnLoading={actionLoading}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
        />
      )}
      {formData.claimTypeId && recordModalVisibility && (
        <RecordCENotice
          open={recordModalVisibility}
          claimType={
            claimTypes!.find(
              (claimType) => claimType.id === formData.claimTypeId
            )!
          }
          ewGiven={earlyWarnings?.find(
            (ew) => ew.id === formData.earlyWarningId
          )}
          primaryBtnLoading={actionLoading}
          onPrimaryClick={handleRecordCENotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ClaimTypeSelect
              productType={ProductType.CompEvents}
              value={formData.claimTypeId}
              claimTypes={claimTypes!}
              error={formDataErrors.claimTypeId}
              onChange={handleClaimTypeChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <>
                <FormLabel
                  label={t("Projects.CompEvents.ActionModal.ewGiven")}
                />
                {ewGivenDisabled ? (
                  <TextField
                    disabled
                    variant="outlined"
                    placeholder={ewGivenPlaceholder}
                    InputProps={{
                      sx: {
                        "input.Mui-disabled": {
                          WebkitTextFillColor: theme.palette.grey[800],
                        },
                      },
                    }}
                    size="small"
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    disablePortal
                    id="early-warning-select-standard"
                    options={earlyWarnings || []}
                    sx={{ maxHeight: 200 }}
                    value={selectedEWItem}
                    onChange={handleEWChange}
                    getOptionLabel={(preset) => preset.name}
                    fullWidth
                    disabled={ewGivenDisabled}
                    renderOption={(props, option) => (
                      <MUIListItem
                        {...props}
                        data-testid={`ce-ew-given-${option.name}-option`}
                      >
                        {option.name}
                      </MUIListItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                )}
              </>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
