import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";

export const addAuthorizationWorkflowMutation = gql`
  ${authorizationWorkflowFields}

  mutation addAuthorizationWorkflow($input: AddAuthorizationWorkflowInput!) {
    addAuthorizationWorkflow(input: $input) {
      ...AuthorizationWorkflowFields
    }
  }
`;
