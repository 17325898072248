import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import {
  RecordCompEventAbandonInstructionMutation,
  RecordCompEventAbandonInstructionMutationVariables,
  SendCompEventAbandonInstructionMutation,
  SendCompEventAbandonInstructionMutationVariables,
} from "generated/graphql";
import { recordCompEventAbandonInstructionMutation } from "graphql/mutations/recordCompEventAbandonInstruction";
import { sendCompEventAbandonInstructionMutation } from "graphql/mutations/sendCompEventAbandonInstruction";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const useAbandonInstructionCEAction = () => {
  const { onChange, compEvent } = useContext(CompEventWidgetContext);
  const { t } = useTranslation();

  const [
    sendCompEventAbandonInstruction,
    { loading: sendCompEventAbandonInstructionLoading },
  ] = useGraphMutation<
    SendCompEventAbandonInstructionMutation,
    SendCompEventAbandonInstructionMutationVariables
  >(
    sendCompEventAbandonInstructionMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventAbandon"),
    })
  );

  const [
    recordCompEventAbandonInstruction,
    { loading: recordCompEventAbandonInstructionLoading },
  ] = useGraphMutation<
    RecordCompEventAbandonInstructionMutation,
    RecordCompEventAbandonInstructionMutationVariables
  >(
    recordCompEventAbandonInstructionMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventAbandon"),
    })
  );

  return {
    actionLoading:
      sendCompEventAbandonInstructionLoading ||
      recordCompEventAbandonInstructionLoading,
    sendCompEventAbandonInstruction,
    recordCompEventAbandonInstruction,
  };
};
