import {
  AddDailyDiaryWorkRecordMutation,
  AddDailyDiaryWorkRecordMutationVariables,
  DailyDiaryItem,
  EditDailyDiaryWorkRecordMutation,
  EditDailyDiaryWorkRecordMutationVariables,
  RemoveDailyDiaryWorkRecordMutation,
  RemoveDailyDiaryWorkRecordMutationVariables,
} from "generated/graphql";
import { addDailyDiaryWorkRecordMutation } from "graphql/mutations/addDailyDiaryWorkRecord";
import { editDailyDiaryWorkRecordMutation } from "graphql/mutations/editDailyDiaryWorkRecord";
import { removeDailyDiaryWorkRecordMutation } from "graphql/mutations/removeDailyDiaryWorkRecord";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { useWorkRecords } from "./useWorkRecords";

export const useWorkSection = (
  dailyDiary?: DailyDiaryItem,
  revisionId?: string
) => {
  const { t } = useTranslation();

  const { refetchDDByDate } = useContext(DailyDiaryContext);

  const { workRecords, workRecordsLoading, refetchDDWorkRecords } =
    useWorkRecords(dailyDiary, revisionId);

  const [addDDWorkRecord, { loading: addDDWorkRecordLoading }] =
    useGraphMutation<
      AddDailyDiaryWorkRecordMutation,
      AddDailyDiaryWorkRecordMutationVariables
    >(
      addDailyDiaryWorkRecordMutation,
      {
        update: async () => {
          if (!dailyDiary) {
            await refetchDDByDate?.();
          } else {
            refetchDDWorkRecords();
          }
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.DailyDiaries.workRecord"),
      })
    );

  const [editDDWorkRecord, { loading: editDDWorkRecordLoading }] =
    useGraphMutation<
      EditDailyDiaryWorkRecordMutation,
      EditDailyDiaryWorkRecordMutationVariables
    >(
      editDailyDiaryWorkRecordMutation,
      {
        update: () => {
          refetchDDWorkRecords();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.DailyDiaries.workRecord"),
      })
    );

  const [removeDDWorkRecord, { loading: removeDDWorkRecordLoading }] =
    useGraphMutation<
      RemoveDailyDiaryWorkRecordMutation,
      RemoveDailyDiaryWorkRecordMutationVariables
    >(
      removeDailyDiaryWorkRecordMutation,
      {
        update: () => {
          refetchDDWorkRecords();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.DailyDiaries.workRecord"),
      })
    );

  return {
    workRecords,
    loading:
      workRecordsLoading ||
      addDDWorkRecordLoading ||
      editDDWorkRecordLoading ||
      removeDDWorkRecordLoading,
    addWorkRecord: addDDWorkRecord,
    editWorkRecord: editDDWorkRecord,
    removeWorkRecord: removeDDWorkRecord,
    refetchDDWorkRecords,
  };
};
