import { gql } from "@apollo/client";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";

export const addAuthorizationWorkflowActionMappingMutation = gql`
  ${authorizationWorkflowActionMappingFields}

  mutation addAuthorizationWorkflowActionMapping(
    $input: AddAuthorizationActionInput!
  ) {
    addAuthorizationWorkflowActionMapping(input: $input) {
      ...AuthorizationWorkflowActionMappingFields
    }
  }
`;
