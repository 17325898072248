import { Box } from "@mui/material";
import { GridRowSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import { TimePeriod } from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumns } from "./ContractTypeTimePeriods.constants";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { GridHeader } from "components/GridHeader";

export type ContractTypeTimePeriodsProps = {
  contractTypeName?: string;
  timePeriods: TimePeriod[];
  loading?: boolean;
};

export const ContractTypeTimePeriods: React.FC<
  ContractTypeTimePeriodsProps
> = ({ contractTypeName, timePeriods, loading }) => {
  const { t } = useTranslation();

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "reference", sort: "asc" },
  ]);

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const handleExportTimePeriodsToExcel = () => {
    const columns = [
      { header: t("common.labels.description"), key: "description", width: 20 },
      {
        header: t("AdminConsole.TimePeriods.reference"),
        key: "reference",
        width: 20,
      },
      {
        header: t("AdminConsole.TimePeriods.durationDays"),
        key: "duration",
        width: 20,
      },
    ];

    const rows = timePeriods.filter(
      (timePeriods) => (selectionModel || []).indexOf(timePeriods.id) >= 0
    );

    exportToExcel(
      `${contractTypeName}-${t("AdminConsole.TimePeriods.timePeriods")}`,
      columns,
      rows
    );
  };

  const columns = useMemo(() => getColumns(t), [t]);

  return (
    <Box>
      <GridHeader
        title={t("AdminConsole.TimePeriods.timePeriods")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportTimePeriodsToExcel}
      />
      <StyledDataGrid
        apiRef={gridApiRef}
        rows={timePeriods}
        columns={columns}
        getRowId={(rowData: TimePeriod) => rowData.id}
        onRowSelectionModelChange={handleGridRowSelectionModelChange}
        loading={loading}
        sortingMode="client"
        sortModel={sortingModel}
        onSortModelChange={setSortingModel}
        filterMode="client"
        // experimentalFeatures={{ newEditingApi: true }}
        autoHeight
        hideFooter
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};
