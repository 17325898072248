import { gql } from "@apollo/client";

export const addContractBindingMutation = gql`
  mutation addContractBinding($input: AddContractBindingInput!) {
    addContractBinding(input: $input) {
      id
      contractBindingTypeId
      status
      defaultCompany
      representativeId
      isCompanyBilled
      dateCreated
      creator {
        id
        firstname
        surname
      }
      companyId
      contractId
    }
  }
`;
