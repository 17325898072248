import { useContext, useMemo } from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";

export enum DailyDiaryUserRole {
  Viewer = "Viewer",
  Owner = "Owner",
  Creator = "Creator",
  Reviewer = "Reviewer",
}

/**
 * Returns if the current user is Owner, Creator, Viewer. Reviwer is not a proper role and is computed based on the
 * authWorkflowAudit objects attached to each DailyDiaryItem.revision
 * @param ddProductInstanceId
 * @returns
 */
export const useDailyDiaryUserBERole = (ddProductInstanceId: string) => {
  const { authenticatedUser, loading } = useContext(GlobalContext);

  const authenticatedUserRole = useMemo(() => {
    if (!authenticatedUser || loading) {
      return undefined;
    }

    const roleOnThisProductInstance = authenticatedUser.roles.items.find(
      (role) => role.productInstanceId === ddProductInstanceId
    );

    if (!roleOnThisProductInstance) {
      return undefined;
    }

    // Note: page may contain different components based on the NAME of the role, which is unstable. But at the same time, ideally,
    // roles on DailyDiary product should not change
    switch (roleOnThisProductInstance.productRole.name) {
      case DailyDiaryUserRole.Owner:
        return DailyDiaryUserRole.Owner;
      case DailyDiaryUserRole.Creator:
        return DailyDiaryUserRole.Creator;
      case DailyDiaryUserRole.Viewer:
      default:
        return DailyDiaryUserRole.Viewer;
    }
  }, [authenticatedUser, ddProductInstanceId, loading]);

  return {
    userRole: authenticatedUserRole,
    loading: !!loading,
  };
};
