import {
  AuthorizationWorkflowAudit,
  ProjectAuthorizationAuditsQuery,
  ProjectAuthorizationAuditsQueryVariables,
} from "generated/graphql";
import { projectAuthorizationAuditsQuery } from "graphql/queries/projectAuthorizationAudits.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useCallback, useEffect, useMemo, useState } from "react";

// TODO: do query lighter. Take only what's needed
// TODO: in Home page, don't fetch all the objects, load only on demand when item is clicked and need to display the Authorize/Deny
const recordsChunkLimit = 20;

export const useProjectAuthorizationTrails = (projectId: string) => {
  const [nextToken, setNextToken] = useState<string>();

  const [
    loadAudits,
    {
      data: authorizationTrails,
      fetchMore,
      refetch,
      loading: authorizationTrailsLoading,
    },
  ] = useGraphLazyQuery<
    ProjectAuthorizationAuditsQuery,
    ProjectAuthorizationAuditsQueryVariables
  >(projectAuthorizationAuditsQuery, { notifyOnNetworkStatusChange: true });

  const loadMore = useCallback(async () => {
    if (nextToken) {
      const { data } = await fetchMore({
        variables: { limit: recordsChunkLimit, nextToken },
        updateQuery: (oldData, { fetchMoreResult: newData }) => ({
          ...newData,
          projectAuthorizationAudits: {
            ...newData.projectAuthorizationAudits,
            items: [
              ...oldData.projectAuthorizationAudits.items,
              ...newData.projectAuthorizationAudits.items,
            ],
          },
        }),
      });

      setNextToken(data.projectAuthorizationAudits.nextToken ?? undefined);
    } else {
      const { data } = await loadAudits({
        variables: { projectId, limit: recordsChunkLimit },
      });

      setNextToken(data?.projectAuthorizationAudits.nextToken ?? undefined);
    }
  }, [loadAudits, fetchMore, nextToken, projectId]);

  const reset = () => {
    setNextToken(undefined);
    refetch({ limit: recordsChunkLimit });
  };

  const authAuditTrails = useMemo(
    () =>
      (authorizationTrails?.projectAuthorizationAudits.items ??
        []) as AuthorizationWorkflowAudit[],
    [authorizationTrails]
  );

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line
  }, []);

  return {
    authAuditTrails,
    loading: authorizationTrailsLoading,
    hasMore: !!nextToken,
    loadMore,
    reset,
  };
};
