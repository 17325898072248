import {
  Chip,
  css,
  hexToRgb,
  PopperPlacementType,
  styled,
} from "@mui/material";

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "bgColor",
})<{ bgColor: string }>(({ bgColor, theme }) => {
  const colorAsRgb = hexToRgb(bgColor);
  const tokens = colorAsRgb.split("rgb(");
  const tokens2 = tokens[1].split(",");

  return css`
    && {
      cursor: pointer;
      background: rgba(
        ${parseInt(tokens2[0])},
        ${parseInt(tokens2[1])},
        ${parseInt(tokens2[2])},
        0.05
      );
      color: ${bgColor};
      &:hover {
        background: ${bgColor};
        color: ${theme.palette.common.white};
      }
    }
  `;
});

export const Arrow = styled("span")<{ placement?: PopperPlacementType }>(
  ({ placement = "" }) => css`
    position: absolute;
    top: ${placement?.indexOf("start") >= 0
      ? "15px !important"
      : "calc(100% - 35px) !important"};
    left: ${placement?.indexOf("right") >= 0 ? "-10px" : "calc(100% - 15px)"};
    border: 1px solid;
    height: 20px;
    width: 20px;
    transform: rotate(45deg) !important;
    border-color: transparent transparent white white;
    background: white;
    border-radius: 4px;
    box-shadow: ${placement?.indexOf("right") >= 0
      ? "-8px 8px 12px rgb(0 0 0 / 6%)"
      : "8px -8px 12px rgb(0 0 0 / 6%)"};
  `
);
