import { BasicModal } from "components/BasicModal/BasicModal";
import {
  CompEventItem,
  CompEventAction as CompEventActionType,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { CompEventActionView } from "./components/CompEventAction/CompEventActionView";

export type CompEventActionModalProps = {
  compEventActions: CompEventActionType[];
  compEvent?: CompEventItem;
  open: boolean;
  onClose: () => void;
};

export const CompEventActionModal: React.FC<CompEventActionModalProps> = ({
  compEvent,
  compEventActions,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      open={open}
      title={t("Projects.CompEvents.ActionModal.title")}
      maxWidth="md"
      titleNextToIcon
      onClose={onClose}
    >
      <CompEventActionView
        compEvent={compEvent}
        compEventActions={compEventActions}
        onCancel={onClose}
      />
    </BasicModal>
  );
};
