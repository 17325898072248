import { Box, css, styled } from "@mui/material";

export const CompEventWidgetSectionContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ${theme.spacing(2)};
    border-radius: 8px;
    border: 1px solid ${theme.palette.grey[200]};
    background: ${theme.palette.common.white};
  `
);
