import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { RegisterUserInput } from "generated/graphql";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import { FormPublicApi } from "types/decl";
import { UserProfileButton } from "components/UserProfileButton/UserProfileButton";
import { cleanseBase64Image } from "helpers/miscelaneous";
import { DatePicker } from "@mui/x-date-pickers";
import { dateISOFormat } from "constants/constants";
import moment from "moment";
import { TermsOfUseModal } from "components/TermsOfUseModal/TermsOfUseModal";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { PrivacyPolicyModal } from "components/PrivacyPolicyModal/PrivacyPolicyModal";
import { CountryData } from "helpers/countries/countries.decl";
import { CountriesSelectNew } from "components/CountriesSelectNew/CountriesSelectNew";

export type PersonalInfoFormType = Omit<
  RegisterUserInput,
  "invitationId" | "password"
>;

export type PersonalInfoFormProps = {
  apiRef?: React.Ref<FormPublicApi>;
  initialData?: PersonalInfoFormType;
  onChange: (data: PersonalInfoFormType) => void;
  onAgreementChecksChange: (checked: boolean) => void;
};

const formDataValidators: DataValidators = {
  country: {
    validators: [ValidatorType.Required],
  },
  dateOfBirth: {
    validators: [ValidatorType.Required, ValidatorType.ValidPastDate],
  },
  firstname: {
    validators: [ValidatorType.Required],
  },
  surname: {
    validators: [ValidatorType.Required],
  },
  jobTitle: {
    validators: [ValidatorType.Required],
  },
  mobileNumber: {
    validators: [ValidatorType.Required, ValidatorType.ValidPhoneNumber],
  },
  alternateNumber: {
    validators: [ValidatorType.NullablePhoneNumber],
  },
};

const defaultFormData: PersonalInfoFormType = {
  alternateNumber: "",
  country: "",
  dateOfBirth: "",
  firstname: "",
  jobTitle: "",
  mobileNumber: "",
  profilePicture: "",
  surname: "",
};

export const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({
  initialData,
  apiRef,
  onChange,
  onAgreementChecksChange,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialData ?? defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const {
    modalVisibility: termsOfUseModalVisibility,
    toggleModalVisibility: toggleTermsOfUseModalVisibility,
  } = useBasicModal();

  const {
    modalVisibility: privacyPolicyModalVisibility,
    toggleModalVisibility: togglePrivacyPolicyModalVisibility,
  } = useBasicModal();

  const [selectedCountry, setSelectedCountry] = useState<CountryData>();
  const [tncAgreementChecked, setTnCAgreementChecked] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const handleCountryChange = (country: CountryData) => {
    setSelectedCountry(country);
    setFormData((curFormData) => ({
      ...curFormData,
      country: country.name,
      mobileNumber: country.phonePrefix,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { country: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleBirthDateChange = (value: Date | null) => {
    setFormData((curData) => ({
      ...curData,
      dateOfBirth: value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { dateOfBirth: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handlePhotoUpload = (photo?: string) => {
    setFormData((curFormData) => ({
      ...curFormData,
      profilePicture: photo,
    }));
  };

  const validateForm = useCallback(
    (formData: PersonalInfoFormType) => {
      const validationResult = validateData(formData, formDataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});

        return tncAgreementChecked;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [tncAgreementChecked]
  );

  const handleTnCChange = () => {
    setTnCAgreementChecked((state) => !state);
  };

  const handlePrivacyPolicyChange = () => {
    setPrivacyPolicyChecked((state) => !state);
  };

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [validateForm, formData, resetForm]
  );

  useEffect(() => {
    onAgreementChecksChange(tncAgreementChecked && privacyPolicyChecked);
  }, [onAgreementChecksChange, tncAgreementChecked, privacyPolicyChecked]);

  useEffect(() => {
    const { dateOfBirth, ...restFormData } = formData;

    onChange({
      ...restFormData,
      dateOfBirth: dateOfBirth
        ? moment(dateOfBirth).format(dateISOFormat)
        : undefined,
      profilePicture: cleanseBase64Image(formData.profilePicture),
    });
  }, [onChange, formData]);

  return (
    <>
      <TermsOfUseModal
        open={termsOfUseModalVisibility}
        onClose={toggleTermsOfUseModalVisibility}
      />
      <PrivacyPolicyModal
        open={privacyPolicyModalVisibility}
        onClose={togglePrivacyPolicyModalVisibility}
      />
      <Stack>
        <Grid container spacing={4.5}>
          <Grid item xs={12}>
            <FormLabel label={t("Settings.profile.profilePhoto")} />
            <UserProfileButton
              onPhotoUpload={handlePhotoUpload}
              buttonHintCaption={t("UserProfileButton.hint")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.firstName")} required />
            <TextField
              fullWidth
              name="firstname"
              value={formData.firstname}
              onChange={handleTextFieldChange}
              type="text"
              error={!!formDataErrors.firstname}
              helperText={formDataErrors.firstname}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.lastName")} required />
            <TextField
              fullWidth
              name="surname"
              value={formData.surname}
              onChange={handleTextFieldChange}
              type="text"
              error={!!formDataErrors.surname}
              helperText={formDataErrors.surname}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel label={t("Register.labels.jobTitle")} required />
            <TextField
              fullWidth
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleTextFieldChange}
              type="text"
              error={!!formDataErrors.jobTitle}
              helperText={formDataErrors.jobTitle}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel label={t("common.labels.country")} required />
              <CountriesSelectNew
                value={selectedCountry}
                onChange={handleCountryChange}
                hasErrors={!!formDataErrors.country}
              />
              {!!formDataErrors.country && (
                <Typography variant="caption" color="error">
                  {formDataErrors.country}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.mobileNumber")} required />
            <TextField
              fullWidth
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleTextFieldChange}
              type="text"
              placeholder={t("common.labels.phonePlaceholder")}
              error={!!formDataErrors.mobileNumber}
              helperText={formDataErrors.mobileNumber}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.alternativeNumber")} />
            <TextField
              fullWidth
              name="alternateNumber"
              value={formData.alternateNumber}
              onChange={handleTextFieldChange}
              type="text"
              placeholder={t("common.labels.phonePlaceholder")}
              error={!!formDataErrors.alternateNumber}
              helperText={formDataErrors.alternateNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.dateOfBirth")} required />
            <DatePicker
              value={
                formData.dateOfBirth ? new Date(formData.dateOfBirth) : null
              }
              onChange={handleBirthDateChange}
              data-testid="dateOfBirth-picker"
              format="yyyy-MM-dd"
              maxDate={new Date()}
              slotProps={{
                popper: {
                  placement: "bottom-end",
                },
                textField: {
                  fullWidth: true,
                  error: !!formDataErrors.dateOfBirth,
                  helperText: formDataErrors.dateOfBirth,
                  required: true,
                },
              }}
            />
          </Grid>
        </Grid>
        <Stack mt={3}>
          <Box display="flex" alignItems="center">
            <Checkbox
              inputProps={{
                "aria-label": "Agreement",
              }}
              onChange={handleTnCChange}
              checked={tncAgreementChecked}
            />
            <Typography variant="body2">
              {t("common.labels.IAgreeTo")}
              <Typography
                variant="body2"
                color="primary.main"
                component="span"
                ml={0.5}
                sx={{ cursor: "pointer" }}
                onClick={toggleTermsOfUseModalVisibility}
              >
                {t("TermsOfUse.termsOfUse")}
              </Typography>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              inputProps={{
                "aria-label": "PrivacyPolicy",
              }}
              onChange={handlePrivacyPolicyChange}
              checked={privacyPolicyChecked}
            />
            <Typography variant="body2">
              {t("common.labels.IAgreeTo")}
              <Typography
                variant="body2"
                color="primary.main"
                component="span"
                ml={0.5}
                sx={{ cursor: "pointer" }}
                onClick={togglePrivacyPolicyModalVisibility}
              >
                {t("PrivacyPolicy.privacyPolicy")}
              </Typography>
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
