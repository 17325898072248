import { gql } from "@apollo/client";

export const changeDocumentTemplateStatusMutation = gql`
  mutation changeDocumentTemplateStatus(
    $id: ID!
    $status: DocumentTemplateStatus!
  ) {
    changeDocumentTemplateStatus(id: $id, status: $status) {
      id
      name
      status
    }
  }
`;
