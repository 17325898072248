import { Stack } from "@mui/material";
import {
  AuthorizationWorkflowAuditTrail,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { ReviewersLevel } from "./ReviewersLevel";
import { useMemo } from "react";
import { getOrderedLevels } from "containers/Home/components/Authorizations/Authorizations.utils";

type ReviewersListProps = {
  authLevels: AuthorizationWorkflowLevel[];
  auditTrails: AuthorizationWorkflowAuditTrail[];
};

export const ReviewersList: React.FC<ReviewersListProps> = ({
  authLevels,
  auditTrails,
}) => {
  const orderedAuthLevels = useMemo(
    () => getOrderedLevels(authLevels)!,
    [authLevels]
  );

  return (
    <Stack spacing={3} width="100%">
      {orderedAuthLevels.map((level) => {
        const levelAuditTrails = auditTrails.filter((trail) =>
          level.userIds.includes(trail.creatorId)
        );
        return (
          <ReviewersLevel
            key={level.sequence}
            level={level}
            levelNo={level.sequence}
            auditTrails={levelAuditTrails}
          />
        );
      })}
    </Stack>
  );
};
