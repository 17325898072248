import { DataValidators, ValidatorType } from "helpers/validators";
import { TableConfigType } from "./InsertTableForm";

export const dataValidators: DataValidators = {
  noOfRows: {
    validators: [ValidatorType.Required],
  },
  noOfColumns: {
    validators: [ValidatorType.Required],
  },
};

export const defaultFormData: TableConfigType = {
  noOfRows: 4,
  noOfColumns: 4,
  includeHeaders: false,
};
