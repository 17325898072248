import { CompEventActionType } from "generated/graphql";

export const compEventActionTypeTranslations: Record<
  CompEventActionType,
  string
> = {
  [CompEventActionType.NotifyCompEvent]:
    "Projects.CompEvents.ActionTypes.NotifyCompEvent",
  [CompEventActionType.AbandonInstruction]:
    "Projects.CompEvents.ActionTypes.AbandonInstruction",
  [CompEventActionType.AcceptQuotation]:
    "Projects.CompEvents.ActionTypes.AcceptQuotation",
  [CompEventActionType.ConfirmCompEvent]:
    "Projects.CompEvents.ActionTypes.ConfirmCompEvent",
  [CompEventActionType.NotifyCompEventRequestQuotation]:
    "Projects.CompEvents.ActionTypes.NotifyCompEventRequestQuotation",
  [CompEventActionType.NotifyOwnAssessment]:
    "Projects.CompEvents.ActionTypes.NotifyOwnAssessment",
  [CompEventActionType.RejectCompEvent]:
    "Projects.CompEvents.ActionTypes.RejectCompEvent",
  [CompEventActionType.RequestMoreTime]:
    "Projects.CompEvents.ActionTypes.RequestMoreTime",
  [CompEventActionType.RequestRevisedQuotation]:
    "Projects.CompEvents.ActionTypes.RequestRevisedQuotation",
  [CompEventActionType.SubmitOwnAssessment]:
    "Projects.CompEvents.ActionTypes.SubmitOwnAssessment",
  [CompEventActionType.SubmitQuotation]:
    "Projects.CompEvents.ActionTypes.SubmitQuotation",
  [CompEventActionType.SubmitRevisedQuotation]:
    "Projects.CompEvents.ActionTypes.SubmitRevisedQuotation",
  [CompEventActionType.ProposedInstructionChangedDecision]:
    "Projects.CompEvents.ActionTypes.ProposedInstructionChangedDecision",
  [CompEventActionType.DenyRequestMoreTime]:
    "Projects.CompEvents.ActionTypes.DenyRequestMoreTime",
  [CompEventActionType.GrantRequestMoreTime]:
    "Projects.CompEvents.ActionTypes.GrantRequestMoreTime",
  [CompEventActionType.NotifySilenceCompEventNotice]:
    "Projects.CompEvents.ActionTypes.NotifySilenceCompEventNotice",
  [CompEventActionType.NotifySilenceOwnAssessment]:
    "Projects.CompEvents.ActionTypes.NotifySilenceOwnAssessment",
  [CompEventActionType.NotifySilenceQuotation]:
    "Projects.CompEvents.ActionTypes.NotifySilenceQuotation",
  [CompEventActionType.CompEventDeemedConfirmed]:
    "Projects.CompEvents.ActionTypes.CompEventDeemedConfirmed",
  [CompEventActionType.QuotationDeemedAccepted]:
    "Projects.CompEvents.ActionTypes.QuotationDeemedAccepted",
};

// export const compEventActionTypeProductOutputActionMapping = {
//   [CompEventActionType.ConfirmCompEvent]: "Confirm CE",
//   [CompEventActionType.AcceptQuotation]: "Accept quotation",
//   [CompEventActionType.SubmitOwnAssessment]: "Submit own assessment",
// } as Record<CompEventActionType, string>;
