import { gql } from "@apollo/client";
import { dailyDiaryWeatherRecordLiteFields } from "graphql/fragments/dailyDiaryWeatherRecordLite.fragment";

export const addDailyDiaryWeatherRecordMutation = gql`
  ${dailyDiaryWeatherRecordLiteFields}

  mutation addDailyDiaryWeatherRecord(
    $input: AddDailyDiaryWeatherRecordInput!
  ) {
    addDailyDiaryWeatherRecord(input: $input) {
      ...DailyDiaryWeatherRecordLiteFields
    }
  }
`;
