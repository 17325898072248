import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  DraftVariationAgreement,
  VariationActionType,
} from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordNotifyAgreementOfVariationModal } from "./useRecordNotifyAgreementOfVariationModal";
import { VariationProposalAgreementDeterminationSummary } from "../components/VariationProposalAgreementDeterminationSummary";

export type RecordNotifyAgreementOfVariationModalProps = {
  draftVariationAgreements: DraftVariationAgreement[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordNotifyAgreementOfVariationModal: React.FC<
  RecordNotifyAgreementOfVariationModalProps
> = ({ draftVariationAgreements, ...restDialogProps }) => {
  const { variationPreview, contract } =
    useRecordNotifyAgreementOfVariationModal(draftVariationAgreements[0].id);
  return (
    <RecordAction
      actionType={VariationActionType.NotifyAgreementVariation}
      variationPreview={variationPreview}
      summaryEl={
        <VariationProposalAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftVariationAgreements}
          type="VariationAgreement"
        />
      }
      {...restDialogProps}
    />
  );
};
