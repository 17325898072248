import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventOwnAssessmentPreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventOwnAssessmentPreview(
    $input: RecordCompEventOwnAssessmentPreviewInput!
  ) {
    generateRecordCompEventOwnAssessmentPreview(input: $input) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
