import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const KeyboardArrowDownIcon: React.FC<BaseIconProps> = ({
  height = "7",
  width = "12",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[900];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9981 0.999023L5.9971 6.00003L0.996094 0.999023"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
