import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import {
  CompEventQuotationAssessment,
  CompEventQuotationTimeChange,
  Contract,
  ContractTimeImpact,
  DraftAssessment,
  ProductType,
} from "generated/graphql";
import { Money } from "phosphor-react";
import { ContractPriceTimeChangeReadOnlyView } from "../../HistoryModal/ContractPriceTimeChangeReadOnlyView";
import { useMemo } from "react";

export type CEQuotationAssessmentReadOnlyModalProps = {
  quotationAssessment: CompEventQuotationAssessment | DraftAssessment;
  contract: Contract;
} & BasicModalProps;

export const CEQuotationAssessmentReadOnlyModal: React.FC<
  CEQuotationAssessmentReadOnlyModalProps
> = ({ quotationAssessment, contract, ...restDialogProps }) => {
  const sectionalChanges = useMemo(() => {
    if (
      quotationAssessment.sectionalChanges &&
      quotationAssessment.sectionalChanges.length
    ) {
      if (
        (quotationAssessment.sectionalChanges as ContractTimeImpact[])[0].id
      ) {
        return quotationAssessment.sectionalChanges as ContractTimeImpact[];
      } else {
        return quotationAssessment.sectionalChanges as CompEventQuotationTimeChange[];
      }
    }

    return undefined;
  }, [quotationAssessment]);

  const keyDatesChanges = useMemo(() => {
    if (
      quotationAssessment.keyDatesChanges &&
      quotationAssessment.keyDatesChanges.length
    ) {
      if ((quotationAssessment.keyDatesChanges as ContractTimeImpact[])[0].id) {
        return quotationAssessment.keyDatesChanges as ContractTimeImpact[];
      } else {
        return quotationAssessment.keyDatesChanges as CompEventQuotationTimeChange[];
      }
    }

    return undefined;
  }, [quotationAssessment]);

  return (
    <BasicModal
      maxWidth="sm"
      titleNextToIcon
      title={quotationAssessment.reference}
      headerIcon={
        <IconContainer greyBackground>
          <Money size={24} />
        </IconContainer>
      }
      {...restDialogProps}
    >
      <ContractPriceTimeChangeReadOnlyView
        details={quotationAssessment.details}
        time={quotationAssessment.time}
        price={quotationAssessment.price}
        contract={contract}
        dateSent={quotationAssessment.dateCreated}
        keyDatesChanges={keyDatesChanges}
        sectionalChanges={sectionalChanges}
        attachments={quotationAssessment.attachments}
        productType={ProductType.CompEvents}
      />
    </BasicModal>
  );
};
