import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const CaretDownIcon: React.FC<
  BaseIconProps & { onClick?: () => void }
> = ({ height = "7", width = "12", dataTestId, color, onClick }) => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      data-testid={dataTestId}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <path
        d="M11 1.00244L6 6.00244L1 1.00244"
        stroke={color ?? theme.palette.common.black}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
