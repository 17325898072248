import { Box, Grid, Typography } from "@mui/material";
import {
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { ClaimActionFormProps } from "../ClaimActionView";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { FormLabel } from "components/FormLabel";
import { useTranslation } from "react-i18next";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { useNotifyErrorFoundInAgreement } from "./useNotifyErrorFoundInAgreementAction";
import { SendErrorFoundInAgreementNoticeModal } from "./SendErrorFoundInAgreementNoticeModal";
import { RecordErrorFoundInAgreementNoticeModal } from "./RecordErrorFoundInAgreementNoticeModal";

const dataValidators: DataValidators = {
  details: {
    validators: [ValidatorType.RequiredRichText],
  },
};

type FormDataType = {
  details: string;
  // attachments?: AttachmentInput[];
};

const defaultFormData: FormDataType = {
  details: "",
  // attachments: [],
};

export const NotifyErrorFoundInAgreement: React.FC<ClaimActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { claim } = useContext(ClaimWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendClaimErrorAgreementNotice,
    recordClaimErrorAgreementNotice,
    actionLoading,
  } = useNotifyErrorFoundInAgreement();

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendErrorInAgreementNotice = async () => {
    await sendClaimErrorAgreementNotice({
      variables: {
        input: {
          claimId: claim?.id!,
          details: formData.details,
          // attachments: formData.attachments,
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordErrorInAgreementNotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordClaimErrorAgreementNotice({
      variables: {
        input: {
          claimId: claim?.id!,
          details: formData.details,
          // attachments: formData.attachments,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const handleTextFieldChange = (details: string) => {
    setFormData((curData) => ({
      ...curData,
      details,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { details: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const result = validateData(formData, dataValidators);

    if (result.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(result.errors);
    }

    return result.valid;
  }, []);

  const isFormValid = useMemo(() => !!formData.details, [formData]);

  // const handleAttachmentsUpdated = async (
  //   attachmentsUpdated: AttachmentInput[]
  // ) => {
  //   setFormData((crtFormData) => ({
  //     ...crtFormData,
  //     attachments: attachmentsUpdated,
  //   }));
  // };

  // const {
  //   allAttachments,
  //   addAttachments,
  //   removeAttachment,
  //   updateAttachment,
  //   downloadAttachment,
  //   unloadLocalAttachments,
  // } = useAttachments([], handleAttachmentsUpdated); // always starting from empty attachments.

  // const handleBeforeAbort = useCallback(() => {
  //   // Error found in agreement flow not finished. Remove all allegedly added attachments from the server
  //   unloadLocalAttachments();
  // }, [unloadLocalAttachments]);

  // const {
  //   imageAttachmentPreviewModalVisible,
  //   imagePreviewData,
  //   previewUrl,
  //   handleAttachmentClick,
  //   closeModal: closeImagePreviewModal,
  // } = useImagePreviewModal(downloadAttachment);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
      onBeforeAbort: () => {},
      // onBeforeAbort: handleBeforeAbort,
    }),
    [formData, validateForm]
  );

  return (
    <>
      {/* <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      /> */}
      {isFormValid && sendModalVisibility && (
        <SendErrorFoundInAgreementNoticeModal
          open={sendModalVisibility}
          details={formData.details}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handleSendErrorInAgreementNotice}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordErrorFoundInAgreementNoticeModal
          open={recordModalVisibility}
          details={formData.details}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handleRecordErrorInAgreementNotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel
              label={t("Projects.Claims.ActionModal.errorsFound")}
              required
            />
            <RichTextArea
              content={formData.details}
              error={!!formDataErrors.details}
              onChange={handleTextFieldChange}
            />
            {!!formDataErrors.details && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.details}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <Attachments
              editMode
              showTabs={false}
              timezone={}
              dencity={AttachmentsDencity.Compact}
              defaultLayout={AttachmentsLayout.List}
              attachments={allAttachments}
              onAttachmentsAdd={addAttachments}
              onAttachmentRemove={removeAttachment}
              onAttachmentUpdate={updateAttachment}
              onAttachmentClick={handleAttachmentClick}
            />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};
