import { gql } from "@apollo/client";
import { contractTypeLiteFields } from "graphql/fragments/contractTypeLite.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";

export const localProductInstanceFields = gql`
  ${contractTypeLiteFields}

  fragment LocalProductInstanceFields on ProductInstance {
    id
    description
    status
    contract {
      id
      friendlyName
      timeZone
      project {
        id
        friendlyName
      }
      contractType {
        ...ContractTypeLiteFields
      }
    }
  }
`;

export const localRegardingEventFields = gql`
  fragment LocalRegardingEventFields on EventItem {
    id
    title
    productInstanceId
    number
    data {
      ...ItemDataFields
    }
    productInstance {
      id
      product {
        id
      }
    }
  }
`;

export const localRegardingInstructionFields = gql`
  fragment LocalRegardingInstructionFields on InstructionItem {
    id
    title
    number
    productInstanceId
    data {
      ...ItemDataFields
    }
    productInstance {
      id
      product {
        id
      }
    }
  }
`;

export const nextStepsCompEventItemFields = gql`
  ${itemDataFields}
  ${localProductInstanceFields}
  ${localRegardingEventFields}
  ${localRegardingInstructionFields}

  fragment NextStepsCompEventItemFields on CompEventItem {
    id
    number
    productInstanceId
    regardingId
    ceRegardingType: regardingType
    deemingDate
    nextStep {
      ceActionType: actionType
      dueDate
      party {
        id
        description
      }
    }
    productInstance {
      ...LocalProductInstanceFields
    }
    regardingEvent {
      ...LocalRegardingEventFields
    }
    regardingInstruction {
      ...LocalRegardingInstructionFields
    }
  }
`;

export const nextStepsVariationItemFields = gql`
  ${itemDataFields}
  ${localProductInstanceFields}
  ${localRegardingInstructionFields}

  fragment NextStepsVariationItemFields on VariationItem {
    id
    number
    productInstanceId
    regardingId
    nextStep {
      voActionType: actionType
      dueDate
      party {
        id
        description
      }
    }
    productInstance {
      ...LocalProductInstanceFields
    }
    regardingInstruction {
      ...LocalRegardingInstructionFields
    }
  }
`;

export const nextStepsClaimItemFields = gql`
  ${itemDataFields}
  ${localProductInstanceFields}
  ${localRegardingEventFields}
  ${localRegardingInstructionFields}

  fragment NextStepsClaimItemFields on ClaimItem {
    id
    number
    productInstanceId
    regardingId
    claimRegardingType: regardingType
    nextStep {
      claimActionType: actionType
      dueDate
      party {
        id
        description
      }
    }
    productInstance {
      ...LocalProductInstanceFields
    }
    regardingEvent {
      ...LocalRegardingEventFields
    }
    regardingInstruction {
      ...LocalRegardingInstructionFields
    }
  }
`;
