import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";

export const editAuthorizationWorkflowMutation = gql`
  ${authorizationWorkflowFields}

  mutation editAuthorizationWorkflow($input: EditAuthorizationWorkflowInput!) {
    editAuthorizationWorkflow(input: $input) {
      ...AuthorizationWorkflowFields
    }
  }
`;
