import { Box, css, styled } from "@mui/material";
import { Card } from "components/Card";

export const NotFoundCard = styled(Card)(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    flex-direction: row;
    padding: 0 0 ${theme.spacing(20)} 0;
    height: auto;
  `
);

export const NotFoundTextContainer = styled(Box)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(8)};
    margin-top: ${theme.spacing(8)};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `
);
