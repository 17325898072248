import { useTheme } from "@mui/material";
import { GridCellParams, GridEventListener } from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { CompEventQuotation, DraftQuotation } from "generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmptyQuotationsAssessmentsTableView } from "./EmptyQuotationsAssessmentsTableView";
import {
  getColumns,
  localCEQuotationToCEQuotation,
} from "./QuotationsTable.constants";

export type LocalCEQuotation = { isSelected?: boolean } & CompEventQuotation;

export type QuotationsTableProps = {
  contractCurrency: string;
  loading?: boolean;
  acceptedQuotationId?: string;
  selectable?: boolean;
  quotations: DraftQuotation[] | CompEventQuotation[];
  onDelete?: (quotationId: string) => void;
  onRowClick?: (quotation: DraftQuotation | CompEventQuotation) => void;
};

export const QuotationsTable: React.FC<QuotationsTableProps> = ({
  loading,
  contractCurrency,
  quotations,
  selectable,
  acceptedQuotationId,
  onDelete,
  onRowClick,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const columns = useMemo(
    () => getColumns(contractCurrency, t, theme, onDelete, selectable),
    [contractCurrency, t, onDelete, selectable, theme]
  );

  const [rows, setRows] = useState<LocalCEQuotation[] | DraftQuotation[]>(
    quotations ?? []
  );

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (params) => {
      const quotation = params.row;

      if (onRowClick) {
        onRowClick(
          quotation.__typename === "CompEventQuotation"
            ? localCEQuotationToCEQuotation(quotation as LocalCEQuotation)
            : quotation
        );
      }
    },
    [onRowClick]
  );

  useEffect(() => {
    setRows(quotations ?? []);
  }, [quotations]);

  useEffect(() => {
    // auto-selecting the first quotation for acceptance flow. TODO: will remove this in the future
    if (selectable && acceptedQuotationId) {
      setRows((crtRows) =>
        crtRows.map(
          (row) =>
            (row.id === acceptedQuotationId
              ? { ...row, isSelected: true }
              : row) as LocalCEQuotation
        )
      );
    }
  }, [selectable, acceptedQuotationId]);

  return (
    <NewStyledDataGrid
      rows={rows}
      columns={columns}
      getRowId={(rowData: DraftQuotation | LocalCEQuotation) => rowData.id}
      loading={loading}
      slots={{ noRowsOverlay: () => <EmptyQuotationsAssessmentsTableView /> }}
      getCellClassName={(
        params: GridCellParams<any, DraftQuotation | LocalCEQuotation, any>
      ) => {
        return (params.row as LocalCEQuotation).isSelected ? "selected" : "";
      }}
      onRowClick={handleRowClick}
      disableRowSelectionOnClick
      hideFooter
      autoHeight
    />
  );
};
