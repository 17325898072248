import {
  Box,
  Link,
  Typography,
  Breadcrumbs,
  Collapse,
  useTheme,
} from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList, ListItem } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useContractTypeStatusOptions } from "components/StatusTag/useContractTypeStatusOptions";
import {
  ProductQuery,
  ProductQueryVariables,
  ProductStatus,
  ProductSchema,
  ChangeProductSchemaStatusMutation,
  ChangeProductSchemaStatusMutationVariables,
  ProductSchemaStatus,
  AddProductSchemaMutationVariables,
  AddProductSchemaMutation,
  AddProductSchemaInput,
  EditProductSchemaMutation,
  EditProductSchemaMutationVariables,
  EditProductSchemaInput,
  ItemStatusCollection,
} from "generated/graphql";
import { changeProductSchemaStatusMutation } from "graphql/mutations/changeProductSchemaStatus";
import { editProductSchemaMutation } from "graphql/mutations/editProductSchema";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useNavigateBack } from "hooks/useNavigateBack";
import { Kanban } from "phosphor-react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { addProductSchemaMutation, productQuery } from "./ProductDetails.query";
import { ProductSchemas } from "./ProductSchemas/ProductSchemas";
import { ProductStatusCollections } from "./ProductStatusCollections/ProductStatusCollections";
import { useProductStatusCollections } from "./ProductStatusCollections/useProductStatusCollections";

export const ProductDetails = () => {
  const handleNavigateBack = useNavigateBack();
  const { t } = useTranslation();
  const theme = useTheme();
  const { productId } = useParams();
  const statusOptions =
    useContractTypeStatusOptions() as StatusOption<ProductStatus>[];

  const [showDetails, setShowDetails] = useState(true);

  const {
    data: productData,
    refetch: refetchProductData,
    loading: getProductDataLoading,
  } = useGraphQuery<ProductQuery, ProductQueryVariables>(productQuery, {
    variables: { id: productId! },
  });

  const [
    changeProductSchemaStatus,
    { loading: changeProductSchemaStatusLoading },
  ] = useGraphMutation<
    ChangeProductSchemaStatusMutation,
    ChangeProductSchemaStatusMutationVariables
  >(
    changeProductSchemaStatusMutation,
    {
      update: () => {
        refetchProductData();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.schema"),
    })
  );

  const [addProductSchema, { loading: addProductSchemaLoading }] =
    useGraphMutation<
      AddProductSchemaMutation,
      AddProductSchemaMutationVariables
    >(
      addProductSchemaMutation,
      {
        update: () => {
          refetchProductData();
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("common.labels.schema"),
      })
    );

  const [editProductSchema, { loading: editProductSchemaLoading }] =
    useGraphMutation<
      EditProductSchemaMutation,
      EditProductSchemaMutationVariables
    >(
      editProductSchemaMutation,
      {
        update: () => {
          refetchProductData();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.schema"),
      })
    );

  const onProductStatusCollectionsChange = () => refetchProductData();

  const {
    addProductStatusCollection,
    addProductStatusCollectionLoading,
    editProductStatusCollection,
    editProductStatusCollectionLoading,
    changeProductStatusCollectionStatus,
    changeProductStatusCollectionStatusLoading,
  } = useProductStatusCollections({
    onProductStatusCollectionAdded: onProductStatusCollectionsChange,
    onProductStatusCollectionStatusChange: onProductStatusCollectionsChange,
    onProductStatusCollectionUpdated: onProductStatusCollectionsChange,
  });

  const handleSchemaStatusChange = useCallback(
    async (row: ProductSchema, newStatus: ProductSchemaStatus) => {
      await changeProductSchemaStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeProductSchemaStatus]
  );

  const handleAddSchema = useCallback(
    async (schemaData: Omit<AddProductSchemaInput, "productId">) => {
      await addProductSchema({
        variables: {
          input: {
            name: schemaData.name,
            productId: productId!,
            isDefault: productData?.product.productSchemas.items.length
              ? !!schemaData.isDefault
              : true,
          },
        },
      });
    },
    [addProductSchema, productId, productData]
  );

  const handleSchemaUpdate = useCallback(
    async (schemaData: Omit<EditProductSchemaInput, "productId">) => {
      await editProductSchema({
        variables: {
          input: {
            productId: productId!,
            name: schemaData.name,
            isDefault: schemaData.isDefault,
            id: schemaData.id,
          },
        },
      });
    },
    [editProductSchema, productId]
  );

  const loading = getProductDataLoading;

  const productEntityObj = useMemo(() => {
    const toReturn: ListItem[] = [];

    if (!productData?.product) {
      return toReturn;
    }

    return [
      {
        id: "name",
        label: t("common.labels.name"),
        value: productData.product.name,
      },
      {
        id: "numberingFormat",
        label: t("AdminConsole.Products.labels.defaultNumberingFormat"),
        value: productData.product.numberingFormat,
      },
      {
        id: "soloModeSupported",
        label: t("AdminConsole.Products.labels.soloMode"),
        value: String(productData.product.soloModeSupported),
      },
      {
        id: "allowMultipleInstances",
        label: t("AdminConsole.Products.labels.multipleProductInstances"),
        value: String(productData.product.allowMultipleInstances),
      },
    ];
  }, [productData, t]);

  return (
    <Box>
      <EntityDetailsHeader
        loading={loading}
        title={productData?.product.name || ""}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={handleNavigateBack}>
              <Typography variant="body2">
                {t("AdminConsole.Products.labels.products")}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        status={productData?.product.status}
        statusOptions={statusOptions}
      />
      <CollapsibleHeader
        title={t("AdminConsole.Products.labels.productDetails")}
        collapsed={!showDetails}
        icon={
          <Kanban size={22} weight="fill" color={theme.palette.primary.main} />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable={false}
        editMode={false}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          <DetailsList loading={loading} entity={productEntityObj} />
        </PageContentContainer>
      </Collapse>
      <ProductSchemas
        schemas={
          (productData?.product.productSchemas.items as ProductSchema[]) || []
        }
        productName={productData?.product.name}
        loading={
          getProductDataLoading ||
          changeProductSchemaStatusLoading ||
          addProductSchemaLoading ||
          editProductSchemaLoading
        }
        onSchemaAdd={handleAddSchema}
        onSchemaStatusChange={handleSchemaStatusChange}
        onSchemaUpdate={handleSchemaUpdate}
      />
      <ProductStatusCollections
        collections={
          (productData?.product.statusCollections
            .items as ItemStatusCollection[]) || []
        }
        productName={productData?.product.name}
        productId={productData?.product.id}
        loading={
          getProductDataLoading ||
          addProductStatusCollectionLoading ||
          editProductStatusCollectionLoading ||
          changeProductStatusCollectionStatusLoading
        }
        onCollectionStatusChange={changeProductStatusCollectionStatus}
        onCollectionAdd={addProductStatusCollection}
        onCollectionUpdate={editProductStatusCollection}
      />
    </Box>
  );
};
