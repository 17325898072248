import {
  AddDailyDiaryGeneralRecordMutation,
  AddDailyDiaryGeneralRecordMutationVariables,
  DailyDiaryItem,
  EditDailyDiaryGeneralRecordMutation,
  EditDailyDiaryGeneralRecordMutationVariables,
  RemoveDailyDiaryGeneralRecordMutation,
  RemoveDailyDiaryGeneralRecordMutationVariables,
} from "generated/graphql";
import { addDailyDiaryGeneralRecordMutation } from "graphql/mutations/addDailyDiaryGeneralRecord";
import { editDailyDiaryGeneralRecordMutation } from "graphql/mutations/editDailyDiaryGeneralRecord";
import { removeDailyDiaryGeneralRecordMutation } from "graphql/mutations/removeDailyDiaryGeneralRecord";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { useGeneralRecords } from "./useGeneralRecords";

export const useGeneralSection = (
  dailyDiary?: DailyDiaryItem,
  revisionId?: string
) => {
  const { t } = useTranslation();

  const { refetchDDByDate } = useContext(DailyDiaryContext);

  const { generalRecords, generalRecordsLoading, refetchDDGeneralRecords } =
    useGeneralRecords(dailyDiary, revisionId);

  const [addDDGeneralRecord, { loading: addDDGeneralRecordLoading }] =
    useGraphMutation<
      AddDailyDiaryGeneralRecordMutation,
      AddDailyDiaryGeneralRecordMutationVariables
    >(
      addDailyDiaryGeneralRecordMutation,
      {
        update: async () => {
          if (!dailyDiary) {
            await refetchDDByDate?.();
          } else {
            refetchDDGeneralRecords();
          }
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.DailyDiaries.generalRecord"),
      })
    );

  const [editDDGeneralRecord, { loading: editDDGeneralRecordLoading }] =
    useGraphMutation<
      EditDailyDiaryGeneralRecordMutation,
      EditDailyDiaryGeneralRecordMutationVariables
    >(
      editDailyDiaryGeneralRecordMutation,
      {
        update: () => {
          refetchDDGeneralRecords();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.DailyDiaries.generalRecord"),
      })
    );

  const [removeDDGeneralRecord, { loading: removeDDGeneralRecordLoading }] =
    useGraphMutation<
      RemoveDailyDiaryGeneralRecordMutation,
      RemoveDailyDiaryGeneralRecordMutationVariables
    >(
      removeDailyDiaryGeneralRecordMutation,
      {
        update: () => {
          refetchDDGeneralRecords();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.DailyDiaries.generalRecord"),
      })
    );

  return {
    generalRecords,
    loading:
      generalRecordsLoading ||
      addDDGeneralRecordLoading ||
      editDDGeneralRecordLoading ||
      removeDDGeneralRecordLoading,
    addGeneralRecord: addDDGeneralRecord,
    editGeneralRecord: editDDGeneralRecord,
    removeGeneralRecord: removeDDGeneralRecord,
    refetchDDGeneralRecords,
  };
};
