import {
  DraftEventItemsQuery,
  DraftEventItemsQueryVariables,
  EventItemsQuery,
  EventItemsQueryVariables,
} from "generated/graphql";
import { draftEventItemsQuery } from "graphql/queries/draftEventItems.query";
import { eventItemsQuery } from "graphql/queries/eventItems.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";

export const useEventsRegister = () => {
  const [
    fetchEventItemsData,
    { data: eventItemsData, loading: eventItemsDataLoading },
  ] = useGraphLazyQuery<EventItemsQuery, EventItemsQueryVariables>(
    eventItemsQuery
  );

  const [
    fetchDraftEventItemsData,
    { data: draftEventItemsData, loading: draftEventItemsDataLoading },
  ] = useGraphLazyQuery<DraftEventItemsQuery, DraftEventItemsQueryVariables>(
    draftEventItemsQuery
  );

  return {
    eventItemsData,
    draftEventItemsData,
    eventItemsDataLoading,
    draftEventItemsDataLoading,
    eventsLoading: eventItemsDataLoading || draftEventItemsDataLoading,
    fetchEventItemsData,
    fetchDraftEventItemsData,
  };
};
