import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventConfirmationMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventConfirmation(
    $input: RecordCompEventConfirmationInput!
  ) {
    recordCompEventConfirmation(input: $input) {
      ...CompEventItemFields
    }
  }
`;
