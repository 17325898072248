import { gql } from "@apollo/client";
import { disabledClaimTypeOverrideFields } from "graphql/fragments/disabledClaimTypeOverride.fragment";

export const disableClaimTypeMutation = gql`
  ${disabledClaimTypeOverrideFields}

  mutation disableClaimType($claimTypeId: ID!, $contractId: ID!) {
    disableClaimType(claimTypeId: $claimTypeId, contractId: $contractId) {
      ...DisabledClaimTypeOverrideFields
    }
  }
`;
