import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";
import { draftDetailedClaimFields } from "graphql/fragments/draftIterimDetailedClaim.fragment";

export const claimInterimClaimDetailsPromptQuery = gql`
  ${draftDetailedClaimFields}
  query claimInterimClaimDetailsPrompt(
    $input: ClaimInterimClaimDetailsPromptInput!
  ) {
    claimInterimClaimDetailsPrompt(input: $input) {
      draftDetailedClaims {
        ...DraftDetailedClaimFields
      }
    }
  }
`;

export const sendClaimInterimClaimDetailsMutation = gql`
  mutation sendClaimInterimClaimDetails(
    $input: SendClaimInterimClaimDetailsInput!
  ) {
    sendClaimInterimClaimDetails(input: $input) {
      id
    }
  }
`;

export const recordClaimInterimClaimDetailsMutation = gql`
  mutation recordClaimInterimClaimDetails(
    $input: RecordClaimInterimClaimDetailsInput!
  ) {
    recordClaimInterimClaimDetails(input: $input) {
      id
    }
  }
`;

export const generateSendClaimInterimClaimDetailsPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimInterimClaimDetailsPreview(
    $input: SendClaimInterimClaimDetailsPreviewInput!
  ) {
    generateSendClaimInterimClaimDetailsPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimInterimClaimDetailsPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimInterimClaimDetailsPreview(
    $input: RecordClaimInterimClaimDetailsPreviewInput!
  ) {
    generateRecordClaimInterimClaimDetailsPreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
