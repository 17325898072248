import {
  DailyDiaryItem,
  DailyDiaryHseRecordsQuery,
  DailyDiaryHseRecordsQueryVariables,
  DailyDiaryHseRecord,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { dailyDiaryHSERecordsQuery } from "./HSESection.query";

export const useHSERecords = (
  dailyDiary?: DailyDiaryItem, // TODO: pass only dailyDiaryId - no need for the entire object
  revisionId?: string
) => {
  const {
    data: hseRecords,
    loading: hseRecordsLoading,
    refetch: refetchDDHseRecords,
  } = useGraphQuery<
    DailyDiaryHseRecordsQuery,
    DailyDiaryHseRecordsQueryVariables
  >(dailyDiaryHSERecordsQuery, {
    variables: { dailyDiaryId: dailyDiary?.id!, revisionId },
    skip: !dailyDiary,
    notifyOnNetworkStatusChange: true,
  });

  return {
    hseRecords: (hseRecords?.dailyDiaryHSERecords ??
      []) as DailyDiaryHseRecord[],
    hseRecordsLoading,
    refetchDDHseRecords,
  };
};
