import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField, Typography } from "@mui/material";
import {
  defaultCognitoPasswordRequirementsRegex,
  FIELD_VALIDATION_ERRORS,
} from "constants/constants";
import { useState } from "react";
import { isValidConfirmPassword, isValidPassword } from "helpers/validators";
import { PasswordEyeAdornment } from "components/miscellaneous/PasswordEyeAdornment";
import { useTranslation } from "react-i18next";
import { AuthPageHeader } from "containers/ResetPassword/components/AuthPageHeader";
import { PasswordFieldLabel } from "components/PasswordFieldLabel";

export type SetNewPasswordFormProps = {
  loading?: boolean;
  onSetNewPassword: (newPassword: string) => void;
};

export const SetNewPasswordForm: React.FC<SetNewPasswordFormProps> = ({
  loading,
  onSetNewPassword,
}) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === "Enter") {
      handleChangePassword();
    }
  };

  const validateForm = () => {
    // TODO ADI B. maybe trim credentials?
    if (!password) {
      setPasswordErrors(FIELD_VALIDATION_ERRORS.RequiredField);
      return false;
    } else if (!isValidPassword(password)) {
      setPasswordErrors(FIELD_VALIDATION_ERRORS.InvalidField);
      return false;
    }
    if (!confirmPassword) {
      setConfirmPasswordErrors(FIELD_VALIDATION_ERRORS.RequiredField);
      return false;
    } else if (!isValidConfirmPassword(password, confirmPassword).valid) {
      setConfirmPasswordErrors(FIELD_VALIDATION_ERRORS.PasswordsNotMatching);
      return false;
    }

    return true;
  };

  const handleChangePassword = () => {
    if (validateForm()) {
      onSetNewPassword(password);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((state) => !state);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((state) => !state);
  };

  const handlePasswordChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setPassword(evt.target.value);
    setPasswordErrors("");
  };

  const handleConfirmPasswordChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setConfirmPassword(evt.target.value);
    setConfirmPasswordErrors("");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      maxWidth="400px"
    >
      <Box mb={7}>
        <AuthPageHeader
          title={t("ResetPassword.resetYourPassword")}
          subtitle={t("SetNewPassword.subtitle")}
        />
      </Box>
      <form>
        <Grid container rowGap={4}>
          <Grid item xs={12}>
            <>
              <PasswordFieldLabel
                label={t("common.labels.newPassword")}
                error={!!passwordErrors}
              />
              <Box mt={1}>
                <TextField
                  fullWidth
                  onChange={handlePasswordChange}
                  value={password}
                  onKeyDown={handleKeyDown}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  variant="outlined"
                  size="medium"
                  error={!!passwordErrors}
                  helperText={passwordErrors}
                  required
                  autoComplete="new-password"
                  inputProps={{
                    pattern: defaultCognitoPasswordRequirementsRegex,
                    autoComplete: "new-password",
                  }}
                  InputProps={{
                    autoComplete: "new-password",
                    endAdornment: (
                      <PasswordEyeAdornment
                        visibility={showPassword}
                        onToggleVisibility={togglePasswordVisibility}
                        mb={0}
                      />
                    ),
                  }}
                />
              </Box>
            </>
          </Grid>
          <Grid item xs={12}>
            <>
              <PasswordFieldLabel
                label={t("ResetPassword.confirmPassword")}
                error={!!passwordErrors}
              />
              <Box mt={1}>
                <TextField
                  fullWidth
                  onChange={handleConfirmPasswordChange}
                  value={confirmPassword}
                  onKeyDown={handleKeyDown}
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  variant="outlined"
                  size="medium"
                  error={!!confirmPasswordErrors}
                  helperText={confirmPasswordErrors}
                  required
                  autoComplete="new-password"
                  inputProps={{
                    pattern: defaultCognitoPasswordRequirementsRegex,
                    autoComplete: "new-password",
                  }}
                  InputProps={{
                    autoComplete: "new-password",
                    endAdornment: (
                      <PasswordEyeAdornment
                        visibility={showConfirmPassword}
                        onToggleVisibility={toggleConfirmPasswordVisibility}
                        mb={0}
                      />
                    ),
                  }}
                />
              </Box>
            </>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              onClick={handleChangePassword}
              loading={loading}
              variant="contained"
              fullWidth
              style={{ textTransform: "none" }}
              size="large"
            >
              <Typography color="white" variant="p2" fontWeight={700}>
                {t("ResetPassword.resetPassword")}
              </Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
