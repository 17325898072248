import { DailyDiaryItem, DailyDiaryItemStatus } from "generated/graphql";
import { useContext } from "react";
import { DailyDiaryContext } from "../DailyDiaryContextProvider";

export const useDailyDiaryStatus = () => {
  const { dailyDiary, emptyDailyDiary } = useContext(DailyDiaryContext);
  const isEmptyDailyDiary =
    emptyDailyDiary?.__typename === "EmptyDailyDiaryItem";
  const isDraftDailyDiary =
    (dailyDiary as DailyDiaryItem)?.status === DailyDiaryItemStatus.Draft;
  const isDraftOrEmptyDailyDiary = isEmptyDailyDiary || isDraftDailyDiary;

  return {
    isEmptyDailyDiary,
    isDraftDailyDiary,
    isDraftOrEmptyDailyDiary,
    isLockedDraft: dailyDiary?.status === DailyDiaryItemStatus.LockedDraft,
  };
};
