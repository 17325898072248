import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";
import { eventItemFields } from "graphql/fragments/eventItem.fragment";

export const draftEventItemQuery = gql`
  ${eventItemFields}
  ${attachmentFields}

  query draftEventItem($id: ID!) {
    draftEventItem(id: $id) {
      ...EventItemFields
      attachments {
        ...AttachmentFields
      }
    }
  }
`;
