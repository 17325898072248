import {
  CompEventHistoryItem,
  CompEventHistoryItemsQuery,
  CompEventHistoryItemsQueryVariables,
} from "generated/graphql";
import { compEventHistoryItemsQuery } from "graphql/queries/compEventHistoryItems.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";

export const useCompEventHistory = (compEventId: string) => {
  const { data: compEventHistoryItems, loading: compEventHistoryItemsLoading } =
    useGraphQuery<
      CompEventHistoryItemsQuery,
      CompEventHistoryItemsQueryVariables
    >(compEventHistoryItemsQuery, {
      variables: { compEventId },
    });

  const orderedHistoryItems = useMemo(
    () =>
      (compEventHistoryItems?.compEventHistoryItems.items ?? [])
        .slice()
        .sort(
          (item1, item2) =>
            new Date(item2.dateSent).getTime() -
            new Date(item1.dateSent).getTime()
        ) as CompEventHistoryItem[],
    [compEventHistoryItems]
  );

  return {
    compEventHistoryItems: orderedHistoryItems,
    loading: compEventHistoryItemsLoading,
  };
};
