import { Box, Skeleton } from "@mui/material";
import { HeaderBackButton } from "containers/Projects/components/Header/HeaderBackButton";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import { DailyDiaryItemMixed } from "generated/graphql";
import { DailyDiaryUserRole } from "hooks/useDailyDiaryUserBERole";
import { DailyDiaryNavigator } from "../DailyDiaryNavigator";
import { DDHeaderActionButtons } from "./DDHeaderActionButtons";

type DailyDiaryHeaderProps = {
  dailyDiary?: DailyDiaryItemMixed;
  dailyDiaryUserRoles?: DailyDiaryUserRole[];
  noActionButtons?: boolean;
  onBack?: () => void;
  onNextClick: () => void;
  onPreviousClick: () => void;
} & Omit<
  React.ComponentProps<typeof DDHeaderActionButtons>,
  "dailyDiaryUserRole" | "dailyDiary"
>;

export enum DailyDiaryHeaderAction {
  Send = "common.buttons.send",
  LockAndEdit = "Projects.DailyDiaries.lockAndEdit",
  Waive = "Projects.DailyDiaries.waive",
}

export const DailyDiaryHeader: React.FC<DailyDiaryHeaderProps> = ({
  dailyDiary,
  loading,
  dailyDiaryUserRoles,
  noActionButtons,
  onBack,
  onNextClick,
  onPreviousClick,
  ...restActionsProps
}) => {
  return (
    <ProductItemHeaderContainer>
      {/* NOTE: isHeaderStuck seems to not be necessary anymore? Not sure why... */}
      {/* {(isHeaderStuck?: boolean) => ( */}
      {() => (
        <>
          <HeaderBackButton onBack={onBack} disabled={loading} />
          {loading || !dailyDiary ? (
            <Box ml={2}>
              <Skeleton variant="rectangular" animation="pulse" width={500} />
            </Box>
          ) : (
            <DailyDiaryNavigator
              date={dailyDiary.date}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              ml={4}
            />
          )}
          {dailyDiaryUserRoles && dailyDiary && !noActionButtons && (
            // mr={isHeaderStuck ? 3 : 0}
            <Box ml="auto">
              <DDHeaderActionButtons
                loading={loading}
                dailyDiary={dailyDiary}
                dailyDiaryUserRoles={dailyDiaryUserRoles}
                {...restActionsProps}
              />
            </Box>
          )}
        </>
      )}
    </ProductItemHeaderContainer>
  );
};
