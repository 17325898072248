import { Popover, Typography } from "@mui/material";
import { KeyboardArrowDownIcon } from "components/Icons/KeyboardArrowDownIcon";
import { KeyboardArrowUpIcon } from "components/Icons/KeyboardArrowUpIcon";
import { User } from "generated/graphql";
import { MouseEventHandler, useState } from "react";
import { OwnerFlyoutTrigger } from "./OwnerSelect.styled";
import { OwnerSelectFlyout } from "./OwnerSelectFlyout";
import { getUserName } from "helpers/miscelaneous";

export type OwnerSelectProps = {
  ownerCandidates: User[];
  owner: User;
  flyoutPlacement?: "left" | "right";
  loading?: boolean;
  readOnly?: boolean;
  onSelectionChange: (newUser: User) => void;
};

export const OwnerSelect: React.FC<OwnerSelectProps> = ({
  ownerCandidates,
  owner,
  flyoutPlacement = "right",
  loading,
  readOnly,
  onSelectionChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event?: Event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleSelectionChange = (selectedUser: User) => {
    handleClose();
    if (selectedUser.id !== owner.id) {
      onSelectionChange(selectedUser);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "explorer-popover" : undefined;

  return (
    <OwnerFlyoutTrigger
      px={1.5}
      mr={-1.5}
      sx={{ width: "100%" }}
      aria-describedby={id}
      onClick={readOnly ? undefined : handleClick}
      active={open}
      readOnly={readOnly}
    >
      <Typography
        variant="p1"
        textOverflow="ellipsis"
        width="100%"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {getUserName(owner)}
      </Typography>
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(event: Event) => handleClose(event)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: flyoutPlacement,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: flyoutPlacement,
        }}
      >
        <OwnerSelectFlyout
          ownerCandidates={ownerCandidates}
          currentOwner={owner}
          loading={loading}
          onListItemClick={handleSelectionChange}
        />
      </Popover>
    </OwnerFlyoutTrigger>
  );
};
