import {
  ApolloError,
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultErrorHandlingOptions,
  ErrorHandlingOptions,
} from "./useGraphMutation";

const asleepDBPartialErrorMsg =
  "error occurred while establishing a connection to SQL Server";

const noInternetConnection = "Failed to fetch";

/**
 * Custom hook based on useQuery, using snackbar to display errors.
 * @param query - The query to execute.
 * @param options - Option that apply to the query.
 * @returns An object holding the error retrieved and other fields returned by useGraphQuery.
 */
export function useGraphQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
  errorHandlingOption: ErrorHandlingOptions = defaultErrorHandlingOptions
): QueryResult<TData, TVariables> {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { error, data, ...rest } = useQuery(query, {
    ...options,
  });

  /**
   * Usually the BE error messages are too unfriendly for the user to see them. So in general
   * we're returning a generic error message, however if we're encountering the DB connection
   * issue, we're returning a nicer DB related message. To be improved in the future once with
   * the more precise error handling.
   * @param error
   * @returns a string for the translation function or the error message itself
   */
  const computeErrorMessage = useCallback(
    (error: ApolloError) => {
      if (error?.message) {
        if (error.message.indexOf(asleepDBPartialErrorMsg) >= 0) {
          return "common.errorMessages.cannotConnectToDB";
        } else if (error.message.indexOf(noInternetConnection) >= 0) {
          return "common.errorMessages.noInternetConnection";
        }
      }
      return errorHandlingOption.errorMessage ?? error?.message;
    },
    [errorHandlingOption.errorMessage]
  );

  useEffect(() => {
    if (error && errorHandlingOption.type === "snackbar") {
      enqueueSnackbar(t(computeErrorMessage(error)), {
        preventDuplicate: true,
      });
    }
  }, [error, errorHandlingOption, enqueueSnackbar, t, computeErrorMessage]);

  return {
    error,
    data,
    ...rest,
  };
}
