import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ListItemsDivider } from "components/ListItemsDivider";
import { FormPublicApi } from "types/decl";
import { AttachmentInput, AttachmentStatus } from "generated/graphql";
import { validateData } from "helpers/validators";
import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Attachments } from "../../../Attachments/Attachments";
import { useAttachments } from "../../../Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "../../../Attachments/hooks/useImagePreviewModal";
import { FormLabel } from "../../../../../../components/FormLabel";
import { PhotoAttachmentPreviewModal } from "../../../PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { RichTextArea } from "../../../../../../components/RichTextArea/RichTextArea";
import {
  dataValidators,
  getDefaultData,
} from "./VariationProposalAgreementDeterminationForm.constants";
import { VariationProposalAgreementDeterminationFormProps } from "./VariationProposalAgreementDeterminationForm.decl";
import { draftVODataToEditVODataInput } from "./VariationProposalAgreementDeterminationForm.utils";
import { TimeField, TimeFieldActionType } from "../../../ActionModal/TimeField";
import { CurrencyTextField } from "components/CurrencyTextField";
import { AttachmentsLayout } from "../../../Attachments/components/AttachmentsHeader/AttachmentsHeader";
import { ImpactedDateField } from "containers/Projects/components/ActionModal/ImpactedDateField";
import {
  ModalType,
  VariationProposalAgreementDeterminationFormDataType,
} from "../VariationProposalAgreementDeterminationModal";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export type VariationProposalAgreementDeterminationFormPublicAPI =
  FormPublicApi & {
    onBeforeAbort: () => void;
  };

// TODO: 90% the same as DetailedClaim form. Extract common parts in other cmps & common hooks and reuse here
export const VariationProposalAgreementDeterminationForm: React.FC<
  VariationProposalAgreementDeterminationFormProps
> = ({
  contractCurrency,
  contractTimezone,
  sections,
  draftProposal,
  draftAgreement,
  draftDetermination,
  apiRef,
  type,
  isFIDIC17White,
  onAttachmentsLoadingChange,
  onChange,
  onAttachmentsChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { variation } = useContext(VariationWidgetContext);

  const [formData, setFormData] =
    useState<VariationProposalAgreementDeterminationFormDataType>(
      (type === ModalType.DraftVOProposal
        ? draftVODataToEditVODataInput(draftProposal)
        : type === ModalType.DraftVOAgreement
        ? draftVODataToEditVODataInput(draftAgreement)
        : draftVODataToEditVODataInput(draftDetermination)) ??
        getDefaultData(variation!.id, sections)
    );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  // Maybe take it from outside?
  const isEditMode = useMemo(
    () =>
      !!draftProposal?.id || !!draftAgreement?.id || !!draftDetermination?.id,
    [draftProposal, draftAgreement, draftDetermination]
  );

  const computedAttachments = useMemo(
    () =>
      draftProposal?.attachments ??
      draftAgreement?.attachments ??
      draftDetermination?.attachments ??
      [],
    [draftProposal, draftAgreement, draftDetermination]
  );

  const handleDetailsChange = (htmlContent: string) => {
    setFormData((curData) => ({
      ...curData,
      details: htmlContent,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { details: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((crtData) => ({
      ...crtData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleTimeDecrement = () => {
    setFormData((crtData) => ({
      ...crtData,
      time: crtData.time - 1,
    }));
  };

  const handleTimeIncrement = () => {
    setFormData((crtData) => ({
      ...crtData,
      time: crtData.time + 1,
    }));
  };

  const handleImpactedDateArrayChange = (
    id: string,
    timeActionType: TimeFieldActionType
  ) => {
    const currentImpactedDateArrayIds = formData.sectionalChanges.map(
      (impactedDateChange) => impactedDateChange?.id
    );

    if (currentImpactedDateArrayIds.indexOf(id) >= 0) {
      // date impact already exists. Updating it...
      setFormData((crtFormData) => {
        return {
          ...crtFormData,
          sectionalChanges: crtFormData.sectionalChanges.map((crtDateChange) =>
            crtDateChange?.id === id
              ? {
                  ...crtDateChange,
                  days:
                    timeActionType === TimeFieldActionType.Increment
                      ? crtDateChange.days + 1
                      : crtDateChange.days - 1,
                }
              : crtDateChange
          ),
        };
      });
    } else {
      setFormData((crtFormData) => ({
        ...crtFormData,
        sectionalChanges: [
          ...crtFormData.sectionalChanges,
          {
            id,
            days: timeActionType === TimeFieldActionType.Increment ? 1 : -1,
          },
        ],
      }));
    }
  };

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    setFormData((crtFormData) => ({
      ...crtFormData,
      attachments: attachmentsUpdated,
    }));

    onAttachmentsChange?.({
      ...formData,
      attachments: attachmentsUpdated,
    });
  };

  const validateForm = useCallback(
    (formData: VariationProposalAgreementDeterminationFormDataType) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);
      return false;
    },
    []
  );

  const reset = useCallback(() => {
    setFormData(
      (type === ModalType.DraftVOProposal
        ? draftVODataToEditVODataInput(draftProposal)
        : type === ModalType.DraftVOAgreement
        ? draftVODataToEditVODataInput(draftAgreement)
        : draftVODataToEditVODataInput(draftDetermination)) ??
        getDefaultData(variation!.id, sections)
    );
  }, [
    draftProposal,
    draftAgreement,
    draftDetermination,
    sections,
    type,
    variation,
  ]);

  const {
    allAttachments,
    attachmentsLoading,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
    unloadLocalAttachments,
  } = useAttachments(
    computedAttachments.filter(
      (attach) => attach.status === AttachmentStatus.Active
    ),
    handleAttachmentsUpdated
  );

  const handleBeforeAbort = useCallback(() => {
    if (!isEditMode) {
      // quotation/assessment not submitted. Remove all allegedly added attachments from the server
      unloadLocalAttachments();
    }
  }, [isEditMode, unloadLocalAttachments]);

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  useImperativeHandle(
    apiRef,
    () => ({
      reset,
      validate: () => validateForm(formData),
      onBeforeAbort: handleBeforeAbort,
    }),
    [validateForm, formData, reset, handleBeforeAbort]
  );

  useEffect(() => {
    setFormData(
      (type === ModalType.DraftVOProposal
        ? draftVODataToEditVODataInput(draftProposal)
        : type === ModalType.DraftVOAgreement
        ? draftVODataToEditVODataInput(draftAgreement)
        : draftVODataToEditVODataInput(draftDetermination)) ??
        getDefaultData(variation!.id, sections)
    );
  }, [
    draftProposal,
    draftAgreement,
    draftDetermination,
    sections,
    type,
    variation,
  ]);

  useEffect(() => {
    onAttachmentsLoadingChange?.(attachmentsLoading);
  }, [attachmentsLoading, onAttachmentsLoadingChange]);

  useEffect(() => {
    onChange?.(formData);
  }, [formData, onChange]);

  return (
    <>
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        contractTimezone={contractTimezone}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormLabel
            label={t(
              `Projects.Variations.VariationProposalModal.${
                type === ModalType.DraftVOProposal
                  ? "detailsOfProposal"
                  : type === ModalType.DraftVOAgreement
                  ? "detailsOfAgreement"
                  : "detailsOfDetermination"
              }`
            )}
            required
          />
          <RichTextArea
            content={formData.details}
            error={!!formDataErrors.details}
            onChange={handleDetailsChange}
          />
          {!!formDataErrors.details && (
            <Typography variant="caption" color="error" mt={0.5}>
              {formDataErrors.details}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} display="flex" alignItems="flex-start">
          <Box display="flex" flexDirection="column" width="240px">
            <FormLabel
              label={t(
                "Projects.Variations.VariationProposalModal.additionalPayment"
              )}
              required
            />
            <CurrencyTextField
              name="price"
              value={formData.price}
              onChange={handleTextFieldChange}
              size="small"
              error={!!formDataErrors.price}
              helperText={formDataErrors.price}
              currency={contractCurrency ?? ""}
              required
            />
          </Box>
          <Box display="flex" flexDirection="column" ml={3} width="240px">
            <FormLabel
              label={t(
                `Projects.Variations.VariationProposalModal.${
                  isFIDIC17White
                    ? "timeCompletionDateFIDIC17White"
                    : "timeCompletionDate"
                }`
              )}
              required
            />
            <TimeField
              number={formData.time}
              onDecrement={handleTimeDecrement}
              onIncrement={handleTimeIncrement}
            />
          </Box>
        </Grid>
        {sections && sections.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <FormLabel
                label={t(
                  "Projects.Variations.VariationProposalModal.sectionalCompletionDates"
                )}
                required
              />
              {sections.map((section) => (
                <React.Fragment key={section.id}>
                  <ImpactedDateField
                    section={section}
                    timeImpact={
                      formData.sectionalChanges.find(
                        (sectionImpact) =>
                          sectionImpact?.id === section.id ||
                          sectionImpact?.id === section.number
                      ) ?? undefined
                    }
                    onIncrement={() =>
                      handleImpactedDateArrayChange(
                        section.id,
                        TimeFieldActionType.Increment
                      )
                    }
                    onDecrement={() =>
                      handleImpactedDateArrayChange(
                        section.id,
                        TimeFieldActionType.Decrement
                      )
                    }
                  />
                  <ListItemsDivider sx={{ sy: theme.spacing(1.5) }} />
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Attachments
            editMode
            showTabs={false}
            timezone={contractTimezone}
            defaultLayout={AttachmentsLayout.List}
            attachments={allAttachments}
            onAttachmentsAdd={addAttachments}
            onAttachmentRemove={removeAttachment}
            onAttachmentUpdate={updateAttachment}
            onAttachmentClick={handleAttachmentClick}
          />
        </Grid>
      </Grid>
    </>
  );
};
