import {
  provideQuotationEnumValue,
  putIntoEffectEnumValue,
} from "constants/constants";
import { useTranslation } from "react-i18next";
import { EnumField } from "./EnumField";
import { useMemo } from "react";

export type InstructionTypeFieldProps = {
  options: string[];
  fieldDisplayText?: string;
  editMode?: boolean;
  fieldValue?: string;
  fieldError?: string;
  fieldTouched?: boolean;
  isNECContractType?: boolean;
  onChange: (selectedOption: string) => void;
};

export const InstructionTypeField: React.FC<InstructionTypeFieldProps> = ({
  options: rawOptions,
  fieldDisplayText,
  editMode,
  fieldValue,
  fieldError,
  fieldTouched,
  isNECContractType = true,
  onChange,
}) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return rawOptions.map((op) => ({
      value: op,
      label: t(
        `Projects.Instructions.${
          isNECContractType ? "NECType" : "FIDICType"
        }.${op}`
      ),
    }));
  }, [rawOptions, isNECContractType, t]);

  const computedOptions = options ?? [
    {
      value: putIntoEffectEnumValue,
      label: t(
        `Projects.Instructions.${
          isNECContractType ? "NECType" : "FIDICType"
        }.${putIntoEffectEnumValue}`
      ),
    },
    {
      value: provideQuotationEnumValue,
      label: t(
        `Projects.Instructions.${
          isNECContractType ? "NECType" : "FIDICType"
        }.${provideQuotationEnumValue}`
      ),
    },
  ];

  return (
    <EnumField
      editMode={editMode}
      options={computedOptions}
      fieldTouched={fieldTouched}
      fieldError={fieldError}
      fieldValue={computedOptions.find((option) => option.value === fieldValue)}
      fieldDisplayText={fieldDisplayText}
      onChange={onChange}
    />
  );
};
