import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { LookupOptionStatus, SchemaField } from "generated/graphql";
import { useLookupCollection } from "hooks/useLookupCollection";
import { useMemo } from "react";

export type SelectOption = {
  id: string;
  label: string;
};

export type LookupFieldProps = {
  field: SchemaField;
  fieldValue?: string;
  fieldError?: string;
  fieldTouched?: boolean;
  readOnly?: boolean;
  onChange: (selectedOptionId: string) => void;
};

export const LookupField: React.FC<LookupFieldProps> = ({
  field,
  fieldValue,
  fieldError,
  fieldTouched,
  readOnly = false,
  onChange,
}) => {
  const theme = useTheme();
  const { lookupCollection, lookupCollectionLoading } = useLookupCollection(
    field.complexTypeItemId!
  );
  const deserialisedFieldValue: {
    lookupCollectionId: string;
    lookupOptionId: string;
  } = useMemo(
    () =>
      fieldValue
        ? JSON.parse(fieldValue)
        : { lookupCollectionId: undefined, lookupOptionId: undefined },
    [fieldValue]
  );

  const options = useMemo(() => {
    return (
      lookupCollection?.lookupCollection.options.items
        .filter((option) => option.status === LookupOptionStatus.Active)
        .sort((option1, option2) => option1.displayOrder - option2.displayOrder)
        .map((option) => ({
          id: option.id,
          label: option.value,
        })) ?? []
    );
  }, [lookupCollection]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(
      JSON.stringify({
        lookupCollectionId: field.complexTypeItemId!,
        lookupOptionId: event.target.value,
      })
    );
  };

  const selectedOption = options.find(
    (option) => option.id === deserialisedFieldValue.lookupOptionId
  );

  return readOnly ? (
    <Typography
      variant="p1"
      color={theme.palette.grey[800]}
      data-testid={`${field.name}-lkp-readonly`}
    >
      {selectedOption?.label}
    </Typography>
  ) : (
    <Box display="flex" flexDirection="column" data-testid="lookup-container">
      <Select
        id="lookup-field"
        data-testid={`${field.displayText}-select`}
        value={deserialisedFieldValue.lookupOptionId ?? ""}
        onChange={handleChange}
        error={!!fieldError && fieldTouched}
        disabled={lookupCollectionLoading || readOnly}
        required={field.isRequired}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            data-testid={`${option.label}-option`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {fieldError && fieldTouched && (
        <Typography variant="caption" color="error" mt={0.5}>
          {fieldError}
        </Typography>
      )}
    </Box>
  );
};
