import { useCallback, useState } from "react";

export const useBasicModal = () => {
  const [modalVisibility, setModalVisibility] = useState(false);

  const toggleModalVisibility = useCallback(() => {
    setModalVisibility((state) => !state);
  }, []);

  return {
    modalVisibility,
    toggleModalVisibility,
    setModalVisibility,
  };
};
