import { Box } from "@mui/material";
import { Editor } from "containers/Projects/components/Comments/Editor/Editor";

export type AdvancedRichTextAreaProps = {
  readOnly?: boolean;
  content?: string;
  error?: boolean;
  dataTestId?: string;
  onChange: (contentHTML: string) => void;
};

export const AdvancedRichTextArea: React.FC<AdvancedRichTextAreaProps> = ({
  readOnly,
  content = "",
  dataTestId = "advanced-rich-textarea",
  error,
  onChange,
}) => {
  return readOnly ? (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      data-testid={`${dataTestId}-readonly`}
    ></div>
  ) : (
    <Box display="flex">
      <Editor
        disabled={false}
        typeaheadOptions={[]}
        initialValue={content}
        hasError={error}
        placeholder=""
        dataTestId={dataTestId}
        withTable
        onChange={onChange}
      />
    </Box>
  );
};
