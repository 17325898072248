import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import {
  ContractBindingType,
  ContractBindingTypeStatus,
} from "generated/graphql";
import { statusComparatorFunction } from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { noop } from "helpers/miscelaneous";

export const useColumns = (
  companyStatusOptions: StatusOption<ContractBindingTypeStatus>[]
): GridColDef<ContractBindingType>[] => {
  const { t } = useTranslation();
  const columns = useMemo(
    () =>
      [
        {
          field: "description",
          headerName: t("common.labels.description"),
          flex: 0.45,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, ContractBindingType, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={companyStatusOptions}
                disablePortal={false}
                disabled
                onChange={noop}
              />
            );
          },
        },
      ] as GridColDef<ContractBindingType>[],
    [t, companyStatusOptions]
  );
  return columns;
};
