import { Box, Collapse } from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList } from "components/DetailsList";
import { ContractsIcon } from "components/Icons/ContractsIcon";
import { PageContentContainer } from "components/PageContentContainer";
import {
  ContractType,
  ContractTypeQuery,
  ContractTypeQueryVariables,
} from "generated/graphql";
import { contractTypeQuery } from "graphql/queries/contractType.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ContractTypeBindingTypes } from "./ContractTypeBindingTypes/ContractTypeBindingTypes";
import { ContractTypeDetailsHeader } from "./ContractTypeDetailsHeader";
import { ContractTypeTimePeriods } from "./ContractTypeTimePeriods/ContractTypeTimePeriods";
import { useContractTypeTimePeriods } from "./ContractTypeTimePeriods/useContractTypeTimePeriods";

export const ContractTypeDetails = () => {
  const { t } = useTranslation();
  const { contractTypeId } = useParams();
  const [showDetails, setShowDetails] = useState(true);
  const [contractTypeDetails, setContractTypeDetails] =
    useState<ContractType>();

  const { timePeriods, loading: timePeriodsLoading } =
    useContractTypeTimePeriods(contractTypeId!);

  const { data: contractTypeData, loading: getContractTypeLoading } =
    useGraphQuery<ContractTypeQuery, ContractTypeQueryVariables>(
      contractTypeQuery,
      { variables: { id: contractTypeId! } }
    );

  useEffect(() => {
    if (contractTypeData?.contractType) {
      setContractTypeDetails(contractTypeData?.contractType as ContractType);
    }
  }, [contractTypeData?.contractType]);

  const contractTypeEntityObj = useMemo(() => {
    if (!contractTypeData) {
      return [];
    }

    return [
      {
        id: "description",
        label: t("AdminConsole.ContractTypes.labels.contractType"),
        value: contractTypeData.contractType.description,
      },
      {
        id: "subtype",
        label: t("AdminConsole.ContractTypes.labels.contractSubtype"),
        value: contractTypeData.contractType.subType,
      },
      {
        id: "version",
        label: t("AdminConsole.ContractTypes.labels.version"),
        value: contractTypeData.contractType.version,
      },
      {
        id: "status",
        label: t("common.labels.status"),
        value: contractTypeData.contractType.status,
      },
    ];
  }, [contractTypeData, t]);

  const contractTypeFullName = `${contractTypeDetails?.description} ${
    contractTypeDetails?.version ?? ""
  } ${contractTypeDetails?.subType ?? ""}`;

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <ContractTypeDetailsHeader generalLoading={getContractTypeLoading} />
      <CollapsibleHeader
        title={t("common.labels.details")}
        collapsed={!showDetails}
        icon={<ContractsIcon />}
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          <DetailsList
            loading={getContractTypeLoading}
            entity={contractTypeEntityObj}
          />
        </PageContentContainer>
      </Collapse>
      <ContractTypeBindingTypes
        contractTypeName={contractTypeFullName}
        bindingTypes={contractTypeDetails?.contractBindingTypes.items || []}
        loading={getContractTypeLoading}
      />
      <Box my={3}>
        <ContractTypeTimePeriods
          contractTypeName={contractTypeFullName}
          timePeriods={timePeriods}
          loading={timePeriodsLoading}
        />
      </Box>
    </Box>
  );
};
