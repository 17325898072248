import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";
import { InfoIcon } from "components/Icons/InfoIcon";
import { PageContentContainer } from "components/PageContentContainer";
import {
  InviteUserMutation,
  InviteUserMutationVariables,
  InviteUserInput,
  InviteUserRoleInput,
  CompanyProductInstancesQuery,
  CompanyProductInstancesQueryVariables,
  Project,
  ContractBindingStatus,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ProductInstanceRolesMapper } from "./ProductInstanceRolesMapper/ProductInstanceRolesMapper";
import { LocalInviteUserRoleInput } from "./ProductInstanceRolesMapper/ProjectRolesContainer";
import { inviteUserValidators } from "./InviteUser.constants";
import {
  companyProductInstancesQuery,
  inviteUserMutation,
} from "./InviteUser.query";
import { InviteUserHeader } from "./InviteUserHeader";
import { InviteUserContext } from "./InviteUser.context";

export const InviteUser = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [productInstancesRolesMapping, setProductInstancesRolesMapping] =
    useState<InviteUserRoleInput[]>([]);
  const [formData, setFormData] = useState<InviteUserInput>({
    companyId: companyId!,
    email: "",
  });
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const { data: companyData, loading: getCompanyDataLoading } = useGraphQuery<
    CompanyProductInstancesQuery,
    CompanyProductInstancesQueryVariables
  >(companyProductInstancesQuery, { variables: { id: companyId! } });

  const [doInviteUser, { loading: inviteUserLoading }] = useGraphMutation<
    InviteUserMutation,
    InviteUserMutationVariables
  >(
    inviteUserMutation,
    {
      update: (cache) => {
        cache.evict({ fieldName: "company" });
        cache.gc();
      },
    },
    t("AdminConsole.Users.InviteUser.userInvited")
  );

  const validateForm = useCallback((formData: InviteUserInput) => {
    const validationResult = validateData(formData, inviteUserValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);
    return false;
  }, []);

  const handleSendInvite = async () => {
    if (validateForm(formData)) {
      const { errors } = await doInviteUser({
        variables: {
          input: {
            email: formData.email,
            companyId: formData.companyId,
            roles: productInstancesRolesMapping,
          },
        },
      });

      if (!errors) {
        navigate(-1);
      }
    }
  };

  const handleEmailChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      email: evt.target.value,
    }));
  };

  // TODO: not needed for MVP - uncomment when necessary
  // const handleAutoRoleChange = (event: SelectChangeEvent<AutoRole>) => {
  //   setFormData((curData) => ({
  //     ...curData,
  //     autoRole: event.target.value as AutoRole,
  //   }));
  // };

  const handleProductInstancesRolesMappingChange = (
    updatedPIRoleMappings: LocalInviteUserRoleInput[]
  ) => {
    if (!updatedPIRoleMappings.length) {
      return;
    }

    setProductInstancesRolesMapping((crtState) => {
      let newPIRoleMappings = [...updatedPIRoleMappings, ...crtState];

      // removing duplicates. First ones take precendence because updatedMappings have been prepended to the array
      const key = "productInstanceId";

      newPIRoleMappings = [
        ...new Map(
          newPIRoleMappings
            .reverse()
            .map((piRoleMapping) => [piRoleMapping[key], piRoleMapping])
        ).values(),
      ];

      // removing the ones with undefined role
      newPIRoleMappings = newPIRoleMappings.filter(
        (piRole) => piRole.productRoleId
      );

      return newPIRoleMappings as InviteUserRoleInput[];
    });
  };

  const contractsWithActiveBindingsIds = useMemo(() => {
    if (companyData) {
      return companyData.company?.contractBindings.items
        .filter((binding) => binding.status === ContractBindingStatus.Active)
        .map((binding) => binding.contractId);
    }
  }, [companyData]);

  return (
    <Box pb={4}>
      <InviteUserContext.Provider value={{ contractsWithActiveBindingsIds }}>
        <InviteUserHeader
          onSendInvite={handleSendInvite}
          companyName={companyData?.company?.registeredName}
          generalLoading={getCompanyDataLoading || inviteUserLoading}
          onSendInviteLoading={inviteUserLoading}
        />
        <PageContentContainer px={0}>
          <Grid
            container
            spacing={6}
            pt={0}
            px={4}
            pb={3}
            sx={{ boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.06);" }}
          >
            <Grid item md={6} xs={12}>
              <Box display="flex" flexDirection="column">
                <TextField
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleEmailChange}
                  type="text"
                  placeholder="invite@email.com"
                  label="Email"
                  variant="standard"
                  error={!!formDataErrors.email}
                  helperText={formDataErrors.email}
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={theme.palette.action.disabled}
                  mt={1.5}
                >
                  {t("AdminConsole.Users.InviteUser.emailLabel")}
                </Typography>
              </Box>
            </Grid>
            {/* TODO: NOT NEEDED FOR MVP - uncomment when necessary */}
            {/* <Grid item md={6} xs={12}>
            <Box display="flex" flexDirection="column">
              <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
                <InputLabel
                  id="user-auto-role-select-label"
                  shrink
                  placeholder="Choose"
                >
                  {t("AdminConsole.Users.InviteUser.autoRole")}
                </InputLabel>
                <Select
                  labelId="user-auto-role-select-label"
                  id="user-auto-role-select"
                  value={formData.autoRole || ""}
                  onChange={handleAutoRoleChange}
                  label="AUTO-ROLE"
                  placeholder="Choose"
                  disabled
                >
                  {Object.entries(UserAutoRoleMapping).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                variant="body2"
                fontWeight={500}
                color={theme.palette.action.disabled}
                mt={1.5}
              >
                {t("AdminConsole.Users.InviteUser.roleLabel")}
              </Typography>
            </Box>
          </Grid> */}
          </Grid>
        </PageContentContainer>
        <Box maxWidth="1300px">
          <Box
            display="flex"
            alignItems="center"
            sx={{ boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.06);" }}
            px={4}
            py={2}
          >
            <Typography variant="h5" fontSize="18px" fontWeight={500}>
              {t("common.labels.roles")}
            </Typography>
            <Box ml={1} display="flex">
              <InfoIcon />
            </Box>
          </Box>
          <ProductInstanceRolesMapper
            projects={(companyData?.company?.projects.items ?? []) as Project[]}
            productInstanceRoles={productInstancesRolesMapping}
            onChange={handleProductInstancesRolesMappingChange}
          />
        </Box>
      </InviteUserContext.Provider>
    </Box>
  );
};
