import { Box, Button, Typography } from "@mui/material";
import React from "react";

export type UploadButtonProps = {
  caption: React.ReactNode;
  hintCaption?: React.ReactNode;
  onPhotoUpload: (photo: string) => void;
};

export const UploadButton: React.FC<UploadButtonProps> = ({
  caption,
  hintCaption,
  onPhotoUpload,
  ...otherProps
}) => {
  const handleUpload: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target?.files?.length) {
      const FR = new FileReader();

      FR.addEventListener("load", function (evt) {
        onPhotoUpload(evt.target!.result! as string);
        event.target.value = "";
      });

      FR.readAsDataURL(event.target.files[0]);
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleButtonClick = () =>
    hiddenFileInput && hiddenFileInput.current?.click();

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Button
          variant="text"
          onClick={handleButtonClick}
          sx={{ p: 0 }}
          {...otherProps}
        >
          <Typography variant="p1" color="primary.500">
            {caption}
          </Typography>
        </Button>
        {hintCaption && (
          <Typography variant="p2" color="grey.500" mt={0.5}>
            {hintCaption}
          </Typography>
        )}
      </Box>
      <input
        type="file"
        ref={hiddenFileInput}
        accept='accept="image/png, image/jpeg"'
        onChange={handleUpload}
        style={{ display: "none" }}
      />
    </>
  );
};
