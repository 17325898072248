import {
  ProductRole,
  ProductRoleAction,
  ProductUserRole,
} from "generated/graphql";

const canAccessPermission = (
  roles: ProductUserRole[],
  permission: ProductRoleAction
) => {
  const allUserPermissions = roles
    .map((role) => role.productRole.actions.items)
    .flat();
  const allUniqUserPermissions = allUserPermissions.filter(
    (userRermission, index) =>
      allUserPermissions.indexOf(userRermission) === index
  );

  return !!allUniqUserPermissions.find(
    (userPermission) => userPermission.id === permission.id
  );
};

export const canAccess = (
  roles: ProductUserRole[],
  permissions: ProductRoleAction | ProductRoleAction[]
) => {
  if (typeof permissions === "object" && Array.isArray(permissions)) {
    return permissions
      .map((permission) => canAccessPermission(roles, permission))
      .reduce((acc, curValue) => acc && curValue, true);
  }

  return canAccessPermission(roles, permissions);
};

/**
 *
 * @param userRoles user roles
 * @param allowedRoles role(s) that are allowed
 * @returns True if user has at least one allowed role.
 */
export const hasRoleAccess = (
  userRoles: ProductUserRole[],
  allowedRoles: ProductRole | ProductRole[]
): boolean => {
  if (typeof allowedRoles === "object" && Array.isArray(allowedRoles)) {
    return userRoles.some((userRole) =>
      allowedRoles.find(
        (allowedRole) => allowedRole.id === userRole.productRole.id
      )
    );
  }
  return userRoles.some(
    (userRole) => userRole.productRole.id === allowedRoles.id
  );
};

export const getProductRolePermissionByName = (
  allProductRolePermissions: ProductRoleAction[],
  permissionName: string // TODO: replace this string with an enum
) => {
  return allProductRolePermissions.find(
    (permission) =>
      permission.name.toLowerCase() === permissionName.toLowerCase()
  );
};
