import {
  DailyDiaryItem,
  ImportDailyDiaryWeatherRecordsMutation,
  ImportDailyDiaryWeatherRecordsMutationVariables,
} from "generated/graphql";
import { useWeatherRecords } from "./useWeatherRecords";
import { useGraphMutation } from "hooks/useGraphMutation";
import { importDailyDiaryWeatherRecordsMutation } from "./WeatherSection.query";
import { useTranslation } from "react-i18next";

export const useImportWeatherRecordsModal = (dailyDiary?: DailyDiaryItem) => {
  const { t } = useTranslation();

  const { weatherRecords, weatherRecordsLoading } =
    useWeatherRecords(dailyDiary);

  const [importRecords, { loading: importLoading }] = useGraphMutation<
    ImportDailyDiaryWeatherRecordsMutation,
    ImportDailyDiaryWeatherRecordsMutationVariables
  >(
    importDailyDiaryWeatherRecordsMutation,
    {},
    t("Projects.DailyDiaries.ImportRecordsModal.importSuccess")
  );

  return {
    weatherRecords,
    loading: weatherRecordsLoading || importLoading,
    importLoading,
    weatherRecordsLoading,
    importRecords,
  };
};
