import { ItemStatusOptionReason } from "generated/graphql";

export const sortOptionReasons = (
  optionReasons: ItemStatusOptionReason[]
): ItemStatusOptionReason[] => {
  const optionReasonsCopy = JSON.parse(
    JSON.stringify(optionReasons)
  ) as ItemStatusOptionReason[];

  optionReasonsCopy.sort((optionReason1, optionReason2) => {
    if (optionReason1.displayOrder < optionReason2.displayOrder) {
      return -1;
    } else if (optionReason1.displayOrder > optionReason2.displayOrder) {
      return 1;
    } else {
      return (
        new Date(optionReason1.dateCreated).getTime() -
        new Date(optionReason2.dateCreated).getTime()
      );
    }
  });

  return optionReasonsCopy;
};
