import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { DailyDiaryItemStatus } from "generated/graphql";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryHeaderContext } from "./DailyDiaryHeader.context";

export const ReviewerButtons: React.FC = () => {
  const { dailyDiaryStatus, loading, onDeny, onApprove } = useContext(
    DailyDiaryHeaderContext
  );
  const { t } = useTranslation();

  return dailyDiaryStatus === DailyDiaryItemStatus.Sent ? (
    <Stack direction="row" spacing={1}>
      <LoadingButton
        variant="outlined"
        color="error"
        size="large"
        loading={loading}
        onClick={onDeny}
      >
        {t("common.buttons.reject")}
      </LoadingButton>
      <LoadingButton
        variant="contained"
        loading={loading}
        size="large"
        onClick={onApprove}
      >
        {t("common.buttons.approve")}
      </LoadingButton>
    </Stack>
  ) : null;
};
