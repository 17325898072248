import {
  GridCellParams,
  GridEventListener,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import {
  DraftVariationAgreement,
  DraftVariationDetermination,
  DraftVariationProposal,
  VariationAgreement,
  VariationDetermination,
  VariationProposal,
} from "generated/graphql";
import { useCallback, useEffect, useState } from "react";
import {
  localVOItemToVOItem,
  useColumns,
} from "./VOProposalsAgreementsDeterminationsTable.constants";
import { EmptyTableView } from "./EmptyTableView";

export type VariationItemTypes =
  | "VariationProposal"
  | "VariationAgreement"
  | "VariationDetermination";

export type VariationResolveType =
  | DraftVariationProposal
  | VariationProposal
  | DraftVariationAgreement
  | VariationAgreement
  | DraftVariationDetermination
  | VariationDetermination;

export type LocalVOResolveType = {
  isSelected?: boolean;
} & VariationResolveType;

export type VOProposalsAgreementsDeterminationsTableProps = {
  contractCurrency: string;
  loading?: boolean;
  selectedItemId?: string;
  type: VariationItemTypes;
  items: VariationResolveType[];
  hasError?: boolean;
  onSelectionChange?: (item?: VariationResolveType) => void;
  onDelete?: (voItemId: string) => void;
  onRowClick?: (voItem: VariationResolveType) => void;
};

export const VOProposalsAgreementsDeterminationsTable: React.FC<
  VOProposalsAgreementsDeterminationsTableProps
> = ({
  loading,
  contractCurrency,
  items,
  selectedItemId,
  type,
  hasError,
  onSelectionChange,
  onDelete,
  onRowClick,
}) => {
  const selectable = !!onSelectionChange;
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const columns = useColumns(contractCurrency, type, onDelete);

  const [rows, setRows] = useState<LocalVOResolveType[]>(items ?? []);

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (params) => {
      const proposal = params.row;

      if (onRowClick) {
        onRowClick(
          proposal.__typename === "VariationProposal"
            ? localVOItemToVOItem(proposal as LocalVOResolveType)
            : proposal
        );
      }
    },
    [onRowClick]
  );

  const handleGridRowSelectionModelChange = useCallback(
    (crtSelection: GridRowSelectionModel) => {
      if (crtSelection.length > 1) {
        const selectionSet = new Set(selectionModel);
        const result = crtSelection.filter((s) => !selectionSet.has(s));

        setSelectionModel(result);
        const selectedItem = items.find((item) => item.id === result[0]);
        onSelectionChange?.(selectedItem);
      } else {
        setSelectionModel(crtSelection);
        const selectedItem = items.find((item) => item.id === crtSelection[0]);
        onSelectionChange?.(selectedItem);
      }
    },
    [selectionModel, items, onSelectionChange]
  );

  useEffect(() => {
    setRows(items ?? []);
  }, [items]);

  useEffect(() => {
    // auto-selecting the first proposal for acceptance flow. TODO: will remove this in the future
    if (selectable && selectedItemId) {
      setRows((crtRows) =>
        crtRows.map(
          (row) =>
            (row.id === selectedItemId
              ? { ...row, isSelected: true }
              : row) as LocalVOResolveType
        )
      );
    }
  }, [selectable, selectedItemId]);

  return (
    <NewStyledDataGrid
      rows={rows}
      columns={columns}
      getRowId={(rowData: DraftVariationProposal | LocalVOResolveType) =>
        rowData.id
      }
      loading={loading}
      slots={{ noRowsOverlay: () => <EmptyTableView type={type} /> }}
      getCellClassName={(
        params: GridCellParams<any, LocalVOResolveType, any>
      ) => {
        return (params.row as LocalVOResolveType).isSelected ? "selected" : "";
      }}
      onRowClick={handleRowClick}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      rowSelectionModel={selectable ? selectionModel : undefined}
      checkboxSelection={selectable}
      onRowSelectionModelChange={handleGridRowSelectionModelChange}
      rowSelection={selectable}
      error={hasError}
      hideFooter
      autoHeight
    />
  );
};
