import { ProductUserRole } from "generated/graphql";
import { temporaryRowId } from "constants/constants";
import { getUserName } from "helpers/miscelaneous";

export const rowsContainTemporaryRecord = (
  rows: ProductUserRole[]
): boolean => {
  return !!rows.find((row) => row.id === temporaryRowId);
};

export const sortRoles = (rows: ProductUserRole[]) => {
  const rowsCopy = [...rows];

  return rowsCopy.sort((role1, role2) => {
    const role1FullName = getUserName(role1.user);
    const role2FullName = getUserName(role2.user);

    return role1FullName > role2FullName
      ? 1
      : role1FullName === role2FullName
      ? 0
      : -1;
  });
};
