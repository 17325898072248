import { changeInstructionItemOwnerMutation } from "graphql/mutations/changeInstructionOwner";
import { useTranslation } from "react-i18next";
import { useGraphMutation } from "./useGraphMutation";
import {
  ChangeInstructionItemOwnerMutation,
  ChangeInstructionItemOwnerMutationVariables,
} from "generated/graphql";

export const useChangeInstructionOwner = (onOwnerChange?: () => void) => {
  const { t } = useTranslation();
  const [changeInstructionOwner, { loading }] = useGraphMutation<
    ChangeInstructionItemOwnerMutation,
    ChangeInstructionItemOwnerMutationVariables
  >(
    changeInstructionItemOwnerMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "instructions" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "instruction" });
        cache.gc();
        onOwnerChange?.();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Instructions.instruction"),
    })
  );

  return {
    changeInstructionOwner,
    loading,
  };
};
