import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const UnselectedNewIcon: React.FC<BaseIconProps> = ({
  height = "14",
  width = "14",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00121 13.001C10.3156 13.001 13.0024 10.3141 13.0024 6.99975C13.0024 3.68537 10.3156 0.998535 7.00121 0.998535C3.68683 0.998535 1 3.68537 1 6.99975C1 10.3141 3.68683 13.001 7.00121 13.001Z"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
