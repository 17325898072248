import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Overlay } from "components/Overlay";
import { FormPublicApi } from "types/decl";
import {
  AddProductSchemaInput,
  EditProductSchemaInput,
  ProductSchema,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { dataValidators } from "./SchemaForm.constants";

export const defaultProductSchema: Omit<AddProductSchemaInput, "productId"> = {
  isDefault: false,
  name: "",
};

export type FormSchemaType =
  | Omit<AddProductSchemaInput, "productId">
  | Omit<EditProductSchemaInput, "productId">;

export type SchemaFormProps = {
  schema?: ProductSchema;
  disabled?: boolean;
  apiRef?: React.Ref<FormPublicApi>;
  onChange: (updatedProductSchema: FormSchemaType) => void;
};

export const SchemaForm: React.FC<SchemaFormProps> = ({
  schema,
  disabled,
  apiRef,
  onChange,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormSchemaType>(
    schema || defaultProductSchema
  );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleCheckboxChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: checked,
    }));
  };

  const validateForm = useCallback((formData: FormSchemaType) => {
    const validationResult = validateData(formData, dataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);

    return false;
  }, []);

  const resetForm = useCallback(() => {
    setFormData(schema ?? defaultProductSchema);
  }, [schema]);

  useEffect(() => {
    const updatedFormData: FormSchemaType = {
      name: formData.name,
      id: schema?.id,
      isDefault: formData.isDefault,
    };

    onChange?.(updatedFormData);
  }, [formData, onChange, schema]);

  useEffect(() => {
    setFormData(schema ?? defaultProductSchema);
  }, [schema]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [validateForm, formData, resetForm]
  );

  return (
    <>
      {disabled && <Overlay withBackground={false} />}
      <Grid container spacing={6}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleTextFieldChange}
            type="text"
            label={t("common.labels.name")}
            variant="standard"
            error={!!formDataErrors.name}
            helperText={formDataErrors.name}
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <FormControlLabel
            label={
              <Typography variant="caption">
                {`${t("AdminConsole.Products.labels.isDefault")}`}
              </Typography>
            }
            labelPlacement="end"
            control={
              <Checkbox
                inputProps={{
                  "aria-label": `${t(
                    "AdminConsole.Products.labels.isDefault"
                  )}`,
                }}
                name="isDefault"
                onChange={handleCheckboxChange}
                checked={!!formData.isDefault}
                required
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
