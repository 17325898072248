import { Box, css, Stack, styled, Typography, useTheme } from "@mui/material";
import {
  ClaimDetailsExplorerItem,
  CompEventQuotationAssessment,
  Contract,
  ProductType,
  VariationProposalExplorerItem,
} from "generated/graphql";
import { ClipboardText, Money } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { ContractPriceTimeChangeReadOnlyView } from "../HistoryModal/ContractPriceTimeChangeReadOnlyView";

export type ContractPriceTimeChangeDetailsProps = {
  item:
    | CompEventQuotationAssessment
    | ClaimDetailsExplorerItem
    | VariationProposalExplorerItem;
  productType:
    | ProductType.CompEvents
    | ProductType.Claims
    | ProductType.Variations;
  contract: Contract;
  isFIDIC17White?: boolean;
  isFIDIC99RedYellow?: boolean;
};

const Container = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(3)};
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.palette.grey[200]};
    border-radius: 8px;
  `
);

export const ContractPriceTimeChangeDetails: React.FC<
  ContractPriceTimeChangeDetailsProps
> = ({ item, productType, contract, isFIDIC17White, isFIDIC99RedYellow }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isQuotationOrAssessment =
    item.__typename === "CompEventQuotation" ||
    item.__typename === "CompEventAssessment";

  const title = `${item.reference} (${
    isQuotationOrAssessment
      ? t(
          `Projects.CompEvents.${
            item.__typename === "CompEventQuotation"
              ? "quotation"
              : "assessment"
          }`
        )
      : productType === ProductType.Claims
      ? t(
          `Projects.Claims.${
            item.__typename === "DetailedClaim"
              ? `detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
              : item.__typename === "ClaimAgreement"
              ? `claimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
              : `claimDetermination${isFIDIC99RedYellow ? "99RY" : ""}`
          }`
        )
      : t(
          `Projects.Variations.${
            item.__typename === "VariationProposal"
              ? "variationProposal"
              : item.__typename === "VariationAgreement"
              ? "variationAgreement"
              : "variationDetermination"
          }`
        )
  }):`;

  const keyDatesChanges = isQuotationOrAssessment
    ? item.keyDatesChanges
    : undefined;

  return (
    <Container>
      <Stack direction="row" spacing={1} alignItems="center" mb={3}>
        {item.__typename === "CompEventQuotation" ||
        item.__typename === "DetailedClaim" ||
        item.__typename === "VariationProposal" ? (
          <Money size={24} color={theme.palette.grey[900]} />
        ) : (
          <ClipboardText size={24} color={theme.palette.grey[900]} />
        )}
        <Typography variant="h3" color="grey.900" fontWeight={600}>
          {title}
        </Typography>
      </Stack>
      <ContractPriceTimeChangeReadOnlyView
        details={item.details}
        time={item.time}
        price={item.price}
        contract={contract}
        isFIDIC17White={isFIDIC17White}
        dateSent={item.dateCreated}
        keyDatesChanges={keyDatesChanges}
        sectionalChanges={item.sectionalChanges}
        attachments={item.attachments}
        productType={productType}
      />
    </Container>
  );
};
