import { styled, css } from "@mui/material";
import { ProductItemsHeader } from "./components/ProductItemsHeader";

export const StyledProductItemsHeader = styled(ProductItemsHeader, {
  shouldForwardProp: (prop) => prop !== "floatLastTabToRight",
})<{
  floatLastTabToRight?: boolean;
}>(
  ({ theme, floatLastTabToRight }) => css`
    ${floatLastTabToRight &&
    css`
      .MuiTabs-root {
        margin-right: ${theme.spacing(1.5)};
        button.MuiButtonBase-root:last-of-type {
          margin-left: auto;
        }
      }
    `}
  `
);
