import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventRejectionMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventRejection($input: SendCompEventRejectionInput!) {
    sendCompEventRejection(input: $input) {
      ...CompEventItemFields
    }
  }
`;
