import { gql } from "@apollo/client";
import { timePeriodLiteFields } from "graphql/fragments/timePeriod.fragment";

export const timePeriodsQuery = gql`
  ${timePeriodLiteFields}
  query timePeriods($contractTypeId: ID!) {
    timePeriods(contractTypeId: $contractTypeId) {
      items {
        ...TimePeriodLiteFields
      }
    }
  }
`;
