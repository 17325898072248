import { gql } from "@apollo/client";

export const changeItemStatusCollectionStatusMutation = gql`
  mutation changeItemStatusCollectionStatus(
    $id: ID!
    $status: ItemStatusCollectionStatus!
  ) {
    changeItemStatusCollectionStatus(id: $id, status: $status) {
      id
      name
      isDefault
      productId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;
