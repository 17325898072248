import {
  ChangeInstructionItemStatusMutation,
  ChangeInstructionItemStatusMutationVariables,
  EditInstructionItemMutation,
  EditInstructionItemMutationVariables,
  InstructionItemExtendedQuery,
  InstructionItemExtendedQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  changeInstructionItemStatusMutation,
  editInstructionItemMutation,
  instructionItemExtendedQuery,
} from "../InstructionDetails.query";
import { ErrorHandlingType, useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const useInstructionDetails = (onReloadChangelog: () => void) => {
  const { t } = useTranslation();
  const { instructionItemId } = useParams();

  const {
    data: instructionData,
    refetch: refetchInstruction,
    loading: instructionLoading,
    error: errorInstruction,
  } = useGraphQuery<
    InstructionItemExtendedQuery,
    InstructionItemExtendedQueryVariables
  >(
    instructionItemExtendedQuery,
    {
      variables: { id: instructionItemId! },
      fetchPolicy: "cache-and-network",
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  const [changeInstructionStatus, { loading: changeInstructionStatusLoading }] =
    useGraphMutation<
      ChangeInstructionItemStatusMutation,
      ChangeInstructionItemStatusMutationVariables
    >(
      changeInstructionItemStatusMutation,
      {
        update: (_cache) => {
          // cache.evict({ id: "ROOT_QUERY", fieldName: "instructionItems" });
          // cache.gc();
          onReloadChangelog();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Instructions.instruction"),
      })
    );

  const [doEditInstruction] = useGraphMutation<
    EditInstructionItemMutation,
    EditInstructionItemMutationVariables
  >(
    editInstructionItemMutation,
    {
      update: onReloadChangelog,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Instructions.instruction"),
    })
  );

  return {
    instruction: instructionData?.instructionItem,
    // instruction: instructionData?.instructionItem as
    //   | InstructionItem
    //   | undefined,
    instructionLoading,
    errorInstruction,
    changeInstructionStatus,
    changeInstructionStatusLoading,
    doEditInstruction,
    refetchInstruction,
  };
};
