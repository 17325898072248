import React from "react";
import { BaseIconProps } from "./decl";

export const UnselectedIcon: React.FC<BaseIconProps> = ({
  height = "20",
  width = "21",
  color = "#DBDBDB",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8289 20C5.30586 20 0.828857 15.523 0.828857 10C0.828857 4.477 5.30586 0 10.8289 0C16.3519 0 20.8289 4.477 20.8289 10C20.8289 15.523 16.3519 20 10.8289 20Z"
        fill={color}
      />
    </svg>
  );
};
