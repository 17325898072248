import { gql } from "@apollo/client";
import { claimTypeFields } from "graphql/fragments/claimType.fragment";

export const claimTypesQuery = gql`
  ${claimTypeFields}

  query claimTypes($contractTypeId: ID!) {
    claimTypes(contractTypeId: $contractTypeId) {
      items {
        ...ClaimTypeFields
      }
    }
  }
`;
