import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CreateIcon } from "components/Icons/CreateIcon";
import { ProductType } from "generated/graphql";
import { useTranslation } from "react-i18next";
import {
  ProductItemCard,
  ProductItemCardProps,
} from "../../../../../../components/ProductItemCard/ProductItemCard";
import { useNewRiskEventItemConfirmModal } from "./useNewRiskEventItemConfirmModal";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { RecipientsPreview } from "containers/Projects/components/RecipientsPreview";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { useParams } from "react-router-dom";

export type NewRiskEventItemConfirmModalProps = ProductItemCardProps &
  ActionsDialogProps;

export const NewRiskEventItemConfirmModal: React.FC<
  NewRiskEventItemConfirmModalProps
> = ({
  itemName,
  projectName,
  contractName,
  productType = ProductType.RisksRegister,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isRisk = productType === ProductType.RisksRegister;
  const { productInstanceId } = useParams();

  const { recipients, loading: recipientsLoading } =
    useNewRiskEventItemConfirmModal(
      productInstanceId!,
      productType === ProductType.Events,
      !restDialogProps.open
    );

  return (
    <ActionsDialog
      iconsHeader={<CreateIcon />}
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {isRisk
              ? t("Projects.Risks.CreateRiskDialog.title")
              : t("Projects.Events.CreateEventDialog.title")}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]}>
            {isRisk
              ? t("Projects.Risks.CreateRiskDialog.subtitle")
              : t("Projects.Events.CreateEventDialog.subtitle")}
          </Typography>
        </Box>
      }
      maxWidth="sm"
      fullWidth
      content={
        <Stack spacing={4}>
          <ProductItemCard
            itemName={itemName}
            productType={productType}
            projectName={projectName}
            contractName={contractName}
          />
          {recipientsLoading ? (
            <CenteredLoadingIndicator />
          ) : recipients ? (
            <CollapsibleContainer title={t("common.labels.recipients")}>
              <RecipientsPreview recipients={recipients} />
            </CollapsibleContainer>
          ) : null}
        </Stack>
      }
      {...restDialogProps}
    />
  );
};
