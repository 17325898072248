import { LinearProgress, styled } from "@mui/material";
import { useGlobalStore } from "state-management/store/globalStore";

const StyledLinearProgress = styled(LinearProgress)`
  background: #78c485; // TODO: not a color from the theme palette
  width: 100%;
  min-height: 4px;
`;

export const GlobalLoading: React.FC = () => {
  const loading = useGlobalStore((state) => state.loading);

  return (
    <StyledLinearProgress
      color="success"
      variant={loading ? "indeterminate" : "determinate"}
      value={loading ? undefined : 0}
    />
  );
};
