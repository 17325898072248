import { gql } from "@apollo/client";

export const usersByContractQuery = gql`
  query usersByContract($contractId: ID!, $limit: Int!, $nextToken: String) {
    usersByContract(
      contractId: $contractId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        firstname
        surname
        email
        companyId
        company {
          id
          tradingName
        }
        registered
        status
        roles {
          items {
            id
            userId
            productInstanceId
            productRoleId
            status
            dateCreated
            creatorId
            productInstance {
              id
              description
              status
              product {
                __typename
                id
                name
                status
              }
            }
            productRole {
              id
              name
              productId
              isInternal
              status
              dateCreated
              creatorId
            }
          }
        }
      }
    }
  }
`;

export const contractProductInstancesQuery = gql`
  query contractProductInstances($id: ID!) {
    contract(id: $id) {
      id
      productInstances {
        items {
          __typename
          id
          description
          status
          product {
            id
            roles {
              items {
                id
                name
                status
              }
            }
          }
        }
      }
    }
  }
`;

export const bulkAssignUserRolesMutation = gql`
  mutation bulkAssignUserRoles($input: BulkUserRoleAssignmentInput!) {
    bulkAssignUserRoles(input: $input)
  }
`;
