import { gql } from "@apollo/client";
import { newClaimTypeOverrideFields } from "graphql/fragments/newClaimType.fragment";

export const changeNewClaimTypeOverrideStatusMutation = gql`
  ${newClaimTypeOverrideFields}

  mutation changeNewClaimTypeOverrideStatus(
    $id: ID!
    $status: NewClaimTypeOverrideStatus!
  ) {
    changeNewClaimTypeOverrideStatus(id: $id, status: $status) {
      ...NewClaimTypeOverrideFields
    }
  }
`;
