import { gql } from "@apollo/client";

export const editUserMutation = gql`
  mutation editUserMutation($input: EditUserInput!) {
    editUser(input: $input) {
      id
      firstname
      surname
      jobTitle
      country
      mobileNumber
      alternateNumber
      dateOfBirth
      profilePicture
      email
      companyId
      registered
      status
      lastActive
      dateInvited
      registeredDate
      invitedBy {
        id
        firstname
        surname
        email
      }
    }
  }
`;
