import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  DraftVariationProposal,
  RecordVariationProposalForProposedVariationMutation,
  RecordVariationProposalForProposedVariationMutationVariables,
  SendVariationProposalForProposedVariationMutation,
  SendVariationProposalForProposedVariationMutationVariables,
  VariationProposalForProposedVariationPromptQuery,
  VariationProposalForProposedVariationPromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "types/decl";
import { useGraphMutation } from "hooks/useGraphMutation";

import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationProposalForProposedVariationMutation,
  sendVariationProposalForProposedVariationMutation,
  variationProposalForProposedVariationPromptQuery,
} from "./SubmitProposalForProposedVariationAction.query";

export const useSubmitProposalForProposedVariationAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationProposalForProposedVariationPromptQuery,
    VariationProposalForProposedVariationPromptQueryVariables
  >(variationProposalForProposedVariationPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationDraftVariationProposals",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalForProposedVariationPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [sendProposal, { loading: sendProposalLoading }] = useGraphMutation<
    SendVariationProposalForProposedVariationMutation,
    SendVariationProposalForProposedVariationMutationVariables
  >(
    sendVariationProposalForProposedVariationMutation,
    { update: onUpdated },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Variations.variationProposal"),
    })
  );

  const [recordProposal, { loading: recordProposalLoading }] = useGraphMutation<
    RecordVariationProposalForProposedVariationMutation,
    RecordVariationProposalForProposedVariationMutationVariables
  >(
    recordVariationProposalForProposedVariationMutation,
    { update: onUpdated },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.variationProposal"),
    })
  );

  const draftVariationProposals = useMemo(() => {
    return (promptData?.variationProposalForProposedVariationPrompt
      .draftProposals ?? []) as DraftVariationProposal[];
  }, [promptData]);

  return {
    draftVariationProposals,
    loading,
    sendProposal,
    recordProposal,
    actionLoading: sendProposalLoading || recordProposalLoading,
  };
};
