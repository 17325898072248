import {
  Box,
  css,
  Dialog,
  DialogProps,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import { XIcon } from "./Icons/XIcon";
import {
  PrimarySecondaryButtons,
  PrimarySecondaryButtonsProps,
} from "./PrimarySecondaryButtons";

export type ActionsDialogProps = Omit<DialogProps, "title"> & {
  iconsHeader?: React.ReactNode;
  title?: React.ReactNode;
  content?: React.ReactNode;
  contentSx?: SxProps<Theme>;
  footer?: React.ReactNode;
} & PrimarySecondaryButtonsProps;

export const IconsHeader = styled(Box)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(3)};
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

export const ActionsDialog: React.FC<ActionsDialogProps> = ({
  open,
  iconsHeader,
  title,
  content,
  footer,
  contentSx,
  primaryBtnCaption,
  primaryBtnLoading,
  secondaryBtnCaption,
  secondaryBtnLoading,
  primaryBtnDisabled,
  primaryBtnDisabledTooltipText,
  primaryBtnColor,
  secondaryBtnColor,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
  ...restProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...restProps}
    >
      <Box sx={contentSx}>
        <IconsHeader>
          {iconsHeader}
          <Box display="flex" ml="auto" sx={{ cursor: "pointer" }}>
            <XIcon
              onClick={() => {
                onClose?.({}, "backdropClick");
              }}
            />
          </Box>
        </IconsHeader>
        {title && (
          <Box py={2} px={3}>
            {title}
          </Box>
        )}
        {content && (
          <Box px={3} pt={2} pb={3}>
            {content}
          </Box>
        )}
        {footer ?? (
          <Box display="flex" p={3} pt={0} justifyContent="flex-end">
            <PrimarySecondaryButtons
              primaryBtnCaption={primaryBtnCaption}
              primaryBtnLoading={primaryBtnLoading}
              primaryBtnDisabled={primaryBtnDisabled}
              primaryBtnColor={primaryBtnColor}
              primaryBtnDisabledTooltipText={primaryBtnDisabledTooltipText}
              secondaryBtnCaption={secondaryBtnCaption}
              secondaryBtnLoading={secondaryBtnLoading}
              secondaryBtnColor={secondaryBtnColor}
              onPrimaryClick={onPrimaryClick}
              onSecondaryClick={onSecondaryClick}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
