import { gql } from "@apollo/client";

export const compEventGrantingOfMoreTimePromptQuery = gql`
  query compEventGrantingOfMoreTimePrompt(
    $input: CompEventGrantingOfMoreTimePromptInput!
  ) {
    compEventGrantingOfMoreTimePrompt(input: $input) {
      grantedDays
      remarks
      actionDueDate
    }
  }
`;
