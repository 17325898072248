import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendNoticeOfClaimInvalidModal } from "./useSendNoticeOfClaimInvalidModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";

export type SendNoticeOfClaimInvalidModalProps = {
  reasons: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendNoticeOfClaimInvalidModal: React.FC<
  SendNoticeOfClaimInvalidModalProps
> = ({ reasons, attachments = [], ...restDialogProps }) => {
  const { claimPreview, contract } = useSendNoticeOfClaimInvalidModal(reasons);
  const { t } = useTranslation();

  return (
    <SendAction
      claimPreview={claimPreview}
      actionType={ClaimActionType.NotifyClaimNoticeInvalid}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={reasons}
          label={t("common.labels.reasons")}
          attachments={attachments}
          contractTimezone={contract.timeZone}
        />
      }
      {...restDialogProps}
    />
  );
};
