import {
  DailyDiaryItem,
  DailyDiaryWeatherRecord,
  DailyDiaryWeatherRecordsQuery,
  DailyDiaryWeatherRecordsQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { dailyDiaryWeatherRecordsQuery } from "./WeatherSection.query";

export const useWeatherRecords = (
  dailyDiary?: DailyDiaryItem, // TODO: pass only dailyDiaryId - no need for the entire object
  revisionId?: string
) => {
  const {
    data: weatherRecords,
    loading: weatherRecordsLoading,
    refetch: refetchDDWeatherRecords,
  } = useGraphQuery<
    DailyDiaryWeatherRecordsQuery,
    DailyDiaryWeatherRecordsQueryVariables
  >(dailyDiaryWeatherRecordsQuery, {
    variables: { dailyDiaryId: dailyDiary?.id!, revisionId },
    skip: !dailyDiary,
    notifyOnNetworkStatusChange: true,
  });

  return {
    weatherRecords: (weatherRecords?.dailyDiaryWeatherRecords ??
      []) as DailyDiaryWeatherRecord[],
    weatherRecordsLoading,
    refetchDDWeatherRecords,
  };
};
