import { createContext } from "react";

export type InviteUserContextType = {
  contractsWithActiveBindingsIds?: string[];
};

const contextDefaultValue: InviteUserContextType = {
  contractsWithActiveBindingsIds: [],
};

export const InviteUserContext =
  createContext<InviteUserContextType>(contextDefaultValue);
