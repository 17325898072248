import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationErrorDeterminationNoticePromptQuery = gql`
  query variationErrorDeterminationNoticePrompt(
    $input: VariationErrorDeterminationNoticePromptInput!
  ) {
    variationErrorDeterminationNoticePrompt(input: $input) {
      details
    }
  }
`;

export const sendVariationErrorDeterminationNoticeMutation = gql`
  mutation sendVariationErrorDeterminationNotice(
    $input: SendVariationErrorDeterminationNoticeInput!
  ) {
    sendVariationErrorDeterminationNotice(input: $input) {
      id
    }
  }
`;

export const recordVariationErrorDeterminationNoticeMutation = gql`
  mutation recordVariationErrorDeterminationNotice(
    $input: RecordVariationErrorDeterminationNoticeInput!
  ) {
    recordVariationErrorDeterminationNotice(input: $input) {
      id
    }
  }
`;

export const generateSendVariationErrorDeterminationNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationErrorDeterminationNoticePreview(
    $input: SendVariationErrorDeterminationNoticePreviewInput!
  ) {
    generateSendVariationErrorDeterminationNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationErrorDeterminationNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationErrorDeterminationNoticePreview(
    $input: RecordVariationErrorDeterminationNoticePreviewInput!
  ) {
    generateRecordVariationErrorDeterminationNoticePreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
