import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventAbandonInstructionMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventAbandonInstruction(
    $input: RecordCompEventAbandonInstructionInput!
  ) {
    recordCompEventAbandonInstruction(input: $input) {
      ...CompEventItemFields
    }
  }
`;
