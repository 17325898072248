import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimErrorAgreementNoticePromptQuery = gql`
  query claimErrorAgreementNoticePrompt(
    $input: ClaimErrorAgreementNoticePromptInput!
  ) {
    claimErrorAgreementNoticePrompt(input: $input) {
      details
    }
  }
`;

export const sendClaimErrorAgreementNoticeMutation = gql`
  mutation sendClaimErrorAgreementNotice(
    $input: SendClaimErrorAgreementNoticeInput!
  ) {
    sendClaimErrorAgreementNotice(input: $input) {
      id
    }
  }
`;

export const recordClaimErrorAgreementNoticeMutation = gql`
  mutation recordClaimErrorAgreementNotice(
    $input: RecordClaimErrorAgreementNoticeInput!
  ) {
    recordClaimErrorAgreementNotice(input: $input) {
      id
    }
  }
`;

export const generateSendClaimErrorAgreementNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimErrorAgreementNoticePreview(
    $input: SendClaimErrorAgreementNoticePreviewInput!
  ) {
    generateSendClaimErrorAgreementNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimErrorAgreementNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimErrorAgreementNoticePreview(
    $input: RecordClaimErrorAgreementNoticePreviewInput!
  ) {
    generateRecordClaimErrorAgreementNoticePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
