import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventQuotationMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventQuotation($input: RecordCompEventQuotationInput!) {
    recordCompEventQuotation(input: $input) {
      ...CompEventItemFields
    }
  }
`;
