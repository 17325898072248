import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType, DetailedClaim } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { useSendResponseRegardingDetailedClaimModal } from "./useSendResponseRegardingDetailedClaimModal";
import { ResponseRegardingDetailedClaimSummary } from "./ResponseRegardingDetailedClaimSummary";

export type SendResponseRegardingDetailedClaimModalProps = {
  details: string;
  detailedClaim: DetailedClaim;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendResponseRegardingDetailedClaimModal: React.FC<
  SendResponseRegardingDetailedClaimModalProps
> = ({ details, attachments = [], detailedClaim, ...restDialogProps }) => {
  const { claimPreview, contract } = useSendResponseRegardingDetailedClaimModal(
    detailedClaim.id,
    details
  );

  return (
    <SendAction
      claimPreview={claimPreview}
      actionType={ClaimActionType.RespondRegardingDetailedClaim}
      summaryEl={
        <ResponseRegardingDetailedClaimSummary
          details={details}
          detailedClaim={detailedClaim}
          attachments={attachments}
          contract={contract}
        />
      }
      {...restDialogProps}
    />
  );
};
