import React from "react";
import { BaseIconProps } from "./decl";

export const UploadIcon: React.FC<BaseIconProps> = ({
  height = "18",
  width = "20",
  color = "black",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 9.48835L13.5359 13.0234L12.3567 14.2025L10.8334 12.6792V17.3334H9.1667V12.6775L7.64337 14.2025L6.4642 13.0234L10 9.48835ZM10 0.666687C11.4309 0.666755 12.8118 1.19262 13.8802 2.14429C14.9486 3.09596 15.6301 4.40707 15.795 5.82835C16.8319 6.11112 17.7365 6.74927 18.3506 7.63129C18.9647 8.51331 19.2493 9.5831 19.1547 10.6537C19.0601 11.7243 18.5923 12.7275 17.833 13.4882C17.0737 14.2488 16.0713 14.7185 15.0009 14.815V13.1367C15.3843 13.0819 15.7531 12.9513 16.0855 12.7525C16.418 12.5537 16.7075 12.2906 16.9372 11.9787C17.1669 11.6668 17.3321 11.3122 17.4233 10.9357C17.5144 10.5592 17.5297 10.1684 17.4681 9.78591C17.4066 9.40346 17.2695 9.03711 17.0648 8.70823C16.8601 8.37935 16.592 8.09453 16.276 7.87041C15.9601 7.64629 15.6027 7.48735 15.2246 7.40286C14.8466 7.31838 14.4555 7.31005 14.0742 7.37835C14.2047 6.77076 14.1977 6.14166 14.0535 5.53715C13.9094 4.93263 13.6319 4.36801 13.2414 3.88463C12.8508 3.40125 12.357 3.01136 11.7963 2.74352C11.2355 2.47567 10.6219 2.33666 10.0005 2.33666C9.379 2.33666 8.76541 2.47567 8.20464 2.74352C7.64387 3.01136 7.15011 3.40125 6.75954 3.88463C6.36896 4.36801 6.09146 4.93263 5.94735 5.53715C5.80325 6.14166 5.79619 6.77076 5.9267 7.37835C5.16641 7.23558 4.38055 7.40067 3.74199 7.83732C3.10343 8.27397 2.66448 8.9464 2.5217 9.70669C2.37893 10.467 2.54402 11.2528 2.98067 11.8914C3.41732 12.53 4.08975 12.9689 4.85003 13.1117L5.00003 13.1367V14.815C3.92958 14.7186 2.92704 14.2491 2.16765 13.4885C1.40825 12.7279 0.940306 11.7247 0.845596 10.654C0.750885 9.58343 1.03543 8.51359 1.64951 7.6315C2.26358 6.74941 3.16812 6.11118 4.20503 5.82835C4.36979 4.407 5.05121 3.09578 6.11968 2.14407C7.18816 1.19237 8.56916 0.666573 10 0.666687Z"
        fill={color}
      />
    </svg>
  );
};
