import {
  GenerateSendVariationProposalConsentForProposedVariationPreviewMutation,
  GenerateSendVariationProposalConsentForProposedVariationPreviewMutationVariables,
  SendVariationProposalConsentForProposedVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateSendVariationProposalConsentForProposedVariationPreviewMutation } from "./ConsentToProposalForProposedVariationAction.query";

export const useSendConsentToProposalForProposedVariationModal = (
  remarks: string,
  acceptedProposalId: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);
  const [variationPreview, setVariationPreview] =
    useState<SendVariationProposalConsentForProposedVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationProposalConsentForProposedVariationPreviewMutation,
    GenerateSendVariationProposalConsentForProposedVariationPreviewMutationVariables
  >(
    generateSendVariationProposalConsentForProposedVariationPreviewMutation,
    {},
    null
  );

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
            acceptedProposalId,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateSendVariationProposalConsentForProposedVariationPreview
        );
      }
    }, [generatePreview, variation, remarks, acceptedProposalId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview,
    contract,
  };
};
