import {
  authAuditTrailDetailsPathConstructor,
  authWorkflowDetailsPathConstructor,
  companyDetailsPathConstructor,
  companyInviteUserPathConstructor,
  companyUserDetailsPathConstructor,
  contractBindingDetailsPathConstructor,
  contractDetailsPathConstructor,
  contractNewBindingPathConstructor,
  contractNewProductInstancePathConstructor,
  contractProductInstanceDetailsPathConstructor,
  contractTypeDetailsPathConstructor,
  customLookupDetailsPathConstructor,
  effectPresetDetailsPathConstructor,
  getDailyDiaryDetailsPath,
  getEditEventPath,
  getEditRiskPath,
  getNewProductItemPath,
  getProductItemDetailsPath,
  lookupDetailsPathConstructor,
  productCollectionOptionDetailsPathConstructor,
  productDetailsPathConstructor,
  productSchemaDetailsPathConstructor,
  productStatusCollectionDetailsPathConstructor,
  projectDetailsPathConstructor,
  projectNewAuthorizationWorkflowPathConstructor,
  projectNewContractPathConstructor,
  templateDetailsPathConstructor,
} from "./pathConstructors";

export const NewAppPaths = {
  nonAuthorized: {
    Login: "/login",
    Register: "/register",
    TermsOfUse: "/terms-of-use", // TODO: probably remove as we'll show it in a popup
    ResetPassword: "/reset-password",
    SetNewPassowrd: "/choose-new-password",
    MFA: "/mfa",
    Support: "/support", // TODO: remove
  },
  authorized: {
    Home: "/",
    Projects: {
      path: "/projects",
      children: {
        NewRisk: {
          path: "/projects/:projectId/contracts/:contractId/products/:productId/productInstances/:productInstanceId/new-risk",
          pathConstructor: getNewProductItemPath,
        },
        RiskDetails: {
          path: "/projects/product-instances/:productInstanceId/risks/:riskItemId",
          pathConstructor: getProductItemDetailsPath,
        },
        EditRisk: {
          path: "/projects/product-instances/:productInstanceId/risks/:riskItemId/edit",
          pathConstructor: getEditRiskPath,
        },
        NewEvent: {
          path: "/projects/:projectId/contracts/:contractId/products/:productId/productInstances/:productInstanceId/new-event",
          pathConstructor: getNewProductItemPath,
        },
        EventDetails: {
          path: "/projects/product-instances/:productInstanceId/events/:eventItemId",
          pathConstructor: getProductItemDetailsPath,
        },
        EditEvent: {
          path: "/projects/product-instances/:productInstanceId/events/:eventItemId/edit",
          pathConstructor: getEditEventPath,
        },
        NewEarlyWarning: {
          path: "/projects/:projectId/contracts/:contractId/products/:productId/productInstances/:productInstanceId/new-early-warning",
          pathConstructor: getNewProductItemPath,
        },
        EarlyWarningDetails: {
          path: "/projects/product-instances/:productInstanceId/early-warnings/:ewItemId",
          pathConstructor: getProductItemDetailsPath,
        },
        NewInstruction: {
          path: "/projects/:projectId/contracts/:contractId/products/:productId/productInstances/:productInstanceId/new-instruction",
          pathConstructor: getNewProductItemPath,
        },
        InstructionDetails: {
          path: "/projects/product-instances/:productInstanceId/instructions/:instructionItemId",
          pathConstructor: getProductItemDetailsPath,
        },
        DailyDiaryDetails: {
          path: "/projects/product-instances/:productInstanceId/daily-diaries/:date",
          pathConstructor: getDailyDiaryDetailsPath,
        },
      },
    },
    ProductInstances: "/product-instances", // TODO: remove
    // Reporting: "/reporting", // TODO: uncomment when implementing reporting
    AdminConsole: {
      path: "/admin",
      children: {
        Companies: {
          path: "/admin/companies",
          children: {
            NewCompany: "/admin/companies/new",
            CompanyDetails: {
              path: "/admin/companies/:companyId",
              pathConstructor: companyDetailsPathConstructor,
              children: {
                UserDetails: {
                  path: "/admin/companies/:companyId/users/:userId",
                  pathConstructor: companyUserDetailsPathConstructor,
                },
                InviteUser: {
                  path: "/admin/companies/:companyId/users/invite",
                  pathConstructor: companyInviteUserPathConstructor,
                },
              },
            },
          },
        },
        Projects: {
          path: "/admin/projects",
          children: {
            NewProject: "/admin/projects/new",
            ProjectDetails: {
              path: "/admin/projects/:projectId",
              pathConstructor: projectDetailsPathConstructor,
              children: {
                NewContract: {
                  path: "/admin/projects/:projectId/new-contract",
                  pathConstructor: projectNewContractPathConstructor,
                },
                NewAuthorizationWorkflow: {
                  path: "/admin/projects/:projectId/new-authorization-workflow",
                  pathConstructor:
                    projectNewAuthorizationWorkflowPathConstructor,
                },
              },
            },
            NewContract: "/admin/projects/:projectId/new-contract",
            ContractDetails: {
              // TODO: maybe move it under projectDetails
              path: "/admin/projects/:projectId/contracts/:contractId",
              pathConstructor: contractDetailsPathConstructor,
              children: {
                NewBinding: {
                  path: "/admin/projects/:projectId/contracts/:contractId/new-binding",
                  pathConstructor: contractNewBindingPathConstructor,
                },
                BindingDetails: {
                  path: "/admin/projects/:projectId/contracts/:contractId/bindings/:bindingId",
                  pathConstructor: contractBindingDetailsPathConstructor,
                },
                NewProductInstance: {
                  path: "/admin/projects/:projectId/contracts/:contractId/new-product-instance",
                  pathConstructor: contractNewProductInstancePathConstructor,
                },
                ProductInstanceDetails: {
                  path: "/admin/projects/:projectId/contracts/:contractId/product-instances/:productInstanceId",
                  pathConstructor:
                    contractProductInstanceDetailsPathConstructor,
                },
              },
            },
            AuthorizationWorkflowDetails: {
              path: "/admin/projects/:projectId/authorization-workflows/:authWorkflowId",
              pathConstructor: authWorkflowDetailsPathConstructor,
            },
            AuthorizationAuditTrailDetails: {
              path: "/admin/projects/:projectId/authorization-audit-trails/:authAuditTrailId",
              pathConstructor: authAuditTrailDetailsPathConstructor,
            },
          },
        },
        System: {
          path: "/admin/system",
          children: {
            ContractTypes: "/admin/system/contract-types",
            ContractTypeDetails: {
              path: "/admin/system/contract-types/:contractTypeId",
              pathConstructor: contractTypeDetailsPathConstructor,
            },
            Products: {
              path: "/admin/system/products",
              children: {
                ProductDetails: {
                  path: "/admin/system/products/:productId",
                  pathConstructor: productDetailsPathConstructor,
                  children: {
                    SchemaDetails: {
                      path: "/admin/system/products/:productId/schemas/:schemaId",
                      pathConstructor: productSchemaDetailsPathConstructor,
                    },
                    StatusCollectionDetails: {
                      path: "/admin/system/products/:productId/status-collections/:collectionId",
                      pathConstructor:
                        productStatusCollectionDetailsPathConstructor,
                      children: {
                        CollectionOptionDetails: {
                          path: "/admin/system/products/:productId/status-collections/:collectionId/status-options/:optionId",
                          pathConstructor:
                            productCollectionOptionDetailsPathConstructor,
                        },
                      },
                    },
                  },
                },
              },
            },
            Terms: "/admin/system/terms",
            Templates: "/admin/system/templates",
            DocumentTemplateRouter:
              "/admin/system/templates/document-template-router",
            ActionTypes: "/admin/system/action-types",
            Notifications: "/admin/system/notifications",
            Lookups: "/admin/system/lookups",
            LookupsEffect: {
              path: "/admin/system/lookups/effect",
              children: {
                EffectPreset: {
                  path: "/admin/system/lookups/effect/:effectPresetId",
                  pathConstructor: effectPresetDetailsPathConstructor,
                },
              },
            },
            SimpleLookups: "/admin/system/lookups/simple",
            CustomSimpleLookups: "/admin/system/lookups/custom",
            NewTemplate: "/admin/system/templates/new",
            TemplateDetails: {
              path: "/admin/system/templates/:templateId",
              pathConstructor: templateDetailsPathConstructor,
            },
            NewLookupCollection: "/admin/system/lookups/simple/new",
            LookupCollectionDetails: {
              path: "/admin/system/lookups/simple/:lookupCollectionId",
              pathConstructor: lookupDetailsPathConstructor,
            },
            CustomLookupCollectionDetails: {
              path: "/admin/system/lookups/custom/:lookupCollectionId",
              pathConstructor: customLookupDetailsPathConstructor,
            },
            NewCustomLookupCollection: "/admin/system/lookups/custom/new",
          },
        },
        Logs: {
          path: "admin/logs",
          children: {
            LoginHistory: "/admin/logs/login-history",
            Notifications: "/admin/logs/notifications",
            Activities: "/admin/logs/activities",
          },
        },
        Support: "/admin/support",
      },
    },
    Settings: {
      path: "/settings",
      children: {
        // Profile: "/settings/profile",
        ProfileEdit: "/settings/profile/edit",
        ChangePassword: "/settings/change-password",
        // Project: "/settings/project",
        Notifications: "/settings/notifications",
      },
    },
    NotFound: "/not-found",
  },
};

/**
 * Non-authorized top level paths
 */
export enum AUTH_SERVICE_URLS {
  Login = "signin",
  MFAVerifyCode = "verify-code",
  ResetPassword = "reset-password",
  SetNewPassword = "confirm-password",
  RefreshToken = "refresh-token",
}
