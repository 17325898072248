import {
  DailyDiaryHseRecord,
  EditDailyDiaryHseRecordInput,
} from "generated/graphql";

export const hseRecordToEditInput = (
  record: DailyDiaryHseRecord
): EditDailyDiaryHseRecordInput => {
  const {
    __typename: _,
    creator: __,
    creatorId: ___,
    dateCreated: ____,
    dateModified: _____,
    attachments: ______,
    hseTypeLookupId,
    ...rest
  } = record;

  return {
    ...rest,
    attachments: [], // TODO
  };
};
