import { Box, css, styled } from "@mui/material";

export const StyledMenuItemContent = styled(Box)`
  display: flex;
  align-items: center;
  min-width: 150px;
`;

export const ContextMenuTrigger = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(
  ({ theme, active }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    cursor: pointer;

    ${active
      ? css`
          background-color: ${theme.palette.grey[100]};
        `
      : ""};
  `
);
