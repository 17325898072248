import {
  ChangeEarlyWarningItemOwnerMutation,
  ChangeEarlyWarningItemOwnerMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "./useGraphMutation";
import { changeEarlyWarningItemOwnerMutation } from "graphql/mutations/changeEarlyWarningOwner";
import { useTranslation } from "react-i18next";

export const useChangeEarlyWarningOwner = (onOwnerChange?: () => void) => {
  const { t } = useTranslation();

  const [changeEarlyWarningOwner, { loading }] = useGraphMutation<
    ChangeEarlyWarningItemOwnerMutation,
    ChangeEarlyWarningItemOwnerMutationVariables
  >(
    changeEarlyWarningItemOwnerMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "earlyWarnings" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "earlyWarning" });
        cache.gc();
        onOwnerChange?.();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.EarlyWarnings.earlyWarning"),
    })
  );

  return {
    changeEarlyWarningOwner,
    loading,
  };
};
