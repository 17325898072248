import { productQuery } from "containers/AdminConsole/containers/System/containers/Products/ProductDetails/ProductDetails.query";
import {
  ItemStatusCollectionStatus,
  ProductQuery,
  ProductQueryVariables,
  ProductSchemaStatus,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useEffect, useState } from "react";

export const useProductInstanceForm = (prodId: string = "") => {
  const [selectedProductId, setSelectedProductId] = useState(prodId);

  const [
    fetchProductData,
    { data: productData, loading: getProductDataLoading },
  ] = useGraphLazyQuery<ProductQuery, ProductQueryVariables>(productQuery);

  const handleSelectedProductChange = (productId: string) => {
    setSelectedProductId(productId);
  };

  useEffect(() => {
    if (selectedProductId) {
      fetchProductData({ variables: { id: selectedProductId } });
    }
  }, [fetchProductData, selectedProductId]);

  useEffect(() => {
    setSelectedProductId(prodId);
  }, [prodId]);

  return {
    handleSelectedProductChange,
    loading: getProductDataLoading,
    selectedProductLite: productData?.product,
    selectedProductSchemas:
      productData?.product.productSchemas.items.filter(
        (schema) => schema.status === ProductSchemaStatus.Active
      ) || [],
    selectedProductStatusCollections:
      productData?.product.statusCollections.items.filter(
        (statusCollection) =>
          statusCollection.status === ItemStatusCollectionStatus.Active
      ) || [],
  };
};
