import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const UsersIcon: React.FC<BaseIconProps> = ({
  height = "16",
  width = "16",
  color,
}) => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 13.811V11.3C6.5 10.3377 7.24625 9.581 8.35025 9.149C7.63827 8.88421 6.88462 8.74907 6.125 8.75C4.7075 8.75 3.398 9.21275 2.33975 9.995C2.66788 10.923 3.21882 11.7563 3.94419 12.4216C4.66956 13.0869 5.54718 13.564 6.5 13.811ZM13.16 11.0645C12.8637 10.6648 11.8775 10.25 10.625 10.25C9.1205 10.25 8 10.8477 8 11.3V14C9.04088 14.0006 10.064 13.7302 10.9687 13.2155C11.8734 12.7008 12.6286 11.9595 13.16 11.0645ZM6.1625 7.625C6.61005 7.625 7.03928 7.44721 7.35574 7.13074C7.67221 6.81427 7.85 6.38505 7.85 5.9375C7.85 5.48995 7.67221 5.06073 7.35574 4.74426C7.03928 4.42779 6.61005 4.25 6.1625 4.25C5.71495 4.25 5.28572 4.42779 4.96926 4.74426C4.65279 5.06073 4.475 5.48995 4.475 5.9375C4.475 6.38505 4.65279 6.81427 4.96926 7.13074C5.28572 7.44721 5.71495 7.625 6.1625 7.625ZM10.625 8.375C11.0228 8.375 11.4044 8.21696 11.6857 7.93566C11.967 7.65436 12.125 7.27282 12.125 6.875C12.125 6.47718 11.967 6.09564 11.6857 5.81434C11.4044 5.53304 11.0228 5.375 10.625 5.375C10.2272 5.375 9.84564 5.53304 9.56434 5.81434C9.28304 6.09564 9.125 6.47718 9.125 6.875C9.125 7.27282 9.28304 7.65436 9.56434 7.93566C9.84564 8.21696 10.2272 8.375 10.625 8.375ZM8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5Z"
        fill={color || theme.palette.primary.main}
      />
    </svg>
  );
};
