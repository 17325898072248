import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import {
  CompEventGrantingOfMoreTimePrompt,
  CompEventGrantingOfMoreTimePromptQuery,
  CompEventGrantingOfMoreTimePromptQueryVariables,
  RecordCompEventGrantingOfMoreTimeMutation,
  RecordCompEventGrantingOfMoreTimeMutationVariables,
  SendCompEventGrantingOfMoreTimeMutation,
  SendCompEventGrantingOfMoreTimeMutationVariables,
} from "generated/graphql";
import { recordCompEventGrantingOfMoreTimeMutation } from "graphql/mutations/recordCompEventGrantingOfMoreTime";
import { sendCompEventGrantingOfMoreTimeMutation } from "graphql/mutations/sendCompEventGrantingOfMoreTime";
import { compEventGrantingOfMoreTimePromptQuery } from "graphql/queries/compEventGrantingOfMoreTimePrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const useGrantMoreTimeToRespondCEAction = () => {
  const { onChange, compEvent } = useContext(CompEventWidgetContext);
  const { t } = useTranslation();

  const {
    data: grantMoreTimePromptData,
    loading: grantMoreTimePromptDataLoading,
  } = useGraphQuery<
    CompEventGrantingOfMoreTimePromptQuery,
    CompEventGrantingOfMoreTimePromptQueryVariables
  >(compEventGrantingOfMoreTimePromptQuery, {
    variables: {
      input: {
        compEventId: compEvent?.id!,
      },
    },
  });

  const [
    sendCompEventGrantingOfMoreTime,
    { loading: sendCompEventGrantingOfMoreTimeLoading },
  ] = useGraphMutation<
    SendCompEventGrantingOfMoreTimeMutation,
    SendCompEventGrantingOfMoreTimeMutationVariables
  >(
    sendCompEventGrantingOfMoreTimeMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventGrantingOfMoreTime"),
    })
  );

  const [
    recordCompEventGrantingOfMoreTime,
    { loading: recordCompEventGrantingOfMoreTimeLoading },
  ] = useGraphMutation<
    RecordCompEventGrantingOfMoreTimeMutation,
    RecordCompEventGrantingOfMoreTimeMutationVariables
  >(
    recordCompEventGrantingOfMoreTimeMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "compEventHistoryItems",
          args: { compEventId: compEvent!.id },
        });
        cache.gc();
        onChange?.();
      },
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventGrantingOfMoreTime"),
    })
  );

  return {
    loading: grantMoreTimePromptDataLoading,
    actionLoading:
      sendCompEventGrantingOfMoreTimeLoading ||
      recordCompEventGrantingOfMoreTimeLoading,
    grantMoreTimePromptData:
      grantMoreTimePromptData?.compEventGrantingOfMoreTimePrompt as
        | CompEventGrantingOfMoreTimePrompt
        | undefined,
    grantMoreTimePromptDataLoading,
    sendCompEventGrantingOfMoreTime,
    recordCompEventGrantingOfMoreTime,
  };
};
