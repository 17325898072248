import {
  CompanyLookupCollection,
  DailyDiaryDelayRecord,
} from "generated/graphql";
import { TFunction } from "i18next";
import { getUserName } from "helpers/miscelaneous";
import { timeStripMinutes } from "../../../utilts";

export const toRecordJSON = ({
  record,
  delayLookups,
  withAreaColumn,
  withDisciplineColumn,
  withTeamColumn,
  withShiftColumn,
  t,
}: {
  record: DailyDiaryDelayRecord;
  delayLookups: CompanyLookupCollection[];
  withShiftColumn: boolean;
  withAreaColumn: boolean;
  withDisciplineColumn: boolean;
  withTeamColumn: boolean;
  t: TFunction;
}) => {
  return {
    [t("common.labels.description")]: record.description,
    [t("Projects.DailyDiaries.Delay.delayType")]:
      delayLookups
        .find((lkp) => lkp.id === record.delayTypeLookupId)
        ?.options.items.find((option) => option.id === record.delayTypeOptionId)
        ?.value ?? "",
    ...(withShiftColumn && {
      [t("Projects.DailyDiaries.Delay.shift")]:
        delayLookups
          .find((lkp) => lkp.id === record.shiftLookupId)
          ?.options.items.find((option) => option.id === record.shiftOptionId)
          ?.value ?? "",
    }),
    ...(withAreaColumn && {
      [t("Projects.DailyDiaries.Delay.area")]:
        delayLookups
          .find((lkp) => lkp.id === record.areaLookupId)
          ?.options.items.find((option) => option.id === record.areaOptionId)
          ?.value ?? "",
    }),
    ...(withDisciplineColumn && {
      [t("Projects.DailyDiaries.Delay.discipline")]:
        delayLookups
          .find((lkp) => lkp.id === record.disciplineLookupId)
          ?.options.items.find(
            (option) => option.id === record.disciplineOptionId
          )?.value ?? "",
    }),
    ...(withTeamColumn && {
      [t("Projects.DailyDiaries.Delay.team")]:
        delayLookups
          .find((lkp) => lkp.id === record.teamLookupId)
          ?.options.items.find((option) => option.id === record.teamOptionId)
          ?.value ?? "",
    }),
    [t("Projects.DailyDiaries.Delay.duration")]: timeStripMinutes(
      record.duration
    ),
    [t("common.labels.createdBy")]: getUserName(record.creator),
  };
};
