import { Box, styled, css, Typography } from "@mui/material";
import { useState } from "react";
import { SeverityPickerItemIcon } from "./SeverityPickerItemIcon";

export type ConsequenceLikelihoodListItemProps = {
  itemSet: string[];
  value: number;
  label: string;
  selected?: boolean;
  onClick: () => void;
};

const ListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "highContrast",
})<{ highContrast?: boolean }>(
  ({ theme, highContrast }) => css`
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid ${theme.palette.grey[highContrast ? 800 : 200]};
    border-radius: 8px;
    cursor: pointer;
  `
);

export const ConsequenceLikelihoodListItem: React.FC<
  ConsequenceLikelihoodListItemProps
> = ({ itemSet, value, label, onClick, selected }) => {
  const [itemHovered, setItemHovered] = useState(false);

  return (
    <ListItem
      px={2}
      onMouseEnter={() => {
        setItemHovered(true);
      }}
      onMouseLeave={() => {
        setItemHovered(false);
      }}
      highContrast={selected || itemHovered}
      onClick={onClick}
      data-testid={`severity-picker-${label}`}
    >
      <SeverityPickerItemIcon
        itemSet={itemSet}
        value={value}
        height={16}
        width={16}
        step={2.5}
        highContrast={selected || itemHovered}
      />
      <Typography variant="p2" color="grey.800" ml={1.5}>
        {label}
      </Typography>
    </ListItem>
  );
};
