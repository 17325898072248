import { gql } from "@apollo/client";
import { instructionItemFields } from "graphql/fragments/instructionItem.fragment";

export const draftInstructionItemsQuery = gql`
  ${instructionItemFields}

  query draftInstructionItems($productInstanceId: ID!) {
    draftInstructionItems(productInstanceId: $productInstanceId) {
      items {
        ...InstructionItemFields
      }
    }
  }
`;
