import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationConfirmationPromptQuery = gql`
  query variationConfirmationPrompt($input: VariationConfirmationPromptInput!) {
    variationConfirmationPrompt(input: $input) {
      remarks
    }
  }
`;

export const sendVariationConfirmationMutation = gql`
  mutation sendVariationConfirmation($input: SendVariationConfirmationInput!) {
    sendVariationConfirmation(input: $input) {
      id
    }
  }
`;

export const recordVariationConfirmationMutation = gql`
  mutation recordVariationConfirmation(
    $input: RecordVariationConfirmationInput!
  ) {
    recordVariationConfirmation(input: $input) {
      id
    }
  }
`;

export const generateSendVariationConfirmationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationConfirmationPreview(
    $input: SendVariationConfirmationPreviewInput!
  ) {
    generateSendVariationConfirmationPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationConfirmationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationConfirmationPreview(
    $input: RecordVariationConfirmationPreviewInput!
  ) {
    generateRecordVariationConfirmationPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
