import { DailyDiaryPresetSection } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";
import { useMemo } from "react";
import { HSELookups } from "./HSERecordForm";

export const useHSERecordFormValidators = (
  section: DailyDiaryPresetSection,
  hseTypeOptionId: string,
  lookups: HSELookups
) => {
  const dataValidators: DataValidators = useMemo(() => {
    // HSEType
    const hseTypeField = section.fields.find(
      (field) => field.name === "HSEType"
    );

    const hseTypeColValidators: DataValidators | undefined =
      hseTypeField && !hseTypeField.isHidden
        ? {
            hseTypeOptionId: {
              validators: [
                hseTypeField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                hseTypeOptionId,
                lookups?.HSEType.options.items?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // Description
    const descriptionField = section.fields.find(
      (field) => field.name === "Description"
    );

    const descriptionColValidators: DataValidators | undefined =
      descriptionField &&
      !descriptionField.isHidden &&
      descriptionField.isRequired
        ? {
            description: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    return {
      ...descriptionColValidators,
      ...hseTypeColValidators,
    };
  }, [section, hseTypeOptionId, lookups]);

  return dataValidators;
};
