import { gql } from "@apollo/client";
import { draftVariationProposalFields } from "graphql/fragments/draftVariationProposal.fragment";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationProposalForVariationPromptQuery = gql`
  ${draftVariationProposalFields}

  query variationProposalForVariationPrompt(
    $input: VariationProposalForVariationPromptInput!
  ) {
    variationProposalForVariationPrompt(input: $input) {
      draftProposals {
        ...DraftVariationProposalFields
      }
    }
  }
`;

export const sendVariationProposalForVariationMutation = gql`
  mutation sendVariationProposalForVariation(
    $input: SendVariationProposalForVariationInput!
  ) {
    sendVariationProposalForVariation(input: $input) {
      id
    }
  }
`;

export const recordVariationProposalForVariationMutation = gql`
  mutation recordVariationProposalForVariation(
    $input: RecordVariationProposalForVariationInput!
  ) {
    recordVariationProposalForVariation(input: $input) {
      id
    }
  }
`;

export const generateSendVariationProposalForVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationProposalForVariationPreview(
    $input: SendVariationProposalForVariationPreviewInput!
  ) {
    generateSendVariationProposalForVariationPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationProposalForVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationProposalForVariationPreview(
    $input: RecordVariationProposalForVariationPreviewInput!
  ) {
    generateRecordVariationProposalForVariationPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
