import { gql } from "@apollo/client";

export const getProjectByIdLiteQuery = gql`
  query getProjectByIdLite($id: ID!) {
    project(id: $id) {
      id
      name
      friendlyName
    }
  }
`;
