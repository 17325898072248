import { useMemo } from "react";
import { useClaimDetailsExplorerItems } from "../../../hooks/useClaimDetailsExplorerItems/useClaimDetailsExplorerItems";

export const useFinalDetailedClaim = () => {
  const { detailedClaims, loading } = useClaimDetailsExplorerItems();

  const finalDetailedClaim = useMemo(() => {
    return detailedClaims.sort((detailedClaim1, detailedClaim2) => {
      const date1 = new Date(detailedClaim1.dateCreated as string).getTime();
      const date2 = new Date(detailedClaim2.dateCreated as string).getTime();

      return date2 - date1;
    })[0];
  }, [detailedClaims]);

  return {
    finalDetailedClaim,
    loading,
  };
};
