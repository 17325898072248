import {
  GridEventListener,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import { useCallback, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { exportToExcel } from "../../../../../helpers/exportToExcel";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { useColumns } from "./AuthorizationInitiatedByYouTable.constants";
import { computeRegardingColumnText } from "../Authorizations.utils";
import { excelDateTimeISOFormat } from "constants/constants";
import { getDatetimeOnTz } from "helpers/timezones";

export type AuthorizationInitiatedByYouTableProps = {
  audits: AuthorizationWorkflowAudit[];
  loading: boolean;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  hasMore?: boolean;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
  onLoadMore: () => void;
  onRowClick: (audit: AuthorizationWorkflowAudit) => void;
};

const auditsInitialBatchCount = 11;

export const AuthorizationInitiatedByYouTable: React.FC<
  AuthorizationInitiatedByYouTableProps
> = ({
  audits,
  loading,
  apiRef,
  selectionModel,
  hasMore,
  onLoadMore,
  onSelectionModelChange,
  onRowClick,
}) => {
  const { t } = useTranslation();

  const columns = useColumns();

  const gridApiRef = useGridApiRef();

  const handleExportToExcel = useCallback(async () => {
    if (!audits || !audits.length) {
      console.warn("No records to export");
      return;
    }

    const columns = [
      {
        header: t("AuthorizationWorkflows.action"),
        key: "action",
      },
      { header: t("common.labels.regarding"), key: "regarding" },
      {
        header: t("common.labels.project"),
        key: "project",
      },
      {
        header: t("common.labels.contract"),
        key: "contract",
      },
      {
        header: t("Home.Authorizations.initiated"),
        key: "initiated",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.status"),
        key: "status",
      },
    ];

    const rows = audits
      .filter((audit) => (selectionModel || []).indexOf(audit.id) >= 0)
      .map((audit) => ({
        ...audit,
        action: audit.action.productOutputAction.name,
        regarding: computeRegardingColumnText(audit),
        project: audit.action.productInstance.contract.project.friendlyName,
        contract: audit.action.productInstance.contract.friendlyName,
        initiated: getDatetimeOnTz(
          audit.dateInitiated,
          audit.regardingProductItem.productInstance.contract.timeZone
        ),
      }));

    exportToExcel(
      t("Home.Authorizations.authorizationsInitiatedByYou"),
      columns,
      rows
    );
  }, [audits, selectionModel, t]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (row: GridRowParams<AuthorizationWorkflowAudit>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        onRowClick(row.row);
      }
    },
    [onRowClick]
  );

  const handleClearSelection = useCallback(() => {
    onSelectionModelChange([]);
  }, [onSelectionModelChange]);

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    onSelectionModelChange(newSelectionModel);
  };

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  useEffect(() => {
    // fetching until there are at least auditsInitialBatchCount records to
    // enable scroll or until there are no more records to fetch
    if (audits.length <= auditsInitialBatchCount && !loading && hasMore) {
      onLoadMore();
    }
  }, [audits, loading, hasMore, onLoadMore]);

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={audits}
      columns={columns}
      getRowId={(rowData: AuthorizationWorkflowAudit) => rowData.id}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading}
      onRowClick={handleRowClick}
      onRowsScrollEnd={onLoadMore}
      scrollEndThreshold={1}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
