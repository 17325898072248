import { useEffect, useMemo } from "react";
import { ImportRecordsModal } from "../ImportRecordsModal/ImportRecordsModal";
import { useImportHSERecordsModal } from "./useImportHSERecordsModal";
import { useAvailableDiariesToImportFrom } from "./useAvailableDiariesToImportFrom";
import { ImportRecordsModalDiaryDetails } from "../ImportRecordsModal/ImportRecordsModalDiaryDetails";
import { useImportRecordsModal } from "../ImportRecordsModal/useImportRecordsModal";
import { HSETable } from "./HSETable/HSETable";
import { CompanyLookupCollection } from "generated/graphql";

type Props = {
  lookups: CompanyLookupCollection[];
  loading?: boolean;
  onImportSuccess: () => void;
} & Omit<
  React.ComponentProps<typeof ImportRecordsModal>,
  | "availableDates"
  | "onChange"
  | "children"
  | "onPrimaryClick"
  | "onSecondaryClick"
>;

export const ImportHSERecordsModal: React.FC<Props> = ({
  lookups,
  open,
  loading,
  onClose,
  onImportSuccess,
}) => {
  const {
    fetchAvailableDiaries,
    availableDiariesToImportFrom,
    availableDiariesToImportFromLoading,
  } = useAvailableDiariesToImportFrom();

  const {
    productInstanceId,
    crtDiaryDate,
    recordIdsToImport,
    availableDatesToImportFrom,
    selectedDateDiary,
    handleDateToImportFromChange,
    handleMonthChange,
    handleSelectionChange,
    fetchAvailableDatesToImportFrom,
    handleSecondaryClick,
  } = useImportRecordsModal(availableDiariesToImportFrom, onClose);

  const { hseRecords, hseRecordsLoading, importRecords, importLoading } =
    useImportHSERecordsModal(selectedDateDiary);

  const handleImportRecords = async () => {
    if (!recordIdsToImport.length) {
      return;
    }
    const { errors } = await importRecords({
      variables: {
        input: {
          destinationDate: crtDiaryDate!,
          destinationProductInstanceId: productInstanceId!,
          sourceDailyDiaryId: selectedDateDiary?.id!,
          sourceRecordIds: recordIdsToImport.map((id) => String(id)),
        },
      },
    });

    if (!errors) {
      onImportSuccess();
    }
  };

  const targetDiaryDetails = useMemo(() => {
    if (selectedDateDiary) {
      return <ImportRecordsModalDiaryDetails diary={selectedDateDiary} />;
    }
  }, [selectedDateDiary]);

  useEffect(() => {
    fetchAvailableDatesToImportFrom(fetchAvailableDiaries);
  }, [fetchAvailableDatesToImportFrom, fetchAvailableDiaries]);

  const recordsLoading = loading || hseRecordsLoading;

  return (
    <ImportRecordsModal
      availableDates={availableDatesToImportFrom}
      calendarLoading={availableDiariesToImportFromLoading}
      open={open}
      onPrimaryClick={handleImportRecords}
      primaryBtnDisabled={
        !recordIdsToImport.length || recordsLoading || importLoading
      }
      onSecondaryClick={handleSecondaryClick}
      onMonthChange={handleMonthChange}
      onClose={onClose}
      onChange={handleDateToImportFromChange}
      primaryBtnLoading={importLoading}
      targetDiaryDetails={targetDiaryDetails}
    >
      <HSETable
        records={hseRecords}
        hseLookupCollections={lookups}
        loading={recordsLoading}
        hideAttachments
        onSelectionChange={handleSelectionChange}
      />
    </ImportRecordsModal>
  );
};
