import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { authAuditDetailsLiteQuery } from "./useAuthAudit.query";
import {
  AuthAuditDetailsLiteQuery,
  AuthAuditDetailsLiteQueryVariables,
  AuthorizationWorkflowAudit,
} from "generated/graphql";

export const useAuthAuditLazy = () => {
  const [fetchAuthAudit, { data: authAudit, loading }] = useGraphLazyQueryLite<
    AuthAuditDetailsLiteQuery,
    AuthAuditDetailsLiteQueryVariables
  >(authAuditDetailsLiteQuery);

  return {
    authAudit: authAudit?.data
      .authorizationWorkflowAudit as AuthorizationWorkflowAudit,
    loading,
    fetchAuthAudit,
  };
};
