import { Box, css, styled } from "@mui/material";
import { mainNavigationHeight } from "components/MainNavigation/MainNavigation.styled";
import { customShadows } from "theme/extendedTheme";

export const ExplorerContainer = styled(Box)(
  ({ theme }) => css`
    box-shadow: ${customShadows.activeSmall};
    padding-left: ${theme.spacing(6)};
    padding-right: ${theme.spacing(6)};
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(3)};
    z-index: 1;
    position: sticky;
    top: ${mainNavigationHeight}px;
    background: white;
    z-index: 10;
  `
);
