import { gql } from "@apollo/client";

export const effectPresetQuery = gql`
  query effectPreset($id: ID!) {
    effectPreset(id: $id) {
      id
      name
      contractTypeId
      isDefault
      isInternal
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
      options {
        items {
          id
          label
          isInternal
          estimationType
          unit
          effectPresetId
          status
          creatorId
          creator {
            id
            firstname
            surname
          }
          dateCreated
        }
      }
    }
  }
`;
