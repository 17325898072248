import { Box, Typography } from "@mui/material";
import { dateISOFormat } from "constants/constants";
import { ChangeLogItem, EffectPreset } from "generated/graphql";
import moment from "moment";
import { useMemo } from "react";
import { ChangelogText } from "./ChangelogText";

export type ChangelogListItemProps = {
  changelog: ChangeLogItem;
  effectPreset: EffectPreset;
  contractTimezone: string;
};

export const ChangelogListItem: React.FC<ChangelogListItemProps> = ({
  changelog,
  effectPreset,
  contractTimezone,
}) => {
  const formattedTimestamp = useMemo(() => {
    const momentDateCreated = moment(changelog.dateCreated).tz(
      contractTimezone
    );
    const date = momentDateCreated.format(dateISOFormat);
    const time = momentDateCreated.format("HH:mm:ss");

    return `${date} ${time}`;
  }, [changelog, contractTimezone]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="p2" color="grey.600">
        {formattedTimestamp}
      </Typography>
      <ChangelogText changelog={changelog} mt={1} effectPreset={effectPreset} />
    </Box>
  );
};
