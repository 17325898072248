import {
  ClaimRegardingType,
  ClaimAction,
  ClaimActionQuery,
  ClaimActionQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { claimActionQuery } from "../queries/claimActionList.query";

export const useClaimWidgetNextStepSection = (
  regardingType: ClaimRegardingType,
  regardingId: string,
  productInstanceId?: string,
  claimId?: string
) => {
  const { data: claimActionList, loading: claimActionListLoading } =
    useGraphQuery<ClaimActionQuery, ClaimActionQueryVariables>(
      claimActionQuery,
      {
        variables: {
          input: {
            productInstanceId: claimId ? undefined : productInstanceId,
            claimId,
            regardingType,
            regardingId,
          },
        },
        skip: !productInstanceId && !claimId,
      }
    );

  return {
    claimActionList: (claimActionList?.claimAction.items ??
      []) as ClaimAction[],
    loading: claimActionListLoading,
  };
};
