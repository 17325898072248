import {
  Box,
  SxProps,
  Tab,
  Tabs,
  Theme,
  ThemeProvider,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { SyntheticEvent } from "react";
import { extendedTheme } from "theme/extendedTheme";
import {
  overridenTabComponentStyles,
  StyledChip,
} from "./TabsWithCount.styled";

export type TabWithCount = {
  id: string;
  label: string;
  count: number;
  alignRight?: boolean;
  tabSx?: SxProps<Theme>;
  renderer?: (params: {
    tab: TabWithCount;
    selectedTabId: string | false;
  }) => React.ReactNode;
};

export type TabsWithCountProps = {
  tabs: TabWithCount[];
  selectedTabId: string;
  onSelectedTabChange: (newSelectedTabId: string) => void;
};

export const TabLabel: React.FC<
  { selectedTabId: string | false; tab: TabWithCount } & TypographyProps
> = ({ selectedTabId, tab, ...restTypographyProps }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="p2"
      color={theme.palette.grey[selectedTabId === tab.id ? 900 : 600]}
      fontWeight={selectedTabId === tab.id ? 700 : 400}
      textTransform="none"
      mr={1}
      {...restTypographyProps}
    >
      {tab.label}
    </Typography>
  );
};

export const TabCountChip: React.FC<{
  selectedTabId: string | false;
  tab: TabWithCount;
}> = ({ selectedTabId, tab }) => {
  const theme = useTheme();

  return (
    <StyledChip
      selected={selectedTabId === tab.id}
      sx={{ cursor: "pointer" }}
      label={
        <Typography
          fontWeight={700}
          fontSize="10px"
          lineHeight="14px"
          color={selectedTabId === tab.id ? "white" : theme.palette.grey[700]}
        >
          {tab.count}
        </Typography>
      }
      size="small"
    />
  );
};

export const TabsWithCount: React.FC<TabsWithCountProps> = ({
  tabs,
  selectedTabId,
  onSelectedTabChange,
}) => {
  const handleTabChange = (
    _: SyntheticEvent<Element, Event>,
    value: string
  ) => {
    onSelectedTabChange(value);
  };

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
        ...overridenTabComponentStyles,
      })}
    >
      <Tabs
        value={selectedTabId}
        onChange={handleTabChange}
        aria-label="product items tabs"
        sx={{ height: "48px", flex: "1" }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            value={tab.id}
            data-testid={`${tab.label}-tab`}
            sx={{
              marginLeft: tab.alignRight ? "auto !important" : "inherit",
              ...tab.tabSx,
            }}
            label={
              tab.renderer ? (
                tab.renderer({ tab, selectedTabId })
              ) : (
                <>
                  <Box>
                    <TabLabel tab={tab} selectedTabId={selectedTabId} />
                    <TabCountChip selectedTabId={selectedTabId} tab={tab} />
                  </Box>
                </>
              )
            }
          />
        ))}
      </Tabs>
    </ThemeProvider>
  );
};
