import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationErrorAgreementNoticePreviewMutation,
  GenerateRecordVariationErrorAgreementNoticePreviewMutationVariables,
  RecordVariationErrorAgreementNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { generateRecordVariationErrorAgreementNoticePreviewMutation } from "./NotifyErrorFoundInAgreement.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useRecordErrorFoundInAgreementNoticeModal = (details: string) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [preview, setPreview] =
    useState<RecordVariationErrorAgreementNoticePreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationErrorAgreementNoticePreviewMutation,
    GenerateRecordVariationErrorAgreementNoticePreviewMutationVariables
  >(generateRecordVariationErrorAgreementNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            details,
          },
        },
      });

      if (data) {
        setPreview(data.generateRecordVariationErrorAgreementNoticePreview);
      }
    }, [generatePreview, variation, details]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview: preview,
    contract,
  };
};
