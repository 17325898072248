import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

export const NoRowsOverlay: React.FC<{
  recordName?: string;
  title?: string;
  hideSubtitle?: boolean;
}> = ({ recordName, title, hideSubtitle }) => {
  const { t } = useTranslation();

  const transComponentsConfig = useMemo(
    () => ({
      bold: <Typography variant="p2" fontWeight={600} />,
    }),
    []
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      pt={4}
    >
      <Typography variant="p2" fontWeight={600} color="grey.800" mb={0.5}>
        {title ?? t("Projects.DailyDiaries.noRecordsLine1")}
      </Typography>
      {!hideSubtitle && (
        <Box>
          <Trans
            i18nKey="Projects.DailyDiaries.noRecordsLine2"
            values={{
              recordName,
            }}
            defaults="To add record, click <bold>+ Work</bold> button or <bold>Import</bold> from the past daily diary."
            components={transComponentsConfig}
          />
        </Box>
      )}
    </Box>
  );
};
