import {
  LookupCollection,
  LookupCollectionsQuery,
  LookupCollectionsQueryVariables,
} from "generated/graphql";
import { lookupCollectionsQuery } from "graphql/queries/lookupCollections.query";
import { useGraphQuery } from "./useGraphQuery";

export const useSimpleLookups = () => {
  const {
    data: simpleLookupCollections,
    refetch: refetchSimpleLookupCollections,
    loading: simpleLookupCollectionsLoading,
  } = useGraphQuery<LookupCollectionsQuery, LookupCollectionsQueryVariables>(
    lookupCollectionsQuery
  );

  return {
    simpleLookupCollections: (simpleLookupCollections?.lookupCollections
      .items ?? []) as LookupCollection[],
    loading: simpleLookupCollectionsLoading,
    refetchSimpleLookupCollections,
  };
};
