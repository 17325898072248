import { PlaceholderListItemType } from "./components/Placeholders/PlaceholderListItem";

export const placeholdersList: PlaceholderListItemType[] = [
  {
    label: "Your company logo",
    value: "UserCompanyLogo",
  },
  {
    label: "Originating party description",
    value: "OriginatingParty.Description",
  },
  {
    label: "Originating party representative",
    value: "OriginatingParty.Representative",
  },
  {
    label: "Originating party company name",
    value: "OriginatingParty.CompanyName",
  },
  {
    label: "Originating party company address",
    value: "OriginatingParty.CompanyAddress",
  },
  {
    label: "Target party description",
    value: "TargetParty.Description",
  },
  {
    label: "Target party representative",
    value: "TargetParty.Representative",
  },
  {
    label: "Target party company name",
    value: "TargetParty.CompanyName",
  },
  {
    label: "Target party company address",
    value: "TargetParty.CompanyAddress",
  },
  {
    label: "Reference number",
    value: "ReferenceNumber",
  },
  {
    label: "Project name",
    value: "Project.Name",
  },
  {
    label: "Project friendly name",
    value: "Project.FriendlyName",
  },
  {
    label: "Contract name",
    value: "Contract.Name",
  },
  {
    label: "Contract friendly name",
    value: "Contract.FriendlyName",
  },
  {
    label: "Contract number",
    value: "Contract.Number",
  },
  {
    label: "Effect",
    value: "Effect",
  },
];

export const mockedPlaceholdersData = {
  UserCompanyLogo: "Mocked company logo",
  OriginatingParty: {
    Description: "Mocked Originating party description",
    Representative: "Mocked Originating party representative",
    CompanyName: "Mocked Originating party company name",
    CompanyAddress: "Mocked Originating party company address",
  },
  TargetParty: {
    Description: "Mocked Target party description",
    Representative: "Mocked Target party representative",
    CompanyName: "Mocked Target party company name",
    CompanyAddress: "Mocked Target party company address",
  },
  ReferenceNumber: "Mocked Reference number",
  Project: {
    Name: "Mocked Project name",
    FriendlyName: "Mocked Project friendly name",
  },
  Contract: {
    Name: "Mocked Contract name",
    FriendlyName: "Mocked Contract friendly name",
    Number: "Mocked Contract number",
  },
  Effect: "Mocked Effect",
};
