import { OnMutationDone } from "types/decl";
import {
  AddItemStatusOptionReasonInput,
  AddItemStatusOptionReasonMutation,
  AddItemStatusOptionReasonMutationVariables,
  ChangeItemStatusOptionReasonStatusMutation,
  ChangeItemStatusOptionReasonStatusMutationVariables,
  EditItemStatusOptionReasonInput,
  EditItemStatusOptionReasonMutation,
  EditItemStatusOptionReasonMutationVariables,
  ItemStatusOptionReason,
  ItemStatusOptionReasonStatus,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  addItemStatusOptionReasonMutation,
  changeItemStatusOptionReasonStatusMutation,
  editItemStatusOptionReasonMutation,
} from "./OptionChangeReasons.query";

export type useStatusCollectionOptionsProps = {
  onStatusOptionReasonAdded: OnMutationDone;
  onStatusOptionReasonUpdated: OnMutationDone;
  onStatusOptionReasonStatusChange: OnMutationDone;
};

export const useOptionChangeReasons = ({
  onStatusOptionReasonAdded,
  onStatusOptionReasonStatusChange,
  onStatusOptionReasonUpdated,
}: useStatusCollectionOptionsProps) => {
  const { t } = useTranslation();

  const [addOptionReason, { loading: addOptionReasonLoading }] =
    useGraphMutation<
      AddItemStatusOptionReasonMutation,
      AddItemStatusOptionReasonMutationVariables
    >(
      addItemStatusOptionReasonMutation,
      {
        update: onStatusOptionReasonAdded,
      },
      t("common.successMessages.entityCreated", {
        entity: t("AdminConsole.Products.labels.optionReason"),
      })
    );

  const [editOptionReason, { loading: editOptionReasonLoading }] =
    useGraphMutation<
      EditItemStatusOptionReasonMutation,
      EditItemStatusOptionReasonMutationVariables
    >(
      editItemStatusOptionReasonMutation,
      {
        update: onStatusOptionReasonUpdated,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("AdminConsole.Products.labels.optionReason"),
      })
    );

  const [
    changeOptionReasonStatus,
    { loading: changeOptionReasonStatusLoading },
  ] = useGraphMutation<
    ChangeItemStatusOptionReasonStatusMutation,
    ChangeItemStatusOptionReasonStatusMutationVariables
  >(
    changeItemStatusOptionReasonStatusMutation,
    {
      update: onStatusOptionReasonStatusChange,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Products.labels.optionReason"),
    })
  );

  const handleAddOptionReason = useCallback(
    async (optionReason: AddItemStatusOptionReasonInput) => {
      await addOptionReason({
        variables: {
          input: optionReason,
        },
      });
    },
    [addOptionReason]
  );

  const handleEditOptionReason = useCallback(
    async (optionReason: EditItemStatusOptionReasonInput) => {
      await editOptionReason({
        variables: {
          input: optionReason,
        },
      });
    },
    [editOptionReason]
  );

  const handleStatusOptionReasonStatusChange = useCallback(
    async (
      row: ItemStatusOptionReason,
      newStatus: ItemStatusOptionReasonStatus
    ) => {
      await changeOptionReasonStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeOptionReasonStatus]
  );

  return {
    addStatusOptionReason: handleAddOptionReason,
    addOptionReasonLoading,
    editStatusOptionReason: handleEditOptionReason,
    editOptionReasonLoading,
    changeStatusOptionReasonStatus: handleStatusOptionReasonStatusChange,
    changeOptionReasonStatusLoading,
  };
};
