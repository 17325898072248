import { AuthorizeDenyModalWrapper } from "./AuthorizeDenyModalWrapper";
import { AuditTrailModalWrapper } from "./AuditTrailModalWrapper";
import { useCallback, useContext, useEffect, useState } from "react";
import { useAuthStore } from "state-management/store/authStore";
import { useAuthAuditLazy } from "containers/Home/components/Authorizations/AuthorizeDenyActionView/hooks/useAuthAudit/useAuthAuditLazy";
import {
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowAuditStatus,
} from "generated/graphql";
import { useGlobalStore } from "state-management/store/globalStore";
import { isLoggedInUserReviewer } from "containers/Home/components/Authorizations/Authorizations.utils";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { AuthorizationInvalidModal } from "./AuthorizationInvalidModal";

const useGlobalAuthorizationsModals = () => {
  const { authenticatedUser } = useContext(GlobalContext);
  const [canUserReviewCrtLevel, setCanUserReviewCrtLevel] =
    useState<boolean>(false);
  const [isUserReviewer, setIsUserReviewer] = useState<boolean>(false);
  const [authAuditFetched, setAuthAuditFetched] = useState(false);
  const [isAuthAuditValid, setIsAuthAuditValid] = useState<boolean>(true);

  const setLoading = useGlobalStore((state) => state.setLoading);
  const { authModalState, setAuthModalState } = useAuthStore((state) => ({
    authModalState: state.authModalState,
    setAuthModalState: state.setAuthModalState,
  }));

  const { fetchAuthAudit: doFetchAuthAudit } = useAuthAuditLazy();

  const fetchAuthAudit = useCallback(
    async (authAuditId: string) => {
      setLoading(true);
      const { data } = await doFetchAuthAudit({ id: authAuditId });
      setLoading(false);
      setAuthAuditFetched(true);
      if (data && data.authorizationWorkflowAudit && authenticatedUser) {
        const { isUserReviewer, canUserReviewCrtLevel } =
          isLoggedInUserReviewer(
            data.authorizationWorkflowAudit as AuthorizationWorkflowAudit,
            undefined,
            authenticatedUser
          );
        setIsUserReviewer(isUserReviewer);
        setCanUserReviewCrtLevel(canUserReviewCrtLevel);
        setIsAuthAuditValid(
          data.authorizationWorkflowAudit.status !==
            AuthorizationWorkflowAuditStatus.Removed
        );
      }
    },
    [authenticatedUser, doFetchAuthAudit, setLoading]
  );

  useEffect(() => {
    if (authModalState && authModalState.type === "Any") {
      setAuthAuditFetched(false);
      setIsAuthAuditValid(true);
      fetchAuthAudit(authModalState.authAuditId);
    }
  }, [authModalState, fetchAuthAudit]);

  useEffect(() => {
    if (
      authModalState &&
      authModalState.type === "Any" &&
      authAuditFetched &&
      isAuthAuditValid
    ) {
      if (canUserReviewCrtLevel) {
        setAuthModalState({
          ...authModalState,
          type: "AuthorizeDeny",
        });
        setCanUserReviewCrtLevel(false);
        setIsUserReviewer(false);
      } else if (isUserReviewer || authModalState.bypassUserAuthorizer) {
        setAuthModalState({
          ...authModalState,
          type: "AuditTrail",
        });
        setCanUserReviewCrtLevel(false);
        setIsUserReviewer(false);
      }
    }
  }, [
    authModalState,
    setAuthModalState,
    authAuditFetched,
    isUserReviewer,
    canUserReviewCrtLevel,
    isAuthAuditValid,
  ]);

  const clearState = () => {
    setAuthModalState(undefined);
    setAuthAuditFetched(false);
    setIsAuthAuditValid(true);
    setCanUserReviewCrtLevel(false);
    setIsUserReviewer(false);
  };

  return {
    isAuthAuditValid,
    clearState,
  };
};

export const GlobalAuthorizationsModals: React.FC = () => {
  const { isAuthAuditValid, clearState } = useGlobalAuthorizationsModals();

  return (
    <>
      <AuthorizationInvalidModal
        open={!isAuthAuditValid}
        onClose={clearState}
      />
      <AuthorizeDenyModalWrapper />
      <AuditTrailModalWrapper />
    </>
  );
};
