import {
  DailyDiaryGeneralImportSourceItemsQuery,
  DailyDiaryGeneralImportSourceItemsQueryVariables,
  DailyDiaryItem,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { dailyDiaryGeneralImportSourceItemsQuery } from "./GeneralSection.query";

export const useAvailableDiariesToImportFrom = () => {
  const [
    fetchAvailableDiaries,
    { data: availableDiariesToImportFrom, loading },
  ] = useGraphLazyQueryLite<
    DailyDiaryGeneralImportSourceItemsQuery,
    DailyDiaryGeneralImportSourceItemsQueryVariables
  >(dailyDiaryGeneralImportSourceItemsQuery);

  return {
    availableDiariesToImportFrom: (availableDiariesToImportFrom?.data
      .dailyDiaryGeneralImportSourceItems.items ?? []) as DailyDiaryItem[],
    availableDiariesToImportFromLoading: loading,
    fetchAvailableDiaries,
  };
};
