import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventRejectionMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventRejection($input: RecordCompEventRejectionInput!) {
    recordCompEventRejection(input: $input) {
      ...CompEventItemFields
    }
  }
`;
