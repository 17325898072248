import { OnMutationDone } from "types/decl";
import {
  AddDocumentTemplateRouteInput,
  AddDocumentTemplateRouteMutation,
  AddDocumentTemplateRouteMutationVariables,
  EditDocumentTemplateRouteInput,
  EditDocumentTemplateRouteMutation,
  EditDocumentTemplateRouteMutationVariables,
  RemoveDocumentTemplateRouteMutation,
  RemoveDocumentTemplateRouteMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  addDocumentTemplateRouteMutation,
  editDocumentTemplateRouteMutation,
  removeDocumentTemplateRouteMutation,
} from "./DocumentTemplateRoutes.query";

export type useDocumentTemplateRoutesArgs = {
  onAdd: OnMutationDone;
  onEdit: OnMutationDone;
  onRemove: OnMutationDone;
};

export const useDocumentTemplateRoutes = ({
  onAdd,
  onEdit,
  onRemove,
}: useDocumentTemplateRoutesArgs) => {
  const { t } = useTranslation();
  const entityName = t("AdminConsole.Templates.labels.documentTemplateRoute");

  const [
    doAddDocumentTemplateRoute,
    { loading: addDocumentTemplateRouteLoading },
  ] = useGraphMutation<
    AddDocumentTemplateRouteMutation,
    AddDocumentTemplateRouteMutationVariables
  >(
    addDocumentTemplateRouteMutation,
    {
      update: onAdd,
    },
    t("common.successMessages.entityCreated", {
      entity: entityName,
    })
  );

  const [
    doEditDocumentTemplateRoute,
    { loading: editDocumentTemplateRouteLoading },
  ] = useGraphMutation<
    EditDocumentTemplateRouteMutation,
    EditDocumentTemplateRouteMutationVariables
  >(
    editDocumentTemplateRouteMutation,
    {
      update: onEdit,
    },
    t("common.successMessages.entityUpdated", {
      entity: entityName,
    })
  );

  const [
    doRemoveDocumentTemplateRoute,
    { loading: removeDocumentTemplateRouteLoading },
  ] = useGraphMutation<
    RemoveDocumentTemplateRouteMutation,
    RemoveDocumentTemplateRouteMutationVariables
  >(
    removeDocumentTemplateRouteMutation,
    {
      update: onRemove,
    },
    t("common.successMessages.entityDeleted", {
      entity: entityName,
    })
  );

  const addDocumentTemplateRoute = useCallback(
    (input: AddDocumentTemplateRouteInput) => {
      return doAddDocumentTemplateRoute({ variables: { input } });
    },
    [doAddDocumentTemplateRoute]
  );

  const editDocumentTemplateRoute = useCallback(
    (input: EditDocumentTemplateRouteInput) => {
      return doEditDocumentTemplateRoute({ variables: { input } });
    },
    [doEditDocumentTemplateRoute]
  );

  const removeDocumentTemplateRoute = useCallback(
    (routeId: string) => {
      return doRemoveDocumentTemplateRoute({ variables: { id: routeId } });
    },
    [doRemoveDocumentTemplateRoute]
  );

  return {
    addDocumentTemplateRoute,
    addDocumentTemplateRouteLoading,
    editDocumentTemplateRoute,
    editDocumentTemplateRouteLoading,
    removeDocumentTemplateRoute,
    removeDocumentTemplateRouteLoading,
  };
};
