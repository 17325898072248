import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const KeyboardArrowRightIcon: React.FC<BaseIconProps> = ({
  height = "18",
  width = "10",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[600];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33203 1.49902L8.83354 9.00053L1.33203 16.502"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
