import {
  ChangeLookupCollectionStatusMutation,
  ChangeLookupCollectionStatusMutationVariables,
  EditLookupCollectionMutation,
  EditLookupCollectionMutationVariables,
  LookupCollectionQuery,
  LookupCollectionQueryVariables,
} from "generated/graphql";
import { changeLookupCollectionStatusMutation } from "graphql/mutations/changeLookupCollectionStatus";
import { editLookupCollectionMutation } from "graphql/mutations/editLookupCollection";
import { lookupCollectionQuery } from "graphql/queries/lookupCollection.query";
import { useTranslation } from "react-i18next";
import { useGraphMutation } from "./useGraphMutation";
import { useGraphQuery } from "./useGraphQuery";

export const useLookupCollection = (lookupCollectionId: string) => {
  const { t } = useTranslation();

  const {
    data: lookupCollection,
    refetch: refetchLookupCollection,
    loading: lookupCollectionLoading,
  } = useGraphQuery<LookupCollectionQuery, LookupCollectionQueryVariables>(
    lookupCollectionQuery,
    { variables: { id: lookupCollectionId } }
  );

  const [editLookupCollection, { loading: editLookupCollectionLoading }] =
    useGraphMutation<
      EditLookupCollectionMutation,
      EditLookupCollectionMutationVariables
    >(
      editLookupCollectionMutation,
      {
        update: (cache) => {
          refetchLookupCollection();

          cache.evict({ id: "ROOT_QUERY", fieldName: "lookupCollections" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("AdminConsole.Lookups.lookupCollection"),
      })
    );

  const [
    changeLookupCollectionStatus,
    { loading: changeLookupCollectionStatusLoading },
  ] = useGraphMutation<
    ChangeLookupCollectionStatusMutation,
    ChangeLookupCollectionStatusMutationVariables
  >(
    changeLookupCollectionStatusMutation,
    {
      update: () => refetchLookupCollection(),
    },
    t("common.successMessages.entityStatusUpdated", {
      entity: t("AdminConsole.Lookups.lookupCollection"),
    })
  );

  return {
    lookupCollection,
    refetchLookupCollection,
    editLookupCollection,
    editLookupCollectionLoading,
    changeLookupCollectionStatus,
    changeLookupCollectionStatusLoading,
    lookupCollectionLoading,
  };
};
