import { Box } from "@mui/material";

export type SeverityColumnColor = {
  highContrastColor: string;
  lowContrastColor: string;
};

export type SeverityPickerItemIconProps = {
  itemSet: string[];
  value: number;
  height: number;
  width: number;
  step: number;
  highContrast?: boolean;
  filledColor?: SeverityColumnColor;
  unfilledColor?: SeverityColumnColor;
};

export const SeverityPickerItemIcon: React.FC<SeverityPickerItemIconProps> = ({
  itemSet,
  height,
  width,
  step,
  value,
  highContrast,
  filledColor,
  unfilledColor,
}) => {
  const computeBarColor = (index: number) => {
    if (index + 1 <= value) {
      if (highContrast) {
        if (filledColor) {
          return filledColor.highContrastColor;
        } else {
          return "grey.800";
        }
      } else {
        if (filledColor) {
          return filledColor.lowContrastColor;
        } else {
          return "grey.300";
        }
      }
    } else {
      if (highContrast) {
        if (unfilledColor) {
          return unfilledColor.highContrastColor;
        } else {
          return "grey.400";
        }
      } else {
        if (unfilledColor) {
          return unfilledColor.lowContrastColor;
        } else {
          return "grey.100";
        }
      }
    }
  };

  const defaultMargin = Math.floor(height / 10);
  const barWidth = Math.floor(
    (width - (itemSet.length - 1) * defaultMargin) / itemSet.length
  );

  return (
    <Box display="flex" alignItems="flex-end" width={width}>
      {itemSet.map((item, index) => {
        const boxHeight = height - (itemSet.length - index) * step;

        return (
          <Box
            key={item}
            sx={{
              height: boxHeight,
              borderRadius: "16px",
              backgroundColor: computeBarColor(index),
              width: `${barWidth}px`,
              marginRight:
                index === itemSet.length - 1 ? 0 : `${defaultMargin}px`,
            }}
          ></Box>
        );
      })}
    </Box>
  );
};
