import { Box, Button, Typography } from "@mui/material";
import { Plus } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { VariationItemTypes } from "../Variations/VariationActionModal/components/VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";
import { ClaimItemsTypes } from "../Claims/ClaimActionModal/components/DetailedClaimsAgreementsDeterminationsTable/DetailedClaimsAgreementsDeterminationsTable";

export type TableHeaderProps = {
  type: "Quotation" | "Assessment" | ClaimItemsTypes | VariationItemTypes;
  disabled?: boolean;
  isFIDIC99RedYellow?: boolean;
  onAdd: () => void;
};

export const TableHeader: React.FC<TableHeaderProps> = ({
  type,
  disabled,
  isFIDIC99RedYellow,
  onAdd,
}) => {
  const { t } = useTranslation();

  const title =
    type === "DetailedClaim"
      ? t(`Projects.Claims.detailedClaims${isFIDIC99RedYellow ? "99RY" : ""}`)
      : type === "ClaimAgreement"
      ? t("Projects.Claims.claimAgreements")
      : type === "ClaimDetermination"
      ? t("Projects.Claims.claimDeterminations")
      : type === "VariationProposal"
      ? t("Projects.Variations.variationProposals")
      : type === "VariationAgreement"
      ? t("Projects.Variations.variationAgreements")
      : type === "VariationDetermination"
      ? t("Projects.Variations.variationDeterminations")
      : t(
          `Projects.CompEvents.${
            type === "Quotation" ? "quotations" : "assessments"
          }`
        );

  const newBtnDataTestId =
    type === "Quotation"
      ? "new-quotation-btn"
      : type === "Assessment"
      ? "new-assessment-btn"
      : type === "DetailedClaim"
      ? "new-detailedClaim-btn"
      : type === "ClaimAgreement"
      ? "new-claimAgreement-btn"
      : type === "ClaimDetermination"
      ? "new-claimDetermination-btn"
      : type === "VariationProposal"
      ? "new-VO-proposal-btn"
      : type === "VariationAgreement"
      ? "new-VO-agreement-btn"
      : "new-VO-determination-btn";

  const entity =
    type === "DetailedClaim"
      ? t(`Projects.Claims.newDetailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`)
      : type === "ClaimAgreement"
      ? t(
          `Projects.Claims.newClaimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
        )
      : type === "ClaimDetermination"
      ? t(
          `Projects.Claims.newClaimDetermination${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        )
      : type === "VariationProposal"
      ? t("Projects.Variations.newProposal")
      : type === "VariationAgreement"
      ? t("Projects.Variations.newAgreement")
      : type === "VariationDetermination"
      ? t("Projects.Variations.newDetermination")
      : t(
          `Projects.CompEvents.${
            type === "Quotation" ? "newQuotation" : "newAssessment"
          }`
        );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Typography
        variant="h3"
        fontSize="18px"
        fontWeight={600}
        color="grey.800"
      >
        {title}
      </Typography>
      <Button
        variant="outlined"
        onClick={onAdd}
        data-testid={newBtnDataTestId}
        disabled={disabled}
      >
        <Plus />
        <Typography
          variant="p2"
          color={disabled ? "grey.400" : "grey.800"}
          fontWeight={700}
          ml={1}
        >
          {entity}
        </Typography>
      </Button>
    </Box>
  );
};
