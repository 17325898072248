import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventNoticeOfSilenceCompEventNoticePreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventNoticeOfSilenceCompEventNoticePreview(
    $input: RecordCompEventNoticeOfSilenceCompEventNoticePreviewInput!
  ) {
    generateRecordCompEventNoticeOfSilenceCompEventNoticePreview(
      input: $input
    ) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
