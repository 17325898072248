import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateRecordCompEventQuotationPreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateRecordCompEventQuotationPreview(
    $input: RecordCompEventQuotationPreviewInput!
  ) {
    generateRecordCompEventQuotationPreview(input: $input) {
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
