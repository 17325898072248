import {
  ProductInstanceSchemaQuery,
  ProductInstanceSchemaQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "./useGraphQuery";
import { productInstanceSchemaQuery } from "graphql/queries/productInstanceSchema.query";

export const useProductInstanceSchema = (productInstanceId: string) => {
  const {
    data: productInstanceSchemaData,
    loading: getProductInstanceSchemaDataLoading,
  } = useGraphQuery<
    ProductInstanceSchemaQuery,
    ProductInstanceSchemaQueryVariables
  >(productInstanceSchemaQuery, {
    variables: { id: productInstanceId },
    fetchPolicy: "cache-first",
  });

  return {
    productInstanceData: productInstanceSchemaData?.productInstance,
    loading: getProductInstanceSchemaDataLoading,
  };
};
