import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventOwnAssessmentMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventOwnAssessment(
    $input: SendCompEventOwnAssessmentInput!
  ) {
    sendCompEventOwnAssessment(input: $input) {
      ...CompEventItemFields
    }
  }
`;
