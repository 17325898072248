import { Box } from "@mui/material";
import {
  CompanyForm,
  CompanyFormPublicApi,
} from "../../components/CompanyForm/CompanyForm";
import { useRef, useState, useCallback } from "react";
import {
  AddCompanyMutation,
  AddCompanyMutationVariables,
  AddCompanyInput,
  Company,
} from "generated/graphql";
import { addCompanyMutation } from "./NewCompany.query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageContentContainer } from "components/PageContentContainer";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  NewEntityHeader,
  NewEntityHeaderFontSize,
  HeaderPublicApi,
} from "components/NewEntityHeader";
import { PageContentHeaderSize } from "components/PageContentHeader/PageContentHeader";

export const NewCompany = () => {
  const { t } = useTranslation();
  const [companyData, setCompanyData] = useState<AddCompanyInput>();
  const ref = useRef<CompanyFormPublicApi>(null);
  const headerRef = useRef<HeaderPublicApi>(null);
  const navigate = useNavigate();

  const [addNewCompany, { loading: addNewCompanyLoading }] = useGraphMutation<
    AddCompanyMutation,
    AddCompanyMutationVariables
  >(
    addCompanyMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "companies" });
        cache.gc();
      },
    },
    t("common.successMessages.entityCreated", {
      entity: t("common.labels.company"),
    })
  );

  const handleAddCompany = async () => {
    if (!companyData) {
      return;
    }

    if (ref.current?.validate()) {
      const cleansedCompanyData = {
        ...companyData,
        type: companyData.type || undefined,
      };

      const { errors } = await addNewCompany({
        variables: {
          input: cleansedCompanyData!,
        },
      });

      if (!errors) {
        navigate(-1);
      }
    }
  };

  const handleLastFormFieldTabPress = useCallback(() => {
    headerRef.current?.focusMainActionBtn();
  }, []);

  const handleFormChange = useCallback((data: AddCompanyInput | Company) => {
    setCompanyData(data as AddCompanyInput);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      justifyContent="revert"
      alignItems="revert"
    >
      <NewEntityHeader
        entityName={t("common.labels.company").toLowerCase()}
        onAdd={handleAddCompany}
        size={PageContentHeaderSize.Normal}
        fontSize={NewEntityHeaderFontSize.Large}
        addActionLoading={addNewCompanyLoading}
        generalLoading={addNewCompanyLoading}
        apiRef={headerRef}
      />
      <PageContentContainer>
        <CompanyForm
          onChange={handleFormChange}
          ref={ref}
          disabled={addNewCompanyLoading}
          onLastFieldTabPressed={handleLastFormFieldTabPress}
        />
      </PageContentContainer>
    </Box>
  );
};
