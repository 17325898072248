import { ProductUserRole, ProductUserRoleStatus } from "generated/graphql";
import { temporaryRowId } from "constants/constants";
import { LocalProductUserRole, LocalProductUserRoleLite } from "./UserRoles";

export const sortRoles = (rows: LocalProductUserRole[]) => {
  return rows.sort((role1, role2) => {
    return role1.project?.name! < role2.project?.name!
      ? -1
      : role1.project?.name! > role2.project?.name!
      ? 1
      : role1.contract?.name! < role2.contract?.name!
      ? -1
      : role1.contract?.name! > role2.contract?.name!
      ? 1
      : role1.product?.name! < role2.product?.name!
      ? -1
      : role1.product?.name! > role2.product?.name!
      ? 1
      : role1.productInstance!.name! < role2.productInstance!.name!
      ? -1
      : role1.productInstance!.name! > role2.productInstance!.name!
      ? 1
      : 0;
  });
};

export const rowsContainTemporaryRecord = (
  rows: LocalProductUserRole[]
): boolean => {
  return !!rows.find((row) => row.id === temporaryRowId);
};

export const productUserRolesToLocalProductUserRoles = (
  userRoles: ProductUserRole[]
): LocalProductUserRole[] => {
  return userRoles.map((userRole) => {
    return {
      id: userRole.id,
      project: {
        id: userRole.productInstance.contract.project.id,
        name: userRole.productInstance.contract.project.friendlyName,
      },
      contract: {
        id: userRole.productInstance.contract.id,
        name: userRole.productInstance.contract.friendlyName,
      },
      product: {
        id: userRole.productInstance.product.id,
        name: userRole.productInstance.product.name,
      },
      productInstance: {
        id: userRole.productInstance.id,
        name: userRole.productInstance.description,
      },
      role: {
        id: userRole.productRole.id,
        name: userRole.productRole.name,
      },
      status: userRole.status,
      dateCreated: userRole.dateCreated,
      creator: userRole.creator,
      changesCommitted: true,
    };
  });
};

export const getUserRoleAttributeIds = (
  row:
    | LocalProductUserRole
    | {
        id: string;
        project?: string;
        contract?: string;
        product?: string;
        productInstance?: string;
        role?: string;
        status: ProductUserRoleStatus;
      }
): LocalProductUserRoleLite => {
  const productInstanceId =
    typeof row.productInstance === "string"
      ? row.productInstance
      : row.productInstance!.id;
  const productRoleId = typeof row.role === "string" ? row.role : row.role!.id;

  return { productInstanceId, productRoleId };
};
