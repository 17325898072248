import { AuthorizationWorkflowActionMappingStatus } from "generated/graphql";
import { useAuthorizationWorkflowMappings } from "hooks/useAuthorizationWorkflowMappings";
import { useMemo } from "react";
import { LocalProductOutputAction } from "types";

export const useApproveDiaryAuthorizationWorkflow = (
  productInstanceId: string,
  skip?: boolean
) => {
  const { authWorkflowActionMappings, loading } =
    useAuthorizationWorkflowMappings(productInstanceId, skip);

  const approveDiaryMapping = useMemo(
    () =>
      authWorkflowActionMappings.find(
        (mapping) =>
          mapping.status === AuthorizationWorkflowActionMappingStatus.Active &&
          mapping.productOutputAction.name ===
            LocalProductOutputAction.ApproveDiary
      ),
    [authWorkflowActionMappings]
  );

  return {
    approveDiaryAuthorizationWorkflow: approveDiaryMapping?.workflow,
    loading,
  };
};
