import { ActionsDialogProps } from "components/ActionsDialog";
import {
  ClaimActionType,
  ClaimDeterminationNoticePrompt,
} from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { DetailedClaimAgreementDeterminationSummary } from "../components/DetailedClaimAgreementDeterminationSummary";
import { useSendClaimDeterminationNoticeModal } from "./useSendClaimDeterminationNoticeModal";

export type SendClaimDeterminationNoticeModalProps = Pick<
  ClaimDeterminationNoticePrompt,
  "draftClaimDeterminations"
> &
  ActionsDialogProps;

export const SendClaimDeterminationNoticeModal: React.FC<
  SendClaimDeterminationNoticeModalProps
> = ({ draftClaimDeterminations, ...restDialogProps }) => {
  const { claimPreview, contract } = useSendClaimDeterminationNoticeModal(
    draftClaimDeterminations[0].id
  );
  return (
    <SendAction
      claimPreview={claimPreview}
      actionType={ClaimActionType.NotifyDeterminationClaim}
      summaryEl={
        <DetailedClaimAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftClaimDeterminations}
          type="ClaimDetermination"
        />
      }
      {...restDialogProps}
    />
  );
};
