import { gql } from "@apollo/client";
import { draftVariationAgreementFields } from "graphql/fragments/draftVariationAgreement.fragment";
import { draftVariationDeterminationFields } from "graphql/fragments/draftVariationDetermination.fragment";
import { draftVariationProposalFields } from "graphql/fragments/draftVariationProposal.fragment";

// Draft Proposal
export const addDraftVariationProposalMutation = gql`
  ${draftVariationProposalFields}

  mutation addDraftVariationProposal($input: AddDraftVariationProposalInput!) {
    addDraftVariationProposal(input: $input) {
      ...DraftVariationProposalFields
    }
  }
`;

export const editDraftVariationProposalMutation = gql`
  ${draftVariationProposalFields}

  mutation editDraftVariationProposal(
    $input: EditDraftVariationProposalInput!
  ) {
    editDraftVariationProposal(input: $input) {
      ...DraftVariationProposalFields
    }
  }
`;

export const removeDraftVariationProposalMutation = gql`
  mutation removeDraftVariationProposal($id: ID!) {
    removeDraftVariationProposal(id: $id)
  }
`;

// Agreeemnt
export const addDraftVariationAgreementMutation = gql`
  ${draftVariationAgreementFields}

  mutation addDraftVariationAgreement(
    $input: AddDraftVariationAgreementInput!
  ) {
    addDraftVariationAgreement(input: $input) {
      ...DraftVariationAgreementFields
    }
  }
`;

export const editDraftVariationAgreementMutation = gql`
  ${draftVariationAgreementFields}

  mutation editDraftVariationAgreement(
    $input: EditDraftVariationAgreementInput!
  ) {
    editDraftVariationAgreement(input: $input) {
      ...DraftVariationAgreementFields
    }
  }
`;

export const removeDraftVariationAgreementMutation = gql`
  mutation removeDraftVariationAgreement($id: ID!) {
    removeDraftVariationAgreement(id: $id)
  }
`;

// Determination
export const addDraftVariationDeterminationMutation = gql`
  ${draftVariationDeterminationFields}

  mutation addDraftVariationDetermination(
    $input: AddDraftVariationDeterminationInput!
  ) {
    addDraftVariationDetermination(input: $input) {
      ...DraftVariationDeterminationFields
    }
  }
`;

export const editDraftVariationDeterminationMutation = gql`
  ${draftVariationDeterminationFields}

  mutation editDraftVariationDetermination(
    $input: EditDraftVariationDeterminationInput!
  ) {
    editDraftVariationDetermination(input: $input) {
      ...DraftVariationDeterminationFields
    }
  }
`;

export const removeDraftVariationDeterminationMutation = gql`
  mutation removeDraftVariationDetermination($id: ID!) {
    removeDraftVariationDetermination(id: $id)
  }
`;
