import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const DoubleSaveIcon: React.FC<BaseIconProps> = ({
  height = "20",
  width = "11",
  color,
}) => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6685 7.46659L10.8452 8.64325L17.9002 1.58825L19.0785 2.76659L10.8452 10.9999L5.54183 5.69659L6.72016 4.51826L8.491 6.28909L9.6685 7.46575V7.46659ZM9.67016 5.10992L13.7968 0.982422L14.9718 2.15742L10.8452 6.28492L9.67016 5.10992ZM7.31433 9.82242L6.13683 10.9999L0.833496 5.69659L2.01183 4.51826L3.18933 5.69576L3.1885 5.69659L7.31433 9.82242Z"
        fill={color || theme.palette.primary.main}
      />
    </svg>
  );
};
