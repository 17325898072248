import { isLoggedInUserReviewer } from "containers/Home/components/Authorizations/Authorizations.utils";
import {
  AuthorizationWorkflow,
  AuthorizationWorkflowAudit,
} from "generated/graphql";
import { useContext } from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";

export const useLoggedInUserReviewer = (
  authAudit?: AuthorizationWorkflowAudit,
  authWorkflow?: AuthorizationWorkflow
) => {
  const { authenticatedUser } = useContext(GlobalContext);

  return isLoggedInUserReviewer(authAudit, authWorkflow, authenticatedUser);
};
