import { Box, styled } from "@mui/material";
import { AnonymousProfile } from "components/Icons/AnonymousProfile";

export type ProfilePictureProps = {
  photoUrl?: string;
  width?: string;
  height?: string;
};

const StyledImg = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  photoUrl,
  width = "57px",
  height = "57px",
}) => {
  return (
    <Box borderRadius="50%" height={height} width={width} overflow="hidden">
      {photoUrl ? (
        <StyledImg src={photoUrl} alt="profile-picture" />
      ) : (
        <AnonymousProfile width={width} height={height} />
      )}
    </Box>
  );
};
