import { gql } from "@apollo/client";
import { productInstanceLiteFields } from "./productInstanceLite.fragment";
import { creatorFields } from "./creator.fragment";

export const authorizationWorkflowAuditTrailFields = gql`
  ${productInstanceLiteFields}
  ${creatorFields}

  fragment AuthorizationWorkflowAuditTrailFields on AuthorizationWorkflowAuditTrail {
    id
    auditId
    #audit: AuthorizationWorkflowAudit!
    regardingProductItemId
    regardingItemHistoryId
    regardingProductType
    productInstanceId
    productInstance {
      ...ProductInstanceLiteFields
    }
    actionId
    #action: AuthorizationWorkflowActionMapping!
    status
    denialReason
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
