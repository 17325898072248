import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendClaimNoAgreementNoticeModal } from "./useSendClaimNoAgreementNoticeModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";

export type SendClaimNoAgreementNoticeModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendClaimNoAgreementNoticeModal: React.FC<
  SendClaimNoAgreementNoticeModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { claimPreview, contract } =
    useSendClaimNoAgreementNoticeModal(remarks);
  return (
    <>
      <SendAction
        claimPreview={claimPreview}
        actionType={ClaimActionType.NotifyAgreementClaimNotReached}
        summaryEl={
          <ReasonsAttachmentsSummary
            text={remarks}
            attachments={attachments}
            contractTimezone={contract.timeZone}
          />
        }
        {...restDialogProps}
      />
    </>
  );
};
