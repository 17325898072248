import {
  GridColDef,
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { temporaryRowId } from "constants/constants";
import {
  ClaimType,
  ClaimTypeStatus,
  NewClaimTypeOverride,
  NewClaimTypeOverrideStatus,
} from "generated/graphql";
import { TFunction } from "i18next";

import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import {
  getCommonActions,
  statusComparatorFunction,
} from "helpers/dataGrid.helpers";

export const getContractTypeClaimTypesGridColumns = ({
  statusOptions,
  onStatusChange,
  t,
}: {
  statusOptions: StatusOption<ClaimTypeStatus>[];
  onStatusChange: (row: ClaimType, newStatus: ClaimTypeStatus) => void;
  t: TFunction;
}): GridColDef<ClaimType>[] => {
  return [
    {
      field: "description",
      headerName: t("common.labels.description"),
      flex: 0.6,
      minWidth: 200,
      resizable: true,
    },
    {
      field: "clause",
      headerName: t("AdminConsole.ClaimTypes.clause"),
      flex: 0.35,
      minWidth: 90,
      resizable: true,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 0.15,
      minWidth: 90,
      resizable: true,
      sortComparator: statusComparatorFunction,
      renderCell: (params: GridRenderCellParams<any, ClaimType, any>) => {
        return (
          <StatusTag
            status={params.row.status}
            options={statusOptions}
            disabled={params.row.id === temporaryRowId}
            placement="left-start"
            onChange={(newStatus: StatusOption<ClaimTypeStatus>) =>
              onStatusChange(params.row, newStatus.id)
            }
          />
        );
      },
    },
  ];
};

export const getContractNewClaimTypesGridColumns = ({
  statusOptions,
  rowModesModel,
  onSaveRow,
  onDeleteRow,
  onStatusChange,
  t,
}: {
  statusOptions: StatusOption<NewClaimTypeOverrideStatus>[];
  rowModesModel: GridRowModesModel;
  onSaveRow: (rowId: GridRowId) => void;
  onDeleteRow: (rowId: GridRowId) => void;
  onStatusChange: (
    row: NewClaimTypeOverride,
    newStatus: NewClaimTypeOverrideStatus
  ) => void;
  t: TFunction;
}): GridColDef<NewClaimTypeOverride>[] => {
  return [
    {
      field: "description",
      headerName: t("common.labels.description"),
      flex: 0.6,
      minWidth: 200,
      editable: true,
      resizable: true,
      renderEditCell: (
        cellParams: GridRenderEditCellParams<any, NewClaimTypeOverride, any>
      ) => (
        <GridEditInputCell
          {...cellParams}
          error={!cellParams.row.description}
        />
      ),
    },
    {
      field: "clause",
      headerName: t("AdminConsole.ClaimTypes.clause"),
      flex: 0.35,
      minWidth: 90,
      editable: true,
      resizable: true,
      renderEditCell: (
        cellParams: GridRenderEditCellParams<any, NewClaimTypeOverride, any>
      ) => <GridEditInputCell {...cellParams} error={!cellParams.row.clause} />,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 0.15,
      minWidth: 90,
      resizable: true,
      sortComparator: statusComparatorFunction,
      renderCell: (
        params: GridRenderCellParams<any, NewClaimTypeOverride, any>
      ) => {
        return (
          <StatusTag
            status={params.row.status}
            options={statusOptions}
            disabled={params.row.id === temporaryRowId}
            placement="left-end"
            onChange={(newStatus: StatusOption<NewClaimTypeOverrideStatus>) =>
              onStatusChange(params.row, newStatus.id)
            }
          />
        );
      },
    },
    {
      field: "actions",
      flex: 0.05,
      type: "actions",
      getActions: (cellParams) =>
        getCommonActions(cellParams, rowModesModel, onSaveRow, onDeleteRow),
    },
  ];
};
