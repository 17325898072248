import { ToggleButton, ToggleButtonGroup, css, styled } from "@mui/material";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  && {
    .MuiToggleButton-root {
      border-radius: 8px;
    }
  }
`;

export const StyledToggleBtn = styled(ToggleButton)(
  ({ theme }) => css`
    border: none;

    &.Mui-selected {
      background: ${theme.palette.grey[200]};
    }
  `
);
