import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const SelectedNewIcon: React.FC<BaseIconProps> = ({
  width = "17",
  height = "18",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7502 7.49951L7.08132 10.9996L5.25 9.24957"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00121 15.001C11.3156 15.001 14.0024 12.3141 14.0024 8.99975C14.0024 5.68537 11.3156 2.99854 8.00121 2.99854C4.68683 2.99854 2 5.68537 2 8.99975C2 12.3141 4.68683 15.001 8.00121 15.001Z"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
