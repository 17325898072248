import { gql } from "@apollo/client";

export const itemEffectPresetQuery = gql`
  query itemEffectPreset($productInstanceId: ID!) {
    itemEffectPreset(productInstanceId: $productInstanceId) {
      id
      name
      contractTypeId
      isDefault
      isInternal
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
      options {
        items {
          id
          label
          isInternal
          estimationType
          unit
          effectPresetId
          status
          creatorId
          creator {
            id
            firstname
            surname
          }
          dateCreated
        }
      }
    }
  }
`;
