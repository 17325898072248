import {
  RemoveClaimPreviewMutation,
  RemoveClaimPreviewMutationVariables,
} from "generated/graphql";
import { removeClaimPreviewMutation } from "graphql/mutations/removeClaimPreview";
import { useGraphMutation } from "hooks/useGraphMutation";

export const useRemoveClaimPreview = () => {
  const [removeClaimPreview, { loading: removeClaimPreviewLoading }] =
    useGraphMutation<
      RemoveClaimPreviewMutation,
      RemoveClaimPreviewMutationVariables
    >(removeClaimPreviewMutation, {}, null);

  return {
    removeClaimPreview,
    loading: removeClaimPreviewLoading,
  };
};
