import { gql } from "@apollo/client";

export const contractBindingTypeFields = gql`
  fragment ContractBindingTypeFields on ContractBindingType {
    id
    description
    contractTypeId
    status
  }
`;
