import { Color, useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export type XIconProps = BaseIconProps & {
  onClick: React.MouseEventHandler<SVGSVGElement>;
};

export const XIcon: React.FC<XIconProps> = ({
  height = "25",
  width = "25",
  dataTestId = "x-btn",
  onClick,
  color,
}) => {
  const theme = useTheme();
  const computedColor =
    color ?? (theme.palette.secondary as Partial<Color>)[900];

  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      data-testid={dataTestId}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7488 5.25098L6.24609 18.7537"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7488 18.7537L6.24609 5.25098"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
