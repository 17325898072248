import { gql } from "@apollo/client";
import { timePeriodOverrideFields } from "graphql/fragments/timePeriodOverride.fragment";

export const timePeriodsOverrideQuery = gql`
  ${timePeriodOverrideFields}

  query timePeriodsOverride($contractId: ID!) {
    timePeriodsOverride(contractId: $contractId) {
      items {
        ...TimePeriodOverrideFields
      }
    }
  }
`;
