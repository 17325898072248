import {
  AddDraftEarlyWarningItemMutation,
  AddDraftEarlyWarningItemMutationVariables,
  DraftEarlyWarningItemQuery,
  DraftEarlyWarningItemQueryVariables,
  EditDraftEarlyWarningItemMutation,
  EditDraftEarlyWarningItemMutationVariables,
  ProductType,
  RecordEarlyWarningItemMutation,
  RecordEarlyWarningItemMutationVariables,
  SendEarlyWarningItemMutation,
  SendEarlyWarningItemMutationVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import {
  addDraftEarlyWarningItemMutation,
  draftEarlyWarningItemQuery,
  editDraftEarlyWarningItemMutation,
  recordEarlyWarningItemMutation,
  sendEarlyWarningItemMutation,
} from "../NewEarlyWarning.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useProductItemContext } from "hooks/useProductItemContext";
import { useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { IntercomEvents } from "constants/intercom";
import { getMetadataSet1 } from "containers/Projects/utils/intercom";

export const useNewEarlyWarning = () => {
  const { t } = useTranslation();
  const { projectId, contractId, productInstanceId } = useParams();
  const { trackEvent } = useIntercom();

  const {
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
  } = useProductItemContext(projectId!, contractId!, productInstanceId!);

  const sendEWTrackingEvent = (
    ewId: string,
    ewTitle: string,
    event: IntercomEvents.SentEarlyWarning | IntercomEvents.RecordedEarlyWarning
  ) => {
    trackEvent(
      event,
      getMetadataSet1({
        productInstanceId: productInstanceId!,
        productInstanceItemId: ewId,
        productType: ProductType.EarlyWarnings,
        contractFn: contractDataLite?.friendlyName ?? "",
        projectFn: projectDataLite?.friendlyName ?? "",
        productInstanceItemName: ewTitle,
        version: process.env.REACT_APP_VERSION!,
      })
    );
  };

  const [
    fetchDraftEWData,
    {
      data: draftEWData,
      loading: getDraftEWDataLoading,
      refetch: refetchDraftEWData,
    },
  ] = useGraphLazyQuery<
    DraftEarlyWarningItemQuery,
    DraftEarlyWarningItemQueryVariables
  >(draftEarlyWarningItemQuery);

  const [sendEarlyWarning, { loading: sendEarlyWarningLoading }] =
    useGraphMutation<
      SendEarlyWarningItemMutation,
      SendEarlyWarningItemMutationVariables
    >(
      sendEarlyWarningItemMutation,
      {
        update: (_cache, result) => {
          sendEWTrackingEvent(
            result.data?.sendEarlyWarningItem.id!,
            result.data?.sendEarlyWarningItem.title ?? "",
            IntercomEvents.SentEarlyWarning
          );

          // cache.evict({ id: "ROOT_QUERY", fieldName: "earlyWarningItems" });
          // cache.evict({ id: "ROOT_QUERY", fieldName: "claimNoticePrompt" });
          // cache.evict({ id: "ROOT_QUERY", fieldName: "compEventNoticePrompt" });
          // cache.evict({
          //   id: "ROOT_QUERY",
          //   fieldName: "draftEarlyWarningItems",
          // });
          // cache.gc();
        },
      },
      null
    );

  const [recordEarlyWarning, { loading: recordEarlyWarningLoading }] =
    useGraphMutation<
      RecordEarlyWarningItemMutation,
      RecordEarlyWarningItemMutationVariables
    >(
      recordEarlyWarningItemMutation,
      {
        update: (_cache, result) => {
          sendEWTrackingEvent(
            result.data?.recordEarlyWarningItem.id!,
            result.data?.recordEarlyWarningItem.title ?? "",
            IntercomEvents.RecordedEarlyWarning
          );
          // cache.evict({ id: "ROOT_QUERY", fieldName: "earlyWarningItems" });
          // cache.evict({
          //   id: "ROOT_QUERY",
          //   fieldName: "draftEarlyWarningItems",
          // });
          // cache.gc();
        },
      },
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.EarlyWarnings.earlyWarning"),
      })
    );

  const [addEWItemDraft, { loading: addEWItemDraftLoading }] = useGraphMutation<
    AddDraftEarlyWarningItemMutation,
    AddDraftEarlyWarningItemMutationVariables
  >(
    addDraftEarlyWarningItemMutation,
    {
      // update: (cache) => {
      //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftEarlyWarningItems" });
      //   cache.gc();
      // },
    },
    t("common.successMessages.entityCreated", {
      entity: t("Projects.EarlyWarnings.draftEWItem"),
    })
  );

  const [editEWItemDraft, { loading: editEWItemDraftLoading }] =
    useGraphMutation<
      EditDraftEarlyWarningItemMutation,
      EditDraftEarlyWarningItemMutationVariables
    >(
      editDraftEarlyWarningItemMutation,
      {
        update: (_cache) => {
          refetchDraftEWData();

          // cache.evict({
          //   id: "ROOT_QUERY",
          //   fieldName: "draftEarlyWarningItems",
          // });
          // cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.EarlyWarnings.draftEWItem"),
      })
    );

  return {
    fetchDraftEWData,
    draftEWData,
    getDraftEWDataLoading,
    sendEarlyWarning,
    sendEarlyWarningLoading,
    recordEarlyWarning,
    recordEarlyWarningLoading,
    addEWItemDraft,
    addEWItemDraftLoading,
    editEWItemDraft,
    editEWItemDraftLoading,
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
  };
};
