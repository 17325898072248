import { Box, Stack } from "@mui/material";
import { FileIconSize, FileIconVariant, FileTypeIcon } from "../FileTypeIcon";
import { OverflowTooltip } from "components/OverflowTooltip";
import { Attachment } from "generated/graphql";
import { FileType } from "../../Attachments.decl";
import { isAttachment, stringToFileMIMEType } from "../../Attachments.utils";
import { FileDescription } from "../FileDescription";
import { LockIcon } from "components/Icons/LockIcon";

export type FileFooterProps = {
  file: Attachment | FileType;
  editable?: boolean;
  hideFileTypeIcon?: boolean;
  newStyle?: boolean;
  onDescriptionChange: (newDescription?: string) => void;
};

export const FileFooter: React.FC<FileFooterProps> = ({
  file,
  editable,
  hideFileTypeIcon,
  newStyle,
  onDescriptionChange,
}) => {
  return (
    <Stack px={newStyle ? 1 : 2} py={newStyle ? 0 : 1.5} mt={newStyle ? 2 : 0}>
      <Stack direction="row" alignItems="center">
        {!hideFileTypeIcon && (
          <FileTypeIcon
            type={stringToFileMIMEType(file.mimeType)}
            size={FileIconSize.Small}
            variant={FileIconVariant.Colored}
          />
        )}
        <OverflowTooltip
          title={file.fileName}
          typographyProps={{
            ml: hideFileTypeIcon ? 0 : 1,
            variant: newStyle ? "p2" : "p1",
            color: "grey.800",
            fontWeight: 600,
            maxWidth: newStyle ? "250px" : "200px",
          }}
        />
      </Stack>
      <Box mt={0.5} display="flex" alignItems="center">
        <FileDescription
          file={file}
          editable={editable}
          newStyle={newStyle}
          onDescriptionChange={onDescriptionChange}
        />
        <Box ml="auto">
          {isAttachment(file) && file.locked ? <LockIcon /> : null}
        </Box>
      </Box>
    </Stack>
  );
};
