import { CompEventActionType } from "generated/graphql";

/**
 * Note: many of these intentionally don't have translations because the text displayed for `NextStep` is
 * actually the next action to take in a happy flow (notify -> confirm -> submit quotation -> accept)
 * with some minor deviations like requestMoreTime, etc.
 */
export const ceStatusToNextStepWordingMap: Record<
  CompEventActionType | "None",
  string | null
> = {
  None: "Projects.CompEvents.nextStepWordings.none",
  [CompEventActionType.AbandonInstruction]:
    "Projects.CompEvents.nextStepWordings.AbandonInstruction",
  [CompEventActionType.AcceptQuotation]:
    "Projects.CompEvents.nextStepWordings.AcceptQuotation",
  [CompEventActionType.CompEventDeemedConfirmed]:
    "Projects.CompEvents.nextStepWordings.CompEventDeemedConfirmed",
  [CompEventActionType.ConfirmCompEvent]:
    "Projects.CompEvents.nextStepWordings.ConfirmCompEvent",
  [CompEventActionType.DenyRequestMoreTime]:
    "Projects.CompEvents.nextStepWordings.DenyRequestMoreTime",
  [CompEventActionType.GrantRequestMoreTime]:
    "Projects.CompEvents.nextStepWordings.GrantRequestMoreTime",
  [CompEventActionType.NotifyCompEvent]:
    "Projects.CompEvents.nextStepWordings.NotifyCompEvent",
  [CompEventActionType.NotifyCompEventRequestQuotation]:
    "Projects.CompEvents.nextStepWordings.NotifyCompEventRequestQuotation",
  [CompEventActionType.NotifyOwnAssessment]:
    "Projects.CompEvents.nextStepWordings.NotifyOwnAssessment",
  [CompEventActionType.NotifySilenceCompEventNotice]:
    "Projects.CompEvents.nextStepWordings.NotifySilenceCompEventNotice",
  [CompEventActionType.NotifySilenceOwnAssessment]:
    "Projects.CompEvents.nextStepWordings.NotifySilenceOwnAssessment",
  [CompEventActionType.NotifySilenceQuotation]:
    "Projects.CompEvents.nextStepWordings.NotifySilenceQuotation",
  [CompEventActionType.ProposedInstructionChangedDecision]:
    "Projects.CompEvents.nextStepWordings.ProposedInstructionChangedDecision",
  [CompEventActionType.QuotationDeemedAccepted]:
    "Projects.CompEvents.nextStepWordings.QuotationDeemedAccepted",
  [CompEventActionType.RejectCompEvent]:
    "Projects.CompEvents.nextStepWordings.RejectCompEvent",
  [CompEventActionType.RequestMoreTime]:
    "Projects.CompEvents.nextStepWordings.RequestMoreTime",
  [CompEventActionType.RequestRevisedQuotation]:
    "Projects.CompEvents.nextStepWordings.RequestRevisedQuotation",
  [CompEventActionType.SubmitOwnAssessment]:
    "Projects.CompEvents.nextStepWordings.SubmitOwnAssessment",
  [CompEventActionType.SubmitQuotation]:
    "Projects.CompEvents.nextStepWordings.SubmitQuotation",
  [CompEventActionType.SubmitRevisedQuotation]:
    "Projects.CompEvents.nextStepWordings.SubmitRevisedQuotation",
};
