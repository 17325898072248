import { Box, css, styled } from "@mui/material";

export enum PageContentHeaderSize {
  Normal = "normal",
  Large = "large",
}

export type PageContentHeaderProps = {
  size?: PageContentHeaderSize;
  withShadow?: boolean;
  sticky?: boolean;
};

export const PageContentHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "withShadow" && prop !== "sticky",
})<PageContentHeaderProps>(
  ({ theme, size = "normal", withShadow = true, sticky = true }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${size === "normal" ? "50px" : "80px"};
    background: ${theme.palette.common.white};
    ${withShadow ? "box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06)" : ""};
    padding: 0 ${theme.spacing(4)};
    box-sizing: border-box;
    position: relative;

    ${sticky
      ? css`
          position: sticky;
          top: 134px; // The heights of <MainNavigation/> and <AdminNavigation />
          z-index: 5;
        `
      : ""}
  `
);
