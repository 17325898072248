import { Box, Color, debounce, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { IconContainer } from "components/IconContainer";
import { eventDebounceDuration } from "constants/constants";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { RecipientsPreview } from "containers/Projects/components/RecipientsPreview";
import {
  RecordProductItemExtraData,
  RecordProductItemForm,
} from "containers/Projects/components/RecordProductItemForm";
import { useContractBindingTypes } from "containers/Projects/hooks/useContractBindingTypes";
import {
  CompEventActionType,
  CompEventQuotation,
  GenerateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutation,
  GenerateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutationVariables,
  ProductType,
  RecordCompEventNoticeOfSilenceOwnAssessmentPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { Plugs } from "phosphor-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ActionModalTitle } from "../../../../ActionModal/ActionModalTitle";
import { QuotationsTable } from "../SubmitQuotationCEAction/QuotationsTable/QuotationsTable";
import { ReasonSummary } from "../../../../ActionModal/ReasonSummary";
import { generateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutation } from "graphql/mutations/generateRecordCompEventNoticeOfSilenceOwnAssessmentPreview";
import { FormLabel } from "components/FormLabel";

export type RecordNotifyOfSilenceCEOwnAssessmentProps = {
  quotations: CompEventQuotation[];
  acceptedQuotationId: string;
  remarks?: string;
} & {
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordNotifyOfSilenceCEOwnAssessment: React.FC<
  RecordNotifyOfSilenceCEOwnAssessmentProps
> = ({
  remarks,
  quotations,
  acceptedQuotationId,
  onPrimaryClick,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { contract, projectName, compEvent } = useContext(
    CompEventWidgetContext
  );
  const { bindingTypes, loading: bindingTypesLoading } =
    useContractBindingTypes(contract.id);
  const extraData = useRef<RecordProductItemExtraData>();
  const extraDataValid = useRef<boolean>(false);

  const [compEventPreview, setCompEventPreview] =
    useState<RecordCompEventNoticeOfSilenceOwnAssessmentPreview>();

  const [generateCEPreview] = useGraphMutation<
    GenerateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutation,
    GenerateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutationVariables
  >(
    generateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutation,
    {},
    null
  );

  const handleExtraDataChange = (data: RecordProductItemExtraData) => {
    extraData.current = data;
  };

  const handleExtraDataValidityChange = (isValid: boolean) => {
    extraDataValid.current = isValid;
  };

  const handlePrimaryClick = () => {
    if (extraDataValid.current) {
      onPrimaryClick(extraData.current!);
    }
  };

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateCEPreview({
        variables: {
          input: {
            compEventId: compEvent?.id!,
          },
        },
      });

      if (data) {
        setCompEventPreview(
          data.generateRecordCompEventNoticeOfSilenceOwnAssessmentPreview
        );
      }
    }, [generateCEPreview, compEvent?.id]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return (
    <ActionsDialog
      onPrimaryClick={handlePrimaryClick}
      primaryBtnCaption={t("common.buttons.record")}
      primaryBtnDisabled={!compEventPreview}
      iconsHeader={
        <IconContainer>
          <Plugs
            size={18}
            color={(theme.palette.secondary as Partial<Color>)[700]}
          />
        </IconContainer>
      }
      maxWidth="sm"
      fullWidth
      content={
        !compEventPreview || bindingTypesLoading ? (
          <CenteredLoadingIndicator />
        ) : (
          <Box>
            <ActionModalTitle mb={2}>
              {`${t(
                `Projects.CompEvents.ActionTypes.${CompEventActionType.NotifySilenceOwnAssessment}`
              )} (record)`}
            </ActionModalTitle>
            <ReasonSummary
              reason={remarks}
              label={t("common.labels.remarks")}
            />
            <Box mt={2}>
              <FormLabel
                label={t(
                  "Projects.CompEvents.ActionModal.quotationToDeemAccepted"
                )}
              />
              <QuotationsTable
                contractCurrency={contract.valueCurrency ?? ""}
                loading={false}
                quotations={quotations}
                selectable
                acceptedQuotationId={acceptedQuotationId}
              />
            </Box>
            <Box mt={4}>
              <RecordProductItemForm
                bindingTypes={bindingTypes}
                productType={ProductType.CompEvents}
                onChange={handleExtraDataChange}
                onValidationChange={handleExtraDataValidityChange}
              />
            </Box>
            <Box mt={4}>
              <ProductItemCard
                itemName={compEvent?.number!}
                productType={ProductType.CompEvents}
                projectName={projectName}
                contractName={contract.friendlyName}
              />
            </Box>
            <Box mt={4}>
              <CollapsibleContainer
                title={`${t(`common.labels.recipients`)} (${
                  compEventPreview.recipients.length
                })`}
              >
                <RecipientsPreview recipients={compEventPreview.recipients} />
              </CollapsibleContainer>
            </Box>
          </Box>
        )
      }
      sx={{ minWidth: "420px" }}
      {...restDialogProps}
    />
  );
};
