import { Box, Button, Typography, useTheme } from "@mui/material";
import { dateISOFormat } from "constants/constants";
import { DaysLate } from "containers/Projects/components/DaysLate";
import { ClaimAction, ClaimItem } from "generated/graphql";
import moment from "moment";
import { CaretRight } from "phosphor-react";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../ClaimWidget.context";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { ClaimActionModal } from "../../ClaimActionModal/ClaimActionModal";
import { getNextStepDueDate, getNextStepText } from "../../Claims.utils";
import { AuthorizationBanner } from "components/Authorization/AuthorizationBanner";
import { useAuthStore } from "state-management/store/authStore";
import { useLoggedInUserReviewer } from "hooks/useLoggedInUserReviewer";

export type ClaimWidgetNextStepSectionProps = {
  claim?: ClaimItem;
  previewMode?: boolean;
  claimActionList: ClaimAction[];
};

export const ClaimWidgetNextStepSection: React.FC<
  ClaimWidgetNextStepSectionProps
> = ({ claim, previewMode = false, claimActionList }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { eventItem, instructionItem, isFIDIC99RedYellow, onChange } =
    useContext(ClaimWidgetContext);

  const setAuthModalState = useAuthStore((state) => state.setAuthModalState);
  const { canUserReviewCrtLevel, isUserReviewer } = useLoggedInUserReviewer(
    claim?.ongoingAuthorizationAudit ?? undefined
  );
  const {
    modalVisibility: nextStepModalVisibility,
    toggleModalVisibility: toggleNextStepModalVisibility,
  } = useBasicModal();

  const computedNextStepText = useMemo(
    () => getNextStepText(claim, isFIDIC99RedYellow),
    [claim, isFIDIC99RedYellow]
  );

  const computedNextStepDueDate = getNextStepDueDate(
    claim,
    eventItem,
    instructionItem
  );

  const computedNextStepDaysLate = useMemo(() => {
    if (claim) {
      return moment().diff(moment(claim.nextStep?.dueDate), "days");
    }

    if (computedNextStepDueDate) {
      const daysDifference = moment(computedNextStepDueDate).diff(
        moment(),
        "days"
      );
      if (daysDifference >= 0) {
        return undefined;
      }

      return Math.abs(daysDifference);
    }
  }, [claim, computedNextStepDueDate]);

  const shallDisplayNextStepButton = !!claimActionList.length;

  const triggerNextStepFlow = () => {
    toggleNextStepModalVisibility();
  };

  const handleAuthorizationBannerClick = () => {
    if (claim?.ongoingAuthorizationAudit) {
      setAuthModalState({
        authAuditId: claim.ongoingAuthorizationAudit.id,
        type: canUserReviewCrtLevel ? "AuthorizeDeny" : "AuditTrail",
        claimId: claim.id,
        onChange,
      });
    }
  };

  return (
    <>
      <ClaimActionModal
        open={nextStepModalVisibility}
        onClose={toggleNextStepModalVisibility}
        claim={claim}
        claimActions={claimActionList}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        data-testid="claim-next-step-section"
      >
        <Box
          display="flex"
          flexDirection={previewMode ? "row" : "column"}
          alignItems={previewMode ? "center" : "flex-start"}
          justifyContent={
            !computedNextStepText && !previewMode ? "center" : "space-between"
          }
          width="100%"
        >
          {claim?.ongoingAuthorizationAudit && !previewMode && (
            <AuthorizationBanner
              size="small"
              stage="inProgress"
              mb={2}
              onClick={
                isUserReviewer ? handleAuthorizationBannerClick : undefined
              }
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            flex={!computedNextStepText ? "unset" : 3}
          >
            <Typography variant="p2" fontWeight={600} color="grey.800">
              {computedNextStepText
                ? t("Projects.Claims.nextStep")
                : t("Projects.Claims.furtherStepsAvailable")}
            </Typography>
            {computedNextStepText && (
              <Typography
                variant="p2"
                color="grey.800"
                mt={0.5}
                data-testid="next-step"
              >
                {t(computedNextStepText)}
              </Typography>
            )}
          </Box>
          {computedNextStepDueDate && (
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              mt={previewMode ? 0 : 2}
            >
              <Typography variant="p2" fontWeight={600} color="grey.800">
                {t("common.labels.dueDate")}
              </Typography>
              <Box display="flex" alignItems="center" mt={0.5}>
                <Typography
                  variant="p2"
                  color="grey.800"
                  data-testid="due-date"
                >
                  {moment(computedNextStepDueDate).format(dateISOFormat)}
                </Typography>
                {computedNextStepDaysLate && computedNextStepDaysLate > 0 ? (
                  <DaysLate daysLate={computedNextStepDaysLate} ml={1.5} />
                ) : null}
              </Box>
            </Box>
          )}
        </Box>
        {shallDisplayNextStepButton && !previewMode && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignSelf="stretch"
          >
            <Button
              data-testid="claim-next-step-btn"
              onClick={triggerNextStepFlow}
              sx={{
                minWidth: computedNextStepText ? "32px" : "24px",
                maxHeight: computedNextStepText ? "36px" : "24px",
              }}
            >
              <CaretRight color={theme.palette.grey[800]} size={18} />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
