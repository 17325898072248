import {
  ClaimNoticeLapsedNoticePromptQuery,
  ClaimNoticeLapsedNoticePromptQueryVariables,
  RecordClaimNoticeLapsedNoticeMutation,
  RecordClaimNoticeLapsedNoticeMutationVariables,
  SendClaimNoticeLapsedNoticeMutation,
  SendClaimNoticeLapsedNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { OnMutationDone } from "types/decl";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  claimNoticeLapsedNoticePromptQuery,
  recordClaimNoticeLapsedNoticeMutation,
  sendClaimNoticeLapsedNoticeMutation,
} from "./NotifyClaimNoticeValidityHasLapsedAction.query";

export const useNotifyClaimNoticeValidityHasLapsedAction = () => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const { data: promptData, loading } = useGraphQuery<
    ClaimNoticeLapsedNoticePromptQuery,
    ClaimNoticeLapsedNoticePromptQueryVariables
  >(claimNoticeLapsedNoticePromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
  });

  const [
    sendClaimNoticeLapsedNotice,
    { loading: sendClaimNoticeLapsedNoticeLoading },
  ] = useGraphMutation<
    SendClaimNoticeLapsedNoticeMutation,
    SendClaimNoticeLapsedNoticeMutationVariables
  >(
    sendClaimNoticeLapsedNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.noticeOfClaimValidityLapsedSent${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  const [
    recordClaimNoticeLapsedNotice,
    { loading: recordClaimNoticeLapsedNoticeLoading },
  ] = useGraphMutation<
    RecordClaimNoticeLapsedNoticeMutation,
    RecordClaimNoticeLapsedNoticeMutationVariables
  >(
    recordClaimNoticeLapsedNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.noticeOfClaimValidityLapsedRecord${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  return {
    reasons: promptData?.claimNoticeLapsedNoticePrompt.remarks,
    loading,
    actionLoading:
      sendClaimNoticeLapsedNoticeLoading ||
      recordClaimNoticeLapsedNoticeLoading,
    sendClaimNoticeLapsedNotice,
    recordClaimNoticeLapsedNotice,
  };
};
