import { Box, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { TrashIconContained } from "components/Icons/TrashIconContained";
import { useTranslation } from "react-i18next";

export type CloseReplyEditorConfirmModalProps = {} & ActionsDialogProps;

export const CloseReplyEditorConfirmModal: React.FC<
  CloseReplyEditorConfirmModalProps
> = ({ ...restDialogProps }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ActionsDialog
      iconsHeader={<TrashIconContained />}
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Comments.CloseConfirmModal.title")}
          </Typography>
          <Box mb={2}>
            <Typography variant="p1" color={theme.palette.grey[700]}>
              {t("Comments.CloseConfirmModal.subtitle")}
            </Typography>
          </Box>
        </Box>
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      secondaryBtnCaption={t("common.buttons.cancel")}
      primaryBtnCaption={t("common.buttons.close")}
      {...restDialogProps}
    />
  );
};
