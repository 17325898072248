import { Box, Typography } from "@mui/material";
import {
  defaultSeverityIndex,
  SeverityColors,
  SeverityColorsMapping,
} from "./Severity.constants";
import { computeSeverityRating } from "./Severity.utils";
import { SeverityPickerItemIcon } from "./SeverityPickerItemIcon";
import { SeverityReadOnlyProps } from "./Severity.decl";

export const SeverityPreviewMedium: React.FC<SeverityReadOnlyProps> = ({
  severityPreset,
  consequenceValue,
  likelihoodValue,
}) => {
  const severityRating = computeSeverityRating(
    severityPreset,
    consequenceValue,
    likelihoodValue
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={4}
      py="20px"
      sx={{
        background:
          SeverityColorsMapping[severityRating.colour as SeverityColors]
            .backgroundColor,
        borderRadius: "12px",
      }}
    >
      <Typography
        variant="h2"
        color={
          SeverityColorsMapping[severityRating.colour as SeverityColors]
            .filledColor
        }
      >
        {severityRating.name}
      </Typography>
      <SeverityPickerItemIcon
        itemSet={severityPreset.likelihoodSets}
        value={likelihoodValue ?? defaultSeverityIndex}
        height={24}
        width={24}
        step={4}
        highContrast
        filledColor={{
          highContrastColor:
            SeverityColorsMapping[severityRating.colour as SeverityColors]
              .filledColor,
          lowContrastColor: "",
        }}
        unfilledColor={{
          highContrastColor:
            SeverityColorsMapping[severityRating.colour as SeverityColors]
              .unfilledColor,
          lowContrastColor: "",
        }}
      />
    </Box>
  );
};
