import { Box } from "@mui/material";
import { InviteUserRoleInput, Project } from "generated/graphql";
import {
  LocalInviteUserRoleInput,
  ProjectRolesContainer,
} from "./ProjectRolesContainer";

export type ProductInstanceRolesMapperProps = {
  projects: Project[];
  productInstanceRoles: InviteUserRoleInput[];
  onChange: (newPIRoleMappings: LocalInviteUserRoleInput[]) => void;
};

export type ProductRoleLite = {
  id: string;
  name: string;
};

export const defaultProductInstanceRole: ProductRoleLite = {
  name: "None",
  id: "",
};

export const acceptedRoleNames = ["none", "viewer", "contributor"];

export const ProductInstanceRolesMapper: React.FC<
  ProductInstanceRolesMapperProps
> = ({ projects, productInstanceRoles, onChange }) => {
  return (
    <Box display="flex" flexDirection="column">
      {projects.map((project) => (
        <ProjectRolesContainer
          key={project.id}
          project={project}
          productInstanceRoles={productInstanceRoles}
          onChange={onChange}
        />
      ))}
    </Box>
  );
};
