import {
  GridFilterModel,
  GridLogicOperator,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { ClaimItem } from "generated/graphql";
import { useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { exportToExcel } from "../../../../../../helpers/exportToExcel";
import { useSearchParams } from "react-router-dom";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { useCEClaimVariationTable } from "hooks/useCEClaimVariationTable";
import { useContractLite } from "hooks/useContractLite";
import { useColumns } from "./ClaimsTable.constants";
import { isFIDIC99RedYellowContractType } from "containers/Projects/Projects.utils";
import { Column } from "exceljs";
import {
  excelDateTimeISOFormat,
  excelTwoDecimalsNumberFormat,
} from "constants/constants";
import { getDatetimeOnTz } from "helpers/timezones";

export type ClaimsTableProps = {
  claims: ClaimItem[];
  loading: boolean;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
};

export const ClaimsTable: React.FC<ClaimsTableProps> = ({
  claims,
  loading,
  apiRef,
  selectionModel,
  onSelectionModelChange,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const contractId = searchParams.get("contractId");

  const { handleRowClick, handleClearSelection, handleSelectionModelChange } =
    useCEClaimVariationTable(projectId!, contractId!, onSelectionModelChange);

  const { contractLite } = useContractLite(contractId!);

  const isFIDIC99RedYellow = useMemo(
    () => isFIDIC99RedYellowContractType(contractLite),
    [contractLite]
  );

  const gridApiRef = useGridApiRef();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "lastAction", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = useCallback(async () => {
    if (!claims || !claims.length) {
      console.warn("No claim to export");
      return;
    }

    const columns: Partial<Column>[] = [
      {
        header: t("Projects.Claims.claimNumber"),
        key: "number",
      },
      {
        header: t("Projects.Claims.regardingTitle"),
        key: "regardingTitle",
      },
      {
        header: t("Projects.Claims.regardingProduct"),
        key: "regardingProduct",
      },
      {
        header: t("common.labels.notified"),
        key: "notified",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.status"),
        key: "status",
      },
      {
        header: t("Projects.Claims.paymentSought"),
        key: "paymentSought",
        numFmt: excelTwoDecimalsNumberFormat,
      },
      {
        header: t("Projects.Claims.eotSought"),
        key: "eotSought",
      },
      {
        header: t("Projects.Claims.paymentGranted"),
        key: "paymentGranted",
        numFmt: excelTwoDecimalsNumberFormat,
      },
      {
        header: t("Projects.Claims.eotGranted"),
        key: "eotGranted",
      },
      {
        header: t(
          `Projects.EarlyWarnings.${
            isFIDIC99RedYellow ? "earlyWarning" : "advanceWarning"
          }`
        ),
        key: "advanceWarning",
      },
    ];

    const rows = claims
      .filter((claim) => (selectionModel || []).indexOf(claim.id) >= 0)
      .map((claim) => ({
        ...claim,
        regardingTitle:
          claim.regardingEvent?.title ?? claim.regardingInstruction?.title,
        regardingProduct: claim.regardingType,
        notified: getDatetimeOnTz(
          claim.dateCreated,
          claim.productInstance.contract.timeZone
        ),
        paymentSought:
          typeof claim.priceSought === "number" ? claim.priceSought : "-",
        eotSought:
          typeof claim.timeSought === "number"
            ? Math.abs(claim.timeSought)
            : "-",
        paymentGranted:
          typeof claim.priceGranted === "number" ? claim.priceGranted : "-",
        eotGranted:
          typeof claim.timeGranted === "number"
            ? Math.abs(claim.timeGranted)
            : "-",
        advanceWarning: claim.advanceWarning?.number ?? "",
      }));

    exportToExcel(t("Projects.Claims.claims"), columns, rows);
  }, [claims, selectionModel, t, isFIDIC99RedYellow]);

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  const columns = useColumns(contractLite);

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={claims || []}
      columns={columns}
      getRowId={(rowData: ClaimItem) => rowData.id}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading}
      sortingMode="client"
      sortModel={sortingModel}
      onSortModelChange={setSortingModel}
      filterMode="client"
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onRowClick={handleRowClick}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
