import {
  DailyDiaryItem,
  DailyDiaryWorkRecord,
  DailyDiaryWorkRecordsQuery,
  DailyDiaryWorkRecordsQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { dailyDiaryWorkRecordsQuery } from "./WorkSection.query";

export const useWorkRecords = (
  dailyDiary?: DailyDiaryItem, // TODO: pass only dailyDiaryId - no need for the entire object
  revisionId?: string
) => {
  const {
    data: workRecords,
    loading: workRecordsLoading,
    refetch: refetchDDWorkRecords,
  } = useGraphQuery<
    DailyDiaryWorkRecordsQuery,
    DailyDiaryWorkRecordsQueryVariables
  >(dailyDiaryWorkRecordsQuery, {
    variables: { dailyDiaryId: dailyDiary?.id!, revisionId },
    skip: !dailyDiary,
    notifyOnNetworkStatusChange: true,
  });

  return {
    workRecords: (workRecords?.dailyDiaryWorkRecords ??
      []) as DailyDiaryWorkRecord[],
    workRecordsLoading,
    refetchDDWorkRecords,
  };
};
