import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const ContractsIcon: React.FC<BaseIconProps> = ({
  height = "14",
  width = "16",
  color,
}) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 5V14.7448C13.7507 14.8432 13.732 14.9409 13.6949 15.0322C13.6579 15.1234 13.6032 15.2065 13.534 15.2766C13.4649 15.3468 13.3826 15.4026 13.2919 15.4409C13.2011 15.4792 13.1037 15.4993 13.0052 15.5H0.99475C0.79736 15.5 0.608043 15.4216 0.468397 15.2821C0.328751 15.1426 0.250199 14.9534 0.25 14.756V1.244C0.25 0.84125 0.58675 0.5 1.0015 0.5H9.24775L13.75 5ZM12.25 5.75H8.5V2H1.75V14H12.25V5.75ZM4 4.25H6.25V5.75H4V4.25ZM4 7.25H10V8.75H4V7.25ZM4 10.25H10V11.75H4V10.25Z"
        fill={color || theme.palette.primary.main}
      />
    </svg>
  );
};
