import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { useCompanies } from "hooks/useCompanies";
import { PencilSimple } from "phosphor-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChangeUserCompany } from "./useChangeUserCompany";

export type ChangeUserCompanyModalProps = Omit<
  ActionsDialogProps,
  "onPrimaryClick" | "onSecondaryClick"
> & {
  onCompanyChanged?: (newCompanyId: string) => void;
  userId: string;
  crtCompanyId: string;
};

const ModalHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5" fontWeight={600} color="grey.800" mb={1}>
        {t("AdminConsole.Users.ChangeUserCompanyModal.title")}
      </Typography>

      <Typography
        variant="body1"
        color="grey.700"
        fontWeight="semibold"
        flex={1}
        display="inline"
      >
        {t("AdminConsole.Users.ChangeUserCompanyModal.subtitle")}
      </Typography>
    </Box>
  );
};

const initialFormData = {
  companyId: "",
};

export const ChangeUserCompanyModal: React.FC<ChangeUserCompanyModalProps> = ({
  userId,
  crtCompanyId,
  onCompanyChanged,
  onClose,
  ...restDialogProps
}) => {
  const { t } = useTranslation();

  const { changeUserCompany, changeUserCompanyLoading } =
    useChangeUserCompany();

  const { companies, loading } = useCompanies(true);

  const filteredCompanies = useMemo(() => {
    return companies.filter((company) => company.id !== crtCompanyId);
  }, [companies, crtCompanyId]);

  const [formData, setFormData] = useState<{
    companyId?: string;
  }>(initialFormData);

  const handleCompanyOptionChange = (
    event: SelectChangeEvent<string | null>
  ) => {
    setFormData((curData) => ({
      ...curData,
      companyId: event.target.value ?? "",
    }));
  };

  const handleChangeCompany = async () => {
    if (!formData.companyId) return;

    const { data } = await changeUserCompany({
      variables: {
        id: userId,
        companyId: formData.companyId,
      },
    });

    return !!data;
  };

  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    setFormData(initialFormData);
    onClose?.(event, reason);
  };

  const handlePrimaryBtnClick = async () => {
    if (formData.companyId) {
      const success = await handleChangeCompany();
      if (success) {
        onCompanyChanged?.(formData.companyId);
        handleClose({}, "backdropClick");
      }
    }
  };

  return (
    <ActionsDialog
      iconsHeader={<PencilSimple size={32} />}
      title={<ModalHeader />}
      onClose={handleClose}
      content={
        <Grid container spacing={5}>
          {filteredCompanies && !loading ? (
            <Grid item sm={12}>
              <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
                <>
                  <InputLabel id="company-select-label" shrink>
                    <Typography>{`${t("common.labels.company")} *`}</Typography>
                  </InputLabel>
                  <Select
                    labelId="company-select-label"
                    id="company-select"
                    value={formData.companyId}
                    onChange={handleCompanyOptionChange}
                    required
                  >
                    {filteredCompanies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.tradingName}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </FormControl>
            </Grid>
          ) : (
            <CenteredLoadingIndicator />
          )}
        </Grid>
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      onPrimaryClick={handlePrimaryBtnClick}
      primaryBtnLoading={changeUserCompanyLoading}
      primaryBtnCaption={t("common.buttons.save")}
      onSecondaryClick={() => handleClose({}, "escapeKeyDown")}
      primaryBtnDisabled={!formData.companyId}
      {...restDialogProps}
    />
  );
};
