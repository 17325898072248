import { Box, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { WarningIcon } from "components/Icons/WarningIcon";
import { useTranslation } from "react-i18next";

export type ConfirmChangesModalProps = {} & ActionsDialogProps;

export const ConfirmChangesModal: React.FC<ConfirmChangesModalProps> = ({
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ActionsDialog
      iconsHeader={<WarningIcon />}
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("ResetPassword.ConfirmChangesModal.title")}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]} mb={2}>
            {t("ResetPassword.ConfirmChangesModal.subtitle")}
          </Typography>
        </Box>
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      {...restDialogProps}
    />
  );
};
