import { ActionsDialogProps } from "components/ActionsDialog";
import {
  DraftVariationAgreement,
  VariationActionType,
} from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendNotifyAgreementOfVariationModal } from "./useSendNotifyAgreementOfVariationModal";
import { VariationProposalAgreementDeterminationSummary } from "../components/VariationProposalAgreementDeterminationSummary";

export type SendNotifyAgreementOfVariationModalProps = {
  draftVariationAgreements: DraftVariationAgreement[];
} & ActionsDialogProps;

export const SendNotifyAgreementOfVariationModal: React.FC<
  SendNotifyAgreementOfVariationModalProps
> = ({ draftVariationAgreements, ...restDialogProps }) => {
  const { variationPreview, contract } = useSendNotifyAgreementOfVariationModal(
    draftVariationAgreements[0].id
  );
  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.NotifyAgreementVariation}
      summaryEl={
        <VariationProposalAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftVariationAgreements}
          type="VariationAgreement"
        />
      }
      {...restDialogProps}
    />
  );
};
