import {
  RemoveAttachmentFileMutation,
  RemoveAttachmentFileMutationVariables,
} from "generated/graphql";
import { removeAttachmentFileMutation } from "graphql/mutations/removeAttachmentFile";
import { useGraphMutation } from "hooks/useGraphMutation";

/**
 * Returns function to remove attachment from S3 bucket
 * @returns Function to remove attachment from S3 bucket.
 */
export const useRemoveAttachmentFile = () => {
  const [removeAttachmentFile] = useGraphMutation<
    RemoveAttachmentFileMutation,
    RemoveAttachmentFileMutationVariables
  >(
    removeAttachmentFileMutation,
    {
      update: (cache) => {
        // TODO: why only these two?
        cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItem" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "draftEarlyWarningItem" });
        cache.gc();
      },
    },
    null
  );

  return removeAttachmentFile;
};
