import { GridColDef } from "@mui/x-data-grid-pro";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCommonAuthorizationColumns } from "../Authorizations.constants";

export const useColumns = () => {
  const { t } = useTranslation();

  const commonAuthColumns = useCommonAuthorizationColumns();

  return useMemo((): GridColDef<AuthorizationWorkflowAudit>[] => {
    return [
      ...commonAuthColumns,
      {
        field: "initiator",
        headerName: t("Home.Authorizations.initiator"),
        flex: 0.125,
        resizable: true,
        valueGetter: (_, row) => getUserName(row.initiatedBy),
      },
    ];
  }, [t, commonAuthColumns]);
};
