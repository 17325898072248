import { styled, css, Box } from "@mui/material";
import throttle from "lodash.throttle";
import { useLayoutEffect, useState } from "react";
import { customShadows } from "theme/extendedTheme";

export const StyledProductItemHeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "stuck",
})<{
  stuck?: boolean;
}>(
  ({ theme, stuck }) => css`
    background: ${theme.palette.common.white};
    border-radius: 16px;
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    display: flex;
    align-items: center;
    box-shadow: ${customShadows.activeSmall};
    position: sticky;
    top: 178px; // 64 + 114 (mainNavigationHeight + ExplorerHeight)
    z-index: 20;

    ${stuck &&
    css`
      width: calc(100% - 48px);
      border-radius: 0;
      transform: translateX(-48px);
      padding-left: ${theme.spacing(9)};
      padding-right: ${theme.spacing(9)};
      [data-testid="btns-container"] {
        margin-right: 24px;
      }
    `}
  `
);

const hardcodedScrollLimit = 32;
const scrollCBThrottlingTime = 100;

export const ProductItemHeaderContainer: React.FC<{
  children: React.ReactNode | ((isHeaderStuck?: boolean) => React.ReactNode);
}> = ({ children }) => {
  const [isHeaderStuck, setIsHeaderStuck] = useState<boolean>();

  useLayoutEffect(() => {
    // Note: First parent element which is scrollable, which is #root for now. To change here if this ever changes
    const stickyElmScrollableParent = document.querySelector("#root");
    const debouncedCB = throttle((e) => {
      const newIsStuck = (e.target as any)?.scrollTop >= hardcodedScrollLimit;
      setIsHeaderStuck(newIsStuck);
    }, scrollCBThrottlingTime);

    stickyElmScrollableParent!.addEventListener("scroll", debouncedCB);
  }, []);

  return (
    <StyledProductItemHeaderContainer stuck={isHeaderStuck}>
      {typeof children === "function" ? children(isHeaderStuck) : children}
    </StyledProductItemHeaderContainer>
  );
};
