import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const InfoLiteIcon: React.FC<BaseIconProps> = ({
  height = "16",
  width = "16",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[600];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99906 13.9981C11.3122 13.9981 13.9981 11.3122 13.9981 7.99906C13.9981 4.68587 11.3122 2 7.99906 2C4.68587 2 2 4.68587 2 7.99906C2 11.3122 4.68587 13.9981 7.99906 13.9981Z"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 7.5H7.99984V10.9989H8.49968"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87507 6.00013C8.28931 6.00013 8.62513 5.66431 8.62513 5.25006C8.62513 4.83582 8.28931 4.5 7.87507 4.5C7.46082 4.5 7.125 4.83582 7.125 5.25006C7.125 5.66431 7.46082 6.00013 7.87507 6.00013Z"
        fill={computedColor}
      />
    </svg>
  );
};
