import { gql } from "@apollo/client";

export const changeProductInstanceStatusMutation = gql`
  mutation changeProductInstanceStatus(
    $id: ID!
    $status: ProductInstanceStatus!
  ) {
    changeProductInstanceStatus(id: $id, status: $status) {
      id
      description
      status
    }
  }
`;
