import { Box, css, styled } from "@mui/material";

export const Tag = styled(Box)<{ height?: string }>(
  ({ theme, height = "30px" }) => css`
    && {
      background: ${theme.palette.common.black};
      color: ${theme.palette.common.white};
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 ${theme.spacing(1)};
      height: ${height};
    }
  `
);
