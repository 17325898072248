import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const ChangeIcon: React.FC<BaseIconProps> = ({
  height = "41",
  width = "41",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40.0048"
        height="40.0048"
        rx="8"
        fill={theme.palette.grey[100]}
      />
      <path
        d="M24.5234 17.3486H29.0243V12.8477"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1719 14.1698C14.9374 13.4032 15.8466 12.795 16.8473 12.38C17.8481 11.9651 18.9209 11.7515 20.0043 11.7515C21.0877 11.7515 22.1605 11.9651 23.1613 12.38C24.162 12.795 25.0712 13.4032 25.8367 14.1698L29.0249 17.3486"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4814 22.6562H10.9805V27.1572"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8335 25.835C25.0679 26.6017 24.1588 27.2099 23.158 27.6248C22.1572 28.0398 21.0844 28.2534 20.001 28.2534C18.9176 28.2534 17.8449 28.0398 16.8441 27.6248C15.8433 27.2099 14.9341 26.6017 14.1686 25.835L10.9805 22.6562"
        stroke={computedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
