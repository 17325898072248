import { ContractType, DocumentTemplateRoute } from "generated/graphql";
import {
  LocalDocumentTemplateRoute,
  LocalDocumentTemplateRouteLite,
} from "./DocumentTemplateRoutes.decl";

export const sortDocumentTemplateRoutes = (
  rows: LocalDocumentTemplateRoute[]
) => {
  return rows.sort((route1, route2) => {
    return route1.product?.name! < route2.product?.name!
      ? -1
      : route1.product?.name! > route2.product?.name!
      ? 1
      : route1.outputAction?.name! < route2.outputAction?.name!
      ? -1
      : route1.outputAction?.name! > route2.outputAction?.name!
      ? 1
      : route1.contractType?.name! < route2.contractType?.name!
      ? -1
      : route1.contractType?.name! > route2.contractType?.name!
      ? 1
      : route1.originatingParty!.name! < route2.originatingParty!.name!
      ? -1
      : route1.originatingParty!.name! > route2.originatingParty!.name!
      ? 1
      : route1.targetParty!.name! < route2.targetParty!.name!
      ? -1
      : route1.targetParty!.name! > route2.targetParty!.name!
      ? 1
      : route1.documentTemplate!.name < route2.documentTemplate!.name
      ? -1
      : route1.documentTemplate!.name > route2.documentTemplate!.name
      ? 1
      : 0;
  });
};

export const computeContractTypeFullName = (contractType?: ContractType) => {
  return contractType
    ? `${contractType.description} ${contractType.version ?? ""} ${
        contractType.subType ?? ""
      }`
    : "";
};

export const documentTemplateRoutesToLocalDocumentTemplateRoutes = (
  routes: DocumentTemplateRoute[]
): LocalDocumentTemplateRoute[] => {
  return routes.map((route) => {
    return {
      id: route.id,
      product: {
        id: route.outputAction.product.id,
        name: route.outputAction.product.name,
      },
      outputAction: {
        id: route.outputAction.id,
        name: route.outputAction.name,
      },
      contractType: {
        id: route.contractType.id,
        name: computeContractTypeFullName(route.contractType),
      },
      originatingParty: {
        id: route.originatingParty.id,
        name: route.originatingParty.description,
      },
      targetParty: {
        id: route.targetParty.id,
        name: route.targetParty.description,
      },
      documentTemplate: {
        id: route.documentTemplate.id,
        name: route.documentTemplate.name,
      },
      changesCommitted: true,
    };
  });
};

export const computeRowIds = (
  newRow:
    | LocalDocumentTemplateRoute
    | {
        id: string;
        product?: string;
        outputAction?: string;
        contractType?: string;
        originatingParty?: string;
        targetParty?: string;
        documentTemplate?: string;
      }
): LocalDocumentTemplateRouteLite => {
  const productId =
    typeof newRow.product === "string" ? newRow.product : newRow.product!.id;
  const outputActionId =
    typeof newRow.outputAction === "string"
      ? newRow.outputAction
      : newRow.outputAction!.id;
  const contractTypeId =
    typeof newRow.contractType === "string"
      ? newRow.contractType
      : newRow.contractType!.id;
  const originatingPartyId =
    typeof newRow.originatingParty === "string"
      ? newRow.originatingParty
      : newRow.originatingParty!.id;
  const targetPartyId =
    typeof newRow.targetParty === "string"
      ? newRow.targetParty
      : newRow.targetParty!.id;
  const documentTemplateId =
    typeof newRow.documentTemplate === "string"
      ? newRow.documentTemplate
      : newRow.documentTemplate!.id;

  return {
    productId,
    outputActionId,
    contractTypeId,
    originatingPartyId,
    targetPartyId,
    documentTemplateId,
  };
};
