import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const DownloadIcon: React.FC<BaseIconProps> = ({
  height = "25",
  width = "25",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.05469 10.3154L12.9923 14.2531L16.93 10.3154"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9961 3.75098V14.2531"
        stroke="black"
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2455 14.2539V19.505C21.2455 19.7039 21.1665 19.8947 21.0258 20.0354C20.8851 20.1761 20.6943 20.2551 20.4954 20.2551H5.49234C5.29339 20.2551 5.10258 20.1761 4.9619 20.0354C4.82122 19.8947 4.74219 19.7039 4.74219 19.505V14.2539"
        stroke="black"
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
