import { Box, CircularProgress, CircularProgressProps } from "@mui/material";

export type CenteredLoadingIndicatorProps = CircularProgressProps;

export const CenteredLoadingIndicator: React.FC<
  CenteredLoadingIndicatorProps
> = (loadingIndicatorProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <CircularProgress {...loadingIndicatorProps} />
    </Box>
  );
};
