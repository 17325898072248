import { Box, Stack, ThemeProvider, Typography } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { NewAppPaths } from "helpers/paths/paths";
import { useContext } from "react";
import { matchPath, Navigate, Outlet, useLocation } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { LeftSideNavigation } from "./components/LeftSideNavigation/LeftSideNavigation";
import { useSystemVersion } from "hooks/useSystemVersion";

export const Settings = () => {
  const location = useLocation();
  const { authenticatedUser, loading: authenticatedUserLoading } =
    useContext(GlobalContext);

  const { systemVersionData, loading: systemVersionLoading } =
    useSystemVersion();

  if (matchPath("/settings", location.pathname)) {
    return (
      <Navigate
        to={NewAppPaths.authorized.Settings.children.ProfileEdit}
        replace
      />
    );
  }

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <NewPageContentContainer
        display="flex"
        sx={{
          alignItems: "flex-start",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
      >
        <Stack sx={{ width: "412px" }}>
          <LeftSideNavigation
            authenticatedUser={authenticatedUser}
            loading={authenticatedUserLoading || systemVersionLoading}
          />
          <Stack direction="row" spacing={0.25} mt={1} ml={3.35}>
            <Typography variant="caption3" color="grey.500">
              {`v${process.env.REACT_APP_VERSION};`}
            </Typography>
            <Typography variant="caption3" color="grey.500">
              {`system v${systemVersionData?.assemblyVersion}`}
            </Typography>
          </Stack>
        </Stack>
        <Box flex={1} ml={3} height="100%">
          <Outlet />
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
