import {
  EarlyWarningItem,
  EventItem,
  InstructionItem,
  RiskItem,
} from "generated/graphql";

export type ProductItem =
  | RiskItem
  | EarlyWarningItem
  | EventItem
  | InstructionItem;

export enum EWInstructionAction {
  Send = "Send",
  Record = "Record",
}
