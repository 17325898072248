import { useState } from "react";
import { Box, css, styled, useTheme } from "@mui/material";
import { ArrowsOut } from "phosphor-react";

export type SmallProductItemPreviewProps = {
  imageUrl: string;
  onClick: () => void;
};

const ExpandButton = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    bottom: 16px;
    right: 16px;
    background: white;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid ${theme.palette.grey[200]};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(1.5)};
  `
);

export const SmallProductItemPreview: React.FC<
  SmallProductItemPreviewProps
> = ({ imageUrl, onClick }) => {
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: "12px",
        border: "1px solid",
        borderColor: theme.palette.grey[300],
        height: "320px",
        overflow: "hidden",
      }}
      position="relative"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <img
        src={imageUrl}
        width="100%"
        style={{ objectFit: "contain" }}
        alt="The preview of the action to be done"
      />
      {hovered && (
        <ExpandButton>
          <ArrowsOut size={24} />
        </ExpandButton>
      )}
    </Box>
  );
};
