import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CommentWithReplyEditor } from "./Comment/CommentWithReplyEditor";
import { UserMentionOption } from "./Editor/plugins/MentionsPlugin/useMentionLookupService";
import { LocalComment } from "./useComments";

export type CommentsListProps = {
  comments: LocalComment[];
  loading?: boolean;
  inlineEditorDisabled?: boolean;
  typeaheadOptions: UserMentionOption[];
  hasMore: boolean;
  readOnly?: boolean;
  onSendReply: (comment: string, commentToReplyToId: string) => void;
  onLoadMoreComments: () => void;
};

export const CommentsList: React.FC<CommentsListProps> = ({
  comments,
  loading,
  inlineEditorDisabled,
  typeaheadOptions,
  hasMore,
  readOnly,
  onSendReply,
  onLoadMoreComments,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress size="20px" />
        </Box>
      )}
      {comments.map((comment, index) => (
        <Box key={comment.id} mt={index === 0 ? 0 : 3}>
          <CommentWithReplyEditor
            comment={comment}
            onSend={onSendReply}
            readOnly={readOnly}
            loading={!!inlineEditorDisabled}
            typeaheadOptions={typeaheadOptions}
          />
        </Box>
      ))}
      {hasMore && (
        <Box display="flex" justifyContent="center">
          <LoadingButton
            variant="text"
            onClick={onLoadMoreComments}
            loading={loading}
          >
            {t("common.buttons.loadMore")}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};
