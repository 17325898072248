import { gql } from "@apollo/client";
import { contractKeyDateFields } from "graphql/fragments/contractKeyDate.fragment";
import { contractSectionFields } from "graphql/fragments/contractSection.fragment";

export const contractByIdQuery = gql`
  ${contractSectionFields}
  ${contractKeyDateFields}

  query contractById($id: ID!) {
    contract(id: $id) {
      id
      name
      friendlyName
      number
      contractTypeId
      timeZone
      valueCurrency
      value
      contractType {
        description
        subType
        version
        id
      }
      sections {
        ...ContractSectionFields
      }
      keyDates {
        ...ContractKeyDateFields
      }
    }
  }
`;
