import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { itemDataFields } from "./itemData.fragment";

export const voLastActionFields = gql`
  fragment VOLastActionFields on VariationItemLastAction {
    actionType
    date
    daysLate
    party {
      id
      description
      contractTypeId
      status
    }
  }
`;

export const voNextStepFields = gql`
  fragment VONextStepFields on VariationItemNextStep {
    actionType
    dueDate
    partyId
    party {
      id
      description
      contractTypeId
      status
    }
  }
`;

export const variationItemFields = gql`
  ${creatorFields}
  ${itemDataFields}
  ${voLastActionFields}
  ${voNextStepFields}

  fragment VariationItemFields on VariationItem {
    __typename
    id
    number
    productInstanceId
    regardingId
    type
    status
    priceSought
    priceGranted
    timeSought
    timeGranted
    dateCreated
    dateModified
    creatorId
    lastAction {
      ...VOLastActionFields
    }
    nextStep {
      ...VONextStepFields
    }
    creator {
      ...CreatorFields
    }
    productInstance {
      id
      description
      numberingFormat
      status
      product {
        id
        name
      }
      contract {
        id
        name
        friendlyName
        status
        timeZone
        valueCurrency
        project {
          id
          name
          friendlyName
          status
        }
      }
    }
    regardingInstruction {
      id
      title
      number
      productInstanceId
      data {
        ...ItemDataFields
      }
      productInstance {
        id
        product {
          id
        }
      }
    }
    # ongoingAuthorizationAudit: AuthorizationWorkflowAudit
  }
`;
