import {
  VariationAction,
  VariationActionQuery,
  VariationActionQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { variationActionQuery } from "../queries/variationActionList.query";

export const useVariationWidgetNextStepSection = (variationId?: string) => {
  const { data: variationActionList, loading: variationActionListLoading } =
    useGraphQuery<VariationActionQuery, VariationActionQueryVariables>(
      variationActionQuery,
      {
        variables: {
          input: {
            variationId: variationId!,
          },
        },
        skip: !variationId,
      }
    );

  return {
    variationActionList: (variationActionList?.variationAction.items ??
      []) as VariationAction[],
    loading: variationActionListLoading,
  };
};
