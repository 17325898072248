export const preferencesList = {
  authorizationWorkflows: {
    label: "Settings.preferences.authorizationWorkflows",
    items: [
      {
        label: "Settings.preferences.authorizationRequired",
        key: "authorizationRequired",
      },
      {
        label: "Settings.preferences.authorizedActionExecuted",
        key: "authorizedActionExecuted",
      },
      {
        label: "Settings.preferences.authorizedActionPrevented",
        key: "authorizedActionPrevented",
      },
    ],
  },
  ownershipAsignment: {
    label: "Settings.preferences.ownershipAssignment",
    items: [
      {
        label: "Settings.preferences.ownershipOfItem",
        key: "ownershipAssignment",
      },
    ],
  },
  comments: {
    label: "Settings.preferences.comments",
    items: [
      {
        label: "Settings.preferences.commentsOnItemsYouOwn",
        key: "newComments",
      },
      {
        label: "Settings.preferences.commentsYouAreMentionedIn",
        key: "commentsMentioned",
      },
      {
        label: "Settings.preferences.repliesToYourComments",
        key: "commentsReplied",
      },
    ],
  },
  attachments: {
    label: "Attachments.attachments",
    items: [
      {
        label: "Settings.preferences.attachmentsEdited",
        key: "attachmentChanges",
      },
    ],
  },
  risks: {
    label: "Projects.Risks.risks",
    items: [
      {
        label: "Settings.preferences.anyNewRisk",
        key: "anyNewRisk",
      },
      {
        label: "Settings.preferences.changesToAnyRisk",
        key: "changesToAnyRisk",
      },
    ],
  },
  earlyWarnings: {
    label: "Settings.preferences.earlyWarnings",
    items: [
      {
        label: "Settings.preferences.anyNewEarlyWarningSent",
        key: "anyNewSentEW",
      },
      {
        label: "Settings.preferences.anyNewEarlyWarningRecorded",
        key: "anyNewRecordedEW",
      },
      {
        label: "Settings.preferences.changesToAnyEarlyWarning",
        key: "changesToAnyEW",
      },
    ],
  },
  events: {
    label: "Settings.preferences.events",
    items: [
      {
        label: "Settings.preferences.anyNewEvent",
        key: "anyNewEvent",
      },
      {
        label: "Settings.preferences.changesToAnyEvent",
        key: "changesToAnyEvent",
      },
    ],
  },
  instructions: {
    label: "Settings.preferences.instructions",
    items: [
      {
        label: "Settings.preferences.anyNewRecordedInstruction",
        key: "anyNewRecordedInstruction",
      },
      {
        label: "Settings.preferences.anyNewSentInstruction",
        key: "anyNewSentInstruction",
      },
      {
        label: "Settings.preferences.changesToAnyInstruction",
        key: "changesToAnyInstruction",
      },
    ],
  },
  compensationEvents: {
    label: "Settings.preferences.compensationEvents",
    items: [
      {
        label: "Settings.preferences.anyNewCEActionRecorded",
        key: "anyNewCEActionRecorded",
      },
      {
        label: "Settings.preferences.anyNewCEActionSent",
        key: "anyNewCEActionSent",
      },
    ],
  },
  claims: {
    label: "Settings.preferences.claims",
    items: [
      {
        label: "Settings.preferences.anyNewClaimActionSent",
        key: "anyNewClaimActionSent",
      },
      {
        label: "Settings.preferences.anyNewClaimActionRecorded",
        key: "anyNewClaimActionRecorded",
      },
    ],
  },
  variations: {
    label: "Settings.preferences.variations",
    items: [
      {
        label: "Settings.preferences.anyNewVariationActionSent",
        key: "anyNewVariationActionSent",
      },
      {
        label: "Settings.preferences.anyNewVariationActionRecorded",
        key: "anyNewVariationActionRecorded",
      },
    ],
  },
};
