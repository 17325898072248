import {
  Box,
  Breadcrumbs,
  Collapse,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DetailsList } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { PageContentContainer } from "components/PageContentContainer";
import {
  AuthorizationWorkflowLevels,
  AuthorizationWorkflowLevelInputWithID,
} from "../components/AuthorizationWorkflowLevels/AuthorizationWorkflowLevels";
import { useAuthorizationWorkflowDetails } from "./useAuthorizationWorkflowDetails";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuthorizationWorkflowUserCandidates } from "hooks/useAuthorizationWorkflowUserCandidates";
import {
  AddAuthorizationWorkflowInput,
  AuthorizationWorkflowStatus,
} from "generated/graphql";
import { useProjectLite } from "containers/AdminConsole/hooks/useProjectLite";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { ShieldCheckered } from "phosphor-react";
import { authorizationWorkflowLevelsToAuthWorkflowLevelsInput } from "./AuthorizationWorkflowDetails.utils";
import { NewAppPaths } from "helpers/paths/paths";
import { getUserName } from "helpers/miscelaneous";
import { workflowLevelsToLocalWorkflowLevels } from "../components/AuthorizationWorkflowLevels/AuthorizationWorkflowLevels.utils";
import { FormErrorLabel } from "components/FormErrorLabel";

type FormDataType = Omit<
  AddAuthorizationWorkflowInput,
  "levels" | "projectId"
> & { levels: AuthorizationWorkflowLevelInputWithID[] };

const dataValidators: DataValidators = {
  name: {
    validators: [ValidatorType.Required],
  },
  levels: {
    validators: [ValidatorType.NonEmptyArray],
  },
};

export const AuthorizationWorkflowDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { projectId, authWorkflowId } = useParams();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<AuthorizationWorkflowStatus>[];

  const originalWFLevelsRef = useRef<AuthorizationWorkflowLevelInputWithID[]>();

  const [formData, setFormData] = useState<FormDataType>({
    name: "",
    levels: [],
  });
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const [readOnly, setReadOnly] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  const { projectDataLite, loading: projectDataLoading } = useProjectLite(
    projectId!
  );

  const { userCandidates, loading: userCandidatesLoading } =
    useAuthorizationWorkflowUserCandidates(projectId!);

  const {
    authorizationWorkflow,
    changeAuthorizationWorkflowStatus,
    editAuthorizationWorkflow,
    editAuthorizationWorkflowLoading,
    loading: authWorkflowLoading,
  } = useAuthorizationWorkflowDetails(authWorkflowId!);

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const validationResult = validateData(formData, dataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);

    return false;
  }, []);

  const handleBreadcrumbClick = (parent?: "project") => {
    if (parent === "project") {
      navigate(-1);
    } else {
      navigate(-2);
    }
  };

  const handleStatusChange = useCallback(
    async (newStatus: AuthorizationWorkflowStatus) => {
      await changeAuthorizationWorkflowStatus({
        variables: {
          id: authWorkflowId!,
          status: newStatus,
        },
      });
    },
    [changeAuthorizationWorkflowStatus, authWorkflowId]
  );

  const handleAuthWorkflowLevelsChange = (
    workflowLevels: AuthorizationWorkflowLevelInputWithID[]
  ) => {
    setFormData((curData) => ({
      ...curData,
      levels: workflowLevels,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { levels: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleEditClick = useCallback(() => {
    setReadOnly(false);
    setShowDetails(true);
  }, []);

  const handleCancelClick = useCallback(() => {
    setFormData({
      name: authorizationWorkflow?.name!,
      levels: originalWFLevelsRef.current!,
    });
    setReadOnly(true);
  }, [authorizationWorkflow]);

  const handleSaveClick = useCallback(async () => {
    if (!validateForm(formData) || !authorizationWorkflow) {
      return;
    }

    const { errors, data } = await editAuthorizationWorkflow({
      variables: {
        input: {
          id: authorizationWorkflow.id,
          levels: authorizationWorkflowLevelsToAuthWorkflowLevelsInput(
            formData.levels
          ),
          name: formData.name,
        },
      },
    });

    if (!errors) {
      setReadOnly(true);
    }

    if (
      data &&
      data.editAuthorizationWorkflow.id !== authorizationWorkflow!.id &&
      projectId
    ) {
      // ID changed, that means user updated the levels which means the old WF's status changed to Removed/Void and a new WF with the new levels was created.
      // Navigating to the new WF details page
      navigate(
        NewAppPaths.authorized.AdminConsole.children.Projects.children.AuthorizationWorkflowDetails.pathConstructor(
          projectId,
          data.editAuthorizationWorkflow.id
        ),
        { replace: true }
      );
    }
  }, [
    editAuthorizationWorkflow,
    formData,
    authorizationWorkflow,
    validateForm,
    navigate,
    projectId,
  ]);

  useEffect(() => {
    if (authorizationWorkflow) {
      originalWFLevelsRef.current = workflowLevelsToLocalWorkflowLevels(
        authorizationWorkflowLevelsToAuthWorkflowLevelsInput(
          authorizationWorkflow.levels
        )
      );

      setFormData({
        name: authorizationWorkflow.name,
        levels: originalWFLevelsRef.current,
      });
    }
  }, [authorizationWorkflow]);

  const contractEntityObj = useMemo(() => {
    if (!authorizationWorkflow) {
      return [];
    }

    return [
      {
        id: "name",
        label: t("common.labels.name"),
        value: authorizationWorkflow.name,
      },
    ];
  }, [authorizationWorkflow, t]);

  const loading =
    authWorkflowLoading || userCandidatesLoading || projectDataLoading;

  const creatorStr = useMemo(() => {
    if (
      authorizationWorkflow?.creator.firstname &&
      authorizationWorkflow?.creator.surname
    ) {
      return getUserName(authorizationWorkflow?.creator);
    }

    return "";
  }, [authorizationWorkflow]);

  return (
    <Box>
      <EntityDetailsHeader
        loading={loading}
        title={authorizationWorkflow?.name}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={() => handleBreadcrumbClick()}>
              <Typography variant="body2">
                {t("AdminConsole.Projects.labels.projects")}
              </Typography>
            </Link>
            <Link key="2" onClick={() => handleBreadcrumbClick("project")}>
              <Typography variant="body2">
                {projectDataLite?.friendlyName}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        status={authorizationWorkflow?.status}
        statusOptions={statusOptions}
        creator={creatorStr}
        dateCreated={authorizationWorkflow?.dateCreated}
        onStatusChange={handleStatusChange}
      />
      <CollapsibleHeader
        title={t(
          "AdminConsole.AuthorizationWorkflows.authorizationWorkflowDetails"
        )}
        collapsed={!showDetails}
        icon={<ShieldCheckered color={theme.palette.primary.main} />}
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onCancel={handleCancelClick}
        onEdit={handleEditClick}
        onSave={handleSaveClick}
        onSaveActionLoading={editAuthorizationWorkflowLoading}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList loading={loading} entity={contractEntityObj} />
          ) : loading ? null : (
            <TextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.name")}
              variant="standard"
              error={!!formDataErrors.name}
              helperText={formDataErrors.name}
              InputLabelProps={{ shrink: true }}
              required
            />
          )}
        </PageContentContainer>
      </Collapse>
      <Box px={4}>
        <AuthorizationWorkflowLevels
          users={userCandidates}
          loading={userCandidatesLoading}
          workflowLevels={formData.levels}
          onChange={handleAuthWorkflowLevelsChange}
          readOnly={readOnly}
        />
        {!!formDataErrors.levels && (
          <FormErrorLabel
            dataTestId="levels-error-msg"
            errorMessage={formDataErrors.levels}
          />
        )}
      </Box>
    </Box>
  );
};
