import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateSendCompEventConfirmationPreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateSendCompEventConfirmationPreview(
    $input: SendCompEventConfirmationPreviewInput!
  ) {
    generateSendCompEventConfirmationPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
