import { css, styled, TextField } from "@mui/material";

// TODO ADI B. check if there's a way to define this directly in the theme
// TODO: Could be deleted when applying the new theme over all the app
export const DisabledTextFields = styled(TextField)(
  ({ theme }) => css`
    && {
      label {
        color: ${theme.palette.primary.main};
      }

      .MuiInput-root.MuiInput-underline.MuiInputBase-root::before {
        border-bottom-style: solid;
        border-color: ${theme.palette.primary.main};
      }

      .MuiInput-root.MuiInput-underline.MuiInputBase-root {
        input.Mui-disabled {
          color: ${theme.palette.primary.main};
          -webkit-text-fill-color: ${theme.palette.primary.main};
        }
      }
    }
  `
);
