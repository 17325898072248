import { gql } from "@apollo/client";
import { dailyDiaryGeneralRecordLiteFields } from "graphql/fragments/dailyDiaryGeneralRecordLite.fragment";

export const editDailyDiaryGeneralRecordMutation = gql`
  ${dailyDiaryGeneralRecordLiteFields}

  mutation editDailyDiaryGeneralRecord(
    $input: EditDailyDiaryGeneralRecordInput!
  ) {
    editDailyDiaryGeneralRecord(input: $input) {
      ...DailyDiaryGeneralRecordLiteFields
    }
  }
`;
