export enum IntercomEvents {
  CreatedEvent = "Created Event",
  CreatedRisk = "Created Risk",
  SentEarlyWarning = "Sent Early Warning",
  RecordedEarlyWarning = "Recorded Early Warning",
  SentInstruction = "Sent Instruction",
  RecordedInstruction = "Recorded Instruction",
  SentDailyDiary = "Sent Daily Diary",
  ApprovedDailyDiary = "Approved Daily Diary",
  RejectedDailyDiary = "Rejected Daily Diary",
  AddedComment = "Added comment",
  ViewedNotifications = "Viewed Notifications",
  ViewedEvent = "Viewed Event",
  ViewedRisk = "Viewed Risk",
  ViewedEarlyWarning = "Viewed Early Warning",
  ViewedInstruction = "Viewed Instruction",
  ViewedDailyDiary = "Viewed Daily Diary",
}
