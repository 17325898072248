import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationRevocationPromptQuery = gql`
  query variationRevocationPrompt($input: VariationRevocationPromptInput!) {
    variationRevocationPrompt(input: $input) {
      remarks
    }
  }
`;

export const sendVariationRevocationMutation = gql`
  mutation sendVariationRevocation($input: SendVariationRevocationInput!) {
    sendVariationRevocation(input: $input) {
      id
    }
  }
`;

export const recordVariationRevocationMutation = gql`
  mutation recordVariationRevocation($input: RecordVariationRevocationInput!) {
    recordVariationRevocation(input: $input) {
      id
    }
  }
`;

export const generateSendVariationRevocationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationRevocationPreview(
    $input: SendVariationRevocationPreviewInput!
  ) {
    generateSendVariationRevocationPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationRevocationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationRevocationPreview(
    $input: RecordVariationRevocationPreviewInput!
  ) {
    generateRecordVariationRevocationPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
