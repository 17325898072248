import {
  ClaimAgreement,
  ClaimDetailsExplorerItem,
  ClaimDetailsExplorerItemsQuery,
  ClaimDetailsExplorerItemsQueryVariables,
  ClaimDetermination,
  DetailedClaim,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { claimDetailsExplorerItemsQuery } from "./useClaimDetailsExplorerItems.query";
import { useContext, useMemo } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";

export const useClaimDetailsExplorerItems = (claimId?: string) => {
  const { claim } = useContext(ClaimWidgetContext);

  const { data: claimDetailsExplorerItemsData, loading } = useGraphQuery<
    ClaimDetailsExplorerItemsQuery,
    ClaimDetailsExplorerItemsQueryVariables
  >(claimDetailsExplorerItemsQuery, {
    variables: { claimId: claimId ?? claim?.id! },
    skip: !claimId && !claim,
  });

  const detailedClaims = useMemo(
    () =>
      (claimDetailsExplorerItemsData?.claimDetailsExplorerItems.items.filter(
        (item) => item.__typename === "DetailedClaim"
      ) || []) as DetailedClaim[],
    [claimDetailsExplorerItemsData]
  );

  const claimAgreements = useMemo(
    () =>
      (claimDetailsExplorerItemsData?.claimDetailsExplorerItems.items.filter(
        (item) => item.__typename === "ClaimAgreement"
      ) || []) as ClaimAgreement[],
    [claimDetailsExplorerItemsData]
  );

  const claimDeterminations = useMemo(
    () =>
      (claimDetailsExplorerItemsData?.claimDetailsExplorerItems.items.filter(
        (item) => item.__typename === "ClaimDetermination"
      ) || []) as ClaimDetermination[],
    [claimDetailsExplorerItemsData]
  );

  const detailsExplorerItems = useMemo(
    () =>
      (claimDetailsExplorerItemsData?.claimDetailsExplorerItems.items ??
        []) as ClaimDetailsExplorerItem[],
    [claimDetailsExplorerItemsData]
  );

  return {
    detailedClaims,
    claimAgreements,
    claimDeterminations,
    detailsExplorerItems,
    loading,
  };
};
