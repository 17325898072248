import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { itemDataFields } from "./itemData.fragment";

// TODO: this fragment is widely used. See if it really needs to contain all the nested data. Especially for Register view
export const compEventItemFields = gql`
  ${creatorFields}
  ${itemDataFields}

  fragment CompEventItemFields on CompEventItem {
    __typename
    id
    number
    productInstanceId
    regardingId
    regardingType
    status
    price
    time
    earlyWarningId
    dateCreated
    dateModified
    creatorId
    deemingDate
    lastAction {
      actionType
      daysLate
      date
    }
    nextStep {
      actionType
      dueDate
    }
    creator {
      ...CreatorFields
    }
    productInstance {
      id
      description
      numberingFormat
      status
      product {
        id
        name
      }
      contract {
        id
        name
        friendlyName
        status
        timeZone
        valueCurrency
        project {
          id
          name
          friendlyName
          status
        }
      }
    }
    earlyWarning {
      id
      title
      dateSent
      number
      title
      productInstance {
        id
        product {
          id
        }
      }
    }
    regardingEvent {
      id
      title
      productInstanceId
      number
      data {
        ...ItemDataFields
      }
      productInstance {
        id
        product {
          id
        }
      }
    }
    regardingInstruction {
      id
      title
      number
      productInstanceId
      data {
        ...ItemDataFields
      }
      productInstance {
        id
        product {
          id
        }
      }
    }
    notifiedByParty {
      id
      description
      contractTypeId
      status
    }
  }
`;
