import { VariationActionType } from "generated/graphql";

export enum VariationActionTypeFIDIC17White {
  RevokeInstructionOfVariationFIDIC17White = "RevokeInstructionOfVariationFIDIC17White",
  AbandonProposedVariationFIDIC17White = "AbandonProposedVariationFIDIC17White",
  ConsentToProposalForProposedVariationFIDIC17White = "ConsentToProposalForProposedVariationFIDIC17White",
  ConfirmInstructionOfVariationFIDIC17White = "ConfirmInstructionOfVariationFIDIC17White",
}

export enum VariationActionTypeFIDIC99RedYellow {
  ConsentToProposalForProposedVariation99RY = "ConsentToProposalForProposedVariation99RY",
  AbandonProposedVariation99RY = "AbandonProposedVariation99RY",
}

export type VariationActionTypeAll =
  | VariationActionType
  | VariationActionTypeFIDIC17White
  | VariationActionTypeFIDIC99RedYellow;

export const variationActionTypeFIDIC17WhiteTypeMapping = (
  actionType: VariationActionType
) => {
  switch (actionType) {
    case VariationActionType.AbandonProposedVariation:
      return VariationActionTypeFIDIC17White.AbandonProposedVariationFIDIC17White;
    case VariationActionType.RevokeInstructionOfVariation:
      return VariationActionTypeFIDIC17White.RevokeInstructionOfVariationFIDIC17White;
    case VariationActionType.ConsentToProposalForProposedVariation:
      return VariationActionTypeFIDIC17White.ConsentToProposalForProposedVariationFIDIC17White;
    case VariationActionType.ConfirmInstructionOfVariation:
      return VariationActionTypeFIDIC17White.ConfirmInstructionOfVariationFIDIC17White;
    default:
      return actionType;
  }
};

export const variationActionTypeFIDIC99RedYellowTypeMapping = (
  actionType: VariationActionType
) => {
  switch (actionType) {
    case VariationActionType.ConsentToProposalForProposedVariation:
      return VariationActionTypeFIDIC99RedYellow.ConsentToProposalForProposedVariation99RY;
    case VariationActionType.AbandonProposedVariation:
      return VariationActionTypeFIDIC99RedYellow.AbandonProposedVariation99RY;
    default:
      return actionType;
  }
};
