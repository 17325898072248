import { gql } from "@apollo/client";
import { dailyDiaryWorkRecordLiteFields } from "graphql/fragments/dailyDiaryWorkRecordLite.fragment";

export const editDailyDiaryWorkRecordMutation = gql`
  ${dailyDiaryWorkRecordLiteFields}

  mutation editDailyDiaryWorkRecord($input: EditDailyDiaryWorkRecordInput!) {
    editDailyDiaryWorkRecord(input: $input) {
      ...DailyDiaryWorkRecordLiteFields
    }
  }
`;
