import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  ClaimAction,
  ClaimActionType,
  ClaimItem,
  ClaimStatus,
  ProductType,
} from "generated/graphql";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { WidgetHeader } from "containers/Projects/components/Widget/WidgetHeader";
import {
  CEWidgetSectionType,
  WidgetSection,
} from "containers/Projects/components/Widget/WidgetSection";
import { ClaimWidgetContext } from "../ClaimWidget/ClaimWidget.context";
import { ClaimWidgetNextStepSection } from "../ClaimWidget/components/ClaimWidgetNextStepSection";
import { AuthorizationProgressPreview } from "components/Authorization/AuthorizationProgressPreview";
import { AuthorizationRequiredPill } from "components/Authorization/AuthorizationRequiredPill";
import { ActionModalFooter } from "containers/Projects/components/ActionModal/ActionModalFooter";
import { NotifyClaimAction } from "./NotifyClaimAction/NotifyClaimAction";
import { claimActionTypeTranslations } from "../ClaimWidget/ClaimWidget.constants";
import { SubmitInterimDetailedClaimAction } from "./SubmitInterimClaim/SubmitInterimDetailedClaimAction";
import { SubmitFinalDetailedClaimAction } from "./SubmitFinalDetailedClaimAction/SubmitFinalDetailedClaimAction";
import { NotifyAgreementClaimAction } from "./NotifyAgreementClaimAction/NotifyAgreementClaimAction";
import { useFinalDetailedClaim } from "./hooks/useFinalDetailedClaim/useFinalDetailedClaim";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DetailedClaimAgreementDeterminationReadOnlyModal } from "../DetailedClaimAgreementDeterminationReadOnlyModal/DetailedClaimAgreementDeterminationReadOnlyModal";
import { DetailedClaimsAgreementsDeterminationsTable } from "./components/DetailedClaimsAgreementsDeterminationsTable/DetailedClaimsAgreementsDeterminationsTable";
import { NotifyNoClaimAgreementAction } from "./NotifyNoClaimAgreementAction/NotifyNoClaimAgreementAction";
import { NotifyClaimDeterminationAction } from "./NotifyClaimDeterminationAction/NotifyClaimDeterminationAction";
import { RequestFurtherParticularsAction } from "./RequestFurtherParticularsAction/RequestFurtherParticularsAction";
import { ProvideFurtherParticularsAction } from "./ProvideFurtherParticularsAction/ProvideFurtherParticularsAction";
import { ConsiderNoticeOfClaimInvalidAction } from "./ConsiderNoticeOfClaimInvalidAction/ConsiderNoticeOfClaimInvalidAction";
import { NotifyClaimNoticeValidityHasLapsedAction } from "./NotifyClaimNoticeValidityHasLapsedAction/NotifyClaimNoticeValidityHasLapsedAction";
import { RespondRegardingDetailedClaimAction } from "./RespondRegardingDetailedClaimAction/RespondRegardingDetailedClaimAction";
import { NotifyErrorFoundInAgreement } from "./NotifyErrorFoundInAgreementAction/NotifyErrorFoundInAgreementAction";
import { DisagreeWithNoticeOfErrorInAgreement } from "./DisagreeWithNoticeOfErrorInAgreementAction/DisagreeWithNoticeOfErrorInAgreementAction";
import { useFinalClaimAgreement } from "./hooks/useFinalDetailedClaim/useFinalClaimAgreement";
import { useFinalDetermination } from "./hooks/useFinalDetailedClaim/useFinalDetermination";
import { NotifyErrorFoundInDetermination } from "./NotifyErrorFoundInDeterminationAction/NotifyErrorFoundInDeterminationAction";
import { DisagreeWithNoticeOfErrorInDetermination } from "./DisagreeWithNoticeOfErrorInDeterminationAction/DisagreeWithNoticeOfErrorInDeterminationAction";
import { claimActionTypeFIDIC99RedYellowTypeMapping } from "../types";

// Note: same as for CompEventAction
export type ClaimActionViewProps = {
  claim?: ClaimItem;
  claimActions: ClaimAction[];
  apiRef?: React.Ref<{ onBeforeClose: () => void }>;
  onCancel: () => void; // Note: this callback is called even when modal auto-closes after a successful action
};

// Note: same as for CompEventAction
export type ClaimFormPublicApi = {
  validate: () => boolean;
  record: () => void;
  send: () => void;
  onBeforeAbort?: () => void;
};

// Note: same as for CompEventAction
export type ClaimActionFormProps = {
  onClose: () => void;
  apiRef?: React.Ref<ClaimFormPublicApi>;
};

export const ClaimActionView: React.FC<ClaimActionViewProps> = ({
  claim,
  claimActions,
  apiRef,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { recordSupported, contract, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const [actionAwaitingAuthorization, setActionAwaitingAuthorization] =
    useState(false);
  const [selectedAction, setSelectedAction] = useState<ClaimActionType>();
  const formAPIRef = useRef<ClaimFormPublicApi | null>(null);

  const selectedActionFull = useMemo(
    () => claimActions.find((action) => action.type === selectedAction),
    [claimActions, selectedAction]
  );

  const { finalDetailedClaim, loading: finalDetailedClaimLoading } =
    useFinalDetailedClaim();
  const { finalAgreement, loading: finalAgreementLoading } =
    useFinalClaimAgreement();
  const { finalDetermination, loading: finalDeterminationLoading } =
    useFinalDetermination();

  const {
    modalVisibility: finalDetailedClaimModalVisibility,
    toggleModalVisibility: togglefinalDetailedClaimModalVisibility,
  } = useBasicModal();

  const {
    modalVisibility: finalAgreementDeterminationModalVisibility,
    toggleModalVisibility: toggleFinalAgreementDeterminationModalVisibility,
  } = useBasicModal();

  const handleActionChange = (event: SelectChangeEvent<ClaimActionType>) => {
    setSelectedAction(event.target.value as ClaimActionType);
  };

  const handleCancel = () => {
    formAPIRef.current?.onBeforeAbort?.();
    onCancel();
  };

  const renderClaimActionForm = useCallback(
    (actionType: ClaimActionType) => {
      // TODO: remove same logic from CE as well

      const ongoingAuthorizationProductOutputName =
        claim?.ongoingAuthorizationAudit?.action.productOutputAction.name;

      if (
        !!ongoingAuthorizationProductOutputName &&
        selectedActionFull?.actionMapping?.productOutputAction.name ===
          ongoingAuthorizationProductOutputName
      ) {
        // action already awaiting authorization. UseEffect will sort out the error display
        return;
      }

      switch (actionType) {
        case ClaimActionType.NotifyClaim:
          return <NotifyClaimAction apiRef={formAPIRef} onClose={onCancel} />;
        case ClaimActionType.SubmitInterimDetailedClaim:
          return (
            <SubmitInterimDetailedClaimAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.SubmitFinalDetailedClaim:
          return (
            <SubmitFinalDetailedClaimAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.NotifyAgreementClaim:
          return (
            <NotifyAgreementClaimAction
              apiRef={formAPIRef}
              onClose={onCancel}
              finalDetailedClaim={finalDetailedClaim}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        case ClaimActionType.NotifyAgreementClaimNotReached:
          return (
            <NotifyNoClaimAgreementAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.NotifyDeterminationClaim:
          return (
            <NotifyClaimDeterminationAction
              apiRef={formAPIRef}
              finalDetailedClaim={finalDetailedClaim}
              onClose={onCancel}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        case ClaimActionType.RequestFurtherParticulars:
          return (
            <RequestFurtherParticularsAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.ProvideFurtherParticulars:
          return (
            <ProvideFurtherParticularsAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.NotifyClaimNoticeInvalid:
          return (
            <ConsiderNoticeOfClaimInvalidAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.NotifyClaimNoticeValidityHasLapsed:
          return (
            <NotifyClaimNoticeValidityHasLapsedAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.RespondRegardingDetailedClaim:
          return (
            <RespondRegardingDetailedClaimAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.NotifyErrorFoundAgreement:
          return (
            <NotifyErrorFoundInAgreement
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.DisagreeWithNoticeErrorAgreement:
          return (
            <DisagreeWithNoticeOfErrorInAgreement
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.NotifyErrorFoundDetermination:
          return (
            <NotifyErrorFoundInDetermination
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case ClaimActionType.DisagreeWithNoticeErrorDetermination:
          return (
            <DisagreeWithNoticeOfErrorInDetermination
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        default:
          return null;
      }
    },
    [
      selectedActionFull,
      onCancel,
      claim?.ongoingAuthorizationAudit,
      finalDetailedClaim,
    ]
  );

  const handleRecord = () => {
    if (formAPIRef.current?.validate()) {
      formAPIRef.current?.record();
    }
  };

  const handleSend = () => {
    if (formAPIRef.current?.validate()) {
      formAPIRef.current?.send();
    }
  };

  const handleBeforeClose = useCallback(() => {
    formAPIRef.current?.onBeforeAbort?.();
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      onBeforeClose: handleBeforeClose,
    }),
    [handleBeforeClose]
  );

  useEffect(() => {
    // TODO: this is subject to being removed as BE probably updated the actionList such that it does not allow users to re-do the same action again
    // TODO: verify this in this patch
    if (selectedActionFull) {
      const ongoingAuthorizationProductOutputName =
        claim?.ongoingAuthorizationAudit?.action.productOutputAction.name;

      setActionAwaitingAuthorization(
        !!ongoingAuthorizationProductOutputName &&
          selectedActionFull.actionMapping?.productOutputAction.name ===
            ongoingAuthorizationProductOutputName
      );
    }
  }, [selectedActionFull, claim?.ongoingAuthorizationAudit]);

  const showLastDetailedClaim =
    (claim?.status === ClaimStatus.Interim &&
      (!selectedAction ||
        [
          ClaimActionType.SubmitInterimDetailedClaim,
          ClaimActionType.SubmitFinalDetailedClaim,
        ].indexOf(selectedAction) < 0)) ||
    (claim?.status === ClaimStatus.Final &&
      (!selectedAction ||
        [
          ClaimActionType.NotifyAgreementClaim,
          ClaimActionType.NotifyDeterminationClaim,
          ClaimActionType.RespondRegardingDetailedClaim,
        ].indexOf(selectedAction) < 0));

  const showAgreementOrDetermination =
    claim?.status === ClaimStatus.Agreed ||
    claim?.status === ClaimStatus.Determined;

  return (
    <Box display="flex" flexDirection="column" pt={1}>
      {claim && (
        <Box mb={3}>
          <WidgetHeader productItem={claim} productType={ProductType.Claims} />
        </Box>
      )}
      {claim?.ongoingAuthorizationAudit && (
        <Box mb={2}>
          <AuthorizationProgressPreview
            authorizationWorkflow={claim?.ongoingAuthorizationAudit.workflow}
            authorizationWorkflowAudit={
              claim?.ongoingAuthorizationAudit ?? undefined
            }
          />
        </Box>
      )}
      <WidgetSection type={CEWidgetSectionType.NextStep}>
        <ClaimWidgetNextStepSection
          claim={claim}
          previewMode
          claimActionList={claimActions}
        />
      </WidgetSection>
      {showLastDetailedClaim && (
        <Box mt={2}>
          {finalDetailedClaimLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              {finalDetailedClaimModalVisibility && finalDetailedClaim && (
                <DetailedClaimAgreementDeterminationReadOnlyModal
                  open={finalDetailedClaimModalVisibility}
                  onClose={togglefinalDetailedClaimModalVisibility}
                  item={finalDetailedClaim}
                  contract={contract}
                />
              )}
              <DetailedClaimsAgreementsDeterminationsTable
                items={[finalDetailedClaim]}
                contractCurrency={contract.valueCurrency ?? ""}
                type="DetailedClaim"
                onRowClick={togglefinalDetailedClaimModalVisibility}
              />
            </>
          )}
        </Box>
      )}
      {showAgreementOrDetermination && !selectedAction && (
        <Box mt={2}>
          {finalAgreementLoading || finalDeterminationLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              {finalAgreementDeterminationModalVisibility &&
                (finalAgreement || finalDetermination) && (
                  <DetailedClaimAgreementDeterminationReadOnlyModal
                    open={finalAgreementDeterminationModalVisibility}
                    onClose={toggleFinalAgreementDeterminationModalVisibility}
                    item={finalAgreement || finalDetermination}
                    contract={contract}
                  />
                )}
              <DetailedClaimsAgreementsDeterminationsTable
                items={[finalAgreement || finalDetermination]}
                contractCurrency={contract.valueCurrency ?? ""}
                type={finalAgreement ? "ClaimAgreement" : "ClaimDetermination"}
                onRowClick={toggleFinalAgreementDeterminationModalVisibility}
              />
            </>
          )}
        </Box>
      )}
      <WidgetSection type={CEWidgetSectionType.ActionSelection} mt={2}>
        <Typography variant="p1" color="grey.800" fontWeight={600} mb={1.25}>
          {t("Projects.Claims.ActionModal.actionsAvailable")}
        </Typography>
        <FormControl fullWidth>
          {!selectedAction && (
            <InputLabel shrink={false} sx={{ top: "-5px" }}>
              {t("Projects.Claims.ActionModal.actionPlaceholder")}
            </InputLabel>
          )}
          <Select
            labelId="actions-available-label"
            id="actions-available-id"
            value={selectedAction ?? ""}
            onChange={handleActionChange}
            data-testid="claim-action-dropdown"
            fullWidth
            size="small"
            sx={{ backgroundColor: "white" }}
            required
            SelectDisplayProps={{
              style: { display: "flex", alignItems: "center" },
            }}
          >
            {claimActions.map((claimActionType) => {
              const label = t(
                claimActionTypeTranslations[
                  isFIDIC99RedYellow
                    ? claimActionTypeFIDIC99RedYellowTypeMapping(
                        claimActionType.type
                      )
                    : claimActionType.type
                ]
              );

              return (
                <MenuItem
                  key={claimActionType.type}
                  value={claimActionType.type}
                  data-testid={`claim-action-${claimActionType.type}-option`}
                >
                  {label}
                  {claimActionType.actionMapping ? (
                    <Box ml={1}>
                      <AuthorizationRequiredPill />
                    </Box>
                  ) : null}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </WidgetSection>
      {selectedAction && !actionAwaitingAuthorization && (
        <Box mt={2}>{renderClaimActionForm(selectedAction)}</Box>
      )}
      {/* TODO: maybe this is not needed anymore, if user cannot select the same action twice */}
      {actionAwaitingAuthorization && (
        <Box mt={1}>
          <Typography variant="caption" color="error" mt={0.5}>
            {t("Home.Authorizations.actionAwaitingAuthorization")}
          </Typography>
        </Box>
      )}
      {/* TODO `authorizationWorkflow` should be present if requiresAuthorization is true - delete extra check after BE is fixed */}
      {selectedActionFull &&
        selectedActionFull.actionMapping &&
        !actionAwaitingAuthorization && (
          <Box mt={3}>
            <AuthorizationProgressPreview
              authorizationWorkflow={selectedActionFull.actionMapping.workflow}
            />
          </Box>
        )}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <ActionModalFooter
          sendDisabled={actionAwaitingAuthorization}
          recordDisabled={actionAwaitingAuthorization}
          recordSupported={recordSupported}
          onCancel={handleCancel}
          onRecord={handleRecord}
          onSend={handleSend}
        />
      </Box>
    </Box>
  );
};
