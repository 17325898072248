import { useGraphQuery } from "hooks/useGraphQuery";
import {
  bulkAssignUserRolesMutation,
  contractProductInstancesQuery,
  usersByContractQuery,
} from "./ContractUsers.query";
import {
  BulkAssignUserRolesMutation,
  BulkAssignUserRolesMutationVariables,
  ContractProductInstancesQuery,
  ContractProductInstancesQueryVariables,
  ProductInstance,
  ProductInstanceStatus,
  User,
  UserRoleAssignmentInput,
  UsersByContractQuery,
  UsersByContractQueryVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import uniqBy from "lodash.uniqby";
import { snackbarAutoHideDuration } from "constants/constants";

const recordsChunkLimit = 100;

export const useContractUsers = (contractId: string) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [nextToken, setNextToken] = useState<string>();

  const {
    data: contractProductInstances,
    loading: contractProductInstancesLoading,
  } = useGraphQuery<
    ContractProductInstancesQuery,
    ContractProductInstancesQueryVariables
  >(contractProductInstancesQuery, {
    variables: {
      id: contractId,
    },
  });

  const [
    fetch,
    {
      data: usersByContract,
      fetchMore,
      refetch,
      loading: usersByContractLoading,
    },
  ] = useGraphLazyQuery<UsersByContractQuery, UsersByContractQueryVariables>(
    usersByContractQuery,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadMore = useCallback(async () => {
    if (nextToken) {
      const { data } = await fetchMore({
        variables: { contractId, limit: recordsChunkLimit, nextToken },
        updateQuery: (oldData, { fetchMoreResult: newData }) => {
          const newItems = [
            ...oldData.usersByContract.items,
            ...newData.usersByContract.items,
          ];

          const uniqNewItems = uniqBy(newItems, "id");

          return {
            ...newData,
            usersByContract: {
              ...newData.usersByContract,
              items: uniqNewItems,
            },
          };
        },
      });

      setNextToken(data.usersByContract.nextToken ?? undefined);
    } else {
      const { data } = await fetch({
        variables: { contractId, limit: recordsChunkLimit },
      });

      setNextToken(data?.usersByContract.nextToken ?? undefined);
    }
  }, [fetch, fetchMore, contractId, nextToken]);

  const resetUsers = () => {
    setNextToken(undefined);
    refetch({ limit: recordsChunkLimit, contractId });
  };

  const [bulkAssignUserRoles, { loading: bulkAssignUserRolesLoading }] =
    useGraphMutation<
      BulkAssignUserRolesMutation,
      BulkAssignUserRolesMutationVariables
    >(bulkAssignUserRolesMutation, undefined, null);

  const handleBulkAssignUserRoles = async (
    userIds: string[],
    roleAssignment: UserRoleAssignmentInput[]
  ) => {
    const result = await bulkAssignUserRoles({
      variables: { input: { userIds, roleAssignment } },
    });
    if (result.data) {
      enqueueSnackbar(
        t("common.successMessages.entityUpdated", {
          entity: t(`common.labels.user${userIds.length > 1 ? "s" : ""}`),
        }),
        {
          autoHideDuration: snackbarAutoHideDuration,
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(
        t("common.errorMessages.entityUpdateFailed", {
          entity: t(`common.labels.user${userIds.length > 1 ? "s" : ""}`),
        }),
        {
          autoHideDuration: snackbarAutoHideDuration,
          variant: "error",
        }
      );
    }
    resetUsers();

    return result;
  };

  const filteredSortedContractProductInstances = useMemo(() => {
    const filteredPIs =
      (contractProductInstances?.contract.productInstances.items.filter(
        (productInstance) =>
          productInstance.status !== ProductInstanceStatus.Removed
      ) || []) as ProductInstance[];
    filteredPIs.sort((pi1, pi2) =>
      pi1.description.localeCompare(pi2.description)
    );

    return filteredPIs;
  }, [contractProductInstances]);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line
  }, []);

  return {
    usersByContract:
      (usersByContract?.usersByContract.items as User[] | undefined) ?? [],
    usersByContractLoading,
    hasMore: !!nextToken,
    loadMore,
    resetUsers,
    bulkAssignUserRoles: handleBulkAssignUserRoles,
    bulkAssignUserRolesLoading,
    contractProductInstances: filteredSortedContractProductInstances,
    contractProductInstancesLoading,
  };
};
