import { Box, Typography } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { FormPublicApi } from "types/decl";
import { Table } from "phosphor-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  InsertTableForm,
  TableConfigType,
} from "./InsertTableForm/InsertTableForm";

export type InsertTableModalProps = {
  onPrimaryClick: (tableConfig: TableConfigType) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const InsertTableModal: React.FC<InsertTableModalProps> = ({
  onPrimaryClick,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formData = useRef<TableConfigType>();
  const formApiRef = useRef<FormPublicApi>(null);

  const handleTableConfigChange = (tableConfig: TableConfigType) => {
    formData.current = tableConfig;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      onPrimaryClick(formData.current!);
    }
  };

  return (
    <ActionsDialog
      iconsHeader={
        <Box display="flex" flexDirection="row" alignItems="center">
          <Table size={32} />
          <Typography variant="h3" fontWeight={600} color="grey.800" ml={1}>
            {t("Projects.AdvancedRichTextField.insertTable")}
          </Typography>
        </Box>
      }
      content={
        <InsertTableForm
          apiRef={formApiRef}
          onChange={handleTableConfigChange}
        />
      }
      maxWidth="md"
      secondaryBtnCaption={t("common.buttons.cancel")}
      primaryBtnCaption={t("common.buttons.insert")}
      onPrimaryClick={handlePrimaryBtnClick}
      {...restDialogProps}
    />
  );
};
