import { Box, Button, styled, Typography } from "@mui/material";
import { NewAppPaths } from "helpers/paths/paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LoginIndicatorContainer = styled(Box)`
  position: absolute;
  left: 80px;
  bottom: 56px;
  display: flex;
  align-items: center;
`;

export const LoginIndicator: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToLoginPage = () => {
    navigate(NewAppPaths.nonAuthorized.Login);
  };

  return (
    <LoginIndicatorContainer>
      <Typography variant="p1" color="grey.700">
        {t("Register.loginHint")}
      </Typography>
      <Button variant="text" onClick={navigateToLoginPage}>
        <Typography
          variant="p1"
          sx={{ textDecoration: "underline" }}
          color="grey.900"
        >
          {t("Login.buttons.login")}
        </Typography>
      </Button>
    </LoginIndicatorContainer>
  );
};
