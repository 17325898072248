import { Box, BoxProps } from "@mui/material";
import { PrimarySecondaryTertiaryButtons } from "components/PrimarySecondaryTertiaryButtons";
import { useTranslation } from "react-i18next";

export type AuthorizeDenyModalFooterProps = {
  onClose: () => void;
  onDeny: () => void;
  onAuthorize: () => void;
} & BoxProps;

export const AuthorizeDenyModalFooter: React.FC<
  AuthorizeDenyModalFooterProps
> = ({ onClose, onDeny, onAuthorize, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      {...restProps}
    >
      <PrimarySecondaryTertiaryButtons
        onTertiaryClick={onClose}
        onSecondaryClick={onDeny}
        secondaryBtnCaption={t("Home.Authorizations.AuthorizeDenyModal.deny")}
        onPrimaryClick={onAuthorize}
        primaryBtnCaption={t(
          "Home.Authorizations.AuthorizeDenyModal.authorize"
        )}
      />
    </Box>
  );
};
