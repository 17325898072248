import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const dailyDiaryWorkRecordLiteFields = gql`
  ${creatorFields}

  fragment DailyDiaryWorkRecordLiteFields on DailyDiaryWorkRecord {
    id
    description
    areaOptionId
    areaLookupId
    disciplineOptionId
    disciplineLookupId
    teamOptionId
    teamLookupId
    activityOptionId
    activityLookupId
    workTypeOptionId
    workTypeLookupId
    shiftOptionId
    shiftLookupId
    amount
    unitOfMeasurementOptionId
    unitOfMeasurementLookupId
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
