import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { dailyDiaryPresetSectionFieldFields } from "./dailyDiaryPresetSectionField.fragment";

export const dailyDiaryPresetFields = gql`
  ${creatorFields}
  ${dailyDiaryPresetSectionFieldFields}

  fragment DailyDiaryPresetFields on DailyDiaryPreset {
    id
    name
    sections {
      name
      fields {
        ...DailyDiaryPresetSectionFieldFields
      }
    }
    status
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
