import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const getUserRolesByIdQuery = gql`
  ${creatorFields}

  query GetUserRolesById($id: ID!) {
    user(id: $id) {
      id
      firstname
      surname
      companyId
      company {
        id
        type
        registeredName
        tradingName
      }
      roles {
        items {
          id
          userId
          productInstanceId
          productRoleId
          status
          dateCreated
          creatorId
          creator {
            ...CreatorFields
          }
          productInstance {
            id
            description
            contract {
              id
              name
              friendlyName
              number
              project {
                id
                name
                friendlyName
                description
              }
            }
            product {
              id
              name
              roles {
                items {
                  id
                  name
                  productId
                }
              }
            }
          }
          productRole {
            id
            name
            productId
          }
        }
      }
    }
  }
`;

export const getUserProductInstancesByIdQuery = gql`
  query GetUserProductInstancesById($id: ID!) {
    user(id: $id) {
      id
      firstname
      surname
      companyId
      company {
        id
        type
        registeredName
        tradingName
        #contractBindings: AllContractBindingsResult!
        #contracts: AllContractsResult!
        projects {
          items {
            id
            name
            friendlyName
            # status: ProjectStatus!
            contracts {
              items {
                id
                name
                friendlyName
                #status: ContractStatus!
                activeBindings {
                  items {
                    id
                    companyId # TODO: check if all active bindings have the same companyId. It means that projectsList offered by user.company is only the projects for which the company has active contracts with
                    # contractBindingTypeId
                    # status: ContractBindingStatus!
                  }
                }
                #contractBindings: AllContractBindingsResult!
                productInstances {
                  items {
                    id
                    description
                    #status: ProductInstanceStatus!
                    product {
                      id
                      name
                      #status: ProductStatus!
                      roles {
                        items {
                          id
                          name
                          #status: ProductRoleStatus!
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
