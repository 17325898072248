import { gql } from "@apollo/client";

export const systemVersionQuery = gql`
  query systemVersion {
    systemVersion {
      assemblyVersion
      environment
    }
  }
`;
