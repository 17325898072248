import { gql } from "@apollo/client";
import { draftQuotationFields } from "graphql/fragments/draftQuotation.fragment";

export const compEventQuotationPromptQuery = gql`
  ${draftQuotationFields}

  query compEventQuotationPrompt($input: CompEventQuotationPromptInput!) {
    compEventQuotationPrompt(input: $input) {
      draftQuotations {
        ...DraftQuotationFields
      }
    }
  }
`;
