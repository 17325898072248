import { gql } from "@apollo/client";

export const sendAuthorizationDenialMutation = gql`
  mutation sendAuthorizationDenial(
    $authorizationWorkflowAuditId: ID!
    $denialReason: String!
  ) {
    sendAuthorizationDenial(
      authorizationWorkflowAuditId: $authorizationWorkflowAuditId
      denialReason: $denialReason
    )
  }
`;
