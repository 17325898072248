import { Box } from "@mui/material";
import {
  NewEntityHeader,
  NewEntityHeaderFontSize,
} from "components/NewEntityHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { PageContentHeaderSize } from "components/PageContentHeader/PageContentHeader";
import { FormPublicApi } from "types/decl";
import {
  AddLookupCollectionInput,
  AddLookupCollectionMutation,
  AddLookupCollectionMutationVariables,
  LookupCollection,
} from "generated/graphql";
import { addLookupCollectionMutation } from "graphql/mutations/addLookupCollection";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LookupCollectionForm } from "./LookupCollectionForm/LookupCollectionForm";

export const NewLookupCollection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef<FormPublicApi>(null);

  const [lookupCollectionData, setLookupCollectionData] =
    useState<AddLookupCollectionInput>();

  const [newLookupCollection, { loading: newLookupCollectionLoading }] =
    useGraphMutation<
      AddLookupCollectionMutation,
      AddLookupCollectionMutationVariables
    >(
      addLookupCollectionMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "lookupCollections" });
          cache.gc();
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("AdminConsole.Lookups.lookupCollection"),
      })
    );

  const handleAddNewLookupCollection = async () => {
    if (ref.current?.validate()) {
      const { errors } = await newLookupCollection({
        variables: {
          input: lookupCollectionData!,
        },
      });

      if (!errors) {
        navigate(-1);
      }
    }
  };

  const handleLookupCollectionChange = useCallback(
    (data: AddLookupCollectionInput | LookupCollection) => {
      setLookupCollectionData(data as AddLookupCollectionInput);
    },
    []
  );

  return (
    <Box>
      <NewEntityHeader
        onAdd={handleAddNewLookupCollection}
        entityName={t("AdminConsole.Lookups.lookupCollection")}
        size={PageContentHeaderSize.Normal}
        fontSize={NewEntityHeaderFontSize.Large}
        generalLoading={newLookupCollectionLoading}
        addActionLoading={newLookupCollectionLoading}
      />
      <PageContentContainer>
        <LookupCollectionForm
          onChange={handleLookupCollectionChange}
          ref={ref}
        />
      </PageContentContainer>
    </Box>
  );
};
