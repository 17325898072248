import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimNoticeInvalidNoticePromptQuery = gql`
  query claimNoticeInvalidNoticePrompt(
    $input: ClaimNoticeInvalidNoticePromptInput!
  ) {
    claimNoticeInvalidNoticePrompt(input: $input) {
      reasons
    }
  }
`;

export const sendClaimNoticeInvalidNoticeMutation = gql`
  mutation sendClaimNoticeInvalidNotice(
    $input: SendClaimNoticeInvalidNoticeInput!
  ) {
    sendClaimNoticeInvalidNotice(input: $input) {
      id
    }
  }
`;

export const recordClaimNoticeInvalidNoticeMutation = gql`
  mutation recordClaimNoticeInvalidNotice(
    $input: RecordClaimNoticeInvalidNoticeInput!
  ) {
    recordClaimNoticeInvalidNotice(input: $input) {
      id
    }
  }
`;

export const generateSendClaimNoticeInvalidNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimNoticeInvalidNoticePreview(
    $input: SendClaimNoticeInvalidNoticePreviewInput!
  ) {
    generateSendClaimNoticeInvalidNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimNoticeInvalidNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimNoticeInvalidNoticePreview(
    $input: RecordClaimNoticeInvalidNoticePreviewInput!
  ) {
    generateRecordClaimNoticeInvalidNoticePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
