import { Box, CircularProgress } from "@mui/material";
import {
  EffectOption,
  EffectOptionStatus,
  ItemEffectPresetQuery,
  ItemEffectPresetQueryVariables,
} from "generated/graphql";
import { itemEffectPresetQuery } from "graphql/queries/effectPresetByProductInstanceId.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";
import { EffectOptionAnswer, EffectWidgetValue } from "./Effect.decl";
import { EffectEditable } from "./EffectEditable";
import { EffectReadOnly } from "./EffectReadOnly";

export type EffectProps = {
  editable?: boolean;
  productInstanceId: string;
  data?: EffectWidgetValue;
  contractCurrency: string;
  noOverflow?: boolean;
  onChange?: (newEffectWidgetData: EffectWidgetValue) => void;
};

export const Effect: React.FC<EffectProps> = ({
  editable = true,
  productInstanceId,
  data,
  contractCurrency,
  noOverflow,
  onChange,
}) => {
  const { data: effectPreset, loading: getEffectPresetLoading } = useGraphQuery<
    ItemEffectPresetQuery,
    ItemEffectPresetQueryVariables
  >(itemEffectPresetQuery, {
    variables: { productInstanceId },
    fetchPolicy: "cache-first",
  });

  const computedData = useMemo(
    () =>
      data ?? {
        effectPresetId: effectPreset?.itemEffectPreset.id || "",
        answers: [] as EffectOptionAnswer[],
      },
    [effectPreset, data]
  );

  const handleEffectChange = (newEffectWidgetData: EffectWidgetValue) => {
    onChange?.(newEffectWidgetData);
  };

  const validEffectPresetOptions = useMemo(() => {
    return (effectPreset?.itemEffectPreset.options.items.filter(
      (option) => option.status === EffectOptionStatus.Active
    ) ?? []) as EffectOption[];
  }, [effectPreset]);

  return getEffectPresetLoading ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  ) : editable ? (
    <EffectEditable
      effectOptions={validEffectPresetOptions}
      widgetData={computedData}
      contractCurrency={contractCurrency}
      onChange={handleEffectChange}
    />
  ) : (
    <EffectReadOnly
      noOverflow={noOverflow}
      effectOptions={validEffectPresetOptions}
      widgetData={computedData}
      contractCurrency={contractCurrency}
    />
  );
};
