import { IconButton } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import { X } from "phosphor-react";
import React, { useCallback } from "react";

export const DismissAction = React.memo(({ id }: { id: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const handeCloseSnackbar = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <IconButton onClick={handeCloseSnackbar} data-testid="dismiss-toast-bar">
      <X size={18} color="white" />
    </IconButton>
  );
});
