import { IntercomEvents } from "constants/intercom";
import { getMetadataSet1 } from "containers/Projects/utils/intercom";
import {
  AddDraftInstructionItemMutation,
  AddDraftInstructionItemMutationVariables,
  DraftInstructionItemQuery,
  DraftInstructionItemQueryVariables,
  EditDraftInstructionItemMutation,
  EditDraftInstructionItemMutationVariables,
  ProductType,
  RecordInstructionItemMutation,
  RecordInstructionItemMutationVariables,
  SendInstructionItemMutation,
  SendInstructionItemMutationVariables,
} from "generated/graphql";
import { addDraftInstructionItemMutation } from "graphql/mutations/addDraftInstructionItem";
import { editDraftInstructionItemMutation } from "graphql/mutations/editDraftInstructionItem";
import { recordInstructionItemMutation } from "graphql/mutations/recordInstructionItem";
import { sendInstructionItemMutation } from "graphql/mutations/sendInstructionItem";
import { draftInstructionItemQuery } from "graphql/queries/draftInstructionItem.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useProductItemContext } from "hooks/useProductItemContext";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

export const useNewInstruction = (
  getInstructionTrackingExtraData: () => object
) => {
  const { t } = useTranslation();
  const { projectId, contractId, productInstanceId } = useParams();
  const { trackEvent } = useIntercom();

  const {
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
    isNECContractType,
    contractTypeId,
  } = useProductItemContext(projectId!, contractId!, productInstanceId!);

  const sendInstructionTrackingEvent = (
    instrId: string,
    instrTitle: string,
    event: IntercomEvents.SentInstruction | IntercomEvents.RecordedInstruction
  ) => {
    trackEvent(event, {
      ...getMetadataSet1({
        productInstanceId: productInstanceId!,
        productInstanceItemId: instrId,
        productType: ProductType.Instructions,
        contractFn: contractDataLite?.friendlyName ?? "",
        projectFn: projectDataLite?.friendlyName ?? "",
        productInstanceItemName: instrTitle,
        version: process.env.REACT_APP_VERSION!,
      }),
      ...getInstructionTrackingExtraData(),
    });
  };

  const [
    fetchDraftInstructionData,
    {
      data: draftInstructionData,
      loading: draftInstructionDataLoading,
      refetch: refetchDraftInstructionData,
    },
  ] = useGraphLazyQuery<
    DraftInstructionItemQuery,
    DraftInstructionItemQueryVariables
  >(draftInstructionItemQuery);

  const [sendInstruction, { loading: sendInstructionLoading }] =
    useGraphMutation<
      SendInstructionItemMutation,
      SendInstructionItemMutationVariables
    >(
      sendInstructionItemMutation,
      {
        update: (_cache, result) => {
          sendInstructionTrackingEvent(
            result.data?.sendInstructionItem.id!,
            result.data?.sendInstructionItem.title ?? "",
            IntercomEvents.SentInstruction
          );
          //   cache.evict({ id: "ROOT_QUERY", fieldName: "instructionItems" });
          //   cache.evict({
          //     id: "ROOT_QUERY",
          //     fieldName: "draftInstructionItems",
          //   });
          //   cache.gc();
        },
      },
      null
    );

  const [recordInstruction, { loading: recordInstructionLoading }] =
    useGraphMutation<
      RecordInstructionItemMutation,
      RecordInstructionItemMutationVariables
    >(
      recordInstructionItemMutation,
      {
        update: (_cache, result) => {
          sendInstructionTrackingEvent(
            result.data?.recordInstructionItem.id!,
            result.data?.recordInstructionItem.title ?? "",
            IntercomEvents.RecordedInstruction
          );
          //   cache.evict({ id: "ROOT_QUERY", fieldName: "instructionItems" });
          //   cache.evict({
          //     id: "ROOT_QUERY",
          //     fieldName: "draftInstructionItems",
          //   });
          //   cache.gc();
        },
      },
      t("common.successMessages.entityRecorded", {
        entity: t("common.labels.instruction"),
      })
    );

  const [addInstructionItemDraft, { loading: addInstructionItemDraftLoading }] =
    useGraphMutation<
      AddDraftInstructionItemMutation,
      AddDraftInstructionItemMutationVariables
    >(
      addDraftInstructionItemMutation,
      {
        // update: (cache) => {
        //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftInstructionItems" });
        //   cache.gc();
        // },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.Instructions.draftInstruction"),
      })
    );

  const [
    editInstructionItemDraft,
    { loading: editInstructionItemDraftLoading },
  ] = useGraphMutation<
    EditDraftInstructionItemMutation,
    EditDraftInstructionItemMutationVariables
  >(
    editDraftInstructionItemMutation,
    {
      update: (_cache) => {
        refetchDraftInstructionData();

        // cache.evict({
        //   id: "ROOT_QUERY",
        //   fieldName: "draftInstructionItems",
        // });
        // cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Instructions.draftInstruction"),
    })
  );

  return {
    fetchDraftInstructionData,
    draftInstructionData,
    draftInstructionDataLoading,
    sendInstruction,
    sendInstructionLoading,
    recordInstruction,
    recordInstructionLoading,
    addInstructionItemDraft,
    addInstructionItemDraftLoading,
    editInstructionItemDraft,
    editInstructionItemDraftLoading,
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
    isNECContractType,
    contractTypeId,
  };
};
