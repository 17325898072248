import { Box, styled, css, Typography, Color } from "@mui/material";
import { AddFileIcon } from "components/Icons/AddFileIcon";
import { useCallback, useContext, useMemo } from "react";
import { FileMIMEType, FileType } from "../../Attachments.decl";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { formatBytes } from "helpers/miscelaneous";
import { stringToFileMIMEType } from "../../Attachments.utils";
import { resizeFile } from "./DroppingArea.utils";
import { GlobalContext } from "state-management/globalContext/Global.context";

const Byte = 1;
const KB = 1024 * Byte;
const MB = 1024 * KB;
const hundredMB = 100 * MB;
const defaultFileSizeLimit = hundredMB;

const DroppingAreaContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDragActive",
})<{ isDragActive: boolean }>(({ theme, isDragActive }) => {
  const activeStyle = css`
    background-color: ${(theme.palette.secondary as Partial<Color>)[100]};
    border-color: ${(theme.palette.secondary as Partial<Color>)[600]};
  `;

  return css`
    border: 1px dashed ${theme.palette.grey[800]};
    background: ${theme.palette.grey[100]};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(227, 243, 229, 0.4);
      border-color: ${(theme.palette.secondary as Partial<Color>)[700]};
    }

    &:focus,
    &:active {
      ${activeStyle}
    }

    ${isDragActive && activeStyle}
  `;
});

export type DroppingAreaProps = {
  onFilesUpload: (files: FileType[]) => void;
};

export const DroppingArea: React.FC<DroppingAreaProps> = ({
  onFilesUpload,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { globalConfiguration } = useContext(GlobalContext);
  const fileSizeLimit = useMemo(
    () =>
      globalConfiguration.maxUploadFileSize
        ? globalConfiguration.maxUploadFileSize * MB
        : defaultFileSizeLimit,
    [globalConfiguration]
  );

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      rejectedFiles: FileRejection[],
      __: DropEvent
    ) => {
      if (acceptedFiles) {
        const result: FileType[] = [];

        for (let index = 0; index < acceptedFiles.length; index++) {
          const file = acceptedFiles[index];
          const FR = new FileReader();

          FR.addEventListener("load", async function (evt) {
            let resizeImageResult: { file: File; dataUrl: string };

            if (stringToFileMIMEType(file.type) === FileMIMEType.Img) {
              resizeImageResult = await resizeFile(file);
            }

            result.push({
              id: uuidv4(),
              fileName: file.name,
              displayName: file.name,
              size: resizeImageResult!
                ? resizeImageResult.file.size
                : file.size,
              // dateModified: file.lastModified, // last date the file was modified
              dateCreated: new Date().getTime(), // the time of the upload
              mimeType: resizeImageResult!
                ? resizeImageResult!.file.type
                : file.type,
              data: resizeImageResult!
                ? resizeImageResult!.dataUrl
                : (evt.target!.result! as string),
              rawFile:
                (resizeImageResult! ? resizeImageResult!.file : file) || "",
            });

            if (result.length === acceptedFiles.length) {
              onFilesUpload(result);
            }
          });

          FR.readAsDataURL(file);
        }
      }
      if (rejectedFiles && rejectedFiles.length) {
        for (let index = 0; index < rejectedFiles.length; index++) {
          const rejectedFile = rejectedFiles[index];

          if (rejectedFile.errors[0].code === "file-too-large") {
            enqueueSnackbar(
              t("Attachments.fileTooLarge", {
                fileName: rejectedFile.file.name,
                sizeLimit: formatBytes(fileSizeLimit),
              })
            );
          }
        }
      }
    },
    [onFilesUpload, t, enqueueSnackbar, fileSizeLimit]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: fileSizeLimit,
  });

  return (
    <DroppingAreaContainer isDragActive={isDragActive} {...getRootProps()}>
      <input {...getInputProps()} />
      <Box display="flex" flexDirection="column" alignItems="center" py={3}>
        <AddFileIcon />
        <Box display="flex" alignItems="center" mt={1.5}>
          {isDragActive ? (
            <Typography variant="p2" color="grey.700">
              {t("Attachments.dragAndDropHere")}
            </Typography>
          ) : (
            <>
              <Typography variant="p2" color="grey.700">
                {t("Attachments.dragAndDropHereOr")}
              </Typography>
              <Typography
                variant="p2"
                fontWeight={600}
                color="grey.700"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                ml={0.5}
              >
                {t("common.buttons.upload")}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </DroppingAreaContainer>
  );
};
