import { gql } from "@apollo/client";

export const addDocumentTemplateMutation = gql`
  mutation addDocumentTemplate($input: AddDocumentTemplateInput!) {
    addDocumentTemplate(input: $input) {
      id
      name
      description
      content
      status
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
    }
  }
`;
