import { Box, css, styled } from "@mui/material";

export const HistoryItemDetailsContainer = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(3)};
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.palette.grey[200]};
    border-radius: 8px;
    margin-top: 6px;
  `
);
