import {
  ApolloError,
  DocumentNode,
  LazyQueryExecFunction,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
} from "@apollo/client";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultErrorHandlingOptions,
  ErrorHandlingOptions,
} from "./useGraphMutation";

export const useGraphLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>,
  errorHandlingOption: ErrorHandlingOptions = defaultErrorHandlingOptions
): LazyQueryResultTuple<TData, TVariables> => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [queryFunction, { error, ...queryResult }] = useLazyQuery(
    query,
    options
  );

  const customQuery: LazyQueryExecFunction<TData, TVariables> = useCallback(
    async (options?: Partial<LazyQueryHookOptions<TData, TVariables>>) => {
      try {
        const result = await queryFunction(options);

        return result;
      } catch (e) {
        const err = e as ApolloError;
        if (errorHandlingOption.type === "snackbar") {
          // TODO: talk to Abel and come up with an error type thrown from BE that includes errorMessage and errorCode. Based on the errorCode, translate the messages
          enqueueSnackbar(t(errorHandlingOption?.errorMessage!) ?? err.message);
        }
        throw err;
      }
    },
    // as errorHandlingOptions is an object, we prefer to take the first passed argument version rather than having to extract in the consumer the options in a const every time
    // eslint-disable-next-line
    [queryFunction, t, enqueueSnackbar]
  );

  useEffect(() => {
    if (error && errorHandlingOption.type === "snackbar") {
      enqueueSnackbar(t(errorHandlingOption?.errorMessage!) ?? error?.message);
    }
    // as errorHandlingOptions is an object, we prefer to take the first passed argument version rather than having to extract in the consumer the options in a const every time
    // eslint-disable-next-line
  }, [error, enqueueSnackbar, t]);

  return [customQuery, { ...queryResult, error }];
};
