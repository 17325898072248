import { Box, BoxProps, css, styled } from "@mui/material";

const BtnInner = styled(Box, {
  shouldForwardProp: (prop) => prop !== "btnStyle",
})<{ btnStyle: "read" | "unread" }>(
  ({ theme, btnStyle }) => css`
    background-color: ${btnStyle === "read"
      ? theme.palette.info.main
      : theme.palette.info.light};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    cursor: pointer;
  `
);

const BtnOuter = styled(Box)(
  ({ theme }) => css`
    background-color: transparent;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-color: ${theme.palette.info.light};
    }
  `
);

export const ReadUnreadButton: React.FC<
  {
    btnStyle: "read" | "unread";
    onClick: (action: "read" | "unread") => void;
  } & Omit<BoxProps, "onClick">
> = ({ onClick, btnStyle, ...restBoxProps }) => {
  return (
    <BtnOuter onClick={() => onClick(btnStyle)} {...restBoxProps}>
      <BtnInner btnStyle={btnStyle}></BtnInner>
    </BtnOuter>
  );
};
