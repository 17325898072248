import { GridEventListener } from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { CompEventAssessment, DraftAssessment } from "generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmptyQuotationsAssessmentsTableView } from "../../SubmitQuotationCEAction/QuotationsTable/EmptyQuotationsAssessmentsTableView";
import { getColumns } from "./AssessmentsTable.constants";

export type AssessmentsTableProps = {
  contractCurrency: string;
  loading?: boolean;
  assessments: DraftAssessment[] | CompEventAssessment[];
  onDelete?: (assessmentId: string) => void;
  onRowClick?: (assessment: DraftAssessment | CompEventAssessment) => void;
};

export const AssessmentsTable: React.FC<AssessmentsTableProps> = ({
  loading,
  contractCurrency,
  assessments,
  onDelete,
  onRowClick,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => getColumns(contractCurrency, t, onDelete),
    [contractCurrency, t, onDelete]
  );

  const [rows, setRows] = useState<CompEventAssessment[] | DraftAssessment[]>(
    assessments ?? []
  );

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (params) => {
      const assessment = params.row;

      if (onRowClick) {
        onRowClick(assessment);
      }
    },
    [onRowClick]
  );

  useEffect(() => {
    setRows(assessments ?? []);
  }, [assessments]);

  return (
    <NewStyledDataGrid
      rows={rows}
      columns={columns}
      getRowId={(rowData: DraftAssessment | CompEventAssessment) => rowData.id}
      loading={loading}
      slots={{
        noRowsOverlay: () => (
          <EmptyQuotationsAssessmentsTableView isAssessment />
        ),
      }}
      onRowClick={handleRowClick}
      disableRowSelectionOnClick
      hideFooter
      autoHeight
    />
  );
};
