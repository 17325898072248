import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { Box, IconButton, styled } from "@mui/material";
import { GridHeader, GridHeaderProps } from "components/GridHeader";

type CollapsibleHeaderIconsProps = {
  collapsed: boolean;
  icon: React.ReactNode;
  toggleBtnDataTestid?: string;
  onToggleCollapse: (newState: boolean) => void;
};

export type CollapsibleHeaderProps = GridHeaderProps &
  CollapsibleHeaderIconsProps;

export const CollapsibleIconContainer = styled(Box)`
  background: rgba(0, 0, 0, 0.03);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CollapsibleHeaderIcons: React.FC<CollapsibleHeaderIconsProps> = ({
  collapsed,
  icon,
  toggleBtnDataTestid,
  onToggleCollapse,
}) => (
  <>
    <IconButton
      onClick={() => onToggleCollapse(!collapsed)}
      style={{ height: "35px", width: "35px" }}
      data-testid={toggleBtnDataTestid}
    >
      {collapsed ? (
        <KeyboardArrowDownRoundedIcon />
      ) : (
        <KeyboardArrowUpRoundedIcon />
      )}
    </IconButton>
    <CollapsibleIconContainer ml={2}>{icon}</CollapsibleIconContainer>
  </>
);

export const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = ({
  collapsed,
  icon,
  onToggleCollapse,
  ...restGridProps
}) => {
  return (
    <GridHeader
      {...restGridProps}
      leftContent={
        <Box display="flex" alignItems="center" mr={2}>
          <CollapsibleHeaderIcons
            icon={icon}
            collapsed={collapsed}
            onToggleCollapse={onToggleCollapse}
            toggleBtnDataTestid={`${restGridProps.title}-section-toggle-btn`}
          />
        </Box>
      }
    />
  );
};
