import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventNoticeOfSilenceCompEventNoticeMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventNoticeOfSilenceCompEventNotice(
    $input: RecordCompEventNoticeOfSilenceCompEventNoticeInput!
  ) {
    recordCompEventNoticeOfSilenceCompEventNotice(input: $input) {
      ...CompEventItemFields
    }
  }
`;
