import { gql } from "@apollo/client";
import { cEListItemFields } from "graphql/fragments/compEventListItem.fragment";

export const compEventNoticeRequestQuotationPromptQuery = gql`
  ${cEListItemFields}

  query compEventNoticeRequestQuotationPrompt(
    $input: CompEventNoticeRequestQuotationPromptInput!
  ) {
    compEventNoticeRequestQuotationPrompt(input: $input) {
      compEventTypes {
        ...CEListItemFields
      }
      assumptions
    }
  }
`;
