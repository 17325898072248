import { DailyDiaryPresetSection, LookupOption } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";
import { useMemo } from "react";

export const useWeatherRecordFormValidators = (
  section: DailyDiaryPresetSection,
  conditionsOptionId: string,
  conditionsOptions: LookupOption[]
) => {
  const dataValidators: DataValidators = useMemo(() => {
    // Conditions
    const conditionsField = section.fields.find(
      (field) => field.name === "Conditions"
    );

    const conditionsColValidators: DataValidators | undefined =
      conditionsField && !conditionsField.isHidden
        ? {
            conditionsOptionId: {
              validators: [
                conditionsField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                conditionsOptionId,
                conditionsOptions.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // Temperature
    const temperatureField = section.fields.find(
      (field) => field.name === "Temperature"
    );

    const temperatureColValidators: DataValidators | undefined =
      temperatureField &&
      !temperatureField.isHidden &&
      temperatureField.isRequired
        ? {
            temperature: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    // Rainfall
    const rainfallField = section.fields.find(
      (field) => field.name === "Rainfall"
    );

    const rainfallColValidators: DataValidators | undefined =
      rainfallField && !rainfallField.isHidden
        ? {
            rainFall: {
              validators: [
                rainfallField.isRequired
                  ? ValidatorType.Required
                  : ValidatorType.PositiveNumberOrZeroOrEmptyValue,
              ],
            },
          }
        : undefined;

    // WindSpeed
    const windSpeedField = section.fields.find(
      (field) => field.name === "WindSpeed"
    );

    const windSpeedColValidators: DataValidators | undefined =
      windSpeedField && !windSpeedField.isHidden
        ? {
            windSpeed: {
              validators: [
                windSpeedField.isRequired
                  ? ValidatorType.Required
                  : ValidatorType.PositiveNumberOrZeroOrEmptyValue,
              ],
            },
          }
        : undefined;

    // Humidity
    const humidityField = section.fields.find(
      (field) => field.name === "Humidity"
    );

    const humidityColValidators: DataValidators | undefined =
      humidityField && !humidityField.isHidden
        ? {
            humidity: {
              validators: [
                humidityField.isRequired
                  ? ValidatorType.Required
                  : ValidatorType.PositiveNumberOrZeroOrEmptyValue,
              ],
            },
          }
        : undefined;

    // TimeOfMeasurement
    const timeOfMeasurementField = section.fields.find(
      (field) => field.name === "TimeOfMeasurement"
    );

    const timeOfMeasurementColValidators: DataValidators | undefined =
      timeOfMeasurementField &&
      !timeOfMeasurementField.isHidden &&
      timeOfMeasurementField.isRequired
        ? {
            timeOfMeasurement: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    return {
      ...conditionsColValidators,
      ...temperatureColValidators,
      ...rainfallColValidators,
      ...windSpeedColValidators,
      ...humidityColValidators,
      ...timeOfMeasurementColValidators,
    };
  }, [conditionsOptionId, conditionsOptions, section]);

  return dataValidators;
};
