import { Alert, AlertTitle, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { unofficialThemeColors } from "theme/extendedTheme";

const StyledAlert = styled(Alert)`
  background: ${unofficialThemeColors.blueWidgetLastStep};
  position: fixed;
  z-index: 200000;
  left: 50%;
  transform: translateX(-50%);
  top: 4px;
`;

export const NewUIVersionAlert: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAlert severity="info">
      <AlertTitle>
        {t("Miscellaneous.NewUIVersionAvailableAlert.title")}
      </AlertTitle>
      {t("Miscellaneous.NewUIVersionAvailableAlert.body")}
    </StyledAlert>
  );
};
