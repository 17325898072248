import { Stack, Typography } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import { ProductType } from "generated/graphql";
import { ArrowSquareOut } from "phosphor-react";
import { useTranslation } from "react-i18next";

type ProductItemCardRegardingAdornmentProps = {
  authRegardingType: ProductType;
  regardingRegardingType: ProductType;
  regardingRegardingTitle: string;
  onClick: () => void;
};

export const ProductItemCardRegardingAdornment: React.FC<
  ProductItemCardRegardingAdornmentProps
> = ({
  authRegardingType,
  regardingRegardingType,
  regardingRegardingTitle,
  onClick,
}) => {
  const { t } = useTranslation();

  return [
    ProductType.CompEvents,
    ProductType.Claims,
    ProductType.Variations,
  ].includes(authRegardingType) ? (
    <Stack direction="row" alignItems="center" spacing={1} mb={2}>
      <Typography variant="h4">
        {t("Home.Authorizations.AuthorizeDenyModal.regarding")}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <ItemTag type={regardingRegardingType} />
        <Typography variant="h4">{regardingRegardingTitle}</Typography>
        <ArrowSquareOut size={18} />
      </Stack>
    </Stack>
  ) : null;
};
