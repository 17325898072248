import { Box, Tooltip, useTheme } from "@mui/material";
import { MetadataHeading } from "./SchemaInterpretor/MetadataHeading";
import { useTranslation } from "react-i18next";
import { Info } from "phosphor-react";

export type MetadataDateSentHeadingProps = {
  tooltipText: string;
};

export const MetadataDateSentHeading: React.FC<
  MetadataDateSentHeadingProps
> = ({ tooltipText }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <MetadataHeading marginRight={0.5}>
        {t("common.labels.dateSent")}
      </MetadataHeading>
      <Tooltip title={tooltipText} arrow placement="bottom">
        <Info size={16} color={theme.palette.grey[800]} />
      </Tooltip>
    </Box>
  );
};
