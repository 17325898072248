import {
  AuthorizationWorkflowAudit,
  UserPendingAuthorizationActionsQuery,
  UserPendingAuthorizationActionsQueryVariables,
} from "generated/graphql";
import { userPendingAuthorizationActionsQuery } from "graphql/queries/userPendingAuthorizationActions.query";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useAuthorizationsRequiredFromYou = () => {
  const {
    data: authorizationsRequiredFromYou,
    loading: authorizationsRequiredFromYouLoading,
  } = useGraphQuery<
    UserPendingAuthorizationActionsQuery,
    UserPendingAuthorizationActionsQueryVariables
  >(userPendingAuthorizationActionsQuery, {
    notifyOnNetworkStatusChange: true,
  });

  return {
    authorizationsRequiredFromYou:
      (authorizationsRequiredFromYou?.userPendingAuthorizationActions ??
        []) as AuthorizationWorkflowAudit[],
    loading: authorizationsRequiredFromYouLoading,
  };
};
