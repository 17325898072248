import { gql } from "@apollo/client";
import { companyLookupCollectionFields } from "graphql/fragments/companyLookupCollection.fragment";

export const companyLookupCollectionQuery = gql`
  ${companyLookupCollectionFields}

  query companyLookupCollection($id: ID!) {
    companyLookupCollection(id: $id) {
      ...CompanyLookupCollectionFields
    }
  }
`;
