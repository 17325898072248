import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { ContractPriceTimeChangeReadOnlyView } from "../../HistoryModal/ContractPriceTimeChangeReadOnlyView";
import { useContext, useMemo } from "react";
import {
  Contract,
  ProductType,
  VariationAgreement,
  VariationDetermination,
  VariationProposal,
  VariationProposalTimeChange,
} from "generated/graphql";
import { IconContainer } from "components/IconContainer";
import { Money } from "phosphor-react";
import { VariationWidgetContext } from "../VariationWidget/VariationWidget.context";

type VariationProposalAgreementDeterminationReadOnlyModalProps = {
  item: VariationProposal | VariationAgreement | VariationDetermination;
  contract: Contract;
} & BasicModalProps;

export const VariationProposalAgreementDeterminationReadOnlyModal: React.FC<
  VariationProposalAgreementDeterminationReadOnlyModalProps
> = ({ item, contract, ...restDialogProps }) => {
  const { isFIDIC17White } = useContext(VariationWidgetContext);

  const sectionalChanges = useMemo(() => {
    if (item.sectionalChanges && item.sectionalChanges.length) {
      // if ((item.sectionalChanges as ContractTimeImpact[])[0].id) {
      //   return item.sectionalChanges as ContractTimeImpact[];
      // } else {
      return item.sectionalChanges as VariationProposalTimeChange[];
      // }
    }

    return undefined;
  }, [item]);

  return (
    <BasicModal
      maxWidth="sm"
      titleNextToIcon
      title={item.reference}
      headerIcon={
        <IconContainer greyBackground>
          <Money size={24} />
        </IconContainer>
      }
      {...restDialogProps}
    >
      <ContractPriceTimeChangeReadOnlyView
        details={item.details}
        time={item.time}
        price={item.price}
        contract={contract}
        dateSent={item.dateCreated}
        sectionalChanges={sectionalChanges}
        attachments={item.attachments}
        isFIDIC17White={isFIDIC17White}
        productType={ProductType.Variations}
      />
    </BasicModal>
  );
};
