import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const productInstanceQuery = gql`
  ${creatorFields}

  query productInstance($id: ID!) {
    productInstance(id: $id) {
      id
      description
      numberingFormat
      soloModeSupported
      contractId
      productId
      productSchemaId
      productSchema {
        id
        name
      }
      statusCollectionId
      statusCollection {
        id
        name
      }
      status
      dateCreated
      creatorId
      creator {
        ...CreatorFields
      }
      contract {
        id
        name
        friendlyName
      }
      product {
        __typename
        id
        name
        roles {
          items {
            id
            name
          }
        }
      }
      roles {
        items {
          id
          userId
          productInstanceId
          productRoleId
          status
          dateCreated
          creatorId
          creator {
            ...CreatorFields
          }
          user {
            id
            firstname
            surname
            jobTitle
            email
            company {
              id
              registeredName
            }
          }
          productRole {
            id
            name
            productId
            actionIds
            status
            dateCreated
            creatorId
            creator {
              ...CreatorFields
            }
            # actions: ProductRoleActionList!
          }
        }
      }
      extraConfig {
        __typename
        ... on DailyDiaryExtraConfig {
          presetId
          manPowerLookupId
          equipmentLookupId
          areaLookupId
          hseTypeLookupId
          companyLookupId
          teamLookupId
          shiftLookupId
          startDate
        }
      }
    }
  }
`;

export const editProductInstanceMutation = gql`
  mutation editProductInstance($input: EditProductInstanceInput!) {
    editProductInstance(input: $input) {
      id
      description
    }
  }
`;
