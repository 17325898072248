import { ActionsDialogProps } from "components/ActionsDialog";
import { VariationActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendFurtherParticularsSubmissionModal } from "./useSendFurtherParticularsSubmissionModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";

export type SendFurtherParticularsSubmissionModalProps = {
  details: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendFurtherParticularsSubmissionModal: React.FC<
  SendFurtherParticularsSubmissionModalProps
> = ({ details, attachments = [], ...restDialogProps }) => {
  const { variationPreview, contract } =
    useSendFurtherParticularsSubmissionModal(details);
  const { t } = useTranslation();

  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.ProvideFurtherParticulars}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={details}
          label={t("Projects.Variations.ActionModal.furtherParticulars")}
          attachments={attachments}
          contractTimezone={contract.timeZone}
        />
      }
      {...restDialogProps}
    />
  );
};
