import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const timePeriodLiteFields = gql`
  ${creatorFields}

  fragment TimePeriodLiteFields on TimePeriod {
    id
    contractTypeId
    reference
    description
    duration
    status
    isInternal
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
