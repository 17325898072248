import { gql } from "@apollo/client";
import { draftVariationDeterminationFields } from "graphql/fragments/draftVariationDetermination.fragment";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationDeterminationNoticePromptQuery = gql`
  ${draftVariationDeterminationFields}

  query variationDeterminationNoticePrompt(
    $input: VariationDeterminationNoticePromptInput!
  ) {
    variationDeterminationNoticePrompt(input: $input) {
      draftVariationDeterminations {
        ...DraftVariationDeterminationFields
      }
    }
  }
`;

export const sendVariationDeterminationNoticeMutation = gql`
  mutation sendVariationDeterminationNotice(
    $input: SendVariationDeterminationNoticeInput!
  ) {
    sendVariationDeterminationNotice(input: $input) {
      id
    }
  }
`;

export const recordVariationDeterminationNoticeMutation = gql`
  mutation recordVariationDeterminationNotice(
    $input: RecordVariationDeterminationNoticeInput!
  ) {
    recordVariationDeterminationNotice(input: $input) {
      id
    }
  }
`;

export const generateSendVariationDeterminationNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationDeterminationNoticePreview(
    $input: SendVariationDeterminationNoticePreviewInput!
  ) {
    generateSendVariationDeterminationNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationDeterminationNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationDeterminationNoticePreview(
    $input: RecordVariationDeterminationNoticePreviewInput!
  ) {
    generateRecordVariationDeterminationNoticePreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
