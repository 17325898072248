import { CountryData } from "helpers/countries/countries.decl";

export const getCountryListItemId = (country: CountryData) => {
  return `country-${country.countryCodeA2}`;
};

export const computeCountryToFocusDown = (
  countries: CountryData[],
  focusedCountry?: CountryData,
  selectedCountry?: CountryData
) => {
  let countryToFocus: CountryData;
  let focusedCountryIndex = countries.findIndex(
    (country) => country.countryCodeA2 === focusedCountry?.countryCodeA2
  );
  if (focusedCountryIndex < 0) {
    focusedCountryIndex = countries.findIndex(
      (country) => country.countryCodeA2 === selectedCountry?.countryCodeA2
    );
  }
  if (focusedCountryIndex >= 0 && countries[focusedCountryIndex + 1]) {
    countryToFocus = countries[focusedCountryIndex + 1];
  } else {
    countryToFocus = countries[0];
  }

  return countryToFocus;
};

export const computeCountryToFocusUp = (
  countries: CountryData[],
  focusedCountry?: CountryData,
  selectedCountry?: CountryData
) => {
  let countryToFocus: CountryData;
  let focusedCountryIndex = countries.findIndex(
    (country) => country.countryCodeA2 === focusedCountry?.countryCodeA2
  );
  if (focusedCountryIndex < 0) {
    focusedCountryIndex = countries.findIndex(
      (country) => country.countryCodeA2 === selectedCountry?.countryCodeA2
    );
  }
  if (focusedCountryIndex >= 0 && countries[focusedCountryIndex - 1]) {
    countryToFocus = countries[focusedCountryIndex - 1];
  } else {
    countryToFocus = countries[0];
  }

  return countryToFocus;
};
