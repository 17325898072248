import { Box, useTheme } from "@mui/material";
import { EffectEstimationType, EffectOption } from "generated/graphql";
import { EffectOptionAnswer } from "../Effect.decl";
import { UnitValueReadOnly } from "./UnitValueReadOnly";
import { Check } from "phosphor-react";
import { RichTextArea } from "components/RichTextArea/RichTextArea";

export type EffectOptionFieldReadOnlyProps = {
  effectOption: EffectOption;
  data?: EffectOptionAnswer;
  allowCheck?: boolean;
  contractCurrency: string;
  noOverflow?: boolean;
};

export const EffectOptionFieldReadOnly: React.FC<
  EffectOptionFieldReadOnlyProps
> = ({
  effectOption,
  data,
  contractCurrency,
  allowCheck = true,
  noOverflow = false,
}) => {
  const theme = useTheme();

  const labelProps = noOverflow
    ? {
        lineClamp: 1,
        lineHeight: "normal",
      }
    : undefined;

  return (
    <Box display="flex" alignItems="center">
      {allowCheck && (
        <Box
          width={32}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          {data?.selected && (
            <Check size={24} color={theme.palette.success.main} />
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flex={1}
      >
        <RichTextArea
          readOnly
          content={effectOption.label}
          color={theme.palette.grey[600]}
          fontSize={theme.typography.p1.fontSize}
          {...labelProps}
        />
        {effectOption.estimationType !== EffectEstimationType.None &&
          data?.value && (
            <UnitValueReadOnly
              unit={effectOption.unit}
              value={data.value}
              currency={contractCurrency}
              estimationType={effectOption.estimationType}
            />
          )}
      </Box>
    </Box>
  );
};
