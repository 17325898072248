import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationProposalAbandonmentPromptQuery = gql`
  query variationProposalAbandonmentPrompt(
    $input: VariationProposalAbandonmentPromptInput!
  ) {
    variationProposalAbandonmentPrompt(input: $input) {
      remarks
    }
  }
`;

export const sendVariationProposalAbandonmentMutation = gql`
  mutation sendVariationProposalAbandonment(
    $input: SendVariationProposalAbandonmentInput!
  ) {
    sendVariationProposalAbandonment(input: $input) {
      id
    }
  }
`;

export const recordVariationProposalAbandonmentMutation = gql`
  mutation recordVariationProposalAbandonment(
    $input: RecordVariationProposalAbandonmentInput!
  ) {
    recordVariationProposalAbandonment(input: $input) {
      id
    }
  }
`;

export const generateSendVariationProposalAbandonmentPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationProposalAbandonmentPreview(
    $input: SendVariationProposalAbandonmentPreviewInput!
  ) {
    generateSendVariationProposalAbandonmentPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationProposalAbandonmentPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationProposalAbandonmentPreview(
    $input: RecordVariationProposalAbandonmentPreviewInput!
  ) {
    generateRecordVariationProposalAbandonmentPreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
