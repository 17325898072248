import { gql } from "@apollo/client";
import { draftClaimAgreementFields } from "graphql/fragments/draftClaimAgreement.fragment";
import { draftClaimDeterminationFields } from "graphql/fragments/draftClaimDetermination.fragment";
import { draftDetailedClaimFields } from "graphql/fragments/draftIterimDetailedClaim.fragment";

export const addDraftDetailedClaimMutation = gql`
  ${draftDetailedClaimFields}

  mutation addDraftDetailedClaim($input: AddDraftDetailedClaimInput!) {
    addDraftDetailedClaim(input: $input) {
      ...DraftDetailedClaimFields
    }
  }
`;

export const editDraftDetailedClaimMutation = gql`
  ${draftDetailedClaimFields}

  mutation editDraftDetailedClaim($input: EditDraftDetailedClaimInput!) {
    editDraftDetailedClaim(input: $input) {
      ...DraftDetailedClaimFields
    }
  }
`;

export const removeDraftDetailedClaimMutation = gql`
  mutation removeDraftDetailedClaim($id: ID!) {
    removeDraftDetailedClaim(id: $id)
  }
`;

export const addDraftClaimAgreementMutation = gql`
  ${draftClaimAgreementFields}

  mutation addDraftClaimAgreement($input: AddDraftClaimAgreementInput!) {
    addDraftClaimAgreement(input: $input) {
      ...DraftClaimAgreementFields
    }
  }
`;

export const editDraftClaimAgreementMutation = gql`
  ${draftClaimAgreementFields}

  mutation editDraftClaimAgreement($input: EditDraftClaimAgreementInput!) {
    editDraftClaimAgreement(input: $input) {
      ...DraftClaimAgreementFields
    }
  }
`;

export const removeDraftClaimAgreementMutation = gql`
  mutation removeDraftClaimAgreement($id: ID!) {
    removeDraftClaimAgreement(id: $id)
  }
`;

export const addDraftClaimDeterminationMutation = gql`
  ${draftClaimDeterminationFields}

  mutation addDraftClaimDetermination(
    $input: AddDraftClaimDeterminationInput!
  ) {
    addDraftClaimDetermination(input: $input) {
      ...DraftClaimDeterminationFields
    }
  }
`;

export const editDraftClaimDeterminationMutation = gql`
  ${draftClaimDeterminationFields}

  mutation editDraftClaimDetermination(
    $input: EditDraftClaimDeterminationInput!
  ) {
    editDraftClaimDetermination(input: $input) {
      ...DraftClaimDeterminationFields
    }
  }
`;

export const removeDraftClaimDeterminationMutation = gql`
  mutation removeDraftClaimDetermination($id: ID!) {
    removeDraftClaimDetermination(id: $id)
  }
`;
