import { Box, Grid } from "@mui/material";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { CompEventAbandonInstructionPrompt } from "generated/graphql";

import { useContext, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAbandonInstructionCEAction } from "./useAbandonInstructionCEAction";
import { SendCEInstructionAbandon } from "./SendCEInstructionAbandon";
import { RecordCEInstructionAbandon } from "./RecordCEInstructionAbandon";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";

const defaultFormData: CompEventAbandonInstructionPrompt = {
  remarks: "",
};

export const AbandonInstructionCEAction: React.FC<CEActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { compEvent } = useContext(CompEventWidgetContext);

  const [formData, setFormData] =
    useState<CompEventAbandonInstructionPrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendCompEventAbandonInstruction,
    recordCompEventAbandonInstruction,
    actionLoading,
  } = useAbandonInstructionCEAction();

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleTextFieldChange = (remarks: string) => {
    setFormData((curData) => ({
      ...curData,
      remarks,
    }));
  };

  const handleSendCERejection = async () => {
    await sendCompEventAbandonInstruction({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          remarks: formData.remarks,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCERejection = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCompEventAbandonInstruction({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
          remarks: formData.remarks,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => true,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    []
  );

  return (
    <>
      {sendModalVisibility && (
        <SendCEInstructionAbandon
          open={sendModalVisibility}
          remarks={formData.remarks}
          onPrimaryClick={handleSendCERejection}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {recordModalVisibility && (
        <RecordCEInstructionAbandon
          open={recordModalVisibility}
          remarks={formData.remarks}
          onPrimaryClick={handleRecordCERejection}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.remarks")} />
            <RichTextArea
              content={formData.remarks ?? ""}
              onChange={handleTextFieldChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
