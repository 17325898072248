import { Box, css, styled, Tooltip, Typography, useTheme } from "@mui/material";
import { Info } from "phosphor-react";
import { useTranslation } from "react-i18next";

const StyledUnorderedList = styled("ul")(
  ({ theme }) => css`
    padding-left: ${theme.spacing(2)};
    margin: 0;
  `
);

export const PasswordInfo: React.FC<{ color?: string; error?: boolean }> = ({
  color,
  error,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const computedColor = error
    ? theme.palette.error.main
    : color ?? theme.palette.grey[700];

  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column" p={1}>
          <Typography variant="caption">
            {t("Register.passwordTip.header")}
          </Typography>
          <StyledUnorderedList>
            <li>
              <Typography variant="caption">
                {t("Register.passwordTip.length")}
              </Typography>
            </li>
            <li>
              <Typography variant="caption">
                {t("Register.passwordTip.atLeastOneDigit")}
              </Typography>
            </li>
            <li>
              <Typography variant="caption">
                {t("Register.passwordTip.atLeastOneSpecialChar")}
              </Typography>
            </li>
            <li>
              <Typography variant="caption">
                {t("Register.passwordTip.atLeastOneUppercaseLetter")}
              </Typography>
            </li>
            <li>
              <Typography variant="caption">
                {t("Register.passwordTip.atLeastOneLowercaseLetter")}
              </Typography>
            </li>
          </StyledUnorderedList>
        </Box>
      }
      arrow
      placement="bottom-end"
    >
      <Box display="flex">
        <Info size={18} color={computedColor} />
      </Box>
    </Tooltip>
  );
};
