import { ActionsDialogProps } from "components/ActionsDialog";
import { VariationActionType, VariationProposal } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendConsentToProposalForProposedVariationModal } from "./useSendConsentToProposalForProposedVariationModal";
import { ConsentToProposalForProposedVariationSummary } from "./ConsentToProposalForProposedVariationSummary";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";

export type SendConsentToProposalForProposedVariationModalProps = {
  remarks: string;
  acceptedProposal: VariationProposal;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendConsentToProposalForProposedVariationModal: React.FC<
  SendConsentToProposalForProposedVariationModalProps
> = ({ remarks, acceptedProposal, attachments = [], ...restDialogProps }) => {
  const { variationPreview, contract } =
    useSendConsentToProposalForProposedVariationModal(
      remarks,
      acceptedProposal.id
    );
  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.ConsentToProposalForProposedVariation}
      summaryEl={
        <ConsentToProposalForProposedVariationSummary
          remarks={remarks}
          proposal={acceptedProposal}
          attachments={attachments}
          allowViewDetails={false}
          contract={contract}
        />
      }
      {...restDialogProps}
    />
  );
};
