import { Box, Switch, useTheme } from "@mui/material";
import { EffectEstimationType, EffectOption } from "generated/graphql";
import { ChangeEvent } from "react";
import { EffectOptionAnswer } from "../Effect.decl";
import { UnitValueEditable } from "./UnitValueEditable";
import { RichTextArea } from "components/RichTextArea/RichTextArea";

export type EffectOptionFieldEditableProps = {
  effectOption: EffectOption;
  data?: EffectOptionAnswer;
  contractCurrency: string;
  onChange: (data: EffectOptionAnswer) => void;
};

export const EffectOptionFieldEditable: React.FC<
  EffectOptionFieldEditableProps
> = ({ effectOption, data, contractCurrency, onChange }) => {
  const theme = useTheme();

  const handleSwitchChange = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onChange({
      ...data,
      optionId: effectOption.id,
      selected: checked,
      ...(checked ? { value: data?.value } : { value: undefined }),
    });
  };

  const handleUnitValueChange = (newValue: string) => {
    onChange({
      selected: !!data?.selected,
      optionId: effectOption.id,
      value: newValue,
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <RichTextArea
          readOnly
          content={effectOption.label}
          color={theme.palette.grey[600]}
          fontSize={theme.typography.p1.fontSize}
        />
        <Switch
          checked={!!data?.selected}
          onChange={handleSwitchChange}
          data-testid={`${effectOption.label}-switch`}
        />
      </Box>
      {effectOption.estimationType !== EffectEstimationType.None &&
        !!data?.selected && (
          <Box mt={2} data-testid={`${effectOption.label}-container`}>
            <UnitValueEditable
              unit={effectOption.unit}
              value={data?.value || ""}
              currency={contractCurrency}
              estimationType={effectOption.estimationType}
              onChange={handleUnitValueChange}
            />
          </Box>
        )}
    </Box>
  );
};
