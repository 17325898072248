import {
  VariationAgreement,
  VariationDetermination,
  VariationProposal,
  VariationProposalExplorerItem,
  VariationProposalExplorerItemsQuery,
  VariationProposalExplorerItemsQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext, useMemo } from "react";
import { VariationWidgetContext } from "../VariationWidget/VariationWidget.context";
import { variationProposalExplorerItemsQuery } from "./useVariationProposalExplorerItems.query";

export const useVariationProposalExplorerItems = (variationId?: string) => {
  const { variation } = useContext(VariationWidgetContext);

  const { data: explorerItemsData, loading } = useGraphQuery<
    VariationProposalExplorerItemsQuery,
    VariationProposalExplorerItemsQueryVariables
  >(variationProposalExplorerItemsQuery, {
    variables: { variationId: variationId ?? variation?.id! },
    skip: !variationId && !variation,
  });

  const variationProposals = useMemo(
    () =>
      (explorerItemsData?.variationProposalExplorerItems.items.filter(
        (item) => item.__typename === "VariationProposal"
      ) || []) as VariationProposal[],
    [explorerItemsData]
  );

  const variationAgreements = useMemo(
    () =>
      (explorerItemsData?.variationProposalExplorerItems.items.filter(
        (item) => item.__typename === "VariationAgreement"
      ) || []) as VariationAgreement[],
    [explorerItemsData]
  );

  const variationDeterminations = useMemo(
    () =>
      (explorerItemsData?.variationProposalExplorerItems.items.filter(
        (item) => item.__typename === "VariationDetermination"
      ) || []) as VariationDetermination[],
    [explorerItemsData]
  );

  const explorerItems = useMemo(
    () =>
      (explorerItemsData?.variationProposalExplorerItems.items ??
        []) as VariationProposalExplorerItem[],
    [explorerItemsData]
  );

  return {
    variationProposals,
    variationAgreements,
    variationDeterminations,
    explorerItems,
    loading,
  };
};
