import { OnMutationDone } from "types/decl";
import {
  AddDocumentTemplateRouteInput,
  AddDocumentTemplateRouteOverrideInput,
  ContractType,
  ContractTypesWithBindingTypesQuery,
  ContractTypesWithBindingTypesQueryVariables,
  DocumentTemplateRoute,
  DocumentTemplateRoutesQuery,
  DocumentTemplateRoutesQueryVariables,
  EditDocumentTemplateRouteInput,
  EditDocumentTemplateRouteOverrideInput,
  Product,
  ProductsWithOutputActionsQuery,
  ProductsWithOutputActionsQueryVariables,
} from "generated/graphql";
import { contractTypesWithBindingTypesQuery } from "graphql/queries/contractTypes.query";
import { productsWithOutputActionsQuery } from "graphql/queries/products.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { documentTemplateRoutesQuery } from "./DocumentTemplateRouter.query";
import { DocumentTemplateRoutes } from "./DocumentTemplateRoutes/DocumentTemplateRoutes";
import { useDocumentTemplateRoutes } from "./DocumentTemplateRoutes/useDocumentTemplateRoutes";
import { useDocumentTemplates } from "../useDocumentTemplates";

export const DocumentTemplateRouter = () => {
  const { documentTemplates, fetchDocumentTemplatesLoading } =
    useDocumentTemplates(true, true);

  const {
    data: documentTemplateRoutes,
    refetch: refetchDocumentTemplateRoutes,
    loading: fetchDocumentTemplateRoutesLoading,
  } = useGraphQuery<
    DocumentTemplateRoutesQuery,
    DocumentTemplateRoutesQueryVariables
  >(documentTemplateRoutesQuery);

  const {
    data: contractTypesWithBindingTypes,
    loading: fetchContractTypesWithBindingTypesLoading,
  } = useGraphQuery<
    ContractTypesWithBindingTypesQuery,
    ContractTypesWithBindingTypesQueryVariables
  >(contractTypesWithBindingTypesQuery);

  const {
    data: productsWithOutputActions,
    loading: fetchProductsWithOutputActionsLoading,
  } = useGraphQuery<
    ProductsWithOutputActionsQuery,
    ProductsWithOutputActionsQueryVariables
  >(productsWithOutputActionsQuery);

  const handleDocumentTemplateRoutesUpdated: OnMutationDone = (cache) => {
    refetchDocumentTemplateRoutes();
    cache.evict({ id: "ROOT_QUERY", fieldName: "documentTemplateRoutes" });
    cache.gc();
  };

  const {
    addDocumentTemplateRoute,
    addDocumentTemplateRouteLoading,
    editDocumentTemplateRoute,
    editDocumentTemplateRouteLoading,
    removeDocumentTemplateRoute,
    removeDocumentTemplateRouteLoading,
  } = useDocumentTemplateRoutes({
    onAdd: handleDocumentTemplateRoutesUpdated,
    onEdit: handleDocumentTemplateRoutesUpdated,
    onRemove: handleDocumentTemplateRoutesUpdated,
  });

  const handleAddDocumentTemplateRoute = (
    input: AddDocumentTemplateRouteInput | AddDocumentTemplateRouteOverrideInput
  ) => {
    return addDocumentTemplateRoute(input as AddDocumentTemplateRouteInput);
  };
  const handleEditDocumentTemplateRoute = (
    input:
      | EditDocumentTemplateRouteInput
      | EditDocumentTemplateRouteOverrideInput
  ) => {
    return editDocumentTemplateRoute(input as EditDocumentTemplateRouteInput);
  };

  return (
    <DocumentTemplateRoutes
      products={(productsWithOutputActions?.products.items ?? []) as Product[]}
      contractTypes={
        (contractTypesWithBindingTypes?.contractTypes.items ??
          []) as ContractType[]
      }
      documentTemplates={documentTemplates}
      documentTemplateRoutes={
        (documentTemplateRoutes?.documentTemplateRoutes.items ??
          []) as DocumentTemplateRoute[]
      }
      loading={
        fetchDocumentTemplatesLoading ||
        fetchContractTypesWithBindingTypesLoading ||
        fetchProductsWithOutputActionsLoading ||
        fetchDocumentTemplateRoutesLoading ||
        addDocumentTemplateRouteLoading ||
        editDocumentTemplateRouteLoading ||
        removeDocumentTemplateRouteLoading
      }
      onAddDocumentTemplateRoute={handleAddDocumentTemplateRoute}
      onEditDocumentTemplateRoute={handleEditDocumentTemplateRoute}
      onRemoveDocumentTemplateRoute={removeDocumentTemplateRoute}
    />
  );
};
