import { gql } from "@apollo/client";

export const contractLiteQuery = gql`
  query contractLite($id: ID!) {
    contract(id: $id) {
      id
      name
      friendlyName
      contractTypeId
      timeZone
      valueCurrency
      contractType {
        description
        subType
        version
        id
      }
    }
  }
`;
