import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  GenerateSendClaimNoticeInvalidNoticePreviewMutation,
  GenerateSendClaimNoticeInvalidNoticePreviewMutationVariables,
  SendClaimNoticeInvalidNoticePreview,
} from "generated/graphql";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { generateSendClaimNoticeInvalidNoticePreviewMutation } from "./ConsiderNoticeOfClaimInvalidAction.query";

export const useSendNoticeOfClaimInvalidModal = (reasons: string) => {
  const [claimPreview, setClaimPreview] =
    useState<SendClaimNoticeInvalidNoticePreview>();

  const { claim, contract } = useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimNoticeInvalidNoticePreviewMutation,
    GenerateSendClaimNoticeInvalidNoticePreviewMutationVariables
  >(generateSendClaimNoticeInvalidNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            reasons,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateSendClaimNoticeInvalidNoticePreview);
      }
    }, [generateClaimPreview, claim, reasons]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
