import { Box, Color, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { IconContainer } from "components/IconContainer";
import { CheckCircle } from "phosphor-react";
import { useTranslation } from "react-i18next";

type ApproveDailyDiaryModalProps = {} & ActionsDialogProps;

export const ApproveDailyDiaryModal: React.FC<ApproveDailyDiaryModalProps> = ({
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ActionsDialog
      iconsHeader={
        <IconContainer>
          <CheckCircle
            size={24}
            color={(theme.palette.secondary as Partial<Color>)[700]}
          />
        </IconContainer>
      }
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Projects.DailyDiaries.ApproveDailyDiaryModal.title")}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]} mb={2}>
            {t("Projects.DailyDiaries.ApproveDailyDiaryModal.subtitle")}
          </Typography>
        </Box>
      }
      fullWidth
      maxWidth="xs"
      primaryBtnCaption={t("common.buttons.approve")}
      secondaryBtnCaption={t("common.buttons.cancel")}
      {...restDialogProps}
    />
  );
};
