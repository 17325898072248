import { Box, Divider, Tooltip, Typography, useTheme } from "@mui/material";
import { Plugs } from "phosphor-react";
import { useTranslation } from "react-i18next";

export const RecordedMark = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <Divider
        orientation="vertical"
        sx={{
          borderColor: theme.palette.grey[300],
          marginRight: theme.spacing(1),
          marginLeft: theme.spacing(1),
          height: "15px",
        }}
      />
      <Tooltip
        title={
          <Box display="flex" flexDirection="column" p={2} pt={1}>
            <Typography variant="p2" fontWeight={600}>
              {t("Projects.EarlyWarnings.sentOffline")}
            </Typography>
            <Typography variant="p2" mt={0.5}>
              {t("Projects.EarlyWarnings.sentOfflineInfoText")}
            </Typography>
          </Box>
        }
        arrow
        placement="bottom"
      >
        <Box display="flex" alignItems="center">
          <Plugs size={16} color={theme.palette.grey[800]} />
          <Typography
            variant="p3"
            fontWeight={700}
            color="grey.800"
            ml={0.5}
            textTransform="uppercase"
          >
            {t("common.labels.offline")}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};
