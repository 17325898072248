import { gql } from "@apollo/client";
import { attachmentLiteFields } from "graphql/fragments/attachmentLite.fragment";
import { claimAgreementLiteFields } from "graphql/fragments/claimAgreementLite.fragment";
import { claimDeterminationLiteFields } from "graphql/fragments/claimDeterminationLite.fragment";
import { detailedClaimLiteFields } from "graphql/fragments/detailedClaimLite.fragment";

export const claimDetailsExplorerItemsQuery = gql`
  ${detailedClaimLiteFields}
  ${claimAgreementLiteFields}
  ${claimDeterminationLiteFields}
  ${attachmentLiteFields}

  query claimDetailsExplorerItems($claimId: ID!) {
    claimDetailsExplorerItems(claimId: $claimId) {
      items {
        ... on DetailedClaim {
          ...DetailedClaimLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
        ... on ClaimAgreement {
          ...ClaimAgreementLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
        ... on ClaimDetermination {
          ...ClaimDeterminationLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }
    }
  }
`;
