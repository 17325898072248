import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useTranslation } from "react-i18next";
import {
  PageContentHeader,
  PageContentHeaderSize,
} from "../../../../../../components/PageContentHeader/PageContentHeader";
import { HeaderLoadingContainer } from "../../components/HeaderLoadingContainer";

export type InviteUserHeaderProps = {
  companyName?: string;
  generalLoading?: boolean;
  onSendInviteLoading?: boolean;
  onSendInvite: () => void;
};

export const InviteUserHeader: React.FC<InviteUserHeaderProps> = ({
  companyName,
  generalLoading,
  onSendInviteLoading,
  onSendInvite,
}) => {
  const { t } = useTranslation();
  const handleNavigateBack = useNavigateBack();

  return (
    <PageContentHeader size={PageContentHeaderSize.Large}>
      {generalLoading && (
        <HeaderLoadingContainer>
          <CircularProgress />
        </HeaderLoadingContainer>
      )}
      <Box display="flex" alignItems="center">
        <ArrowBackIcon
          onClick={handleNavigateBack}
          style={{ cursor: "pointer" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          ml={3}
        >
          <Typography variant="subtitle2">{companyName}</Typography>
          <Typography variant="h5">
            {t("AdminConsole.Users.labels.inviteUser")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" ml="auto">
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onSendInvite}
          disableRipple
          style={{ textTransform: "none" }}
          size="small"
          loading={onSendInviteLoading}
        >
          <Typography variant="body2" fontWeight={500} color="white">
            {t("AdminConsole.Users.labels.sendInvite")}
          </Typography>
        </LoadingButton>
      </Box>
    </PageContentHeader>
  );
};
