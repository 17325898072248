import { TextFieldProps, Typography } from "@mui/material";
import { PlusMinusController } from "./PlusMinusController";
import { NakedTextField, UnitInputContainer } from "./UnitInput.styled";

export type UnitInputProps = {
  unit: string;
  onChange: (newValue: number | undefined) => void;
  onIncrement: () => void;
  onDecrement: () => void;
} & Omit<TextFieldProps, "onChange">;

export const UnitInput: React.FC<UnitInputProps> = ({
  unit,
  value,
  onChange,
  onIncrement,
  onDecrement,
  ...textFieldProps
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    onChange(evt.target.value ? Number(evt.target.value) : undefined);
  };

  return (
    <UnitInputContainer>
      <NakedTextField
        value={value}
        type="number"
        variant="standard"
        fullWidth
        InputProps={{
          disableUnderline: true,
        }}
        onChange={handleChange}
        {...textFieldProps}
      />
      <Typography ml={1} mr={3} variant="p2" color="grey.500">
        {unit}
      </Typography>
      <PlusMinusController
        onIncrement={onIncrement}
        onDecrement={onDecrement}
      />
    </UnitInputContainer>
  );
};
