import { useContractTypeTimePeriods } from "containers/AdminConsole/containers/System/containers/ContractTypes/ContractTypeDetails/ContractTypeTimePeriods/useContractTypeTimePeriods";
import {
  AddTimePeriodOverrideInput,
  AddTimePeriodOverrideMutation,
  AddTimePeriodOverrideMutationVariables,
  RemoveTimePeriodOverrideMutation,
  RemoveTimePeriodOverrideMutationVariables,
  TimePeriodOverride,
  TimePeriodsOverrideQuery,
  TimePeriodsOverrideQueryVariables,
} from "generated/graphql";
import { addTimePeriodOverrideMutation } from "graphql/mutations/addTimePeriodOverride";
import { removeTimePeriodOverrideMutation } from "graphql/mutations/removeTimePeriodOverride";
import { timePeriodsOverrideQuery } from "graphql/queries/timePeriodsOverride.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useTimePeriodsOverride = (
  contractTypeId?: string,
  contractId?: string
) => {
  const { t } = useTranslation();

  const { timePeriods, loading: timePeriodsLoading } =
    useContractTypeTimePeriods(contractTypeId);

  const {
    data: timePeriodsOverride,
    loading: timePeriodsOverrideLoading,
    refetch: refetchTimePeriodsOverride,
  } = useGraphQuery<
    TimePeriodsOverrideQuery,
    TimePeriodsOverrideQueryVariables
  >(timePeriodsOverrideQuery, {
    skip: !contractId,
    variables: { contractId: contractId! },
  });

  const [doAddTimePeriodOverride, { loading: addTimePeriodOverrideLoading }] =
    useGraphMutation<
      AddTimePeriodOverrideMutation,
      AddTimePeriodOverrideMutationVariables
    >(
      addTimePeriodOverrideMutation,
      {
        update: () => {
          refetchTimePeriodsOverride();
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("AdminConsole.TimePeriods.timePeriodOverride"),
      })
    );

  const [
    doRemoveTimePeriodOverride,
    { loading: removeTimePeriodOverrideLoading },
  ] = useGraphMutation<
    RemoveTimePeriodOverrideMutation,
    RemoveTimePeriodOverrideMutationVariables
  >(
    removeTimePeriodOverrideMutation,
    {
      update: () => {
        refetchTimePeriodsOverride();
      },
    },
    t("common.successMessages.entityDeleted", {
      entity: t("AdminConsole.TimePeriods.timePeriodOverride"),
    })
  );

  const addTimePeriodOverride = useCallback(
    async (timePeriodOverrideInput: AddTimePeriodOverrideInput) => {
      const { data } = await doAddTimePeriodOverride({
        variables: { input: timePeriodOverrideInput },
      });

      return !!data;
    },
    [doAddTimePeriodOverride]
  );

  const removeTimePeriodOverride = useCallback(
    async (timePeriodOverrideId: string) => {
      const { data } = await doRemoveTimePeriodOverride({
        variables: { id: timePeriodOverrideId },
      });

      return !!data;
    },
    [doRemoveTimePeriodOverride]
  );

  return {
    timePeriods,
    timePeriodsOverride: (timePeriodsOverride?.timePeriodsOverride.items ??
      []) as TimePeriodOverride[],
    loading:
      timePeriodsLoading ||
      timePeriodsOverrideLoading ||
      addTimePeriodOverrideLoading ||
      removeTimePeriodOverrideLoading,
    addTimePeriodOverride,
    removeTimePeriodOverride,
  };
};
