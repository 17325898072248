import { Box, css, styled } from "@mui/material";

export const TableContainer = styled(Box)(
  ({ theme }) => css`
    flex: 1;
    width: 100%;
    overflow: auto;
    max-height: 550px;
    margin-top: ${theme.spacing(3)};
  `
);
