// import { DDSection } from "containers/Projects/containers/DailyDiary/components/DailyDiaryInterpretor/DDSectionsInterpretor";
import { create } from "zustand";

type DiaryState = {
  // used across Diary functionality to easily differentiate if diary is empty or not
  dailyDiaryId?: string | null;
  setDailyDiaryId: (value?: string | null) => void;

  // general info - maybe to be deleted
  dailyDiaryDate?: string | null;
  setDailyDiaryDate: (value?: string | null) => void;

  // used to distinguish which is the selected revision
  revisionId?: string | null;
  setRevisionId: (value?: string | null) => void;

  // misc
  clearStore: () => void;
};

export const useDiaryStore = create<DiaryState>()((set) => ({
  dailyDiaryId: undefined,
  setDailyDiaryId: (dailyDiaryId?: string | null) =>
    set(() => ({ dailyDiaryId })),

  revisionId: undefined,
  setRevisionId: (revisionId?: string | null) => set(() => ({ revisionId })),

  dailyDiaryDate: undefined,
  setDailyDiaryDate: (dailyDiaryDate?: string | null) =>
    set(() => ({ dailyDiaryDate })),
  clearStore: () =>
    set(() => ({
      dailyDiaryId: undefined,
      revisionId: undefined,
      dailyDiaryDate: undefined,
    })),
}));
