import {
  ExtendedOptionStyles,
  StatusOptionNew,
} from "components/StatusTag/StatusTagNew";
import { ClaimStatus } from "generated/graphql";

export const claimStatusOptions: StatusOptionNew<string>[] = [
  {
    id: ClaimStatus.Agreed,
    label: `Projects.Claims.status.${ClaimStatus.Agreed}`,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: ClaimStatus.Determined,
    label: `Projects.Claims.status.${ClaimStatus.Determined}`,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: ClaimStatus.Final,
    label: `Projects.Claims.status.${ClaimStatus.Final}`,
    style: ExtendedOptionStyles.Purple,
  },
  {
    id: ClaimStatus.Interim,
    label: `Projects.Claims.status.${ClaimStatus.Interim}`,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: ClaimStatus.NotNotified,
    label: `Projects.Claims.status.${ClaimStatus.NotNotified}`,
    style: ExtendedOptionStyles.Black,
  },
  {
    id: ClaimStatus.Notified,
    label: `Projects.Claims.status.${ClaimStatus.Notified}`,
    style: ExtendedOptionStyles.Grey,
  },
];
