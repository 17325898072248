import { gql } from "@apollo/client";

export const compEventNoticeOfSilenceCompEventNoticePromptQuery = gql`
  query compEventNoticeOfSilenceCompEventNoticePrompt(
    $input: CompEventNoticeOfSilenceCompEventNoticePromptInput!
  ) {
    compEventNoticeOfSilenceCompEventNoticePrompt(input: $input) {
      remarks
    }
  }
`;
