import { Typography } from "@mui/material";

export const AuthPageHeader: React.FC<{
  title: string;
  subtitle: string;
}> = ({ title, subtitle }) => (
  <>
    <Typography variant="h1" color="grey.800" fontWeight={700}>
      {title}
    </Typography>
    <Typography
      fontSize="18px"
      lineHeight="24px"
      color="grey.700"
      mt={1}
      sx={{ wordWrap: "wrap" }}
    >
      {subtitle}
    </Typography>
  </>
);
