import { Chip, css, styled } from "@mui/material";

const StyledChip = styled(Chip)(
  ({ theme }) => css`
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    padding-top: ${theme.spacing(0.5)};
    padding-bottom: ${theme.spacing(0.5)};
    color: ${theme.palette.info.main};
    background-color: ${theme.palette.info.light};
    font-size: ${theme.typography.p3.fontSize};
    line-height: ${theme.typography.p3.lineHeight};
    font-weight: ${theme.typography.p3.fontWeight};
    height: auto;

    .MuiChip-label {
      padding-left: 0;
      padding-right: 0;
    }
  `
);

export const AuthorizationRequiredPill = () => {
  return <StyledChip label="Authorization required" variant="filled" />;
};
