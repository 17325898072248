import { gql } from "@apollo/client";
import { dailyDiaryPresetSchemaSectionFieldFields } from "graphql/fragments/dailyDiaryPresetSchemaSectionField.fragment";

export const dailyDiaryPresetSchemaQuery = gql`
  ${dailyDiaryPresetSchemaSectionFieldFields}

  query dailyDiaryPresetSchema {
    dailyDiaryPresetSchema {
      lookups {
        id
        name
      }
      sections {
        name
        fields {
          ...DailyDiaryPresetSchemaSectionFieldFields
        }
      }
    }
  }
`;
