import { gql } from "@apollo/client";
import { claimHistoryItemFields } from "graphql/fragments/claimHistoryItem.fragment";

export const claimHistoryItemsQuery = gql`
  ${claimHistoryItemFields}

  query claimHistoryItems($claimId: ID!) {
    claimHistoryItems(claimId: $claimId) {
      items {
        ...ClaimHistoryItemFields
      }
    }
  }
`;
