import { ResourceFormDataType } from "./ResourceRecordForm";

export const defaultFormData: ResourceFormDataType = {
  id: "",
  attachments: [],
  companyOptionId: "",
  hours: null,
  quantity: "" as any,
  remarks: "",
  resourceOptionId: "",
  teamOptionId: "",
  totalHours: "",
  shiftOptionId: "",
};
