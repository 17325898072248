import { QueryMatch } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import {
  AtSignMentionsRegex,
  AtSignMentionsRegexAliasRegex,
  CapitalizedNameMentionsRegex,
  CHARS_TO_TRIGGER_NAME_SUGGESTIONS,
} from "./constants";

export const checkForCapitalizedNameMentions = (
  text: string,
  minMatchLength: number
): QueryMatch | null => {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      };
    }
  }
  return null;
};

export const checkForAtSignMentions = (
  text: string,
  minMatchLength: number
): QueryMatch | null => {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
};

export const getPossibleQueryMatch = (text: string): QueryMatch | null => {
  const match = checkForAtSignMentions(text, 1);
  return match === null
    ? checkForCapitalizedNameMentions(text, CHARS_TO_TRIGGER_NAME_SUGGESTIONS)
    : match;
};
