import {
  ContractBindingType,
  ContractBindingTypesQuery,
  ContractBindingTypesQueryVariables,
} from "generated/graphql";
import { contractBindingTypesQuery } from "graphql/queries/contractBindingTypes.query";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useContractBindingTypes = (contractId: string) => {
  const { data: contractWithBindingTypes, loading } = useGraphQuery<
    ContractBindingTypesQuery,
    ContractBindingTypesQueryVariables
  >(contractBindingTypesQuery, { variables: { id: contractId } });

  return {
    bindingTypes: (contractWithBindingTypes?.contract.contractType
      .contractBindingTypes.items ?? []) as ContractBindingType[],
    loading,
  };
};
