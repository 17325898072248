import { Box, css, styled } from "@mui/material";

export const Overlay = styled(Box, {
  shouldForwardProp: (prop) => prop !== "withBackground",
})<{ withBackground?: boolean }>(
  ({ withBackground = true }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: ${withBackground ? "rgba(158, 158, 158, 0.1)" : "transparent"};
  `
);
