import {
  Contract,
  ContractByProductInstanceQuery,
  ContractByProductInstanceQueryVariables,
} from "generated/graphql";
import { contractByProductInstanceQuery } from "./query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";

export const useContractByProductInstanceId = (
  productInstanceId: string,
  skip?: boolean
) => {
  const { data: productInstance, loading } = useGraphQuery<
    ContractByProductInstanceQuery,
    ContractByProductInstanceQueryVariables
  >(contractByProductInstanceQuery, {
    variables: {
      id: productInstanceId,
    },
    fetchPolicy: "cache-and-network",
    skip,
  });

  const contract = useMemo(
    () => productInstance?.productInstance.contract as Contract | undefined,
    [productInstance]
  );

  return {
    contract,
    loading,
  };
};
