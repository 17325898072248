import {
  AddEffectPresetInput,
  AddEffectPresetMutation,
  AddEffectPresetMutationVariables,
  EditEffectPresetInput,
  EditEffectPresetMutation,
  EditEffectPresetMutationVariables,
} from "generated/graphql";
import {
  addEffectPresetMutation,
  editEffectPresetMutation,
} from "../../Effect.query";
import { useTranslation } from "react-i18next";
import { useGraphMutation } from "hooks/useGraphMutation";
import { OnMutationDone } from "types/decl";
import { useChangeEffectPresetStatus } from "./useChangeEffectPresetStatus";

export type useEffectPresetsArgs = {
  onAdd: OnMutationDone;
  onEdit: OnMutationDone;
  onStatusChange: OnMutationDone;
};

export const useEffectPresets = ({
  onStatusChange,
  onAdd,
  onEdit,
}: useEffectPresetsArgs) => {
  const { t } = useTranslation();

  const { loading: changeEffectPresetStatusLoading, changeEffectPresetStatus } =
    useChangeEffectPresetStatus(onStatusChange);

  const [doAddEffectPreset, { loading: addEffectPresetLoading }] =
    useGraphMutation<AddEffectPresetMutation, AddEffectPresetMutationVariables>(
      addEffectPresetMutation,
      {
        update: onAdd,
      },
      t("common.successMessages.entityCreated", {
        entity: t("AdminConsole.Effect.labels.effectPreset"),
      })
    );

  const [doEditEffectPreset, { loading: editEffectPresetLoading }] =
    useGraphMutation<
      EditEffectPresetMutation,
      EditEffectPresetMutationVariables
    >(
      editEffectPresetMutation,
      {
        update: onEdit,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("AdminConsole.Effect.labels.effectPreset"),
      })
    );

  const addEffectPreset = async (input: AddEffectPresetInput) => {
    await doAddEffectPreset({
      variables: {
        input,
      },
    });
  };

  const editEffectPreset = async (input: EditEffectPresetInput) => {
    await doEditEffectPreset({
      variables: {
        input,
      },
    });
  };

  return {
    loading:
      changeEffectPresetStatusLoading ||
      addEffectPresetLoading ||
      editEffectPresetLoading,
    changeEffectPresetStatus,
    addEffectPreset,
    editEffectPreset,
  };
};
