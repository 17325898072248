import { Box, Stack } from "@mui/material";
import { Attachment } from "generated/graphql";
import { useState, useRef, useEffect } from "react";
import { AttachmentsDencity, FileType } from "../Attachments.decl";
import { AttachmentsContainer } from "../Attachments.styled";
import { filterAttachmentsByTab } from "../Attachments.utils";
import { AttachmentTab } from "./AttachmentsTabs";
import { AttachmentsTitle } from "./AttachmentsTitle";
import { DeleteAttachmentModal } from "./DeleteAttachmentModal";
import { DroppingArea } from "./DroppingArea/DroppingArea";
import { FileListItem } from "./FileListItem/FileListItem";
import {
  AttachmentsLayout,
  AttachmentsHeader,
} from "./AttachmentsHeader/AttachmentsHeader";
import { FileGridItem } from "./FileGridItem/FileGridItem";

export type AttachmentsEditableProps = {
  attachments: (Attachment | FileType)[];
  showTabs?: boolean;
  dencity?: AttachmentsDencity;
  defaultLayout?: AttachmentsLayout;
  hideTitle?: boolean;
  hideHeader?: boolean;
  onAttachmentsAdd: (newAttachments: FileType[]) => void;
  onAttachmentRemove: (attachment: FileType | Attachment) => void;
  onAttachmentUpdate: (updatedAttachment: FileType | Attachment) => void;
  onAttachmentClick: (attachment: FileType | Attachment) => void;
};

export const AttachmentsEditable: React.FC<AttachmentsEditableProps> = ({
  attachments,
  showTabs,
  dencity,
  defaultLayout,
  hideTitle,
  hideHeader,
  onAttachmentsAdd,
  onAttachmentRemove,
  onAttachmentUpdate,
  onAttachmentClick,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(AttachmentTab.All);
  const [tabAttachments, setTabAttachments] = useState<
    (Attachment | FileType)[]
  >(filterAttachmentsByTab(AttachmentTab.All, attachments));
  const fileToDelete = useRef<Attachment | FileType>();
  const [attachmentsLayout, setAttachmentsLayout] = useState<AttachmentsLayout>(
    defaultLayout ?? AttachmentsLayout.Grid
  );

  const triggerDeleteAttachmentFlow = (file: FileType | Attachment) => {
    fileToDelete.current = file;
    setDeleteModalVisible(true);
  };

  const handleDeleteAttachment = () => {
    setDeleteModalVisible(false);
    onAttachmentRemove(fileToDelete.current!);
    fileToDelete.current = undefined;
  };

  const handleAddDescription = (
    file: FileType | Attachment,
    description?: string
  ) => {
    onAttachmentUpdate({
      ...file,
      description,
    });
  };

  const handleSelectedTabChange = (selectedTab: string) => {
    setSelectedTab(selectedTab as AttachmentTab);
  };

  useEffect(() => {
    setTabAttachments(
      filterAttachmentsByTab(selectedTab as AttachmentTab, attachments)
    );
  }, [selectedTab, attachments]);

  return (
    <>
      <DeleteAttachmentModal
        open={deleteModalVisible}
        fileName={fileToDelete.current?.fileName || ""}
        onClose={() => {
          setDeleteModalVisible(false);
        }}
        onPrimaryClick={handleDeleteAttachment}
        onSecondaryClick={() => {
          setDeleteModalVisible(false);
        }}
      />
      <AttachmentsContainer>
        {!hideTitle && (
          <AttachmentsTitle
            attachmentsCount={attachments.length}
            dencity={dencity}
          />
        )}
        <DroppingArea onFilesUpload={onAttachmentsAdd} />
        {attachments.length > 0 && !hideHeader && (
          <AttachmentsHeader
            showTabs={!!showTabs}
            attachments={attachments}
            attachmentsLayout={attachmentsLayout}
            onFilterTabChange={handleSelectedTabChange}
            onLayoutChange={setAttachmentsLayout}
          />
        )}
        {attachmentsLayout === AttachmentsLayout.List ? (
          <Stack
            spacing={attachmentsLayout === AttachmentsLayout.List ? 1 : 1.5}
            pt={attachments.length ? 2 : 0}
          >
            {tabAttachments?.map((file) => (
              <FileListItem
                key={file.id}
                file={file}
                onAddDescription={handleAddDescription}
                onDelete={() => triggerDeleteAttachmentFlow(file)}
                onClick={() => onAttachmentClick(file)}
              />
            ))}
          </Stack>
        ) : (
          <Box
            display="grid"
            gridTemplateColumns={
              dencity === AttachmentsDencity.Comfortable
                ? "repeat(auto-fit, 270px)"
                : "repeat(auto-fit, 260px)"
            }
            gap={1.5}
            pt={2}
          >
            {tabAttachments?.map((file) => (
              <FileGridItem
                file={file}
                key={file.id}
                onAddDescription={handleAddDescription}
                onDelete={() => triggerDeleteAttachmentFlow(file)}
                onClick={() => onAttachmentClick(file)}
              />
            ))}
          </Box>
        )}
      </AttachmentsContainer>
    </>
  );
};
