import {
  DraftVariationProposal,
  GenerateSendVariationProposalForProposedVariationPreviewMutation,
  GenerateSendVariationProposalForProposedVariationPreviewMutationVariables,
  SendVariationProposalForProposedVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateSendVariationProposalForProposedVariationPreviewMutation } from "./SubmitProposalForProposedVariationAction.query";

export const useSendProposalForProposedVariationModal = (
  draftProposals: DraftVariationProposal[],
  preferredProposalId: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);
  const [variationPreview, setVariationPreview] =
    useState<SendVariationProposalForProposedVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationProposalForProposedVariationPreviewMutation,
    GenerateSendVariationProposalForProposedVariationPreviewMutationVariables
  >(generateSendVariationProposalForProposedVariationPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            preferredProposalId,
            proposalIds: draftProposals.map((proposal) => proposal.id),
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateSendVariationProposalForProposedVariationPreview
        );
      }
    }, [generatePreview, variation, preferredProposalId, draftProposals]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview,
    contract,
  };
};
