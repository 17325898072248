import { Box, Typography } from "@mui/material";
import { compEventActionTypeTranslations } from "containers/Projects/components/CompEvents/CompEventActionModal/components/CompEventAction/CompEventAction.constants";
import {
  ClaimActionType,
  CompEventActionType,
  ProductType,
  VariationActionType,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { claimActionTypeTranslations } from "../Claims/ClaimWidget/ClaimWidget.constants";
import { variationActionTypeTranslations } from "../Variations/VariationWidget/VariationWidget.constants";
import {
  variationActionTypeFIDIC17WhiteTypeMapping,
  variationActionTypeFIDIC99RedYellowTypeMapping,
} from "../Variations/Variations.types";
import { claimActionTypeFIDIC99RedYellowTypeMapping } from "../Claims/types";

export type HistoryItemDetailsHeaderProps = {
  isFIDIC17White?: boolean;
  isFIDIC99RedYellow?: boolean;
  historyItemActionType:
    | CompEventActionType
    | ClaimActionType
    | VariationActionType;
  historyItemNumber: string;
  productType:
    | ProductType.CompEvents
    | ProductType.Claims
    | ProductType.Variations;
};

export const HistoryItemDetailsHeader: React.FC<
  HistoryItemDetailsHeaderProps
> = ({
  historyItemActionType,
  productType,
  historyItemNumber,
  isFIDIC17White,
  isFIDIC99RedYellow,
}) => {
  const { t } = useTranslation();

  const variationActionTypeWording =
    variationActionTypeTranslations[
      isFIDIC17White
        ? variationActionTypeFIDIC17WhiteTypeMapping(
            historyItemActionType as VariationActionType
          )
        : isFIDIC99RedYellow
        ? variationActionTypeFIDIC99RedYellowTypeMapping(
            historyItemActionType as VariationActionType
          )
        : (historyItemActionType as VariationActionType)
    ];

  const claimActionTypeWording =
    claimActionTypeTranslations[
      isFIDIC99RedYellow
        ? claimActionTypeFIDIC99RedYellowTypeMapping(
            historyItemActionType as ClaimActionType
          )
        : (historyItemActionType as ClaimActionType)
    ];

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="h3" color="grey.900" fontWeight={600}>
        {`${t(
          productType === ProductType.CompEvents
            ? compEventActionTypeTranslations[
                historyItemActionType as CompEventActionType
              ]
            : productType === ProductType.Claims
            ? claimActionTypeWording
            : variationActionTypeWording
        )} (${historyItemNumber})`}
      </Typography>
    </Box>
  );
};
