import { SxProps, Theme } from "@mui/material";
import { PriceTimeTag, PriceTimeTagStyle } from "./PriceTimeTag";
import { formatNumber } from "helpers/miscelaneous";
import getSymbolFromCurrency from "currency-symbol-map";

type PriceTagProps = {
  value?: number | null;
  currency?: string;
  sx?: SxProps<Theme>;
};

export const PriceTag: React.FC<PriceTagProps> = ({ value, currency, sx }) => {
  let label;
  let style;
  if (typeof value === "number" && currency) {
    label = `${getSymbolFromCurrency(currency)} ${formatNumber(
      Math.abs(value)
    )}`;
    style =
      value > 0
        ? PriceTimeTagStyle.Increase
        : value < 0
        ? PriceTimeTagStyle.Decrease
        : PriceTimeTagStyle.Static;
  } else {
    label = "";
    style = PriceTimeTagStyle.Static;
  }

  return <PriceTimeTag type="price" label={label} style={style} sx={sx} />;
};
