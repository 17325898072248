import { CountriesData } from "./countries.decl";

export const countriesData: CountriesData = {
  AF: { name: "Afghanistan", phonePrefix: "+93", countryCodeA2: "AF" },
  AL: { name: "Albania", phonePrefix: "+355", countryCodeA2: "AL" },
  DZ: { name: "Algeria", phonePrefix: "+213", countryCodeA2: "DZ" },
  AS: { name: "American Samoa", phonePrefix: "+1-684", countryCodeA2: "AS" },
  AD: { name: "Andorra", phonePrefix: "+376", countryCodeA2: "AD" },
  AO: { name: "Angola", phonePrefix: "+244", countryCodeA2: "AO" },
  AI: { name: "Anguilla", phonePrefix: "+1-264", countryCodeA2: "AI" },
  AG: {
    name: "Antigua and Barbuda",
    phonePrefix: "+1-268",
    countryCodeA2: "AG",
  },
  AR: { name: "Argentina", phonePrefix: "+54", countryCodeA2: "AR" },
  AM: { name: "Armenia", phonePrefix: "+374", countryCodeA2: "AM" },
  AW: { name: "Aruba", phonePrefix: "+297", countryCodeA2: "AW" },
  AU: { name: "Australia", phonePrefix: "+61", countryCodeA2: "AU" },
  AT: { name: "Austria", phonePrefix: "+43", countryCodeA2: "AT" },
  AZ: { name: "Azerbaijan", phonePrefix: "+994", countryCodeA2: "AZ" },
  BS: { name: "Bahamas", phonePrefix: "+1-242", countryCodeA2: "BS" },
  BH: { name: "Bahrain", phonePrefix: "+973", countryCodeA2: "BH" },
  BD: { name: "Bangladesh", phonePrefix: "+880", countryCodeA2: "BD" },
  BB: { name: "Barbados", phonePrefix: "+1-246", countryCodeA2: "BB" },
  BY: { name: "Belarus", phonePrefix: "+375", countryCodeA2: "BY" },
  BE: { name: "Belgium", phonePrefix: "+32", countryCodeA2: "BE" },
  BZ: { name: "Belize", phonePrefix: "+501", countryCodeA2: "BZ" },
  BJ: { name: "Benin", phonePrefix: "+229", countryCodeA2: "BJ" },
  BM: { name: "Bermuda", phonePrefix: "+1-441", countryCodeA2: "BM" },
  BT: { name: "Bhutan", phonePrefix: "+975", countryCodeA2: "BT" },
  BO: { name: "Bolivia", phonePrefix: "+591", countryCodeA2: "BO" },
  BA: {
    name: "Bosnia and Herzegovina",
    phonePrefix: "+387",
    countryCodeA2: "BA",
  },
  BW: { name: "Botswana", phonePrefix: "+267", countryCodeA2: "BW" },
  BR: { name: "Brazil", phonePrefix: "+55", countryCodeA2: "BR" },
  IO: {
    name: "British Indian Ocean Territory",
    phonePrefix: "+246",
    countryCodeA2: "IO",
  },
  BG: { name: "Bulgaria", phonePrefix: "+359", countryCodeA2: "BG" },
  BF: { name: "Burkina Faso", phonePrefix: "+226", countryCodeA2: "BF" },
  BI: { name: "Burundi", phonePrefix: "+257", countryCodeA2: "BI" },
  KH: { name: "Cambodia", phonePrefix: "+855", countryCodeA2: "KH" },
  CM: { name: "Cameroon", phonePrefix: "+237", countryCodeA2: "CM" },
  CA: { name: "Canada", phonePrefix: "+1", countryCodeA2: "CA" },
  CV: { name: "Cape Verde", phonePrefix: "+238", countryCodeA2: "CV" },
  KY: { name: "Cayman Islands", phonePrefix: "+1-345", countryCodeA2: "KY" },
  CF: {
    name: "Central African Republic",
    phonePrefix: "+236",
    countryCodeA2: "CF",
  },
  TD: { name: "Chad", phonePrefix: "+235", countryCodeA2: "TD" },
  CL: { name: "Chile", phonePrefix: "+56", countryCodeA2: "CL" },
  CN: { name: "China", phonePrefix: "+86", countryCodeA2: "CN" },
  CO: { name: "Colombia", phonePrefix: "+57", countryCodeA2: "CO" },
  KM: { name: "Comoros", phonePrefix: "+269", countryCodeA2: "KM" },
  CG: {
    name: "Republic of the Congo",
    phonePrefix: "+242",
    countryCodeA2: "CG",
  },
  CD: {
    name: "Democratic Republic of the Congo",
    phonePrefix: "+243",
    countryCodeA2: "CD",
  },
  CK: { name: "Cook Islands", phonePrefix: "+682", countryCodeA2: "CK" },
  CR: { name: "Costa Rica", phonePrefix: "+506", countryCodeA2: "CR" },
  CI: { name: "Ivory Coast", phonePrefix: "+225", countryCodeA2: "CI" },
  HR: { name: "Croatia", phonePrefix: "+385", countryCodeA2: "HR" },
  CU: { name: "Cuba", phonePrefix: "+53", countryCodeA2: "CU" },
  CW: { name: "Curacao", phonePrefix: "+599", countryCodeA2: "CW" },
  CY: { name: "Cyprus", phonePrefix: "+357", countryCodeA2: "CY" },
  CZ: { name: "Czech Republic", phonePrefix: "+420", countryCodeA2: "CZ" },
  DK: { name: "Denmark", phonePrefix: "+45", countryCodeA2: "DK" },
  DJ: { name: "Djibouti", phonePrefix: "+253", countryCodeA2: "DJ" },
  DM: { name: "Dominica", phonePrefix: "+1-767", countryCodeA2: "DM" },
  DO: { name: "Dominican Republic", phonePrefix: "+1", countryCodeA2: "DO" }, // -809, -829, -849
  EC: { name: "Ecuador", phonePrefix: "+593", countryCodeA2: "EC" },
  EG: { name: "Egypt", phonePrefix: "+20", countryCodeA2: "EG" },
  SV: { name: "El Salvador", phonePrefix: "+503", countryCodeA2: "SV" },
  GQ: { name: "Equatorial Guinea", phonePrefix: "+240", countryCodeA2: "GQ" },
  ER: { name: "Eritrea", phonePrefix: "+291", countryCodeA2: "ER" },
  EE: { name: "Estonia", phonePrefix: "+372", countryCodeA2: "EE" },
  ET: { name: "Ethiopia", phonePrefix: "+251", countryCodeA2: "ET" },
  FK: { name: "Falkland Islands", phonePrefix: "+500", countryCodeA2: "FK" },
  FO: { name: "Faroe Islands", phonePrefix: "+298", countryCodeA2: "FO" },
  FJ: { name: "Fiji", phonePrefix: "+679", countryCodeA2: "FJ" },
  FI: { name: "Finland", phonePrefix: "+358", countryCodeA2: "FI" },
  FR: { name: "France", phonePrefix: "+33", countryCodeA2: "FR" },
  PF: { name: "French Polynesia", phonePrefix: "+689", countryCodeA2: "PF" },
  GA: { name: "Gabon", phonePrefix: "+241", countryCodeA2: "GA" },
  GM: { name: "Gambia", phonePrefix: "+220", countryCodeA2: "GM" },
  GE: { name: "Georgia", phonePrefix: "+995", countryCodeA2: "GE" },
  DE: { name: "Germany", phonePrefix: "+49", countryCodeA2: "DE" },
  GH: { name: "Ghana", phonePrefix: "+233", countryCodeA2: "GH" },
  GI: { name: "Gibraltar", phonePrefix: "+350", countryCodeA2: "GI" },
  GR: { name: "Greece", phonePrefix: "+30", countryCodeA2: "GR" },
  GL: { name: "Greenland", phonePrefix: "+299", countryCodeA2: "GL" },
  GD: { name: "Grenada", phonePrefix: "+1-473", countryCodeA2: "GD" },
  GU: { name: "Guam", phonePrefix: "+1-671", countryCodeA2: "GU" },
  GT: { name: "Guatemala", phonePrefix: "+502", countryCodeA2: "GT" },
  GG: { name: "Guernsey", phonePrefix: "+44-1481", countryCodeA2: "GG" },
  GN: { name: "Guinea", phonePrefix: "+224", countryCodeA2: "GN" },
  GW: { name: "Guinea-Bissau", phonePrefix: "+245", countryCodeA2: "GW" },
  HT: { name: "Haiti", phonePrefix: "+509", countryCodeA2: "HT" },
  HN: { name: "Honduras", phonePrefix: "+504", countryCodeA2: "HN" },
  HK: { name: "Hong Kong", phonePrefix: "+852", countryCodeA2: "HK" },
  HU: { name: "Hungary", phonePrefix: "+36", countryCodeA2: "HU" },
  IS: { name: "Iceland", phonePrefix: "+354", countryCodeA2: "IS" },
  IN: { name: "India", phonePrefix: "+91", countryCodeA2: "IN" },
  ID: { name: "Indonesia", phonePrefix: "+62", countryCodeA2: "ID" },
  IR: { name: "Iran", phonePrefix: "+98", countryCodeA2: "IR" },
  IQ: { name: "Iraq", phonePrefix: "+964", countryCodeA2: "IQ" },
  IE: { name: "Ireland", phonePrefix: "+353", countryCodeA2: "IE" },
  IM: { name: "Isle of Man", phonePrefix: "+44-1624", countryCodeA2: "IM" },
  IL: {
    name: "Israel",
    phonePrefix: "+972",
    countryCodeA2: "IL",
  },
  IT: {
    name: "Italy",
    phonePrefix: "+39",
    countryCodeA2: "IT",
  },
  JM: {
    name: "Jamaica",
    phonePrefix: "+1-876",
    countryCodeA2: "JM",
  },
  JP: {
    name: "Japan",
    phonePrefix: "+81",
    countryCodeA2: "JP",
  },
  JE: {
    name: "Jersey",
    phonePrefix: "+44-1534",
    countryCodeA2: "JE",
  },
  JO: {
    name: "Jordan",
    phonePrefix: "+962",
    countryCodeA2: "JO",
  },
  KZ: {
    name: "Kazakhstan",
    phonePrefix: "+7",
    countryCodeA2: "KZ",
  },
  KE: {
    name: "Kenya",
    phonePrefix: "+254",
    countryCodeA2: "KE",
  },
  KI: {
    name: "Kiribati",
    phonePrefix: "+686",
    countryCodeA2: "KI",
  },
  XK: {
    name: "Kosovo",
    phonePrefix: "+383",
    countryCodeA2: "XK",
  },
  KW: {
    name: "Kuwait",
    phonePrefix: "+965",
    countryCodeA2: "KW",
  },
  KG: {
    name: "Kyrgyzstan",
    phonePrefix: "+996",
    countryCodeA2: "KG",
  },
  LA: {
    name: "Laos",
    phonePrefix: "+856",
    countryCodeA2: "LA",
  },
  LV: {
    name: "Latvia",
    phonePrefix: "+371",
    countryCodeA2: "LV",
  },
  LB: {
    name: "Lebanon",
    phonePrefix: "+961",
    countryCodeA2: "LB",
  },
  LS: {
    name: "Lesotho",
    phonePrefix: "+266",
    countryCodeA2: "LS",
  },
  LR: {
    name: "Liberia",
    phonePrefix: "+231",
    countryCodeA2: "LR",
  },
  LY: {
    name: "Libya",
    phonePrefix: "+218",
    countryCodeA2: "LY",
  },
  LI: {
    name: "Liechtenstein",
    phonePrefix: "+423",
    countryCodeA2: "LI",
  },
  LT: {
    name: "Lithuania",
    phonePrefix: "+370",
    countryCodeA2: "LT",
  },
  LU: {
    name: "Luxembourg",
    phonePrefix: "+352",
    countryCodeA2: "LU",
  },
  MO: {
    name: "Macau",
    phonePrefix: "+853",
    countryCodeA2: "MO",
  },
  MK: {
    name: "Macedonia",
    phonePrefix: "+389",
    countryCodeA2: "MK",
  },
  MG: {
    name: "Madagascar",
    phonePrefix: "+261",
    countryCodeA2: "MG",
  },
  MW: {
    name: "Malawi",
    phonePrefix: "+265",
    countryCodeA2: "MW",
  },
  MY: {
    name: "Malaysia",
    phonePrefix: "+60",
    countryCodeA2: "MY",
  },
  MV: {
    name: "Maldives",
    phonePrefix: "+960",
    countryCodeA2: "MV",
  },
  ML: {
    name: "Mali",
    phonePrefix: "+223",
    countryCodeA2: "ML",
  },
  MT: {
    name: "Malta",
    phonePrefix: "+356",
    countryCodeA2: "MT",
  },
  MH: {
    name: "Marshall Islands",
    phonePrefix: "+692",
    countryCodeA2: "MH",
  },
  MR: {
    name: "Mauritania",
    phonePrefix: "+222",
    countryCodeA2: "MR",
  },
  MU: {
    name: "Mauritius",
    phonePrefix: "+230",
    countryCodeA2: "MU",
  },
  YT: {
    name: "Mayotte",
    phonePrefix: "+262",
    countryCodeA2: "YT",
  },
  MX: {
    name: "Mexico",
    phonePrefix: "+52",
    countryCodeA2: "MX",
  },
  FM: {
    name: "Micronesia",
    phonePrefix: "+691",
    countryCodeA2: "FM",
  },
  MD: {
    name: "Moldova",
    phonePrefix: "+373",
    countryCodeA2: "MD",
  },
  MC: {
    name: "Monaco",
    phonePrefix: "+377",
    countryCodeA2: "MC",
  },
  MN: {
    name: "Mongolia",
    phonePrefix: "+976",
    countryCodeA2: "MN",
  },
  ME: {
    name: "Montenegro",
    phonePrefix: "+382",
    countryCodeA2: "ME",
  },
  MS: {
    name: "Montserrat",
    phonePrefix: "+1-664",
    countryCodeA2: "MS",
  },
  MA: {
    name: "Morocco",
    phonePrefix: "+212",
    countryCodeA2: "MA",
  },
  MZ: {
    name: "Mozambique",
    phonePrefix: "+258",
    countryCodeA2: "MZ",
  },
  MM: {
    name: "Myanmar",
    phonePrefix: "+95",
    countryCodeA2: "MM",
  },
  NA: {
    name: "Namibia",
    phonePrefix: "+264",
    countryCodeA2: "NA",
  },
  NR: {
    name: "Nauru",
    phonePrefix: "+674",
    countryCodeA2: "NR",
  },
  NP: {
    name: "Nepal",
    phonePrefix: "+977",
    countryCodeA2: "NP",
  },
  NL: {
    name: "Netherlands",
    phonePrefix: "+31",
    countryCodeA2: "NL",
  },
  AN: {
    name: "Netherlands Antilles",
    phonePrefix: "+599",
    countryCodeA2: "AN",
  },
  NC: {
    name: "New Caledonia",
    phonePrefix: "+687",
    countryCodeA2: "NC",
  },
  NZ: {
    name: "New Zealand",
    phonePrefix: "+64",
    countryCodeA2: "NZ",
  },
  NI: {
    name: "Nicaragua",
    phonePrefix: "+505",
    countryCodeA2: "NI",
  },
  NE: {
    name: "Niger",
    phonePrefix: "+227",
    countryCodeA2: "NE",
  },
  NG: {
    name: "Nigeria",
    phonePrefix: "+234",
    countryCodeA2: "NG",
  },
  NU: {
    name: "Niue",
    phonePrefix: "+683",
    countryCodeA2: "NU",
  },
  KP: {
    name: "North Korea",
    phonePrefix: "+850",
    countryCodeA2: "KP",
  },
  MP: {
    name: "Northern Mariana Islands",
    phonePrefix: "+1-670",
    countryCodeA2: "MP",
  },
  NO: {
    name: "Norway",
    phonePrefix: "+47",
    countryCodeA2: "NO",
  },
  OM: {
    name: "Oman",
    phonePrefix: "+968",
    countryCodeA2: "OM",
  },
  PK: {
    name: "Pakistan",
    phonePrefix: "+92",
    countryCodeA2: "PK",
  },
  PW: {
    name: "Palau",
    phonePrefix: "+680",
    countryCodeA2: "PW",
  },
  PR: {
    name: "Puerto Rico",
    phonePrefix: "+1", // -787, -939
    countryCodeA2: "PR",
  },
  PS: {
    name: "Palestine",
    phonePrefix: "+970",
    countryCodeA2: "PS",
  },
  PA: {
    name: "Panama",
    phonePrefix: "+507",
    countryCodeA2: "PA",
  },
  PG: {
    name: "Papua New Guinea",
    phonePrefix: "+675",
    countryCodeA2: "PG",
  },
  PY: {
    name: "Paraguay",
    phonePrefix: "+595",
    countryCodeA2: "PY",
  },
  PE: {
    name: "Peru",
    phonePrefix: "+51",
    countryCodeA2: "PE",
  },
  PH: {
    name: "Philippines",
    phonePrefix: "+63",
    countryCodeA2: "PH",
  },
  PN: {
    name: "Pitcairn",
    phonePrefix: "+64",
    countryCodeA2: "PN",
  },
  PL: {
    name: "Poland",
    phonePrefix: "+48",
    countryCodeA2: "PL",
  },
  PT: {
    name: "Portugal",
    phonePrefix: "+351",
    countryCodeA2: "PT",
  },
  QA: {
    name: "Qatar",
    phonePrefix: "+974",
    countryCodeA2: "QA",
  },
  RE: {
    name: "Reunion",
    phonePrefix: "+262",
    countryCodeA2: "RE",
  },
  RO: {
    name: "Romania",
    phonePrefix: "+40",
    countryCodeA2: "RO",
  },
  RU: {
    name: "Russia",
    phonePrefix: "+7",
    countryCodeA2: "RU",
  },
  RW: {
    name: "Rwanda",
    phonePrefix: "+250",
    countryCodeA2: "RW",
  },
  BL: {
    name: "Saint Barthelemy",
    phonePrefix: "+590",
    countryCodeA2: "BL",
  },
  SH: {
    name: "Saint Helena",
    phonePrefix: "+290",
    countryCodeA2: "SH",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    phonePrefix: "+1-869",
    countryCodeA2: "KN",
  },
  LC: {
    name: "Saint Lucia",
    phonePrefix: "+1-758",
    countryCodeA2: "LC",
  },
  MF: {
    name: "Saint Martin",
    phonePrefix: "+590",
    countryCodeA2: "MF",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    phonePrefix: "+508",
    countryCodeA2: "PM",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    phonePrefix: "+1-784",
    countryCodeA2: "VC",
  },
  WS: {
    name: "Samoa",
    phonePrefix: "+685",
    countryCodeA2: "WS",
  },
  SM: {
    name: "San Marino",
    phonePrefix: "+378",
    countryCodeA2: "SM",
  },
  ST: {
    name: "Sao Tome and Principe",
    phonePrefix: "+239",
    countryCodeA2: "ST",
  },
  SA: {
    name: "Saudi Arabia",
    phonePrefix: "+966",
    countryCodeA2: "SA",
  },
  SN: {
    name: "Senegal",
    phonePrefix: "+221",
    countryCodeA2: "SN",
  },
  RS: {
    name: "Serbia",
    phonePrefix: "+381",
    countryCodeA2: "RS",
  },
  SC: {
    name: "Seychelles",
    phonePrefix: "+248",
    countryCodeA2: "SC",
  },
  SL: {
    name: "Sierra Leone",
    phonePrefix: "+232",
    countryCodeA2: "SL",
  },
  SG: {
    name: "Singapore",
    phonePrefix: "+65",
    countryCodeA2: "SG",
  },
  SX: {
    name: "Sint Maarten",
    phonePrefix: "+1-721",
    countryCodeA2: "SX",
  },
  SK: {
    name: "Slovakia",
    phonePrefix: "+421",
    countryCodeA2: "SK",
  },
  SI: {
    name: "Slovenia",
    phonePrefix: "+386",
    countryCodeA2: "SI",
  },
  SB: {
    name: "Solomon Islands",
    phonePrefix: "+677",
    countryCodeA2: "SB",
  },
  SO: {
    name: "Somalia",
    phonePrefix: "+252",
    countryCodeA2: "SO",
  },
  ZA: {
    name: "South Africa",
    phonePrefix: "+27",
    countryCodeA2: "ZA",
  },
  KR: {
    name: "South Korea",
    phonePrefix: "+82",
    countryCodeA2: "KR",
  },
  SS: {
    name: "South Sudan",
    phonePrefix: "+211",
    countryCodeA2: "SS",
  },
  ES: {
    name: "Spain",
    phonePrefix: "+34",
    countryCodeA2: "ES",
  },
  LK: {
    name: "Sri Lanka",
    phonePrefix: "+94",
    countryCodeA2: "LK",
  },
  SD: {
    name: "Sudan",
    phonePrefix: "+249",
    countryCodeA2: "SD",
  },
  SR: {
    name: "Suriname",
    phonePrefix: "+597",
    countryCodeA2: "SR",
  },
  SJ: {
    name: "Svalbard and Jan Mayen",
    phonePrefix: "+47",
    countryCodeA2: "SJ",
  },
  SZ: {
    name: "Swaziland",
    phonePrefix: "+268",
    countryCodeA2: "SZ",
  },
  SE: {
    name: "Sweden",
    phonePrefix: "+46",
    countryCodeA2: "SE",
  },
  CH: {
    name: "Switzerland",
    phonePrefix: "+41",
    countryCodeA2: "CH",
  },
  SY: {
    name: "Syria",
    phonePrefix: "+963",
    countryCodeA2: "SY",
  },
  TW: {
    name: "Taiwan",
    phonePrefix: "+886",
    countryCodeA2: "TW",
  },
  TJ: {
    name: "Tajikistan",
    phonePrefix: "+992",
    countryCodeA2: "TJ",
  },
  TZ: {
    name: "Tanzania",
    phonePrefix: "+255",
    countryCodeA2: "TZ",
  },
  TH: {
    name: "Thailand",
    phonePrefix: "+66",
    countryCodeA2: "TH",
  },
  TG: {
    name: "Togo",
    phonePrefix: "+228",
    countryCodeA2: "TG",
  },
  TK: {
    name: "Tokelau",
    phonePrefix: "+690",
    countryCodeA2: "TK",
  },
  TO: {
    name: "Tonga",
    phonePrefix: "+676",
    countryCodeA2: "TO",
  },
  TT: {
    name: "Trinidad and Tobago",
    phonePrefix: "+1-868",
    countryCodeA2: "TT",
  },
  TN: {
    name: "Tunisia",
    phonePrefix: "+216",
    countryCodeA2: "TN",
  },
  TR: {
    name: "Turkey",
    phonePrefix: "+90",
    countryCodeA2: "TR",
  },
  TM: {
    name: "Turkmenistan",
    phonePrefix: "+993",
    countryCodeA2: "TM",
  },
  TC: {
    name: "Turks and Caicos Islands",
    phonePrefix: "+1-649",
    countryCodeA2: "TC",
  },
  TV: {
    name: "Tuvalu",
    phonePrefix: "+688",
    countryCodeA2: "TV",
  },
  VI: {
    name: "U.S. Virgin Islands",
    phonePrefix: "+1-340",
    countryCodeA2: "VI",
  },
  UG: {
    name: "Uganda",
    phonePrefix: "+256",
    countryCodeA2: "UG",
  },
  UA: {
    name: "Ukraine",
    phonePrefix: "+380",
    countryCodeA2: "UA",
  },
  AE: {
    name: "United Arab Emirates",
    phonePrefix: "+971",
    countryCodeA2: "AE",
  },
  GB: {
    name: "United Kingdom",
    phonePrefix: "+44",
    countryCodeA2: "GB",
  },
  US: {
    name: "United States",
    phonePrefix: "+1",
    countryCodeA2: "US",
  },
  UY: {
    name: "Uruguay",
    phonePrefix: "+598",
    countryCodeA2: "UY",
  },
  UZ: {
    name: "Uzbekistan",
    phonePrefix: "+998",
    countryCodeA2: "UZ",
  },
  VU: {
    name: "Vanuatu",
    phonePrefix: "+678",
    countryCodeA2: "VU",
  },
  VA: {
    name: "Vatican",
    phonePrefix: "+379",
    countryCodeA2: "VA",
  },
  VE: {
    name: "Venezuela",
    phonePrefix: "+58",
    countryCodeA2: "VE",
  },
  VN: {
    name: "Vietnam",
    phonePrefix: "+84",
    countryCodeA2: "VN",
  },
  WF: {
    name: "Wallis and Futuna",
    phonePrefix: "+681",
    countryCodeA2: "WF",
  },
  EH: {
    name: "Western Sahara",
    phonePrefix: "+212",
    countryCodeA2: "EH",
  },
  YE: {
    name: "Yemen",
    phonePrefix: "+967",
    countryCodeA2: "YE",
  },
  ZM: {
    name: "Zambia",
    phonePrefix: "+260",
    countryCodeA2: "ZM",
  },
  ZW: {
    name: "Zimbabwe",
    phonePrefix: "+263",
    countryCodeA2: "ZW",
  },
};
