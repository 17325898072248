import {
  CompanyLookupCollection,
  DailyDiaryHseRecord,
} from "generated/graphql";
import { TFunction } from "i18next";
import { getUserName } from "helpers/miscelaneous";

export const toRecordJSON = (
  record: DailyDiaryHseRecord,
  hseLookups: CompanyLookupCollection[],
  t: TFunction
) => {
  return {
    [t("common.labels.description")]: record.description,
    [t("Projects.DailyDiaries.HSE.HSEType")]:
      hseLookups
        .find((lkp) => lkp.id === record.hseTypeLookupId)
        ?.options.items.find((option) => option.id === record.hseTypeOptionId)
        ?.value ?? "",
    [t("common.labels.createdBy")]: getUserName(record.creator),
  };
};
