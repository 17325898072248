import { gql } from "@apollo/client";
import { authorizationWorkflowAuditFields } from "graphql/fragments/authorizationWorkflowAudit.fragment";

export const projectAuthorizationAuditsQuery = gql`
  ${authorizationWorkflowAuditFields}

  query projectAuthorizationAudits(
    $projectId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    projectAuthorizationAudits(
      projectId: $projectId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        ...AuthorizationWorkflowAuditFields
      }
    }
  }
`;
