import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventNoticeMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventNotice($input: RecordCompEventNoticeInput!) {
    recordCompEventNotice(input: $input) {
      ...CompEventItemFields
    }
  }
`;
