// TODO: remove this when it's added in the graphql schema
export enum PermissionEnum {
  AddComment = "Add Comment",
  ManageAttachments = "Manage Attachments",
  View = "View",
  Edit = "Edit",
  ChangeStatus = "Change Status",
  ChangeOwner = "Change Owner",
  Add = "Add",
  Draft = "Draft",
}
