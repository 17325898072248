import { gql } from "@apollo/client";

export const detailedClaimLiteFields = gql`
  fragment DetailedClaimLiteFields on DetailedClaim {
    id
    claimId
    reference
    details
    price
    currency
    time
    sectionalChanges {
      number
      description
      days
    }
    dateCreated
    creatorId
  }
`;
