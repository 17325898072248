import { TypographyProps, useTheme } from "@mui/material";
import { OverflowTooltip } from "components/OverflowTooltip";

type StringCellRendererWithTooltipProps = {
  value?: string;
  typographyProps?: TypographyProps;
};

export const StringCellRendererWithTooltip: React.FC<
  StringCellRendererWithTooltipProps
> = ({ value = "", typographyProps }) => {
  const theme = useTheme();

  return (
    <OverflowTooltip
      title={value}
      typographyProps={{
        variant: "body2",
        fontWeight: 400,
        color: theme.palette.grey[600],
        ...typographyProps,
      }}
    />
  );
};
