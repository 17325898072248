import { gql } from "@apollo/client";

const creatorFields = gql`
  fragment LocalCreatorFields on User {
    id
    firstname
    surname
    profilePicture
    company {
      tradingName
    }
  }
`;

const commentReplyFields = gql`
  ${creatorFields}

  fragment CommentReplyFields on CommentReply {
    id
    commentId
    content
    dateCreated
    creatorId
    creator {
      ...LocalCreatorFields
    }
  }
`;

export const commentFields = gql`
  ${commentReplyFields}
  ${creatorFields}

  fragment CommentFields on Comment {
    id
    itemId
    content
    dateCreated
    creatorId
    replies {
      items {
        ...CommentReplyFields
      }
    }
    creator {
      ...LocalCreatorFields
    }
  }
`;

export const riskItemCommentsQuery = gql`
  ${commentFields}

  query riskItemComments($id: ID!, $limit: Int!, $nextToken: String) {
    riskItem(id: $id) {
      id
      title
      comments(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
          ...CommentFields
        }
      }
    }
  }
`;

export const ewItemCommentsQuery = gql`
  ${commentFields}

  query earlyWarningItemComments($id: ID!, $limit: Int!, $nextToken: String) {
    earlyWarningItem(id: $id) {
      id
      title
      comments(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
          ...CommentFields
        }
      }
    }
  }
`;

export const eventItemCommentsQuery = gql`
  ${commentFields}

  query eventItemComments($id: ID!, $limit: Int!, $nextToken: String) {
    eventItem(id: $id) {
      id
      title
      comments(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
          ...CommentFields
        }
      }
    }
  }
`;
export const instructionItemCommentsQuery = gql`
  ${commentFields}

  query instructionItemComments($id: ID!, $limit: Int!, $nextToken: String) {
    instructionItem(id: $id) {
      id
      title
      comments(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
          ...CommentFields
        }
      }
    }
  }
`;

export const dailyDiaryItemCommentsQuery = gql`
  ${commentFields}
  query dailyDiaryItemComments(
    $dailyDiaryId: ID!
    $revisionId: ID
    $limit: Int!
    $nextToken: String
  ) {
    dailyDiaryItemComments(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        ...CommentFields
      }
    }
  }
`;

export const addCommentMutation = gql`
  ${commentFields}

  mutation addComment($input: AddCommentInput!) {
    addComment(input: $input) {
      ...CommentFields
    }
  }
`;

export const replyCommentMutation = gql`
  ${commentReplyFields}

  mutation replyComment($input: ReplyCommentInput!) {
    replyComment(input: $input) {
      ...CommentReplyFields
    }
  }
`;
