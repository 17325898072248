import { useGraphQuery } from "hooks/useGraphQuery";
import { authAuditDetailsQuery } from "./useAuthAudit.query";
import {
  AuthAuditDetailsQuery,
  AuthAuditDetailsQueryVariables,
} from "generated/graphql";
import { unifyAuthAuditObj } from "./useAuthAudit.utils";

export const useAuthAudit = (authAuditId: string, skip?: boolean) => {
  const { data: authAuditData, loading: getAuthAuditLoading } = useGraphQuery<
    AuthAuditDetailsQuery,
    AuthAuditDetailsQueryVariables
  >(authAuditDetailsQuery, {
    variables: { id: authAuditId },
    skip,
  });

  return {
    authAudit: unifyAuthAuditObj(authAuditData),
    loading: getAuthAuditLoading,
  };
};
