import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { ListItem, ProductType } from "generated/graphql";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export type ClaimTypeSelectProps = {
  value: string;
  claimTypes: ListItem[];
  error?: string;
  productType: ProductType.Claims | ProductType.CompEvents;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
};

export const ClaimTypeSelect: React.FC<ClaimTypeSelectProps> = ({
  value,
  claimTypes,
  error,
  productType,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormLabel
        label={
          productType === ProductType.CompEvents
            ? t("Projects.CompEvents.ActionModal.compEventType")
            : t("Projects.Claims.ActionModal.claimType")
        }
        required
      />
      <Select
        labelId="claim-type-label"
        id="claim-type-select-standard"
        data-testid="claim-type-dropdown"
        value={value}
        onChange={onChange}
        placeholder={t("common.labels.suspensionSelect")}
        error={!!error}
        sx={{ maxHeight: "200px" }}
        MenuProps={{
          sx: {
            maxHeight: "400px",
            width: "300px",
          },
        }}
        fullWidth
        size="small"
        required
      >
        {claimTypes.map((claimType) => (
          <MenuItem
            key={claimType.id}
            value={claimType.id}
            data-testid={`claim-type-${claimType.name}-option`}
          >
            <Tooltip title={claimType.name}>
              <Typography
                variant="p2"
                color="grey.800"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {claimType.name}
              </Typography>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
      {!!error && (
        <Typography variant="caption" color="error" mt={0.5}>
          {error}
        </Typography>
      )}
    </>
  );
};
