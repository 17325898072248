import { Box, Typography, useTheme } from "@mui/material";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export type RequestGrantMoreTimeSummaryProps = {
  isRequest: boolean;
  days: number;
  remarks?: string;
};

export const RequestGrantMoreTimeSummary: React.FC<RequestGrantMoreTimeSummaryProps> =
  memo(({ isRequest = true, remarks, days }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="p2" color="grey.800" fontWeight={600}>
          {isRequest
            ? t("Projects.CompEvents.requestForMoreTimeLabel")
            : t("Projects.CompEvents.grantMoreTimeLabel")}
        </Typography>
        <Typography variant="p1" color="grey.600" mt={0.5}>
          {days}
        </Typography>
        <Box mt={2} display="flex" flexDirection="column">
          <Typography variant="p2" color="grey.800" fontWeight={600} mb={0.5}>
            {t("common.labels.remarks")}
          </Typography>
          {remarks ? (
            <RichTextArea
              readOnly
              content={remarks ?? ""}
              color={theme.palette.grey[600]}
            />
          ) : (
            <Typography variant="p1" color="grey.600" mt={0.5}>
              {t("common.labels.n/a")}
            </Typography>
          )}
        </Box>
      </Box>
    );
  });
