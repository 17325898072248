import {
  DraftDetailedClaim,
  GenerateSendClaimFinalClaimDetailsPreviewMutation,
  GenerateSendClaimFinalClaimDetailsPreviewMutationVariables,
  SendClaimFinalClaimDetailsPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { generateSendClaimFinalClaimDetailsPreviewMutation } from "./SubmitFinalDetailedClaimAction.query";

export const useSendFinalDetailedClaimAction = (
  draftDetailedClaim: DraftDetailedClaim
) => {
  const { claim, contract } = useContext(ClaimWidgetContext);
  const [claimPreview, setClaimPreview] =
    useState<SendClaimFinalClaimDetailsPreview>();

  const [generateFinalDetailedClaimPreview] = useGraphMutation<
    GenerateSendClaimFinalClaimDetailsPreviewMutation,
    GenerateSendClaimFinalClaimDetailsPreviewMutationVariables
  >(generateSendClaimFinalClaimDetailsPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateFinalDetailedClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            draftDetailedClaimId: draftDetailedClaim.id,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateSendClaimFinalClaimDetailsPreview);
      }
    }, [generateFinalDetailedClaimPreview, claim, draftDetailedClaim]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
