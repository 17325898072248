import {
  DailyDiaryDelayRecord,
  DailyDiaryDelayRecordsQuery,
  DailyDiaryDelayRecordsQueryVariables,
  DailyDiaryItem,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { dailyDiaryDelayRecordsQuery } from "./DelaySection.query";

export const useDelayRecords = (
  dailyDiary?: DailyDiaryItem, // TODO: pass only dailyDiaryId - no need for the entire object
  revisionId?: string
) => {
  const {
    data: delayRecords,
    loading: delayRecordsLoading,
    refetch: refetchDDDelayRecords,
  } = useGraphQuery<
    DailyDiaryDelayRecordsQuery,
    DailyDiaryDelayRecordsQueryVariables
  >(dailyDiaryDelayRecordsQuery, {
    variables: { dailyDiaryId: dailyDiary?.id!, revisionId },
    skip: !dailyDiary,
    notifyOnNetworkStatusChange: true,
  });

  return {
    delayRecords: (delayRecords?.dailyDiaryDelayRecords ??
      []) as DailyDiaryDelayRecord[],
    delayRecordsLoading,
    refetchDDDelayRecords,
  };
};
