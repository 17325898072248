import {
  VariationHistoryItem,
  VariationHistoryItemsQuery,
  VariationHistoryItemsQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";
import { variationHistoryItemsQuery } from "../VariationWidget.query";

export const useVariationHistory = (variationId: string) => {
  const { data: variationHistoryItems, loading: variationHistoryItemsLoading } =
    useGraphQuery<
      VariationHistoryItemsQuery,
      VariationHistoryItemsQueryVariables
    >(variationHistoryItemsQuery, {
      variables: { variationId },
    });

  const orderedHistoryItems = useMemo(
    () =>
      (variationHistoryItems?.variationHistoryItems.items ?? [])
        .slice()
        .sort(
          (item1, item2) =>
            new Date(item2.dateSent).getTime() -
            new Date(item1.dateSent).getTime()
        ) as VariationHistoryItem[],
    [variationHistoryItems]
  );

  return {
    variationHistoryItems: orderedHistoryItems,
    loading: variationHistoryItemsLoading,
  };
};
