import { customSort } from "containers/Projects/Projects.utils";
import {
  ActiveClaimTypesQuery,
  ActiveClaimTypesQueryVariables,
  ListItem,
} from "generated/graphql";
import { activeClaimTypesQuery } from "graphql/queries/activeClaimTypes.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";

export const useInstructionProvideQuotationExtraDataForm = (
  contractId: string
) => {
  const { data: activeClaimTypes, loading } = useGraphQuery<
    ActiveClaimTypesQuery,
    ActiveClaimTypesQueryVariables
  >(activeClaimTypesQuery, {
    variables: {
      contractId,
    },
  });

  const orderedActiveClaimTypes = useMemo(
    () =>
      activeClaimTypes?.activeClaimTypes
        .slice()
        .sort((claim1, claim2) => claim1.name.localeCompare(claim2.name))
        .sort(customSort), // TODO: remove this logic when claimTypes have a displayOrder attribute
    [activeClaimTypes]
  );

  return {
    activeClaimTypes: (orderedActiveClaimTypes ?? []) as ListItem[],
    loading,
  };
};
