import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const dailyDiaryHSERecordLiteFields = gql`
  ${creatorFields}

  fragment DailyDiaryHSERecordLiteFields on DailyDiaryHSERecord {
    id
    description
    hseTypeOptionId
    hseTypeLookupId
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
    }
  }
`;
