import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  BoxProps,
} from "@mui/material";
import { CaretDown, CaretUp } from "phosphor-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductRoleLite } from "./ProductInstanceRolesMapper";

export type ParentEntityRoleHeaderProps = {
  title: string;
  collapsed: boolean;
  roles: ProductRoleLite[];
  indentationLevel: 1 | 2;
  onToggleCollapse: () => void;
  onSetRole: (productRoleName: string) => void;
} & BoxProps;

export const ParentEntityRoleHeader: React.FC<ParentEntityRoleHeaderProps> = ({
  title,
  collapsed,
  roles,
  indentationLevel = 1,
  onToggleCollapse,
  onSetRole,
  ...restBoxProps
}) => {
  const { t } = useTranslation();

  const [selectedRoleId, setSelectedRoleId] = useState<string>("");

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    setSelectedRoleId(event.target.value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.06);",
      }}
      pl={indentationLevel * 4}
      pr={4}
      py={0.75}
      {...restBoxProps}
    >
      <Box
        onClick={onToggleCollapse}
        sx={{ cursor: "pointer" }}
        display="flex"
        flex={1}
        ml={0}
      >
        {collapsed ? <CaretDown size={20} /> : <CaretUp size={20} />}
        <Typography fontWeight={500} fontSize="15px" ml={2}>
          {title}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="500px"
      >
        <Typography fontWeight={500} fontSize="14px" color="#A7A7A7">
          {t("AdminConsole.Users.InviteUser.setAllProductsTo")}
        </Typography>
        <Select
          id={`${title}-role-select-label`}
          value={selectedRoleId}
          onChange={handleRoleChange}
          sx={{ minWidth: "150px" }}
          size="small"
          displayEmpty
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          onClick={() => {
            onSetRole(roles.find((role) => role.id === selectedRoleId)!.name);
          }}
        >
          <Typography
            variant="body2"
            color="white"
            fontWeight={500}
            textTransform="none"
          >
            {t("common.buttons.set")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
