import { gql } from "@apollo/client";

export const effectPresetsQuery = gql`
  query effectPresets {
    effectPresets {
      items {
        id
        name
        contractTypeId
        isDefault
        isInternal
        status
        creatorId
        creator {
          id
          firstname
          surname
        }
        dateCreated
        options {
          items {
            id
            label
            isInternal
            estimationType
            unit
            effectPresetId
            status
            creatorId
            creator {
              id
              firstname
              surname
            }
            dateCreated
          }
        }
      }
    }
  }
`;

export const changeEffectPresetStatusMutation = gql`
  mutation changeEffectPresetStatus($id: ID!, $status: EffectPresetStatus!) {
    changeEffectPresetStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const addEffectPresetMutation = gql`
  mutation addEffectPreset($input: AddEffectPresetInput!) {
    addEffectPreset(input: $input) {
      id
      name
      status
    }
  }
`;

export const editEffectPresetMutation = gql`
  mutation editEffectPreset($input: EditEffectPresetInput!) {
    editEffectPreset(input: $input) {
      id
      name
      status
    }
  }
`;

export const changeEffectOptionStatusMutation = gql`
  mutation changeEffectOptionStatus($id: ID!, $status: EffectOptionStatus!) {
    changeEffectOptionStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const addEffectOptionMutation = gql`
  mutation addEffectOption($input: AddEffectOptionInput!) {
    addEffectOption(input: $input) {
      id
      label
      status
    }
  }
`;
