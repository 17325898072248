import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { dailyDiaryItemSubmissionFields } from "./dailyDiaryItemSubmission.fragment";
import { productInstanceLiteFields } from "./productInstanceLite.fragment";
import { authorizationWorkflowAuditFields } from "./authorizationWorkflowAudit.fragment";

export const dailyDiaryItemFields = gql`
  ${creatorFields}
  ${productInstanceLiteFields}
  ${dailyDiaryItemSubmissionFields}
  ${authorizationWorkflowAuditFields}

  fragment DailyDiaryItemFields on DailyDiaryItem {
    id
    productInstanceId
    date
    number
    status
    revisions {
      id
      number
      sentById
      dateSent
      status
      authorizationWorkflowAudit {
        ...AuthorizationWorkflowAuditFields
      }
      sentBy {
        ...CreatorFields
      }
    }
    latestRevision {
      id
      number
      sentById
      dateSent
      status
      authorizationWorkflowAudit {
        ...AuthorizationWorkflowAuditFields
      }
      sentBy {
        ...CreatorFields
      }
    }
    dateCreated
    dateModified
    creatorId
    sentById
    dateSent
    submissions {
      ...DailyDiaryItemSubmissionFields
    }
    creator {
      ...CreatorFields
    }
    sentBy {
      ...CreatorFields
    }
    productInstance {
      ...ProductInstanceLiteFields
    }
  }
`;
