import { useTranslation } from "react-i18next";
import { ActionsDialog } from "components/ActionsDialog";
import { Copy } from "phosphor-react";
import { Color, Grid, Stack, useTheme } from "@mui/material";
import { IconContainer } from "components/IconContainer";
import { GridDatePickerItem } from "./ImportRecordsModal.styled";
import { ImportRecordsModalHeader } from "./ImportRecordsModalHeader";
import { ImportRecordsModalProps } from "./ImportRecordsModal.types";
import { CalendarView } from "./CalendarView";
import { ImportRecordsTableHeader } from "./ImportRecordsTableHeader";

export const ImportRecordsModal: React.FC<ImportRecordsModalProps> = ({
  children,
  availableDates,
  calendarLoading,
  targetDiaryDetails,
  onChange,
  onMonthChange,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ActionsDialog
      maxWidth="md"
      fullWidth
      primaryBtnCaption={t("common.buttons.import")}
      iconsHeader={
        <IconContainer greyBackground>
          <Copy size={18} color={(theme.palette.grey as Partial<Color>)[800]} />
        </IconContainer>
      }
      title={<ImportRecordsModalHeader />}
      content={
        <Grid container rowGap={4}>
          <GridDatePickerItem item xs={12} p={2} pt={0}>
            <CalendarView
              onChange={onChange}
              loading={calendarLoading}
              availableDates={availableDates}
              onMonthChange={onMonthChange}
            />
          </GridDatePickerItem>
          <Grid item xs={12}>
            <Stack gap={3}>
              <ImportRecordsTableHeader
                targetDiaryDetails={targetDiaryDetails}
              />
              {children}
            </Stack>
          </Grid>
        </Grid>
      }
      {...restDialogProps}
    />
  );
};
