import {
  Company,
  ContractBindingType,
  ContractTypeQuery,
  ContractTypeQueryVariables,
  GetCompanyUsersQuery,
  GetCompanyUsersQueryVariables,
  User,
} from "generated/graphql";
import { getCompanyUsersQuery } from "graphql/queries/companyUsers.query";
import { contractTypeQuery } from "graphql/queries/contractType.query";
import { useCompanies } from "hooks/useCompanies";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export type useContractBindingFormResult = {
  loading: boolean;
  usersLoading: boolean;
  companies?: Company[];
  bindingTypes?: ContractBindingType[];
  companyUsers?: User[];
  setSelectedCompanyId: Dispatch<SetStateAction<string | undefined>>;
};

export const useContractBindingForm = (
  contractTypeId?: string
): useContractBindingFormResult => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();

  const { companies, loading: companiesLoading } = useCompanies();

  const [
    getCompanyUsers,
    { data: companyUsers, loading: getCompanyUsersLoading },
  ] = useGraphLazyQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(
    getCompanyUsersQuery
  );

  const [
    getContractType,
    { data: contractTypeData, loading: getContractTypeData },
  ] = useGraphLazyQuery<ContractTypeQuery, ContractTypeQueryVariables>(
    contractTypeQuery
  );

  useEffect(() => {
    if (selectedCompanyId) {
      getCompanyUsers({ variables: { id: selectedCompanyId } });
    }
  }, [selectedCompanyId, getCompanyUsers]);

  useEffect(() => {
    if (contractTypeId) {
      getContractType({ variables: { id: contractTypeId } });
    }
  }, [contractTypeId, getContractType]);

  return {
    loading: companiesLoading || getContractTypeData,
    usersLoading: getCompanyUsersLoading,
    companies,
    companyUsers: companyUsers?.company?.users.items as User[],
    bindingTypes: contractTypeData?.contractType.contractBindingTypes
      .items as ContractBindingType[],
    setSelectedCompanyId,
  };
};
