import { Box, Stack, Typography } from "@mui/material";
import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { PrimarySecondaryTertiaryButtons } from "components/PrimarySecondaryTertiaryButtons";
import { ShieldCheck } from "phosphor-react";
import { useTranslation } from "react-i18next";

export type ConfirmAuthorizationModalProps = {
  onCancel: () => void;
  onBack: () => void;
  onConfirm: () => void;
  primaryBtnLoading?: boolean;
} & Omit<BasicModalProps, "onClose">;

export const ConfirmAuthorizationModal: React.FC<
  ConfirmAuthorizationModalProps
> = ({ open, primaryBtnLoading, onCancel, onBack, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      open={open}
      paperProps={{ className: "confirm-authorization-modal" }}
      title={
        <Stack spacing={1}>
          <Typography
            variant="h3"
            fontWeight={600}
            fontSize="20px"
            color="grey.800"
          >
            {t("Home.Authorizations.confirmAuthorizationTitle")}
          </Typography>
          <Typography variant="p1" color="grey.700">
            {t("Home.Authorizations.confirmAuthorizationText")}
          </Typography>
        </Stack>
      }
      maxWidth="md"
      headerIcon={
        <IconContainer>
          <ShieldCheck size={24} />
        </IconContainer>
      }
      onClose={onBack}
    >
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <PrimarySecondaryTertiaryButtons
          primaryBtnLoading={primaryBtnLoading}
          secondaryBtnCaption={t("common.buttons.back")}
          primaryBtnCaption={t("common.buttons.confirm")}
          onTertiaryClick={onCancel}
          onSecondaryClick={onBack}
          onPrimaryClick={onConfirm}
        />
      </Box>
    </BasicModal>
  );
};
