import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { ClaimActionType, DetailedClaim } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ResponseRegardingDetailedClaimSummary } from "./ResponseRegardingDetailedClaimSummary";
import { useRecordResponseRegardingDetailedClaimModal } from "./useRecordResponseRegardingDetailedClaimModal";

export type RecordResponseRegardingDetailedClaimModalProps = {
  details: string;
  detailedClaim: DetailedClaim;
  attachments?: EnhancedAttachment[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordResponseRegardingDetailedClaimModal: React.FC<
  RecordResponseRegardingDetailedClaimModalProps
> = ({ details, attachments = [], detailedClaim, ...restDialogProps }) => {
  const { claimPreview, contract } =
    useRecordResponseRegardingDetailedClaimModal(detailedClaim.id, details);
  return (
    <RecordAction
      actionType={ClaimActionType.RespondRegardingDetailedClaim}
      claimPreview={claimPreview}
      summaryEl={
        <ResponseRegardingDetailedClaimSummary
          details={details}
          detailedClaim={detailedClaim}
          attachments={attachments}
          contract={contract}
        />
      }
      {...restDialogProps}
    />
  );
};
