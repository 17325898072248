import { gql } from "@apollo/client";

export const productsWithSchemasQuery = gql`
  query productsWithSchemas {
    products {
      items {
        numberingFormat
        soloModeSupported
        allowMultipleInstances
        id
        name
        status
        productSchemas {
          items {
            id
            name
            isDefault
            productId
            isInternal
            status
            creatorId
            dateCreated
            schemaSections {
              items {
                id
                name
                displayText
                productSchemaId
                isInternal
                status
                schemaFields {
                  items {
                    id
                    name
                    schemaSectionId
                    fieldTypeId
                    displayText
                    isRequired
                    isInternal
                    complexTypeItemId
                    status
                    fieldType {
                      id
                      description
                      shortCode
                      status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
