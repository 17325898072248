import { Box, Typography } from "@mui/material";
import { CompEventOwnAssessmentPrompt } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { AssessmentsTable } from "./AssessmentsTable/AssessmentsTable";

export type SubmitAssessmentSummaryProps = { contractCurrency: string } & Pick<
  CompEventOwnAssessmentPrompt,
  "draftAssessments"
>;

export const SubmitAssessmentSummary: React.FC<
  SubmitAssessmentSummaryProps
> = ({ contractCurrency, draftAssessments }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="h4"
        fontSize="18px"
        color="grey.800"
        fontWeight={600}
        mb={2.5}
      >
        {t("Projects.CompEvents.assessments")}
      </Typography>
      <AssessmentsTable
        assessments={draftAssessments}
        contractCurrency={contractCurrency}
      />
    </Box>
  );
};
