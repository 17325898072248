import { debounce } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateSendClaimAgreementNoticePreviewMutation,
  GenerateSendClaimAgreementNoticePreviewMutationVariables,
  SendClaimAgreementNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { eventDebounceDuration } from "constants/constants";
import { generateSendClaimAgreementNoticePreviewMutation } from "./NotifyAgreementClaimAction.query";

export const useSendAgreementClaimNoticeModal = (
  draftClaimAgreementId: string
) => {
  const [claimPreview, setClaimPreview] =
    useState<SendClaimAgreementNoticePreview>();

  const { claim, contract } = useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimAgreementNoticePreviewMutation,
    GenerateSendClaimAgreementNoticePreviewMutationVariables
  >(generateSendClaimAgreementNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            draftClaimAgreementId,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateSendClaimAgreementNoticePreview);
      }
    }, [generateClaimPreview, claim, draftClaimAgreementId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
