import { Box, Typography, useTheme } from "@mui/material";
import {
  defaultSeverityIndex,
  SeverityColors,
  SeverityColorsMapping,
} from "./Severity.constants";
import { computeSeverityRating } from "./Severity.utils";
import { SeverityPickerItemIcon } from "./SeverityPickerItemIcon";
import { SeverityReadOnlyProps } from "./Severity.decl";

export const SeverityPreviewSmall: React.FC<SeverityReadOnlyProps> = ({
  severityPreset,
  consequenceValue,
  likelihoodValue,
}) => {
  const theme = useTheme();
  const severityRating = computeSeverityRating(
    severityPreset,
    consequenceValue,
    likelihoodValue
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={2}
      py={1.5}
      sx={{
        background:
          SeverityColorsMapping[severityRating.colour as SeverityColors]
            .backgroundColor,
        borderRadius: "12px",
        // maxWidth: '170px',
        marginLeft: "auto",
      }}
    >
      <Typography variant="p2" color={theme.palette.grey[900]}>
        Severity: {severityRating.name}
      </Typography>
      <SeverityPickerItemIcon
        itemSet={severityPreset.likelihoodSets}
        value={likelihoodValue ?? defaultSeverityIndex}
        height={16}
        width={16}
        step={2.5}
        highContrast
        filledColor={{
          highContrastColor:
            SeverityColorsMapping[severityRating.colour as SeverityColors]
              .filledColor,
          lowContrastColor: "",
        }}
        unfilledColor={{
          highContrastColor:
            SeverityColorsMapping[severityRating.colour as SeverityColors]
              .unfilledColor,
          lowContrastColor: "",
        }}
      />
    </Box>
  );
};
