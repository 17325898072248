import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { ThreeDotsMenu } from "./ThreeDotsMenu/ThreeDotsMenu";
import { noop } from "helpers/miscelaneous";

export const useKebabButtonColumn = <T extends { id: string }>(
  onEdit?: (rowId: GridRowId) => void,
  onRemove?: (rowId: GridRowId) => void
): GridColDef<T> => {
  return {
    field: "dotsMenu",
    headerName: "",
    width: 35,
    sortable: false,
    disableColumnMenu: true,
    resizable: false,
    renderCell: (params: GridRenderCellParams<T, any, any>) => {
      return (
        <ThreeDotsMenu
          onEdit={() => onEdit?.(params.id)}
          onRemove={() => onRemove?.(params.id)}
          onSendNotes={noop}
        />
      );
    },
  };
};
