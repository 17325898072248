import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  useBasicTypeaheadTriggerMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { $createTextNode, TextNode } from "lexical";
import { useCallback, useMemo, useState } from "react";
import { $createMentionNode } from "../../nodes/MentionNode";
import { SUGGESTION_LIST_LENGTH_LIMIT } from "./constants";
import { getPossibleQueryMatch } from "./Mentions.utils";
import {
  MentionsTypeaheadMenuItem,
  MentionTypeaheadOption,
} from "./MentionsMenuItem";
import {
  useMentionLookupServiceLite,
  UserMentionOption,
} from "./useMentionLookupService";
import "./mentions.css";
import ReactDOM from "react-dom";

export default function MentionsPlugin({
  mentionsList,
}: {
  mentionsList: UserMentionOption[];
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const results = useMentionLookupServiceLite(mentionsList, queryString);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0,
  });

  const options = useMemo(
    () =>
      results
        .map((result) => new MentionTypeaheadOption(result.name, result.userId))
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
    [results]
  );

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.name,
          selectedOption.userId
        );
        const parentNode = nodeToReplace?.getParent();

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }

        const textNode = $createTextNode(" ");
        parentNode?.append(textNode);
        textNode.select();
        closeMenu();
      });
    },
    [editor]
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const mentionMatch = getPossibleQueryMatch(text);
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      return !slashMatch && mentionMatch ? mentionMatch : null;
    },
    [checkForSlashTriggerMatch, editor]
  );

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) => {
        if (anchorElementRef.current) {
          if (results.length) {
            return ReactDOM.createPortal(
              <div className="typeahead-popover mentions-menu">
                <ul>
                  {options.map((option, i: number) => (
                    <MentionsTypeaheadMenuItem
                      index={i}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i);
                      }}
                      key={option.userId}
                      option={option}
                    />
                  ))}
                </ul>
              </div>,
              anchorElementRef.current
            );
          }
        }
        return null;
      }}
    />
  );
}
