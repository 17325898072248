import { BasicModal } from "components/BasicModal/BasicModal";
import {
  VariationItem,
  VariationAction as VariationActionType,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { VariationActionView } from "./VariationActionView";

export type VariationActionModalProps = {
  variationActions: VariationActionType[];
  variation?: VariationItem;
  open: boolean;
  onClose: () => void;
};

export const VariationActionModal: React.FC<VariationActionModalProps> = ({
  variation,
  variationActions,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const ref = useRef<{ onBeforeClose: () => void } | null>(null);

  const handleClose = () => {
    ref.current?.onBeforeClose();
    onClose();
  };

  return (
    <BasicModal
      open={open}
      title={t("Projects.Variations.ActionModal.title")}
      maxWidth="md"
      titleNextToIcon
      onClose={handleClose}
    >
      <VariationActionView
        variation={variation}
        apiRef={ref}
        variationActions={variationActions}
        onCancel={onClose}
      />
    </BasicModal>
  );
};
