export type ErrorsType = { Errors: string[]; Success: boolean };

/**
 * Local enum of productOutputActions. They're not coming from BE directly as they are stored
 * in the DB and managed dynamically, but created it for easier management.
 */
export enum LocalProductOutputAction {
  ContractorSendsEW = "Contractor Gives Early Warning",
  PMSendsEW = "Project Manager Gives Early Warning",
  SendInstructionProvideQuotation = "Give Instruction (Provide quotation)",
  SendInstructionPutIntoEffect = "Give Instruction (Put into effect)",
  ApproveDiary = "Approve Diary",
  // new ones
  PMSendsInstructionPutIntoEffectConsistsVO = "Give Instruction (Put into effect)(Variation)",
}
