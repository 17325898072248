import {
  TimePeriod,
  TimePeriodsQuery,
  TimePeriodsQueryVariables,
} from "generated/graphql";
import { timePeriodsQuery } from "graphql/queries/timePeriods.query";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useContractTypeTimePeriods = (contractTypeId?: string) => {
  const { data: timePeriods, loading } = useGraphQuery<
    TimePeriodsQuery,
    TimePeriodsQueryVariables
  >(timePeriodsQuery, {
    skip: !contractTypeId,
    variables: { contractTypeId: contractTypeId! },
  });

  return {
    timePeriods: (timePeriods?.timePeriods.items ?? []) as TimePeriod[],
    loading,
  };
};
