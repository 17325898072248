import { gql } from "@apollo/client";
import { attachmentLiteFields } from "./attachmentLite.fragment";

export const draftVariationDeterminationFields = gql`
  ${attachmentLiteFields}

  fragment DraftVariationDeterminationFields on DraftVariationDetermination {
    id
    variationId
    reference
    details
    price
    time
    sectionalChanges {
      id
      days
    }
    dateCreated
    dateModified
    creatorId
    attachments {
      ...AttachmentLiteFields
    }
  }
`;
