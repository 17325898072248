import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import {
  ChangeItemStatusCollectionStatusMutation,
  ChangeItemStatusCollectionStatusMutationVariables,
  ItemStatusCollectionQuery,
  ItemStatusCollectionQueryVariables,
  ItemStatusCollectionStatus,
  ItemStatusOption,
  ProductLiteQuery,
  ProductLiteQueryVariables,
  User,
} from "generated/graphql";
import { changeItemStatusCollectionStatusMutation } from "graphql/mutations/changeStatusCollectionStatus";
import { productLiteQuery } from "graphql/queries/productLite.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { StatusCollectionOptions } from "./components/StatusCollectionOptions/StatusCollectionOptions";
import { useStatusCollectionOptions } from "./components/StatusCollectionOptions/useStatusCollectionOptions";
import { itemStatusCollectionQuery } from "./StatusCollectionDetails.query";
import { getUserName } from "helpers/miscelaneous";

export const StatusCollectionDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { productId, collectionId } = useParams();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ItemStatusCollectionStatus>[];

  const {
    data: collectionData,
    refetch: refetchCollectionData,
    loading: getCollectionDataLoading,
  } = useGraphQuery<
    ItemStatusCollectionQuery,
    ItemStatusCollectionQueryVariables
  >(itemStatusCollectionQuery, {
    variables: { id: collectionId! },
  });

  const [
    changeProductStatusCollectionStatus,
    { loading: changeProductStatusCollectionStatusLoading },
  ] = useGraphMutation<
    ChangeItemStatusCollectionStatusMutation,
    ChangeItemStatusCollectionStatusMutationVariables
  >(
    changeItemStatusCollectionStatusMutation,
    {
      update: () => {
        refetchCollectionData();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Products.labels.statusCollection"),
    })
  );

  const { data: productDataLite, loading: getProductDataLiteLoading } =
    useGraphQuery<ProductLiteQuery, ProductLiteQueryVariables>(
      productLiteQuery,
      {
        variables: { id: productId! },
      }
    );

  const onStatusCollectionOptionUpdated = () => refetchCollectionData();

  const {
    addStatusCollectionOption,
    addStatusCollectionOptionLoading,
    editStatusCollectionOption,
    editStatusCollectionOptionLoading,
    changeStatusCollectionOptionStatus,
    changeStatusCollectionOptionStatusLoading,
  } = useStatusCollectionOptions({
    onStatusCollectionOptionAdded: onStatusCollectionOptionUpdated,
    onStatusCollectionOptionStatusChange: onStatusCollectionOptionUpdated,
    onStatusCollectionOptionUpdated,
  });

  const handleBreadcrumbClick = (parent?: "product") => {
    if (parent === "product") {
      navigate(-1);
    } else {
      navigate(-2);
    }
  };

  const creatorStr = useMemo(() => {
    if (
      collectionData?.itemStatusCollection.creator.firstname &&
      collectionData?.itemStatusCollection.creator.surname
    ) {
      return getUserName(
        collectionData?.itemStatusCollection.creator as User | undefined
      );
    }

    return "";
  }, [collectionData]);

  const handleCollectionStatusChange = useCallback(
    async (newStatus: ItemStatusCollectionStatus) => {
      await changeProductStatusCollectionStatus({
        variables: {
          id: collectionId!,
          status: newStatus,
        },
      });
    },
    [changeProductStatusCollectionStatus, collectionId]
  );

  const loading =
    getCollectionDataLoading ||
    getProductDataLiteLoading ||
    changeProductStatusCollectionStatusLoading;

  return (
    <Box>
      <EntityDetailsHeader
        loading={loading}
        title={collectionData?.itemStatusCollection.name || ""}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={() => handleBreadcrumbClick()}>
              <Typography variant="body2">
                {t("AdminConsole.Products.labels.products")}
              </Typography>
            </Link>
            <Link key="2" onClick={() => handleBreadcrumbClick("product")}>
              <Typography variant="body2">
                {productDataLite?.product.name || ""}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        status={collectionData?.itemStatusCollection.status}
        statusOptions={statusOptions}
        creator={creatorStr}
        dateCreated={collectionData?.itemStatusCollection.dateCreated}
        onStatusChange={handleCollectionStatusChange}
      />
      <StatusCollectionOptions
        statusOptions={
          (collectionData?.itemStatusCollection.statusOptions
            .items as ItemStatusOption[]) || []
        }
        statusCollectionName={collectionData?.itemStatusCollection.name}
        statusCollectionId={collectionData?.itemStatusCollection.id}
        loading={
          loading ||
          addStatusCollectionOptionLoading ||
          editStatusCollectionOptionLoading ||
          changeStatusCollectionOptionStatusLoading
        }
        onCollectionOptionStatusChange={changeStatusCollectionOptionStatus}
        onCollectionOptionAdd={addStatusCollectionOption}
        onCollectionOptionUpdate={editStatusCollectionOption}
      />
    </Box>
  );
};
