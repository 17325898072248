import { css, InputBase, styled } from "@mui/material";

export const SearchBox = styled(InputBase)(
  ({ theme }) => css`
    background-color: ${theme.palette.grey[100]};
    border-radius: 8px;
    width: 100%;
    height: 36px;
    border: none;
    padding-left: ${theme.spacing(2)};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.palette.grey[800]};
  `
);
