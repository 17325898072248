import { gql } from "@apollo/client";
import { attachmentLiteFields } from "graphql/fragments/attachmentLite.fragment";
import { variationProposalLiteFields } from "graphql/fragments/variationProposalLite.fragment";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationProposalConsentForProposedVariationPromptQuery = gql`
  ${variationProposalLiteFields}
  ${attachmentLiteFields}

  query variationProposalConsentForProposedVariationPrompt(
    $input: VariationProposalConsentForProposedVariationPromptInput!
  ) {
    variationProposalConsentForProposedVariationPrompt(input: $input) {
      proposals {
        ...VariationProposalLiteFields
        attachments {
          ...AttachmentLiteFields
        }
      }
    }
  }
`;

export const sendVariationProposalConsentForProposedVariationMutation = gql`
  mutation sendVariationProposalConsentForProposedVariation(
    $input: SendVariationProposalConsentForProposedVariationInput!
  ) {
    sendVariationProposalConsentForProposedVariation(input: $input) {
      id
    }
  }
`;

export const recordVariationProposalConsentForProposedVariationMutation = gql`
  mutation recordVariationProposalConsentForProposedVariation(
    $input: RecordVariationProposalConsentForProposedVariationInput!
  ) {
    recordVariationProposalConsentForProposedVariation(input: $input) {
      id
    }
  }
`;

export const generateSendVariationProposalConsentForProposedVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationProposalConsentForProposedVariationPreview(
    $input: SendVariationProposalConsentForProposedVariationPreviewInput!
  ) {
    generateSendVariationProposalConsentForProposedVariationPreview(
      input: $input
    ) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationProposalConsentForProposedVariationPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationProposalConsentForProposedVariationPreview(
    $input: RecordVariationProposalConsentForProposedVariationPreviewInput!
  ) {
    generateRecordVariationProposalConsentForProposedVariationPreview(
      input: $input
    ) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
