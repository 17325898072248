import { Timeline, TimelineContent, TimelineSeparator } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { Check } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { RegistrationStep } from "../../Register";
import {
  StepperContainer,
  StyledTimelineConnector,
  StyledTimelineDot,
  StyledTimelineItem,
} from "./RegistrationStepper.styled";

export type RegistrationStepperProps = {
  step: RegistrationStep;
};

export const RegistrationStepper: React.FC<RegistrationStepperProps> = ({
  step,
}) => {
  const { t } = useTranslation();

  return (
    <StepperContainer>
      <Typography variant="h2" color="grey.800">
        {t("common.labels.getStartedCCOM")}
      </Typography>
      <Timeline position="right" sx={{ padding: 0, margin: 0 }}>
        <StyledTimelineItem>
          <TimelineSeparator>
            <StyledTimelineConnector visible={false} />
            <StyledTimelineDot
              state={step === RegistrationStep.Account ? "active" : "completed"}
            >
              {step === RegistrationStep.Account ? (
                1
              ) : (
                <Check size={20} weight="bold" />
              )}
            </StyledTimelineDot>
            <StyledTimelineConnector visible />
          </TimelineSeparator>
          <TimelineContent>
            <Box width="300px" height="100%" display="flex" alignItems="center">
              <Typography variant="h4" color="grey.800" lineHeight="24px">
                {t("Register.accountTitle")}
              </Typography>
            </Box>
          </TimelineContent>
        </StyledTimelineItem>
        <StyledTimelineItem>
          <TimelineSeparator>
            <StyledTimelineConnector visible />
            <StyledTimelineDot
              state={step === RegistrationStep.Account ? "rest" : "active"}
              color={
                step === RegistrationStep.PersonalInfo ? "success" : "inherit"
              }
            >
              2
            </StyledTimelineDot>
            <StyledTimelineConnector visible={false} />
          </TimelineSeparator>
          <TimelineContent>
            <Box height="100%" display="flex" alignItems="center">
              <Typography
                variant="h4"
                color="grey.800"
                lineHeight="24px"
                overflow="hidden"
                sx={{ wordBreak: "break-word" }}
              >
                {t("Register.personalInformationTitle")}
              </Typography>
            </Box>
          </TimelineContent>
        </StyledTimelineItem>
      </Timeline>
    </StepperContainer>
  );
};
