import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export type CancelUpdateButtonsProps = {
  size?: "small" | "medium" | "large" | undefined;
  loading?: boolean;
  updateBtnCaption?: string;
  updateBtnRef?: React.Ref<HTMLButtonElement>;
  onCancel?: () => void;
  onUpdate?: () => void;
};

export const CancelUpdateButtons: React.FC<CancelUpdateButtonsProps> = ({
  size = "small",
  loading,
  updateBtnCaption,
  updateBtnRef,
  onCancel,
  onUpdate,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        disableRipple
        style={{ textTransform: "none" }}
        size={size}
        sx={{ height: size === "large" ? 40 : undefined }}
        onClick={onCancel}
        data-testid="cancel-btn"
      >
        <Typography variant="body2" fontWeight={500}>
          {t("common.buttons.cancel")}
        </Typography>
      </Button>
      <Box ml={2}>
        <LoadingButton
          ref={updateBtnRef}
          color="primary"
          onClick={onUpdate}
          loading={loading}
          variant="contained"
          data-testid="add-update-btn"
          fullWidth
          style={{ textTransform: "none" }}
          size={size}
          sx={{ height: size === "large" ? 40 : undefined }}
        >
          <Typography variant="body2" fontWeight={500} color="white">
            {updateBtnCaption ?? t("common.buttons.update")}
          </Typography>
        </LoadingButton>
      </Box>
    </>
  );
};
