import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";
import { productOutputActionFields } from "graphql/fragments/productOutputAction.fragment";
import { contractBindingTypeFields } from "graphql/fragments/contractBindingType.fragment";

export const workflowActionsPromptQuery = gql`
  ${authorizationWorkflowFields}
  ${productOutputActionFields}
  ${contractBindingTypeFields}

  query workflowActionsPrompt($input: WorkflowActionsPromptInput!) {
    workflowActionsPrompt(input: $input) {
      workflows {
        items {
          ...AuthorizationWorkflowFields
        }
      }
      outputActions {
        items {
          productOutputAction {
            ...ProductOutputActionFields
          }
          originatingParty {
            ...ContractBindingTypeFields
          }
        }
      }
    }
  }
`;
