import {
  DailyDiaryPresetSchema,
  DailyDiaryPresetSchemaQuery,
  DailyDiaryPresetSchemaQueryVariables,
} from "generated/graphql";
import { dailyDiaryPresetSchemaQuery } from "graphql/queries/dailyDiaryPresetSchema.query";
import { useGraphQuery } from "./useGraphQuery";

export const useDailyDiaryPresetSchema = () => {
  const { data, loading } = useGraphQuery<
    DailyDiaryPresetSchemaQuery,
    DailyDiaryPresetSchemaQueryVariables
  >(dailyDiaryPresetSchemaQuery);

  return {
    dailyDiaryPresetSchema: data?.dailyDiaryPresetSchema as
      | DailyDiaryPresetSchema
      | undefined,
    loading,
  };
};
