import {
  AddDailyDiaryManpowerRecordMutation,
  AddDailyDiaryManpowerRecordMutationVariables,
  DailyDiaryItem,
  EditDailyDiaryManpowerRecordMutation,
  EditDailyDiaryManpowerRecordMutationVariables,
  RemoveDailyDiaryManpowerRecordMutation,
  RemoveDailyDiaryManpowerRecordMutationVariables,
} from "generated/graphql";
import { addDailyDiaryManpowerRecordMutation } from "graphql/mutations/addDailyDiaryManpowerRecord";
import { editDailyDiaryManpowerRecordMutation } from "graphql/mutations/editDailyDiaryManpowerRecord";
import { removeDailyDiaryManpowerRecordMutation } from "graphql/mutations/removeDailyDiaryManpowerRecord";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { useManpowerRecords } from "./useManpowerRecords";

export const useManpowerSection = (
  dailyDiary?: DailyDiaryItem,
  revisionId?: string
) => {
  const { t } = useTranslation();
  const { refetchDDByDate } = useContext(DailyDiaryContext);

  const { manpowerRecords, manpowerRecordsLoading, refetchDDManpowerRecords } =
    useManpowerRecords(dailyDiary, revisionId);

  const [addDDManpowerRecord, { loading: addDDManpowerRecordLoading }] =
    useGraphMutation<
      AddDailyDiaryManpowerRecordMutation,
      AddDailyDiaryManpowerRecordMutationVariables
    >(
      addDailyDiaryManpowerRecordMutation,
      {
        update: async () => {
          if (!dailyDiary) {
            await refetchDDByDate?.();
          } else {
            refetchDDManpowerRecords();
          }
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.DailyDiaries.manpowerRecord"),
      })
    );

  const [editDDManpowerRecord, { loading: editDDManpowerRecordLoading }] =
    useGraphMutation<
      EditDailyDiaryManpowerRecordMutation,
      EditDailyDiaryManpowerRecordMutationVariables
    >(
      editDailyDiaryManpowerRecordMutation,
      {
        update: () => {
          refetchDDManpowerRecords();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.DailyDiaries.manpowerRecord"),
      })
    );

  const [removeDDManpowerRecord, { loading: removeDDManpowerRecordLoading }] =
    useGraphMutation<
      RemoveDailyDiaryManpowerRecordMutation,
      RemoveDailyDiaryManpowerRecordMutationVariables
    >(
      removeDailyDiaryManpowerRecordMutation,
      {
        update: () => {
          refetchDDManpowerRecords();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.DailyDiaries.manpowerRecord"),
      })
    );

  return {
    manpowerRecords,
    loading:
      manpowerRecordsLoading ||
      addDDManpowerRecordLoading ||
      editDDManpowerRecordLoading ||
      removeDDManpowerRecordLoading,
    addManpowerRecord: addDDManpowerRecord,
    editManpowerRecord: editDDManpowerRecord,
    removeManpowerRecord: removeDDManpowerRecord,
    refetchDDManpowerRecords,
  };
};
