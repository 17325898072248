import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { authorizationWorkflowActionMappingFields } from "./authorizationWorkflowActionMapping.fragment";
import { compEventItemFields } from "./compEventItem.fragment";
import { instructionItemFields } from "./instructionItem.fragment";
import { earlyWarningItemFields } from "./earlyWarningItem.fragment";
import { authorizationWorkflowAuditTrailFields } from "./authorizationWorkflowAuditTrail.fragment";
import { productLiteFields } from "./productLite.fragment";
import { authorizationWorkflowFields } from "./authorizationWorkflow.fragment";

const authLocalDailyDiaryItemFields = gql`
  ${productLiteFields}

  fragment AuthLocalDailyDiaryItemFields on DailyDiaryItem {
    __typename
    id
    date
    number
    dateCreated
    dateModified
    creatorId
    sentById
    productInstance {
      id
      description
      numberingFormat
      soloModeSupported
      status
      contract {
        id
        name
        friendlyName
        timeZone
        number
        status
        dateCreated
        project {
          id
          name
          friendlyName
          status
        }
      }
      product {
        ...ProductLiteFields
      }
    }
  }
`;

// TODO: limit the usage of this fragment as it's too general
export const authorizationWorkflowAuditFields = gql`
  ${creatorFields}
  ${authorizationWorkflowActionMappingFields}
  ${compEventItemFields}
  ${earlyWarningItemFields}
  ${authorizationWorkflowAuditTrailFields}
  ${instructionItemFields}
  ${authLocalDailyDiaryItemFields}
  ${authorizationWorkflowFields}

  fragment AuthorizationWorkflowAuditFields on AuthorizationWorkflowAudit {
    __typename
    id
    initiatedById
    initiatedBy {
      ...CreatorFields
    }
    regardingProductItemId
    regardingProductType
    regardingItemHistoryId
    workflowId
    workflow {
      ...AuthorizationWorkflowFields
    }
    actionId
    action {
      ...AuthorizationWorkflowActionMappingFields
    }
    status
    dateCreated
    dateInitiated
    regardingProductItem {
      # TODO: fetch only necessary data for the table. RegardingProductItem can be fetched only when opening the modal
      ... on CompEventItem {
        ...CompEventItemFields
      }

      # fetching only EW and Instruction here, because they are not created yet and
      # cannot be lazy loaded through "query instructionItem(id: ID)". On the other hand, CompEvents are created and should
      # be lazy loaded
      ... on EarlyWarningItem {
        ...EarlyWarningItemFields
      }
      ... on InstructionItem {
        ...InstructionItemFields
      }
      ... on DailyDiaryItem {
        ...AuthLocalDailyDiaryItemFields
      }
      ... on ClaimItem {
        __typename
        id
        number
        regardingId
        claimRegardingType: regardingType
        productInstance {
          id
          description
          numberingFormat
          contract {
            id
            timeZone # needed to display authorization time (in levels) in contract's timezone
          }
        }
      }
      ... on VariationItem {
        __typename
        id
        number
        regardingId
        productInstance {
          id
          description
          numberingFormat
          contract {
            id
            timeZone
          }
        }
      }
    }
    auditTrails {
      ...AuthorizationWorkflowAuditTrailFields
    }
  }
`;
