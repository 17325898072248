import { useMemo } from "react";
import { useVariationProposalExplorerItems } from "../../hooks/useVariationProposalExplorerItems";

export const useFinalVariationAgreement = () => {
  const { variationAgreements, loading } = useVariationProposalExplorerItems();

  const finalAgreement = useMemo(() => {
    return variationAgreements.sort((agreement1, agreement2) => {
      const date1 = new Date(agreement1.dateCreated as string).getTime();
      const date2 = new Date(agreement2.dateCreated as string).getTime();

      return date2 - date1;
    })[0];
  }, [variationAgreements]);

  return {
    finalAgreement,
    loading,
  };
};
