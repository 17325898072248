import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ImportRecordsModalHeader: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack direction="column">
      <Typography
        variant="h3"
        fontWeight={600}
        color={theme.palette.grey[800]}
        mb={1}
      >
        {t("Projects.DailyDiaries.ImportRecordsModal.title")}
      </Typography>
      <Typography variant="p1" color={theme.palette.grey[700]}>
        {t("Projects.DailyDiaries.ImportRecordsModal.subtitle")}
      </Typography>
    </Stack>
  );
};
