import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { IconContainer } from "components/IconContainer";
import {
  AddDraftVariationAgreementInput,
  AddDraftVariationDeterminationInput,
  AddDraftVariationProposalInput,
  ContractSection,
  ContractSectionStatus,
  DraftVariationAgreement,
  DraftVariationDetermination,
  DraftVariationProposal,
  EditDraftVariationAgreementInput,
  EditDraftVariationDeterminationInput,
  EditDraftVariationProposalInput,
  ProductType,
  VariationProposal,
} from "generated/graphql";
import { Info, Money } from "phosphor-react";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormPublicApi } from "types/decl";
import { ContractPriceTimeChangeReadOnlyView } from "../../HistoryModal/ContractPriceTimeChangeReadOnlyView";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { ListItemsDivider } from "components/ListItemsDivider";
import { VariationWidgetContext } from "../VariationWidget/VariationWidget.context";
import { VariationProposalAgreementDeterminationForm } from "./VariationProposalAgreementDeterminationForm/VariationProposalAgreementDeterminationForm";

export enum ModalType {
  DraftVOProposal = "DraftVOProposal",
  DraftVOAgreement = "DraftVOAgreement",
  DraftVODetermination = "DraftVODetermination",
}

export type VOFormPublicAPI = FormPublicApi & {
  onBeforeAbort: () => void;
};

enum InternalModalType {
  CreateDraftProposal = "CreateDraftProposal",
  EditDraftProposal = "EditDraftProposal",
  CreateDraftAgreement = "CreateDraftAgreement",
  EditDraftAgreement = "EditDraftAgreement",
  CreateDraftDetermination = "CreateDraftDetermination",
  EditDraftDetermination = "EditDraftDetermination",
}

export type VariationProposalAgreementDeterminationFormDataType =
  | (AddDraftVariationProposalInput | EditDraftVariationProposalInput)
  | (AddDraftVariationAgreementInput | EditDraftVariationAgreementInput)
  | (
      | AddDraftVariationDeterminationInput
      | EditDraftVariationDeterminationInput
    );

export type VariationProposalAgreementDeterminationModalProps = {
  type: ModalType;
  contractCurrency: string;
  contractSections: ContractSection[];
  contractTimezone: string;
  draftProposal?: DraftVariationProposal;
  draftAgreement?: DraftVariationAgreement;
  draftDetermination?: DraftVariationDetermination;
  consentedProposal?: VariationProposal;
  finalProposal?: VariationProposal;
  onPrimaryClick: (
    draftVOData: VariationProposalAgreementDeterminationFormDataType
  ) => void;
  onAttachmentsChange: (
    draftVOData: VariationProposalAgreementDeterminationFormDataType
  ) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const VariationProposalAgreementDeterminationModal: React.FC<
  VariationProposalAgreementDeterminationModalProps
> = ({
  draftAgreement,
  draftProposal,
  draftDetermination,
  type,
  contractCurrency,
  contractTimezone,
  contractSections,
  consentedProposal,
  finalProposal,
  maxWidth = "md",
  onPrimaryClick,
  onSecondaryClick,
  onAttachmentsChange,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { contract, isFIDIC17White } = useContext(VariationWidgetContext);
  const formRef = useRef<VOFormPublicAPI>(null);
  const formDataRef =
    useRef<VariationProposalAgreementDeterminationFormDataType>();
  const [primaryBtnPressed, setPrimaryBtnPressed] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>();

  const isEditMode = useMemo(
    () => draftAgreement || draftProposal || draftDetermination,
    [draftAgreement, draftProposal, draftDetermination]
  );

  const baseProposal = consentedProposal ?? finalProposal;

  const activeSections = useMemo(
    () =>
      contractSections.filter(
        (section) => section.status === ContractSectionStatus.Active
      ),
    [contractSections]
  );

  const internalModalType = useMemo(() => {
    if (type === ModalType.DraftVOProposal) {
      return draftProposal
        ? InternalModalType.EditDraftProposal
        : InternalModalType.CreateDraftProposal;
    } else if (type === ModalType.DraftVOAgreement) {
      return draftAgreement
        ? InternalModalType.EditDraftAgreement
        : InternalModalType.CreateDraftAgreement;
    }
    return draftDetermination
      ? InternalModalType.EditDraftDetermination
      : InternalModalType.CreateDraftDetermination;
  }, [type, draftProposal, draftAgreement, draftDetermination]);

  const headerText = useMemo(() => {
    switch (internalModalType) {
      case InternalModalType.CreateDraftProposal:
        return {
          title: t("Projects.Variations.VariationProposalModal.createTitle", {
            entity: t("Projects.Variations.variationProposal"),
          }),
          subtitle: t(
            "Projects.Variations.VariationProposalModal.createSubtitle",
            {
              entity: t("Projects.Variations.variationProposal"),
            }
          ),
        };
      case InternalModalType.EditDraftProposal:
        return {
          title: t("Projects.Variations.VariationProposalModal.editTitle", {
            entity: t("Projects.Variations.variationProposal"),
          }),
          subtitle: t(
            "Projects.Variations.VariationProposalModal.editSubtitle",
            {
              entity: t("Projects.Variations.variationProposal"),
            }
          ),
        };
      case InternalModalType.CreateDraftAgreement:
        return {
          title: t("Projects.Variations.VariationProposalModal.createTitle", {
            entity: t("Projects.Variations.variationAgreement"),
          }),
          subtitle: t(
            "Projects.Variations.VariationProposalModal.createSubtitle",
            {
              entity: t("Projects.Variations.variationAgreement"),
            }
          ),
        };
      case InternalModalType.EditDraftAgreement:
        return {
          title: t("Projects.Variations.VariationProposalModal.editTitle", {
            entity: t("Projects.Variations.variationAgreement"),
          }),
          subtitle: t(
            "Projects.Variations.VariationProposalModal.editSubtitle",
            {
              entity: t("Projects.Variations.variationAgreement"),
            }
          ),
        };
      case InternalModalType.CreateDraftDetermination:
        return {
          title: t("Projects.Variations.VariationProposalModal.createTitle", {
            entity: t("Projects.Variations.variationDetermination"),
          }),
          subtitle: t(
            "Projects.Variations.VariationProposalModal.createSubtitle",
            {
              entity: t("Projects.Variations.variationDetermination"),
            }
          ),
        };
      case InternalModalType.EditDraftDetermination:
        return {
          title: t("Projects.Variations.VariationProposalModal.editTitle", {
            entity: t("Projects.Variations.variationDetermination"),
          }),
          subtitle: t(
            "Projects.Variations.VariationProposalModal.editSubtitle",
            {
              entity: t("Projects.Variations.variationDetermination"),
            }
          ),
        };
    }
  }, [internalModalType, t]);

  const primaryBtnCaption = isEditMode
    ? t("common.buttons.save")
    : `${t("common.buttons.create")} ${t(
        `Projects.Variations.${
          type === ModalType.DraftVOProposal
            ? "variationProposal"
            : type === ModalType.DraftVOAgreement
            ? "variationAgreement"
            : "variationDetermination"
        }`
      )}`;

  const handleSubmit = useCallback(() => {
    const isFormValid = formRef.current?.validate();

    if (isFormValid) {
      onPrimaryClick(formDataRef.current!);
      setPrimaryBtnPressed(true);
    }

    setIsFormValid(!!isFormValid);
  }, [onPrimaryClick]);

  const handleSecondaryClick = () => {
    formRef.current?.onBeforeAbort();
    onSecondaryClick();
  };

  const handleClose = () => {
    formRef.current?.onBeforeAbort();
    onSecondaryClick();
  };

  const handleQuotationAssessmentChange = useCallback(
    (formData: VariationProposalAgreementDeterminationFormDataType) => {
      formDataRef.current = formData;
      setIsFormValid(true);
    },
    []
  );

  const initialAgreementDeterminationVO = useMemo(() => {
    if (type !== ModalType.DraftVOProposal && baseProposal) {
      return {
        attachments: [],
        variationId: baseProposal.variationId,
        details: "",
        price: baseProposal.price,
        sectionalChanges: baseProposal.sectionalChanges.map((timeChange) => {
          const crtSection = contractSections.find(
            (section) => section.number === timeChange.number
          );

          return {
            id: crtSection?.id,
            days: timeChange.days,
          };
        }),
        time: baseProposal.time,
      } as any as DraftVariationAgreement | DraftVariationDetermination;
    }
  }, [type, baseProposal, contractSections]);

  const proposalTitleTooltip = t(
    `Projects.Variations.${
      consentedProposal
        ? "consentedProposalSubmitted"
        : "latestProposalSubmitted"
    }`,
    {
      contractor: t(
        `Projects.Variations.${
          isFIDIC17White ? "contractorFIDIC17White" : "contractor"
        }`
      ),
      engineer: t(
        `Projects.Variations.${
          isFIDIC17White ? "engineerFIDIC17White" : "engineer"
        }`
      ),
    }
  );

  return (
    <ActionsDialog
      iconsHeader={
        <IconContainer greyBackground>
          <Money size={24} />
        </IconContainer>
      }
      onPrimaryClick={handleSubmit}
      onSecondaryClick={handleSecondaryClick}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      contentSx={{ minWidth: "420px" }}
      primaryBtnCaption={primaryBtnCaption}
      primaryBtnDisabled={!isFormValid || attachmentsLoading}
      primaryBtnDisabledTooltipText={
        !isFormValid
          ? t("common.labels.invalidForm")
          : attachmentsLoading
          ? t("Attachments.loadingTooltip")
          : undefined
      }
      content={
        <Stack direction="row">
          <Stack direction="column" flex={1}>
            <Box display="flex" flexDirection="column" pb={4}>
              <Typography
                variant="h3"
                fontWeight={600}
                color={theme.palette.grey[800]}
                mb={1}
              >
                {headerText.title}
              </Typography>
              <Typography variant="p1" color={theme.palette.grey[700]}>
                {headerText.subtitle}
              </Typography>
            </Box>
            <VariationProposalAgreementDeterminationForm
              apiRef={formRef}
              sections={activeSections}
              contractCurrency={contractCurrency}
              contractTimezone={contractTimezone}
              draftProposal={draftProposal}
              isFIDIC17White={isFIDIC17White}
              draftAgreement={
                internalModalType === InternalModalType.CreateDraftAgreement
                  ? (initialAgreementDeterminationVO as DraftVariationAgreement)
                  : internalModalType === InternalModalType.EditDraftAgreement
                  ? draftAgreement
                  : undefined
              }
              draftDetermination={
                internalModalType === InternalModalType.CreateDraftDetermination
                  ? (initialAgreementDeterminationVO as DraftVariationDetermination)
                  : internalModalType ===
                    InternalModalType.EditDraftDetermination
                  ? draftDetermination
                  : undefined
              }
              type={type}
              onAttachmentsLoadingChange={setAttachmentsLoading}
              onChange={handleQuotationAssessmentChange}
              onAttachmentsChange={onAttachmentsChange}
            />
          </Stack>
          {type !== ModalType.DraftVOProposal && baseProposal && (
            <Stack direction="row" flex={0.7}>
              <ListItemsDivider orientation="vertical" sx={{ ml: 4 }} />
              <Stack
                direction="column"
                sx={{
                  pl: 4,
                }}
                flex={1}
              >
                <Box display="flex" flexDirection="column" pb={4}>
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="h3"
                      fontWeight={600}
                      color={theme.palette.grey[800]}
                      pr={0.5}
                    >
                      {baseProposal.reference}
                    </Typography>
                    <Tooltip
                      title={proposalTitleTooltip}
                      arrow
                      placement="bottom"
                    >
                      <Info size={20} color={theme.palette.grey[800]} />
                    </Tooltip>
                  </Stack>
                </Box>
                <ContractPriceTimeChangeReadOnlyView
                  detailsCustomRender={
                    <Stack direction="column">
                      <Typography
                        variant="p2"
                        fontWeight={600}
                        color="grey.800"
                      >
                        {t(
                          "Projects.Variations.VariationProposalModal.detailsOfProposal"
                        )}
                      </Typography>
                      <RichTextArea
                        content={baseProposal.details}
                        readOnly
                        color={theme.palette.grey[600]}
                        fontSize="16px"
                      />
                    </Stack>
                  }
                  time={baseProposal.time}
                  isFIDIC17White={isFIDIC17White}
                  price={baseProposal.price}
                  contract={contract}
                  dateSent={baseProposal.dateCreated}
                  sectionalChanges={baseProposal.sectionalChanges}
                  attachments={baseProposal.attachments}
                  productType={ProductType.Variations}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      }
      primaryBtnLoading={primaryBtnPressed}
      {...restDialogProps}
    />
  );
};
