import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventDenyingOfMoreTimeMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventDenyingOfMoreTime(
    $input: SendCompEventDenyingOfMoreTimeInput!
  ) {
    sendCompEventDenyingOfMoreTime(input: $input) {
      ...CompEventItemFields
    }
  }
`;
