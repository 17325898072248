import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { ClaimActionType, ListItem } from "generated/graphql";
import { NotifyCEClaimSummary } from "containers/Projects/components/ActionModal/NotifyCEClaimSummary";
import { RecordAction } from "../components/RecordAction";
import { useRecordNotifyClaimAction } from "./useRecordNotifyClaimAction";

export type RecordClaimNoticeProps = {
  claimType: ListItem;
  awGiven?: ListItem;
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordClaimNotice: React.FC<RecordClaimNoticeProps> = ({
  claimType,
  awGiven,
  ...restDialogProps
}) => {
  const { claimPreview } = useRecordNotifyClaimAction();
  return (
    <RecordAction
      actionType={ClaimActionType.NotifyClaim}
      claimPreview={claimPreview}
      summaryEl={
        <NotifyCEClaimSummary
          claimType={claimType.name}
          ewTitle={awGiven?.name}
          contextType="claim"
        />
      }
      {...restDialogProps}
    />
  );
};
