import { Divider, DividerProps, useTheme } from "@mui/material";

export const ListItemsDivider: React.FC<DividerProps> = ({
  sx,
  ...restProps
}) => {
  const theme = useTheme();

  return (
    <Divider
      sx={{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderColor: theme.palette.grey[200],
        ...sx,
      }}
      {...restProps}
    />
  );
};
