import { Box, css, styled } from "@mui/material";
import { FileMIMEType } from "../../Attachments.decl";
import {
  FileIconSize,
  FileIconVariant,
  FileTypeIcon,
  FileTypeIconProps,
} from "../FileTypeIcon";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";

export type ThumbnailProps = {
  mimeType: FileMIMEType;
  imageUrl?: string;
  imageObjectFit?: "fill" | "contain" | "none" | "cover" | "scale-down";
  imgBorderRadius?: "straight" | "rounded";
} & Pick<FileTypeIconProps, "size">;

const ImgContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "withBorderRadius",
})<{ withBorderRadius?: boolean }>(
  ({ withBorderRadius }) => css`
    ${withBorderRadius &&
    css`
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    `}
    height: 100%;
    width: 100%;
    overflow: hidden;
  `
);

export const Thumbnail: React.FC<ThumbnailProps> = ({
  imageUrl,
  mimeType,
  imageObjectFit,
  imgBorderRadius = "rounded",
  size,
}) => {
  return mimeType === FileMIMEType.Img ? (
    imageUrl ? (
      <ImgContainer withBorderRadius={imgBorderRadius === "rounded"}>
        <img
          src={imageUrl}
          alt="thumbnail of the actual attachment"
          height="100%"
          width="100%"
          style={{ objectFit: imageObjectFit ?? "cover" }}
        />
      </ImgContainer>
    ) : (
      <CenteredLoadingIndicator />
    )
  ) : (
    <FileTypeIcon
      type={mimeType}
      size={size ?? FileIconSize.FullWidth}
      variant={FileIconVariant.Grey}
      flex={1}
      ml="auto"
      mr="auto"
    />
  );
};
