import {
  SeverityPreset,
  SeverityPresetStatus,
  SeverityRating,
  SeverityRatingStatus,
  User,
} from "generated/graphql";
import { unofficialThemeColors } from "theme/extendedTheme";

export enum SeverityColors {
  Green = "Green",
  Yellow = "Yellow",
  Orange = "Orange",
  Red = "Red",
  Grey = "Grey",
}

export const SeverityColorsMapping: Record<
  SeverityColors,
  { filledColor: string; unfilledColor: string; backgroundColor: string }
> = {
  [SeverityColors.Green]: {
    filledColor: "#5BB96B",
    unfilledColor: "#C4E6C9",
    backgroundColor: "#DEF1E1",
  },
  [SeverityColors.Yellow]: {
    filledColor: unofficialThemeColors.amber,
    unfilledColor: "#FAE5AE",
    backgroundColor: "#FCF7E8",
  },
  [SeverityColors.Orange]: {
    filledColor: "#E87E30",
    unfilledColor: "#F2D5C0",
    backgroundColor: "#FBF1E9",
  },
  [SeverityColors.Red]: {
    filledColor: "#CF3030",
    unfilledColor: "#CF3030",
    backgroundColor: "#FAEAEA",
  },
  [SeverityColors.Grey]: {
    filledColor: "#62707F",
    unfilledColor: "#D7DBDF",
    backgroundColor: "#F2F4F6",
  },
};

export const unknownSeverityRating: SeverityRating = {
  colour: SeverityColors.Grey,
  creator: {} as User,
  creatorId: "",
  dateCreated: new Date(),
  id: "15",
  isInternal: true,
  label: "Unknown",
  lowerRange: 1,
  name: "Unknown",
  severityPresetId: "1",
  status: SeverityRatingStatus.Active,
  upperRange: 25,
};

export const defaultSeverityPreset: SeverityPreset = {
  consequenceLabel: "Consequence",
  consequenceSets: [
    "Insignificant",
    "Minor",
    "Moderate",
    "Major",
    "Catastrophic",
  ],
  creator: {} as User,
  creatorId: "",
  dateCreated: new Date(),
  id: "1",
  isDefault: true,
  isInternal: true,
  likelihoodLabel: "Likelihood",
  likelihoodSets: ["Rare", "Unlikely", "Possible", "Likely", "Almost certain"],
  name: "Default severity preset",
  ratings: {
    items: [
      {
        colour: SeverityColors.Green,
        creator: {} as User,
        creatorId: "",
        dateCreated: new Date(),
        id: "11",
        isInternal: true,
        label: "Low",
        lowerRange: 1,
        name: "Low",
        severityPresetId: "1",
        status: SeverityRatingStatus.Active,
        upperRange: 3,
      },
      {
        colour: SeverityColors.Yellow,
        creator: {} as User,
        creatorId: "",
        dateCreated: new Date(),
        id: "12",
        isInternal: true,
        label: "Moderate",
        lowerRange: 4,
        name: "Moderate",
        severityPresetId: "1",
        status: SeverityRatingStatus.Active,
        upperRange: 6,
      },
      {
        colour: SeverityColors.Orange,
        creator: {} as User,
        creatorId: "",
        dateCreated: new Date(),
        id: "13",
        isInternal: true,
        label: "High",
        lowerRange: 7,
        name: "High",
        severityPresetId: "1",
        status: SeverityRatingStatus.Active,
        upperRange: 12,
      },
      {
        colour: SeverityColors.Red,
        creator: {} as User,
        creatorId: "",
        dateCreated: new Date(),
        id: "14",
        isInternal: true,
        label: "Extreme",
        lowerRange: 13,
        name: "Extreme",
        severityPresetId: "1",
        status: SeverityRatingStatus.Active,
        upperRange: 25,
      },
    ],
  },
  status: SeverityPresetStatus.Active,
};

// Will be used when creating a new severity field and no consequence and no likelihood are selected
export const defaultSeverityIndex = 2;
