import { gql } from "@apollo/client";

export const itemStatusOptionQuery = gql`
  query itemStatusOption($id: ID!) {
    itemStatusOption(id: $id) {
      id
      displayOrder
      description
      equatesToRemoved
      style
      statusCollectionId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
      optionReasons {
        items {
          id
          displayOrder
          description
          statusOptionId
          status
          creatorId
          creator {
            id
            firstname
            surname
          }
          dateCreated
        }
      }
    }
  }
`;
