import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { ShieldCheck } from "phosphor-react";
import { AuthorizeDenyModalFooter } from "./components/AuthorizeDenyModalFooter";
import { AuthorizeDenyModalTitle } from "./components/AuthorizeDenyModalTitle";
import { AuthorizeDenyActionView } from "containers/Home/components/Authorizations/AuthorizeDenyActionView/AuthorizeDenyActionView";

export type AuthorizeDenyActionModalProps = {
  authAuditId: string;
  onAuthorize: () => void;
  onDeny: () => void;
  onClose: () => void;
} & Omit<BasicModalProps, "onClose">;

export const AuthorizeDenyActionModal: React.FC<
  AuthorizeDenyActionModalProps
> = ({ authAuditId, open, onClose, onDeny, onAuthorize }) => {
  return (
    <BasicModal
      open={open}
      title={<AuthorizeDenyModalTitle />}
      maxWidth="md"
      headerIcon={
        <IconContainer>
          <ShieldCheck size={24} />
        </IconContainer>
      }
      onClose={onClose}
    >
      <AuthorizeDenyActionView authAuditId={authAuditId} onClose={onClose} />
      <AuthorizeDenyModalFooter
        onClose={onClose}
        onDeny={onDeny}
        onAuthorize={onAuthorize}
        mt={4}
      />
    </BasicModal>
  );
};
