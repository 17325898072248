import { MenuItem, Select, SelectProps } from "@mui/material";

export type DataGridSelectOption = {
  value: string;
  label: React.ReactNode;
};

export type CustomDataGridSingleSelectProps = Omit<SelectProps, "options"> & {
  options: DataGridSelectOption[];
};

export const CustomDataGridSingleSelect: React.FC<
  CustomDataGridSingleSelectProps
> = ({ options, ...selectProps }) => {
  return (
    <Select
      labelId="custom-data-grid-single-select-label"
      id="custom-data-grid-single-seleect"
      {...selectProps}
      sx={{
        ...selectProps.sx,
        width: "100%",
      }}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
