import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { productLiteFields } from "./productLite.fragment";

export const productOutputActionFields = gql`
  ${creatorFields}
  ${productLiteFields}

  fragment ProductOutputActionFields on ProductOutputAction {
    id
    name
    productId
    isInternal
    status
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
    product {
      ...ProductLiteFields
    }
  }
`;
