import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { css, styled } from "@mui/system";
import { dateTimeISOFormat } from "constants/constants";
import {
  ClaimHistoryItem,
  CompEventHistoryItem,
  Contract,
  VariationHistoryItem,
} from "generated/graphql";
import moment from "moment";
import {
  CheckCircle,
  ClockCounterClockwise,
  FilePlus,
  HeartStraightBreak,
  Plugs,
  XCircle,
} from "phosphor-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DaysLate } from "../DaysLate";

export enum HistoryItemStatus {
  Success = "Success",
  Failure = "Failure",
  RequestMoreTime = "RequestMoreTime",
  NoAgreement = "NoAgreement",
  MoreDetails = "MoreDetails",
}

type HistoryListItemProps = {
  historyItem: CompEventHistoryItem | ClaimHistoryItem | VariationHistoryItem;
  position: "start" | "middle" | "end" | "start-end";
  selected?: boolean;
  contract: Contract;
  status: HistoryItemStatus;
  title: string;
  onClick: () => void;
};

const ListItemWrapper = styled(Box)<{ selected?: boolean }>(
  ({ theme, selected = false }) => css`
    display: flex;
    border-radius: 12px;
    padding: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.grey[200]};
    background: ${selected ? theme.palette.grey[100] : "white"};
    box-sizing: border-box;
  `
);

const StyledTimelineItem = styled(TimelineItem)`
  cursor: pointer;

  &:before {
    display: none;
  }
`;

export const HistoryListItem: React.FC<HistoryListItemProps> = ({
  historyItem,
  selected,
  position,
  contract,
  status,
  title,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const ItemIcon = useMemo(() => {
    switch (status) {
      case HistoryItemStatus.RequestMoreTime:
        return <ClockCounterClockwise color="white" />;
      case HistoryItemStatus.NoAgreement:
        return <HeartStraightBreak color="white" />;
      case HistoryItemStatus.Failure:
        return <XCircle color="white" />;
      case HistoryItemStatus.MoreDetails:
        return <FilePlus color="white" />;
      case HistoryItemStatus.Success:
      default:
        return <CheckCircle color="white" />;
    }
  }, [status]);

  const borderColor = useMemo(() => {
    switch (status) {
      case HistoryItemStatus.RequestMoreTime:
      case HistoryItemStatus.NoAgreement:
      case HistoryItemStatus.MoreDetails:
        return theme.palette.warning.main;
      case HistoryItemStatus.Failure:
        return theme.palette.error.main;
      case HistoryItemStatus.Success:
      default:
        return theme.palette.success.main;
    }
  }, [status, theme]);

  return (
    <StyledTimelineItem
      onClick={onClick}
      data-testid={`history-list-item-${historyItem.id}`}
    >
      <TimelineSeparator>
        <TimelineConnector
          sx={{
            bgcolor:
              position !== "start" && position !== "start-end"
                ? theme.palette.grey[300]
                : "white",
          }}
        />
        <TimelineDot
          color={
            status === HistoryItemStatus.Success
              ? "success"
              : status === HistoryItemStatus.RequestMoreTime ||
                status === HistoryItemStatus.NoAgreement ||
                status === HistoryItemStatus.MoreDetails
              ? "warning"
              : "error"
          }
        >
          {ItemIcon}
        </TimelineDot>
        <TimelineConnector
          sx={{
            bgcolor:
              position !== "end" && position !== "start-end"
                ? theme.palette.grey[300]
                : "white",
          }}
        />
      </TimelineSeparator>
      <TimelineContent>
        <ListItemWrapper width="320px" selected={selected}>
          <Box sx={{ borderLeft: "2px solid", borderColor }}></Box>
          <Box display="flex" flexDirection="column" ml={2}>
            <Typography variant="p1" color="grey.800">
              {title}
            </Typography>
            <Typography variant="p2" color="grey.600" mt={1.5}>
              {moment(historyItem.dateSent)
                .tz(contract.timeZone)
                .format(dateTimeISOFormat)}
            </Typography>
            {(historyItem.offline || historyItem.daysLate > 0) && (
              <Box display="flex" alignItems="center" mt={1.5}>
                {historyItem.offline && (
                  <>
                    <Plugs />
                    <Typography
                      variant="p3"
                      color="grey.800"
                      fontWeight={700}
                      pl={0.5}
                    >
                      {t("common.labels.offline")}
                    </Typography>
                  </>
                )}
                {historyItem.daysLate > 0 ? (
                  <DaysLate
                    daysLate={historyItem.daysLate}
                    ml={historyItem.offline ? 2 : 0}
                  />
                ) : null}
              </Box>
            )}
          </Box>
        </ListItemWrapper>
      </TimelineContent>
    </StyledTimelineItem>
  );
};
