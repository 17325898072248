import { Box, Color, Typography, styled, useTheme } from "@mui/material";
import { AuthorizationWorkflowAuditStatus } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { StatusStyles, unofficialThemeColors } from "theme/extendedTheme";

export type LevelBadgeProps = {
  status: AuthorizationWorkflowAuditStatus;
  orderNumber: number;
};

const LevelBadgeContainer = styled(Box)<{
  status: AuthorizationWorkflowAuditStatus;
}>(({ status, theme }) => {
  const statusBgColorMapping: Record<AuthorizationWorkflowAuditStatus, string> =
    {
      [AuthorizationWorkflowAuditStatus.InProgress]:
        unofficialThemeColors.amber,
      [AuthorizationWorkflowAuditStatus.Authorized]: (
        theme.palette.primary as Partial<Color>
      )[500]!,
      [AuthorizationWorkflowAuditStatus.Denied]: unofficialThemeColors.red,
      [AuthorizationWorkflowAuditStatus.Removed]: StatusStyles.Grey.bgColor,
    };

  return `
    background: ${statusBgColorMapping[status]};
    border-radius: 8px;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
    color: ${theme.palette.common.white};
    display: flex;
    align-items: center;
    width: fit-content;
  `;
});

export const LevelBadge: React.FC<LevelBadgeProps> = ({
  status,
  orderNumber,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <LevelBadgeContainer
      status={status}
      data-testid={`auth-level-${orderNumber}-status-badge`}
    >
      <Typography
        variant="caption3"
        textTransform="uppercase"
        lineHeight={theme.typography.p3.lineHeight}
      >
        {t("Home.Authorizations.levelNumber", { number: orderNumber })}
      </Typography>
    </LevelBadgeContainer>
  );
};
