import { Box, Typography } from "@mui/material";
import { dateISOFormat, dateTimeISOFormat } from "constants/constants";
import { DaysLate } from "containers/Projects/components/DaysLate";
import { MetadataBox } from "containers/Projects/components/SchemaInterpretor/MetadataFooter/Metadata.styled";
import { MetadataHeading } from "components/SchemaInterpretor/MetadataHeading";
import { MetadataText } from "components/SchemaInterpretor/MetadataText";
import {
  ClaimHistoryItem,
  CompEventHistoryItem,
  ProductType,
  VariationHistoryItem,
} from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import moment from "moment";
import { Plugs } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { MetadataDateSentHeading } from "components/MetadataDateSentHeading";

export type HistoryItemMetaProps = {
  historyItem: CompEventHistoryItem | ClaimHistoryItem | VariationHistoryItem;
  contractTimezone: string;
  productType:
    | ProductType.CompEvents
    | ProductType.Claims
    | ProductType.Variations;
};

export const HistoryItemMeta: React.FC<HistoryItemMetaProps> = ({
  historyItem,
  contractTimezone,
  productType,
}) => {
  const { t } = useTranslation();

  const dateCreatedText = moment(historyItem.dateCreated)
    .tz(contractTimezone)
    .format(dateTimeISOFormat);
  const dateSentText = moment(historyItem.dateSent)
    .tz(contractTimezone)
    .format(dateTimeISOFormat);

  return (
    <Box display="flex" alignItems="flex-start">
      <MetadataBox>
        <>
          {historyItem.offline ? (
            <MetadataDateSentHeading
              tooltipText={
                productType === ProductType.CompEvents
                  ? t("Projects.CompEvents.compEventActionRecordedOn", {
                      dateText: dateCreatedText,
                    })
                  : productType === ProductType.Claims
                  ? t("Projects.Claims.claimActionRecordedOn", {
                      dateText: dateCreatedText,
                    })
                  : t("Projects.Variations.variationActionRecordedOn", {
                      dateText: dateCreatedText,
                    })
              }
            />
          ) : (
            <MetadataHeading>{t("common.labels.dateSent")}</MetadataHeading>
          )}

          <MetadataText>{dateSentText}</MetadataText>
        </>
        {(historyItem.offline || historyItem.daysLate > 0) && (
          <Box display="flex" alignItems="center" mt={1.5}>
            {historyItem.offline && (
              <>
                <Plugs />
                <Typography
                  variant="p3"
                  color="grey.800"
                  fontWeight={700}
                  pl={0.5}
                >
                  {t("common.labels.offline")}
                </Typography>
              </>
            )}
            {historyItem.daysLate > 0 && (
              <DaysLate
                daysLate={historyItem.daysLate}
                ml={historyItem.offline ? 1.5 : 0}
              />
            )}
          </Box>
        )}
      </MetadataBox>
      <MetadataBox>
        <MetadataHeading>{t("common.labels.dueDate")}</MetadataHeading>
        <MetadataText>
          {historyItem.dueDate
            ? moment(historyItem.dueDate).format(dateISOFormat)
            : t("common.labels.n/a")}
        </MetadataText>
      </MetadataBox>
      <MetadataBox>
        <MetadataHeading>{t("common.labels.sentBy")}</MetadataHeading>
        <MetadataText>{getUserName(historyItem.sentByUser)}</MetadataText>
      </MetadataBox>
      {historyItem.offline ? (
        <MetadataBox>
          <MetadataHeading>{t("common.labels.givenBy")}</MetadataHeading>
          <MetadataText>{historyItem.sentByParty?.description}</MetadataText>
        </MetadataBox>
      ) : null}
    </Box>
  );
};
