import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { CompEventConfirmationPrompt } from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import {
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { RecordCEConfirmation } from "./RecordCEConfirmation";
import { SendCEConfirmation } from "./SendCEConfirmation";
import { useConfirmCEAction } from "./useConfirmCEAction";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";

const dataValidators: DataValidators = {
  ewCouldveBeenGiven: {
    validators: [ValidatorType.Required],
  },
};

const defaultFormData: CompEventConfirmationPrompt = {
  assumptions: "",
  ewCouldveBeenGiven: false,
};

export const ConfirmCEAction: React.FC<
  CEActionFormProps & { triggersAuthWorkflow: boolean }
> = ({ apiRef, triggersAuthWorkflow, onClose }) => {
  const { t } = useTranslation();
  const { compEvent } = useContext(CompEventWidgetContext);

  const [formData, setFormData] =
    useState<CompEventConfirmationPrompt>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendCompEventConfirmation,
    recordCompEventConfirmation,
    actionLoading,
  } = useConfirmCEAction(triggersAuthWorkflow);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleAssumptionsChange = (updatedAssuptions: string) => {
    setFormData((curData) => ({
      ...curData,
      assumptions: updatedAssuptions,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { assumptions: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleEWCouldveBeenGivenChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormData((curData) => ({
      ...curData,
      ewCouldveBeenGiven: checked,
    }));
  };

  const handleSendCEConfirmation = async () => {
    await sendCompEventConfirmation({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          assumptions: formData.assumptions,
          ewCouldveBeenGiven: formData.ewCouldveBeenGiven,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEConfirmation = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCompEventConfirmation({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          assumptions: formData.assumptions,
          ewCouldveBeenGiven: formData.ewCouldveBeenGiven,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const validateForm = useCallback((formData: CompEventConfirmationPrompt) => {
    const result = validateData(formData, dataValidators);
    if (!result.valid) {
      setFormDataErrors(result.errors);
    } else {
      setFormDataErrors({});
    }

    return result.valid;
  }, []);

  const isFormValid = useMemo(
    () => validateForm(formData),
    [validateForm, formData]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [validateForm, formData]
  );

  return (
    <>
      {isFormValid && sendModalVisibility && (
        <SendCEConfirmation
          open={sendModalVisibility}
          assumptions={formData.assumptions}
          ewCouldveBeenGiven={formData.ewCouldveBeenGiven}
          onPrimaryClick={handleSendCEConfirmation}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordCEConfirmation
          open={recordModalVisibility}
          assumptions={formData.assumptions}
          ewCouldveBeenGiven={formData.ewCouldveBeenGiven}
          onPrimaryClick={handleRecordCEConfirmation}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel
              label={t("Projects.CompEvents.ActionModal.assumptions")}
            />
            <RichTextArea
              content={formData.assumptions ?? ""}
              placeholder={t(
                "Projects.CompEvents.ActionModal.confirmCEPlaceholder"
              )}
              error={!!formDataErrors.assumptions}
              onChange={handleAssumptionsChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={
                <FormLabel
                  label={t(
                    "Projects.CompEvents.ActionModal.ewCouldveBeenGivenButWasNot"
                  )}
                  defaultMarginBottom={false}
                />
              }
              labelPlacement="end"
              control={
                <Checkbox
                  inputProps={{
                    "aria-label": "Agreement",
                  }}
                  onChange={handleEWCouldveBeenGivenChange}
                  checked={formData.ewCouldveBeenGiven}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
