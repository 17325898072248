import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { EditUserInput, User } from "generated/graphql";
import { getCountryByName } from "helpers/countries/countries";
import { cleanseBase64Image } from "helpers/miscelaneous";
import { validateData } from "helpers/validators";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { getCountryByPhoneNumber } from "./ProfileForm.utils";
import { UserProfilePhotoSelector } from "../UserProfilePhotoSelector";
import { defaultFormData, formDataValidators } from "./ProfileForm.constants";
import { Overlay } from "components/Overlay";
import { dateISOFormat } from "constants/constants";
import { FormLabel } from "../../../../../../components/FormLabel";
import { CountryData } from "helpers/countries/countries.decl";
import { CountriesSelectNew } from "components/CountriesSelectNew/CountriesSelectNew";
import { CountriesSelectAdornment } from "components/CountriesSelectNew";

export type ProfileFormProps = {
  companyName?: string;
  user?: User;
  loading?: boolean;
  onChange: (data: EditUserInput) => void;
};

const Label: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Typography
      variant="p1"
      fontWeight={600}
      color="grey.800"
      display="inline-block"
      mb={1}
    >
      {label}
    </Typography>
  );
};

export type ProfileFormPublicAPI = {
  validate: () => boolean;
  reset: () => void;
};

export const ProfileForm = React.forwardRef(
  (
    { companyName, user, loading, onChange }: ProfileFormProps,
    ref: React.Ref<ProfileFormPublicAPI>
  ) => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState(defaultFormData);
    const [formDataErrors, setFormDataErrors] = useState<{
      [key: string]: string;
    }>({});
    const [selectedCountry, setSelectedCountry] = useState<CountryData>();
    const [phoneNumberPrefixCountry, setPhoneNumberPrefixCountry] =
      useState<CountryData>();
    const [
      alternatePhoneNumberPrefixCountry,
      setAlternatePhoneNumberPrefixCountry,
    ] = useState<CountryData>();

    const handleCountryChange = (country: CountryData) => {
      setSelectedCountry(country);
      setFormData((curFormData) => ({
        ...curFormData,
        country: country.name,
      }));
      handleFormChange({
        ...formData,
        country: country.name,
      });

      setFormDataErrors((curFormDataErrs) => {
        const { country: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const handlePrefixCountryChange = (country: CountryData) => {
      setPhoneNumberPrefixCountry(country);
      const newPhoneNumber = country.phonePrefix;
      const mobileNumberUpdate = formData.mobileNumber
        ? formData.mobileNumber.indexOf(country.phonePrefix) < 0
          ? { mobileNumber: newPhoneNumber }
          : {}
        : { mobileNumber: newPhoneNumber };

      setFormData((curFormData) => ({
        ...curFormData,
        ...mobileNumberUpdate,
      }));
      handleFormChange({
        ...formData,
        ...mobileNumberUpdate,
      });
    };

    const handleAlternatePrefixCountryChange = (newCountry: CountryData) => {
      setAlternatePhoneNumberPrefixCountry(newCountry);

      const newPhoneNumber = newCountry.phonePrefix;
      const alternateNumberUpdate = formData.alternateNumber
        ? formData.alternateNumber.indexOf(newCountry.phonePrefix) < 0
          ? { alternateNumber: newPhoneNumber }
          : {}
        : { alternateNumber: newPhoneNumber };

      setFormData((curFormData) => ({
        ...curFormData,
        ...alternateNumberUpdate,
      }));
      handleFormChange({
        ...formData,
        ...alternateNumberUpdate,
      });
    };

    const handleTextFieldChange: React.ChangeEventHandler<
      HTMLTextAreaElement | HTMLInputElement
    > = (evt) => {
      setFormData((curData) => ({
        ...curData,
        [evt.target.name]: evt.target.value,
      }));

      handleFormChange({
        ...formData,
        [evt.target.name]: evt.target.value,
      });

      setFormDataErrors((curFormDataErrs) => {
        const { [evt.target.name]: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const validateForm = useCallback((formData: EditUserInput | User) => {
      const validationResult = validateData(formData, formDataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);
      return false;
    }, []);

    const handleFormChange = useCallback(
      (formData: EditUserInput) => {
        onChange?.({
          alternateNumber: formData.alternateNumber || undefined,
          companyId: formData.companyId,
          country: formData.country,
          dateOfBirth: formData.dateOfBirth,
          firstname: formData.firstname,
          id: formData.id,
          jobTitle: formData.jobTitle,
          mobileNumber: formData.mobileNumber,
          surname: formData.surname,
          profilePicture: cleanseBase64Image(formData.profilePicture),
        });
      },
      [onChange]
    );

    const resetForm = useCallback(() => {
      setFormData((user || defaultFormData) as EditUserInput);
      handleFormChange((user || defaultFormData) as EditUserInput);
    }, [user, handleFormChange]);

    const handlePhotoUpload = (photo?: string) => {
      setFormData((curFormData) => ({
        ...curFormData,
        profilePicture: photo,
      }));
      handleFormChange({
        ...formData,
        profilePicture: photo,
      });
    };

    useEffect(() => {
      setFormData((user || defaultFormData) as EditUserInput);
    }, [user]);

    useEffect(() => {
      if (user) {
        const countryCode = getCountryByName(user.country);
        const updatedUser = {
          ...user,
          dateOfBirth: moment(user.dateOfBirth).format(dateISOFormat),
        };

        setFormData(updatedUser as EditUserInput);
        setSelectedCountry(countryCode);
        setPhoneNumberPrefixCountry(getCountryByPhoneNumber(user.mobileNumber));
        setAlternatePhoneNumberPrefixCountry(
          user.alternateNumber
            ? getCountryByPhoneNumber(user.alternateNumber)
            : undefined
        );
        validateForm(updatedUser);
      }
    }, [user, validateForm]);

    useImperativeHandle(
      ref,
      () => ({
        validate: () => validateForm(formData),
        reset: resetForm,
      }),
      [validateForm, formData, resetForm]
    );

    return (
      <>
        {loading && <Overlay withBackground={false} />}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <>
              <Label label={t("Settings.profile.profilePhoto")} />
              <UserProfilePhotoSelector
                url={user?.profilePicture ?? undefined}
                onPhotoUpload={handlePhotoUpload}
              />
            </>
          </Grid>
          <Grid item md={6} xs={12}>
            <>
              <Label label={t("common.labels.email")} />
              <TextField
                fullWidth
                name="email"
                value={user?.email ?? ""}
                type="email"
                disabled
                variant="outlined"
              />
            </>
          </Grid>
          <Grid item md={6} xs={12}>
            <>
              <Label label={t("common.labels.company")} />
              <TextField
                fullWidth
                name="company"
                value={companyName ?? ""}
                type="text"
                variant="outlined"
                disabled
              />
            </>
          </Grid>
          <Grid item md={6} xs={12}>
            <>
              <FormLabel label={t("common.labels.firstName")} required />
              <TextField
                fullWidth
                name="firstname"
                value={formData.firstname ?? ""}
                onChange={handleTextFieldChange}
                type="text"
                variant="outlined"
                error={!!formDataErrors.firstname}
                helperText={formDataErrors.firstname}
                required
              />
            </>
          </Grid>
          <Grid item md={6} xs={12}>
            <>
              <FormLabel label={t("common.labels.surname")} required />
              <TextField
                fullWidth
                name="surname"
                value={formData.surname ?? ""}
                onChange={handleTextFieldChange}
                type="text"
                variant="outlined"
                error={!!formDataErrors.surname}
                helperText={formDataErrors.surname}
                required
              />
            </>
          </Grid>
          <Grid item md={6} xs={12}>
            <>
              <FormLabel label={t("Register.labels.jobTitle")} required />
              <TextField
                fullWidth
                name="jobTitle"
                value={formData.jobTitle ?? ""}
                onChange={handleTextFieldChange}
                type="text"
                variant="outlined"
                error={!!formDataErrors.jobTitle}
                helperText={formDataErrors.jobTitle}
                required
              />
            </>
          </Grid>
          <Grid item md={6} xs={12}>
            <>
              <FormLabel label={t("common.labels.dateOfBirth")} required />
              <TextField
                fullWidth
                name="dateOfBirth"
                value={formData.dateOfBirth ?? ""}
                onChange={handleTextFieldChange}
                type="date"
                placeholder="DD.MM.YYYY"
                variant="outlined"
                error={!!formDataErrors.dateOfBirth}
                helperText={formDataErrors.dateOfBirth}
                required
              />
            </>
          </Grid>
          <Grid item xs={6}>
            <>
              <FormLabel label={t("common.labels.mobileNumber")} required />
              <TextField
                fullWidth
                name="mobileNumber"
                value={formData.mobileNumber ?? ""}
                onChange={handleTextFieldChange}
                type="text"
                placeholder={t("common.labels.phonePlaceholder")}
                variant="outlined"
                error={!!formDataErrors.mobileNumber}
                helperText={formDataErrors.mobileNumber}
                sx={{ paddingLeft: 0 }}
                InputProps={{
                  sx: {
                    paddingLeft: 0,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <CountriesSelectAdornment
                        value={phoneNumberPrefixCountry}
                        onChange={handlePrefixCountryChange}
                        hideSelectedCountryName
                      />
                    </InputAdornment>
                  ),
                }}
                required
              />
            </>
          </Grid>
          <Grid item xs={6}>
            <>
              <FormLabel label={t("common.labels.alternativeNumber")} />
              <TextField
                fullWidth
                name="alternateNumber"
                value={formData.alternateNumber ?? ""}
                onChange={handleTextFieldChange}
                type="text"
                placeholder={t("common.labels.phonePlaceholder")}
                variant="outlined"
                error={!!formDataErrors.alternateNumber}
                helperText={formDataErrors.alternateNumber}
                sx={{ paddingLeft: 0 }}
                InputProps={{
                  sx: {
                    paddingLeft: 0,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box>
                        <CountriesSelectAdornment
                          value={alternatePhoneNumberPrefixCountry}
                          onChange={handleAlternatePrefixCountryChange}
                          hideSelectedCountryName
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <>
                <FormLabel label={t("common.labels.country")} required />
                <CountriesSelectNew
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  hasErrors={!!formDataErrors.country}
                />
                {!!formDataErrors.country && (
                  <Typography variant="caption" color="error">
                    {formDataErrors.country}
                  </Typography>
                )}
              </>
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  }
);
