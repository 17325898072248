import { gql } from "@apollo/client";

export const documentTemplateRouteLiteFields = gql`
  fragment DocumentTemplateRouteLiteFields on DocumentTemplateRoute {
    id
    productOutputActionId
    contractTypeId
    originatingPartyId
    contractId
    targetPartyId
    defaultNumbering
    clause
    documentTemplateId
    isInternal
    dateCreated
    creatorId
  }
`;

export const addDocumentTemplateRouteMutation = gql`
  ${documentTemplateRouteLiteFields}

  mutation addDocumentTemplateRoute($input: AddDocumentTemplateRouteInput!) {
    addDocumentTemplateRoute(input: $input) {
      ...DocumentTemplateRouteLiteFields
    }
  }
`;

export const editDocumentTemplateRouteMutation = gql`
  ${documentTemplateRouteLiteFields}

  mutation editDocumentTemplateRoute($input: EditDocumentTemplateRouteInput!) {
    editDocumentTemplateRoute(input: $input) {
      ...DocumentTemplateRouteLiteFields
    }
  }
`;

export const removeDocumentTemplateRouteMutation = gql`
  mutation removeDocumentTemplateRoute($id: ID!) {
    removeDocumentTemplateRoute(id: $id)
  }
`;

export const addDocumentTemplateRouteOverrideMutation = gql`
  ${documentTemplateRouteLiteFields}

  mutation addDocumentTemplateRouteOverride(
    $input: AddDocumentTemplateRouteOverrideInput!
  ) {
    addDocumentTemplateRouteOverride(input: $input) {
      ...DocumentTemplateRouteLiteFields
    }
  }
`;

export const editDocumentTemplateRouteOverrideMutation = gql`
  ${documentTemplateRouteLiteFields}

  mutation editDocumentTemplateRouteOverride(
    $input: EditDocumentTemplateRouteOverrideInput!
  ) {
    editDocumentTemplateRouteOverride(input: $input) {
      ...DocumentTemplateRouteLiteFields
    }
  }
`;

export const removeDocumentTemplateRouteOverrideMutation = gql`
  mutation removeDocumentTemplateRouteOverride($id: ID!) {
    removeDocumentTemplateRouteOverride(id: $id)
  }
`;
