import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import {
  ChangeItemStatusOptionStatusMutation,
  ChangeItemStatusOptionStatusMutationVariables,
  ItemStatusCollectionLiteQuery,
  ItemStatusCollectionLiteQueryVariables,
  ItemStatusOptionQuery,
  ItemStatusOptionQueryVariables,
  ItemStatusOptionReason,
  ItemStatusOptionStatus,
  ProductLiteQuery,
  ProductLiteQueryVariables,
  User,
} from "generated/graphql";
import { changeItemStatusOptionStatusMutation } from "graphql/mutations/changeStatusCollectionOptionStatus";
import { productLiteQuery } from "graphql/queries/productLite.query";
import { itemStatusCollectionLiteQuery } from "graphql/queries/statusCollectionLite.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { OptionChangeReasons } from "./components/OptionChangeReasons/OptionChangeReasons";
import { useOptionChangeReasons } from "./components/OptionChangeReasons/useOptionChangeReasons";
import { itemStatusOptionQuery } from "./StatusOptionDetails.query";
import { getUserName } from "helpers/miscelaneous";

export const StatusOptionDetails = () => {
  const { t } = useTranslation();
  const { productId, collectionId, optionId } = useParams();
  const navigate = useNavigate();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ItemStatusOptionStatus>[];

  const {
    data: optionData,
    refetch: refetchOptionData,
    loading: getOptionDataLoading,
  } = useGraphQuery<ItemStatusOptionQuery, ItemStatusOptionQueryVariables>(
    itemStatusOptionQuery,
    {
      variables: { id: optionId! },
    }
  );

  const [
    changeCollectionOptionStatus,
    { loading: changeCollectionOptionStatusLoading },
  ] = useGraphMutation<
    ChangeItemStatusOptionStatusMutation,
    ChangeItemStatusOptionStatusMutationVariables
  >(
    changeItemStatusOptionStatusMutation,
    {
      update: () => {
        refetchOptionData();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Products.labels.statusOption"),
    })
  );

  const { data: collectionDataLite, loading: getCollectionDataLiteLoading } =
    useGraphQuery<
      ItemStatusCollectionLiteQuery,
      ItemStatusCollectionLiteQueryVariables
    >(itemStatusCollectionLiteQuery, {
      variables: { id: collectionId! },
    });

  const { data: productDataLite, loading: getProductDataLiteLoading } =
    useGraphQuery<ProductLiteQuery, ProductLiteQueryVariables>(
      productLiteQuery,
      {
        variables: { id: productId! },
      }
    );

  const onStatusOptionReasonUpdated = () => refetchOptionData();

  const {
    addStatusOptionReason,
    addOptionReasonLoading,
    editStatusOptionReason,
    editOptionReasonLoading,
    changeStatusOptionReasonStatus,
    changeOptionReasonStatusLoading,
  } = useOptionChangeReasons({
    onStatusOptionReasonAdded: onStatusOptionReasonUpdated,
    onStatusOptionReasonStatusChange: onStatusOptionReasonUpdated,
    onStatusOptionReasonUpdated,
  });

  const creatorStr = useMemo(() => {
    if (
      optionData?.itemStatusOption.creator.firstname &&
      optionData?.itemStatusOption.creator.surname
    ) {
      return getUserName(
        optionData?.itemStatusOption.creator as User | undefined
      );
    }

    return "";
  }, [optionData]);

  const handleCollectionOptionStatusChange = useCallback(
    async (newStatus: ItemStatusOptionStatus) => {
      await changeCollectionOptionStatus({
        variables: {
          id: optionId!,
          status: newStatus,
        },
      });
    },
    [changeCollectionOptionStatus, optionId]
  );

  const handleBreadcrumbClick = (parent?: "collection" | "product") => {
    if (parent === "collection") {
      navigate(-1);
    } else if (parent === "product") {
      navigate(-2);
    } else {
      navigate(-3);
    }
  };

  const loading =
    getOptionDataLoading ||
    getProductDataLiteLoading ||
    getCollectionDataLiteLoading ||
    changeCollectionOptionStatusLoading;

  return (
    <Box>
      <EntityDetailsHeader
        loading={loading}
        title={optionData?.itemStatusOption.description || ""}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={() => handleBreadcrumbClick()}>
              <Typography variant="body2">
                {t("AdminConsole.Products.labels.products")}
              </Typography>
            </Link>
            <Link key="2" onClick={() => handleBreadcrumbClick("product")}>
              <Typography variant="body2">
                {productDataLite?.product.name}
              </Typography>
            </Link>
            <Link key="3" onClick={() => handleBreadcrumbClick("collection")}>
              <Typography variant="body2">
                {collectionDataLite?.itemStatusCollection.name}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        status={optionData?.itemStatusOption.status}
        statusOptions={statusOptions}
        creator={creatorStr}
        dateCreated={optionData?.itemStatusOption.dateCreated}
        onStatusChange={handleCollectionOptionStatusChange}
      />
      <OptionChangeReasons
        optionReasons={
          (optionData?.itemStatusOption.optionReasons
            .items as ItemStatusOptionReason[]) || []
        }
        optionName={optionData?.itemStatusOption.description}
        optionId={optionData?.itemStatusOption.id}
        loading={
          loading ||
          addOptionReasonLoading ||
          editOptionReasonLoading ||
          changeOptionReasonStatusLoading
        }
        onOptionReasonStatusChange={changeStatusOptionReasonStatus}
        onOptionReasonAdd={addStatusOptionReason}
        onOptionReasonUpdate={editStatusOptionReason}
      />
    </Box>
  );
};
