import { Box, Typography, css, styled, useTheme } from "@mui/material";
import { Info } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { unofficialThemeColors } from "theme/extendedTheme";

export const DiaryBannerContainer = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    border-radius: 8px;
    border: 1px solid rgba(24, 106, 222, 0.3); // Note: this is info.main color, but with 0,30 opacity
    background-color: ${unofficialThemeColors.bluePale}; // note: the same color used in notifications
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  `
);

export const LockedDailyDiaryBanner: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <DiaryBannerContainer>
      <Box mr={2}>
        <Info size={24} color={theme.palette.info.main} />
      </Box>
      <Typography variant="p2" color="grey.900">
        {t("Projects.DailyDiaries.lockedBannerMessage")}
      </Typography>
    </DiaryBannerContainer>
  );
};
