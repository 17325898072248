import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";
import { dailyDiaryWorkRecordLiteFields } from "graphql/fragments/dailyDiaryWorkRecordLite.fragment";

export const dailyDiaryWorkRecordsQuery = gql`
  ${dailyDiaryWorkRecordLiteFields}
  ${attachmentFields}

  query dailyDiaryWorkRecords($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryWorkRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      ...DailyDiaryWorkRecordLiteFields
      attachments {
        ...AttachmentFields
      }
    }
  }
`;

export const dailyDiaryWorkImportSourceItemsQuery = gql`
  query dailyDiaryWorkImportSourceItems(
    $productInstanceId: ID!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    dailyDiaryWorkImportSourceItems(
      productInstanceId: $productInstanceId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      items {
        id
        productInstanceId
        date
        number
        status
        dateCreated
        creatorId
        sentById
        dateSent
        latestRevision {
          id
          number
          status
          dateSent
        }
      }
    }
  }
`;

export const importDailyDiaryWorkRecordsMutation = gql`
  mutation importDailyDiaryWorkRecords($input: ImportDailyDiaryRecordsInput!) {
    importDailyDiaryWorkRecords(input: $input)
  }
`;
