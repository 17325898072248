import { gql } from "@apollo/client";
import { dailyDiaryResourceRecordLiteFields } from "graphql/fragments/dailyDiaryResourceRecordLite.fragment";

export const editDailyDiaryManpowerRecordMutation = gql`
  ${dailyDiaryResourceRecordLiteFields}

  mutation editDailyDiaryManpowerRecord(
    $input: EditDailyDiaryResourceRecordInput!
  ) {
    editDailyDiaryManpowerRecord(input: $input) {
      ...DailyDiaryResourceRecordLiteFields
    }
  }
`;
