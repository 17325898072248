import { gql } from "@apollo/client";

export const changeContractBindingStatusMutation = gql`
  mutation changeContractBindingStatus(
    $id: ID!
    $status: ContractBindingStatus!
  ) {
    changeContractBindingStatus(id: $id, status: $status) {
      id
      contractBindingTypeId
      status
      defaultCompany
      representativeId
      isCompanyBilled
      dateCreated
      creator {
        id
        firstname
        surname
      }
      companyId
      contractId
    }
  }
`;
