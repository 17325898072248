import { AddDocumentTemplateInput } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";

export const defaultDocumentTemplate: AddDocumentTemplateInput = {
  content: "",
  description: "",
  name: "",
};

export const documentTemplateDataValidators: DataValidators = {
  name: {
    validators: [ValidatorType.Required],
  },
  content: {
    validators: [ValidatorType.Required],
  },
};
