import {
  ChangeEarlyWarningItemStatusMutation,
  ChangeEarlyWarningItemStatusMutationVariables,
  EarlyWarningItem,
  EarlyWarningItemQuery,
  EarlyWarningItemQueryVariables,
  EditEarlyWarningItemMutation,
  EditEarlyWarningItemMutationVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useTranslation } from "react-i18next";
import {
  changeEarlyWarningItemStatusMutation,
  earlyWarningItemQuery,
  editEarlyWarningItemMutation,
} from "../EarlyWarningDetails.query";
import { ErrorHandlingType, useGraphMutation } from "hooks/useGraphMutation";
import { useParams } from "react-router-dom";

export const useEarlyWarningDetails = (onReloadChangelog: () => void) => {
  const { t } = useTranslation();
  const { ewItemId } = useParams();

  const {
    data: ewData,
    loading: earlyWarningLoading,
    error: earlyWarningError,
  } = useGraphQuery<EarlyWarningItemQuery, EarlyWarningItemQueryVariables>(
    earlyWarningItemQuery,
    {
      variables: { id: ewItemId! },
      fetchPolicy: "cache-and-network",
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  const [changeEWStatus, { loading: changeEWStatusLoading }] = useGraphMutation<
    ChangeEarlyWarningItemStatusMutation,
    ChangeEarlyWarningItemStatusMutationVariables
  >(
    changeEarlyWarningItemStatusMutation,
    {
      update: (_cache) => {
        // cache.evict({ id: "ROOT_QUERY", fieldName: "earlyWarningItems" });
        // cache.gc();
        onReloadChangelog();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.EarlyWarnings.earlyWarning"),
    })
  );

  const [doEditEarlyWarning] = useGraphMutation<
    EditEarlyWarningItemMutation,
    EditEarlyWarningItemMutationVariables
  >(
    editEarlyWarningItemMutation,
    {
      update: () => {
        onReloadChangelog();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.EarlyWarnings.earlyWarning"),
    })
  );

  return {
    earlyWarning: ewData?.earlyWarningItem as EarlyWarningItem | undefined,
    earlyWarningLoading,
    earlyWarningError,
    changeEWStatus,
    changeEWStatusLoading,
    doEditEarlyWarning,
  };
};
