import { BasicModal } from "components/BasicModal/BasicModal";
import { ClaimItem, ClaimAction as ClaimActionType } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { ClaimActionView } from "./ClaimActionView";
import { useContext, useRef } from "react";
import { ClaimWidgetContext } from "../ClaimWidget/ClaimWidget.context";

export type ClaimActionModalProps = {
  claimActions: ClaimActionType[];
  claim?: ClaimItem;
  open: boolean;
  onClose: () => void;
};

export const ClaimActionModal: React.FC<ClaimActionModalProps> = ({
  claim,
  claimActions,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const ref = useRef<{ onBeforeClose: () => void } | null>(null);
  const { isFIDIC99RedYellow } = useContext(ClaimWidgetContext);

  const handleClose = () => {
    ref.current?.onBeforeClose();
    onClose();
  };

  return (
    <BasicModal
      open={open}
      title={t(
        `Projects.Claims.ActionModal.title${isFIDIC99RedYellow ? "99RY" : ""}`
      )}
      maxWidth="md"
      titleNextToIcon
      onClose={handleClose}
    >
      <ClaimActionView
        claim={claim}
        apiRef={ref}
        claimActions={claimActions}
        onCancel={onClose}
      />
    </BasicModal>
  );
};
