import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useExplorer } from "./useExplorer";
import { useExplorerUrlEntities } from "./useExplorerUrlEntities";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { read as readFromLS, write as writeToLS } from "helpers/localStorage";
import { NewAppPaths } from "helpers/paths/paths";
import { noop } from "helpers/miscelaneous";

export type ExplorerState = {
  projectId?: string;
  contractId?: string;
  productId?: string;
  productInstanceId?: string;
};

export type ExplorerContextType = {
  projectId?: string;
  contractId?: string;
  productId?: string;
  productInstanceId?: string;
  loading?: boolean;
  clear: () => void;
  setLoading: (loading: boolean) => void;
  changeExplorerEntities: (explorerEntities: ExplorerState) => void;
  changeProject: (newProjectId: string) => void;
  changeContract: (newContractId?: string) => void;
  changeProduct: (newProductId?: string) => void;
  changeProductInstance: (newProductInstanceId?: string) => void;
};

export const ExplorerContext = createContext<ExplorerContextType>({
  projectId: "",
  contractId: "",
  productId: "",
  productInstanceId: "",
  loading: false,
  clear: noop,
  setLoading: noop,
  changeProject: noop,
  changeContract: noop,
  changeProduct: noop,
  changeProductInstance: noop,
  changeExplorerEntities: noop,
});

const localstorageExplorerDataKey = "explorerData";

export const ExplorerContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [localExplorerState, setLocalExplorerState] = useState<ExplorerState>();
  const [loading, setLoading] = useState(false);

  const { projectId, contractId, productId, productInstanceId } =
    useExplorerUrlEntities();

  const {
    changeProject,
    changeContract,
    changeProduct,
    changeProductInstance,
  } = useExplorer();

  const handleChangeProject = useCallback(
    (newProjectId: string) => {
      if (newProjectId !== projectId) {
        changeProject(newProjectId);
        setLocalExplorerState({});
      }
    },
    [changeProject, projectId]
  );

  const handleChangeContract = useCallback(
    (newContractId?: string) => {
      if (newContractId !== contractId) {
        changeContract(
          (projectId ?? localExplorerState?.projectId)!,
          newContractId
        );
        setLocalExplorerState({});
      }
    },
    [changeContract, projectId, contractId, localExplorerState]
  );

  const handleChangeProduct = useCallback(
    (newProductId?: string) => {
      if (newProductId !== productId) {
        changeProduct(
          (projectId ?? localExplorerState?.projectId)!,
          (contractId ?? localExplorerState?.contractId)!,
          newProductId
        );
        setLocalExplorerState({});
      }
    },
    [changeProduct, projectId, contractId, productId, localExplorerState]
  );

  const handleChangeProductInstance = useCallback(
    (newProductInstanceId?: string) => {
      if (newProductInstanceId !== productInstanceId) {
        setLocalExplorerState({});
        changeProductInstance(
          (projectId ?? localExplorerState?.projectId)!,
          (contractId ?? localExplorerState?.contractId)!,
          (productId ?? localExplorerState?.productId)!,
          newProductInstanceId
        );
      }
    },
    [
      changeProductInstance,
      projectId,
      contractId,
      productId,
      productInstanceId,
      localExplorerState,
    ]
  );

  const changeExplorerEntities = useCallback((explorerState: ExplorerState) => {
    setLocalExplorerState(explorerState);
  }, []);

  const clear = useCallback(() => {
    changeExplorerEntities({});
    writeToLS(
      {
        projectId: undefined,
        contractId: undefined,
        productId: undefined,
        productInstanceId: undefined,
      },
      localstorageExplorerDataKey
    );
  }, [changeExplorerEntities]);

  const contextValue = useMemo(() => {
    return {
      projectId: projectId ?? localExplorerState?.projectId,
      contractId: contractId ?? localExplorerState?.contractId,
      productId: productId ?? localExplorerState?.productId,
      productInstanceId:
        productInstanceId ?? localExplorerState?.productInstanceId,
      loading,
      clear,
      setLoading,
      changeExplorerEntities,
      changeProject: handleChangeProject,
      changeContract: handleChangeContract,
      changeProduct: handleChangeProduct,
      changeProductInstance: handleChangeProductInstance,
    };
  }, [
    localExplorerState,
    projectId,
    contractId,
    productId,
    productInstanceId,
    loading,
    clear,
    changeExplorerEntities,
    handleChangeProject,
    handleChangeContract,
    handleChangeProduct,
    handleChangeProductInstance,
    setLoading,
  ]);

  useEffect(() => {
    if (
      matchPath(NewAppPaths.authorized.Projects.path, location.pathname) &&
      !projectId
    ) {
      // user navigated to Projects' "Home page". Apply old Explorer state from before leaving Projects realm. Do not apply the
      // old state if user navigates to /projects?projectId=newProjId
      const explorerData = readFromLS(
        localstorageExplorerDataKey
      ) as ExplorerState;

      if (explorerData && Object.keys(explorerData).length) {
        let previousProjectsPath = NewAppPaths.authorized.Projects.path;
        if (explorerData.projectId)
          previousProjectsPath += `?projectId=${explorerData.projectId}`;
        if (explorerData.contractId)
          previousProjectsPath += `&contractId=${explorerData.contractId}`;
        if (explorerData.productId)
          previousProjectsPath += `&productId=${explorerData.productId}`;
        if (explorerData.productInstanceId)
          previousProjectsPath += `&productInstanceId=${explorerData.productInstanceId}`;

        navigate(previousProjectsPath, {
          replace: true,
          state: location.state,
        });
      }
    }
  }, [location, navigate, projectId]);

  useEffect(() => {
    const { projectId, contractId, productId, productInstanceId } =
      contextValue;

    // write to LocalStorage
    writeToLS(
      { projectId, contractId, productId, productInstanceId },
      localstorageExplorerDataKey
    );
  }, [contextValue]);

  return (
    <ExplorerContext.Provider value={contextValue}>
      {children}
    </ExplorerContext.Provider>
  );
};
