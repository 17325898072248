import {
  RecordClaimErrorDeterminationNoticeMutation,
  RecordClaimErrorDeterminationNoticeMutationVariables,
  SendClaimErrorDeterminationNoticeMutation,
  SendClaimErrorDeterminationNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { OnMutationDone } from "types/decl";
import {
  recordClaimErrorDeterminationNoticeMutation,
  sendClaimErrorDeterminationNoticeMutation,
} from "./NotifyErrorFoundInDeterminationAction.query";

export const useNotifyErrorFoundInDetermination = () => {
  const { t } = useTranslation();
  const { onChange, claim } = useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendClaimErrorDeterminationNotice,
    { loading: sendClaimErrorDeterminationNoticeLoading },
  ] = useGraphMutation<
    SendClaimErrorDeterminationNoticeMutation,
    SendClaimErrorDeterminationNoticeMutationVariables
  >(
    sendClaimErrorDeterminationNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Claims.errorInDetermination"),
    })
  );

  const [
    recordClaimErrorDeterminationNotice,
    { loading: recordClaimErrorDeterminationNoticeLoading },
  ] = useGraphMutation<
    RecordClaimErrorDeterminationNoticeMutation,
    RecordClaimErrorDeterminationNoticeMutationVariables
  >(
    recordClaimErrorDeterminationNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Claims.errorInDetermination"),
    })
  );

  return {
    actionLoading:
      sendClaimErrorDeterminationNoticeLoading ||
      recordClaimErrorDeterminationNoticeLoading,
    sendClaimErrorDeterminationNotice,
    recordClaimErrorDeterminationNotice,
  };
};
