import { gql } from "@apollo/client";
import { variationHistoryItemFields } from "graphql/fragments/variationHistoryItem.fragment";

export const variationHistoryItemQuery = gql`
  ${variationHistoryItemFields}

  query variationHistoryItem($id: ID!) {
    variationHistoryItem(id: $id) {
      ...VariationHistoryItemFields
    }
  }
`;
