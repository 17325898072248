import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const TrashIcon: React.FC<BaseIconProps> = ({
  height = "18",
  width = "17",
  color,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.text.primary;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5022 4.49854H2.5"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 7.49951V11.4997"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50391 7.49951V11.4997"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5018 4.49854V14.0005C12.5018 14.1331 12.4491 14.2603 12.3553 14.3541C12.2616 14.4479 12.1344 14.5006 12.0017 14.5006H4.0001C3.86747 14.5006 3.74026 14.4479 3.64648 14.3541C3.55269 14.2603 3.5 14.1331 3.5 14.0005V4.49854"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5002 4.49836V3.49833C10.5002 3.2331 10.3948 2.97874 10.2073 2.79119C10.0197 2.60365 9.76537 2.49829 9.50014 2.49829H6.50004C6.23481 2.49829 5.98045 2.60365 5.7929 2.79119C5.60536 2.97874 5.5 3.2331 5.5 3.49833V4.49836"
        stroke={computedColor}
        strokeWidth="1.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
