import {
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { DailyDiarySectionDataGrid } from "components/StyledDataGrid";
import {
  AttachmentStatus,
  CompanyLookupCollection,
  DailyDiaryWorkRecord,
} from "generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDailyDiaryStatus } from "../../../hooks/useDailyDiaryStatus";
import { NoRowsOverlay } from "../../NoRowsOverlay";
import { useColumns } from "./WorkTable.constants";
import { DailyDiaryRecordAttachmentsModal } from "../../DailyDiaryRecordAttachmentsModal/DailyDiaryRecordAttachmentsModal";
import { toRecordJSON } from "./WorkTable.utils";
import { useDiaryTableSorting } from "../../../hooks/useDiaryTableSorting";

export type WorkLookups = {
  Shift: CompanyLookupCollection;
  Area: CompanyLookupCollection;
  Discipline: CompanyLookupCollection;
  Team: CompanyLookupCollection;
  Activity: CompanyLookupCollection;
  WorkType: CompanyLookupCollection;
  UnitOfMeasurement: CompanyLookupCollection;
};

export enum WorkOptionalColumn {
  Shift = "shift",
  Description = "description",
  Area = "area",
  Discipline = "discipline",
  Team = "team",
  Activity = "activity",
}

export type WorkTableProps = {
  records: DailyDiaryWorkRecord[];
  optionalColumns: WorkOptionalColumn[];
  workLookupCollections: CompanyLookupCollection[];
  loading?: boolean;
  hideAttachments?: boolean;
  onEdit?: (record: GridRowId) => void;
  onRemove?: (record: GridRowId) => void;
  onSelectionChange?: (selectedRecordIds?: GridRowSelectionModel) => void;
};

export const WorkTable: React.FC<WorkTableProps> = ({
  records,
  optionalColumns,
  workLookupCollections,
  loading,
  hideAttachments,
  onEdit,
  onRemove,
  onSelectionChange,
}) => {
  const { t } = useTranslation();

  const { isDraftOrEmptyDailyDiary } = useDailyDiaryStatus();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const selectionEnabled = !!onSelectionChange;
  const editable = !!onEdit || !!onRemove;

  const { sortingModel, setSortingModel, rows } = useDiaryTableSorting(records);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });
  const [selectedRow, setSelectedRow] = useState<DailyDiaryWorkRecord>();

  const handleAttachmentsClick = (gridRowId: GridRowId) => {
    setSelectedRow(rows.find((record) => record.id === gridRowId));
  };

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
      onSelectionChange?.(selectionModel);
    },
    [onSelectionChange]
  );

  const withActivityColumn = optionalColumns.includes(
    WorkOptionalColumn.Activity
  );
  const withShiftColumn = optionalColumns.includes(WorkOptionalColumn.Shift);
  const withAreaColumn = optionalColumns.includes(WorkOptionalColumn.Area);
  const withDescriptionColumn = optionalColumns.includes(
    WorkOptionalColumn.Description
  );
  const withDisciplineColumn = optionalColumns.includes(
    WorkOptionalColumn.Discipline
  );
  const withTeamColumn = optionalColumns.includes(WorkOptionalColumn.Team);

  const columns = useColumns({
    withShiftColumn,
    withActivityColumn,
    withAreaColumn,
    withDescriptionColumn,
    withDisciplineColumn,
    withTeamColumn,
    workLookups: workLookupCollections,
    withAttachments: !hideAttachments,
    onEdit,
    onRemove,
    onAttachmentsClick: handleAttachmentsClick,
  });

  const selectedRowActiveAttachments = useMemo(
    () =>
      (selectedRow?.attachments ?? []).filter(
        (attach) => attach.status === AttachmentStatus.Active
      ),
    [selectedRow]
  );

  return (
    <>
      {selectedRow && (
        <DailyDiaryRecordAttachmentsModal
          open={!!selectedRow}
          onClose={() => setSelectedRow(undefined)}
          attachments={selectedRowActiveAttachments}
          recordJSON={toRecordJSON(
            selectedRow,
            withShiftColumn,
            withActivityColumn,
            withAreaColumn,
            withDescriptionColumn,
            withDisciplineColumn,
            withTeamColumn,
            workLookupCollections,
            t
          )}
          title={t("Projects.DailyDiaries.sections.work")}
        />
      )}
      <DailyDiarySectionDataGrid
        rows={rows}
        columns={columns}
        getRowId={(rowData: DailyDiaryWorkRecord) => rowData.id}
        loading={loading}
        sortingMode="client"
        sortModel={sortingModel}
        onSortModelChange={setSortingModel}
        filterMode="client"
        filterModel={filterModel}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              title={
                isDraftOrEmptyDailyDiary
                  ? undefined
                  : t("Grid.labels.noRecords")
              }
              hideSubtitle={!isDraftOrEmptyDailyDiary || !editable}
              recordName={t("Projects.DailyDiaries.sections.work")}
            />
          ),
        }}
        onFilterModelChange={setFilterModel}
        rowSelectionModel={selectionEnabled ? selectionModel : undefined}
        checkboxSelection={selectionEnabled}
        onRowSelectionModelChange={handleGridRowSelectionModelChange}
        rowSelection={selectionEnabled}
        disableRowSelectionOnClick
        hideFooter
        autoHeight
        stickyHeader
      />
    </>
  );
};
