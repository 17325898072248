import { gql } from "@apollo/client";
import { draftVariationAgreementFields } from "graphql/fragments/draftVariationAgreement.fragment";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationAgreementNoticePromptQuery = gql`
  ${draftVariationAgreementFields}

  query variationAgreementNoticePrompt(
    $input: VariationAgreementNoticePromptInput!
  ) {
    variationAgreementNoticePrompt(input: $input) {
      draftVariationAgreements {
        ...DraftVariationAgreementFields
      }
    }
  }
`;

export const sendVariationAgreementNoticeMutation = gql`
  mutation sendVariationAgreementNotice(
    $input: SendVariationAgreementNoticeInput!
  ) {
    sendVariationAgreementNotice(input: $input) {
      id
    }
  }
`;

export const recordVariationAgreementNoticeMutation = gql`
  mutation recordVariationAgreementNotice(
    $input: RecordVariationAgreementNoticeInput!
  ) {
    recordVariationAgreementNotice(input: $input) {
      id
    }
  }
`;

export const generateSendVariationAgreementNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationAgreementNoticePreview(
    $input: SendVariationAgreementNoticePreviewInput!
  ) {
    generateSendVariationAgreementNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationAgreementNoticePreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationAgreementNoticePreview(
    $input: RecordVariationAgreementNoticePreviewInput!
  ) {
    generateRecordVariationAgreementNoticePreview(input: $input) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
