import { gql } from "@apollo/client";
import { dailyDiaryPresetFields } from "graphql/fragments/dailyDiaryPreset.fragment";

export const dailyDiaryPresetsQuery = gql`
  ${dailyDiaryPresetFields}

  query dailyDiaryPresets {
    dailyDiaryPresets {
      items {
        ...DailyDiaryPresetFields
      }
    }
  }
`;
