import { Box, Button, Dialog, DialogProps, Typography } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { BackIcon } from "components/Icons/BackIcon";
import { DownloadIcon } from "components/Icons/DownloadIcon";
import { Attachment } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { FileType } from "../Attachments/Attachments.decl";
import { DateWithTimeTooltip } from "../DateWithTimeTooltip";

export type PhotoAttachmentPreviewModalProps = {
  attachment?: Attachment | FileType;
  creatorName?: string;
  creatorCompany?: string;
  contractTimezone?: string;
  previewUrl?: string;
  onDownload: (attachment: Attachment | FileType) => void;
} & DialogProps;

export const PhotoAttachmentPreviewModal: React.FC<
  PhotoAttachmentPreviewModalProps
> = ({
  attachment,
  creatorName,
  creatorCompany,
  contractTimezone,
  previewUrl,
  open,
  onDownload,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation();

  const handleBackClick = () => {
    onClose?.({}, "backdropClick");
  };

  const handleDownload = () => {
    onDownload(attachment!);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="image-attachment-dialog"
      aria-describedby="image-attachment-dialog"
      maxWidth="xl"
      fullWidth
      {...restProps}
    >
      <Box
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        px={6}
        py={5}
      >
        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            onClick={handleBackClick}
            size="large"
            sx={{ minWidth: "110px" }}
          >
            <BackIcon />
            <Typography variant="p2" fontWeight={700} color="grey.800" ml={1}>
              {t("common.buttons.back")}
            </Typography>
          </Button>
          <Typography variant="h3" color="grey.900" whiteSpace="wrap" ml={2}>
            {attachment?.fileName ?? ""}
          </Typography>
          <Box ml="auto" display="flex" alignItems="center">
            <Button variant="outlined" onClick={handleDownload} size="large">
              <DownloadIcon />
              <Typography variant="p2" fontWeight={700} color="grey.800" ml={1}>
                {t("common.buttons.download")}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box
          height="600px"
          width="100%"
          mt={3}
          overflow="hidden"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {previewUrl ? (
            <img
              alt="preview of the file"
              style={{
                objectFit: "contain",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
              src={previewUrl}
              itemType={attachment?.mimeType ?? "png"}
            />
          ) : (
            <CenteredLoadingIndicator />
          )}
        </Box>
        {attachment?.description && (
          <Typography variant="p2" mt={3}>
            {attachment.description}
          </Typography>
        )}
        <Box display="flex" flexDirection="column" mt={3}>
          {attachment?.dateCreated && contractTimezone && (
            <Box>
              <DateWithTimeTooltip
                datetime={attachment.dateCreated}
                timezone={contractTimezone}
                variant="p3"
                color="grey.500"
              />
            </Box>
          )}
          <Typography color="grey.800" fontWeight="600" variant="p2" mt={1}>
            {creatorName}
          </Typography>
          <Typography color="grey.600" fontWeight="600" variant="p2">
            {creatorCompany}
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
