import Resizer from "react-image-file-resizer";

export const resizeFile = (
  file: File,
  maxWidth = 1080,
  maxHeight = 1080
): Promise<{ file: File; dataUrl: string }> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      100,
      0,
      (result) => {
        const fileReader = new FileReader();
        const toReturn: { file: File; dataUrl: string } = {
          file: result as File,
          dataUrl: "",
        };

        fileReader.addEventListener(
          "load",
          (evt) => {
            toReturn.dataUrl = evt.target!.result! as string;

            resolve(toReturn);
          },
          false
        );

        fileReader.readAsDataURL(result as File);
      },
      "file"
    );
  });
