import { ActionsDialogProps } from "components/ActionsDialog";
import {
  ClaimActionType,
  ClaimInterimClaimDetailsPrompt,
} from "generated/graphql";
import { DetailedClaimAgreementDeterminationSummary } from "../components/DetailedClaimAgreementDeterminationSummary";
import { useSendInterimDetailedClaimAction } from "./useSendInterimDetailedClaimAction";
import { SendAction } from "../components/SendAction";

export type SendInterimDetailedClaimActionProps = Pick<
  ClaimInterimClaimDetailsPrompt,
  "draftDetailedClaims"
> &
  ActionsDialogProps;

export const SendInterimDetailedClaimAction: React.FC<
  SendInterimDetailedClaimActionProps
> = ({ draftDetailedClaims, ...restDialogProps }) => {
  const { claimPreview, contract } = useSendInterimDetailedClaimAction(
    draftDetailedClaims[0]
  );
  return (
    <SendAction
      claimPreview={claimPreview}
      actionType={ClaimActionType.SubmitInterimDetailedClaim}
      summaryEl={
        <DetailedClaimAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftDetailedClaims}
          type="DetailedClaim"
        />
      }
      {...restDialogProps}
    />
  );
};
