import { Newspaper } from "phosphor-react";
import { BasicModal, BasicModalProps } from "../BasicModal/BasicModal";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

export const TermsOfUseModal: React.FC<BasicModalProps> = (modalProps) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      headerIcon={<Newspaper size={24} />}
      {...modalProps}
      title={t("TermsOfUse.termsOfUse")}
      titleNextToIcon
      maxWidth="md"
      onClose={modalProps.onClose}
    >
      <Box>
        <div>
          <div>
            <Typography variant="h2" color="grey.900" fontWeight={600}>
              Terms of Use
            </Typography>
            <Typography variant="h3" color="grey.900" fontWeight={500}>
              Updated 20 May 2024
            </Typography>
          </div>
          <ol>
            <li id="li-1">
              <h2>Introduction and Legal Terms</h2>
              <ol>
                <li>
                  <p>
                    At Contract Communicator Systems SA (Pty) Ltd (“
                    <strong>Contract Communicator</strong>”) we offer contract
                    and project management software for the construction
                    industry (the “<strong>Services</strong>”). By accessing or
                    using our application, C-COM or our platform,
                    https://app.ccom.cloud or any related software
                    (collectively, “<strong>the Platform</strong>”) you agree
                    that you have read, understood, and agree to be bound to the
                    terms and conditions contained herein (“
                    <strong>Terms</strong>”). All rights in and to the content
                    of the Platform always remain expressly reserved by Contract
                    Communicator.
                  </p>
                </li>
                <li>
                  <p>
                    These Terms explain the conditions applicable to how you
                    will use the Platform. Please read these Terms carefully
                    before using the Platform or our Services. We will assume
                    you have read and understood these Terms if you continue to
                    access or make use of our Platform.
                  </p>
                </li>
                <li>
                  <p>
                    Please pay specific attention to the{" "}
                    <strong>BOLD paragraphs</strong> of these Terms. These
                    paragraphs limit the risk or liability of Contract
                    Communicator, constitute an assumption of risk or liability
                    by you, impose an obligation on you to indemnify Contract
                    Communicator or is an acknowledgement of any fact by you.
                  </p>
                </li>
                <li>
                  <p>
                    The terms "user", “you" and “your” are used interchangeably
                    in these Terms and accordingly refer to all clients
                    accessing the Platform for any reason whatsoever.
                    Accordingly, the terms “us”, “our” or “we” refers to
                    Contract Communicator or its possession.
                  </p>
                </li>
              </ol>
            </li>
            <li id="li-2">
              <h2>Our Services</h2>
              <ol>
                <li>
                  <p>
                    Our Platform offers you the ability to manage all your
                    ongoing projects in one convenient place and utilize our
                    various products.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Our core product offering includes the following products:
                    </strong>
                  </p>
                  <ol>
                    <li>
                      <p>
                        Risks - Provides a basis for risk identification,
                        management, and reporting.
                      </p>
                    </li>
                    <li>
                      <p>
                        Events - Allows reporting of adverse or unplanned events
                        likely to incur delay or cost.
                      </p>
                    </li>
                    <li>
                      <p>
                        Early Warnings - Facilitates raising of Early Warnings.
                      </p>
                    </li>
                    <li>
                      <p>
                        Instructions - Enables the project manager to issue
                        instructions to the contractor and track consequential
                        cost and time impact.
                      </p>
                    </li>
                    <li>
                      <p>
                        Compensation Events – Facilitates administration of
                        compensation events in compliance with the NEC
                        conditions of contract.
                      </p>
                    </li>
                    <li>
                      <p>
                        Daily Diary – Facilitates compilation, sending and
                        review of daily diaries.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <strong>Additional Add-On Services:</strong>
                  </p>
                  <ol>
                    <li>
                      <p>
                        Additional Seat - For project plans with limited seats
                        you pay a monthly fee per additional seat for the
                        project.
                      </p>
                    </li>
                    <li>
                      <p>
                        Additional Contract - For project plans with limited
                        contracts you pay a monthly fee per additional active
                        contract exceeding your project plan’s limit.
                      </p>
                    </li>
                    <li>
                      <p>
                        Bespoke contracts facility - Turn on bespoke contracts
                        facility to enable support for bespoke conditions of
                        contract for the project. Any number of bespoke
                        contracts can be added to the project once the bespoke
                        contracts facility is enabled (plan’s maximum number of
                        contracts allowance applies).
                      </p>
                    </li>
                    <li>
                      <p>
                        Authorisation workflows facility - Turn on authorization
                        workflows facility to enforce your corporate policy
                        and/or delegations of authority for contractual
                        acceptances of cost or time changes. Project managers or
                        delegates initiate acceptances, but these acceptances
                        are only executed if authorization is obtained from
                        relevant persons.
                      </p>
                    </li>
                    <li>
                      <p>
                        Custom schemas facility - Pay a monthly fee to utilize
                        custom schemas on your project.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <strong>Support Services:</strong>
                  </p>
                  <ol>
                    <li>
                      <p>
                        Support - In addition to the self-help documentation and
                        videos, we offer live chat and email assistance for
                        active users on C-COM.
                      </p>
                    </li>
                    <li>
                      <p>
                        Training – Virtual group training session with a
                        Contract Communicator product specialist to onboard new
                        users.
                      </p>
                    </li>
                    <li>
                      <p>
                        Contract setup - We can setup contracts for your project
                        on your behalf instead of you doing it yourself using
                        the self-admin facilities. Our helpdesk will send you a
                        list of required information and complete the setup of a
                        contract for you.
                      </p>
                    </li>
                    <li>
                      <p>
                        Recording offline items - We record contractual
                        correspondence exchanged prior to C-COM’s
                        implementation.
                      </p>
                    </li>
                    <li>
                      <p>Customization</p>
                      <ol>
                        <li>
                          <p>
                            Custom schemas: We setup a custom schema for you
                            with additional, removed or renamed fields for
                            describing items like Risks, Early Warnings or
                            Instructions. Once we’ve built a custom schema you
                            can use it on any projects with custom schemas
                            facility enabled.
                          </p>
                        </li>
                        <li>
                          <p>
                            Bespoke contracts: If you need to manage a contract
                            based on bespoke conditions, we can develop support.
                            Once we’ve built support for your bespoke conditions
                            you can setup contracts based on these conditions on
                            any projects with bespoke contracts facility
                            enabled.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>
                        Advisory - We consult with you to design and execute a
                        digital contract management strategy.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Any and all Services and the associated payment terms are at
                    all times subject to the Master Service Agreement (“
                    <strong>MSA</strong>”) entered between a client and Contract
                    Communicator. In any circumstance where there may be a
                    conflict between these Terms and the MSA, the provisions of
                    the MSA will prevail.
                  </p>
                </li>
              </ol>
            </li>
            <li id="li-3">
              <h2>Payment for Services</h2>
              <ol>
                <li>
                  <p>Packages</p>
                  <ol>
                    <li>
                      <p>
                        C-COM is licensed per project. We offer three packages:
                        Core; Pro and Enterprise.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>General Payment Terms</p>
                  <ol>
                    <li>
                      <p>
                        On the 25<sup>th</sup>day of each calendar month during
                        the subscription period and in advance, we will provide
                        you with an invoice for:
                      </p>
                      <ol>
                        <li>
                          <p>
                            the subscription fee and/or add-ons selected for
                            each project on C-COM, which fees shall be charged
                            in advance; and
                          </p>
                        </li>
                        <li>
                          <p>
                            the supporting service fee and/or any other
                            applicable costs &nbsp;which will be charged in
                            arrears if you have requested any supporting
                            services or incurred other applicable costs during
                            the month immediately preceding the date of the
                            Invoice.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>
                        All invoices are payable within a period of 30 (thirty)
                        days after presentation. Invoices must be paid via
                        electronic funds transfer (EFT) into a bank account
                        nominated by Contract Communicator for that purpose in
                        the relevant invoice.
                      </p>
                    </li>
                    <li>
                      <p>
                        VAT and any other applicable taxes shall be added to the
                        invoice if such VAT and/or taxes apply.
                      </p>
                    </li>
                    <li>
                      <p>
                        You understand and agree that Contract Communicator
                        shall be entitled, in our sole discretion, to escalate
                        our fees by a maximum of 5% (five percent) on 1 March of
                        every year during any subscription period.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Refunds And Returns</p>
                  <p>
                    Unless otherwise required by applicable laws,{" "}
                    <strong>
                      Contract Communicator does not refund any fee which has
                      been paid for any package.
                    </strong>{" "}
                    This also means that we will not return any fee already paid
                    where you terminate your use of the Platform before the paid
                    subscription period has lapsed entirely or where your
                    account has been terminated for violating these Terms.
                  </p>
                </li>
              </ol>
            </li>
            <li id="li-4">
              <h2>Responsibilities and Warranties</h2>
              <ol>
                <li>
                  <p>
                    By using the Platform and/or the Services, you warrant that:
                  </p>
                  <ol>
                    <li>
                      <p>
                        you have read and agreed to these Terms and will use the
                        Platform and Services in accordance with them;
                      </p>
                    </li>
                    <li>
                      <p>
                        you have not made any misrepresentations and the
                        information provided in the registration process about
                        you, your company and/or your status is true, accurate
                        and complete in every aspect;
                      </p>
                    </li>
                    <li>
                      <p>
                        you are above the age of 18 (eighteen) years old and
                        have the legal capacity to understand, agree with and be
                        bound with these Terms;
                      </p>
                    </li>
                    <li>
                      <p>
                        you lawfully possess and submit all information to
                        Contract Communicator for the use of the Platform or the
                        Services and warrant that you have the consent of any
                        third party to any contract or project to share such
                        information with Contract Communicator and indemnify
                        Contract Communicator against any third party claims
                        that may arise in this regard;
                      </p>
                    </li>
                    <li>
                      <p>
                        you will not post, upload, replicate or transmit any
                        abusive content on the Platform that is or could
                        reasonably be considered to be obscene, pornographic,
                        threatening, harassing, defamatory, abusive, racist,
                        sexist,discriminatory, in breach of confidence, or in
                        breach of privacy;
                      </p>
                    </li>
                    <li>
                      <p>
                        you will not send any unsolicited electronic messages or
                        use any software, routine or device to interfere or
                        attempt to interfere electronically or manually with the
                        operation or functionality of the Platform or the
                        Services including but not limited to uploading or
                        making available files containing corrupt data or
                        viruses via whatever means or deface, alter or interfere
                        with the front end ‘look and feel’ of the Platform or
                        the underlying software code;
                      </p>
                    </li>
                    <li>
                      <p>
                        you will not infringe the intellectual property or other
                        rights of any third party or the Platform or transmit
                        content that you do not own or do not have the right to
                        publish or distribute;
                      </p>
                    </li>
                    <li>
                      <p>
                        you will not use the Platform/Services for any
                        commercial purpose otherthan as expressly provided for
                        by Contract Communicator herein;
                      </p>
                    </li>
                    <li>
                      <p>
                        you will not use the Platform to breach any applicable
                        law or regulation or perform or encourage any illegal
                        activity including (without limitation) promoting or
                        facilitating money laundering or financial crimes;
                        and/or
                      </p>
                    </li>
                    <li>
                      <p>
                        you will not facilitate or assist any third party to do
                        any of the above.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Failure to comply with clause 4.1 will automatically and
                    immediately be deemed to be a material breach of these
                    Terms, allowing us to manifest our rights in the case of
                    breach, including but not limited to denying you access to
                    the Platform/Services, reporting your actions to an
                    applicable authority, demanding specific performance and/or
                    suing you for damages.
                  </p>
                  <ol></ol>
                </li>
                <li>
                  <p>
                    The Platform is only available on compatible devices
                    connected to the internet. It is your responsibility to
                    obtain these devices and any connectivity necessary to
                    usethe Platform.
                  </p>
                  <ol></ol>
                </li>
                <li>
                  <p>
                    <strong>
                      Without prejudice to any of our other rights (whether at
                      law or otherwise), we reserve the right to deny you access
                      to the Platform/Services where we believe (in our
                      reasonable discretion) that you are in breach of any of
                      these Terms.
                    </strong>
                  </p>
                  <ol></ol>
                </li>
                <li>
                  <p>
                    We do not guarantee that the Platform/Services, or any
                    portion thereof, will function on any particular hardware or
                    device.
                  </p>
                  <ol></ol>
                </li>
              </ol>
            </li>
            <li id="li-5">
              <h2>Messages and Advertising</h2>
              <ol>
                <li>
                  <p>Data Messages between You and Contract Communicator</p>
                  <ol>
                    <li>
                      <p>
                        Data messages, including email messages, you send to us
                        will be considered as received only when we acknowledge
                        or responded to these messages.
                      </p>
                    </li>
                    <li>
                      <p>
                        Data messages we send to you will be regarded as
                        received when the data message enters your email sever
                        inbox and is capable of being retrieved and processed by
                        you.
                      </p>
                    </li>
                    <li>
                      <p>
                        We reserve the right not to respond to any email or
                        other data message that contains obscene, threatening,
                        defamatory or otherwise illegal, unlawful, or
                        inappropriate content, and to take appropriate action
                        against the sender of such email or data message if
                        necessary.
                      </p>
                    </li>
                    <li>
                      <p>
                        Messages sent over the internet cannot be guaranteed to
                        be completely secure as they can be intercepted, lost,
                        or corrupted. We are therefore not responsible for the
                        accuracy or safety of any message sent by email or over
                        the internet.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Hyperlinks, Deep Links, Framing</p>
                  <ol>
                    <li>
                      <p>
                        The Platform may include links to other websites ("
                        <strong>other sites</strong>"). Contract Communicator
                        does not own or endorse these other sites and is not
                        responsible for the information, material, products, or
                        services contained on or accessible through these other
                        sites. Any hyperlinks do not imply any endorsement,
                        agreement on or support of the content or products of
                        these other sites.
                      </p>
                    </li>
                    <li>
                      <p>
                        We do not own the content on any other site which may be
                        shown on the Platform. Should the owner of any content
                        showcased on the Platform want the content to be
                        removed, please contact us to request the removal of
                        such content.
                      </p>
                    </li>
                    <li>
                      <p>
                        Your access and use of the other sites remain solely at
                        your own risk and on the terms set by the operator of
                        any other site.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li id="li-6">
              <h2>Intellectual Property and Confidentiality</h2>
              <ol>
                <li>
                  <p>
                    All website layout, website content, material, information,
                    data, software, icons, text, graphics,layouts, images, sound
                    clips, advertisements, video clips, user interface design
                    and layout, trade names, logos, trademarks, designs,
                    copyright and/or service marks, together with the underlying
                    software code, (“<strong>the intellectual property</strong>
                    ”) are owned (or co-owned or licenced, as the case may be)
                    by Contract Communicator, itsshareholders, associates and/or
                    partners, whether directly or indirectly, andas such, are
                    protected from infringement by domestic and
                    internationallegislation and treaties.
                  </p>
                </li>
                <li>
                  <p>
                    All rights to any intellectual property provided by you to
                    the Platform will remain with you, but for which you have
                    provided us with a non-exclusive, non-transferable licenceto
                    use such intellectual property to provide you with our
                    Services.
                  </p>
                </li>
                <li>
                  <p>
                    Subject to the rights afforded to you in these Terms, all
                    other rights to all intellectual propertyon the Platform and
                    contained within the Services are expressly reserved.{" "}
                    <strong>
                      You may not copy, download, print, modify, alter, publish,
                      broadcast, distribute, sell, or transfer any intellectual
                      property, editorial content, graphics or other material or
                      the underlying software code whether in whole or in part,
                      without the written consent of Contract Communicator first
                      being granted, which consent may be refused at the
                      discretion of Contract Communicator. No modification of
                      any intellectual property or graphics is permitted. Should
                      you breach these provisions, Contract Communicator and/or
                      the rightful intellectual property rights owner may launch
                      legal proceedings against you for a breach of contract,
                      resulting in a claim of damages against you.
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    We reserve the right to make improvements or changes to the
                    intellectual property, information, videos, graphics, and
                    other materials on the Platform/Services, or to suspend or
                    terminate the Platform, at any time without notice; provided
                    that any transactions or functions already concluded, will
                    not be affected by such suspension or termination (as the
                    case may be).
                  </p>
                </li>
                <li>
                  <p>
                    Where any intellectual property has been licensed to us or
                    belongs to any third party all rights of use will also be
                    subject to any terms and conditions which that licensor or
                    third party imposes from time to time, and you agree to
                    comply with such third-party terms and conditions.
                  </p>
                </li>
                <li>
                  <p>
                    Subject to adherence to the Terms, we grant to you a
                    personal, non-exclusive, non-assignable and non-transferable
                    license to use and display all content and information on
                    any machine of which you are the primary user. However,
                    nothing contained on the Platform or in these Terms should
                    be construed as granting any licence or right to use any
                    intellectual property without the prior written permission
                    of Contract Communicator first being granted.
                  </p>
                </li>
                <li>
                  <p>
                    All information made available by you to us when using the
                    Platform will be held in strictest confidence and shall
                    never be disclosed, used, or exploited by us unless such
                    information is already made public, or we are required to
                    disclose same by law or court order. Confidentiality under
                    these Terms such survive the termination of our agreement
                    for any reason.
                  </p>
                </li>
              </ol>
            </li>
            <li id="li-7">
              <h2>Indemnities, Disclaimers, and Warranties</h2>
              <ol>
                <li>
                  <p>Disclaimers</p>
                  <ol>
                    <li>
                      <p>
                        The Platform and Services, including intellectual
                        property appearing therein, are provided "as is" and "as
                        available". We make no representations or warranties,
                        express or implied, including but not limited to
                        warranties as to the accuracy, correctness, or
                        suitability of either the Platform or the Services.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          Contract Communicator, its shareholders, directors,
                          employees, and partners, accept no liability
                          whatsoever for any loss, whether direct or indirect,
                          consequential,or arising from information made
                          available on (or by means of) the Platform or from the
                          Services offered.
                        </strong>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          Contract Communicator, its shareholders, directors,
                          employees, partners, and affiliates, accept no
                          liability whatsoever for any costs, expenses, fines,
                          or damages, including but not limited to direct or
                          indirect loss or damages, including any economic loss,
                          consequential loss, loss of profits or any form of
                          punitive damages, resulting from the facilitation and
                          offering of the Services, and access to, or use of,
                          the Platform in any manner.{" "}
                        </strong>
                      </p>
                    </li>
                    <li>
                      <p>
                        We take reasonable security measures to ensure the
                        safety and integrity of the Platform and to exclude
                        viruses, unlawful monitoring and/or access from the
                        Platform offline. However, we do not warrant or
                        represent that your access to the Platform will be
                        uninterrupted or error-free or that any information,
                        data, content, software,or other material accessible
                        through the Platform will be free of bugs, viruses,
                        worms, trojan horses or other harmful components. Your
                        access to and use of the Platform remains solely at your
                        own risk, and you should take your own precautions
                        accordingly.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Indemnities</p>
                  <ol>
                    <li>
                      <p>
                        <strong>
                          You indemnify and hold harmless Contract Communicator,
                          its shareholders, employees, and partners from any
                          demand, action or application or other proceedings,
                          including for attorneys’ fees and related costs such
                          as tracing fees, made by any third party, and arising
                          out of or in connection with your use of the Platform
                          and/or Services offered or concluded through the
                          Platform in any way.
                        </strong>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          You agree to indemnify, defend, and hold Contract
                          Communicator harmless from any direct or indirect
                          liability, loss, claim and expense (including
                          reasonable legal fees) related to your breach of these
                          Terms.
                        </strong>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>This clause will survive termination of these Terms.</p>
                </li>
              </ol>
            </li>
            <li id="li-8">
              <h2>Dispute Resolution</h2>
              <ol>
                <li>
                  <p>
                    Negotiation - Should any dispute, disagreement or claim
                    arise between you and Contract Communicator concerning the
                    use of the Platform or the Services, the parties shall
                    endeavour to resolve the dispute amicably, by negotiation,
                    and with the best interests of both parties in mind.
                  </p>
                </li>
                <li>
                  <p>
                    Mediation - Should these parties fail to resolve such
                    dispute in the aforesaid manner or within such further
                    period as the parties may agree to in their negotiation, the
                    parties will approach an independent industry expert who
                    shall mediate the discussions between them to find a
                    mutually beneficial solution.
                  </p>
                </li>
                <li>
                  <p>
                    Arbitration - If the dispute is still not resolved after
                    such mediation, the parties will commence and be party to
                    binding and confidential arbitration in terms of the
                    expedited rules of the Arbitration Foundation of Southern
                    Africa (“<strong>AFSA</strong>”), with an arbitrator
                    selected by Contract Communicator.
                  </p>
                </li>
                <li>
                  <p>
                    Jurisdiction - Notwithstanding the above, both parties'
                    consent to the jurisdiction of an appropriate South African
                    court. Either party may also always use the dispute
                    resolution services of any applicable legislative tribunal
                    or ombud, as provided for in applicable legislation.
                  </p>
                </li>
                <li>
                  <p>
                    No publication - The parties both agree that in no
                    circumstance will either party publicise the dispute on any
                    social media or other public Platforms. The parties
                    understand that any publicity of this nature can cause
                    serious damage to the other party, which damage may result
                    in a financial claim against the infringing party.
                  </p>
                </li>
              </ol>
            </li>
            <li id="li-9">
              <h2>Termination of Use</h2>
              <ol>
                <li>
                  <p>
                    <strong>
                      IN ADDITION TO OUR OTHER RIGHTS HEREIN, WE RESERVE THE
                      RIGHT TO RESTRICT AND/OR TERMINATE YOUR USE OF THE
                      PLATFORM IF YOU BREACH ANY OF THESE TERMS, OR FOR ANY
                      OTHER REASON IN OUR SOLE DISCRETION PROVIDED THAT WE GIVE
                      REASONABLE NOTICE TO YOU
                    </strong>
                    <strong>.</strong>
                  </p>
                </li>
                <li>
                  <p>
                    If you wish to terminate your agreement with us and these
                    Terms, you may do so by providing us with a calendar month’s
                    notice of your intention to terminate. Such termination will
                    however not have any effect on the continued and
                    comprehensive functioning or legitimacy of any lawful rights
                    which we may have at the time of said termination.
                  </p>
                </li>
              </ol>
            </li>
            <li id="li-10">
              <h2>Notices and Service Address</h2>
              <ol>
                <li>
                  <p>
                    Each of the parties chooses their service address for the
                    purposes of the giving of any notice, the serving of any
                    process and for any other purposes arising from these Terms
                    as being:
                  </p>
                  <ol>
                    <li>
                      <p>
                        in the case of Contract Communicator, at
                        info@ccom.online; or
                      </p>
                    </li>
                    <li>
                      <p>
                        in the case of the Client, at the e-mail, cellphone
                        number, and addresses provided to us.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Each of the parties will be entitled from time to time, by
                    written notice to the other to vary its service address to
                    any other address which is not a post office box or poste
                    restante, provided that the change will become effective
                    only 14 (fourteen) days after service of the notice in
                    question.
                  </p>
                </li>
                <li>
                  <p>
                    Notwithstanding the above, any notice given in writing in
                    English, and actually received by the party to whom the
                    notice is addressed, will be deemed to have been properly
                    given and received, notwithstanding that such notice has not
                    been given in accordance with this clause.
                  </p>
                </li>
              </ol>
            </li>
            <li id="li-11">
              <h2>Company Information</h2>
              <ol>
                <li>
                  <p>
                    <strong>Site owner: </strong>ContractCommunicator Systems SA
                    (Pty) Ltd
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Legal status: </strong>Private Company
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Registration number: </strong>2010/011756/07
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Description of business: </strong>Software Company
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Telephone number: </strong>+27 11 519 8960
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Email address: </strong>info@ccom.online
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Platform address: </strong>https://app.ccom.cloud
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Physical address: </strong>24 Cradock
                    Avenue,Rosebank, Johannesburg, South Africa, 2196
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Postal address: </strong>Suit 695, Private Bag X29,
                    Gallo Manor, 2052
                  </p>
                </li>
              </ol>
            </li>
            <li id="li-12">
              <h2>General</h2>
              <ol>
                <li>
                  <p>
                    Relationship Between the Parties - The relationship of the
                    parties, <em>inter se</em>, shall be governed by these Terms
                    and nothing contained herein shall be deemed to constitute a
                    partnership, joint venture, employer/employee agreement,
                    agency agreement, or the like between them. No party shall
                    by the reasons of the actions of the other party incur any
                    personal liability as co-partner to any third party.
                  </p>
                </li>
                <li>
                  <p>
                    Force Majeure - If either party is prevented, whether in
                    whole or in part, or delayed from performing any of its
                    duties, functions or obligations under these Terms,whether
                    timeously or at all, due to an event out of their control
                    (which for the purposes hereof shall mean war, political
                    riots, civil commotions, electrical load-shedding or surges,
                    legal prohibitions or restrictions, epidemics, pandemics,
                    governmental lockdowns, fire, floods or other similar
                    natural disasters), then such failure shall not constitute a
                    breach under these Terms, and the obligation to perform
                    shall be suspended to the extent and during the continuance
                    of such prevention provided that the parties shall use their
                    reasonable commercial endeavours to minimise any delay
                    occasioned
                  </p>
                </li>
                <li>
                  <p>
                    Change Without Notice - The Platform and these Terms are
                    subject to change without notice. These Terms are updated or
                    amended from time to time and will be effective once we
                    upload the amended Terms to the Platform. Your continued
                    access or use of the Platform constitutes your acceptance to
                    be boundby these Terms, as amended. It is your
                    responsibility to read these Terms periodically to ensure
                    you are aware of any changes.
                  </p>
                </li>
                <li>
                  <p>
                    Entire Agreement - This document contains the entire
                    agreement between the parties in relation to the subject
                    matter hereof, unless the parties have entered into the MSA,
                    in which regard the provisions of the MSA will prevail over
                    these Terms. Save as contemplated in clause 12.3, no
                    alteration, cancellation, variation of, or addition hereto
                    will be of any force or effect unless reduced to writing and
                    signed by all the parties to these Terms or their duly
                    authorised representatives.
                  </p>
                </li>
                <li>
                  <p>
                    No Indulgence - No indulgence, leniency or extension of time
                    granted by Contract Communicator shall constitute a waiver
                    of any of Contract Communicator’s rights under these Terms
                    and, accordingly, Contract Communicator shall not be
                    precluded as a consequence of having granted such
                    indulgence, from exercising any rights against the client
                    which may have arisen in the past or which might arise in
                    the future.
                  </p>
                </li>
                <li>
                  <p>
                    Importation of Words - Words importing the singular will
                    include the plural and vice versa. Words importing one
                    gender will include the other genders, and words importing
                    persons will include partnerships, trusts, and bodies
                    corporate, and vice versa.
                  </p>
                </li>
                <li>
                  <p>
                    Headings as Reference - The headings tothe paragraphs in
                    these Terms are inserted for reference purposes only and
                    willnot affect the interpretation of any of the provisions
                    to which they relate.
                  </p>
                </li>
                <li>
                  <p>
                    Governing Law - Your access and/or use of the Platform
                    and/or the Services, any downloaded material from it and the
                    operation of these Terms (including any transaction
                    concluded pursuant thereto) shall be governed by and
                    construed in accordance with the laws of the Republic of
                    South Africa.
                  </p>
                </li>
                <li>
                  <p>
                    Failure to Pay - In the event of a client failing to pay any
                    amount timeously or breaching these Terms, the client shall
                    be liable for all legal costs (on the scale as between
                    attorney and client) (including collection commission) which
                    may be incurred by Contract Communicator in relation to the
                    payment failure or breach.
                  </p>
                </li>
                <li>
                  <p>
                    Severability - Each sentence, paragraph, term, clause and
                    provision of these Terms and any portion thereof shall be
                    considered severable and if for any reason, any such
                    sentence, paragraph, term, clause or provision is held to be
                    invalid, contrary to, or in conflict with any applicable
                    present or future law or regulation or in terms of a final,
                    binding judgment issued by any court, it shall to that
                    extent be deemed not to form part hereof and shall not
                    impair the operation of, or have any effect upon such other
                    sentence, paragraph, term, clause or provision hereof as may
                    otherwise remain valid or intelligible, which shall continue
                    to be given full force and effect and bind the parties
                    hereto.
                  </p>
                </li>
                <li>
                  <p>
                    Prohibited Provision - No term or condition of these Terms
                    is intended to breach any peremptory provisions of any
                    consumer protection legislation and any regulations thereto
                    ("<strong>Prohibited Provision</strong>"). Any breach of any
                    such Prohibited Provision shall be governed by the
                    provisions of clause 12.10.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Box>
    </BasicModal>
  );
};
