import { gql } from "@apollo/client";
import { userPreferencesValueFields } from "./userPreferencesValue.fragment";

export const variationRecipientFields = gql`
  ${userPreferencesValueFields}

  fragment VariationRecipientFields on VariationRecipient {
    userId
    name
    preference {
      ...UserPreferencesValueFields
    }
  }
`;
