import { useParams, useSearchParams } from "react-router-dom";

export const useExplorerUrlEntities = () => {
  const [searchParams] = useSearchParams();
  const projectIdQueryParam = searchParams.get("projectId");
  const contractIdQueryParam = searchParams.get("contractId");
  const productIdQueryParam = searchParams.get("productId");
  const productInstanceIdQueryParam = searchParams.get("productInstanceId");

  const {
    projectId: projectIdUrlParam,
    contractId: contractIdUrlParam,
    productId: productIdUrlParam,
    productInstanceId: productInstanceIdUrlParam,
  } = useParams();

  return {
    projectId: projectIdQueryParam ?? projectIdUrlParam,
    contractId: contractIdQueryParam ?? contractIdUrlParam,
    productId: productIdQueryParam ?? productIdUrlParam,
    productInstanceId: productInstanceIdQueryParam ?? productInstanceIdUrlParam,
  };
};
