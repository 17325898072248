import { useVariationProductInstance } from "containers/Projects/hooks/useVariationProductInstance";
import { Contract } from "generated/graphql";

export const useVariationWidget = (contract: Contract) => {
  const { variationProductInstance, loading: variationProdInstanceLoading } =
    useVariationProductInstance(contract.id);

  return {
    loading: variationProdInstanceLoading,
    variationProductInstance,
  };
};
