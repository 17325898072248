import { Box, useTheme } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ItemTag } from "components/ItemTag";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { VariationItem, Contract, ProductType } from "generated/graphql";
import {
  datetimeComparatorFn,
  statusComparatorFunction,
} from "helpers/dataGrid.helpers";
import { variationStatusOptions } from "../VariationsView.constants";
import { VariationItemPreview } from "../VariationItemPreview";
import { OverflowTooltip } from "components/OverflowTooltip";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { PriceTag } from "containers/Projects/components/HistoryModal/PriceTag";
import { TimeTag } from "containers/Projects/components/HistoryModal/TimeTag";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";

export const useColumns = (
  contract?: Contract
): GridColDef<VariationItem>[] => {
  const theme = useTheme();
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        field: "number",
        headerName: t("Projects.Variations.variationNumber"),
        flex: 0.1,
        minWidth: 150,
        resizable: true,
        renderCell: (params) => {
          return (
            <OverflowTooltip
              title={params.value}
              typographyProps={{
                variant: "p2",
                color: "text.primary",
              }}
            />
          );
        },
      },
      {
        field: "type",
        headerName: t("common.labels.origin"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        valueGetter: (_, row) => t(`Projects.Variations.types.${row.type}`),
      },
      {
        field: "status",
        headerName: t("common.labels.status"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        sortComparator: statusComparatorFunction,
        valueGetter: (_, row) => row.status,
        renderCell: (params: GridRenderCellParams<VariationItem, any, any>) => {
          return (
            <StatusTagNew
              selectedOptionId={params.row.status}
              disabled
              options={variationStatusOptions}
            />
          );
        },
      },
      {
        field: "priceSought",
        headerName: t("Projects.Variations.priceSought"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        valueGetter: (_, row) => row.priceSought,
        renderCell: (
          params: GridRenderCellParams<
            VariationItem,
            number | undefined | null,
            any
          >
        ) => (
          <PriceTag
            value={params.value}
            currency={contract?.valueCurrency}
            sx={{ width: "100%" }}
          />
        ),
      },
      {
        field: "eotSought",
        headerName: t("Projects.Variations.eotSought"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        valueGetter: (_, row) => row.timeSought,
        renderCell: (
          params: GridRenderCellParams<
            VariationItem,
            number | undefined | null,
            any
          >
        ) => <TimeTag value={params.value} sx={{ width: "100%" }} />,
      },
      {
        field: "priceGranted",
        headerName: t("Projects.Variations.priceGranted"),
        flex: 0.1,
        width: 150,
        resizable: true,
        valueGetter: (_, row) => row.priceGranted,
        renderCell: (
          params: GridRenderCellParams<
            VariationItem,
            number | undefined | null,
            any
          >
        ) => (
          <PriceTag
            value={params.value}
            currency={contract?.valueCurrency}
            sx={{ width: "100%" }}
          />
        ),
      },
      {
        field: "eotGranted",
        headerName: t("Projects.Variations.eotGranted"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        valueGetter: (_, row) => row.timeGranted,
        renderCell: (
          params: GridRenderCellParams<
            VariationItem,
            number | undefined | null,
            any
          >
        ) => <TimeTag value={params.value} sx={{ width: "100%" }} />,
      },
      {
        field: "lastAction",
        headerName: t("Projects.Variations.lastAction"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        sortComparator: datetimeComparatorFn,
        valueGetter: (_, row) => row.lastAction.date,
        renderCell: (params) => {
          return (
            <DateWithTimeTooltip
              datetime={params.value}
              timezone={params.row.productInstance.contract.timeZone}
              color="grey.600"
              variant="p2"
            />
          );
        },
      },
      {
        field: "regarding",
        headerName: t("common.labels.regarding"),
        flex: 0.35,
        minWidth: 225,
        resizable: true,
        sortable: true,
        valueGetter: (_, row) => row.regardingInstruction?.title ?? "",
        renderCell: (
          params: GridRenderCellParams<VariationItem, string, any>
        ) => (
          <Box display="flex" alignItems="center" width="100%">
            <ItemTag type={ProductType.Instructions} />
            <OverflowTooltip
              title={params.value}
              typographyProps={{
                variant: "p2",
                ml: 1,
                color: theme.palette.grey[700],
              }}
            />
          </Box>
        ),
      },
      {
        field: "dateSent",
        headerName: t("common.labels.dateSent"),
        width: 125,
        resizable: true,
        sortComparator: datetimeComparatorFn,
        valueGetter: (_, row) => row.regardingInstruction?.dateCreated,
        renderCell: (params) => {
          return params.value ? (
            <DateWithTimeTooltip
              datetime={params.value}
              timezone={params.row.productInstance.contract.timeZone}
              color="grey.600"
              variant="p2"
            />
          ) : (
            "N/A"
          );
        },
      },
      {
        field: "options",
        headerName: "",
        width: 50,
        resizable: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<VariationItem, any, any>) => {
          return <VariationItemPreview variation={params.row} />;
        },
      },
    ],
    [theme, t, contract]
  );
};
