import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  ClaimActionType,
  ClaimDeterminationNoticePrompt,
} from "generated/graphql";
import { DetailedClaimAgreementDeterminationSummary } from "../components/DetailedClaimAgreementDeterminationSummary";
import { RecordAction } from "../components/RecordAction";
import { useRecordClaimDeterminationNoticeModal } from "./useRecordClaimDeterminationNoticeModal";

export type RecordClaimDeterminationNoticeModalProps = {
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Pick<ClaimDeterminationNoticePrompt, "draftClaimDeterminations"> &
  Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordClaimDeterminationNoticeModal: React.FC<
  RecordClaimDeterminationNoticeModalProps
> = ({ draftClaimDeterminations, ...restDialogProps }) => {
  const { claimPreview, contract } = useRecordClaimDeterminationNoticeModal(
    draftClaimDeterminations[0].id
  );
  return (
    <RecordAction
      actionType={ClaimActionType.NotifyDeterminationClaim}
      claimPreview={claimPreview}
      summaryEl={
        <DetailedClaimAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftClaimDeterminations}
          type="ClaimDetermination"
        />
      }
      {...restDialogProps}
    />
  );
};
