import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { schemaFieldFields } from "./schemaField.fragment";

export const schemaSectionFields = gql`
  ${creatorFields}
  ${schemaFieldFields}

  fragment SchemaSectionFields on SchemaSection {
    id
    name
    displayText
    displayOrder
    columnPlacement
    productSchemaId
    isInternal
    status
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
    schemaFields {
      items {
        ...SchemaFieldFields
      }
    }
  }
`;
