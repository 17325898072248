import { gql } from "@apollo/client";
import { draftQuotationFields } from "graphql/fragments/draftQuotation.fragment";

export const editDraftQuotationMutation = gql`
  ${draftQuotationFields}

  mutation editDraftQuotation($input: EditDraftQuotationInput!) {
    editDraftQuotation(input: $input) {
      ...DraftQuotationFields
    }
  }
`;
