import { Attachment } from "generated/graphql";
import { useCallback, useContext, useState } from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { FileMIMEType, FileType } from "../Attachments.decl";
import { isAttachment, stringToFileMIMEType } from "../Attachments.utils";
import { useAttachmentDownloadPresignedUrl } from "./useAttachmentDownloadPresignedUrl";
import { getUserName } from "helpers/miscelaneous";

export const useImagePreviewModal = (
  onDownload: (file: Attachment | FileType) => void
) => {
  const { authenticatedUser } = useContext(GlobalContext);
  const [previewUrl, setPreviewUrl] = useState("");
  const { getAttachmentDownloadPresignedUrl } =
    useAttachmentDownloadPresignedUrl();

  const [
    imageAttachmentPreviewModalVisible,
    setImageAttachmentPreviewModalVisible,
  ] = useState(false);
  const [imagePreviewData, setImagePreviewData] = useState<{
    creatorName: string;
    creatorCompany: string;
    attachment: Attachment | FileType;
  }>();

  const loadPresignedUrl = useCallback(
    async (fileUrl: string) => {
      const dwdUrl = await getAttachmentDownloadPresignedUrl(fileUrl);
      setPreviewUrl(dwdUrl ?? "");
    },
    [getAttachmentDownloadPresignedUrl]
  );

  const handleAttachmentClick = async (file: Attachment | FileType) => {
    if (stringToFileMIMEType(file.mimeType) === FileMIMEType.Img) {
      setImageAttachmentPreviewModalVisible(true);

      await loadPresignedUrl(file.fileUrl!);

      setImagePreviewData({
        attachment: file,
        creatorName: isAttachment(file)
          ? getUserName(file.creator)
          : getUserName(authenticatedUser),
        creatorCompany: isAttachment(file)
          ? file.creator.company.registeredName
          : authenticatedUser!.company.registeredName,
      });
    } else {
      onDownload(file);
    }
  };

  const closeModal = () => {
    setPreviewUrl("");
    setImageAttachmentPreviewModalVisible(false);
  };

  return {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal,
  };
};
