import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { projectLiteFields } from "graphql/fragments/projectLite.fragment";

export const projectQuery = gql`
  ${creatorFields}
  ${projectLiteFields}

  query project($id: ID!) {
    project(id: $id) {
      ...ProjectLiteFields
      contracts {
        items {
          id
          friendlyName
          name
          number
          country
          province
          timeZone
          status
          contractTypeId
          dateCreated
          creator {
            ...CreatorFields
          }
          startDate
          endDate
          contractType {
            description
            version
            subType
          }
        }
      }
    }
  }
`;

export const editProjectMutation = gql`
  ${creatorFields}

  mutation editProject($input: EditProjectInput!) {
    editProject(input: $input) {
      id
      name
      friendlyName
      number
      sector
      description
      status
      dateCreated
      creator {
        ...CreatorFields
      }
      contracts {
        items {
          id
          friendlyName
          number
          country
          province
          timeZone
          status
          contractTypeId
          dateCreated
          creator {
            ...CreatorFields
          }
        }
      }
    }
  }
`;
