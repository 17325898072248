import { gql } from "@apollo/client";
import { addressFields } from "./address.fragment";
import { creatorFields } from "./creator.fragment";

export const companyLiteFields = gql`
  ${creatorFields}
  ${addressFields}

  fragment CompanyLiteFields on Company {
    id
    type
    registeredName
    tradingName
    registrationNumber
    logo
    vatRegistrationNumber
    physicalAddress {
      ...AddressFields
    }
    postalAddress {
      ...AddressFields
    }
    status
    creatorId
    dateCreated
    creator {
      ...CreatorFields
    }
    # contracts {
    #   items {
    #     id
    #     name
    #     friendlyName
    #     status
    #   }
    # }
    # projects {
    #   items {
    #     id
    #     name
    #     friendlyName
    #     status
    #   }
    # }
    #contractBindings: ContractBindingList!
    #contracts: ContractList!
    #users: AllUsersResult!
    #projects: ProjectList!
  }
`;
