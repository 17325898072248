import { Box, CircularProgress, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useNavigateBack } from "hooks/useNavigateBack";
import {
  PageContentHeader,
  PageContentHeaderProps,
  PageContentHeaderSize,
} from "components/PageContentHeader/PageContentHeader";
import { CancelUpdateButtons } from "./CancelUpdateButtons";
import { useCallback, useImperativeHandle, useRef } from "react";

export type HeaderPublicApi = {
  focusMainActionBtn: () => void;
};

export enum NewEntityHeaderFontSize {
  Normal = "normal",
  Large = "large",
}

export type NewEntityHeaderProps = {
  entityName?: string;
  title?: React.ReactNode;
  fontSize?: NewEntityHeaderFontSize;
  generalLoading?: boolean;
  addActionLoading?: boolean;
  apiRef?: React.Ref<HeaderPublicApi>;
  onAdd: () => void;
  onCancel?: () => void;
} & Pick<PageContentHeaderProps, "size">;

export const NewEntityHeader: React.FC<NewEntityHeaderProps> = ({
  entityName,
  title,
  size = PageContentHeaderSize.Large,
  fontSize = NewEntityHeaderFontSize.Normal,
  generalLoading,
  addActionLoading,
  apiRef,
  onCancel,
  onAdd,
}) => {
  const { t } = useTranslation();
  const handleNavigateBack = useNavigateBack();
  const addBtnRef = useRef<HTMLButtonElement>(null);

  const focusMainActionBtn = useCallback(() => {
    addBtnRef.current?.focus();
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      focusMainActionBtn,
    }),
    [focusMainActionBtn]
  );

  return (
    <PageContentHeader size={size}>
      {generalLoading && (
        <Box
          position="absolute"
          alignSelf="center"
          justifySelf="center"
          sx={{ left: "50%", transform: "translateX(-50%)" }}
          mt="5px"
          zIndex={20}
        >
          <CircularProgress />
        </Box>
      )}
      <Box display="flex" alignItems="center">
        <ArrowBackIcon
          onClick={handleNavigateBack}
          style={{ cursor: "pointer" }}
        />
        {title || (
          <Typography
            variant="subtitle1"
            fontSize={
              fontSize === NewEntityHeaderFontSize.Large ? "18px" : undefined
            }
            ml={4}
          >
            {t("common.buttons.addEntity", {
              entity: entityName,
            })}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <CancelUpdateButtons
          updateBtnCaption={t("common.buttons.add")}
          onCancel={onCancel ?? handleNavigateBack}
          onUpdate={onAdd}
          loading={addActionLoading}
          updateBtnRef={addBtnRef}
        />
      </Box>
    </PageContentHeader>
  );
};
