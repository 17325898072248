import { Typography, TypographyProps, useTheme } from "@mui/material";

export const MetadataHeading: React.FC<TypographyProps> = ({
  children,
  ...typographyProps
}) => {
  const theme = useTheme();

  return (
    <Typography
      variant="p2"
      fontWeight={600}
      color={theme.palette.grey[800]}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};
