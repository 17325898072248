import { gql } from "@apollo/client";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";

export const eventItemRecipientsQuery = gql`
  ${notificationRecipientFields}

  query eventItemRecipients($productInstanceId: ID!) {
    eventItemRecipients(productInstanceId: $productInstanceId) {
      ...NotificationRecipientFields
    }
  }
`;

export const riskItemRecipientsQuery = gql`
  ${notificationRecipientFields}

  query riskItemRecipients($productInstanceId: ID!) {
    riskItemRecipients(productInstanceId: $productInstanceId) {
      ...NotificationRecipientFields
    }
  }
`;
