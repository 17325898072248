import {
  DraftAssessment,
  DraftQuotation,
  EditDraftAssessmentInput,
  EditDraftQuotationInput,
} from "generated/graphql";
import { attachmentsToAttachmentInputs } from "../../../Attachments/Attachments.utils";

export const quotationVariationToEditQuotationInput = (
  quotation?: DraftQuotation
): EditDraftQuotationInput | undefined => {
  if (!quotation) {
    return quotation;
  }

  return {
    attachments: attachmentsToAttachmentInputs(quotation.attachments),
    compEventId: quotation.compEventId,
    details: quotation.details,
    id: quotation.id,
    keyDatesChanges: quotation.keyDatesChanges,
    price: quotation.price,
    sectionalChanges: quotation.sectionalChanges,
    time: quotation.time,
  };
};

export const draftAssessmentToEditAssessmentInput = (
  assessment?: DraftAssessment
): EditDraftAssessmentInput | undefined => {
  if (!assessment) {
    return assessment;
  }

  return {
    attachments: attachmentsToAttachmentInputs(assessment.attachments),
    compEventId: assessment.compEventId,
    details: assessment.details,
    id: assessment.id,
    keyDatesChanges: assessment.keyDatesChanges,
    price: assessment.price,
    sectionalChanges: assessment.sectionalChanges,
    time: assessment.time,
  };
};
