import moment from "moment";
import { minutesToHoursAndMinutes } from "helpers/miscelaneous";

export const datetimeToHoursAndMinutes = (
  datetime: string,
  quantity: number = 1
) => {
  const hours = moment(datetime).hours();
  const minutes = moment(datetime).minutes();
  const hoursAsMinutes = 60 * hours + minutes || 0;
  const totalHoursAsMinutes = quantity * hoursAsMinutes;

  return minutesToHoursAndMinutes(totalHoursAsMinutes);
};
