import { Box, CircularProgress, Typography } from "@mui/material";
import { UserPreferences, UserPreferencesValueInput } from "generated/graphql";
import { DeviceMobileCamera, EnvelopeSimple } from "phosphor-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NotificationsPreferencesList } from "./NotificationsPreferencesList";
import { useNotificationsSettings } from "./useNotificationsSettings";
import { RHSCard } from "./RHSCard";
import { HeaderContainer } from "./NotificationsSettings.styled";

export const NotificationsSettings = () => {
  const { t } = useTranslation();

  const {
    userPreferences,
    generalLoading,
    updateLoading,
    updateUserPreferences,
  } = useNotificationsSettings();

  const handlePreferencesChange = useCallback(
    (key: string, updatedPreference: UserPreferencesValueInput) => {
      const attrId = key as keyof UserPreferences;
      const crtPreferences = userPreferences as UserPreferences;

      const updatedAllPreferences = {
        ...crtPreferences,
        [key]: {
          ...crtPreferences![attrId],
          ...updatedPreference!,
        },
      };

      updateUserPreferences(updatedAllPreferences);
    },
    [userPreferences, updateUserPreferences]
  );

  return (
    <RHSCard>
      <HeaderContainer>
        <Typography variant="h3" fontWeight={600} color="grey.900">
          {t("Settings.notificationSettings")}
        </Typography>
        <Typography variant="p2" fontWeight={400} mt={1.5} color="grey.600">
          {t("Settings.preferences.subtitle")}
        </Typography>
        <Box ml="auto" display="flex" alignItems="center" mt={5}>
          <DeviceMobileCamera />
          <Typography variant="caption2" fontWeight={700} ml={0.5}>
            {t("Settings.preferences.inApp")}
          </Typography>
          <Box ml={4} display="flex" alignItems="center">
            <EnvelopeSimple />
            <Typography variant="caption2" fontWeight={700} ml={0.5}>
              {t("Settings.preferences.email")}
            </Typography>
          </Box>
        </Box>
      </HeaderContainer>
      {generalLoading || !userPreferences ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <NotificationsPreferencesList
            loading={updateLoading || generalLoading}
            onChange={handlePreferencesChange}
            preferences={userPreferences!}
          />
        </Box>
      )}
    </RHSCard>
  );
};
