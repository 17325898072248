import { Grid } from "@mui/material";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { ListItemsDivider } from "components/ListItemsDivider";
import {
  ProductItemCard,
  ProductItemCardProps,
} from "components/ProductItemCard/ProductItemCard";
import {
  AuthorizationWorkflow,
  EarlyWarningItemPreview,
  InstructionItemPreview,
} from "generated/graphql";
import { RecipientsPreview } from "../../../../components/RecipientsPreview";
import { SmallProductItemPreview } from "../../../../components/SmallProductItemPreview";
import { useTranslation } from "react-i18next";
import { AuthorizationProgressPreview } from "components/Authorization/AuthorizationProgressPreview";

export type SendEWInstructionConfirmModalContentProps = {
  ewInstructionPreview: EarlyWarningItemPreview | InstructionItemPreview;
  isProvideQuotationType: boolean;
  contractId: string;
  authorizationWorkflow?: AuthorizationWorkflow;
  onPreviewClick: () => void;
} & ProductItemCardProps;

export const SendEWInstructionConfirmModalContent: React.FC<
  SendEWInstructionConfirmModalContentProps
> = ({
  ewInstructionPreview,
  itemName,
  productType,
  projectName,
  contractName,
  authorizationWorkflow,
  onPreviewClick,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      {authorizationWorkflow && (
        <Grid item xs={12}>
          <AuthorizationProgressPreview
            authorizationWorkflow={authorizationWorkflow}
            omitTitle
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ProductItemCard
          itemName={itemName}
          productType={productType}
          projectName={projectName}
          contractName={contractName}
        />
      </Grid>
      <Grid item xs={12}>
        <CollapsibleContainer title={t("common.labels.preview")}>
          <SmallProductItemPreview
            imageUrl={ewInstructionPreview.fileUrl}
            onClick={onPreviewClick}
          />
        </CollapsibleContainer>
        <ListItemsDivider />
      </Grid>
      <Grid item xs={12}>
        <CollapsibleContainer title={t("common.labels.recipients")}>
          <RecipientsPreview recipients={ewInstructionPreview.recipients} />
        </CollapsibleContainer>
      </Grid>
    </Grid>
  );
};
