import {
  VariationItem,
  VariationItemsRegisterQuery,
  VariationItemsRegisterQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { variationItemsRegisterQuery } from "./useVariationsRegister.query";
import { useMemo } from "react";

export const useVariationsRegister = () => {
  const [
    fetchVariationItemsData,
    { data: variationItemsData, loading: variationItemsDataLoading },
  ] = useGraphLazyQuery<
    VariationItemsRegisterQuery,
    VariationItemsRegisterQueryVariables
  >(variationItemsRegisterQuery);

  const items = useMemo(
    () => (variationItemsData?.variationItems.items ?? []) as VariationItem[],
    [variationItemsData]
  );

  return {
    variationItemsData: items,
    variationItemsDataLoading,
    fetchVariationItemsData,
  };
};
