import { gql } from "@apollo/client";
import { dailyDiaryItemFields } from "graphql/fragments/dailyDiaryItem.fragment";

export const dailyDiaryItemByDateQuery = gql`
  ${dailyDiaryItemFields}

  query dailyDiaryItemByDate($productInstanceId: ID!, $date: AWSDate!) {
    dailyDiaryItemByDate(productInstanceId: $productInstanceId, date: $date) {
      __typename
      ... on DailyDiaryItem {
        ...DailyDiaryItemFields
      }

      ... on EmptyDailyDiaryItem {
        productInstanceId
        date
      }
    }
  }
`;
