import { AuthorizationWorkflowLevelInput } from "generated/graphql";
import { AuthorizationWorkflowLevelInputWithID } from "./AuthorizationWorkflowLevels";
import { v4 as uuidv4 } from "uuid";

export const sortLevels = (
  workflowLevels:
    | AuthorizationWorkflowLevelInput[]
    | AuthorizationWorkflowLevelInputWithID[]
) =>
  workflowLevels
    .slice()
    .sort((level1, level2) => level1.sequence - level2.sequence);

export const workflowLevelsToLocalWorkflowLevels = (
  workflowLevels: AuthorizationWorkflowLevelInput[]
): AuthorizationWorkflowLevelInputWithID[] =>
  workflowLevels.map((level) => ({
    id: uuidv4(),
    ...level,
  }));
