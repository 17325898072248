import {
  AuthAuditDetailsQuery,
  AuthorizationWorkflowAudit,
  ProductType,
} from "generated/graphql";

export const unifyAuthAuditObj = (
  queryData?: AuthAuditDetailsQuery
): AuthorizationWorkflowAudit | undefined => {
  if (!queryData) {
    return;
  }

  const rawAuthAudit = queryData.authorizationWorkflowAudit;
  if (rawAuthAudit) {
    const { regardingProductItem, ...rest } = rawAuthAudit;

    return {
      ...rest,
      regardingProductItem: {
        ...regardingProductItem,
        status:
          (regardingProductItem as any).status ??
          (regardingProductItem as any).claimStatus ??
          (regardingProductItem as any).voStatus,
        nextStep:
          (regardingProductItem as any).nextStep ??
          (regardingProductItem as any).claimNextStep ??
          (regardingProductItem as any).voNextStep,
        lastAction:
          (regardingProductItem as any).lastAction ??
          (regardingProductItem as any).claimLastAction ??
          (regardingProductItem as any).voLastAction,
        regardingType:
          (regardingProductItem as any).regardingType ??
          (regardingProductItem as any).claimRegardingType ??
          ProductType.Instructions, // NOTE: for VOs, until now, only Instructions can have Variations. In the future we'll have voRegardingType
      },
    } as AuthorizationWorkflowAudit;
  }
};
