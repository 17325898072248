import { gql } from "@apollo/client";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";

export const claimActionQuery = gql`
  ${authorizationWorkflowActionMappingFields}

  query claimAction($input: ClaimActionInput!) {
    claimAction(input: $input) {
      items {
        type
        actionMapping {
          ...AuthorizationWorkflowActionMappingFields
        }
      }
    }
  }
`;
