import { SelectChangeEvent, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import {
  AuthorizationWorkflow,
  AuthorizationWorkflowActionMapping,
  ProductOutputActionOriginatingParty,
} from "generated/graphql";
import { temporaryRowId } from "constants/constants";
import { getCommonActions } from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { DataGridSingleSelect } from "components/DataGridSingleSelect";

type ArgsType = {
  workflows: AuthorizationWorkflow[];
  actions: ProductOutputActionOriginatingParty[];
  rowModesModel: GridRowModesModel;
  onActionChange: (
    row: AuthorizationWorkflowActionMapping,
    newActionId: string
  ) => void;
  onWorkflowChange: (
    row: AuthorizationWorkflowActionMapping,
    newWorkflowId: string
  ) => void;
  onSaveRow: (rowId: GridRowId) => void;
  onDeleteRow: (rowId: GridRowId) => void;
};

export const useColumns = ({
  workflows,
  actions,
  rowModesModel,
  onActionChange,
  onWorkflowChange,
  onSaveRow,
  onDeleteRow,
}: ArgsType): GridColDef<AuthorizationWorkflowActionMapping>[] => {
  const { t } = useTranslation();

  const workflowOptions = workflows.map((workflow) => ({
    value: workflow.id,
    label: workflow.name,
  }));

  const workflowActions = actions.map((productOutputActionWithParty) => {
    const action = productOutputActionWithParty.productOutputAction;
    const value = `${action.id}${
      productOutputActionWithParty.originatingParty?.id
        ? `_${productOutputActionWithParty.originatingParty?.id}`
        : ""
    }`;
    const label = `${action.name} ${
      productOutputActionWithParty.originatingParty?.description
        ? `(${productOutputActionWithParty.originatingParty?.description})`
        : ""
    }`;

    return {
      value,
      label,
    };
  });

  const columns = useMemo(
    () =>
      [
        {
          field: "productOutputAction",
          headerName: t("AdminConsole.AuthorizationWorkflows.action"),
          flex: 0.4,
          minWidth: 90,
          resizable: true,
          editable: true,
          type: "singleSelect",
          valueGetter: (_, row) =>
            `${row.productOutputActionId}${
              row.partyId ? `_${row.partyId}` : ""
            }`,
          sortComparator: (rawActionId1: string, rawActionId2: string) => {
            const label1 =
              workflowActions.find(
                (workflowAction) => workflowAction.value === rawActionId1
              )?.label ?? "";
            const label2 =
              workflowActions.find(
                (workflowAction) => workflowAction.value === rawActionId2
              )?.label ?? "";

            if (label1 < label2) {
              return -1;
            } else if (label1 > label2) {
              return 1;
            }

            return 0;
          },
          valueOptions: workflowActions,
          valueFormatter: (value: string) => {
            const selectedOption = workflowActions.find(
              (valueOption: { value: string; label: string }) =>
                valueOption.value === value
            );

            return selectedOption?.label;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<
              AuthorizationWorkflowActionMapping,
              string,
              any
            >
          ) => {
            return cellParams.id === temporaryRowId ? (
              <DataGridSingleSelect
                cellParams={cellParams}
                disabled={cellParams.id !== temporaryRowId}
                error={!cellParams.row.productOutputActionId}
                onValueChange={(event: SelectChangeEvent<any>) => {
                  onActionChange(cellParams.row, event.target.value);
                }}
              />
            ) : (
              <Typography variant="p2">
                {
                  workflowActions.find(
                    (action) => action.value === cellParams.value
                  )?.label
                }
              </Typography>
            );
          },
        },
        {
          field: "workflow",
          headerName: t("AdminConsole.AuthorizationWorkflows.workflow"),
          flex: 0.4,
          minWidth: 90,
          resizable: true,
          editable: true,
          type: "singleSelect",
          valueGetter: (_, row) => row.workflowId,
          valueOptions: workflowOptions,
          valueFormatter: (value: string) => {
            const selectedOption = workflowOptions.find(
              (valueOption: { value: string; label: string }) =>
                valueOption.value === value
            );

            return selectedOption?.label;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<
              any,
              AuthorizationWorkflowActionMapping,
              any
            >
          ) => (
            <DataGridSingleSelect
              cellParams={cellParams}
              error={!cellParams.row.workflowId}
              onValueChange={(event: SelectChangeEvent<any>) => {
                onWorkflowChange(cellParams.row, event.target.value);
              }}
            />
          ),
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          sortable: false,
          getActions: (cellParams) =>
            getCommonActions(cellParams, rowModesModel, onSaveRow, onDeleteRow),
        },
      ] as GridColDef<AuthorizationWorkflowActionMapping>[],
    [
      t,
      rowModesModel,
      workflowActions,
      workflowOptions,
      onActionChange,
      onWorkflowChange,
      onSaveRow,
      onDeleteRow,
    ]
  );
  return columns;
};
