import { Grid, TextField, Typography } from "@mui/material";
import { Overlay } from "components/Overlay";
import {
  AddAuthorizationWorkflowInput,
  AuthorizationWorkflow,
  EditAuthorizationWorkflowInput,
  User,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormPublicApi } from "types/decl";
import {
  defaultFormData,
  dataValidators,
} from "./AuthorizationWorkflow.constants";
import {
  AuthorizationWorkflowLevels,
  AuthorizationWorkflowLevelInputWithID,
} from "../AuthorizationWorkflowLevels/AuthorizationWorkflowLevels";
import { workflowLevelsToLocalWorkflowLevels } from "../AuthorizationWorkflowLevels/AuthorizationWorkflowLevels.utils";
import { authorizationWorkflowLevelsToAuthWorkflowLevelsInput } from "../../AuthorizationWorkflowDetails/AuthorizationWorkflowDetails.utils";

export type AuthorizationWorkflowFormProps = {
  authorizationWorkflow?: AuthorizationWorkflow;
  users: User[];
  disabled?: boolean;
  apiRef?: React.Ref<FormPublicApi>;
  projectId: string;
  loading?: boolean;
  onChange: (
    updatedWorkflow:
      | AddAuthorizationWorkflowInput
      | EditAuthorizationWorkflowInput
  ) => void;
};

export type FormType = Omit<AddAuthorizationWorkflowInput, "levels"> & {
  levels: AuthorizationWorkflowLevelInputWithID[];
};

export const AuthorizationWorkflowForm: React.FC<
  AuthorizationWorkflowFormProps
> = ({
  authorizationWorkflow,
  disabled,
  users,
  loading,
  apiRef,
  projectId,
  onChange,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormType>(
    authorizationWorkflow
      ? {
          ...authorizationWorkflow,
          levels: workflowLevelsToLocalWorkflowLevels(
            authorizationWorkflowLevelsToAuthWorkflowLevelsInput(
              authorizationWorkflow?.levels ?? []
            )
          ),
        }
      : defaultFormData
  );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback(
    (formData: AddAuthorizationWorkflowInput | AuthorizationWorkflow) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    []
  );

  const resetForm = useCallback(() => {
    setFormData(
      authorizationWorkflow
        ? {
            ...authorizationWorkflow,
            levels: workflowLevelsToLocalWorkflowLevels(
              authorizationWorkflowLevelsToAuthWorkflowLevelsInput(
                authorizationWorkflow?.levels ?? []
              )
            ),
          }
        : defaultFormData
    );
  }, [authorizationWorkflow]);

  const handleAuthWorkflowChange = (
    workflowLevels: AuthorizationWorkflowLevelInputWithID[]
  ) => {
    setFormData((crtFormData) => ({
      ...crtFormData,
      levels: workflowLevels,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { levels: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  useEffect(() => {
    const { levels, ...rest } = formData;

    onChange({
      ...rest,
      levels: authorizationWorkflowLevelsToAuthWorkflowLevelsInput(levels),
      projectId,
    });
  }, [formData, onChange, projectId]);

  useEffect(() => {
    setFormData(
      authorizationWorkflow
        ? {
            ...authorizationWorkflow,
            levels: workflowLevelsToLocalWorkflowLevels(
              authorizationWorkflowLevelsToAuthWorkflowLevelsInput(
                authorizationWorkflow.levels
              )
            ),
          }
        : defaultFormData
    );
  }, [authorizationWorkflow]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [validateForm, formData, resetForm]
  );

  return (
    <>
      {disabled && <Overlay withBackground={false} />}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleTextFieldChange}
            type="text"
            label={t("common.labels.name")}
            variant="standard"
            error={!!formDataErrors.name}
            helperText={formDataErrors.name}
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <AuthorizationWorkflowLevels
            workflowLevels={formData?.levels ?? []}
            loading={!!loading}
            users={users}
            onChange={handleAuthWorkflowChange}
          />
          {/* TODO: create a component out of this */}
          {!!formDataErrors.levels && (
            <Typography variant="caption" color="error">
              {formDataErrors.levels}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};
