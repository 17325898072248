import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationFurtherParticularsSubmissionPreviewMutation,
  GenerateRecordVariationFurtherParticularsSubmissionPreviewMutationVariables,
  RecordVariationFurtherParticularsSubmissionPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";
import { generateRecordVariationFurtherParticularsSubmissionPreviewMutation } from "./ProvideFurtherParticularsAction.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useRecordFurtherParticularsSubmissionModal = (details: string) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [preview, setPreview] =
    useState<RecordVariationFurtherParticularsSubmissionPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationFurtherParticularsSubmissionPreviewMutation,
    GenerateRecordVariationFurtherParticularsSubmissionPreviewMutationVariables
  >(
    generateRecordVariationFurtherParticularsSubmissionPreviewMutation,
    {},
    null
  );

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            details,
          },
        },
      });

      if (data) {
        setPreview(
          data.generateRecordVariationFurtherParticularsSubmissionPreview
        );
      }
    }, [generatePreview, variation, details]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview: preview,
    contract,
  };
};
