import {
  ApolloQueryResult,
  DocumentNode,
  FetchPolicy,
  OperationVariables,
  QueryOptions,
  useApolloClient,
} from "@apollo/client";
import { useCallback, useState } from "react";

export function useGraphLazyQueryLite<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode
): [
  (
    variables?: TVariables,
    fetchPolicy?: FetchPolicy,
    queryOptions?: QueryOptions<TVariables, TData>
  ) => Promise<ApolloQueryResult<TData>>,
  { data: ApolloQueryResult<TData> | undefined; loading: boolean }
] {
  const client = useApolloClient();
  const [result, setResult] = useState<ApolloQueryResult<TData>>();
  const [loading, setLoading] = useState<boolean>(false);

  const execute = useCallback(
    async (
      variables?: TVariables,
      fetchPolicy?: FetchPolicy,
      otherQueryOptions?: Omit<
        QueryOptions<TVariables, TData>,
        "variables" | "query" | "fetchPolicy"
      >
    ) => {
      try {
        setLoading(true);
        const response = await client.query<TData, TVariables>({
          query,
          variables,
          ...(fetchPolicy ? { fetchPolicy } : {}),
          ...otherQueryOptions,
        });

        setLoading(false);
        setResult(response);

        return response;
      } finally {
        setLoading(false);
      }
    },
    [client, query]
  );

  return [execute, { data: result, loading }];
}
