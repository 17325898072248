import { Box, Grid, Typography } from "@mui/material";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { SendCompEventOwnAssessmentNoticeInput } from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import {
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { RecordCEOwnAssessmentNotice } from "./RecordCEOwnAssessmentNotice";
import { SendCEOwnAssessmentNotice } from "./SendCEOwnAssessmentNotice";
import { useNotifyOwnAssessmentCEAction } from "./useNotifyOwnAssessmentCEAction";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";
import { isNEC3PSCContractType } from "containers/Projects/Projects.utils";

const dataValidators: DataValidators = {
  reason: {
    validators: [ValidatorType.RequiredRichText],
  },
};

type FormDataType = Pick<SendCompEventOwnAssessmentNoticeInput, "reason">;

const defaultNEC3ECCReason =
  '<ul><li value="1"><span>The Contractor has not submitted a quotation and details of his assessment within the time allowed</span></li><li value="2"><span>The Contractor has not assessed the compensation event correctly</span></li><li value="3"><span>The Contractor has not submitted a programme which this contract requires him to submit</span></li><li value="4"><span>The Contractor’s latest programme has not been accepted for one of the reasons stated in this contract</span></li></ul>';
const defaultNEC3PSCReason = `<ul><li value="1"><span>the</span><i><em class="PlaygroundEditorTheme__textItalic">&nbsp;Consultant</em></i><span>&nbsp;has not submitted their quotation and details of their assessment within the time allowed</span></li><li value="2"><span>the</span><i><em class="PlaygroundEditorTheme__textItalic">&nbsp;Consultant</em></i><span>&nbsp;has not assessed the compensation event correctly</span></li><li value="3"><span>the&nbsp;</span><i><em class="PlaygroundEditorTheme__textItalic">Consultant</em></i><span>&nbsp;has not submitted a programme or alterations to a programme</span></li><li value="4"><span>the&nbsp;</span><i><em class="PlaygroundEditorTheme__textItalic">Employer</em></i><span>&nbsp;has not accepted the&nbsp;</span><i><em class="PlaygroundEditorTheme__textItalic">Consultant’s</em></i><span>&nbsp;latest programme</span></li></ul>`;

const defaultFormData = {
  reason: "",
};

export const NotifyOwnAssessmentCEAction: React.FC<CEActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { compEvent, contract } = useContext(CompEventWidgetContext);

  const defaultReasonsText = useMemo(() => {
    if (isNEC3PSCContractType(contract)) {
      return defaultNEC3PSCReason;
    }

    return defaultNEC3ECCReason;
  }, [contract]);

  const [formData, setFormData] = useState<FormDataType>({
    ...defaultFormData,
    reason: defaultReasonsText,
  });
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendCEOwnAssessmentNotice,
    recordCEOwnAssessmentNotice,
    actionLoading,
  } = useNotifyOwnAssessmentCEAction();

  const handleTextFieldChange = (reason: string) => {
    setFormData((curData) => ({
      ...curData,
      reason,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { reason: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const result = validateData(formData, dataValidators);

    if (result.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(result.errors);
    }

    return result.valid;
  }, []);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };
  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCEOwnAssessmentNotice = async () => {
    await sendCEOwnAssessmentNotice({
      variables: {
        input: {
          compEventId: compEvent!.id,
          reason: formData.reason,
        },
      },
    });

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEOwnAssessmentNotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCEOwnAssessmentNotice({
      variables: {
        input: {
          compEventId: compEvent!.id,
          reason: formData.reason,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [formData, validateForm]
  );

  return (
    <>
      {formData.reason && sendModalVisibility && (
        <SendCEOwnAssessmentNotice
          open={sendModalVisibility}
          reason={formData.reason}
          onPrimaryClick={handleSendCEOwnAssessmentNotice}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {formData.reason && recordModalVisibility && (
        <RecordCEOwnAssessmentNotice
          open={recordModalVisibility}
          reason={formData.reason}
          onPrimaryClick={handleRecordCEOwnAssessmentNotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel
              label={t("Projects.CompEvents.reasonForOwnAssessment")}
              required
            />
            <RichTextArea
              content={formData.reason}
              error={!!formDataErrors.reason}
              onChange={handleTextFieldChange}
            />
            {!!formDataErrors.reason && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.reason}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
