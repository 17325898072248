import {
  AddDailyDiaryEquipmentRecordMutation,
  AddDailyDiaryEquipmentRecordMutationVariables,
  DailyDiaryItem,
  EditDailyDiaryEquipmentRecordMutation,
  EditDailyDiaryEquipmentRecordMutationVariables,
  RemoveDailyDiaryEquipmentRecordMutation,
  RemoveDailyDiaryEquipmentRecordMutationVariables,
} from "generated/graphql";
import { addDailyDiaryEquipmentRecordMutation } from "graphql/mutations/addDailyDiaryEquipmentRecord";
import { editDailyDiaryEquipmentRecordMutation } from "graphql/mutations/editDailyDiaryEquipmentRecord";
import { removeDailyDiaryEquipmentRecordMutation } from "graphql/mutations/removeDailyDiaryEquipmentRecord";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { useEquipmentRecords } from "./useEquipmentRecords";

export const useEquipmentSection = (
  dailyDiary?: DailyDiaryItem,
  revisionId?: string
) => {
  const { t } = useTranslation();

  const { refetchDDByDate } = useContext(DailyDiaryContext);
  const {
    equipmentRecords,
    equipmentRecordsLoading,
    refetchDDEquipmentRecords,
  } = useEquipmentRecords(dailyDiary, revisionId);

  const [addDDEquipmentRecord, { loading: addDDEquipmentRecordLoading }] =
    useGraphMutation<
      AddDailyDiaryEquipmentRecordMutation,
      AddDailyDiaryEquipmentRecordMutationVariables
    >(
      addDailyDiaryEquipmentRecordMutation,
      {
        update: async () => {
          if (!dailyDiary) {
            await refetchDDByDate?.();
          } else {
            refetchDDEquipmentRecords();
          }
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.DailyDiaries.equipmentRecord"),
      })
    );

  const [editDDEquipmentRecord, { loading: editDDEquipmentRecordLoading }] =
    useGraphMutation<
      EditDailyDiaryEquipmentRecordMutation,
      EditDailyDiaryEquipmentRecordMutationVariables
    >(
      editDailyDiaryEquipmentRecordMutation,
      {
        update: () => {
          refetchDDEquipmentRecords();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.DailyDiaries.equipmentRecord"),
      })
    );

  const [removeDDEquipmentRecord, { loading: removeDDEquipmentRecordLoading }] =
    useGraphMutation<
      RemoveDailyDiaryEquipmentRecordMutation,
      RemoveDailyDiaryEquipmentRecordMutationVariables
    >(
      removeDailyDiaryEquipmentRecordMutation,
      {
        update: () => {
          refetchDDEquipmentRecords();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.DailyDiaries.equipmentRecord"),
      })
    );

  return {
    equipmentRecords,
    loading:
      equipmentRecordsLoading ||
      addDDEquipmentRecordLoading ||
      editDDEquipmentRecordLoading ||
      removeDDEquipmentRecordLoading,
    addEquipmentRecord: addDDEquipmentRecord,
    editEquipmentRecord: editDDEquipmentRecord,
    removeEquipmentRecord: removeDDEquipmentRecord,
    refetchDDEquipmentRecords,
  };
};
