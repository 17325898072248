export const timeRelatedUnits = [
  "hour",
  "hours",
  "minute",
  "minutes",
  "second",
  "seconds",
  "day",
  "days",
  "week",
  "weeks",
  "month",
  "months",
  "year",
  "years",
];
