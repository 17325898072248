import { TabPanel } from "@mui/lab";
import { Tab, css, styled } from "@mui/material";

export const StyledTab = styled(Tab)(
  ({ theme }) => css`
    && {
      color: ${theme.palette.grey[600]};
      background-color: ${theme.palette.grey[100]};
      margin: 0;
      padding: ${theme.spacing(1)} ${theme.spacing(2)};
      border-radius: 8px;
      border-color: ${theme.palette.grey[200]};
      height: 40px;
      min-height: 40px;
      text-transform: none;
      &:not(:last-child) {
        margin-right: ${theme.spacing(1)};
      }

      &:hover {
        box-shadow: none;
        color: inherit;
      }

      &.Mui-selected {
        background-color: ${theme.palette.grey[900]};
        border-color: ${theme.palette.grey[900]};
        color: ${theme.palette.common.white};
      }
    }
  `
);

export const StyledTabPanel = styled(TabPanel)(
  () => css`
    padding: 0;
  `
);
