import { Box, css, styled, Typography, useTheme } from "@mui/material";
import { Check, Copy } from "phosphor-react";
import { useState } from "react";

export type PlaceholderListItemType = {
  label: string;
  value: string;
};

export type PlaceholderListItemProps = {
  placeholder: PlaceholderListItemType;
};

const StyledListItem = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    padding-top: ${theme.spacing(0.5)};
    padding-bottom: ${theme.spacing(0.5)};
    padding-left: ${theme.spacing(4)};
    padding-right: ${theme.spacing(4)};

    &:hover {
      background: ${theme.palette.grey[100]};
      cursor: pointer;
    }
  `
);

export const PlaceholderListItem: React.FC<PlaceholderListItemProps> = ({
  placeholder,
}) => {
  const theme = useTheme();

  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = (placeholder: PlaceholderListItemType) => {
    navigator.clipboard.writeText(`{{ ${placeholder.value} }}`);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <StyledListItem onClick={() => handleCopyToClipboard(placeholder)}>
      <Typography variant="body2">{placeholder.label}</Typography>
      {copied ? <Check color={theme.palette.primary.main} /> : <Copy />}
    </StyledListItem>
  );
};
