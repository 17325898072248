import { gql } from "@apollo/client";
import { cEListItemFields } from "graphql/fragments/compEventListItem.fragment";

export const compEventNoticePromptQuery = gql`
  ${cEListItemFields}

  query compEventNoticePrompt($input: CompEventNoticePromptInput!) {
    compEventNoticePrompt(input: $input) {
      compEventTypes {
        ...CEListItemFields
      }
      earlyWarnings {
        ...CEListItemFields
      }
    }
  }
`;
