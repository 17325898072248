import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventDenyingOfMoreTimeMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventDenyingOfMoreTime(
    $input: RecordCompEventDenyingOfMoreTimeInput!
  ) {
    recordCompEventDenyingOfMoreTime(input: $input) {
      ...CompEventItemFields
    }
  }
`;
