import { ProductType } from "generated/graphql";
import {
  getAbsolutePath,
  getDailyDiaryDetailsPath,
  getProductItemDetailsPath,
} from "helpers/paths/pathConstructors";
import { MetadataSet1, MetadataSet2, MetadataSet3 } from "../types/intercom";

export const getMetadataSet1 = ({
  productInstanceId,
  productInstanceItemId,
  productInstanceItemName,
  contractFn,
  productType,
  projectFn,
  version,
}: {
  productInstanceId: string;
  productInstanceItemId: string;
  productInstanceItemName: string;
  contractFn: string;
  projectFn: string;
  productType: ProductType;
  version: string;
}): MetadataSet1 => {
  const productInstanceItemPath = getAbsolutePath(
    getProductItemDetailsPath(
      productInstanceId,
      productInstanceItemId,
      productType
    )
  );

  return {
    Title: {
      value: productInstanceItemName,
      url: productInstanceItemPath,
    },
    Contract: contractFn,
    Project: projectFn,
    Version: version,
  };
};

export const getMetadataSet2 = ({
  diaryNumber,
  diaryRevision,
  productInstanceName,
  projectFn,
  productInstanceId,
  diaryId,
  date,
  revisionId,
  contractFn,
  version,
}: {
  diaryNumber: string;
  diaryRevision: number;
  productInstanceName: string;
  contractFn: string;
  projectFn: string;
  productInstanceId: string;
  date: string;
  diaryId: string;
  version: string;
  revisionId?: string;
}): MetadataSet2 => {
  const diaryPath = getAbsolutePath(
    getDailyDiaryDetailsPath(productInstanceId, date, diaryId, revisionId)
  );

  return {
    DiaryNumber: {
      value: diaryNumber,
      url: diaryPath,
    },
    DiaryRevision: diaryRevision,
    ...getMetadataSet3({ productInstanceName, contractFn, projectFn, version }),
  };
};

export const getMetadataSet3 = ({
  productInstanceName,
  projectFn,
  contractFn,
  version,
}: {
  productInstanceName: string;
  projectFn: string;
  contractFn: string;
  version: string;
}): MetadataSet3 => {
  return {
    Project: projectFn,
    Contract: contractFn,
    ProductInstance: productInstanceName,
    Version: version,
  };
};
