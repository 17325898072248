import {
  DraftVariationAgreement,
  DraftVariationDetermination,
  DraftVariationProposal,
  EditDraftVariationAgreementInput,
  EditDraftVariationDeterminationInput,
  EditDraftVariationProposalInput,
} from "generated/graphql";
import { attachmentsToAttachmentInputs } from "../../../Attachments/Attachments.utils";

export const draftVODataToEditVODataInput = (
  draftVOData?:
    | DraftVariationProposal
    | DraftVariationAgreement
    | DraftVariationDetermination
):
  | EditDraftVariationProposalInput
  | EditDraftVariationAgreementInput
  | EditDraftVariationDeterminationInput
  | undefined => {
  if (!draftVOData) {
    return draftVOData;
  }

  return {
    attachments: attachmentsToAttachmentInputs(draftVOData.attachments),
    variationId: draftVOData.variationId,
    details: draftVOData.details,
    id: draftVOData.id,
    price: draftVOData.price,
    sectionalChanges: draftVOData.sectionalChanges,
    time: draftVOData.time,
  };
};
