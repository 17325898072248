import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";

export const authorizationWorkflowsQuery = gql`
  ${authorizationWorkflowFields}

  query authorizationWorkflows($projectId: ID!) {
    authorizationWorkflows(projectId: $projectId) {
      items {
        ...AuthorizationWorkflowFields
      }
    }
  }
`;
