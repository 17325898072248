import { ProductType } from "generated/graphql";

export enum ProductTypename {
  RisksRegister = "RisksRegister",
  EarlyWarnings = "EarlyWarnings",
  Events = "Events",
  Instructions = "Instructions",
  CompEvents = "CompEvents",
  DailyDiary = "DailyDiary",
  Claims = "Claims",
  Variations = "Variations",
}

export const ProductToProductTypename: Record<ProductType, ProductTypename> = {
  [ProductType.RisksRegister]: ProductTypename.RisksRegister,
  [ProductType.EarlyWarnings]: ProductTypename.EarlyWarnings,
  [ProductType.Events]: ProductTypename.Events,
  [ProductType.Instructions]: ProductTypename.Instructions,
  [ProductType.CompEvents]: ProductTypename.CompEvents,
  [ProductType.DailyDiary]: ProductTypename.DailyDiary,
  [ProductType.Claims]: ProductTypename.Claims,
  [ProductType.Variations]: ProductTypename.Variations,
};

export const ProductTypenameToProduct: Record<ProductTypename, ProductType> = {
  [ProductTypename.RisksRegister]: ProductType.RisksRegister,
  [ProductTypename.EarlyWarnings]: ProductType.EarlyWarnings,
  [ProductTypename.Events]: ProductType.Events,
  [ProductTypename.Instructions]: ProductType.Instructions,
  [ProductTypename.CompEvents]: ProductType.CompEvents,
  [ProductTypename.DailyDiary]: ProductType.DailyDiary,
  [ProductTypename.Claims]: ProductType.Claims,
  [ProductTypename.Variations]: ProductType.Variations,
};

export enum ProductItemTypename {
  RiskItem = "RiskItem",
  EarlyWarningItem = "EarlyWarningItem",
  EventItem = "EventItem",
  InstructionItem = "InstructionItem",
  CompEventItem = "CompEventItem",
  DailyDiaryItem = "DailyDiaryItem",
  ClaimItem = "ClaimItem",
  VariationItem = "VariationItem",
}

export const ProductItemTypenameToProduct: Record<
  ProductItemTypename,
  ProductType
> = {
  [ProductItemTypename.RiskItem]: ProductType.RisksRegister,
  [ProductItemTypename.EarlyWarningItem]: ProductType.EarlyWarnings,
  [ProductItemTypename.EventItem]: ProductType.Events,
  [ProductItemTypename.InstructionItem]: ProductType.Instructions,
  [ProductItemTypename.CompEventItem]: ProductType.CompEvents,
  [ProductItemTypename.DailyDiaryItem]: ProductType.DailyDiary,
  [ProductItemTypename.ClaimItem]: ProductType.Claims,
  [ProductItemTypename.VariationItem]: ProductType.Variations,
};
