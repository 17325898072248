import { Box, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { IconContainer } from "components/IconContainer";
import { Swap } from "phosphor-react";
import { useTranslation } from "react-i18next";

type ReviseDiaryModalProps = {} & ActionsDialogProps;

export const ReviseDiaryModal: React.FC<ReviseDiaryModalProps> = ({
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ActionsDialog
      iconsHeader={
        <IconContainer greyBackground>
          <Swap size={24} color={theme.palette.grey[800]} />
        </IconContainer>
      }
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Projects.DailyDiaries.ReviseDailyDiaryModal.title")}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]} mb={2}>
            {t("Projects.DailyDiaries.ReviseDailyDiaryModal.subtitle")}
          </Typography>
        </Box>
      }
      fullWidth
      maxWidth="xs"
      primaryBtnCaption={t("common.buttons.revise")}
      secondaryBtnCaption={t("common.buttons.cancel")}
      {...restDialogProps}
    />
  );
};
