export const APP_KEY = "ContractCommunicator";

export const read = (key?: string): object | null => {
  const dataAsString = localStorage.getItem(APP_KEY);
  let dataAsObj;

  if (!dataAsString) {
    return null;
  }

  try {
    dataAsObj = JSON.parse(dataAsString);
  } catch (error) {
    console.log(error);
  }

  if (key) {
    return dataAsObj[key];
  }

  return dataAsObj;
};

export const write = (data: object, subKey?: string) => {
  let currentData: any = read() || {};
  if (subKey) {
    currentData[subKey] = data;
  } else {
    currentData = data;
  }

  const dataAsString = JSON.stringify(currentData);

  localStorage.setItem(APP_KEY, dataAsString);
};
