import { BoxProps, useTheme } from "@mui/material";
import { useMemo } from "react";
import { CompEventWidgetSectionContainer } from "../CompEvents/CompEventWidget/CompEventWidget.styled";
import { unofficialThemeColors } from "theme/extendedTheme";

// TODO: unify CE and Claim next steps. For now leave CE steps
export enum CEWidgetSectionType {
  NextStep = "Next step",
  LastAction = "Last action",
  Quotation = "Quotation",
  EarlyWarning = "Early Warning",
  ActionSelection = "Action selection",
}

export type WidgetSectionProps = {
  type: CEWidgetSectionType;
  children: React.ReactNode;
} & BoxProps;

export const WidgetSection: React.FC<WidgetSectionProps> = ({
  type,
  children,
  ...restBoxProps
}) => {
  const theme = useTheme();

  const sectionTypeStyle: Record<
    CEWidgetSectionType,
    { bgColor: string; borderColor: string } | undefined
  > = useMemo(
    () => ({
      [CEWidgetSectionType.NextStep]: {
        borderColor: theme.palette.warning.main,
        bgColor: unofficialThemeColors.amberWidgetNextStep,
      },
      [CEWidgetSectionType.LastAction]: {
        borderColor: theme.palette.info.main,
        bgColor: unofficialThemeColors.blueWidgetLastStep,
      },
      [CEWidgetSectionType.ActionSelection]: {
        borderColor: theme.palette.grey[300],
        bgColor: theme.palette.background.paper,
      },
      [CEWidgetSectionType.Quotation]: undefined,
      [CEWidgetSectionType.EarlyWarning]: undefined,
    }),
    [theme]
  );

  return (
    <CompEventWidgetSectionContainer
      sx={{
        ...restBoxProps.sx,
        backgroundColor: sectionTypeStyle[type]?.bgColor,
        borderColor: sectionTypeStyle[type]?.borderColor,
      }}
      {...restBoxProps}
    >
      {children}
    </CompEventWidgetSectionContainer>
  );
};
