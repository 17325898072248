import { DailyDiaryPresetSection, LookupOption } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";
import { ResourceFormDataType } from "./ResourceRecordForm";
import { useMemo } from "react";

export const useResourceRecordFormValidators = (
  section: DailyDiaryPresetSection,
  formData: ResourceFormDataType,
  resourceLookupOptions: LookupOption[],
  shiftLookupOptions?: LookupOption[],
  companyLookupOptions?: LookupOption[],
  teamLookupOptions?: LookupOption[]
) => {
  const dataValidators: DataValidators = useMemo(() => {
    // RESOURCE
    const resourceField = section.fields.find(
      (field) => field.name === "Resource" && field.fieldType === "LKP"
    );

    const resourceColValidators: DataValidators | undefined =
      resourceField && !resourceField.isHidden
        ? {
            resourceOptionId: {
              validators: [
                resourceField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.resourceOptionId,
                resourceLookupOptions?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // SHIFT
    const shiftField = section.fields.find(
      (field) => field.name === "Shift" && field.fieldType === "LKP"
    );

    const shiftColValidators: DataValidators | undefined =
      shiftField && !shiftField.isHidden
        ? {
            shiftOptionId: {
              validators: [
                shiftField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.shiftOptionId,
                shiftLookupOptions?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // COMPANY
    const companyField = section.fields.find(
      (field) => field.name === "Company" && field.fieldType === "LKP"
    );

    const companyColValidators: DataValidators | undefined =
      companyField && !companyField.isHidden
        ? {
            companyOptionId: {
              validators: [
                companyField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.companyOptionId,
                companyLookupOptions?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // TEAM
    const teamField = section.fields.find(
      (field) => field.name === "Team" && field.fieldType === "LKP"
    );

    const teamColValidators: DataValidators | undefined =
      teamField && !teamField.isHidden
        ? {
            teamOptionId: {
              validators: [
                teamField.isRequired
                  ? ValidatorType.ValidOption
                  : ValidatorType.ValidOptionOrEmpty,
              ],
              getData: () => [
                formData.teamOptionId,
                teamLookupOptions?.map((option) => option.id),
              ],
            },
          }
        : undefined;

    // QTY
    const qtyField = section.fields.find((field) => field.name === "Qty");
    const qtyColValidators: DataValidators | undefined =
      qtyField && !qtyField.isHidden && qtyField.isRequired
        ? {
            quantity: {
              validators: [ValidatorType.PositiveNumberOrZero],
            },
          }
        : undefined;

    // HOURS
    const hoursField = section.fields.find((field) => field.name === "Hours");
    const hoursColValidators: DataValidators | undefined =
      hoursField && !hoursField.isHidden && hoursField.isRequired
        ? {
            hours: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    // Remarks
    const remarksField = section.fields.find(
      (field) => field.name === "Remarks"
    );
    const remarksColValidators: DataValidators | undefined =
      remarksField && !remarksField.isHidden && remarksField.isRequired
        ? {
            remarks: {
              validators: [ValidatorType.Required],
            },
          }
        : undefined;

    return {
      ...resourceColValidators,
      ...companyColValidators,
      ...teamColValidators,
      ...shiftColValidators,
      ...qtyColValidators,
      ...hoursColValidators,
      ...remarksColValidators,
    };
  }, [
    section,
    formData,
    resourceLookupOptions,
    shiftLookupOptions,
    companyLookupOptions,
    teamLookupOptions,
  ]);

  return dataValidators;
};
