import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationRevocationMutation,
  RecordVariationRevocationMutationVariables,
  SendVariationRevocationMutation,
  SendVariationRevocationMutationVariables,
  VariationRevocationPromptQuery,
  VariationRevocationPromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "types/decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationRevocationMutation,
  sendVariationRevocationMutation,
  variationRevocationPromptQuery,
} from "./CancelInstructionOfVariationAction.query";

export const useCancelInstructionOfVariationAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationRevocationPromptQuery,
    VariationRevocationPromptQueryVariables
  >(variationRevocationPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationRevocationPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [
    sendVariationRevocationNotice,
    { loading: sendVariationRevocationNoticeLoading },
  ] = useGraphMutation<
    SendVariationRevocationMutation,
    SendVariationRevocationMutationVariables
  >(
    sendVariationRevocationMutation,
    { update: onUpdated },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Variations.variationRevocation"),
    })
  );

  const [
    recordVariationRevocationNotice,
    { loading: recordVariationRevocationNoticeLoading },
  ] = useGraphMutation<
    RecordVariationRevocationMutation,
    RecordVariationRevocationMutationVariables
  >(
    recordVariationRevocationMutation,
    { update: onUpdated },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.variationRevocation"),
    })
  );

  return {
    remarks: promptData?.variationRevocationPrompt.remarks ?? "",
    loading,
    sendVariationRevocationNotice,
    recordVariationRevocationNotice,
    actionLoading:
      sendVariationRevocationNoticeLoading ||
      recordVariationRevocationNoticeLoading,
  };
};
