import {
  RecordVariationErrorDeterminationDisagreementMutation,
  RecordVariationErrorDeterminationDisagreementMutationVariables,
  SendVariationErrorDeterminationDisagreementMutation,
  SendVariationErrorDeterminationDisagreementMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OnMutationDone } from "types/decl";
import {
  recordVariationErrorDeterminationDisagreementMutation,
  sendVariationErrorDeterminationDisagreementMutation,
} from "./DisagreeWithNoticeOfErrorInDeterminationAction.query";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useDisagreeWithNoticeOfErrorInDetermination = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationHistoryItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationProposalExplorerItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendErrorDeterminationDisagreement,
    { loading: sendErrorDeterminationDisagreementLoading },
  ] = useGraphMutation<
    SendVariationErrorDeterminationDisagreementMutation,
    SendVariationErrorDeterminationDisagreementMutationVariables
  >(
    sendVariationErrorDeterminationDisagreementMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t(
        "Projects.Variations.successMessages.disagreementErrorInDetermination"
      ),
    })
  );

  const [
    recordErrorDeterminationDisagreement,
    { loading: recordErrorDeterminationDisagreementLoading },
  ] = useGraphMutation<
    RecordVariationErrorDeterminationDisagreementMutation,
    RecordVariationErrorDeterminationDisagreementMutationVariables
  >(
    recordVariationErrorDeterminationDisagreementMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t(
        "Projects.Variations.successMessages.disagreementErrorInDetermination"
      ),
    })
  );

  return {
    actionLoading:
      sendErrorDeterminationDisagreementLoading ||
      recordErrorDeterminationDisagreementLoading,
    sendErrorDeterminationDisagreement,
    recordErrorDeterminationDisagreement,
  };
};
