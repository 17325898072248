import { useTheme } from "@mui/material";
import {
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDraftProductItemsTableColumns } from "../ProductItemsView.constants";
import { exportToExcel } from "../../../../../helpers/exportToExcel";
import { ProductItemsTablePublicAPI } from "../ProductItemsView.decl";
import { ProductItem } from "containers/Projects/Projects.decl";
import { excelDateTimeISOFormat } from "constants/constants";

export type DraftProductItemsTableProps<T extends ProductItem> = {
  draftProductItems: T[];
  loading: boolean;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  schemaContainsSeveritySection?: boolean;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
  onDeleteDraft: (draftId: string) => void;
  onNavigateToDraftProductItem: (draftId: string) => void;
};

export const DraftProductItemsTable = <T extends ProductItem>({
  draftProductItems,
  loading,
  apiRef,
  selectionModel,
  schemaContainsSeveritySection,
  onDeleteDraft,
  onSelectionModelChange,
  onNavigateToDraftProductItem,
}: DraftProductItemsTableProps<T>) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const gridApiRef = useGridApiRef();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "dateModified", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = useCallback(async () => {
    const columns = [
      { header: t("common.labels.title"), key: "title" },
      {
        header: t("common.labels.dateModified"),
        key: "dateModified",
        numFmt: excelDateTimeISOFormat,
      },
    ];

    const rows = draftProductItems
      .filter(
        (draftProductItem) =>
          (selectionModel || []).indexOf(draftProductItem.id) >= 0
      )
      .map((draftProductItem) => ({
        ...draftProductItem,
        dateModified: draftProductItem.dateModified
          ? new Date(draftProductItem.dateModified)
          : "",
      }));

    exportToExcel(t("Projects.Risks.draftRisks"), columns, rows);
  }, [draftProductItems, selectionModel, t]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (clickedRowData: GridRowParams<T>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        onNavigateToDraftProductItem(clickedRowData.row.id);
      }
    },
    [onNavigateToDraftProductItem]
  );

  const handleClearSelection = useCallback(() => {
    onSelectionModelChange([]);
  }, [onSelectionModelChange]);

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    onSelectionModelChange(newSelectionModel);
  };

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  const columns = useMemo(() => {
    return getDraftProductItemsTableColumns<T>(
      onDeleteDraft,
      t,
      theme,
      schemaContainsSeveritySection
    );
  }, [onDeleteDraft, t, theme, schemaContainsSeveritySection]);

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={draftProductItems || []}
      columns={columns}
      getRowId={(rowData: T) => rowData.id}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading}
      sortingMode="client"
      sortModel={sortingModel}
      onSortModelChange={setSortingModel}
      filterMode="client"
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onRowClick={handleRowClick}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
