import { gql } from "@apollo/client";

export const addItemStatusOptionReasonMutation = gql`
  mutation addItemStatusOptionReason($input: AddItemStatusOptionReasonInput!) {
    addItemStatusOptionReason(input: $input) {
      id
      displayOrder
      description
      statusOptionId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;

export const editItemStatusOptionReasonMutation = gql`
  mutation editItemStatusOptionReason(
    $input: EditItemStatusOptionReasonInput!
  ) {
    editItemStatusOptionReason(input: $input) {
      id
      displayOrder
      description
    }
  }
`;

export const changeItemStatusOptionReasonStatusMutation = gql`
  mutation changeItemStatusOptionReasonStatus(
    $id: ID!
    $status: ItemStatusOptionReasonStatus!
  ) {
    changeItemStatusOptionReasonStatus(id: $id, status: $status) {
      id
      displayOrder
      description
    }
  }
`;
