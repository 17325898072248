import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const itemStatusOptionFields = gql`
  ${creatorFields}

  fragment ItemStatusOptionFields on ItemStatusOption {
    id
    displayOrder
    description
    equatesToRemoved
    style
    statusCollectionId
    isInternal
    status
    creatorId
    creator {
      ...CreatorFields
    }
    dateCreated
  }
`;
