import {
  ClaimDeterminationNoticePromptQuery,
  ClaimDeterminationNoticePromptQueryVariables,
  DraftClaimDetermination,
  RecordClaimDeterminationNoticeMutation,
  RecordClaimDeterminationNoticeMutationVariables,
  SendClaimDeterminationNoticeMutation,
  SendClaimDeterminationNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  claimDeterminationNoticePromptQuery,
  recordClaimDeterminationNoticeMutation,
  sendClaimDeterminationNoticeMutation,
} from "./NotifyClaimDeterminationAction.query";
import { OnMutationDone } from "types/decl";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";

export const useNotifyClaimDeterminationAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    ClaimDeterminationNoticePromptQuery,
    ClaimDeterminationNoticePromptQueryVariables
  >(claimDeterminationNoticePromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
  });

  const handleMutationDone: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimItems",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimHistoryItems",
        args: { claimId: claim!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimDetailsExplorerItems",
        args: { claimId: claim!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimAction",
      });
      cache.gc();
      onChange?.();
    },
    [onChange, claim]
  );

  const [
    sendClaimDeterminationNotice,
    { loading: sendClaimDeterminationNoticeLoading },
  ] = useGraphMutation<
    SendClaimDeterminationNoticeMutation,
    SendClaimDeterminationNoticeMutationVariables
  >(
    sendClaimDeterminationNoticeMutation,
    {
      update: handleMutationDone,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t(
          `Projects.Claims.claimDetermination${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordClaimDeterminationNotice,
    { loading: recordClaimDeterminationNoticeLoading },
  ] = useGraphMutation<
    RecordClaimDeterminationNoticeMutation,
    RecordClaimDeterminationNoticeMutationVariables
  >(
    recordClaimDeterminationNoticeMutation,
    {
      update: handleMutationDone,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t(
          `Projects.Claims.claimDetermination${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const draftClaimDeterminations = useMemo(
    () =>
      (promptData?.claimDeterminationNoticePrompt.draftClaimDeterminations ??
        []) as DraftClaimDetermination[],
    [promptData]
  );

  return {
    draftClaimDeterminations,
    loading,
    actionLoading:
      sendClaimDeterminationNoticeLoading ||
      recordClaimDeterminationNoticeLoading,
    sendClaimDeterminationNotice,
    recordClaimDeterminationNotice,
  };
};
