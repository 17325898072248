import { gql } from "@apollo/client";
import { variationRecipientFields } from "graphql/fragments/variationRecipient.fragment";

export const variationErrorDeterminationDisagreementPromptQuery = gql`
  query variationErrorDeterminationDisagreementPrompt(
    $input: VariationErrorDeterminationDisagreementPromptInput!
  ) {
    variationErrorDeterminationDisagreementPrompt(input: $input) {
      remarks
    }
  }
`;

export const sendVariationErrorDeterminationDisagreementMutation = gql`
  mutation sendVariationErrorDeterminationDisagreement(
    $input: SendVariationErrorDeterminationDisagreementInput!
  ) {
    sendVariationErrorDeterminationDisagreement(input: $input) {
      id
    }
  }
`;

export const recordVariationErrorDeterminationDisagreementMutation = gql`
  mutation recordVariationErrorDeterminationDisagreement(
    $input: RecordVariationErrorDeterminationDisagreementInput!
  ) {
    recordVariationErrorDeterminationDisagreement(input: $input) {
      id
    }
  }
`;

export const generateSendVariationErrorDeterminationDisagreementPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateSendVariationErrorDeterminationDisagreementPreview(
    $input: SendVariationErrorDeterminationDisagreementPreviewInput!
  ) {
    generateSendVariationErrorDeterminationDisagreementPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;

export const generateRecordVariationErrorDeterminationDisagreementPreviewMutation = gql`
  ${variationRecipientFields}

  mutation generateRecordVariationErrorDeterminationDisagreementPreview(
    $input: RecordVariationErrorDeterminationDisagreementPreviewInput!
  ) {
    generateRecordVariationErrorDeterminationDisagreementPreview(
      input: $input
    ) {
      recipients {
        ...VariationRecipientFields
      }
    }
  }
`;
