import {
  ExtendedOptionStyles,
  StatusOptionNew,
} from "components/StatusTag/StatusTagNew";
import { CompEventStatus } from "generated/graphql";

export const compEventStatusOptions: StatusOptionNew<string>[] = [
  {
    id: CompEventStatus.Notified,
    label: CompEventStatus.Notified,
    style: ExtendedOptionStyles.Grey,
  },
  {
    id: CompEventStatus.Confirmed,
    label: CompEventStatus.Confirmed,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: CompEventStatus.Quoted,
    label: CompEventStatus.Quoted,
    style: ExtendedOptionStyles.Purple,
  },
  {
    id: CompEventStatus.Assessing,
    label: CompEventStatus.Assessing,
    style: ExtendedOptionStyles.Amber,
  },
  {
    id: CompEventStatus.Implemented,
    label: CompEventStatus.Implemented,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: CompEventStatus.Rejected,
    label: CompEventStatus.Rejected,
    style: ExtendedOptionStyles.Red,
  },
  {
    id: CompEventStatus.Proposed,
    label: CompEventStatus.Proposed,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: CompEventStatus.Abandoned,
    label: CompEventStatus.Abandoned,
    style: ExtendedOptionStyles.Red,
  },
];
