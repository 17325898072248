import {
  CompEventItem,
  CompEventRegardingType,
  Contract,
  EventItem,
  InstructionItem,
} from "generated/graphql";
import { createContext } from "react";

export type CompEventWidgetContextType = {
  productInstanceId: string;
  productInstanceNumberingFormat: string;
  recordSupported: boolean;
  projectId: string;
  projectName: string;
  contract: Contract;
  regardingId: string;
  regardingType: CompEventRegardingType;
  compEvent?: CompEventItem;
  instructionItem?: InstructionItem;
  eventItem?: EventItem;
  onChange?: () => void;
};

const contextDefaultValue: CompEventWidgetContextType = {
  productInstanceId: "",
  productInstanceNumberingFormat: "",
  projectId: "",
  projectName: "",
  recordSupported: false,
  contract: {} as Contract,
  regardingId: "",
  regardingType: CompEventRegardingType.Event,
  compEvent: undefined,
  onChange: undefined,
  instructionItem: undefined,
  eventItem: undefined,
};

export const CompEventWidgetContext =
  createContext<CompEventWidgetContextType>(contextDefaultValue);
