import { Box } from "@mui/material";
import { PasswordInfo } from "components/PasswordInfo";
import { FormLabel } from "components/FormLabel";

export const PasswordFieldLabel: React.FC<{
  label: string;
  error?: boolean;
}> = ({ label, error }) => (
  <Box display="flex" alignItems="flex-start">
    <FormLabel label={label} required />
    <PasswordInfo error={error} />
  </Box>
);
