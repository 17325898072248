import { Box, CircularProgress, Typography } from "@mui/material";
import { SchemaField } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { placeholdersList } from "../../NewTemplate.constants";
import { PlaceholderListItem } from "./PlaceholderListItem";

export type PlaceholdersPopperContentType = {
  schemaFields: SchemaField[];
  loading?: boolean;
};

export const PlaceholdersPopperContent: React.FC<
  PlaceholdersPopperContentType
> = ({ schemaFields, loading }) => {
  const { t } = useTranslation();

  return loading ? (
    <Box display="flex" justifyContent="center" width="100%">
      <CircularProgress />
    </Box>
  ) : (
    <Box
      py={2}
      display="flex"
      flexDirection="column"
      sx={{ maxHeight: "400px", overflow: "auto" }}
    >
      <Typography variant="body1" mb={1} px={2}>
        {t("AdminConsole.Templates.labels.commonPlaceholders")}
      </Typography>
      {placeholdersList.map((placeholder) => (
        <PlaceholderListItem
          key={placeholder.value}
          placeholder={placeholder}
        />
      ))}
      <Typography variant="body1" mb={1} mt={2} px={2}>
        {t("AdminConsole.Templates.labels.schemaPlaceholders")}
      </Typography>
      {schemaFields.map((field) => (
        <PlaceholderListItem
          key={field.id}
          placeholder={{ label: field.name, value: field.name }}
        />
      ))}
    </Box>
  );
};
