import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { ProductInstance, User, UserStatus } from "generated/graphql";
import { statusComparatorFunction } from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";
import { useActiveRemovedSuspendedStatusOptions } from "components/StatusTag/useActiveRemovedSuspendedOptions";
import { StyledLink } from "components/StyledLink";
import { NewAppPaths } from "helpers/paths/paths";
import { Box, Typography } from "@mui/material";

const usetGetProductInstancesColumnConfigs = (
  contractProductInstances: ProductInstance[]
): GridColDef<User>[] => {
  return contractProductInstances.map((productInstance) => ({
    field: `productInstance-${productInstance.id}}`,
    headerName: productInstance.description,
    flex: 0.125,
    minWidth: 125,
    resizable: true,
    valueGetter: (_, row) => {
      return row.roles.items.find(
        (role) => role.productInstanceId === productInstance.id
      )?.productRole.name;
    },
  }));
};

export const useColumns = (
  contractProductInstances: ProductInstance[]
): GridColDef<User>[] => {
  const statusOptions =
    useActiveRemovedSuspendedStatusOptions() as StatusOption<UserStatus>[];

  const { t } = useTranslation();
  const productInstancesColumnConfigs = usetGetProductInstancesColumnConfigs(
    contractProductInstances
  );

  const columns = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: t("common.labels.name"),
          flex: 0.175,
          minWidth: 180,
          resizable: true,
          editable: false,
          valueGetter: (_, row) => (row.registered ? getUserName(row) : ""),
          renderCell: (params: GridRenderCellParams<User, any, any>) => {
            const userName = params.value;

            return (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <StyledLink
                  to={NewAppPaths.authorized.AdminConsole.children.Companies.children.CompanyDetails.children.UserDetails.pathConstructor(
                    params.row.companyId,
                    params.row.id
                  )}
                  onClick={(evt) => {
                    evt.stopPropagation();
                  }}
                >
                  <Typography variant="body2">{userName}</Typography>
                </StyledLink>
              </Box>
            );
          },
        },
        {
          field: "email",
          headerName: t("common.labels.email"),
          editable: false,
          flex: 0.225,
          minWidth: 180,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.1,
          minWidth: 110,
          resizable: false,
          sortComparator: statusComparatorFunction,
          renderCell: (params: GridRenderCellParams<User, any, any>) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disabled
              />
            );
          },
        },
        {
          field: "company",
          headerName: t("common.labels.company"),
          flex: 0.2,
          minWidth: 180,
          resizable: true,
          valueGetter: (_, row) => {
            return row.company.tradingName;
          },
        },
        ...productInstancesColumnConfigs,
      ] as GridColDef<User>[],
    [t, statusOptions, productInstancesColumnConfigs]
  );

  return columns;
};
