import { Button, Stack, StackProps, Typography } from "@mui/material";
import {
  PrimarySecondaryButtons,
  PrimarySecondaryButtonsProps,
} from "./PrimarySecondaryButtons";
import { useTranslation } from "react-i18next";

export type PrimarySecondaryTertiaryButtonsProps = {
  tertiaryBtnCaption?: string;
  tertiaryBtnDisabled?: boolean;
  onTertiaryClick: () => void;
  containerProps?: StackProps;
} & PrimarySecondaryButtonsProps;

export const PrimarySecondaryTertiaryButtons: React.FC<
  PrimarySecondaryTertiaryButtonsProps
> = ({
  containerProps,
  tertiaryBtnCaption,
  tertiaryBtnDisabled,
  onTertiaryClick,
  ...restButtonProps
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={2} {...containerProps}>
      <Button
        variant="text"
        onClick={onTertiaryClick}
        data-testid="tertiary-btn"
      >
        <Typography variant="p2" fontWeight={700} color="grey.800">
          {tertiaryBtnCaption ?? t("common.buttons.cancel")}
        </Typography>
      </Button>
      <PrimarySecondaryButtons {...restButtonProps} />
    </Stack>
  );
};
