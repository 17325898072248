import {
  AuthorizationWorkflowLevel,
  AuthorizationWorkflowLevelInput,
} from "generated/graphql";
import { AuthorizationWorkflowLevelInputWithID } from "../components/AuthorizationWorkflowLevels/AuthorizationWorkflowLevels";

export const authorizationWorkflowLevelsToAuthWorkflowLevelsInput = (
  authWorkflowLevels:
    | AuthorizationWorkflowLevel[]
    | AuthorizationWorkflowLevelInputWithID[]
): AuthorizationWorkflowLevelInput[] =>
  authWorkflowLevels.map((level) => ({
    mode: level.mode,
    sequence: level.sequence,
    userIds: level.userIds,
  }));
