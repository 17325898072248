import { Box } from "@mui/material";
import {
  ProductType,
  VariationHistoryItem,
  VariationAgreement,
  VariationDetermination,
  Contract,
} from "generated/graphql";
import { memo, useContext } from "react";
import { HistoryItemMeta } from "../../HistoryModal/HistoryItemMeta";
import { VariationWidgetContext } from "../VariationWidget/VariationWidget.context";
import { VariationProposalAgreementDeterminationReadOnlyModal } from "../VariationProposalAgreementDeterminationReadOnlyModal/VariationProposalAgreementDeterminationReadOnlyModal";
import { useVariationProposalAgreementDeterminationModal } from "../VariationProposalAgreementDeterminationModal/useVariationProposalAgreementDeterminationModal";
import { VOProposalsAgreementsDeterminationsTable } from "../VariationActionModal/components/VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";
import { ReasonSummary } from "../../ActionModal/ReasonSummary";
import { ConsentToProposalForProposedVariationSummary } from "../VariationActionModal/ConsentToProposalForProposedVariationAction/ConsentToProposalForProposedVariationSummary";
import { useTranslation } from "react-i18next";
import { InstructWorkToCommenceSummary } from "../VariationActionModal/InstructWorkToCommenceAction/InstructWorkToCommenceSummary";
import { ReasonsAttachmentsSummary } from "../../ActionModal/ReasonsAttachmentsSummary";

type VariationHistoryItemDetailsSummaryProps = {
  historyItem: VariationHistoryItem;
  contract: Contract;
};

export const VariationHistoryItemDetailsSummary: React.FC<
  VariationHistoryItemDetailsSummaryProps
> = ({ historyItem, contract }) => {
  const { t } = useTranslation();
  const {
    modalVisibility: voItemModalVisibility,
    toggleModalVisibility: toggleVOItemModalVisibility,
  } = useVariationProposalAgreementDeterminationModal();

  if (
    historyItem.answers?.__typename ===
      "VariationHistoryProposalForProposedVariationAnswers" ||
    historyItem.answers?.__typename ===
      "VariationHistoryProposalForVariationAnswers"
  ) {
    const currency =
      contract.valueCurrency ?? historyItem.answers.proposal.currency;

    return (
      <>
        <VariationProposalAgreementDeterminationReadOnlyModal
          open={voItemModalVisibility}
          onClose={toggleVOItemModalVisibility}
          item={historyItem.answers.proposal}
          contract={contract}
        />
        <VOProposalsAgreementsDeterminationsTable
          contractCurrency={currency}
          type="VariationProposal"
          items={[historyItem.answers.proposal]}
          onRowClick={toggleVOItemModalVisibility}
        />
      </>
    );
  } else if (
    historyItem.answers?.__typename ===
      "VariationHistoryObjectionProposedVariationAnswers" ||
    historyItem.answers?.__typename ===
      "VariationHistoryObjectionVariationAnswers"
  ) {
    return <ReasonSummary reason={historyItem.answers.reasons} />;
  } else if (
    historyItem.answers?.__typename ===
    "VariationHistoryConsentForProposedVariationAnswers"
  ) {
    return (
      <ConsentToProposalForProposedVariationSummary
        remarks={historyItem.answers.remarks}
        proposal={historyItem.answers.proposal}
        attachments={[]}
        allowViewDetails
        contract={contract}
      />
    );
  } else if (
    historyItem.answers?.__typename === "VariationHistoryAgreementAnswers"
  ) {
    // Note: agreement is optional to handle the case of pending authorization, but in historyView, there should always be an agreement
    const currency =
      contract.valueCurrency ??
      historyItem.answers.agreement?.currency ??
      historyItem.authorizationWorkflowAudit?.action.productInstance.contract
        .valueCurrency;

    const agreement = (
      historyItem.isIncomplete
        ? historyItem.answers.draftAgreement!
        : historyItem.answers.agreement!
    ) as VariationAgreement;

    return (
      <>
        <VariationProposalAgreementDeterminationReadOnlyModal
          open={voItemModalVisibility}
          onClose={toggleVOItemModalVisibility}
          item={agreement}
          contract={contract}
        />
        <VOProposalsAgreementsDeterminationsTable
          contractCurrency={currency ?? ""}
          type="VariationAgreement"
          items={[agreement]}
          onRowClick={toggleVOItemModalVisibility}
        />
      </>
    );
  } else if (
    historyItem.answers?.__typename ===
      "VariationHistoryNoAgreementReachedAnswers" ||
    historyItem.answers?.__typename ===
      "VariationHistoryRevisedProposalRequestAnswers" ||
    historyItem.answers?.__typename === "VariationHistoryAbandonmentAnswers" ||
    historyItem.answers?.__typename ===
      "VariationHistoryRevocationVariationAnswers" ||
    historyItem.answers?.__typename ===
      "VariationHistoryConfirmationVariationAnswers" ||
    historyItem.answers?.__typename ===
      "VariationHistoryErrorAgreementDisagreementAnswers" ||
    historyItem.answers?.__typename ===
      "VariationHistoryErrorDeterminationDisagreementAnswers"
  ) {
    return (
      <ReasonSummary
        reason={historyItem.answers.remarks}
        label={t("common.labels.remarks")}
      />
    );
  } else if (
    historyItem.answers?.__typename === "VariationHistoryDeterminationAnswers"
  ) {
    // Note: determination is optional to handle the case of pending authorization, but in historyView, there should always be an determination
    const currency =
      contract.valueCurrency ??
      historyItem.answers.determination?.currency ??
      historyItem.authorizationWorkflowAudit?.action.productInstance.contract
        .valueCurrency;

    const determination = (
      historyItem.isIncomplete
        ? historyItem.answers.draftDetermination!
        : historyItem.answers.determination!
    ) as VariationDetermination;

    return (
      <>
        <VariationProposalAgreementDeterminationReadOnlyModal
          open={voItemModalVisibility}
          onClose={toggleVOItemModalVisibility}
          item={determination}
          contract={contract}
        />
        <VOProposalsAgreementsDeterminationsTable
          contractCurrency={currency ?? ""}
          type="VariationDetermination"
          items={[determination]}
          onRowClick={toggleVOItemModalVisibility}
        />
      </>
    );
  } else if (
    historyItem.answers?.__typename ===
    "VariationHistoryInstructionCommenceWorkAnswers"
  ) {
    return (
      <InstructWorkToCommenceSummary
        text={historyItem.answers.remarks}
        noAgreement={!historyItem.answers.agreementSubmitted}
        contractTimezone={contract.timeZone}
        pastWording
        attachments={[]}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
    "VariationHistoryFurtherParticularsRequestAnswers"
  ) {
    return (
      <ReasonsAttachmentsSummary
        text={historyItem.answers.reasons}
        label={t("Projects.Variations.ActionModal.furtherParticularsReasons")}
        attachments={[]}
        contractTimezone={contract.timeZone}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
    "VariationHistoryFurtherParticularsSubmissionAnswers"
  ) {
    return (
      <ReasonsAttachmentsSummary
        text={historyItem.answers.details}
        label={t("Projects.Variations.ActionModal.furtherParticulars")}
        attachments={[]}
        contractTimezone={contract.timeZone}
      />
    );
  } else if (
    historyItem.answers?.__typename ===
      "VariationHistoryErrorAgreementNoticeAnswers" ||
    historyItem.answers?.__typename ===
      "VariationHistoryErrorDeterminationNoticeAnswers"
  ) {
    return (
      <ReasonsAttachmentsSummary
        text={historyItem.answers.details}
        label={t("Projects.Variations.ActionModal.errorsFound")}
        attachments={[]}
        contractTimezone={contract.timeZone}
      />
    );
  }

  return null;
};

type VariationHistoryItemDataViewProps = {
  historyItem: VariationHistoryItem;
};

export const VariationHistoryItemDataView: React.FC<VariationHistoryItemDataViewProps> =
  memo(({ historyItem }) => {
    const { contract } = useContext(VariationWidgetContext);

    return (
      <Box display="flex" flexDirection="column">
        <VariationHistoryItemDetailsSummary
          historyItem={historyItem}
          contract={contract}
        />
        <Box mt={3}>
          <HistoryItemMeta
            contractTimezone={contract.timeZone}
            historyItem={historyItem}
            productType={ProductType.Variations}
          />
        </Box>
      </Box>
    );
  });
