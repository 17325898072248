import { createContext, useMemo } from "react";

export type AttachmentsContextType = {
  timezone: string;
};

const contextDefaultValue: AttachmentsContextType = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const AttachmentsContext =
  createContext<AttachmentsContextType>(contextDefaultValue);

export const AttachmentsContextProvider: React.FC<{
  timezone: string;
  children: React.ReactNode;
}> = ({ children, timezone }) => {
  const contextData = useMemo(
    () => ({
      timezone,
    }),
    [timezone]
  );

  return (
    <AttachmentsContext.Provider value={contextData}>
      {children}
    </AttachmentsContext.Provider>
  );
};
