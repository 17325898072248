import { gql } from "@apollo/client";

export const contractByProductInstanceQuery = gql`
  query contractByProductInstance($id: ID!) {
    productInstance(id: $id) {
      id
      description
      numberingFormat
      status
      product {
        id
        name
      }
      contract {
        id
        name
        friendlyName
        status
        timeZone
        valueCurrency
        contractType {
          description
          subType
          version
        }
        project {
          id
          name
          friendlyName
          status
        }
      }
    }
  }
`;
