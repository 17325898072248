import { Button, ButtonProps, styled, Typography } from "@mui/material";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

const StyledDescriptionBtn = styled(Button)`
  height: 20px !important;
`;
export const AddDescriptionBtn: React.FC<ButtonProps> = ({
  onClick,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();

  const handleBtnClick: MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.stopPropagation();
    onClick?.(evt);
  };

  return (
    <StyledDescriptionBtn
      variant="text"
      onClick={handleBtnClick}
      {...restProps}
      sx={{ padding: 0, height: "20px" }}
    >
      {children ?? (
        <Typography variant="p2" sx={{ textDecoration: "underline" }}>
          {t("Attachments.addADescription")}
        </Typography>
      )}
    </StyledDescriptionBtn>
  );
};
