import { AddContractBindingInput } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";

export const defaultContractBinding: AddContractBindingInput = {
  companyId: "",
  contractBindingTypeId: "",
  contractId: "",
  defaultCompany: false,
  isCompanyBilled: false,
  representativeId: "",
};

export const dataValidators: DataValidators = {
  companyId: {
    validators: [ValidatorType.Required],
  },
  contractBindingTypeId: {
    validators: [ValidatorType.Required],
  },
};
