import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

// TODO: See if it really needs to contain all the nested data. Especially for Register view
export const compEventItemsQuery = gql`
  ${compEventItemFields}

  query compEventItems($productInstanceId: ID!) {
    compEventItems(productInstanceId: $productInstanceId) {
      items {
        ...CompEventItemFields
      }
    }
  }
`;
