import { useGraphQuery } from "hooks/useGraphQuery";
import { localCompEventItemQuery } from "./useCompEvent.query";
import {
  CompEventItem,
  LocalCompEventItemQuery,
  LocalCompEventItemQueryVariables,
} from "generated/graphql";
import { ErrorHandlingType } from "hooks/useGraphMutation";

export const useCompEvent = (id?: string) => {
  const {
    data: compEventItem,
    refetch,
    loading,
    error,
  } = useGraphQuery<LocalCompEventItemQuery, LocalCompEventItemQueryVariables>(
    localCompEventItemQuery,
    {
      variables: { id: id! },
      skip: !id,
      notifyOnNetworkStatusChange: true,
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  return {
    compEvent: compEventItem?.compEventItem as CompEventItem | undefined,
    refetchCompEvent: refetch,
    compEventLoading: loading,
    error,
  };
};
