import { gql } from "@apollo/client";
import { instructionItemBaseFields } from "graphql/fragments/instructionItem.fragment";
import { attachmentFields } from "../EarlyWarningDetails/EarlyWarningDetails.query";
import { productItemProductInstanceFields } from "../RiskDetails/RiskDetails.query";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";

const localInstructionFields = gql`
  ${instructionItemBaseFields}
  ${productItemProductInstanceFields}
  ${attachmentFields}
  ${notificationRecipientFields}

  fragment LocalInstructionFields on InstructionItem {
    __typename
    ...InstructionItemBaseFields
    productInstance {
      ...ProductInstanceFields
    }
    attachments {
      ...AttachmentFields
    }
    compEvent {
      id
    }
    claim {
      id
    }
    variation {
      id
    }
    authorizationWorkflowAudit {
      id
    }
    notificationRecipients {
      ...NotificationRecipientFields
    }
  }
`;

export const instructionItemExtendedQuery = gql`
  ${localInstructionFields}

  query instructionItemExtended($id: ID!) {
    instructionItem(id: $id) {
      ...LocalInstructionFields
    }
  }
`;

export const editInstructionItemMutation = gql`
  ${localInstructionFields}

  mutation editInstructionItem($input: EditInstructionItemInput!) {
    editInstructionItem(input: $input) {
      ...LocalInstructionFields
    }
  }
`;

export const changeInstructionItemStatusMutation = gql`
  ${localInstructionFields}

  mutation changeInstructionItemStatus(
    $input: ChangeInstructionItemStatusInput!
  ) {
    changeInstructionItemStatus(input: $input) {
      ...LocalInstructionFields
    }
  }
`;
