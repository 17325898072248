import { gql } from "@apollo/client";

export const dailyDiaryPresetSectionFieldFields = gql`
  fragment DailyDiaryPresetSectionFieldFields on DailyDiaryPresetSectionField {
    name # fieldName, unique
    displayName
    fieldType
    isRequired
    lookupId
    isHidden
    unit
  }
`;
