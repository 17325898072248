import {
  DailyDiaryItem,
  DailyDiaryWorkImportSourceItemsQuery,
  DailyDiaryWorkImportSourceItemsQueryVariables,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { dailyDiaryWorkImportSourceItemsQuery } from "./WorkSection.query";

export const useAvailableDiariesToImportFrom = () => {
  const [
    fetchAvailableDiaries,
    { data: availableDiariesToImportFrom, loading },
  ] = useGraphLazyQueryLite<
    DailyDiaryWorkImportSourceItemsQuery,
    DailyDiaryWorkImportSourceItemsQueryVariables
  >(dailyDiaryWorkImportSourceItemsQuery);

  return {
    availableDiariesToImportFrom: (availableDiariesToImportFrom?.data
      .dailyDiaryWorkImportSourceItems.items ?? []) as DailyDiaryItem[],
    availableDiariesToImportFromLoading: loading,
    fetchAvailableDiaries,
  };
};
