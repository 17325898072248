import { useGraphQuery } from "hooks/useGraphQuery";
import { contractByIdQuery } from "./useContract.query";
import {
  Contract,
  ContractByIdQuery,
  ContractByIdQueryVariables,
} from "generated/graphql";
import { useMemo } from "react";

export const useContract = (contractId?: string) => {
  const { data: contractData, loading: contractLoading } = useGraphQuery<
    ContractByIdQuery,
    ContractByIdQueryVariables
  >(contractByIdQuery, {
    variables: { id: contractId! },
    skip: !contractId,
  });

  const contract = useMemo(
    () => contractData?.contract as Contract,
    [contractData]
  );

  return {
    contract,
    contractLoading,
  };
};
