import { Box, Collapse } from "@mui/material";
import { InviteUserRoleInput, Project } from "generated/graphql";
import { useState, useMemo, useContext } from "react";
import { ContractRolesContainer } from "./ContractRolesContainer";
import { ParentEntityRoleHeader } from "./ParentEntityRoleHeader";
import {
  acceptedRoleNames,
  defaultProductInstanceRole,
} from "./ProductInstanceRolesMapper";
import { InviteUserContext } from "../InviteUser.context";
import uniqBy from "lodash.uniqby";

export type LocalInviteUserRoleInput = {
  productInstanceId: string;
  productRoleId: string | undefined;
};

export type ProjectRolesContainerProps = {
  project: Project;
  productInstanceRoles: InviteUserRoleInput[];
  onChange: (newPIRoleMappings: LocalInviteUserRoleInput[]) => void;
};

export const ProjectRolesContainer: React.FC<ProjectRolesContainerProps> = ({
  project,
  productInstanceRoles,
  onChange,
}) => {
  const { contractsWithActiveBindingsIds } = useContext(InviteUserContext);
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed((state) => !state);
  };

  const contracts = useMemo(
    () =>
      project.contracts.items.filter((contract) =>
        contractsWithActiveBindingsIds?.includes(contract.id)
      ) ?? [],
    [project, contractsWithActiveBindingsIds]
  );

  // Taking all the uniq roles from all the product instances of all the contracts
  const roles = useMemo(() => {
    const productInstances = project.contracts.items.flatMap(
      (contract) => contract.productInstances.items
    );
    const rolesLite = uniqBy(
      productInstances.flatMap((pi) => pi.product.roles.items) ?? [],
      "name"
    ).map((role) => ({
      id: role.id,
      name: role.name,
    }));

    return [defaultProductInstanceRole, ...rolesLite].filter(
      (role) => acceptedRoleNames.indexOf(role.name.toLowerCase()) >= 0
    );
  }, [project]);

  const allProductInstances = useMemo(
    () => contracts.map((contract) => contract.productInstances.items).flat(),
    [contracts]
  );

  const handleSetChildrenRole = (selectedRoleName: string) => {
    const allPIRoles = allProductInstances.map((pi) => ({
      productInstanceId: pi.id,
      productRoleId:
        pi.product.roles.items.find(
          (role) => role.name.toLowerCase() === selectedRoleName.toLowerCase()
        )?.id ?? "",
    }));

    onChange(allPIRoles);
  };

  const handleProductInstanceRoleChange = (
    newPIRoleMappings: LocalInviteUserRoleInput[]
  ) => {
    onChange(newPIRoleMappings);
  };

  return (
    <Box boxSizing="border-box">
      <ParentEntityRoleHeader
        title={project.name}
        collapsed={collapsed}
        roles={roles}
        indentationLevel={1}
        onToggleCollapse={toggleCollapse}
        onSetRole={handleSetChildrenRole}
      />
      <Collapse in={!collapsed}>
        {contracts.map((contract) => (
          <ContractRolesContainer
            key={contract.id}
            contract={contract}
            productInstanceRoles={productInstanceRoles.filter((piRole) => {
              const prodInstancesIds = contract.productInstances.items.map(
                (pi) => pi.id
              );

              return prodInstancesIds.indexOf(piRole.productInstanceId) >= 0;
            })}
            onChange={handleProductInstanceRoleChange}
          />
        ))}
      </Collapse>
    </Box>
  );
};
