import {
  RecordVariationErrorAgreementDisagreementMutation,
  RecordVariationErrorAgreementDisagreementMutationVariables,
  SendVariationErrorAgreementDisagreementMutation,
  SendVariationErrorAgreementDisagreementMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  recordVariationErrorAgreementDisagreementMutation,
  sendVariationErrorAgreementDisagreementMutation,
} from "./DisagreeWithNoticeOfErrorInAgreement.query";
import { OnMutationDone } from "types/decl";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useDisagreeWithNoticeOfErrorInAgreement = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationHistoryItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationProposalExplorerItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendErrorAgreementDisagreement,
    { loading: sendErrorAgreementDisagreementLoading },
  ] = useGraphMutation<
    SendVariationErrorAgreementDisagreementMutation,
    SendVariationErrorAgreementDisagreementMutationVariables
  >(
    sendVariationErrorAgreementDisagreementMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t(
        "Projects.Variations.successMessages.disagreementErrorInAgreement"
      ),
    })
  );

  const [
    recordErrorAgreementDisagreement,
    { loading: recordErrorAgreementDisagreementLoading },
  ] = useGraphMutation<
    RecordVariationErrorAgreementDisagreementMutation,
    RecordVariationErrorAgreementDisagreementMutationVariables
  >(
    recordVariationErrorAgreementDisagreementMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t(
        "Projects.Variations.successMessages.disagreementErrorInAgreement"
      ),
    })
  );

  return {
    actionLoading:
      sendErrorAgreementDisagreementLoading ||
      recordErrorAgreementDisagreementLoading,
    sendErrorAgreementDisagreement,
    recordErrorAgreementDisagreement,
  };
};
