import { gql } from "@apollo/client";
import { documentTemplateFields } from "graphql/fragments/documentTemplate.fragment";

export const documentTemplateQuery = gql`
  ${documentTemplateFields}
  query documentTemplate($id: ID!) {
    documentTemplate(id: $id) {
      ...DocumentTemplateFields
    }
  }
`;

export const editDocumentTemplateMutation = gql`
  ${documentTemplateFields}

  mutation editDocumentTemplate($input: EditDocumentTemplateInput!) {
    editDocumentTemplate(input: $input) {
      ...DocumentTemplateFields
    }
  }
`;
