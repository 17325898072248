import { ProductType, VariationHistoryItem } from "generated/graphql";
import { VariationHistoryItemDataView } from "./VariationHistoryItemDataView";
import { BaseHistoryItemDetails } from "../../HistoryModal/BaseHistoryItemDetails";
import { useContext } from "react";
import { VariationWidgetContext } from "../VariationWidget/VariationWidget.context";

type VariationHistoryItemDetailsProps = {
  historyItem: VariationHistoryItem;
};

export const VariationHistoryItemDetails: React.FC<
  VariationHistoryItemDetailsProps
> = ({ historyItem }) => {
  const { isFIDIC17White, isFIDIC99RedYellow, contract } = useContext(
    VariationWidgetContext
  );

  return (
    <BaseHistoryItemDetails
      historyItem={historyItem}
      productType={ProductType.Variations}
      isFIDIC17White={isFIDIC17White}
      isFIDIC99RedYellow={isFIDIC99RedYellow}
      contractTimezone={contract.timeZone}
    >
      <VariationHistoryItemDataView historyItem={historyItem} />
    </BaseHistoryItemDetails>
  );
};
