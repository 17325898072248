import { Box, Grid } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { CompEventGrantingOfMoreTimePrompt } from "generated/graphql";
import { FormLabel } from "components/FormLabel";
import {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useGrantMoreTimeToRespondCEAction } from "./useGrantMoreTimeToRespondCEAction";
import {
  ValidatorType,
  validateData,
  DataValidators,
} from "helpers/validators";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { SendCEGrantMoreTime } from "./SendCEGrantMoreTime";
import { RecordCEGrantMoreTime } from "./RecordCEGrantMoreTime";
import moment from "moment";
import { dailyDiaryDateFormat } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import { ResultingDueDateField } from "../RequestForMoreTimeCEAction/ResultingDueDateField";
import { DaysTextField } from "../RequestForMoreTimeCEAction/DaysTextField";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";

export const dataValidators: DataValidators = {
  grantedDays: {
    validators: [ValidatorType.PositiveNumber],
  },
};

const defaultFormData: CompEventGrantingOfMoreTimePrompt = {
  remarks: "",
  actionDueDate: 0,
  grantedDays: "" as any,
};

export const GrantMoreTimeToRespondCEAction: React.FC<CEActionFormProps> = ({
  apiRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { compEvent } = useContext(CompEventWidgetContext);

  const [formData, setFormData] =
    useState<CompEventGrantingOfMoreTimePrompt>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);

  const {
    sendCompEventGrantingOfMoreTime,
    recordCompEventGrantingOfMoreTime,
    grantMoreTimePromptData,
    loading: grantingMoreTimeLoading,
    actionLoading,
  } = useGrantMoreTimeToRespondCEAction();

  const handleRemarksChange = (remarks: string) => {
    setFormData((curData) => ({
      ...curData,
      remarks,
    }));
  };

  const validateForm = useCallback(
    (formData: CompEventGrantingOfMoreTimePrompt) => {
      const validationResult = validateData(formData, dataValidators);

      if (!validationResult.valid) {
        setFormDataErrors(validationResult.errors);
      } else {
        setFormDataErrors({});
      }

      return validationResult.valid;
    },
    []
  );

  const handleGrantedDaysChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setFormData((curData) => ({
      ...curData,
      grantedDays: event.target.value as any,
    }));

    validateForm({
      ...formData,
      grantedDays: Number(event.target.value),
    });
  };

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCEGrantingOfMoreTime = async () => {
    await sendCompEventGrantingOfMoreTime({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          remarks: formData.remarks,
          grantedDays: formData.grantedDays,
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEGrantingOfMoreTime = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCompEventGrantingOfMoreTime({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          remarks: formData.remarks,
          grantedDays: formData.grantedDays,
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => validateForm(formData),
    [validateForm, formData]
  );

  useEffect(() => {
    setFormData((crtFormData) => ({
      ...crtFormData,
      grantedDays: grantMoreTimePromptData?.grantedDays ?? ("" as any),
    }));
  }, [grantMoreTimePromptData]);

  const computedDueDate = useMemo(() => {
    const crtDueDate = grantMoreTimePromptData?.actionDueDate;
    if (formData.grantedDays < 0) {
      return t("common.labels.n/a");
    }

    return moment(crtDueDate)
      .add(formData.grantedDays ?? 0, "days")
      .format(dailyDiaryDateFormat);
  }, [formData, grantMoreTimePromptData, t]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [validateForm, formData]
  );

  return grantingMoreTimeLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {isFormValid && sendModalVisibility && (
        <SendCEGrantMoreTime
          open={sendModalVisibility}
          remarks={formData.remarks}
          grantedDays={formData.grantedDays}
          onPrimaryClick={handleSendCEGrantingOfMoreTime}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordCEGrantMoreTime
          open={recordModalVisibility}
          remarks={formData.remarks}
          grantedDays={formData.grantedDays}
          onPrimaryClick={handleRecordCEGrantingOfMoreTime}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={9} pr={2.5}>
            <FormLabel
              label={t("Projects.CompEvents.grantMoreTimeLabel")}
              required
            />
            <DaysTextField
              name="grantedDays"
              value={formData.grantedDays}
              onChange={handleGrantedDaysChange}
              placeholder={t("Projects.CompEvents.grantMoreTimePlaceholder")}
              error={!!formDataErrors.grantedDays}
              helperText={formDataErrors.grantedDays}
            />
          </Grid>
          <ResultingDueDateField dueDate={computedDueDate} />
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.remarks")} />
            <RichTextArea
              content={formData.remarks ?? ""}
              onChange={handleRemarksChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
