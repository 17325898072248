import { gql } from "@apollo/client";
import { newClaimTypeOverrideFields } from "graphql/fragments/newClaimType.fragment";

export const newClaimTypesOverrideQuery = gql`
  ${newClaimTypeOverrideFields}

  query newClaimTypesOverride($contractId: ID!) {
    newClaimTypesOverride(contractId: $contractId) {
      items {
        ...NewClaimTypeOverrideFields
      }
    }
  }
`;
