import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClaimItemsTypes } from "./DetailedClaimsAgreementsDeterminationsTable";
import { unofficialThemeColors } from "theme/extendedTheme";

export const EmptyTableView: React.FC<{
  type?: ClaimItemsTypes;
  isFIDIC99RedYellow?: boolean;
}> = ({ type = "DetailedClaim", isFIDIC99RedYellow }) => {
  const { t } = useTranslation();

  const computedLine1 = t(
    `Projects.Claims.DetailedClaimModal.${
      type === "DetailedClaim"
        ? `noDetailedClaims${isFIDIC99RedYellow ? "99RY" : ""}`
        : type === "ClaimAgreement"
        ? `noClaimAgreements${isFIDIC99RedYellow ? "99RY" : ""}`
        : `noClaimDeterminations${isFIDIC99RedYellow ? "99RY" : ""}`
    }`
  );
  const computedLine2 = t(
    `Projects.Claims.DetailedClaimModal.${
      type === "DetailedClaim"
        ? `clickToAddDetailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
        : type === "ClaimAgreement"
        ? `clickToAddClaimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
        : `clickToAddClaimDetermination${isFIDIC99RedYellow ? "99RY" : ""}`
    }`
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      width="100%"
      sx={{ background: unofficialThemeColors.blueWidgetLastStep }}
    >
      <Typography
        variant="h3"
        fontSize="18px"
        fontWeight={600}
        color="grey.800"
      >
        {computedLine1}
      </Typography>
      <Typography variant="p1" fontWeight={400} color="grey.700" mt={1}>
        {computedLine2}
      </Typography>
    </Box>
  );
};
