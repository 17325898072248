import { Box, BoxProps, Button, Typography, useTheme } from "@mui/material";
import { Copy, Plus } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useDailyDiaryStatus } from "../hooks/useDailyDiaryStatus";
import { useContext, useMemo } from "react";
import { DailyDiaryContext } from "../DailyDiaryContextProvider";
import {
  DailyDiaryUserRole,
  useDailyDiaryUserBERole,
} from "hooks/useDailyDiaryUserBERole";
import { useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";

export type BaseHeaderSectionProps = {
  title: string;
  addBtnCaption?: string;
  onImport: () => void;
  onAdd: () => void;
};

export const BaseHeaderSection: React.FC<BaseHeaderSectionProps & BoxProps> = ({
  title,
  addBtnCaption,
  onImport,
  onAdd,
  ...restBoxProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { authenticatedUser } = useContext(GlobalContext);
  const { productInstanceId } = useParams();
  const { isLastRevisionSelected } = useContext(DailyDiaryContext);
  const { isEmptyDailyDiary, isDraftDailyDiary, isLockedDraft } =
    useDailyDiaryStatus();
  const { userRole } = useDailyDiaryUserBERole(productInstanceId!); // Note: no need to know if user is reviewer, as this role has no power over adding records
  const canAddRecords = useMemo(
    () =>
      authenticatedUser?.roles.items.find(
        (role) =>
          role.productInstanceId === productInstanceId &&
          role.productRole.actions.items.find(
            (prodRoleAction) =>
              [PermissionEnum.Add, PermissionEnum.Draft].indexOf(
                prodRoleAction.name as PermissionEnum
              ) >= 0
          )
      ),
    [authenticatedUser, productInstanceId]
  );

  // TODO: remove usage of isLastRevisionSelected once we have Status per revision
  const editable =
    canAddRecords &&
    (isEmptyDailyDiary ||
      (isLastRevisionSelected &&
        ((isLockedDraft && userRole === DailyDiaryUserRole.Owner) ||
          isDraftDailyDiary)));

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...restBoxProps}
    >
      <Typography
        fontSize="20px"
        lineHeight="28px"
        fontWeight={600}
        color="grey.800"
      >
        {title}
      </Typography>
      {editable && (
        <Box display="flex" alignItems="center">
          <Button variant="outlined" onClick={onImport}>
            <Copy size={18} />
            <Typography variant="p2" fontWeight={700} color="grey.800" ml={1}>
              {t("common.buttons.import")}
            </Typography>
          </Button>
          <Box ml={2}>
            <Button variant="contained" onClick={onAdd}>
              <Plus size={18} color={theme.palette.common.white} />
              <Typography variant="p2" fontWeight={700} ml={1}>
                {addBtnCaption ?? title}
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
