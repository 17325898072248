import { useGraphQuery } from "hooks/useGraphQuery";
import { getUserByIdQuery } from "../UserDetails.query";
import {
  GetUserByIdQuery,
  GetUserByIdQueryVariables,
  User,
} from "generated/graphql";
import { useMemo } from "react";

export const useUserById = (userId: string) => {
  const {
    data: userData,
    refetch: reloadUser,
    loading: getUserLoading,
    error: userError,
  } = useGraphQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    getUserByIdQuery,
    {
      variables: { id: userId! },
    }
  );

  const user = useMemo(() => {
    return userData?.user as User | undefined;
  }, [userData]);

  return {
    user,
    reloadUser,
    getUserLoading,
    userError,
  };
};
