import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { DraftVariationProposal, VariationActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordProposalForVariationModal } from "./useRecordProposalForVariationModal";
import { VariationProposalAgreementDeterminationSummary } from "../components/VariationProposalAgreementDeterminationSummary";

export type RecordProposalForVariationModalProps = {
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & {
  draftProposals: DraftVariationProposal[];
  preferredProposalId: string;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordProposalForVariationModal: React.FC<
  RecordProposalForVariationModalProps
> = ({ draftProposals, preferredProposalId, ...restDialogProps }) => {
  const { variationPreview, contract } = useRecordProposalForVariationModal(
    draftProposals,
    preferredProposalId
  );
  return (
    <RecordAction
      actionType={VariationActionType.SubmitProposalForVariation}
      variationPreview={variationPreview}
      summaryEl={
        <VariationProposalAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftProposals}
          type="VariationProposal"
        />
      }
      {...restDialogProps}
    />
  );
};
