import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { itemDataFields } from "./itemData.fragment";

export const claimLastActionFields = gql`
  fragment ClaimLastActionFields on ClaimItemLastAction {
    actionType
    date
    daysLate
    party {
      id
      description
      contractTypeId
      status
    }
  }
`;

export const claimNextStepFields = gql`
  fragment ClaimNextStepFields on ClaimItemNextStep {
    actionType
    dueDate
    partyId
    party {
      id
      description
      contractTypeId
      status
    }
  }
`;

export const claimItemFields = gql`
  ${creatorFields}
  ${itemDataFields}
  ${claimLastActionFields}
  ${claimNextStepFields}

  fragment ClaimItemFields on ClaimItem {
    __typename
    id
    number
    productInstanceId
    regardingId
    regardingType
    status
    advanceWarningId
    dateCreated
    dateModified
    creatorId
    notifiedByPartyId
    lastAction {
      ...ClaimLastActionFields
    }
    nextStep {
      ...ClaimNextStepFields
    }
    creator {
      ...CreatorFields
    }
    productInstance {
      id
      description
      numberingFormat
      status
      product {
        id
        name
      }
      contract {
        id
        name
        friendlyName
        status
        timeZone
        valueCurrency
        project {
          id
          name
          friendlyName
          status
        }
      }
    }
    advanceWarning {
      id
      title
      dateSent
      number
      title
      productInstance {
        id
        product {
          id
        }
      }
    }
    regardingEvent {
      id
      title
      productInstanceId
      number
      data {
        ...ItemDataFields
      }
      productInstance {
        id
        product {
          id
        }
      }
    }
    regardingInstruction {
      id
      title
      number
      productInstanceId
      data {
        ...ItemDataFields
      }
      productInstance {
        id
        product {
          id
        }
      }
    }
    notifiedByParty {
      id
      description
      contractTypeId
      status
    }
  }
`;
