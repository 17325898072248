import {
  Box,
  Button,
  Stack,
  Typography,
  css,
  styled,
  useTheme,
} from "@mui/material";
import { ArrowRight, Info } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { DiaryBannerContainer } from "./LockedDailyDiaryBanner";

const BannerContainer = styled(DiaryBannerContainer)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.warning.main};
    background-color: rgba(241, 189, 3, 0.05); // warning.main, with 0.5 opacity
  `
);

type NotLatestRevisionBannerProps = {
  onGoToLatestRevision: () => void;
};

export const NotLatestRevisionBanner: React.FC<
  NotLatestRevisionBannerProps
> = ({ onGoToLatestRevision }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <BannerContainer>
      <Box mr={2}>
        <Info size={24} color={theme.palette.warning.main} />
      </Box>
      <Stack direction="row" alignItems="center">
        <Typography variant="p2" color="grey.900">
          {t("Projects.DailyDiaries.notLatestRevisionBannerMessage")}
        </Typography>
        <Button
          variant="text"
          onClick={onGoToLatestRevision}
          sx={{ p: 0, pl: 1 }}
        >
          <Typography
            fontSize="14px"
            fontWeight={600}
            sx={{ textDecoration: "underline" }}
            color="greu.800"
            mr={0.5}
          >
            {t("Projects.DailyDiaries.notLatestRevisionBannerBtnText")}
          </Typography>
          <ArrowRight size={16} color={theme.palette.grey[800]} />
        </Button>
      </Stack>
    </BannerContainer>
  );
};
