import { gql } from "@apollo/client";

export const editProductSchemaMutation = gql`
  mutation editProductSchema($input: EditProductSchemaInput!) {
    editProductSchema(input: $input) {
      id
      name
      isDefault
      productId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;
