import { gql } from "@apollo/client";

export const variationProposalLiteFields = gql`
  fragment VariationProposalLiteFields on VariationProposal {
    id
    variationId
    reference
    details
    price
    currency
    consented
    time
    sectionalChanges {
      number
      description
      days
    }
    dateCreated
    creatorId
  }
`;
