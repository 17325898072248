import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { VariationActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { useTranslation } from "react-i18next";
import { useRecordDisagreementRegardingNoticeOfErrorInDeterminationModal } from "./useRecordDisagreementRegardingNoticeOfErrorInDeterminationModal";

export type RecordDisagreementRegardingNoticeOfErrorInDeterminationModalProps =
  {
    remarks: string;
    attachments?: EnhancedAttachment[];
    onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
  } & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordDisagreementRegardingNoticeOfErrorInDeterminationModal: React.FC<
  RecordDisagreementRegardingNoticeOfErrorInDeterminationModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { variationPreview, contract } =
    useRecordDisagreementRegardingNoticeOfErrorInDeterminationModal(remarks);
  const { t } = useTranslation();

  return (
    <RecordAction
      actionType={VariationActionType.DisagreeWithNoticeErrorDetermination}
      variationPreview={variationPreview}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={remarks}
          label={t("common.labels.remarks")}
          attachments={attachments}
          contractTimezone={contract.timeZone}
        />
      }
      {...restDialogProps}
    />
  );
};
