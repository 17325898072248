import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { timePeriodLiteFields } from "./timePeriod.fragment";

export const timePeriodOverrideFields = gql`
  ${creatorFields}
  ${timePeriodLiteFields}

  fragment TimePeriodOverrideFields on TimePeriodOverride {
    id
    contractId
    timePeriodId
    duration
    dateCreated
    creatorId
    timePeriod {
      ...TimePeriodLiteFields
    }
    contract {
      id
      name
      friendlyName
      timeZone
    }
    creator {
      ...CreatorFields
    }
  }
`;
