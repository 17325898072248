import { createTheme, ThemeOptions } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const font = "'Inter', sans-serif";

const baseThemeOverrides: ThemeOptions = {
  palette: {
    primary: {
      light: "#00c43b",
      main: "#00BC3A",
      dark: "#00694D",
    },
    secondary: {
      main: "#EAEAEA",
    },
    text: {
      primary: "#202020",
      disabled: "#A7A7A7",
    },
    grey: {
      300: "#F0F0F0",
      400: "#EAEAEA",
      500: "#dbdbdb",
      600: "#ADADAD",
      700: "#7d7d7d",
    },
    warning: {
      main: "#F0C11A",
    },
    common: {
      black: "#202020",
      white: "#FFFFFF",
    },
    action: {
      hover: "#fefcf4",
    },
  },
  typography: {
    fontFamily: font,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        // disableRipple: true, // TBD
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "small" && {
            height: "30px",
          }),
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
        color: "inherit",
        sx: {
          cursor: "pointer",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 2px 6px rgba(37, 44, 50, 0.12)",
        },
      },
    },
  },
};

export const baseTheme = createTheme(baseThemeOverrides);
