import { ProductType } from "generated/graphql";
import { createContext } from "react";
import { ItemMetadata } from "./SchemaInterpretor";

export type SchemaInterpretorContextType = {
  productInstanceId: string;
  productItemType: ProductType;
  contractCurrency?: string;
  contractTimezone: string;
  metadata?: ItemMetadata;
  liteVariant?: boolean;
  authAuditId?: string;
  isNECContractType?: boolean;
  contractTypeId?: string;
  onOwnerChange?: () => void;
};

export const SchemaInterpretorContext =
  createContext<SchemaInterpretorContextType>({
    productInstanceId: "",
    contractCurrency: "",
    contractTimezone: "",
    productItemType: ProductType.RisksRegister,
    isNECContractType: true,
    authAuditId: undefined,
    onOwnerChange: undefined,
    liteVariant: false,
    contractTypeId: undefined,
    metadata: undefined,
  });

export const SchemaInterpretorContextProvider: React.FC<{
  children: React.ReactNode;
  value: SchemaInterpretorContextType;
}> = ({ children, value }) => {
  return (
    <SchemaInterpretorContext.Provider value={value}>
      {children}
    </SchemaInterpretorContext.Provider>
  );
};
