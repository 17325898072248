import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const authorizationWorkflowCandidatesQuery = gql`
  ${creatorFields}

  query authorizationWorkflowCandidates($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      friendlyName
      status
      contracts {
        items {
          id
          name
          friendlyName
          status
          activeBindings {
            items {
              id
              status
              company {
                id
                registeredName
                tradingName
                status
                users {
                  items {
                    ...CreatorFields
                    registered
                    status
                    email
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
