import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DeleteConfirmationModal } from "components/DeleteConfirmationModal";
import {
  AddDailyDiaryResourceRecordInput,
  AttachmentInput,
  DailyDiaryExtraConfig,
  DailyDiaryPresetSection,
  DailyDiaryResourceRecord,
  EditDailyDiaryResourceRecordInput,
} from "generated/graphql";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { BaseHeaderSection } from "../BaseSectionHeader";
import {
  ResourceRecordModal,
  ResourceType,
} from "./ResourceRecordModal/ResourceRecordModal";
import { ResourceTable } from "./ResourceTable/ResourceTable";
import { useManpowerSection } from "./useManpowerSection";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { ImportManpowerRecordsModal } from "./ImportManpowerRecordsModal";
import { useDiaryMetadata } from "../../hooks/useDiaryMetadata";

export type ManpowerSectionProps = {
  section: DailyDiaryPresetSection;
};

export const ManpowerSection: React.FC<ManpowerSectionProps> = ({
  section,
}) => {
  const { t } = useTranslation();
  const [updatedAttachments, setUpdatedAttachments] =
    useState<AttachmentInput[]>();

  const { dailyDiaryId: dailyDiaryIdSearchParam } = useDiaryMetadata();

  const [recordToDeleteEdit, setRecordToDeleteEdit] =
    useState<DailyDiaryResourceRecord>();
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const {
    modalVisibility: importModalVisibility,
    toggleModalVisibility: toggleImportModalVisibility,
  } = useBasicModal();

  const { modalVisibility, toggleModalVisibility } = useBasicModal();
  const {
    contractLookupCollections,
    activeContractLookupCollections,
    dailyDiary,
    emptyDailyDiary,
    loading: contextLoading,
    productInstance,
    crtRevisionId,
    refetchDDByDate,
  } = useContext(DailyDiaryContext);
  const {
    manpowerRecords,
    loading: manpowerRecordsLoading,
    addManpowerRecord,
    editManpowerRecord,
    removeManpowerRecord,
    refetchDDManpowerRecords,
  } = useManpowerSection(dailyDiary, crtRevisionId);

  const manpowerLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).manPowerLookupId
    );
  }, [productInstance]);

  const shiftLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).shiftLookupId
    );
  }, [productInstance]);

  const companyLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).companyLookupId
    );
  }, [productInstance]);

  const teamLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).teamLookupId
    );
  }, [productInstance]);

  const localSection = useMemo(() => {
    let overwrittenSection = section;

    overwrittenSection = manpowerLookupIdOverride
      ? {
          ...overwrittenSection,
          fields: overwrittenSection.fields.map((field) => {
            if (field.name === "Resource" && field.fieldType === "LKP") {
              return {
                ...field,
                lookupId: manpowerLookupIdOverride,
              };
            }
            return field;
          }),
        }
      : overwrittenSection;

    overwrittenSection = shiftLookupIdOverride
      ? {
          ...overwrittenSection,
          fields: overwrittenSection.fields.map((field) => {
            if (field.name === "Shift" && field.fieldType === "LKP") {
              return {
                ...field,
                lookupId: shiftLookupIdOverride,
              };
            }
            return field;
          }),
        }
      : overwrittenSection;

    overwrittenSection = companyLookupIdOverride
      ? {
          ...overwrittenSection,
          fields: overwrittenSection.fields.map((field) => {
            if (field.name === "Company" && field.fieldType === "LKP") {
              return {
                ...field,
                lookupId: companyLookupIdOverride,
              };
            }
            return field;
          }),
        }
      : overwrittenSection;

    overwrittenSection = teamLookupIdOverride
      ? {
          ...overwrittenSection,
          fields: overwrittenSection.fields.map((field) => {
            if (field.name === "Team" && field.fieldType === "LKP") {
              return {
                ...field,
                lookupId: teamLookupIdOverride,
              };
            }
            return field;
          }),
        }
      : overwrittenSection;

    return overwrittenSection;
  }, [
    section,
    manpowerLookupIdOverride,
    shiftLookupIdOverride,
    companyLookupIdOverride,
    teamLookupIdOverride,
  ]);

  const toggleConfirmationModalVisibility = () => {
    setConfirmationModalVisibility((crtState) => !crtState);
  };

  const triggerEditRecordFlow = (recordId: GridRowId) => {
    const record = manpowerRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    toggleModalVisibility();
  };

  const triggerRemoveFlow = (recordId: GridRowId) => {
    const record = manpowerRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    setConfirmationModalVisibility(true);
  };

  const handleAttachmentsUpdated = (attachmentInputs: AttachmentInput[]) => {
    setUpdatedAttachments(attachmentInputs);
  };

  const handleModalPrimaryClick = async (
    manpowerRecord:
      | AddDailyDiaryResourceRecordInput
      | EditDailyDiaryResourceRecordInput,
    closeModal?: boolean
  ) => {
    if (recordToDeleteEdit) {
      await editManpowerRecord({
        variables: {
          input: {
            ...(manpowerRecord as EditDailyDiaryResourceRecordInput),
            id: recordToDeleteEdit.id,
            attachments:
              updatedAttachments ??
              attachmentsToAttachmentInputs(
                recordToDeleteEdit.attachments ?? []
              ),
          },
        },
      });
    } else {
      await addManpowerRecord({
        variables: {
          input: {
            ...(manpowerRecord as AddDailyDiaryResourceRecordInput),
            ...(dailyDiary?.id
              ? { dailyDiaryId: dailyDiary.id }
              : {
                  date: emptyDailyDiary?.date,
                  productInstanceId: emptyDailyDiary?.productInstanceId,
                }),
            attachments: updatedAttachments ?? [],
          },
        },
      });
    }
    setUpdatedAttachments(undefined);

    if (closeModal) {
      handleManpowerRecordModalClose();
    }
  };

  const handleSuccessfulImport = () => {
    toggleImportModalVisibility();
    if (dailyDiaryIdSearchParam) {
      refetchDDManpowerRecords();
    } else {
      refetchDDByDate?.();
    }
  };

  const handleManpowerRecordModalClose = () => {
    toggleModalVisibility();
    setRecordToDeleteEdit(undefined);
  };

  const handleRemoveRecord = () => {
    toggleConfirmationModalVisibility();
    removeManpowerRecord({ variables: { id: recordToDeleteEdit?.id! } });
    setRecordToDeleteEdit(undefined);
  };

  const handleAddManpowerRecord = () => {
    toggleModalVisibility();
  };

  useEffect(() => {
    if (recordToDeleteEdit && !manpowerRecords.includes(recordToDeleteEdit)) {
      const updatedRecord = manpowerRecords.find(
        (rec) => rec.id === recordToDeleteEdit.id
      );
      if (updatedRecord) {
        setRecordToDeleteEdit(updatedRecord);
      }
    }
  }, [manpowerRecords, recordToDeleteEdit]);

  useEffect(() => {
    if (!recordToDeleteEdit) {
      setUpdatedAttachments(undefined);
    }
  }, [recordToDeleteEdit]);

  return (
    <>
      <DeleteConfirmationModal
        title={t("Projects.DailyDiaries.RemoveConfirmationModal.title")}
        subtitle={t("Projects.DailyDiaries.RemoveConfirmationModal.subtitle")}
        open={confirmationModalVisibility}
        onPrimaryClick={handleRemoveRecord}
        onSecondaryClick={toggleConfirmationModalVisibility}
      />
      {importModalVisibility && (
        <ImportManpowerRecordsModal
          open={importModalVisibility}
          section={localSection}
          lookups={contractLookupCollections}
          loading={contextLoading}
          onClose={toggleImportModalVisibility}
          onImportSuccess={handleSuccessfulImport}
        />
      )}
      <ResourceRecordModal
        open={modalVisibility}
        onPrimaryClick={handleModalPrimaryClick}
        onSecondaryClick={handleManpowerRecordModalClose}
        onClose={handleManpowerRecordModalClose}
        onAttachmentsUpdated={handleAttachmentsUpdated}
        section={localSection}
        resourceRecord={recordToDeleteEdit}
        primaryBtnCaption={
          recordToDeleteEdit ? t("common.labels.saveChanges") : undefined
        }
        primaryBtnLoading={manpowerRecordsLoading}
        attachmentsPreview={
          updatedAttachments && recordToDeleteEdit
            ? recordToDeleteEdit.attachments?.filter((attach) =>
                updatedAttachments.find(
                  (crtAttach) => crtAttach.id === attach.id
                )
              )
            : undefined
        }
        type={ResourceType.Manpower}
        resourceLookupCollections={activeContractLookupCollections}
      />
      <Box width="100%">
        <BaseHeaderSection
          title={t("Projects.DailyDiaries.sections.manpower")}
          onAdd={handleAddManpowerRecord}
          onImport={toggleImportModalVisibility}
          mb={3}
        />
        <ResourceTable
          section={localSection}
          records={manpowerRecords}
          resourceLookupCollections={contractLookupCollections}
          loading={manpowerRecordsLoading || contextLoading}
          recordName={t("Projects.DailyDiaries.sections.manpower")}
          onEdit={triggerEditRecordFlow}
          onRemove={triggerRemoveFlow}
        />
      </Box>
    </>
  );
};
