import {
  AddDailyDiaryWeatherRecordMutation,
  AddDailyDiaryWeatherRecordMutationVariables,
  DailyDiaryItem,
  EditDailyDiaryWeatherRecordMutation,
  EditDailyDiaryWeatherRecordMutationVariables,
  RemoveDailyDiaryWeatherRecordMutation,
  RemoveDailyDiaryWeatherRecordMutationVariables,
} from "generated/graphql";
import { addDailyDiaryWeatherRecordMutation } from "graphql/mutations/addDailyDiaryWeatherRecord";
import { editDailyDiaryWeatherRecordMutation } from "graphql/mutations/editDailyDiaryWeatherRecord";
import { removeDailyDiaryWeatherRecordMutation } from "graphql/mutations/removeDailyDiaryWeatherRecord";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { useWeatherRecords } from "./useWeatherRecords";

export const useWeatherSection = (
  dailyDiary?: DailyDiaryItem,
  revisionId?: string
) => {
  const { t } = useTranslation();

  const { refetchDDByDate } = useContext(DailyDiaryContext);
  const { weatherRecords, weatherRecordsLoading, refetchDDWeatherRecords } =
    useWeatherRecords(dailyDiary, revisionId);

  const [addDDWeatherRecord, { loading: addDDWeatherRecordLoading }] =
    useGraphMutation<
      AddDailyDiaryWeatherRecordMutation,
      AddDailyDiaryWeatherRecordMutationVariables
    >(
      addDailyDiaryWeatherRecordMutation,
      {
        update: async () => {
          if (!dailyDiary) {
            await refetchDDByDate?.();
          } else {
            refetchDDWeatherRecords();
          }
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.DailyDiaries.weatherRecord"),
      })
    );

  const [editDDWeatherRecord, { loading: editDDWeatherRecordLoading }] =
    useGraphMutation<
      EditDailyDiaryWeatherRecordMutation,
      EditDailyDiaryWeatherRecordMutationVariables
    >(
      editDailyDiaryWeatherRecordMutation,
      {
        update: () => {
          refetchDDWeatherRecords();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.DailyDiaries.weatherRecord"),
      })
    );

  const [removeDDWeatherRecord, { loading: removeDDWeatherRecordLoading }] =
    useGraphMutation<
      RemoveDailyDiaryWeatherRecordMutation,
      RemoveDailyDiaryWeatherRecordMutationVariables
    >(
      removeDailyDiaryWeatherRecordMutation,
      {
        update: () => {
          refetchDDWeatherRecords();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.DailyDiaries.weatherRecord"),
      })
    );

  return {
    weatherRecords,
    loading:
      weatherRecordsLoading ||
      addDDWeatherRecordLoading ||
      editDDWeatherRecordLoading ||
      removeDDWeatherRecordLoading,
    refetchDDWeatherRecords,
    addWeatherRecord: addDDWeatherRecord,
    editWeatherRecord: editDDWeatherRecord,
    removeWeatherRecord: removeDDWeatherRecord,
  };
};
