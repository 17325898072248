import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AuthLSDataType } from "providers/auth.context";
import { onError } from "@apollo/client/link/error";
import { refreshToken } from "services/AuthService";
import { NewAppPaths } from "helpers/paths/paths";
import { DynamicConfig } from "config/config";
import { omitTypename } from "helpers/miscelaneous";
import { read } from "helpers/localStorage";

const getHttpLink = (graphQlUrl: string) => {
  return createHttpLink({
    uri: graphQlUrl,
  });
};

const getErrorLink = (authBaseUrl: string) => {
  return onError(({ graphQLErrors, networkError: _, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const graphQLErrorCode = err?.extensions?.code;
        const customErrorType = (
          err as any as { errorType: string; message: string }
        ).errorType;

        switch (graphQLErrorCode ?? customErrorType) {
          case "UNAUTHENTICATED": // for graphQLErrorCode
          case "UnauthorizedException": // for customErrorType
            return fromPromise(
              refreshToken(authBaseUrl).catch((error) => {
                // Handle token refresh errors e.g clear stored tokens, redirect to login
                console.warn(error);
                window.location.replace(NewAppPaths.nonAuthorized.Login);
                return localStorage.clear();
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((tokenData) => {
                // TODO ADI B. error case should be handled by above .catch block.
                // /refresh-token endpoint returns 200 even in failure scenarios, therefore for quick fixing it by double-checking it here.
                if ((tokenData as any).Success === false) {
                  console.error((tokenData as any).Errors[0]);
                  window.location.replace(NewAppPaths.nonAuthorized.Login);
                  localStorage.clear();

                  return null as any;
                }

                const oldHeaders = operation.getContext().headers;
                // modify the operation context with a new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${
                      (tokenData! as { AccessToken: string }).AccessToken
                    }`,
                  },
                });

                // retry the request, returning the new observable
                return forward(operation);
              });
        }
      }
    }
  });
};

// TODO: right now, refresh token won't work because it's set on LS, and Auth.Context is not listening to LS changes.
// - authContext does not need to pass down the actual tokens. This is nowhere used but here, in apolloClient; Remove `contextData` from authContext.
// - make getAuthLink rely only on localStorage - this way it'll work even after token refresh action. For registration page, we'll use appKey
// - for `keep me logged in - unchecked`, save a `keepMeLoggedIn` flag in the store and when loading the data from LS, check for the flag. If present, don't use the data.
const getAuthLink = setContext((_, { headers }) => {
  const lsAuthData = read() as AuthLSDataType;
  const accessToken = lsAuthData?.tokenData?.AccessToken;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
    },
  };
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation);
});

// TODO: remove __typename from from response once we have useManagedQuery
export const getApolloClient = (
  appConfig: DynamicConfig
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: ApolloLink.from([
      cleanTypeName,
      getAuthLink,
      getErrorLink(appConfig.REACT_APP_AUTH_BASE_URL),
      getHttpLink(appConfig.REACT_APP_GRAPHQL_URL),
    ]),
    cache: new InMemoryCache({}),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
      query: {
        fetchPolicy: "network-only",
      },
      mutate: {
        fetchPolicy: "network-only",
      },
    },
    // queryDeduplication: true
  });
};
