import {
  DailyDiaryEquipmentImportSourceItemsQuery,
  DailyDiaryEquipmentImportSourceItemsQueryVariables,
  DailyDiaryItem,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { dailyDiaryEquipmentImportSourceItemsQuery } from "./EquipmentSection.query";

export const useAvailableDiariesToImportFrom = () => {
  const [
    fetchAvailableDiaries,
    { data: availableDiariesToImportFrom, loading },
  ] = useGraphLazyQueryLite<
    DailyDiaryEquipmentImportSourceItemsQuery,
    DailyDiaryEquipmentImportSourceItemsQueryVariables
  >(dailyDiaryEquipmentImportSourceItemsQuery);

  return {
    availableDiariesToImportFrom: (availableDiariesToImportFrom?.data
      .dailyDiaryEquipmentImportSourceItems.items ?? []) as DailyDiaryItem[],
    availableDiariesToImportFromLoading: loading,
    fetchAvailableDiaries,
  };
};
