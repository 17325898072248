import {
  AvailableLookupsByContractQuery,
  AvailableLookupsByContractQueryVariables,
  CompanyLookupCollection,
} from "generated/graphql";
import { availableLookupsByContractQuery } from "graphql/queries/availableLookupsByContract.query";
import { useDailyDiaryPresets } from "hooks/useDailyDiaryPresets";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useProductInstanceDailyDiaryExtraConfigForm = (
  contractId?: string
) => {
  const {
    data: contractLookupCollections,
    loading: contractLookupCollectionsLoading,
  } = useGraphQuery<
    AvailableLookupsByContractQuery,
    AvailableLookupsByContractQueryVariables
  >(availableLookupsByContractQuery, {
    variables: { contractId: contractId! },
    skip: !contractId,
  });

  const { dailyDiaryPresets, loading: dailyDiaryPresetsLoading } =
    useDailyDiaryPresets();

  return {
    dailyDiaryPresets,
    lookups: (contractLookupCollections?.availableLookupsByContract.items ??
      []) as CompanyLookupCollection[],
    loading: contractLookupCollectionsLoading || dailyDiaryPresetsLoading,
  };
};
