import { Attachment } from "generated/graphql";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabsWithCount, TabWithCount } from "../../TabsWithCount/TabsWithCount";
import { FileType } from "../Attachments.decl";
import { filterAttachmentsByTab } from "../Attachments.utils";

export type AttachmentsTabsProps = {
  attachments: (Attachment | FileType)[];
  onSelectedTabChange: (newSelectedTabId: string) => void;
};

export enum AttachmentTab {
  All = "all",
  Documents = "documents",
  Images = "images",
  Videos = "videos",
}

export const AttachmentsTabs: React.FC<AttachmentsTabsProps> = ({
  attachments,
  onSelectedTabChange,
}) => {
  const { t } = useTranslation();

  const [selectedTabId, setSelectedTabId] = useState<string | undefined>();

  const handleSelectedTabChange = (newSelectedTabId: string) => {
    setSelectedTabId(newSelectedTabId);
    onSelectedTabChange(newSelectedTabId);
  };

  const tabs = useMemo(() => {
    const localTabs: TabWithCount[] = [
      {
        id: AttachmentTab.All,
        label: t("common.labels.all"),
        count: attachments.length,
      },
      {
        id: AttachmentTab.Documents,
        label: t("Attachments.documents"),
        count: filterAttachmentsByTab(AttachmentTab.Documents, attachments)
          .length,
      },
      {
        id: AttachmentTab.Images,
        label: t("Attachments.images"),
        count: filterAttachmentsByTab(AttachmentTab.Images, attachments).length,
      },
      {
        id: AttachmentTab.Videos,
        label: t("Attachments.videos"),
        count: filterAttachmentsByTab(AttachmentTab.Videos, attachments).length,
      },
    ];

    return localTabs;
  }, [attachments, t]);

  return (
    <TabsWithCount
      tabs={tabs}
      selectedTabId={selectedTabId ?? tabs[0].id}
      onSelectedTabChange={handleSelectedTabChange}
    />
  );
};
