import { useEffect, useState } from "react";

export type UserMentionOption = {
  name: string;
  userId: string;
};

const mentionsCache = new Map();

const lookupService = {
  search(
    mentionsList: UserMentionOption[],
    string: string,
    callback: (results: UserMentionOption[]) => void
  ): void {
    setTimeout(() => {
      const results = mentionsList.filter((mention) =>
        mention.name.toLowerCase().includes(string.toLowerCase())
      );
      callback(results);
    }, 500);
  },
};

// TODO: cache is shared between instances. Update it if we'll ever need this lookupService
export const useMentionLookupService = (
  mentionsList: UserMentionOption[],
  mentionString: string | null
) => {
  const [results, setResults] = useState<UserMentionOption[]>([]);

  useEffect(() => {
    const cachedResults = mentionsCache.get(mentionString);

    if (mentionString == null) {
      setResults([]);
      return;
    }

    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    mentionsCache.set(mentionString, null);
    lookupService.search(mentionsList, mentionString, (newResults) => {
      mentionsCache.set(mentionString, newResults);
      setResults(newResults);
    });
  }, [mentionString, mentionsList]);

  return results;
};

export const useMentionLookupServiceLite = (
  mentionsList: UserMentionOption[],
  mentionString: string | null
) => {
  const [results, setResults] = useState<UserMentionOption[]>([]);

  useEffect(() => {
    if (!mentionString) {
      setResults([]);
      return;
    }

    const localResults = mentionsList.filter((mention) =>
      mention.name.toLowerCase().includes(mentionString.toLowerCase())
    );
    setResults(localResults);
  }, [mentionString, mentionsList]);

  return results;
};
