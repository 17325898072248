import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";
import { draftClaimDeterminationFields } from "graphql/fragments/draftClaimDetermination.fragment";

export const claimDeterminationNoticePromptQuery = gql`
  ${draftClaimDeterminationFields}

  query claimDeterminationNoticePrompt(
    $input: ClaimDeterminationNoticePromptInput!
  ) {
    claimDeterminationNoticePrompt(input: $input) {
      draftClaimDeterminations {
        ...DraftClaimDeterminationFields
      }
    }
  }
`;

export const sendClaimDeterminationNoticeMutation = gql`
  mutation sendClaimDeterminationNotice(
    $input: SendClaimDeterminationNoticeInput!
  ) {
    sendClaimDeterminationNotice(input: $input) {
      id
    }
  }
`;

export const recordClaimDeterminationNoticeMutation = gql`
  mutation recordClaimDeterminationNotice(
    $input: RecordClaimDeterminationNoticeInput!
  ) {
    recordClaimDeterminationNotice(input: $input) {
      id
    }
  }
`;

export const generateSendClaimDeterminationNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimDeterminationNoticePreview(
    $input: SendClaimDeterminationNoticePreviewInput!
  ) {
    generateSendClaimDeterminationNoticePreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimDeterminationNoticePreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimDeterminationNoticePreview(
    $input: RecordClaimDeterminationNoticePreviewInput!
  ) {
    generateRecordClaimDeterminationNoticePreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
