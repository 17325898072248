import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { ClaimActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { useTranslation } from "react-i18next";
import { useRecordErrorFoundInDeterminationNoticeModal } from "./useRecordErrorFoundInDeterminationNoticeModal";

export type RecordErrorFoundInDeterminationNoticeModalProps = {
  details: string;
  attachments?: EnhancedAttachment[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordErrorFoundInDeterminationNoticeModal: React.FC<
  RecordErrorFoundInDeterminationNoticeModalProps
> = ({ details, attachments = [], ...restDialogProps }) => {
  const { claimPreview, contract } =
    useRecordErrorFoundInDeterminationNoticeModal(details);
  const { t } = useTranslation();

  return (
    <RecordAction
      actionType={ClaimActionType.NotifyErrorFoundDetermination}
      claimPreview={claimPreview}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={details}
          label={t("Projects.Claims.ActionModal.errorsFound")}
          attachments={attachments}
          contractTimezone={contract.timeZone}
        />
      }
      {...restDialogProps}
    />
  );
};
