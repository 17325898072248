import {
  AddProductInstanceInput,
  DailyDiaryExtraConfig,
  ProductInstance,
} from "generated/graphql";

export const defaultProductInstance: AddProductInstanceInput = {
  contractId: "",
  description: "",
  numberingFormat: "",
  productId: "",
  productSchemaId: "",
  soloModeSupported: false,
  statusCollectionId: "",
  extraConfig: undefined,
};

export const productInstanceToAddProductInstanceInput = (
  productInstance: ProductInstance
): AddProductInstanceInput => ({
  contractId: productInstance.contractId,
  description: productInstance.description,
  extraConfig: {
    dailyDiary: productInstance.extraConfig as DailyDiaryExtraConfig,
  },
  numberingFormat: productInstance.numberingFormat,
  productId: productInstance.productId,
  productSchemaId: productInstance.productSchemaId,
  soloModeSupported: productInstance.soloModeSupported,
  statusCollectionId: productInstance.statusCollectionId,
});
