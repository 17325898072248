import {
  ProductType,
  RemoveDraftEarlyWarningItemMutation,
  RemoveDraftEarlyWarningItemMutationVariables,
  RemoveDraftEventItemMutation,
  RemoveDraftEventItemMutationVariables,
  RemoveDraftInstructionItemMutation,
  RemoveDraftInstructionItemMutationVariables,
  RemoveDraftRiskItemMutation,
  RemoveDraftRiskItemMutationVariables,
} from "generated/graphql";
import { removeDraftEarlyWarningItemMutation } from "graphql/mutations/removeDraftEarlyWarning";
import { removeDraftEventItemMutation } from "graphql/mutations/removeDraftEvent";
import { removeDraftInstructionItemMutation } from "graphql/mutations/removeDraftInstructionItem";
import { removeDraftRiskItemMutation } from "graphql/mutations/removeDraftRisk";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useDeleteProductItemDraftConfirmModal = () => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const [removeRiskItemDraft, { loading: removeRiskItemDraftLoading }] =
    useGraphMutation<
      RemoveDraftRiskItemMutation,
      RemoveDraftRiskItemMutationVariables
    >(
      removeDraftRiskItemMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItems" });
          cache.gc();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.Risks.draftRiskItem"),
      })
    );

  const [removeEWItemDraft, { loading: removeEWItemDraftLoading }] =
    useGraphMutation<
      RemoveDraftEarlyWarningItemMutation,
      RemoveDraftEarlyWarningItemMutationVariables
    >(
      removeDraftEarlyWarningItemMutation,
      {
        update: (cache) => {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "draftEarlyWarningItems",
          });
          cache.gc();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.EarlyWarnings.draftEWItem"),
      })
    );
  const [removeEventItemDraft, { loading: removeEventItemDraftLoading }] =
    useGraphMutation<
      RemoveDraftEventItemMutation,
      RemoveDraftEventItemMutationVariables
    >(
      removeDraftEventItemMutation,
      {
        update: (cache) => {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "draftEventItems",
          });
          cache.gc();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.Events.draftEvent"),
      })
    );

  const [
    removeInstructionItemDraft,
    { loading: removeInstructionItemDraftLoading },
  ] = useGraphMutation<
    RemoveDraftInstructionItemMutation,
    RemoveDraftInstructionItemMutationVariables
  >(
    removeDraftInstructionItemMutation,
    {
      update: (cache) => {
        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "draftInstructionItems",
        });
        cache.gc();
      },
    },
    t("common.successMessages.entityDeleted", {
      entity: t("Projects.Instructions.draftInstruction"),
    })
  );

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleConfirm = async (draftId: string, productType: ProductType) => {
    switch (productType) {
      case ProductType.RisksRegister:
        await removeRiskItemDraft({ variables: { id: draftId } });
        break;
      case ProductType.EarlyWarnings:
        await removeEWItemDraft({ variables: { id: draftId } });
        break;
      case ProductType.Events:
        await removeEventItemDraft({ variables: { id: draftId } });
        break;
      case ProductType.Instructions:
        await removeInstructionItemDraft({ variables: { id: draftId } });
        break;
    }

    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  return {
    modalVisible,
    setModalVisible,
    loading:
      removeRiskItemDraftLoading ||
      removeEWItemDraftLoading ||
      removeEventItemDraftLoading ||
      removeInstructionItemDraftLoading,
    handleConfirm,
    handleCancel,
    closeModal,
  };
};
