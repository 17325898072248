import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NewAppPaths } from "../../helpers/paths/paths";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import {
  getRegistrationInfoQuery,
  registerUserMutation,
} from "./Register.query";
import {
  RegisterUserInput,
  RegisterUserMutation,
  RegisterUserMutationVariables,
  UserRegistrationQuery,
  UserRegistrationQueryVariables,
} from "generated/graphql";
import { AuthContext } from "providers/auth.context";
import { useTranslation } from "react-i18next";
import { useConfig } from "providers/config.context";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSnackbar } from "notistack";
import { NewAuthPageTemplate } from "components/NewAuthPageTemplate";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { RegistrationStepper } from "./components/RegistrationStepper/RegistrationStepper";
import { LoginIndicator } from "./components/LoginIndicator";
import { RegistrationHeader } from "./components/RegistrationHeader";
import { Box } from "@mui/material";
import { RegistrationFooter } from "./components/RegistrationFooter";
import { UserAccountForm } from "./components/UserAccountForm";
import { FormPublicApi } from "types/decl";
import {
  PersonalInfoForm,
  PersonalInfoFormType,
} from "./components/PersonalInfoForm";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { ApolloError } from "@apollo/client";
import { ErrorView } from "./components/ErrorView";

export enum RegistrationStep {
  Account = "account",
  PersonalInfo = "Personal information",
}

export const Register: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { config } = useConfig();

  const { isLoggedIn } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const registrationId = searchParams.get("registrationId");

  const [step, setStep] = useState<RegistrationStep>(RegistrationStep.Account);
  const [agreementChecks, setAgreementChecks] = useState(false);
  const [registrationError, setRegistrationError] = useState<ApolloError>();

  const accountFormRef = useRef<FormPublicApi>(null);
  const personalInfoFormRef = useRef<FormPublicApi>(null);
  const [accountData, setAccountData] = useState<{ password: string }>();
  const [personalInfoData, setPersonalInfoData] =
    useState<PersonalInfoFormType>();

  const [getRegistrationInfo, { data, loading: getRegistrationInfoLoading }] =
    useGraphLazyQueryLite<
      UserRegistrationQuery,
      UserRegistrationQueryVariables
    >(getRegistrationInfoQuery);

  const [registerUser, { loading: registerUserLoading }] = useGraphMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(
    registerUserMutation,
    {
      context: {
        headers: {
          "x-api-key": config.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      },
    },
    t("common.successMessages.registrationSuccessful")
  );

  const register = async (
    userData: Omit<RegisterUserInput, "invitationId">
  ) => {
    try {
      const { errors } = await registerUser({
        variables: {
          input: {
            ...userData,
            invitationId: registrationId!,
          },
        },
      });

      if (!errors) {
        navigateToLoginPage();
      }
    } catch (error: any) {
      enqueueSnackbar(error.message ?? t("common.errorMessages.generic"));
    }
  };

  const handleUserAccountInfoChange = useCallback((password: string) => {
    setAccountData((crtState) => ({
      ...crtState,
      password,
    }));
  }, []);

  const handlePersonalInfoChange = useCallback(
    (personalInfoData: PersonalInfoFormType) => {
      setPersonalInfoData((crtState) => ({
        ...crtState,
        ...personalInfoData,
      }));
    },
    []
  );

  const handleCreateAccount = () => {
    if (personalInfoFormRef.current?.validate()) {
      const { alternateNumber, ...restPersonalInfo } = personalInfoData!;
      register({
        ...accountData!,
        ...restPersonalInfo,
        ...(alternateNumber ? { alternateNumber } : {}),
      });
    }
  };

  const toggleFirstStep = () => {
    setStep(RegistrationStep.Account);
  };

  const navigateToLoginPage = useCallback(() => {
    navigate(NewAppPaths.nonAuthorized.Login);
  }, [navigate]);

  const proceedToSecondStep = () => {
    if (accountFormRef.current?.validate()) {
      setStep(RegistrationStep.PersonalInfo);
    }
  };

  const getUserRegistrationInfo = useCallback(async () => {
    try {
      const { error } = await getRegistrationInfo(
        { invitationId: registrationId },
        undefined,
        {
          query: getRegistrationInfoQuery,
          context: {
            headers: {
              "x-api-key": config.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          },
        }
      );

      if (error) {
        setRegistrationError(error);
      }
    } catch (error: any) {
      setRegistrationError(error as ApolloError);
    }
  }, [getRegistrationInfo, registrationId, config]);

  useEffect(() => {
    if (!registrationId) {
      navigateToLoginPage();
    }
  }, [registrationId, navigateToLoginPage]);

  useEffect(() => {
    if (registrationId) {
      getUserRegistrationInfo();
    }
  }, [registrationId, getUserRegistrationInfo]);

  if (isLoggedIn) {
    return <Navigate replace to={`${NewAppPaths.authorized.Home}`} />;
  }

  return (
    <NewAuthPageTemplate
      lhsChildren={
        <>
          <RegistrationStepper step={step} />
          <LoginIndicator />
        </>
      }
    >
      {getRegistrationInfoLoading ? (
        <CenteredLoadingIndicator />
      ) : data && !registrationError ? (
        <Box display="flex" flexDirection="column">
          <RegistrationHeader step={step} />
          <Box width="430px">
            <Box mt={8}>
              <Box>
                {step === RegistrationStep.Account ? (
                  <UserAccountForm
                    companyName={
                      data.data.userRegistration!.company.tradingName
                    }
                    email={data.data.userRegistration!.email}
                    apiRef={accountFormRef}
                    initialData={accountData?.password}
                    onChange={handleUserAccountInfoChange}
                  />
                ) : (
                  <PersonalInfoForm
                    onChange={handlePersonalInfoChange}
                    onAgreementChecksChange={setAgreementChecks}
                    initialData={personalInfoData}
                    apiRef={personalInfoFormRef}
                  />
                )}
              </Box>
            </Box>
            <Box mt={6}>
              <RegistrationFooter
                step={step}
                createAccountLoading={registerUserLoading}
                createAccountDisabled={!agreementChecks}
                onNext={proceedToSecondStep}
                onBack={toggleFirstStep}
                onCreateAccount={handleCreateAccount}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        registrationError && (
          <ErrorView
            registrationError={registrationError}
            registrationId={registrationId}
          />
        )
      )}
    </NewAuthPageTemplate>
  );
};
