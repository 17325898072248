import { systemVersionQuery } from "graphql/queries/systemVersion.query";
import {
  SystemVersionQuery,
  SystemVersionQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "./useGraphLazyQuery";
import { useConfig } from "providers/config.context";
import { useEffect } from "react";

export const useSystemVersion = () => {
  const { config } = useConfig();

  const [getSystemVersion, { data, loading: getSystemVersionLoading }] =
    useGraphLazyQuery<SystemVersionQuery, SystemVersionQueryVariables>(
      systemVersionQuery,
      {
        context: {
          headers: {
            "x-api-key": config.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      }
    );

  useEffect(() => {
    getSystemVersion();
  }, [getSystemVersion]);

  return {
    systemVersionData: data?.systemVersion,
    loading: getSystemVersionLoading,
  };
};
