import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  GenerateRecordClaimNoticePreviewMutation,
  GenerateRecordClaimNoticePreviewMutationVariables,
  RecordClaimNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { generateRecordClaimNoticePreviewMutation } from "./NotifyClaimAction.query";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "constants/constants";

export const useRecordNotifyClaimAction = () => {
  const { regardingId, regardingType, productInstanceId } =
    useContext(ClaimWidgetContext);

  const [claimPreview, setClaimPreview] = useState<RecordClaimNoticePreview>();

  const [generateClaimPreview] = useGraphMutation<
    GenerateRecordClaimNoticePreviewMutation,
    GenerateRecordClaimNoticePreviewMutationVariables
  >(generateRecordClaimNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            productInstanceId,
            regardingId,
            regardingType,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateRecordClaimNoticePreview);
      }
    }, [productInstanceId, generateClaimPreview, regardingId, regardingType]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    claimPreview,
  };
};
