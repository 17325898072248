import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TimePeriod } from "generated/graphql";
import { TFunction } from "i18next";

export const getColumns = (t: TFunction): GridColDef<TimePeriod>[] => [
  {
    field: "description",
    headerName: t("common.labels.description"),
    flex: 0.8,
    minWidth: 190,
    resizable: true,
    editable: true,
    renderCell: (params: GridRenderCellParams<any, TimePeriod, any>) => {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
        >
          <Tooltip
            title={params.row.description}
            enterDelay={1000}
            arrow
            placement="bottom"
          >
            <Typography
              variant="body2"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              width="100%"
              overflow="hidden"
            >
              {params.row.description}
            </Typography>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: "reference",
    headerName: t("AdminConsole.TimePeriods.reference"),
    flex: 0.1,
    minWidth: 190,
    resizable: true,
    editable: true,
  },
  {
    field: "duration",
    headerName: t("AdminConsole.TimePeriods.durationDays"),
    flex: 0.1,
    minWidth: 190,
    resizable: true,
    editable: true,
  },
];
