import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventNoticeRequestQuotationMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventNoticeRequestQuotation(
    $input: RecordCompEventNoticeRequestQuotationInput!
  ) {
    recordCompEventNoticeRequestQuotation(input: $input) {
      ...CompEventItemFields
    }
  }
`;
