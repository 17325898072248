import { SelectChangeEvent } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
  GridSingleSelectColDef,
} from "@mui/x-data-grid-pro";
import { TFunction } from "i18next";
import { ContractType, DocumentTemplate, Product } from "generated/graphql";
import { LocalDocumentTemplateRoute } from "./DocumentTemplateRoutes.decl";
import { DataGridSingleSelect } from "components/DataGridSingleSelect";
import { getCommonActions } from "helpers/dataGrid.helpers";
import { DataGridSelectOption } from "components/CustomDataGridSingleSelect";

export type IdNameType = {
  id: string;
  name: string;
};

type getColumnsArgsType = {
  rowModesModel: GridRowModesModel;
  products: Product[];
  contractTypes: ContractType[];
  documentTemplates: DocumentTemplate[];
  fixedContractType?: ContractType;
  t: TFunction;
  onProductChange: (
    row: LocalDocumentTemplateRoute,
    newProject: IdNameType
  ) => void;
  onOutputActionChange: (
    row: LocalDocumentTemplateRoute,
    newOutputAction: IdNameType
  ) => void;
  onContractTypeChange: (
    row: LocalDocumentTemplateRoute,
    newContractType: IdNameType
  ) => void;
  onOriginatingPartyChange: (
    row: LocalDocumentTemplateRoute,
    newOriginatingParty: IdNameType
  ) => void;
  onTargetPartyChange: (
    row: LocalDocumentTemplateRoute,
    newTargetParty: IdNameType
  ) => void;
  onDocumentTemplateChange: (
    row: LocalDocumentTemplateRoute,
    newDocumentTemplate: IdNameType
  ) => void;
  handleSaveRow: (rowId: GridRowId) => void;
  handleDeleteRow: (rowId: GridRowId) => void;
};

export const getColumns = ({
  rowModesModel,
  products,
  contractTypes,
  documentTemplates,
  fixedContractType,
  t,
  onProductChange,
  onOutputActionChange,
  onContractTypeChange,
  onOriginatingPartyChange,
  onTargetPartyChange,
  onDocumentTemplateChange,
  handleSaveRow,
  handleDeleteRow,
}: getColumnsArgsType): GridColDef<LocalDocumentTemplateRoute>[] => {
  const productOptions = products
    .map((product) => ({
      value: product.id,
      label: product.name,
    }))
    .sort((p1, p2) => p1.label.localeCompare(p2.label));

  const contractTypeOptions = contractTypes.map((contractType) => ({
    value: contractType.id,
    label: `${contractType?.description} ${contractType?.version ?? ""} ${
      contractType?.subType ?? ""
    }`,
  }));

  const documentTemplateOptions = documentTemplates
    .map((documentTemplate) => ({
      value: documentTemplate.id,
      label: documentTemplate.name,
    }))
    .sort((t1, t2) => t1.label.localeCompare(t2.label));

  return [
    {
      field: "product",
      headerName: t("common.labels.product"),
      flex: 0.125,
      minWidth: 90,
      resizable: true,
      editable: true,
      type: "singleSelect",
      valueOptions: productOptions,
      valueGetter: (_, row) => {
        return row.product?.id;
      },
      valueFormatter: (value: string) => {
        const productId = value;
        const selectedOption = productOptions.find(
          (valueOption: { value: string; label: string }) =>
            valueOption.value === productId
        );

        return selectedOption?.label;
      },
      renderEditCell: (
        cellParams: GridRenderEditCellParams<
          any,
          LocalDocumentTemplateRoute,
          any
        >
      ) => (
        <DataGridSingleSelect
          cellParams={cellParams}
          error={!cellParams.row.product}
          onValueChange={(event: SelectChangeEvent<any>) => {
            const newSelectedProduct = productOptions.find(
              (option) => option.value === event.target.value
            );
            onProductChange(cellParams.row, {
              id: newSelectedProduct!.value,
              name: newSelectedProduct!.label,
            });
          }}
        />
      ),
    },
    {
      field: "outputAction",
      headerName: t("AdminConsole.Templates.labels.outputAction"),
      flex: 0.125,
      minWidth: 90,
      resizable: true,
      editable: true,
      type: "singleSelect",
      valueOptions: (params) => {
        let crtRow: LocalDocumentTemplateRoute;
        if ((params as any).api) {
          const rows = (params as any).api.getRowModels() as Map<
            string,
            LocalDocumentTemplateRoute
          >;
          crtRow = rows.get(params.id as string)!;
        } else {
          crtRow = params.row!;
        }

        const selectedProduct = products.find(
          (product) => product.id === crtRow!.product?.id
        );

        return (
          selectedProduct
            ? selectedProduct.outputActions.items.map((outputAction) => ({
                value: outputAction.id,
                label: outputAction.name,
              }))
            : []
        ).sort((a1, a2) => a1.label.localeCompare(a2.label));
      },
      valueGetter: (_, row) => {
        return row.outputAction?.id ?? "";
      },
      valueFormatter: (value, row, column, api) => {
        const outputActionId = value;
        const colDef = api.current.getColumn(
          column.field
        ) as GridSingleSelectColDef<LocalDocumentTemplateRoute>;
        const valueOptions = (
          typeof colDef.valueOptions === "function"
            ? colDef.valueOptions({
                id: row.id,
                field: column.field,
                row,
              })
            : colDef.valueOptions
        ) as DataGridSelectOption[] | undefined;
        const selectedOption = valueOptions?.find(
          (valueOption) => valueOption.value === outputActionId
        );

        return selectedOption?.label || "";
      },
      renderEditCell: (
        cellParams: GridRenderEditCellParams<
          any,
          LocalDocumentTemplateRoute,
          any
        >
      ) => (
        <DataGridSingleSelect
          cellParams={cellParams}
          error={!cellParams.row.outputAction}
          onValueChange={(event: SelectChangeEvent<any>) => {
            const colDef =
              cellParams.colDef as GridSingleSelectColDef<LocalDocumentTemplateRoute>;
            const options =
              typeof colDef.valueOptions === "function"
                ? (colDef.valueOptions(cellParams) as DataGridSelectOption[])
                : [];
            const newSelectedOutputAction = options.find(
              (option) => option.value === event.target.value
            );
            onOutputActionChange(cellParams.row, {
              id: newSelectedOutputAction!.value,
              name: newSelectedOutputAction!.label as string,
            });
          }}
        />
      ),
    },
    fixedContractType
      ? {
          field: "contractType",
          headerName: t("common.labels.contractType"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          editable: false,
          valueGetter: () => fixedContractType.id,
          valueFormatter: () => {
            return `${fixedContractType.description} ${
              fixedContractType.version ?? ""
            } ${fixedContractType.subType ?? ""}`;
          },
        }
      : {
          field: "contractType",
          headerName: t("common.labels.contractType"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          editable: true,
          type: "singleSelect",
          valueOptions: contractTypeOptions,
          valueGetter: (_, row) => {
            return row.contractType?.id;
          },

          valueFormatter: (value: string) => {
            const contractTypeId = value;
            const selectedOption = contractTypeOptions.find(
              (valueOption: { value: string; label: string }) =>
                valueOption.value === contractTypeId
            );

            return selectedOption?.label;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<
              any,
              LocalDocumentTemplateRoute,
              any
            >
          ) => {
            return (
              <DataGridSingleSelect
                cellParams={cellParams}
                error={!cellParams.row.contractType}
                onValueChange={(event: SelectChangeEvent<any>) => {
                  const newSelectedContractType = contractTypeOptions.find(
                    (option) => option.value === event.target.value
                  );

                  onContractTypeChange(cellParams.row, {
                    id: newSelectedContractType!.value,
                    name: newSelectedContractType!.label,
                  });
                }}
              />
            );
          },
        },
    {
      field: "originatingParty",
      headerName: t("AdminConsole.Templates.labels.originatingParty"),
      flex: 0.125,
      minWidth: 90,
      resizable: true,
      editable: true,
      type: "singleSelect",
      valueOptions: (params) => {
        let crtRow: LocalDocumentTemplateRoute;
        if ((params as any).api) {
          const rows = (params as any).api.getRowModels() as Map<
            string,
            LocalDocumentTemplateRoute
          >;
          crtRow = rows.get(params.id as string)!;
        } else {
          crtRow = params.row!;
        }

        const selectedContractType = contractTypes.find(
          (contractType) => contractType.id === crtRow!.contractType?.id
        );

        return selectedContractType
          ? selectedContractType.contractBindingTypes.items.map(
              (bindingType) => ({
                value: bindingType.id,
                label: bindingType.description,
              })
            )
          : [];
      },
      valueGetter: (_, row) => {
        return row.originatingParty?.id;
      },
      valueFormatter: (value, row, column, api) => {
        const originatingPartyId = value;
        const colDef = api.current.getColumn(
          column.field
        ) as GridSingleSelectColDef<LocalDocumentTemplateRoute>;
        const valueOptions = (
          typeof colDef.valueOptions === "function"
            ? colDef.valueOptions({
                id: row.id,
                field: column.field,
                row,
              })
            : colDef.valueOptions
        ) as { value: string; label: string }[] | undefined;
        const selectedOption = valueOptions?.find(
          (valueOption) => valueOption.value === originatingPartyId
        );

        return selectedOption?.label;
      },
      renderEditCell: (
        cellParams: GridRenderEditCellParams<
          any,
          LocalDocumentTemplateRoute,
          any
        >
      ) => (
        <DataGridSingleSelect
          cellParams={cellParams}
          error={!cellParams.row.originatingParty}
          onValueChange={(event: SelectChangeEvent<any>) => {
            const colDef =
              cellParams.colDef as GridSingleSelectColDef<LocalDocumentTemplateRoute>;

            const options =
              typeof colDef.valueOptions === "function"
                ? (colDef.valueOptions(cellParams) as {
                    value: string;
                    label: string;
                  }[])
                : [];
            const newSelectedOriginatingParty = options.find(
              (option) => option.value === event.target.value
            );

            onOriginatingPartyChange(cellParams.row, {
              id: newSelectedOriginatingParty!.value,
              name: newSelectedOriginatingParty!.label,
            });
          }}
        />
      ),
    },
    {
      field: "targetParty",
      headerName: t("AdminConsole.Templates.labels.targetParty"),
      flex: 0.125,
      minWidth: 90,
      resizable: true,
      editable: true,
      type: "singleSelect",
      valueOptions: (params) => {
        let crtRow: LocalDocumentTemplateRoute;
        if ((params as any).api) {
          const rows = (params as any).api.getRowModels() as Map<
            string,
            LocalDocumentTemplateRoute
          >;
          crtRow = rows.get(params.id as string)!;
        } else {
          crtRow = params.row!;
        }

        const selectedContractType = contractTypes.find(
          (contractType) => contractType.id === crtRow!.contractType?.id
        );

        return selectedContractType
          ? selectedContractType.contractBindingTypes.items.map(
              (bindingType) => ({
                value: bindingType.id,
                label: bindingType.description,
              })
            )
          : [];
      },
      valueGetter: (_, row) => {
        return row.targetParty?.id;
      },
      valueFormatter: (value, row, column, api) => {
        const targetPartyId = value;
        const colDef = api.current.getColumn(
          column.field
        ) as GridSingleSelectColDef<LocalDocumentTemplateRoute>;
        const valueOptions = (
          typeof colDef.valueOptions === "function"
            ? colDef.valueOptions({
                id: row.id,
                field: column.field,
                row,
              })
            : colDef.valueOptions
        ) as DataGridSelectOption[] | undefined;

        const selectedOption = valueOptions?.find(
          (valueOption) => valueOption.value === targetPartyId
        );

        return selectedOption?.label;
      },
      renderEditCell: (
        cellParams: GridRenderEditCellParams<
          any,
          LocalDocumentTemplateRoute,
          any
        >
      ) => (
        <DataGridSingleSelect
          cellParams={cellParams}
          error={!cellParams.row.targetParty}
          onValueChange={(event: SelectChangeEvent<any>) => {
            const colDef =
              cellParams.colDef as GridSingleSelectColDef<LocalDocumentTemplateRoute>;

            const options =
              typeof colDef.valueOptions === "function"
                ? (colDef.valueOptions(cellParams) as DataGridSelectOption[])
                : [];
            const newTargetPartySelected = options.find(
              (option) => option.value === event.target.value
            );

            onTargetPartyChange(cellParams.row, {
              id: newTargetPartySelected!.value,
              name: newTargetPartySelected!.label as string,
            });
          }}
        />
      ),
    },
    {
      field: "documentTemplate",
      headerName: t("common.labels.documentTemplate"),
      flex: 0.125,
      minWidth: 90,
      resizable: true,
      editable: true,
      type: "singleSelect",
      valueOptions: documentTemplateOptions,
      valueGetter: (_, row) => {
        return row.documentTemplate?.id;
      },
      valueFormatter: (value: string) => {
        const documentTemplateId = value;
        const selectedOption = documentTemplateOptions.find(
          (valueOption: { value: string; label: string }) =>
            valueOption.value === documentTemplateId
        );

        return selectedOption?.label;
      },
      renderEditCell: (
        cellParams: GridRenderEditCellParams<
          any,
          LocalDocumentTemplateRoute,
          any
        >
      ) => (
        <DataGridSingleSelect
          cellParams={cellParams}
          error={!cellParams.row.documentTemplate}
          onValueChange={(event: SelectChangeEvent<any>) => {
            const newDocumentTemplateSelected = documentTemplateOptions.find(
              (option) => option.value === event.target.value
            );
            onDocumentTemplateChange(cellParams.row, {
              id: newDocumentTemplateSelected!.value,
              name: newDocumentTemplateSelected!.label,
            });
          }}
        />
      ),
    },
    {
      field: "actions",
      flex: 0.05,
      type: "actions",
      sortable: false,
      getActions: (cellParams) =>
        getCommonActions(
          cellParams,
          rowModesModel,
          handleSaveRow,
          handleDeleteRow
        ),
    },
  ];
};
