import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import {
  useAttachments,
  AttachmentOperation,
} from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import {
  Changelog,
  ChangelogPublicAPI,
} from "containers/Projects/components/Changelog/Changelog";
import { Comments } from "containers/Projects/components/Comments/Comments";
import { CompEventWidget } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget";
import {
  ExplorerContext,
  ExplorerState,
} from "containers/Projects/components/Explorer/Explorer.context";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  CompEventRegardingType,
  Contract,
  InstructionItem,
  ItemStatusOption,
  ProductSchema,
  ProductType,
  User,
} from "generated/graphql";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { useHasAccess } from "hooks/useHasAccess";
import { useShallDisplayCompEventWidget } from "hooks/useShallDisplayCompEventWidget";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { EWInstructionDetailsHeader } from "../../components/Header/EWInstructionDetailsHeader";
import { NewAppPaths } from "helpers/paths/paths";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useNavigateToRegisterPage } from "containers/Projects/hooks/useNavigateToRegisterPage";
import { useShallDisplayVariationWidget } from "containers/Projects/hooks/useShallDisplayVariationWidget";
import { VariationWidget } from "containers/Projects/components/Variations/VariationWidget/VariationWidget";
import { isNECContractType } from "containers/Projects/Projects.utils";
import { NotifiedUsersWidgetHeader } from "containers/Projects/components/NotifiedUsers/NotifiedUsersWidgetHeader";
import { NotifiedUsers } from "containers/Projects/components/NotifiedUsers/NotifiedUsers";
import { useInstructionDetails } from "./hooks/useInstructionDetails";
import { IntercomEvents } from "constants/intercom";
import { useInstructionDetailsTrackingEvents } from "./hooks/useInstructionDetailsTrackingEvents";
import { useHeaderLinks } from "containers/Projects/hooks/useHeaderLinks";
import { ChangelogTitle } from "containers/Projects/components/Changelog/ChangelogTitle";

export const InstructionDetails = () => {
  const { productInstanceId, instructionItemId } = useParams();
  const visitedRef = useRef<boolean>(false);
  const handleNavigateToRegisterPage = useNavigateToRegisterPage();
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const {
    attachmentsContainerRef,
    commentsContainerRef,
    commentsCount,
    handleCommentsLoaded,
    hasMoreComments,
    handleAttachmentsClick,
    handleCommentsClick,
  } = useHeaderLinks();

  const { trackInstructionDetailsEvent } =
    useInstructionDetailsTrackingEvents();

  const [changelogApiRef, setChangelogApiRef] = useState<ChangelogPublicAPI>();

  const reloadChangelog = useCallback(() => {
    changelogApiRef?.reload();
  }, [changelogApiRef]);

  const {
    changeInstructionStatus,
    changeInstructionStatusLoading,
    doEditInstruction,
    errorInstruction,
    instruction,
    instructionLoading,
    refetchInstruction,
  } = useInstructionDetails(reloadChangelog);

  const isContractTypeNEC = useMemo(
    () => isNECContractType(instruction?.productInstance.contract as Contract),
    [instruction]
  );

  const contractTypeId = useMemo(
    () => instruction?.productInstance.contract.contractTypeId,
    [instruction]
  );

  const canAccessCompEventWidget = useShallDisplayCompEventWidget(
    CompEventRegardingType.Instruction,
    instructionItemId!,
    instruction?.compEvent?.id,
    instruction?.productInstance.contract.id
  );

  const canAccessVariationWidget = useShallDisplayVariationWidget(
    instruction?.variation?.id ?? undefined,
    instruction?.productInstance.contract.id
  );

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[],
    operation: AttachmentOperation
  ) => {
    if (!instruction || !instructionItemId) return;

    await doEditInstruction({
      variables: {
        input: {
          id: instructionItemId,
          attachments: attachmentsUpdated ?? instruction.attachments, // see if needs ?? []; The same for EW
        },
      },
      optimisticResponse: {
        editInstructionItem: {
          ...instruction,
          __typename: "InstructionItem",
          attachments:
            operation === AttachmentOperation.Delete && instruction.attachments
              ? instruction.attachments.filter((attach) =>
                  attachmentsUpdated.find(
                    (crtAttach) => crtAttach.id === attach.id
                  )
                )
              : instruction.attachments,
        },
      },
    });
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    ((instruction?.attachments as Attachment[]) ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const handleChangeInstructionStatus = async (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => {
    await changeInstructionStatus({
      variables: {
        input: {
          id: instructionItemId!,
          newStatusId: newStatus.id,
          reasonId,
          remarks,
        },
      },
    });
  };

  const handleCommentAdded = (noOfMentions: number) => {
    reloadChangelog();
    instruction &&
      trackInstructionDetailsEvent(
        IntercomEvents.AddedComment,
        instruction as InstructionItem,
        {
          Product: instruction.productInstance.product.name,
          Mentions: noOfMentions,
        }
      );
  };

  useEffect(() => {
    if (changelogApiRef) {
      reloadChangelog();
    }
  }, [changelogApiRef, reloadChangelog]);

  useEffect(() => {
    if (instruction) {
      changeExplorerEntities({
        projectId: instruction?.productInstance.contract.project.id,
        contractId: instruction?.productInstance.contract.id,
        productId: instruction?.productInstance.product.id,
        productInstanceId: instruction?.productInstanceId,
      });
    }
  }, [changeExplorerEntities, instruction]);

  useEffect(() => {
    if (location.state) {
      changeExplorerEntities(location.state as ExplorerState);
    } else {
      // state not preset, needs fetching
      setExplorerDataLoading(true);
    }
  }, [setExplorerDataLoading, location, changeExplorerEntities]);

  useEffect(() => {
    if (instruction) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, instruction]);

  useEffect(() => {
    if (errorInstruction && !instruction) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [errorInstruction, instruction, navigate, clearExplorerData]);

  useEffect(() => {
    if (!visitedRef.current && instruction) {
      trackInstructionDetailsEvent(
        IntercomEvents.ViewedInstruction,
        instruction as InstructionItem
      );
      visitedRef.current = true;
    }
  }, [instruction, trackInstructionDetailsEvent]);

  const isCurrentUserOwner = instruction?.ownerId === authenticatedUser?.id;
  const canChangeStatus =
    useHasAccess(
      undefined,
      [PermissionEnum.ChangeStatus],
      productInstanceId ?? undefined
    ) || isCurrentUserOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserOwner;

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={instruction?.productInstance.contract.timeZone}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <EWInstructionDetailsHeader
            title={instruction?.title ?? ""}
            itemNumber={instruction?.number}
            statusOptionId={instruction?.statusOptionId || ""}
            dataLoading={instructionLoading || changeInstructionStatusLoading}
            productType={ProductType.Instructions}
            statusOptions={
              (instruction?.productInstance.statusCollection.statusOptions
                .items as ItemStatusOption[]) ?? []
            }
            isRecorded={!!instruction?.offline}
            onStatusChange={
              canChangeStatus ? handleChangeInstructionStatus : undefined
            }
            onBack={handleNavigateToRegisterPage}
            attachmentsCount={allAttachments.length}
            commentsCount={commentsCount}
            hasMoreComments={hasMoreComments}
            onAttachmentsClick={handleAttachmentsClick}
            onCommentsClick={handleCommentsClick}
          />
          <Box mt={3}>
            {instructionLoading || !instruction || !contractTypeId ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode={false}
                productItemType={ProductType.Instructions}
                schema={
                  instruction.productInstance.productSchema as ProductSchema
                }
                authAuditId={
                  instruction.authorizationWorkflowAudit?.id ?? undefined
                }
                isNECContractType={isContractTypeNEC}
                contractTypeId={contractTypeId}
                metadata={{
                  owner: instruction.owner as User,
                  creator: instruction.creator as User,
                  dateModified: instruction.dateModified,
                  dateCreated: instruction.dateCreated,
                  productItemId: instruction.id,
                  dateSent: instruction.dateSent,
                  offline: instruction.offline,
                }}
                schemaValues={instruction.data}
                contractCurrency={
                  instruction.productInstance.contract.valueCurrency ?? ""
                }
                contractTimezone={
                  instruction.productInstance.contract.timeZone ?? ""
                }
                productInstanceId={instruction.productInstanceId}
                onOwnerChange={reloadChangelog}
                mainColumnExtraWidgets={
                  <>
                    <Grid item xs={12} key="attachments" position="relative">
                      <SectionContainer ref={attachmentsContainerRef}>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          timezone={
                            instruction?.productInstance.contract.timeZone
                          }
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid item xs={12} key="comments" position="relative">
                      <SectionContainer ref={commentsContainerRef}>
                        <Comments
                          productType={ProductType.Instructions}
                          productItemId={instructionItemId!}
                          productInstanceId={productInstanceId!}
                          timezone={
                            instruction?.productInstance.contract.timeZone
                          }
                          onCommentAdded={handleCommentAdded}
                          onCommentsLoaded={handleCommentsLoaded}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
                secondaryColumnExtraWidgets={
                  <>
                    {canAccessCompEventWidget && (
                      <Box width="100%" key="comp-event" position="relative">
                        <SectionContainer>
                          <CompEventWidget
                            loading={instructionLoading}
                            regardingId={instructionItemId!}
                            compEventId={instruction.compEvent?.id}
                            contract={
                              instruction.productInstance.contract as Contract
                            }
                            projectId={
                              instruction.productInstance.contract.project.id
                            }
                            projectName={
                              instruction.productInstance.contract.project
                                .friendlyName
                            }
                            instructionItem={instruction as InstructionItem}
                            onChange={refetchInstruction}
                          />
                        </SectionContainer>
                      </Box>
                    )}
                    {canAccessVariationWidget && (
                      <Box width="100%" key="variation" position="relative">
                        <SectionContainer>
                          <VariationWidget
                            loading={instructionLoading}
                            regardingId={instructionItemId!}
                            variationId={instruction.variation?.id}
                            contract={
                              instruction.productInstance.contract as Contract
                            }
                            projectId={
                              instruction.productInstance.contract.project.id
                            }
                            projectName={
                              instruction.productInstance.contract.project
                                .friendlyName
                            }
                            instructionItem={instruction as InstructionItem}
                            onChange={refetchInstruction}
                          />
                        </SectionContainer>
                      </Box>
                    )}
                    <Box width="100%" key="changelog" position="relative">
                      <CollapsibleSectionContainer
                        collapsible
                        title={<ChangelogTitle />}
                        maxHeight="600px"
                        overflow="auto"
                      >
                        <Changelog
                          productItemId={instruction.id}
                          productType={ProductType.Instructions}
                          productInstanceId={productInstanceId!}
                          contractTimezone={
                            instruction.productInstance.contract.timeZone ?? ""
                          }
                          ref={(apiRef) => {
                            setChangelogApiRef(apiRef!);
                          }}
                        />
                      </CollapsibleSectionContainer>
                    </Box>
                    {instruction.notificationRecipients.length > 0 && (
                      <Box
                        width="100%"
                        key="notified-users"
                        position="relative"
                      >
                        <CollapsibleSectionContainer
                          collapsible
                          initialCollapseState={true}
                          title={
                            <NotifiedUsersWidgetHeader
                              recipientsCount={
                                instruction.notificationRecipients.length
                              }
                            />
                          }
                        >
                          <NotifiedUsers
                            recipients={instruction.notificationRecipients}
                          />
                        </CollapsibleSectionContainer>
                      </Box>
                    )}
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
