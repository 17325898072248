import { gql } from "@apollo/client";
import { disabledClaimTypeOverrideFields } from "graphql/fragments/disabledClaimTypeOverride.fragment";

export const disabledClaimTypesOverrideQuery = gql`
  ${disabledClaimTypeOverrideFields}

  query disabledClaimTypesOverride($contractId: ID!) {
    disabledClaimTypesOverride(contractId: $contractId) {
      items {
        ...DisabledClaimTypeOverrideFields
      }
    }
  }
`;
