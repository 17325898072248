import React from "react";
import { useTheme } from "@mui/material";

export type AnonymousProfileProps = {
  width?: string;
  height?: string;
};

export const AnonymousProfile: React.FC<AnonymousProfileProps> = ({
  width = "57",
  height = "57",
}) => {
  const theme = useTheme();

  const foregroundColor = theme.palette.grey[300];
  const backgroundColor = theme.palette.grey[100];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="55"
        rx="27.5"
        fill={backgroundColor}
      />
      <path
        d="M37.7406 35.8752C36.3411 33.4644 34.1011 31.655 31.45 30.7939C32.7207 30.0383 33.7082 28.8863 34.2608 27.5149C34.8134 26.1436 34.9004 24.6288 34.5086 23.2032C34.1167 21.7776 33.2677 20.5201 32.0918 19.6239C30.916 18.7276 29.4784 18.2422 28 18.2422C26.5215 18.2422 25.0839 18.7276 23.9081 19.6239C22.7323 20.5201 21.8832 21.7776 21.4914 23.2032C21.0995 24.6288 21.1866 26.1436 21.7391 27.5149C22.2917 28.8863 23.2792 30.0383 24.55 30.7939C21.8988 31.655 19.6588 33.4644 18.2593 35.8752C18.1906 35.9882 18.1543 36.1179 18.1543 36.2502C18.1543 36.3824 18.1906 36.5121 18.2593 36.6252C18.3234 36.7401 18.4174 36.8356 18.5312 36.9016C18.6451 36.9676 18.7746 37.0016 18.9062 37.0002H37.0937C37.2253 37.0016 37.3549 36.9676 37.4687 36.9016C37.5826 36.8356 37.6765 36.7401 37.7406 36.6252C37.8093 36.5121 37.8456 36.3824 37.8456 36.2502C37.8456 36.1179 37.8093 35.9882 37.7406 35.8752Z"
        fill={foregroundColor}
      />
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="55"
        rx="27.5"
        stroke={foregroundColor}
        strokeDasharray="4 4"
      />
    </svg>
  );
};
