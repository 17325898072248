import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventQuotationRevisionRequestMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventQuotationRevisionRequest(
    $input: RecordCompEventQuotationRevisionRequestInput!
  ) {
    recordCompEventQuotationRevisionRequest(input: $input) {
      ...CompEventItemFields
    }
  }
`;
