import { useMemo } from "react";
import { useClaimDetailsExplorerItems } from "../../../hooks/useClaimDetailsExplorerItems/useClaimDetailsExplorerItems";

export const useFinalClaimAgreement = () => {
  const { claimAgreements, loading } = useClaimDetailsExplorerItems();

  const finalAgreement = useMemo(() => {
    return claimAgreements.sort((agreement1, agreement2) => {
      const date1 = new Date(agreement1.dateCreated as string).getTime();
      const date2 = new Date(agreement2.dateCreated as string).getTime();

      return date2 - date1;
    })[0];
  }, [claimAgreements]);

  return {
    finalAgreement,
    loading,
  };
};
