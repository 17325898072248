import {
  RemoveCompEventPreviewMutation,
  RemoveCompEventPreviewMutationVariables,
} from "generated/graphql";
import { removeCompEventPreviewMutation } from "graphql/mutations/removeCompEventPreview";
import { useGraphMutation } from "hooks/useGraphMutation";

export const useRemoveCEPreview = () => {
  const [removeCEPreview, { loading: removeCEPreviewLoading }] =
    useGraphMutation<
      RemoveCompEventPreviewMutation,
      RemoveCompEventPreviewMutationVariables
    >(removeCompEventPreviewMutation, {}, null);

  return {
    removeCEPreview,
    loading: removeCEPreviewLoading,
  };
};
