import { gql } from "@apollo/client";
import { statusOptionLightFields } from "graphql/fragments/statusOptionLite.fragment";

export const changeRiskItemOwnerMutation = gql`
  ${statusOptionLightFields}

  mutation changeRiskItemOwner($id: ID!, $ownerId: ID!) {
    changeRiskItemOwner(id: $id, ownerId: $ownerId) {
      id
      title
      statusOption {
        ...StatusOptionLightFields
      }
      dateModified
      ownerId
      owner {
        id
        firstname
        surname
        jobTitle
        company {
          id
          registeredName
          tradingName
        }
      }
    }
  }
`;
