import {
  addProductUserRoleMutation,
  editProductUserRoleMutation,
  changeProductUserRoleStatusMutation,
} from "containers/AdminConsole/containers/Projects/ContractDetails/ContractProductInstanceDetails/components/ProductInstanceRoles/ProductInstanceRoles.query";
import { OnMutationDone } from "types/decl";
import {
  AddProductUserRoleInput,
  AddProductUserRoleMutation,
  AddProductUserRoleMutationVariables,
  ChangeProductUserRoleStatusMutation,
  ChangeProductUserRoleStatusMutationVariables,
  EditProductUserRoleInput,
  EditProductUserRoleMutation,
  EditProductUserRoleMutationVariables,
  ProductUserRoleStatus,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type useUserProductRoleProps = {
  onAdd: OnMutationDone;
  onEdit: OnMutationDone;
  onStatusChange: OnMutationDone;
};

export const useUserProductRole = ({
  onAdd,
  onEdit,
  onStatusChange,
}: useUserProductRoleProps) => {
  const { t } = useTranslation();

  const [addUserRole, { loading: addUserRoleLoading }] = useGraphMutation<
    AddProductUserRoleMutation,
    AddProductUserRoleMutationVariables
  >(
    addProductUserRoleMutation,
    {
      update: onAdd,
    },
    t("common.successMessages.entityCreated", {
      entity: t("common.labels.role"),
    })
  );

  const [editUserRole, { loading: editUserRoleLoading }] = useGraphMutation<
    EditProductUserRoleMutation,
    EditProductUserRoleMutationVariables
  >(
    editProductUserRoleMutation,
    {
      update: onEdit,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.role"),
    })
  );

  const [changeUserRoleStatus, { loading: changeUserRoleStatusLoading }] =
    useGraphMutation<
      ChangeProductUserRoleStatusMutation,
      ChangeProductUserRoleStatusMutationVariables
    >(
      changeProductUserRoleStatusMutation,
      {
        update: onStatusChange,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.role"),
      })
    );

  const handleAddUserRole = useCallback(
    async (userRole: AddProductUserRoleInput) => {
      const { errors } = await addUserRole({
        variables: {
          input: userRole,
        },
      });

      return !errors;
    },
    [addUserRole]
  );

  const handleEditUserRole = useCallback(
    async (userRole: EditProductUserRoleInput) => {
      const { errors } = await editUserRole({
        variables: {
          input: userRole,
        },
      });

      return !errors;
    },
    [editUserRole]
  );

  const handleUserRoleStatusChange = useCallback(
    async (
      userRole: EditProductUserRoleInput,
      newStatus: ProductUserRoleStatus
    ) => {
      const { errors } = await changeUserRoleStatus({
        variables: {
          id: userRole.id,
          status: newStatus,
        },
      });

      return !errors;
    },
    [changeUserRoleStatus]
  );

  return {
    addUserRole: handleAddUserRole,
    addUserRoleLoading,
    editUserRole: handleEditUserRole,
    editUserRoleLoading,
    changeUserRoleStatus: handleUserRoleStatusChange,
    changeUserRoleStatusLoading,
  };
};
