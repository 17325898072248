import {
  ChangeRiskItemOwnerMutation,
  ChangeRiskItemOwnerMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "./useGraphMutation";
import { changeRiskItemOwnerMutation } from "graphql/mutations/changeRiskOwner";
import { useTranslation } from "react-i18next";

export const useChangeRiskOwner = (onOwnerChange?: () => void) => {
  const { t } = useTranslation();

  const [changeRiskOwner, { loading }] = useGraphMutation<
    ChangeRiskItemOwnerMutation,
    ChangeRiskItemOwnerMutationVariables
  >(
    changeRiskItemOwnerMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "riskItems" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "riskItem" });
        cache.gc();
        onOwnerChange?.();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Risks.riskItem"),
    })
  );

  return {
    changeRiskOwner,
    loading,
  };
};
