import { Box, Typography } from "@mui/material";
import { ListItemsDivider } from "components/ListItemsDivider";
import { UserPreferences, UserPreferencesValueInput } from "generated/graphql";
import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationPreferenceListItem } from "./NotificationPreferenceListItem";
import { preferencesList } from "./NotificationsSettings.constants";

export type NotificationsPreferencesListProps = {
  preferences: UserPreferences;
  loading?: boolean;
  onChange: (key: string, updatedPreference: UserPreferencesValueInput) => void;
};

export const NotificationsPreferencesList: React.FC<
  NotificationsPreferencesListProps
> = ({ preferences, loading, onChange }) => {
  const { t } = useTranslation();

  const handlePreferenceChange = (
    id: string,
    newPreference: UserPreferencesValueInput
  ) => {
    onChange(id, newPreference);
  };

  return (
    <Box>
      {Object.values(preferencesList).map((preferenceGroup, index) => {
        return (
          <React.Fragment key={preferenceGroup.label}>
            <Typography variant="p1" color="grey.900" fontWeight={600}>
              {t(preferenceGroup.label)}
            </Typography>
            {preferenceGroup.items.map((preferenceItem) => (
              <React.Fragment key={preferenceItem.key}>
                <NotificationPreferenceListItem
                  label={t(preferenceItem.label)}
                  id={preferenceItem.key}
                  readOnly={!!loading}
                  preference={(preferences as any)[preferenceItem.key]}
                  onChange={handlePreferenceChange}
                />
              </React.Fragment>
            ))}
            {index < Object.values(preferencesList).length - 1 && (
              <ListItemsDivider />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};
