import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const attachmentLiteFields = gql`
  ${creatorFields}

  fragment AttachmentLiteFields on Attachment {
    id
    fileName
    fileUrl
    mimeType
    description
    locked
    fileSize
    status
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
      company {
        id
        registeredName
        tradingName
      }
    }
  }
`;
