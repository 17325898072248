import {
  ChangeLogItem,
  EarlyWarningItemChangelogQuery,
  EarlyWarningItemChangelogQueryVariables,
  EffectPreset,
  EventItemChangelogQuery,
  EventItemChangelogQueryVariables,
  InstructionItemChangelogQuery,
  InstructionItemChangelogQueryVariables,
  ItemEffectPresetQuery,
  ItemEffectPresetQueryVariables,
  ProductType,
  RiskItemChangelogQuery,
  RiskItemChangelogQueryVariables,
} from "generated/graphql";
import { itemEffectPresetQuery } from "graphql/queries/effectPresetByProductInstanceId.query";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useMemo, useState } from "react";
import {
  earlyWarningItemChangelogQuery,
  eventItemChangelogQuery,
  instructionItemChangelogQuery,
  riskItemChangelogQuery,
} from "./Changelog.query";

export type useChangelogArgs = {
  productItemId: string;
  type: ProductType;
  productInstanceId: string;
};

export type useChangelogReturnType = {
  changelogs: ChangeLogItem[];
  loading: boolean;
  effectPreset?: EffectPreset;
  reload: () => void;
};

export const useChangelog = ({
  type,
  productItemId,
  productInstanceId,
}: useChangelogArgs): useChangelogReturnType => {
  const [localLoading, setLocalLoading] = useState(false);
  const [fetchChangelogs, { data: changelog, loading: getChangelogLoading }] =
    useGraphLazyQueryLite<
      | RiskItemChangelogQuery
      | EarlyWarningItemChangelogQuery
      | EventItemChangelogQuery
      | InstructionItemChangelogQuery,
      | RiskItemChangelogQueryVariables
      | EarlyWarningItemChangelogQueryVariables
      | EventItemChangelogQueryVariables
      | InstructionItemChangelogQueryVariables
    >(
      type === ProductType.RisksRegister
        ? riskItemChangelogQuery
        : type === ProductType.EarlyWarnings
        ? earlyWarningItemChangelogQuery
        : type === ProductType.Events
        ? eventItemChangelogQuery
        : instructionItemChangelogQuery
    );

  const { data: effectPreset, loading: getEffectPresetLoading } = useGraphQuery<
    ItemEffectPresetQuery,
    ItemEffectPresetQueryVariables
  >(itemEffectPresetQuery, { variables: { productInstanceId } });

  const reload = useCallback(() => {
    // logs are not automatically updated in the DB as they are asynchronous between the systems. Setting a delay of 5s. TODO
    setLocalLoading(true);
    setTimeout(async () => {
      await fetchChangelogs({ id: productItemId }, "network-only");
      setLocalLoading(false);
    }, 3000);
  }, [fetchChangelogs, productItemId]);

  const changeLogs = useMemo(() => {
    switch (type) {
      case ProductType.RisksRegister:
        return ((changelog?.data as RiskItemChangelogQuery)?.riskItem.changeLog
          .items ?? []) as unknown[] as ChangeLogItem[];
      case ProductType.EarlyWarnings:
        return ((changelog?.data as EarlyWarningItemChangelogQuery)
          ?.earlyWarningItem?.changeLog.items ??
          []) as unknown[] as ChangeLogItem[];
      case ProductType.Events:
        return ((changelog?.data as EventItemChangelogQuery)?.eventItem
          .changeLog.items ?? []) as unknown[] as ChangeLogItem[];
      case ProductType.Instructions:
        return ((changelog?.data as InstructionItemChangelogQuery)
          ?.instructionItem?.changeLog.items ??
          []) as unknown[] as ChangeLogItem[];
      default:
        return [];
    }
  }, [type, changelog]);

  return {
    changelogs: changeLogs,
    loading: getChangelogLoading || getEffectPresetLoading || localLoading,
    effectPreset: effectPreset?.itemEffectPreset as EffectPreset,
    reload,
  };
};
