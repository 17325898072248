import { NewAppPaths } from "helpers/paths/paths";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useExplorer = () => {
  const navigate = useNavigate();

  const handleSelectProject = useCallback(
    (newProjectId: string) => {
      if (newProjectId) {
        navigate(
          {
            pathname: NewAppPaths.authorized.Projects.path,
            search: `projectId=${newProjectId}`,
          },
          { replace: true }
        );
      }
    },
    [navigate]
  );

  const handleSelectContract = useCallback(
    (projectId: string, newContractId?: string) => {
      if (newContractId) {
        navigate(
          {
            pathname: NewAppPaths.authorized.Projects.path,
            search: `projectId=${projectId}&contractId=${newContractId}`,
          },
          { replace: true }
        );
      }
    },
    [navigate]
  );

  const handleSelectProduct = useCallback(
    (projectId: string, contractId: string, newProductId?: string) => {
      if (newProductId) {
        navigate(
          {
            pathname: NewAppPaths.authorized.Projects.path,
            search: `projectId=${projectId}&contractId=${contractId}&productId=${newProductId}`,
          },
          { replace: true }
        );
      }
    },
    [navigate]
  );

  const handleSelectProductInstance = useCallback(
    (
      projectId: string,
      contractId: string,
      productId: string,
      newProductInstanceId?: string
    ) => {
      if (newProductInstanceId) {
        navigate(
          {
            pathname: NewAppPaths.authorized.Projects.path,
            search: `projectId=${projectId}&contractId=${contractId}&productId=${productId}&productInstanceId=${newProductInstanceId}`,
          },
          { replace: true }
        );
      }
    },
    [navigate]
  );

  return {
    changeProject: handleSelectProject,
    changeContract: handleSelectContract,
    changeProduct: handleSelectProduct,
    changeProductInstance: handleSelectProductInstance,
  };
};
