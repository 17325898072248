import { Box, Typography } from "@mui/material";
import {
  DraftClaimAgreement,
  DraftClaimDetermination,
  DraftDetailedClaim,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import {
  ClaimItemsTypes,
  DetailedClaimsAgreementsDeterminationsTable,
} from "./DetailedClaimsAgreementsDeterminationsTable/DetailedClaimsAgreementsDeterminationsTable";
import { useContext } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";

export type DetailedClaimAgreementDeterminationSummaryProps = {
  contractCurrency: string;
  items:
    | DraftDetailedClaim[]
    | DraftClaimAgreement[]
    | DraftClaimDetermination[];
  type: ClaimItemsTypes;
};

export const DetailedClaimAgreementDeterminationSummary: React.FC<
  DetailedClaimAgreementDeterminationSummaryProps
> = ({ contractCurrency, type, items }) => {
  const { t } = useTranslation();
  const { isFIDIC99RedYellow } = useContext(ClaimWidgetContext);

  return (
    <Box>
      <Typography
        variant="h4"
        fontSize="18px"
        color="grey.800"
        fontWeight={600}
        mb={2.5}
      >
        {type === "DetailedClaim"
          ? t(
              `Projects.Claims.detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
            )
          : type === "ClaimAgreement"
          ? t(
              `Projects.Claims.claimAgreement${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            )
          : t(
              `Projects.Claims.claimDetermination${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            )}
      </Typography>
      <DetailedClaimsAgreementsDeterminationsTable
        items={items}
        type={type}
        contractCurrency={contractCurrency}
      />
    </Box>
  );
};
