import { Box, css, styled } from "@mui/material";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";

export const StyledContentEditable = styled(ContentEditable)(
  ({ theme }) => css`
    width: 100%;
    padding: ${theme.spacing(1)};
    color: ${theme.palette.grey[900]};
    font-size: ${theme.typography.p1.fontSize};
    font-weight: ${theme.typography.p1.fontWeight};
    line-height: ${theme.typography.p1.lineHeight};
    box-sizing: border-box;
    outline: none;
    border-radius: 8px;
  `
);

export const EditorWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasError" && prop !== "disabled",
})<{
  disabled?: boolean;
  hasError?: boolean;
}>(
  ({ theme, disabled, hasError }) => css`
    position: relative;
    border: 1.5px solid
      ${hasError ? theme.palette.error.main : theme.palette.grey[300]};
    border-radius: 8px;
    width: 100%;
    background: #fff;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;

    ${disabled
      ? css`
          pointer-events: none;
          background: theme.palette.grey[100];
          cursor: "default";
        `
      : ""}
  `
);

const selectedColor = "#1877e833";
export const ToolbarIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(
  ({ theme, isSelected }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1)};
    border-radius: 8px;
    background: ${isSelected ? selectedColor : "transparent"};

    &&:hover {
      svg {
        cursor: pointer;
        color: black;
      }
    }
  `
);
