import { gql } from "@apollo/client";

export const addProductUserRoleMutation = gql`
  mutation addProductUserRole($input: AddProductUserRoleInput!) {
    addProductUserRole(input: $input) {
      id
      userId
      productInstanceId
      productRoleId
      status
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
      user {
        id
        firstname
        surname
        jobTitle
        country
        mobileNumber
        alternateNumber
        dateOfBirth
        profilePicture
        email
        companyId
        company {
          id
          registeredName
          tradingName
        }
        registered
        status
        lastActive
        dateInvited
        registeredDate
      }
      productRole {
        id
        name
        productId
        actionIds
        status
        dateCreated
        creatorId
        creator {
          id
          firstname
          surname
        }
        actions {
          items {
            id
            name
            status
            dateCreated
            creatorId
            creator {
              id
              firstname
              surname
            }
          }
        }
      }
    }
  }
`;

export const getAvailableUsersForRolesQuery = gql`
  query GetAvailableUsersForRoles($id: ID!) {
    productInstance(id: $id) {
      id
      description
      numberingFormat
      soloModeSupported
      contractId
      productId
      productSchemaId
      statusCollectionId
      status
      dateCreated
      creatorId
      contract {
        id
        name
        friendlyName
        projectId
        status
        activeBindings {
          items {
            id
            contractBindingTypeId
            status
            defaultCompany
            representativeId
            isCompanyBilled
            companyId
            contractId
            company {
              id
              type
              registeredName
              status
              tradingName
              registrationNumber
              logo
              vatRegistrationNumber
              users {
                items {
                  id
                  firstname
                  surname
                  email
                  registered
                  status
                  jobTitle
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const changeProductUserRoleStatusMutation = gql`
  mutation changeProductUserRoleStatus(
    $id: ID!
    $status: ProductUserRoleStatus!
  ) {
    changeProductUserRoleStatus(id: $id, status: $status) {
      userId
      productInstanceId
      productRoleId
    }
  }
`;

export const editProductUserRoleMutation = gql`
  mutation editProductUserRole($input: EditProductUserRoleInput!) {
    editProductUserRole(input: $input) {
      userId
      productInstanceId
      productRoleId
    }
  }
`;
