import { gql } from "@apollo/client";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";

export const variationActionQuery = gql`
  ${authorizationWorkflowActionMappingFields}

  query variationAction($input: VariationActionInput!) {
    variationAction(input: $input) {
      items {
        type
        actionMapping {
          ...AuthorizationWorkflowActionMappingFields
        }
      }
    }
  }
`;
