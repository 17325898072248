import { Box, Color, css, styled } from "@mui/material";

export const OwnerFlyoutTrigger = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "readOnly",
})<{
  active?: boolean;
  readOnly?: boolean;
}>(
  ({ theme, active, readOnly }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    cursor: pointer;
    color: ${theme.palette.grey[800]};
    box-sizing: border-box;
    height: 36px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: transparent;

    & svg {
      margin-left: ${theme.spacing(2)};
      visibility: hidden;
    }

    ${active &&
    css`
      border-color: ${(theme.palette.primary as Partial<Color>)[500]};
      svg {
        visibility: visible;
      }
    `}

    ${readOnly &&
    css`
      pointer-events: none;
    `}

    border-color: ${theme.palette.grey[300]};
    svg {
      visibility: visible;
    }

    /* &:hover { */
    /* border-color: ${theme.palette.grey[300]};
    svg {
      visibility: visible;
    } */
    /* } */
  `
);
