import { Box, Button, Typography, useTheme } from "@mui/material";
import { DaysLate } from "containers/Projects/components/DaysLate";
import {
  ClaimActionType,
  ClaimHistoryItem,
  ClaimItem,
  CompEventHistoryItem,
  ProductType,
  VariationHistoryItem,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { HistoryModal } from "containers/Projects/components/HistoryModal/HistoryModal";
import { useCallback, useContext, useState } from "react";
import { useClaimHistory } from "../hooks/useClaimHistory";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CaretRight } from "phosphor-react";
import { ClaimHistoryItemDetails } from "../../ClaimHistoryModal/ClaimHistoryItemDetails";
import { ClaimWidgetContext } from "../ClaimWidget.context";
import {
  ClaimActionTypeAll,
  ClaimActionTypeFIDIC99RedYellow,
  claimActionTypeFIDIC99RedYellowTypeMapping,
} from "../../types";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";

export type ClaimWidgetLastStepSectionProps = {
  claim: ClaimItem;
  previewMode?: boolean;
};

export const actionTypeToLastActionTextMapping: Record<
  ClaimActionTypeAll,
  string
> = {
  [ClaimActionType.NotifyClaim]: "Projects.Claims.LastAction.NotifyClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaim99RY]:
    "Projects.Claims.LastAction.NotifyClaim99RY",
  [ClaimActionType.SubmitInterimDetailedClaim]:
    "Projects.Claims.LastAction.SubmitInterimDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.SubmitInterimDetailedClaim99RY]:
    "Projects.Claims.LastAction.SubmitInterimDetailedClaim99RY",
  [ClaimActionType.SubmitFinalDetailedClaim]:
    "Projects.Claims.LastAction.SubmitFinalDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.SubmitFinalDetailedClaim99RY]:
    "Projects.Claims.LastAction.SubmitFinalDetailedClaim99RY",
  [ClaimActionType.NotifyAgreementClaim]:
    "Projects.Claims.LastAction.NotifyAgreementClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaim99RY]:
    "Projects.Claims.LastAction.NotifyAgreementClaim99RY",
  [ClaimActionType.RequestMoreTime]:
    "Projects.Claims.LastAction.RequestMoreTime",
  [ClaimActionType.NotifyAgreementClaimNotReached]:
    "Projects.Claims.LastAction.NotifyAgreementClaimNotReached",
  [ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaimNotReached99RY]:
    "Projects.Claims.LastAction.NotifyAgreementClaimNotReached99RY",
  [ClaimActionType.NotifyDeterminationClaim]:
    "Projects.Claims.LastAction.NotifyDeterminationClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyDeterminationClaim99RY]:
    "Projects.Claims.LastAction.NotifyDeterminationClaim99RY",
  [ClaimActionType.RequestFurtherParticulars]:
    "Projects.Claims.LastAction.RequestFurtherParticulars",
  [ClaimActionTypeFIDIC99RedYellow.RequestFurtherParticulars99RY]:
    "Projects.Claims.LastAction.RequestFurtherParticulars99RY",
  [ClaimActionType.ProvideFurtherParticulars]:
    "Projects.Claims.LastAction.ProvideFurtherParticulars",
  [ClaimActionTypeFIDIC99RedYellow.ProvideFurtherParticulars99RY]:
    "Projects.Claims.LastAction.ProvideFurtherParticulars99RY",
  [ClaimActionType.NotifyClaimNoticeInvalid]:
    "Projects.Claims.LastAction.NotifyClaimNoticeInvalid",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaimNoticeInvalid99RY]:
    "Projects.Claims.LastAction.NotifyClaimNoticeInvalid99RY",
  [ClaimActionType.NotifyClaimNoticeValidityHasLapsed]:
    "Projects.Claims.LastAction.NotifyClaimNoticeValidityHasLapsed",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaimNoticeValidityHasLapsed99RY]:
    "Projects.Claims.LastAction.NotifyClaimNoticeValidityHasLapsed99RY",
  [ClaimActionType.RespondRegardingDetailedClaim]:
    "Projects.Claims.LastAction.RespondRegardingDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.RespondRegardingDetailedClaim99RY]:
    "Projects.Claims.LastAction.RespondRegardingDetailedClaim99RY",
  [ClaimActionType.NotifyErrorFoundAgreement]:
    "Projects.Claims.LastAction.NotifyErrorFoundAgreement",
  [ClaimActionType.DisagreeWithNoticeErrorAgreement]:
    "Projects.Claims.LastAction.DisagreeWithNoticeErrorAgreement",
  [ClaimActionType.NotifyErrorFoundDetermination]:
    "Projects.Claims.LastAction.NotifyErrorFoundDetermination",
  [ClaimActionType.DisagreeWithNoticeErrorDetermination]:
    "Projects.Claims.LastAction.DisagreeWithNoticeErrorDetermination",
} as any;

export const ClaimWidgetLastStepSection: React.FC<
  ClaimWidgetLastStepSectionProps
> = ({ claim, previewMode = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const { contract, isFIDIC99RedYellow } = useContext(ClaimWidgetContext);

  const { claimHistoryItems, loading: claimHistoryItemsLoading } =
    useClaimHistory(claim.id);

  const toggleHistoryModal = useCallback(
    () => setHistoryModalVisible((state) => !state),
    []
  );

  return (
    <>
      {historyModalVisible && (
        <HistoryModal
          open={historyModalVisible}
          title={claim.number}
          historyItems={claimHistoryItems}
          onClose={toggleHistoryModal}
          productType={ProductType.Claims}
          contract={contract}
          isFIDIC99RedYellow={isFIDIC99RedYellow}
          renderHistoryItemDetails={(
            historyItem:
              | ClaimHistoryItem
              | CompEventHistoryItem
              | VariationHistoryItem
          ) => (
            <ClaimHistoryItemDetails
              historyItem={historyItem as ClaimHistoryItem}
            />
          )}
        />
      )}
      <Box
        display="flex"
        flexDirection={previewMode ? "row" : "column"}
        alignItems={previewMode ? "center" : "flex-start"}
        width="100%"
      >
        <Box display="flex" flexDirection="column" flex={3}>
          <Typography variant="p2" fontWeight={600}>
            {t("Projects.Claims.lastAction")}
          </Typography>
          <Typography variant="p2" mt={0.5} data-testid="last-action">
            {t(
              actionTypeToLastActionTextMapping[
                isFIDIC99RedYellow
                  ? claimActionTypeFIDIC99RedYellowTypeMapping(
                      claim.lastAction.actionType
                    )
                  : claim.lastAction.actionType
              ]
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          mt={previewMode ? 0 : 2}
          flex={1}
        >
          <Typography variant="p2" fontWeight={600}>
            {t("common.labels.dateSent")}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            mt={0.5}
            data-testid="date-sent"
          >
            <DateWithTimeTooltip
              datetime={claim.lastAction.date}
              timezone={contract.timeZone}
              variant="p2"
            />
            {claim.lastAction.daysLate ? (
              <DaysLate daysLate={claim.lastAction.daysLate} ml={1.5} />
            ) : null}
          </Box>
        </Box>
        {!previewMode && (
          <Box mt={3}>
            {claimHistoryItemsLoading ? (
              <CenteredLoadingIndicator size={20} />
            ) : (
              <Button
                variant="text"
                sx={{ padding: 0 }}
                onClick={toggleHistoryModal}
                data-testid="view-full-history-btn"
              >
                <Typography
                  variant="p2"
                  color="grey.800"
                  fontWeight={600}
                  mr={0.25}
                >
                  {t("Projects.Claims.viewFullHistory", {
                    itemsNo: claimHistoryItems.length,
                  })}
                </Typography>
                <CaretRight color={theme.palette.grey[800]} size={18} />
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
