import { Checkbox, SelectChangeEvent } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
  GridEditInputCell,
  GridEditSingleSelectCell,
} from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { temporaryRowId } from "constants/constants";
import {
  ContractType,
  EffectPreset,
  EffectPresetStatus,
} from "generated/graphql";
import {
  getCommonActions,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<EffectPresetStatus>[],
  contractTypes: ContractType[],
  onStatusChange: (row: EffectPreset, newStatus: EffectPresetStatus) => void,
  rowModesModel: GridRowModesModel,
  handleSaveRow: (rowId: GridRowId) => void,
  handleDeleteRow: (rowId: GridRowId) => void,
  handleIsDefaultChange: (rowId: GridRowId) => void,
  onContractTypeChange: (row: EffectPreset, newContractTypeId: string) => void
): GridColDef<EffectPreset>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const contractTypeOptions = contractTypes.map((contractType) => ({
    value: contractType.id,
    label: `${contractType.description} ${contractType.version ?? ""} ${
      contractType.subType ?? ""
    }`,
  }));
  const columns = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: t("common.labels.name"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          editable: true,
          sortable: true,
          renderEditCell: (
            params: GridRenderEditCellParams<any, EffectPreset>
          ) => {
            return <GridEditInputCell {...params} error={!params.row.name} />;
          },
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, EffectPreset, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disablePortal={false}
                disabled={params.row.id === temporaryRowId}
                onChange={(newStatus: StatusOption<EffectPresetStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "isDefault",
          headerName: t("AdminConsole.Products.labels.isDefault"),
          flex: 0.075,
          minWidth: 90,
          resizable: true,
          editable: false,
          renderCell: (
            params: GridRenderCellParams<any, EffectPreset, any>
          ) => {
            return (
              <Checkbox
                checked={params.row.isDefault}
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
                onChange={() => {
                  handleIsDefaultChange(params.row.id);
                }}
              />
            );
          },
        },
        {
          field: "contractTypeId",
          headerName: t("common.labels.contractType"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          editable: true,
          sortable: true,
          type: "singleSelect",
          valueOptions: contractTypeOptions,
          valueFormatter: (value: string) => {
            const contractTypeId = value;
            const selectedOption = contractTypeOptions.find(
              (valueOption: { value: string; label: string }) =>
                valueOption.value === contractTypeId
            );

            return selectedOption?.label;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<any, EffectPreset, any>
          ) => (
            <GridEditSingleSelectCell
              {...cellParams}
              error={!cellParams.row.contractTypeId}
              onValueChange={(event: SelectChangeEvent<any>) => {
                onContractTypeChange(cellParams.row, event.target.value);
              }}
              initialOpen={false}
            />
          ),
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: true,
          valueGetter: (_, row) => {
            return getUserName(row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          sortable: false,
          getActions: (cellParams) =>
            getCommonActions(
              cellParams,
              rowModesModel,
              handleSaveRow,
              handleDeleteRow
            ),
        },
      ] as GridColDef<EffectPreset>[],
    [
      t,
      dateCreatedColConfig,
      statusOptions,
      contractTypeOptions,
      onStatusChange,
      rowModesModel,
      handleSaveRow,
      handleDeleteRow,
      handleIsDefaultChange,
      onContractTypeChange,
    ]
  );
  return columns;
};
