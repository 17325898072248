import {
  AddNewClaimTypeOverrideInput,
  ClaimType,
  ClaimTypeStatus,
  Contract,
  NewClaimTypeOverride,
  NewClaimTypeOverrideStatus,
} from "generated/graphql";
import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import { exportToExcel } from "helpers/exportToExcel";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getContractNewClaimTypesGridColumns,
  getContractTypeClaimTypesGridColumns,
} from "./ClaimTypes.constants";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { dateTimeISOFormat, temporaryRowId } from "constants/constants";
import { GlobalContext } from "state-management/globalContext/Global.context";
import moment from "moment";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { Coins } from "phosphor-react";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedSuspendedStatusOptions } from "components/StatusTag/useActiveRemovedSuspendedOptions";

export type ClaimTypesOverrideProps = {
  contractTypeClaimTypes: ClaimType[];
  newClaimTypes: NewClaimTypeOverride[];
  contractId: string;
  claimTypesLoading?: boolean;
  additionalClaimTypesLoading?: boolean;
  projectFriendlyName?: string;
  contractFriendlyName?: string;
  onContractTypeClaimTypeStatusChange: (
    claimTypeId: string,
    contractId: string,
    status: ClaimTypeStatus
  ) => Promise<boolean>;
  onAddNewClaimType: (input: AddNewClaimTypeOverrideInput) => Promise<boolean>;
  onNewClaimTypeStatusChange: (
    id: string,
    status: NewClaimTypeOverrideStatus
  ) => Promise<boolean>;
};

export const ClaimTypesOverride: React.FC<ClaimTypesOverrideProps> = ({
  contractTypeClaimTypes,
  newClaimTypes,
  claimTypesLoading,
  additionalClaimTypesLoading,
  projectFriendlyName,
  contractId,
  contractFriendlyName,
  onContractTypeClaimTypeStatusChange,
  onAddNewClaimType,
  onNewClaimTypeStatusChange,
}) => {
  const { t } = useTranslation();
  const { authenticatedUser } = useContext(GlobalContext);
  const theme = useTheme();
  const claimTypesStatusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ClaimTypeStatus>[];
  const newClaimTypesStatusOptions =
    useActiveRemovedSuspendedStatusOptions() as StatusOption<NewClaimTypeOverrideStatus>[];

  const [newClaimTypesRows, setNewClaimTypesRows] =
    useState<NewClaimTypeOverride[]>(newClaimTypes);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(newClaimTypes)
  );
  const [claimTypesGridVisibility, setClaimTypesGridVisibility] =
    useState(true);
  const [newClaimTypesGridVisibility, setNewClaimTypesGridVisibility] =
    useState(true);
  const [claimTypesSelectionModel, setClaimTypesSelectionModel] =
    useState<GridRowSelectionModel>();
  const [newClaimTypesSelectionModel, setNewClaimTypesSelectionModel] =
    useState<GridRowSelectionModel>();
  const [claimTypesSortingModel, setClaimTypesSortingModel] =
    useState<GridSortModel>([{ field: "clause", sort: "asc" }]);
  const [newClaimTypesSortingModel, setNewClaimTypesSortingModel] =
    useState<GridSortModel>([{ field: "clause", sort: "asc" }]);

  const [newClaimTypeFilterModel, setNewClaimTypeFilterModel] =
    useState<GridFilterModel>({
      items: [
        {
          field: "status",
          operator: "isAnyOf",
          value: [NewClaimTypeOverrideStatus.Active],
        },
      ],
      logicOperator: GridLogicOperator.And,
      quickFilterLogicOperator: GridLogicOperator.And,
      quickFilterValues: [],
    });

  const [claimTypeFilterModel, setClaimTypeFilterModel] =
    useState<GridFilterModel>({
      items: [
        {
          field: "status",
          operator: "isAnyOf",
          value: [ClaimTypeStatus.Active],
        },
      ],
      logicOperator: GridLogicOperator.And,
      quickFilterLogicOperator: GridLogicOperator.And,
      quickFilterValues: [],
    });

  const handleClaimTypesGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setClaimTypesSelectionModel(selectionModel);
    },
    []
  );

  const handleNewClaimTypesGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setNewClaimTypesSelectionModel(selectionModel);
    },
    []
  );

  const handleContractTypeClaimTypesExportToExcel = () => {
    const columns = [
      {
        header: t("common.labels.description"),
        key: "description",
        width: 20,
      },
      {
        header: t("AdminConsole.ClaimTypes.clause"),
        key: "clause",
        width: 20,
      },
      {
        header: t("common.labels.status"),
        key: "status",
        width: 20,
      },
    ];

    const rows = contractTypeClaimTypes.filter(
      (contractTypeClaimType) =>
        (claimTypesSelectionModel || []).indexOf(contractTypeClaimType.id) >= 0
    );

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}-${t(
        "AdminConsole.ClaimTypes.claimTypes"
      )}`,
      columns,
      rows
    );
  };

  const handleContractNewClaimTypesExportToExcel = () => {
    const columns = [
      {
        header: t("common.labels.description"),
        key: "description",
        width: 20,
      },
      {
        header: t("AdminConsole.ClaimTypes.clause"),
        key: "clause",
        width: 20,
      },
      {
        header: t("common.labels.status"),
        key: "status",
        width: 20,
      },
    ];

    const rows = newClaimTypesRows.filter(
      (contractClaimType) =>
        (newClaimTypesSelectionModel || []).indexOf(contractClaimType.id) >= 0
    );

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}-${t(
        "AdminConsole.ClaimTypes.additionalClaimTypes"
      )}`,
      columns,
      rows
    );
  };

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<NewClaimTypeOverride>,
      _: GridRowModel<NewClaimTypeOverride>
    ) => {
      if (!newRow.description || !newRow.clause) {
        setRowModesModel((prevData) => ({
          ...prevData,
          [temporaryRowId]: {
            mode: GridRowModes.Edit,
            fieldToFocus: !newRow.description ? "description" : "clause",
          },
        }));
        return newRow;
      }

      if (newRow.id === temporaryRowId) {
        const success = await onAddNewClaimType({
          contractId,
          clause: newRow.clause,
          description: newRow.description,
        });

        if (!success) {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "description",
            },
          }));
        }
      }

      return newRow;
    },
    [onAddNewClaimType, contractId]
  );

  const handleClaimTypeStatusChange = useCallback(
    (row: ClaimType, newStatus: ClaimTypeStatus) => {
      onContractTypeClaimTypeStatusChange(row.id, contractId, newStatus);
    },
    [onContractTypeClaimTypeStatusChange, contractId]
  );

  const handleNewClaimTypeStatusChange = useCallback(
    (row: NewClaimTypeOverride, newStatus: NewClaimTypeOverrideStatus) => {
      onNewClaimTypeStatusChange(row.id, newStatus);
    },
    [onNewClaimTypeStatusChange]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setNewClaimTypesRows((curRows) =>
          curRows.filter((row) => row.id !== rowId)
        );
      } else {
        onNewClaimTypeStatusChange(
          rowId as string,
          NewClaimTypeOverrideStatus.Removed
        );
      }
    },
    [onNewClaimTypeStatusChange]
  );

  const handleAddTemporaryRecord = () => {
    setNewClaimTypesRows((currentRecords) => [
      ...currentRecords,
      {
        id: temporaryRowId,
        clause: "",
        description: "",
        status: NewClaimTypeOverrideStatus.Active,
        contract: {} as Contract,
        contractId,
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        creatorId: authenticatedUser!.id,
        creator: authenticatedUser!,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "description",
        },
      }));
    });
  };

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const claimTypeColumns = useMemo(
    () =>
      getContractTypeClaimTypesGridColumns({
        statusOptions: claimTypesStatusOptions,
        onStatusChange: handleClaimTypeStatusChange,
        t,
      }),
    [handleClaimTypeStatusChange, claimTypesStatusOptions, t]
  );

  const newClaimTypeColumns = useMemo(
    () =>
      getContractNewClaimTypesGridColumns({
        statusOptions: newClaimTypesStatusOptions,
        rowModesModel,
        onSaveRow: handleRowSaveClick,
        onDeleteRow: handleDeleteRow,
        onStatusChange: handleNewClaimTypeStatusChange,
        t,
      }),
    [
      newClaimTypesStatusOptions,
      rowModesModel,
      handleRowSaveClick,
      handleDeleteRow,
      handleNewClaimTypeStatusChange,
      t,
    ]
  );

  useEffect(() => {
    setNewClaimTypesRows(newClaimTypes);
    setRowModesModel(computeGridRowModes(newClaimTypes));
  }, [newClaimTypes]);

  return (
    <Box>
      <Box>
        <CollapsibleHeader
          title={t("AdminConsole.ClaimTypes.claimTypes")}
          visibleRowsCount={contractTypeClaimTypes.length || 0}
          selectedCount={claimTypesSelectionModel?.length || 0}
          onExportToExcel={handleContractTypeClaimTypesExportToExcel}
          onToggleCollapse={() =>
            setClaimTypesGridVisibility((state) => !state)
          }
          collapsed={!claimTypesGridVisibility}
          withShadow={false}
          icon={
            <Coins size={22} weight="fill" color={theme.palette.primary.main} />
          }
        />
        <Collapse in={claimTypesGridVisibility}>
          <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
            <StyledDataGrid
              rows={contractTypeClaimTypes}
              columns={claimTypeColumns}
              getRowId={(rowData: ClaimType) => rowData.id}
              onRowSelectionModelChange={
                handleClaimTypesGridRowSelectionModelChange
              }
              loading={claimTypesLoading}
              sortingMode="client"
              sortModel={claimTypesSortingModel}
              onSortModelChange={setClaimTypesSortingModel}
              filterMode="client"
              filterModel={claimTypeFilterModel}
              onFilterModelChange={setClaimTypeFilterModel}
              // experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              hideFooter
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </Collapse>
      </Box>
      <Box mt={2}>
        <CollapsibleHeader
          title={t("AdminConsole.ClaimTypes.additionalClaimTypes")}
          visibleRowsCount={newClaimTypesRows.length || 0}
          selectedCount={newClaimTypesSelectionModel?.length || 0}
          onExportToExcel={handleContractNewClaimTypesExportToExcel}
          onToggleCollapse={() =>
            setNewClaimTypesGridVisibility((state) => !state)
          }
          collapsed={!newClaimTypesGridVisibility}
          withShadow={false}
          icon={
            <Coins size={22} weight="fill" color={theme.palette.primary.main} />
          }
        />
        <Collapse in={newClaimTypesGridVisibility}>
          <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
            <StyledDataGrid
              rows={newClaimTypesRows}
              columns={newClaimTypeColumns}
              getRowId={(rowData: NewClaimTypeOverride) => rowData.id}
              onRowSelectionModelChange={
                handleNewClaimTypesGridRowSelectionModelChange
              }
              loading={additionalClaimTypesLoading}
              sortingMode="client"
              sortModel={newClaimTypesSortingModel}
              onSortModelChange={setNewClaimTypesSortingModel}
              rowModesModel={rowModesModel}
              filterMode="client"
              filterModel={newClaimTypeFilterModel}
              onFilterModelChange={setNewClaimTypeFilterModel}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={handleRowChangesCommited}
              // experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              hideFooter
              checkboxSelection
              disableRowSelectionOnClick
            />
            <DataGridAddRecordButton
              onClick={handleAddTemporaryRecord}
              disabled={rowsContainTemporaryRecord(newClaimTypesRows)}
            />
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
