import { Attachment } from "generated/graphql";

export type FileType = {
  id: string;
  fileName: string;
  displayName: string;
  size: number;
  dateCreated: number;
  mimeType: string;
  description?: string;
  fileUrl?: string;
  loading?: boolean;
  data: string; // TODO: keep only one
  rawFile: File; // TODO: keep only one
};

export type EnhancedAttachment = Attachment & { loading?: boolean }; // TODO: see if really needed

export enum FileMIMEType {
  Doc,
  Xls,
  Pdf,
  Img,
  Video,
  Zip,
  Ppt,
  Unknown,
}

export enum AttachmentsDencity {
  Comfortable = "comfortable",
  Compact = "compact",
}

export type FileItemProps = {
  editable?: boolean;
  file: FileType | Attachment;
  onAddDescription?: (file: FileType | Attachment, description: string) => void;
  onDelete?: VoidFunction;
  onClick: VoidFunction;
};
