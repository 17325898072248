import { Box, css, styled, useTheme } from "@mui/material";
import { Editor } from "containers/Projects/components/Comments/Editor/Editor";
import React from "react";

export type RichTextAreaProps = {
  readOnly?: boolean;
  content?: string;
  children?: any;
  error?: boolean;
  color?: string;
  lineHeight?: React.CSSProperties["lineHeight"];
  fontSize?: string | number;
  placeholder?: string;
  dataTestId?: string;
  lineClamp?: number;
  className?: string;
  onChange?: (contentHTML: string) => void;
};

const TrimmedText = styled("div", {
  shouldForwardProp: (prop) => prop !== "lineClamp",
})<{ lineClamp: number }>(
  ({ lineClamp }) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${lineClamp};
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: ${lineClamp};
    white-space: break-spaces;
  `
);

export const RichTextArea: React.FC<RichTextAreaProps> = ({
  readOnly,
  error,
  color,
  content = "",
  children,
  fontSize,
  placeholder = "",
  lineHeight,
  dataTestId = "rich-textarea",
  lineClamp,
  className,
  onChange,
}) => {
  const theme = useTheme();
  const computedColor = color ?? theme.palette.grey[800];

  return readOnly ? (
    lineClamp ? (
      <TrimmedText
        dangerouslySetInnerHTML={{ __html: content ?? children }}
        data-testid={`${dataTestId}-readonly`}
        style={{ color: computedColor, fontSize, lineHeight }}
        lineClamp={lineClamp}
        className={className}
      ></TrimmedText>
    ) : (
      <div
        dangerouslySetInnerHTML={{ __html: content ?? children }}
        data-testid={`${dataTestId}-readonly`}
        style={{ color: computedColor, fontSize, lineHeight }}
        className={className}
      ></div>
    )
  ) : (
    <Box display="flex" className={className}>
      <Editor
        disabled={false}
        typeaheadOptions={[]}
        initialValue={content ?? children}
        hasError={error}
        dataTestId={dataTestId}
        onChange={onChange}
        placeholder={placeholder}
        toolbarFullWidth={false}
      />
    </Box>
  );
};
