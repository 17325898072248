import { IconButton, Stack, styled, Typography, useTheme } from "@mui/material";
import { ChatText, Paperclip } from "phosphor-react";
import { useTranslation } from "react-i18next";

export type HeaderLinksProps = {
  commentsCount?: number;
  hasMoreComments?: boolean;
  attachmentsCount?: number;
  onCommentsClick?: () => void;
  onAttachmentsClick?: () => void;
};

const HoverableText = styled(Typography)`
  &:hover {
    text-decoration: underline;
  }
`;

export const HeaderLinks: React.FC<HeaderLinksProps> = ({
  commentsCount,
  attachmentsCount,
  hasMoreComments,
  onCommentsClick,
  onAttachmentsClick,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!commentsCount && !attachmentsCount) return null;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {commentsCount && commentsCount > 0 ? (
        <IconButton onClick={onCommentsClick}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <ChatText size={16} color={theme.palette.info.main} />
            <HoverableText variant="p2" color={theme.palette.info.main}>
              {t("Comments.comment", {
                count: commentsCount,
                plus: hasMoreComments ? "+" : undefined,
              })}
            </HoverableText>
          </Stack>
        </IconButton>
      ) : null}
      {attachmentsCount && attachmentsCount > 0 ? (
        <IconButton onClick={onAttachmentsClick}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Paperclip size={16} color={theme.palette.info.main} />
            <HoverableText variant="p2" color={theme.palette.info.main}>
              {t("Attachments.attachment", { count: attachmentsCount })}
            </HoverableText>
          </Stack>
        </IconButton>
      ) : null}
    </Stack>
  );
};
