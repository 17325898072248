import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import { ProductType } from "generated/graphql";
import { useTranslation } from "react-i18next";
import {
  ContentContainer,
  ContentText,
  Heading,
} from "./ProductItemCard.styled";
import React from "react";

export type ProductItemCardProps = {
  itemName: string;
  productType: ProductType;
  projectName: string;
  contractName: string;
  adornment?: React.ReactNode;
  hideTitle?: boolean;
  orientation?: "column" | "row";
};

export const ProductItemCard: React.FC<ProductItemCardProps> = ({
  itemName,
  projectName,
  productType,
  contractName,
  adornment,
  hideTitle = false,
  orientation = "column",
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ContentContainer>
      {hideTitle ? null : (
        <Box display="flex" alignItems="center" mb={4}>
          <ItemTag type={productType} />
          <Typography
            variant="h3"
            fontWeight={600}
            ml={1}
            color={theme.palette.grey[900]}
            data-testid="item-reference"
          >
            {itemName}
          </Typography>
        </Box>
      )}
      {adornment}
      <Stack
        spacing={orientation === "column" ? 2 : 0}
        flexDirection={orientation}
        justifyContent={orientation === "row" ? "space-between" : ""}
        width="100%"
      >
        <Box display="flex" flexDirection="column" flex={1}>
          <Heading>{t("common.labels.project")}</Heading>
          <ContentText data-testid="project">{projectName}</ContentText>
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Heading>{t("common.labels.contract")}</Heading>
          <ContentText data-testid="contract">{contractName}</ContentText>
        </Box>
      </Stack>
    </ContentContainer>
  );
};
