import { Box } from "@mui/material";
import {
  AuthorizationWorkflowAuditStatus,
  AuthorizationWorkflowAuditTrail,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { LevelBadge } from "./LevelBadge";
import { LevelRuleBanner } from "./LevelRuleBanner";
import { AuthorizationLevelOverview } from "./AuthorizationLevelOverview";

export type AuthorizationLevelProps = {
  authLevel: AuthorizationWorkflowLevel;
  settledTrails: AuthorizationWorkflowAuditTrail[];
  levelStatus: AuthorizationWorkflowAuditStatus;
  authAuditStatus: AuthorizationWorkflowAuditStatus;
  contractTimezone: string;
};

export const AuthorizationLevel: React.FC<AuthorizationLevelProps> = ({
  authLevel,
  settledTrails,
  levelStatus,
  authAuditStatus,
  contractTimezone,
}) => {
  const authAuditSettled = [
    AuthorizationWorkflowAuditStatus.Authorized,
    AuthorizationWorkflowAuditStatus.Denied,
    AuthorizationWorkflowAuditStatus.Removed,
  ].includes(authAuditStatus);

  return (
    <Box>
      <LevelBadge
        status={
          authAuditSettled &&
          levelStatus === AuthorizationWorkflowAuditStatus.InProgress
            ? authAuditStatus
            : levelStatus
        }
        orderNumber={authLevel.sequence}
      />
      <Box mt={2} sx={{ background: "white" }}>
        <LevelRuleBanner mode={authLevel.mode} mb={1} />
        <AuthorizationLevelOverview
          levelStatus={levelStatus}
          authAuditStatus={authAuditStatus}
          authLevel={authLevel}
          settledTrails={settledTrails}
          contractTimezone={contractTimezone}
        />
      </Box>
    </Box>
  );
};
