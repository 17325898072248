import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const recordCompEventOwnAssessmentNoticeMutation = gql`
  ${compEventItemFields}

  mutation recordCompEventOwnAssessmentNotice(
    $input: RecordCompEventOwnAssessmentNoticeInput!
  ) {
    recordCompEventOwnAssessmentNotice(input: $input) {
      ...CompEventItemFields
    }
  }
`;
