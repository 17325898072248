import {
  AddDraftRiskItemMutation,
  AddDraftRiskItemMutationVariables,
  AddRiskItemMutation,
  AddRiskItemMutationVariables,
  Contract,
  DraftRiskItemQuery,
  DraftRiskItemQueryVariables,
  EditDraftRiskItemMutation,
  EditDraftRiskItemMutationVariables,
  ProductInstance,
  ProductType,
  Project,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import {
  addDraftRiskItemMutation,
  addRiskItemMutation,
  draftRiskItemQuery,
  editDraftRiskItemMutation,
} from "../NewRiskItem.query";
import { useIntercom } from "react-use-intercom";
import { IntercomEvents } from "constants/intercom";
import { getMetadataSet1 } from "containers/Projects/utils/intercom";
import { useParams } from "react-router-dom";

export const useNewRisk = (
  projectDataLite?: Project,
  contractDataLite?: Contract,
  productInstanceData?: ProductInstance,
  productInstanceDataLoading?: boolean
) => {
  const { t } = useTranslation();
  const { trackEvent } = useIntercom();
  const { productInstanceId } = useParams();

  const [
    fetchDraftRiskData,
    {
      data: draftRiskData,
      loading: fetchDraftRiskDataLoading,
      refetch: refetchDraftRiskData,
    },
  ] = useGraphLazyQuery<DraftRiskItemQuery, DraftRiskItemQueryVariables>(
    draftRiskItemQuery
  );

  const [addRiskItem, { loading: addRiskItemLoading }] = useGraphMutation<
    AddRiskItemMutation,
    AddRiskItemMutationVariables
  >(
    addRiskItemMutation,
    {
      update: (_cache, result) => {
        //   cache.evict({ id: "ROOT_QUERY", fieldName: "riskItems" });
        //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItems" });
        //   cache.gc();
        trackEvent(
          IntercomEvents.CreatedRisk,
          getMetadataSet1({
            productInstanceId: productInstanceId!,
            productInstanceItemId: result.data?.addRiskItem.id!,
            productType: ProductType.RisksRegister,
            contractFn: contractDataLite?.friendlyName ?? "",
            projectFn: projectDataLite?.friendlyName ?? "",
            productInstanceItemName: result.data?.addRiskItem.title ?? "",
            version: process.env.REACT_APP_VERSION!,
          })
        );
        // cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItems" });
        // cache.gc();
      },
    },
    t("common.successMessages.entityCreated", {
      entity: t("Projects.Risks.riskItem"),
    })
  );

  const [addRiskItemDraft, { loading: addRiskItemDraftLoading }] =
    useGraphMutation<
      AddDraftRiskItemMutation,
      AddDraftRiskItemMutationVariables
    >(
      addDraftRiskItemMutation,
      {
        // update: (cache) => {
        // cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItems" });
        // cache.gc();
        // },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.Risks.draftRiskItem"),
      })
    );

  const [editRiskItemDraft, { loading: editRiskItemDraftLoading }] =
    useGraphMutation<
      EditDraftRiskItemMutation,
      EditDraftRiskItemMutationVariables
    >(
      editDraftRiskItemMutation,
      {
        update: (_cache) => {
          refetchDraftRiskData();

          //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItems" });
          //   cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Risks.draftRiskItem"),
      })
    );

  return {
    draftRiskData,
    fetchDraftRiskData,
    fetchDraftRiskDataLoading,
    addRiskItem,
    addRiskItemLoading,
    addRiskItemDraft,
    addRiskItemDraftLoading,
    editRiskItemDraft,
    editRiskItemDraftLoading,
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
  };
};
