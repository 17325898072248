import { gql } from "@apollo/client";
import { compEventRecipientFields } from "graphql/fragments/compEventRecipient.fragment";

export const generateSendCompEventOwnAssessmentPreviewMutation = gql`
  ${compEventRecipientFields}

  mutation generateSendCompEventOwnAssessmentPreview(
    $input: SendCompEventOwnAssessmentPreviewInput!
  ) {
    generateSendCompEventOwnAssessmentPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...CompEventRecipientFields
      }
    }
  }
`;
