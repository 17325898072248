import { Liquid } from "liquidjs";
import { useCallback, useEffect, useState } from "react";
import * as DOMPurify from "dompurify";
import { Box } from "@mui/material";

export type ReactLiquidProps = {
  template: string;
  data: object;
  emptyPreviewText?: string;
};

const liquidEngine = new Liquid();

export const ReactLiquid: React.FC<ReactLiquidProps> = ({
  template,
  data,
  emptyPreviewText,
}) => {
  const [renderedTemplate, setRenderedTemplate] = useState<string>("");

  const computeRenderedTemplate = useCallback(
    async (template: string, data: object) => {
      const compiledRender = await liquidEngine.parseAndRender(template, data);
      // TODO: ADI B. domPurify does not allow importing CSS files though <link /> tag because it considers it potential malicious code.
      // Import common CSS file hosted in S3 in index.css file
      const purifiedHtml = DOMPurify.sanitize(compiledRender, {
        USE_PROFILES: { html: true },
      });
      setRenderedTemplate(purifiedHtml);
    },
    []
  );

  useEffect(() => {
    computeRenderedTemplate(template, data);
  }, [template, data, computeRenderedTemplate]);

  const renderTemplate = () => {
    return (
      <div
        key={new Date().getTime()}
        dangerouslySetInnerHTML={{ __html: renderedTemplate }}
      ></div>
    );
  };

  return renderedTemplate ? (
    renderTemplate()
  ) : (
    <Box display="flex" width="100%" justifyContent="center" mt={2}>
      {emptyPreviewText}
    </Box>
  );
};
