import { Box } from "@mui/material";
import { ListItemsDivider } from "components/ListItemsDivider";
import { EffectOption } from "generated/graphql";
import React from "react";
import { EffectOptionFieldEditable } from "./components/EffectOptionFieldEditable";
import { EffectOptionAnswer, EffectWidgetValue } from "./Effect.decl";

export type EffectEditableProps = {
  effectOptions: EffectOption[];
  widgetData: EffectWidgetValue;
  contractCurrency: string;
  onChange: (newEffectWidgetData: EffectWidgetValue) => void;
};

export const EffectEditable: React.FC<EffectEditableProps> = ({
  widgetData,
  effectOptions,
  contractCurrency,
  onChange,
}) => {
  const handleEffectAnswerFieldChange = (optionAnswer: EffectOptionAnswer) => {
    const dataWithoutUpdatedOption = widgetData.answers.filter(
      (option) => option.optionId !== optionAnswer.optionId
    );

    onChange({
      effectPresetId: widgetData.effectPresetId,
      answers: [...dataWithoutUpdatedOption, optionAnswer],
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      {effectOptions.map((effectOption, index) => (
        <React.Fragment key={effectOption.id}>
          <EffectOptionFieldEditable
            effectOption={effectOption}
            data={widgetData.answers.find(
              (answer) => answer.optionId === effectOption.id
            )}
            contractCurrency={contractCurrency}
            onChange={handleEffectAnswerFieldChange}
          />
          {index < effectOptions.length - 1 && <ListItemsDivider />}
        </React.Fragment>
      ))}
    </Box>
  );
};
