import {
  GenerateEarlyWarningPreviewMutation,
  GenerateEarlyWarningPreviewMutationVariables,
  GenerateInstructionPreviewMutation,
  GenerateInstructionPreviewMutationVariables,
  ProductType,
} from "generated/graphql";
import { generateInstructionPreviewMutation } from "graphql/mutations/generateInstructionPreview";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useMemo } from "react";
import { generateEarlyWarningPreviewMutation } from "../NewEarlyWarning.query";

export const useGenerateEWInstructionPreview = (productType: ProductType) => {
  const [generateEWPreview, { loading: generateEWPreviewLoading }] =
    useGraphMutation<
      GenerateEarlyWarningPreviewMutation,
      GenerateEarlyWarningPreviewMutationVariables
    >(generateEarlyWarningPreviewMutation, {}, null);

  const [
    generateInstructionPreview,
    { loading: generateInstructionPreviewLoading },
  ] = useGraphMutation<
    GenerateInstructionPreviewMutation,
    GenerateInstructionPreviewMutationVariables
  >(generateInstructionPreviewMutation, {}, null);

  const generatePreview = useMemo(
    () =>
      productType === ProductType.EarlyWarnings
        ? generateEWPreview
        : generateInstructionPreview,
    [productType, generateEWPreview, generateInstructionPreview]
  );

  const loading = useMemo(
    () =>
      productType === ProductType.EarlyWarnings
        ? generateEWPreviewLoading
        : generateInstructionPreviewLoading,
    [productType, generateEWPreviewLoading, generateInstructionPreviewLoading]
  );

  return { generatePreview, loading };
};
