import { gql } from "@apollo/client";

export const changeItemStatusOptionStatusMutation = gql`
  mutation changeItemStatusOptionStatus(
    $id: ID!
    $status: ItemStatusOptionStatus!
  ) {
    changeItemStatusOptionStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
