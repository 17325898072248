import { gql } from "@apollo/client";

export const userProductInstancesQuery = gql`
  query UserProductInstances($id: ID!) {
    user(id: $id) {
      id
      roles {
        items {
          productInstanceId
          productRoleId
          status
          productInstance {
            id
            soloModeSupported
            contractId
            numberingFormat
            productId
            statusCollectionId
            status
            product {
              id
              name
              numberingFormat
              soloModeSupported
              allowMultipleInstances
              status
            }
          }
          productRole {
            id
            name
            productId
            actionIds
            status
          }
        }
      }
    }
  }
`;
