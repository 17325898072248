import {
  Divider,
  MenuItem,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { BasicContextMenu } from "components/BasicContextMenu/BasicContextMenu";
import { BasicContextMenuPublicAPI } from "components/BasicContextMenu/BasicContextMenu.decl";
import {
  DotsThreeVertical,
  // Note,
  PencilSimpleLine,
  Trash,
} from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContextMenuTrigger,
  StyledMenuItemContent,
} from "./ThreeDotsMenu.styled";

export type ThreeDotsMenuProps = {
  onEdit: () => void;
  onSendNotes: () => void;
  onRemove: () => void;
};

const OptionLabel: React.FC<TypographyProps> = ({ children, ...restProps }) => (
  <Typography variant="p2" ml={1} color="grey.800" {...restProps}>
    {children}
  </Typography>
);

export const ThreeDotsMenu: React.FC<ThreeDotsMenuProps> = ({
  onEdit,
  // onSendNotes,
  onRemove,
}) => {
  const theme = useTheme();
  const apiRef = useRef<BasicContextMenuPublicAPI>(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const iconStyle = {
    size: 18,
    color: theme.palette.grey[800],
  };

  return (
    <BasicContextMenu
      apiRef={apiRef}
      onDropdownVisibilityChange={setOpen}
      triggerIcon={
        <ContextMenuTrigger active={open}>
          <DotsThreeVertical size={18} />
        </ContextMenuTrigger>
      }
      wrapperProps={{ marginY: -1 }} // Note: used for DailyDiary records to not exceed 36px height
      menuItems={[
        <MenuItem
          key="edit"
          data-testid="edit-option"
          onClick={() => {
            apiRef.current?.close();
            onEdit();
          }}
          sx={{ height: "36px" }}
        >
          <StyledMenuItemContent>
            <PencilSimpleLine {...iconStyle} />
            <OptionLabel>{t("common.buttons.edit")}</OptionLabel>
          </StyledMenuItemContent>
        </MenuItem>,
        // <MenuItem
        //   key="send-notes"
        //   data-testid="send-notes-option"
        //   onClick={() => {
        //     apiRef.current?.close();
        //     onSendNotes();
        //   }}
        //   sx={{ height: "36px" }}
        // >
        //   <StyledMenuItemContent>
        //     <Note {...iconStyle} />
        //     <OptionLabel>{t("Projects.DailyDiaries.sendNotes")}</OptionLabel>
        //   </StyledMenuItemContent>
        // </MenuItem>,
        <Divider key="divider-1" sx={{ margin: "0px !important" }} />,
        <MenuItem
          key="remove"
          data-testid="remove-option"
          onClick={() => {
            apiRef.current?.close();
            onRemove();
          }}
          sx={{ height: "36px" }}
        >
          <StyledMenuItemContent>
            <Trash color={theme.palette.error.main} size={18} />
            <OptionLabel color="error.main">
              {t("common.buttons.remove")}
            </OptionLabel>
          </StyledMenuItemContent>
        </MenuItem>,
      ]}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    />
  );
};
