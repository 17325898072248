import { gql } from "@apollo/client";
import { timePeriodOverrideFields } from "graphql/fragments/timePeriodOverride.fragment";

export const addTimePeriodOverrideMutation = gql`
  ${timePeriodOverrideFields}

  mutation addTimePeriodOverride($input: AddTimePeriodOverrideInput!) {
    addTimePeriodOverride(input: $input) {
      ...TimePeriodOverrideFields
    }
  }
`;
