import { gql } from "@apollo/client";

export const addItemStatusOptionMutation = gql`
  mutation addItemStatusOption($input: AddItemStatusOptionInput!) {
    addItemStatusOption(input: $input) {
      id
      displayOrder
      description
      equatesToRemoved
      style
      statusCollectionId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;

export const editItemStatusOptionMutation = gql`
  mutation editItemStatusOption($input: EditItemStatusOptionInput!) {
    editItemStatusOption(input: $input) {
      id
      displayOrder
      description
      equatesToRemoved
      style
      statusCollectionId
      status
      creatorId
      creator {
        id
        firstname
        surname
      }
      dateCreated
    }
  }
`;
