import { Box, Button, css, styled } from "@mui/material";
import { PlusIcon } from "components/Icons/PlusIcon";
import { ProductType } from "generated/graphql";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { PermissionsGate } from "helpers/Permissions/PermissionsGate";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  TabsWithCount,
  TabsWithCountProps,
} from "../../TabsWithCount/TabsWithCount";
import { TableHeaderSelectView } from "../../../../../components/TableHeaderSelectView";

export type ProductItemsHeaderProps = {
  selectedItemsCount?: number;
  productType?: ProductType;
  selectedTabId?: string;
  onClearSelection?: () => void;
  onExportToExcel?: () => void;
  onAddProductItem?: () => void;
} & Omit<TabsWithCountProps, "selectedTabId">;

export const RoundedChip = styled(Box)(
  ({ theme }) => css`
    width: 22px;
    height: 22px;
    color: white;
    background-color: ${theme.palette.grey[900]};
    font-size: ${theme.typography.caption3.fontSize};
    font-weight: 700;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

export const ProductItemsHeader: React.FC<ProductItemsHeaderProps> = ({
  tabs,
  selectedItemsCount,
  productType,
  selectedTabId: controlledSelectedTabId,
  onAddProductItem,
  onClearSelection,
  onExportToExcel,
  onSelectedTabChange,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const productInstanceId = searchParams.get("productInstanceId");

  const [selectedTabId, setSelectedTabId] = useState<string | undefined>(
    controlledSelectedTabId
  );

  const newProductItemBtnLabel = useMemo(() => {
    switch (productType) {
      case ProductType.RisksRegister:
        return t("Projects.Risks.newRisk");
      case ProductType.EarlyWarnings:
        return t("Projects.EarlyWarnings.newEarlyWarning");
      case ProductType.Events:
        return t("Projects.Events.newEvent");
      case ProductType.Instructions:
        return t("Projects.Instructions.newInstruction");
      default:
        return t("common.labels.new");
    }
  }, [productType, t]);

  const handleSelectedTabChange = (newSelectedTabId: string) => {
    setSelectedTabId(newSelectedTabId);
    onSelectedTabChange(newSelectedTabId);
  };

  useEffect(() => {
    setSelectedTabId(controlledSelectedTabId);
  }, [controlledSelectedTabId]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...restProps}
    >
      {selectedItemsCount ? (
        <TableHeaderSelectView
          onClearSelection={onClearSelection}
          onExportToExcel={onExportToExcel}
          selectedItemsCount={selectedItemsCount}
        />
      ) : (
        <>
          {tabs && tabs.length > 0 && (
            <TabsWithCount
              tabs={tabs}
              selectedTabId={selectedTabId ?? tabs[0].id}
              onSelectedTabChange={handleSelectedTabChange}
            />
          )}
          {onAddProductItem && (
            <PermissionsGate
              productInstanceId={productInstanceId!}
              requiredPermissions={[PermissionEnum.Add]}
            >
              <Button
                data-testid="new-product-item-btn"
                variant="contained"
                size="large"
                onClick={onAddProductItem}
              >
                <Box mr={1} display="flex" alignItems="center">
                  <PlusIcon />
                </Box>
                {newProductItemBtnLabel}
              </Button>
            </PermissionsGate>
          )}
        </>
      )}
    </Box>
  );
};
