import { Box, Dialog, DialogProps, Typography } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { XIcon } from "components/Icons/XIcon";
import { ItemTag } from "components/ItemTag";
import { Contract, ProductType } from "generated/graphql";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractPriceTimeChangeDetails } from "./ContractPriceTimeChangeDetails";
import {
  ContractPriceTimeChangeList,
  ContractPriceTimeChangeListProps,
} from "./ContractPriceTimeChangeList";

export type ContractPriceTimeChangesExplorerModalProps = {
  contract: Contract;
  loading?: boolean;
  regardingItemNumber: string;
  isFIDIC17White?: boolean;
  isFIDIC99RedYellow?: boolean;
} & Omit<DialogProps, "title"> &
  Pick<ContractPriceTimeChangeListProps, "items" | "productType">;

export const ContractPriceTimeChangesExplorerModal: React.FC<
  ContractPriceTimeChangesExplorerModalProps
> = ({
  onClose,
  items,
  contract,
  loading,
  productType,
  regardingItemNumber,
  isFIDIC17White,
  isFIDIC99RedYellow,
  ...restProps
}) => {
  const { t } = useTranslation();

  const orderedItems = useMemo(
    () =>
      items
        .slice()
        .sort(
          (item1, item2) =>
            new Date(item2.dateCreated).getTime() -
            new Date(item1.dateCreated).getTime()
        ),
    [items]
  );
  const [selectedItemId, setSelectedItemId] = useState<string>(items[0].id);

  const selectedItem = useMemo(
    () => (items as any).find((item: any) => item.id === selectedItemId)!,
    [items, selectedItemId]
  );

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="lg" {...restProps}>
      <Box
        sx={{ height: "calc(100vh - 64px)" }} // TODO: why this calc
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Box
          py={4}
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <ItemTag type={productType} />
            <Typography variant="h3" color="grey.800" fontWeight={400} ml={1.5}>
              {regardingItemNumber}
            </Typography>
            <Typography variant="h3" color="grey.800" fontWeight={600} ml={1}>
              {productType === ProductType.CompEvents
                ? t("Projects.CompEvents.quotationsAndAssessments")
                : productType === ProductType.Claims
                ? t(
                    `Projects.Claims.explorerItems${
                      isFIDIC99RedYellow ? "99RY" : ""
                    }`
                  )
                : t("Projects.Variations.explorerItems")}
            </Typography>
          </Box>
          <Box display="flex" sx={{ cursor: "pointer" }}>
            <XIcon
              onClick={() => {
                onClose?.({}, "backdropClick");
              }}
            />
          </Box>
        </Box>
        <Box
          p={3}
          pt={0}
          display="flex"
          height="100%"
          flex={1}
          overflow="hidden"
        >
          {loading ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <Box sx={{ overflowY: "auto" }} maxHeight="100%" mr={2}>
                <ContractPriceTimeChangeList
                  contract={contract}
                  productType={productType}
                  items={orderedItems}
                  selectedItemId={selectedItemId}
                  onSelectedItemChange={setSelectedItemId}
                />
              </Box>
              <Box flex={1} overflow="auto">
                <ContractPriceTimeChangeDetails
                  item={selectedItem}
                  contract={contract}
                  productType={productType}
                  isFIDIC17White={isFIDIC17White}
                  isFIDIC99RedYellow={isFIDIC99RedYellow}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
