import {
  AddAuthorizationActionInput,
  AddAuthorizationWorkflowActionMappingMutation,
  AddAuthorizationWorkflowActionMappingMutationVariables,
  AuthorizationWorkflow,
  AuthorizationWorkflowActionMapping,
  AuthorizationWorkflowActionMappingsQuery,
  AuthorizationWorkflowActionMappingsQueryVariables,
  AuthorizationWorkflowStatus,
  EditAuthorizationActionInput,
  EditAuthorizationWorkflowActionMappingMutation,
  EditAuthorizationWorkflowActionMappingMutationVariables,
  ProductOutputActionOriginatingParty,
  RemoveAuthorizationWorkflowActionMappingMutation,
  RemoveAuthorizationWorkflowActionMappingMutationVariables,
  WorkflowActionsPromptQuery,
  WorkflowActionsPromptQueryVariables,
} from "generated/graphql";
import { addAuthorizationWorkflowActionMappingMutation } from "graphql/mutations/addAuthorizationWorkflowActionMapping";
import { editAuthorizationWorkflowActionMappingMutation } from "graphql/mutations/editAuthorizationWorkflowActionMapping";
import { removeAuthorizationWorkflowActionMappingMutation } from "graphql/mutations/removeAuthorizationWorkflowActionMapping";
import { authorizationWorkflowActionMappingsQuery } from "graphql/queries/authorizationWorkflowActionMappings.query";
import { workflowActionsPromptQuery } from "graphql/queries/workflowActionsPrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useProductInstanceWorkflowActionMappings = (
  productInstanceId: string,
  projectId: string,
  skip?: boolean
) => {
  const { t } = useTranslation();

  const { data: workflowActionsPrompt, loading: workflowActionsPromptLoading } =
    useGraphQuery<
      WorkflowActionsPromptQuery,
      WorkflowActionsPromptQueryVariables
    >(workflowActionsPromptQuery, {
      variables: {
        input: {
          projectId,
          productInstanceId,
        },
      },
      skip,
    });

  const {
    data: authWorkflowActionMappings,
    refetch: refetchWorkflowActionMappings,
    loading: authWorkflowActionMappingsLoading,
  } = useGraphQuery<
    AuthorizationWorkflowActionMappingsQuery,
    AuthorizationWorkflowActionMappingsQueryVariables
  >(authorizationWorkflowActionMappingsQuery, {
    variables: { productInstanceId },
  });

  const [
    addAuthorizationWorkflowActionMapping,
    { loading: addAuthorizationWorkflowActionMappingLoading },
  ] = useGraphMutation<
    AddAuthorizationWorkflowActionMappingMutation,
    AddAuthorizationWorkflowActionMappingMutationVariables
  >(
    addAuthorizationWorkflowActionMappingMutation,
    {
      update: () => refetchWorkflowActionMappings(),
    },
    t("common.successMessages.entityCreated", {
      entity: t(
        "AdminConsole.AuthorizationWorkflows.authorizationWorkflowOutputActionMapping"
      ),
    })
  );

  const [
    editAuthorizationWorkflowActionMapping,
    { loading: editAuthorizationWorkflowActionMappingLoading },
  ] = useGraphMutation<
    EditAuthorizationWorkflowActionMappingMutation,
    EditAuthorizationWorkflowActionMappingMutationVariables
  >(
    editAuthorizationWorkflowActionMappingMutation,
    {
      update: () => refetchWorkflowActionMappings(),
    },
    t("common.successMessages.entityUpdated", {
      entity: t(
        "AdminConsole.AuthorizationWorkflows.authorizationWorkflowOutputActionMapping"
      ),
    })
  );

  const [
    removeAuthorizationWorkflowActionMapping,
    { loading: removeAuthorizationWorkflowActionMappingLoading },
  ] = useGraphMutation<
    RemoveAuthorizationWorkflowActionMappingMutation,
    RemoveAuthorizationWorkflowActionMappingMutationVariables
  >(
    removeAuthorizationWorkflowActionMappingMutation,
    {
      update: () => refetchWorkflowActionMappings(),
    },
    t("common.successMessages.entityDeleted", {
      entity: t(
        "AdminConsole.AuthorizationWorkflows.authorizationWorkflowOutputActionMapping"
      ),
    })
  );

  const handleAddAuthorizationWorkflowActionMapping = async (
    input: AddAuthorizationActionInput
  ) => {
    const { data } = await addAuthorizationWorkflowActionMapping({
      variables: {
        input,
      },
    });

    return !!data;
  };

  const handleEditAuthorizationWorkflowActionMapping = async (
    input: EditAuthorizationActionInput
  ) => {
    const { data } = await editAuthorizationWorkflowActionMapping({
      variables: {
        input,
      },
    });

    return !!data;
  };
  const handleRemoveAuthorizationWorkflowActionMapping = async (id: string) => {
    const { data } = await removeAuthorizationWorkflowActionMapping({
      variables: {
        id,
      },
    });

    return !!data;
  };

  const outputActions = useMemo(
    () =>
      (workflowActionsPrompt?.workflowActionsPrompt?.outputActions.items ??
        []) as ProductOutputActionOriginatingParty[],
    [workflowActionsPrompt]
  );

  const activeWorkflows = useMemo(
    () =>
      (
        workflowActionsPrompt?.workflowActionsPrompt?.workflows?.items ?? []
      ).filter(
        (workflow) => workflow.status === AuthorizationWorkflowStatus.Active
      ) as AuthorizationWorkflow[],
    [workflowActionsPrompt]
  );

  return {
    workflows: activeWorkflows,
    outputActions,
    authWorkflowActionMappings: (authWorkflowActionMappings
      ?.authorizationWorkflowActionMappings?.items ??
      []) as AuthorizationWorkflowActionMapping[],
    addAuthorizationWorkflowActionMapping:
      handleAddAuthorizationWorkflowActionMapping,
    editAuthorizationWorkflowActionMapping:
      handleEditAuthorizationWorkflowActionMapping,
    removeAuthorizationWorkflowActionMapping:
      handleRemoveAuthorizationWorkflowActionMapping,
    loading:
      authWorkflowActionMappingsLoading ||
      workflowActionsPromptLoading ||
      addAuthorizationWorkflowActionMappingLoading ||
      editAuthorizationWorkflowActionMappingLoading ||
      removeAuthorizationWorkflowActionMappingLoading,
  };
};
