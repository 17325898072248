import { Stack } from "@mui/material";
import {
  AuthorizationWorkflowAuditTrail,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { ReviewerListItem } from "./ReviewerListItem";
import { ReviewersLevelHeader } from "./ReviewersLevelHeader";

type ReviewersLevelProps = {
  level: AuthorizationWorkflowLevel;
  levelNo: number;
  auditTrails: AuthorizationWorkflowAuditTrail[];
};

export const ReviewersLevel: React.FC<ReviewersLevelProps> = ({
  level,
  levelNo,
  auditTrails,
}) => {
  return (
    <Stack spacing={1} position="relative">
      <ReviewersLevelHeader levelNo={levelNo} authMode={level.mode} />
      {level.users.items.map((reviewer, index) => {
        const auditTrail = auditTrails.find(
          (trail) => trail.creatorId === reviewer.id
        );

        return (
          <ReviewerListItem
            key={reviewer.id}
            reviewer={reviewer}
            auditTrail={auditTrail}
            pt={index === 0 ? 1 : 0}
          />
        );
      })}
    </Stack>
  );
};
