import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export type NotifyCEClaimSummaryProps = {
  claimType: string;
  ewTitle?: string;
  isFIDIC99RedYellow?: boolean;
  contextType: "claim" | "compEvent";
};

export const NotifyCEClaimSummary: React.FC<NotifyCEClaimSummaryProps> = memo(
  ({ claimType, ewTitle, contextType, isFIDIC99RedYellow }) => {
    const { t } = useTranslation();

    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="p2" color="grey.800" fontWeight={600}>
          {t(
            contextType === "compEvent" || isFIDIC99RedYellow
              ? "Projects.CompEvents.ActionModal.ewGiven"
              : "Projects.Claims.ActionModal.awGiven"
          )}
        </Typography>
        <Typography variant="p1" color="grey.600" mt={0.5}>
          {ewTitle
            ? `${t("common.labels.yes")} - ${ewTitle}`
            : t("common.labels.no")}
        </Typography>
        <Box mt={2} display="flex" flexDirection="column">
          <Typography variant="p2" color="grey.800" fontWeight={600}>
            {t(
              contextType === "compEvent"
                ? "Projects.CompEvents.ActionModal.compEventType"
                : "Projects.Claims.ActionModal.claimType"
            )}
          </Typography>
          <Typography variant="p1" color="grey.600" mt={0.5}>
            {claimType}
          </Typography>
        </Box>
      </Box>
    );
  }
);
