import { ContractSection } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";
import { VariationProposalAgreementDeterminationFormDataType } from "../VariationProposalAgreementDeterminationModal";

export const getDefaultData = (
  variationId: string,
  sections: ContractSection[]
) => {
  const defaultFormData: VariationProposalAgreementDeterminationFormDataType = {
    attachments: [],
    variationId,
    details: "",
    price: 0,
    sectionalChanges: [],
    time: 0,
  };

  defaultFormData.sectionalChanges = sections.map((section) => ({
    id: section.id,
    days: 0,
  }));

  return defaultFormData;
};

export const dataValidators: DataValidators = {
  details: {
    validators: [ValidatorType.RequiredRichText],
  },
  price: {
    validators: [ValidatorType.Required],
  },
  time: {
    validators: [ValidatorType.Required],
  },
  sectionalChanges: {
    validators: [ValidatorType.Required],
  },
};
