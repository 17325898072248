import { gql } from "@apollo/client";
import { contractKeyDateFields } from "./contractKeyDate.fragment";
import { contractSectionFields } from "./contractSection.fragment";
import { creatorFields } from "./creator.fragment";

export const contractLiteFields = gql`
  ${contractSectionFields}
  ${contractKeyDateFields}
  ${creatorFields}

  fragment ContractLiteFields on Contract {
    id
    name
    friendlyName
    number
    valueCurrency
    value
    startDate
    endDate
    contractTypeId
    projectId
    country
    province
    timeZone
    coordinatesLatitude
    coordinatesLongitude
    sections {
      ...ContractSectionFields
    }
    keyDates {
      ...ContractKeyDateFields
    }
    status
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
    #activeBindings: ContractBindingList!
    #contractBindings: ContractBindingList!
    #productInstances: ProductInstanceList!
    project {
      id
      name
      friendlyName
      status
    }
    contractType {
      id
      description
      subType
      version
      status
    }
    #templateRoutesOverride: DocumentTemplateRouteList!
  }
`;
