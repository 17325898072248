import { IntercomEvents } from "constants/intercom";
import { getMetadataSet1 } from "containers/Projects/utils/intercom";
import { InstructionItem, ProductType } from "generated/graphql";
import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";

export const useInstructionDetailsTrackingEvents = () => {
  const { trackEvent } = useIntercom();

  const trackInstructionDetailsEvent = useCallback(
    (
      trackingEvent: IntercomEvents,
      instruction: InstructionItem,
      extraData?: object
    ) => {
      trackEvent(trackingEvent, {
        ...getMetadataSet1({
          productInstanceId: instruction.productInstance.id,
          productInstanceItemId: instruction.id,
          productInstanceItemName: instruction.title,
          contractFn: instruction.productInstance.contract.friendlyName,
          projectFn: instruction.productInstance.contract.project.friendlyName,
          productType: ProductType.Instructions,
          version: process.env.REACT_APP_VERSION!,
        }),
        ...extraData,
      });
    },
    [trackEvent]
  );

  return {
    trackInstructionDetailsEvent,
  };
};
