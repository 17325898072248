import { useTheme } from "@mui/material";
import React from "react";
import { BaseIconProps } from "./decl";

export const WarningIcon: React.FC<BaseIconProps> = ({
  height = "41",
  width = "41",
  color,
}) => {
  const theme = useTheme();
  const errorComputedColor = color ?? theme.palette.error.main;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40.0048"
        height="40.0048"
        rx="8"
        fill={theme.palette.error.light}
      />
      <path
        d="M20.0039 17.752V21.5027"
        stroke={errorComputedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7066 11.7506L10.4549 26.0035C10.3235 26.2312 10.2541 26.4894 10.2539 26.7523C10.2537 27.0152 10.3225 27.2736 10.4536 27.5015C10.5846 27.7294 10.7733 27.9189 11.0006 28.051C11.228 28.183 11.486 28.253 11.7489 28.254H28.2522C28.5152 28.253 28.7732 28.183 29.0005 28.051C29.2279 27.9189 29.4165 27.7294 29.5476 27.5015C29.6786 27.2736 29.7475 27.0152 29.7473 26.7523C29.747 26.4894 29.6777 26.2312 29.5463 26.0035L21.2946 11.7506C21.1641 11.5227 20.9757 11.3332 20.7485 11.2014C20.5213 11.0696 20.2633 11.0002 20.0006 11.0002C19.7379 11.0002 19.4799 11.0696 19.2527 11.2014C19.0254 11.3332 18.837 11.5227 18.7066 11.7506V11.7506Z"
        stroke={errorComputedColor}
        strokeWidth="1.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0825 24.8784C21.0825 25.474 20.5997 25.9567 20.0041 25.9567C19.4086 25.9567 18.9258 25.474 18.9258 24.8784C18.9258 24.2828 19.4086 23.8001 20.0041 23.8001C20.5997 23.8001 21.0825 24.2828 21.0825 24.8784Z"
        fill={errorComputedColor}
        stroke={errorComputedColor}
        strokeWidth="0.0937689"
      />
    </svg>
  );
};
