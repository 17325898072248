import { gql } from "@apollo/client";

export const claimItemLiteFields = gql`
  fragment ClaimItemLiteFields on ClaimItem {
    id
    number
    productInstanceId
    regardingId
    regardingType
    status
    priceSought
    priceGranted
    timeSought
    timeGranted
    advanceWarningId
    dateCreated
    dateModified
    creatorId
    notifiedByPartyId
  }
`;
