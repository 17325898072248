import { create } from "zustand";

type GlobalState = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const useGlobalStore = create<GlobalState>()((set) => ({
  loading: false,
  setLoading: (loading?: boolean) => set(() => ({ loading })),
}));
