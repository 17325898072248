import { Box, Typography } from "@mui/material";
import { ClockIcon } from "components/Icons/ClockIcon";
import { EffectEstimationType } from "generated/graphql";
import getSymbolFromCurrency from "currency-symbol-map";
import { timeRelatedUnits } from "../constants";

export type UnitValueReadOnlyProps = {
  unit?: string;
  value: string;
  currency?: string; // EUR, USD, RON, etc etc.
  estimationType: EffectEstimationType.Decimal | EffectEstimationType.Monetary;
};

export const UnitValueReadOnly: React.FC<UnitValueReadOnlyProps> = ({
  unit,
  value,
  currency,
  estimationType,
}) => {
  const renderMonetaryValue = () => {
    return (
      <>
        {currency && (
          <Typography variant="p1" color="grey.800">
            {getSymbolFromCurrency(currency) ?? currency}
          </Typography>
        )}
        <Typography variant="p1" color="grey.800" ml={1}>
          {Number(value).toLocaleString("en-US")}
        </Typography>
      </>
    );
  };

  const renderUnitValue = () => {
    return (
      <>
        {unit && timeRelatedUnits.indexOf(unit?.toLowerCase()) >= 0 && (
          <ClockIcon />
        )}
        <Typography
          variant="p1"
          color="grey.800"
          ml={1}
        >{`${value} ${unit}`}</Typography>
      </>
    );
  };

  return (
    <Box display="flex" alignItems="center">
      {estimationType === EffectEstimationType.Monetary
        ? renderMonetaryValue()
        : renderUnitValue()}
    </Box>
  );
};
