import { gql } from "@apollo/client";
import { dailyDiaryGeneralRecordLiteFields } from "graphql/fragments/dailyDiaryGeneralRecordLite.fragment";

export const addDailyDiaryGeneralRecordMutation = gql`
  ${dailyDiaryGeneralRecordLiteFields}

  mutation addDailyDiaryGeneralRecord(
    $input: AddDailyDiaryGeneralRecordInput!
  ) {
    addDailyDiaryGeneralRecord(input: $input) {
      ...DailyDiaryGeneralRecordLiteFields
    }
  }
`;
