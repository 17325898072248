import { useContext } from "react";
import { ExplorerContext } from "../components/Explorer/Explorer.context";
import { NewAppPaths } from "helpers/paths/paths";
import { useNavigate } from "react-router-dom";

export const useNavigateToRegisterPage = () => {
  const navigate = useNavigate();
  const { projectId, contractId, productId, productInstanceId } =
    useContext(ExplorerContext);

  const handleNavigateToRegisterPage = () => {
    if (projectId && contractId && productId && productInstanceId) {
      navigate({
        pathname: NewAppPaths.authorized.Projects.path,
        search: `projectId=${projectId}&contractId=${contractId}&productId=${productId}&productInstanceId=${productInstanceId}`,
      });
    } else {
      console.warn(
        "useNavigateToRegisterPage() hook should not be called before `projectId` && `contractId` && `productId` && `productInstanceId` are initialised"
      );
    }
  };

  return handleNavigateToRegisterPage;
};
