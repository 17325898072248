import {
  DailyDiaryWorkRecord,
  EditDailyDiaryWorkRecordInput,
} from "generated/graphql";

export const workRecordToEditInput = (
  record: DailyDiaryWorkRecord
): EditDailyDiaryWorkRecordInput => {
  const {
    __typename: _,
    creator: __,
    creatorId: ___,
    dateCreated: ____,
    dateModified: _____,
    attachments: ______,
    areaLookupId,
    teamLookupId,
    shiftLookupId,
    activityLookupId,
    workTypeLookupId,
    disciplineLookupId,
    unitOfMeasurementLookupId,
    ...rest
  } = record;

  return {
    ...rest,
    attachments: [], // TODO
  };
};
