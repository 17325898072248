import { Newspaper } from "phosphor-react";
import { BasicModal, BasicModalProps } from "../BasicModal/BasicModal";
import { useTranslation } from "react-i18next";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    maxWidth: "200px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    maxWidth: "200px",
  },
}));

const StyledSectionListItem = styled("li")`
  &::marker {
    font-size: 1.5rem;
  }
`;

type TableRowData = {
  id: number;
  purpose: string;
  type: string;
  lawfulBasis: string;
};

const tableRows: TableRowData[] = [
  {
    id: 1,
    purpose:
      "To engage with you after you have contacted us via the Platform or otherwise",
    type: `(a)&nbsp;&nbsp;Identity
    <br />
    (b)&nbsp;&nbsp;Contact
    <br />
    (c)&nbsp;&nbsp;Technical
    <br />
    (d)&nbsp;&nbsp;Usage
    <br />
    (e)&nbsp;&nbsp;Marketing and Communications <br />
    (f)&nbsp;&nbsp;Support`,
    lawfulBasis: `(a)&nbsp;&nbsp;Express consent
    <br />
    (b)&nbsp;&nbsp;Performance of a contract with you
    <br />
    (c)&nbsp;&nbsp;Necessary for our
    legitimate interests (keep records updated, study how
    users use our services, develop services, grow)`,
  },
  {
    id: 2,
    purpose:
      "To allow you to use and integrate our Services into your business and to provide you with our Services as contracted",
    type: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Identity
    <br />
    (b)&nbsp;&nbsp;&nbsp;Contact
    <br />
    (c)&nbsp;&nbsp;&nbsp;Contract
    <br />
    (d)&nbsp;&nbsp;&nbsp;Financial
    <br />
    (e)&nbsp;&nbsp;&nbsp;Usage
    <br />
    (f)&nbsp;&nbsp;&nbsp;&nbsp;Transaction
    <br />
    (g) &nbsp;&nbsp;Support`,
    lawfulBasis: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Performance of a contract
    with you
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Express consent
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Necessary to comply with a
    legal obligation
    <br />
    (d) &nbsp;&nbsp;&nbsp;Necessary for our legitimate
    interests (provide you with Services you contracted to
    acquire, keep records updated, study how users use
    Services)`,
  },
  {
    id: 3,
    purpose: "To contract with you as a service provider to CCSSA",
    type: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Identity
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Contact
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Financial
    <br />
    (d)&nbsp;&nbsp;&nbsp;&nbsp;Transaction`,
    lawfulBasis: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Performance of a contract
    with you
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Express consent
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Necessary to comply with a
    legal obligation
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Necessary for our
    legitimate interests (provide us with your services,
    keep our records updated, and study how we may use
    third-party services)`,
  },
  {
    id: 4,
    purpose:
      "To provide it to our authorised service providers who need your personal information to provide their services to you",
    type: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Identity
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Contact
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Contract
    <br />
    (d)&nbsp;&nbsp;&nbsp;&nbsp;Financial
    <br />
    (d)&nbsp;&nbsp;&nbsp;&nbsp;Transaction&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <br />
    (f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Support`,
    lawfulBasis: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Performance of a contract
    with you
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Necessary for our
    legitimate interests (provide you with Services you
    contracted from the third-party, develop our services,
    and grow)
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Express consent`,
  },
  {
    id: 5,
    purpose: `To process and service &nbsp;your payment for any
    services rendered by CCSSA or its service providers.
    <br />
    <br />
    To manage payments, fees, &nbsp;and charges`,
    type: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Identity
    <br />
    (b)&nbsp;&nbsp;&nbsp; Contact
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Financial
    <br />
    (d)&nbsp;&nbsp;&nbsp; Transaction`,
    lawfulBasis: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Performance of a contract
    with you
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Necessary for our
    legitimate interests (make or receive necessary
    payments)
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Express consent`,
  },
  {
    id: 6,
    purpose: `To manage our &nbsp;relationship with you which may
    include notifying you about changes to our
    &nbsp;Terms, Privacy Policy, or Services`,
    type: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Identity
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Contact
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Marketing and
    Communications
    <br />
    (d)&nbsp;&nbsp;&nbsp;&nbsp;Support`,
    lawfulBasis: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Performance of a contract
    with you
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Necessary to comply with a
    legal obligation
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Necessary for our
    legitimate interests (keep our records updated and
    study how users use our Services)
    <br />
    (d)&nbsp;&nbsp;&nbsp;&nbsp;Express consent`,
  },
  {
    id: 7,
    purpose:
      "To administer and protect our company, Platform and services (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)",
    type: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Identity
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Contact
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Technical
    <br />
    (d)&nbsp;&nbsp;&nbsp;&nbsp;Usage
    <br />
    (e)&nbsp;&nbsp;&nbsp;Support`,
    lawfulBasis: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Necessary for our
    legitimate interests (running our organisation,
    provision of administration and IT, network security,
    to prevent fraud and organisation restructuring
    exercise)
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Necessary to comply with a
    legal obligation
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Express consent`,
  },
  {
    id: 8,
    purpose:
      "To use data analytics to improve our Platform, Services, and client relationships and experiences",
    type: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Technical
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Usage
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Identity
    <br />
    (d)&nbsp;&nbsp;&nbsp;&nbsp;Contract
    <br />
    (e)&nbsp;&nbsp;&nbsp;&nbsp;Marketing and
    Communications
    <br />
    (f)&nbsp;&nbsp;&nbsp;&nbsp;Support`,
    lawfulBasis: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Necessary for our
    legitimate interests (define types of users, keep
    Platform updated, develop our organisation and inform
    our marketing strategy)
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Express consent`,
  },
  {
    id: 9,
    purpose:
      "To provide you with direct marketing, make suggestions about services that may be of interest",
    type: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Identity
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Contact
    <br />
    (c)&nbsp;&nbsp;&nbsp;&nbsp;Technical
    <br />
    (d)&nbsp;&nbsp;&nbsp;&nbsp;Usage
    <br />
    (e)&nbsp;&nbsp;&nbsp;&nbsp;Marketing and
    Communications
    <br />
    (f)&nbsp;&nbsp;&nbsp;Support`,
    lawfulBasis: `(a)&nbsp;&nbsp;&nbsp;&nbsp;Necessary for our
    legitimate interests (develop our services and grow)
    <br />
    (b)&nbsp;&nbsp;&nbsp;&nbsp;Express consent`,
  },
];

export const PrivacyPolicyModal: React.FC<BasicModalProps> = (modalProps) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      headerIcon={<Newspaper size={24} />}
      {...modalProps}
      title={t("PrivacyPolicy.privacyPolicy")}
      titleNextToIcon
      maxWidth="md"
      onClose={modalProps.onClose}
    >
      <Box>
        <div>
          <div>
            <Typography variant="h2" color="grey.900" fontWeight={600}>
              Privacy Policy
            </Typography>
            <Typography variant="h3" color="grey.900" fontWeight={500}>
              Updated 15 September 2023
            </Typography>
          </div>
          <p>
            At Contract Communicator Systems SA (Pty) Ltd (reg:2010/011756/07)
            ("<strong>CCSSA</strong>”) we adhere to the highest standards of
            protecting your personal information when we process it by virtue of
            your use of our Services or our Platform http://www.ccom.cloud and
            any related platforms and applications (collectively, “the
            Platform”), or by providing us with your personal information in any
            other way. As such, we have created this privacy policy for you to
            read and to understand how we safeguard your personal information
            and respect your privacy (“<strong>Privacy Policy</strong>”). <br />
            <br />
            Please note that CCSSA is a private limited liability company duly
            registered and operating in accordance with the laws of the Republic
            of South Africa.
            <br />
            <br />
            Not all terms are necessarily defined in order or may be defined in
            our Terms of Use (“<strong>Terms</strong>”).
            <br />
            <br />
            Please ensure that you read all the provisions below, and our
            policies and guidelines which may apply from time to time, to
            understand all of your, and our, rights and duties.
          </p>
          <ol>
            <StyledSectionListItem id="li-1">
              <h2>Important Information and Who We Are</h2>
              <ol>
                <li>
                  <p>Purpose of this Privacy Policy</p>
                  <ol>
                    <li>
                      <p>
                        This Privacy Policy aims to give you information on how
                        we collect and process your personal information through
                        any form of your engagement with us. &nbsp;This Privacy
                        Policy complies with, and facilitates the obligations
                        required from, the South African
                        <em>
                          Protection of Personal Information Act, No.4 of 2013
                        </em>
                        &nbsp;(“<strong>POPI</strong>”), as amended.
                      </p>
                    </li>
                    <li>
                      <p>
                        It is important that you read this Privacy Policy
                        together with any other privacy policy or fair
                        processing notice we may provide on specific occasions
                        when we are collecting or processing personal
                        information about you, so that you are fully aware of
                        how and why we are using your personal information. This
                        Privacy Policy supplements the other notices and is not
                        intended to override them.
                      </p>
                    </li>
                    <li>
                      <p>
                        We do not process the data of minors nor special
                        categories of personal information. Do not provide us
                        with any such personal data, as it will constitute an
                        immediate and automatic breach of this Privacy Policy
                        and our Terms.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Responsible Party and Operator</p>
                  <ol>
                    <li>
                      <p>
                        CCSSA is the “Responsible Party” and is responsible for
                        your personal information when we decide the processing
                        operations of your personal information. In many
                        instances we operate as an “Operator” of personal
                        information on behalf of clients who use our services
                        within their business.
                      </p>
                    </li>
                    <li>
                      <p>
                        We have appointed an information officer at CCSSA who is
                        responsible for overseeing questions in relation to this
                        Privacy Policy. Ifyou have any questions about this
                        Privacy Policy, including any requests to exercise your
                        legal rights, please contact our information officer
                        using the details set out below.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Our Contact Details</p>
                  <ol>
                    <li>
                      <p>Our full details are:</p>
                      <p>
                        <strong>Full name</strong>: &nbsp;Contract Communicator
                        Systems SA (Pty) Ltd
                        <br />
                        <strong>Information Officer</strong>: Sharlene Rambally
                        <br />
                        &zwj;<strong>Email address</strong>:
                        iofficer@ccom.online
                        <br />
                        <strong>Postal address</strong>: 24 Cradock
                        Avenue,Johannesburg, SA, 2196
                        <br />
                        &zwj;<strong>Telephone number</strong>: +27 11519 8960
                      </p>
                    </li>
                    <li>
                      <p>
                        You have the right to make a complaint at any time to
                        the South African regulator’s office (Information
                        Regulator’s Office of South Africa). We would, however,
                        appreciate the chance to deal with your concerns before
                        you approach any such regulator, so please contact us in
                        the first instance.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Changes to this Privacy Policy</p>
                  <ol>
                    <li>
                      <p>
                        This Privacy Policy was last updated on
                        <strong> 15 September</strong>
                        <strong> 2023</strong> and previous versions are
                        archived and can be provided on request.
                      </p>
                    </li>
                    <li>
                      <p>
                        It is important that the personal information we hold
                        about you is accurate and current. Please keep us
                        informed if your personal information changes during
                        your relationship with us.
                      </p>
                    </li>
                    <li>
                      <p>
                        This Privacy Policy is subject to change without notice
                        and is updated or amended from time to time and will be
                        effective once we upload the amended version to the
                        Platform. Your continued access or use of our Services
                        constitutes your acceptance of this Privacy Policy, as
                        amended. It is your responsibility to read this document
                        periodically to ensure you are aware of any changes.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Third-Party Links on Platform</p>
                  <p>
                    The Platform may include links to third-party platforms,
                    plug-ins, and applications. Clicking on those links or
                    enabling those connections may allow third parties to
                    collect or share information about you. We do not control
                    these third-party platforms and are not responsible for
                    their privacy statements or terms. When you leave our
                    Platform, or engage with such third parties, we encourage
                    you to read the distinct privacy policy of every third-party
                    you engage with.
                  </p>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-2">
              <h2>What We Collect About You</h2>
              <ol>
                <li>
                  <p>
                    Personal information, or personally identifiable
                    information, means any information about an individual, both
                    natural and juristic entities (i.e., people and companies),
                    from which that entity can be identified. It does not
                    include information where the identity has been removed
                    (anonymous data).
                  </p>
                </li>
                <li>
                  <p>
                    We may collect, use, store, and transfer (“
                    <strong>process</strong>”) different kinds of personal
                    information about you which we have grouped together as
                    follows:
                  </p>
                  <ol>
                    <li>
                      <p>
                        <strong>Identity Data</strong> including full name, date
                        of birth, job title, country, or the information about
                        your company such as company name, address and/or
                        company registration details;
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Contact Data</strong> including email address,
                        physical/registered addresses, and contact phone
                        numbers;
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Contract Data</strong> including all the data
                        uploaded by a client or by us on behalf of a client to
                        the Platform in use of the Services including
                        contractual agreements, service provider information,
                        supplier information, data from the particular
                        conditions of your contracts, daily diaries, risks,
                        events, compensation events, claims, instructions, early
                        warnings, quotations, comments and supporting documents;
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Social Media Data </strong>including all the
                        data available on your publicly available social media
                        profile;
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Financial Data </strong>including bank account
                        details and third-party payment provider information and
                        payment card details (which we do not process but is
                        processed by a payment service provider on our
                        instruction);
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Transaction Data </strong>including details
                        about payments to and from you, contracts, contractual
                        terms, contract fees, signups, subscriptions, invoices
                        and other details of products and services you have
                        obtained from us, or provide to us;
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Technical Data </strong>including internet
                        protocol address/es, login data, browser type and
                        version, time zone setting and location, cookies,
                        browser plug-in types andversions, operating system and
                        platform and other technology on the devices youuse to
                        access the Platform;
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Usage Data </strong>including information about
                        how you use our company, Platform, surveys,and Services;
                        and;
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Marketing and Communications Data </strong>
                        including your preferences in receiving notices and
                        marketing from us and our third parties and your
                        communication preferences as well as details of which
                        communications were sent to you and how they were sent.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Support Data </strong>including all messages you
                        send to us via live chat, email and WhatsApp as well as
                        records of all conversations had with you over phone or
                        through online conferencing sessions.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    We also collect, use, and share
                    <strong> Aggregated Data</strong> such as statistical or
                    demographic data for any purpose. Aggregated Data may be
                    derived from your personal information but is not considered
                    personal information in law as this data does not directly
                    or indirectly reveal your identity. For example, we may
                    aggregate your Usage Data to calculate the percentage of
                    users accessing a specific Platform feature. However, if we
                    combine or connect Aggregated Data with your personal
                    information so that it can directly or indirectly identify
                    you, we treat the combined data as personal information
                    which will be used in accordance with this Privacy Policy.
                  </p>
                </li>
                <li>
                  <p>
                    Where we need to collect personal information by law, or
                    under the terms of a contract we have with you and you fail
                    to provide that data when requested, we may not be able to
                    perform in terms of the contract we have or are trying to
                    enter into with you (for example, to provide you with
                    services or allow you to provide us with your services). In
                    this case, we may have to cancel Platform-access or Services
                    you have with us, but we will notify you if this is the case
                    at the time.
                  </p>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-3">
              <h2>How Is Your Personal Information Collected?</h2>
              <ol>
                <li>
                  <p>
                    We use different methods to collect personal information
                    from and about you, including through:
                  </p>
                  <ol>
                    <li>
                      <p>
                        <strong>Direct interactions</strong>: You may give us
                        your
                        <strong>
                          &nbsp;Identity, Contact, Contract, Transaction,
                          Technical, Marketing and Communications
                        </strong>
                        &nbsp;and <strong>Usage Data</strong> by filling in
                        various forms or by corresponding with us through the
                        Platform, by phone, email or otherwise. This includes
                        personal information you provide when you:
                      </p>
                      <ol>
                        <li>
                          <p>use our Services</p>
                        </li>
                        <li>
                          <p>use our Platform;</p>
                        </li>
                        <li>
                          <p>Subscribe to our application;</p>
                        </li>
                        <li>
                          <p>contract with us;</p>
                        </li>
                        <li>
                          <p>
                            provide any services to us as a service provider or
                            independent contractor on contract with us;
                          </p>
                        </li>
                        <li>
                          <p>request information to be sent to you;</p>
                        </li>
                        <li>
                          <p>give us some feedback.</p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>
                        <strong>&nbsp;Automated technologies</strong>
                        <strong>or</strong> <strong>interactions</strong>: As
                        you interact with our Platform, we may automatically
                        collect
                        <strong>&nbsp;Technical Data</strong> and
                        <strong>&nbsp;Usage Data</strong> about your equipment,
                        browsing actions and patterns. We may collect this
                        personalinformation by using cookies, server logs and
                        other similar technologies. We may also receive
                        Technical Data about you if you visit other Platforms
                        employing our cookies.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Third parties</strong>: We may receive
                        personalinformation about you from various third parties
                        such as:
                      </p>
                      <ol>
                        <li>
                          <p>analytics providers;</p>
                        </li>
                        <li>
                          <p>financial institutions;</p>
                        </li>
                        <li>
                          <p>marketing platforms; and</p>
                        </li>
                        <li>
                          <p>search information providers.</p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-4">
              <h2>How We Use Your Personal Information</h2>
              <ol>
                <li>
                  <p>
                    We will only use your personal information when the law
                    allows us to and for legitimate reasons, which you hereby
                    expressly understand and consent to. Most commonly, we will
                    use your personal information in the following
                    circumstances:
                  </p>
                  <ol>
                    <li>
                      <p>where we have your express consent to do so;</p>
                    </li>
                    <li>
                      <p>
                        where we need to consult with you or
                        <strong>
                          &nbsp;perform on the Services contract
                        </strong>{" "}
                        we are about to enter into or have entered into with
                        you;
                      </p>
                    </li>
                    <li>
                      <p>
                        where it is necessary for our
                        <strong>&nbsp;legitimate business interests</strong> (or
                        those of a third party) and your interests and
                        fundamental rights do not override those interests;
                        and/or
                      </p>
                    </li>
                    <li>
                      <p>
                        where we need to comply with a
                        <strong>&nbsp;legal or regulatory obligation</strong>.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-5">
              <h2>Purposes For Which We Will Use Your Personal Information</h2>
              <ol>
                <li>
                  <p>
                    We have set out below, in a table format, a description of
                  </p>
                  <ol>
                    <li>
                      <p>
                        the purpose for which we will process your personal
                        information
                      </p>
                    </li>
                    <li>
                      <p>the type of data we process;</p>
                    </li>
                    <li>
                      <p>the lawful basis of processing; and</p>
                    </li>
                    <li>
                      <p>
                        which External Third Parties your personal information
                        is shared with.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Note that we may process your personal information for more
                    than one lawful ground depending on the specific purpose for
                    which we are using your personal information.
                  </p>
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            PURPOSE / ACTIVITY
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            TYPE OF DATA
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            LAWFUL BASIS AND BUSINESSINTEREST
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableRows.map((row) => (
                          <TableRow key={row.id}>
                            <StyledTableCell component="th" scope="row">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row.purpose,
                                }}
                              ></div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row.type,
                                }}
                              ></div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row.lawfulBasis,
                                }}
                              ></div>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </li>
                <li>
                  <p>Marketing</p>
                  <ol>
                    <li>
                      <p>
                        We strive to provide you with choices regarding certain
                        personal information uses, particularly around marketing
                        and advertising. To manifest your rights attached to any
                        marketing sent to you as an existing client, please use
                        the in-built prompts provided on those communications,
                        or contact us.
                      </p>
                    </li>
                    <li>
                      <p>
                        You will receive marketing communications from us if you
                        have requested our Services, requested information from
                        us, or provided us with your details in any other
                        circumstance and, in each case, have not opted-out of
                        receiving that marketing.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Third-Party Marketing
                    <br />
                    Whilst we may use your personal information within our
                    company, we will get your express opt-in consent before we
                    share your personal information publicly with any entity
                    outside of CCSSA for marketing.
                  </p>
                  <ol></ol>
                </li>
                <li>
                  <p>
                    Opting-Out
                    <br />
                    You can ask us to stop sending you marketing messages at any
                    time by contacting us and requesting us to cease or change
                    your marketing preferences. Where you opt-out of receiving
                    these marketing messages, this opt-out will not apply to
                    other personal information of yours which we process for
                    another lawful basis or necessary communications we send
                    when you use our Services.
                  </p>
                  <ol></ol>
                </li>
                <li>
                  <p>Change of Purpose</p>
                  <ol>
                    <li>
                      <p>
                        We will only use your personal information for the
                        purposes for which we collected it, unless we reasonably
                        consider that we need to use it for another reason and
                        that reason is compatible with the original purpose. If
                        you wish to get an explanation as to how the processing
                        for the new purpose is compatible with the original
                        purpose, please contact us.
                      </p>
                    </li>
                    <li>
                      <p>
                        If we need to use your personal information for an
                        unrelated purpose, we will notify you and we will
                        explain the legal basis which allows us to do so.
                      </p>
                    </li>
                    <li>
                      <p>
                        Please note that we may process your personal
                        information without your knowledge or consent, in
                        compliance with the above rules, where this is required
                        or permitted by law.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-6">
              <h2>Disclosures Of Your Personal Information</h2>
              <ol>
                <li>
                  <p>
                    We may have to share your personal information withthe
                    parties set out below for the purposes set out in the table
                    above.
                  </p>
                  <ol>
                    <li>
                      <p>
                        Internal Third Parties as set out in the
                        <strong>&nbsp;Glossary</strong>;
                      </p>
                    </li>
                    <li>
                      <p>
                        External Third Parties as set out in the
                        <strong>&nbsp;Glossary</strong>;
                      </p>
                    </li>
                    <li>
                      <p>
                        Third parties to whom we may choose to sell, transfer,
                        or merge parts of our company or our assets.
                        Alternatively, we may seek to acquire other
                        organisations or merge with them. If a change happens to
                        our company, we may continue to use your personal
                        information in the same way as set out in thisPrivacy
                        Policy.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    We require all third parties to respect the security of your
                    personal information and to treat it in accordance with the
                    law. We do not allow our third-party service providers to
                    use your personal information for their own purposes and
                    only permit them to process your personal information in
                    accordance with our instructions and standards.
                  </p>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-7">
              <h2>Cookies</h2>
              <ol>
                <li>
                  <p>
                    The Platform may make use of “cookies” to automatically
                    collect information and data through the standard operation
                    of the Internet servers. “Cookies” are small text files a
                    platform can use (and which we may use) to recognise repeat
                    users, facilitate the user’s on-going access to and use of a
                    platform and allow a platform to track usage behaviour and
                    compile aggregate data that will allow the platform operator
                    to improve the functionality of the platform and its
                    content, and to display more focused advertising to a user
                    by way of third party tools.
                  </p>
                </li>
                <li>
                  <p>
                    The type of information collected by cookies is not used to
                    personally identify you. If you do not want information
                    collected using cookies, there is a simple procedure in most
                    browsers that allows you to deny or accept the cookie
                    feature. Please note that cookies may be necessary to
                    provide you with certain features available on our Platform
                    and thus if you disable the cookies on your browser you may
                    not be able to use those features, and your access to our
                    Platform will therefore be limited. If you do not disable
                    “cookies”, you are deemed to consent to our use of any
                    personal information collected using those cookies, subject
                    to the provisions of thisPrivacy Policy and our other
                    policies or terms.
                  </p>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-8">
              <h2>International Transfers</h2>
              <ol>
                <li>
                  <p>
                    We may share and process your personal information outside
                    of South Africa for the purpose of cloud storage or to
                    engage with third parties. These countries include Ireland
                    and Germany.
                  </p>
                </li>
                <li>
                  <p>
                    Whenever we may transfer your personal information out of
                    South Africa, we will ensure a similar degree of protection
                    is afforded to it by ensuring at least one of the following
                    safeguards is implemented:
                  </p>
                  <ol>
                    <li>
                      <p>
                        We will only transfer your personal information to
                        countries that have appropriate data protection
                        legislation in place similar to that of South Africa;
                        and/or
                      </p>
                    </li>
                    <li>
                      <p>
                        Where we use service providers, we may use specific
                        contracts/clauses which ensure personal information is
                        processed and secured lawfully.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Please contact us if you want further information regarding
                    transfer of personal information out of South Africa.
                  </p>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-9">
              <h2>Data Security</h2>
              <ol>
                <li>
                  <p>
                    We have put in place appropriate security measures to
                    prevent your personal information from being accidentally
                    lost, used, or accessed in an unauthorised way, altered, or
                    disclosed by implementing our information security policy
                    within CCSSA. We also limit access to your personal
                    information to those employees, agents, contractors and
                    other third parties who have a legitimate need to know. They
                    will only process your personal information on our
                    instruction and are subject to a duty of confidentiality.
                  </p>
                </li>
                <li>
                  <p>
                    We have put in place procedures to deal with any suspected
                    personal information breach and will notify you and the
                    Information Regulator of a breach where we are legally
                    required to do so.
                  </p>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-10">
              <h2>Data Retention</h2>
              <ol>
                <li>
                  <p>
                    We will only retain your personal information for as long as
                    necessary to fulfil the purpose we collected it for
                    including any legal, accounting, or reporting requirements.
                  </p>
                </li>
                <li>
                  <p>
                    To determine the appropriate retention period for personal
                    information, we consider the amount, nature, and sensitivity
                    of the personal information, the potential risk of harm from
                    unauthorised use or disclosure of your personal information,
                    the purpose for which we process your personal information,
                    any other South African applicable law requiring us to
                    retain the personal information and whether we can achieve
                    those purposes through other means, and the applicable legal
                    requirements.
                  </p>
                </li>
                <li>
                  <p>
                    We may also anonymise your personal information (so that it
                    can no longer be associated with you) for research or
                    statistical purposes in which case we may use this
                    information indefinitely without further notice to you.
                  </p>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-11">
              <h2>Your Legal Rights</h2>
              <ol>
                <li>
                  <p>
                    You have rights in relation to your personal information
                    where we are the relevant “Responsible Party” over such
                    personal information. Please contact us to find out more
                    about, or manifest, these rights:
                  </p>
                  <ol>
                    <li>
                      <p>request access to your personal information;</p>
                    </li>
                    <li>
                      <p>request correction of your personal information;</p>
                    </li>
                    <li>
                      <p>request erasure of your personal information;</p>
                    </li>
                    <li>
                      <p>
                        object to the processing of your personal information;
                      </p>
                    </li>
                    <li>
                      <p>
                        request a restriction of processing your personal
                        information;
                      </p>
                    </li>
                    <li>
                      <p>
                        request transfer of your personal information; and/or
                      </p>
                    </li>
                    <li>
                      <p>right to withdraw consent.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    You will not have to pay a fee to access your personal
                    information (or to exercise any of the other rights).
                    However, we may charge a reasonable fee if your request is
                    clearly unfounded, repetitive, or excessive. Alternatively,
                    we may refuse to comply with your request in these
                    circumstances.
                  </p>
                </li>
                <li>
                  <p>
                    We may need to request specific information from you to help
                    us confirm your identity and ensure your right to access
                    your personal information (or to exercise any of your other
                    rights). This is a security measure to ensure that personal
                    information is not disclosed to any person who has no right
                    to receive it. We may also contact you to ask you for
                    further information in relation to your request to speed up
                    our response.
                  </p>
                </li>
                <li>
                  <p>
                    We try to respond to all legitimate requests within one
                    month. Occasionally it may take us longer than a month if
                    your request is particularly complex or you have made a
                    number of requests. In this case, we will notify you and
                    keep you updated.
                  </p>
                </li>
                <li>
                  <p>
                    Users with citizenships from jurisdictions other than South
                    Africa, please note that we comply with South African data
                    protection laws when processing your personal information as
                    we are a South African entity. Should foreign law be
                    applicable to your use of the Services and/or the Platform
                    in any way, including how we may process your personal
                    information, please contact us and we will gladly engage
                    with you on your rights.
                  </p>
                </li>
              </ol>
            </StyledSectionListItem>
            <StyledSectionListItem id="li-12">
              <h2>Glossary</h2>
              <ol>
                <li>
                  <p>Lawful Basis</p>
                  <ol>
                    <li>
                      <p>
                        <strong>Legitimate Interest</strong> means the interest
                        of our organisation in conducting and managing our
                        organisation to enable us to give you the best service
                        and the most secure experience. We make sure we consider
                        and balance any potential impact on you (both positive
                        and negative) and your rights before we process your
                        personal information for our legitimate interests. We do
                        not use your personal information for activities where
                        our interests are overridden by the impact on you
                        (unless we have your consent or are otherwise required
                        or permitted to by law). You can obtain further
                        information about how we assess our legitimate interests
                        against any potential impact on you in respect of
                        specific activities by contacting us.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Performance of Contract</strong> means
                        processing your personal information where it is
                        necessary for the performance of a contract to which you
                        are a party or to take steps at your request before
                        entering into such a contract.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          Comply with a legal or regulatory obligation
                        </strong>
                        &nbsp;means processing your personal information where
                        it is necessary for compliance with a legal or
                        regulatory obligation that we are subject to.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Express consent</strong> means the confirmed
                        express consent you have provided to our processing of
                        your personal information by actively accepting this
                        Privacy Policy.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Third Parties</p>
                  <ol>
                    <li>
                      <p>
                        <strong>Internal Third Parties&nbsp;</strong>means other
                        entities or parties in the CCSSA group acting as joint
                        responsible parties or operators and provide IT and
                        system administration services and undertake reporting.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>External Third Parties</strong> means:
                      </p>
                      <ol>
                        <li>
                          <p>
                            Authorised third-party service providers under
                            contract with CCSSA who need your personal
                            information in order to contact and transact with
                            you pursuant to your use of the Services;
                          </p>
                        </li>
                        <li>
                          <p>
                            service providers acting as operators who provide IT
                            and system administration services;
                          </p>
                        </li>
                        <li>
                          <p>
                            South African or other national governments and/or
                            their respective authorities pursuant to our
                            adherence with anti-corruption and crime-fighting
                            legislation; and/or
                          </p>
                        </li>
                        <li>
                          <p>
                            professional advisers acting as operators or joint
                            responsible parties including lawyers, bankers,
                            auditors and insurers based in South Africa who
                            provide consultancy, banking, legal, insurance and
                            accounting services as required.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>Your Legal Rights</p>
                  <p>You have the right to:</p>
                  <ol>
                    <li>
                      <p>
                        <strong>Request access</strong> to your personal
                        information (commonly known as a “
                        <strong>data subject access request</strong>”). This
                        enables you to receive a copy of the personal
                        information we hold about you and to check that we are
                        lawfully processing it.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Request correction</strong> of the personal
                        information that we hold about you. This enables you to
                        have any incomplete or inaccurate data we hold about you
                        corrected, though we may need to verify the accuracy of
                        the new data you provide to us.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Request erasure </strong>of your personal
                        information. This enables you to ask us to delete or
                        remove personal information where there is no valid
                        reason for us continuing to process it. You also have
                        the right to ask us to delete or remove your personal
                        information where you have successfully exercised your
                        right to object to processing (see below), where we may
                        have processed your information unlawfully or where we
                        are required to erase your personal information to
                        comply with local law.
                        <strong>
                          &nbsp;Note, however, that we may not always be able to
                          comply with your request of erasure for specific legal
                          reasons which will be communicated to you, if
                          applicable, at the time of your request.
                        </strong>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Object to processing </strong>of your personal
                        information where we are relying on a legitimate
                        interest (or those of a third party) and there is
                        something about your situation which makes you want to
                        object to processing on this ground as you feel it
                        impacts on your fundamental rights and freedoms. You
                        also have the right to object where we are processing
                        your personal information for direct marketing purposes.
                        In some cases, we may demonstrate that we have
                        compelling legitimate grounds to process your
                        information which override your rights and freedoms.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Request restriction of processing </strong>of
                        your personal information. This enables you to ask us to
                        suspend the processing of your personal information in
                        the following scenarios:
                      </p>
                      <ol>
                        <li>
                          <p>
                            if you want us to establish the data’s accuracy;
                          </p>
                        </li>
                        <li>
                          <p>
                            where our use of the data is unlawful but you do not
                            want us to erase it;
                          </p>
                        </li>
                        <li>
                          <p>
                            where you need us to hold the data even if we no
                            longer require it as you need it to establish,
                            exercise or defend legal claims; or
                          </p>
                        </li>
                        <li>
                          <p>
                            you have objected to our use of your data, but we
                            need to verify whether we have overriding legitimate
                            grounds to use it.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>
                        <strong>Request the transfer </strong>of your personal
                        information to you or to a third party. We will provide
                        to you, or a third party you have chosen, your personal
                        information in a structured, commonly used,
                        machine-readable format. Note that this right only
                        applies to automated information which you initially
                        provided consent for us to use or where we used the
                        information to perform on a contract with you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Withdraw consent at anytime </strong>where we
                        are relying on consent to process your personal
                        information.However, this will not affect the lawfulness
                        of any processing carried out before you withdraw your
                        consent. If you withdraw your consent, we may not be
                        able to provide certain Platform access or Services to
                        you. We will advise you if this is the case at the time
                        you withdraw your consent.
                        <strong>
                          &nbsp;Please take note that regardless of your right
                          to withdraw consent under POPI, other South African
                          legislation applies and may require that we continue
                          to process your data to comply with anti-corruption,
                          crime-fighting and/or other national legislation,
                          which you expressly understand and agree to.
                        </strong>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </StyledSectionListItem>
          </ol>
        </div>
      </Box>
    </BasicModal>
  );
};
