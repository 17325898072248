import { useMemo } from "react";
import { useClaimDetailsExplorerItems } from "../../../hooks/useClaimDetailsExplorerItems/useClaimDetailsExplorerItems";

export const useFinalDetermination = () => {
  const { claimDeterminations, loading } = useClaimDetailsExplorerItems();

  const finalDetermination = useMemo(() => {
    return claimDeterminations.sort((determination1, determination2) => {
      const date1 = new Date(determination1.dateCreated as string).getTime();
      const date2 = new Date(determination2.dateCreated as string).getTime();

      return date2 - date1;
    })[0];
  }, [claimDeterminations]);

  return {
    finalDetermination,
    loading,
  };
};
