import { Stack, Typography } from "@mui/material";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { dailyDiaryStatusOptions } from "containers/Projects/components/DailyDiariesView/DailyDiariesView.constants";
import { DailyDiaryItem } from "generated/graphql";
import { useTranslation } from "react-i18next";

type ImportRecordsModalDiaryDetailsProps = {
  diary: DailyDiaryItem;
};

export const ImportRecordsModalDiaryDetails: React.FC<
  ImportRecordsModalDiaryDetailsProps
> = ({ diary }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography
        variant="p1"
        fontWeight={600}
        color="grey.800"
      >{`${diary.number}`}</Typography>
      <Typography variant="p1" fontWeight={600} color="grey.800">
        {t("Projects.DailyDiaries.revisionNo", {
          revisionNo: Number(diary.latestRevision.number),
        })}
      </Typography>
      <StatusTagNew
        selectedOptionId={diary.latestRevision.status}
        disabled
        options={dailyDiaryStatusOptions}
      />
    </Stack>
  );
};
