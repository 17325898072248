import { useTheme } from "@mui/material";
import {
  CompanyStatus,
  NewClaimTypeOverrideStatus,
  UserStatus,
} from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StatusOption } from "./StatusTag";

enum ActiveRemovedSuspended {
  Active = "Active",
  Removed = "Removed",
  Suspended = "Suspended",
}

/**
 * Valid for enums of type Active, Removed & Suspended
 * @returns Array of valid options for <StatusTag />
 */
export const useActiveRemovedSuspendedStatusOptions = (): StatusOption<
  | CompanyStatus
  | UserStatus
  | ActiveRemovedSuspended
  | NewClaimTypeOverrideStatus
>[] => {
  const theme = useTheme();
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(ActiveRemovedSuspended).map((status) => ({
      id: status,
      label: t(`Enums.ActiveRemovedSuspendedStatus.${status}`),
      color:
        status === ActiveRemovedSuspended.Active
          ? theme.palette.primary.main
          : status === ActiveRemovedSuspended.Suspended
          ? theme.palette.warning.main
          : theme.palette.grey[700],
    }));
  }, [theme, t]);

  return options;
};
