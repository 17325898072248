import { TimePickerProps } from "@mui/lab";
import { css, styled } from "@mui/material";
import { TimePicker as TimePickerMUI } from "@mui/x-date-pickers-pro";

const StyledTimePicker = styled(TimePickerMUI)<{ error?: boolean }>(
  ({ theme, error }) => {
    return css`
      && {
        fieldset {
          border-color: ${error ? theme.palette.error.main : "none"};
        }
      }
    `;
  }
);

export const TimePicker: React.FC<
  TimePickerProps<Date> & { error?: boolean }
> = ({ error, ...props }) => {
  return (
    <StyledTimePicker
      label="hh:mm"
      error={error}
      slotProps={{ textField: { size: "small", fullWidth: true } }}
      ampm={false}
      {...props}
    />
  );
};
