import {
  DailyDiaryItem,
  ImportDailyDiaryWorkRecordsMutation,
  ImportDailyDiaryWorkRecordsMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useWorkRecords } from "./useWorkRecords";
import { importDailyDiaryWorkRecordsMutation } from "./WorkSection.query";

export const useImportWorkRecordsModal = (dailyDiary?: DailyDiaryItem) => {
  const { t } = useTranslation();

  const { workRecords, workRecordsLoading } = useWorkRecords(dailyDiary);

  const [importRecords, { loading: importLoading }] = useGraphMutation<
    ImportDailyDiaryWorkRecordsMutation,
    ImportDailyDiaryWorkRecordsMutationVariables
  >(
    importDailyDiaryWorkRecordsMutation,
    {},
    t("Projects.DailyDiaries.ImportRecordsModal.importSuccess")
  );

  return {
    workRecords,
    loading: workRecordsLoading || importLoading,
    importLoading,
    workRecordsLoading,
    importRecords,
  };
};
