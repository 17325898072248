import { gql } from "@apollo/client";

export const changeLookupOptionStatusMutation = gql`
  mutation changeLookupOptionStatus($id: ID!, $status: LookupOptionStatus!) {
    changeLookupOptionStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
