import { ListItem } from "components/DetailsList";
import { dateISOFormat } from "constants/constants";
import {
  DailyDiaryExtraConfig,
  ProductInstance,
  ProductType,
} from "generated/graphql";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ProductInstanceMode,
  productInstanceModes,
} from "../../components/ProductInstanceForm/ProductInstanceForm";
import { useProductInstanceDailyDiaryExtraConfigForm } from "../../components/ProductInstanceForm/useProductInstanceDailyDiaryExtraConfigForm";
import { ProductTypename, ProductTypenameToProduct } from "constants/products";

export const useProductInstanceEntityObj = (
  productInstance?: ProductInstance
) => {
  const { t } = useTranslation();

  const {
    lookups,
    loading: ddExtraConfigLoading,
    dailyDiaryPresets,
  } = useProductInstanceDailyDiaryExtraConfigForm(productInstance?.contractId);

  const obj = useMemo(() => {
    const toReturn: ListItem[] = [];

    if (!productInstance || ddExtraConfigLoading) {
      return toReturn;
    }
    const isDailyDiaryProdInstance =
      ProductTypenameToProduct[
        (productInstance.product as any).__typename as ProductTypename
      ] === ProductType.DailyDiary;

    const prodInstanceStartDate = isDailyDiaryProdInstance
      ? (productInstance.extraConfig as DailyDiaryExtraConfig)?.startDate
      : null;

    return [
      {
        id: "product",
        label: t("common.labels.product"),
        value: productInstance.product.name,
      },
      {
        id: "instance",
        label: t("AdminConsole.ProductInstances.labels.instance"),
        value: productInstance.description,
      },
      ...(isDailyDiaryProdInstance
        ? []
        : [
            {
              id: "config",
              label: t("common.labels.config"),
              value: productInstance.productSchema.name,
            },
          ]),
      {
        id: "itemStatuses",
        label: t("AdminConsole.ProductInstances.labels.itemStatuses"),
        value: productInstance.statusCollection.name,
      },
      {
        id: "mode",
        label: t("AdminConsole.ProductInstances.labels.mode"),
        value: productInstance.soloModeSupported
          ? productInstanceModes[ProductInstanceMode.Solo].label
          : productInstanceModes[ProductInstanceMode.Multi].label,
      },
      {
        id: "numberingFormat",
        label: t("AdminConsole.ProductInstances.labels.numberingFormat"),
        value: productInstance.numberingFormat,
      },
      ...(isDailyDiaryProdInstance
        ? [
            {
              id: "startDate",
              label: t("common.labels.startDate"),
              value: prodInstanceStartDate
                ? moment(new Date(prodInstanceStartDate)).format(dateISOFormat)
                : "",
            },
            {
              id: "preset",
              label: t("AdminConsole.ProductInstances.labels.preset"),
              value:
                dailyDiaryPresets.find(
                  (preset) =>
                    preset.id ===
                    (productInstance.extraConfig as DailyDiaryExtraConfig)
                      .presetId
                )?.name ?? "",
            },
            {
              id: "equipmentLookup",
              label: t("AdminConsole.ProductInstances.labels.equipmentLookup"),
              value:
                lookups.find(
                  (lookup) =>
                    lookup.id ===
                    (productInstance.extraConfig as DailyDiaryExtraConfig)
                      .equipmentLookupId
                )?.name ?? "",
            },
            {
              id: "manpowerLookup",
              label: t("AdminConsole.ProductInstances.labels.manPowerLookup"),
              value:
                lookups.find(
                  (lookup) =>
                    lookup.id ===
                    (productInstance.extraConfig as DailyDiaryExtraConfig)
                      .manPowerLookupId
                )?.name ?? "",
            },
            {
              id: "areaLookup",
              label: t("AdminConsole.ProductInstances.labels.areaLookup"),
              value:
                lookups.find(
                  (lookup) =>
                    lookup.id ===
                    (productInstance.extraConfig as DailyDiaryExtraConfig)
                      .areaLookupId
                )?.name ?? "",
            },
            {
              id: "hseTypeLookup",
              label: t("AdminConsole.ProductInstances.labels.hseTypeLookup"),
              value:
                lookups.find(
                  (lookup) =>
                    lookup.id ===
                    (productInstance.extraConfig as DailyDiaryExtraConfig)
                      .hseTypeLookupId
                )?.name ?? "",
            },
            {
              id: "companyLookup",
              label: t("AdminConsole.ProductInstances.labels.companyLookup"),
              value:
                lookups.find(
                  (lookup) =>
                    lookup.id ===
                    (productInstance.extraConfig as DailyDiaryExtraConfig)
                      .companyLookupId
                )?.name ?? "",
            },
            {
              id: "teamLookup",
              label: t("AdminConsole.ProductInstances.labels.teamLookup"),
              value:
                lookups.find(
                  (lookup) =>
                    lookup.id ===
                    (productInstance.extraConfig as DailyDiaryExtraConfig)
                      .teamLookupId
                )?.name ?? "",
            },
            {
              id: "shiftLookup",
              label: t("AdminConsole.ProductInstances.labels.shiftLookup"),
              value:
                lookups.find(
                  (lookup) =>
                    lookup.id ===
                    (productInstance.extraConfig as DailyDiaryExtraConfig)
                      .shiftLookupId
                )?.name ?? "",
            },
          ]
        : []),
    ];
  }, [productInstance, t, ddExtraConfigLoading, dailyDiaryPresets, lookups]);

  return obj;
};
