import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { useContractStatusOptions } from "components/StatusTag/useContractStatusOptions";
import { dateTimeISOFormat } from "constants/constants";
import { ProjectStatus } from "generated/graphql";
import { useNavigateBack } from "hooks/useNavigateBack";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import {
  PageContentHeader,
  PageContentHeaderSize,
} from "../../../../../components/PageContentHeader/PageContentHeader";
import { HeaderLoadingContainer } from "../../Companies/components/HeaderLoadingContainer";

export type ProjectDetailsHeaderProps = {
  generalLoading?: boolean;
  friendlyName: string;
  name: string;
  status?: ProjectStatus;
  creator: string;
  dateCreated: string;
  onStatusChange: (newStatus: ProjectStatus) => void;
};

export const ProjectDetailsHeader: React.FC<ProjectDetailsHeaderProps> = ({
  friendlyName,
  status,
  creator,
  dateCreated,
  generalLoading,
  onStatusChange,
}) => {
  const { t } = useTranslation();
  const handleNavigateBack = useNavigateBack();
  const projectStatusOptions =
    useContractStatusOptions() as StatusOption<ProjectStatus>[];

  const handleStatusChange = (newStatus: StatusOption<ProjectStatus>) => {
    onStatusChange(newStatus.id as ProjectStatus);
  };

  return (
    <PageContentHeader size={PageContentHeaderSize.Large}>
      {generalLoading && (
        <HeaderLoadingContainer>
          <CircularProgress />
        </HeaderLoadingContainer>
      )}
      <Box display="flex" alignItems="center">
        <ArrowBackIcon
          onClick={handleNavigateBack}
          style={{ cursor: "pointer" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          ml={3}
          mr={1}
        >
          {generalLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "14px" }} width={150} />
          ) : (
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link key="1" onClick={handleNavigateBack}>
                <Typography variant="body2">
                  {t("AdminConsole.Projects.labels.projects")}
                </Typography>
              </Link>
            </Breadcrumbs>
          )}
          {generalLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "24px" }} width={150} />
          ) : (
            <Typography variant="h5">{friendlyName}</Typography>
          )}
        </Box>
        {status && (
          <Box alignSelf="flex-end">
            <StatusTag
              status={status}
              onChange={handleStatusChange}
              options={projectStatusOptions}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {creator && dateCreated && (
          <Box display="flex" alignItems="center">
            <Typography variant="body2">
              <Trans
                i18nKey="common.labels.createdByOn"
                values={{
                  creator,
                  dateCreated: moment(dateCreated).format(dateTimeISOFormat),
                }}
              />
            </Typography>
          </Box>
        )}
      </Box>
    </PageContentHeader>
  );
};
