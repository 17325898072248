import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";

export const sendCompEventOwnAssessmentNoticeMutation = gql`
  ${compEventItemFields}

  mutation sendCompEventOwnAssessmentNotice(
    $input: SendCompEventOwnAssessmentNoticeInput!
  ) {
    sendCompEventOwnAssessmentNotice(input: $input) {
      ...CompEventItemFields
    }
  }
`;
