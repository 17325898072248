import { Box, styled } from "@mui/material";

export const TooltipContainer = styled(Box)`
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  width: 550px;
  padding: 24px;
  border-radius: 8px;
  right: -20px;
  top: 100%;
  z-index: 2;
  box-sizing: border-box;
  overflow: hidden;
`;
