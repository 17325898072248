import { AutoRole } from "generated/graphql";
import { DataValidators, ValidatorType } from "helpers/validators";

export const UserAutoRoleMapping: Record<AutoRole, string> = {
  [AutoRole.None]: "None",
  [AutoRole.Viewer]: "Viewer",
  [AutoRole.Contributor]: "Contributor",
};

export const inviteUserValidators: DataValidators = {
  email: {
    validators: [ValidatorType.Required, ValidatorType.ValidEmail],
  },
};
