import { Box, Typography } from "@mui/material";
import {
  ContractKeyDate,
  ContractSection,
  ContractTimeImpact,
} from "generated/graphql";
import { useMemo } from "react";
import { TimeField } from "./TimeField";

export type ImpactedDateFieldProps = {
  keyDate?: ContractKeyDate;
  section?: ContractSection;
  timeImpact?: ContractTimeImpact;
  positiveOnly?: boolean;
  onIncrement: () => void;
  onDecrement: () => void;
};

export const ImpactedDateField: React.FC<ImpactedDateFieldProps> = ({
  timeImpact,
  keyDate,
  section,
  positiveOnly,
  onIncrement,
  onDecrement,
}) => {
  const defaultTimeImpact = useMemo(
    () => ({
      id: keyDate ? keyDate.id : section!.id,
      days: 0,
    }),
    [keyDate, section]
  );
  const computedTimeImpact = useMemo(
    () => timeImpact ?? defaultTimeImpact,
    [timeImpact, defaultTimeImpact]
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="p2" color="grey.800">
        {section ? section.description : keyDate!.conditionToBeMet}
      </Typography>
      <Box width="200px">
        <TimeField
          number={computedTimeImpact.days}
          positiveOnly={positiveOnly}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
        />
      </Box>
    </Box>
  );
};
