import {
  SetUserPreferencesMutation,
  SetUserPreferencesMutationVariables,
  UserPreferences,
  UserPreferencesQuery,
  UserPreferencesQueryVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useTranslation } from "react-i18next";
import { notificationPreferencesToInput } from "./NotificationsPreferences.utils";
import {
  setUserPreferencesMutation,
  userPreferencesQuery,
} from "./NotificationsSettings.query";

export const useNotificationsSettings = () => {
  const { t } = useTranslation();

  const { data: userPreferences, loading } = useGraphQuery<
    UserPreferencesQuery,
    UserPreferencesQueryVariables
  >(userPreferencesQuery);

  const [doUpdateUserPreferences, { loading: updateUserPreferencesLoading }] =
    useGraphMutation<
      SetUserPreferencesMutation,
      SetUserPreferencesMutationVariables
    >(
      setUserPreferencesMutation,
      {
        update: (cache, data) => {
          cache.updateQuery({ query: userPreferencesQuery }, () => ({
            userPreferences: {
              ...data.data?.setUserPreferences,
            },
          }));
        },
      },
      t("Notifications.labels.preferencesUpdatedMsg")
    );

  const updateUserPreferences = (updatedUserPreferences: UserPreferences) => {
    doUpdateUserPreferences({
      variables: {
        input: notificationPreferencesToInput(updatedUserPreferences),
      },
      optimisticResponse: {
        setUserPreferences: {
          __typename: "UserPreferences",
          ...updatedUserPreferences,
        },
      },
    });
  };

  return {
    userPreferences: userPreferences?.userPreferences,
    updateUserPreferences,
    generalLoading: loading,
    updateLoading: updateUserPreferencesLoading,
  };
};
