import { Box, Button, Tooltip, Typography } from "@mui/material";
import { CancelUpdateButtons } from "components/CancelUpdateButtons";
import { useTranslation } from "react-i18next";

export type EditEntityHeaderButtonsProps = {
  editMode?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  onEdit?: () => void;
  onEditTooltip?: string;
  onSaveActionLoading?: boolean;
  updateBtnRef?: React.Ref<HTMLButtonElement>;
};
export const EditEntityHeaderButtons: React.FC<
  EditEntityHeaderButtonsProps
> = ({
  editMode,
  onEditTooltip,
  onCancel,
  onSave,
  onEdit,
  updateBtnRef,
  onSaveActionLoading,
}) => {
  const { t } = useTranslation();

  return editMode ? (
    <Box display="flex" alignItems="center" ml={4}>
      <CancelUpdateButtons
        onCancel={onCancel}
        onUpdate={onSave}
        loading={onSaveActionLoading}
        updateBtnRef={updateBtnRef}
      />
    </Box>
  ) : (
    <Box ml={4} display="flex" alignItems="center">
      <Tooltip title="Coming soon..." arrow>
        <span>
          <Button
            variant="contained"
            color="secondary"
            disableRipple
            style={{ textTransform: "none" }}
            size="small"
            disabled
          >
            <Typography variant="body2" fontWeight={500}>
              {t("common.buttons.changeLog")}
            </Typography>
          </Button>
        </span>
      </Tooltip>
      <Box ml={2}>
        {onEdit ? (
          <Button
            variant="contained"
            color="primary"
            onClick={onEdit}
            disableRipple
            style={{ textTransform: "none" }}
            size="small"
          >
            <Typography variant="body2" fontWeight={500} color="white">
              {t("common.buttons.edit")}
            </Typography>
          </Button>
        ) : (
          <Tooltip title={onEditTooltip || ""} arrow>
            <span>
              <Button
                variant="contained"
                color="primary"
                disabled
                disableRipple
                style={{ textTransform: "none" }}
                size="small"
              >
                <Typography variant="body2" fontWeight={500} color="white">
                  {t("common.buttons.edit")}
                </Typography>
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
