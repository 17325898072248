import {
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowAuditQuery,
  AuthorizationWorkflowAuditQueryVariables,
} from "generated/graphql";
import { authorizationWorkflowAuditQuery } from "graphql/queries/authorizationWorkflowAudit.query";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useAuthorizationAuditTrailDetails = (authAuditTrailId: string) => {
  const { data: authAuditTrail, loading: authAuditTrailLoading } =
    useGraphQuery<
      AuthorizationWorkflowAuditQuery,
      AuthorizationWorkflowAuditQueryVariables
    >(authorizationWorkflowAuditQuery, {
      variables: { id: authAuditTrailId },
    });

  return {
    authAuditTrail: authAuditTrail?.authorizationWorkflowAudit as
      | AuthorizationWorkflowAudit
      | undefined,
    loading: authAuditTrailLoading,
  };
};
