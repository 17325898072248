import { useTranslation } from "react-i18next";
import { DailyDiaryHeaderAction } from "./DailyDiaryHeader";
import { LoadingButton } from "@mui/lab";

export const DraftStateOwnerActionButtons: React.FC<{
  loading?: boolean;
  disabled?: boolean;
  onClick: (selectedOption: string) => void;
}> = ({ loading, disabled, onClick }) => {
  const { t } = useTranslation();

  // TODO: uncomment when enabling back Waive and LockEdit

  // return (
  //   <SplitButton
  //     options={[
  //       {
  //         label: t(DailyDiaryHeaderAction.Send),
  //         icon: <PaperPlaneTilt size={18} />,
  //       },
  //       {
  //         label: t(DailyDiaryHeaderAction.LockAndEdit),
  //         icon: <Lock size={18} />,
  //       },
  //       {
  //         label: t(DailyDiaryHeaderAction.Waive),
  //         icon: <CalendarX size={18} />,
  //       },
  //     ]}
  //     minWidth="100px !important"
  //     disabled={loading}
  //     onClick={onClick}
  //   />
  // );

  const handleClick = () => {
    onClick(t(DailyDiaryHeaderAction.Send));
  };

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      onClick={handleClick}
      variant="contained"
      size="large"
    >
      {t("common.buttons.send")}
    </LoadingButton>
  );
};
