import { ActionsDialogProps } from "components/ActionsDialog";
import { DraftVariationProposal, VariationActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { useSendProposalForVariationModal } from "./useSendProposalForVariationModal";
import { VariationProposalAgreementDeterminationSummary } from "../components/VariationProposalAgreementDeterminationSummary";

export type SendProposalForVariationModalProps = {
  draftProposals: DraftVariationProposal[];
  preferredProposalId: string;
} & ActionsDialogProps;

export const SendProposalForVariationModal: React.FC<
  SendProposalForVariationModalProps
> = ({ draftProposals, preferredProposalId, ...restDialogProps }) => {
  const { variationPreview, contract } = useSendProposalForVariationModal(
    draftProposals,
    preferredProposalId
  );
  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.SubmitProposalForVariation}
      summaryEl={
        <VariationProposalAgreementDeterminationSummary
          contractCurrency={contract.valueCurrency ?? ""}
          items={draftProposals}
          type="VariationProposal"
        />
      }
      {...restDialogProps}
    />
  );
};
