import {
  ClaimHistoryItem,
  ClaimHistoryItemQuery,
  ClaimHistoryItemQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { claimHistoryItemQuery } from "./claimHistoryItem.query";

export const useClaimHistoryItem = (id?: string) => {
  const { data: claimHistoryItem, loading: claimHistoryItemLoading } =
    useGraphQuery<ClaimHistoryItemQuery, ClaimHistoryItemQueryVariables>(
      claimHistoryItemQuery,
      {
        variables: {
          id: id!,
        },
        skip: !id,
      }
    );

  return {
    claimHistoryItem: claimHistoryItem?.claimHistoryItem as
      | ClaimHistoryItem
      | undefined,
    loading: claimHistoryItemLoading,
  };
};
