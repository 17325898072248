import { gql } from "@apollo/client";
import { compEventHistoryItemFields } from "graphql/fragments/compEventHistoryItem.fragment";

export const compEventHistoryItemsQuery = gql`
  ${compEventHistoryItemFields}

  query compEventHistoryItems($compEventId: ID!) {
    compEventHistoryItems(compEventId: $compEventId) {
      items {
        ...CompEventHistoryItemFields
      }
    }
  }
`;
