import {
  DraftEarlyWarningItemsQuery,
  DraftEarlyWarningItemsQueryVariables,
  EarlyWarningItemsQuery,
  EarlyWarningItemsQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import {
  draftEarlyWarningItemsQuery,
  earlyWarningItemsQuery,
} from "../Projects.query";

export const useEarlyWarningsRegister = () => {
  const [fetchEWItemsData, { data: ewItemsData, loading: ewItemsDataLoading }] =
    useGraphLazyQuery<EarlyWarningItemsQuery, EarlyWarningItemsQueryVariables>(
      earlyWarningItemsQuery
    );

  const [
    fetchDraftEWItemsData,
    { data: draftEWItemsData, loading: draftEWItemsDataLoading },
  ] = useGraphLazyQuery<
    DraftEarlyWarningItemsQuery,
    DraftEarlyWarningItemsQueryVariables
  >(draftEarlyWarningItemsQuery);

  return {
    ewItemsData,
    draftEWItemsData,
    ewItemsDataLoading,
    draftEWItemsDataLoading,
    ewsLoading: draftEWItemsDataLoading || ewItemsDataLoading,
    fetchEWItemsData,
    fetchDraftEWItemsData,
  };
};
