import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { lookupOptionFields } from "./lookupOption.fragment";

export const companyLookupCollectionFields = gql`
  ${creatorFields}
  ${lookupOptionFields}

  fragment CompanyLookupCollectionFields on CompanyLookupCollection {
    id
    companyId
    name
    optionsLockedForUsers
    optionsCharLengthLimit
    usage
    status
    creatorId
    dateCreated
    creator {
      ...CreatorFields
    }
    options {
      items {
        ...LookupOptionFields
      }
    }
  }
`;
