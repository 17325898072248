import { gql } from "@apollo/client";
import { attachmentLiteFields } from "graphql/fragments/attachmentLite.fragment";
import { variationAgreementLiteFields } from "graphql/fragments/variationAgreementLite.fragment";
import { variationDeterminationLiteFields } from "graphql/fragments/variationDeterminationLite.fragment";
import { variationProposalLiteFields } from "graphql/fragments/variationProposalLite.fragment";

export const variationProposalExplorerItemsQuery = gql`
  ${attachmentLiteFields}
  ${variationProposalLiteFields}
  ${variationAgreementLiteFields}
  ${variationDeterminationLiteFields}

  query variationProposalExplorerItems($variationId: ID!) {
    variationProposalExplorerItems(variationId: $variationId) {
      items {
        ... on VariationProposal {
          ...VariationProposalLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
        ... on VariationAgreement {
          ...VariationAgreementLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
        ... on VariationDetermination {
          ...VariationDeterminationLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }
    }
  }
`;
