import { gql } from "@apollo/client";
import { claimTypeFields } from "./claimType.fragment";
import { creatorFields } from "./creator.fragment";

export const disabledClaimTypeOverrideFields = gql`
  ${creatorFields}
  ${claimTypeFields}

  fragment DisabledClaimTypeOverrideFields on DisabledClaimTypeOverride {
    id
    contractId
    claimTypeId
    dateCreated
    creatorId
    claimType {
      ...ClaimTypeFields
    }
    contract {
      id
      name
      friendlyName
      timeZone
    }
    creator {
      ...CreatorFields
    }
  }
`;
