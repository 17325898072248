import { gql } from "@apollo/client";
import { userFields } from "graphql/fragments/user.fragment";

export const authenticationDataQuery = gql`
  ${userFields}

  query authenticationData {
    authenticationData {
      user {
        ...UserFields
      }
      isAdmin
      intercomIdentityHash
    }
  }
`;
