import {
  GridFilterModel,
  GridLogicOperator,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { CompEventItem } from "generated/graphql";
import { useCallback, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useColumns } from "./CompEventsTable.constants";
import { exportToExcel } from "../../../../../../../helpers/exportToExcel";
import { useSearchParams } from "react-router-dom";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { useCEClaimVariationTable } from "hooks/useCEClaimVariationTable";
import { Column } from "exceljs";
import {
  excelDateTimeISOFormat,
  excelTwoDecimalsNumberFormat,
} from "constants/constants";
import { getDatetimeOnTz } from "helpers/timezones";

export type CompEventsTableProps = {
  compEvents: CompEventItem[];
  loading: boolean;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
};

export const CompEventsTable: React.FC<CompEventsTableProps> = ({
  compEvents,
  loading,
  apiRef,
  selectionModel,
  onSelectionModelChange,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const contractId = searchParams.get("contractId");

  const { handleRowClick, handleClearSelection, handleSelectionModelChange } =
    useCEClaimVariationTable(projectId!, contractId!, onSelectionModelChange);

  const gridApiRef = useGridApiRef();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "lastAction", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = useCallback(async () => {
    if (!compEvents || !compEvents.length) {
      console.warn("No comp events to export");
      return;
    }

    const columns: Partial<Column>[] = [
      {
        header: t("Projects.CompEvents.ceNumber"),
        key: "number",
      },
      {
        header: t("common.labels.status"),
        key: "status",
      },
      {
        header: t("common.labels.price"),
        key: "price",
        numFmt: excelTwoDecimalsNumberFormat,
      },
      {
        header: t("common.labels.time"),
        key: "time",
      },
      {
        header: t("Projects.EarlyWarnings.earlyWarning"),
        key: "earlyWarning",
      },
      {
        header: t("Projects.CompEvents.lastAction"),
        key: "lastAction",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.regarding"),
        key: "regarding",
      },
    ];

    const rows = compEvents
      .filter((compEvent) => (selectionModel || []).indexOf(compEvent.id) >= 0)
      .map((compEvent) => ({
        ...compEvent,
        price: typeof compEvent.price === "number" ? compEvent.price : "-",
        time: typeof compEvent.time === "number" ? compEvent.time : "-",
        lastAction: compEvent.lastAction.date
          ? getDatetimeOnTz(
              compEvent.lastAction.date,
              compEvent.productInstance.contract.timeZone
            )
          : "",

        regarding:
          compEvent.regardingEvent?.title ??
          compEvent.regardingInstruction?.title,
        earlyWarning: compEvent.earlyWarning?.number ?? "",
      }));

    exportToExcel(t("Projects.CompEvents.compEvents"), columns, rows);
  }, [compEvents, selectionModel, t]);

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  const columns = useColumns();

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={compEvents || []}
      columns={columns}
      getRowId={(rowData: CompEventItem) => rowData.id}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading}
      sortingMode="client"
      sortModel={sortingModel}
      onSortModelChange={setSortingModel}
      filterMode="client"
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onRowClick={handleRowClick}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
