import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { productInstanceLiteFields } from "./productInstanceLite.fragment";
import { companyLiteFields } from "./companyLite.fragment";

// TODO: not high priority, but split this query into multiple fragments. AdminConsole has been built on queries without fragments
export const userFields = gql`
  ${creatorFields}
  ${productInstanceLiteFields}
  ${companyLiteFields}

  fragment UserFields on User {
    id
    firstname
    surname
    jobTitle
    country
    mobileNumber
    alternateNumber
    dateOfBirth
    profilePicture
    email
    companyId
    company {
      ...CompanyLiteFields
    }
    registered
    status
    lastActive
    dateInvited
    registeredDate
    invitedBy {
      ...CreatorFields
    }
    roles {
      items {
        userId
        productInstanceId
        productInstance {
          ...ProductInstanceLiteFields
        }
        productRoleId
        status
        dateCreated
        creatorId
        creator {
          ...CreatorFields
        }
        user {
          ...CreatorFields
        }
        productRole {
          id
          name
          productId
          actionIds
          isInternal
          status
          dateCreated
          creatorId
          creator {
            ...CreatorFields
          }
          actions {
            items {
              id
              name
              isInternal
              status
              dateCreated
              creatorId
              creator {
                ...CreatorFields
              }
            }
          }
        }
      }
    }
  }
`;
