import { gql } from "@apollo/client";

export const contractBindingQuery = gql`
  query contractBinding($id: ID!) {
    contractBinding(id: $id) {
      id
      contractBindingTypeId
      status
      defaultCompany
      representativeId
      isCompanyBilled
      dateCreated
      creator {
        id
        firstname
        surname
      }
      companyId
      contractId
      company {
        id
        registeredName
        tradingName
      }
      contractBindingType {
        id
        description
      }
      representative {
        id
        firstname
        surname
      }
    }
  }
`;

export const editContractBindingMutation = gql`
  mutation editContractBinding($input: EditContractBindingInput!) {
    editContractBinding(input: $input) {
      id
      contractBindingTypeId
      status
      defaultCompany
      representativeId
      isCompanyBilled
      dateCreated
      creator {
        id
        firstname
        surname
      }
      companyId
      contractId
      company {
        id
        registeredName
        tradingName
      }
      contractBindingType {
        id
        description
      }
      representative {
        id
        firstname
        surname
      }
    }
  }
`;
