import { Box, css, styled } from "@mui/material";
import { unofficialThemeColors } from "theme/extendedTheme";

export const Container = styled(Box)(
  ({ theme }) => css`
    border-radius: 8px;
    border: 1px solid ${theme.palette.grey[300]};
    padding: ${theme.spacing(2)};
    width: 100%;
    box-sizing: border-box;
    position: relative;
    height: 166px;
  `
);

export const EmptyProgressBar = styled(Box)(
  ({ theme }) => css`
    border-radius: 8px;
    border: 1px solid ${theme.palette.grey[200]};
    background: ${theme.palette.grey[100]};
    width: 100%;
    height: 24px;
    z-index: 1;
    position: relative;
  `
);

export const ProgressBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== "completed",
})<{ completed?: boolean }>(
  ({ completed }) => css`
    border-radius: 8px;
    border: 1px solid;
    border-color: ${completed // unofficialThemeColors.amberDarker with 0.2 opacity
      ? "rgba(42, 162, 62, 0.20)"
      : "rgba(204, 101, 7, 0.2)"};
    background: ${completed
      ? unofficialThemeColors.greenPale
      : unofficialThemeColors.amber1};
    height: 100%;
    position: absolute;
    height: 24px;
    left: -1px;
    top: -1px;
  `
);
