import { Box, Stack } from "@mui/material";
import {
  AuthorizationWorkflowAudit,
  ClaimItem,
  ClaimRegardingType,
  CompEventItem,
  CompEventRegardingType,
  EarlyWarningItem,
  InstructionItem,
  ProductType,
  VariationItem,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { ProductItemCard } from "../../../../../components/ProductItemCard/ProductItemCard";
import { ActionMetadata } from "components/Authorization/AuthorizeDenyActionModal/ActionMetadata";
import { CollapsibleContainer } from "../../../../../components/CollapsibleContainer";
import { AuthorizationLevels } from "components/Authorization/AuthorizeDenyActionModal/components/AuthorizationLevels/AuthorizationLevels";
import { ActionDetailsContainer } from "./AuthorizeDenyActionView.styled";
import { CompEventSummary } from "./components/CompEventSummary";
import { EWInstructionSummary } from "./components/EWInstructionSummary";
import { VariationSummary } from "./components/VariationSummary";
import { ClaimSummary } from "./components/ClaimSummary";
import { useAuthAudit } from "./hooks/useAuthAudit/useAuthAudit";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { NewAppPaths } from "helpers/paths/paths";
import { ProductItemCardRegardingAdornment } from "./components/ProductItemCardRegardingAdornment";

export type AuthorizeDenyActionViewProps = {
  authAuditId: string;
  authAudit?: AuthorizationWorkflowAudit;
  onClose?: () => void;
};

export const AuthorizeDenyActionView: React.FC<
  AuthorizeDenyActionViewProps
> = ({ authAuditId, authAudit: externalAuthAudit, onClose }) => {
  const navigate = useNavigate();
  const { authAudit, loading: authAuditLoading } = useAuthAudit(
    authAuditId,
    !!externalAuthAudit
  );

  const computedAuthAudit = useMemo(
    () => externalAuthAudit ?? authAudit,
    [externalAuthAudit, authAudit]
  );

  const { t } = useTranslation();
  const isCompEvent =
    computedAuthAudit?.regardingProductType === ProductType.CompEvents;
  const isClaim =
    computedAuthAudit?.regardingProductType === ProductType.Claims;
  const isVariation =
    computedAuthAudit?.regardingProductType === ProductType.Variations;
  const isEarlyWarning =
    computedAuthAudit?.regardingProductType === ProductType.EarlyWarnings;
  const isInstruction =
    computedAuthAudit?.regardingProductType === ProductType.Instructions;

  const itemName =
    isCompEvent || isClaim || isVariation
      ? computedAuthAudit.regardingProductItem.number
      : `${
          computedAuthAudit?.regardingProductItem.productInstance
            .numberingFormat ??
          computedAuthAudit?.regardingProductItem.productInstance.product
            .numberingFormat
        }XXXX`;

  const claimRegardingItem = useMemo(() => {
    if (isClaim && computedAuthAudit) {
      return {
        regardingType:
          (computedAuthAudit.regardingProductItem as ClaimItem)
            .regardingType === ClaimRegardingType.Event
            ? ProductType.Events
            : ProductType.Instructions,
        title:
          (computedAuthAudit.regardingProductItem as ClaimItem).regardingEvent
            ?.title ??
          (computedAuthAudit.regardingProductItem as ClaimItem)
            .regardingInstruction?.title,
        productInstanceId: ((
          computedAuthAudit.regardingProductItem as ClaimItem
        ).regardingEvent?.productInstanceId ??
          (computedAuthAudit.regardingProductItem as ClaimItem)
            .regardingInstruction?.productInstanceId) as string,
        id: ((computedAuthAudit.regardingProductItem as ClaimItem)
          .regardingEvent?.id ??
          (computedAuthAudit.regardingProductItem as ClaimItem)
            .regardingInstruction?.id) as string,
      };
    }
  }, [isClaim, computedAuthAudit]);

  const ceRegardingItem = useMemo(() => {
    if (isCompEvent && computedAuthAudit) {
      return {
        regardingType:
          (computedAuthAudit.regardingProductItem as CompEventItem)
            .regardingType === CompEventRegardingType.Event
            ? ProductType.Events
            : ProductType.Instructions,
        title:
          (computedAuthAudit.regardingProductItem as CompEventItem)
            .regardingEvent?.title ??
          (computedAuthAudit.regardingProductItem as CompEventItem)
            .regardingInstruction?.title,
        productInstanceId: ((
          computedAuthAudit.regardingProductItem as CompEventItem
        ).regardingEvent?.productInstanceId ??
          (computedAuthAudit.regardingProductItem as CompEventItem)
            .regardingInstruction?.productInstanceId) as string,
        id: ((computedAuthAudit.regardingProductItem as CompEventItem)
          .regardingEvent?.id ??
          (computedAuthAudit.regardingProductItem as CompEventItem)
            .regardingInstruction?.id) as string,
      };
    }
  }, [isCompEvent, computedAuthAudit]);

  const variationRegardingItem = useMemo(() => {
    if (isVariation && computedAuthAudit) {
      return {
        regardingType: ProductType.Instructions, // NOTE: will be updated soon
        title: (computedAuthAudit.regardingProductItem as VariationItem)
          .regardingInstruction?.title, // NOTE: will be updated soon
        productInstanceId: (
          computedAuthAudit.regardingProductItem as VariationItem
        ).regardingInstruction?.productInstanceId!,
        id: (computedAuthAudit.regardingProductItem as VariationItem)
          .regardingInstruction?.id!,
      };
    }
  }, [isVariation, computedAuthAudit]);

  const regardingRegardingItemData =
    claimRegardingItem ?? ceRegardingItem ?? variationRegardingItem;

  const handleRegardingRegardingItemClick = () => {
    if (regardingRegardingItemData) {
      const path =
        regardingRegardingItemData.regardingType === ProductType.Instructions
          ? NewAppPaths.authorized.Projects.children.InstructionDetails.pathConstructor(
              regardingRegardingItemData.productInstanceId,
              regardingRegardingItemData.id,
              regardingRegardingItemData.regardingType
            )
          : NewAppPaths.authorized.Projects.children.EventDetails.pathConstructor(
              regardingRegardingItemData.productInstanceId,
              regardingRegardingItemData.id,
              regardingRegardingItemData.regardingType
            );

      onClose?.();
      navigate(path);
    }
  };

  return (
    <>
      {authAuditLoading || !computedAuthAudit ? (
        <CenteredLoadingIndicator />
      ) : (
        <Stack spacing={3}>
          <ProductItemCard
            itemName={itemName}
            productType={computedAuthAudit.regardingProductType}
            projectName={
              computedAuthAudit.action.productInstance.contract.project
                .friendlyName
            }
            contractName={
              computedAuthAudit.action.productInstance.contract.friendlyName
            }
            orientation="row"
            hideTitle={[
              ProductType.Instructions,
              ProductType.EarlyWarnings,
            ].includes(computedAuthAudit.regardingProductType)}
            adornment={
              <ProductItemCardRegardingAdornment
                authRegardingType={computedAuthAudit.regardingProductType}
                regardingRegardingType={
                  regardingRegardingItemData?.regardingType!
                }
                regardingRegardingTitle={
                  regardingRegardingItemData?.title ?? ""
                }
                onClick={handleRegardingRegardingItemClick}
              />
            }
          />
          <ActionDetailsContainer>
            <ActionMetadata authAudit={computedAuthAudit} />
            <Box mt={2}>
              {isCompEvent ? (
                <CompEventSummary authAudit={computedAuthAudit} />
              ) : isVariation ? (
                <VariationSummary authAudit={computedAuthAudit} />
              ) : isClaim ? (
                <ClaimSummary authAudit={computedAuthAudit} />
              ) : isEarlyWarning || isInstruction ? (
                <EWInstructionSummary
                  ewInstItem={
                    computedAuthAudit.regardingProductItem as
                      | EarlyWarningItem
                      | InstructionItem
                  }
                />
              ) : null}
            </Box>
          </ActionDetailsContainer>
          <CollapsibleContainer
            title={t("Home.Authorizations.authorizations")}
            subtitle={t(
              "Home.Authorizations.AuthorizeDenyModal.levelsSubtitle"
            )}
          >
            <Box mt={3} position="relative">
              <AuthorizationLevels authAudit={computedAuthAudit} />
            </Box>
          </CollapsibleContainer>
        </Stack>
      )}
    </>
  );
};
