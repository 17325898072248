import { gql } from "@apollo/client";
import { companyLookupCollectionFields } from "graphql/fragments/companyLookupCollection.fragment";

export const availableLookupsByContractQuery = gql`
  ${companyLookupCollectionFields}

  query availableLookupsByContract($contractId: ID!) {
    availableLookupsByContract(contractId: $contractId) {
      nextToken
      items {
        ...CompanyLookupCollectionFields
      }
    }
  }
`;
