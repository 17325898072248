import { AuthorizationWorkflowAudit } from "generated/graphql";
import { useApproveDiaryAuthorizationWorkflow } from "containers/Projects/containers/DailyDiary/hooks/useApproveDiaryAuthorizationWorkflow";
import { useLoggedInUserReviewer } from "./useLoggedInUserReviewer";

/**
 * Returns isLoggedInUserDiaryApprover: boolean if current user needs to approve / reject the dailyDiary in the current state. It takes into consideration
 * Diary's state, workflow levels and if user already approved.
 * @param dailyDiaryProductInstanceId
 * @param authAudit
 * @returns isLoggedInUserDiaryApprover: boolean, loading: boolean
 */
export const useLoggedInUserDiaryApprover = (
  dailyDiaryProductInstanceId: string,
  authAudit?: AuthorizationWorkflowAudit
) => {
  const { approveDiaryAuthorizationWorkflow, loading } =
    useApproveDiaryAuthorizationWorkflow(
      dailyDiaryProductInstanceId,
      !!authAudit
    );

  const { canUserReviewCrtLevel } = useLoggedInUserReviewer(
    authAudit,
    approveDiaryAuthorizationWorkflow
  );

  return {
    isLoggedInUserDiaryApprover: canUserReviewCrtLevel,
    loading,
  };
};
