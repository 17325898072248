import { gql } from "@apollo/client";
import { attachmentFields } from "containers/Projects/containers/EarlyWarningDetails/EarlyWarningDetails.query";
import { dailyDiaryWeatherRecordLiteFields } from "graphql/fragments/dailyDiaryWeatherRecordLite.fragment";

export const dailyDiaryWeatherRecordsQuery = gql`
  ${dailyDiaryWeatherRecordLiteFields}
  ${attachmentFields}

  query dailyDiaryWeatherRecords($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryWeatherRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      ...DailyDiaryWeatherRecordLiteFields
      attachments {
        ...AttachmentFields
      }
    }
  }
`;

export const dailyDiaryWeatherImportSourceItemsQuery = gql`
  query dailyDiaryWeatherImportSourceItems(
    $productInstanceId: ID!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    dailyDiaryWeatherImportSourceItems(
      productInstanceId: $productInstanceId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      items {
        id
        productInstanceId
        date
        number
        status
        dateCreated
        creatorId
        sentById
        dateSent
        latestRevision {
          id
          number
          status
          dateSent
        }
      }
    }
  }
`;

export const importDailyDiaryWeatherRecordsMutation = gql`
  mutation importDailyDiaryWeatherRecords(
    $input: ImportDailyDiaryRecordsInput!
  ) {
    importDailyDiaryWeatherRecords(input: $input)
  }
`;
