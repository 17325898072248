import {
  EditUserMutationMutation,
  EditUserMutationMutationVariables,
} from "generated/graphql";
import { editUserMutation } from "graphql/mutations/editUserMutation";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";

export const useEditUser = (onUpdate?: () => void) => {
  const { t } = useTranslation();

  const [editUser, { loading: editUserLoading }] = useGraphMutation<
    EditUserMutationMutation,
    EditUserMutationMutationVariables
  >(
    editUserMutation,
    {
      update: (cache) => {
        onUpdate?.();

        cache.evict({ id: "ROOT_QUERY", fieldName: "company" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.user"),
    })
  );

  return {
    editUser,
    editUserLoading,
  };
};
