import {
  ChangeCompanyLookupCollectionStatusMutation,
  ChangeCompanyLookupCollectionStatusMutationVariables,
  CompanyLookupCollection,
  CompanyLookupCollectionQuery,
  CompanyLookupCollectionQueryVariables,
  EditCompanyLookupCollectionMutation,
  EditCompanyLookupCollectionMutationVariables,
} from "generated/graphql";
import { changeCompanyLookupCollectionStatusMutation } from "graphql/mutations/changeCompanyLookupCollectionStatus";
import { editCompanyLookupCollectionMutation } from "graphql/mutations/editCompanyLookupCollection";
import { companyLookupCollectionQuery } from "graphql/queries/companyLookupCollection.query";
import { useTranslation } from "react-i18next";
import { useGraphMutation } from "./useGraphMutation";
import { useGraphQuery } from "./useGraphQuery";

export const useCustomLookupCollection = (lookupCollectionId: string) => {
  const { t } = useTranslation();

  const {
    data: companyLookupCollection,
    refetch: refetchCompanyLookupCollection,
    loading: companyLookupCollectionLoading,
  } = useGraphQuery<
    CompanyLookupCollectionQuery,
    CompanyLookupCollectionQueryVariables
  >(companyLookupCollectionQuery, { variables: { id: lookupCollectionId } });

  const [
    editCompanyLookupCollection,
    { loading: editCompanyLookupCollectionLoading },
  ] = useGraphMutation<
    EditCompanyLookupCollectionMutation,
    EditCompanyLookupCollectionMutationVariables
  >(
    editCompanyLookupCollectionMutation,
    {
      update: (cache) => {
        refetchCompanyLookupCollection();

        cache.evict({
          id: "ROOT_QUERY",
          fieldName: "companyLookupCollections",
        });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Lookups.customLookupCollection"),
    })
  );

  const [
    changeCompanyLookupCollectionStatus,
    { loading: changeCompanyLookupCollectionStatusLoading },
  ] = useGraphMutation<
    ChangeCompanyLookupCollectionStatusMutation,
    ChangeCompanyLookupCollectionStatusMutationVariables
  >(
    changeCompanyLookupCollectionStatusMutation,
    {
      update: () => refetchCompanyLookupCollection(),
    },
    t("common.successMessages.entityStatusUpdated", {
      entity: t("AdminConsole.Lookups.customLookupCollection"),
    })
  );

  return {
    companyLookupCollection:
      companyLookupCollection?.companyLookupCollection as
        | CompanyLookupCollection
        | undefined,
    refetchCompanyLookupCollection,
    companyLookupCollectionLoading,
    editCompanyLookupCollection,
    changeCompanyLookupCollectionStatus,
    loading:
      companyLookupCollectionLoading ||
      editCompanyLookupCollectionLoading ||
      changeCompanyLookupCollectionStatusLoading,
    editCompanyLookupCollectionLoading,
    changeCompanyLookupCollectionStatusLoading,
  };
};
