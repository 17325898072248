import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { supportMail } from "constants/constants";
import { useMemo } from "react";
import { ApolloError } from "@apollo/client";
import { StyledLink, StyledMuiLink } from "components/StyledLink";
import { NewAppPaths } from "helpers/paths/paths";

export enum RegisterErrorType {
  InvitationExpired,
  InvitationNotFound,
  Unknown,
}

type ErrorViewProps = {
  registrationError: ApolloError;
  registrationId?: string | null;
};

const isInvitationExpired = (errMsg: string) =>
  errMsg === "Invitation has expired";
const isInvitationNotFound = (errMsg: string, registrationId?: string | null) =>
  errMsg === `Invitation with ID ${registrationId} was not found`;

export const getErrorType = (
  errMsg: string,
  registrationId?: string | null
) => {
  if (isInvitationExpired(errMsg)) {
    return RegisterErrorType.InvitationExpired;
  } else if (isInvitationNotFound(errMsg, registrationId)) {
    return RegisterErrorType.InvitationNotFound;
  }

  return RegisterErrorType.Unknown;
};

export const ErrorView: React.FC<ErrorViewProps> = ({
  registrationError,
  registrationId,
}) => {
  const { t } = useTranslation();
  const registrationErrorType = getErrorType(
    registrationError.message,
    registrationId
  );

  const errorTitle = useMemo(() => {
    return t(
      `Register.errorMessages.${
        registrationErrorType === RegisterErrorType.InvitationExpired
          ? "invitationExpired"
          : registrationErrorType === RegisterErrorType.InvitationNotFound
          ? "invitationNotFound"
          : "unkownError"
      }`
    );
  }, [t, registrationErrorType]);

  return (
    <Stack textAlign="center">
      <Typography variant="h3" pb={2}>
        {errorTitle}
      </Typography>
      {registrationErrorType === RegisterErrorType.InvitationExpired ? (
        <StyledMuiLink href={`mailto:${supportMail}`}>
          <Typography variant="p1">
            {t("Register.requestNewInvitation")}
          </Typography>
        </StyledMuiLink>
      ) : registrationErrorType === RegisterErrorType.InvitationNotFound ? (
        <StyledLink to={NewAppPaths.nonAuthorized.Login}>
          <Typography variant="p1">{t("Register.signin")}</Typography>
        </StyledLink>
      ) : (
        <StyledMuiLink href={`mailto:${supportMail}`}>
          <Typography variant="p1">
            {t("common.errorMessages.generic")}
          </Typography>
        </StyledMuiLink>
      )}
    </Stack>
  );
};
