import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ExtendedOptionStyles } from "components/StatusTag/StatusTagNew";
import { StyledChipNew } from "components/StatusTag/StatusTagNew.styled";
import { ItemStatusOptionStyles } from "generated/graphql";
import { CaretDown, CaretUp } from "phosphor-react";
import { StatusStyles } from "theme/extendedTheme";

export enum PriceTimeTagStyle {
  Increase,
  Decrease,
  Static,
}

export type PriceTimeTagProps = {
  label: React.ReactNode;
  type: "price" | "time";
  style: PriceTimeTagStyle;
  sx?: SxProps<Theme>;
};

export const TagStatusStyles: Record<
  ExtendedOptionStyles | ItemStatusOptionStyles,
  { color: string; bgColor: string }
> = {
  ...StatusStyles,
  Grey: {
    color: "#495765",
    bgColor: "#F6F7F9",
  },
  Red: {
    color: "#E92B33",
    bgColor: "#FED8D9",
  },
};

export const PriceTimeTag: React.FC<PriceTimeTagProps> = ({
  label,
  type,
  style,
  sx = {},
}) => {
  const computedStyle = label
    ? style === PriceTimeTagStyle.Increase
      ? "Red"
      : style === PriceTimeTagStyle.Decrease
      ? "Green"
      : "Grey"
    : "Grey";
  const tagStyle = TagStatusStyles[computedStyle];
  const isEmpty = !label;

  return (
    <StyledChipNew
      variant="filled"
      bgColor={tagStyle.bgColor}
      size="small"
      sx={{ ...sx, borderRadius: "8px" }}
      label={
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          {!isEmpty && (
            <Box mr={0.75} display="flex" alignItems="center">
              {style === PriceTimeTagStyle.Increase ? (
                <CaretUp
                  width={15}
                  height={20}
                  weight="fill"
                  color={tagStyle.color}
                />
              ) : style === PriceTimeTagStyle.Decrease ? (
                <CaretDown
                  width={15}
                  height={20}
                  weight="fill"
                  color={tagStyle.color}
                />
              ) : (
                ""
              )}
            </Box>
          )}
          <Typography
            variant="p3"
            fontWeight={600}
            color={tagStyle.color}
            flex={1}
            data-testid={type === "price" ? "price-value" : "time-value"}
          >
            {label || "-"}
          </Typography>
        </Box>
      }
    />
  );
};
