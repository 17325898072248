import { gql } from "@apollo/client";
import { claimRecipientFields } from "graphql/fragments/claimRecipient.fragment";

export const claimErrorDeterminationDisagreementPromptQuery = gql`
  query claimErrorDeterminationDisagreementPrompt(
    $input: ClaimErrorDeterminationDisagreementPromptInput!
  ) {
    claimErrorDeterminationDisagreementPrompt(input: $input) {
      remarks
    }
  }
`;

export const sendClaimErrorDeterminationDisagreementMutation = gql`
  mutation sendClaimErrorDeterminationDisagreement(
    $input: SendClaimErrorDeterminationDisagreementInput!
  ) {
    sendClaimErrorDeterminationDisagreement(input: $input) {
      id
    }
  }
`;

export const recordClaimErrorDeterminationDisagreementMutation = gql`
  mutation recordClaimErrorDeterminationDisagreement(
    $input: RecordClaimErrorDeterminationDisagreementInput!
  ) {
    recordClaimErrorDeterminationDisagreement(input: $input) {
      id
    }
  }
`;

export const generateSendClaimErrorDeterminationDisagreementPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateSendClaimErrorDeterminationDisagreementPreview(
    $input: SendClaimErrorDeterminationDisagreementPreviewInput!
  ) {
    generateSendClaimErrorDeterminationDisagreementPreview(input: $input) {
      id
      fileUrl
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;

export const generateRecordClaimErrorDeterminationDisagreementPreviewMutation = gql`
  ${claimRecipientFields}

  mutation generateRecordClaimErrorDeterminationDisagreementPreview(
    $input: RecordClaimErrorDeterminationDisagreementPreviewInput!
  ) {
    generateRecordClaimErrorDeterminationDisagreementPreview(input: $input) {
      recipients {
        ...ClaimRecipientFields
      }
    }
  }
`;
